import { crudQuery, ICRUDQuery } from '../Core/query';

const teamStaff = `
id
manager {
  avatar
  createdAt
  email
  gender
  id
  name
  phoneNumber
  status
}
`;

export const GroupTeamStaff = 'group_manager';
// tslint:disable-next-line:no-empty-interface
interface ITeamStaffQuery extends ICRUDQuery {
  //
}
export const getTeamStaffQuery: ITeamStaffQuery = {
  ...crudQuery(GroupTeamStaff, teamStaff),
};
