import { DKGqlClient } from 'root/api/graphql/Client';
import { ActionType, IState, MutationType } from 'root/store';
import { crudActions } from 'root/store/helpers';
import { ActionContext } from 'vuex';
import { IPointsState } from './mutation';
import { ActionTypePoints } from './types';

const { points } = DKGqlClient();
export const actions = {
  ...crudActions('points', {
    filter: points.filter
  }),
  async [ActionTypePoints.GET_LIST_POINT]({
    commit, dispatch
  }: ActionContext<IPointsState, IState>, {form}) {
    try {
      const PointsList = await points.getListPoint(form);
      commit(MutationType.GetPoints, PointsList);
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    }
  }
};
