// import { Message } from 'element-ui';
import { isNil } from 'lodash';
// import moment from 'moment';
import { BannerRouter } from 'root/admin/BannerManagement';
import { BranchAndGroupRouter } from 'root/admin/BranchAndGroup';
import { CustomCampaignRouter, RaCampaignRouter } from 'root/admin/CustomCampaign';
import { NewRaCampaignRouter } from 'root/admin/CustomCampaign/NewRACampaign2023';
import { SaleInCampaignRouter } from 'root/admin/CustomCampaign/SaleInCampaign';
import { SalesEduCampaignRouter } from 'root/admin/CustomCampaign/SalesEduCampaign';
import { zeroPercentCampaignRouter } from 'root/admin/CustomCampaign/ZeroPercentCampaign';
import { dealerRouter } from 'root/admin/Dealer';
import { LoginContainer } from 'root/admin/Login';
import { manageDealerTypeRouter } from 'root/admin/ManageDealerType';
import { manageFeedbackRouter } from 'root/admin/ManageFeedback';
import { manageImportRouter } from 'root/admin/ManageImport';
import { manageInvoiceRouter } from 'root/admin/ManageInvoice';
import { productRouter } from 'root/admin/ProductManage';
import { RedemptionRouter } from 'root/admin/Redemption';
import { userRouter, UserRouterName } from 'root/admin/User';
import { SystemRoleAlias } from 'root/admin/User/Store/types';
import { WelcomePageRouter } from 'root/admin/WelcomePage';
import { IUser } from 'root/models';
// import { ActionType, Layout, MutationType } from 'root/store';
import Vue from 'vue';
import VueRouter, { Route } from 'vue-router';
import { NewsNotificationsRouter } from '../admin/NewsAnnoucement';
import store from '../store';
import { RouteDictionary } from './types';

Vue.use(VueRouter);
function getUserState(): Promise<IUser> {
  return new Promise((resolve) => {
    if (isNil(store.state.global.authUser)) {
      const unwatch = store.watch(
        () => store.state.global.authUser,
        (value) => {
          unwatch();
          resolve(value);
        }
      );
    } else {
      resolve(store.state.global.authUser);
    }

    return;
  });
}
async function redirectSuperAdmin(to: any, _: any, next) {
  const user = await getUserState();
  const { role, superAdmin} = user || {
    role: {
      alias: null
    },
    superAdmin: false
  };
  // const expireTime = moment(localStorage.getItem('ExpireTime'), 'DD-MM-YYYY HH:mm:ss').add(1, 'minutes');
  // const now = moment();
  // if (expireTime && now.isBefore(expireTime, 'minutes')) {
  //   console.log('expire before');

  //   return next(next.path);

  // }
  // console.log('router', expireTime.format('DD-MM-YYYY'));
  // if (expireTime && now.isAfter(expireTime, 'minutes')) {
  //   store.dispatch(ActionType.Unauthenticated, router);
  //   store.commit(MutationType.Unauthenticated);
  //   store.commit(MutationType.SetLayout, Layout.Login);
  //   Message.error('Your session was expired, please try to login again!');

  //   return next('/login');
  // }
  if (superAdmin || role && role.alias === SystemRoleAlias.Admin) {
    return next();
  }
  const routerDealer = [
    SystemRoleAlias.SaleAdmin,
    SystemRoleAlias.RegionDirector,
    // SystemRoleAlias.SaleStaff
  ];
  const routerBranch = [
    SystemRoleAlias.BranchManager,
    SystemRoleAlias.BranchPIC,
    SystemRoleAlias.SaleDepartmentPic,
    SystemRoleAlias.GroupLeader,
  ];
  const routerGroup = [
    SystemRoleAlias.TeamLeader,
    SystemRoleAlias.Staff
  ];
  const routerSaleMan = [
    SystemRoleAlias.CallCenter,
    SystemRoleAlias.Operator
  ];
  if (role && role.alias === SystemRoleAlias.SaleStaff) {
    if (to.name === UserRouterName.List) {
      return next('/2023-campaign/registration');
    }

    return next();
  }
  if (role && routerDealer.includes(role.alias)) {
    if (to.name === UserRouterName.List) {
      return next('/manager-dealers/list');
    }

    return next();
  }
  if (role && routerGroup.includes(role.alias)) {
    if (to.name === UserRouterName.List) {
      return next('/branches-groups/groups');
    }

    return next();
  }
  if (role && routerBranch.includes(role.alias)) {
    if (to.name === UserRouterName.List) {
      return next('/manager-dealers/list');
    }

    return next();
  }
  if (role && routerSaleMan.includes(role.alias)) {
    if (to.name === UserRouterName.List) {
      return next('/manager-dealers/sub-dealer');
    }

    return next();
  }

  return next(next.path);

}
const router = new VueRouter({
  mode: 'history',
  routes: [
    {
      ...WelcomePageRouter,
      beforeEnter: redirectSuperAdmin
    },
    {
      ...NewsNotificationsRouter,
      beforeEnter: redirectSuperAdmin
    },
    {
      ...BannerRouter,
      beforeEnter: redirectSuperAdmin
    },
    {
      path: RouteDictionary.Login,
      component: LoginContainer,
      meta: {
        isGuest: true
      }
    },
    {
      ...userRouter,
      beforeEnter: redirectSuperAdmin
    },
    {
      ...dealerRouter,
      beforeEnter: redirectSuperAdmin
    },
    {
      ...BranchAndGroupRouter,
      beforeEnter: redirectSuperAdmin
    },
    {
      ...CustomCampaignRouter,
      beforeEnter: redirectSuperAdmin
    },
    {
      ...RaCampaignRouter,
      beforeEnter: redirectSuperAdmin
    },
    {
      ...zeroPercentCampaignRouter,
      beforeEnter: redirectSuperAdmin
    },
    {
      ...NewRaCampaignRouter,
      beforeEnter: redirectSuperAdmin
    },
    {
      ...manageInvoiceRouter,
      beforeEnter: redirectSuperAdmin
    },
    {
      ...manageImportRouter,
      beforeEnter: redirectSuperAdmin
    },
    {
      ...manageFeedbackRouter,
      beforeEnter: redirectSuperAdmin
    },
    {
      ...manageDealerTypeRouter,
      beforeEnter: redirectSuperAdmin
    },
    {
      ...productRouter,
      beforeEnter: redirectSuperAdmin
    },
    {
      ...RedemptionRouter,
      beforeEnter: redirectSuperAdmin
    },
    {
      ...SalesEduCampaignRouter,
      beforeEnter: redirectSuperAdmin
    },
    {
      ...SaleInCampaignRouter,
      beforeEnter: redirectSuperAdmin
    },
    {
      path: '*',
      redirect: '/'
    }
  ]
});

router.afterEach((to: Route, from: Route) => {
  if (to.name !== from.name) {
    window.scrollTo(0, 0);
  }
});

export default router;
