export enum PushStatus {
  Sent = 'sent',
  Scheduled = 'scheduled'
}

export enum PushTarget {
  All = 'all',
  CustomerApp = 'customer',
  TechnicianApp = 'technician',
  SingleCustomer = 'single_customer',
  SingleTechnician = 'single_technician',
  TechnicianGroup = 'technician_group',
}

export enum ETypePartner {
  InHouse = 'in_house',
  OutSource = 'out_source'
}

export interface INewsAnnouncement {
  id?: string;
  title?: string;
  subtitle?: string;
  description?: string;
  url?: string;
  createdAt?: string;
  updatedAt?: string;
  createdBy?: string;
  updatedBy?: string;
  type?: PushTargetType;
  image?: string;
}
export interface INewsAnnouncementForm extends INewsAnnouncement {
  target?: PushTarget[] | PushTarget;
  scheduledAt?: string;
  typeTechnician?: ETypePartner;
  receiverId?: string;
  filter?: {
    province?: string[];
  };
}

export const newsAnnouncementFormDefault = (): INewsAnnouncementForm => {
  return {
    title: '',
    description: '',
    url: '',
    scheduledAt: '',
    target: PushTarget.All,
    typeTechnician: ETypePartner.InHouse,
    filter: {
      province: ['all']
    }
  };
};

export enum PushTargetType {
  Mobile = 'mobile_notification',
  News = 'news'
}
