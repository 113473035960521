import { PermissionType } from 'root/models';
import { RouteConfig } from 'vue-router';
import { SalesEduCampaignContainer } from './SalesEduCampaignContainer';
import { SalesEduCampaignList } from './SalesEduCampaignList';

export enum SalesEduCampaignRouterPath {
  Default = '/sale-edu-campaign'
}
export enum SalesEduCampaignRouterName {
  Default = 'default',
}
export const SalesEduCampaignRouter: RouteConfig = {
  path: '/sale-edu-campaign',
  component: SalesEduCampaignContainer,
  redirect: SalesEduCampaignRouterPath.Default,
  meta: {
    permission: PermissionType.SalesEduCampaign
  },
  children: [
    {
      path: SalesEduCampaignRouterPath.Default,
      component: SalesEduCampaignList,
      name: SalesEduCampaignRouterName.Default,
      meta: {
        permission: PermissionType.SalesEduCampaign
      },
    },
  ]
};
