export enum ActionTypeGroup {
  GET_LIST_GROUP = 'getListGroup',
  FilterNoCache = 'groupFilterNoCache'
}
export enum ActionTypeGroupSystem {
  GET_LIST_GROUP_SYSTEM = 'getListGroupSystem',
  FilterNoCache = 'systemGroupFilterNoCache'
}
export enum MutationTypeGroup {
  FilterChange = 'groupFilterChange'
}
