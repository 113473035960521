import { INotification } from 'root/models/Notifications';
import { crudMutations, CRUDState, ICRUDState } from 'root/store/helpers';

export interface INotificationState extends ICRUDState<INotification> {}

export const defaultState: INotificationState = {
  ...CRUDState()
  // orderBy: {
  //   createdAt: 'desc'
  // }
};

export const mutations = {
  ...crudMutations('notifications')
};
