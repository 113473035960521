import { TabPane } from 'element-ui';
import { cloneDeep } from 'lodash';
import { ActionTypeBranchManage } from 'root/admin/BranchAndGroup/BranchManage/Store/types';
import { CustomCampaignRouterName, CustomCampaignTab } from 'root/admin/CustomCampaign';
import { SystemRoleAlias } from 'root/admin/User/Store/types';
import { IPaginationParams } from 'root/api/graphql/Core';
import { FilterByBranch } from 'root/components/FilterByBranch';
import { fullNameUser } from 'root/helpers';
import { IAddress, IBreadcrumb, IFilterInput, IUser } from 'root/models';
import { IState, MutationType } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import { ActionTypeAclCampaign, MutationTypeAclCampaign } from '../../Store/types';
import './styles.scss';

const enum FilterKey {
  Branch = 'branch'
}
@Component({
  template: require('./view.html'),
  computed: {
    ...mapState({
      data: (state: IState) => state.aclCampaign.data,
      loading: (state: IState) => state.aclCampaign.loading,
      pagination: (state: IState) => state.aclCampaign.pagination,
      authUser: (state: IState) => state.global.authUser,
      branch: (state: IState) => state.branchManage.data,
    }),
  },
  components: {
    'btn-filter-by-branch': FilterByBranch
  }
})

export class CampaignParticipant extends Vue {
  public get serviceId(): string {
    return this.$route.params.id;
  }

  public get activeTab() {
    return CustomCampaignTab.Participant;
  }

  public set activeTab(_value: string) {
    //
  }
  public get isSalePlanning() {
    if (this.authUser.superAdmin || this.authUser.role.alias === SystemRoleAlias.Admin
      || this.authUser.role.alias === SystemRoleAlias.SaleAdmin) {
      return true;
    }

    return false;
  }
  public get isBranchPic() {
    if (this.authUser.role.alias === SystemRoleAlias.BranchManager) {
      return true;
    }

    return false;
  }
  public get isDepartmentPic() {
    if (this.authUser.role.alias === SystemRoleAlias.GroupLeader) {
      return true;
    }

    return false;
  }
  public get dataFilter() {
    const _branch: IFilterInput[] = this.branch.reduce((res, item) => {
      res.push({
        key: item.id,
        value: item.id,
        name: item.name
      });

      return res;
    }, []);

    return [
      {
        key: FilterKey.Branch,
        name: 'Branch',
        value: _branch
      },
    ];
  }
  public get selectedFilter() {
    const _filterParams = cloneDeep(this.filterParams);

    return [
      {
        key: FilterKey.Branch,
        value: _filterParams[FilterKey.Branch]
      }
    ];
  }
  public get columns() {
    return [
      {
        prop: 'name',
        label: 'Dealer Name',
        labelItem: 'Code',
        formatter: (model: any) => {
          return fullNameUser(model) || '--';
        },
        avatar: {
          formatter: (model: any) => {
            return model ? model : null;
          }
        },
        width: 250,
        fixed: true
      },
      {
        prop: 'shortName',
        label: 'Short Name',
        width: 180,
        formatter: (model: any) => {
          return model.shortName || '--';
        }
      },
      {
        prop: 'proshopType',
        label: 'Type',
        width: 180,
        formatter: (model: any) => {
          return model.proshopType ? model.proshopType.name : '--';
        }
      },
      {
        prop: 'address',
        label: 'Address',
        width: 220,
        formatter: (model: any) => {
          if (model.address) {
            return this.displayAddress(model.address) || '--';
          }

          return '--';
        }
      },
      {
        prop: 'branch',
        label: 'Branch',
        width: 180,
        formatter: (model: any) => {
          if (model.branch && model.branch.name) {
            return model.branch.name;
          }

          return '--';
        }
      },
      {
        prop: 'email',
        label: 'Owner Email',
        width: 200,
        formatter: (model: any) => {
          return model.email || '--';
        }
      },
      {
        prop: 'totalShop',
        label: 'Total Shop',
        width: 150,
        formatter: (model: any) => {
          return model.shops_aggregate.aggregate.count;
        }
      },
      {
        prop: 'joinedShop',
        label: 'Joined Shop',
        width: 150,
        formatter: (model: any) => {
          return model.acl_campaigns_aggregate.aggregate.count;
        }
      },
      {
        prop: 'action',
        label: '',
        align: 'center',
        fixed: 'right',
        width: 60
      }
    ];
  }

  public searchText: string = '';
  public authUser: IUser;
  public pagination: IPaginationParams;
  public filterParams = {
    [FilterKey.Branch]: []
  };
  public branch: any[];
  public displayAddress(model: IAddress) {
    return model && model.province ? this.$store.getters['getAddressString'](model) : '--';
  }

  public getAction() {

    return 'view';
  }
  public handleActions(account, action) {
    console.log(action);
    this.$router.push({
      name: CustomCampaignRouterName.CampaignDetail,
      params: {
        id : account.id
      }
    });
  }
  public changeTab(tab: TabPane) {
    switch (tab.name) {
    case CustomCampaignTab.Statistic:
      this.$router.push({
        name: CustomCampaignRouterName.Statistic
      });
      break;
    case CustomCampaignTab.Shops:
      this.$router.push({
        name: CustomCampaignRouterName.Shops
      });
      break;
    case CustomCampaignTab.QualifiedShops:
      this.$router.push({
        name: CustomCampaignRouterName.QualifiedShops
      });
      break;
    case CustomCampaignTab.Configuration:
      this.$router.push({
        name: CustomCampaignRouterName.Configuration
      });
      break;
    case CustomCampaignTab.ManageExport:
      this.$router.push({
        name: CustomCampaignRouterName.ManageExport
      });
      break;
    default:
      break;
    }
  }

  public handleSearch() {
    this.fetchData();
  }

  public handlePaginationChange(value) {
    if (typeof value === 'number') {
      this.$store.commit(MutationTypeAclCampaign.PaginationChange, {
        ...this.pagination,
        size: value
      });
    } else {
      this.$store.commit(MutationTypeAclCampaign.PaginationChange, {
        ...this.pagination,
        ...value
      });
    }
    this.fetchData();
  }

  public editSuccess() {
    this.fetchData();
  }
  public changeFilter(filter) {
    const nextFilter = {
      [FilterKey.Branch]: filter ? filter : [],
    };
    this.filterParams = {
      ...cloneDeep(this.filterParams),
      ...nextFilter
    };
    this.fetchData();
  }

  protected mounted() {
    this.$nextTick(() => {
      const breadcrumb: IBreadcrumb[] = [
        {
          label: 'title.campaign',
          path: null,
          current: false
        },
        {
          label: 'title.acl_campaign',
          path: null,
          current: true
        }
      ];
      this.$store.commit(MutationType.SetBreadcrumb, breadcrumb);
      this.$store.dispatch(ActionTypeBranchManage.GetListBranch, {
        form: {
          filter: {},
          pagination: {}
        }
      });
      this.fetchData();
    });
  }
  protected beforeDestroy() {
    this.$store.commit(MutationType.ClearBreadcrumb);
  }

  private fetchData() {
    const text = cloneDeep(this.searchText);
    const _filterParams = <any> {};
    if (this.filterParams[FilterKey.Branch].length) {
      _filterParams.branch = this.filterParams[FilterKey.Branch];
    }

    this.$store.dispatch(ActionTypeAclCampaign.GetAclCampaign, {
      search: `%${text}%`,
      branch: _filterParams.branch,
      pagination: this.pagination
    });
  }
}
