import { crudMutations, CRUDState, ICRUDState } from 'root/store/helpers';
import { MutationTypeGroupManager } from './types';

export interface IGroupManageState extends ICRUDState<any> {
  loading: boolean;
  detail: any;
  leaders: any;
  team: any;
}

export const defaultState: IGroupManageState = {
  ...CRUDState(),
  loading: false,
  detail: {},
  leaders: [],
  team: []
};

export const mutations = {
  ...crudMutations('group_manager'),
  [MutationTypeGroupManager.SetGroupManager](state: IGroupManageState, data) {
    state.detail = data.data;
  },
  [MutationTypeGroupManager.SetListGroup](state: IGroupManageState, data) {
    state.data = data.data;
    state.pagination = { ...state.pagination, ...data.pagination};
  },
  [MutationTypeGroupManager.SetGroupTeamStaff](state: IGroupManageState, data) {
    console.log(data);
    state.team = data;
    state.pagination = { ...state.pagination, ...data.pagination};
  },
  [MutationTypeGroupManager.SetGroupLeader](state: IGroupManageState, data) {
    state.leaders = data.data;
    state.pagination = {
      ...state.pagination,
      ...data.pagination,
      limit: data.pagination.size
    };
  },
  [MutationTypeGroupManager.SetListGroupFilter](state: IGroupManageState, data) {
    state.data = data.data.groups;
  },
};
