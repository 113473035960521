import { crudMutations, CRUDState, ICRUDState } from 'root/store/helpers';
import { MutationTypeInvoice } from './types';

export interface IInvoiceState extends ICRUDState<any> {
  loading: boolean;
}

export const defaultState: IInvoiceState = {
  ...CRUDState(),
  loading: false,
};

export const mutations = {
  ...crudMutations('invoice'),
  [MutationTypeInvoice.GetListInvoice](state: IInvoiceState, data: any) {
    state.data = data.data;
    state.pagination = {
      ...state.pagination,
      ...data.pagination,
      limit: data.pagination.size
    };
  }
};
