import './styles.scss';

import { stripObject } from '@hgiasac/helper';
import { cloneDeep, find } from 'lodash';
import moment from 'moment';
import { IPaginationParams } from 'root/api/graphql/Core';
import { fullNameUser, FormatDateFullTime } from 'root/helpers';
import { IFilterInput, RewardType, StatusCode } from 'root/models';
import { IUser } from 'root/models/User';
import { IState, MutationType, TypeAlert } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import { RedemptionRouterName } from '..';
import { RedemptionDetailModal } from '../Components/RedemptionDetail';
import { RedemptionEditor } from '../Components/RedemptionEditor';
import { VoucherCodeList } from '../Components/VoucherCodeList';
import { ActionTypeRedemption, MutationTypeRedemption } from '../Store/types';

const enum FilterKey {
  Status = 'status'
}

@Component({
  template: require('./view.html'),
  components: {
    editor: RedemptionEditor,
    'redemption-detail-modal': RedemptionDetailModal,
    'voucher-code-list': VoucherCodeList
  },
  computed: {
    ...mapState({
      // authUser: (state: IState) => state.global.authUser,
      data: (state: IState) => state.redemption.data,
      pagination: (state: IState) => state.redemption.pagination,
      loading: (state: IState) => state.redemption.loading,
      isRefesh: (state: IState) => state.global.isRefesh
    }),
    selectedStatus() {
      const filterParams = [];

      if (!filterParams) {
        return [];
      }

      return [
        {
          key: 'isCreatedByAppleReviewer',
          value: []
        }
      ];
    }
  }
})
export class RedemptionList extends Vue {
  public get columns() {
    return [
      {
        label: 'Name',
        formatter: (model: any) => {
          return model.titleEng || '--';
        },
        width: 250
      },
      {
        prop: 'description',
        label: 'Description',
        width: 250,
        formatter: (model: any) => {
          return model.description || '--';
        }
      },
      {
        prop: 'rewardType',
        label: 'Reward Type',
        width: 180,
      },
      {
        prop: 'redeemType',
        label: 'Redeem Type',
        width: 180,
        formatter: (model: any) => {
          return model.redeemType && model.redeemType.charAt(0).toUpperCase() + model.redeemType.slice(1) || '--';
        }
      },
      {
        prop: 'quantity',
        label: 'USED/QTY',
        width: 180,
        formatter: (model: any) => {
          return (model.usedQuantity || model.usedQuantity === 0)
          && model.maxQuantity ? `${model.usedQuantity}/${model.maxQuantity}` : '--';
        }
      },
      // {
      //   prop: 'code',
      //   label: 'Code',
      //   width: 180,
      //   formatter: (model: any) => {
      //     if (model.rewardType && model.rewardType === RewardType.Static) {

      //       return model.voucherCodes && model.voucherCodes.length ? model.voucherCodes[0].code : '--';
      //     }
      //     if (model.rewardType && model.rewardType === RewardType.Dynamic) {

      //       return model.voucherCodes_aggregate ? model.voucherCodes_aggregate.aggregate.count : '--';
      //     }

      //     return '--';
      //   }
      // },
      {
        prop: 'point',
        label: 'Redeem Point',
        width: 180,
        formatter: (model: any) => {
          return model.point || '--';
        }
      },
      {
        prop: 'duration',
        label: 'Duration',
        width: 160,
        formatter: (model: any) => {
          if (model.startDate && model.endDate) {
            return `${FormatDateFullTime(model.startDate)}  ${FormatDateFullTime(
              model.endDate
            )}`;
          }

          return '--';
        }
      },
      {
        prop: 'status',
        label: this.$t('status'),
        formatter: (model: any) => {
          return this.$t(model.rewardStatus);
        },
        labelStyle: (model: any) => {
          return model.rewardStatus;
        },
        align: 'center',
        width: 180
      },
      {
        prop: 'action',
        label: '',
        align: 'center',
        fixed: 'right',
        width: 60
      }
    ];
  }
  public get actions() {
    return ['view', 'edit', 'block'];
  }

  public authUser: IUser;
  public FormatDateFullTime = FormatDateFullTime;
  public pagination: IPaginationParams;
  public data: any[];
  public status: any[];
  public visible: boolean = false;
  public isSample: boolean = false;
  public userId: string = '';
  public visibleDetail: boolean = false;
  public popupVisible: boolean = false;
  public filterParams = {
    [FilterKey.Status]: []
  };
  public visibleUpgrade: boolean = false;
  public searchText: string = '';
  public filterTypeDealer: any[];
  public isRefesh: boolean;
  public UserType = {
    Customer: 'customer',
    System: 'system'
  };
  public type: any;
  public visibleCodeList: boolean = false;
  public detailIdCodeList: string = '';

  public get inputSearchPlaceholder(): string {
    return `Search by name`;
  }
  public renderRewardCode(model: any) {
    if (model.rewardType && model.rewardType === RewardType.Static) {

      return model.voucherCodes && model.voucherCodes.length ? model.voucherCodes.length : '--';
    }
    if (model.rewardType && model.rewardType === RewardType.Dynamic) {

      return model.voucherCodes_aggregate ? model.voucherCodes_aggregate.aggregate.count : '--';
    }

    return '--';
  }
  public renderType(model: any) {
    if (model.rewardType && model.rewardType === RewardType.Static) {

      return model.voucherCodes && model.voucherCodes.length ?
       model.voucherCodes[0].hashedCode : '--';
    }

    return '--';
  }
  public openCodeList(model: any) {
    if (model && model.rewardType === RewardType.Static) {
      return;
    }
    this.visibleCodeList = true;
    this.detailIdCodeList = model.id;
  }
  public closeCodeList() {
    this.visibleCodeList = false;
    this.detailIdCodeList = '';
  }
  public getActions(status: string, endDate: string) {
    const now = moment();
    if (moment(endDate) < now) {
      return ['view'];
    }

    if (status === StatusCode.Available) {

      return ['view', 'edit', 'block'];
    }
    if (status === StatusCode.Blocked) {
      return ['view', 'edit', 'block'];
    }
    if (status === StatusCode.Schedule) {

      return ['view', 'edit', 'block'];
    }

    return ['view'];
  }

  public handleActions({ id, action }) {
    if (action === 'edit') {
      this.handleUpdate(id);

      return;
    }

    if (action === 'view') {
      this.clickDetail(id);

      return;
    }

    if (action === 'block' || action === 'unblock') {
      this.disabledConfirm(id);
    }

    return;
  }

  public closeDialogDetail() {
    this.userId = '';
    this.visibleDetail = false;
  }
  public disabledConfirm(id: string) {
    this.$msgbox({
      title: this.$t('confirm').toString(),
      message: this.$t('action_confirm_message').toString(),
      showCancelButton: true,
      confirmButtonText: this.$t('confirm').toString(),
      cancelButtonText: this.$t('cancel').toString(),
      beforeClose: (action, instance, done) => {
        if (action === 'confirm') {
          instance.confirmButtonLoading = true;
          instance.confirmButtonText = 'Loading...';
          this.handleDisabled({ id, instance, done });
        } else {
          done();
        }

        return;
      }
    }).catch(() => {
      // no handle
    });
  }
  public upgradeRequest(id: string) {
    this.visibleUpgrade = true;
    this.userId = id;
  }

  public createSuccess() {
    this.visible = false;
    this.fetchData();
  }

  public applyFilter() {
    this.fetchData();
  }
  public goAddNewRouter() {
    this.visible = true;
    this.userId = '';
  }
  public closeDialog() {
    this.isSample = false;
    this.fetchData();
  }

  public handleSearch() {
    this.$store.commit(MutationTypeRedemption.PaginationChange, {
      ...this.pagination,
      page: 1
    });
    this.fetchData();
  }

  public handleDisabled(model: any) {
    const user = find(
        cloneDeep(this.data),
        (e) => e.id.toString() === model.id.toString()
      ),
      isBlocked = user.rewardStatus === StatusCode.Blocked;
    this.$store.dispatch(ActionTypeRedemption.UpdateStatusRedemption, {
      id: model.id,
      set: {
        rewardStatus: isBlocked ? StatusCode.Available : StatusCode.Blocked
      },
      onSuccess: () => {
        this.fetchData();
        model.instance.confirmButtonLoading = false;
        model.instance.confirmButtonText = 'Confirm';
        const message = 'Block Redemption successfully';
        this.$store.commit(MutationType.OpenTopAlert, {
          message,
          type: TypeAlert.Success
        });
        model.done();
      },
      onFailure: () => {
        model.instance.confirmButtonLoading = false;
        model.instance.confirmButtonText = 'Confirm';
      }
    });
  }
  public changePassSuccess() {
    this.popupVisible = false;
    this.fetchData();
  }
  public get dataFilter() {
    const _statuses: IFilterInput[] = [
      {
        key: StatusCode.Available,
        value: StatusCode.Available,
        name: this.$t(StatusCode.Available)
      },
      {
        key: StatusCode.Blocked,
        value: StatusCode.Blocked,
        name: this.$t(StatusCode.Blocked)
      },
      {
        key: StatusCode.Schedule,
        value: StatusCode.Schedule,
        name: this.$t(StatusCode.Schedule)
      },
      {
        key: StatusCode.Expired,
        value: StatusCode.Expired,
        name: this.$t(StatusCode.Expired)
      }
    ];

    return [
      {
        key: FilterKey.Status,
        name: this.$t('field.status'),
        value: _statuses
      }
    ];
  }
  public get selectedFilter() {
    const filterParams = cloneDeep(this.filterParams);

    return [
      {
        key: FilterKey.Status,
        value: filterParams[FilterKey.Status]
      }
    ];
  }

  public clickDetail(id: string) {
    this.$router.push({
      name: RedemptionRouterName.RedeemtionDetail,
      params: {
        id
      }});
  }
  public handleUpdate(id: string) {
    this.visible = true;
    this.userId = id;
  }

  public handlePaginationChange(value) {
    if (typeof value === 'number') {
      this.$store.commit(MutationTypeRedemption.PaginationChange, {
        ...this.pagination,
        size: value
      });
    } else {
      this.$store.commit(MutationTypeRedemption.PaginationChange, {
        ...this.pagination,
        ...value
      });
    }
    this.fetchData();
  }

  public changeFilter(model) {
    const status = model.find((e) => e.key === FilterKey.Status);
    const nextFilter = {
      [FilterKey.Status]: status ? status.value : []
    };
    this.filterParams = {
      ...cloneDeep(this.filterParams),
      ...nextFilter
    };
    this.fetchData();
  }

  public formatFullNameUser(model) {
    return fullNameUser(model);
  }

  protected mounted() {
    this.$nextTick(() => {
      this.$store.commit(MutationType.ClearBack);
      this.$store.commit(MutationType.SetBreadcrumb, this.$t('title.redeem'));
      // this.$store.commit(MutationTypeUser.PaginationReset);
      !this.isRefesh && this.fetchData();
    });
  }
  protected beforeDestroy() {
    this.$store.commit(MutationType.ClearBreadcrumb);
  }

  private fetchData() {
    const text = cloneDeep(this.searchText);
    this.$store.commit(
      MutationTypeRedemption.FilterChange,
      stripObject({
        rewardStatus: this.filterParams[FilterKey.Status],
        _and:
          text.trim() !== ''
            ? {
              mode: 'special',
              data: {
                _or: [
                  {
                    titleEng: {
                      _ilike: `%${text}%`
                    }
                  },
                ]
              }
            }
            : null
      })
    );
    this.$store.commit(MutationTypeRedemption.ChangeOrderBy, {
      createdAt: 'desc'
    });
    this.$store.dispatch(ActionTypeRedemption.FilterNoCache);
  }
}
