import { stripObject } from '@hgiasac/helper';
import { Form } from 'element-ui';
import { cloneDeep, debounce, pick } from 'lodash';
import { ruleRequired } from 'root/helpers';
import { IUser } from 'root/models';
import { IState, MutationType, TypeAlert } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import { ActionTypeSaleInCampaign, ActionTypeSaleInProduct } from '../../../Store/types';

@Component({
  template: require('./view.html'),
  props: {
    visible: Boolean,
    detailId: String
  },
  computed: {
    ...mapState({
      authUser: (state: IState) => state.global.authUser,
    }),
  }
})

export class ApplicationProductForm extends Vue {
  public $refs: {
    form: Form;
  };
  public rules = {
    required: ruleRequired(),
  };
  public authUser: IUser;
  public SBUOptions: any[] = [];
  public form: any = {};
  public isErrorModelNumber: boolean = false;
  public loading: boolean = false;
  public openDialog() {
    this.form = {};
    // tslint:disable-next-line:early-exit
    if (this.$props.detailId) {
      const detail = this.$store.getters.getSaleInProductDetail(this.$props.detailId);
      this.form = {
        ...cloneDeep(detail),
        deviceTypeId: detail.deviceType.id
      };
    }
  }
  public closeDialog() {
    this.$emit('closeDialog');
    this.isErrorModelNumber = false;
    this.$refs.form.clearValidate();
  }
  public onChangeModelNumber() {
    this.isErrorModelNumber = false;
    this.form = {
      ...this.form,
      displayModel: this.form.modelNumber
    };
    this.$store.dispatch(ActionTypeSaleInProduct.CheckModelNumberExits, {
      model: this.form.modelNumber,
      onSuccess: (result) => {
        if (result) {
          return this.isErrorModelNumber = true;
        }

        return this.isErrorModelNumber = false;
      }
    });
  }
  public onChangeDeviceType(value) {
    const deviceType = this.SBUOptions.find((it) => it.value === value);
    this.form = {
      ...this.form,
      deviceName: deviceType && deviceType.deviceName ? deviceType.deviceName : ''
    };
  }
  public submit() {
    this.$refs.form.validate(async (valid) => {
      // tslint:disable-next-line:early-exit
      if (valid) {
        this.loading = true;
        const form = <any> cloneDeep(this.form);
        if (this.$props.detailId) {
          this.$store.dispatch(ActionTypeSaleInProduct.Upsert, {
            // id: this.$props.detailId,
            form: {
              ...stripObject(pick(form, ['modelNumber', 'displayModel', 'deviceTypeId', 'deviceName'])),
              modelId: this.$props.detailId,
              actionBy: this.authUser.id,
            },
            onSuccess: () => {
              this.loading = false;
              const message = 'Updated successfully';
              this.$store.commit(MutationType.OpenTopAlert, {
                message,
                type: TypeAlert.Success
              });
              this.$emit('createSuccess');
            }
          });

          return;
        }
        this.$store.dispatch(ActionTypeSaleInProduct.Upsert, {
          form: {
            ...stripObject(pick(form, ['modelNumber', 'displayModel', 'deviceTypeId', 'deviceName'])),
            modelId: null,
            actionBy: this.authUser.id,
          },
          onSuccess: () => {
            this.loading = false;
            const message = 'Created successfully';
            this.$store.commit(MutationType.OpenTopAlert, {
              message,
              type: TypeAlert.Success
            });
            this.$emit('createSuccess');
          }
        });
      }
    });
  }
  public async mounted() {
    this.$store.dispatch(ActionTypeSaleInCampaign.GetConditionMetadata, {
      onSuccess: (data) => {
        this.SBUOptions = data.si_campaign_data.map((it) => ({
          label: it.value,
          value: it.id,
          deviceName: it.name
        }));
      }
    });
    // console.log(this.$props.campaignType);
  }
  protected created() {
    this.onChangeModelNumber = debounce(this.onChangeModelNumber, 300);
  }
}
