import { DocumentNode } from 'graphql';
import gql from 'graphql-tag';
import { crudQuery, ICRUDQuery } from '../Core/query';

const aclCampaignModel = `
  id
  address
  name
  dealerCode
  shortName
  proshopType {
    name
    alias
  }
  branch {
    id
    name
    branchCode
  }
  email
  shops_aggregate(where: {isOwner: {_eq: true}}) {
    aggregate {
      count
    }
  }
  acl_campaigns_aggregate(where: {campaign: {campaignType: {_eq: "ACL_CAMPAIGN"}}}) {
    aggregate {
      count
    }
  }
`;
const aclCampaignDetailModel = `
  id
  createdAt
  branch {
    id
    name
  }
  email
  address
  name
  role {
    name
  }
  proshopType {
    name
    alias
  }
  acl_campaigns_aggregate(where: {campaign: {campaignType: {_eq: "ACL_CAMPAIGN"}}}) {
    aggregate {
      count
    }
  }
  status
  updatedAt
  dealerCode
  shortName
  updatedBy
  createdBy
  createdUser {
    id
    name
  }
  updatedUser {
    id
    name
  }
`;

export const aclCampaign = 'acl_campaigns';
// tslint:disable-next-line:no-empty-interface
interface IAclCampaignQuery extends ICRUDQuery {
  GET_LIST_ACL_CAMPAGIN: DocumentNode;
  GET_LIST_MANAGE_EXPORT: DocumentNode;
  GET_LIST_SHOP_CAMPAGIN: DocumentNode;
  GET_LIST_SHOP_CAMPAGIN_NOTJOIN: DocumentNode;
  APRROVE_SHOP: DocumentNode;
  REQUEST_REUP: DocumentNode;
  GET_SHOP: DocumentNode;
  GET_PDF_EXPORT_DATA: DocumentNode;
  SUBMIT_REVIEW: DocumentNode;
  GET_PACKAGE: DocumentNode;
  UPDATE_PACKAGE: DocumentNode;
  GET_CONFIG: DocumentNode;
  GET_MASK_CONFIG: DocumentNode;
  END_CAMPAIGN: DocumentNode;
  MASK_CHANGE: DocumentNode;
  GET_STATISTIC: DocumentNode;
  EXPORT_STATISTIC: DocumentNode;
  GET_QUALIFIED_SHOP: DocumentNode;
  APPROVE_ALL: DocumentNode;
  GET_ACTION_HISTORY: DocumentNode;
  GET_DEALER_BY_BRANCH: DocumentNode;
  GET_LIST_JOINED_SHOP: DocumentNode;
  GET_LIST_JOINED_SHOP_NOT_REVIEW: DocumentNode;
  GET_CONFIG_CROP_PICTURE: DocumentNode;
  GET_LIST_SHOPS: DocumentNode;
  GET_LIST_SHOPS_NOT_REVIEW: DocumentNode;
  GET_LIST_SHOPS_NOT_JOIN: DocumentNode;
  GET_LIST_SHOPS_NOT_JOIN_NOT_REIVEW: DocumentNode;
}
export const aclCampaignQuery: IAclCampaignQuery = {
  ...crudQuery(aclCampaign, aclCampaignModel),
  GET_LIST_ACL_CAMPAGIN: gql`
  query GET_LIST_ACL_CAMPAGIN($limit:Int, $branch: [uuid!] , $offset: Int, $search: String) {
    dealers(where: {role: {alias: {_eq: "daikin_dealer"}}, _and: [{branch: {id: {_in: $branch}}},
      {_or: [{name: {_ilike: $search}}, {shortName: {_ilike: $search}}, {dealerCode: {_ilike: $search}}]}]},
    offset: $offset, limit: $limit) {
      ${aclCampaignModel}
    }
    dealers_aggregate (where: {role: {alias: {_eq: "daikin_dealer"}}, _and: [{branch: {id: {_in: $branch}}},
      {_or: [{name: {_ilike: $search}}, {shortName: {_ilike: $search}}, {dealerCode: {_ilike: $search}}]}]}) {
        aggregate {
          count
        }
    }
  }
  `,
  GET_LIST_SHOP_CAMPAGIN: gql`
  query GET_LIST_SHOP_CAMPAGIN($id: uuid!, $status:[String!], $limit:Int, $offset: Int, $search: String) {
    dealers(where: {role: {alias: {_eq: "daikin_dealer"}}, id: {_eq: $id}}) {
      ${aclCampaignDetailModel}
      shops(where: {isOwner: { _eq: true },_and: [{_or: [
        { shop: { acl_campaigns: {campaign: {campaignType: {_eq: "ACL_CAMPAIGN"}}, requestStatus: { _in: $status } } } }
        { shop: { _not: { acl_campaigns: {campaign: {campaignType: {_nin: ["RA_CAMPAIGN"]}}}}}}]}
        {_or: [
        { shop: { name: { _ilike: $search } } }
        { shop: { phoneNumber: { _ilike: $search } } }
        { shop: { email: { _ilike: $search } } }
        ]}]},offset: $offset, limit: $limit) {
        shop {
          id
          name
          email
          address
          phoneNumber
          acl_campaigns(where: {campaign: {campaignType: {_eq: "ACL_CAMPAIGN"}}}) {
            id
            reason
            requestStatus
            updatedAt
            classification
            reviews {
              description
              updatedAt
            }
          }
          dealerShops {
            isOwner
            contractStatus
          }
        }
      }
      shops_aggregate(where: {
        isOwner: { _eq: true }
        _and: [
          {_or: [
        { shop: { acl_campaigns: {campaign: {campaignType: {_eq: "ACL_CAMPAIGN"}}, requestStatus: { _in: $status } } } }
        { shop: { _not: { acl_campaigns: {campaign: {campaignType: {_nin: ["RA_CAMPAIGN"]}}} } } }
        ]}
        {
        _or: [{ shop: { name: { _ilike: $search } } }{ shop: { phoneNumber: { _ilike: $search } } }
          { shop: { email: { _ilike: $search }}}]}]}) {
        aggregate {
          count
        }
      }
    }
  }
  `,
  GET_LIST_SHOP_CAMPAGIN_NOTJOIN: gql`
  query GET_LIST_SHOP_CAMPAGIN($id: uuid!, $status:[String!], $limit:Int, $offset: Int, $search: String) {
    dealers(where: {role: {alias: {_eq: "daikin_dealer"}}, id: {_eq: $id}}) {
      ${aclCampaignDetailModel}
      shops(where: {isOwner: {_eq: true}, _and: [{shop: {acl_campaigns: {campaign: {campaignType: {_eq: "ACL_CAMPAIGN"}}
      ,requestStatus: {_in: $status}}}},
        {_or: [{shop: {name: {_ilike: $search}}}, {shop: {phoneNumber: {_ilike: $search}}},
          {shop: {email: {_ilike: $search}}}]}]}, offset: $offset, limit: $limit) {
        shop {
          id
          name
          email
          address
          phoneNumber
          acl_campaigns(where: {campaign: {campaignType: {_eq: "ACL_CAMPAIGN"}}}) {
            id
            reason
            requestStatus
            updatedAt
            classification
            reviews {
              description
              updatedAt
            }
          }
          dealerShops {
            isOwner
            contractStatus
          }
        }
      }
      shops_aggregate(where:{isOwner:{_eq: true}, _and:[{shop:{acl_campaigns:{
      campaign: {campaignType:{_eq: "ACL_CAMPAIGN"}}, requestStatus: {_in: $status}}}},
        {_or: [{shop: {name: {_ilike: $search}}}, {shop: {phoneNumber: {_ilike: $search}}},
          {shop: {email: {_ilike: $search}}}]}]}) {
        aggregate {
          count
        }
      }
    }
  }
  `,
  GET_LIST_MANAGE_EXPORT: gql`
  query GetManageExport($type: jsonb, $limit: Int!, $offset: Int!) {
    export_acl_campaign_reports (where:{filter:{_contains: $type}},
      limit: $limit, offset: $offset, order_by: {createdAt: desc}) {
      createdAt
      createdBy
      exportStatus
      failedReasons
      id
      isReindex
      status
      url
      createdUser {
        name
      }
    }
    export_acl_campaign_reports_aggregate(where:{filter:{_contains: $type}}) {
      aggregate {
        count
      }
    }
  }
  `,
  APRROVE_SHOP: gql`
  mutation APRROVE_SHOP($ids: [uuid!], $action: String!, $reason: String, $updatedBy: uuid!){
    update_acl_campaigns(where: {id: {_in: $ids}},
      _set: {requestStatus: $action, reason: $reason, updatedBy: $updatedBy}) {
      affected_rows
    }
  }`,
  REQUEST_REUP: gql`
  mutation REQUEST_REUP($ids: [uuid!], $action: String!, $reason: String, $updatedBy: uuid!){
    update_acl_campaigns(where: {id: {_in: $ids}},
      _set: {requestStatus: $action, reason: $reason, updatedBy: $updatedBy,
        isBranchReview: true, isDealerReUp: false}) {
      affected_rows
    }
  }`,
  GET_SHOP: gql`
  query GET_SHOP_CAMPAIGN_DETAIL($id: uuid!) {
    shops(where: {id: {_eq: $id}}) {
      address
      email
      name
      dealerShops {
        isOwner
        contractStatus
      }
      acl_campaigns(where: {campaign: {campaignType: {_eq: "ACL_CAMPAIGN"}}}) {
        id
        packages
        pictureURLs
        requestStatus
        classification
        branchRequestReUpCount
        isBranchReview
        isDealerReUp
        reviews {
          createdAt
          createdUser {
            name
          }
          classification
          updatedAt
          updatedUser {
            name
          }
          description
        }
        updatedAt
        createdAt
        logs(order_by: {createdAt: desc}) {
          requestStatus
          submittedAt
          updatedAt
          createdUser{
            name
          }
          reason
          dealer {
            name
          }
        }
      }
    }
  }
  `,
  GET_PDF_EXPORT_DATA: gql`
  query getDetailCampaignById($id: uuid!) {
    acl_campaigns(where: {id: {_eq: $id}, campaign: {campaignType: {_eq: "ACL_CAMPAIGN"}}}) {
    campaign {
    campaignCode
    aclCampaignConfig {
      reviewEndDate
      reviewStartDate
      uploadStartDate
      uploadEndDate
    }
    target
    title
    condition {
      description
      name
    }
    dealerRoles
    metadata
    }
    dealer {
      name
      publicCode
      dealerCode
      taxNumber
      address
      ownerName
      ownerPosition
      shortName
    }
    shop {
      name
      shopCode
      address
    }
    packages
    pictureURLs
    resizedPictureURLs
    reason
    requestStatus
    reviews(order_by: {createdAt: desc}, limit: 1) {
      createdUser {
        name
      }
    }
    updatedUser {
        name
        }
      }
    }
  `,
  SUBMIT_REVIEW: gql`
  mutation SUBMIT_REVIEW($id: uuid, $review: String, $createdBy: uuid, $qualify: String!){
    insert_acl_campaign_reviews(objects: {campaignId: $id, description: $review,
      classification: $qualify, createdBy: $createdBy, updatedBy: $createdBy}) {
      affected_rows
    }
    update_acl_campaigns(where: {id: {_eq: $id}}, _set: {updatedBy: $createdBy, classification: $qualify}) {
      affected_rows
      __typename
    }
  }`,
  GET_PACKAGE: gql`
  query GET_PACKAGE {
    campaigns(where: {campaignType : {_eq: "ACL_CAMPAIGN"}}) {
      metadata
      startDate
      endDate
      isEnded
    }
  }`,
  UPDATE_PACKAGE: gql`
  mutation UPDATE_PACKAGE($id: String!, $models: [Int]){
    updateACLPackages(form: {id: $id, packages: $models})
  }`,
  GET_CONFIG: gql`
  query GET_CONFIG {
    campaigns(where:{campaignType:{_eq:"ACL_CAMPAIGN"}}){
      aclCampaignConfig {
        id
        uploadStartDate
        uploadEndDate
      }
    }
  }`,
  GET_MASK_CONFIG: gql`
  query GET_MASK_CONFIG($id: uuid!) {
    campaigns(where: {aclConfig: {_eq: $id}}) {
      isSpecial
      isEnded
      startDate
      endDate
    }
  }`,
  END_CAMPAIGN: gql`
  mutation END_CAMPAIGN($id: uuid!){
    update_campaigns(where: {aclConfig: {_eq: $id}}, _set: {isEnded : true}) {
      affected_rows
    }
  }`,
  MASK_CHANGE: gql`
  mutation MASK_CHANGE($id: uuid!, $mask: Boolean!){
    update_campaigns(where: {aclConfig: {_eq: $id}}, _set: {isSpecial : $mask}) {
      affected_rows
    }
  }`,
  GET_STATISTIC: gql`
  query GET_STATISTIC {
    joinedDealers: acl_campaigns_aggregate(where: {campaign: {campaignType: {_eq: "ACL_CAMPAIGN"}}}) {
      aggregate {
        count(columns: dealerId, distinct: true)
      }
    }
    totalDealers: dealers_aggregate(where: {role: {alias: {_eq: "daikin_dealer"}}, status: {_eq: "active"}}) {
      aggregate {
        count(columns: id, distinct: true)
      }
    }
    joinedShops: acl_campaigns_aggregate(where: {campaign: {campaignType: {_eq: "ACL_CAMPAIGN"}}}) {
      aggregate {
        count(columns: shopId, distinct: true)
      }
    }
    totalShops: shops_aggregate(where:{dealerShops:{isOwner:{_eq: true}}}) {
      aggregate {
        count(columns: id, distinct: true)
      }
    }
    totalCompleted: acl_campaigns_aggregate(where: {campaign: {campaignType: {_eq: "ACL_CAMPAIGN"}},
    requestStatus: {_in: ["approved","rejected"]}}) {
      aggregate {
        count(columns: shopId, distinct: true)
      }
    }
    totalIncompleted: acl_campaigns_aggregate(where: {campaign: {campaignType: {_eq: "ACL_CAMPAIGN"}},
    requestStatus: {_nin: ["approved","rejected"]}}) {
      aggregate {
        count(columns: shopId, distinct: true)
      }
    }
    totalRejected: acl_campaigns_aggregate(where: {campaign: {campaignType: {_eq: "ACL_CAMPAIGN"}},
    requestStatus: {_eq: "rejected"}}) {
      aggregate {
        count(columns: shopId, distinct: true)
      }
    }
    requests: acl_campaign_status_logs(where: {aclCampaign : {campaign: {campaignType: {_eq: "ACL_CAMPAIGN"}}}}) {
      requestStatus
      createdAt
      updatedAt
      campaignId
    }
    config: acl_campaign_configurations {
      uploadStartDate
      uploadEndDate
    }
    configCampaign: campaigns(where:{campaignType:{_eq:"ACL_CAMPAIGN"}}){
      aclCampaignConfig {
        id
        uploadStartDate
        uploadEndDate
      }
    }
    model1: acl_campaigns_aggregate(where: {campaign: {campaignType: {_eq: "ACL_CAMPAIGN"}},
    packages: {_contains: [1]}}) {
      aggregate {
        count
      }
    }
    model2: acl_campaigns_aggregate(where: {campaign: {campaignType: {_eq: "ACL_CAMPAIGN"}},
    packages: {_contains: [2]}}) {
      aggregate {
        count
      }
    }
    model3: acl_campaigns_aggregate(where: {campaign: {campaignType: {_eq: "ACL_CAMPAIGN"}},
    packages: {_contains: [3]}}) {
      aggregate {
        count
      }
    }
    model4: acl_campaigns_aggregate(where: {campaign: {campaignType: {_eq: "ACL_CAMPAIGN"}},
    packages: {_contains: [4]}}) {
      aggregate {
        count
      }
    }
    labelBranch: branches(where: {status: {_eq: "active"}}) {
      id
      name
    }
  }
  `,
  EXPORT_STATISTIC: gql`
  mutation EXPORT_STATISTIC($id: uuid!, $filter: jsonb) {
    insert_export_acl_campaign_reports(objects: { createdBy: $id, filter: $filter}) {
      affected_rows
    }
  }`,
  GET_QUALIFIED_SHOP: gql`
  query GET_QUALIFIED_SHOP($limit:Int, $offset: Int) {
    acl_campaigns(where: {campaign: {campaignType: {_eq: "ACL_CAMPAIGN"}}, classification: {_eq: "qualified"},
    requestStatus: {_in: ["waiting_for_review","request_re_up"]}}, limit: $limit, offset: $offset) {
      id
      dealer {
        id
        name
      }
      reviews {
        id
        createdAt
        updatedAt
        description
      }
      shop {
        id
        name
        address
        dealerShops{
          isOwner
          contractStatus
        }
      }
      requestStatus
    }
    acl_campaigns_aggregate(where: {campaign: {campaignType: {_eq: "ACL_CAMPAIGN"}}, classification: {_eq: "qualified"},
    requestStatus: {_in: ["waiting_for_review","request_re_up"]}}) {
      aggregate {
        count
      }
    }
  }
  `,
  APPROVE_ALL: gql`
  mutation APPROVE_ALL($id: uuid!){
    update_acl_campaigns(where: {campaign: {campaignType: {_eq: "ACL_CAMPAIGN"}}, classification: {_eq: "qualified"}},
      _set: {requestStatus: "approved", updatedBy: $id}) {
      affected_rows
    }
  }`,
  GET_ACTION_HISTORY: gql`
    subscription GET_ACTION_HISTORY ($id: uuid!) {
      acl_campaign_status_logs(order_by: {createdAt: desc}, where: {campaignId: {_eq: $id}}) {
        requestStatus
          submittedAt
          updatedAt
          createdUser{
            name
          }
          reason
          dealer {
            name
          }
      }
    }
  `,
  GET_DEALER_BY_BRANCH: gql`
  query GET_DEALER_BY_BRANCH ($name: uuid!) {
    total: shops_aggregate(where: {dealerShops: {dealer: {branch: {id: {_eq: $name}}}}}) {
      aggregate {
        count
      }
    }
    join: shops_aggregate(where: {acl_campaigns: {campaign: {campaignType: {_eq: "ACL_CAMPAIGN"}},
    dealer: {branch: {id: {_eq: $name}}}}}) {
      aggregate {
        count
      }
    }
  }
  `,
  GET_LIST_JOINED_SHOP: gql`
  query GET_JOINED_SHOP_LIST($search: String, $branch: [uuid!],
     $saleFilter: [String!], $branchFilter: [String!], $limit:Int, $offset: Int) {
    acl_campaigns(where: {_and:[{campaign: {campaignType: {_eq: "ACL_CAMPAIGN"}}},
    {dealer:{branchId:{_in: $branch}}}, {requestStatus: {_in: $saleFilter}},
      {classification: {_in: $branchFilter}}
      {_or: [{shop: {name: {_ilike: $search}}},{shop:{shopCode:{_ilike : $search}}},
        {dealer:{name:{_ilike : $search}}},{dealer:{dealerCode:{_ilike : $search}}}]}]},
        limit: $limit, offset: $offset) {
      id
      dealer {
        id
        name
        publicCode
        dealerCode
        taxNumber
        address
        ownerName
        ownerPosition
        shortName
      }
      shop {
        id
        name
        shopCode
        address
      }
      packages
      pictureURLs
      resizedPictureURLs
      reason
      requestStatus
      reviews {
        classification
        createdAt
        description
      }
      classification
      requestStatus
      shopId
    }
    acl_campaigns_aggregate(where: {_and:[{campaign: {campaignType: {_eq: "ACL_CAMPAIGN"}}},
    {dealer:{branchId:{_in: $branch}}}, {requestStatus: {_in: $saleFilter}},
      {classification: {_in: $branchFilter}}
      {_or: [{shop: {name: {_ilike: $search}}},{shop:{shopCode:{_ilike : $search}}},
        {dealer:{name:{_ilike : $search}}},{dealer:{dealerCode:{_ilike : $search}}}]}]}) {
      aggregate {
        count
      }
    }
  }
  `,
  GET_LIST_JOINED_SHOP_NOT_REVIEW: gql`
  query GET_LIST_JOINED_SHOP_NOT_REVIEW($search: String, $branch: [uuid!],
     $saleFilter: [String!], $branchFilter: [String!], $limit:Int, $offset: Int) {
    acl_campaigns(where: {_and: [{campaign: {campaignType: {_eq: "ACL_CAMPAIGN"}}},
    {dealer: {branchId: {_in: $branch}}}, {requestStatus: {_in: $saleFilter}},
      {_or:[{classification: {_in: $branchFilter}}, {_not: {reviews: {}}}]}, {_or:
      [{shop: {name: {_ilike: $search}}},{shop:{shopCode:{_ilike : $search}}},
        {dealer:{name:{_ilike : $search}}},{dealer:{dealerCode:{_ilike : $search}}}]}]},
        limit: $limit, offset: $offset) {
      id
      dealer {
        id
        name
        publicCode
        dealerCode
        taxNumber
        address
        ownerName
        ownerPosition
        shortName
      }
      shop {
        id
        name
        shopCode
        address
      }
      packages
      pictureURLs
      resizedPictureURLs
      reason
      requestStatus
      reviews {
        classification
        createdAt
        description
      }
      classification
      requestStatus
      shopId
    }
    acl_campaigns_aggregate(where: {_and: [{campaign: {campaignType: {_eq: "ACL_CAMPAIGN"}}},
    {dealer: {branchId: {_in: $branch}}}, {requestStatus: {_in: $saleFilter}},
      {_or:[{classification: {_in: $branchFilter}}, {_not: {reviews: {}}}]}, {_or:
      [{shop: {name: {_ilike: $search}}},{shop:{shopCode:{_ilike : $search}}},
        {dealer:{name:{_ilike : $search}}},{dealer:{dealerCode:{_ilike : $search}}}]}]}) {
      aggregate {
        count
      }
    }
  }
  `,
  GET_CONFIG_CROP_PICTURE: gql`
  query GET_CONFIG_CROP_PICTURE {
    system_configurations(where: {variableName: {_eq: "ACL_CAMPAIGN_CROP_PICTURE"}}){
      variableName
      value
    }
  }`,
  GET_LIST_SHOPS_NOT_REVIEW: gql`
  query GET_LIST_SHOPS_NOT_REVIEW($search: String, $status:[String!], $branch: [uuid!],
    $saleFilter: [String!], $branchFilter: [String!], $limit:Int, $offset: Int) {
      shops(where: {_and:[{acl_campaigns:{campaign: {campaignType: {_eq: "ACL_CAMPAIGN"}},
      requestStatus: {_in: $saleFilter}}},
        {dealerShops:{dealer: {branchId: {_in: $branch}}}},
        {_or:[{acl_campaigns:{campaign: {campaignType: {_eq: "ACL_CAMPAIGN"}}, classification: {_in: $branchFilter}}},
        {_not: {acl_campaigns:{campaign: {campaignType: {_nin: ["RA_CAMPAIGN"]}}, reviews: {}}}}]},
        {_or:[{dealerShops:{shop: {name: {_ilike: $search}}}},{dealerShops:{shop:{shopCode:{_ilike : $search}}}},
        {dealerShops:{dealer:{name:{_ilike : $search}}}},{dealerShops:{dealer:{dealerCode:{_ilike : $search}}}}]}
        ]},
        limit: $limit, offset: $offset) {
        name
        shopCode
        address
        id
        dealerShops(where: {isOwner: {_eq: true}}) {
          isOwner
          dealer {
            id
            name
            dealerCode
          }
        }
        acl_campaigns(where: {campaign: {campaignType: {_eq: "ACL_CAMPAIGN"}}}) {
          id
          dealer {
            id
            name
            publicCode
            dealerCode
            taxNumber
            address
            ownerName
            ownerPosition
            shortName
          }
          shop {
            id
            name
            shopCode
            address
          }
          packages
          pictureURLs
          resizedPictureURLs
          reason
          requestStatus
          reviews {
            id
            classification
            createdAt
            description
          }
          classification
          requestStatus
          shopId
        }
      }
      shops_aggregate(where: {_and:[{acl_campaigns:{campaign: {campaignType: {_eq: "ACL_CAMPAIGN"}},
      requestStatus: {_in: $saleFilter}}},
        {dealerShops:{dealer: {branchId: {_in: $branch}}}},
        {_or:[{acl_campaigns:{campaign: {campaignType: {_eq: "ACL_CAMPAIGN"}}, classification: {_in: $branchFilter}}},
        {_not: {acl_campaigns:{campaign: {campaignType: {_nin: ["RA_CAMPAIGN"]}}, reviews: {}}}}]},
        {_or:[{dealerShops:{shop: {name: {_ilike: $search}}}},{dealerShops:{shop:{shopCode:{_ilike : $search}}}},
        {dealerShops:{dealer:{name:{_ilike : $search}}}},{dealerShops:{dealer:{dealerCode:{_ilike : $search}}}}]}
        ]}) {
        aggregate {
          count
        }
      }
    }
  `,
  GET_LIST_SHOPS_NOT_JOIN_NOT_REIVEW: gql`
  query GET_LIST_SHOPS_NOT_JOIN_NOT_REIVEW($search: String, $status:[String!], $branch: [uuid!],
    $saleFilter: [String!], $branchFilter: [String!], $limit:Int, $offset: Int) {
      shops(where: {_and:[{_or:[{_and:[{_not:{acl_campaigns:{campaign:
        {campaignType: {_nin: ["RA_CAMPAIGN"]}}}}},{dealerShops:{isOwner:{_eq: true}}}]},
        {acl_campaigns:{campaign: {campaignType: {_eq: "ACL_CAMPAIGN"}}, requestStatus: {_in: $saleFilter}}}]}
        {dealerShops:{dealer: {branchId: {_in: $branch}}}},
        {_or:[{acl_campaigns:{campaign: {campaignType: {_eq: "ACL_CAMPAIGN"}},
        classification: {_in: $branchFilter}}}, {_not: {acl_campaigns:{campaign: {campaignType:
        {_nin: ["RA_CAMPAIGN"]}},
        reviews: {}}}}]}, {_or:
        [{dealerShops:{shop: {name: {_ilike: $search}}}},{dealerShops:{shop:{shopCode:{_ilike : $search}}}},
        {dealerShops:{dealer:{name:{_ilike : $search}}}},{dealerShops:{dealer:{dealerCode:{_ilike : $search}}}}]}
        ]},
        limit: $limit, offset: $offset) {
        name
        shopCode
        address
        id
        dealerShops(where: {isOwner: {_eq: true}}) {
          isOwner
          dealer {
            id
            name
            dealerCode
          }
        }
        acl_campaigns(where: {campaign: {campaignType: {_eq: "ACL_CAMPAIGN"}}}) {
          id
          dealer {
            name
            id
            publicCode
            dealerCode
            taxNumber
            address
            ownerName
            ownerPosition
            shortName
          }
          shop {
            id
            name
            shopCode
            address
          }
          packages
          pictureURLs
          resizedPictureURLs
          reason
          requestStatus
          reviews {
            id
            classification
            createdAt
            description
          }
          classification
          requestStatus
          shopId
        }
      }
      shops_aggregate(where: {_and:[{_or:[{_and:[{_not:{acl_campaigns:{campaign:
        {campaignType: {_nin: ["RA_CAMPAIGN"]}}}}},{dealerShops:{isOwner:{_eq: true}}}]},
        {acl_campaigns:{campaign: {campaignType: {_eq: "ACL_CAMPAIGN"}}, requestStatus: {_in: $saleFilter}}}]}
        {dealerShops:{dealer: {branchId: {_in: $branch}}}},
        {_or:[{acl_campaigns:{campaign: {campaignType: {_eq: "ACL_CAMPAIGN"}},
        classification: {_in: $branchFilter}}}, {_not: {acl_campaigns:{campaign: {campaignType: {_eq: "ACL_CAMPAIGN"}},
        reviews: {}}}}]}, {_or:
        [{dealerShops:{shop: {name: {_ilike: $search}}}},{dealerShops:{shop:{shopCode:{_ilike : $search}}}},
        {dealerShops:{dealer:{name:{_ilike : $search}}}},{dealerShops:{dealer:{dealerCode:{_ilike : $search}}}}]}
        ]}) {
        aggregate {
          count
        }
      }
    }
  `,
  GET_LIST_SHOPS_NOT_JOIN: gql`
  query GET_LIST_SHOPS_NOT_JOIN($search: String, $status:[String!], $branch: [uuid!],
    $saleFilter: [String!], $branchFilter: [String!], $limit:Int, $offset: Int) {
      shops(where: {_and:[{_or:[{_and:[{_not:{acl_campaigns:{campaign: {campaignType: {_nin: ["RA_CAMPAIGN"]}}}}},
      {dealerShops:{isOwner:{_eq: true}}}]},
        {acl_campaigns:{campaign: {campaignType: {_eq: "ACL_CAMPAIGN"}}, requestStatus: {_in: $saleFilter}}}]}
        {dealerShops:{dealer: {branchId: {_in: $branch}}}},
        {acl_campaigns:{campaign: {campaignType: {_eq: "ACL_CAMPAIGN"}}, classification: {_in: $branchFilter}}}, {_or:
        [{dealerShops:{shop: {name: {_ilike: $search}}}},{dealerShops:{shop:{shopCode:{_ilike : $search}}}},
        {dealerShops:{dealer:{name:{_ilike : $search}}}},{dealerShops:{dealer:{dealerCode:{_ilike : $search}}}}]}
        ]},
        limit: $limit, offset: $offset) {
        name
        shopCode
        id
        address
        dealerShops(where: {isOwner: {_eq: true}}) {
          isOwner
          dealer {
            id
            name
            dealerCode
          }
        }
        acl_campaigns(where: {campaign: {campaignType: {_eq: "ACL_CAMPAIGN"}}}) {
          id
          dealer {
            id
            name
            publicCode
            dealerCode
            taxNumber
            address
            ownerName
            ownerPosition
            shortName
          }
          shop {
            id
            name
            shopCode
            address
          }
          packages
          pictureURLs
          resizedPictureURLs
          reason
          requestStatus
          reviews {
            id
            classification
            createdAt
            description
          }
          classification
          requestStatus
          shopId
        }
      }
      shops_aggregate(where: {_and:[{_or:[{_and:[{_not:{acl_campaigns:{campaign: {campaignType:
      {_nin: ["RA_CAMPAIGN"]}}}}}, {dealerShops:{isOwner:{_eq: true}}}]},
        {acl_campaigns:{campaign: {campaignType: {_eq: "ACL_CAMPAIGN"}}, requestStatus: {_in: $saleFilter}}}]}
        {dealerShops:{dealer: {branchId: {_in: $branch}}}},
        {acl_campaigns:{campaign: {campaignType: {_eq: "ACL_CAMPAIGN"}}, classification: {_in: $branchFilter}}}, {_or:
        [{dealerShops:{shop: {name: {_ilike: $search}}}},{dealerShops:{shop:{shopCode:{_ilike : $search}}}},
        {dealerShops:{dealer:{name:{_ilike : $search}}}},{dealerShops:{dealer:{dealerCode:{_ilike : $search}}}}]}
        ]}) {
        aggregate {
          count
        }
      }
    }
  `,
  GET_LIST_SHOPS: gql`
  query GET_LIST_SHOPS($search: String, $status:[String!], $branch: [uuid!],
    $saleFilter: [String!], $branchFilter: [String!], $limit:Int, $offset: Int) {
      shops(where: {_and:[{acl_campaigns:{campaign: {campaignType: {_eq: "ACL_CAMPAIGN"}},
      requestStatus: {_in: $saleFilter}}},
        {dealerShops:{dealer: {branchId: {_in: $branch}}}},
        {acl_campaigns:{campaign: {campaignType: {_eq: "ACL_CAMPAIGN"}}, classification: {_in: $branchFilter}}},
        {_or:[{dealerShops:{shop: {name: {_ilike: $search}}}},{dealerShops:{shop:{shopCode:{_ilike : $search}}}},
        {dealerShops:{dealer:{name:{_ilike : $search}}}},{dealerShops:{dealer:{dealerCode:{_ilike : $search}}}}]}
        ]},
        limit: $limit, offset: $offset) {
        name
        shopCode
        address
        id
        dealerShops(where: {isOwner: {_eq: true}}) {
          isOwner
          dealer {
            id
            name
            dealerCode
          }
        }
        acl_campaigns(where: {campaign: {campaignType: {_eq: "ACL_CAMPAIGN"}}}) {
          id
          dealer {
            id
            name
            publicCode
            dealerCode
            taxNumber
            address
            ownerName
            ownerPosition
            shortName
          }
          shop {
            id
            name
            shopCode
            address
          }
          packages
          pictureURLs
          resizedPictureURLs
          reason
          requestStatus
          reviews {
            id
            classification
            createdAt
            description
          }
          classification
          requestStatus
          shopId
        }
      }
      shops_aggregate(where: {_and:[{acl_campaigns:{campaign: {campaignType: {_eq: "ACL_CAMPAIGN"}},
      requestStatus: {_in: $saleFilter}}},
        {dealerShops:{dealer: {branchId: {_in: $branch}}}},
        {acl_campaigns:{campaign: {campaignType: {_eq: "ACL_CAMPAIGN"}}, classification: {_in: $branchFilter}}},
        {_or:[{dealerShops:{shop: {name: {_ilike: $search}}}},{dealerShops:{shop:{shopCode:{_ilike : $search}}}},
        {dealerShops:{dealer:{name:{_ilike : $search}}}},{dealerShops:{dealer:{dealerCode:{_ilike : $search}}}}]}
        ]}) {
        aggregate {
          count
        }
      }
    }
  `
};
