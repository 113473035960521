import { gqlClient } from '../Core';
import { CRUDGqlService, ICRUDGqlService } from '../Core/crud';
import { newRACampaign, newRACampaignQuery } from '../Query/newRACampaign';

export interface INewRACampaignCampaignGqlService extends ICRUDGqlService<any> {
  acl_requests: ICRUDGqlService<any>;
  acl_request_reviews: ICRUDGqlService<any>;
  getAllSubdealer(): Promise<any>;
  addParticipant(createdBy: string, form: any): Promise<any>;
  getList(search: any, role: any, branch: any, order: any, limit: number, offset: number): Promise<any>;
  getListShop(classification: any, id: string, status: any, notJoin: boolean,
              search: string, limit: number, offset: number, dealerApproval: any): Promise<any>;
  getConfig(): Promise<any>;
  getJoinedShopList(classification: any, payment: any, saleManager: any, search: string, notJoin: boolean, status: any,
                    branch: any, saleFilter: any, notReview: boolean,
                    branchFilter: any, limit: number, offset: number, dealerApproval: any): Promise<any>;
  getSubDealerCampaignList(classification: any, payment: any, saleManager: any, search: string,
                           notJoin: boolean, status: any, branch: any,
                           saleFilter: any, notReview: boolean, branchFilter: any,
                           limit: number, offset: number, dealerApproval: any): Promise<any>;
  getSubDealerCampaignDetail(id: string): Promise<any>;
  submitReview(id: string, review: string, qualify: string, force: boolean, createdBy: string): Promise<any>;
  requestReup(ids: string[], action: string, reason: string, updatedBy: string): Promise<any>;
  getShop(id: string): Promise<any>;
  exportListRegistration(form: any): Promise<any>;
  updatePackage(id: string, models: any): Promise<any>;
  getQualifiedShopsList(sales: any, saleManager: any, limit: number, offset: number, salesStaff: any,
                        salesPic: any, salesPlanning: any, remove: any, search: any, type: any): Promise<any>;
  getHistoryLog(roles: any, requestId: string): Promise<any>;
  endCampaign(id: string): Promise<any>;
  getMaskConfig(id: string): Promise<any>;
  maskChange(id: string, mask: boolean): Promise<any>;
  getDealerByBranch(name: string): Promise<any>;
  getStatistic(): Promise<any>;
  exportStatistic(id: string): Promise<any>;
  getListManageExport(limit: number, offset: number): Promise<any>;
  getSalesStaffReviewSameGroup(filter: any): Promise<any>;
  exportPaymentDocument(id: string, form: any): Promise<any>;
}
export function newRACampaignGqlService(): INewRACampaignCampaignGqlService {
  async function getAllSubdealer(): Promise<any> {

    const result = await gqlClient.query({
      query: newRACampaignQuery.GET_ALL_SUBDEALER,
      fetchPolicy: 'no-cache'
    });
    if (result.errors) {
      throw result.errors;
    }

    return result.data.dealers;
  }
  async function addParticipant(createdBy: string, form: any): Promise<any> {
    const objects = [];
    if (form && form.length) {
      form.map((it) => {
        if (it.sub && it.sub.length) {
          it.sub.map((e) => {
            let _clone = {};
            _clone = {
              createdBy,
              subDealerId: e.id,
              subDealerCode: e.dealerCode,
              dealerId: it.dealer.id,
              dealerCode: it.dealer.dealerCode,
            };
            objects.push(_clone);
          });
        }

        return;
      });
    }
    const result = await gqlClient.mutate({
      mutation: newRACampaignQuery.ADD_PARTICIPANT,
      variables: {
        objects
      }
    });
    if (result.errors) {
      throw result.errors;
    }

    return result.data;
  }
  async function getList(search: any, role: any, branch: any, order: any,
                         limit: number, offset: number): Promise<any> {
    const results = await gqlClient.query({
      query: newRACampaignQuery.GET_LIST_RA_CAMPAIGN,
      variables: {
        search, role, branch, order, limit, offset
      },
      fetchPolicy: 'network-only'
    });
    if (results.errors) {
      throw results.errors;
    }

    return results.data;
  }
  async function getListShop(classification: any, id: string, status: any, notJoin: boolean,
                             search: string, limit: number, offset: number, dealerApproval: any): Promise<any> {
    if (notJoin) {
      const result = await gqlClient.query({
        query: newRACampaignQuery.GET_LIST_SHOP_CAMPAGIN,
        variables: {
          id, status, search, limit, offset, classification, dealerApproval
        },
        fetchPolicy: 'network-only'
      });
      if (result.errors) {
        throw result.errors;
      }

      return result.data;
    }
    const results = await gqlClient.query({
      query: newRACampaignQuery.GET_LIST_SHOP_CAMPAGIN_NOTJOIN,
      variables: {
        id, status, search, limit, offset, classification, dealerApproval
      },
      fetchPolicy: 'network-only'
    });
    if (results.errors) {
      throw results.errors;
    }

    return results.data;
  }
  async function getConfig(): Promise<any> {
    const results = await gqlClient.query({
      query: newRACampaignQuery.GET_CONFIG,
      fetchPolicy: 'network-only'
    });
    if (results.errors) {
      throw results.errors;
    }

    return results.data.campaigns;
  }
  async function getJoinedShopList(classification: any, payment: any, saleManager: any,
                                   search: string, notJoin: boolean, status: any, branch: any,
                                   saleFilter: any, notReview: boolean, branchFilter: any,
                                   limit: number, offset: number, dealerApproval: any): Promise<any> {
    let _status = [];
    if (status && status.length) {
      _status.push(...status);
    }
    if (saleFilter && saleFilter.length) {
      _status.push(...saleFilter);
    }
    if (_status.length <= 0) {
      _status = null;
    }
    if (notJoin && status && status.length === 0 && !saleFilter) {
      _status = [];
    }
    if (notReview && notJoin) {
      const result = await gqlClient.query({
        query: newRACampaignQuery.GET_LIST_SHOPS_NOT_JOIN_NOT_REIVEW,
        variables: {
// tslint:disable-next-line: object-shorthand-properties-first
          classification, payment, saleManager, search, branch, saleFilter: _status,
          // tslint:disable-next-line:object-shorthand-properties-first
          branchFilter , limit, offset, dealerApproval
        },
        fetchPolicy: 'network-only'
      });
      if (result.errors) {
        throw result.errors;
      }

      return result.data;
    }
    if (!notReview && notJoin) {
      const result = await gqlClient.query({
        query: newRACampaignQuery.GET_LIST_SHOPS_NOT_JOIN,
        variables: {
// tslint:disable-next-line: object-shorthand-properties-first
          classification, payment, saleManager, search, branch, saleFilter: _status,
          // tslint:disable-next-line:object-shorthand-properties-first
          branchFilter , limit, offset, dealerApproval
        },
        fetchPolicy: 'network-only'
      });
      if (result.errors) {
        throw result.errors;
      }

      return result.data;
    }
    if (notReview && !notJoin) {
      const result = await gqlClient.query({
        query: newRACampaignQuery.GET_LIST_SHOPS_NOT_REVIEW,
        variables: {
// tslint:disable-next-line: object-shorthand-properties-first
          classification, payment, saleManager, search, branch, saleFilter: _status, branchFilter , limit, offset,
          // tslint:disable-next-line:object-shorthand-properties-first
          dealerApproval
        },
        fetchPolicy: 'network-only'
      });
      if (result.errors) {
        throw result.errors;
      }

      return result.data;
    }

    const results = await gqlClient.query({
      query: newRACampaignQuery.GET_LIST_SHOPS,
      variables: {
// tslint:disable-next-line: object-shorthand-properties-first
        classification, payment, saleManager, search, branch, saleFilter: _status,
         // tslint:disable-next-line:object-shorthand-properties-first
        branchFilter, limit, offset, dealerApproval
      },
      fetchPolicy: 'network-only'
    });
    if (results.errors) {
      throw results.errors;
    }

    return results.data;
  }
  async function getSubDealerCampaignList(classification: any, payment: any, saleManager: any, search: string,
                                          notJoin: boolean, status: any, branch: any,
                                          saleFilter: any, notReview: boolean, branchFilter: any,
                                          limit: number, offset: number, dealerApproval: any): Promise<any> {
    let _status = [];
    if (status && status.length) {
      _status.push(...status);
    }
    if (saleFilter && saleFilter.length) {
      _status.push(...saleFilter);
    }
    if (_status.length <= 0) {
      _status = null;
    }
    if (notJoin && status && status.length === 0 && !saleFilter) {
      _status = [];
    }
    if (notReview && notJoin) {
      const result = await gqlClient.query({
        query: newRACampaignQuery.GET_LIST_SUB_NOT_JOIN_NOT_REIVEW,
        variables: {
// tslint:disable-next-line: object-shorthand-properties-first
          classification, payment, saleManager, search, branch, saleFilter: _status,
          // tslint:disable-next-line:object-shorthand-properties-first
          branchFilter , limit, offset, dealerApproval
        },
        fetchPolicy: 'network-only'
      });
      if (result.errors) {
        throw result.errors;
      }

      return result.data;
    }
    if (!notReview && notJoin) {
      const result = await gqlClient.query({
        query: newRACampaignQuery.GET_LIST_SUB_NOT_JOIN,
        variables: {
// tslint:disable-next-line: object-shorthand-properties-first
          classification, payment, saleManager, search, branch, saleFilter: _status,
          // tslint:disable-next-line:object-shorthand-properties-first
          branchFilter , limit, offset, dealerApproval
        },
        fetchPolicy: 'network-only'
      });
      if (result.errors) {
        throw result.errors;
      }

      return result.data;
    }
    if (notReview && !notJoin) {
      const result = await gqlClient.query({
        query: newRACampaignQuery.GET_LIST_SUB_NOT_REVIEW,
        variables: {
// tslint:disable-next-line: object-shorthand-properties-first
          classification, payment, saleManager, search, branch, saleFilter: _status,
           // tslint:disable-next-line:object-shorthand-properties-first
          branchFilter , limit, offset, dealerApproval
        },
        fetchPolicy: 'network-only'
      });
      if (result.errors) {
        throw result.errors;
      }

      return result.data;
    }
    const results = await gqlClient.query({
      query: newRACampaignQuery.GET_LIST_SUB,
      variables: {
// tslint:disable-next-line: object-shorthand-properties-first
        classification, payment, saleManager, search, branch, saleFilter: _status,
        // tslint:disable-next-line:object-shorthand-properties-first
        branchFilter, limit, offset, dealerApproval
      },
      fetchPolicy: 'network-only'
    });
    if (results.errors) {
      throw results.errors;
    }

    return results.data;
  }
  async function getSubDealerCampaignDetail(id: string): Promise<any> {
    const results = await gqlClient.query({
      query: newRACampaignQuery.GET_SUB_DEALER_CAMPAIGN_DETAIL,
      variables: {
        id
      },
      fetchPolicy: 'network-only'
    });
    if (results.errors) {
      throw results.errors;
    }

    return results.data;
  }
  async function submitReview(id: string, review: string, qualify: string,
                              force: boolean, createdBy: string): Promise<any> {
    const results = await gqlClient.mutate({
      mutation: newRACampaignQuery.SUBMIT_REVIEW,
      variables: {
        id,
        review,
        qualify,
        force,
        createdBy
      }
    });
    if (results.errors) {
      throw results.errors;
    }

    return results.data;
  }
  async function requestReup(ids: string[], action: string, reason: string, updatedBy: string): Promise<any> {
    const results = await gqlClient.mutate({
      mutation: newRACampaignQuery.REQUEST_REUP,
      variables: {
        ids,
        action,
        reason,
        updatedBy
      }
    });
    if (results.errors) {
      throw results.errors;
    }

    return results.data;
  }
  async function getShop(id: string): Promise<any> {
    const results = await gqlClient.query({
      query: newRACampaignQuery.GET_SHOP,
      variables: {
        id
      },
      fetchPolicy: 'network-only'
    });
    if (results.errors) {
      throw results.errors;
    }

    return results.data;
  }
  async function exportListRegistration(form: any): Promise<any> {
    const results = await gqlClient.mutate({
      mutation: newRACampaignQuery.EXPORT_LIST_REGISTRATION,
      variables: {
        objects: form
      },
    });
    if (results.errors) {
      throw results.errors;
    }

    return results.data.insert_exports;
  }
  async function updatePackage(id: string, models: any): Promise<any> {
    const results = await gqlClient.mutate({
      mutation: newRACampaignQuery.UPDATE_PACKAGE,
      variables: {
        id,
        models,
      }
    });
    if (results.errors) {
      throw results.errors;
    }

    return results.data;
  }
  async function getQualifiedShopsList(sales: any, saleManager: any, limit: number, offset: number, salesStaff: any,
                                       // tslint:disable-next-line:max-line-length
                                       salesPic: any, salesPlanning: any, remove: any, search: any, type: any): Promise<any> {
    const results = await gqlClient.query({
      query: newRACampaignQuery.GET_QUALIFIED_REQUEST,
      variables: {
        sales, saleManager, limit, offset , salesStaff, salesPic, salesPlanning, remove, search, type
      },
      fetchPolicy: 'network-only'
    });
    if (results.errors) {
      throw results.errors;
    }

    return results.data;
  }
  async function getHistoryLog(roles: any, requestId: string): Promise<any> {
    const results = await gqlClient.query({
      query: newRACampaignQuery.GET_HISTORY_LOG,
      variables: {
        requestId,
        roles
      }
    });
    if (results.errors) {
      throw results.errors;
    }

    return results.data.acl_request_logs;
  }
  async function endCampaign(id: string): Promise<any> {
    const results = await gqlClient.mutate({
      mutation: newRACampaignQuery.END_CAMPAIGN,
      variables: {
        id
      }
    });
    if (results.errors) {
      throw results.errors;
    }

    return results.data;
  }
  async function getMaskConfig(id: string): Promise<any> {
    const results = await gqlClient.query({
      query: newRACampaignQuery.GET_MASK_CONFIG,
      variables: {
        id
      },
      fetchPolicy: 'network-only'
    });
    if (results.errors) {
      throw results.errors;
    }

    return results.data.campaigns;
  }
  async function maskChange(id: string, mask: boolean): Promise<any> {
    const results = await gqlClient.mutate({
      mutation: newRACampaignQuery.MASK_CHANGE,
      variables: {
        id,
        mask
      }
    });
    if (results.errors) {
      throw results.errors;
    }

    return results.data;
  }
  async function getDealerByBranch(name: string): Promise<any> {
    const results = await gqlClient.query({
      query: newRACampaignQuery.GET_DEALER_BY_BRANCH,
      variables: {
        name
      }
    });
    if (results.errors) {
      throw results.errors;
    }

    return results.data;
  }
  async function getStatistic(): Promise<any> {
    const results = await gqlClient.query({
      query: newRACampaignQuery.GET_STATISTIC,
      fetchPolicy: 'network-only'
    });
    if (results.errors) {
      throw results.errors;
    }

    return results.data;
  }
  async function exportStatistic(id: string): Promise<any> {
    const results = await gqlClient.mutate({
      mutation: newRACampaignQuery.EXPORT_STATISTIC,
      variables: {
        id,
        // tslint:disable-next-line: object-literal-key-quotes
        filter: 'ra_campaign_2023',
        form: {
          search: null, branchIds: null
        }
      }
    });
    if (results.errors) {
      throw results.errors;
    }

    return results.data;
  }
  async function getListManageExport(limit: number, offset: number): Promise<any> {
    const results = await gqlClient.query({
      query: newRACampaignQuery.GET_LIST_MANAGE_EXPORT,
      variables: {
        limit, offset,
        // tslint:disable-next-line: object-literal-key-quotes
        type: {
          type: {_eq: 'ra_campaign_2023'}
        }
      },
      fetchPolicy: 'network-only'
    });
    if (results.errors) {
      throw results.errors;
    }

    return results.data;
  }
  async function getSalesStaffReviewSameGroup(filter: any): Promise<any> {
    const results = await gqlClient.query({
      query: newRACampaignQuery.GET_SALE_STAFF_REVIEW_SAME_GROUP,
      variables: {
        filter
      },
      fetchPolicy: 'network-only'
    });
    if (results.errors) {
      throw results.errors;
    }

    return results.data.acl_campaigns_2023_view;
  }
  async function exportPaymentDocument(id: string, form: any = {search: null, branchIds: null}): Promise<any> {
    const results = await gqlClient.mutate({
      mutation: newRACampaignQuery.EXPORT_STATISTIC,
      variables: {
        id,
        form,
        // tslint:disable-next-line: object-literal-key-quotes
        filter: 'ra_campaign_2023_to_dealer'
      }
    });
    if (results.errors) {
      throw results.errors;
    }

    return results.data;
  }

  return {
    getAllSubdealer,
    addParticipant,
    getList,
    getListShop,
    getConfig,
    getJoinedShopList,
    getSubDealerCampaignList,
    getSubDealerCampaignDetail,
    submitReview,
    requestReup,
    getShop,
    exportListRegistration,
    updatePackage,
    getQualifiedShopsList,
    getHistoryLog,
    endCampaign,
    getMaskConfig,
    maskChange,
    getDealerByBranch,
    getStatistic,
    exportStatistic,
    getListManageExport,
    getSalesStaffReviewSameGroup,
    exportPaymentDocument,
    ...CRUDGqlService<any>(newRACampaign, newRACampaignQuery),
    acl_requests: CRUDGqlService<any>('acl_requests', newRACampaignQuery.CampaignRequest),
    acl_request_reviews: CRUDGqlService<any>('acl_request_reviews', newRACampaignQuery.ClassifiedRequest),
  };
}
