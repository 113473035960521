// import { stripObject } from '@hgiasac/helper';
import { stripObject } from '@hgiasac/helper';
import { Form } from 'element-ui';
import { cloneDeep, omit } from 'lodash';
import { ActionTypeBranchManage } from 'root/admin/BranchAndGroup/BranchManage/Store/types';
import { ActionTypeGroupManage } from 'root/admin/BranchAndGroup/GroupManage/Store/types';
import { ActionTypeUser, MutationTypeUser, SystemRoleAlias } from 'root/admin/User/Store/types';
import { Avatar } from 'root/components';
import { AddressSelection } from 'root/components/AddressSelection';
import { ruleEmail, ruleEmailNoRequired, ruleMinLength, ruleNumber, rulePhoneNumber,
  ruleRequired, sanitizeString
} from 'root/helpers';
import { groupFormDefault, IGroupForm } from 'root/models/BranchGroupManage';
import { IPartner } from 'root/models/Partner';
import {
  groupSystemUserSelection,
  EGender, IUser
} from 'root/models/User';
import { pathUpload} from 'root/services';
import { IState, MutationType, TypeAlert} from 'root/store';
// import { IState, MutationType, TypeAlert } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapGetters, mapState } from 'vuex';
// import { ActionTypeDealer } from '../../Store/types';
import './styles.scss';

const genderOptions = [
  {
    value: EGender.Male,
    label: EGender.Male
  },
  {
    value: EGender.Female,
    label: EGender.Female
  }
];

@Component({
  template: require('./view.html'),
  components: {
    avatar: Avatar,
    'address-selection': AddressSelection
  },
  props: {
    visible: Boolean,
    userId: String,
    visibleExtend: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters(['branch', 'group']),
    ...mapState({
      authUser: (state: IState) => state.global.authUser,
      userDetailLoading: () => false,
      branch: (state: IState) => state.branchManage.data,
      leaders: (state: IState) => state.user.data,
      isRefesh: (state: IState) => state.global.isRefesh,
      detail: (state: IState) => state.groupManage.detail
    }),
    cloneBranchOptions() {
      const branch: any[] = cloneDeep(this.branch);

      return branch.map((e) => {
        return {
          label: e.name,
          value: e.id
        };
      });
    },
    cloneLeadersOptions() {
      const leader: any[] = cloneDeep(this.leaders);

      return leader.filter((e) =>
      e.role.alias === SystemRoleAlias.GroupLeader).map((e) => {
        return {
          label: e.name,
          value: e.id
        };
      });
    },
    cloneLeadersPICOptions() {
      const leader: any[] = cloneDeep(this.leaders);

      return leader.filter((e) =>
      e.role.alias === SystemRoleAlias.SaleDepartmentPic).map((e) => {
        return {
          label: e.name,
          value: e.id
        };
      });
    },
    cloneStations() {
      // if (this.isSystemUser && this.form.permission === 'head_of_station') {
      //   return this.stations.filter((e) => !this.stationIdsHaveHeader.includes(e.id));
      // }

      return this.stations;
    }
  },
})

export class GroupEditor extends Vue {

  public get isUpdating(): boolean {
    return !!this.userId;
  }
  public get clonePartners() {
    const text = sanitizeString(this.querySearchPartner);

    return this.partners.filter((e) => sanitizeString(e.name).includes(text));
    // !this.partnerHaveCoordinator.includes(e.id) &&
  }

  public get rules() {
    return {
      required: ruleRequired(),
      rulePhoneNumber: rulePhoneNumber(),
      min: ruleMinLength(8),
      ruleEmail: ruleEmail(),
      ruleEmailNoRequired: ruleEmailNoRequired(),
      number: ruleNumber()
    };
  }

  public $refs: {
    form: Form
  };
  public loading: boolean = false;
  public authUser: IUser;
  public detail: null;
  public userDetail: IUser;
  public roleOptions: any[];
  public form: IGroupForm = groupFormDefault();
  public groupSystemUserSelection = groupSystemUserSelection();
  // public stationBranchOptions = stationBranchOptions();
  public stationGroupOptions = groupSystemUserSelection();
  public fileUpload: File = null;
  public route: any;
  // public userType: UserType;
  public userId: string;
  public technicianRates: any;
  public partners: IPartner[];
  public partnerHaveCoordinator: string[] = [];
  public validateTechnicianCode: {
    status?: string,
    message?: string
  } = null;
  public genderOptions = genderOptions;
  public isDealer: boolean = true;
  public cloneRoleOptions: any[];
  public isSub: boolean = false;
  public addressText = '';
  public isRefesh: boolean;
  public isDisable: boolean;
  public bookingCodes: any[] = [];
  public leaders: any[];
  public mutiManager: any[] = [];

  private pathUploadAvatar: pathUpload = pathUpload.SYSTEM_USER;
  // private stationIdsHaveHeader: string[] = [];
  // private subscribe: any = null;
  // private subscribePartnerHaveCoordinator: Subscription = null;
  private querySearchPartner: string = '';
  public remoteMethodService(query: string) {
    this.addressText = query;
  }

  public changeForm() {
    //
  }

  public async submit() {
    this.$refs.form.validate(async (valid) => {
      if (valid) {
        // this.loading = true;
        // tslint:disable-next-line: whitespace
        let form = <any>cloneDeep(this.form);

        form = omit(form, ['createdUser', 'updatedUser']);
        if (this.userId) {
          form.updatedBy = this.authUser.id;
        }
        this.handleSystemUser(form);
      }

      return;
    });
  }

  public back() {
    this.$emit('update:visible', false);
  }
  public openDialog() {
    this.$store.dispatch(ActionTypeUser.FilterNoCache);
    if (this.userId) {
      const _form = this.$store.getters.getGroupManageDetail(this.userId)
      ? this.$store.getters.getGroupManageDetail(this.userId) : this.detail ;
      this.form = _form;
      if (this.form && _form.branch) {
        this.form.branch = _form.branch.id;
      }
      if (this.form && _form.groupManagers && _form.groupManagers.length) {
        const managers = [];
        _form.groupManagers.map((it) => {
          managers.push(it.id);
        });

        this.form.groupManagers = managers;
      }
    }

    if (this.$refs.form) {
      this.$refs.form.clearValidate();
    }
  }
  public closeDialog() {
    this.form = groupFormDefault();
    this.addressText = '';
    this.isDealer = true;
    this.back();
  }
  public change(cod: any[]) {
    this.isDisable = false;
    this.bookingCodes = cloneDeep(this.leaders).filter((it) => cod.includes(it.id));
  }

  public remoteMethod(query: string) {
    if (query !== '') {
      this.querySearchPartner = query;
    }
  }

  // public changeStationBranch(value: StationBranch) {
  //   switch (value) {
  //   case StationBranch.Sale:
  //     this.$store.dispatch(ActionTypeGroup.FilterNoCache);
  //     break;
  //   case StationBranch.System:
  //     this.$store.dispatch(ActionTypeGroup.FilterNoCache);
  //   default:
  //     break;
  //   }
  // }
  protected mounted() {
    if (!this.isRefesh) {
      this.$store.dispatch(ActionTypeBranchManage.GetListBranch, {form: {
        filter: {},
        pagination: {}
      }});
      this.$store.commit(MutationTypeUser.FilterChange, stripObject({

        _and: {
          mode: 'special',
          data: {
            role: {alias: {_in: [SystemRoleAlias.GroupLeader, SystemRoleAlias.SaleDepartmentPic]}},
            managerGroup: {managerId : {_is_null: true}}
          }
        }}));

    }

    return;
  }

  protected beforeDestroy() {
    this.querySearchPartner = '';
    // this.$store.commit(MutationTypeTechnicianRate.ResetState);
  }

  private async handleSystemUser(form: any) {
    const postForm = {
      branchId: form.branchId,
      managerId: form.groupManagers,
    };
    const formGroup = {
      name: form.name,
      address: form.address,
      branchId: form.branch,
      description: form.description,
      groupCode: form.groupCode,
      createdBy: this.authUser.id
    };
    if (this.userId) { // update
      this.$store.dispatch(ActionTypeGroupManage.UpdateGroupManager, {
        form: {
          ...omit(formGroup, ['__typename', 'updatedAt', 'updatedBy',
            'createdAt', 'createdBy', 'createdUser', 'updatedUser', 'id']),
          updatedBy: this.authUser.id
        },
        id: this.userId,
        set: postForm.managerId,
        onSuccess: () => {
          this.loading = false;
          const message = 'Update Group successfully';
          this.$store.commit(MutationType.OpenTopAlert, {
            message,
            type: TypeAlert.Success
          });
          this.closeDialog();
          this.$emit('updateSuccess');
        },
        onFailure: () => {
          this.loading = false;
        }
      });
    } else { // create
      this.$store.dispatch(ActionTypeGroupManage.InsertGroup, {
        form: {
          ...stripObject(formGroup)
        },
        formManager: {
          ...stripObject(postForm)
        },
        onSuccess: () => {
          this.loading = false;
          const message = 'Create successfully';
          this.$store.commit(MutationType.OpenTopAlert, {
            message,
            type: TypeAlert.Success
          });
          this.$store.dispatch(ActionTypeGroupManage.GetListGroupManage, {
            form: {
              filter: {},
              pagination: {}
            }
          });
          this.closeDialog();
        },
        onFailure: () => {
          this.loading = false;
        }
      });
    }
  }
}
