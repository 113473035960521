import { crudMutations, CRUDState, ICRUDState } from 'root/store/helpers';

export interface ITransactionViewState extends ICRUDState<any> {
  loading: boolean;
}

export const defaultState: ITransactionViewState = {
  ...CRUDState(),
  loading: false,
};

export const mutations = {
  ...crudMutations('transactionView')
};
