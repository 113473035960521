import { cloneDeep, find } from 'lodash';
import { IZeroCampaignState } from '.';

export const getters = {
  getZeroCampaignDetail(state: IZeroCampaignState) {
    return (id: string) => {
      return find(cloneDeep(state.data), (e) => e.id === id);
    };
  },
  getRequestCampaignDetail(state: IZeroCampaignState) {
    return (id: string) => {
      return find(cloneDeep(state.ip_campaign_companies.data), (e) => e.id === id);
    };
  }
};
