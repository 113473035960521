import { get } from 'lodash';
import { DKGqlClient } from 'root/api/graphql/Client';
import { ActionType, IState, MutationType, TypeAlert } from 'root/store';
import { crudActions } from 'root/store/helpers';
import { ActionContext } from 'vuex';
import { IRegionState } from '.';
import { ActionTypeRegion, MutationTypeRegion } from './types';

const { region } = DKGqlClient();
export const actions = {
  ...crudActions('regions', {
    filter: region.filter
  }),
  async [ActionTypeRegion.InsertRegion]({
    commit, dispatch
 }: ActionContext<IRegionState, IState>,
                                        {form, onSuccess, onFailure}) {
    try {
      await region.insertRegion(form);
      onSuccess();
    } catch (error) {
      onFailure(error);
      const graphQLErrors = get(error, 'graphQLErrors');
      const message =
      graphQLErrors && graphQLErrors[0] ? graphQLErrors[0].message : '';
      // tslint:disable-next-line: max-line-length
      if (message === `Uniqueness violation. duplicate key value violates unique constraint "regions_name_key"`
      // tslint:disable-next-line: max-line-length
      || message === `Uniqueness violation. duplicate key value violates unique constraint \"regions_regionCode_key\"`) {
        commit(MutationType.OpenTopAlert, {
          message: 'Duplicate regionCode',
          type: TypeAlert.Error
        });

        return;
      }
      dispatch(ActionType.CatchException, error);
    }
  },
  async [ActionTypeRegion.UpdateRegion]({
    commit, dispatch
  }: ActionContext<IRegionState, IState>,
                                        {id, form, onSuccess, onFailure}) {
    try {
      await region.updateRegion(id, form);
      onSuccess();
    } catch (error) {
      onFailure(error);
      const graphQLErrors = get(error, 'graphQLErrors');
      const message =
      graphQLErrors && graphQLErrors[0] ? graphQLErrors[0].message : '';
      // tslint:disable-next-line: max-line-length
      if (message === `Uniqueness violation. duplicate key value violates unique constraint \"regions_regionCode_key\"`) {
        commit(MutationType.OpenTopAlert, {
          message: 'Duplicate regionCode',
          type: TypeAlert.Error
        });

        return;
      }
      dispatch(ActionType.CatchException, error);

    }
  },
  async [ActionTypeRegion.RegionGetById]({
    commit, dispatch
  }: ActionContext<IRegionState, IState>,
                                         {id, onSuccess}) {
    try {
      const RegionDetail = await region.regionGetById(id);
      commit(MutationTypeRegion.RegionSetDetail, {data: RegionDetail});
      if (onSuccess) {
        onSuccess();
      }
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    }
  },
  async [ActionTypeRegion.GetListBranch]({
     dispatch
  }: ActionContext<IRegionState, IState>,
                                         {id, onSuccess}) {
    try {
      const ListBranch = await region.regionGetListBranch(id);
      if (onSuccess) {
        onSuccess(ListBranch);
      }
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    }
  },
};
