import { StatusCode } from './Common';

export interface IMaterialsForm {
  name: string;
  cost: number;
  description: string;
  code: string;
  unit: string;
  technicianCost?: number;
  createdBy?: string;
}
export function materialsFormDefault(): IMaterialsForm {
  return {
    description: '',
    cost: null,
    technicianCost: null,
    name: '',
    code: '',
    unit: ''
  };
}
export interface IMaterials extends IMaterialsForm {
  id: string;
  status: StatusCode;
}
export function materialsDefault(): IMaterials {
  return {
    ...materialsFormDefault(),
    id: '',
    status: StatusCode.Active
  };
}

export enum MaterialsType {
  Repair = 'repair',
  Maintenance = 'maintenance'
}
