// import moment from 'moment';
import { SystemRoleAlias } from 'root/admin/User/Store/types';
import { IUser } from 'root/models';
import { IState, MutationType, TypeAlert } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import { ActionTypeZeroCampaign } from '../../Store/types';
import './styles.scss';
@Component({
  template: require('./view.html'),
  props: {
    visible: Boolean,
    campaignCompany: {
      type: Object,
      default: () => {
        return {};
      }
    },
  },
  computed: {
    ...mapState({
      authUser: (state: IState) => state.global.authUser,
    }),
  }
})
export class ApproveZeroCampaign extends Vue {
  public loadingSubmit: boolean = false;
  public authUser: IUser;
  public async openDialog() {
    // changeafter
  }
  public submitUpdate() {
    this.loadingSubmit = true;
    this.$store.dispatch(ActionTypeZeroCampaign.ApproveCampaign, {
      id: this.$props.campaignCompany.id,
      set: {
        updatedBy: this.authUser.id,
        lastReviewerId: this.authUser.id,
        lastReviewStatus: 'approved',
        ...this.authUser.role.alias === SystemRoleAlias.GroupLeader
        && this.$props.campaignCompany.type === 'open_code' ? {
          companyStatus: 'approved',
        } : this.authUser.role.alias === SystemRoleAlias.GroupLeader
        && this.$props.campaignCompany.type === 'closed_code' ? {
          companyStatus: 'stop_joining',
          // stoppedByDaikin: this.authUser.id
        } : {},
      },
      // companyStatus: 'approved',
      // reason: '',
      // updatedBy: this.authUser.id,
      // updatedAt: moment(),
      onSuccess: () => {
        const message = 'Approve financial company successfully';
        this.$store.commit(MutationType.OpenTopAlert, {
          message,
          type: TypeAlert.Success,
        });
        this.loadingSubmit = false;
        this.$emit('refetchData');
        this.closeDialog();
      }
    });
  }
  public closeDialog() {
    this.$emit('closeDialog');
  }
}
