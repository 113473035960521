import { Form } from 'element-ui';
import { cloneDeep, includes, isArray, omit } from 'lodash';
import moment from 'moment';
import { ActionTypeDealer } from 'root/admin/Dealer/Store/types';
import { DKGqlClient } from 'root/api/graphql/Client';
import { DKEditor } from 'root/components/DKCustomEditor';
import { Upload } from 'root/components/Upload';
import { resetOrientation } from 'root/helpers';
import { ruleRequired, ruleUrl } from 'root/helpers';
import { IDealer } from 'root/models/Dealer';
import {
  defaultNotificationForm,
  INotification,
  INotificationForm,
} from 'root/models/Notifications';
import { pathUpload, sizeImage, uploadToFirebase } from 'root/services';
import { FirebaseAuthService } from 'root/services/auth/firebase';
import { ActionType, IState } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import { ActionTypeNotification } from '../../store/type';
import './style.scss';

export enum TargetOptions {
  DealerApp = 'dealer_app',
  Dealer = 'dealer',
  Technician = 'technician',
  DealerGroup = 'dealer_group'
}

export enum NotificationType {
  DaikinDealer = 'daikin_dealer',
  SubDealer = 'sub_dealer',
  Freelancer = 'freelancer',
  Staff = 'daikin_staff',
  All = 'all'
}

@Component({
  template: require('./view.html'),
  components: {
    upload: Upload,
    'dk-editor': DKEditor
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    currentNotificationId: {
      type: String,
      default: ''
    },
    targetDetails: {
      type: Array,
      default: []
    }
  },
  computed: {
    ...mapState({
      dealers: (state: IState) => state.dealer.data,
      notifications: (state: IState) => state.notification.data
    })
  }
})
export class NotificationEditor extends Vue {
  public get allDealers() {

    return this.dealers.map((dealer) => ({
      value: dealer.id,
      label: dealer.role && dealer.role.alias === 'daikin_staff' ? dealer.phoneNumber : dealer.dealerCode
    }));
  }
  // public targetDetails: ITargetDetail[] = [];
  public get rules() {
    return {
      required: ruleRequired(),
      url: ruleUrl()
    };
  }

  public get pickerOptions() {
    return {
      disabledDate: (time) => {
        return moment(time) < moment(new Date()).subtract(1, 'days');
      }
    };
  }
  public $refs: {
    form: Form;
  };
  public loading: boolean = false;
  public form: INotificationForm = defaultNotificationForm();
  public dealers: IDealer[];
  public currentNotificationId: string;
  public fileUpload: File;
  public notifications: INotification[];
  public isAll: boolean = false;
  public typeAll: boolean = false;
  public onlyStaff: boolean = false;

  public targetOptions = [
    {
      value: TargetOptions.DealerApp,
      label: 'Dealer App'
    },
    {
      value: TargetOptions.Dealer,
      label: 'Single Dealer'
    },
    // {
    //   value: TargetOptions.Technician,
    //   label: 'Technician'
    // },
    // {
    //   value: TargetOptions.DealerGroup,
    //   label: 'Staff'
    // }
  ];

  public typeOptions = [
    {
      value: NotificationType.DaikinDealer,
      label: 'Direct Dealer'
    },
    // {
    //   value: NotificationType.Freelancer,
    //   label: 'Freelancer'
    // },
    {
      value: NotificationType.SubDealer,
      label: 'Sub-Dealer'
    },
    {
      value: NotificationType.Staff,
      label: 'Salesman'
    },
    // {
    //   value: NotificationType.Staff,
    //   label: 'All'
    // },
    // {
    //   value: NotificationType.All,
    //   label: 'All'
    // }
  ];

  public getActions(scheduledAt) {
    if (moment(scheduledAt) > moment(new Date())) {
      return ['view', 'edit', 'block'];
    }

    return ['view'];
  }

  public targetChange() {
    this.form.receiverId = '';
    this.form.typeDealers = [];
    this.isAll = false;
    // if (this.form.target === TargetOptions.DealerGroup) {
    //   this.typeOptions.splice(3, 1);

    //   return;
    // }
    switch (this.form.target) {
    case TargetOptions.DealerApp: {
      const optionAll = this.typeOptions.find(
        (option) => option.value === NotificationType.All
      );
      if (!optionAll) {
        this.typeOptions.push({ value: NotificationType.All, label: 'All' });
      }
      this.typeAll = true;
      this.form.typeDealers = [NotificationType.All];
      break;
    }
    case TargetOptions.Dealer: {
      this.typeAll = false;
      const optionAll = this.typeOptions.find(
        (option) => option.value === NotificationType.All
      );
      if (optionAll) {
        const _index = this.typeOptions.indexOf(optionAll);
        this.typeOptions.splice(_index, 1);
      }
      // this.$store.dispatch(ActionTypeDealer.GetTargetDetail, {
      //   role: 'Daikin Dealer',
      //   status: null
      // });
      // break;
    }
    // case TargetOptions.Technician: {
    //   this.$store.dispatch(ActionTypeDealer.GetTargetDetail, {
    //     role: 'Technician',
    //     status: ['active', 'expired_card']
    //   });
    //   break;
    // }
    // case TargetOptions.DealerGroup: {
    //   // const optionAll = this.typeOptions.find(
    //   //   (option) => option.value === NotificationType.All
    //   // );
    //   // if (!optionAll) {
    //   //   this.typeOptions.push({ value: NotificationType.Staff, label: 'All' });
    //   // }
    //   this.form.typeDealers = [NotificationType.Staff];
    //   break;
    // }
    }

    // tslint:disable-next-line: early-exit
    // if (this.form.target === TargetOptions.DealerApp) {
    //   const optionAll = this.typeOptions.find(
    //     (option) => option.value === NotificationType.All
    //   );
    //   if (!optionAll) {
    //     this.typeOptions.push({ value: NotificationType.All, label: 'All' });
    //   }
    //   this.form.typeDealers = [NotificationType.All];
    // }
  }
  public typeChange() {
    // if (this.form.typeDealers.length >= 1 && this.form.typeDealers.indexOf('daikin_staff') >= 0) {
    //   this.onlyStaff = true;
    //   this.isAll = true;

    //   return;
    // }
    // this.onlyStaff = false;
    // this.isAll = false;
    this.$store.dispatch(ActionTypeDealer.GetTargetDetail, {
      role: this.form.typeDealers,
      status: ['active']
    });
  }

  public onDateChange() {
    const { scheduledAt } = this.form;
    const currentMinute = moment().minutes();
    const scheduledAtMinute = moment(scheduledAt).minutes();
    const scheduledAtDate = moment(scheduledAt).format();
    const currentDate = moment().format();
    const isSameDay = moment(currentDate).isSame(scheduledAtDate, 'day');
    const isSameMonth = moment(currentDate).isSame(scheduledAtDate, 'year');
    const isSameHour = moment(currentDate).isSame(scheduledAtDate, 'hour');

    // tslint:disable-next-line: early-exit
    if (
      isSameDay &&
      isSameMonth &&
      isSameHour &&
      scheduledAtMinute <= currentMinute
    ) {
      this.form.scheduledAt = moment(scheduledAt)
        .add(300000)
        .format();
    }
  }

  public changeTargetDetail() {
    if (includes(this.form.receiverId, 'all')) {
      this.form.receiverId = ['all'];

      return this.isAll = true;
    }

    return this.isAll = false;
  }

  public confirmClose() {
    this.$msgbox({
      title: this.$t('confirm').toString(),
      message: this.$t('action_confirm_message').toString(),
      showCancelButton: true,
      confirmButtonText: this.$t('confirm').toString(),
      cancelButtonText: this.$t('cancel').toString(),
      beforeClose: (action, _instance, done) => {
        if (action === 'confirm') {
          this.closeEditor();
          done();
        } else {
          done();
        }

        return;
      }
    });
  }
  public async openEditor() {
    const currentNotification = this.notifications.find(
      (noti) => noti.id === this.currentNotificationId
    );

    if (!currentNotification) {
      return;
    }

    // const targetValue = isArray(currentNotification.target)
    //   ? currentNotification.target[0]
    //   : currentNotification.target;

    // const dealers = [];
    // if (targetValue === TargetOptions.Dealer) {
    //   const receiverIdList: ITargetDetail = targetDetailList.find(
    //     (receiver) => receiver.referenceId === this.currentNotificationId
    //   );
    //   if (!receiverIdList) {
    //     return;
    //   }
    //   if (!isArray(receiverIdList.receiverIds)) {
    //     const dealer = this.dealers.find(
    //       (elememt) => elememt.id === receiverIdList.receiverIds
    //     );
    //     if (dealer) {
    //       dealers.push(dealer.id);
    //     }
    //   } else {
    //     receiverIdList.receiverIds.forEach((receiver) => {
    //       const dealer = this.dealers.find((elememt) => elememt.id === receiver);
    //       if (!dealer) {
    //         return;
    //       }
    //       dealers.push(dealer.id);
    //     });
    //   }
    // }

    // this.form = {
    //   ...currentNotification,
    //   target: isArray(currentNotification.target)
    //     ? currentNotification.target[0]
    //     : currentNotification.target,
    //   receiverId: this.currentNotificationId ? dealers : []
    // };
    // if (this.form.target === TargetOptions.DealerGroup) {
    //   this.typeOptions.splice(3, 1);
    // }
    this.form = {...currentNotification,
      receiverId : currentNotification.dealerIds,
      target: isArray(currentNotification.target)
        ? currentNotification.target[0]
        : currentNotification.target,
    };
    if (this.form.target === TargetOptions.DealerGroup) {
      this.form.target = includes(this.form.typeDealers, 'technician') ? TargetOptions.Technician
      : includes(this.form.typeDealers, 'daikin_dealer') ? TargetOptions.Dealer : TargetOptions.DealerGroup;
      this.form.typeDealers = [];
      this.form.receiverId = ['all'];
      this.isAll = true;
      this.typeAll = true;
    }
    if (this.form.target === TargetOptions.DealerApp) {
      const optionAll = this.typeOptions.find(
        (option) => option.value === NotificationType.All
      );
      if (!optionAll) {
        this.typeOptions.push({ value: NotificationType.All, label: 'All' });
      }
      this.form.typeDealers = [NotificationType.All];
      this.typeAll = true;
    }
    if (this.form.target === TargetOptions.Dealer) {
      const optionAll = this.typeOptions.find(
        (option) => option.value === NotificationType.All
      );
      if (optionAll) {
        const _index = this.typeOptions.indexOf(optionAll);
        this.typeOptions.splice(_index, 1);
      }
      this.form.typeDealers = this.form.metadata && this.form.metadata.filter
      && this.form.metadata.filter.typeDealers.length ? this.form.metadata.filter.typeDealers : [];
      this.isAll = false;
      this.typeAll = false;
      this.$store.dispatch(ActionTypeDealer.GetTargetDetail, {
        role: this.form.typeDealers,
        status: ['active']
      });
    }

    return;
  }

  public submit(): void {
    this.$refs.form.validate(async (valid) => {
      if (valid) {
        try {
          this.loading = true;
          let { image } = this.form;
          if (image && this.fileUpload) {
            const token = await DKGqlClient().user.getFirebaseAuthenticateToken();
            await FirebaseAuthService().signInWithCustomToken(
              token.authenticate
            );
            image = await uploadToFirebase(this.fileUpload, pathUpload.NEWS, {
              ...sizeImage.message.original,
              quality: 1
            });
            this.form.image = image;
          }
          if (this.currentNotificationId) {
            const updatedForm = <any> cloneDeep(this.form);
            const processedEditForm = omit(updatedForm, [
              '__typename',
              'createdUser',
              'updatedUser',
              'typeDealer',
              'dealerId',
              'dealerIds',
              'updatedAt',
            ]);

            // if (updatedForm.target === TargetOptions.Dealer) {
            //   this.$store.dispatch(
            //     ActionTypeNotification.NotificationUpdateDealers,
            //     { referenceId: this.form.id, receiverIds: this.form.receiverId }
            //   );
            // }
            if (updatedForm.target === TargetOptions.DealerApp) {
              processedEditForm.receiverId = '';
            }
            if (updatedForm.target === TargetOptions.Dealer) {
              processedEditForm.typeDealers = null;
              processedEditForm.metadata.filter.typeDealers = updatedForm.typeDealers;
            }
            if (this.isAll) {
              processedEditForm.target = TargetOptions.DealerGroup;
              processedEditForm.typeDealers = this.form.typeDealers;
            }
            processedEditForm.titleEng = updatedForm.title;
            processedEditForm.subtitleEng = updatedForm.subtitle;
            processedEditForm.descriptionEng = updatedForm.description;
            processedEditForm.updatedAt = moment().format('YYYY/MM/DD');
            this.$store.dispatch(ActionTypeNotification.NotificationUpdate, {
              // id: this.form.id,
              form: processedEditForm,
              onSuccess: () => {
                this.closeEditor();
                this.$emit('fetchData');
                // this.$emit('fetchTargetDetail');
                this.$message.success(
                  this.$t('message.updateSuccess').toString()
                );
                // this.$emit('fetchTargetDetail');
                this.loading = false;
              },
              onFailure: (err) => {
                this.$store.dispatch(ActionType.CatchException, err);
                this.loading = false;
              }
            });

            return;
          }
          let processedForm = <any> cloneDeep(this.form);
          processedForm = omit(processedForm, ['url', 'typeDealer']);
          if (processedForm.target === TargetOptions.Dealer) {
            processedForm.typeDealers = null;
            processedForm.metadata.filter.typeDealers = this.form.typeDealers;
          }
          if (this.isAll) {
            processedForm.target = TargetOptions.DealerGroup;
            processedForm.typeDealers = this.form.typeDealers;
          }
          processedForm.titleEng = this.form.title;
          processedForm.subtitleEng = this.form.subtitle;
          processedForm.descriptionEng = this.form.description;
          this.$store.dispatch(ActionTypeNotification.NotificationCreate, {
            form: processedForm,
            onSuccess: () => {
              this.closeEditor();
              this.$emit('fetchData');
              // this.$emit('fetchTargetDetail');
              this.$message.success(
                this.$t('message.createSuccess').toString()
              );
              this.loading = false;
            },
            onFailure: (err) => {
              this.$store.dispatch(ActionType.CatchException, err);
              this.loading = false;
            }
          });
        } catch (err) {
          this.$store.dispatch(ActionType.CatchException, err);
        }

        return;
      }
      this.$message.error('Form is invalid');
    });
  }

  public formatLabel(label: string) {
    return label.toUpperCase();
  }

  public removeUpload() {
    this.form.image = '';
    this.fileUpload = null;
  }

  public async handleUpload(file: any) {
    // tslint:disable-next-line: whitespace
    const _file = <any>await resetOrientation(file.raw, -1);
    this.form.image = window.URL.createObjectURL(_file);
    this.fileUpload = _file;
  }
  public closeEditor() {
    this.$emit('closeDialog');
    this.$emit('clearCurrentNotificationId');
    this.form = defaultNotificationForm();
    this.$refs.form.resetFields();
    this.fileUpload = null;
  }
}
