import { IConditions } from 'root/models';
import { CRUDGqlService, ICRUDGqlService } from '../Core/crud';
import { ConditionQuery } from '../Query/condition';

export interface IConditionGqlService extends ICRUDGqlService<IConditions> {}

export function conditionGqlService(): IConditionGqlService {
  return {
    ...CRUDGqlService<IConditions>('conditions', ConditionQuery)
  };
}
