import { TabPane } from 'element-ui';
import moment from 'moment';
import { BranchAndGroupRouterPath } from 'root/admin/BranchAndGroup';
import { SystemRoleAlias } from 'root/admin/User/Store/types';
import { formatterPhoneNumber, parseUTCToTime, FormatDateFullTime } from 'root/helpers';
import { IAddress, IBreadcrumb, IServiceCategory, IUser, IWorkMaster } from 'root/models';
import { IDealer } from 'root/models/Dealer';
import { IState, MutationType } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import { DealerRouterName, DealerRouterPath } from '..';
import { DealerEditor } from '../Components/DealerEditor';
import { ActionTypeDealer, DealerTab } from '../Store/types';
import './styles.scss';

@Component({
  template: require('./view.html'),
  components: {
    editor: DealerEditor
  },
  computed: {
    ...mapState({
      authUser: (state: IState) => state.global.authUser,
      dealer: (state: IState) => state.dealer.data,
      loading: (state: IState) => state.dealer.loading,
      isRefesh: (state: IState) => state.global.isRefesh,
      typeCollapse: (state: IState) => state.global.typeCollapse
    }),
    detail() {
      return this.$store.getters.getDealerDetail(this.serviceId);
    },
    isTechnician() {
      // if (this.detail && this.detail.role.name === 'Technician') {
      //   return true;
      // }

      return false;
    }
  }
})
export class DealerDetail extends Vue {
  public get serviceId(): string {
    return this.$route.params.id;
  }
  public get activeTab() {
    return this.$route.name;
  }
  public set activeTab(_value: string) {
    //
  }
  public FormatDateFullTime = FormatDateFullTime;
  public formatDate = parseUTCToTime;
  public visibleEditor: boolean = false;
  public dealer: IDealer[];
  public repairs: IWorkMaster[];
  public category: IServiceCategory;
  public fileUpload: File[] = [];
  public isRefesh: boolean;
  public isTechnician: boolean;
  public authUser: IUser;
  public typeCollapse: boolean;
  public back() {
    this.$router.push(DealerRouterName.ListDealer);
  }
  public closeDialog() {
    this.visibleEditor = false;
  }
  public goEdit() {
    this.visibleEditor = true;
  }
  public displayAddress(model: IAddress) {
    return model && model.province ? this.$store.getters['getAddressString'](model) : '';
  }
  public formatUTCDate(date) {
    return moment(date).utc().format('DD/MM/YYYY');
  }
  public formatterPhoneNumber(phone) {
    return formatterPhoneNumber(phone);
  }
  public changeTab(tab: TabPane) {
    switch (tab.name) {
    case DealerTab.Inventory:
      this.$router.push({
        name: DealerRouterName.Inventory,
        params: {
          id: this.serviceId
        }
      });
      break;
    case DealerTab.PointHistory:
      this.$router.push({
        name: DealerRouterName.PointHistory,
        params: {
          id: this.serviceId
        }
      });
      break;
    case DealerTab.SaleRouts:
      this.$router.push({
        name: DealerRouterName.SaleRouts,
        params: {
          id: this.serviceId
        }
      });
      break;
    case DealerTab.Transaction:
      this.$router.push({
        name: DealerRouterName.Transaction,
        params: {
          id: this.serviceId
        }
      });
      break;
    case DealerTab.ActiveEWarranty:
      this.$router.push({
        name: DealerRouterName.ActiveEWarranty,
        params: {
          id: this.serviceId
        }
      });
      break;
    case DealerTab.DealerShop:
      this.$router.push({
        name: DealerRouterName.DealerShop,
        params: {
          id: this.serviceId
        }
      });
      break;
    case DealerTab.Staff:
      this.$router.push({
        name: DealerRouterName.AccountStaff,
        params: {
          id: this.serviceId
        }
      });
      break;
    case DealerTab.Campaign:
      this.$router.push({
        name: DealerRouterName.Campaign,
        params: {
          id: this.serviceId
        }
      });
      break;
    default:
      break;
    }
  }

  protected mounted() {
    const breadcrumb: IBreadcrumb[] = [
      {
        label: 'title.dealer',
        path: DealerRouterPath.ListDealer,
        current: false
      },
      {
        label: 'title.view_details',
        path: null,
        current: true
      }
    ];
    const _breadcrumb: IBreadcrumb[] = [
      {
        label: 'title.managegroup',
        path: BranchAndGroupRouterPath.ListGroupManage,
        current: false
      },
      {
        label: 'title.view_details',
        path: null,
        current: true
      }
    ];
    this.$store.commit(MutationType.SetBack);
    this.$store.commit(MutationType.SetBreadcrumb, _breadcrumb);
    this.$store.commit(MutationType.SetShowCollapse);
    this.$store.commit(MutationType.SetTypeCollapse, true);
    if (this.authUser && (this.authUser.role.alias === SystemRoleAlias.Admin ||
      this.authUser.role.alias === SystemRoleAlias.SaleAdmin)) {
      this.$store.commit(MutationType.SetBreadcrumb, breadcrumb);
    }

    this.fetchData();
  }
  protected beforeDestroy() {
    this.$store.commit(MutationType.ClearShowCollapse);
    this.$store.commit(MutationType.ClearBreadcrumb);
  }
  private fetchData() {
    // this.$store.dispatch(ActionTypeDealer.GetListDealer, {
    //   form: {
    //     filter: {},
    //     pagination: {
    //       size: 20,
    //       page: 1
    //     }
    //   },
    // });
    // if (this.dealer.length) {
    //   return this.$store.getters.getDealerDetail(this.serviceId);
    // }

    return this.$store.dispatch(ActionTypeDealer.GetById, {
      id: this.serviceId,
      onSuccess: () => {
          //
      },
      onFailure: () => {
          //
      }
    });
  }
}
