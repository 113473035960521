import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import { split, ApolloLink } from 'apollo-link';
import { setContext } from 'apollo-link-context';
import DebounceLink from 'apollo-link-debounce';
import { HttpLink } from 'apollo-link-http';
import { RetryLink } from 'apollo-link-retry';
import { WebSocketLink } from 'apollo-link-ws';
import { getMainDefinition } from 'apollo-utilities';
import moment from 'moment';
import { ELocalStorage } from 'root/models';
import DaikinAuth from 'root/services/auth';

const DEFAULT_DEBOUNCE_TIMEOUT = 314;
export interface IAuthenticator {
  getIdToken(): Promise<string>;
}

export interface IGraphQLClientOptions {
  httpURL: string;
  wsURL: string;
  authenticator: IAuthenticator;
}

function getOsversion() {
  if (navigator.platform) {
    return navigator.platform;
  }

  return '';
}
function getModel() {
  const ua = navigator.userAgent;
  let tem,
    M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
  if (/trident/i.test(M[1])) {
    tem = /\brv[ :]+(\d+)/g.exec(ua) || [];

    return 'IE ' + (tem[1] || '');
  }
  if (M[1] === 'Chrome') {
    tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
    if (tem !== null) { return tem.slice(1).join(' ').replace('OPR', 'Opera'); }
  }
  M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
  // tslint:disable-next-line:no-conditional-assignment
  if ((tem = ua.match(/version\/(\d+)/i)) !== null) { M.splice(1, 1, tem[1]); }

  return M.join(' ');
}

export function GraphQLClient(
  options: IGraphQLClientOptions
): ApolloClient<InMemoryCache> {

  const authLink = setContext(async (_, { headers }) => {
    const data = {
      token: localStorage.getItem(ELocalStorage.Token),
      refreshToken: localStorage.getItem(ELocalStorage.RefreshToken)
    };
    const expireTime = moment(localStorage.getItem('ExpireTime'), 'DD-MM-YYYY HH:mm:ss');

    const now = moment();
    let authorization = '';
    // console.log('now- expire time', );
    if (expireTime && now.isAfter(expireTime, 'minutes')) {
      const newToken = await DaikinAuth.refreshToken(data);
      authorization = newToken && newToken.data.data.token;
      const refreshTK = newToken && newToken.data.data.refreshToken;
      localStorage.setItem(ELocalStorage.Token, authorization);
      localStorage.setItem(ELocalStorage.RefreshToken, refreshTK);
      localStorage.setItem('ExpireTime', now.add(50, 'minutes').format('DD-MM-YYYY HH:mm:ss'));
    } else {
      authorization = localStorage.getItem(ELocalStorage.Token);
    }

    return {
      headers: {
        ...headers,
        authorization,
        device: 'web',
        platform: 'sales_portal',
        model: getModel(),
        osversion: getOsversion(),
        appversion: '1.1.0',
        // 'x-hasura-admin-secret': 'daikin-sales@nexlab',
      }
    };
  });
  const retryLink = new RetryLink({
    delay: {
      initial: 300,
      max: Infinity,
      jitter: true
    },
    attempts: {
      max: 5,
      retryIf: (error, _operation) => !!error
    }
  });
  const httpLink = ApolloLink.from([
    retryLink,
    new DebounceLink(DEFAULT_DEBOUNCE_TIMEOUT),
    new HttpLink({
      uri: options.httpURL
    }),
  ]);

  // Create a WebSocket link:
  const wsLink = new WebSocketLink({
    uri: options.wsURL,
    options: {
      lazy: true,
      connectionParams: async () => {
        const now = moment();
        const data = {
          token: localStorage.getItem(ELocalStorage.Token),
          refreshToken: localStorage.getItem(ELocalStorage.RefreshToken)
        };
        let authorization = '';
        const expireTime = moment(localStorage.getItem('ExpireTime'), 'DD-MM-YYYY HH:mm:ss');
        if (expireTime && now.isAfter(expireTime, 'minutes')) {
          const newToken = await DaikinAuth.refreshToken(data);
          authorization = newToken && newToken.data.data.token;
          const refreshTK = newToken && newToken.data.data.refreshToken;
          localStorage.setItem(ELocalStorage.Token, authorization);
          localStorage.setItem(ELocalStorage.RefreshToken, refreshTK);
          localStorage.setItem('ExpireTime', now.add(50, 'minutes').format('DD-MM-YYYY HH:mm:ss'));
        } else {
          authorization = localStorage.getItem(ELocalStorage.Token);
        }

        return ({
          headers: {
            authorization,
            device: 'web',
            platform: 'sales_portal',
            model: getModel(),
            osversion: getOsversion(),
            appversion: '1.1.0',
            'x-hasura-access-token': authorization
            // 'x-hasura-admin-secret': 'daikin-sales@nexlab',
          }
        });
      },
      reconnect: true
    }
  });

  const link = split(
    ({ query }) => {
      // tslint:disable-next-line: whitespace
      const { kind, operation } = <any>getMainDefinition(query);

      return kind === 'OperationDefinition'
        && operation === 'subscription';
    },
    wsLink,
    authLink.concat(httpLink),
  );

  // tslint:disable-next-line: whitespace
  return new ApolloClient(<any>{
    link,

    cache: new InMemoryCache({addTypename: false}),
    connectToDevTools: process.env.NODE_ENV !== 'production'
  });

}
