import { DKGqlClient } from 'root/api/graphql/Client';
import { ActionType, IState } from 'root/store';
import { crudActions } from 'root/store/helpers';
import { ActionContext } from 'vuex';
import { IRaCampaignState } from '.';
import { ActionTypeRaCampaign, MutationTypeRaCampaign } from './types';

const { raCampaign } = DKGqlClient();
export const actions = {
  ...crudActions('acl_campaigns', {
    filter: raCampaign.filter
  }),
  async [ActionTypeRaCampaign.GetAclCampaign]({
    commit, dispatch
  }: ActionContext<IRaCampaignState, IState>,
                                              {search, role, branch, order, pagination}) {
    try {
      commit(MutationTypeRaCampaign.AclCampaignLoading);
      const { page, limit } = pagination;
      const offset = (page - 1) * limit;
      const listAclCampaign = await raCampaign.getList(search, role, branch, order, limit, offset);
      if (listAclCampaign) {
        commit(MutationTypeRaCampaign.SetAclCompaign, listAclCampaign);
      }
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    } finally {
      commit(MutationTypeRaCampaign.AclCampaignLoaded);
    }
  },
  async [ActionTypeRaCampaign.GetAclCampaignDetail]({
    commit, dispatch
  }: ActionContext<IRaCampaignState, IState>,
                                                    {id, status, notJoin, search, pagination}) {
    try {
      commit(MutationTypeRaCampaign.AclCampaignLoading);
      const { page, limit } = pagination;
      const offset = (page - 1) * limit;
      const aclCampaignDetail = await raCampaign.getListShop(id, status, notJoin, search, limit, offset);
      if (aclCampaignDetail) {
        commit(MutationTypeRaCampaign.SetAclCompaignDetail, aclCampaignDetail);
      }
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    } finally {
      commit(MutationTypeRaCampaign.AclCampaignLoaded);
    }
  },
  async [ActionTypeRaCampaign.ApproveShop]({
    commit, dispatch
  }: ActionContext<IRaCampaignState, IState>,
                                           {ids, action, updatedBy, reason, onSuccess, onFailure}) {
    try {
      commit(MutationTypeRaCampaign.AclCampaignLoading);
      await raCampaign.approveShop(ids, action, reason, updatedBy);
      if (onSuccess) {
        onSuccess();
      }
    } catch (error) {
      if (onFailure) {
        onFailure();
      }
      dispatch(ActionType.CatchException, error);
    } finally {
      commit(MutationTypeRaCampaign.AclCampaignLoaded);
    }
  },
  async [ActionTypeRaCampaign.RequestReup]({
    commit, dispatch
  }: ActionContext<IRaCampaignState, IState>,
                                           {ids, action, updatedBy, reason, onSuccess, onFailure}) {
    try {
      commit(MutationTypeRaCampaign.AclCampaignLoading);
      await raCampaign.requestReup(ids, action, reason, updatedBy);
      if (onSuccess) {
        onSuccess();
      }
    } catch (error) {
      if (onFailure) {
        onFailure();
      }
      dispatch(ActionType.CatchException, error);
    } finally {
      commit(MutationTypeRaCampaign.AclCampaignLoaded);
    }
  },
  async [ActionTypeRaCampaign.GetShopDetail]({
    commit, dispatch
  }: ActionContext<IRaCampaignState, IState>,
                                             {id, onSuccess, onFailure}) {
    try {
      commit(MutationTypeRaCampaign.AclCampaignLoading);
      const ShopDetail = await raCampaign.getShop(id);
      if (onSuccess) {
        onSuccess(ShopDetail);
      }
    } catch (error) {
      if (onFailure) {
        onFailure();
      }
      dispatch(ActionType.CatchException, error);
    } finally {
      commit(MutationTypeRaCampaign.AclCampaignLoaded);
    }
  },
  async [ActionTypeRaCampaign.SubmitReview]({
    commit, dispatch
  }: ActionContext<IRaCampaignState, IState>,
                                            {id, review, qualify, force, createdBy, onSuccess, onFailure}) {
    try {
      commit(MutationTypeRaCampaign.AclCampaignLoading);
      await raCampaign.submitReview(id, review, qualify, force, createdBy);
      if (onSuccess) {
        onSuccess();
      }
    } catch (error) {
      if (onFailure) {
        onFailure();
      }
      dispatch(ActionType.CatchException, error);
    } finally {
      commit(MutationTypeRaCampaign.AclCampaignLoaded);
    }
  },
  async [ActionTypeRaCampaign.GetPackage]({
    commit, dispatch
  }: ActionContext<IRaCampaignState, IState>,
                                          {onSuccess, onFailure}) {
    try {
      commit(MutationTypeRaCampaign.AclCampaignLoading);
      const aclPackage = await raCampaign.getPackage();
      if (onSuccess) {
        onSuccess(aclPackage);
      }
    } catch (error) {
      if (onFailure) {
        onFailure();
      }
      dispatch(ActionType.CatchException, error);
    } finally {
      commit(MutationTypeRaCampaign.AclCampaignLoaded);
    }
  },
  async [ActionTypeRaCampaign.UpdatePackage]({
    commit, dispatch
  }: ActionContext<IRaCampaignState, IState>,
                                             {id, models, onSuccess, onFailure}) {
    try {
      commit(MutationTypeRaCampaign.AclCampaignLoading);
      const aclPackage = await raCampaign.updatePackage(id, models);
      if (onSuccess) {
        onSuccess(aclPackage);
      }
    } catch (error) {
      if (onFailure) {
        onFailure();
      }
      dispatch(ActionType.CatchException, error);
    } finally {
      commit(MutationTypeRaCampaign.AclCampaignLoaded);
    }
  },
  async [ActionTypeRaCampaign.GetPdfExportData]({
    commit, dispatch
  }: ActionContext<IRaCampaignState, IState>,
                                                {id, onSuccess, onFailure}) {
    try {
      commit(MutationTypeRaCampaign.AclCampaignLoading);
      const pdfExportData = await raCampaign.getPdfExportData(id);
      if (onSuccess) {
        onSuccess(pdfExportData);
      }
    } catch (error) {
      if (onFailure) {
        onFailure();
      }
      dispatch(ActionType.CatchException, error);
    } finally {
      commit(MutationTypeRaCampaign.AclCampaignLoaded);
    }
  },
  async [ActionTypeRaCampaign.GetCampaignConfiguration]({
    commit, dispatch
  }: ActionContext<IRaCampaignState, IState>,
                                                        {onSuccess, onFailure}) {
    try {
      commit(MutationTypeRaCampaign.AclCampaignLoading);
      const aclConfig = await raCampaign.getConfig();
      if (onSuccess) {
        onSuccess(aclConfig);
      }
    } catch (error) {
      if (onFailure) {
        onFailure();
      }
      dispatch(ActionType.CatchException, error);
    } finally {
      commit(MutationTypeRaCampaign.AclCampaignLoaded);
    }
  },
  async [ActionTypeRaCampaign.EndCampaign]({
    commit, dispatch
  }: ActionContext<IRaCampaignState, IState>,
                                           {id, onSuccess, onFailure}) {
    try {
      commit(MutationTypeRaCampaign.AclCampaignLoading);
      await raCampaign.endCampaign(id);
      if (onSuccess) {
        onSuccess();
      }
    } catch (error) {
      if (onFailure) {
        onFailure();
      }
      dispatch(ActionType.CatchException, error);
    } finally {
      commit(MutationTypeRaCampaign.AclCampaignLoaded);
    }
  },
  async [ActionTypeRaCampaign.GetMaskConfig]({
    commit, dispatch
  }: ActionContext<IRaCampaignState, IState>,
                                             {id, onSuccess, onFailure}) {
    try {
      commit(MutationTypeRaCampaign.AclCampaignLoading);
      const maskConfig = await raCampaign.getMaskConfig(id);
      if (onSuccess) {
        onSuccess(maskConfig);
      }
    } catch (error) {
      if (onFailure) {
        onFailure();
      }
      dispatch(ActionType.CatchException, error);
    } finally {
      commit(MutationTypeRaCampaign.AclCampaignLoaded);
    }
  },
  async [ActionTypeRaCampaign.MaskChange]({
    commit, dispatch
  }: ActionContext<IRaCampaignState, IState>,
                                          {id, mask, onSuccess, onFailure}) {
    try {
      commit(MutationTypeRaCampaign.AclCampaignLoading);
      await raCampaign.maskChange(id, mask);
      if (onSuccess) {
        onSuccess();
      }
    } catch (error) {
      if (onFailure) {
        onFailure();
      }
      dispatch(ActionType.CatchException, error);
    } finally {
      commit(MutationTypeRaCampaign.AclCampaignLoaded);
    }
  },
  async [ActionTypeRaCampaign.GetManageExport]({
    commit, dispatch
  }: ActionContext<IRaCampaignState, IState>,
                                               {pagination}) {
    try {
      commit(MutationTypeRaCampaign.AclCampaignLoading);
      const { page, limit } = pagination;
      const offset = (page - 1) * limit;
      const listManagerExport = await raCampaign.getListManageExport(limit, offset);
      if (listManagerExport) {
        commit(MutationTypeRaCampaign.SetAclManageExport, listManagerExport);
      }
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    } finally {
      commit(MutationTypeRaCampaign.AclCampaignLoaded);
    }
  },
  async [ActionTypeRaCampaign.GetStatistic]({
    commit, dispatch
  }: ActionContext<IRaCampaignState, IState>,
                                            {onSuccess, onFailure}) {
    try {
      commit(MutationTypeRaCampaign.AclCampaignLoading);
      const statistic = await raCampaign.getStatistic();
      if (onSuccess) {
        onSuccess(statistic);
      }
    } catch (error) {
      if (onFailure) {
        onFailure();
      }
      dispatch(ActionType.CatchException, error);
    } finally {
      commit(MutationTypeRaCampaign.AclCampaignLoaded);
    }
  },
  async [ActionTypeRaCampaign.ExportStatistic]({
    commit, dispatch
  }: ActionContext<IRaCampaignState, IState>,
                                               {id, onSuccess, onFailure}) {
    try {
      commit(MutationTypeRaCampaign.AclCampaignLoading);
      const statistic = await raCampaign.exportStatistic(id);
      if (onSuccess) {
        onSuccess(statistic);
      }
    } catch (error) {
      if (onFailure) {
        onFailure();
      }
      dispatch(ActionType.CatchException, error);
    } finally {
      commit(MutationTypeRaCampaign.AclCampaignLoaded);
    }
  },
  async [ActionTypeRaCampaign.GetQualifiedShops]({
    commit, dispatch
  }: ActionContext<IRaCampaignState, IState>,
                                                 {pagination}) {
    try {
      commit(MutationTypeRaCampaign.AclCampaignLoading);
      const { page, limit } = pagination;
      const offset = (page - 1) * limit;
      const listQualifiedShops = await raCampaign.getQualifiedShopsList(limit, offset);
      if (listQualifiedShops) {
        commit(MutationTypeRaCampaign.SetQualifiedList, listQualifiedShops);
      }
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    } finally {
      commit(MutationTypeRaCampaign.AclCampaignLoaded);
    }
  },
  async [ActionTypeRaCampaign.ApproveAll]({
    commit, dispatch
  }: ActionContext<IRaCampaignState, IState>,
                                          {id, onSuccess, onFailure}) {
    try {
      commit(MutationTypeRaCampaign.AclCampaignLoading);
      const result = await raCampaign.approveAll(id);
      if (onSuccess) {
        onSuccess(result);
      }
    } catch (error) {
      if (onFailure) {
        onFailure();
      }
      dispatch(ActionType.CatchException, error);
    } finally {
      commit(MutationTypeRaCampaign.AclCampaignLoaded);
    }
  },
  async [ActionTypeRaCampaign.GetDealerByBranch]({
    commit, dispatch
  }: ActionContext<IRaCampaignState, IState>,
                                                 {name, onSuccess, onFailure}) {
    try {
      commit(MutationTypeRaCampaign.AclCampaignLoading);
      const result = await raCampaign.getDealerByBranch(name);
      if (onSuccess) {
        onSuccess(result);
      }
    } catch (error) {
      if (onFailure) {
        onFailure();
      }
      dispatch(ActionType.CatchException, error);
    } finally {
      commit(MutationTypeRaCampaign.AclCampaignLoaded);
    }
  },
  async [ActionTypeRaCampaign.GetJoinedShop]({
    commit, dispatch
  }: ActionContext<IRaCampaignState, IState>,
                                             {type, search, notJoin, status, branch, saleFilter,
                                                notReview, branchFilter, pagination}) {
    try {
      commit(MutationTypeRaCampaign.AclCampaignLoading);
      const { page, limit } = pagination;
      const offset = (page - 1) * limit;
      const listJoinedShop = await raCampaign.getJoinedShopList(
        type, search, notJoin, status, branch, saleFilter, notReview, branchFilter, limit, offset);
      if (listJoinedShop) {
        commit(MutationTypeRaCampaign.SetJoinedShopList, listJoinedShop);
      }
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    } finally {
      commit(MutationTypeRaCampaign.AclCampaignLoaded);
    }
  },
  async [ActionTypeRaCampaign.GetConfigCropPicture]({
    dispatch
  }: ActionContext<IRaCampaignState, IState>,
                                                    {onSuccess}) {
    try {
      const config = await raCampaign.getConfigCropPicture();
      if (onSuccess) {
        onSuccess(config);
      }
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    }
  },
  async [ActionTypeRaCampaign.GetListSubDealerRequest]({
    commit, dispatch
  }: ActionContext<IRaCampaignState, IState>,
                                                       {search, region, branch, pagination}) {
    try {
      commit(MutationTypeRaCampaign.AclCampaignLoading);
      const { page, limit } = pagination;
      const offset = (page - 1) * limit;
      const listRequest = await raCampaign.getListRequest(search, region, branch, limit, offset);
      if (listRequest) {
        commit(MutationTypeRaCampaign.SetListRequest, listRequest);
      }
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    } finally {
      commit(MutationTypeRaCampaign.AclCampaignLoaded);
    }
  },
  async [ActionTypeRaCampaign.UpdateSubDealerRequest]({
    dispatch
  }: ActionContext<IRaCampaignState, IState>,
                                                      {id, onSuccess, onFailure}) {
    try {
      await raCampaign.updateSubDealerRequest(id);
      if (onSuccess) {
        onSuccess();
      }
    } catch (error) {
      if (onFailure) {
        onFailure();
      }
      dispatch(ActionType.CatchException, error);
    }
  },
  async [ActionTypeRaCampaign.GetConfirmationCode]({
    commit, dispatch
  }: ActionContext<IRaCampaignState, IState>,
                                                   {type, search, status, pagination}) {
    try {
      commit(MutationTypeRaCampaign.AclCampaignLoading);
      const { page, limit } = pagination;
      const offset = (page - 1) * limit;
      const listCode = await raCampaign.getConfirmCode(type, search, status, limit, offset);
      if (listCode) {
        commit(MutationTypeRaCampaign.SetListConfirmCode, listCode);
      }
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    } finally {
      commit(MutationTypeRaCampaign.AclCampaignLoaded);
    }
  },
  async [ActionTypeRaCampaign.DownloadConfirmationCode]({
    dispatch
  }: ActionContext<IRaCampaignState, IState>,
                                                        {onSuccess}) {
    try {
      const url = await raCampaign.downloadConfirmationCode();
      if (onSuccess) {
        onSuccess(url);
      }
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    }
  },
  async [ActionTypeRaCampaign.GenerateCode]({
    dispatch
  }: ActionContext<IRaCampaignState, IState>,
                                            {codeType, quantity, onSuccess}) {
    try {
      await raCampaign.generateCode(codeType, quantity);
      if (onSuccess) {
        onSuccess();
      }
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    }
  },
  async [ActionTypeRaCampaign.GetSubDealerCampaign]({
    commit, dispatch
  }: ActionContext<IRaCampaignState, IState>,
                                                    {type, search, notJoin, status, branch, saleFilter,
                                                notReview, branchFilter, pagination}) {
    try {
      commit(MutationTypeRaCampaign.AclCampaignLoading);
      const { page, limit } = pagination;
      const offset = (page - 1) * limit;
      const listJoinedShop = await raCampaign.getSubDealerCampaignList(
        type, search, notJoin, status, branch, saleFilter, notReview, branchFilter, limit, offset);
      if (listJoinedShop) {
        commit(MutationTypeRaCampaign.SetSubDealerCampaign, listJoinedShop);
      }
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    } finally {
      commit(MutationTypeRaCampaign.AclCampaignLoaded);
    }
  },
  async [ActionTypeRaCampaign.GetSubDealerCampaginDetail]({
    commit, dispatch
  }: ActionContext<IRaCampaignState, IState>,
                                                          {id, onSuccess, onFailure}) {
    try {
      commit(MutationTypeRaCampaign.AclCampaignLoading);
      const ShopDetail = await raCampaign.getSubDealerCampaginDetail(id);
      if (onSuccess) {
        onSuccess(ShopDetail);
      }
    } catch (error) {
      if (onFailure) {
        onFailure();
      }
      dispatch(ActionType.CatchException, error);
    } finally {
      commit(MutationTypeRaCampaign.AclCampaignLoaded);
    }
  },
  async [ActionTypeRaCampaign.PushReminder]({
    dispatch
  }: ActionContext<IRaCampaignState, IState>,
                                            {form, campaignId, createdBy, onSuccess}) {
    try {
      await raCampaign.pushReminder(form);
      await raCampaign.logReminder(campaignId, createdBy);
      if (onSuccess) {
        onSuccess();
      }
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    }
  },
  async [ActionTypeRaCampaign.GetBranchId]({
    dispatch
  }: ActionContext<IRaCampaignState, IState>,
                                           {id, onSuccess}) {
    try {
      const branchId = await raCampaign.getBranchId(id);
      if (onSuccess) {
        onSuccess(branchId);
      }
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    }
  },
};
