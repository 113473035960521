import { crudQuery, ICRUDQuery } from '../Core/query';

const active = `
branchId
    cityID
    createdAt
    createdBy
    customerAddress
    customerEmail
    customerName
    customerPhone
    customerType
    dealer {
      name
      id
    }
    dealerId
    groupId
    id
    product {
      name
      id
      modelNumber
      serialNumber
      deviceModel {
        barcode
      }
      deviceType {
        id
        name
      }
    }
    productId
    updatedAt
    updatedBy
    warrantyStatus
    error
`;

export const EWarranty = 'product_warranties';
// tslint:disable-next-line:no-empty-interface
interface IProductWarrantyQuery extends ICRUDQuery {
}
export const productEWarrantyListQuery: IProductWarrantyQuery = {
  ...crudQuery(EWarranty, active),
};
