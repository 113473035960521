import { StatusCode } from './Common';

export interface IKPI {
  id?: string;
  status?: StatusCode;
  createdAt?: string;
  updatedAt?: string;
  createdBy?: string;
  updatedBy?: string;
  address: string;
  code: string;
}
export function kpiDefault(): IKPI {
  return {
    address: '',
    code: ''
  };
}

export interface IKPIStatisticForm {
  mode: string;
  partnerIds: string;
  periodTime: {
    from: string,
    to: string
  };
}
export interface IExportLog {
  id: string;
  exportTechnicianBonus: any;
  requestPayload: IExportLogRequestPayload;
  responseData: any;
  kpiTemplateId: string;
  statusExport: 'completed' | 'loading' | 'error' | 'indexing';
  createdAt: string;
  kpiTemplate: {
    id: string;
    from: string;
    to: string;
  };
}
export interface IExportLogCounter {
  loading: number;
  indexing: number;
  completed: number;
  error: number;
  cancelled: number;
}
export interface IExportLogRequestPayload {
  id: string;
  otherCosts: Array<{
    case: number;
    item: string;
    result: number;
  }>;
  periodTime: {
    to: string;
    from: string;
  };
  customerServiceCase: number;
  customerServiceArchivedRate: number;
}
export enum ExportLogTemplateType {
  KPIPayment = 'kpi_payment',
  KPIStatistic = 'kpi_statistic',
  CallCenter = 'call_center',
  Warranty = 'warranty_claim',
  QC = 'qc',
  TechnicianBonus = 'technician_bonus',
  TechnicianInHouseOT = 'technician_overtime',
  MaintenancePaymentForTechnician = 'technician_maintenance_payment',
  MaintenancePaymentForPartner = 'partner_maintenance_payment'
}

export enum MaintenanceType {
  Technician = 'technician',
  Partner = 'partner'
}
