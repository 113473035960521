import { cloneDeep } from 'lodash';
import { IPaginationParams } from 'root/api/graphql/Core';
import { FormatDateFullTime } from 'root/helpers';
import { IState } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import { ActionTypeSaleInLogs, MutationTypeSaleInLogs } from '../Store/types';
import { SaleInHistoryLogsDetail } from './Components/HistoryLogsDetail';
import './styles.scss';

export enum LogsType {
  Create = 'create',
  Edit = 'edit',
  Import = 'import'
}
const enum FilterKey {
  Action = 'action',
  Status = 'status'
}
// enum SearchByKey {
//   DeviceCode = 'deviceCode',
//   ModelNumber = 'modelNumber',
//   DisplayModel = 'displayModel',
//   DeviceName = 'deviceName',
//   DeviceType = 'deviceType'
// }

@Component({
  template: require('./view.html'),
  props: {
    visible: Boolean,
    typeLogs: {
      type: String,
      default: 'rewardLogs'
    }
  },
  components: {
    'history-detail': SaleInHistoryLogsDetail
  },
  computed: {
    ...mapState({
      data: (state: IState) => state.saleInCampaign.saleInLogs.data,
      pagination: (state: IState) => state.saleInCampaign.saleInLogs.pagination,
      loading: (state: IState) => state.saleInCampaign.saleInLogs.loading,
    })
  }
})

export class SaleInHistoryLogs extends Vue {
  public get columns() {
    return [
      {
        label: 'code',
        formatter: (model: any) => {
          return this.$props.typeLogs === 'rewardLogs' && model && model.reward && model.reward.code ?
          model.reward.code :
          model && model.model && model.model.code ? model.model.code : '--';
        },
        fixed: true,
        width: 160,
      },
      ...this.$props.typeLogs === 'rewardLogs' ? [
        {
          label: 'reward name',
          formatter: (model: any) => {
            return model && model.reward && model.reward.rewardName ? model.reward.rewardName : '--';
          },
          fixed: true,
          width: 300,
        },
      ] : [
        {
          label: 'model number',
          formatter: (model: any) => {
            return model && model.model && model.model.modelNumber ? model.model.modelNumber : '--';
          },
          fixed: true,
          width: 300,
        },
      ],
      {
        label: 'updated by',
        formatter: (model: any) => {
          return model && model.creator && model.creator.name ? model.creator.name : '--';
        },
        width: 246,
      },
      {
        label: 'role',
        formatter: (model: any) => {
          return model && model.creator && model.creator.role
          && model.creator.role.name ? model.creator.role.name : '--';
        },
        width: 200,
      },
      {
        label: 'updated at',
        formatter: (model: any) => {
          return model && model.createdAt ? this.FormatDateFullTime(model.createdAt) : '--';
        },
        width: 200,
      },
      {
        label: 'action',
        prop: 'actionLogs',
        width: 160,
      }
    ];
  }
  public get ImportColumns() {
    return [
      {
        label: 'import code',
        width: 160,
        prop: 'importCode',
        fixed: true
      },
      {
        label: 'import by',
        formatter: (model: any) => {
          return model && model.creator && model.creator.name ? model.creator.name : '--';
        },
        width: 200
      },
      {
        label: 'role',
        formatter: (model: any) => {
          return model && model.creator && model.creator.role &&
           model.creator.role.name ? model.creator.role.name : '--';
        },
        width: 160
      },
      {
        label: 'import at',
        formatter: (model: any) => {
          return model && model.createdAt ? this.FormatDateFullTime(model.createdAt) : '--';
        },
        width: 180
      },
      {
        label: 'url',
        prop: 'url',
        width: 120
      },
      {
        label: 'status',
        prop: 'importStatus',
        formatter: (model: any) => {
          return model && model.importStatus && model.importStatus === 'done' ? 'successful' :
          model.importStatus && model.importStatus ? model.importStatus : '--';
        },
        labelStyle: (model: any) => {
          return model && model.importStatus ? `${model.importStatus === 'done' ?
          'success' : model.importStatus} text--capitalize` : '';
        },
        width: 150
      },
      {
        label: 'reason',
        prop: 'failedReasons',
        width: 250
      }
    ];
  }
  public get dataFilter() {
    const action = [
      {
        value: 'create',
        name: 'Create',
        key: 'create',
      },
      {
        value: 'edit',
        name: 'Edit',
        key: 'edit',
      }
    ];

    return [
      {
        key: FilterKey.Action,
        name: 'Action',
        value: action
      },
    ];
  }
  public get dataFilterImport() {
    const action = [
      {
        value: 'done',
        name: 'Successful',
        key: 'done',
      },
      {
        value: 'failed',
        name: 'Failed',
        key: 'failed',
      }
    ];

    return [
      {
        key: FilterKey.Status,
        name: 'Status',
        value: action
      },
    ];
  }
  public get selectedFilter() {
    const _filterParams = cloneDeep(this.filterParams);

    return [
      {
        key: FilterKey.Action,
        value: _filterParams[FilterKey.Action]
      },
    ];
  }
  public get selectedFilterImport() {
    const _filterParams = cloneDeep(this.filterParamsImport);

    return [
      {
        key: FilterKey.Status,
        value: _filterParams[FilterKey.Status]
      },
    ];
  }
  public FormatDateFullTime = FormatDateFullTime;
  public visibleDetail: boolean = false;
  public searchText: string = '';
  public detailId: string = '';
  public activeTab: string = 'action';
  public detailType: string = '';
  public loadingImport: boolean = false;
  public detailProps: any = {};
  public dataImport: any [] = [];
  public filterParams = {
    [FilterKey.Action]: [],
  };
  public filterParamsImport = {
    [FilterKey.Status]: [],
  };
  public paginationImports: IPaginationParams = {
    limit: 20,
    page: 1,
    total: null
  };
  public handlePaginationChange() {
    // 
  }
  public changeFilter(model) {
    const action = model.find((e) => e.key === FilterKey.Action);

    const nextFilter = {
      [FilterKey.Action]: action ? action.value : [],
    };
    this.filterParams = {
      ...cloneDeep(this.filterParams),
      ...nextFilter
    };
    this.fetchData();
  }
  public changeFilterImport(model) {
    const status = model.find((e) => e.key === FilterKey.Status);

    const nextFilter = {
      [FilterKey.Status]: status ? status.value : [],
    };
    this.filterParamsImport = {
      ...cloneDeep(this.filterParamsImport),
      ...nextFilter
    };
    this.fetchData();
  }
  public handleClick() {
    this.searchText = '';
    this.fetchData();
  }
  public clickDetail(id) {
    this.detailId = id;
    this.visibleDetail = true;
  }
  public closeDialogHistory() {
    this.detailId = '';
    this.detailType = '';
    this.detailProps = {};
  }
  public clickDetailImport(id) {
    this.detailType = 'import';
    this.detailProps = this.dataImport.find((it) => it.id === id);
    this.visibleDetail = true;
  }
  public handleSearch() {
    this.fetchData();
  }
  public handlePaginationChangeImport(value) {
    if (typeof value === 'number') {
      this.paginationImports = {
        ...this.paginationImports,
        limit: value
      };
    } else {
      this.paginationImports = {
        ...this.paginationImports,
        ...value
      };
    }
    this.fetchData();
  }
  public onClickUrl(url) {
    window.open(url, '_blank');
  }
  public fetchData() {
    const text = cloneDeep(this.searchText);
    const { action } = this.filterParams;
    const { status} = this.filterParamsImport;
    if (this.activeTab === 'import') {
      this.loadingImport = true;
      this.$store.dispatch(ActionTypeSaleInLogs.GetImportModelLogs, {
        typeLogs: ['si_model'],
        search: `%${text}%`,
        status: status && status.length ? status : null,
        pagination: this.paginationImports,
        onSuccess: (data) => {
          this.dataImport = data.imports;
          this.paginationImports = {
            ...this.paginationImports,
            total : data.imports_aggregate.aggregate.count
          };
          this.loadingImport = false;
        }
      });

      return;
    }
    this.$store.commit(MutationTypeSaleInLogs.ChangeOrderBy, {
      createdAt: 'desc'
    });
    this.$store.commit(MutationTypeSaleInLogs.FilterChange, {
      _and: {
        mode: 'special',
        data: {
          type: {
            _eq: this.$props.typeLogs === 'rewardLogs' ? 'reward' : 'model'
          },
          action: {
            _in: action && action.length ? action :
             this.$props.typeLogs === 'rewardLogs' ? ['create', 'edit'] : ['create', 'edit']
          },
          ...text && text.trim().length > 0 ? {
            _or: [
              {
                reward: {
                  code: {
                    _ilike: `%${text}%`
                  }
                }
              },
              {
                reward: {
                  rewardName: {
                    _ilike: `%${text}%`
                  }
                }
              },
              {
                model: {
                  code: {
                    _ilike: `%${text}%`
                  }
                }
              },
              {
                model: {
                  modelNumber: {
                    _ilike: `%${text}%`
                  }
                }
              },
              {
                model: {
                  displayModel: {
                    _ilike: `%${text}%`
                  }
                }
              },
              {
                creator: {
                  name: {
                    _ilike: `%${text}%`
                  }
                }
              }
            ]
          } : {}
        }
      }
    });
    this.$store.dispatch(ActionTypeSaleInLogs.FilterNoCache);
  }
  public openDialog() {
    this.fetchData();
  }
  public closeDialog() {
    this.$emit('update:visible', false);
    this.filterParams = {
      [FilterKey.Action]: []
    };
    this.filterParamsImport = {
      [FilterKey.Status]: []
    };
    this.searchText = '';
  }
}
