import { crudQuery, ICRUDQuery } from '../Core/query';

const exampleModel = `
id
parentId
name
type
status`;

export const EXAMPLE_PREFIX = 'example';
// tslint:disable-next-line:no-empty-interface
interface IExampleQuery extends ICRUDQuery {
  //
}
export const exampleQueries: IExampleQuery = {
  ...crudQuery(EXAMPLE_PREFIX, exampleModel),
};
