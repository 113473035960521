// import { PermissionType } from 'root/models';
import { PermissionType } from 'root/models';
import { RouteConfig } from 'vue-router';
import { DealerProduct } from './DealerProducts';
import { DealerProductDetail } from './DealerProducts/components/DealerProductDetail';
// import { ProductManageDetail } from './ProductManageDetail';
// import { ProductManageDetail } from './ProductManageDetail';
// import { ProductManageEditor } from './ProductManageEditor';
// import { ProductList } from './ProductList';
import { ProductManageContainer } from './ProductManageContainer';
import { ProductRegistrationList } from './ProductRegistation';
import { StatisticProducts } from './ProductStatistic';
import { SubDealerProduct } from './SubDealerProduct';

export enum ProductManageRouterPath {
  Default = '/products',
  Create = '/products/:productType/create',
  Detail = '/products/:productType/:productId/detail',
  Edit = '/products/:productType/:productId/edit',
  ListProduct = '/products',
  ProductRegistation = '/products/registation',
  DealerProduct = '/products/dealer',
  SubDealerProduct = '/products/sub-dealer',
  System = '/products/system_products',
  Technician = '/products/technicians',
  Profile = '/products/profile',
  DealerProductDetail = '/dealer-product/detail/:id',
  StatisticProduct = '/statistic'
}
export enum ProductManageRouterName {
  Create = 'product-create',
  Edit = 'product-edit',
  Detail = 'product-detail',
  Profile = '/products/profile',
  ListProduct = 'ListProduct',
  ProductRegistation = 'ProductRegistation',
  DealerProduct = 'DealerProduct',
  SubDealerProduct = 'SubDealerProduct',
  System = 'system_products',
  Technician = 'technicians',
  DealerProductDetail = 'dealer-product/detail',
  StatisticProduct = 'StatisticProducts'
}
export enum ProductRegistationTab {
  DealerProduct = 'DealerProducts',
  SubDealerProduct = 'SubDealerProducts',
  ProductList = 'ProductList',
  StatisticProduct = 'StatisticProducts'
}

export const productRouter: RouteConfig = {
  path: '/products',
  component: ProductManageContainer,
  redirect: ProductManageRouterPath.DealerProduct,
  meta: {
    permission: PermissionType.Product
  },
  children: [
    {
      path: ProductManageRouterPath.DealerProduct,
      component: DealerProduct,
      name: ProductManageRouterName.DealerProduct,
      meta: {
        permission: PermissionType.Product
      }
    },
    {
      path: ProductManageRouterPath.SubDealerProduct,
      component: SubDealerProduct,
      name: ProductManageRouterName.SubDealerProduct,
      meta: {
        permission: PermissionType.Product
      }
    },
    {
      path: ProductManageRouterPath.ProductRegistation,
      component: ProductRegistrationList,
      name: ProductManageRouterName.ProductRegistation,
      meta: {
        permission: PermissionType.Product
      }
    },
    {
      path: ProductManageRouterPath.DealerProductDetail,
      component: DealerProductDetail,
      name: ProductManageRouterName.DealerProductDetail,
      meta: {
        permission: PermissionType.Product
      }
    },
    {
      path: ProductManageRouterPath.StatisticProduct,
      component: StatisticProducts,
      name: ProductManageRouterName.StatisticProduct,
      meta: {
        permission: PermissionType.Product
      }
    },
  ]
};
