export enum ActionTypeAccountStaff {
  GET_LIST_ACCOUNTSTAFF = 'getListAccountStaff',
  GET_STAFF_LIST = 'getListStaff',
  CREATE_STAFF = 'createStaff',
  GET_STAFF_DETAIL = 'getStaffDetail'
}
export enum MutationTypeAccountStaff {
  GetListAccountStaff = 'getListAccountStaff',
  AccountStaffLoading = 'accountStaffLoading',
  AccountStaffLoaded = 'accountStaffLoaded',
  SetPagination= 'accountStaffSetPagination',
  PaginationChange = 'accountStaffPaginationChange'
}
