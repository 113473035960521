import { ISimpleUser } from 'root/models';

export enum IWarningNoteStatus {
  Resolved = 'resolved',
  NeedToResolve = 'need_to_resolve'
}

export interface IWarningNote {
  id: number;
  issuedNote: string;
  issuedTime: string;
  resolvedNote?: string;
  resolvedTime?: string;
  status: IWarningNoteStatus;
  issuedUser: ISimpleUser;
  resolvedUser?: ISimpleUser;
}
