import { TabPane } from 'element-ui';
import moment from 'moment';
import { CustomCampaignRouterName, CustomCampaignTab } from 'root/admin/CustomCampaign';
import { SystemRoleAlias } from 'root/admin/User/Store/types';
import BarChart from 'root/helpers/bar-chart';
import LineChart from 'root/helpers/line-chart';
import { IBreadcrumb, IUser } from 'root/models';
import { IState, MutationType } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import { ActionTypeRaCampaign } from '../../Store/types';
import './styles.scss';

@Component({
  template: require('./view.html'),
  components: {
    'bar-chart': BarChart,
    'line-chart': LineChart,
  },
  computed: {
    ...mapState({
      authUser: (state: IState) => state.global.authUser,
    })
  }
})

export class RaCampaignStatistic extends Vue {
  public get activeTab() {
    return CustomCampaignTab.RAStatistic;
  }

  public set activeTab(_value: string) {
    //
  }
  public get isSalePlanning() {
    if (this.authUser.superAdmin || this.authUser.role.alias === SystemRoleAlias.Admin
      || this.authUser.role.alias === SystemRoleAlias.SaleAdmin) {
      return true;
    }

    return false;
  }
  public get optionTimeLineBar() {
    return [
      {
        key: 'All',
        value: 'All',
        name: 'All',
      },
      {
        key: '03/2022',
        value: '03/2022',
        name: '03/2022',
      },
      {
        key: '04/2022',
        value: '04/2022',
        name: '04/2022',
      },
      {
        key: '05/2022',
        value: '05/2022',
        name: '05/2022',
      },
      {
        key: '06/2022',
        value: '06/2022',
        name: '06/2022',
      }
    ];
  }
  public get chartLineData() {
    // tslint:disable-next-line: max-line-length
    return {
      hoverBackgroundColor: 'red',
      hoverBorderWidth: 10,
      labels: this.cloneLabelLine.length ? this.cloneLabelLine : this.label,
      datasets: [
        {
          label: 'Shops',
          borderColor: '#03A1E4',
          pointBackgroundColor: '#03A1E4',
          backgroundColor: 'rgba(0, 0, 0, 0)',
          // data: this.dataLineChart,
          data: this.dataShopsRequest
        },
        {
          label: 'Sub-Dealer',
          borderColor: '#24A148',
          pointBackgroundColor: '#24A148',
          backgroundColor: 'rgba(0, 0, 0, 0)',
          // data: this.dataLineChart,
          data: this.dataSubRequest
        }
      ]
    };
  }
  public get chartBarModelData() {
    const labels = ['FTKA', 'FTKB', 'FTKC/FTKY', 'Other'];

    return {
      labels,
      datasets: [
        {
          backgroundColor: ['#FFB14D', '#3DBB61', '#BB8EFF', '#35B3E9'],
          barPercentage: 100,
          barThickness: 20,
          categoryPercentage: 0.5,
          data: this.modelQuantity
        },
      ]
    };
  }
  public get chartBarBranchData() {
    const labels = this.labelBranch;

    return {
      labels,
      datasets: [
        {
          label: 'Total',
          backgroundColor: ['#FCD0D3', '#FFD2BB', '#FFEDBB', '#B8EFB7', '#B8B7FF', '#B8B7FF', '#DCB9FF',
            '#FCD0D3', '#FFD2BB', '#FFEDBB', '#B8EFB7', '#B8B7FF', '#B8B7FF', '#DCB9FF'],
          barPercentage: 100,
          barThickness: 50,
          data: this.dataNotJoinedBranchchart,
          categoryPercentage: 10,
          stack: 1
        },
        {
          label: 'Joined',
          backgroundColor: ['#FCD0D3', '#FFD2BB', '#FFEDBB', '#B8EFB7', '#B8B7FF', '#B8B7FF', '#DCB9FF',
            '#FCD0D3', '#FFD2BB', '#FFEDBB', '#B8EFB7', '#B8B7FF', '#B8B7FF', '#DCB9FF'],
          barPercentage: 100,
          barThickness: 50,
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          categoryPercentage: 10,
          stack: 1
        },
        {
          label: 'Sub-Dealer',
          backgroundColor: ['#FB4B53', '#FF9761', '#FFC934', '#2ACA25', '#4279FB', '#5352FF', '#A142FF',
            '#FF767C', '#FF9761', '#FFD359', '#49CD45', '#4279FB', '#5352FF', '#B569FF'],
          barPercentage: 100,
          barThickness: 50,
          data: this.dataJoinedSubBranchchart,
          categoryPercentage: 10,
          stack: 1
        },
        {
          label: 'Dealer',
          backgroundColor: ['#FF767C', '#FFB893', '#FFE18D', '#83D981', '#93B3FF', '#9998FF', '#D09FFF',
            '#FF767C', '#FF9761', '#FFD359', '#49CD45', '#4279FB', '#5352FF', '#B569FF'],
          barPercentage: 100,
          barThickness: 50,
          data: this.dataJoinedBranchchart,
          categoryPercentage: 10,
          stack: 1
        },
      ]
    };
  }
  public get chartBarData() {

    return {
      labels: this.cloneLabelRequest.length ? this.cloneLabelRequest : this.label,
      datasets: [
        {
          label: 'Waiting for review',
          backgroundColor: '#2B85FF',
          barPercentage: 1.0,
          barThickness: 9,
          categoryPercentage: 0.5,
          data: this.dataReview
        },
        {
          label: 'Waiting for reup',
          backgroundColor: '#C9BEFF',
          barPercentage: 1.0,
          barThickness: 9,
          categoryPercentage: 0.5,
          data: this.dataReup
        },
        {
          label: 'Completed',
          backgroundColor: '#B1E5FC',
          barPercentage: 1.0,
          barThickness: 9,
          categoryPercentage: 0.5,
          data: this.dataApprove
        },
        {
          label: 'Reject',
          backgroundColor: '#FFBC99',
          barPercentage: 1.0,
          barThickness: 9,
          categoryPercentage: 0.5,
          data: this.dataReject
        }
      ]
    };
  }
  public statistic: any = null;
  public loading: boolean = false;
  public label: any[] = [];
  public dataLineChart: any[] = [];
  public dataShopsRequest: any[] = [];
  public dataSubRequest: any[] = [];
  public dataReview: any[] = [];
  public dataReup: any[] = [];
  public dataApprove: any[] = [];
  public dataReject: any[] = [];
  public modelQuantity: any[] = [];
  public branchQuantity: any[] = [];
  public labelBranch: any[] = [];
  public branchIds: any[] = [];
  public dataJoinedSubBranchchart: any[] = [];
  public dataJoinedBranchchart: any[] = [];
  public dataTotalBranchchart: any[] = [];
  public dataNotJoinedBranchchart: any[] = [];
  public lineBarSelect: string = 'All';
  public requestBarSelect: string= 'All';
  public cloneLabelLine: any[] = [];
  public cloneLabelRequest: any[] = [];
  public chartLineOptions: object = {
    legend: {
      display: true,
      align: 'end',
      labels: {
        usePointStyle: true,
        pointStyle: 'circle'
      },
    },
    scales: {
      xAxes: [{
        scaleLabel: {
          display: true,
          labelString: 'Campaign Duration'
        }
      }],
      yAxes: [{
        scaleLabel: {
          display: true,
          labelString: 'New Request'
        }
      }]
    },
    title: {
      display: true,
      text: 'NEW JOIN REQUEST (DAY)',
      align: 'start',
      fontSize: 12,
      fontStyle: 'bold'
    },
    animation: {
      animateScale: true
    },
    elements: {
      line: {
        tension: 0,
      }
    },
    responsive: true,
    maintainAspectRatio: false,
  };
  public chartBarOptions: object = {
    responsive: true,
    scales: {
      xAxes: [{
        stacked: true,
        scaleLabel: {
          display: true,
          labelString: 'Campaign Duration'
        }
      }],
      yAxes: [{
        stacked: true,
        scaleLabel: {
          display: true,
          labelString: 'Quantity'
        }
      }]
    },
    legend: {
      align: 'end',
      labels: {
        usePointStyle: true,
        pointStyle: 'circle'
      },
    },
    tooltips: {
      mode: 'label',
      callbacks: {
        label: (t, d) => {
          const dstLabel = d.datasets[t.datasetIndex].label;
          const yLabel = t.yLabel;

          return `${dstLabel} : ${yLabel}`;
        },
      }
    },
    title: {
      display: true,
      text: 'STATUS',
      align: 'start',
      fontSize: 12,
      fontStyle: 'bold'
    },
    maintainAspectRatio: false,
  };
  public chartBarModelOptions: object = {
    responsive: true,
    scales: {
      xAxes: [{
        scaleLabel: {
          display: true,
          labelString: 'Model'
        }
      }],
      yAxes: [{
        scaleLabel: {
          display: true,
          labelString: 'Quantity'
        },
        ticks: {
          beginAtZero: true,
        }
      }]
    },
    legend: {
      display: false
    },
    title: {
      display: true,
      text: 'MODEL',
      align: 'start',
      fontSize: 12,
      fontStyle: 'bold'
    },
    maintainAspectRatio: false,
  };
  public chartBarBranchOptions: object = {
    responsive: true,
    scales: {
      xAxes: [{
        scaleLabel: {
          display: true,
          labelString: 'Branch'
        }
      }],
      yAxes: [{
        staked: true,
        scaleLabel: {
          display: true,
          labelString: 'Quantity'
        },
        ticks: {
          beginAtZero: true,
        }
      }]
    },
    legend: {
      display: false
    },
    // tooltips: {
    //   enabled: false
    // },
    tooltips: {
      mode: 'label',
      callbacks: {
        label: (t, d) => {
          const dstLabel = d.datasets[t.datasetIndex].label;
          const yLabel = t.yLabel;
          if (t.datasetIndex === 0) {

            return `${dstLabel} :  ${yLabel + d.datasets[2].data[t.index] + d.datasets[3].data[t.index]}`;
          }
          if (t.datasetIndex === 1) {

            return `${dstLabel} :  ${d.datasets[2].data[t.index] + d.datasets[3].data[t.index]}`;
          }

          return `${dstLabel} : ${yLabel}`;
        },
      }
    },
    title: {
      display: true,
      text: 'Branch',
      align: 'start',
      fontSize: 12,
      fontStyle: 'bold'
    },
    maintainAspectRatio: false,
  };
  public authUser: IUser;
  public changeTab(tab: TabPane) {
    switch (tab.name) {
    case CustomCampaignTab.RAParticipant:
      this.$router.push({
        name: CustomCampaignRouterName.RACampaign
      });
      break;
    case CustomCampaignTab.RAConfiguration:
      this.$router.push({
        name: CustomCampaignRouterName.RAConfiguration
      });
      break;
    case CustomCampaignTab.RAManageExport:
      this.$router.push({
        name: CustomCampaignRouterName.RAManageExport
      });
      break;
    case CustomCampaignTab.RAQualifiedShops:
      this.$router.push({
        name: CustomCampaignRouterName.RAQualifiedShops
      });
      break;
    case CustomCampaignTab.RAShops:
      this.$router.push({
        name: CustomCampaignRouterName.RAShops
      });
      break;
    case CustomCampaignTab.SubDealerRequest:
      this.$router.push({
        name: CustomCampaignRouterName.SubDealerRequest
      });
      break;
    case CustomCampaignTab.ConfirmationCode:
      this.$router.push({
        name: CustomCampaignRouterName.ConfirmationCode
      });
      break;
    case CustomCampaignTab.SubDealerCampaign:
      this.$router.push({
        name: CustomCampaignRouterName.SubDealerCampaign
      });
      break;
    default:
      break;
    }
  }
  public changeTimeLinBar(value) {
    switch (value) {
    case '03/2022' :
      const _clone = this.enumerateDays(moment('2022-03-01T00:00:00+00:00'), moment('2022-04-01T00:00:00+00:00'));
      this.cloneLabelLine =
      this.enumerateDaysBetweenDates(moment('2022-03-01T00:00:00+00:00'), moment('2022-04-01T00:00:00+00:00'));
      this.sortDataLine(_clone);
      break;
    case '04/2022' :
      const _clone1 = this.enumerateDays(moment('2022-04-01T00:00:00+00:00'), moment('2022-05-01T00:00:00+00:00'));
      this.cloneLabelLine =
      this.enumerateDaysBetweenDates(moment('2022-04-01T00:00:00+00:00'), moment('2022-05-01T00:00:00+00:00'));
      this.sortDataLine(_clone1);
      break;
    case '05/2022' :
      const _clone2 = this.enumerateDays(moment('2022-05-01T00:00:00+00:00'), moment('2022-06-01T00:00:00+00:00'));
      this.cloneLabelLine =
      this.enumerateDaysBetweenDates(moment('2022-05-01T00:00:00+00:00'), moment('2022-06-01T00:00:00+00:00'));
      this.sortDataLine(_clone2);
      break;
    case '06/2022' :
      const _clone3 = this.enumerateDays(moment('2022-06-01T00:00:00+00:00'), moment('2022-07-01T00:00:00+00:00'));
      this.cloneLabelLine =
      this.enumerateDaysBetweenDates(moment('2022-06-01T00:00:00+00:00'), moment('2022-07-01T00:00:00+00:00'));
      this.sortDataLine(_clone3);
      break;
    case 'All' :
      this.cloneLabelLine =
          this.enumerateDaysBetweenDates(moment(this.statistic.configCampaign[0].aclCampaignConfig.uploadStartDate),
          moment(this.statistic.configCampaign[0].aclCampaignConfig.uploadEndDate));
      const _cloneAll = this.enumerateDays(moment(this.statistic.configCampaign[0].aclCampaignConfig.uploadStartDate),
        moment(this.statistic.configCampaign[0].aclCampaignConfig.uploadEndDate));
      this.sortDataLine(_cloneAll);
      break;
    }
  }
  public changeTimeRequest(value) {
    switch (value) {
    case '03/2022' :
      const _clone = this.enumerateDays(moment('2022-03-01T00:00:00+00:00'), moment('2022-04-01T00:00:00+00:00'));
      this.cloneLabelRequest =
      this.enumerateDaysBetweenDates(moment('2022-03-01T00:00:00+00:00'), moment('2022-04-01T00:00:00+00:00'));
      this.sortDataRequest(_clone);
      break;
    case '04/2022' :
      const _clone1 = this.enumerateDays(moment('2022-04-01T00:00:00+00:00'), moment('2022-05-01T00:00:00+00:00'));
      this.cloneLabelRequest =
      this.enumerateDaysBetweenDates(moment('2022-04-01T00:00:00+00:00'), moment('2022-05-01T00:00:00+00:00'));
      this.sortDataRequest(_clone1);
      break;
    case '05/2022' :
      const _clone2 = this.enumerateDays(moment('2022-05-01T00:00:00+00:00'), moment('2022-06-01T00:00:00+00:00'));
      this.cloneLabelRequest =
      this.enumerateDaysBetweenDates(moment('2022-05-01T00:00:00+00:00'), moment('2022-06-01T00:00:00+00:00'));
      this.sortDataRequest(_clone2);
      break;
    case '06/2022' :
      const _clone3 = this.enumerateDays(moment('2022-06-01T00:00:00+00:00'), moment('2022-07-01T00:00:00+00:00'));
      this.cloneLabelRequest =
      this.enumerateDaysBetweenDates(moment('2022-06-01T00:00:00+00:00'), moment('2022-07-01T00:00:00+00:00'));
      this.sortDataRequest(_clone3);
      break;
    case 'All' :
      this.cloneLabelRequest =
          this.enumerateDaysBetweenDates(moment(this.statistic.configCampaign[0].aclCampaignConfig.uploadStartDate),
          moment(this.statistic.configCampaign[0].aclCampaignConfig.uploadEndDate));
      const _cloneAll = this.enumerateDays(moment(this.statistic.configCampaign[0].aclCampaignConfig.uploadStartDate),
        moment(this.statistic.configCampaign[0].aclCampaignConfig.uploadEndDate));
      this.sortDataRequest(_cloneAll);
      break;
    }
  }
  public handleExport() {
    this.$msgbox({
      title: this.$t('export_confirm').toString(),
      message: `Do you want to export all?`,
      showCancelButton: true,
      confirmButtonText: 'Confirm',
      cancelButtonText: 'Cancel',
      beforeClose: (action, instance, done) => {
        if (action === 'confirm') {
          instance.confirmButtonLoading = true;
          instance.confirmButtonText = 'Loading...';
          this.exportStatistic({ instance, done });
        } else {
          done();
        }

        return;
      }
    })
        .then(() => {
          this.$message({
            type: 'success',
            message: this.$t('message.export_success').toString()
          });
        })
        .catch(() => {
          // no handle
        });
  }

  public exportStatistic(model) {
    this.$store.dispatch(ActionTypeRaCampaign.ExportStatistic, {
      id: this.authUser.id,
      onSuccess: () => {
        this.fetchData();
        model.instance.confirmButtonLoading = false;
        model.instance.confirmButtonText = 'Confirm';
        model.done();
      },
      onFailure: () => {
        model.instance.confirmButtonLoading = false;
        model.instance.confirmButtonText = 'Cancel';
      }
    });
  }
  public enumerateDaysBetweenDates(startDate, endDate) {
    const dates = [];

    // startDate = startDate.add(1, 'days');

    while (startDate.isBefore(endDate)) {
      dates.push(moment(startDate).format('DD/MM/YYYY'));
      startDate = startDate.add(1, 'days');
    }

    return dates;
  }
  public enumerateDays(startDate, endDate) {
    const dates = [];

    // startDate = startDate.add(1, 'days');

    while (startDate.isBefore(endDate)) {
      dates.push(moment(startDate));
      startDate = startDate.add(1, 'days');
    }

    return dates;
  }
  public fetchData() {
    this.loading = true;
    this.$store.dispatch(ActionTypeRaCampaign.GetStatistic, {
      onSuccess: (statistic) => {
        this.statistic = statistic;
        this.label =
          this.enumerateDaysBetweenDates(moment(this.statistic.configCampaign[0].aclCampaignConfig.uploadStartDate),
          moment(this.statistic.configCampaign[0].aclCampaignConfig.uploadEndDate));
        const _clone = this.enumerateDays(moment(this.statistic.configCampaign[0].aclCampaignConfig.uploadStartDate),
          moment(this.statistic.configCampaign[0].aclCampaignConfig.uploadEndDate));
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < _clone.length; i++) {
          this.dataShopsRequest.push(0);
          this.dataSubRequest.push(0);
          this.dataReview.push(0);
          this.dataReup.push(0);
          this.dataApprove.push(0);
          this.dataReject.push(0);
        }
        _clone.map((day, index) => {
          const _toDay = moment(day);
          const _nextDay = moment(day).add(1, 'days');
          const _cloneAllRequest = [];
          const _cloneAllRequestJoin = [];
          const _cloneRequestPerDay = [];
          this.statistic.requests.map((request) => {
            if (moment(request.createdAt).isAfter(moment(_toDay))
            && moment(request.createdAt).isBefore(moment(_nextDay))) {
              _cloneRequestPerDay.push(request);
            }
          });
          const _requestPerDayUnique = [..._cloneRequestPerDay];
          const _requestPerDayUniqueJoin = [..._cloneRequestPerDay];
          _cloneRequestPerDay.map((it) => {
            _requestPerDayUnique.map((req, ind) => {
              if (it.campaignId === req.campaignId && moment(it.createdAt).isAfter(moment(req.createdAt))) {
                _requestPerDayUnique.splice(ind, 1);
              }
            });
          });
          _cloneRequestPerDay.map((it) => {
            _requestPerDayUniqueJoin.map((req, ind) => {
              if (it.campaignId === req.campaignId && moment(it.createdAt).isBefore(moment(req.createdAt))) {
                _requestPerDayUniqueJoin.splice(ind, 1);
              }
            });
          });
          _cloneAllRequest.push(..._requestPerDayUnique);
          _cloneAllRequestJoin.push(..._requestPerDayUniqueJoin);
          _cloneAllRequestJoin.map((requestClone) => {
            if (requestClone.dealer && requestClone.dealer.role.alias === 'daikin_dealer'
              && requestClone.requestStatus === 'waiting_for_review') {
              this.dataShopsRequest[index] += 1;
            }
            if (requestClone.dealer && requestClone.dealer.role.alias === 'sub_dealer'
              && requestClone.requestStatus === 'waiting_for_review') {
              this.dataSubRequest[index] += 1;
            }
          });
          _cloneAllRequest.map((requestClone) => {
            if (requestClone.requestStatus === 'waiting_for_review') {
              this.dataReview[index] += 1;
            }
            if (requestClone.requestStatus === 'request_re_up') {
              this.dataReup[index] += 1;
            }
            if (requestClone.requestStatus === 'approved') {
              this.dataApprove[index] += 1;
            }
            if (requestClone.requestStatus === 'rejected') {
              this.dataReject[index] += 1;
            }
          });
        });
        this.modelQuantity = [];
        this.labelBranch = [];
        this.modelQuantity = [
          this.statistic.modelFTKA.aggregate.count,
          this.statistic.modelFTKB1.aggregate.count + this.statistic.modelFTKB2.aggregate.count,
          this.statistic.modelFTKC1.aggregate.count + this.statistic.modelFTKC2.aggregate.count
          + this.statistic.modelFTKY1.aggregate.count + this.statistic.modelFTKY2.aggregate.count,
          this.statistic.totalModel.aggregate.count * 3 - this.statistic.modelFTKA.aggregate.count
          - this.statistic.modelFTKB1.aggregate.count - this.statistic.modelFTKB2.aggregate.count
          - this.statistic.modelFTKC1.aggregate.count - this.statistic.modelFTKC2.aggregate.count
          - this.statistic.modelFTKY1.aggregate.count - - this.statistic.modelFTKY2.aggregate.count
        ];
        this.statistic.labelBranch.map((branch) => {
          this.labelBranch.push(branch.name);
          this.branchIds.push(branch.id);
          this.dataJoinedSubBranchchart.push(0);
          this.dataJoinedBranchchart.push(0);
          this.dataTotalBranchchart.push(0);
          this.dataNotJoinedBranchchart.push(0);
        });
        this.branchIds.map((it, index) => {
          this.$store.dispatch(ActionTypeRaCampaign.GetDealerByBranch, {
            name: it,
            onSuccess: (result) => {
              this.dataJoinedSubBranchchart.splice(index, 1, result.joinSub.aggregate.count);
              this.dataJoinedBranchchart.splice(index, 1, result.joinDealer.aggregate.count);
              this.dataTotalBranchchart.splice(index, 1, result.total.aggregate.count);
              this.dataNotJoinedBranchchart.splice(index, 1,
                result.total.aggregate.count - result.joinSub.aggregate.count - result.joinDealer.aggregate.count);
            }
          });
        });
        this.loading = false;
      }
    });
  }
  public sortDataLine(_clone) {
    this.dataShopsRequest = [];
    this.dataSubRequest = [];
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < _clone.length; i++) {
      this.dataShopsRequest.push(0);
      this.dataSubRequest.push(0);
    }
    _clone.map((day, index) => {
      const _toDay = moment(day);
      const _nextDay = moment(day).add(1, 'days');
      const _cloneAllRequest = [];
      const _cloneRequestPerDay = [];
      this.statistic.requests.map((request) => {
        if (moment(request.createdAt).isAfter(moment(_toDay))
        && moment(request.createdAt).isBefore(moment(_nextDay))) {
          _cloneRequestPerDay.push(request);
        }
      });
      const _requestPerDayUnique = [..._cloneRequestPerDay];
      _cloneRequestPerDay.map((it) => {
        _requestPerDayUnique.map((req, ind) => {
          if (it.campaignId === req.campaignId && moment(it.createdAt).isAfter(moment(req.createdAt))) {
            _requestPerDayUnique.splice(ind, 1);
          }
        });
      });
      _cloneAllRequest.push(..._requestPerDayUnique);
      _cloneAllRequest.map((requestClone) => {
        if (requestClone.dealer && requestClone.dealer.role.alias === 'daikin_dealer'
          && requestClone.requestStatus === 'waiting_for_review') {
          this.dataShopsRequest[index] += 1;
        }
        if (requestClone.dealer && requestClone.dealer.role.alias === 'sub_dealer'
          && requestClone.requestStatus === 'waiting_for_review') {
          this.dataSubRequest[index] += 1;
        }
      });
    });
  }
  public sortDataRequest(_clone) {
    this.dataReview = [];
    this.dataReup = [];
    this.dataApprove = [];
    this.dataReject = [];
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < _clone.length; i++) {
      this.dataReview.push(0);
      this.dataReup.push(0);
      this.dataApprove.push(0);
      this.dataReject.push(0);
    }
    _clone.map((day, index) => {
      const _toDay = moment(day);
      const _nextDay = moment(day).add(1, 'days');
      const _cloneAllRequest = [];
      const _cloneRequestPerDay = [];
      this.statistic.requests.map((request) => {
        if (moment(request.createdAt).isAfter(moment(_toDay))
        && moment(request.createdAt).isBefore(moment(_nextDay))) {
          _cloneRequestPerDay.push(request);
        }
      });
      const _requestPerDayUnique = [..._cloneRequestPerDay];
      _cloneRequestPerDay.map((it) => {
        _requestPerDayUnique.map((req, ind) => {
          if (it.campaignId === req.campaignId && moment(it.createdAt).isAfter(moment(req.createdAt))) {
            _requestPerDayUnique.splice(ind, 1);
          }
        });
      });
      _cloneAllRequest.push(..._requestPerDayUnique);
      _cloneAllRequest.map((requestClone) => {
        if (requestClone.requestStatus === 'waiting_for_review') {
          this.dataReview[index] += 1;
        }
        if (requestClone.requestStatus === 'request_re_up') {
          this.dataReup[index] += 1;
        }
        if (requestClone.requestStatus === 'approved') {
          this.dataApprove[index] += 1;
        }
        if (requestClone.requestStatus === 'rejected') {
          this.dataReject[index] += 1;
        }
      });
    });
  }
  protected mounted() {
    this.$nextTick(() => {
      const breadcrumb: IBreadcrumb[] = [
        {
          label: 'title.campaign',
          path: null,
          current: false
        },
        {
          label: 'title.ra_campaign',
          path: null,
          current: true
        }
      ];
      this.$store.commit(MutationType.SetBreadcrumb, breadcrumb);

      this.fetchData();
    });
  }
}
