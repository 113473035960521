import { DocumentNode } from 'graphql';
import gql from 'graphql-tag';
import { crudQuery, ICRUDQuery } from '../Core/query';
const saleInCampaignModel = `
  id
  sicInformation {
    id
    pin
    rewardType
    descriptionType
    countdownDays
    rewardStartTime
    rewardEndTime
  }
  icon image
  campaignCode
  title
  dealerTypes
  campaignType
  dealerRoles
  targetBranch
  targetBranches {
    branch {
      id
      name
    }
  }
  startDate
  endDate
  description
  isSpecial
  campaignStatus
  createdUser{
    name
  }
  updatedUser{
    name
  }
  createdAt
  updatedAt
`;
const saleInProductModel = `
  id
  code
  modelNumber
  displayModel
  deviceName
  deviceType {
    id
    value
  }
  createdAt
  updatedAt
  creator {
    name
  }
  updater {
    name
  }
`;
const saleInRewardModel = `
  id code createdAt updatedAt status rewardName
  rewardType unit price icon
  creator { name } updater { name }
`;
const saleInLogsModel = `
id
creator {
  name
  role {name}
}
createdAt
action
updatedKeys
importId
import {
  createdAt
  creator {
    name
    role {name}
  }
  url
  importStatus
  failedReasons
}
reward {
  code
  rewardName
}
model {
  code
  modelNumber
  displayModel
  deviceType {value}
  deviceName
}`;

// tslint:disable-next-line:no-empty-interface
interface ISaleInCampaignQuery extends ICRUDQuery {
  GET_DEAlER_TYPES: DocumentNode;
  GET_CONDITION_METADATA: DocumentNode;
  CHECK_CAMPAIGN_CODE_EXIST: DocumentNode;
  GET_PARTICIPANT_LIST: DocumentNode;
  SALE_IN_PRODUCT_CREATE: DocumentNode;
  SALE_IN_PRODUCT_UPDATE: DocumentNode;
  SALE_IN_PRODUCT_UPSERT: DocumentNode;
  SALE_IN_REWARD_CREATE: DocumentNode;
  SALE_IN_REWARD_UPDATE: DocumentNode;
  GET_SALE_IN_CAMPAIGN_DETAIL: DocumentNode;
  GET_SALE_IN_CAMPAIGN_DETAIL_PARTICIPANT_LIST: DocumentNode;
  GET_PINED_CAMPAIGN_LIST: DocumentNode;
  PIN_SALE_IN_CAMPAIGN: DocumentNode;
  CHANGE_CAMPAIGN_STATUS: DocumentNode;
  CREATE_SALE_IN_CAMPAIGN: DocumentNode;
  UPDATE_SALE_IN_CAMPAIGN: DocumentNode;
  IMPORT_SALE_IN_CAMPAIGN: DocumentNode;
  GET_IMPORT_MODEL_LOGS: DocumentNode;
  CHECK_MODEL_NUMBER_EXITS: DocumentNode;
  CHECK_REWARD_CODE_EXITS: DocumentNode;
  GET_HISTORY_IMPORT_MODEL_LOGS: DocumentNode;
  GET_QUANTITY_LIST_PARTICIPANT: DocumentNode;
  GET_REWARD_LIST_PARTICIPANT: DocumentNode;
  SEND_CONFIRM: DocumentNode;
  GET_IMPORT_LOG_ID: DocumentNode;
  GET_SEND_CONFIRM_LIST_FORM: DocumentNode;
  CHANGE_PARTICIPANT: DocumentNode;
  GET_PARTICIPANT_LOGS: DocumentNode;
  UPDATE_CAMPAIGN_INACTIVE: DocumentNode;
  saleInProduct: ICRUDQuery;
  saleInReward: ICRUDQuery;
  saleInLogs: ICRUDQuery;
}
export const saleInCampaignQuery: ISaleInCampaignQuery = {
  ...crudQuery('campaigns', saleInCampaignModel),
  saleInProduct: crudQuery('si_campaign_models', saleInProductModel),
  saleInReward: crudQuery('si_campaign_rewards', saleInRewardModel),
  saleInLogs: crudQuery('si_campaign_logs', saleInLogsModel),
  GET_DEAlER_TYPES: gql`
    query GET_DEAlER_TYPES {
      dealer_types {
        alias
        type
        name
      }
    }
  `,
  GET_CONDITION_METADATA: gql`
    query GET_CONDITION_METADATA {
      si_campaign_data(where: { type: { _eq: "device_type" } }) {
        id
        value
        name
        models {
          displayModel
          modelNumber
          id
        }
      }
      si_campaign_rewards {
        id
        rewardName
        price
      }
    }
  `,
  CHECK_CAMPAIGN_CODE_EXIST: gql`
    query CHECK_CAMPAIGN_CODE_EXIST($code: String!) {
      campaigns_aggregate(where: { campaignCode: { _eq: $code } }) {
        aggregate {
          count
        }
      }
    }
  `,
  GET_PARTICIPANT_LIST: gql`
    query GET_PARTICIPANT_LIST(
      $where: dealers_bool_exp
      $limit: Int
      $offset: Int
    ) {
      dealers(where: $where, limit: $limit, offset: $offset) {
        id
        name
        dealerCode
        role {
          name
          alias
        }
        phoneNumber
        branch {
          name
        }
      }
      dealers_aggregate(where: $where) {
        aggregate {
          count
        }
      }
    }
  `,
  SALE_IN_PRODUCT_CREATE: gql`
    mutation SALE_IN_PRODUCT_CREATE($form: si_campaign_models_insert_input!) {
      insert_si_campaign_models_one(object: $form) {
        id
      }
    }
  `,
  SALE_IN_PRODUCT_UPDATE: gql`
    mutation SALE_IN_PRODUCT_UPDATE(
      $id: uuid!
      $form: si_campaign_models_set_input!
    ) {
      update_si_campaign_models_by_pk(pk_columns: { id: $id }, _set: $form) {
        id
      }
    }
  `,
  SALE_IN_PRODUCT_UPSERT: gql`
    mutation SALE_IN_PRODUCT_UPSERT($form: UpsertSiCampaignModelInput!) {
      sicUpsertModel(form: $form) {
        isSuccess
        message
      }
    }
  `,
  SALE_IN_REWARD_CREATE: gql`
    mutation SALE_IN_REWARD_CREATE($form: si_campaign_rewards_insert_input!) {
      insert_si_campaign_rewards_one(object: $form) {
        id
      }
    }
  `,
  SALE_IN_REWARD_UPDATE: gql`
    mutation SALE_IN_REWARD_UPDATE(
      $id: uuid!
      $form: si_campaign_rewards_set_input!
    ) {
      update_si_campaign_rewards_by_pk(pk_columns: { id: $id }, _set: $form) {
        id
      }
    }
  `,
  GET_SALE_IN_CAMPAIGN_DETAIL: gql`
  query GET_SALE_IN_CAMPAIGN_DETAIL($id: uuid!) {
    campaigns(where:{id: {_eq: $id}, alias: {_eq: "sell_in"}}) {
      ${saleInCampaignModel}
      sicConditionGroups {
        id
        deviceType {
          id
          value
          models {
            modelNumber
          }
        }
        conditions {
          model{
            modelNumber
          }
         conditionModelId
        }
        incentiveNotifyContent
        incentiveCondition
        rewardNotifyContent
        modelQuantity
        reward {
          id
          code
          icon
          price
          rewardName
          rewardType
          unit
        }
        rewardId
        discountAmount
        rewardQuantity
        rewardType
      }
    }
  }`,
  GET_SALE_IN_CAMPAIGN_DETAIL_PARTICIPANT_LIST: gql`
    query GET_SALE_IN_CAMPAIGN_DETAIL_PARTICIPANT_LIST(
      $where: si_campaign_participants_bool_exp, $limit: Int!, $offset: Int!
    ) {
      si_campaign_participants_aggregate(where: $where) {
        aggregate {
          count
        }
      }
      si_campaign_participants(where: $where, limit: $limit, offset: $offset) {
        id
        dealer {
          name
          id
          dealerCode
          role {
            name
          }
          phoneNumber
          address
          branch {
            name
          }
        }
        quantitySold
        targetFinished
        target
        confirmedAt
      }
    }
  `,
  GET_PINED_CAMPAIGN_LIST: gql`
    query GET_PINED_CAMPAIGN_LIST {
      campaigns(
        where: {
          alias: { _eq: "sell_in" }
          sicInformation: { pin: { _eq: true } }
        }
      ) {
        id
        campaignCode
      }
    }
  `,
  PIN_SALE_IN_CAMPAIGN: gql`
    mutation PIN_SALE_IN_CAMPAIGN($id: uuid!, $type: Boolean!) {
      update_si_campaigns(where: { id: { _eq: $id } }, _set: { pin: $type }) {
        affected_rows
      }
    }
  `,
  CHANGE_CAMPAIGN_STATUS: gql`
    mutation CHANGE_CAMPAIGN_STATUS($form: ChangeSiCampaignStatusInput!) {
      sicChangeCampaignStatus(form: $form) {
        isSuccess
        message
      }
    }
  `,
  CREATE_SALE_IN_CAMPAIGN: gql`
    mutation CREATE_SALE_IN_CAMPAIGN($form: campaigns_insert_input!) {
      insert_campaigns_one(object: $form) {
        id
      }
    }
  `,
  UPDATE_SALE_IN_CAMPAIGN: gql`
  mutation UPDATE_SALE_IN_CAMPAIGN($id: uuid!, $form: campaigns_set_input!, $information: si_campaigns_set_input!,
    $conditionGroup: [si_campaign_condition_groups_insert_input!]!) {
    update_campaigns(where: {id: {_eq: $id}}, _set: $form) {
      affected_rows
    }
    update_si_campaigns(where: {campaignId: {_eq: $id}}, _set: $information) {
      affected_rows
    }
    delete_si_campaign_conditions(where: {campaignId: {_eq: $id}}) {
      affected_rows
    }
    delete_si_campaign_condition_groups(where: {campaignId: {_eq: $id}}) {
      affected_rows
    }
    insert_si_campaign_condition_groups(objects: $conditionGroup){
      returning{
        id
      }
    }
  }
`,
  IMPORT_SALE_IN_CAMPAIGN: gql`
  mutation IMPORT_SALE_IN_CAMPAIGN($form: imports_insert_input!) {
    insert_imports_one(object: $form) {
      id
    }
  }`,
  GET_IMPORT_MODEL_LOGS: gql`
  query GET_IMPORT_MODEL_LOGS($importId: uuid!) {
    si_campaign_import_logs(where: {importId: {_eq: $importId}}, order_by: {createdAt: desc}) {
      modelNumber
      displayModel
      deviceName
      deviceType
      status
      reason
      dealerName
      dealerCode
      quantity
      target
    }
  }
  `,
  CHECK_MODEL_NUMBER_EXITS: gql`
  query CHECK_MODEL_NUMBER_EXITS($model: String!) {
    si_campaign_models_aggregate(where: {modelNumber : {_eq: $model}}) {
      aggregate {count}
    }
  }`,
  CHECK_REWARD_CODE_EXITS: gql`
  query CHECK_MODEL_NUMBER_EXITS($code: String!) {
    si_campaign_rewards_aggregate(where: {code : {_eq: $code}}) {
      aggregate {count}
    }
  }`,
  GET_HISTORY_IMPORT_MODEL_LOGS: gql`
  query GET_HISTORY_IMPORT_MODEL_LOGS($where: imports_bool_exp,$limit: Int!, $offset: Int!) {
    imports(where: $where, limit: $limit, offset: $offset) {
      importCode
      id
      creator {
        name,
        role {name}
      }
      createdAt
      url
      type
      importStatus
      failedReasons
    }
    imports_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }`,
  GET_QUANTITY_LIST_PARTICIPANT: gql`
  query GET_QUANTITY_LIST_PARTICIPANT($participantId: uuid!, $limit: Int!, $offset: Int!) {
    si_campaign_participant_models(where: {participantId: {_eq: $participantId}}, limit: $limit, offset: $offset){
      model {
        displayModel
      }
      quantity
    }

  si_campaign_participant_models_aggregate(where: {participantId: {_eq: $participantId}}){
    aggregate {count}
    }
  }`,
  GET_REWARD_LIST_PARTICIPANT: gql`
  query GET_REWARD_LIST_PARTICIPANT($participantId: uuid!, $limit: Int!, $offset: Int!) {
    si_campaign_participant_rewards(where: {participantId: {_eq: $participantId}}, limit: $limit, offset: $offset){
      reward{
        rewardName
        unit
        price
      }
      quantity
    }
    si_campaign_participant_rewards_aggregate(where: {participantId: {_eq: $participantId}}){
    aggregate {count}
    }
  }`,
  SEND_CONFIRM: gql`
  mutation sendConfirm($form: SendConfirmSiCampaignInput!) {
  sicSendConfirm(form: $form) {
    isSuccess
    message
  }
}`,
  GET_IMPORT_LOG_ID: gql`
  query GET_IMPORT_LOG_ID($importId: uuid!) {
    si_campaign_import_logs(where: {importId: {_eq: $importId}}) {
      id
      creator {
        name
        role {name}
      }
      createdAt
      status
      reason
      modelNumber
      displayModel
      quantity
      dealerName
      dealerCode
      deviceName
      deviceType
    }
  }`,
  GET_SEND_CONFIRM_LIST_FORM: gql`
   query GET_SEND_CONFIRM_LIST_FORM(
     $where: si_campaign_participants_bool_exp
   ) {
     si_campaign_participants(where: $where) {
       id
       dealer{
        id
        name
        dealerCode
       }
    }
   }
 `,
  CHANGE_PARTICIPANT: gql`
 mutation changeParticipantScopeToDraft ($form: ChangeParticipantScopeInput!){
  sicChangeParticipantScope(form: $form) {
    isSuccess
    message
  }
}
 `,
  GET_PARTICIPANT_LOGS: gql`
 query GET_PARTICIPANT_LOGS($campaignId: uuid!) {
  si_campaign_logs(where: {type: {_eq: "participant"}, campaignId: {_eq: $campaignId}}) {
    id
    action
    description
    createdAt
  }
 }`,
  UPDATE_CAMPAIGN_INACTIVE: gql`
  mutation changeCampaignInformation($form: ChangeSiCampaignInformationInput!) {
    sicChangeInformation(form: $form) {
    isSuccess
    message
  }
  }
 `
};
