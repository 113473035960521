import './styles.scss';

// import moment from 'moment';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import { IProductState } from 'root/admin/Product/Store';
import { IPaginationParams } from 'root/api/graphql/Core';
import { Avatar } from 'root/components/Avatar';
import { fullNameUser } from 'root/helpers';
import { IBreadcrumb, IFilterInput, StatusCode } from 'root/models';
import { IUser } from 'root/models/User';
import { IState, MutationType } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import { BranchAndGroupRouterName, BranchAndGroupRouterPath } from '../..';
import { ActionTypeGroupManage, MutationTypeGroupManager } from '../../GroupManage/Store/types';
import { GroupEditor } from '../Components/GroupManageEditor';

const enum FilterKey {
  Status = 'status'
}

@Component({
  template: require('./view.html'),
  components: {
    avatar: Avatar,
    editor: GroupEditor,
    // 'user-detail-modal': UserDetailModal,
  },
  computed: {
    ...mapState({
      data: (state: IState) => state.groupManage.data,
      pagination: (state: IState) => state.groupManage.pagination,
      loading: (state: IState) => state.groupManage.loading,
    }),
    selectedStatus() {
      const filterParams = [];

      if (!filterParams) {
        return [];
      }

      return [{
        key: 'isCreatedByAppleReviewer',
        value: []
      }];
    }
  },
  watch: {
    type() {
      this.paramsFilter = {
        text: ''
      };
      // this.$store.commit(MutationTypeUser.ClearUserList);
      this.$store.commit(MutationType.SetBreadcrumb, this.$t(this.type));
      this.fetchData();
    }
  },
})

export class BranchGroupsList extends Vue {
  public get serviceId(): string {
    return this.$route.params.id;
  }
  public get columns() {
    return [
      {
        prop: 'name',
        label: 'Department Name',
        labelItem: 'Code',
        width: 180,
        formatter: (model: any) => {
          if (model.name) {
            return model.name || '--';
          }
        },
        description: {
          formatter: (model: any) => {
            return model.groupCode ? model.groupCode : '--';
          }
        },
      },
      {
        prop: 'groupManagers',
        label: 'Leader(s)',
        width: 180,
        formatter: (model: any) => {
          return model.groupManagers ? model.groupManagers.length : '--';
        }
      },
      // {
      //   prop: 'address',
      //   label: 'Address',
      //   width: 180,
      //   formatter: (model: any) => {
      //     return model.address ? model.address.address : '--';
      //   }
      // },
      {
        prop: 'dealers',
        label: 'Dealer/Technician(s)',
        width: 180,
        formatter: (model: any) => {
          if (model.dealers) {
            return model.dealers.length || '--';

          }

          return '--';
        }
      },
      {
        prop: 'createdBy',
        label: 'Created By',
        width: 180,
        formatter: (model: any) => {
          if (model.createdUser && model.createdUser.name) {
            return model.createdUser.name;
          }

          return '--';
        }
      },
      {
        prop: 'createdAt',
        label: 'Created At',
        formatter: (model: any) => {
          return moment(model.createdAt).format('DD/MM/YYYY') || '--';
        },
        width: 180
      },
      {
        prop: 'status',
        label: this.$t('status'),
        formatter: (model: any) => model.status ? this.$t(model.status) : '',
        labelStyle: (model: any) => {
          return model.status;
        },
        align: 'center',
        width: 180
      }
    ];

  }

  public authUser: IUser;
  public pagination: IPaginationParams;
  public data: IProductState[];
  public deviceType: any[];
  public visible: boolean = false;
  public isSample: boolean = false;
  public userId: string = '';
  public visibleDetail: boolean = false;
  public popupVisible: boolean = false;
  public filterParams = {
    [FilterKey.Status]: [],
  };
  public searchText: string = '';

  public get inputSearchPlaceholder(): string {

    return `Search by Name, Email`;
  }

  public UserType = {
    Customer: 'customer',
    System: 'system',
  };
  public type: any;
  // tslint:disable-next-line:max-line-length
  private sampleUrl = `https://firebasestorage.googleapis.com/v0/b/daikin-test-staging.appspot.com/o/technicians%2Ftechnicians-import-sample.xlsx?alt=media&token=279661a1-11fe-49a0-8109-364e964ec11b`;

  public handleSearch() {
    this.fetchData();
  }
  public displayListGroups(model: any) {
    if (model.groupManagers
      && model.groupManagers.length > 0) {
      return model.groupManagers.map((t) => `${t.name}`).join(', ');
    }

    return '__';
  }

  public closeDialogDetail() {
    this.userId = '';
    this.visibleDetail = false;
  }

  public createSuccess() {
    this.visible = false;
    this.fetchData();
  }
  public get actions() {

    return ['view', 'edit'];
  }
  public applyFilter() {
    this.fetchData();
  }
  public goAddNewRouter() {
    this.visible = true;
    this.userId = '';
  }
  public closeDialog() {
    this.isSample = false;
    this.fetchData();
  }
  public downloadSample() {
    window.open(
      this.sampleUrl,
      '_blank'
    );
  }
  public clickDetail(id: string) {
    this.$router.push({
      name: BranchAndGroupRouterName.DetailGroup,
      params: {
        id
      }});
  }
  public handleUpdate(id: string) {
    this.visible = true;
    this.userId = id;
  }
  public get dataFilter() {
    const _status: IFilterInput[] = [
      {
        key: StatusCode.Active,
        value: StatusCode.Active,
        name: this.$t(StatusCode.Active)
      },
      {
        key: StatusCode.Blocked,
        value: StatusCode.Blocked,
        name: this.$t(StatusCode.Blocked)
      }
    ];

    return [
      {
        key: FilterKey.Status,
        name: this.$t('field.status'),
        value: _status
      },
    ];
  }
  public get selectedFilter() {
    const _filterParams = cloneDeep(this.filterParams);

    return [
      {
        key: FilterKey.Status,
        value: _filterParams[FilterKey.Status]
      },
    ];
  }

  public changeFilter(model) {
    const deviceType = model.find((e) => e.key === FilterKey.Status);
    const nextFilter = {
      [FilterKey.Status]: deviceType ? deviceType.value : [],
    };
    this.filterParams = {
      ...cloneDeep(this.filterParams),
      ...nextFilter
    };
    this.fetchData();
  }

  public formatFullNameUser(model) {
    return fullNameUser(model);
  }
  public handlePaginationChange(value) {
    if (typeof value === 'number') {
      this.$store.commit(MutationTypeGroupManager.PaginationChange, {
        ...this.pagination,
        size: value
      });
    } else {
      this.$store.commit(MutationTypeGroupManager.PaginationChange, {
        ...this.pagination,
        ...value
      });
    }
    this.fetchData();
  }

  protected mounted() {
    this.$nextTick(() => {
      const breadcrumb: IBreadcrumb[] = [
        {
          label: 'title.managebranch',
          path: BranchAndGroupRouterPath.ListBranch,
          current: false
        },
        {
          label: 'title.view_details',
          path: null,
          current: true
        }
      ];
      this.$store.commit(MutationType.SetBack);
      this.$store.commit(MutationType.SetBreadcrumb, breadcrumb);
      // this.$store.commit(MutationTypeUser.PaginationReset);
      this.fetchData();
    });
  }
  protected beforeDestroy() {
    this.$store.commit(MutationType.ClearBreadcrumb);
  }

  private fetchData() {
    const text = cloneDeep(this.searchText);
    const _filterParams =<any> {
      branch: this.serviceId
    };
    if (this.filterParams[FilterKey.Status].length) {
      _filterParams.status = this.filterParams[FilterKey.Status];
    }
    if (text) {
      _filterParams.search = text;
    }
    this.$store.dispatch(ActionTypeGroupManage.GetListGroupManage, {
      form: {
        filter: _filterParams,
        pagination: {
          size: this.pagination.limit,
          page: text ? 1 : this.pagination.page
        }
      }
    });
  }
}
