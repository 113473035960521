import { FormatDateFullTime } from 'root/helpers';
import Vue from 'vue';
import Component from 'vue-class-component';
import { ActionTypeSaleInCampaign, ActionTypeSaleInLogs } from '../../../Store/types';
import { LogsType } from '../../component';
import './styles.scss';

@Component({
  template: require('./view.html'),
  props: {
    visible: Boolean,
    detailId: String,
    logsModule: {
      type: String,
      default: 'rewardLogs'
    },
    detailType: String,
    detailProps: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
})

export class SaleInHistoryLogsDetail extends Vue {
  public get updateInfoColumns() {
    return [
      {
        label: 'data',
        prop: 'label',
        width: 270
      },
      {
        label: 'former',
        prop: 'former',
        width: 238
      },
      {
        label: 'new',
        prop: 'newValue',
        width: 164
      },
    ];
  }
  public get columns() {
    return [
      {
        label: 'model number',
        prop: 'modelNumber',
        fixed: true,
        width: 160,
      },
      {
        label: 'display model',
        prop: 'displayModel',
        fixed: true,
        width: 160,
      },
      {
        label: 'device type',
        labelItem: 'device Name',
        formatter: (model: any) => {
          return model && model.deviceType ? model.deviceType : '--';
        },
        description: {
          formatter: (model: any) => {
            return model && model.deviceName ? model.deviceName : '--';
          },
        },
        width: 180
      },
      {
        label: 'status',
        formatter: (model: any) => {
          return model && model.status ? model.status : '--';
        },
        labelStyle: (model: any) => {
          return model && model.status ? `${model.status === 'successful' ?
          'success' : model.status} text--capitalize` : '';
        },
        width: 150
      },
      {
        label: 'reason',
        prop: 'reason',
        width: 130
      }
    ];
  }
  public FormatDateFullTime = FormatDateFullTime;
  public formatDate = FormatDateFullTime;
  public LogsType = LogsType;
  public detail: any = {};
  public dataUpdateInformation: any[] = [];
  public dataImportModel: any[] = [];
  public handlePaginationChange() {
    // 
  }
  public downloadImportFile(url: string) {
    window.open(url, '_blank');
  }
  public async openDialog() {
    if (this.$props.detailType === 'import') {
      this.$store.dispatch(ActionTypeSaleInLogs.GetImportLogsId, {
        importId: this.$props.detailProps.id,
        onSuccess: (result) => {
          this.dataImportModel = result;
        }
      });

      return;
    }
    this.detail = this.$store.getters.getSaleInLogsDetail(this.$props.detailId);
    if (this.detail && this.detail.action === LogsType.Edit) {
      this.dataUpdateInformation = [];
      // tslint:disable-next-line:forin
      for (const key in this.detail.updatedKeys) {
        const value = {
          label: key,
          former: this.detail.updatedKeys[key].old.value,
          newValue: this.detail.updatedKeys[key].new.value
        };
        this.dataUpdateInformation.push(value);
      }

      return;
    }
    if (this.detail && this.detail.action === LogsType.Import) {
      this.dataImportModel = [];
      this.$store.dispatch(ActionTypeSaleInCampaign.GetImportModelLogs, {
        importId: this.$props.detailId,
        onSuccess: (data) => {
          this.dataImportModel = data;
        }
      });

      return;
    }

    return;
  }
  public closeDialog() {
    this.$emit('update:visible', false);
    this.$emit('closeDialog');
  }
}
