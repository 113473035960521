import { IConditions } from 'root/models';
import { crudMutations, CRUDState, ICRUDState } from 'root/store/helpers';

export interface IConditionState extends ICRUDState<IConditions> {}

export const defaultState: IConditionState = {
  ...CRUDState()
  // pagination: {
  //   page: 1,
  //   limit: 5
  // }
};

export const mutations = {
  ...crudMutations('conditions')
};
