import { DocumentNode } from 'graphql';
import gql from 'graphql-tag';
import { crudQuery, ICRUDQuery } from '../Core/query';

const groupManagerModel = `
data {
  address{
    address
    district
    province
    ward
  }
  branch {
    address
    branchManagers {
      id
      name
    }
    description
    id
    name
  }
  createdAt
  createdUser {
    id
    name
  }
  dealers {
    id
    name
  }
  description
  groupCode
  groupManagers {
    id
    name
    role {
      alias
    }
  }
  updatedUser {
    id
    name
  }
  id
  name
  status
}
pagination {
  nextPageToken
  page
  size
  total
  totalPages
  totalPerPage
}
`;
const groupManagerDetailModel = `
address{
  address
  district
  province
  ward
}
  branch {
      address
    branchManagers {
      id
      name
    }
    description
    id
    name
  }
  createdAt
  createdUser {
    id
    name
  }
  dealers {
    id
    name
  }
  description
  groupCode
  groupManagers {
    id
    name
  }
  updatedAt
  updatedUser {
    id
    name
  }
  id
  name
  status
`;
const groupLeaderModel = `
data {
  avatar
  createdAt
  email
  gender
  groups {
    id
    name
  }
  id
  name
  phoneNumber
  status
}
pagination {
  nextPageToken
  page
  size
  total
  totalPages
  totalPerPage
}
`;

export const GroupManage = 'group_manager';
// tslint:disable-next-line:no-empty-interface
interface IGroupManageQuery extends ICRUDQuery {
  //
  GET_BY_ID: DocumentNode;
  GET_LIST_GROUP_MANAGER: DocumentNode;
  GET_GROUP_LEADER: DocumentNode;
  UPDATE_GROUP_MANAGER: DocumentNode;
  INSERT_GROUP: DocumentNode;
  INSERT_GROUP_MANAGER: DocumentNode;
  UPDATE_GROUP: DocumentNode;
  DELETE_GROUP_MANAGER: DocumentNode;
  GET_LIST_GROUP_FILTER: DocumentNode;
}
export const getListGroupManageQuery: IGroupManageQuery = {
  ...crudQuery(GroupManage, groupManagerModel),
  GET_BY_ID: gql`
  query GET_BY_ID($id: String!){
    getGroupDetail(form: {id: $id}){
      ${groupManagerDetailModel}
    }
  }
`,
  GET_LIST_GROUP_MANAGER: gql`
  query GET_LIST_GROUP_MANAGER($form: FilterGroupInput!) {
    getGroupList(form: $form){
      ${groupManagerModel}
    }
  }
`,
  GET_GROUP_LEADER: gql`
  query GET_GROUP_LEADER($form: FilterGroupInput!){
    getGroupLeaderList(form: $form){
      ${groupLeaderModel}
    }
  }
`,
  UPDATE_GROUP_MANAGER: gql`
  mutation UPDATE_GROUP_MANAGER($form: FormUpdateGroupLeaders){
    updateGroupLeaders(form: $form){
      id
    }
  }
`,
  INSERT_GROUP: gql`
  mutation INSERT_GROUP($form: [groups_insert_input!]!){
    insert_groups(objects: $form){
      affected_rows
      returning {
        id
      }
    }
  }
`,
  INSERT_GROUP_MANAGER: gql `
  mutation INSERT_GROUP_MANAGER($form: [group_manager_insert_input!]!){
    insert_group_manager(objects: $form){
      affected_rows
    }
  }
`,
  UPDATE_GROUP: gql`
  mutation UPDATE_GROUP($id: uuid!, $form: groups_set_input ){
    update_groups(where: {id: {_eq: $id}}, _set: $form){
      affected_rows
    }
  }
  `,
  DELETE_GROUP_MANAGER: gql`
  mutation DELETE_GROUP_MANAGER($groupId: uuid!){
    delete_group_manager(where: {groupId: {_eq: $groupId}}){
      affected_rows
    }
  }`,
  GET_LIST_GROUP_FILTER: gql`
  query GET_LIST_GROUP_FILTER {
    groups {
      id
      name
      branch {
        id
        name
      }
    }
  }`
};
