import './styles.scss';

// import moment from 'moment';
import { stripObject } from '@hgiasac/helper';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import { ActionTypeProductWarranty, MutationTypeProductWarranty } from 'root/admin/ActiveEWarranty/Store/types';
import { ActionTypeDeviceType } from 'root/admin/DeviceType/Store/types';
import { IProductState } from 'root/admin/Product/Store';
import { IPaginationParams } from 'root/api/graphql/Core';
import { Avatar } from 'root/components/Avatar';
import { formatterPhoneNumber, fullNameUser } from 'root/helpers';
import { IBreadcrumb, IFilterInput, StatusCode } from 'root/models';
import { CityData } from 'root/models/City';
import { IUser } from 'root/models/User';
import { IState, MutationType } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import { BranchAndGroupRouterPath } from '../..';

const enum FilterKey {
  DeviceType = 'deviceTypeId',
  Status = 'warrantyStatus'
}

@Component({
  template: require('./view.html'),
  components: {
    avatar: Avatar,
    // 'user-detail-modal': UserDetailModal,
  },
  computed: {
    ...mapState({
      // authUser: (state: IState) => state.global.authUser,
      data: (state: IState) => state.productEWarranty.data,
      pagination: (state: IState) => state.productEWarranty.pagination,
      loading: (state: IState) => state.productEWarranty.loading,
      // filterParams: (state: IState) => state.product.filterParams,
      deviceType: (state: IState) => state.deviceType.data,
      isRefesh: (state: IState) => state.global.isRefesh
    }),
    selectedStatus() {
      const filterParams = [];

      if (!filterParams) {
        return [];
      }

      return [{
        key: 'isCreatedByAppleReviewer',
        value: []
      }];
    }
  },
  watch: {
    type() {
      this.paramsFilter = {
        text: ''
      };
      // this.$store.commit(MutationTypeUser.ClearUserList);
      this.fetchData();
    }
  },
})

export class ActiveEWarrantyGroup extends Vue {
  public get serviceId(): string {
    return this.$route.params.id;
  }
  public get columns() {
    return [
      {
        label: 'Barcode number',
        width: 180,
        formatter: (model: any) => {
          if (model.product && model.product.deviceModel) {
            return model.product.deviceModel.barcode || '--';

          }
        }
      },
      {
        label: 'Serial Number',
        width: 180,
        formatter: (model: any) => {
          return model.product && model.product.serialNumber || '--';
        }
      },
      {
        label: 'Customer',
        labelItem: 'Phone',
        width: 180,
        formatter: (model: any) => {
          return model.customerPhone ? formatterPhoneNumber(model.customerPhone) : '--';
        }
      },
      {
        label: 'Email',
        width: 180,
        formatter: (model: any) => {
          return model.customerEmail || '--';
        }
      },
      {
        label: 'Customer type',
        width: 180,
        formatter: (model: any) => {
          return model.customerType && model.customerType === 1 ? 'Tổ Chức' : !model.customerType ?
          '--' : 'Cá Nhân';
        }
      },
      {
        label: 'Device type',
        width: 180,
        formatter: (model: any) => {
          if (model.product && model.product.deviceType) {
            return model.product.deviceType.name || '--';

          }

          return '--';
        }
      },
      {
        prop: 'createdAt',
        label: 'Created At',
        formatter: (model: any) => {
          return moment(model.createdAt).format('DD/MM/YYYY') || '--';
        },
        width: 180
      },
      {
        label: 'address',
        width: 180,
        formatter: (model: any) => {
          if (model && model.cityID && model.customerAddress) {
            const city = CityData.filter((it) => it.id === model.cityID);

            return city.length ? `${model.customerAddress}, ${city[0].value}` : model.customerAddress;

          }

          return '--';
        }
      },
      {
        prop: 'status',
        label: this.$t('status'),
        formatter: (model: any) => (model.warrantyStatus ? this.$t(model.warrantyStatus) : '--'),
        labelStyle: (model: any) => {
          return model.warrantyStatus;
        },
        align: 'center',
        width: 180
      },
      {
        label: 'Error message',
        width: 180,
        formatter: (model: any) => {
          if (model.error) {
            return model.error.en ? model.error.en : '--';
          }

          return '--';
        }
      },
    ];

  }

  public authUser: IUser;
  public pagination: IPaginationParams;
  public data: IProductState[];
  public deviceType: any[];
  public visible: boolean = false;
  public isSample: boolean = false;
  public userId: string = '';
  public visibleDetail: boolean = false;
  public popupVisible: boolean = false;
  public filterParams = {
    [FilterKey.DeviceType]: [],
    [FilterKey.Status]: []
  };
  public searchText: string = '';
  public isRefesh: boolean;

  public get inputSearchPlaceholder(): string {

    return `Search by Name, Email`;
  }

  public UserType = {
    Customer: 'customer',
    System: 'system',
  };
  public type: any;
  // tslint:disable-next-line:max-line-length
  private sampleUrl = `https://firebasestorage.googleapis.com/v0/b/daikin-test-staging.appspot.com/o/technicians%2Ftechnicians-import-sample.xlsx?alt=media&token=279661a1-11fe-49a0-8109-364e964ec11b`;

  public handleSearch() {
    this.fetchData();
  }

  public closeDialogDetail() {
    this.userId = '';
    this.visibleDetail = false;
  }

  public createSuccess() {
    this.visible = false;
    this.fetchData();
  }

  public applyFilter() {
    this.fetchData();
  }
  public goAddNewRouter() {
    this.visible = true;
    this.userId = '';
  }
  public closeDialog() {
    this.isSample = false;
    this.fetchData();
  }
  public downloadSample() {
    window.open(
      this.sampleUrl,
      '_blank'
    );
  }
  public get dataFilter() {
    const _deviceType: IFilterInput[] = this.deviceType.reduce((res, item) => {
      res.push({
        key: item.id,
        value: item.id,
        name: item.name
      });

      return res;
    }, []);
    const _statuses: IFilterInput[] = [
      {
        key: StatusCode.Activated,
        value: StatusCode.Activated,
        name: this.$t(StatusCode.Activated)
      },
      {
        key: StatusCode.Fail,
        value: StatusCode.Fail,
        name: this.$t(StatusCode.Fail)
      }
    ];

    return [
      {
        key: FilterKey.DeviceType,
        name: this.$t('field.deviceType'),
        value: _deviceType
      },
      {
        key: FilterKey.Status,
        name: this.$t('field.status'),
        value: _statuses
      }
    ];
  }
  public get selectedFilter() {
    const _filterParams = cloneDeep(this.filterParams);

    return [
      {
        key: FilterKey.DeviceType,
        value: _filterParams[FilterKey.DeviceType]
      },
      {
        key: FilterKey.Status,
        value: _filterParams[FilterKey.Status]
      },
    ];
  }

  public changeFilter(model) {
    const deviceType = model.find((e) => e.key === FilterKey.DeviceType);
    const status = model.find((e) => e.key === FilterKey.Status);

    const nextFilter = {
      [FilterKey.DeviceType]: deviceType ? deviceType.value : [],
      [FilterKey.Status]: status ? status.value : []
    };
    this.filterParams = {
      ...cloneDeep(this.filterParams),
      ...nextFilter
    };
    this.filterParams = {
      ...cloneDeep(this.filterParams),
      ...nextFilter
    };
    this.fetchData();
  }

  public formatFullNameUser(model) {
    return fullNameUser(model);
  }
  public handlePaginationChange(value) {
    if (typeof value === 'number') {
      this.$store.commit(MutationTypeProductWarranty.PaginationChange, {
        ...this.pagination,
        size: value
      });
    } else {
      this.$store.commit(MutationTypeProductWarranty.PaginationChange, {
        ...this.pagination,
        ...value
      });
    }
    this.fetchData();
  }

  protected mounted() {
    this.$nextTick(() => {
      const breadcrumb: IBreadcrumb[] = [
        {
          label: 'title.managegroup',
          path: BranchAndGroupRouterPath.ListGroupManage,
          current: false
        },
        {
          label: 'title.view_details',
          path: null,
          current: true
        }
      ];
      this.$store.commit(MutationType.SetBack);
      this.$store.commit(MutationType.SetBreadcrumb, breadcrumb);
      // this.$store.commit(MutationTypeUser.PaginationReset);
      if (!this.isRefesh) {
        this.fetchData();

      }
    });
  }
  protected beforeDestroy() {
    // this.$store.commit(MutationType.ClearBreadcrumb);
  }

  private fetchData() {
    const text = cloneDeep(this.searchText);

    this.$store.commit(MutationTypeProductWarranty.FilterChange, stripObject({
      mode: 'special',
      product: {
        [FilterKey.DeviceType]: this.filterParams[FilterKey.DeviceType],
      },
      warrantyStatus: this.filterParams[FilterKey.Status],
      groupId: this.serviceId,
      _and: text.trim() !== '' ? {
        mode: 'special',
        data: {
          _or: [
            {
              product: {
                serialNumber: {
                  _like: `%${text}%`
                }
              }
            }
          ]
        }
      } : null
    }));
    this.$store.commit(MutationTypeProductWarranty.ChangeOrderBy, {createdAt: 'desc'});
    this.$store.dispatch(ActionTypeProductWarranty.FilterNoCache);
    this.$store.dispatch(ActionTypeDeviceType.FilterNoCache);
  }
}
