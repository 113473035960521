import { Message } from 'element-ui';
import { DKGqlClient } from 'root/api/graphql/Client';
import { i18n } from 'root/locales';
import { IBanners } from 'root/models';
import { ActionType, IState } from 'root/store';
import { crudActions } from 'root/store/helpers';
import { ActionContext } from 'vuex';
import { IBannerState } from '.';
import { BannersActionType, BannersMutationType } from './types';

const { filter, deleteById, update, create } = DKGqlClient().banner;
const {banner} = DKGqlClient();
export const actions = {
  ...crudActions('banners', { filter, deleteById, update, create }),
  async [BannersActionType.UpdatePriority](
    { commit, dispatch }: ActionContext<IBannerState, IState>,
    { form }
  ) {
    try {
      commit(BannersMutationType.BannersLoading);
      const data = await Promise.all(
        form.map(async (_banner: IBanners, index: number) => {
          await update(_banner.id, {
            orderNumber: index
          });

          return {
            ..._banner,
            orderNumber: index
          };
        })
      );
      commit(BannersMutationType.UpdateModels, data);
      Message.success(
        i18n()
          .t('message.updateSuccess')
          .toString()
      );
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    } finally {
      commit(BannersMutationType.BannersLoaded);
    }
  },
  async [BannersActionType.InsertBranchBanner]({
    dispatch
  }: ActionContext<IBannerState, IState>,
                                               {id, form, onSuccess, onFailure}) {
    try {
      const dataPush = <any> [];

      form.branchId.map((it) => {
        dataPush.push(
          {bannerId: id,
            branchId: it,
            createdBy: form.createdBy
          });
      });
      const updateResult = await banner.insertBranchBanner(dataPush);
      if (updateResult) {
        onSuccess();
      }
    } catch (error) {
      onFailure(error);
      dispatch(ActionType.CatchException, error);
    }
  },
  async [BannersActionType.UpdateBranchBannrt]({
    dispatch
  }: ActionContext<IBannerState, IState>,
                                               {id, form, onSuccess, onFailure}) {
    try {
      const updateResult = await form.branchId.map((it) => {
        banner.updateBranchBanner(id, {
          branchId: it,
          updatedBy: form.updatedBy
        });
      });
      if (updateResult) {
        onSuccess();
      }
    } catch (error) {
      onFailure(error);
      dispatch(ActionType.CatchException, error);
    }
  },
  async [BannersActionType.RemoveBranchBanner]({
    dispatch
  }: ActionContext<IBannerState, IState>,
                                               {id, form, onSuccess, onFailure}) {
    try {
      const updateResult = await form.branchId.map((it) => {
        banner.deleteBranchBanner(id, it);
      });
      if (updateResult) {
        onSuccess();
      }
    } catch (error) {
      onFailure(error);
      dispatch(ActionType.CatchException, error);
    }
  },
  async [BannersActionType.UpdateDescriptionServiceType]({
    dispatch
  }: ActionContext<IBannerState, IState>,
                                                         {variableName, description, onSuccess, onFailure}) {
    try {
      const updateResult = await banner.updateDescriptionServiceType(variableName, description);
      if (onSuccess && updateResult) {
        onSuccess();
      }
    } catch (error) {
      onFailure(error);
      dispatch(ActionType.CatchException, error);
    }
  },
  async [BannersActionType.FetchAllConfiguration]({
    commit, dispatch
  }: ActionContext<IBannerState, IState>,
                                                  {pagination}) {
    try {
      const { page, limit } = pagination;
      const offset = (page - 1) * limit;
      const config = await banner.getConfig(limit, offset);
      if (config) {
        commit(BannersMutationType.SetConfiguration, config);
      }
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    }
  },
  async [BannersActionType.ChangeConfig]({
    dispatch
  }: ActionContext<IBannerState, IState>,
                                         {id, value, onSuccess}) {
    try {
      await banner.changeConfig(id, value);
      if (onSuccess) {
        onSuccess();
      }
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    }
  },
};
