import { gqlClient } from '../Core';
import { CRUDGqlService, ICRUDGqlService } from '../Core/crud';
import { getListBranchQuery, Branch } from '../Query/branch';

export interface IBranchGqlService extends ICRUDGqlService<any> {
  //
  getListBranch(): Promise<any>;
}
export function BranchGqlService(): IBranchGqlService {
  async function getListBranch(): Promise<any> {
    const results = await gqlClient.query({
      query: getListBranchQuery.GET_LIST_BRANCH,
      fetchPolicy: 'network-only'
    });
    if (results.errors) {
      throw results.errors;
    }

    return results.data.branches;
  }

  return {
    ...CRUDGqlService<any>(Branch, getListBranchQuery),
    getListBranch
  };
}
