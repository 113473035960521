import { StatusCode } from './Common';
import { ISimpleUser } from './User';

export interface IDevicePartForm {
  name: string;
  description: string;
  partCode: string;
  photos: string[];
  quantity: number;
  partNumber: string;
  // deviceModelId: string;
  genericPartId: string;
  cost: number;
  vnName: string;
  partWarrantyDuration: number;
  // locationCode: string;
}
export function devicePartFormDefault(): IDevicePartForm {
  return {
    description: '',
    name: '',
    partCode: '',
    photos: [],
    quantity: 0,
    // deviceModelId: '',
    genericPartId: '',
    cost: null,
    partNumber: null,
    vnName: '',
    partWarrantyDuration: null,
    // locationCode: ''
  };
}

export interface IDevicePart extends IDevicePartForm {
  id: string;
  availableStatus: boolean;
  status: StatusCode;
  createdAt: string;
  updatedAt: string;
  createdBy: ISimpleUser;
  updatedBy: ISimpleUser;
  // deviceCategory?: IDeviceCategory;
  deviceModel?: IDeviceModel;
}
export function devicePartDefault(): IDevicePart {
  return {
    ...devicePartFormDefault(),
    availableStatus: null,
    createdAt: null,
    createdBy: null,
    updatedAt: null,
    updatedBy: null,
    status: null,
    id: ''
  };
}

export interface IDeviceCategoryForm {
  name: string;
  type?: string;
  description: string;
  genericCode: string;
  createdBy?: string;
  updatedBy?: string;
}
export function deviceCategoryFormDefault(): IDeviceCategoryForm {
  return {
    description: '',
    name: '',
    type: 'normal',
    genericCode: '',
    createdBy: '',
    updatedBy: '',
  };
}
export interface IDeviceCategory extends IDeviceCategoryForm {
  id: string;
  status: StatusCode;
  createdUser?: ISimpleUser;
  updatedUser?: ISimpleUser;
}
export function deviceCategoryDefault(): IDeviceCategory {
  return {
    ...deviceCategoryFormDefault(),
    id: '',
    status: StatusCode.Active
  };
}
export interface IDeviceModelForm {
  modelName: string;
  description: string;
  modelYear: string;
  type: DeviceModelType;
  deviceTypeId: string;
  symptomIds?: string[];
  errorIds?: string[];
  HP: number;
  modelNumber: string;
  specUrl?: string;
  createdBy?: string;
  updatedBy?: string;
}
export function deviceModelFormDefault(): IDeviceModelForm {
  return {
    modelName: '',
    description: '',
    modelYear: '',
    type: DeviceModelType.Indoor,
    symptomIds: [],
    errorIds: [],
    HP: 1.0,
    modelNumber: '',
    deviceTypeId: ''
  };
}
export enum DeviceModelType {
  Indoor = 'Indoor',
  Outdoor = 'Outdoor',
  Other = 'Other'
}
export interface IDeviceModel extends IDeviceModelForm {
  pairWith: string;
  id: string;
  createdAt?: string;
  status: StatusCode;
  updatedAt?: string;
  deviceType?: IDeviceType;
}
export function deviceModelDefault(): IDeviceModel {
  return {
    ...deviceModelFormDefault(),
    id: '',
    pairWith: '',
    status: StatusCode.Active
  };
}
export interface IGenericPartForm {
  name: string;
  vnName: string;
  partCode: string;
  specUrl: string;
  photos: string[];
  image: string;
  description?: string;
}
export interface IGenericPart extends IGenericPartForm {
  id: string;
  status: StatusCode;
  createdAt?: string;
  updatedAt?: string;
  createdBy?: string;
  updatedBy?: string;
}
export function genericPartFormDefault(): IGenericPartForm {
  return {
    name: '',
    description: '',
    partCode: '',
    vnName: '',
    specUrl: '',
    photos: [],
    image: ''
  };
}
export function genericPartDefault(): IGenericPart {
  return {
    ...genericPartFormDefault(),
    id: '',
    status: StatusCode.Active
  };
}

export interface IDeviceModelPart {
  deviceModelId: string;
  devicePartId: string;
  quantity: number;
  locationCode: string;
  id?: string;
  deviceModel?: IDeviceModel;
  devicePart?: IDevicePart;
}
export function deviceModelPartDefault(): IDeviceModelPart {
  return {
    deviceModelId: '',
    devicePartId: '',
    quantity: null,
    locationCode: ''
  };
}

export interface IExceptionalDevice {
  bookingId: string;
  typeExceptionalCase: string;
  isWarrantyExpired: boolean;
  isInvalidWarranty: boolean;
  modelNumber: string;
  installationDate?: string;
  serialNote?: string;
  id?: string;
}
export function exceptionalDeviceDefault(): IExceptionalDevice {
  return {
    bookingId: '',
    typeExceptionalCase: '',
    isWarrantyExpired: null,
    isInvalidWarranty: null,
    modelNumber: ''
  };
}
export interface IDeviceType {
  id?: string;
  code?: string;
  createdAt?: string;
  createdBy?: string;
  createdUser?: ISimpleUser;
  description: string;
  deviceTypeStatus?: any;
  imageIcon?: string;
  instruction?: string;
  name: string;
  englishName?: string;
  priority?: number;
  status?: StatusCode;
  updatedAt?: string;
  updatedBy?: string;
  updatedUser?: ISimpleUser;
}
export function deviceTypeDefault(): IDeviceType {
  return {
    description: '',
    name: '',
    id: '',
    code: ''
  };
}

export interface IErrorDeviceModel {
  id?: string;
  status: StatusCode;
  createdUser?: ISimpleUser;
  updatedUser?: ISimpleUser;
  deviceModelId: string;
  errorId: string;
  createdBy?: string;
  updatedBy?: string;
}

export interface ISymptomDeviceModel {
  id?: string;
  status: StatusCode;
  createdUser?: ISimpleUser;
  updatedUser?: ISimpleUser;
  deviceModelId: string;
  symptomId: string;
  createdBy?: string;
  updatedBy?: string;
}
