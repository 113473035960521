import './styles.scss';

import { cloneDeep, find, get, isArray } from 'lodash';
import moment from 'moment';
// import { ActionTypeBranch } from 'root/admin/Branch/Store/types';
// import { ActionTypeGroup } from 'root/admin/Group/Store/types';
import { IPaginationParams } from 'root/api/graphql/Core';
import { fullNameUser } from 'root/helpers';
import { IFilterInput, StatusCode } from 'root/models';
import { IBranch, IGroup } from 'root/models/BranchGroupManage';
import { IUser } from 'root/models/User';
import { IState, MutationType, TypeAlert } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
// import { RedemptionRouterName } from '../..';
import { CampaignDetailModal } from '../../Components/CompaignDetail';
import { CompaignEditor } from '../../Components/CompaignEditor';
import { ActionTypeCampaign, MutationTypeCampaign } from '../Store/types';

const enum FilterKey {
  Status = 'status',
  Branch = 'branch',
  Group = 'group'
}

@Component({
  template: require('./view.html'),
  components: {
    editor: CompaignEditor,
    'campaign-detail-modal': CampaignDetailModal
  },
  computed: {
    ...mapState({
      // authUser: (state: IState) => state.global.authUser,
      data: (state: IState) => state.campaign.list,
      pagination: (state: IState) => state.campaign.pagination,
      loading: (state: IState) => state.campaign.loading,
      branches: (state: IState) => state.branch.data,
      groups: (state: IState) => state.group.data
    }),
    selectedStatus() {
      const filterParams = [];

      if (!filterParams) {
        return [];
      }

      return [
        {
          key: 'isCreatedByAppleReviewer',
          value: []
        }
      ];
    }
  }
})
export class CampaignList extends Vue {
  public get columns() {
    return [
      {
        label: 'Campaign Name',
        formatter: (model: any) => {
          return get(model, 'title');
        },
        width: 250
      },
      {
        label: 'Start Date',
        formatter: (model: any) => {
          return model.startDate ? moment(model.startDate).format('DD/MM/YYYY') : '--';
        },
        width: 150
      },
      {
        label: 'End Date',
        formatter: (model: any) => {
          return model.endDate ? moment(model.endDate).format('DD/MM/YYYY') : '--';
        },
        width: 150
      },
      {
        label: 'Target',
        formatter: (model: any) => {
          return model && model.dealerRoles ? this.formatterDealerRole(model.dealerRoles) : '--';
        },
        width: 150
      },
    ];
  }

  public authUser: IUser;
  public pagination: IPaginationParams;
  public data: any[];
  public status: any[];
  public visible: boolean = false;
  public isSample: boolean = false;
  public userId: string = '';
  public visibleDetail: boolean = false;
  public popupVisible: boolean = false;
  public filterParams = {
    [FilterKey.Status]: [],
    [FilterKey.Branch]: [],
    [FilterKey.Group]: []
  };
  public visibleUpgrade: boolean = false;
  public searchText: string = '';
  public filterTypeDealer: any[];
  public branches: IBranch[];
  public groups: IGroup[];
  public fetchDataDone: boolean = false;

  public get inputSearchPlaceholder(): string {
    return `Search by name`;
  }

  public UserType = {
    Customer: 'customer',
    System: 'system'
  };
  public type: any;
  public getActions(status: string, endDate: string) {
    const now = moment();
    if (moment(endDate) < now) {
      return ['view'];
    }

    if (status === StatusCode.Available) {

      return ['view', 'edit', 'block'];
    }
    if (status === StatusCode.Blocked) {

      return ['view', 'edit', 'block'];

    }
    if (status === StatusCode.Schedule) {

      return ['view', 'edit', 'block'];
    }

    return ['view'];
  }
  public formatterDealerRole(role) {
    if (!isArray(role)) {
      return role
        .split('_')
        .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
    }
    const _cloneTarget = [...role];
    if (_cloneTarget.includes('daikin_staff')) {
      const _index = _cloneTarget.indexOf('daikin_staff');
      _cloneTarget.splice(_index, 1, 'salesman');
    }
    const result = _cloneTarget.reduce((acc, type) => {
      const formattedType = type
        .split('_')
        .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');

      return `${acc + formattedType}, `;
    }, '');

    return result.slice(0, -2) || '--';
  }

  public handleActions({ id, action }) {
    if (action === 'edit') {
      this.handleUpdate(id);

      return;
    }

    if (action === 'view') {
      this.clickDetail(id);

      return;
    }

    if (action === 'block' || action === 'unblock') {
      this.disabledConfirm(id);
    }

    return;
  }
  public closeDialogDetail() {
    this.userId = '';
    this.visibleDetail = false;
  }
  public upgradeRequest(id: string) {
    this.visibleUpgrade = true;
    this.userId = id;
  }

  public createSuccess() {
    this.visible = false;
    this.fetchData();
  }

  public applyFilter() {
    this.fetchData();
  }
  public goAddNewRouter() {
    this.visible = true;
    this.userId = '';
  }
  public closeDialog() {
    this.isSample = false;
    this.fetchData();
  }
  public handleSearch() {
    this.$store.commit(MutationTypeCampaign.PaginationChange, {
      ...this.pagination,
      page: 1
    });
    this.fetchData();
  }
  public disabledConfirm(id: string) {
    this.$msgbox({
      title: this.$t('confirm').toString(),
      message: this.$t('action_confirm_message').toString(),
      showCancelButton: true,
      confirmButtonText: this.$t('confirm').toString(),
      cancelButtonText: this.$t('cancel').toString(),
      beforeClose: (action, instance, done) => {
        if (action === 'confirm') {
          instance.confirmButtonLoading = true;
          instance.confirmButtonText = 'Loading...';
          this.handleDisabled({ id, instance, done });
        } else {
          done();
        }

        return;
      }
    }).catch(() => {
      // no handle
    });
  }

  public handleDisabled(model: any) {
    const user = find(
        cloneDeep(this.data),
        (e) => e.id.toString() === model.id.toString()
      ),
      isBlocked = user.campaignStatus === StatusCode.Blocked;
    const id = user.id;
    this.$store.dispatch(ActionTypeCampaign.UpdateStatusCampaign, {
      id,
      form: {
        campaignStatus: isBlocked ? StatusCode.Available : StatusCode.Blocked
      },
      onSuccess: () => {
        this.fetchData();
        model.instance.confirmButtonLoading = false;
        model.instance.confirmButtonText = 'Confirm';
        const message = 'Block Campaign successfully';
        this.$store.commit(MutationType.OpenTopAlert, {
          message,
          type: TypeAlert.Success
        });
        model.done();
      },
      onFailure: () => {
        model.instance.confirmButtonLoading = false;
        model.instance.confirmButtonText = 'Confirm';
      }
    });
  }
  public changePassSuccess() {
    this.popupVisible = false;
    this.fetchData();
  }
  public get dataFilter() {
    const _statuses: IFilterInput[] = [
      {
        key: StatusCode.Available,
        value: StatusCode.Available,
        name: this.$t(StatusCode.Available)
      },
      {
        key: StatusCode.Blocked,
        value: StatusCode.Blocked,
        name: this.$t(StatusCode.Blocked)
      },
      {
        key: StatusCode.Expired,
        value: StatusCode.Expired,
        name: this.$t(StatusCode.Expired)
      },
      {
        key: StatusCode.Schedule,
        value: StatusCode.Schedule,
        name: this.$t(StatusCode.Schedule)
      }
    ];

    const branches = this.branches || [],
      groups = this.groups || [];

    return [
      {
        key: FilterKey.Status,
        name: this.$t('field.status'),
        value: _statuses
      },
      {
        key: FilterKey.Branch,
        name: this.$t('branch'),
        value: branches.map((e) => ({
          key: e.id,
          value: e.id,
          name: e.name
        }))
      },
      {
        key: FilterKey.Group,
        name: this.$t('group'),
        value: groups.map((e) => ({
          key: e.id,
          value: e.id,
          name: e.name
        }))
      }
    ];
  }
  public get selectedFilter() {
    const filterParams = cloneDeep(this.filterParams);

    return [
      {
        key: FilterKey.Status,
        value: filterParams[FilterKey.Status]
      },
      {
        key: FilterKey.Group,
        value: filterParams[FilterKey.Group]
      },
      {
        key: FilterKey.Branch,
        value: filterParams[FilterKey.Branch]
      }
    ];
  }

  public clickDetail(id: string) {
    this.userId = id;
    this.visibleDetail = true;
    // this.$router.push({
    //   name: RedemptionRouterName.CampaignDetail,
    //   params: {
    //     id
    //   }});
  }
  public handleUpdate(id: string) {
    this.visible = true;
    this.userId = id;
  }
  public handleDelete(model: any) {
    this.$store.dispatch(ActionTypeCampaign.UpdateStatusCampaign, {
      id: model.id,
      form: {
        status: StatusCode.Deleted,
      },
      onSuccess: () => {
        this.fetchData();
        model.instance.confirmButtonLoading = false;
        model.instance.confirmButtonText = 'Confirm';
        model.done();
      },
      onFailure: () => {
        model.instance.confirmButtonLoading = false;
        model.instance.confirmButtonText = 'Confirm';
      }
    });
  }
  public fetchDone() {
    this.fetchDataDone = true;
  }

  public changeFilter(model) {
    const status = model.find((e) => e.key === FilterKey.Status),
      branches = model.find((e) => e.key === FilterKey.Branch),
      groups = model.find((e) => e.key === FilterKey.Group);
    const nextFilter = {
      [FilterKey.Status]: status ? status.value : [],
      [FilterKey.Group]: groups ? groups.value : [],
      [FilterKey.Branch]: branches ? branches.value : []
    };
    this.filterParams = {
      ...cloneDeep(this.filterParams),
      ...nextFilter
    };
    this.fetchData();
  }

  public formatFullNameUser(model) {
    return fullNameUser(model);
  }

  public handlePaginationChange(value) {
    if (typeof value === 'number') {
      this.$store.commit(MutationTypeCampaign.PaginationChange, {
        ...this.pagination,
        size: value
      });
    } else {
      this.$store.commit(MutationTypeCampaign.PaginationChange, {
        ...this.pagination,
        ...value
      });
    }
    this.fetchData();
  }

  protected mounted() {
    this.$nextTick(() => {
      this.$store.commit(MutationType.ClearBack);
      this.$store.commit(MutationType.SetBreadcrumb, this.$t('title.campaign'));
      // this.$store.commit(MutationTypeUser.PaginationReset);
      this.fetchData();
    });
  }
  protected beforeDestroy() {
    this.$store.commit(MutationType.ClearBreadcrumb);
  }

  private fetchData() {
    // this.$store.commit(MutationTypeCampaign.FilterChange, {
    //   distinct_on: 'campaignId'
    // });
    const text = cloneDeep(this.searchText);
    const filterDealer = <any> {};
    if (this.filterParams[FilterKey.Status].length) {
      filterDealer.status = this.filterParams[FilterKey.Status];
    }
    if (text) {
      filterDealer.search = text;
    }
    if (this.filterParams[FilterKey.Branch].length > 0) {
      filterDealer.branchIds = this.filterParams[FilterKey.Branch];
    }
    if (this.filterParams[FilterKey.Group].length > 0) {
      filterDealer.groupIds = this.filterParams[FilterKey.Group];
    }
    this.$store.dispatch(ActionTypeCampaign.GetListCampaign, {
      pagination: this.pagination,
      filter: {
        status: {
          _eq: StatusCode.Active
        },
        alias: {
          _in: ['other', 'custom']
        }
      }
    });
    // this.$store.dispatch(ActionTypeBranch.FilterNoCache);
    // this.$store.dispatch(ActionTypeGroup.FilterNoCache);
  }
}
