import { gqlClient } from '../Core';
import { CRUDGqlService, ICRUDGqlService } from '../Core/crud';
import { getListGroupQuery, getListGroupSystemQuery, Groups, GroupsSystem } from '../Query/group';

export interface IGroupGqlService extends ICRUDGqlService<any> {
  //
  getListGroup(): Promise<any>;
}
export function groupGqlService(): IGroupGqlService {
  async function getListGroup(): Promise<any> {
    const results = await gqlClient.query({
      query: getListGroupQuery.GET_LIST_GROUP,
      fetchPolicy: 'network-only'
    });
    if (results.errors) {
      throw results.errors;
    }

    return results.data.groups;
  }

  return {
    ...CRUDGqlService<any>(Groups, getListGroupQuery),
    getListGroup
  };
}
export interface IGroupSystemGqlService extends ICRUDGqlService<any> {
  //
  getListGroupSystem(): Promise<any>;
}
export function groupSystemGqlService(): IGroupSystemGqlService {
  async function getListGroupSystem(): Promise<any> {
    const results = await gqlClient.query({
      query: getListGroupSystemQuery.GET_LIST_GROUP_SYSTEM,
      fetchPolicy: 'network-only'
    });
    if (results.errors) {
      throw results.errors;
    }

    return results.data.system_groups;
  }

  return {
    ...CRUDGqlService<any>(GroupsSystem, getListGroupSystemQuery),
    getListGroupSystem
  };
}
