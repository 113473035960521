import { cloneDeep, find } from 'lodash';
import moment from 'moment';
import { parseUTCToTime } from 'root/helpers';
import { IAddress, IProvince } from 'root/models';
import { IState } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import './styles.scss';

@Component({
  template: require('./view.html'),
  props: {
    visible: Boolean,
    dealerId: String,
  },
  computed: {
    ...mapState({
      localVN: (state: IState) => state.global.localVN,
    }),
  },
})

export class SubDealerDetail extends Vue {
  public dealerId: string;
  public localVN: IProvince[];
  public dealerDetail: any = {};
  public loading: boolean = false;
  public formatDate = parseUTCToTime;
  public currentIndex: number = 0;
  public currentIndexVAT: number = 0;
  public visibleMedia: boolean = false;
  public visibleMediaVAT: boolean = false;
  public imageList: any[] = [];
  public imageListVAT: any[] = [];
  public get currentImg() {
    return this.imageList[Math.abs(this.currentIndex) % this.imageList.length];
  }
  public get currentImgVAT() {
    return this.imageListVAT[Math.abs(this.currentIndexVAT) % this.imageListVAT.length];
  }
  public formatterTime(time) {
    if (time) {
      return moment(time).format('HH:mm-DD/MM/YYYY');
    }

    return '--';
  }
  public formatterProvince(prov) {
    const province = find(cloneDeep(this.localVN), (e) => Number(e.matp) === Number(prov.province));

    return province && province.name ? province.name : '--';
  }
  public displayAddress(model: IAddress) {
    return model && model.province ? this.$store.getters['getAddressString'](model) : '';
  }

  public back() {
    this.$emit('update:visible', false);
  }
  public formatterStatus(status) {
    return status ? this.$t(status) : '--';
  }
  public clickViewImage(index: number) {
    this.currentIndex = index - 1;
    this.visibleMedia = true;
  }
  public closeDialogMedia() {
    this.visibleMedia = false;
  }
  public prev() {
    this.currentIndex -= 1;
  }
  public next() {
    this.currentIndex += 1;
  }
  public clickViewImageVAT(index: number) {
    this.currentIndexVAT = index;
    this.visibleMediaVAT = true;
  }
  public closeDialogMediaVAT() {
    this.visibleMediaVAT = false;
  }
  public prevVAT() {
    this.currentIndexVAT -= 1;
  }
  public nextVAT() {
    this.currentIndexVAT += 1;
  }
  public openDialog() {
    this.dealerDetail = this.$store.getters.getSubDealerDetail(this.dealerId);
    this.imageList = [];
    this.imageListVAT = [];
    if (this.dealerDetail && this.dealerDetail.information.businessHouseholdPicURLs.front) {
      this.imageList.push(this.dealerDetail.information.businessHouseholdPicURLs.front);
      this.imageList.push(this.dealerDetail.information.businessHouseholdPicURLs.back);
    }
    if (this.dealerDetail && this.dealerDetail.information.businessRegistrationPicURLs.front) {
      this.imageList.push(this.dealerDetail.information.businessRegistrationPicURLs.front);
      this.imageList.push(this.dealerDetail.information.businessRegistrationPicURLs.back);
    }
    // tslint:disable-next-line: early-exit
    if (this.dealerDetail && this.dealerDetail.information
      && this.dealerDetail.information.productReceiptPicURLs.length) {
      // tslint:disable-next-line: forin
      for (const pic in this.dealerDetail.information.productReceiptPicURLs) {
        this.imageListVAT.push(this.dealerDetail.information.productReceiptPicURLs[pic]);
      }
    }
  }
  public closeDialog() {
    this.$emit('update:visible', false);
  }

}
