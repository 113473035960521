export enum ActionTypeRaCampaign {
  FilterNoCache = 'evaluatePhotoFilterNoCache',
  GetAclCampaign = 'getRaCampaign',
  GetAclCampaignDetail = 'getRaCampaignDetail',
  DownloadSample = 'downloadSampleRa',
  ApproveShop = 'approveShopRa',
  RequestReup = 'requestReupRa',
  GetShopDetail= 'getShopDetailRa',
  SubmitReview = 'submitReviewRa',
  GetPackage = 'getPackageRa',
  UpdatePackage = 'updatePackageRa',
  GetPdfExportData = 'getPdfExportDataRa',
  GetCampaignConfiguration = 'getCampaignConfigurationRa',
  GetMaskConfig= 'getMaskConfigRa',
  EndCampaign = 'endCampaignRa',
  MaskChange = 'maskChangeRa',
  GetManageExport = 'getManageExportRa',
  GetStatistic = 'getStatisticRa',
  ExportStatistic = 'exportStatisticRa',
  GetQualifiedShops = 'getQualifiedShopsRa',
  ApproveAll = 'approveAllRa',
  GetDealerByBranch = 'getDealerByBranchRa',
  GetJoinedShop = 'getJoinedShopRa',
  GetConfigCropPicture = 'GetConfigCropPictureRa',
  GetListSubDealerRequest = 'getListSubDealerRequest',
  UpdateSubDealerRequest = 'updateSubDealerRequest',
  GetConfirmationCode = 'getConfirmationCode',
  DownloadConfirmationCode = 'downloadConfirmationCode',
  GenerateCode = 'generateCode',
  GetSubDealerCampaign = 'getSubDealerCampaign',
  GetSubDealerCampaginDetail = 'getSubDealerCampaginDetail',
  PushReminder = 'pushReminder',
  GetBranchId = 'GetBranchId'
}
export enum MutationTypeRaCampaign {
  SetAclCompaign = 'setDataRaCompaign',
  SetAclManageExport = 'setDataRaManageExport',
  SetAclCompaignDetail = 'setDataRaCompaignDetail',
  AclCampaignLoading = 'ra_campaignsLoading',
  AclCampaignLoaded = 'ra_campaignsLoaded',
  SetPagination= 'ra_campaignsSetPagination',
  PaginationChange = 'ra_campaignsPaginationChange',
  SetQualifiedList= 'setQualifiedListRa',
  SetJoinedShopList = 'setJoinedShopListRa',
  SetListRequest = 'setListRequest',
  SetListConfirmCode = 'setListConfirmCode',
  SetSubDealerCampaign = 'setSubDealerCampaign'
}
