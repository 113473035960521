import { PermissionType } from 'root/models';
import { RouteConfig } from 'vue-router';
import { DealerTypeList } from './DealerTypeList';
import { ManageDealerTypeContainer } from './ManageDealerTypeContainer';

export enum ManageDealerTypeRouterPath {
  DealerTypeList = '/manage-dealer-type',
}

export enum ManageDealerTypeRouterName {
    DealerTypeList = 'ListDealerType',
}

export const manageDealerTypeRouter: RouteConfig = {
  path: '/manage-dealer-type',
  component: ManageDealerTypeContainer,
  meta: {
    permission: PermissionType.DealerType
  },
  children: [
    {
      path: ManageDealerTypeRouterPath.DealerTypeList,
      component: DealerTypeList,
      name: ManageDealerTypeRouterName.DealerTypeList,
      meta: {
        permission: PermissionType.DealerType
      }
    },
  ]
};
