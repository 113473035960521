import { cloneDeep, omit } from 'lodash';
import moment from 'moment';
import { TargetOptions } from 'root/admin/NewsAnnoucement/components/NotificationEditor';
import { INotification } from 'root/models/Notifications';
import { gqlClient } from '../Core';
import { CRUDGqlService, ICRUDGqlService } from '../Core/crud';
import {
  CREATE_NEW_NOTIFICATION,
  NotificationQuery,
  UPDATE_NOTIFICATIONS,
  UPDATE_NOTIFICATIONS_DEALERS
} from '../Query/notification';

export interface INotificationGqlService
  extends ICRUDGqlService<INotification> {
  createNewNotification(form: any): Promise<any>;
  updateNotification(form: any): Promise<any>;
  updateNotificationDealers(
    referenceId: string,
    receiversId: string[]
  ): Promise<any>;
  readNotifications(ids: any, readAt: any): Promise<any>;
}

export function notificationGqlService(): INotificationGqlService {
  async function updateNotification(form): Promise<any> {
    const mutation = UPDATE_NOTIFICATIONS;
    let newForm = <any> cloneDeep(form);
    newForm = omit(newForm, [
      'updatedAt',
      'id',
      'notificationStatus',
      'createdAt',
      'url'
    ]);
    if (newForm.target[0] === TargetOptions.Dealer) {
      newForm = omit(newForm, ['typeDealers']);
    }
    newForm.scheduledAt = moment(newForm.scheduledAt).utc().format(
      'YYYY-MM-DD HH:mm:ss'
    );
    const result = await gqlClient.mutate({
      mutation,
      variables: {
        form: newForm,
        id: form.id
      }
    });
    if (result.errors) {
      throw result.errors;
    }

    return result;
  }
  async function createNewNotification(form): Promise<any> {
    const mutation = CREATE_NEW_NOTIFICATION;
    const newForm = <any> cloneDeep(form);
    newForm.type = 'news';
    const result = await gqlClient.mutate({
      mutation,
      variables: {
        form: newForm
      }
    });
    if (result.errors) {
      throw result.errors;
    }

    return result;
  }

  async function updateNotificationDealers(
    referenceId: string,
    receiverIds: string[] | string = []
  ): Promise<any> {
    const mutation = UPDATE_NOTIFICATIONS_DEALERS;
    const result = await gqlClient.mutate({
      mutation,
      variables: {
        referenceId,
        receiverIds
      }
    });

    if (result.errors) {
      throw result.errors;
    }

    return result;
  }
  async function readNotifications(ids: any, readAt: any): Promise<any> {
    const result = await gqlClient.mutate({
      mutation: NotificationQuery.READ_NOTIFICATION,
      variables: {
        ids,
        readAt
      }
    });
    if (result.errors) {
      throw result.errors;
    }

    return result.data;
  }

  return {
    ...CRUDGqlService<INotification>('notifications', NotificationQuery),
    createNewNotification,
    updateNotification,
    updateNotificationDealers,
    readNotifications
  };
}
