import moment from 'moment';
import { ActionTypeDealer } from 'root/admin/Dealer/Store/types';
import { ActionTypeProduct } from 'root/admin/Product/Store/types';
import { warrantyStatus, warrantyStatusCode } from 'root/admin/ProductManage/ProductRegistation';
import { parseUTCToTime } from 'root/helpers';
import { IAddress } from 'root/models';
import Vue from 'vue';
import Component from 'vue-class-component';
import './styles.scss';

@Component({
  template: require('./view.html'),
  props: {
    visible: Boolean,
    dealerId: String,
    productId: String,
  },
})

export class ProductRegistationDetail extends Vue {
  public dealerId: string;
  public productId: string;
  public dealerDetail: any = {};
  public productDetail: any = {};
  public loading: boolean = false;
  public formatDate = parseUTCToTime;

  public formatterTime(time) {
    if (time) {
      return moment(time).format('HH:mm-DD/MM/YYYY');
    }

    return '--';
  }
  public displayAddress(model: IAddress) {
    return model && model.province ? this.$store.getters['getAddressString'](model) : '';
  }

  public back() {
    this.$emit('update:visible', false);
  }
  public formatterStatus(status) {
    switch (status) {
    case warrantyStatusCode.Success:
      return warrantyStatus.Success;
    case warrantyStatusCode.Failed:
      return warrantyStatus.Failed;
    }
  }
  public async openDialog() {
    // change after
    this.$store.dispatch(ActionTypeDealer.GetById, {
      id: this.dealerId,
      onSuccess: (dealer) => {
        this.dealerDetail = dealer;
      },
      onFailure: () => {
          //
      }
    });
    this.$store.dispatch(ActionTypeProduct.GetProductDetail, {
      id: this.productId,
      onSuccess: (product) => {
        this.productDetail = product.warranties[0];
      },
      onFailure: () => {
          //
      }
    });
  }
  public closeDialog() {
    this.$emit('update:visible', false);
  }

}
