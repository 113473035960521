import { gqlClient } from '../Core';
import { CRUDGqlService, ICRUDGqlService } from '../Core/crud';
import { feedback, feedbackListQuery } from '../Query/feedback';

export interface IFeedbackGqlService extends ICRUDGqlService<any> {
  //
  getListFeedback(): Promise<any>;
  updateStatus(id: string, status: string): Promise<any>;
  getOwner(shopId: string): Promise<any>;
}
export function feedbackGqlService(): IFeedbackGqlService {
  async function getListFeedback(): Promise<any> {
    const results = await gqlClient.query({
      query: feedbackListQuery.GET_FEEDBACK_LIST,
      fetchPolicy: 'network-only'
    });
    if (results.errors) {
      throw results.errors;
    }

    return results.data.dealer_feedbacks;
  }
  async function updateStatus(id: string, status: string): Promise<any> {
    const result = await gqlClient.mutate({
      mutation: feedbackListQuery.UPDATE_STATUS,
      variables: {
        id,
        status
      }
    });
    if (result.errors) {
      throw result.errors;
    }
  }
  async function getOwner(shopId: string): Promise<any> {
    const results = await gqlClient.query({
      query: feedbackListQuery.GET_OWNER_DEALER,
      variables: {
        shopId
      },
      fetchPolicy: 'network-only'
    });
    if (results.errors) {
      throw results.errors;
    }

    return results.data.dealer_shops;
  }

  return {
    ...CRUDGqlService<any>(feedback, feedbackListQuery),
    getListFeedback,
    updateStatus,
    getOwner
  };
}
