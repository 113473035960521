import { Address } from 'cluster';

export interface IRegionForm {
  regionCode: string;
  description?: string;
  name: string;
  id?: string;
}
export const regionFormDefault = (): IRegionForm => {
  return {
    regionCode: null,
    description: '',
    name: null,
  };
};

export const regionDefault = (): IRegion => {
  return {
    ...regionFormDefault(),
  };
};

export interface IRegion extends IRegionForm {
  regionCode: string;
  description?: string;
  name: string;
}
export interface IBranchForm {
  address?: Address;
  branchCode: string;
  branchManagers?: any;
  description?: string;
  name: string;
  region?: {
    id?: string,
    name?: string
  };
  id?: string;
}
export const branchFormDefault = (): IBranchForm => {
  return {
    address: null,
    branchCode: null,
    branchManagers: null,
    description: '',
    name: null,
    region: null
  };
};

export const branchDefault = (): IBranch => {
  return {
    ...branchFormDefault(),
  };
};

export interface IBranch extends IBranchForm {
  address?: Address;
  branchCode: string;
  branchManagers?: any;
  description?: string;
  name: string;
  region?: {
    id?: string,
    name?: string
  };
}

export interface IGroupForm {
  address?: Address;
  groupCode: string;
  groupManagers?: any;
  description?: string;
  name: string;
  branch?: string;
}
export const groupFormDefault = (): IGroupForm => {
  return {
    address: null,
    groupCode: null,
    groupManagers: null,
    description: '',
    name: null,
    branch: null
  };
};

export const groupDefault = (): IGroup => {
  return {
    ...groupFormDefault(),
  };
};

export interface IGroup extends IGroupForm {
  address?: Address;
  groupCode: string;
  groupManagers?: any;
  description?: string;
  name: string;
  branch?: string;
  id?: string;
}
