import { omit } from 'lodash';
import { DKGqlClient } from 'root/api/graphql/Client';
import { ActionType, IState } from 'root/store';
import { crudActions } from 'root/store/helpers';
import { ActionContext } from 'vuex';
import { IRedemptionState } from './mutation';
import { ActionTypeRedemption, MutationTypeRedemption } from './types';

const { redemption } = DKGqlClient();
export const actions = {
  ...crudActions('rewards', {
    filter: redemption.filter
  }),
  async [ActionTypeRedemption.CreateRedemption]({
    dispatch
  }: ActionContext<IRedemptionState, IState>,
                                                {form, reusedVouchder, onSuccess, onFailure}) {
    try {
      const createUserResult = await redemption.createRedemption(
        {...omit(form, ['voucherCodes'])});
      if (createUserResult) {
        if (reusedVouchder && reusedVouchder.length) {
          const updateVoucher = await redemption.updateVoucher(reusedVouchder,
            createUserResult.data.insert_rewards.returning[0].id);
          if (updateVoucher) {
            //
          }
        }
        dispatch(ActionTypeRedemption.AddVoucherCode, {
          form: {codes: form.voucherCodes, rewardId: createUserResult.data.insert_rewards.returning[0].id}
        });
        onSuccess();
        dispatch(ActionTypeRedemption.FilterNoCache);
      }
    } catch (error) {
      onFailure();
      dispatch(ActionType.CatchException, error);
    }
  },
  async [ActionTypeRedemption.UpdateRedemption]({
     dispatch
  }: ActionContext<IRedemptionState, IState>,
                                                {id, set, onSuccess, onFailure}) {
    try {
      const updateResult = await redemption.updateRedemption(id, set);
      onSuccess();
      if (updateResult) {
        dispatch(ActionTypeRedemption.FilterNoCache);
      }
    } catch (error) {
      onFailure(error);
      dispatch(ActionType.CatchException, error);
    }
  },
  async [ActionTypeRedemption.UpdateStatusRedemption]({
    dispatch
 }: ActionContext<IRedemptionState, IState>,
                                                      {id, set, onSuccess, onFailure}) {
    try {
      const updateResult = await redemption.updateRedemption(id, set);
      onSuccess();
      if (updateResult) {
        dispatch(ActionTypeRedemption.FilterNoCache);
      }
    } catch (error) {
      onFailure(error);
      dispatch(ActionType.CatchException, error);
    }
  },
  async [ActionTypeRedemption.GetRedeemDetail]({
    dispatch, commit
 }: ActionContext<IRedemptionState, IState>,
                                               { form }) {
    try {
      const updateResult = await redemption.getRedeemDetail(form);
      if (updateResult) {
        commit(MutationTypeRedemption.GetRedeemDetail, updateResult);
      }
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    }
  },
  async [ActionTypeRedemption.AddVoucherCode]({
    dispatch, commit
 }: ActionContext<IRedemptionState, IState>,
                                              { form }) {
    try {
      const updateResult = await redemption.addVoucherCode(form);
      if (updateResult) {
        commit(MutationTypeRedemption.GetRedeemDetail, updateResult);
      }
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    }
  },
  async [ActionTypeRedemption.SearchVoucher]({
    dispatch
 }: ActionContext<IRedemptionState, IState>,
                                             { code }) {
    try {
      const updateResult = await redemption.searchVoucher(code);

      return updateResult.voucher_codes;
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    }
  },
  async [ActionTypeRedemption.GetListVoucher]({
    dispatch, commit
 }: ActionContext<IRedemptionState, IState>,
                                              { where }) {
    try {
      const updateResult = await redemption.getListVoucher(where);

      if (updateResult) {
        commit(MutationTypeRedemption.GetListVoucher, updateResult);
      }
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    }
  },
};
