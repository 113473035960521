import { DKGqlClient } from 'root/api/graphql/Client';
import { ActionType, IState } from 'root/store';
import { crudActions } from 'root/store/helpers';
import { ActionContext } from 'vuex';
import { IAclCampaignState } from '.';
import { ActionTypeAclCampaign, MutationTypeAclCampaign } from './types';

const { aclCampaign } = DKGqlClient();
export const actions = {
  ...crudActions('acl_campaigns', {
    filter: aclCampaign.filter
  }),
  async [ActionTypeAclCampaign.GetAclCampaign]({
    commit, dispatch
  }: ActionContext<IAclCampaignState, IState>, {search, branch, pagination}) {
    try {
      commit(MutationTypeAclCampaign.AclCampaignLoading);
      const { page, limit } = pagination;
      const offset = (page - 1) * limit;
      const listAclCampaign = await aclCampaign.getList(search, branch, limit, offset);
      if (listAclCampaign) {
        commit(MutationTypeAclCampaign.SetAclCompaign, listAclCampaign);
      }
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    } finally {
      commit(MutationTypeAclCampaign.AclCampaignLoaded);
    }
  },
  async [ActionTypeAclCampaign.GetAclCampaignDetail]({
    commit, dispatch
  }: ActionContext<IAclCampaignState, IState>,
                                                     {id, status, notJoin, search, pagination}) {
    try {
      commit(MutationTypeAclCampaign.AclCampaignLoading);
      const { page, limit } = pagination;
      const offset = (page - 1) * limit;
      const aclCampaignDetail = await aclCampaign.getListShop(id, status, notJoin, search, limit, offset);
      if (aclCampaignDetail) {
        commit(MutationTypeAclCampaign.SetAclCompaignDetail, aclCampaignDetail);
      }
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    } finally {
      commit(MutationTypeAclCampaign.AclCampaignLoaded);
    }
  },
  async [ActionTypeAclCampaign.ApproveShop]({
    commit, dispatch
  }: ActionContext<IAclCampaignState, IState>,
                                            {ids, action, updatedBy, reason, onSuccess, onFailure}) {
    try {
      commit(MutationTypeAclCampaign.AclCampaignLoading);
      await aclCampaign.approveShop(ids, action, reason, updatedBy);
      if (onSuccess) {
        onSuccess();
      }
    } catch (error) {
      if (onFailure) {
        onFailure();
      }
      dispatch(ActionType.CatchException, error);
    } finally {
      commit(MutationTypeAclCampaign.AclCampaignLoaded);
    }
  },
  async [ActionTypeAclCampaign.RequestReup]({
    commit, dispatch
  }: ActionContext<IAclCampaignState, IState>,
                                            {ids, action, updatedBy, reason, onSuccess, onFailure}) {
    try {
      commit(MutationTypeAclCampaign.AclCampaignLoading);
      await aclCampaign.requestReup(ids, action, reason, updatedBy);
      if (onSuccess) {
        onSuccess();
      }
    } catch (error) {
      if (onFailure) {
        onFailure();
      }
      dispatch(ActionType.CatchException, error);
    } finally {
      commit(MutationTypeAclCampaign.AclCampaignLoaded);
    }
  },
  async [ActionTypeAclCampaign.GetShopDetail]({
    commit, dispatch
  }: ActionContext<IAclCampaignState, IState>,
                                              {id, onSuccess, onFailure}) {
    try {
      commit(MutationTypeAclCampaign.AclCampaignLoading);
      const ShopDetail = await aclCampaign.getShop(id);
      if (onSuccess) {
        onSuccess(ShopDetail);
      }
    } catch (error) {
      if (onFailure) {
        onFailure();
      }
      dispatch(ActionType.CatchException, error);
    } finally {
      commit(MutationTypeAclCampaign.AclCampaignLoaded);
    }
  },
  async [ActionTypeAclCampaign.SubmitReview]({
    commit, dispatch
  }: ActionContext<IAclCampaignState, IState>,
                                             {id, review, qualify, createdBy, onSuccess, onFailure}) {
    try {
      commit(MutationTypeAclCampaign.AclCampaignLoading);
      await aclCampaign.submitReview(id, review, qualify, createdBy);
      if (onSuccess) {
        onSuccess();
      }
    } catch (error) {
      if (onFailure) {
        onFailure();
      }
      dispatch(ActionType.CatchException, error);
    } finally {
      commit(MutationTypeAclCampaign.AclCampaignLoaded);
    }
  },
  async [ActionTypeAclCampaign.GetPackage]({
    commit, dispatch
  }: ActionContext<IAclCampaignState, IState>,
                                           {onSuccess, onFailure}) {
    try {
      commit(MutationTypeAclCampaign.AclCampaignLoading);
      const aclPackage = await aclCampaign.getPackage();
      if (onSuccess) {
        onSuccess(aclPackage);
      }
    } catch (error) {
      if (onFailure) {
        onFailure();
      }
      dispatch(ActionType.CatchException, error);
    } finally {
      commit(MutationTypeAclCampaign.AclCampaignLoaded);
    }
  },
  async [ActionTypeAclCampaign.UpdatePackage]({
    commit, dispatch
  }: ActionContext<IAclCampaignState, IState>,
                                              {id, models, onSuccess, onFailure}) {
    try {
      commit(MutationTypeAclCampaign.AclCampaignLoading);
      const aclPackage = await aclCampaign.updatePackage(id, models);
      if (onSuccess) {
        onSuccess(aclPackage);
      }
    } catch (error) {
      if (onFailure) {
        onFailure();
      }
      dispatch(ActionType.CatchException, error);
    } finally {
      commit(MutationTypeAclCampaign.AclCampaignLoaded);
    }
  },
  async [ActionTypeAclCampaign.GetPdfExportData]({
    commit, dispatch
  }: ActionContext<IAclCampaignState, IState>,
                                                 {id, onSuccess, onFailure}) {
    try {
      commit(MutationTypeAclCampaign.AclCampaignLoading);
      const pdfExportData = await aclCampaign.getPdfExportData(id);
      if (onSuccess) {
        onSuccess(pdfExportData);
      }
    } catch (error) {
      if (onFailure) {
        onFailure();
      }
      dispatch(ActionType.CatchException, error);
    } finally {
      commit(MutationTypeAclCampaign.AclCampaignLoaded);
    }
  },
  async [ActionTypeAclCampaign.GetCampaignConfiguration]({
    commit, dispatch
  }: ActionContext<IAclCampaignState, IState>,
                                                         {onSuccess, onFailure}) {
    try {
      commit(MutationTypeAclCampaign.AclCampaignLoading);
      const aclConfig = await aclCampaign.getConfig();
      if (onSuccess) {
        onSuccess(aclConfig);
      }
    } catch (error) {
      if (onFailure) {
        onFailure();
      }
      dispatch(ActionType.CatchException, error);
    } finally {
      commit(MutationTypeAclCampaign.AclCampaignLoaded);
    }
  },
  async [ActionTypeAclCampaign.EndCampaign]({
    commit, dispatch
  }: ActionContext<IAclCampaignState, IState>,
                                            {id, onSuccess, onFailure}) {
    try {
      commit(MutationTypeAclCampaign.AclCampaignLoading);
      await aclCampaign.endCampaign(id);
      if (onSuccess) {
        onSuccess();
      }
    } catch (error) {
      if (onFailure) {
        onFailure();
      }
      dispatch(ActionType.CatchException, error);
    } finally {
      commit(MutationTypeAclCampaign.AclCampaignLoaded);
    }
  },
  async [ActionTypeAclCampaign.GetMaskConfig]({
    commit, dispatch
  }: ActionContext<IAclCampaignState, IState>,
                                              {id, onSuccess, onFailure}) {
    try {
      commit(MutationTypeAclCampaign.AclCampaignLoading);
      const maskConfig = await aclCampaign.getMaskConfig(id);
      if (onSuccess) {
        onSuccess(maskConfig);
      }
    } catch (error) {
      if (onFailure) {
        onFailure();
      }
      dispatch(ActionType.CatchException, error);
    } finally {
      commit(MutationTypeAclCampaign.AclCampaignLoaded);
    }
  },
  async [ActionTypeAclCampaign.MaskChange]({
    commit, dispatch
  }: ActionContext<IAclCampaignState, IState>,
                                           {id, mask, onSuccess, onFailure}) {
    try {
      commit(MutationTypeAclCampaign.AclCampaignLoading);
      await aclCampaign.maskChange(id, mask);
      if (onSuccess) {
        onSuccess();
      }
    } catch (error) {
      if (onFailure) {
        onFailure();
      }
      dispatch(ActionType.CatchException, error);
    } finally {
      commit(MutationTypeAclCampaign.AclCampaignLoaded);
    }
  },
  async [ActionTypeAclCampaign.GetManageExport]({
    commit, dispatch
  }: ActionContext<IAclCampaignState, IState>,
                                                {pagination}) {
    try {
      commit(MutationTypeAclCampaign.AclCampaignLoading);
      const { page, limit } = pagination;
      const offset = (page - 1) * limit;
      const listManagerExport = await aclCampaign.getListManageExport(limit, offset);
      if (listManagerExport) {
        commit(MutationTypeAclCampaign.SetAclManageExport, listManagerExport);
      }
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    } finally {
      commit(MutationTypeAclCampaign.AclCampaignLoaded);
    }
  },
  async [ActionTypeAclCampaign.GetStatistic]({
    commit, dispatch
  }: ActionContext<IAclCampaignState, IState>,
                                             {onSuccess, onFailure}) {
    try {
      commit(MutationTypeAclCampaign.AclCampaignLoading);
      const statistic = await aclCampaign.getStatistic();
      if (onSuccess) {
        onSuccess(statistic);
      }
    } catch (error) {
      if (onFailure) {
        onFailure();
      }
      dispatch(ActionType.CatchException, error);
    } finally {
      commit(MutationTypeAclCampaign.AclCampaignLoaded);
    }
  },
  async [ActionTypeAclCampaign.ExportStatistic]({
    commit, dispatch
  }: ActionContext<IAclCampaignState, IState>,
                                                {id, onSuccess, onFailure}) {
    try {
      commit(MutationTypeAclCampaign.AclCampaignLoading);
      const statistic = await aclCampaign.exportStatistic(id);
      if (onSuccess) {
        onSuccess(statistic);
      }
    } catch (error) {
      if (onFailure) {
        onFailure();
      }
      dispatch(ActionType.CatchException, error);
    } finally {
      commit(MutationTypeAclCampaign.AclCampaignLoaded);
    }
  },
  async [ActionTypeAclCampaign.GetQualifiedShops]({
    commit, dispatch
  }: ActionContext<IAclCampaignState, IState>,
                                                  {pagination}) {
    try {
      commit(MutationTypeAclCampaign.AclCampaignLoading);
      const { page, limit } = pagination;
      const offset = (page - 1) * limit;
      const listQualifiedShops = await aclCampaign.getQualifiedShopsList(limit, offset);
      if (listQualifiedShops) {
        commit(MutationTypeAclCampaign.SetQualifiedList, listQualifiedShops);
      }
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    } finally {
      commit(MutationTypeAclCampaign.AclCampaignLoaded);
    }
  },
  async [ActionTypeAclCampaign.ApproveAll]({
    commit, dispatch
  }: ActionContext<IAclCampaignState, IState>,
                                           {id, onSuccess, onFailure}) {
    try {
      commit(MutationTypeAclCampaign.AclCampaignLoading);
      const result = await aclCampaign.approveAll(id);
      if (onSuccess) {
        onSuccess(result);
      }
    } catch (error) {
      if (onFailure) {
        onFailure();
      }
      dispatch(ActionType.CatchException, error);
    } finally {
      commit(MutationTypeAclCampaign.AclCampaignLoaded);
    }
  },
  async [ActionTypeAclCampaign.GetDealerByBranch]({
    commit, dispatch
  }: ActionContext<IAclCampaignState, IState>,
                                                  {name, onSuccess, onFailure}) {
    try {
      commit(MutationTypeAclCampaign.AclCampaignLoading);
      const result = await aclCampaign.getDealerByBranch(name);
      if (onSuccess) {
        onSuccess(result);
      }
    } catch (error) {
      if (onFailure) {
        onFailure();
      }
      dispatch(ActionType.CatchException, error);
    } finally {
      commit(MutationTypeAclCampaign.AclCampaignLoaded);
    }
  },
  async [ActionTypeAclCampaign.GetJoinedShop]({
    commit, dispatch
  }: ActionContext<IAclCampaignState, IState>,
                                              {search, notJoin, status, branch, saleFilter,
                                                notReview, branchFilter, pagination}) {
    try {
      commit(MutationTypeAclCampaign.AclCampaignLoading);
      const { page, limit } = pagination;
      const offset = (page - 1) * limit;
      const listJoinedShop = await aclCampaign.getJoinedShopList(
        search, notJoin, status, branch, saleFilter, notReview, branchFilter, limit, offset);
      if (listJoinedShop) {
        commit(MutationTypeAclCampaign.SetJoinedShopList, listJoinedShop);
      }
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    } finally {
      commit(MutationTypeAclCampaign.AclCampaignLoaded);
    }
  },
  async [ActionTypeAclCampaign.GetConfigCropPicture]({
    dispatch
  }: ActionContext<IAclCampaignState, IState>,
                                                     {onSuccess}) {
    try {
      const config = await aclCampaign.getConfigCropPicture();
      if (onSuccess) {
        onSuccess(config);
      }
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    }
  },
};
