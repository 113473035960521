import { crudMutations, CRUDState, ICRUDState } from 'root/store/helpers';
import { MutationTypeDealer } from './types';

export interface IDealerState extends ICRUDState<any> {
  loading: boolean;
  upgradeRequest: any;
  subDealer: any[];
  bankInfo: any[];
  dealerType: any[];
}

export const defaultState: IDealerState = {
  ...CRUDState(),
  loading: false,
  upgradeRequest: [],
  subDealer: [],
  bankInfo: [],
  dealerType: [],
  // pagination: {
  //   limit: 10,
  //   page: 1
  // }
};
export const mutations = {
  ...crudMutations('dealer'),
  [MutationTypeDealer.GetUpgradeRequestMutation](state: IDealerState, data) {
    state.upgradeRequest = data;
  },
  [MutationTypeDealer.GetListSubDealer](state: IDealerState, data) {
    state.subDealer = data.dealers;
    state.pagination.total = data.dealers_aggregate.aggregate.count;
  },
  [MutationTypeDealer.SetBank](state: IDealerState, data) {
    state.bankInfo = data.banks;
  },
  [MutationTypeDealer.SetDealerType](state: IDealerState, data) {
    state.dealerType = data.dealer_types;
  }
};
