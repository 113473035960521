// import { PhoneNumberUtil } from 'google-libphonenumber';
import { isEmpty, isNil, isNumber, isString } from 'lodash';
import moment from 'moment';
import { getLatLngFromCoordinatorString } from 'root/helpers/formatter';
import { i18n } from 'root/locales';

export interface IMessage {
  text: string;
}

function parseMessage(message: string | IMessage, callback) {
  const result = typeof message === 'string' ?
    i18n().t(message).toString() : message.text;

  return callback(new Error(result));
}

function wrapRule(rule) {
  return { validator: rule, trigger: ['blur', 'change'] };
}

export function ruleRequired(message = 'field_required') {
  return wrapRule((_rule, value, callback) => {
    if (isNil(value)) {

      return parseMessage(message, callback);
    }

    return (isString(value) && !!value.trim() && callback())
      || (Array.isArray(value) ? value.length > 0 && callback()
        : (!isNil(value) && value !== '' ? callback() : false))
      || (isNumber(value) ? callback() : false)
      || parseMessage(message, callback);
  });
}

export function ruleEmail(message = 'email_invalid') {
  return wrapRule((_rule, value, callback) => {
    // tslint:disable
    const pattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    // tslint:enable

    return !value || pattern.test(value.trim()) || parseMessage(message, callback);
  });
}

export function ruleEmailNoRequired(message = 'email_invalid') {
  return wrapRule((_rule, value, callback) => {
    // tslint:disable
    const pattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    // tslint:enable

    if (isNil(value) || isEmpty(value)) {
      return callback();
    }

    return !value || (pattern.test(value.trim()) && callback()) || parseMessage(message, callback);
  });
}
export function ruleCompare(targetVaule: string, message = 'value_not_compare') {
  return wrapRule((_rule: any, value: string, callback: any) => {
    if (isNil(value)) {
      return parseMessage(message, callback);
    }

    return (typeof value === 'string' && value === targetVaule && callback())
      || parseMessage(message, callback);
  });
}

export function ruleMinValue(targetVaule, message: any = 'value.min') {
  return wrapRule((_rule: any, value: string, callback: any) => {
    if (isNil(value)) {
      return parseMessage(message, callback);
    }

    return Number(value) > targetVaule ? callback() : parseMessage(message, callback);
  });
}
export function ruleMaxValue(targetVaule, message: any = 'value.max') {
  return wrapRule((_rule: any, value: string, callback: any) => {
    if (isNil(value)) {
      return parseMessage(message, callback);
    }

    return (Number(value) < targetVaule) ? callback() : parseMessage(message, callback);
  });
}

export function ruleNumber(message?: string) {
  message = message || 'invalid_number';
  const pattern = /^[0-9]*$/;

  return wrapRule((_rule, value, callback) => {
    return pattern.test(value) ? callback() : parseMessage(message, callback);

  });
}
export function ruleNumberInteger(message?: string) {
  message = message || 'invalid_number';
  const pattern = /^[1-9]\d*$/;

  return wrapRule((_rule, value, callback) => {
    return !!(pattern.test(value)) || parseMessage(message, callback);

  });
}
export function ruleNumberFLoat(message?: string) {
  message = message || 'invalid_number';
  const pattern = /^[0-9]+(\.[0-9]{1,20})?$/;

  return wrapRule((_rule, value, callback) => {
    return !!(pattern.test(value)) || parseMessage(message, callback);

  });
}
export function rulePhoneNumber(message?: string) {
  message = message || 'invalid_phone_number';
  // const util = PhoneNumberUtil.getInstance();
  const pattern = /^(\+|)[0-9]*$/;

  return wrapRule((_rule, value, callback) => {

    return (value.length >= 8 && pattern.test(value)) || parseMessage(message, callback);
  });
}

export function ruleMinLength(min: number, message?: string) {

  message = message || `At_least_${min}_characters`;

  return wrapRule((_rule, value, callback) => {
    return (value && value.length >= min) || parseMessage(message, callback);
  });
}
export function ruleLength(length: number, message?: string) {

  message = message || `At_least_${length}_characters`;

  return wrapRule((_rule, value, callback) => {
    return !value || value.length === length || parseMessage(message, callback);
  });
}

export function ruleMaxLength(max: number, message?: string) {

  message = message || `Maximum ${max} characters`;

  return wrapRule((_rule, value, callback) => {
    return (value && value.length <= max) ? callback() : parseMessage(message, callback);
  });
}

export function rulePositiveNumber() {
  const message = `Field numbers must be positive integers`;

  return wrapRule((_rule, value, callback) => {
    return (value && parseInt(value, 10) > 0) ? callback() : parseMessage(message, callback);
  });
}

export function ruleBetween(min: number, max: number, message?: string) {

  message = message || `Character length must be between ${min} and ${max}`;

  return wrapRule((_rule, value, callback) => {
    return (value && value.length >= min && value.length <= max) || parseMessage(message, callback);
  });
}

export function ruleVersionRex(message = 'version_invalid') {
  return wrapRule((_rule, value, callback) => {
    const isValid = /^(\d+\.)?(\d+\.)?(\d+\.)?(\*|\d+)$/.test(value);

    return isValid ? callback() : parseMessage(message, callback);
  });
}

export function matchYoutubeUrl(message = 'youtube_link_invalid') {
  return wrapRule((_rule, value, callback) => {
    // tslint:disable
    const pattern = /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
    // tslint:enable

    return !value || pattern.test(value.trim()) || parseMessage(message, callback);
  });
}

export function rulePastTime(limit = 10, message = 'cannot_select_past_time') {
  return wrapRule((_rule, value, callback) => {
    const mvalue = moment(value);
    const now = moment().seconds(0).milliseconds(0);
    const isPastTime = mvalue < now.add(limit, 'minutes');

    return !value || !isPastTime || parseMessage(message, callback);
  });
}

export function notAllowSpacingContent(message = 'empty_content') {
  return wrapRule((_rule, value, callback) => {
    return value.trim() === '' ? parseMessage(message, callback) : callback();
  });
}

/*Use this function to validate lat lng when it not required but when input one field require other field*/
export function latLngMuchBeProvided(otherValue = '', message = 'lat_lng_validation') {
  return wrapRule((_rule, value, callback) => {
    if ((!value || value === '') && (!otherValue || otherValue === '')) {
      return callback();
    }

    const pattern = /^[0-9]+(\.[0-9]{1,20})?$/;

    return !otherValue || otherValue === '' ?
      parseMessage(message, callback) : value && value !== '' && pattern.test(value) ?
        callback() : parseMessage('invalid_number', callback);
  });
}

export function validateLatLngText(message = 'invalid_lat_lng') {
  return wrapRule((_rule, value, callback) => {
    if (!value || value === '') {
      return callback();
    }
    const parsedCoordinator = getLatLngFromCoordinatorString(value);
    if (!parsedCoordinator || (parsedCoordinator && (!parsedCoordinator.lat || !parsedCoordinator.long))) {
      return parseMessage(message, callback);
    }
    const pattern = /^[0-9]+(\.[0-9]{1,20})?$/;

    return pattern.test(parsedCoordinator.lat.toString()) && pattern.test(parsedCoordinator.lat.toString()) ?
      callback() : parseMessage(message, callback);
  });
}
export function ruleUrl(message = 'url_invalid') {
  return wrapRule((_rule, value, callback) => {
    // tslint:disable
    const pattern = new RegExp('^(https?:\\/\\/)?' +
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' +
      '((\\d{1,3}\\.){3}\\d{1,3}))' +
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' +
      '(\\?[;&a-z\\d%_.~+=-]*)?' +
      '(\\#[-a-z\\d_]*)?$', 'i');
    // tslint:enable

    return !value || pattern.test(value.trim()) || parseMessage(message, callback);
  });
}
