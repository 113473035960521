import { FormatDateFullTime } from 'root/helpers';
import { BannersDefault, IBanners } from 'root/models';
import { IState } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';

@Component({
  template: require('./view.html'),
  props: {
    visible: {
      type: Boolean,
      default: false,
      required: true
    },
    detail: {
      type: Object,
      default: BannersDefault(),
      required: true
    }
  },
  computed: {
    ...mapState({
      authUser: (state: IState) => state.global.authUser,
      branch: (state: IState) => state.branchManage.data,
      isRefesh: (state: IState) => state.global.isRefesh
    }),
  }
})
export class BannerDetail extends Vue {
  public visible: boolean;
  public detail: IBanners;
  public branch: any[];
  public FormatDateFullTime = FormatDateFullTime;
  public formatType(type: string): string {
    return type.charAt(0).toUpperCase() + type.slice(1);
  }
  public renderBranch(value: any) {
    const dataBranch = [];
    if (value.length) {
      this.$props.detail.branchBanners.map((it) => {
        const _branch = this.branch.filter((item) => item.id === it.branchId);
        if (_branch) {
          dataBranch.push(_branch[0].name);
        }
      });
    }

    return dataBranch.map((it) => it).join(', ');
  }
  public closeDialog() {
    this.$emit('closeViewDetailDialog');
  }
}
