// import { stripObject } from '@hgiasac/helper';
import { stripObject } from '@hgiasac/helper';
import { Form } from 'element-ui';
import { cloneDeep } from 'lodash';
import { ActionTypeRegion } from 'root/admin/Region/Store/types';
import { ActionTypeUser, MutationTypeUser, SystemRoleAlias } from 'root/admin/User/Store/types';
import { Avatar } from 'root/components';
import { AddressSelection } from 'root/components/AddressSelection';
import {
 ruleEmail, ruleEmailNoRequired, ruleMinLength, ruleNumber, rulePhoneNumber,
  ruleRequired
} from 'root/helpers';
import { branchFormDefault, IBranchForm } from 'root/models/BranchGroupManage';
import { IPartner } from 'root/models/Partner';
import {
  groupSystemUserSelection,
  EGender, IUser
} from 'root/models/User';
import { pathUpload } from 'root/services';
import { IState, MutationType, TypeAlert} from 'root/store';
// import { IState, MutationType, TypeAlert } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import { ActionTypeBranchManage } from '../../Store/types';
// import { ActionTypeDealer } from '../../Store/types';
import './styles.scss';

const genderOptions = [
  {
    value: EGender.Male,
    label: EGender.Male
  },
  {
    value: EGender.Female,
    label: EGender.Female
  }
];

@Component({
  template: require('./view.html'),
  components: {
    avatar: Avatar,
    'address-selection': AddressSelection
  },
  props: {
    visible: Boolean,
    userId: String,
    visibleExtend: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState({
      authUser: (state: IState) => state.global.authUser,
      userDetailLoading: () => false,
      manager: (state: IState) => state.user.data,
      detail: (state: IState) => state.branchManage.detail,
      isRefesh: (state: IState) => state.global.isRefesh,
      region: (state: IState) => state.region.data
    }),
    cloneManagerOptions() {
      const manager: any[] = cloneDeep(this.manager);

      return manager.filter((e) =>
      e.role.alias === SystemRoleAlias.BranchManager).map((e) => {
        return {
          label: e.name,
          value: e.id
        };
      });
    },
    clonePICOptions() {
      const manager: any[] = cloneDeep(this.manager);

      return manager.filter((e) =>
      e.role.alias === SystemRoleAlias.BranchPIC).map((e) => {
        return {
          label: e.name,
          value: e.id
        };
      });
    },
    cloneRegionOptions() {
      const region: any[] = cloneDeep(this.region);

      return region.map((e) => {
        return {
          label: e.name,
          value: e.id
        };
      });
    },
    cloneStations() {
      // if (this.isSystemUser && this.form.permission === 'head_of_station') {
      //   return this.stations.filter((e) => !this.stationIdsHaveHeader.includes(e.id));
      // }

      return this.stations;
    }
  },
})

export class BranchEditor extends Vue {

  public get isUpdating(): boolean {
    return !!this.userId;
  }
  public get rules() {
    return {
      required: ruleRequired(),
      rulePhoneNumber: rulePhoneNumber(),
      min: ruleMinLength(8),
      ruleEmail: ruleEmail(),
      ruleEmailNoRequired: ruleEmailNoRequired(),
      number: ruleNumber()
    };
  }

  public $refs: {
    form: Form
  };
  public loading: boolean = false;
  public authUser: IUser;
  public userDetail: IUser;
  public roleOptions: any[];
  public form: IBranchForm = branchFormDefault();
  public groupSystemUserSelection = groupSystemUserSelection();
  // public stationBranchOptions = stationBranchOptions();
  public stationGroupOptions = groupSystemUserSelection();
  public fileUpload: File = null;
  public detail: IUser;
  public route: any;
  // public userType: UserType;
  public userId: string;
  public technicianRates: any;
  public partners: IPartner[];
  public partnerHaveCoordinator: string[] = [];
  public validateTechnicianCode: {
    status?: string,
    message?: string
  } = null;
  public genderOptions = genderOptions;
  public isDealer: boolean = true;
  public cloneRoleOptions: any[];
  public isSub: boolean = false;
  public addressText = '';
  public isRefesh: boolean;
  public bookingCodes: any[] = [];
  public isDisable: boolean;
  public manager: any[];
  public mutiManager: any[] = [];

  private pathUploadAvatar: pathUpload = pathUpload.SYSTEM_USER;
  // private stationIdsHaveHeader: string[] = [];
  // private subscribe: any = null;
  // private subscribePartnerHaveCoordinator: Subscription = null;
  private querySearchPartner: string = '';
  public remoteMethodService(query: string) {
    this.addressText = query;
  }

  public handleRemoveService(id: string) {
    this.isDisable = false;
    this.bookingCodes = this.bookingCodes.filter((e) => e.id !== id);
    this.form.branchManagers = this.form.branchManagers.filter((e) => e !== id);
  }

  public changeForm() {
    //
  }
  public change(cod: any[]) {
    this.isDisable = false;
    this.bookingCodes = cloneDeep(this.manager).filter((it) => cod.includes(it.id));
  }
  public reset() {
    this.isDisable = true;
    this.bookingCodes = [];
  }
  public async submit() {
    this.$refs.form.validate(async (valid) => {
      if (valid) {
        this.loading = true;
        // tslint:disable-next-line: whitespace
        const form = <any>cloneDeep(this.form);
        this.handleSystemUser(form);
      }

      return;
    });
  }

  public back() {
    this.$emit('update:visible', false);
  }
  public openDialog() {
    if (!this.isRefesh) {
      this.$store.dispatch(ActionTypeRegion.FilterNoCache);
      this.$store.commit(MutationTypeUser.FilterChange, stripObject({
        _and: {
          mode: 'special',
          data: {
            role: {alias: {
              _in: [
                SystemRoleAlias.BranchManager, SystemRoleAlias.BranchPIC
              ]
            }},
            managerBranch: {
              managerId: {
                _is_null: true
              }
            },
          }
        }
      }));
      this.$store.dispatch(ActionTypeUser.FilterNoCache);
    }
    if (this.userId) {
      this.$store.dispatch(ActionTypeBranchManage.BranchGetById, {
        id: this.userId
      });
      this.form = this.$store.getters.getBranhManageDetail(this.userId);
    }
    if (this.form) {
      this.bookingCodes = this.form.branchManagers;
      if (this.form.branchManagers && this.form.branchManagers.length) {
        const managers = [];
        this.form.branchManagers.map((it) => {
          managers.push(it.id);
        });

        return this.form.branchManagers = managers;
      }
    }
    setTimeout(() => {
      if (this.$refs.form) {
        this.$refs.form.clearValidate();
      }
    }, 0);

  }
  public closeDialog() {
    this.form = branchFormDefault();
    this.addressText = '';
    this.isDealer = true;
    this.back();
  }
  public confirmClose() {
    this.$msgbox({
      title: this.$t('confirm').toString(),
      message: this.$t('action_confirm_message').toString(),
      showCancelButton: true,
      confirmButtonText: this.$t('confirm').toString(),
      cancelButtonText: this.$t('cancel').toString(),
      beforeClose: (action, _instance, done) => {
        if (action === 'confirm') {
          this.closeDialog();
          done();
        } else {
          done();
        }

        return;
      }
    });
  }

  public remoteMethod(query: string) {
    if (query !== '') {
      this.querySearchPartner = query;
    }
  }

  protected mounted() {
    // if (!this.isRefesh) {
    //   this.$store.dispatch(ActionTypeRegion.FilterNoCache);
    //   this.$store.commit(MutationTypeUser.FilterChange, stripObject({
    //     role: {alias: SystemRoleAlias.BranchManager},
    //     _and: {
    //       mode: 'special',
    //     }}));
    //   this.$store.dispatch(ActionTypeUser.FilterNoCache);
    //   if (this.userId) {
    //     this.$store.dispatch(ActionTypeBranchManage.BranchGetById, {
    //       id: this.userId
    //     });
    //   }
    // }

    // return;
  }

  protected beforeDestroy() {
    this.querySearchPartner = '';
    // this.$store.commit(MutationTypeTechnicianRate.ResetState);
  }

  private async handleSystemUser(form: any) {
    const postForm = {
      managerId: form.branchManagers
    };
    const formBranch = {
      name: form.name,
      address: form.address,
      description: form.description,
      branchCode: form.branchCode,
      updatedBy: this.authUser.id,
      regionId: form.region.id ? form.region.id :  form.region
    };
    if (this.userId) { // update
      this.$store.dispatch(ActionTypeBranchManage.updateBranch, {
        form: {
          ...formBranch,
          updatedBy: this.authUser.id
        },
        formManager: postForm,
        id: this.userId,
        onSuccess: () => {
          this.loading = false;
          const message = 'Update successfully';
          this.$store.commit(MutationType.OpenTopAlert, {
            message,
            type: TypeAlert.Success
          });
          this.$store.dispatch(ActionTypeBranchManage.GetListBranch, {
            form: {
              filter: {},
              pagination: {}
            }
          });
          this.closeDialog();
        },
        onFailure: () => {
          this.loading = false;
        }
      });
    } else { // create
      const formGroup = {
        name: form.name,
        address: form.address,
        description: form.description,
        branchCode: form.branchCode,
        createdBy: this.authUser.id,
        regionId: form.region
      };
      this.$store.dispatch(ActionTypeBranchManage.InsertBranch, {
        form: {
          ...stripObject(formGroup)
        },
        formManager: {
          ...stripObject(postForm)
        },
        onSuccess: () => {
          this.loading = false;
          const message = 'Create successfully';
          this.$store.commit(MutationType.OpenTopAlert, {
            message,
            type: TypeAlert.Success
          });
          this.$store.dispatch(ActionTypeBranchManage.GetListBranch, {
            form: {
              filter: {},
              pagination: {}
            }
          });
          this.closeDialog();
        },
        onFailure: () => {
          this.loading = false;
        }
      });
    }
  }
}
