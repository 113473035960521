import { IFilterInput } from 'root/models';
import Vue from 'vue';
import Component from 'vue-class-component';
import './styles.scss';

@Component({
  template: require('./view.html'),
  props: {
    data: {
      type: Array,
      default: () => {
        return [];
      }
    },
  },
})

export class FilterByBranch extends Vue {
  public data: IFilterInput[];
  public selected: IFilterInput[] = [];
  public resultSearch: IFilterInput[] = [];

  public get dataFilter() {
    return this.resultSearch.length > 0 ? this.resultSearch : this.data;
  }

  public clickFilter(name) {
    if (this.selected.indexOf(name) < 0) {
      this.selected.push(name);
      this.$emit('change', this.selected);

      return;
    }
    const _index = this.selected.indexOf(name);
    this.selected.splice(_index, 1);
    this.$emit('change', this.selected);
  }
  public formaterCheck(name: any) {
    if (this.selected.indexOf(name) >= 0) {

      return 'btn-filter-checked';
    }

    return 'btn-filter-uncheck';
  }
}
