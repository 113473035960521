import './styles.scss';

import { BannerRouterPath } from 'root/admin/BannerManagement';
import { BranchAndGroupRouterPath } from 'root/admin/BranchAndGroup';
import { CustomCampaignRouterPath } from 'root/admin/CustomCampaign';
import { NewRaCampaignRouterPath } from 'root/admin/CustomCampaign/NewRACampaign2023';
import { SaleInCampaignRouterPath } from 'root/admin/CustomCampaign/SaleInCampaign';
import { ActionTypeSaleInCampaign } from 'root/admin/CustomCampaign/SaleInCampaign/Store/types';
import { SalesEduCampaignRouterPath } from 'root/admin/CustomCampaign/SalesEduCampaign';
import { ZeroPercentCampaignRouterPath } from 'root/admin/CustomCampaign/ZeroPercentCampaign';
import { DealerRouterPath } from 'root/admin/Dealer';
import { ManageFeedbackRouterPath } from 'root/admin/ManageFeedback';
import { ManageImportRouterPath } from 'root/admin/ManageImport';
import { ManageInvoiceRouterPath } from 'root/admin/ManageInvoice';
import { ProductManageRouterPath } from 'root/admin/ProductManage';
import { RedemptionRouterPath } from 'root/admin/Redemption';
import { UserRouterPath } from 'root/admin/User';
import { SystemRoleAlias } from 'root/admin/User/Store/types';
import { DKGqlClient } from 'root/api/graphql/Client';
import { PermissionType } from 'root/models';
import { IState } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import { NewsNotificationsRouterPath } from '../../admin/NewsAnnoucement';
import { Avatar } from '../Avatar';

@Component({
  template: require('./view.html'),
  components: {
    avatar: Avatar
  },
  computed: {
    ...mapState({
      authUser: (state: IState) => state.global.authUser,
      pinedList: (state: IState) => state.saleInCampaign.pinedList,
    })
  },
  props: {
    isCollapse: {
      type: Boolean,
      default: false
    },
    version: String,
    hasNewVersion: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    $route(val) {
      this.activeRoute = val.path;
    }
  }
})
export class Navigator extends Vue {
  public authUser;
  public version;
  public pinedList: any[];
  public activeRoute: string = window.location.pathname || '';
  public isCollapse: boolean;
  public config: any[] = [];
  public saleInCampaignsPined: any[] = [];
  public get pinedListSaleInCampaign() {
    return this.pinedList.map((campaign) => ({
      path: `${SaleInCampaignRouterPath.Pined}/${campaign.id}`,
      label: `${campaign.campaignCode}`,
      showSubIcon: true,
      icon: 'pined-campaign'
    }));
  }
  public get navigations() {
    const dealerChild = [
      {
        path: DealerRouterPath.ListDealer,
        label: 'Direct Dealer',
      },
      {
        path: DealerRouterPath.SubDealer,
        label: 'Sub-Dealer',
      },
      {
        path: DealerRouterPath.Saleman,
        label: 'Salesman',
      }
    ];
    const branchpicChild = [
      {
        path: DealerRouterPath.ListDealer,
        label: 'Direct Dealer',
      },
      {
        path: DealerRouterPath.SubDealer,
        label: 'Sub-Dealer',
      },
    ];
    const departmentpicChild = [
      {
        path: DealerRouterPath.ListDealer,
        label: 'Direct Dealer',
      },
    ];
    const operatorChild = [
      {
        path: DealerRouterPath.SubDealer,
        label: 'Sub-Dealer',
      },
      {
        path: DealerRouterPath.Saleman,
        label: 'Salesman',
      }
    ];
    const feedbackChild = [
      {
        path: ManageFeedbackRouterPath.ChangeProfileList,
        label: 'Change Profile',
      },
      {
        path: ManageFeedbackRouterPath.ApplicationFeedbackList,
        label: 'Application Feedback',
      },
      {
        path: ManageFeedbackRouterPath.SerciceFeedbackList,
        label: 'Service Feedback',
      }
    ];
    const feedbackBranchChild = [
      {
        path: ManageFeedbackRouterPath.ChangeProfileList,
        label: 'Change Profile',
      },
    ];
    const regionChild = [
      {
        path: BranchAndGroupRouterPath.ListRegion,
        label: 'Region',
      },
      {
        path: BranchAndGroupRouterPath.ListBranch,
        label: 'Branch',
      },
      {
        path: BranchAndGroupRouterPath.ListGroupManage,
        label: 'Sale Department',
      }
    ];
    const branchChild = [
      {
        path: BranchAndGroupRouterPath.ListBranch,
        label: 'Branch',
      },
      {
        path: BranchAndGroupRouterPath.ListGroupManage,
        label: 'Sale Department',
      }
    ];
    const groupChild = [
      {
        path: BranchAndGroupRouterPath.ListGroupManage,
        label: 'Sale Department'
      }
    ];
    const { role } = this.authUser;

    return [
      {
        groupName: 'menu',
        redirect: UserRouterPath.List,
        children: [
          {
            path: '/users',
            label: 'System User',
            icon: 'systemuser',
            permission: PermissionType.Systemuser,
          },
          ...role && role.alias !== SystemRoleAlias.SaleStaff ? [
            {
              path: '/manager-dealers',
              label: 'Dealers',
              collapse: true,
              icon: 'dealers',
              permission: PermissionType.Dealers,
              children: role && (role.alias === SystemRoleAlias.CallCenter)
                || role && (role.alias === SystemRoleAlias.Operator)
                ? operatorChild : role && (role.alias === SystemRoleAlias.BranchManager ||
                  role.alias === SystemRoleAlias.BranchPIC) ? branchpicChild :
                  role && (role.alias === SystemRoleAlias.GroupLeader ||
                    role.alias === SystemRoleAlias.SaleDepartmentPic) ? departmentpicChild : dealerChild
            }
          ] : [],
          ...role && role.alias !== SystemRoleAlias.SaleStaff ? [
            {
              path: BranchAndGroupRouterPath.BranchAndGroup,
              label: 'Department',
              icon: 'branchgroup',
              collapse: true,
              permission: PermissionType.Branchgroup,
              children: role && (role.alias === SystemRoleAlias.Admin || role.alias === SystemRoleAlias.SaleAdmin)
                ? regionChild : (role.alias === SystemRoleAlias.BranchManager ||
                  role.alias === SystemRoleAlias.BranchPIC
                  || role.alias === SystemRoleAlias.CallCenter) ? branchChild : groupChild
            }
          ] : [],
          ...role && role.alias !== SystemRoleAlias.BranchPIC && role.alias !== SystemRoleAlias.SaleDepartmentPic
          && role.alias !== SystemRoleAlias.SaleStaff ? [
            {
              path: '/acl-campaign',
              label: 'Campaign Y2021',
              icon: 'evaluate-display-photo',
              collapse: true,
              permission: PermissionType.CustomCampaign,
              children: [
                // {
                //   path: CustomCampaignRouterPath.CustomCampaign,
                //   label: 'Evaluate Display Photo'
                // },
                {
                  path: CustomCampaignRouterPath.ACLCampaignList,
                  label: 'ACL Display'
                },
                // {
                //   path: CustomCampaignRouterPath.RACampaignList,
                //   label: 'FY22 RA peak season display',
                //   isLineBreak: true
                // },
              ]
            }
          ] : [],
          ...role && role.alias !== SystemRoleAlias.BranchPIC && role.alias !== SystemRoleAlias.SaleDepartmentPic
          && role.alias !== SystemRoleAlias.SaleStaff ? [
            {
              path: '/ra-campaign',
              label: 'Campaign Y2022',
              icon: 'ra-campaign',
              collapse: true,
              permission: PermissionType.raCampaign,
              children: [
                // {
                //   path: CustomCampaignRouterPath.CustomCampaign,
                //   label: 'Evaluate Display Photo'
                // },
                // {
                //   path: CustomCampaignRouterPath.ACLCampaignList,
                //   label: 'ACL Display'
                // },
                {
                  path: CustomCampaignRouterPath.RACampaignList,
                  label: 'FY22 RA peak season display',
                  isLineBreak: true
                },
              ]
            }
          ] : [],
          {
            path: '/zero-percent-campaign',
            label: 'Year Round Support',
            icon: 'zero-percent-campaign',
            collapse: true,
            permission: PermissionType.PerCampaign,
            children: [
              // {
              //   path: CustomCampaignRouterPath.CustomCampaign,
              //   label: 'Evaluate Display Photo'
              // },
              // {
              //   path: CustomCampaignRouterPath.ACLCampaignList,
              //   label: 'ACL Display'
              // },
              {
                path: ZeroPercentCampaignRouterPath.Participant,
                label: '0% Campaign',
              },
            ]
          },
          ...this.checkModuleNavigator('new_ra_campaign') ? [
            ...this.isShowModuleByConfig('new_ra_campaign') ? [
              {
                path: '/2023-campaign',
                label: 'Campaign Y2023',
                icon: '2023-campaign',
                collapse: true,
                permission: PermissionType.NewRACampaign,
                children: [
                  {
                    path: NewRaCampaignRouterPath.Registration,
                    label: 'FY23 RA Peak Season Display',
                  },
                ]
              }
            ] : []
          ] : [
            ...role && (role.alias === SystemRoleAlias.Admin
              || role.alias === SystemRoleAlias.SaleAdmin
              || role.alias === SystemRoleAlias.BranchManager
              || role.alias === SystemRoleAlias.BranchPIC
              || role.alias === SystemRoleAlias.SaleDepartmentPic
              || role.alias === SystemRoleAlias.GroupLeader
              || role.alias === SystemRoleAlias.SaleStaff) ? [
                {
                  path: '/2023-campaign',
                  label: 'Campaign Y2023',
                  icon: '2023-campaign',
                  collapse: true,
                  permission: PermissionType.NewRACampaign,
                  children: [
                  // {
                  //   path: CustomCampaignRouterPath.CustomCampaign,
                  //   label: 'Evaluate Display Photo'
                  // },
                  // {
                  //   path: CustomCampaignRouterPath.ACLCampaignList,
                  //   label: 'ACL Display'
                  // },
                    {
                      path: NewRaCampaignRouterPath.Registration,
                      label: 'FY23 RA Peak Season Display',
                    },
                  ]
                },
              ] : [],
          ],
          ...role && (role.alias === SystemRoleAlias.Admin ||
            role.alias === SystemRoleAlias.SaleAdmin
            || role.alias === SystemRoleAlias.SaleDepartmentPic) ? [
              {
                label: 'Edu Sale Campaign 2023',
                icon: 'sale-edu-campaign',
                permission: PermissionType.SalesEduCampaign,
                collapse: true,
                children: [
                  {
                    path: SalesEduCampaignRouterPath.Default,
                    label: 'Banking Payment',
                  },
                ]
              },
            ] : [],
          ...role && (role.alias === SystemRoleAlias.Admin ||
              role.alias === SystemRoleAlias.SaleAdmin) ? [
                {
                  label: 'Sell In Campaign',
                  icon: 'sale-in-campaign',
                  permission: PermissionType.SaleInCampaign,
                  collapse: true,
                  children: [
                    {
                      path: SaleInCampaignRouterPath.Default,
                      label: 'Campaign Management',
                    },
                    {
                      path: SaleInCampaignRouterPath.Detail,
                      label: 'Pined Campaign',
                      collapse: true,
                      children: [
                        ...this.pinedListSaleInCampaign
                      ]
                    },
                    {
                      path: SaleInCampaignRouterPath.Product,
                      label: 'Application Product',
                    },
                    {
                      path: SaleInCampaignRouterPath.Reward,
                      label: 'Reward Management',
                    },
                  ]
                },
              ] : [],
          {
            label: 'Manage Invoice',
            icon: 'manage-invoice',
            permission: PermissionType.ManageInvoice,
            path: ManageInvoiceRouterPath.ListManageInvoice
          },
          {
            label: 'Manage Import',
            icon: 'manage-import',
            collapse: true,
            permission: PermissionType.ManageImport,
            // path: ManageImportRouterPath.ManageImportList
            children: [
              {
                path: ManageImportRouterPath.ManageImportDealer,
                label: 'Dealer',
              },
              {
                path: ManageImportRouterPath.ManageImportSubDealer,
                label: 'Sub-Dealer',
              },
            ]
          },
          // {
          //   label: 'Manage Dealer Type',
          //   icon: 'product',
          //   permission: PermissionType.DealerType,
          //   path: ManageDealerTypeRouterPath.DealerTypeList
          // },
          {
            path: ManageFeedbackRouterPath.ManageFeedbackList,
            label: 'Feedback',
            icon: 'feedback',
            collapse: true,
            permission: PermissionType.Feedback,
            children: role && (role.alias === SystemRoleAlias.Admin ||
              role.alias === SystemRoleAlias.SaleAdmin || role.alias === SystemRoleAlias.SystemAdmin)
              ? feedbackChild : feedbackBranchChild
          },
          {
            label: 'Product Registration',
            icon: 'product',
            permission: PermissionType.Product,
            path: ProductManageRouterPath.DealerProduct
          },
          {
            path: RedemptionRouterPath.Redemption,
            label: 'Campaign Management',
            icon: 'redemption',
            collapse: true,
            permission: PermissionType.Redemption,
            children: [
              {
                path: RedemptionRouterPath.Compaign,
                label: 'Campaign'
              },
              // {
              //   path: RedemptionRouterPath.Redemption,
              //   label: 'Redeem'
              // },
              // {
              //   path: RedemptionRouterPath.Condition,
              //   label: 'Campaign Condition'
              // }
            ]
          },
          {
            label: 'News',
            icon: 'news',
            permission: PermissionType.NewsAndAnnouncement,
            path: NewsNotificationsRouterPath.Notification
          },
          {
            label: 'Configuration',
            permission: PermissionType.Configuration,
            icon: 'configuration',
            path: BannerRouterPath.Default
          }
        ]
      }
    ];
  }
  public get currentNavigations() {
    const authUser = this.authUser,
      { role, superAdmin } = authUser;
    if (superAdmin || role && role.alias === SystemRoleAlias.Admin) {
      return this.navigations;
    }
    if (role && role.alias === SystemRoleAlias.SystemAdmin) {
      const adminNavi = [
        PermissionType.Systemuser,
        PermissionType.NewsAndAnnouncement,
        PermissionType.Feedback,
        PermissionType.ManageImport,
        PermissionType.Configuration,
      ];

      return this.navigations.map((el) => {
        return {
          ...el,
          children: el.children.filter((e) =>
            adminNavi.indexOf(e.permission) >= 0)
        };
      });
    }

    if (role && role.alias === SystemRoleAlias.SaleAdmin) {
      const saleNavi = [
        PermissionType.Dealers,
        PermissionType.Branchgroup,
        PermissionType.Feedback,
        PermissionType.CustomCampaign,
        PermissionType.raCampaign,
        PermissionType.PerCampaign,
        PermissionType.ManageImport,
        PermissionType.Product,
        PermissionType.NewRACampaign,
        PermissionType.Redemption,
        PermissionType.SalesEduCampaign,
        PermissionType.SaleInCampaign
      ];

      return this.navigations.map((el) => {
        return {
          ...el,
          children: el.children.filter((e) =>
            saleNavi.indexOf(e.permission) >= 0)
        };
      });
    }
    if (role && role.alias === SystemRoleAlias.Operator) {
      const adminNavi = [
        PermissionType.Dealers,
      ];

      return this.navigations.map((el) => {
        return {
          ...el,
          children: el.children.filter((e) =>
            adminNavi.indexOf(e.permission) >= 0)
        };
      });
    }
    const branchGroup = [SystemRoleAlias.BranchManager, SystemRoleAlias.BranchPIC,
      SystemRoleAlias.GroupLeader, SystemRoleAlias.SaleDepartmentPic, SystemRoleAlias.SaleStaff];
    if (role && branchGroup.includes(role.alias)) {
      const branchGroupNavi = [
        PermissionType.Dealers,
        PermissionType.Branchgroup,
        PermissionType.CustomCampaign,
        PermissionType.raCampaign,
        PermissionType.PerCampaign,
        PermissionType.Product,
        PermissionType.Feedback,
        PermissionType.NewRACampaign,
        PermissionType.SalesEduCampaign,
      ];

      return this.navigations.map((el) => {
        return {
          ...el,
          children: el.children.filter((e) =>
            branchGroupNavi.indexOf(e.permission) >= 0)
        };
      });
    }
    if (role && role.alias === SystemRoleAlias.CallCenter) {
      const branchGroupNavi = [
        PermissionType.Dealers,
      ];

      return this.navigations.map((el) => {
        return {
          ...el,
          children: el.children.filter((e) =>
            branchGroupNavi.indexOf(e.permission) >= 0)
        };
      });
    }
    // if (role && (role.alias === SystemRoleAlias.SaleAdmin)) {
    //   const saleNavi = [
    //     PermissionType.Configuration];

    //   return this.navigations.map((el) => {
    //     return {
    //       ...el,
    //       children: el.children.filter((e) =>
    //       saleNavi.indexOf(e.permission) < 0)
    //     };
    //   });
    // }
    if (role && (role.alias === SystemRoleAlias.RegionDirector)) {
      const saleNavi = [
        PermissionType.Configuration,
        PermissionType.Systemuser,
        PermissionType.CustomCampaign,
        PermissionType.raCampaign,
        PermissionType.PerCampaign,
        PermissionType.NewsAndAnnouncement,
        PermissionType.Redemption,
        PermissionType.DealerType,
      ];

      return this.navigations.map((el) => {
        return {
          ...el,
          children: el.children.filter((e) =>
            saleNavi.indexOf(e.permission) < 0)
        };
      });
    }
    if (role && (role.alias === SystemRoleAlias.TeamLeader || role.alias === SystemRoleAlias.Staff)) {
      const saleNavi = [
        PermissionType.Branchgroup
      ];

      return this.navigations.map((el) => {
        return {
          ...el,
          children: el.children.filter((e) =>
            saleNavi.indexOf(e.permission) >= 0)
        };
      });
    }
    const saleAdminnavi = [
      PermissionType.Configuration,
      PermissionType.DealerType,
    ];

    return this.navigations.map((el) => {
      return {
        ...el,
        children: el.children.filter((e) =>
          saleAdminnavi.indexOf(e.permission) < 0)
      };
    });
  }

  public get versionPortal() {
    return localStorage.version ? localStorage.version : '1.0.0';
  }

  public isShowItemChildren(path: string) {
    // check navigate child
    if (path === BranchAndGroupRouterPath.BranchAndGroup) {
      return false;
    }

    return true;
  }
  public checkModuleNavigator(moduleName: string) {
    const module = this.config.find((it) => it.moduleName === moduleName);
    if (module && module.isCheck === true) {
      return true;
    }

    return false;
  }
  public isShowModuleByConfig(moduleName: string) {
    const module = this.config.find((it) => it.moduleName === moduleName);
    if (module && module.roles.includes(this.authUser.role.alias) && module.emails.includes(this.authUser.email)) {
      return true;
    }

    return false;
  }
  public updateVersion() {
    this.$emit('updateVersion', this.version);
  }
  public async mounted() {
    const data = await DKGqlClient().configuration.getConfigNavigator();
    this.config = data;
    this.$store.dispatch(ActionTypeSaleInCampaign.GetPinedCampaignsList, {
      onSuccess: (result) => {
        this.saleInCampaignsPined = result;
      }
    });
  }
  public getSubMenuIcon(iconName: string) {
    return `/assets/img/${iconName}.svg`;
  }
  public getIconSource(iconName: string) {
    const activeIcon =
      this.$route.meta.permission &&
        this.$route.meta.permission.includes(iconName)
        ? '-active'
        : '';

    return `/assets/img/menu-icon/${iconName}${activeIcon}.png`;
  }
}
