import { chunk, cloneDeep } from 'lodash';
import { convertTextToSearch, FormatDateFullTime } from 'root/helpers';
// import { convertTextToSearch } from 'root/helpers';
import { IFilterInput, VoucherCodeStatus } from 'root/models';
import { IState } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import { ActionTypeRedemption } from '../../Store/types';
import './styles.scss';

@Component({
  template: require('./view.html'),
  props: {
    visible: Boolean,
    detailId: String
  },
  components: {
  },
  computed: {
    ...mapState({
      data: (state: IState) => state.redemption.voucher.data,
      loading: (state: IState) => state.redemption.voucher.loading,
      // counter: (state: IState) => state.vouchers.voucherCode.counter
    }),
    voucherCodeStatus() {
      const result: IFilterInput[] = [];
      for (const key in VoucherCodeStatus) {
        // tslint:disable-next-line:early-exit
        if (VoucherCodeStatus.hasOwnProperty(key)) {
          const element = VoucherCodeStatus[key];
          result.push({
            key,
            value: element,
            name: this.$t(element)
          });
        }
      }

      return [{
        key: 'status',
        name: this.$t('field.voucherStatus'),
        value: result
      }];
    },
    selectedStatus() {
      const filterParams = cloneDeep(this.filterParams);

      return [{
        key: 'status',
        value: filterParams.status ? filterParams.status : []
      }];
    }
  }
})

export class VoucherCodeList extends Vue {
  public searchText: string = '';
  public detailId: any;
  public data: any[];
  public formatDateFullTime = FormatDateFullTime;
  public filterParams: any = { status: [] };
  public counter: {
    used: number,
    available: number,
    expired: number
  } = {
    used: 0,
    available: 0,
    expired: 0
  };
  public get Counter() {
    if (this.data && this.data.length) {
      this.counter = {
        used: 0,
        available: 0,
        expired: 0
      };
      this.data.map((it) => {
        if (it.voucherCodeStatus === VoucherCodeStatus.Used) {

          return this.counter.used = this.counter.used + 1 ;
        }
        if (it.voucherCodeStatus === VoucherCodeStatus.Expired) {

          return this.counter.expired = this.counter.expired + 1 ;
        }

        return this.counter.available = this.counter.available + 1 ;
      });

      return this.counter;
    }

    return this.counter;
  }

  public getDealer(item) {
    if (item.dealerRewards && item.dealerRewards.length) {

      return item.dealerRewards[0].dealer && item.dealerRewards[0].dealer.name ?
      item.dealerRewards[0].dealer.name : '--';
    }

    return '--';
  }
  public getDateUsed(item) {
    if (item.dealerRewards && item.dealerRewards.length) {

      return item.dealerRewards[0].createdAt ?
       this.formatDateFullTime(item.dealerRewards[0].createdAt) : '--';
    }

    return '--';
  }
  public getVoucherCodeStatus(item) {
    if (item.voucherCodeStatus === VoucherCodeStatus.Used) {

      return VoucherCodeStatus.Used;
    }
    if (item.voucherCodeStatus === VoucherCodeStatus.Expired) {

      return VoucherCodeStatus.Expired;
    }

    return VoucherCodeStatus.Available;
  }

  public getIndex(i, index, item) {
    if (index === 0) {
      return i + 1;
    }

    return (i + 1) + (index * item.length);
  }

  public get getCodeListItemsToRender() {
    let outputList = this.data;
    const divideVal = Math.round(outputList.length / 3);
    outputList = chunk(outputList, divideVal === 0 ? 1 : divideVal);

    return outputList;
  }

  public changeFilter(model) {
    const statuses = model.find((e) => e.key === 'status');
    this.filterParams.status = statuses ? statuses.value : [];
    this.fetchData();
  }
  public onEnterFilter() {
    this.fetchData();
  }
  public fetchData() {
    let where= <any> {
      rewardId: {_eq: this.detailId}
    };
    if (this.searchText && this.searchText.trim()) {
      const text = convertTextToSearch(this.searchText.trim());
      where = {
        ...where,
        hashedCode: {_ilike: `%${text}%`}
      };
    }
    if (this.filterParams.status && this.filterParams.status.length) {
      where = {
        ...where,
        voucherCodeStatus: {
          _in: this.filterParams.status
        }
      };
    }

    return this.$store.dispatch(ActionTypeRedemption.GetListVoucher, {
      where
    });
  }
  public openDialog() {
    this.$store.dispatch(ActionTypeRedemption.GetListVoucher, {
      where: {
        rewardId: {_eq: this.detailId}
      }
    });
  }

  public closeDialog() {
    this.$emit('update:visible', false);
    this.$emit('closeDialog');
  }
}
