import { RouteConfig } from 'vue-router';
import { UserRouterPath } from '../User';
import { HelloWorld } from './HelloWorld';
import { WelcomePageContainer } from './WelcomePageContainer';

export enum WelcomePageRouterPath {}

export const WelcomePageRouter: RouteConfig = {
  path: '/',
  component: WelcomePageContainer,
  redirect: UserRouterPath.List,
  children: [
    {
      path: '/',
      component: HelloWorld,
      redirect: UserRouterPath.List
    }
  ]
};
