// import { cloneDeep } from 'lodash';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import { ActionTypeProduct, MutationTypeProduct } from 'root/admin/Product/Store/types';
import { ProductManageRouterPath } from 'root/admin/ProductManage';
import { warrantyStatus, warrantyStatusCode } from 'root/admin/ProductManage/ProductRegistation';
import { SystemRoleAlias } from 'root/admin/User/Store/types';
import { IPaginationParams } from 'root/api/graphql/Core';
import { formatterPhoneNumber, parseUTCToTime, FormatDateFullTime } from 'root/helpers';
import { ConfigurationKey, IAddress, IBreadcrumb, IFilterInput, IUser } from 'root/models';
import { IState, MutationType } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import { ProductRegistationDetail } from '../ProductRegistrationDetail';
import './styles.scss';

const enum FilterKey {
  DeviceType = 'deviceType',
  Status = 'status'
}
@Component({
  template: require('./view.html'),
  components: {
    detail: ProductRegistationDetail
  },
  computed: {
    ...mapState({
      authUser: (state: IState) => state.global.authUser,
      detail: (state: IState) => state.product.dealerDetail,
      data: (state: IState) => state.product.dealerProductlist,
      loading: (state: IState) => state.product.loading,
      pagination: (state: IState) => state.product.pagination,
      typeCollapse: (state: IState) => state.global.typeCollapse,
      deviceType: (state: IState) => state.product.deviceType,
    }),
  }
})
export class DealerProductDetail extends Vue {
  public get serviceId(): string {
    return this.$route.params.id;
  }
  public get isSalePlanning() {
    if (this.authUser.role.alias === SystemRoleAlias.SaleAdmin) {
      return true;
    }

    return false;
  }
  public get dataFilter() {
    const _deviceType: IFilterInput = this.deviceType.filter((type) =>
      this.ValidDeviceType.indexOf(type.code) >= 0).reduce((res, item) => {
        res.push({
          key: item.id,
          value: item.code,
          name: item.name
        });

        return res;
      }, []);
    const _status: IFilterInput[] = [
      {
        key: warrantyStatusCode.Success,
        value: warrantyStatusCode.Success,
        name: warrantyStatus.Success
      },
      {
        key: warrantyStatusCode.Failed,
        value: warrantyStatusCode.Failed,
        name: warrantyStatus.Failed
      },
    ];

    return this.isSalePlanning ? [{
      key: FilterKey.DeviceType,
      name: 'Device Type',
      value: _deviceType
    }] : [{
      key: FilterKey.DeviceType,
      name: 'Device Type',
      value: _deviceType
    },
    {
      key: FilterKey.Status,
      name: this.$t('field.status'),
      value: _status
    }];
  }
  public get selectedFilter() {
    const _filterParams = cloneDeep(this.filterParams);

    return [
      {
        key: FilterKey.DeviceType,
        value: _filterParams[FilterKey.DeviceType]
      },
      {
        key: FilterKey.Status,
        value: _filterParams[FilterKey.Status]
      }
    ];
  }
  public get columns() {
    return [
      {
        prop: 'review at',
        label: 'Model Number',
        formatter: (model: any) => {
          return model.modelNumber ? model.modelNumber : '--';
        },
        width: 180,
      },
      {
        prop: 'classification',
        label: 'Serial Number',
        formatter: (model: any) => {
          return model.serialNumber ? model.serialNumber : '--';
        },
        width: 180,
      },
      {
        prop: 'review content',
        label: 'Device Type',
        formatter: (model: any) => {
          return model.deviceType ? model.deviceType.name : '--';
        },
        width: 180,
      },
      {
        prop: 'name',
        label: 'Unit',
        formatter: (model: any) => {
          return model.type ? model.type : '--';
        },
        width: 180,
      },
      {
        prop: 'createAt',
        label: 'Date Created',
        width: 200,
        formatter: this.formatterCreatedDate
      },
      {
        prop: 'status',
        label: 'Status',
        width: 150,
        formatter: this.formatterWarrantyStatus,
        labelStyle: (model: any) => {
          return model.warrantyStatus;
        },
      },
      {
        prop: 'action',
        label: '',
        align: 'center',
        fixed: 'right',
        width: 60
      }
    ];
  }
  public searchText: string = '';
  public FormatDateFullTime = FormatDateFullTime;
  public formatDate = parseUTCToTime;
  public pagination: IPaginationParams;
  public deviceType: any;
  public authUser: IUser;
  public typeCollapse: boolean;
  public visible: boolean = false;
  public dealerId: string = '';
  public productId: string = '';
  public ValidDeviceType: any[] = [];
  public filterParams = {
    [FilterKey.DeviceType]: [],
    [FilterKey.Status]: []
  };
  public getAction() {

    return 'view';
  }
  public displayAddress(model: IAddress) {
    return model && model.province ? this.$store.getters['getAddressString'](model) : '';
  }
  public formatUTCDate(date) {
    return moment(date).utc().format('DD/MM/YYYY');
  }
  public formatterPhoneNumber(phone) {
    return formatterPhoneNumber(phone);
  }
  public formatterCreatedDate(model: any) {
    return model && model.createdAt ? moment(model.createdAt).format('HH:mm, DD/MM/YYYY') : '--';
  }
  public formatterWarrantyStatus(model: any) {
    if (model && model.warrantyStatus) {
      switch (model.warrantyStatus) {
      case warrantyStatusCode.Success:
        return warrantyStatus.Success;
      case warrantyStatusCode.Failed:
        return warrantyStatus.Failed;
      }
    }

    return '--';
  }
  public handleActions(account) {
    this.dealerId = account.createdBy;
    this.productId = account.id;
    this.visible = true;
  }

  public changeFilter(model) {
    const devicetype = model.find((e) => e.key === FilterKey.DeviceType);
    const status = model.find((e) => e.key === FilterKey.Status);
    const nextFilter = {
      [FilterKey.DeviceType]: devicetype ? devicetype.value : [],
      [FilterKey.Status]: status ? status.value : [],
    };
    this.filterParams = {
      ...cloneDeep(this.filterParams),
      ...nextFilter
    };
    this.fetchData();
  }

  public clickDetail() {
    // change after
  }

  public handleSearch() {
    this.fetchData();
  }

  public handlePaginationChange(value) {
    if (typeof value === 'number') {
      this.$store.commit(MutationTypeProduct.PaginationChange, {
        ...this.pagination,
        size: value
      });
    } else {
      this.$store.commit(MutationTypeProduct.PaginationChange, {
        ...this.pagination,
        ...value
      });
    }
    this.fetchData();
  }

  protected async mounted() {
    const _breadcrumb: IBreadcrumb[] = [
      {
        label: 'title.productRegistation',
        path: ProductManageRouterPath.DealerProduct,
        current: false
      },
      {
        label: 'title.view_details',
        path: null,
        current: true
      }
    ];
    this.$store.commit(MutationType.SetBack);
    this.$store.commit(MutationType.SetBreadcrumb, _breadcrumb);
    this.$store.commit(MutationType.SetShowCollapse);
    this.$store.commit(MutationType.SetTypeCollapse, true);
    this.$store.dispatch(ActionTypeProduct.GetDeviceType);
    const systemConfig = await this.$store.dispatch(ActionTypeProduct.getScanProductMode);
    if (systemConfig && systemConfig.length) {
      this.ValidDeviceType =
      systemConfig.find((e) => e.variableName === ConfigurationKey.ValidDeviceType).value;
    }
    this.fetchData();
  }
  protected beforeDestroy() {
    this.$store.commit(MutationType.ClearBreadcrumb);
    this.$store.commit(MutationType.ClearShowCollapse);
  }
  private fetchData() {
    const text = cloneDeep(this.searchText);
    const _filterParams = <any> {};
    if (this.filterParams[FilterKey.Status].length) {
      _filterParams.status = this.filterParams[FilterKey.Status];
    }
    if (this.filterParams[FilterKey.DeviceType].length) {
      _filterParams.deviceType = this.filterParams[FilterKey.DeviceType];
    }

    this.$store.dispatch(ActionTypeProduct.GetDealerProductDetail, {
      id: this.serviceId,
      search: `%${text}%`,
      deviceType: _filterParams.deviceType,
      status: this.isSalePlanning ? warrantyStatusCode.Success : _filterParams.status,
      pagination: this.pagination,
      onSuccess: () => {
          //
      },
      onFailure: () => {
          //
      }
    });
  }
}
