import { DocumentNode } from 'graphql';
import gql from 'graphql-tag';
import { crudQuery, ICRUDQuery } from '../Core/query';

const dealerShopModel = `
  address
  id
  name
  shopCode
  phoneNumber
  email
  dealerShops(where: {_and:[{isOwner: {_eq: false}},{contractStatus:{_eq:"accepted"}}]}) {
    dealer {
      name
      phoneNumber
      email
      address
    }
  }
  dealerShops_aggregate(where: {_and:[{isOwner: {_eq: false}},{contractStatus:{_eq:"accepted"}}]}) {
    aggregate {
      count
    }
  }
`;
const dealerShopDetail = `
  address
  createdAt
  name
  shopCode
  phoneNumber
  status
  updatedAt
  createdUser {
    id
    name
  }
  updatedUser {
    id
    name
  }
  dealerShops(where: {_and:[{isOwner: {_eq: false}},{contractStatus:{_eq:"accepted"}}]}) {
    dealer {
      name
      phoneNumber
      email
      address
    }
  }
  dealerShops_aggregate(where: {_and:[{isOwner: {_eq: false}},{contractStatus:{_eq:"accepted"}}]}) {
    aggregate {
      count
    }
  }
`;

export const dealerShop = 'dealerShop';
// tslint:disable-next-line:no-empty-interface
interface IDealerShopQuery extends ICRUDQuery {
  GET_DEALER_SHOP_LIST: DocumentNode;
  CREATE_SHOP: DocumentNode;
  GET_SHOP_DETAIL: DocumentNode;
  UPDATE_SHOP: DocumentNode;
}
export const dealerShopListQuery: IDealerShopQuery = {
  ...crudQuery(dealerShop, dealerShopModel),
  GET_DEALER_SHOP_LIST: gql`
    query GetDealerShop($id: uuid!, $limit:Int, $offset: Int, $search: String) {
      shops(where: {dealerShops: {dealerId: {_eq: $id}, isOwner: {_eq: true}},
         _and: {_or:[{name: {_ilike: $search}},{phoneNumber: {_ilike: $search}},{email:{_ilike:$search}}]}},
        limit: $limit, offset: $offset, order_by: {shopCode: asc}) {
      ${dealerShopModel}
    }
    shops_aggregate(where: {dealerShops: {dealerId: {_eq: $id}, isOwner: {_eq: true}},
       _and: {_or:[{name: {_ilike: $search}},{phoneNumber: {_ilike: $search}},{email:{_ilike:$search}}]}}) {
      aggregate {
        count
      }
    }
  }
`,
  CREATE_SHOP: gql`
    mutation CreateShops($dealerId: uuid!, $name: String!, $shopCode: String!,
      $address: jsonb, $email: String, $createdBy: uuid!) {
      insert_shops(objects: {dealerShops: {data: {dealerId: $dealerId, isOwner: true}}, name: $name,
         shopCode: $shopCode,  address: $address, email: $email, createdBy: $createdBy}) {
        affected_rows
      }
    }
  `,
  GET_SHOP_DETAIL: gql`
  query GET_SHOP_DETAIL($id: uuid!){
    shops(where: {id : {_eq: $id}}){
      ${dealerShopDetail}
    }
  }
  `,
  UPDATE_SHOP: gql`
    mutation UpdateShops($shopId: uuid!, $name: String!, $address: jsonb, , $updatedBy: uuid!) {
      update_shops(where: {id: {_eq: $shopId}}, _set: {name: $name, address: $address, updatedBy: $updatedBy}) {
        affected_rows
      }
    }
  `,
};
