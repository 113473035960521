// import { stripObject } from '@hgiasac/helper';
import { stripObject } from '@hgiasac/helper';
import { Form } from 'element-ui';
import { cloneDeep, omit } from 'lodash';
import { DKEditor } from 'root/components/DKCustomEditor';
import { ruleMaxLength, rulePositiveNumber, ruleRequired } from 'root/helpers';
import { redemptionFormDefault, IRedemptionForm } from 'root/models/Redemption';
import { IUser } from 'root/models/User';
import { IState, MutationType, TypeAlert } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapGetters, mapState } from 'vuex';
import { ConditionActionType } from '../../Condition/Store/types';
// import { ActionTypeDealer } from '../../Store/types';
import './styles.scss';

@Component({
  template: require('./view.html'),
  components: {
    'dk-editor': DKEditor
  },
  props: {
    visible: Boolean,
    userId: String,
    fromCallCenter: Boolean
  },
  computed: {
    ...mapGetters(['branch', 'group']),
    ...mapState({
      authUser: (state: IState) => state.global.authUser,
      userDetailLoading: () => false,
    })
  }
})
export class ConditionEditor extends Vue {
  public get isUpdating(): boolean {
    return !!this.userId;
  }
  public get rules() {
    return {
      required: ruleRequired(),
      max: ruleMaxLength(50),
      positive: rulePositiveNumber()
    };
  }

  public $refs: {
    form: Form;
  };
  public loading: boolean = false;
  public authUser: IUser;
  public userDetail: IUser;
  public form: IRedemptionForm = redemptionFormDefault();
  public detail: IUser;
  public route: any;
  public userId: string;
  public isRefesh: boolean;
  public inputVisible: boolean = false;
  public inputValue: string = '';
  public dynamicTags: any[] = [];
  public voucherCode: any[] = [];
  public avaibleVoucher: any[] = [];
  public voucher: any[];
  public staticVoucher: string = '';
  public errorCode: boolean = false;
  public selectedRedeemType: string = '';
  public errorVoucherCode: boolean = false;

  private querySearchPartner: string = '';

  public changeForm() {
    //
  }
  public async submit() {
    this.$refs.form.validate(async (valid) => {
      if (valid) {
        const form = <any> cloneDeep(this.form);
        this.handleSystemUser(form);
      }

      return;
    });
  }

  public back() {
    this.$emit('update:visible', false);
  }
  public openDialog() {
    if (this.userId) {
      this.form = this.$store.getters.getConditionsSorted(this.userId);
    }
    setTimeout(() => {
      if (this.$refs.form) {
        this.$refs.form.clearValidate();
      }
    }, 0);

  }
  public closeDialog() {
    this.form = redemptionFormDefault();
    this.loading = false;
    this.errorVoucherCode = false;
    this.selectedRedeemType = '';
    this.back();
  }

  public remoteMethod(query: string) {
    if (query !== '') {
      this.querySearchPartner = query;
    }
  }
  public handleClose(tag) {
    if (!this.userId) {
      this.dynamicTags.splice(this.dynamicTags.indexOf(tag), 1);
      this.voucherCode.splice(this.dynamicTags.indexOf(tag), 1);
    }

  }

  public showInput() {
    this.inputVisible = true;
    // this.$nextTick(_ => {
    //   this.$refs.saveTagInput.$refs.input.focus();
    // });
  }

  protected mounted() {
    //
  }

  protected beforeDestroy() {
    this.querySearchPartner = '';
    // this.$store.commit(MutationTypeTechnicianRate.ResetState);
  }

  private async handleSystemUser(form: any) {
    const formattedForm = cloneDeep(form);
    if (this.userId) {
      // update

      this.$store.dispatch(ConditionActionType.ConditionUpdate, {
        form: {
          ...omit(formattedForm, [
            '__typename',
            'updatedAt',
            'updatedBy',
            'createdAt',
            'createdBy',
            'createdUser',
            'updatedUser',
            'id',
          ]),
          updatedBy: this.authUser.id
        },
        id: this.userId,
        onSuccess: () => {
          this.loading = false;
          const message = 'Update Campaign Condition successfully';
          this.$store.commit(MutationType.OpenTopAlert, {
            message,
            type: TypeAlert.Success
          });
          this.$store.dispatch(ConditionActionType.ConditionFilterNoCache);
          this.closeDialog();
          this.$emit('updateSuccess');
        },
        onFailure: () => {
          this.loading = false;
        }
      });
    } else {
      // create
      // const dataVoucher: any[] = [];
      // if (this.dynamicTags.length && !formattedForm.voucherCodes) {
      //   this.dynamicTags.map((it) => {
      //     dataVoucher.push({
      //       code: it
      //     });
      //   });
      // }
      this.$store.dispatch(ConditionActionType.ConditionCreate, {
        form: {
          ...stripObject(formattedForm),
          createdBy: this.authUser.id
        },
        onSuccess: () => {
          this.loading = false;
          const message = 'Create Campaign Condition successfully';
          this.$store.commit(MutationType.OpenTopAlert, {
            message,
            type: TypeAlert.Success
          });
          this.$store.dispatch(ConditionActionType.ConditionFilterNoCache);
          this.closeDialog();
          this.$emit('createSuccess');
        },
        onFailure: () => {
          this.loading = false;
        }
      });
    }
  }
}
