export enum ActionTypeZeroCampaign {
  FilterNoCache = 'ip_campaignsFilterNoCache',
  GetZeroCampaignById = 'GetZeroCampaignById',
  UpdateSalesmanInfo = 'UpdateSalesmanInfo',
  GetListFinancialCompany = 'GetListFinancialCompany',
  UpdateFinancialCompany = 'UpdateFinancialCompany',
  AprroveRejectCampaign = 'AprroveRejectCampaign',
  ImportPosCode = 'importPosCode',
  GetConfig = 'ZeroCampaignGetConfig',
  ForceCloseCode = 'ForceCloseCode',
  ExportCode = 'ZeroCampaignExportCode',
  GetStatistic = 'zeroCampaignGetStatistic',
  GetStatisticDataByBranch = 'GetZeroStatisticDataByBranch',
  GetStatisticForDepartmentPic = 'GetStatisticForDepartmentPic',
  UpdateOwnerNameInfo = 'updateOwnerNameInfo',
  UpdatePOSCode = 'UpdatePOSCode',
  ApproveCampaign = 'ApproveCampaign',
  GetStatisticParticipantList = 'GetStatisticParticipantList',
  GetStatisticTopList = 'GetStatisticTopList',
  GetStatisticTopListShop = 'GetStatisticTopListShop',
  ExportCampaignOverview = 'exportCampaignOverview',
  GetStatisticParticipantListSaleAdmin = 'GetStatisticParticipantListSaleAdmin',
  GetConfiguration = 'GetConfiguration',
  UpdateConfiguration = 'UpdateConfiguration',
  GetConfigHistoryLogs = 'GetConfigHistoryLogs',
  GetStatisticTable = 'GetStatisticTable'
}
export enum MutationTypeZeroCampaign {
  FilterChange = 'ip_campaignsFilterChange',
  PaginationChange = 'ip_campaignsPaginationChange',
  ChangeOrderBy = 'ip_campaignsChangeOrderBy',
  ZeroCampaignLoading = 'ip_campaignsLoading',
  ZeroCampaignLoaded = 'ip_campaignsLoaded',
}

export enum ActionTypeZeroCampaignRequestReview {
  FilterNoCache = 'ip_campaign_companiesFilterNoCache',
  Update = 'ip_campaign_companiesUpdate',
  ApproveAll = 'ApproveAllIpCampaign'
}
export enum MutationTypeZeroCampaignRequestReview {
  FilterChange = 'ip_campaign_companiesFilterChange',
  PaginationChange = 'ip_campaign_companiesPaginationChange',
  ChangeOrderBy = 'ip_campaign_companiesChangeOrderBy',
  ZeroCampaignLoading = 'ip_campaign_companiesLoading',
  ZeroCampaignLoaded = 'ip_campaign_companiesLoaded',
}
