import { stripObject } from '@hgiasac/helper';
import { omit, pick, uniq } from 'lodash';
import { DKGqlClient } from 'root/api/graphql/Client';
import { defaultSaleInCampaignForm, IUser } from 'root/models';
import { pathUpload, sizeImage, uploadToFirebase } from 'root/services';
import { FirebaseAuthService } from 'root/services/auth/firebase';
import { IState } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import { ActionTypeSaleInCampaign } from '../../../Store/types';
import { GeneralInformation } from './Components/GeneralInformation';
import { SaleInFormConditions } from './Components/SaleInFormConditions';
import { SaleInFormParticipant } from './Components/SaleInFormParticipant';

@Component({
  template: require('./view.html'),
  props: {
    visible: Boolean,
    detailId: String,
  },
  components: {
    'general-information': GeneralInformation,
    participant: SaleInFormParticipant,
    conditions: SaleInFormConditions
  },
  computed: {
    ...mapState({
      authUser: (state: IState) => state.global.authUser,
    }),
  }
})

export class SaleInCampaignForm extends Vue {
  public currentTab: number = 0;
  public $refs: {
    generalInformation: GeneralInformation,
    participant: SaleInFormParticipant,
    conditions: SaleInFormConditions
  };
  public actTab: string = '';
  public loading: boolean = false;
  public detail: any = {};
  public authUser: IUser;
  public tabList = [
    { label: 'General Information', name: 'general' },
    { label: 'Participant', name: 'participant' },
    { label: 'Conditions', name: 'conditions' },
  ];
  public campaignType: string = '';
  public get activeTab() {
    return this.tabList[this.currentTab].name;
  }
  public set activeTab(_newValue: string) {
    // this.actTab = newValue;
  }
  public openDialog() {
    this.currentTab = 0;
    // tslint:disable-next-line:early-exit
    if (this.$props.detailId) {
      this.loading = true;
      this.$store.dispatch(ActionTypeSaleInCampaign.GetCampaignDetailById, {
        id: this.$props.detailId,
        onSuccess: (data) => {
          this.detail = data[0];
          const { campaignCode, description, icon, image, isSpecial,
             title, dealerRoles, startDate, endDate, dealerTypes } = data[0];
          this.$refs.generalInformation.form = {
            ...this.$refs.generalInformation.form,
            campaignCode, description, icon, image, isSpecial, title, startDate, endDate,
            metadata: data[0].sicInformation.descriptionType,
            sicInformations: {
              data: [
                {
                  rewardType: data[0].sicInformation.rewardType,
                  rewardStartTime: data[0].sicInformation.rewardStartTime,
                  rewardEndTime: data[0].sicInformation.rewardEndTime,
                  countdownDays: data[0].sicInformation.countdownDays,
                }
              ]
            }
          };
          this.$refs.participant.form = {
            ...this.$refs.participant.form,
            dealerRoles,
            targetBranch: data[0].targetBranch && data[0].targetBranch === 'all' ? ['all'] :
              data[0].targetBranches.map((it) => it.branch.id)
          };
          this.$refs.conditions.listCondition = [
            ...data[0].sicConditionGroups.map((it) => (
              {
                deviceTypeId: it.deviceType.id,
                conditionGroupId: it.id,
                model: it.conditions.map((el) => el.conditionModelId),
                quantity: it.modelQuantity,
                rewardId: it.rewardId,
                rewardQuantity: it.rewardQuantity,
                rewardNotifyContent: it.rewardNotifyContent,
                incentiveNotifyContent: it.incentiveNotifyContent,
                incentiveConditionFrom: it.incentiveCondition && it.incentiveCondition.from ?
                  it.incentiveCondition.from : '',
                incentiveConditionTo: it.incentiveCondition && it.incentiveCondition.to ?
                  it.incentiveCondition.to : '',
                discountAmount: it.discountAmount,
                isIncentiveNoti: it.incentiveNotifyContent || it.incentiveCondition.from ||
                  it.incentiveCondition.to ? true : false,
                isRewardNoti: it.rewardNotifyContent ? true : false
              }
            ))
          ];
          this.$refs.participant.cloneDealerType = [...dealerTypes];
          this.$refs.participant.uniqTypeDealer();
          this.$refs.participant.fetchData();
          this.$refs.participant.fetchParticipantList();
          this.loading = false;
        }
      });
    }
  }
  public checkValidateGeneralTab() {
    this.$refs.generalInformation.$refs.form.validate(async (valid) => {
      if (valid) {
        return true;
      }

      return false;
    });

    return false;
  }
  public next() {
    this.$refs.conditions.campaignType = this.$refs.generalInformation.form.sicInformations.data[0].rewardType;
    if (this.currentTab === 1) {
      this.$refs.participant.$refs.form.validate(async (valid) => {
        if (valid) {
          this.currentTab += 1;
          if (!this.$refs.conditions.listCondition || this.$refs.conditions.listCondition.length <= 0) {
            this.$refs.conditions.listCondition.push([]);
          }

          return;
        }

        return;
      });
    }

    if (this.currentTab === 0) {
      this.$refs.generalInformation.$refs.form.validate(async (valid) => {
        if (valid) {
          this.currentTab += 1;
          this.$refs.participant.$refs.form.clearValidate();

          return;
        }

        return;
      });
    }

    return;
  }
  public back() {
    this.currentTab -= 1;
  }
  public addCondition() {
    this.$refs.conditions.listCondition.push([]);
  }
  public async submit() {
    if (this.$refs.conditions.listCondition && this.$refs.conditions.listCondition.length <= 0) {
      this.$message.error('Please add condition!');

      return;
    }
    this.loading = true;
    let _image = '';
    let _icon = '';
    const { image, icon } = this.$refs.generalInformation.form;
    const { fileUploadLogo, fileUploadBanner } = this.$refs.generalInformation;
    const token = await DKGqlClient().user.getFirebaseAuthenticateToken();
    await FirebaseAuthService().signInWithCustomToken(token.authenticate);
    if (image && fileUploadBanner) {
      _image = await uploadToFirebase(
        fileUploadBanner,
        pathUpload.CAMPAIGN,
        {
          ...sizeImage.message.original,
          quality: 1
        }
      );
      _image = _image;
    }
    if (icon && fileUploadLogo) {
      _icon = await uploadToFirebase(
        fileUploadLogo,
        pathUpload.CAMPAIGN,
        {
          ...sizeImage.message.original,
          quality: 1
        }
      );
      _icon = _icon;
    }
    const { campaignCode, startDate, endDate,
      isSpecial, title, sicInformations, description } = this.$refs.generalInformation.form;
    const { dealerRoles, targetBranch } = this.$refs.participant.form;
    const { proShopType, businessType, potentialType } = this.$refs.participant;
    const cloneDealerTypes = [
      ...proShopType,
      ...businessType,
      ...potentialType
    ];
    const dealerTypes = uniq(cloneDealerTypes);
    const sicConditionGroups = {
      data: this.$refs.conditions.listCondition.map((it) => ({
        conditionGroupId: it.conditionGroupId,
        createdBy: this.authUser.id,
        rewardType: sicInformations.data[0].rewardType,
        deviceTypeId: it.deviceTypeId,
        modelQuantity: it.quantity,
        rewardId: it.rewardId,
        rewardQuantity: it.rewardQuantity,
        discountAmount: it.discountAmount,
        rewardNotifyContent: it.rewardNotifyContent,
        incentiveNotifyContent: it.incentiveNotifyContent,
        incentiveCondition: {
          from: it.incentiveConditionFrom,
          to: it.incentiveConditionTo,
        },
        conditions: {
          data: [
            ...it.model.map((el) => ({
              createdBy: this.authUser.id,
              conditionModelId: el
            }))
          ]
        },
      }))
    };
    const form = {
      campaignCode, startDate, endDate, isSpecial, title,
      sicConditionGroups, description,
      dealerRoles, dealerTypes,
      targetBranch: targetBranch && targetBranch.includes('all') ? 'all' : 'specific',
      targetBranches: targetBranch && !targetBranch.includes('all') ? {
        data: targetBranch.map((branch) => ({
          branchId: branch,
          createdBy: this.authUser.id
        }))
      } : null,
      image: _image,
      icon: _icon,
      campaignStatus: 'draft',
      sicInformations: {
        data: [{
          ...sicInformations.data[0],
          countdownDays: sicInformations.data[0].countdownDays ? sicInformations.data[0].countdownDays : null,
          descriptionType: this.$refs.generalInformation.metadata,
          createdBy: this.authUser.id
        }]
      },
      alias: 'sell_in',
      target: 'dealer_role',
      createdBy: this.authUser.id
    };
    if (this.$props.detailId) {
      if (this.detail && this.detail.campaignStatus === 'inactive') {
        const {rewardStartTime, rewardEndTime} = form.sicInformations.data[0];
        this.$store.dispatch(ActionTypeSaleInCampaign.UpdateCampaignOnInactive, {
          form: {
            title, rewardStartTime, rewardEndTime,
            id: this.$props.detailId,
            image: _image,
            icon: _icon,
            countdownDays: sicInformations.data[0].countdownDays ? sicInformations.data[0].countdownDays : null,
            descriptionType: this.$refs.generalInformation.metadata,
            ...stripObject(pick(form, ['campaignCode', 'startDate',
              'endDate', 'description', 'isSpecial'])),
            updatedBy: this.authUser.id,
            conditionGroup: [
              ...form.sicConditionGroups.data.map((el: any, index) => ({
                ...stripObject(pick(el, ['incentiveNotifyContent', 'rewardNotifyContent', 'incentiveCondition'])),
                conditionGroupId: el.conditionGroupId,
                noGroup: index + 1,
                incentiveCondition: {
                  from: el && el.incentiveCondition && el.incentiveCondition.from ?
                   parseInt(el.incentiveCondition.from, 10) : null,
                  to: el && el.incentiveCondition && el.incentiveCondition.to ?
                   parseInt(el.incentiveCondition.to, 10) : null,
                }
              }))
            ],
          },
          onSuccess: () => {
            this.loading = false;
            this.$message.success('Updated successfully!');
            this.closeDialog();
            this.$emit('createSuccess');
          }
        });
      }
      if (this.detail && this.detail.campaignStatus === 'draft') {
        this.$store.dispatch(ActionTypeSaleInCampaign.UpdateCampaign, {
          id: this.$props.detailId,
          form: {
            ...stripObject(omit(form, ['targetBranches', 'targetBranch',
              'sicConditionGroups', 'createdBy', 'alias', 'target', 'sicInformations', 'campaignStatus'])),
            updatedBy: this.authUser.id
          },
          information: {
            ...stripObject(omit(form.sicInformations.data[0], ['createdBy'])),
            updatedBy: this.authUser.id
          },
          conditionGroup: [
            ...form.sicConditionGroups.data.map((el) => ({
              ...el,
              campaignId: this.$props.detailId,
              conditions: {
                data: el.conditions.data.map((it) => ({
                  ...it,
                  campaignId: this.$props.detailId,
                }))
              }
            }))
          ],
          onSuccess: () => {
            this.loading = false;
            this.$message.success('Updated successfully!');
            this.closeDialog();
            this.$emit('createSuccess');
          }
        });
        this.$store.dispatch(ActionTypeSaleInCampaign.ChangeParticipant, {
          form: {
            dealerRoles,
            dealerTypes,
            campaignId: this.$props.detailId,
            targetBranch: targetBranch && targetBranch.includes('all') ? 'all' : 'specific',
            branchIds: targetBranch && targetBranch.includes('all') ? [] : targetBranch,
            updatedBy: this.authUser.id
          }
        });
      }

      return;
    }
    this.$store.dispatch(ActionTypeSaleInCampaign.CreateCampaign, {
      form,
      onSuccess: () => {
        this.loading = false;
        this.$message.success('Created successfully!');
        this.closeDialog();
        this.$emit('createSuccess');
      }
    });
  }
  public closeDialog() {
    this.$emit('closeDialog');
    this.$refs.generalInformation.form = defaultSaleInCampaignForm();
    this.$refs.participant.form = {};
    this.$refs.conditions.listCondition = [];
    this.$refs.generalInformation.fileUploadBanner = null;
    this.$refs.generalInformation.fileUploadLogo = null;
    this.$refs.generalInformation.$refs.form.clearValidate();
    this.$refs.participant.$refs.form.clearValidate();
  }
}
