import { Avatar } from 'root/components/Avatar';
import { FormatDateFullTime } from 'root/helpers';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import './styles.scss';
@Component({
  template: require('./view.html'),
  props: {
    visible: Boolean,
    detailId: String
  },
  components: {
    avatar: Avatar
  },
  computed: {
    ...mapState({
      // userDetail: (state: IState) => state.user.userDetail,
    })
  }
})
export class DealerTypeDetail extends Vue {
  public FormatDateFullTime = FormatDateFullTime;
  public userDetail = null;
  public detailId: string;

  public formatterDepartmentQty(model: any) {
    if (model && model.branches && model.branches.length) {
      let count = 0;
      model.branches.map((item) => {
        count += item.groups_aggregate.aggregate.count;
      });

      return count;
    }

    return 0;
  }

  public async openDialog() {
    this.userDetail = this.$store.getters.getDealerTypeDetail(this.detailId);
  }

  public closeDialog() {
    this.$emit('closeDialog');
  }
}
