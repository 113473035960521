import './styles.scss';

// import moment from 'moment';
import { stripObject } from '@hgiasac/helper';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import { BranchAndGroupRouterPath } from 'root/admin/BranchAndGroup';
import { ActionTypeDeviceType } from 'root/admin/DeviceType/Store/types';
import { IProductTransState } from 'root/admin/ProductTransaction/Store';
import { ActionTypeProductTrans, MutationTypeProductTrans } from 'root/admin/ProductTransaction/Store/types';
import { ITransactionViewState } from 'root/admin/TransactionView/Store';
import { ActionTypeTransactionView, MutationTypeTransactionView } from 'root/admin/TransactionView/Store/types';
import { SystemRoleAlias } from 'root/admin/User/Store/types';
import { IPaginationParams } from 'root/api/graphql/Core';
import { Avatar } from 'root/components/Avatar';
import { fullNameUser } from 'root/helpers';
import { IBreadcrumb, IFilterInput } from 'root/models';
import { IUser } from 'root/models/User';
import { IState, MutationType } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import { DealerRouterName, DealerRouterPath } from '..';
import { TransStatus } from '../Store/types';

const enum FilterKey {
  DeviceType = 'deviceTypeId',
  TransactionStatus = 'transactionStatus'
}

@Component({
  template: require('./view.html'),
  components: {
    avatar: Avatar,
    // 'user-detail-modal': UserDetailModal,
  },
  computed: {
    ...mapState({
      authUser: (state: IState) => state.global.authUser,
      data: (state: IState) => state.productTrans.data,
      pagination: (state: IState) => state.productTrans.pagination,
      loading: (state: IState) => state.productTrans.loading,
      deviceType: (state: IState) => state.deviceType.data,
      isRefesh: (state: IState) => state.global.isRefesh,
      dataTrans: (state: IState) => state.transactionView.data,
      paginationTrans: (state: IState) => state.transactionView.pagination
    }),
    selectedStatus() {
      const filterParams = [];

      if (!filterParams) {
        return [];
      }

      return [{
        key: 'isCreatedByAppleReviewer',
        value: []
      }];
    }
  },
  watch: {
    type() {
      this.paramsFilter = {
        text: ''
      };
      // this.$store.commit(MutationTypeUser.ClearUserList);
      this.$store.commit(MutationType.SetBreadcrumb, this.$t(this.type));
      this.fetchData();
    }
  },
})

export class TransactionList extends Vue {
  public get columns() {
    return [
      {
        prop: 'deviceName',
        label: 'Device Name',
        width: 180,
        formatter: (model: any) => {
          if (model.product && model.product.name) {
            return model.product.name || '--';

          }
          if (model.productName) {
            return model.productName || '--';
          }
        }
      },
      {
        prop: 'serialNumber',
        label: 'Serial Number',
        width: 180,
        formatter: (model: any) => {
          if (model.product && model.product.serialNumber) {
            return model.product.serialNumber || '--';

          }
          if (model.serialNumber) {
            return model.serialNumber || '--';
          }
        }
      },
      {
        prop: 'modelNumber',
        label: 'Model Number',
        width: 180,
        formatter: (model: any) => {
          if (model.product && model.product.modelNumber) {
            return model.product.modelNumber || '--';

          }
          if (model.modelNumber) {
            return model.modelNumber || '--';

          }
        }
      },
      {
        prop: 'transactionType',
        label: 'Transaction Type',
        width: 180,
        formatter: (model: any) => {
          if (model.transactionStatus) {
            return model.transactionStatus === TransStatus.SaleIn ? 'Sale In' : 'Sale Out';

          }

          return '--';
        },
        labelStyle: (model: any) => {

          if (model.transactionStatus) {
            return model.transactionStatus || '--';

          }
        }
      },
      {
        prop: 'deviceType',
        label: 'Device Type',
        width: 180,
        formatter: (model: any) => {
          if (model.product && model.product.deviceType && model.product.deviceType.name) {
            return model.product.deviceType.name || '--';

          }
          if (model.deviceType && model.deviceType.name) {
            return model.deviceType.name || '--';

          }
        }
      },
      {
        prop: 'createdBy',
        label: 'Exported By',
        width: 180,
        formatter: (model: any) => {
          if (model && this.authUser && (this.authUser.role.alias === SystemRoleAlias.SaleAdmin ||
            this.authUser.role.alias === SystemRoleAlias.Admin)) {
            return model.transactionStatus && model.transactionStatus === TransStatus.SaleIn ?
            model.dealer && model.dealer.name : model.formerDealer && model.formerDealer.name;
          }
          if (model) {
            return model.transactionStatus && model.transactionStatus === TransStatus.SaleIn ?
            model.dealerName : model.formerDealerName;
          }

          return '--';
        }
      },
      {
        prop: 'createdAt',
        label: 'Exported At',
        formatter: (model: any) => {
          return moment(model.createdAt).format('DD/MM/YYYY') || '--';
        },
        width: 180
      },
    ];

  }

  public get inputSearchPlaceholder(): string {

    return `Search by Name, Email`;
  }
  public get serviceId(): string {
    return this.$route.params.id;
  }
  public get isAdmin(): boolean {
    if (this.authUser && (this.authUser.role.alias === SystemRoleAlias.SaleAdmin ||
      this.authUser.role.alias === SystemRoleAlias.Admin)) {
      return true;
    }

    return false;
  }

  public authUser: IUser;
  public pagination: IPaginationParams;
  public paginationTrans: IPaginationParams;
  public data: IProductTransState[];
  public dataTrans: ITransactionViewState[];
  public visible: boolean = false;
  public isSample: boolean = false;
  public userId: string = '';
  public visibleDetail: boolean = false;
  public popupVisible: boolean = false;
  public deviceType: any[];
  public isRefesh: boolean;

  public UserType = {
    Customer: 'customer',
    System: 'system',
  };
  public filterParams = {
    [FilterKey.DeviceType]: [],
    [FilterKey.TransactionStatus]: [],
  };
  public searchText: string = '';
  public type: any;
  // tslint:disable-next-line:max-line-length
  private sampleUrl = `https://firebasestorage.googleapis.com/v0/b/daikin-test-staging.appspot.com/o/technicians%2Ftechnicians-import-sample.xlsx?alt=media&token=279661a1-11fe-49a0-8109-364e964ec11b`;

  public closeDialogDetail() {
    this.userId = '';
    this.visibleDetail = false;
  }
  public handleSearch() {
    this.fetchData();
  }

  public createSuccess() {
    this.visible = false;
    this.fetchData();
  }

  public applyFilter() {
    this.fetchData();
  }
  public goAddNewRouter() {
    this.visible = true;
    this.userId = '';
  }
  public closeDialog() {
    this.isSample = false;
    this.fetchData();
  }
  public downloadSample() {
    window.open(
      this.sampleUrl,
      '_blank'
    );
  }

  public clickDetail(id: string) {
    this.$router.push({
      name: DealerRouterName.DetailDealer,
      params: {
        id
      }});
  }
  public handleUpdate(id: string) {
    this.visible = true;
    this.userId = id;
  }

  public get dataFilter() {
    const _deviceType: IFilterInput[] = this.deviceType.reduce((res, item) => {
      res.push({
        key: item.id,
        value: item.id,
        name: item.name
      });

      return res;
    }, []);
    const _statuses: IFilterInput[] = [
      {
        key: 'sale_in',
        value: 'sale_in',
        name: 'Sale In'
      },
      {
        key: 'sale_out',
        value: 'sale_out',
        name: 'Sale out'
      }
    ];

    return [
      {
        key: FilterKey.DeviceType,
        name: this.$t('field.deviceType'),
        value: _deviceType
      },
      {
        key: FilterKey.TransactionStatus,
        name: 'Transaction Status',
        value: _statuses
      },
    ];
  }
  public get selectedFilter() {
    const _filterParams = cloneDeep(this.filterParams);

    return [
      {
        key: FilterKey.DeviceType,
        value: _filterParams[FilterKey.DeviceType]
      },
      {
        key: FilterKey.TransactionStatus,
        value: _filterParams[FilterKey.TransactionStatus]
      },
    ];
  }

  public changeFilter(model) {
    const deviceType = model.find((e) => e.key === FilterKey.DeviceType);
    const status = model.find((e) => e.key === FilterKey.TransactionStatus);
    const nextFilter = {
      [FilterKey.DeviceType]: deviceType ? deviceType.value : [],
      [FilterKey.TransactionStatus]: status ? status.value : []
    };
    this.filterParams = {
      ...cloneDeep(this.filterParams),
      ...nextFilter
    };
    this.fetchData();
  }
  public formatFullNameUser(model) {
    return fullNameUser(model);
  }
  public handlePaginationChange(value) {
    if (typeof value === 'number') {
      this.$store.commit(MutationTypeProductTrans.PaginationChange, {
        ...this.pagination,
        size: value
      });
      this.$store.commit(MutationTypeTransactionView.PaginationChange, {
        ...this.paginationTrans,
        size: value
      });
    } else {
      this.$store.commit(MutationTypeProductTrans.PaginationChange, {
        ...this.pagination,
        ...value
      });
      this.$store.commit(MutationTypeTransactionView.PaginationChange, {
        ...this.paginationTrans,
        ...value
      });
    }
    this.fetchData();
  }

  protected mounted() {
    this.$nextTick(() => {
      const breadcrumb: IBreadcrumb[] = [
        {
          label: 'title.dealer',
          path: DealerRouterPath.ListDealer,
          current: false
        },
        {
          label: 'title.view_details',
          path: null,
          current: true
        }
      ];
      const _breadcrumb: IBreadcrumb[] = [
        {
          label: 'title.managegroup',
          path: BranchAndGroupRouterPath.ListGroupManage,
          current: false
        },
        {
          label: 'title.view_details',
          path: null,
          current: true
        }
      ];
      this.$store.commit(MutationType.SetBack);
      this.$store.commit(MutationType.SetBreadcrumb, _breadcrumb);
      if (this.authUser && (this.authUser.role.alias === SystemRoleAlias.Admin ||
        this.authUser.role.alias === SystemRoleAlias.SaleAdmin)) {
        this.$store.commit(MutationType.SetBreadcrumb, breadcrumb);
      }
      this.fetchData();
    });
  }
  protected beforeDestroy() {
    // this.$store.commit(MutationType.ClearBreadcrumb);
  }

  private fetchData() {
    const text = cloneDeep(this.searchText);
    const _filter = <any> {
    };
    if (this.filterParams[FilterKey.DeviceType].length &&
      this.authUser && (this.authUser.role.alias !== SystemRoleAlias.SaleAdmin &&
      this.authUser.role.alias !== SystemRoleAlias.Admin)) {
      _filter.deviceType = {
        id: this.filterParams[FilterKey.DeviceType]
      };
    }
    if (this.filterParams[FilterKey.TransactionStatus] && this.filterParams[FilterKey.TransactionStatus].length) {
      _filter.transactionStatus = this.filterParams[FilterKey.TransactionStatus];
    }
    this.$store.commit(MutationTypeProductTrans.FilterChange, stripObject({
      ..._filter,
      dealerId: this.serviceId,
      product: {
        [FilterKey.DeviceType]: this.filterParams[FilterKey.DeviceType],
      },
      _and: text.trim() !== '' ? {
        mode: 'special',
        data: {
          _or: [
            {
              product : {
                name: {
                  _like: `%${text}%`
                }
              }
            },
            {
              product : {
                modelNumber: {
                  _like: `%${text}%`
                }
              }
            },
            {
              product : {
                serialNumber: {
                  _like: `%${text}%`
                }
              }
            }
          ]
        }
      } : null
    }));
    this.$store.commit(MutationTypeTransactionView.FilterChange, stripObject({
      ..._filter,
      dealerId: this.serviceId,
      // deviceType: {
      //   id: this.filterParams[FilterKey.DeviceType],
      // },
      _and: text.trim() !== '' ? {
        mode: 'special',
        data: {
          _or: [
            {
              productName: {
                _like: `%${text}%`
              }
            },
            {
              modelNumber: {
                _like: `%${text}%`
              }
            },
            {
              serialNumber: {
                _like: `%${text}%`
              }
            }
          ]
        }
      } : null
    }));
    if (this.authUser && (this.authUser.role.alias === SystemRoleAlias.SaleAdmin ||
      this.authUser.role.alias === SystemRoleAlias.Admin)) {
      this.$store.dispatch(ActionTypeProductTrans.FilterNoCache);
    }
    this.$store.dispatch(ActionTypeTransactionView.FilterNoCache);
    this.$store.dispatch(ActionTypeDeviceType.FilterNoCache);
  }
}
