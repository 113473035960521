// import { MutationType, TypeAlert } from 'root/store';
// import moment from 'moment';
import { SystemRoleAlias } from 'root/admin/User/Store/types';
import { IUser } from 'root/models';
import { IState, MutationType, TypeAlert } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import { ActionTypeZeroCampaign } from '../../Store/types';
import './styles.scss';
@Component({
  template: require('./view.html'),
  props: {
    visible: Boolean,
    campaignCompany: {
      type: Object,
      default: () => {
        return {};
      }
    },
    closeCodeFlow: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState({
      authUser: (state: IState) => state.global.authUser,
    }),
  }
})
export class RejectZeroCampaign extends Vue {
  public loadingSubmit: boolean = false;
  public reason: string = '';
  public otherReason: string = '';
  public authUser: IUser;
  public get disableReject() {
    if (this.reason && (
      this.reason !== 'Other' || (
        this.reason === 'Other' && this.otherReason
      )
    )) {
      return false;
    }

    return true;
  }
  public async openDialog() {
    if (this.$props.closeCodeFlow) {
      return this.reason = 'Other';
    }
  }
  public handleChange() {
    // console.log(this.reason);
  }
  public submitUpdate() {
    this.loadingSubmit = true;
    this.$store.dispatch(ActionTypeZeroCampaign.ApproveCampaign, {
      id: this.$props.campaignCompany.id,
      set: {
        updatedBy: this.authUser.id,
        lastReviewerId: this.authUser.id,
        lastReviewStatus: 'rejected',
        ...this.authUser.role.alias === SystemRoleAlias.GroupLeader &&
        this.$props.campaignCompany.type !== 'closed_code' ? {
          companyStatus: 'rejected',
        } : this.authUser.role.alias === SystemRoleAlias.GroupLeader &&
        this.$props.campaignCompany.type === 'closed_code' &&
        this.$props.campaignCompany.companyStatus === 'have_code' ? {
          type: 'open_code',
          requesterStopId: null
        } : this.authUser.role.alias === SystemRoleAlias.GroupLeader &&
        this.$props.campaignCompany.type === 'closed_code' &&
        this.$props.campaignCompany.companyStatus === 'request_stop_joining' ? {
          type: 'open_code',
          requesterStopId: null,
          companyStatus: 'have_code'
        } : this.authUser.role.alias === SystemRoleAlias.GroupLeader ? {
          companyStatus: 'rejected',
        } : {},
        reasons: this.reason !== 'Other' ? this.reason : this.reason === 'Other'
          && this.otherReason ? this.otherReason : '--'
      },
      // companyStatus: 'rejected',
      // reason: this.reason,
      // updatedBy: this.authUser.id,
      // updatedAt: moment(),
      onSuccess: () => {
        const message = 'Reject financial company successfully';
        this.$store.commit(MutationType.OpenTopAlert, {
          message,
          type: TypeAlert.Success,
        });
        this.loadingSubmit = false;
        this.closeDialog();
        this.$emit('refetchData');
      }
    });
  }
  public closeDialog() {
    this.otherReason = '';
    this.reason = '';
    this.$emit('closeDialog');
  }
}
