import { cloneDeep, find } from 'lodash';
import { IEvaluatePhotoState } from '.';

export const getters = {
  getEvaluatePhotoDetail(state: IEvaluatePhotoState) {
    return (id: string) => {
      return find(cloneDeep(state.data), (e) => e.id === id);
    };
  },
  getSalesEduCampaign(state: IEvaluatePhotoState) {
    return (id: string) => {
      return find(cloneDeep(state.bank_dealers.data), (e) => e.id === id);
    };
  }
};
