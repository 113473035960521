import { gqlClient } from '../Core';
import { CRUDGqlService, ICRUDGqlService } from '../Core/crud';
import { userProfileQuery, SystemUsers } from '../Query/user';

export interface IUserGqlService extends ICRUDGqlService<any> {
  //
  getProfile(): Promise<any>;
  createSystemUser(form: any): Promise<any>;
  changePassSystemUser(form: any): Promise<any>;
  updateStatusSystemUser(id: string, status: string): Promise<any>;
  getFirebaseAuthenticateToken(): Promise<{authenticate: string}>;
  getTeamStaff(id: string): Promise<any>;
  getTeamStaffDealer(id: string): Promise<any>;
  updateUserManager(form: any): Promise<any>;
  getInfoByPhone(phone: string): Promise<any>;
}
export function userGqlService(): IUserGqlService {
  async function getProfile(): Promise<any> {
    const results = await gqlClient.query({
      query: userProfileQuery.GET_USER_PROFILE,
      fetchPolicy: 'network-only'
    });
    if (results.errors) {
      throw results.errors;
    }

    return results.data.getSystemUserProfile;
  }
  async function createSystemUser(form: any) {
    const result = await gqlClient.mutate({
      mutation: userProfileQuery.CREATE_SYSTEM_USER,
      variables: {
        form,
      },
    });
    if (result.errors) {
      throw result.errors;
    }
  }
  async function changePassSystemUser(form: any) {
    const result = await gqlClient.mutate({
      mutation: userProfileQuery.CHANGE_PASS_SYSTEM_USER,
      variables: {
        form,
      }
    });
    if (result.errors) {
      throw result.errors;
    }
  }
  async function updateStatusSystemUser(id: string, status: string) {
    const result = await gqlClient.mutate({
      mutation: userProfileQuery.UPDATE_STATUS_SYSTEM_USER,
      variables: {
        id,
        status
      }
    });
    if (result.errors) {
      throw result.errors;
    }
  }
  async function getFirebaseAuthenticateToken(): Promise<{authenticate: string}> {
    const result = await gqlClient.query({
      query: userProfileQuery.GET_FIREBASE_AUTHENTICATE_TOKEN,
      fetchPolicy: 'network-only'
    });
    if (result.errors) {
      throw result.errors;
    }

    return result.data.getFirebaseAuthenticateToken;
  }
  async function getTeamStaff(id: string): Promise<any> {
    const results = await gqlClient.query({
      query: userProfileQuery.GET_TEAM_STAFF,
      variables: {id},
      fetchPolicy: 'network-only'
    });
    if (results.errors) {
      throw results.errors;
    }

    return results.data.leader_staffs;
  }
  async function getTeamStaffDealer(id: string): Promise<any> {
    const results = await gqlClient.query({
      query: userProfileQuery.GET_TEAM_STAFF_DEALER,
      variables: {id},
      fetchPolicy: 'network-only'
    });
    if (results.errors) {
      throw results.errors;
    }

    return results.data.dealer_leaders;
  }
  async function updateUserManager(form: any) {
    const result = await gqlClient.mutate({
      mutation: userProfileQuery.UPDATE_USER_MANAGER,
      variables: {
        form
      }
    });
    if (result.errors) {
      throw result.errors;
    }
  }
  async function getInfoByPhone(phone: string) {
    const result = await gqlClient.query({
      query: userProfileQuery.GET_INFO_BY_PHONE,
      variables: {
        phone
      }
    });
    if (result.errors) {
      throw result.errors;
    }
  }

  return {
    ...CRUDGqlService<any>(SystemUsers, userProfileQuery),
    getProfile,
    createSystemUser,
    changePassSystemUser,
    updateStatusSystemUser,
    getFirebaseAuthenticateToken,
    getTeamStaff,
    getTeamStaffDealer,
    updateUserManager,
    getInfoByPhone
  };
}
