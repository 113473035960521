import { DocumentNode } from 'graphql';
import gql from 'graphql-tag';
import { crudQuery, ICRUDQuery } from '../Core/query';

const groupView = `
branchId
dealerId
groupId
id
transactionStatus
createdAt
dealerId
formerDealerId
productId
deviceType {
    id
    name
  }
formerDealerName
productGroupId
productCode
serialNumber
modelNumber
productName
productBranchId
dealerName
`;
const groupViewHistory = `
branchId
dealerId
groupId
id
transactionStatus
createdAt
dealerId
formerDealerId
productId
formerDealerName
productGroupId
productCode
serialNumber
modelNumber
productName
productBranchId
dealerName
`;

export const transactionView = 'product_transaction_leader_view';
// tslint:disable-next-line:no-empty-interface
interface ITransactionViewQuery extends ICRUDQuery {
  GET_GROUP_VIEW: DocumentNode;
  GET_VIEW_BY_ID: DocumentNode;
}
export const transactionViewQuery: ITransactionViewQuery = {
  ...crudQuery(transactionView, groupView),
  GET_GROUP_VIEW: gql`
  query GET_GROUP_VIEW{
    product_transaction_leader_view{
      ${groupView}
    }
  }
`,
  GET_VIEW_BY_ID: gql`
  query GET_VIEW_BY_ID($id:uuid!){
    product_history_leader_view(where: { productId: {_eq: $id}}, order_by: {createdAt: desc}){
      ${groupViewHistory}
    }
  }
  `
};
