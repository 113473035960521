import { cloneDeep } from 'lodash';
import moment from 'moment';
import { SystemRoleAlias } from 'root/admin/User/Store/types';
import { IPaginationParams } from 'root/api/graphql/Core';
import {
  formatterPhoneNumber,
  parseUTCToTime,
  FormatDateFullTime,
  FormatDateTime
} from 'root/helpers';
import { ConfigurationKey, IAddress, IBreadcrumb, IFilterInput, IUser } from 'root/models';
import { pathUpload } from 'root/services';
import { IState, MutationType } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import { NewRaCampaignRouterPath } from '../..';
import {
  ActionTypeNewRaCampaign,
  MutationTypeNewRaCampaign,
} from '../../Store/types';
import { downloadPDF } from '../../downloadPDFhelper';
import { ShopCampaignDetailModel } from '../ShopCampaignDetailModel';
import './styles.scss';

export enum ContractStatus {
  Accepted = 'accepted',
}
export enum RequestStatusCode {
  NotJoin = 'not_join',
  WaitingForReup = 'request_re_up',
  WaitingForReview = 'waiting_for_review',
  Approved = 'approved',
  Rejected = 'rejected',
}

export enum RequestStatus {
  NotJoin = 'Not Join',
  WaitingForReup = 'Waiting For Reup',
  WaitingForReview = 'Waiting For Review',
  Approved = 'Approved',
  Rejected = 'Rejected',
}
export enum RequestAction {
  Approved = 'approve',
  Rejected = 'reject',
  RequestUpdate = 'request_update',
}
export enum QualifiedCode {
  Qualified = 'qualified',
  NonQualified = 'non_qualified'
}
export enum Qualified {
  Qualified = 'Qualified',
  NonQualified = 'Non Qualified'
}
const enum FilterKey {
  Status = 'status',
  Classification = 'classification',
  DealerApproval = 'dealerApproval'
}
@Component({
  template: require('./view.html'),
  name: 'NewRaCampaignDetail',
  components: {
    'new-shops-detail-modal': ShopCampaignDetailModel,
  },
  computed: {
    ...mapState({
      authUser: (state: IState) => state.global.authUser,
      dealer: (state: IState) => state.newRACampaign.data,
      data: (state: IState) => state.newRACampaign.data,
      loading: (state: IState) => state.newRACampaign.loading,
      pagination: (state: IState) => state.newRACampaign.pagination,
      typeCollapse: (state: IState) => state.global.typeCollapse
    }),
    detail() {
      return this.$store.getters.getNewRACampaignDetail(this.dealerId);
    },
    cloneData() {
      return this.data && this.data.length ? this.data[0].shops : [];
    },
  },
})
export class NewRaCampaignDetail extends Vue {
  public get dealerId(): string {
    return this.$route.params.id;
  }
  public get isSalePlanning() {
    if (this.authUser.superAdmin || this.authUser.role.alias === SystemRoleAlias.Admin
      || this.authUser.role.alias === SystemRoleAlias.SaleAdmin) {
      return true;
    }

    return false;
  }
  public get currentRole() {
    return this.authUser.role.alias;
  }
  public get isSaleDepartmentPic() {
    if (this.authUser.role.alias === SystemRoleAlias.SaleDepartmentPic) {
      return true;
    }

    return false;
  }
  public get isBranchPic() {
    if (this.authUser.role.alias === SystemRoleAlias.BranchPIC) {
      return true;
    }

    return false;
  }
  public get columns() {
    return [
      {
        prop: 'review at',
        label: 'Sales Staff last review at',
        formatter: (model: any) => {
          return model.shop ? model.shop.name : '--';
        },
        width: 100,
      },
      {
        prop: 'classification',
        label: 'Sales Staff Last classification',
        formatter: (model: any) => {
          return model.shop ? model.shop.name : '--';
        },
        width: 150,
      },
      // {
      //   prop: 'review content',
      //   label: 'Sales Staff last review content',
      //   formatter: (model: any) => {
      //     return model.shop ? model.shop.name : '--';
      //   },
      //   width: 300,
      // },
      {
        prop: 'shop',
        label: 'Shop Name',
        labelItem: 'Shop Code',
        width: 250,
      },
      // {
      //   prop: 'staff',
      //   label: 'Staff',
      //   width: 120,
      //   formatter: (model: any) => {
      //     return model.staff || '--';
      //   }
      // },
      {
        prop: 'address',
        label: 'Address',
        width: 220,
        formatter: (model: any) => {
          if (model.shop.address) {
            return this.displayAddress(model.shop.address) || '--';
          }

          return '--';
        }
      },
      {
        prop: 'reject reason',
        label: 'Rejected Reason',
        width: 150,
        formatter: this.formatterReason
      },
      // {
      //   prop: 'dealer approval',
      //   label: 'Dealer Approval',
      //   width: 150,
      //   formatter: () => {
      //     return '--';
      //   }
      // },
      {
        prop: 'status',
        label: 'Request Status',
        width: 150,
        formatter: this.formaterStatus,
        labelStyle: (model: any) => {
          return model.shop && model.shop.acl_campaigns_2023.length ?
            `ra_${model.shop.acl_campaigns_2023[0].requestStatus}` : RequestStatusCode.NotJoin;
        },
      },
      {
        prop: 'dealerApprove',
        label: 'Dealer Approval',
        formatter: (model: any) => {
          return model && model.shop && model.shop.acl_campaigns_2023.length &&
           model.shop.acl_campaigns_2023[0] && model.shop.acl_campaigns_2023[0].isDealerConfirm ? 'YES' : 'No';
        },
        labelStyle: (model: any) => {
          return model && model.shop && model.shop.acl_campaigns_2023.length &&
          model.shop.acl_campaigns_2023[0] && model.shop.acl_campaigns_2023[0].isDealerConfirm ?
          'ra_has_confirmCode' : 'ra_no_has_confirmCode';
        },
        width: 120,
      },
      {
        prop: 'dealerApproveAt',
        label: 'Dealer Approval At',
        formatter: (model: any) => {
          return model && model.shop && model.shop.acl_campaigns_2023.length &&
          model.shop.acl_campaigns_2023[0] && model.shop.acl_campaigns_2023[0].dealerApproveAt ?
          moment(model.shop.acl_campaigns_2023[0].dealerApproveAt).format('HH:mm, DD/MM/YYYY') : '--';
        },
        width: 120,
      },
      {
        prop: 'action',
        label: '',
        align: 'center',
        fixed: 'right',
        width: 60
      }
    ];
  }
  public get dataFilter() {
    const _statuses: IFilterInput[] = [
      {
        key: RequestStatusCode.NotJoin,
        value: RequestStatusCode.NotJoin,
        name: RequestStatus.NotJoin,
      },
      {
        key: RequestStatusCode.WaitingForReview,
        value: RequestStatusCode.WaitingForReview,
        name: RequestStatus.WaitingForReview,
      },
      {
        key: RequestStatusCode.WaitingForReup,
        value: RequestStatusCode.WaitingForReup,
        name: RequestStatus.WaitingForReup,
      },
      {
        key: RequestStatusCode.Approved,
        value: RequestStatusCode.Approved,
        name: RequestStatus.Approved,
      },
      {
        key: RequestStatusCode.Rejected,
        value: RequestStatusCode.Rejected,
        name: RequestStatus.Rejected,
      },
    ];
    const classification: IFilterInput[] = [
      {
        key: 'not_review',
        value: 'not_review',
        name: 'Not Review',
      },
      {
        key: QualifiedCode.Qualified,
        value: QualifiedCode.Qualified,
        name: Qualified.Qualified,
      },
      {
        key: QualifiedCode.NonQualified,
        value: QualifiedCode.NonQualified,
        name: Qualified.NonQualified,
      },
    ];
    const dealerApproval: IFilterInput[] = [
      {
        key: 'true',
        value: 'true',
        name: 'Yes',
      },
      {
        key: 'false',
        value: 'false',
        name: 'No',
      },
    ];

    return [
      {
        key: FilterKey.Status,
        name: this.$t('field.status'),
        value: _statuses
      },
      {
        key: FilterKey.Classification,
        name: 'Sales Staff Last Classification',
        value: classification
      },
      {
        key: FilterKey.DealerApproval,
        name: 'Dealer Approval',
        value: dealerApproval
      }
    ];
  }
  public get selectedFilter() {
    const filterParams = cloneDeep(this.filterParams);

    return [
      {
        key: FilterKey.Status,
        value: filterParams[FilterKey.Status]
      },
      {
        key: FilterKey.Classification,
        value: filterParams[FilterKey.Classification]
      },
      {
        key: FilterKey.DealerApproval,
        value: filterParams[FilterKey.DealerApproval]
      }
    ];
  }
  public pathUpload: pathUpload = pathUpload.PDFEXPORT;
  public detail: any;
  public pagination: IPaginationParams;
  public searchText: string = '';
  public FormatDateFullTime = FormatDateFullTime;
  public formatDate = parseUTCToTime;
  public downloadPDF = downloadPDF;
  public visibleEditor: boolean = false;
  public shopId: string = '';
  public visibleDetail: boolean = false;
  public cloneData: any[];
  public disableApprove: boolean = true;
  public SystemRoleAlias = SystemRoleAlias;
  public disableDownload: boolean = true;
  public approveIds: any[] = [];
  public isTechnician: boolean;
  public exportLoading: boolean = false;
  public listExportIds: any[] = [];
  public authUser: IUser;
  public isOnCampaign: boolean = false;
  public typeCollapse: boolean;
  public ConfigCropPicture: string = 'NO';
  public filterParams = {
    [FilterKey.Status]: [],
    [FilterKey.Classification]: [],
    [FilterKey.DealerApproval]: []
  };
  public formatterStaff(model: any) {
    return model.shop.dealerShops.filter(
      (item) => !item.isOwner && item.contractStatus === ContractStatus.Accepted
    ).length;
  }
  public formatterTime(model: any) {
    if (model.shop && model.shop.acl_campaigns_2023.length && model.shop.acl_campaigns_2023[0].reviews.length) {
      const last = model.shop.acl_campaigns_2023[0].reviews.length - 1;

      return moment(model.shop.acl_campaigns_2023[0].reviews[last].updatedAt).format('HH:mm');
    }

    return '--';
  }
  public addressFormater(model: any) {
    if (model) {
      return this.displayAddress(model) || '--';
    }

    return '--';
  }

  public formatterDate(model: any) {
    if (model.shop && model.shop.acl_campaigns_2023.length && model.shop.acl_campaigns_2023[0].reviews.length) {
      const last = model.shop.acl_campaigns_2023[0].reviews.length - 1;

      return moment(model.shop.acl_campaigns_2023[0].reviews[last].updatedAt).format('DD/MM/YYYY');
    }

    return '--';
  }
  public formatterClassification(model: any) {
    if (model.shop && model.shop.acl_campaigns_2023.length && model.shop.acl_campaigns_2023[0].classification) {
      switch (model.shop.acl_campaigns_2023[0].classification) {
      case QualifiedCode.Qualified:
        return Qualified.Qualified;
      case QualifiedCode.NonQualified:
        return Qualified.NonQualified;
      }
    }

    return '--';
  }
  public formatterReason(model: any) {
    if (model.shop && model.shop.acl_campaigns_2023.length &&
      model.shop.acl_campaigns_2023[0].requestStatus === 'rejected') {

      return model.shop.acl_campaigns_2023[0].reason;
    }

    return '--';
  }
  public formatterContent(model: any) {
    if (model.shop && model.shop.acl_campaigns_2023.length && model.shop.acl_campaigns_2023[0].reviews.length) {
      const last = model.shop.acl_campaigns_2023[0].reviews.length - 1;

      return model.shop.acl_campaigns_2023[0].reviews[last].description.length < 120 ?
        model.shop.acl_campaigns_2023[0].reviews[last].description
        : model.shop.acl_campaigns_2023[0].reviews[last].description.slice(0, 120) + '...';
    }

    return '--';
  }

  public formaterStatus(model: any) {
    if (model.shop && model.shop.acl_campaigns_2023.length) {
      switch (model.shop && model.shop.acl_campaigns_2023.length && model.shop.acl_campaigns_2023[0].requestStatus) {
      case RequestStatusCode.WaitingForReup:
        return RequestStatus.WaitingForReup;
      case RequestStatusCode.WaitingForReview:
        return RequestStatus.WaitingForReview;
      case RequestStatusCode.Approved:
        return RequestStatus.Approved;
      case RequestStatusCode.Rejected:
        return RequestStatus.Rejected;
      }
    }

    return RequestStatus.NotJoin;
  }
  public getAction() {
    return 'view';
  }
  public closeDialog() {
    this.visibleEditor = false;
  }
  public handleActions(id) {
    this.clickDetail(id.shop.id);
  }
  public approve() {
    // change after
    this.$msgbox({
      title: this.$t('approve_confirm').toString(),
      message: `Do you want to approve campaign request for ${this.approveIds.length} shop?`,
      showCancelButton: true,
      confirmButtonText: this.$t('confirm').toString(),
      cancelButtonText: this.$t('cancel').toString(),
      beforeClose: (action, instance, done) => {
        if (action === 'confirm') {
          instance.confirmButtonLoading = true;
          instance.confirmButtonText = 'Loading...';
          this.handleApprove({ instance, done });
        } else {
          done();
        }

        return;
      },
    })
      .then(() => {
        this.$message({
          type: 'success',
          message: this.$t('message.approveSuccess').toString(),
        });
      })
      .catch(() => {
        // no handle
      });
  }
  public handleApprove(model) {
    this.$store.dispatch(ActionTypeNewRaCampaign.ApproveShop, {
      ids: this.approveIds,
      action: 'approved',
      updatedBy: this.authUser.id,
      onSuccess: () => {
        model.instance.confirmButtonLoading = false;
        model.instance.confirmButtonText = 'Confirm';
        model.done();
        this.fetchData();
      },
      onFailure: () => {
        model.instance.confirmButtonLoading = false;
        model.instance.confirmButtonText = 'Confirm';
      },
    });
  }
  public formatPackage(campaign: any) {
    if (!campaign || campaign.length === 0) {
      return '--';
    }

    return `${campaign.model1}, ${campaign.model2}, ${campaign.model3}`;
  }
  public formatStartTimeEndtime(startTime: any, endTime: any) {
    if (!startTime || !endTime) {
      return '--';
    }

    return `${FormatDateTime(startTime)} - ${FormatDateTime(endTime)}`;
  }
  public formatStartTimeEndtimeFromMetadata(metadata, keyToDetech) {
    for (const [key, value] of Object.entries(metadata)) {
      if (key.toString() === keyToDetech) {
        return value;
      }
    }
  }
  public async download() {
    this.exportLoading = true;
    if (this.listExportIds.length === 0) {
      this.exportLoading = false;

      return;
    }

    this.downloadPDF(this.listExportIds);
  }
  public displayAddress(model: IAddress) {
    return model && model.province
      ? this.$store.getters['getAddressString'](model)
      : '';
  }
  public formatUTCDate(date) {
    return moment(date).utc().format('DD/MM/YYYY');
  }
  public formatterPhoneNumber(phone) {
    return formatterPhoneNumber(phone);
  }
  public clickDetail(id: string) {
    this.shopId = id;
    this.visibleDetail = true;
  }
  public closeDialogDetail() {
    // change after
    this.shopId = '';
    this.visibleDetail = false;
    this.fetchData();
  }
  public handlePaginationChange(value) {
    if (typeof value === 'number') {
      this.$store.commit(MutationTypeNewRaCampaign.PaginationChange, {
        ...this.pagination,
        size: value,
      });
    } else {
      this.$store.commit(MutationTypeNewRaCampaign.PaginationChange, {
        ...this.pagination,
        ...value,
      });
    }
    this.fetchData();
  }
  public changeSelect(e) {
    this.approveIds = [];
    this.listExportIds = [];
    if (
      e.length && e.every(
        (item) =>
          item.shop && item.shop.acl_campaigns_2023.length && item.shop.acl_campaigns_2023[0].requestStatus ===
          RequestStatusCode.WaitingForReview || item.shop && item.shop.acl_campaigns_2023.length
          && item.shop.acl_campaigns_2023[0].requestStatus === RequestStatusCode.WaitingForReup
      )
    ) {
      this.disableApprove = false;
      e.map((it) => this.approveIds.push(it.shop && it.shop.acl_campaigns_2023.length &&
        it.shop.acl_campaigns_2023[0].id));

      return;
    }
    if (
      e.length && e.every(
        (item) =>
          item.shop && item.shop.acl_campaigns_2023.length
          && (item.shop.acl_campaigns_2023[0].requestStatus === RequestStatusCode.Approved
            || item.shop.acl_campaigns_2023[0].requestStatus === RequestStatusCode.Rejected)
      )
    ) {
      this.disableDownload = false;
      e.map((it) => this.listExportIds.push(it.shop && it.shop.acl_campaigns_2023.length &&
        it.shop.acl_campaigns_2023[0].id));

      return;
    }
    this.disableApprove = true;
    this.disableDownload = true;
  }
  public removeItem() {
    this.disableDownload = true;
  }
  public handleSearch() {
    this.fetchData();
  }
  public formatDateTime(date: Date) {
    return moment(date).format('HH:mm, DD/MM/YYYY');
  }
  public changeFilter(model) {
    const status = model.find((e) => e.key === FilterKey.Status);
    const classification = model.find((e) => e.key === FilterKey.Classification);
    const dealerApproval = model.find((e) => e.key === FilterKey.DealerApproval);
    const nextFilter = {
      [FilterKey.Status]: status ? status.value : [],
      [FilterKey.Classification]: classification ? classification.value : [],
      [FilterKey.DealerApproval]: dealerApproval ? dealerApproval.value : []
    };
    this.filterParams = {
      ...cloneDeep(this.filterParams),
      ...nextFilter
    };
    this.fetchData();
  }
  protected mounted() {
    this.$nextTick(() => {
      const breadcrumb: IBreadcrumb[] = [
        {
          label: 'title.new_ra_campaign',
          path: null,
          current: false
        },
        {
          label: 'title.new_ra_campaign_participant',
          path: NewRaCampaignRouterPath.Participant,
          current: true
        },
        {
          label: 'title.view_details',
          path: null,
          current: true,
        },
      ];
      this.$store.commit(MutationType.SetShowCollapse);
      this.$store.commit(MutationType.SetTypeCollapse, true);
      this.$store.commit(MutationType.SetBreadcrumb, breadcrumb);
      this.$store.dispatch(ActionTypeNewRaCampaign.GetConfigCropPicture, {
        onSuccess: (config) => {
          this.ConfigCropPicture =
            config && config.system_configurations.length ? config.system_configurations.find((e) =>
              e.variableName === ConfigurationKey.ACLCampaignCropPicture).value : 'NO';
        }
      });
      this.$store.dispatch(ActionTypeNewRaCampaign.GetCampaignConfiguration, {
        onSuccess: (aclConfig) => {
          if (moment(aclConfig[0].aclCampaignConfig.uploadStartDate).isBefore(moment()) &&
            moment(aclConfig[0].aclCampaignConfig.uploadEndDate).isAfter(moment())) {
            this.isOnCampaign = true;
          }
        }
      });
      this.fetchData();
    });
  }
  protected beforeDestroy() {
    this.$store.commit(MutationType.ClearShowCollapse);
    this.$store.commit(MutationType.ClearBreadcrumb);
  }
  private fetchData() {
    const text = cloneDeep(this.searchText);
    const _filterStatus = <any> {};
    let _notJoin = true;
    const classification = null;
    let _cloneFilter = null;
    if (this.filterParams[FilterKey.Status].length) {
      _filterStatus.status = this.filterParams[FilterKey.Status];
    }
    if (this.filterParams[FilterKey.Classification].length) {
      _filterStatus.classification = this.filterParams[FilterKey.Classification];
    }
    if (_filterStatus.status && _filterStatus.status.indexOf('not_join') < 0) {
      _notJoin = false;
    }
    const _dealerApproval = this.filterParams[FilterKey.DealerApproval] &&
    this.filterParams[FilterKey.DealerApproval].length ?
    this.filterParams[FilterKey.DealerApproval] : null;
    if (_filterStatus.status && _filterStatus.status.indexOf('not_join') >= 0) {
      const _index = _filterStatus.status.indexOf('not_join');
      _cloneFilter = [..._filterStatus.status];
      _cloneFilter.splice(_index, 1);
      _notJoin = true;
    }
    this.$store.dispatch(ActionTypeNewRaCampaign.GetNewRaCampaignDetail, {
      classification,
      id: this.dealerId,
      status: _cloneFilter ? _cloneFilter : _filterStatus.status,
      notJoin: _notJoin,
      search: `%${text}%`,
      pagination: this.pagination,
      dealerApproval: _dealerApproval
    });
  }
}
