import { DKGqlClient } from 'root/api/graphql/Client';
import { ActionType, IState } from 'root/store';
import { crudActions } from 'root/store/helpers';
import { ActionContext } from 'vuex';
import { IInvoiceState } from './mutation';
import { ActionTypeInvoice, MutationTypeInvoice } from './types';

const { invoice } = DKGqlClient();
export const actions = {
  ...crudActions('invoice', {
  }),
  async [ActionTypeInvoice.GET_LIST_INVOICE]({
    commit, dispatch
  }: ActionContext<IInvoiceState, IState>,
                                             { form }) {
    try {
      commit(MutationTypeInvoice.InvoiceLoading);
      const InvoiceList = await invoice.getListInvoice(form);
      commit(MutationTypeInvoice.GetListInvoice, InvoiceList);
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    } finally {
      commit(MutationTypeInvoice.InvoiceLoaded);
    }
  }
};
