import { Form } from 'element-ui';
import { ElUpload } from 'element-ui/types/upload';
import { debounce } from 'lodash';
import moment from 'moment';
import { ActionTypeSaleInCampaign } from 'root/admin/CustomCampaign/SaleInCampaign/Store/types';
import { DKEditor } from 'root/components/DKCustomEditor';
import { Upload } from 'root/components/Upload';
import { resetOrientation, ruleRequired } from 'root/helpers';
import { defaultSaleInCampaignForm } from 'root/models';
import { pathUpload, uploadToFirebase } from 'root/services';
import { MutationType, TypeAlert } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import './styles.scss';

@Component({
  template: require('./view.html'),
  props: {
    isUpdate: Boolean
  },
  components: {
    upload: Upload,
    'dk-editor': DKEditor,
  }
})

export class GeneralInformation extends Vue {
  public endDatePickerOption = {
    disabledDate: (time: any) => {
      const startDate = this.form.startDate;
      if (startDate) {
        return time.getTime() < moment(startDate) ;
      }

      return false;

    }
  };
  public endRewardDatePickerOption = {
    disabledDate: (time: any) => {
      const startDate = this.form.sicInformations.data[0].rewardStartTime;
      if (startDate) {
        return time.getTime() < moment(startDate) ;
      }

      return false;

    }
  };
  public get targetType() {
    return [
      {
        key: 'discount',
        value: 'discount',
        label: 'Discount'
      },
      {
        key: 'gift',
        value: 'gift_product',
        label: 'Gift/Product'
      },
    ];
  }
  public $refs: {
    form: Form;
    upload: ElUpload;
  };
  public rules = {
    required: ruleRequired(),
  };
  public isErrorCode: boolean = false;
  public isErrorCountdown: boolean = false;
  public form: any = defaultSaleInCampaignForm();
  public metadata: string = 'pdf';
  public pathUploadAvatar: pathUpload = pathUpload.SYSTEM_USER;
  public fileUploadLogo = null;
  public fileUploadBanner = null;
  public loading: boolean = false;
  public fileList = [];
  public async handleUploadBanner(file: any) {
     // tslint:disable-next-line: whitespace
    const _file = <any>await resetOrientation(file.raw, -1);
    this.form.image = window.URL.createObjectURL(_file);
    this.fileUploadBanner = _file;
  }
  public async handleUploadLogo(file: any) {
      // tslint:disable-next-line: whitespace
    const _file = <any>await resetOrientation(file.raw, -1);
    this.form.icon = window.URL.createObjectURL(_file);
    this.fileUploadLogo = _file;
  }
  public removeUploadLogo() {
    this.form.icon = '';
    this.fileUploadLogo = null;
  }
  public removeUploadBanner() {
    this.form.image = '';
    this.fileUploadBanner = null;
  }
  public onChangeRadio() {
    this.fileList = [];
    this.form = {
      ...this.form,
      description: ''
    };
    this.$refs.form.clearValidate('description');
  }
  public onChangeCountdownDays() {
    this.isErrorCountdown = false;
    const diffDays = moment.duration(moment(this.form.endDate).diff(
      moment(this.form.startDate))).asDays();
    if (this.form.sicInformations.data[0].countdownDays &&
      this.form.sicInformations.data[0].countdownDays > diffDays) {
      this.isErrorCountdown = true;
    }
  }
  public checkCodeExist() {
    this.isErrorCode = false;
    this.$store.dispatch(ActionTypeSaleInCampaign.CheckCampaignCodeExits, {
      code: this.form.campaignCode,
      onSuccess: (result) => {
        if (result) {
          return this.isErrorCode = true;
        }

        return this.isErrorCode = false;
      }
    });
  }
  public pushFileUpload(file: any) {
    this.form = {
      ...this.form,
      description: 'pdf'
    };
    const f = this.fileList;
    if (this.fileList.length > 0) {
      const _file = this.fileList.filter((it) => it.name === file.name);
      if (_file && _file.length > 0) {
        this.$refs.upload.clearFiles();
        const message = 'File has been exits';
        this.$store.commit(MutationType.OpenTopAlert, {
          message,
          type: TypeAlert.Warning
        });

        return this.$refs.upload.$data.uploadFiles = f;
      }
      this.fileList = [];

      return this.fileList.push(file);
    }

    f.push(file);

    this.fileList = f;
    this.uploadFile();
  }
  public async uploadFile() {
    const uploadedFile = await Promise.all(this.fileList.map(async (it) => {
      const _file = await uploadToFirebase(it.raw, this.pathUploadAvatar);

        // return _file ? uploadedFile.push(_file) : uploadedFile;
        // tslint:disable-next-line:no-var-before-return
      return _file;
    }));
    this.form = {
      ...this.form,
      description : uploadedFile[0]
    };
  }
  // public next() {
  //   this.$refs.form.validate(async (valid) => {
  //     if (valid) {
  //       console.log(this.form);
  //       this.$emit('next');

  //       return;
  //     }

  //     return;
  //   });
  // }
  public onRemove() {
    //  
  }
  protected created() {
    this.checkCodeExist = debounce(this.checkCodeExist, 300);
  }
}
