import { DKMultiCheckboxList } from 'root/components/DKMultiCheckboxList';
import { DKSelectedViewOnlyList } from 'root/components/DKSelectedViewOnlyList';
import Vue from 'vue';
import Component from 'vue-class-component';

import './styles.scss';

@Component({
  template: require('./view.html'),
  components: {
    'dk-multi-checkbox-list': DKMultiCheckboxList,
    'dk-selected-view-only-list': DKSelectedViewOnlyList,
  },
  props: {
    visible: Boolean,
    dataFilter: Object,
    isTriggerSearchDisabled: Boolean,
    selectedFormValue: Array,
    isLoading: Boolean,
    isApplyFilterBtnVisible: {
      type: Boolean,
      default: () => true,
    },
    dataChildren: Array
  }
})

export class DKFilter extends Vue {
  public dependentItems = [];
  public selectedItems: any = [];
  public selectedFormValue: string[];

  public openDialog() {
    this.selectedItems = this.selectedFormValue;
  }

  public closeDialog() {
    this.$emit('closeDialog');
  }

  public onParentListChange(value) {
    this.dependentItems = value;
    // this.$emit('changeBranch', value);
  }

  public handleGetSelectedItems(value) {
    this.$emit('onSelectedChildChange');
    this.$emit('changeBranch', value);
    this.selectedItems = value;
  }

  public onSaveBtnClick() {
    this.$emit('onSaveBtnClick', this.selectedItems);
  }

  public onApplyBtnClick() {
    this.$emit('onFilter', this.selectedItems);
  }
  // protected mounted() {
  //   //
  //   // this.selectedItems = this.selectedFormValue;
  // }
}
