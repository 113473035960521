// import { PermissionType } from 'root/models';
import { PermissionType } from 'root/models';
import { RouteConfig } from 'vue-router';
import { UserContainer } from './UserContainer';
import { UserDetail } from './UserDetail';
// import { UserDetail } from './UserDetail';
// import { UserEditor } from './UserEditor';
import { UserList } from './UserList';

export enum UserRouterPath {
  Default = '/users',
  Create = '/users/:userType/create',
  Detail = '/users/:userType/:userId/detail',
  Edit = '/users/:userType/:userId/edit',
  List = '/users',
  System = '/users/system_users',
  Technician = '/users/technicians',
  Profile = '/users/profile'
}
export enum UserRouterName {
  Create = 'user-create',
  Edit = 'user-edit',
  Detail = 'user-detail',
  Profile = '/users/profile',
  List = 'List',
  System = 'system_users',
  Technician = 'technicians',
}

export const userRouter: RouteConfig = {
  path: '/users',
  component: UserContainer,
  meta: {
    permission: PermissionType.Systemuser
  },
  children: [
    {
      path: UserRouterPath.List,
      component: UserList,
      name: UserRouterName.List,
      meta: {
        permission: PermissionType.Systemuser
      }
    },
    {
      path: UserRouterPath.Profile,
      component: UserDetail,
      name: UserRouterName.Profile,
      meta: {
        // permission: PermissionType.Customer
      }
    },
    // {
    //   path: UserRouterPath.System,
    //   component: UserList,
    //   name: UserRouterName.System,
    //   meta: {
    //     permission: PermissionType.SystemUsers
    //   }
    // },
    // {
    //   path: UserRouterPath.Technician,
    //   component: UserList,
    //   name: UserRouterName.Technician,
    //   meta: {
    //     permission: PermissionType.Technician
    //   }
    // },
    // {
    //   path: UserRouterPath.Create,
    //   component: UserEditor,
    //   name: UserRouterName.Create,
    // },
    // {
    //   path: UserRouterPath.Detail,
    //   component: UserDetail,
    //   name: UserRouterName.Detail
    // }, {
    //   path: UserRouterPath.Edit,
    //   component: UserEditor,
    //   name: UserRouterName.Edit
    // }
  ]
};
