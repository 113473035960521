import { ISimpleUser } from './User';

export interface IConfigurationForm {
  key: string;
  type: string;
  parent?: {
    key?: string;
    value?: string;
  };
}
export interface IConfiguration extends IConfigurationForm {
  id?: string;
  value: any;
  updatedUser?: ISimpleUser;
  updatedAt?: string;
  variableName: ConfigurationKey;
}
export function configurationDefault(): IConfiguration {
  return {
    id: null,
    key: '',
    type: '',
    value: null,
    variableName: null
  };
}

export interface INotification {
  id: string;
  heading?: string;
  title: string;
  type: NotificationType;
  referenceId: number;
  content: string;
  icon?: string;
  data?: INotificationData;
}

export interface INotificationData {
  id: number;
  title: string;
  type: NotificationType;
  referenceId: number;
  metadata?: any;
}
export enum NotificationType {
  NewBooking = 'new_booking'
}
export enum ConfigurationKey {
  VersionAdminPortal = 'versionAdminPortal',
  IOSVersion = 'IOS_VERSION',
  AndroidVersion = 'ANDROID_VERSION',
  PortalVersion = 'PORTAL_VERSION',
  IsForceUpdateIOS = 'IS_FORCE_UPDATE_IOS',
  IsForceUpdateAndroid = 'IS_FORCE_UPDATE_ANDROID',
  IsForceUpdatePortal = 'IS_FORCE_UPDATE_PORTAL',
  ScanProductMode = 'SCAN_PRODUCT_MODE',
  DealerActiveWarranty = 'DEALER_ACTIVATE_WARRANTY',
  DealerPictureCampaign = 'DEALER_PICTURE_CAMPAIGN',
  TechnicianActiveWarranty = 'TECHNICIAN_ACTIVATE_WARRANTY',
  TechnicianPictureCampaign = 'TECHNICIAN_PICTURE_CAMPAIGN',
  ValidDeviceType = 'VALID_DEVICE_TYPES',
  ACLCampaignCropPicture = 'ACL_CAMPAIGN_CROP_PICTURE'
}
