import './styles.scss';

// import moment from 'moment';
import { cloneDeep, find } from 'lodash';
import { SystemRoleAlias } from 'root/admin/User/Store/types';
import { IPaginationParams } from 'root/api/graphql/Core';
import { Avatar } from 'root/components/Avatar';
import { fullNameUser } from 'root/helpers';
import { IAddress, IFilterInput, StatusCode } from 'root/models';
import { IUser } from 'root/models/User';
import { IState, MutationType } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import { BranchAndGroupRouterName } from '../..';
import { BranchEditor } from '../Components/BranchManageEditor';
import {
  ActionTypeBranchManage,
  MutationTypeBranchManager
} from '../Store/types';

const enum FilterKey {
  Region = 'region'
}

@Component({
  template: require('./view.html'),
  components: {
    avatar: Avatar,
    editor: BranchEditor
  },
  computed: {
    ...mapState({
      authUser: (state: IState) => state.global.authUser,
      data: (state: IState) => state.branchManage.data,
      pagination: (state: IState) => state.branchManage.pagination,
      loading: (state: IState) => state.branchManage.loading,
      // filterParams: (state: IState) => state.branchManage.filterParams,
      deviceType: (state: IState) => state.deviceType.data,
      isRefesh: (state: IState) => state.global.isRefesh,
      region: (state: IState) => state.region.data
    }),
    selectedStatus() {
      const filterParams = [];

      if (!filterParams) {
        return [];
      }

      return [
        {
          key: 'isCreatedByAppleReviewer',
          value: []
        }
      ];
    }
  },
  watch: {
    type() {
      this.paramsFilter = {
        text: ''
      };
      // this.$store.commit(MutationTypeUser.ClearUserList);
      this.$store.commit(MutationType.SetBreadcrumb, this.$t(this.type));
      this.fetchData();
    }
  }
})
export class BranchList extends Vue {
  public get serviceId(): string {
    return this.$route.params.id;
  }
  public get actions() {

    return ['view', 'edit'];
  }

  public get AccessCreateBranch() {
    if (this.authUser && (this.authUser.role.alias === SystemRoleAlias.SaleAdmin ||
      this.authUser.role.alias === SystemRoleAlias.Admin)) {
      return true;
    }

    return false;
  }

  public get columns() {

    return [
      {
        prop: 'name',
        label: 'Name',
        width: 180,
        formatter: (model: any) => {
          if (model.name) {
            return model.name || '--';
          }

          return '--';
        }
      },
      {
        prop: 'code',
        label: 'Code',
        width: 180,
        formatter: (model: any) => {
          if (model.branchCode) {
            return model.branchCode || '--';
          }

          return '--';
        }
      },
      {
        prop: 'region',
        label: 'Region',
        width: 180,
        formatter: (model: any) => {
          if (model.region) {
            return model.region.name || '--';
          }

          return '--';
        }
      },
      {
        prop: 'branchManager',
        label: 'Branch Manager',
        width: 160,
        formatter: (model: any) => {
          if (model.branchManagers) {
            return model.branchManagers.length || '--';
          }

          return '--';
        }
      },
      {
        prop: 'totalGroup',
        label: 'Total Department',
        width: 160,
        formatter: (model: any) => {
          if (model.groups) {
            return model.groups.length || '--';
          }

          return '--';
        }
      },
      // {
      //   prop: 'address',
      //   label: 'Address',
      //   width: 250,
      //   formatter: (model: any) => {
      //     if (model.address) {
      //       return this.displayAddress(model.address) || '--';
      //     }

      //     return '--';
      //   }
      // },
      {
        prop: 'description',
        label: 'Description',
        formatter: (model: any) => {
          if (model.description) {
            return model.description || '--';
          }
        },
        width: 140
      },
      {
        prop: 'status',
        label: this.$t('status'),
        formatter: (model: any) => (model.status ? this.$t(model.status) : ''),
        labelStyle: (model: any) => {
          return model.status;
        },
      }
    ];
  }

  public get inputSearchPlaceholder(): string {
    return `Search by Name, Email`;
  }
  public get dataFilter() {
    const _region: IFilterInput[] = this.region.reduce((res, item) => {
      res.push({
        key: item.id,
        value: item.id,
        name: item.name
      });

      return res;
    }, []);

    return [
      {
        key: FilterKey.Region,
        name: this.$t('field.region'),
        value: _region
      }
    ];
  }
  public get selectedFilter() {
    const _filterParams = cloneDeep(this.filterParams);

    return [
      {
        key: FilterKey.Region,
        value: _filterParams[FilterKey.Region]
      }
    ];
  }

  public authUser: IUser;
  public pagination: IPaginationParams;
  public data: any[];
  public SystemRoleAlias = SystemRoleAlias;
  public deviceType: any[];
  public visible: boolean = false;
  public isSample: boolean = false;
  public userId: string = '';
  public visibleDetail: boolean = false;
  public popupVisible: boolean = false;
  public region: any[];
  public filterParams = {
    [FilterKey.Region]: []
  };
  public searchText: string = '';

  public UserType = {
    Customer: 'customer',
    System: 'system'
  };
  public type: any;
  public isRefesh: boolean;
  // tslint:disable-next-line:max-line-length
  private sampleUrl = `https://firebasestorage.googleapis.com/v0/b/daikin-test-staging.appspot.com/o/technicians%2Ftechnicians-import-sample.xlsx?alt=media&token=279661a1-11fe-49a0-8109-364e964ec11b`;
  public displayListManager(model: any, role: any) {
    if (model.branchManagers && model.branchManagers.length > 0) {
      return model.branchManagers.filter((e) =>
      e.role.alias === role).map((t) => `${t.name}`).join(', ');
    }

    return '__';
  }
  public displayListGroups(model: any) {
    if (model.groups && model.groups.length > 0) {
      return model.groups.map((t) => `${t.name}`).join(', ');
    }

    return '__';
  }

  public handleSearch() {
    this.fetchData();
  }

  public handleChangePagination(value) {
    if (typeof value === 'number') {
      this.$store.commit(MutationTypeBranchManager.PaginationChange, {
        ...this.pagination,
        size: value
      });
    } else {
      this.$store.commit(MutationTypeBranchManager.PaginationChange, {
        ...this.pagination,
        ...value
      });
    }
    this.fetchData();
  }

  public closeDialogDetail() {
    this.userId = '';
    this.visibleDetail = false;
  }

  public createSuccess() {
    this.visible = false;
    this.fetchData();
  }

  public applyFilter() {
    this.fetchData();
  }
  public goAddNewRouter() {
    this.visible = true;
    this.userId = '';
  }
  public closeDialog() {
    this.isSample = false;
    this.fetchData();
  }
  public downloadSample() {
    window.open(this.sampleUrl, '_blank');
  }
  public handleDelete(model: any) {
    this.$store.dispatch(ActionTypeBranchManage.Update, {
      id: model.id,
      form: {
        status: StatusCode.Deleted
      },
      onSuccess: () => {
        this.fetchData();
        model.instance.confirmButtonLoading = false;
        model.instance.confirmButtonText = 'Confirm';
        model.done();
      },
      onFailure: () => {
        model.instance.confirmButtonLoading = false;
        model.instance.confirmButtonText = 'Confirm';
      }
    });
  }
  public handleDisabled(model: any) {
    const user = find(
        cloneDeep(this.data),
        (e) => e.id.toString() === model.id.toString()
      ),
      isBlocked = user.status === StatusCode.Blocked;
    this.$store.dispatch(ActionTypeBranchManage.UpdateStatus, {
      id: model.id,
      set: {
        status: isBlocked ? StatusCode.Active : StatusCode.Blocked
      },
      onSuccess: () => {
        this.fetchData();
        model.instance.confirmButtonLoading = false;
        model.instance.confirmButtonText = 'Confirm';
        model.done();
      },
      onFailure: () => {
        model.instance.confirmButtonLoading = false;
        model.instance.confirmButtonText = 'Confirm';
      }
    });
  }

  public clickDetail(id: string) {
    this.$router.push({
      name: BranchAndGroupRouterName.DetailBranch,
      params: {
        id
      }
    });
  }
  public handleUpdate(id: string) {
    this.visible = true;
    this.userId = id;
  }

  public changeFilter(model) {
    const status = model.find((e) => e.key === FilterKey.Region);
    const nextFilter = {
      [FilterKey.Region]: status ? status.value : []
    };
    this.filterParams = {
      ...cloneDeep(this.filterParams),
      ...nextFilter
    };
    this.fetchData();
  }

  public formatFullNameUser(model) {
    return fullNameUser(model);
  }
  public displayAddress(model: IAddress) {
    return model && model.province ? this.$store.getters['getAddressString'](model) : '';
  }

  protected mounted() {
    this.$nextTick(() => {
      this.$store.commit(MutationType.ClearBack);
      this.$store.commit(MutationType.SetBreadcrumb, this.$t('title.managebranch'));
      // this.$store.commit(MutationTypeUser.PaginationReset);
      !this.isRefesh && this.fetchData();
    });
  }
  protected beforeDestroy() {
    this.$store.commit(MutationType.ClearBreadcrumb);
  }

  private fetchData() {
    const text = cloneDeep(this.searchText);
    const _filterParams = <any> {};
    if (this.filterParams[FilterKey.Region].length) {
      _filterParams.region = this.filterParams[FilterKey.Region];
    }
    if (text) {
      _filterParams.search = text;
    }
    this.$store.dispatch(ActionTypeBranchManage.GetListBranch, {
      form: {
        filter: _filterParams,
        pagination: {
          size: this.pagination.limit,
          page: text ? 1 : this.pagination.page
        }
      }
    });
  }
}
