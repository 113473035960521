import { crudMutations, CRUDState, ICRUDState } from 'root/store/helpers';

export interface IGroupState extends ICRUDState<any> {
  loading: boolean;
  systemGroup: ICRUDState<any>;
}

export const defaultState: IGroupState = {
  ...CRUDState(),
  loading: false,
  systemGroup: CRUDState()
};

export const mutations = {
  ...crudMutations('group'),
  ...crudMutations('systemGroup', 'id', {keyState: 'systemGroup'})

};
