import { stripObject } from '@hgiasac/helper';
import { Form } from 'element-ui';
import { cloneDeep } from 'lodash';
import { ActionTypeBranchManage } from 'root/admin/BranchAndGroup/BranchManage/Store/types';
import { TypeRole } from 'root/admin/Dealer/Store/types';
import { ActionTypeProduct } from 'root/admin/Product/Store/types';
import { ActionTypeRole } from 'root/admin/Role/Store/types';
import { SystemRoleAlias } from 'root/admin/User/Store/types';
import { DKGqlClient } from 'root/api/graphql/Client';
import { IPaginationParams } from 'root/api/graphql/Core';
import {
  BannersDefault,
  BannersFormDefault,
  BannerType,
  ConfigurationKey,
  IBanners,
  IBannersForm,
  IConfiguration,
  IFilterInput,
  IUser,
  StatusCode
} from 'root/models';
import { ItemPage } from 'root/models';
import { ActionType, IState, MutationType, TypeAlert } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import draggable from 'vuedraggable';
import { mapGetters, mapState } from 'vuex';
import { BannerDetail } from '../BannerDetail';
import { BannerEditor } from '../BannerEditor';
import { BannersActionType, BannersMutationType } from '../store/types';
import './styles.scss';

@Component({
  template: require('./view.html'),
  components: {
    draggable,
    'banner-detail': BannerDetail,
    'banner-editor': BannerEditor
  },
  computed: {
    ...mapState({
      loading: (state: IState) => state.banners.loading,
      pagination: (state: IState) => state.banners.pagination,
      role: (state: IState) => state.role.data,
      config: (state: IState) => state.banners.config,
      configPagination: (state: IState) => state.banners.configPagination,
      authUser: (state: IState) => state.global.authUser,
    }),
    ...mapGetters(['getBannersSorted']),
    selectedFilter() {
      const filterParams = cloneDeep(this.filterParams);

      return [
        {
          key: 'type',
          value: filterParams.type ? filterParams.type : []
        }
      ];
    },
    dataFilter() {
      const result: IFilterInput[] = [];
      for (const key in BannerType) {
        // tslint:disable-next-line:early-exit
        if (BannerType.hasOwnProperty(key)) {
          const element = BannerType[key];
          result.push({
            key,
            value: element,
            name: element.charAt(0).toUpperCase() + element.slice(1)
          });
        }
      }

      return [
        {
          key: 'type',
          name: this.$t('type'),
          value: result
        }
      ];
    }
  }
})
export class BannerList extends Vue {
  public get isAdminRole() {
    if (this.authUser.superAdmin || this.authUser.role.alias === SystemRoleAlias.Admin) {
      return true;
    }

    return false;
  }
  public $refs: {
    form: Form
  };
  public authUser: IUser;
  public getBannersSorted: IBanners[];
  public config: any[];
  public bannerDetail: IBanners = BannersDefault();
  public configDetail: any = {};
  public openViewDetail: boolean = false;
  public loadingBtn: boolean = false;
  public openBannerEditor: boolean = false;
  public bannerForm: IBannersForm = BannersFormDefault();
  public detailId: string = '';
  public pagination: IPaginationParams;
  public configPagination: IPaginationParams;
  public filterParams: any = { type: null };
  public tabPosition: string = 'banner';
  public radio: string = '';
  public radioScan: string = '';
  public configurations = [];
  public role: any[];
  public ItemPage = ItemPage;
  public versionForm = null;
  public loadingVersion: boolean = false;
  public editDealerNoteScan: boolean = false;
  public DealerNoteScan: string = '';
  public DealerNotePicture: string = '';
  public TechnicianNoteScan: string = '';
  public TechnicianNotePicture: string = '';
  public isEditting: boolean = false;
  public openConfigEditor: boolean = false;

  public changeFilter(model: IFilterInput[]) {
    this.$store.commit(BannersMutationType.PaginationChange, {
      limit: 20,
      page: 1
    });
    const types = model[0] ? model[0].value : [];
    this.filterParams.type = types;
    this.$store.commit(
      BannersMutationType.FilterChange,
      stripObject({
        type: types
      })
    );
    this.fetchData();
  }

  public openNewBannerDialog() {
    this.openBannerEditor = true;
  }

  public openBannerEditorDialog(id) {
    this.openBannerEditor = true;
    const editedBanner = this.getBannersSorted.find((banner) => banner.id === id);
    const editedBannerForm: IBannersForm = {
      name: editedBanner.name,
      description: editedBanner.description,
      imageUrl: editedBanner.imageUrl,
      type: editedBanner.type,
      url: editedBanner.url,
      targetType: editedBanner.targetType,
      target: editedBanner.target,
      branchBanners: editedBanner.branchBanners
    };
    this.detailId = id;
    this.bannerForm = cloneDeep(editedBannerForm);
  }

  public currentChange(newValue) {
    window.scrollTo(0, 0);
    this.$store.commit(BannersMutationType.PaginationChange, {
      limit: 5,
      page: newValue
    });
    this.fetchData();
  }

  // public clickEditDealerScan() {
  //   this.editDealerNoteScan = !this.editDealerNoteScan;
  //   this.isEditting = false;
  // }
  public onEditDealerNoteScan() {
    this.isEditting = true;
  }
  public onCancelDealerNoteScan(value) {
    this.isEditting = false;
    switch (value) {
    case ConfigurationKey.DealerActiveWarranty:
      this.DealerNoteScan =
      this.configurations.find((e) => e.variableName.toString() === value).description;
      break;
    }
    switch (value) {
    case ConfigurationKey.DealerPictureCampaign:
      this.DealerNotePicture =
      this.configurations.find((e) => e.variableName.toString() === value).description;
      break;
    }
    switch (value) {
    case ConfigurationKey.TechnicianActiveWarranty:
      this.TechnicianNoteScan =
      this.configurations.find((e) => e.variableName.toString() === value).description;
      break;
    }
    switch (value) {
    case ConfigurationKey.TechnicianPictureCampaign:
      this.TechnicianNotePicture =
      this.configurations.find((e) => e.variableName.toString() === value).description;
      break;
    }
  }
  public onSaveDealerNoteScan(value) {
    this.isEditting = false;
    switch (value) {
    case ConfigurationKey.DealerActiveWarranty:
      this.$store.dispatch(BannersActionType.UpdateDescriptionServiceType, {
        variableName: value,
        description: this.DealerNoteScan
      });
      break;
    }
    switch (value) {
    case ConfigurationKey.DealerPictureCampaign:
      this.$store.dispatch(BannersActionType.UpdateDescriptionServiceType, {
        variableName: value,
        description: this.DealerNotePicture
      });
      break;
    }
    switch (value) {
    case ConfigurationKey.TechnicianActiveWarranty:
      this.$store.dispatch(BannersActionType.UpdateDescriptionServiceType, {
        variableName: value,
        description: this.TechnicianNoteScan
      });
      break;
    }
    switch (value) {
    case ConfigurationKey.TechnicianPictureCampaign:
      this.$store.dispatch(BannersActionType.UpdateDescriptionServiceType, {
        variableName: value,
        description: this.TechnicianNotePicture
      });
      break;
    }
  }

  public handlePaginationChange(value) {
    if (typeof value === 'number') {
      this.$store.commit(BannersMutationType.PaginationChange, {
        ...this.pagination,
        size: value
      });
    } else {
      this.$store.commit(BannersMutationType.PaginationChange, {
        ...this.pagination,
        ...value
      });
    }
    this.fetchData();
  }
  public handleConfigPaginationChange(value) {
    if (typeof value === 'number') {
      this.$store.commit(BannersMutationType.ConfigPaginationChange, {
        ...this.configPagination,
        size: value
      });
    } else {
      this.$store.commit(BannersMutationType.ConfigPaginationChange, {
        ...this.configPagination,
        ...value
      });
    }
    this.fetchData();
  }

  public closeBannerEditor() {
    this.openBannerEditor = false;
    this.bannerForm = BannersFormDefault();
    this.detailId = '';
  }

  public closeViewDetailDialog() {
    this.openViewDetail = false;
    this.bannerDetail = BannersDefault();
  }

  public dragHandler(data) {
    this.$store.dispatch(BannersActionType.UpdatePriority, { form: data });
  }

  public formatType(type: string): string {
    return type.charAt(0).toUpperCase() + type.slice(1);
  }

  public handleDelete(model) {
    this.$store.dispatch(BannersActionType.BannersUpdate, {
      id: model.id,
      form: {
        status: StatusCode.Deleted
      },
      onSuccess: () => {
        model.instance.confirmButtonLoading = false;
        model.instance.confirmButtonText = 'Confirm';
        model.done();
        this.fetchData();
      },
      onFail: () => {
        model.instance.confirmButtonLoading = false;
        model.instance.confirmButtonText = 'Confirm';
      }
    });
  }
  public handleSetRoleType() {
    const roleId = this.role && this.role.filter((it) => it.name === TypeRole.DaikinDealer);
    this.$store.dispatch(ActionTypeRole.UpdateSystemRoleType, {
      id: roleId ? roleId[0].id : '',
      type: this.radio,
      onSuccess: () => {
        this.fetchData();
        const message = 'Change Unit Mode successfully';
        this.$store.commit(MutationType.OpenTopAlert, {
          message,
          type: TypeAlert.Success
        });
      },
      onFailure: () => {
        //
      }
    });
  }
  public handleResetRoleType() {
    const roleId = this.role && this.role.filter((it) => it.name === TypeRole.DaikinDealer);
    this.$store.dispatch(ActionTypeRole.UpdateSystemRoleType, {
      id: roleId ? roleId[0].id : '',
      type: 'point',
      onSuccess: () => {
        this.fetchData();
        const message = 'Reset Unit Mode successfully';
        this.$store.commit(MutationType.OpenTopAlert, {
          message,
          type: TypeAlert.Success
        });
      },
      onFailure: () => {
        //
      }
    });
  }
  public handleSetScanProductType() {
    this.$store.dispatch(ActionTypeProduct.UpdateScanProductMode, {
      value: this.radioScan,
      onSuccess: () => {
        this.fetchData();
        const message = 'Change ScanProduct Mode successfully';
        this.$store.commit(MutationType.OpenTopAlert, {
          message,
          type: TypeAlert.Success
        });
      },
      onFailure: () => {
        //
      }
    });
  }
  public handleResetScanProductType() {
    this.$store.dispatch(ActionTypeProduct.UpdateScanProductMode, {
      value: 'all',
      onSuccess: () => {
        this.fetchData();
        const message = 'Reset ScanProduct Mode successfully';
        this.$store.commit(MutationType.OpenTopAlert, {
          message,
          type: TypeAlert.Success
        });
      },
      onFailure: () => {
        //
      }
    });
  }
  public onChangetab() {
    this.fetchData();
  }

  public openViewDetailDialog(id) {
    this.openViewDetail = true;
    this.bannerDetail = this.getBannersSorted.find((banner) => banner.id === id);
  }

  public clickDropdown(newValue: string) {
    const [command, bannerId] = newValue.split('|');
    switch (command) {
    case 'edit':
      this.openBannerEditorDialog(bannerId);
      break;
    case 'view':
      this.openViewDetailDialog(bannerId);
      break;
    case 'delete':
      this.$msgbox({
        title: this.$t('delete_confirm').toString(),
        message: this.$t('delete_confirm_message').toString(),
        showCancelButton: true,
        customClass: 'message-error',
        confirmButtonText: this.$t('confirm').toString(),
        cancelButtonText: this.$t('cancel').toString(),
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true;
            instance.confirmButtonText = 'Loading...';
            this.handleDelete({ instance, done, id: bannerId });
          } else {
            done();
          }

          return;
        }
      })
          .then(() => {
            this.$message({
              type: 'success',
              message: this.$t('message.deleteSuccess').toString()
            });
          })
          .catch(() => {
            // no handle
          });
    default:
      break;
    }
  }
  public clickDropdownConfig(newValue: string) {
    const [command, bannerId] = newValue.split('|');
    switch (command) {
    case 'edit':
      this.openConfigEditorDialog(bannerId);
      break;
    default:
      break;
    }
  }
  public openConfigEditorDialog(id: string) {
    this.configDetail = this.config.find((config) => config.id === id);
    this.openConfigEditor = true;
  }
  public changeConfig() {
    this.loadingBtn = true;
    const {id, value} = this.configDetail;
    this.$store.dispatch(BannersActionType.ChangeConfig, {
      id,
      value,
      onSuccess: () => {
        this.closeDialogConfig();
        const message = 'Update successfully';
        this.$store.commit(MutationType.OpenTopAlert, {
          message,
          type: TypeAlert.Success
        });
        this.loadingBtn = false;
      }
    });
  }
  public closeDialogConfig() {
    this.openConfigEditor = false;
    this.configDetail = {};
  }
  public async updateVersionForm() {
    try {
      this.loadingVersion = true;
      const form = cloneDeep(this.versionForm);
      for (const key in form) {
        if (form.hasOwnProperty(key)) {
          await DKGqlClient().configuration.update(key, {
            value: form[key]
          });
        } else {
          //
        }
      }
      this.$message.success('Update App version successfully');
    } catch (error) {
      this.$store.dispatch(ActionType.CatchException, error);
    } finally {
      this.loadingVersion = false;
    }
  }
  public resetVersionForm() {
    this.setVersionForm(this.configurations);
  }
  public getConfigId(key: string) {
    const config = this.configurations.find((e) => e.variableName === key);

    return config ? config.id : '';
  }
  public changeForceUpdate(value: boolean, id: string) {
    this.versionForm[id] = value ? 'YES' : 'NO';
  }
  public changeServiceType(value: boolean, id: string) {
    this.versionForm[id] = value ? 'true' : 'false';
  }
  public async fetchData() {
    this.$store.dispatch(BannersActionType.FetchAllConfiguration, {
      pagination: this.configPagination,
      optionsHTTP: {
        orderBy: {
          createdAt: 'desc'
        }
      }
    });
    this.$store.dispatch(BannersActionType.BannersFilterNoCache, {
      optionsHTTP: {
        orderBy: {
          createdAt: 'desc'
        }
      }
    });
    this.$store.dispatch(ActionTypeRole.FilterNoCache, {
      params: {
        onSuccess: (result) => {
          const roleType = result.filter((it) => it.name === TypeRole.DaikinDealer);
          this.radio = roleType && this.tabPosition === 'redeemtion' ? roleType[0].pointType : 'point';
        }
      }
    });
    this.$store.dispatch(ActionTypeBranchManage.GetListBranch, {
      form: {
        filter: {},
        pagination: {}
      }
    });
    const systemConfig = await this.$store.dispatch(ActionTypeProduct.getScanProductMode);
    if (systemConfig && systemConfig.length) {
      const scanProductMode = systemConfig.find((e) => e.variableName === ConfigurationKey.ScanProductMode);
      this.DealerNoteScan =
        systemConfig.find((e) => e.variableName === ConfigurationKey.DealerActiveWarranty).description;
      this.DealerNotePicture =
        systemConfig.find((e) => e.variableName === ConfigurationKey.DealerPictureCampaign).description;
      this.TechnicianNoteScan =
        systemConfig.find((e) => e.variableName === ConfigurationKey.TechnicianActiveWarranty).description;
      this.TechnicianNotePicture =
        systemConfig.find((e) => e.variableName === ConfigurationKey.TechnicianPictureCampaign).description;
      // this.DealerNoteScan = scanDealerNote ? scanDealerNote.description : '';
      this.radioScan = this.tabPosition === 'scanProduct' && scanProductMode ? scanProductMode.value : '';
      this.configurations = systemConfig;
      this.setVersionForm(systemConfig);
    }

    return;
  }
  protected mounted() {
    this.fetchData();
    this.$store.dispatch(ActionTypeRole.FilterNoCache, {
      params: {
        onSuccess: (result) => {
          const roleType = result.filter((it) => it.name === TypeRole.DaikinDealer);
          this.radio = roleType ? roleType[0].pointType : 'point';
        }
      }
    });
    this.$store.commit(MutationType.SetBreadcrumb, 'Configuration');
  }
  private setVersionForm(systemConfig: IConfiguration[]) {
    systemConfig.filter((e) =>
        [ConfigurationKey.IOSVersion, ConfigurationKey.AndroidVersion, ConfigurationKey.PortalVersion,
          ConfigurationKey.IsForceUpdateAndroid, ConfigurationKey.IsForceUpdateIOS,
          ConfigurationKey.IsForceUpdatePortal,
          ConfigurationKey.DealerActiveWarranty, ConfigurationKey.DealerPictureCampaign,
          ConfigurationKey.TechnicianActiveWarranty, ConfigurationKey.TechnicianPictureCampaign].includes(
          e.variableName))
        .forEach((element) => {
          this.versionForm = {
            ...this.versionForm,
            [element.id]: element.value
          };
        });
  }
}
