import { stripObject } from '@hgiasac/helper';
import { Subscription } from 'apollo-client/util/Observable';
import { TabPane } from 'element-ui';
import { ElUpload } from 'element-ui/types/upload';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import { ActionTypeBranchManage } from 'root/admin/BranchAndGroup/BranchManage/Store/types';
import { ActionTypeGroupManage } from 'root/admin/BranchAndGroup/GroupManage/Store/types';
import { SystemRoleAlias } from 'root/admin/User/Store/types';
import { DKGqlClient } from 'root/api/graphql/Client';
import { gqlClient, IPaginationParams } from 'root/api/graphql/Core';
import { zeroCampaignQuery } from 'root/api/graphql/Query/zeroCampaign';
import { FilterByBranch } from 'root/components/FilterByBranch';
import { SearchByFilter } from 'root/components/SearchByFilter';
import { FormatDateFullTime } from 'root/helpers';
import { IBreadcrumb, IFilterInput, IUser } from 'root/models';
import { pathUpload, uploadToFirebase } from 'root/services';
import { FirebaseAuthService } from 'root/services/auth/firebase';
import { IState, MutationType, TypeAlert } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import { ZeroPercentCampaignRouterName, ZeroPercentCampaignTab } from '..';
import { ApproveAllRequestForm } from '../Components/ApproveAllRequestForm';
import { ApproveZeroCampaign } from '../Components/ApproveZeroCampaign';
import { RejectZeroCampaign } from '../Components/RejectZeroCampaign';
import { ActionTypeZeroCampaign,
  ActionTypeZeroCampaignRequestReview, MutationTypeZeroCampaignRequestReview } from '../Store/types';
import './styles.scss';

const enum FilterKey {
  Status = 'status',
  Type = 'type',
  Company = 'company'
}
export enum FinancialCompanyStatusCode {
  WaitingForReview = 'waiting_for_review',
  Approved = 'approved',
  Rejected = 'rejected',
  HaveCode = 'have_code',
  RequestStopJoining = 'request_stop_joining',
  StopJoining = 'stop_joining'
}
export enum FinancialCompanyStatus {
  WaitingForReview = 'Waiting For Review',
  Approved = 'Approved',
  Rejected = 'Rejected',
  HaveCode = 'Have Code',
  RequestStopJoining = 'Request Stop Joining',
  StopJoining = 'Stop Joining'
}
export enum FinancialCompanyColor {
  WaitingForReview = '#FF9B1A',
  Approved = '#24A148',
  Rejected = '#FB4B53',
  HaveCode = '#03A1E4',
  RequestStopJoining = '#A66EFA',
  StopJoining = '#A66EFA'
}
export enum PosCodeType {
  OpenCode = 'open_code',
  ClosedCode = 'closed_code'
}
enum campaignSearchBy {
  DealerCode = 'dealerCode',
  Dealer = 'dealer',
  DealerShop = 'dealerShop',
  ShopCode = 'shopCode',
  PosCode = 'posCode'
}
@Component({
  template: require('./view.html'),
  computed: {
    ...mapState({
      data: (state: IState) => state.zeroCampaign.ip_campaign_companies.data,
      loading: (state: IState) => state.zeroCampaign.ip_campaign_companies.loading,
      pagination: (state: IState) => state.zeroCampaign.ip_campaign_companies.pagination,
      authUser: (state: IState) => state.global.authUser,
      branch: (state: IState) => state.branchManage.data,
      group: (state: IState) => state.groupManage.data,
    }),
    campaignSearchBy() {
      const result: IFilterInput[] = [];
      for (const key in campaignSearchBy) {
        // tslint:disable-next-line:early-exit
        if (campaignSearchBy.hasOwnProperty(key)) {
          const element = campaignSearchBy[key];
          result.push({
            key,
            value: element,
            name: this.$t(element)
          });
        }
      }

      return [{
        key: 'select',
        name: this.$t('select'),
        value: result
      }];
    },
    selectedFieldSearchBy() {
      const filterParams = cloneDeep(this.modelSearchBy);

      return [{
        key: 'select',
        value: filterParams ? filterParams : []
      }];
    },
  },
  components: {
    'approve-confirm': ApproveZeroCampaign,
    'reject-confirm': RejectZeroCampaign,
    'search-by-filter': SearchByFilter,
    'btn-filter-by-branch': FilterByBranch,
    'approve-all-request': ApproveAllRequestForm
  }
})

export class RequestReviewList extends Vue {

  public get activeTab() {
    return ZeroPercentCampaignTab.RequestReview;
  }

  public set activeTab(_value: string) {
    //
  }
  public get isSuperAdmin() {
    if (this.authUser.superAdmin || this.authUser.role.alias === SystemRoleAlias.Admin) {
      return true;
    }

    return false;
  }
  public get isSalePlanning() {
    if (this.authUser.role.alias === SystemRoleAlias.SaleAdmin) {
      return true;
    }

    return false;
  }
  public get isBranchPic() {
    if (this.authUser.role.alias === SystemRoleAlias.BranchManager) {
      return true;
    }

    return false;
  }
  public get isDepartmentPic() {
    if (this.authUser.role.alias === SystemRoleAlias.GroupLeader) {
      return true;
    }

    return false;
  }
  public get currentRole() {
    return this.authUser.role.alias;
  }
  public get disableExport() {
    if (this.filterParams[FilterKey.Status].length && this.filterParams[FilterKey.Status].length === 1 &&
      this.TimeFilter && this.TimeFilter.startMonth && this.TimeFilter.startYear
      && this.TimeFilter.endMonth && this.TimeFilter.endYear) {
      return false;
    }

    return true;
  }
  public get disableStatisticFilter() {
    return this.filterParams[FilterKey.Company] && this.filterParams[FilterKey.Company].length === 3 ? true : false;
  }
  public get dataSalePlanningFilter() {
    // const _status: IFilterInput[] = [
    //   {
    //     key: FinancialCompanyStatusCode.Approved,
    //     value: FinancialCompanyStatusCode.Approved,
    //     name: FinancialCompanyStatus.Approved
    //   },
    //   {
    //     key: FinancialCompanyStatusCode.Rejected,
    //     value: FinancialCompanyStatusCode.Rejected,
    //     name: FinancialCompanyStatus.Rejected
    //   },
    // ];
    const company: IFilterInput[] = [
      {
        key: 'open_code',
        value: 'open_code',
        name: 'Open Code'
      },
      {
        key: 'closed_code',
        value: 'closed_code',
        name: 'Close Code'
      },
    ];

    return [
      {
        key: FilterKey.Type,
        name: 'Type',
        value: company
      },
      // {
      //   key: FilterKey.Status,
      //   name: 'Latest Status',
      //   value: _status
      // },
    ];
  }
  public get columns() {
    return [
      {
        prop: 'shop',
        label: 'Dealer Shop',
        labelItem: 'Shop Code',
        width: 270,
      },
      {
        prop: 'dealer',
        label: 'Dealer Name',
        labelItem: 'Dealer Code',
        width: 270,
      },
      {
        prop: 'lastStatus',
        label: 'Last Status',
        labelItem: 'Update Time',
        width: 200,
      },
      {
        prop: 'company',
        label: 'Financial company',
        formatter: (model) => {
          return model && model.financial_company && model.financial_company.name ?
          model.financial_company.name : '--';
        },
        width: 200,
      },
      {
        prop: 'type',
        label: 'Type',
        width: 120,
      },
      {
        prop: 'updated',
        label: 'Updated By',
        labelItem: 'Name',
        width: 200,
      },
    ];
  }
  public get selectedFilter() {
    const _filterParams = cloneDeep(this.filterParams);

    return [
      {
        key: FilterKey.Status,
        value: _filterParams[FilterKey.Status]
      },
      {
        key: FilterKey.Type,
        value: _filterParams[FilterKey.Type]
      },
      {
        key: FilterKey.Company,
        value: _filterParams[FilterKey.Company]
      }
    ];
  }

  public searchText: string = '';
  public authUser: IUser;
  public pagination: IPaginationParams;
  public campaignId: string = '';
  public approveAllVisible: boolean = false;
  public FormatDateFullTime = FormatDateFullTime;
  public campaignCompany: any = {};
  public fileList = [];
  public statistic: any = {
    fe: null,
    hd: null,
    acs: null
  };
  public fileUploaded = [];
  public modelSearchBy: any[] = [];
  public visibleImport: boolean = false;
  public loadingUpload: boolean = false;
  public visibleApprove: boolean = false;
  public visibleReject: boolean = false;
  public loadingExport: boolean = false;
  public selectedItem: any[] =[];
  public loadingImport: boolean = false;
  public loadingDownload: boolean = false;
  // tslint:disable-next-line: max-line-length
  public urlTemplate: string = 'https://firebasestorage.googleapis.com/v0/b/daikin-sale-staging.appspot.com/o/template%2Fimports%2FposCode1654679013597Import_Code_Template_26Apr.xlsx?alt=media&token=6b219d09-12bd-4925-96c6-a31ba39e7e72';
  public pathUploadAvatar: pathUpload = pathUpload.POSCODE;
  public TimeFilter: any = {
    startMonth: null,
    endMonth: null,
    startYear: null,
    endYear: null
  };
  public FilterTimeUpdate: any = {
    from: null,
    to: null
  };
  public $refs: {
    upload: ElUpload;
  };
  public subscribeURL: Subscription = null;
  public subscribeImport: Subscription = null;
  public filterParams = {
    [FilterKey.Status]: [],
    [FilterKey.Type]: [],
    [FilterKey.Company]: [
      'HD', 'FE', 'ACS'
    ],
  };
  public visibleDetail: boolean = false;
  public visibleUpdateCompany: boolean = false;
  public listFinancialCompany: any = [];
  public branch: any[];
  public group: any[];

  public getActions() {
    if (this.isDepartmentPic || this.isSuperAdmin) {
      return ['view', 'updateCompany'];
    }

    return ['view'];
  }
  public handleActions(model) {
    if (model && model.action === 'view') {
      this.onClickDetail(model.id);
    }
    if (model && model.action === 'updateCompany') {
      this.onClickUpdateCompany(model.id);
    }

    return;
  }
  public changeTab(tab: TabPane) {
    switch (tab.name) {
    case ZeroPercentCampaignTab.Statistic:
      this.$router.push({
        name: ZeroPercentCampaignRouterName.Statistic
      });
      break;
    case ZeroPercentCampaignTab.Participant:
      this.$router.push({
        name: ZeroPercentCampaignRouterName.Participant
      });
      break;
    default:
      break;
    }
  }

  public handleSearch() {
    this.fetchData();
  }

  public handlePaginationChange(value) {
    if (typeof value === 'number') {
      this.$store.commit(MutationTypeZeroCampaignRequestReview.PaginationChange, {
        ...this.pagination,
        size: value
      });
    } else {
      this.$store.commit(MutationTypeZeroCampaignRequestReview.PaginationChange, {
        ...this.pagination,
        ...value
      });
    }
    this.fetchData();
  }
  public formatterTypePosCode(model , alias) {
    if (model && model.ip_campaign_companies && model.ip_campaign_companies.length) {
      const _company = model.ip_campaign_companies.find((company) => company.financial_company.alias === alias);
      if (!_company) {
        return '--';
      }

      return _company.type;
    }

    return '--';
  }
  public handleApproveAll() {
    this.approveAllVisible = true;
  }
  public StatusByRole(company) {
    if (this.currentRole === SystemRoleAlias.SaleStaff && company.companyStatus === 'waiting_for_review'
    && !company.lastReviewer) {
      return 'waiting_for_review';
    }
    if (this.currentRole !== SystemRoleAlias.SaleStaff && company.companyStatus === 'waiting_for_review'
    && !company.lastReviewer) {
      return '';
    }
    if (this.currentRole === SystemRoleAlias.SaleDepartmentPic && company.companyStatus === 'waiting_for_review'
    && company.lastReviewer && company.lastReviewer.role &&
    company.lastReviewer.role.alias === SystemRoleAlias.SaleStaff) {
      return 'waiting_for_review';
    }
    if (this.currentRole === SystemRoleAlias.GroupLeader && company.companyStatus === 'waiting_for_review'
    && company.lastReviewer && company.lastReviewer.role &&
    company.lastReviewer.role.alias === SystemRoleAlias.SaleDepartmentPic) {
      return 'waiting_for_review';
    }

    return company.lastReviewStatus ? company.lastReviewStatus : 'waiting_for_review';
  }
  public formatterFinancialCompanyName(model) {
    if (model && model.companyStatus) {
      switch (model.companyStatus) {
      case FinancialCompanyStatusCode.RequestStopJoining:
        return FinancialCompanyStatus.RequestStopJoining;
      }
    }
    if (model && model.lastReviewStatus) {
      switch (model.lastReviewStatus) {
      case FinancialCompanyStatusCode.WaitingForReview:
        return FinancialCompanyStatus.WaitingForReview;
      case FinancialCompanyStatusCode.Approved:
        return FinancialCompanyStatus.Approved;
      case FinancialCompanyStatusCode.Rejected:
        return FinancialCompanyStatus.Rejected;
      case FinancialCompanyStatusCode.HaveCode:
        return FinancialCompanyStatus.HaveCode;
      case FinancialCompanyStatusCode.RequestStopJoining:
        return FinancialCompanyStatus.RequestStopJoining;
      case FinancialCompanyStatusCode.StopJoining:
        return FinancialCompanyStatus.StopJoining;
      default:
        return '--';
      }
    }

    return '--';
  }
  public getActiveStatisticFilter(alias) {
    if (this.filterParams[FilterKey.Company].includes(alias)) {
      return true;
    }

    return false;
  }
  public onClickStatistic(alias) {
    if (this.filterParams[FilterKey.Company].includes(alias)) {
      const ind = this.filterParams[FilterKey.Company].indexOf(alias);
      this.filterParams[FilterKey.Company].splice(ind, 1);
      this.fetchData();

      return;
    }
    this.filterParams[FilterKey.Company].push(alias);
    this.fetchData();
  }
  public clearStatistic() {
    this.filterParams[FilterKey.Company] = ['HD', 'FE', 'ACS'];
    this.fetchData();
  }
  public formatterFinacialCompanyUpdateAt(model) {
    if (model && model.lastReview && model.lastReview.createdAt) {
      return this.FormatDateFullTime(model.lastReview.createdAt);
    }
    if (model && model.ip_campaign_company_status_logs && model.ip_campaign_company_status_logs.length > 0) {
      return this.FormatDateFullTime(model.ip_campaign_company_status_logs[0].createdAt);
    }

    return '--';
  }
  public FormatterDateByStatusLogs(model: any) {
    if (model && model.ip_campaign_company_status_logs.length) {
      let _firstLog: any = model.ip_campaign_company_status_logs[0];
      model.ip_campaign_company_status_logs.map((log) => {
        if (moment(log.createdAt).isAfter(moment(_firstLog.createdAt))) {
          _firstLog = log;
        }
      });

      return this.FormatDateFullTime(_firstLog.createdAt);
    }

    return model.updatedAt ? this.FormatDateFullTime(model.updatedAt) : '--';
  }
  public formatterUpdateByRole(model) {
    if (model && model.updatedBy && model.updatedBy.role && model.updatedBy.role.name) {

      return model.updatedBy.role.name;
    }

    return '--';
  }
  public formatterUpdateByName(model) {
    if (model && model.updatedBy && model.updatedBy.name) {

      return model.updatedBy.name;
    }

    return '--';
  }
  public importPosCode() {
    this.loadingImport = true;
    this.visibleImport = true;
  }
  public closeModalImport() {
    this.loadingImport = false;
    this.visibleImport = false;
    this.$refs.upload.clearFiles();
    this.fileList = [];
  }
  public downloadTemplate() {
    window.open(this.urlTemplate, '_blank');
  }
  public pushFileUpload(file: any) {
    const f = this.fileList;
    if (this.fileList.length > 0) {
      const _file = this.fileList.filter((it) => it.name === file.name);
      if (_file && _file.length > 0) {
        this.$refs.upload.clearFiles();
        const message = 'File has been exits';
        this.$store.commit(MutationType.OpenTopAlert, {
          message,
          type: TypeAlert.Warning
        });

        return this.$refs.upload.$data.uploadFiles = f;
      }

      return this.fileList = [file];
    }

    f.push(file);

    return this.fileList = [file];
  }

  public onRemove(_file: any, fileList: any) {
    this.fileList = fileList;
  }

  public async submitFile() {
    // tslint:disable-next-line: whitespace
    try {
      this.loadingUpload = true;
      // const uploadedFile = <any> [];
      const token = await DKGqlClient().user.getFirebaseAuthenticateToken();
      await FirebaseAuthService().signInWithCustomToken(token.authenticate);
      const uploadedFile = await Promise.all(this.fileList.map(async (it) => {
        const _file = await uploadToFirebase(it.raw, this.pathUploadAvatar);

        // return _file ? uploadedFile.push(_file) : uploadedFile;
        // tslint:disable-next-line:no-var-before-return
        return _file;
      }));

      if (uploadedFile.length > 0) {
        const form = {
          createdBy: this.authUser.id,
          url: uploadedFile[0],
          type: 'ip_campaign_pos_code',
          isReIndex: true
        };
        this.$store.dispatch(ActionTypeZeroCampaign.ImportPosCode, {
          form,
          onSuccess: (result) => {
            this.loadingUpload = false;
            if (result && result.data && result.data.insert_imports
            && result.data.insert_imports.returning && result.data.insert_imports.returning.length) {
              this.subscribeImport = <any> gqlClient.subscribe({
                query: zeroCampaignQuery.IMPORT_POS_CODE_SUBSCRIBE,
                variables : {id: result.data.insert_imports.returning[0].id},
                fetchPolicy: 'cache-first'
              }).subscribe((results) => {
                if (results.data && results.data.imports && results.data.imports.length
                  && results.data.imports[0].importStatus === 'done') {
                  const message = 'Import POS Code successfully';
                  this.$store.commit(MutationType.OpenTopAlert, {
                    message,
                    type: TypeAlert.Success
                  });
                  this.subscribeImport.unsubscribe();
                  this.loadingExport = false;
                  this.fetchData();
                } else if (results.data && results.data.imports && results.data.imports.length
                  && results.data.imports[0].importStatus === 'failed') {
                  const message = 'Import POS Code failure';
                  this.$store.commit(MutationType.OpenTopAlert, {
                    message,
                    type: TypeAlert.Error
                  });
                  this.subscribeImport.unsubscribe();
                  this.loadingExport = false;
                }
              });
            }
            this.visibleImport = false;
            this.fileList = [];
            this.fileUploaded = [];
            this.$refs.upload.clearFiles();
          },
          onFailure: () => {
            this.loadingUpload = false;

          }});
      }

    } catch (error) {
      console.log(error);
    }

  }
  public formatterFinancialCompanyStatus(status) {
    if (status) {
      switch (status) {
      case FinancialCompanyStatusCode.WaitingForReview:
        return FinancialCompanyStatus.WaitingForReview;
      case FinancialCompanyStatusCode.Approved:
        return FinancialCompanyStatus.Approved;
      case FinancialCompanyStatusCode.Rejected:
        return FinancialCompanyStatus.Rejected;
      case FinancialCompanyStatusCode.HaveCode:
        return FinancialCompanyStatus.HaveCode;
      case FinancialCompanyStatusCode.RequestStopJoining:
        return FinancialCompanyStatus.RequestStopJoining;
      case FinancialCompanyStatusCode.StopJoining:
        return FinancialCompanyStatus.StopJoining;
      default:
        return '--';
      }
    }

    return '--';
  }
  public formatterLabelStatus(model: any) {
    if (model && model.companyStatus) {
      switch (model.companyStatus) {
      case FinancialCompanyStatusCode.RequestStopJoining:
        return FinancialCompanyColor.RequestStopJoining;
      }
    }
    if (model && model.lastReviewStatus) {
      switch (model.lastReviewStatus) {
      case FinancialCompanyStatusCode.WaitingForReview:
        return FinancialCompanyColor.WaitingForReview;
      case FinancialCompanyStatusCode.Approved:
        return FinancialCompanyColor.Approved;
      case FinancialCompanyStatusCode.Rejected:
        return FinancialCompanyColor.Rejected;
      case FinancialCompanyStatusCode.HaveCode:
        return FinancialCompanyColor.HaveCode;
      case FinancialCompanyStatusCode.RequestStopJoining:
        return FinancialCompanyColor.RequestStopJoining;
      case FinancialCompanyStatusCode.StopJoining:
        return FinancialCompanyColor.StopJoining;
      default:
        return '--';
      }
    }

    return '--';
  }
  public onClickDetail(id) {
    const detail = this.$store.getters.getRequestCampaignDetail(id);
    this.visibleDetail = true;
    this.campaignId = detail.ipCampaign.id;
  }
  public onClickUpdateCompany(id) {
    const campaign = this.$store.getters.getZeroCampaignDetail(id);
    this.listFinancialCompany = [];
    if (campaign && campaign.ip_campaign_companies.length) {
      campaign.ip_campaign_companies.map((company) => {
        this.listFinancialCompany.push(company.financial_company.alias);
      });
    }
    this.visibleUpdateCompany = true;
    this.campaignId = id;
  }
  public closeDialogDetail() {
    this.visibleDetail = false;
    this.campaignId = '';
    this.fetchData();
  }
  public closeDialogUpdateFinancialCompany() {
    this.visibleUpdateCompany = false;
    this.campaignId = '';
    this.fetchData();
  }
  public changeFilter(model) {
    const status = model.find((e) => e.key === FilterKey.Status);
    const type = model.find((e) => e.key === FilterKey.Type);
    const nextFilter = {
      [FilterKey.Status]: status ? status.value : [],
      [FilterKey.Type]: type ? type.value : [],
    };
    this.filterParams = {
      ...cloneDeep(this.filterParams),
      ...nextFilter
    };
    this.fetchData();
  }
  public changeSelect(value) {
    this.selectedItem = value;
  }
  public closeDialogApprove() {
    this.visibleApprove = false;
    this.campaignCompany = {};
    setTimeout(() => {
      this.$emit('refetchData');
    }, 500);
  }
  public closeDialogReject() {
    this.visibleReject = false;
    this.campaignCompany = {};
    setTimeout(() => {
      this.$emit('refetchData');
    }, 500);
  }
  public clickApprove(id) {
    this.campaignCompany = this.$store.getters.getRequestCampaignDetail(id);
    this.visibleApprove = true;
  }
  public clickReject(id) {
    this.campaignCompany = this.$store.getters.getRequestCampaignDetail(id);
    this.visibleReject = true;
  }

  public changeSearchBy(model: IFilterInput[]) {
    this.modelSearchBy = model[0].value;

    this.fetchData();
  }
  public onEnterFilter(value) {
    this.searchText = value;

    this.fetchData();
  }
  public async mounted() {
    this.$nextTick(() => {
      const breadcrumb: IBreadcrumb[] = [
        {
          label: 'title.zer-per-campaign',
          path: null,
          current: false
        },
      ];
      this.$store.dispatch(ActionTypeBranchManage.GetListBranch, {
        form: {
          filter: {},
          pagination: {}
        }
      });
      this.$store.dispatch(ActionTypeGroupManage.GetListGroupManage, {
        form: {
          filter: {},
          pagination: {}
        }
      });
      this.$store.commit(MutationType.SetBreadcrumb, breadcrumb);
      this.fetchData();
      this.fetchDataStatistic();
    });
  }
  public beforeDestroy() {
    this.$store.commit(MutationType.ClearBreadcrumb);
  }
  public async refetchData() {
    this.fetchData();
    this.fetchDataStatistic();
  }
  public async fetchData() {
    const text = cloneDeep(this.searchText);
    // const _filterParams = <any> {};
    const _searchModel: any[] = [];
    const {company, type, status} = this.filterParams;
    if (this.modelSearchBy.length && this.modelSearchBy.indexOf('dealer') >= 0) {
      _searchModel.push({
        dealer: {
          name :  {
            _ilike: `%${text}%`
          }
        }
      });
    }
    if (this.modelSearchBy.length && this.modelSearchBy.indexOf('dealerCode') >= 0) {
      _searchModel.push({
        dealer: {
          dealerCode :  {
            _ilike: `%${text}%`
          }
        }
      });
    }
    if (this.modelSearchBy.length && this.modelSearchBy.indexOf('dealerShop') >= 0) {
      _searchModel.push({
        shop: {
          name :  {
            _ilike: `%${text}%`
          }
        }
      });
    }
    if (this.modelSearchBy.length && this.modelSearchBy.indexOf('shopCode') >= 0) {
      _searchModel.push({
        shop: {
          shopCode :  {
            _ilike: `%${text}%`
          }
        }
      });
    }
    if (this.modelSearchBy.length && this.modelSearchBy.indexOf('posCode') >= 0) {
      _searchModel.push({
        ip_campaign_companies: {
          posCode :  {
            _ilike: `%${text}%`
          }
        }
      });
    }
    this.$store.commit(MutationTypeZeroCampaignRequestReview.FilterChange, stripObject({
      _and:
      {
        mode: 'special',
        data: [
          {
            ...this.authUser.role.alias !== SystemRoleAlias.SaleStaff ? {
              lastReviewer: {
                role: {
                  alias: {
                    _eq: this.authUser.role.alias === SystemRoleAlias.SaleDepartmentPic ?
                    SystemRoleAlias.SaleStaff : this.authUser.role.alias === SystemRoleAlias.GroupLeader ?
                    SystemRoleAlias.SaleDepartmentPic : null
                  }
                }
              }
            } : {}
          },
          {
            ...this.authUser.role.alias === SystemRoleAlias.SaleStaff ? {
              companyStatus: {
                _in: [
                  'waiting_for_review',
                  'request_stop_joining'
                ]
              },
              requesterStopId: {
                _is_null: true
              },
              _or: [
                {
                  lastReviewerId: {
                    _is_null: true
                  }
                },
                {
                  lastReviewer: {
                    role: {
                      alias: {
                        _nin: [
                          'sale_staff',
                          'sale_department_pic'
                        ]
                      }
                    }
                  }
                }
              ],
            } : {}
          }
        ].filter((e) => !!e)
      },
      ...company && company.length ? {
        financial_company: {
          alias: company
        }
      } : {},
      ...type && type.length ? {
        type
      } : {},
      ...status && status.length ? {
        lastReviewStatus: status
      } : {}
    }
    ));
    this.$store.dispatch(ActionTypeZeroCampaignRequestReview.FilterNoCache, {});
  }
  public async fetchDataStatistic() {
    this.$store.dispatch(ActionTypeZeroCampaign.GetStatisticParticipantList, {
      where: {
        ...this.authUser.role.alias !== SystemRoleAlias.SaleStaff ? {
          lastReviewer: {
            role: {
              alias: {
                _eq: this.authUser.role.alias === SystemRoleAlias.SaleDepartmentPic ?
                SystemRoleAlias.SaleStaff : this.authUser.role.alias === SystemRoleAlias.GroupLeader ?
                SystemRoleAlias.SaleDepartmentPic : null
              }
            }
          }
        } : {},
        ...this.authUser.role.alias === SystemRoleAlias.SaleStaff ? {
          companyStatus: {
            _in: [
              'waiting_for_review',
              'request_stop_joining'
            ]
          },
          requesterStopId: {
            _is_null: true
          },
          _or: [
            {
              lastReviewerId: {
                _is_null: true
              }
            },
            {
              lastReviewer: {
                role: {
                  alias: {
                    _nin: [
                      'sale_staff',
                      'sale_department_pic'
                    ]
                  }
                }
              }
            }
          ],
        } : {}
      },
      onSuccess: (data) => {
        const clone = {
          fe: data.financial_companies.find((e) => e.alias === 'FE').campaignCompanies_aggregate.aggregate.count,
          hd: data.financial_companies.find((e) => e.alias === 'HD').campaignCompanies_aggregate.aggregate.count,
          acs: data.financial_companies.find((e) => e.alias === 'ACS').campaignCompanies_aggregate.aggregate.count
        };
        this.statistic = {...clone};
      }
    });
  }
}
