import { DocumentNode } from 'graphql';
import gql from 'graphql-tag';
import { crudQuery, ICRUDQuery } from '../Core/query';

const dealerTypeModel = `
  type
  name
  alias
  description
  status
  createdAt
  updatedAt
`;

export const dealerType = 'dealer_types';
// tslint:disable-next-line:no-empty-interface
interface IDealerTypeQuery extends ICRUDQuery {
  INSERT_DEALER_TYPES: DocumentNode;
  UPDATE_DEALER_TYPES: DocumentNode;
}
export const dealerTypeListQuery: IDealerTypeQuery = {
  ...crudQuery(dealerType, dealerTypeModel),
  INSERT_DEALER_TYPES: gql`
  mutation InsertDealerType($form: [dealer_types_insert_input!]!) {
    insert_dealer_types(objects: $form) {
      affected_rows
    }
  }
  `,
  UPDATE_DEALER_TYPES: gql`
  mutation UpdateDealerType($alias: String!, $form: dealer_types_set_input!){
    update_dealer_types(where: {alias: {_eq: $alias}}, _set: $form){
      affected_rows
    }
  }
  `
};
