import { crudMutations, CRUDState, ICRUDState } from 'root/store/helpers';
import { MutationTypeRedemption } from './types';

export interface IRedemptionState extends ICRUDState<any> {
  loading: boolean;
  campaign: ICRUDState<any>;
  detail: any;
  voucher: any;
}

export const defaultState: IRedemptionState = {
  ...CRUDState(),
  loading: false,
  campaign: CRUDState(),
  detail: {
    data: [],
    pagination: {
      limit: 10,
      page: 1
    }
  },
  voucher: {
    data: [],
    loading: false
  }
};

export const mutations = {
  ...crudMutations('rewards'),
  ...crudMutations('campaigns', 'id', {keyState: 'campagins'}),
  [MutationTypeRedemption.GetRedeemDetail](state: IRedemptionState, data) {
    state.detail.data = data.data;
    state.detail.pagination = {
      ...state.detail.pagination,
      ...data.pagination,
      limit: data.pagination && data.pagination.size ? data.pagination.size : 20,
      page: data.pagination && data.pagination.page ? data.pagination.page : 1
    };
  },
  [MutationTypeRedemption.GetListVoucher](state: IRedemptionState, data) {
    state.voucher.data = data.voucher_codes;
    state.voucher.loading = data.loading;
  }
};
