import './styles.scss';

// import moment from 'moment';
import { cloneDeep, find } from 'lodash';
import { ChangePasswordDialog } from 'root/admin/User/Components/ChangePasswordDialog';
import { IPaginationParams } from 'root/api/graphql/Core';
import { Avatar } from 'root/components/Avatar';
import { fullNameUser } from 'root/helpers';
import { IBreadcrumb, IFilterInput, StatusCode } from 'root/models';
import { ESystemGroup, IUser } from 'root/models/User';
import { IState, MutationType, TypeAlert } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import { BranchAndGroupRouterPath } from '../..';
import { DealerRouterName } from '../../../Dealer';
import { DealerEditor } from '../../../Dealer/Components/DealerEditor';
import { ActionTypeDealer, MutationTypeDealer } from '../../../Dealer/Store/types';
import { ActionTypeGroupManage } from '../../GroupManage/Store/types';
import { ActionTypeBranchManage } from '../Store/types';

const enum FilterKey {
  Group = 'groupdId',
  Role = 'roleId',
  Status = 'status'
}

@Component({
  template: require('./view.html'),
  components: {
    editor: DealerEditor,
    avatar: Avatar,
    // 'user-detail-modal': UserDetailModal,
    'change-pass-modal': ChangePasswordDialog,
  },
  computed: {
    ...mapState({
      // authUser: (state: IState) => state.global.authUser,
      data: (state: IState) => state.dealer.data,
      pagination: (state: IState) => state.dealer.pagination,
      loading: (state: IState) => state.dealer.loading,
      group: (state: IState) => state.group.data,
      typefilter: (state: IState) => state.role.data,
    }),
    selectedStatus() {
      const filterParams = [];

      if (!filterParams) {
        return [];
      }

      return [{
        key: 'isCreatedByAppleReviewer',
        value: []
      }];
    }
  },
})

export class BranchDealerList extends Vue {
  public get serviceId(): string {
    return this.$route.params.id;
  }
  public get columns() {
    return [{
      label: 'Name',
      formatter: (model: any) => {
        return fullNameUser(model) || '--';
      },
      avatar: {
        formatter: (model: any) => {
          return model ? model : null;
        }
      },
      width: 250,
      fixed: true
    },
    {
      prop: 'code',
      label: 'Code',
      width: 180,
      formatter: (model: any) => {
        return model.dealerCode || '--';
      }
    },
    {
      prop: 'type',
      label: 'Type',
      width: 180,
      formatter: (model: any) => {
        if (model.role && model.role.name) {
          return model.role.name;
        }

        return '--';
      }
    },
    // {
    //   prop: 'subDealerId',
    //   label: 'sub-dealer ID',
    //   width: 180,
    //   formatter: (model: any) => {
    //     return model.loginId;
    //   }
    // },
    {
      prop: 'email',
      label: 'Email',
      width: 180,
      formatter: (model: any) => {
        return model.email || '--';
      }
    },
    {
      prop: 'branch',
      label: 'Branch',
      width: 180,
      formatter: (model: any) => {
        if (model.branch && model.branch.name) {
          return model.branch.name;
        }

        return '';
      }
    },
    {
      prop: 'group',
      label: 'Department',
      formatter: (model: any) => {
        if (model.group && model.group.name) {
          return model.group.name;
        }

        return '--';
      },
      width: 180
    },
    // {
    //   prop: 'saleIn',
    //   label: 'Sale in',
    //   width: 180,
    //   formatter: (model: any) => {
    //     return model.saleIn || '0';
    //   }
    // },
    // {
    //   prop: 'saleOut',
    //   label: 'Sale-out',
    //   width: 180,
    //   formatter: (model: any) => {
    //     return model.saleOut || '0';
    //   }
    // },
    // {
    //   prop: 'inventory',
    //   label: 'Inventory',
    //   width: 180,
    //   formatter: (model: any) => {
    //     return model.inventory || '0';
    //   }
    // },
    {
      prop: 'address',
      label: 'Address',
      width: 180,
      formatter: (model: any) => {
        if (model.address) {
          // tslint:disable-next-line:prefer-template
          const _address = model.address.address;

          return _address || '--';
        }

        return '';
      }
    },
    {
      prop: 'description',
      label: 'Description',
      width: 180,
      formatter: (model: any) => {
        return model.description || '--';
      }
    },
    {
      prop: 'status',
      label: this.$t('status'),
      formatter: (model: any) => model.status ? this.$t(model.status) : '',
      labelStyle: (model: any) => {
        return model.status;
      },
      align: 'center',
      width: 180
    }];

  }
  public get actions() {

    return ['view', 'edit', 'block'];
  }

  public authUser: IUser;
  public pagination: IPaginationParams;
  public data: any[];
  public group: any[];
  public typefilter: any[];
  public status: any[];
  public visible: boolean = false;
  public isSample: boolean = false;
  public userId: string = '';
  public visibleDetail: boolean = false;
  public popupVisible: boolean = false;
  public filterParams = {
    [FilterKey.Group]: [],
    [FilterKey.Role]: [],
    [FilterKey.Status]: []
  };
  public searchText: string = '';
  public filterTypeDealer: any[];
  // public showUploadDialog() {
  //   this.isSample = true;
  // }

  public get inputSearchPlaceholder(): string {

    return `Search by Name, Email`;
  }

  public UserType = {
    Customer: 'customer',
    System: 'system',
  };
  public type: any;
  // tslint:disable-next-line:max-line-length
  private sampleUrl = `https://firebasestorage.googleapis.com/v0/b/daikin-test-staging.appspot.com/o/technicians%2Ftechnicians-import-sample.xlsx?alt=media&token=279661a1-11fe-49a0-8109-364e964ec11b`;

  // public isShowButtonNew(): boolean {
  //   if (this.authUser.group === GroupSystemUserType.Station) {
  //     return false;
  //   }

  //   return true;
  // }

  public closeDialogDetail() {
    this.userId = '';
    this.visibleDetail = false;
  }

  public createSuccess() {
    this.visible = false;
    this.fetchData();
  }

  public applyFilter() {
    this.fetchData();
  }
  public goAddNewRouter() {
    this.visible = true;
    this.userId = '';
  }
  public closeDialog() {
    this.isSample = false;
    this.fetchData();
  }
  public downloadSample() {
    window.open(
      this.sampleUrl,
      '_blank'
    );
  }
  public handleSearch() {
    this.fetchData();
  }

  public handleDisabled(model: any) {
    const user = find(
      cloneDeep(this.data),
      (e) => e.id.toString() === model.id.toString()
    ),
      isBlocked = user.status === StatusCode.Blocked;
    this.$store.dispatch(ActionTypeDealer.UpdateStatusDealer, {
      id: model.id,
      status: isBlocked ? StatusCode.Active : StatusCode.Blocked,
      onSuccess: () => {
        this.fetchData();
        model.instance.confirmButtonLoading = false;
        model.instance.confirmButtonText = 'Confirm';
        const message = 'Block Dealer success';
        this.$store.commit(MutationType.OpenTopAlert, {
          message,
          type: TypeAlert.Success
        });
        model.done();
      },
      onFailure: () => {
        model.instance.confirmButtonLoading = false;
        model.instance.confirmButtonText = 'Confirm';
      }
    });
  }
  public reduceType() {
    const roleSystem = this.typefilter.filter((it: any) =>
    it.systemGroup.name &&
    it.systemGroup.name === ESystemGroup.Dealer &&
    (it.name === 'Daikin Dealer' || it.name === 'Guest'));

    // tslint:disable-next-line:no-var-before-return
    return roleSystem;
  }

  public get dataFilter() {
    this.reduceType();
    const _groups: IFilterInput[] = this.group.reduce((res, item) => {
      res.push({
        key: item.id,
        value: item.id,
        name: item.name
      });

      return res;
    }, []);
    const _typeFilter: IFilterInput[] = this.reduceType().reduce((res, item) => {
      res.push({
        key: item.id,
        value: item.id,
        name: item.name
      });

      return res;
    }, []);
    const _statuses: IFilterInput[] = [
      {
        key: StatusCode.Active,
        value: StatusCode.Active,
        name: this.$t(StatusCode.Active)
      },
      {
        key: StatusCode.Blocked,
        value: StatusCode.Blocked,
        name: this.$t(StatusCode.Blocked)
      }
    ];

    return [
      {
        key: FilterKey.Group,
        name: this.$t('field.group'),
        value: _groups
      },
      {
        key: FilterKey.Role,
        name: 'Type',
        value: _typeFilter
      },
      {
        key: FilterKey.Status,
        name: this.$t('field.status'),
        value: _statuses
      }
    ];
  }
  public get selectedFilter() {
    const filterParams = cloneDeep(this.filterParams);

    return [
      {
        key: FilterKey.Group,
        value: filterParams[FilterKey.Group]
      },
      {
        key: FilterKey.Role,
        value: filterParams[FilterKey.Role]
      },
      {
        key: FilterKey.Status,
        value: filterParams[FilterKey.Status]
      }
    ];
  }

  public clickDetail(id: string) {
    this.$router.push({
      name: DealerRouterName.DetailDealer,
      params: {
        id
      }});
  }
  public handleUpdate(id: string) {
    this.visible = true;
    this.userId = id;
  }

  public changeFilter(model) {
    const group = model.find((e) => e.key === FilterKey.Group);
    const role = model.find((e) => e.key === FilterKey.Role);
    const status = model.find((e) => e.key === FilterKey.Status);
    const nextFilter = {
      [FilterKey.Group]: group ? group.value : [],
      [FilterKey.Role]: role ? role.value : [],
      [FilterKey.Status]: status ? status.value : []
    };
    this.filterParams = {
      ...cloneDeep(this.filterParams),
      ...nextFilter
    };
    this.fetchData();
  }
  public handlePaginationChange(value) {
    if (typeof value === 'number') {
      this.$store.commit(MutationTypeDealer.DealerPaginationChange, {
        ...this.pagination,
        size: value
      });
    } else {
      this.$store.commit(MutationTypeDealer.DealerPaginationChange, {
        ...this.pagination,
        ...value
      });
    }
    this.fetchData();
  }

  public formatFullNameUser(model) {
    return fullNameUser(model);
  }

  protected mounted() {
    this.$nextTick(() => {
      const breadcrumb: IBreadcrumb[] = [
        {
          label: 'title.managebranch',
          path: BranchAndGroupRouterPath.ListBranch,
          current: false
        },
        {
          label: 'title.view_details',
          path: null,
          current: true
        }
      ];
      this.$store.commit(MutationType.SetBack);
      this.$store.commit(MutationType.SetBreadcrumb, breadcrumb);
      this.$store.commit(MutationTypeDealer.PaginationReset);
      this.fetchData();
      this.$store.dispatch(ActionTypeBranchManage.GetListBranch, {
        form: {
          filter: {},
          pagination: {}
        }
      });
        // this.$store.dispatch(ActionTypeGroup.FilterNoCache);
      this.$store.dispatch(ActionTypeGroupManage.GetListGroupManage, {
        form: {
          filter: {},
          pagination: {}
        }
      });
    });
  }
  protected beforeDestroy() {
    this.$store.commit(MutationType.ClearBreadcrumb);
  }

  private fetchData() {
    const text = cloneDeep(this.searchText);
    const filterDealer = <any> {
      branch: this.serviceId,
      status: [StatusCode.Active, StatusCode.Blocked],
      // isGetProducts: true
    };

    if (this.filterParams[FilterKey.Group].length) {
      filterDealer.group = this.filterParams[FilterKey.Group];
    }
    if (this.filterParams[FilterKey.Role].length) {
      filterDealer.type = this.filterParams[FilterKey.Role];
    }
    if (this.filterParams[FilterKey.Status].length) {
      filterDealer.status = this.filterParams[FilterKey.Status];
    }
    if (text) {
      filterDealer.search = text;
    }
    this.$store.dispatch(ActionTypeDealer.GetListDealer, {
      form: {
        filter: filterDealer,
        pagination: {
          size: this.pagination.limit,
          page: text ? 1 : this.pagination.page
        }
      }
    });
  }
}
