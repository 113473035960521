export enum BannersActionType {
  BannersFilterNoCache = 'bannersFilterNoCache',
  BannersDelete = 'bannersDelete',
  BannersUpdate = 'bannersUpdate',
  BannersCreate = 'bannersCreate',
  UpdatePriority = 'bannersUpdatePriority',
  InsertBranchBanner = 'bannersInsertBranch',
  UpdateBranchBannrt = 'bannersUpdateBranch',
  RemoveBranchBanner = 'bannersRemoveBranch',
  UpdateDescriptionServiceType = 'updateDescriptionServiceType',
  FetchAllConfiguration = 'FetchAllConfiguration',
  ChangeConfig = 'changeConfig'
}

export enum BannersMutationType {
  BannersLoading = 'bannersLoading',
  BannersLoaded = 'bannersLoaded',
  PaginationChange = 'bannersPaginationChange',
  FilterChange = 'bannersFilterChange',
  UpdateModels = 'bannersUpdateModels',
  SetConfiguration = 'setConfiguration',
  ConfigPaginationChange = 'configPaginationChange'
}
