import { cloneDeep, find } from 'lodash';
import { IDealerTypeState } from './mutation';

export const getters = {
  getDealerTypeDetail(state: IDealerTypeState) {
    return (alias: string) => {
      return find(cloneDeep(state.data), (e) => e.alias === alias);
    };
  }
};
