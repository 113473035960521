// import { IErrorException } from 'root/models';
import { ConsoleLogger } from '../logger/console';
import { getBugsnag } from './bugsnagInit';

export function sendErrorToBugsnag(error) {
  const bugsnagClient = getBugsnag();
  if (!bugsnagClient) {
    ConsoleLogger.warn('Do not load bugsnag');

    return;
  }
  const message = error.graphQLErrors && error.graphQLErrors[0] && error.graphQLErrors[0].message
    ? error.graphQLErrors[0].message : error.message ? error.message : 'Bug without message';
  const _error = new Error(`[${process.env.NODE_ENV}] ${message}`);

  bugsnagClient.notify(_error, {
    metaData: error
  });
}
export function sendAppVersionToBugsnag() {
  const bugsnagClient = getBugsnag();
  if (!bugsnagClient) {
    ConsoleLogger.warn('Do not load bugsnag');

    return;
  }
  bugsnagClient.startSession();
}
