import { DocumentNode } from 'graphql';
import gql from 'graphql-tag';
import { crudQuery, ICRUDQuery } from '../Core/query';
const zeroCampaignModel = `
  id
  ownerName
  ownerPhone
  salesmanName
  salesmanPhone
  referenceId
  dealer {
    id
    name
    phoneNumber
    ownerName
    dealerCode
    branch {
      id
      name
    }
    group {
      id
      name
    }
  }
  shop {
    id
    name
    shopCode
    address
  }
  ip_campaign_companies(where: { status : {_eq: "active"}}) {
    id
    companyStatus
    posCode
    reasons
    updatedAt
    type
    createdAt
    updater {
      name
      role {
        name
      }
    }
    financial_company {
      name
      alias
    }
    lastReviewStatus
    ip_campaign_company_status_logs {
      companyStatus
      reasons
      campaignCompanyId
      createdAt
      dealer {
        id
        name
      }
      system_user {
        id
        name
      }
    }
  }
`;
const RequestModel = `
id
lastReviewStatus
companyStatus
ip_campaign_company_status_logs(
  where: {
    companyStatus: {
      _eq: "waiting_for_review"
    }
  },
  limit: 1,
  order_by: {
    createdAt: desc
  }
) {
  createdAt
}
financial_company {
  name
}
lastReview {
  reason
  type
  reviewStatus
  createdAt
}
updatedBy: lastReviewer {
  name
  role {
    name
  }
}
type
ipCampaign {
  id
  shop {
    name
    shopCode
  }
  dealer {
    name
    ownerName
    dealerCode
  }
}
`;

export const zeroCampaign = 'ip_campaigns';
// tslint:disable-next-line:no-empty-interface
interface IZeroCampaignQuery extends ICRUDQuery {
  GET_BY_ID: DocumentNode;
  UPDATE_SALESMAN_INFO: DocumentNode;
  GET_LIST_COMPANY_ID: DocumentNode;
  UPDATE_FINANCIAL_COMPANY: DocumentNode;
  APPROVE_REJECT_CAMPAIGN: DocumentNode;
  IMPORT_POS_CODE: DocumentNode;
  GET_CONFIG: DocumentNode;
  FORCE_CLOSE_CODE: DocumentNode;
  EXPORT_CODE: DocumentNode;
  DOWNLOAD_EXPORT: DocumentNode;
  GET_STATISTIC: DocumentNode;
  GET_STATISTIC_TABLE: DocumentNode;
  GET_STATISTIC_BY_BRANCH: DocumentNode;
  GET_STATISTIC_FOR_DEPARTMENT_PIC: DocumentNode;
  IMPORT_POS_CODE_SUBSCRIBE: DocumentNode;
  UPDATE_OWNER_INFO: DocumentNode;
  UPDATE_MANAGER_INFO: DocumentNode;
  UPDATE_POS_CODE: DocumentNode;
  APPROVE_CAMPAIGN: DocumentNode;
  GET_STATISTIC_PARTICIPANT: DocumentNode;
  GET_STATISTIC_PARTICIPANT_SALE_ADMIN: DocumentNode;
  APPROVE_ALL: DocumentNode;
  REQUEST_LIST: ICRUDQuery;
  GET_STATISTIC_LIST: DocumentNode;
  GET_STATISTIC_LIST_SHOP: DocumentNode;
  EXPORT_CAMPAIGN_OVERVIEW: DocumentNode;
  GET_CONFIGURATION: DocumentNode;
  UPDATE_CONFIGURATION: DocumentNode;
  GET_CONFIG_HISTORY_LOGS: DocumentNode;
}
export const zeroCampaignQuery: IZeroCampaignQuery = {
  ...crudQuery(zeroCampaign, zeroCampaignModel),
  REQUEST_LIST: crudQuery('ip_campaign_companies', RequestModel),
  GET_BY_ID: gql`
  query GET_ZERO_CAMPAIGN_BY_ID($id: uuid!) {
    ip_campaigns (where: {id : {_eq: $id}}){
      id
      ownerName
      ownerPhone
      salesmanName
      salesmanPhone
      referenceId
      dealerId
      dealer {
        id
        name
        dealerCode
        phoneNumber
        ownerName
      }
      shop {
        id
        name
        shopCode
        address
        managerName
        managerPhone
      }
      ip_campaign_companies(where: {status: {_eq: "active"}}) {
        id
        companyStatus
        lastReviewStatus
        posCode
        reasons
        type
        updatedAt
        createdAt
        posCode
        codeTimeAvailable
        stopRequester {
          id
          name
          role {
            alias
          }
        }
        stoppedDaikin {
          id
          name
        }
        lastReviewer {
          role {
            alias
            name
          }
          name
        }
        lastReview {
          reason
          type
          reviewStatus
          createdAt
        }
        reviews(order_by: {createdAt: desc}){
          type
          creator {
            role {name}
            name
          }
          createdAt
          reviewStatus
        }
        stoppedDealer {
          id
          name
        }
        stopRequester {
          name
          role {
            alias
            name
          }
        }
        financial_company {
          name
          alias
        }
        ip_campaign_company_status_logs {
          companyStatus
          reasons
          campaignCompanyId
          createdAt
          dealer {
            id
            name
          }
          system_user {
            id
            name
          }
        }
      }
    }
  }`,
  UPDATE_SALESMAN_INFO: gql`
  mutation UPDATE_SALESMAN_INFO($id: uuid!, $set: ip_campaigns_set_input!) {
    update_ip_campaigns(where: {id: {_eq: $id}}, _set: $set) {
      affected_rows
    }
  }`,
  GET_LIST_COMPANY_ID: gql`
  query GET_LIST_COMPANY_ID($alias: [String!]){
    financial_companies(where: {alias: {_in: $alias}}) {
      id
    }
  }`,
  UPDATE_FINANCIAL_COMPANY: gql`
  mutation UPDATE_FINANCIAL_COMPANY($object: [ip_campaign_companies_insert_input!]!){
    insert_ip_campaign_companies(objects: $object) {
      affected_rows
    }
  }`,
  APPROVE_REJECT_CAMPAIGN: gql`
  mutation APPROVE_REJECT_CAMPAIGN($id: uuid!, $companyStatus: String!,
    $reason: String!, $updatedBy: uuid!, $updatedAt: timestamptz) {
    update_ip_campaign_companies(where: {id: {_eq : $id}}, _set: {companyStatus: $companyStatus,
      reasons: $reason, updatedBy: $updatedBy, updatedAt: $updatedAt}) {
      affected_rows
    }
  }`,
  IMPORT_POS_CODE: gql `
  mutation IMPORT_POS_CODE($objects: [imports_insert_input!]!){
    insert_imports(objects: $objects) {
      affected_rows
      returning {
        id
      }
    }
  }`,
  GET_CONFIG: gql`
  query GET_CONFIG {
    campaigns(where:{campaignType:{_eq:"IP_CAMPAIGN"}}){
      id
      metadata
    }
  }`,
  FORCE_CLOSE_CODE: gql`
  mutation FORCE_CLOSE_CODE($form: FormForceCloseCode) {
    forceCloseCode(form: $form)
  }`,
  EXPORT_CODE: gql `
  mutation EXPORT_CODE($objects: [exports_insert_input!]!){
    insert_exports(objects: $objects) {
      affected_rows
      returning {
        id
      }
    }
  }`,
  DOWNLOAD_EXPORT: gql`
  subscription DOWNLOAD_EXPORT_CODE($id: uuid!) {
    exports(where: {id: {_eq: $id}}){
      id
      url
      exportStatus
    }
  }
  `,
  GET_STATISTIC: gql`
  query GET_STATISTIC($filter: timestamptz_comparison_exp) {
    totalDealer: dealers_aggregate(where: {createdAt: $filter,
    role: {alias: {_eq: "daikin_dealer"}}, status: {_nin : ["deleted"]}}) {
      aggregate {
        count(columns: id)
      }
    }
    totalShop: shops_aggregate(where: {_and: [
      {dealerShops: {isOwner: {_eq:true}}}, {createdAt: $filter}
      {dealerShops: {dealer: {role: {alias: {_eq: "daikin_dealer"}}}}},
      {status: {_nin: ["deleted"]}}
    ]}){
      aggregate{
        count
      }
    }
    totalDealerJoinCampaign: ip_campaigns_aggregate(where: {createdAt: $filter,
    status: {
      _eq: "active"
    }}) {
      aggregate {
        count(columns: dealerId, distinct: true)
      }
    }
    totalShopJoinCampaign: ip_campaigns_aggregate(where: {createdAt: $filter,
    status: {
      _eq: "active"
    }}) {
      aggregate {
        count(columns: shopId, distinct: true)
      }
    }
    totalShopRequestStopJoining: ip_campaigns_aggregate(where:{createdAt: $filter,
    ip_campaign_companies:{companyStatus: {_eq: "request_stop_joining"}}}){
      aggregate{
        count(columns: shopId)
      }
    }
    totalDealerHaveCode: ip_campaigns_aggregate(where: {createdAt: $filter,
    ip_campaign_companies: {companyStatus: {_eq: "have_code"}}}) {
      aggregate {
        count(columns: dealerId, distinct: true)
      }
    }
    totalShopHaveCode: ip_campaigns_aggregate(where:{createdAt: $filter,
    ip_campaign_companies:{companyStatus: {_eq: "have_code"}}}){
      aggregate{
        count(columns: shopId)
      }
    }
    totalShopStopJoining: ip_campaigns_aggregate(where:{createdAt: $filter,
    ip_campaign_companies:{companyStatus: {_eq: "stop_joining"}}}){
      aggregate{
        count(columns: shopId)
      }
    }
    totalShopStopJoiningHD: ip_campaigns_aggregate(where:{createdAt: $filter, ip_campaign_companies:
    {companyStatus: {_eq: "stop_joining"},financial_company:{alias: {_eq: "HD"}}}}){
      aggregate{
        count(columns: shopId)
      }
    }
    totalShopHD: ip_campaigns_aggregate(where:{createdAt: $filter,
    ip_campaign_companies:{financial_company:{alias: {_eq: "HD"}}}}){
      aggregate{
        count(columns: shopId)
      }
    }
    totalShopStopJoiningFE: ip_campaigns_aggregate(where:{createdAt: $filter, ip_campaign_companies:
    {companyStatus: {_eq: "stop_joining"},financial_company:{alias: {_eq: "FE"}}}}){
      aggregate{
        count(columns: shopId)
      }
    }
    totalShopFE: ip_campaigns_aggregate(where:{createdAt: $filter,
    ip_campaign_companies:{financial_company:{alias: {_eq: "FE"}}}}){
      aggregate{
        count(columns: shopId)
      }
    }
    totalShopStopJoiningACS: ip_campaigns_aggregate(where:{createdAt: $filter, ip_campaign_companies:
    {companyStatus: {_eq: "stop_joining"},financial_company:{alias: {_eq: "ACS"}}}}){
      aggregate{
        count(columns: shopId)
      }
    }
    totalShopACS: ip_campaigns_aggregate(where:{createdAt: $filter,
    ip_campaign_companies:{financial_company:{alias: {_eq: "ACS"}}}}){
      aggregate{
        count(columns: shopId)
      }
    }
    totalDeviceRAofHD: ip_campaign_devices_aggregate(where: {
    createdAt: $filter,
    type: {
      _eq: "RA"
    },
    campaignCompany: {
      financial_company: {
        alias: {_eq: "HD"}
      }
    }
  }){aggregate{count}}
  totalDeviceACLofHD: ip_campaign_devices_aggregate(where: {
    createdAt: $filter,
    type: {
      _eq: "ACL"
    },
    campaignCompany: {
      financial_company: {
        alias: {_eq: "HD"}
      }
    }
  }){aggregate{count}}
  totalDeviceRAofFE: ip_campaign_devices_aggregate(where: {
    createdAt: $filter,
    type: {
      _eq: "RA"
    },
    campaignCompany: {
      financial_company: {
        alias: {_eq: "FE"}
      }
    },
  }){aggregate{count}}

  totalDeviceACLofFE: ip_campaign_devices_aggregate(where: {
    createdAt: $filter,
    type: {
      _eq: "ACL"
    },
    campaignCompany: {
      financial_company: {
        alias: {_eq: "FE"}
      }
    },
  }){aggregate{count}}

  totalDeviceRAofACS: ip_campaign_devices_aggregate(where: {
    createdAt: $filter,
    type: {
      _eq: "RA"
    },
    campaignCompany: {
      financial_company: {
        alias: {_eq: "ACS"}
      }
    },
  }){aggregate{count}}

  totalDeviceACLofACS: ip_campaign_devices_aggregate(where: {
    createdAt: $filter,
    type: {
      _eq: "ACL"
    },
    campaignCompany: {
      financial_company: {
        alias: {_eq: "ACS"}
      }
    },
  }){aggregate{count}}
  totalCustomerOfHD: ip_campaign_customers_aggregate(where: {
    createdAt: $filter,
    campaignCompany: {
      financial_company: {
        alias: {_eq: "HD"}
      }
    },
  }){aggregate{count}}

  totalCustomerOfFE: ip_campaign_customers_aggregate(where: {
    createdAt: $filter,
    campaignCompany: {
      financial_company: {
        alias: {_eq: "FE"}
      }
    },
  }){aggregate{count}}

  totalCustomerOfACS: ip_campaign_customers_aggregate(where: {
    createdAt: $filter,
    campaignCompany: {
      financial_company: {
        alias: {_eq: "ACS"}
      }
    },
  }){aggregate{count}}
    labelBranch: branches(where: {isTest:{_eq: false},status: {_eq: "active"}}) {
      id
      name
    }
  }
  `,
  GET_STATISTIC_TABLE: gql`
   query GET_STATISTIC_TABLE($filter: timestamptz_comparison_exp) {
    totalShopHaveCodeHD: ip_campaigns_aggregate(where:{createdAt: $filter, ip_campaign_companies:
    {companyStatus: {_eq: "have_code"},financial_company:{alias: {_eq: "HD"}}}}){
      aggregate{
        count(columns: shopId, distinct: true)
      }
    }
    totalDealerHaveCodeHD: ip_campaigns_aggregate(where:{createdAt: $filter, ip_campaign_companies:
    {companyStatus: {_eq: "have_code"},financial_company:{alias: {_eq: "HD"}}}}){
      aggregate{
        count(columns: dealerId, distinct: true)
      }
    }
    totalShopStopJoiningHD: ip_campaigns_aggregate(where:{createdAt: $filter, ip_campaign_companies:
    {companyStatus: {_eq: "stop_joining"},financial_company:{alias: {_eq: "HD"}}}}){
      aggregate{
        count(columns: shopId, distinct: true)
      }
    }
    totalDealerStopJoiningHD: ip_campaigns_aggregate(where:{createdAt: $filter, ip_campaign_companies:
    {companyStatus: {_eq: "stop_joining"},financial_company:{alias: {_eq: "HD"}}}}){
      aggregate{
        count(columns: dealerId, distinct: true)
      }
    }
    totalShopHaveCodeFE: ip_campaigns_aggregate(where:{createdAt: $filter, ip_campaign_companies:
    {companyStatus: {_eq: "have_code"},financial_company:{alias: {_eq: "FE"}}}}){
      aggregate{
        count(columns: shopId, distinct: true)
      }
    }
    totalDealerHaveCodeFE: ip_campaigns_aggregate(where:{createdAt: $filter, ip_campaign_companies:
    {companyStatus: {_eq: "have_code"},financial_company:{alias: {_eq: "FE"}}}}){
      aggregate{
        count(columns: dealerId, distinct: true)
      }
    }
    totalShopStopJoiningFE: ip_campaigns_aggregate(where:{createdAt: $filter, ip_campaign_companies:
    {companyStatus: {_eq: "stop_joining"},financial_company:{alias: {_eq: "FE"}}}}){
      aggregate{
        count(columns: shopId, distinct: true)
      }
    }
    totalDealerStopJoiningFE: ip_campaigns_aggregate(where:{createdAt: $filter, ip_campaign_companies:
    {companyStatus: {_eq: "stop_joining"},financial_company:{alias: {_eq: "FE"}}}}){
      aggregate{
        count(columns: dealerId, distinct: true)
      }
    }
    totalShopHaveCodeACS: ip_campaigns_aggregate(where:{createdAt: $filter, ip_campaign_companies:
    {companyStatus: {_eq: "have_code"},financial_company:{alias: {_eq: "ACS"}}}}){
      aggregate{
        count(columns: shopId, distinct: true)
      }
    }
    totalDealerHaveCodeACS: ip_campaigns_aggregate(where:{createdAt: $filter, ip_campaign_companies:
    {companyStatus: {_eq: "have_code"},financial_company:{alias: {_eq: "ACS"}}}}){
      aggregate{
        count(columns: dealerId, distinct: true)
      }
    }
    totalShopStopJoiningACS: ip_campaigns_aggregate(where:{createdAt: $filter, ip_campaign_companies:
    {companyStatus: {_eq: "stop_joining"},financial_company:{alias: {_eq: "ACS"}}}}){
      aggregate{
        count(columns: shopId, distinct: true)
      }
    }
    totalDealerStopJoiningACS: ip_campaigns_aggregate(where:{createdAt: $filter, ip_campaign_companies:
    {companyStatus: {_eq: "stop_joining"},financial_company:{alias: {_eq: "ACS"}}}}){
      aggregate{
        count(columns: dealerId, distinct: true)
      }
    }
   }
   `,
  GET_STATISTIC_BY_BRANCH: gql`
  query GET_STATISTIC_BY_BRANCH($name: uuid!, $filter: timestamptz_comparison_exp){
    totalShopRegisterHD: ip_campaigns_aggregate(where: {createdAt: $filter, dealer: {branch: {id: {_eq: $name}}},
      ip_campaign_companies:{companyStatus: {_eq: "have_code"}, financial_company: {alias: {_eq: "HD"}}}}) {
        aggregate {
          count(columns: shopId)
        }
    }
    totalDealerRegisterHD: ip_campaigns_aggregate(where: {createdAt: $filter, dealer: {branch: {id: {_eq: $name}}},
      ip_campaign_companies:{companyStatus: {_eq: "have_code"}, financial_company: {alias: {_eq: "HD"}}}}) {
        aggregate {
          count(columns: dealerId, distinct: true)
        }
    }
    totalShopRegisterFE: ip_campaigns_aggregate(where: {createdAt: $filter, dealer: {branch: {id: {_eq: $name}}},
      ip_campaign_companies:{companyStatus: {_eq: "have_code"}, financial_company: {alias: {_eq: "FE"}}}}) {
        aggregate {
          count(columns: shopId)
        }
    }
    totalDealerRegisterFE: ip_campaigns_aggregate(where: {createdAt: $filter, dealer: {branch: {id: {_eq: $name}}},
      ip_campaign_companies:{companyStatus: {_eq: "have_code"}, financial_company: {alias: {_eq: "FE"}}}}) {
        aggregate {
          count(columns: dealerId, distinct: true)
        }
    }
    totalShopRegisterACS: ip_campaigns_aggregate(where: {createdAt: $filter, dealer: {branch: {id: {_eq: $name}}},
      ip_campaign_companies:{companyStatus: {_eq: "have_code"}, financial_company: {alias: {_eq: "ACS"}}}}) {
        aggregate {
          count(columns: shopId)
        }
    }
    totalDealerRegisterACS: ip_campaigns_aggregate(where: {createdAt: $filter, dealer: {branch: {id: {_eq: $name}}},
      ip_campaign_companies:{companyStatus: {_eq: "have_code"}, financial_company: {alias: {_eq: "ACS"}}}}) {
        aggregate {
          count(columns: dealerId, distinct: true)
        }
    }
  }
  `,
  GET_STATISTIC_FOR_DEPARTMENT_PIC: gql`
  query GET_STATISTIC_FOR_DEPARTMENT_PIC{
    totalDealerRegisterHD: ip_campaigns_aggregate(where:
      {ip_campaign_companies:
        {_and: [
          {financial_company:
          {alias:
            {_eq: "HD"}
          }
          },
          {companyStatus: {_eq: "have_code"}}
        ]}
      }) {
      aggregate {
        count(columns: dealerId, distinct: true)
      }
    }
    totalDealerRegisterFE: ip_campaigns_aggregate(where:
      {ip_campaign_companies:
        {_and: [
          {financial_company:
          {alias:
            {_eq: "FE"}
          }
          },
          {companyStatus: {_eq: "have_code"}}
        ]}
      }) {
      aggregate {
        count(columns: dealerId, distinct: true)
      }
    }
    totalDealerRegisterACS: ip_campaigns_aggregate(where:
      {ip_campaign_companies:
        {_and: [
          {financial_company:
          {alias:
            {_eq: "ACS"}
          }
          },
          {companyStatus: {_eq: "have_code"}}
        ]}
      }) {
      aggregate {
        count(columns: dealerId, distinct: true)
      }
    }
    totalShopRegisterHD: ip_campaigns_aggregate(where:
      {ip_campaign_companies:
        {_and: [
          {financial_company:
          {alias:
            {_eq: "HD"}
          }
          },
          {companyStatus: {_eq: "have_code"}}
        ]}
      }) {
      aggregate {
        count(columns: shopId)
      }
    }
    totalShopRegisterFE: ip_campaigns_aggregate(where:
      {ip_campaign_companies:
        {_and: [
          {financial_company:
          {alias:
            {_eq: "FE"}
          }
          },
          {companyStatus: {_eq: "have_code"}}
        ]}
      }) {
      aggregate {
        count(columns: shopId)
      }
    }
    totalShopRegisterACS: ip_campaigns_aggregate(where:
      {ip_campaign_companies:
        {_and: [
          {financial_company:
          {alias:
            {_eq: "ACS"}
          }
          },
          {companyStatus: {_eq: "have_code"}}
        ]}
      }) {
      aggregate {
        count(columns: shopId)
      }
    }
  }
  `,
  IMPORT_POS_CODE_SUBSCRIBE: gql`
  subscription IMPORT_POS_CODE_SUBSCRIBE($id: uuid!) {
    imports(where: {id: {_eq: $id}}){
      id
      importStatus
    }
  }
  `,
  UPDATE_OWNER_INFO: gql`
  mutation UPDATE_OWNER_INFO($dealerId: uuid!, $set: ip_campaigns_set_input!) {
    update_ip_campaigns(where: {dealerId: {_eq: $dealerId}}, _set: $set) {
      affected_rows
    }
  }`,
  UPDATE_MANAGER_INFO: gql`
  mutation UPDATE_MANAGER_INFO($shopId: uuid!, $set: shops_set_input!) {
    update_shops(where: {id: {_eq: $shopId}}, _set: $set) {
      affected_rows
    }
  }`,
  UPDATE_POS_CODE: gql`
  mutation UPDATE_POS_CODE($id: uuid!, $posCode: String!) {
    update_ip_campaign_companies(where: {id: {_eq: $id}}, _set: { posCode: $posCode}) {
      affected_rows
    }
  }`,
  APPROVE_CAMPAIGN: gql`
    mutation APPROVE_CAMPAIGN ($id: uuid!, $set: ip_campaign_companies_set_input!){
    update_ip_campaign_companies(where: {
      id: {_eq: $id}
    }, _set: $set){
      affected_rows
    }
  }
  `,
  GET_STATISTIC_PARTICIPANT: gql`
  query GET_STATISTIC_PARTICIPANT($where: ip_campaign_companies_bool_exp!) {
    financial_companies {
      alias
      campaignCompanies_aggregate(where: $where){aggregate{count}}
    }
  }`,
  GET_STATISTIC_PARTICIPANT_SALE_ADMIN: gql`
  query GET_STATISTIC_PARTICIPANT_SALE_ADMIN {
    financial_companies {
      alias
      campaignCompanies_aggregate(where: {
        companyStatus: {_eq: "approved"},
        lastReviewer: {
          role: {
            alias: {
              _eq: "group_leader"
            }
          }
        }
      }){aggregate{count}}
    }
  }`,
  APPROVE_ALL: gql`
  mutation APPROVE_ALL($ids: [uuid!]!, $set: ip_campaign_companies_set_input){
    update_ip_campaign_companies (where:{id : {_in: $ids}}, _set: $set)
    {affected_rows}
  }`,
  GET_STATISTIC_LIST: gql`
  query topDealer ($form: FilterTopMostCustomers!){
  topDealerMostCustomers(form: $form) {
    name
    totalCustomers
    percentage
    top
    code
    branchName
  }
  }
  `,
  GET_STATISTIC_LIST_SHOP: gql`
  query topShop ($form: FilterTopMostCustomers!){
    topShopMostCustomers(form: $form) {
    name
    totalCustomers
    percentage
    top
    code
    branchName
  }
  }
  `,
  EXPORT_CAMPAIGN_OVERVIEW: gql`
  mutation EXPORT_CAMPAIGN_OVERVIEW($form: [exports_insert_input!]!){
    insert_exports(objects: $form) {
      affected_rows
      returning {
        id
      }
  }}`,
  GET_CONFIGURATION: gql`
  query GET_CONFIGURATION{
    ip_campaign_configs {
      key
      status
    }
  }`,
  UPDATE_CONFIGURATION: gql`
  mutation UPDATE_CONFIGURATION($key: String!, $status: String!, $reason: String!, $updatedBy: uuid!) {
    update_ip_campaign_configs(where: {key: {_eq: $key}},
    _set: {status: $status, reason: $reason, updatedBy: $updatedBy}){
      affected_rows
    }
  }`,
  GET_CONFIG_HISTORY_LOGS: gql`
  query GET_CONFIG_HISTORY_LOGS($limit: Int!, $offset: Int!){
    ip_campaign_config_logs(limit: $limit, offset: $offset, order_by: {createdAt: desc}) {
      id
      createdAt
      action
      reason
      creator {
        name
        role {
          name
        }
      }
    }
    ip_campaign_config_logs_aggregate {
      aggregate {
        count
      }
    }
  }`
};
