import { cloneDeep, find } from 'lodash';
import { IRedemptionState } from './mutation';

export const getters = {
  getRedemptionDetail(state: IRedemptionState) {
    return (id: string) => {
      return find(cloneDeep(state.data), (e) => e.id === id);
    };
  },
};
