export enum ActionTypeNewRaCampaign {
  FilterNoCache = 'new_ra_campaignFilterNoCache',
  Delete = 'new_ra_campaignDelete',
  Update = 'new_ra_campaignUpdate',
  Create = 'new_ra_campaignCreate',
  FetchMany = 'new_ra_campaignFetchMany',
  FindById = 'new_ra_campaignFindById',
  GetListSubDealerAssign = 'GetListSubDealerAssign',
  AddParticipant = 'AddParticipant',
  ExportListRegistration = 'ExportListRegistration',
  GetNewRaCampaign = 'GetNewRaCampaign',
  GetConfigCropPicture = 'GetConfigCropPictureRa',
  GetCampaignConfiguration = 'getCampaignConfigurationNewRa',
  GetNewRaCampaignDetail = 'getNewRaCampaignDetail',
  ApproveShop = 'approveShopNewRa',
  GetJoinedShop = 'NewRaCampaignJoinedShop',
  GetSubDealerCampaign = 'GetSubDealerNewRACampaign',
  SubmitReview = 'NewRaSubmitReview',
  RequestReup = 'NewRaRequestReup',
  GetSubDealerCampaignDetail = 'GetSubDealerNewRaCampaignDetail',
  PushReminder = 'NewRaPushReminder',
  UpdatePackage = 'NewRaUpdatePackage',
  GetShopDetail = 'GetShopNewRaCampaignDetail',
  GetQualifiedRequest = 'getQualifiedRequest',
  EndCampaign = 'newRaCampaignEnd',
  GetMaskConfig= 'getMaskConfigNewRaCampaign',
  MaskChange = 'maskChangeNewRaCampaign',
  GetDealerByBranch = 'getDealerByBranchNewRaCampaign',
  GetStatistic = 'getStatisticNewRaCampaign',
  ExportStatistic = 'exportStatisticNewRaCampaign',
  GetManageExport = 'getManageExportNewRa',
  ExportPaymentDocument = 'ExportPaymentDocument'
}

export enum MutationTypeNewRaCampaign {
  FilterChange = 'new_ra_campaignFilterChange',
  Loading = 'new_ra_campaignLoading',
  Loaded = 'new_ra_campaignLoaded',
  PaginationChange = 'new_ra_campaignPaginationChange',
  UpdateModels = 'new_ra_campaignUpdateModels',
  RefreshModels ='new_ra_campaignRefreshModels',
  ChangeOrderBy = 'new_ra_campaignChangeOrderBy',
  SetNewRaCampaign = 'SetNewRaCampaign',
  NewRaCampaignLoading = 'NewRaCampaignLoading',
  NewRaCampaignLoaded = 'NewRaCampaignLoaded',
  SetCampaignDetail = 'SetCampaignDetail',
  SetJoinedShopList = 'NewRASetJoinedShopList',
  SetSubDealerCampaign = 'SetSubDealerNewRACampaign',
  SetQualifiedRequest= 'SetQualifiedRequest',
  SetManageExport = 'setDataNewRaManageExport',
}
export enum ActionTypeCampaignRequestList {
  FilterNoCache = 'acl_requestsFilterNoCache',
  Delete = 'acl_requestsDelete',
  Update = 'acl_requestsUpdate',
  Create = 'acl_requestsCreate',
  FetchMany = 'acl_requestsFetchMany',
  FindById = 'acl_requestsFindById',
  GetHistoryLog = 'GetHistoryLog'
}
export enum MutationTypeCampaignRequestList {
  FilterChange = 'acl_requestsFilterChange',
  Loading = 'acl_requestsLoading',
  Loaded = 'acl_requestsLoaded',
  PaginationChange = 'acl_requestsPaginationChange',
  UpdateModels = 'acl_requestsUpdateModels',
  RefreshModels ='acl_requestsRefreshModels',
  ChangeOrderBy = 'acl_requestsChangeOrderBy',
}
export enum ActionTypeClassifiedRequest {
  FilterNoCache = 'acl_request_reviewsFilterNoCache',
  Delete = 'acl_request_reviewsDelete',
  Update = 'acl_request_reviewsUpdate',
  Create = 'acl_request_reviewsCreate',
  FetchMany = 'acl_request_reviewsFetchMany',
  FindById = 'acl_request_reviewsFindById',
  GetSalesStaffSameGroup = 'GetSalesStaffSameGroup',
}
export enum MutationTypeClassifiedRequest {
  FilterChange = 'acl_request_reviewsFilterChange',
  Loading = 'acl_request_reviewsLoading',
  Loaded = 'acl_request_reviewsLoaded',
  PaginationChange = 'acl_request_reviewsPaginationChange',
  UpdateModels = 'acl_request_reviewsUpdateModels',
  RefreshModels ='acl_request_reviewsRefreshModels',
  ChangeOrderBy = 'acl_request_reviewsChangeOrderBy',
}
