import { cloneDeep } from 'lodash';
import moment from 'moment';
import { IPaginationParams } from 'root/api/graphql/Core';
import { parseUTCToTime, FormatDateFullTime, FormatDateTime } from 'root/helpers';
import { IAddress, IBreadcrumb, IServiceCategory, IWorkMaster, StatusCode } from 'root/models';
import { ICampaign } from 'root/models/Redemption';
import { IState, MutationType } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import { RedemptionRouterName, RedemptionRouterPath } from '..';
import { RedemptionDetailModal } from '../Components/RedemptionDetail';
import { RedemptionEditor } from '../Components/RedemptionEditor';
import { ActionTypeRedemption, MutationTypeRedemption } from '../Store/types';
import './styles.scss';

@Component({
  template: require('./view.html'),
  components: {
    editor: RedemptionEditor,
    'redemption-detail-modal': RedemptionDetailModal
  },
  computed: {
    ...mapState({
      redemption: (state: IState) => state.redemption.data,
      isRefesh: (state: IState) => state.global.isRefesh,
      dataDetail: (state: IState) => state.redemption.detail.data,
      loadingDetail: (state: IState) => state.redemption.detail.loading,
      paginationDetail: (state: IState) => state.redemption.detail.pagination,
    }),
    detail() {

      return this.$store.getters.getRedemptionDetail(this.serviceId);
    }
  }
})
export class RedeemDetail extends Vue {
  public get columns() {
    return [{
      prop: 'dealerCode',
      label: 'DEALER CODE',
      formatter: (model: any) => {
        if (model.dealerCode) {
          return model.dealerCode || '--';
        }

        return '--';
      },
      width: 180
    },
    {
      prop: 'dealerName',
      label: 'DEALER NAME',
      width: 250,
      formatter: (model: any) => {
        if (model.dealerName) {
          return model.dealerName;
        }

        return '--';
      }
    },
    {
      prop: 'branchName',
      label: 'BRANCH',
      width: 180,
      formatter: (model: any) => {
        if (model.branchName) {
          return model.branchName;
        }

        return '--';
      }
    },
    {
      prop: 'groupName',
      label: 'Group',
      width: 180,
      formatter: (model: any) => {
        if (model.groupName) {
          return model.groupName;
        }

        return '--';
      }
    },
    {
      prop: 'frequency',
      label: 'FREQUENCY',
      width: 180,
      formatter: (model: any) => {
        if (model.frequency) {
          return model.frequency;
        }

        return '--';
      }
    },
    {
      prop: 'totalPoint',
      label: 'POINT USED',
      width: 180,
      formatter: (model: any) => {
        if (model.totalPoint) {
          return `${model.totalPoint}`;
        }

        return '--';
      }
    },
    ];

  }
  public get serviceId(): string {
    return this.$route.params.id;
  }
  public get activeTab() {
    return this.$route.name;
  }
  public set activeTab(_value: string) {
    //
  }
  public FormatDateFullTime = FormatDateFullTime;
  public formatDate = parseUTCToTime;
  public formatDateTime = FormatDateTime;
  public visibleEditor: boolean = false;
  public visible: boolean = false;
  public redemption: ICampaign[];
  public repairs: IWorkMaster[];
  public dataDetail: any[];
  public category: IServiceCategory;
  public fileUpload: File[] = [];
  public isRefesh: boolean;
  public searchText: string = '';
  public canEdit: boolean = false;
  public detail: any;

  public isEng: boolean = false;
  public paginationDetail: IPaginationParams;
  public subPagination= <any> {
    page: 1,
    limit: 10
  };
  public get inputSearchPlaceholder(): string {

    return `Search by name, code`;
  }
  public back() {
    this.$router.push(RedemptionRouterName.Compaign);
  }
  public renderDuration(startDate: any, endDate: any) {

    return `${this.FormatDateFullTime(startDate)} - ${this.FormatDateFullTime(endDate)}`;
  }
  public get CanEdit() {
    if (this.detail && this.detail.rewardStatus === StatusCode.Available) {

      return this.canEdit = true;
    }
    if (this.detail && this.detail.rewardStatus === StatusCode.Blocked) {
      const now = moment();
      if (moment(this.detail.endDate) > now) {

        return this.canEdit = true;
      }

      return this.canEdit = false;
    }
    if (this.detail && this.detail.rewardStatus === StatusCode.Schedule) {

      return this.canEdit = true;
    }

    return this.canEdit = false;
  }
  public closeDialog() {
    this.visibleEditor = false;
  }
  public closeDialogDetail() {
    this.visible = false;
  }
  public goEdit() {
    this.visibleEditor = true;
  }
  public goDetail(value: boolean) {
    this.visible = true;
    this.isEng = value;
  }
  public displayAddress(model: IAddress) {
    return model && model.province ? this.$store.getters['getAddressString'](model) : '';
  }
  public createSuccess() {
    this.visible = false;
    this.fetchData();
  }

  public handleSearch() {
    this.fetchData();
  }
  public handlePaginationChange(value) {
    if (typeof value === 'number') {
      this.subPagination = {
        ...this.paginationDetail,
        limit: value
      };
    } else {
      this.subPagination = {
        ...this.paginationDetail,
        ...value
      };
    }
    this.fetchData();
  }
  protected mounted() {
    const breadcrumb: IBreadcrumb[] = [
      {
        label: 'title.redeem',
        path: RedemptionRouterPath.Redemption,
        current: false
      },
      {
        label: 'title.view_details',
        path: null,
        current: true
      }
    ];
    this.$store.commit(MutationType.SetBack);
    this.$store.commit(MutationType.SetBreadcrumb, breadcrumb);
    this.fetchData();
  }
  protected beforeDestroy() {
    this.$store.commit(MutationType.ClearBreadcrumb);
  }
  private fetchData() {
    this.$store.commit(MutationTypeRedemption.ChangeOrderBy, {
      createdAt: 'desc'
    });
    this.$store.dispatch(ActionTypeRedemption.FilterNoCache);
    const text = cloneDeep(this.searchText);
    this.$store.dispatch(ActionTypeRedemption.GetRedeemDetail, {
      form: {
        filter: {
          rewardId: this.serviceId,
          search: text
        },
        pagination: {
          size: this.subPagination.limit ? this.subPagination.limit : this.paginationDetail.limit,
          page: this.subPagination.page ? this.subPagination.page : this.paginationDetail.page
        }
      },
    });
    if (this.redemption.length) {
      return this.$store.getters.getRedemptionDetail(this.serviceId);
    }

  }
}
