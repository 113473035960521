import { IPaginationParams } from 'root/api/graphql/Core';
import { crudMutations, CRUDState, ICRUDState } from 'root/store/helpers';
import { MutationTypeRaCampaign } from './types';
// import { MutationTypeEvaluatePhoto } from './types';

export interface IRaCampaignState extends ICRUDState<any> {
  loading: boolean;
  manageExport: any;
  listQualifiedShop: any;
  listJoinedShop: any;
  subDealerRequest: any;
  listConfirmCode: any;
  listSubDealerCampaign: any;
}

export const defaultState: IRaCampaignState = {
  ...CRUDState(),
  loading: false,
  manageExport: [],
  listQualifiedShop: [],
  listJoinedShop : [],
  subDealerRequest: [],
  listConfirmCode: [],
  listSubDealerCampaign: []
};

export const mutations = {
  ...crudMutations('ra_campaigns'),
  [MutationTypeRaCampaign.SetAclCompaign](state: IRaCampaignState, data) {
    state.data = data.dealers;
    state.pagination.total = data.dealers_aggregate.aggregate.count;
  },
  [MutationTypeRaCampaign.SetAclManageExport](state: IRaCampaignState, data) {
    state.manageExport = data.export_acl_campaign_reports;
    state.pagination.total = data.export_acl_campaign_reports_aggregate.aggregate.count;
  },
  [MutationTypeRaCampaign.SetAclCompaignDetail](state: IRaCampaignState, data) {
    state.data = data.dealers;
    state.pagination.total = data.dealers[0].shops_aggregate.aggregate.count;
  },
  [MutationTypeRaCampaign.SetPagination](state: IRaCampaignState, pagination: IPaginationParams) {
    state.pagination.limit = pagination.limit;
    state.pagination.page = pagination.page;
  },
  [MutationTypeRaCampaign.SetQualifiedList](state: IRaCampaignState, data) {
    state.listQualifiedShop = data.acl_campaigns;
    state.pagination.total = data.acl_campaigns_aggregate.aggregate.count;
  },
  [MutationTypeRaCampaign.SetJoinedShopList](state: IRaCampaignState, data) {
    state.listJoinedShop = data.shops;
    state.pagination.total = data.shops_aggregate.aggregate.count;
  },
  [MutationTypeRaCampaign.SetListRequest](state: IRaCampaignState, data) {
    state.subDealerRequest = data.campaign_dealers;
    state.pagination.total = data.campaign_dealers_aggregate.aggregate.count;
  },
  [MutationTypeRaCampaign.SetListRequest](state: IRaCampaignState, data) {
    state.subDealerRequest = data.campaign_dealers;
    state.pagination.total = data.campaign_dealers_aggregate.aggregate.count;
  },
  [MutationTypeRaCampaign.SetListConfirmCode](state: IRaCampaignState, data) {
    state.listConfirmCode = data.confirmation_codes;
    state.pagination.total = data.confirmation_codes_aggregate.aggregate.count;
  },
  [MutationTypeRaCampaign.SetSubDealerCampaign](state: IRaCampaignState, data) {
    state.listSubDealerCampaign = data.dealers;
    state.pagination.total = data.dealers_aggregate.aggregate.count;
  },
};
