import { CRUDGqlService, ICRUDGqlService } from '../Core/crud';
import { getTeamStaffQuery } from '../Query/teamStaff';

export interface ITeamStaffService extends ICRUDGqlService<any> {}

export function teamStaffGqlService(): ITeamStaffService {
  return {
    ...CRUDGqlService<any>('group_manager', getTeamStaffQuery)
  };
}
