import { PermissionType } from 'root/models';
import { RouteConfig } from 'vue-router';
import { CampaignConfiguration } from './ACLCampaign/Components/CampaignConfiguration';
import { AclCampaignDetail } from './ACLCampaign/Components/CampaignDetail';
import { CampaignParticipant } from './ACLCampaign/Components/CampaignParticipant';
import { CampaignStatistic } from './ACLCampaign/Components/CampaignStatistic';
import { JoinedShops } from './ACLCampaign/Components/JoinedShops';
import { ManageExport } from './ACLCampaign/Components/ManageExport';
import { QualifiedShops } from './ACLCampaign/Components/QualifiedShops';
import { CustomCampaignContainer } from './CustomCampaignContainer';
import { RaCampaignConfiguration } from './RACampaign/Components/CampaignConfiguration';
import { RaCampaignDetail } from './RACampaign/Components/CampaignDetail';
import { RaCampaignStatistic } from './RACampaign/Components/CampaignStatistic';
import { ConfirmationCode } from './RACampaign/Components/ConfirmationCode';
import { RaJoinedShops } from './RACampaign/Components/JoinedShops';
import { RaManageExport } from './RACampaign/Components/ManageExport';
import { RaQualifiedShops } from './RACampaign/Components/QualifiedShops';
import { RAParticipant } from './RACampaign/Components/RAParticipant';
import { SubDealerCampaign } from './RACampaign/Components/SubDealerCampaign';
import { SubDealerRequest } from './RACampaign/Components/SubDealerRequest';

export enum CustomCampaignRouterPath {
  CustomCampaign = '/evaluate-display-photo/list',
  ACLCampaignList = '/acl-campagin/list',
  Participant = '/acl-campagin/participant',
  Statistic = '/acl-campagin/statistic',
  Configuration = '/acl-campagin/configuration',
  CampaignDetail = '/acl-campaign/detail/:id',
  ManageExport = '/acl-campaign/manage-export',
  QualifiedShops = '/acl-campaign/qualified-shops',
  Shops = '/acl-campaign/joined-shops',
  RACampaignList = '/ra-campagin/list',
  RACampaignDetail = '/ra-campaign/detail/:id',
  RAStatistic = '/ra-campagin/statistic',
  RAShops = '/ra-campaign/campaign-request',
  SubDealerCampaign = '/ra-campaign/sub-dealer-campaign',
  RAQualifiedShops = '/ra-campaign/qualified-request',
  RAManageExport = '/ra-campaign/manage-export',
  RAConfiguration = '/ra-campagin/configuration',
  SubDealerRequest = '/ra-campagin/sub-dealer-request',
  ConfirmationCode = 'ra-campaign/confirmation-code'
}
export enum CustomCampaignRouterName {
  CustomCampaign = 'List Evaluate Display Photo',
  ACLCampaign = 'ACL Campaign',
  Participant = 'Participant',
  Statistic = 'Statistic',
  Configuration = 'Configuration',
  CampaignDetail = 'AclCampaginDetail',
  ManageExport = 'ManageExport',
  QualifiedShops = 'QualifiedShops',
  Shops = 'Shops',
  RACampaign = 'RA Campaign',
  RACampaignDetail = 'RACampaignDetail',
  RAStatistic = 'RAStatistic',
  RAConfiguration = 'RAConfiguration',
  RAManageExport = 'RAManageExport',
  RAQualifiedShops = 'RAQualifiedShops',
  RAShops = 'RAShops',
  SubDealerCampaign = 'SubDealerCampaign',
  SubDealerRequest = 'SubDealerRequest',
  ConfirmationCode = 'ConfirmationCode'
}

export enum CustomCampaignTab {
  Participant = 'Participant',
  Statistic = 'Statistic',
  Configuration = 'Configuration',
  ManageExport= 'ManageExport',
  QualifiedShops = 'QualifiedShops',
  Shops = 'Shops',
  RAParticipant = 'RAParticipant',
  RAStatistic = 'RAStatistic',
  RAConfiguration = 'RAConfiguration',
  RAManageExport= 'RAManageExport',
  RAQualifiedShops = 'RAQualifiedShops',
  RAShops = 'RAShops',
  SubDealerCampaign = 'SubDealerCampaign',
  SubDealerRequest = 'SubDealerRequest',
  ConfirmationCode ='ConfirmationCode'
}

export const CustomCampaignRouter: RouteConfig = {
  path: '/acl-campaign',
  component: CustomCampaignContainer,
  redirect: CustomCampaignRouterPath.ACLCampaignList,
  meta: {
    permission: PermissionType.CustomCampaign
  },
  children: [
    {
      path: CustomCampaignRouterPath.ACLCampaignList,
      component: CampaignParticipant,
      name: CustomCampaignRouterName.Participant,
      meta: {
        permission: PermissionType.CustomCampaign
      },
    },
    {
      path: CustomCampaignRouterPath.Statistic,
      component: CampaignStatistic,
      name: CustomCampaignRouterName.Statistic,
      meta: {
        permission: PermissionType.CustomCampaign
      },
    },
    {
      path: CustomCampaignRouterPath.Configuration,
      component: CampaignConfiguration,
      name: CustomCampaignRouterName.Configuration,
      meta: {
        permission: PermissionType.CustomCampaign
      },
    },
    {
      path: CustomCampaignRouterPath.QualifiedShops,
      component: QualifiedShops,
      name: CustomCampaignRouterName.QualifiedShops,
      meta: {
        permission: PermissionType.CustomCampaign
      },
    },
    {
      path: CustomCampaignRouterPath.Shops,
      component: JoinedShops,
      name: CustomCampaignRouterName.Shops,
      meta: {
        permission: PermissionType.CustomCampaign
      },
    },
    {
      path: CustomCampaignRouterPath.ManageExport,
      component: ManageExport,
      name: CustomCampaignRouterName.ManageExport,
      meta: {
        permission: PermissionType.CustomCampaign
      },
    },
    {
      path: CustomCampaignRouterPath.CampaignDetail,
      component: AclCampaignDetail,
      name: CustomCampaignRouterName.CampaignDetail,
      meta: {
        permission: PermissionType.CustomCampaign
      },
    },
  ]
};
export const RaCampaignRouter: RouteConfig = {
  path: '/ra-campaign',
  component: CustomCampaignContainer,
  redirect: CustomCampaignRouterPath.RACampaignList,
  meta: {
    permission: PermissionType.raCampaign
  },
  children: [
    {
      path: CustomCampaignRouterPath.RACampaignList,
      component: RAParticipant,
      name: CustomCampaignRouterName.RACampaign,
      meta: {
        permission: PermissionType.raCampaign
      },
    },
    {
      path: CustomCampaignRouterPath.RACampaignDetail,
      component: RaCampaignDetail,
      name: CustomCampaignRouterName.RACampaignDetail,
      meta: {
        permission: PermissionType.raCampaign
      },
    },
    {
      path: CustomCampaignRouterPath.RAStatistic,
      component: RaCampaignStatistic,
      name: CustomCampaignRouterName.RAStatistic,
      meta: {
        permission: PermissionType.raCampaign
      },
    },
    {
      path: CustomCampaignRouterPath.RAShops,
      component: RaJoinedShops,
      name: CustomCampaignRouterName.RAShops,
      meta: {
        permission: PermissionType.raCampaign
      },
    },
    {
      path: CustomCampaignRouterPath.RAQualifiedShops,
      component: RaQualifiedShops,
      name: CustomCampaignRouterName.RAQualifiedShops,
      meta: {
        permission: PermissionType.raCampaign
      },
    },
    {
      path: CustomCampaignRouterPath.RAManageExport,
      component: RaManageExport,
      name: CustomCampaignRouterName.RAManageExport,
      meta: {
        permission: PermissionType.raCampaign
      },
    },
    {
      path: CustomCampaignRouterPath.RAConfiguration,
      component: RaCampaignConfiguration,
      name: CustomCampaignRouterName.RAConfiguration,
      meta: {
        permission: PermissionType.raCampaign
      },
    },
    {
      path: CustomCampaignRouterPath.SubDealerRequest,
      component: SubDealerRequest,
      name: CustomCampaignRouterName.SubDealerRequest,
      meta: {
        permission: PermissionType.raCampaign
      },
    },
    {
      path: CustomCampaignRouterPath.ConfirmationCode,
      component: ConfirmationCode,
      name: CustomCampaignRouterName.ConfirmationCode,
      meta: {
        permission: PermissionType.raCampaign
      },
    },
    {
      path: CustomCampaignRouterPath.SubDealerCampaign,
      component: SubDealerCampaign,
      name: CustomCampaignRouterName.SubDealerCampaign,
      meta: {
        permission: PermissionType.raCampaign
      },
    },
  ]
};
