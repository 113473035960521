import { crudMutations, CRUDState, ICRUDState } from 'root/store/helpers';

export interface IPointsState extends ICRUDState<any> {
  loading: boolean;
}

export const defaultState: IPointsState = {
  ...CRUDState(),
  loading: false,
};

export const mutations = {
  ...crudMutations('points')
};
