import { crudMutations, CRUDState, ICRUDState } from 'root/store/helpers';

export interface IProductTransState extends ICRUDState<any> {
  loading: boolean;
}

export const defaultState: IProductTransState = {
  ...CRUDState(),
  loading: false,
};

export const mutations = {
  ...crudMutations('productTrans')
};
