import { gqlClient } from '../Core';
import { CRUDGqlService, ICRUDGqlService } from '../Core/crud';
import { productTransListQuery, ProductTrans } from '../Query/productTrans';

export interface IProductTransGqlService extends ICRUDGqlService<any> {
  //
  getListProductTrans(): Promise<any>;
  getByIdProduct(id: string): Promise<any>;
  getGroupView(where: any): Promise<any>;
  getViewById(id: string): Promise<any>;
}
export function productTransGqlService(): IProductTransGqlService {
  async function getListProductTrans(): Promise<any> {
    const results = await gqlClient.query({
      query: productTransListQuery.GET_PRODUCT_TRANS_LIST,
      fetchPolicy: 'network-only'
    });
    if (results.errors) {
      throw results.errors;
    }

    return results.data.product_transactions;
  }
  async function getByIdProduct(id: string): Promise<any> {
    const result = await gqlClient.query({
      query: productTransListQuery.GET_BY_ID_PRODUCT,
      variables: {
        id,
      },
      fetchPolicy: 'network-only'
    });
    if (result.errors) {
      throw result.errors;
    }

    return result.data.product_transactions;
  }
  async function getGroupView(where: any): Promise<any> {
    const results = await gqlClient.query({
      query: productTransListQuery.GET_GROUP_VIEW,
      variables: {
        where
      }
    });
    if (results.errors) {
      throw results.errors;
    }

    return results.data.product_transaction_leader_view;
  }
  async function getViewById(id: string): Promise<any> {
    const result = await gqlClient.query({
      query: productTransListQuery.GET_VIEW_BY_ID,
      variables: {
        id,
      },
      fetchPolicy: 'network-only'
    });
    if (result.errors) {
      throw result.errors;
    }

    return result.data.product_history_leader_view;
  }

  return {
    ...CRUDGqlService<any>(ProductTrans
    , productTransListQuery),
    getListProductTrans,
    getByIdProduct,
    getGroupView,
    getViewById
  };
}
