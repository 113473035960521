import { isFunction } from 'lodash';
import { DKGqlClient } from 'root/api/graphql/Client';
import { ActionType, IState, MutationType } from 'root/store';
import { crudActions } from 'root/store/helpers';
import { ActionContext } from 'vuex';
import { IDealerState } from './mutation';
import { ActionTypeDealer, MutationTypeDealer } from './types';

const { dealer } = DKGqlClient();
export const actions = {
  ...crudActions('dealer', {
    filter: dealer.filter,
    fetchMany: dealer.fetchMany,
  }),
  async [ActionTypeDealer.CreateDealer]({
    commit, dispatch
  }: ActionContext<IDealerState, IState>,
                                        {form, onSuccess, onFailure}) {
    try {
      const createUserResult = await dealer.createDealer(form);
      onSuccess();
      if (createUserResult) {
        commit(ActionTypeDealer.FilterNoCache);
      }
    } catch (error) {
      onFailure();
      dispatch(ActionType.CatchException, error);
    }
  },
  async [ActionTypeDealer.GetListDealer](
  {commit, dispatch}: ActionContext<IDealerState, IState>, {
    form, onSuccess
  }) {
    try {
      commit(MutationTypeDealer.Loading);
      const dealerList = await dealer.getListDealer(form);
      commit(MutationType.GetListDealer, dealerList);
      if (isFunction(onSuccess)) {
        onSuccess();
      }
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    } finally {
      commit(MutationTypeDealer.Loaded);
    }
  },
  async [ActionTypeDealer.GetTargetDetail](
  {commit, dispatch}: ActionContext<IDealerState, IState>, {
    role, status, onSuccess
  }) {
    try {
      commit(MutationTypeDealer.Loading);
      const dealerList = await dealer.getTargetDetail(role, status);
      commit(MutationType.GetTargetDetail, dealerList);
      if (isFunction(onSuccess)) {
        onSuccess();
      }
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    } finally {
      commit(MutationTypeDealer.Loaded);
    }
  },
  async [ActionTypeDealer.GetById]({
    commit, dispatch
  }: ActionContext<IDealerState, IState>,
                                   {id, onSuccess}) {
    try {
      commit(MutationTypeDealer.Loading);
      const dealerDetail = await dealer.dealerGetById(id);
      if (onSuccess) {
        onSuccess(dealerDetail);
      }
      commit(MutationType.GetListDealer, {data: [dealerDetail]});
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    } finally {
      commit(MutationTypeDealer.Loaded);
    }
  },
  async [ActionTypeDealer.UpdateStatusDealer]({
    commit, dispatch
  }: ActionContext<IDealerState, IState>,
                                              {id, status, onSuccess, onFailure}) {
    try {
      const updateResult = await dealer.updateStatusDealer(id, status);
      onSuccess();
      if (updateResult) {
        commit(ActionTypeDealer.FilterNoCache);
      }
    } catch (error) {
      onFailure(error);
      dispatch(ActionType.CatchException, error);
    }
  },
  async [ActionTypeDealer.ChangePassDealer]({
    commit, dispatch
  }: ActionContext<IDealerState, IState>,
                                            {id, pass, onSuccess, onFailure}) {
    try {
      const updateResult = await dealer.changePassDealer(id, pass);
      onSuccess();
      if (updateResult) {
        commit(ActionTypeDealer.GetListDealer);
      }
    } catch (error) {
      onFailure(error);
      dispatch(ActionType.CatchException, error);
    }
  },
  async [ActionTypeDealer.UpdateDealer]({
    commit, dispatch
  }: ActionContext<IDealerState, IState>,
                                        {id, set, onSuccess, onFailure}) {
    try {
      const updateResult = await dealer.updateDealer(id, set);
      onSuccess();
      if (updateResult) {
        commit(ActionTypeDealer.GetListDealer);
      }
    } catch (error) {
      onFailure(error);
      dispatch(ActionType.CatchException, error);
    }
  },
  async [ActionTypeDealer.UpdateSubDealer]({
    commit, dispatch
  }: ActionContext<IDealerState, IState>,
                                           {id, set, businessLicense, houseHoldLicense, onSuccess, onFailure}) {
    try {
      const updateResult = await dealer.updateSubDealer(id, set, businessLicense, houseHoldLicense);
      onSuccess();
      if (updateResult) {
        commit(ActionTypeDealer.GetListDealer);
      }
    } catch (error) {
      onFailure(error);
      dispatch(ActionType.CatchException, error);
    }
  },
  async [ActionTypeDealer.GetUpgradeRequest]({
    commit, dispatch
  }: ActionContext<IDealerState, IState>,
                                             {id}) {
    try {
      const upgradeRequest = await dealer.getUpgradeRequest(id);
      if (upgradeRequest) {
        commit(MutationTypeDealer.GetUpgradeRequestMutation, {data: upgradeRequest});
      }
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    }
  },
  async [ActionTypeDealer.CreateUpgradeRequest]({
    commit, dispatch
  }: ActionContext<IDealerState, IState>,
                                                {form, onSuccess, onFailure}) {
    try {
      const updateResult = await dealer.createUpgradeRequest(form);
      onSuccess();
      if (updateResult) {
        commit(ActionTypeDealer.GetListDealer);
      }
    } catch (error) {
      onFailure(error);
      dispatch(ActionType.CatchException, error);
    }
  },
  async [ActionTypeDealer.ImportFileDealer]({
    dispatch
 }: ActionContext<IDealerState, IState>,
                                            {form, onSuccess, onFailure}) {
    try {
      await dealer.insertFileDealer(form);
      onSuccess();
    } catch (error) {
      onFailure();
      dispatch(ActionType.CatchException, error);
    }
  },
  async [ActionTypeDealer.GetListSaleman](
    {commit, dispatch}: ActionContext<IDealerState, IState>, {
      form, onSuccess
    }) {
    try {
      commit(MutationTypeDealer.Loading);
      const salemanList = await dealer.getListSaleman(form);
      commit(MutationType.GetListDealer, salemanList);
      if (isFunction(onSuccess)) {
        onSuccess();
      }
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    } finally {
      commit(MutationTypeDealer.Loaded);
    }
  },
  async [ActionTypeDealer.GetListSubDealer](
    {commit, dispatch}: ActionContext<IDealerState, IState>, {pagination, searchName, searchCode,
      searchBusiness, searchHouseHold, searchIdentity, searchBranchName, searchBranchCode, searchPhoneNumber,
      branch, region, status}) {
    try {
      commit(MutationTypeDealer.Loading);
      const { page, limit } = pagination;
      const offset = (page - 1) * limit;
      const subDealerList = await dealer.getListSubDealer(limit, offset, searchName, searchCode,
        searchBusiness, searchHouseHold, searchIdentity, searchBranchName, searchBranchCode, searchPhoneNumber,
         branch, region, status);
      commit(MutationTypeDealer.GetListSubDealer, subDealerList);
      // if (isFunction(onSuccess)) {
      //   onSuccess();
      // }
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    } finally {
      commit(MutationTypeDealer.Loaded);
    }
  },
  async [ActionTypeDealer.ImportFileSubDealer]({
    dispatch
 }: ActionContext<IDealerState, IState>,
                                               {form, onSuccess, onFailure}) {
    try {
      await dealer.insertFileSubDealer(form);
      onSuccess();
    } catch (error) {
      onFailure();
      dispatch(ActionType.CatchException, error);
    }
  },
  async [ActionTypeDealer.ExportDealer]({
    dispatch
 }: ActionContext<IDealerState, IState>,
                                        {object, onSuccess}) {
    try {
      const url = await dealer.exportDealer(object);
      if (onSuccess) {
        onSuccess(url);
      }
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    }
  },
  async [ActionTypeDealer.GetRoleId]({
    dispatch
 }: ActionContext<IDealerState, IState>,
                                     {role, onSuccess}) {
    try {
      const id = await dealer.getRoleId(role);
      if (onSuccess) {
        onSuccess(id);
      }
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    }
  },
  async [ActionTypeDealer.GetBankInfo]({
    commit, dispatch
  }: ActionContext<IDealerState, IState>) {
    try {
      const bankInfo = await dealer.getBankInfo();
      commit(MutationTypeDealer.SetBank, bankInfo);
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    }
  },
  async [ActionTypeDealer.GetDealerType]({
    commit, dispatch
  }: ActionContext<IDealerState, IState>) {
    try {
      const types = await dealer.getDealerType();
      commit(MutationTypeDealer.SetDealerType, types);
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    }
  },
};
