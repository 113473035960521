import { gqlClient } from '../Core';
import { CRUDGqlService, ICRUDGqlService } from '../Core/crud';
import { dealerType, dealerTypeListQuery } from '../Query/dealerType';

export interface IDealerTypeGqlService extends ICRUDGqlService<any> {
  insertDealerType(form: any): Promise<any>;
  updateDealerType(id: string, form: any): Promise<any>;
  //
}
export function dealerTypeGqlService(): IDealerTypeGqlService {
  async function insertDealerType(form: any): Promise<any> {
    const results = await gqlClient.mutate({
      mutation: dealerTypeListQuery.INSERT_DEALER_TYPES,
      variables: {
        form
      },
    });
    if (results.errors) {
      throw results.errors;
    }

    return results;
  }
  async function updateDealerType(alias: string, form: any): Promise<any> {
    const results = await gqlClient.mutate({
      mutation: dealerTypeListQuery.UPDATE_DEALER_TYPES,
      variables: {
        alias,
        form
      },
    });
    if (results.errors) {
      throw results.errors;
    }

    return results;
  }

  return {
    ...CRUDGqlService<any>(dealerType, dealerTypeListQuery),
    insertDealerType,
    updateDealerType,
  };
}
