import './styles.scss';

// import moment from 'moment';
import { cloneDeep, find } from 'lodash';
import { ActionTypeProduct } from 'root/admin/Product/Store/types';
import { SystemRoleAlias } from 'root/admin/User/Store/types';
import { IPaginationParams } from 'root/api/graphql/Core';
import { Avatar } from 'root/components/Avatar';
import { fullNameUser } from 'root/helpers';
import { IAddress, IFilterInput, StatusCode } from 'root/models';
import { IUser } from 'root/models/User';
import { IState, MutationType } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import { BranchAndGroupRouterName } from '../..';
import { GroupEditor } from '../Components/GroupManageEditor';
import {
  ActionTypeGroupManage,
  MutationTypeGroupManager
} from '../Store/types';

const enum FilterKey {
  Status = 'status',
  Branch = 'branch'
}

@Component({
  template: require('./view.html'),
  components: {
    avatar: Avatar,
    editor: GroupEditor
  },
  computed: {
    ...mapState({
      authUser: (state: IState) => state.global.authUser,
      data: (state: IState) => state.groupManage.data,
      pagination: (state: IState) => state.groupManage.pagination,
      loading: (state: IState) => state.groupManage.loading,
      // filterParams: (state: IState) => state.groupManage.filterParams,
      deviceType: (state: IState) => state.deviceType.data,
      isRefesh: (state: IState) => state.global.isRefesh,
      branch: (state: IState) => state.branchManage.data
    }),
    selectedStatus() {
      const filterParams = [];

      if (!filterParams) {
        return [];
      }

      return [
        {
          key: 'isCreatedByAppleReviewer',
          value: []
        }
      ];
    }
  },
  watch: {
    type() {
      // this.paramsFilter = {
      //   text: ''
      // };
      // this.$store.commit(MutationTypeUser.ClearUserList);
      this.$store.commit(MutationType.SetBreadcrumb, this.$t(this.type));
      this.fetchData();
    }
  }
})
export class GroupList extends Vue {
  public get serviceId(): string {
    return this.$route.params.id;
  }
  public get actions() {
    if (this.authUser && (this.authUser.role.alias === SystemRoleAlias.TeamLeader ||
      this.authUser.role.alias === SystemRoleAlias.Staff)) {
      return ['view'];
    }

    return ['view', 'edit'];
  }
  public get AccessCreateGroup() {
    if (this.authUser && (this.authUser.role.alias === SystemRoleAlias.SaleAdmin ||
      this.authUser.role.alias === SystemRoleAlias.Admin ||
      this.authUser.role.alias === SystemRoleAlias.BranchManager ||
      this.authUser.role.alias === SystemRoleAlias.BranchManager)) {

      return true;
    }

    return false;
  }

  public get columns() {
    return [
      {
        prop: 'name',
        label: 'Name',
        width: 180,
        formatter: (model: any) => {
          if (model.name) {
            return model.name || '--';
          }

          return '--';
        }
      },
      {
        prop: 'code',
        label: 'Code',
        width: 180,
        formatter: (model: any) => {
          if (model) {
            return model.groupCode || '--';
          }

          return '--';
        }
      },
      {
        prop: 'leader',
        label: 'Leader',
        width: 100,
        formatter: (model: any) => {
          if (model.groupManagers) {
            const _length = model.groupManagers.filter((it) => it.role &&
            it.role.alias === SystemRoleAlias.GroupLeader).length;

            return _length || '--';
          }

          return '--';
        }
      },
      {
        label: 'Team Leader',
        width: 140,
        formatter: (model: any) => {
          if (model.groupManagers && model.groupManagers.length) {
            const _length = model.groupManagers.filter((it) => it.role &&
            it.role.alias === SystemRoleAlias.TeamLeader).length;

            return _length || '--';
          }

          return '--';
        }
      },
      {
        label: 'Staff',
        width: 100,
        formatter: (model: any) => {
          if (model.groupManagers && model.groupManagers.length) {
            const _length = model.groupManagers.filter((it) => it.role &&
            it.role.alias === SystemRoleAlias.Staff).length;

            return _length || '--';
          }

          return '--';
        }
      },
      {
        label: 'Branch Managers',
        width: 160,
        formatter: (model: any) => {
          if (model.branch) {
            const _length = model.branch.branchManagers && model.branch.branchManagers.length;

            return _length || '--';
          }

          return '--';
        }
      },
      {
        prop: 'branch',
        label: 'Branch',
        width: 180,
        formatter: (model: any) => {
          if (model.branch) {
            return model.branch.name || '--';
          }

          return '--';
        }
      },
      // {
      //   prop: 'address',
      //   label: 'Address',
      //   width: 250,
      //   formatter: (model: any) => {
      //     if (model.address) {
      //       return this.displayAddress(model.address) || '--';
      //     }

      //     return '--';
      //   }
      // },
      {
        prop: 'description',
        label: 'Description',
        formatter: (model: any) => {

          return model.description || '--';

        },
        width: 140
      },
      {
        prop: 'status',
        label: this.$t('status'),
        formatter: (model: any) => (model.status ? this.$t(model.status) : ''),
        labelStyle: (model: any) => {
          return model.status;
        },
      }
    ];
  }

  public get inputSearchPlaceholder(): string {
    return `Search by Name, Email`;
  }
  public get dataFilter() {
    const _branch: IFilterInput[] = this.branch.reduce((res, item) => {
      res.push({
        key: item.id,
        value: item.id,
        name: item.name
      });

      return res;
    }, []);

    return [
      {
        key: FilterKey.Branch,
        name: this.$t('field.branch'),
        value: _branch
      },
    ];
  }
  public get selectedFilter() {
    const _filterParams = cloneDeep(this.filterParams);

    return [
      {
        key: FilterKey.Branch,
        value: _filterParams[FilterKey.Branch]
      }
    ];
  }

  public authUser: IUser;
  public pagination: IPaginationParams;
  public data: any[];
  public deviceType: any[];
  public visible: boolean = false;
  public isSample: boolean = false;
  public SystemRoleAlias = SystemRoleAlias;
  public userId: string = '';
  public visibleDetail: boolean = false;
  public popupVisible: boolean = false;
  public filterParams = {
    [FilterKey.Branch]: []
  };
  public searchText: string = '';
  public branch: any[];

  public UserType = {
    Customer: 'customer',
    System: 'system'
  };
  public isRefesh: boolean;
  public type: any;
  public roleAlias = [SystemRoleAlias.TeamLeader, SystemRoleAlias.Staff];
  // tslint:disable-next-line:max-line-length
  private sampleUrl = `https://firebasestorage.googleapis.com/v0/b/daikin-test-staging.appspot.com/o/technicians%2Ftechnicians-import-sample.xlsx?alt=media&token=279661a1-11fe-49a0-8109-364e964ec11b`;
  public displayListManager(model: any, role: string) {
    if (model.groupManagers && model.groupManagers.length > 0) {
      return model.groupManagers.filter((e) => e.role.alias === role).map((t) => `${t.name}`).join(', ');
    }

    return '__';
  }
  public displayListGroups(model: any) {
    if (model.group && model.group.length > 0) {
      return model.group.map((t) => `${t.name}`).join(', ');
    }

    return '__';
  }

  public handleSearch() {
    this.fetchData();
  }

  public closeDialogDetail() {
    this.userId = '';
    this.visibleDetail = false;
  }

  public createSuccess() {
    this.visible = false;
    this.fetchData();
  }

  public applyFilter() {
    this.fetchData();
  }
  public goAddNewRouter() {
    this.visible = true;
    this.userId = '';
  }
  public closeDialog() {
    this.isSample = false;
    this.fetchData();
  }
  public downloadSample() {
    window.open(this.sampleUrl, '_blank');
  }
  public handleDelete(model: any) {
    this.$store.dispatch(ActionTypeProduct.UpdateStatusProduct, {
      id: model.id,
      status: StatusCode.Deleted,
      onSuccess: () => {
        this.fetchData();
        model.instance.confirmButtonLoading = false;
        model.instance.confirmButtonText = 'Confirm';
        model.done();
      },
      onFailure: () => {
        model.instance.confirmButtonLoading = false;
        model.instance.confirmButtonText = 'Confirm';
      }
    });
  }
  public handleDisabled(model: any) {
    const user = find(
        cloneDeep(this.data),
        (e) => e.id.toString() === model.id.toString()
      ),
      isBlocked = user.status === StatusCode.Blocked;
    this.$store.dispatch(ActionTypeGroupManage.UpdateGroupManager, {
      id: model.id,
      set: {
        status: isBlocked ? StatusCode.Active : StatusCode.Blocked
      },
      onSuccess: () => {
        this.fetchData();
        model.instance.confirmButtonLoading = false;
        model.instance.confirmButtonText = 'Confirm';
        model.done();
      },
      onFailure: () => {
        model.instance.confirmButtonLoading = false;
        model.instance.confirmButtonText = 'Confirm';
      }
    });
  }

  public clickDetail(id: string) {
    this.$router.push({
      name: BranchAndGroupRouterName.DetailGroup,
      params: {
        id
      }
    });
  }
  public handleUpdate(id: string) {
    this.visible = true;
    this.userId = id;
  }

  public handlePaginationChange(value) {
    if (typeof value === 'number') {
      this.$store.commit(MutationTypeGroupManager.PaginationChange, {
        ...this.pagination,
        size: value
      });
    } else {
      this.$store.commit(MutationTypeGroupManager.PaginationChange, {
        ...this.pagination,
        ...value
      });
    }
    this.fetchData();
  }
  public changeFilter(model) {
    const branch = model.find((e) => e.key === FilterKey.Branch);
    const nextFilter = {
      [FilterKey.Branch]: branch ? branch.value : []
    };
    this.filterParams = {
      ...cloneDeep(this.filterParams),
      ...nextFilter
    };
    this.fetchData();
  }

  public formatFullNameUser(model) {
    return fullNameUser(model);
  }
  public displayAddress(model: IAddress) {
    return model && model.province ? this.$store.getters['getAddressString'](model) : '';
  }

  protected mounted() {
    this.$nextTick(() => {
      this.$store.commit(MutationType.ClearBack);
      this.$store.commit(MutationType.SetBreadcrumb, this.$t('title.managedepartment'));
      // this.$store.commit(MutationTypeUser.PaginationReset);
    });
    !this.isRefesh && this.fetchData();
  }
  protected beforeDestroy() {
    this.$store.commit(MutationType.ClearBreadcrumb);
  }

  private fetchData() {
    const text = cloneDeep(this.searchText);
    const _filterParams = <any> {};
    if (this.filterParams[FilterKey.Branch].length) {
      _filterParams.branch = this.filterParams[FilterKey.Branch];
    }
    if (text) {
      _filterParams.search = text;
    }
    this.$store.dispatch(ActionTypeGroupManage.GetListGroupManage, {
      form: {
        filter: _filterParams,
        pagination: {
          size: this.pagination.limit,
          page: text ? 1 : this.pagination.page
        }
      }
    });
  }
}
