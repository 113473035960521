import './main.scss';

import { SplashScreenComponent, TopAlertComponent } from './components';
import { HomeAdminLayout, LoginLayout } from './layouts';
import store, { IState } from './store';

import CKEditor from '@ckeditor/ckeditor5-vue';
import ElementUI from 'element-ui';
import locale from 'element-ui/lib/locale/lang/en';
import { get } from 'lodash';
import Viewer from 'v-viewer';
import Vue from 'vue';
import VueGoogleMaps from 'vue-googlemaps';
import VueLazyload from 'vue-lazyload';
import VueLogger from 'vuejs-logger';
import { mapState } from 'vuex';
import { sync } from 'vuex-router-sync';
import { ShopCampaignDetailModel } from './admin/CustomCampaign/NewRACampaign2023/Components/ShopCampaignDetailModel';
// tslint:disable-next-line:max-line-length
import { SubDealerCampaignDetailModel } from './admin/CustomCampaign/NewRACampaign2023/Components/SubDealerCampaignDetailModel';
import { ZPCampaignDetail } from './admin/CustomCampaign/ZeroPercentCampaign/Components/ZPCampaignDetail';
import { ManageFeedbackEditor } from './admin/ManageFeedback/ManageFeedbackEditor';
import { ActionTypeUser } from './admin/User/Store/types';
import { ButtonFilter } from './components/ButtonFilter';
import { CurrencyInput } from './components/CurrencyInput';
import { ListData } from './components/ListData';
import { configLogger } from './helpers';
import { i18n } from './locales';
import router from './router/router-admin';
import { initNotification, sendTagForNotification, setExternalUserId } from './services';

Vue.use(ElementUI, { locale });
Vue.use(VueGoogleMaps, {
  load: {
    // Google API key
    apiKey: process.env.GOOGLE_API_KEY,
    // Enable more Google Maps libraries here
    // libraries: ['places'],
    // Use new renderer
    useBetaRenderer: false
  }
});
sync(store, router);
Vue.use(Viewer, {
  name: 'image-viewer',
  defaultOptions: {
    zIndex: 9999
  }
});
const _VueLogger: any = VueLogger;
Vue.use(_VueLogger, configLogger());
Vue.use(VueLazyload, {
  preLoad: 1.3,
  error: '/assets/img/Placeholder@3x.png',
  loading: '/assets/img/Placeholder@3x.png',
  attempt: 1
});

Vue.directive('uppercase', {
  bind: (el: HTMLElement) => {
    el.addEventListener('keyup', (e: any) => {
      e.target.value = e.target.value.toUpperCase();
    });
  }
});
Vue.component('currency-input', CurrencyInput);
Vue.component('list-data', ListData);
Vue.component('btn-filter', ButtonFilter);
Vue.component('feedback-detail', ManageFeedbackEditor);
Vue.component('new-shops-detail-modal', ShopCampaignDetailModel);
Vue.component('new-subs-detail-modal', SubDealerCampaignDetailModel);
Vue.component('zeroCampaign-detail', ZPCampaignDetail);

Vue.use(CKEditor);
// tslint:disable
new Vue({
  el: '#app',
  router,
  store,
  i18n: i18n(),
  computed: {
    ...mapState({
      layout: (state: IState) => {
        return state.global.layout;
      },
      isLoading: (state: IState) => {
        return state.global.isLoading;
      },
      topAlertOpened: (state: IState) => {
        const topAlert = state.global.topAlert;
        if (topAlert.opened) {
          return topAlert;
        }
        return false;
      },
      authUser: (state: IState) => state.global.authUser,
      isAuthUserAndInitOneSignal: (state: IState) => state.global.authUser && state.global.authUser.id && state.global.oneSignalInitial
    })
  },
  watch: {
    isAuthUserAndInitOneSignal(newValue) {
      if (newValue) {
        sendTagForNotification(<any> {
          user: this.authUser
        });
      }
      setExternalUserId(this.authUser);
    }
  },
  components: {
    'home-layout': HomeAdminLayout,
    'login-layout': LoginLayout,
    'splash-screen': SplashScreenComponent,
    'top-alert': TopAlertComponent
  },
  mounted() {
    document.title = `Dealers Management Portal ${
      process.env.ENV !== 'production' && process.env.ENV === 'staging' ? `(${process.env.ENV})` : ''
    }`;
  },
  async created() {
    if (localStorage.token && localStorage.token !== 'null') {
      await this.$store.dispatch(ActionTypeUser.GetProfile, {
        router: this.$router
      });
    } else {
      this.$router.replace('/login');
    }

    if (process.env.ONESIGNAL_APP_ID) {
      await initNotification(process.env.ONESIGNAL_APP_ID, {
        commit: this.$store.commit,
        onReceiveNotification: (_data) => {
          const type = get(_data, 'data.type');
        },
        onClickNotification: async (_data) => {
          const { group, role } = this.authUser;
        }
      });
    }
  },
});
