import { DocumentNode } from 'graphql';
import gql from 'graphql-tag';
import { crudQuery, ICRUDQuery } from '../Core/query';

const invoiceModel = `
data {
  code
  createdAt
  createdBy
  createdUser {
    name
  }
  date
  orderNumber
  dealer {
    address {
      address
      district
      province
      ward
    }
    dealerCode
    id
    name
  }
  dealerId
  groupLeaders {
    name
  }
  id
  name
  status
  taxNumber
  total
  totalAmount
  updatedAt
  updatedBy
}
pagination {
  nextPageToken
  page
  size
  total
  totalPages
  totalPerPage
}
`;

export const invoices = 'invoices';
// tslint:disable-next-line:no-empty-interface
interface IInvoiceQuery extends ICRUDQuery {
  GET_INVOICE_LIST: DocumentNode;
}
export const invoiceListQuery: IInvoiceQuery = {
  ...crudQuery(invoices, invoiceModel),
  GET_INVOICE_LIST: gql`
  query GET_INVOICE_LIST($form: FilterInvoiceInput) {
    getInvoices(form: $form){
      ${invoiceModel}
    }
  }
`,
};
