import { cloneDeep } from 'lodash';
import { SystemRoleAlias } from 'root/admin/User/Store/types';
import { IPaginationParams } from 'root/api/graphql/Core';
import { IAddress, IBreadcrumb, IUser } from 'root/models';
import { dealerShopFormDefault, IDealerShopForm } from 'root/models/Dealer';
import { IState, MutationType } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import { DealerRouterPath } from '..';
import { DealerShopDetail } from './DealerShopDetail';
import { DealerShopEditor } from './DealerShopEditor';
import { ActionTypeDealerShop, MutationTypeDealerShop } from './Store/types';
import './styles.scss';

@Component({
  template: require('./view.html'),
  components: {
    editor: DealerShopEditor,
    detail: DealerShopDetail
  },
  computed: {
    ...mapState({
      data: (state: IState) => state.dealerShop.data,
      loading: (state: IState) => state.dealerShop.loading,
      authUser: (state: IState) => state.global.authUser,
      pagination: (state: IState) => state.dealerShop.pagination,
    }),
  },
})
export class DealerShop extends Vue {
  public get serviceId(): string {
    return this.$route.params.id;
  }
  public get isSalePlanning() {
    if (this.authUser.superAdmin || this.authUser.role.alias === SystemRoleAlias.Admin
      || this.authUser.role.alias === SystemRoleAlias.SaleAdmin) {
      return true;
    }

    return false;
  }
  public get columns() {
    return [
      {
        prop: 'name',
        label: 'Name',
        width: 180,
        formatter: (model: any) => {
          return model.name ? model.name : '--';
        },
      },
      {
        prop: 'shopCode',
        label: 'Code',
        width: 180,
        formatter: (model: any) => {
          return model.shopCode ? model.shopCode : '--';
        },
      },
      {
        prop: 'staff',
        label: 'Staff',
        width: 120,
        formatter: (model: any) => {
          return model.dealerShops_aggregate.aggregate.count || 0;
        },
      },
      // {
      //   prop: 'phoneNumber',
      //   label: 'Phone',
      //   width: 180,
      //   formatter: (model: any) => {
      //     return model.phoneNumber || '--';
      //   },
      // },
      // {
      //   prop: 'email',
      //   label: 'Email',
      //   width: 180,
      //   formatter: (model: any) => {
      //     return model.email ? model.email : '--';
      //   },
      // },
      {
        prop: 'address',
        label: 'Address',
        width: 240,
        formatter: (model: any) => {
          return model.address ? this.displayAddress(model.address) : '--';
        },
      },
      {
        prop: 'action',
        label: 'Action',
        align: 'center',
        fixed: 'right',
      },
    ];
  }

  public searchText: string = '';
  public authUser: IUser;
  public pagination: IPaginationParams;
  public dealerShop: IDealerShopForm = dealerShopFormDefault();
  public visible: boolean = false;
  public visibleDetail: boolean =false;
  public dealerId: string = '';
  public shopId: string = '';

  public displayAddress(model: IAddress) {
    return model && model.province
      ? this.$store.getters['getAddressString'](model)
      : '';
  }
  public getActions() {
    if (this.isSalePlanning) {
      return [
        'view',
        'edit',
      ];
    }

    return [
      'view',
    ];
  }
  public handleActions({ id, action }) {
    if (action === 'edit') {
      this.handleUpdate(id);

      return;
    }
    if (action === 'view') {
      this.clickDetail(id);

      return;
    }

    return;
  }

  public handleUpdate(id: string) {
    this.shopId = id;
    this.visible = true;
  }
  public clickDetail(id: string) {
    this.dealerId = id;
    this.visibleDetail = true;
  }
  public changeFilter(value) {
    if (typeof value === 'number') {
      this.$store.commit(MutationTypeDealerShop.PaginationChange, {
        ...this.pagination,
        size: value
      });
    } else {
      this.$store.commit(MutationTypeDealerShop.PaginationChange, {
        ...this.pagination,
        ...value
      });
    }
    this.fetchData();
  }
  public handleSearch() {
    this.fetchData();
  }
  public createSuccess() {
    this.fetchData();
  }
  public goAddNewRouter() {
    this.dealerShop = null;
    this.shopId = '';
    this.visible = true;
  }

  public displayListStaff(model: any) {
    if (model.dealerShops && model.dealerShops.length > 0) {
      return model.dealerShops.map((t) => `${t.dealer.name}`).join(', ');
    }

    return '__';
  }

  public handlePaginationChange() {
    this.fetchData();
  }

  protected mounted() {
    this.$nextTick(() => {
      const breadcrumb: IBreadcrumb[] = [
        {
          label: 'title.dealer',
          path: DealerRouterPath.ListDealer,
          current: false
        },
        {
          label: 'title.view_details',
          path: null,
          current: true
        }
      ];
      this.$store.commit(MutationType.SetBreadcrumb, breadcrumb);
      this.fetchData();
    });
  }
  protected beforeDestroy() {
    this.$store.commit(MutationType.ClearBreadcrumb);
  }

  private fetchData() {
    const text = cloneDeep(this.searchText);
    this.$store.dispatch(ActionTypeDealerShop.GET_LIST_DEALERSHOP, {
      id: this.serviceId,
      search: `%${text}%`,
      pagination: this.pagination
    });
  }
}
