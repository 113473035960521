import { StatusCode } from './Common';
import { IDevicePart } from './DeviceParts';
import { ISimpleUser } from './User';

export enum AccessoryOrderFilter {
  Code = 'code',
  DeliveryName = 'deliveryName',
  Phone = 'phone'
}
export enum AccessoryOrderFilterKey {
  OrderStatus = 'orderStatus'
}
export enum AccessoryProductFilter {
  EnglishName = 'englishName',
  VietnameseName = 'vietnameseName',
  Model = 'model'
}

export enum AccessoryOrderStatus {
  New = 'new',
  Confirmed = 'confirmed',
  Delivering = 'delivering',
  PendingPayment = 'pending_payment',
  Completed = 'completed',
  Cancelled = 'cancelled'
}

export const enum AccessoryCategoryType {
  Materital = 'material',
  Accessory = 'accessory',
  SparePart = 'spare_part'
}
export interface IAccessoryCategory {
  id?: string;
  type?: AccessoryCategoryType;
  name?: string;
  status?: StatusCode;
  parentId?: string;
}
export interface ICountry {
  id?: string;
  vietnameseName: string;
}
export interface IAccessoryBrand {
  id?: string;
  status?: StatusCode;
  createdBy?: string;
  createdAt?: string;
  vietnameseName?: string;
}
export interface IAccessoryProduct {
  id?: string;
  images?: string[];
  model?: string;
  package?: object;
  price?: string;
  firstCategoryLevelId?: string;
  firstCategoryLevel?: IAccessoryCategory;
  secondCategoryLevelId?: string;
  secondCategoryLevel?: IAccessoryCategory;
  vietnameseName?: string;
  englishName?: string;
  devicePartId?: string;
  devicePart?: IDevicePart;
  description?: string;
  createdBy?: string;
  createdUser?: ISimpleUser;
  createdAt?: string;
  countryId?: string;
  country?: ICountry;
  accessoryBrandId?: string;
  accessoryBrand?: IAccessoryBrand;
  warranty?: string;
  status?: StatusCode;
  total?: number;
  quantity?: number;
  productStatus?: StatusCode;
}
export const accessoryProductDefault: IAccessoryProduct = {
  englishName: '',
  vietnameseName: '',
  images: [],
  model: '',
  package: {},
  price: '',
  firstCategoryLevelId: '',
  secondCategoryLevelId: '',
  devicePartId: '',
  description: '',
  countryId: '',
  accessoryBrandId: '',
  warranty: ''
};
export enum AccessoryFilterKey {
  Category = 'firstCategoryLevelId',
  Brand = 'accessoryBrandId',
  Country = 'countryId'
}

export interface IAccessoryOrder {
  id: string;
  code: string;
  orderStatus: AccessoryOrderStatus;
  isCancelledByCustomer?: boolean;
  status: StatusCode;
  updatedAt: string;
  updatedBy: string;
  updatedUser: ISimpleUser;
  createdAt: string;
  createdUser: ISimpleUser;
  contactId: string;
  contact: ISimpleUser;
  customerId: string;
  customer: ISimpleUser;
  billingStatic: {
    total: number;
    deliveryFee: number;
    productCost: number;
    discount: number;
  };
  products: Array<{
    id: string;
    productId: string;
    quantity: number;
    productInfoStatic: any
    status: StatusCode;
  }>;
  agentNote?: string;
}
