import { stripObject } from '@hgiasac/helper';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import { ActionTypeBranchManage } from 'root/admin/BranchAndGroup/BranchManage/Store/types';
import { SystemRoleAlias } from 'root/admin/User/Store/types';
import { IPaginationParams } from 'root/api/graphql/Core';
import { FilterByBranch } from 'root/components/FilterByBranch';
import { IAddress, IFilterInput, IUser } from 'root/models';
import { IState, MutationType, TypeAlert } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
// import { ManageFeedbackEditor } from '../ManageFeedbackEditor';
import { ActionTypeFeedback, MutationTypeFeedback } from '../Store/types';
import './styles.scss';

export enum FeedbackTypeCode {
  ChangeProfile = 'change_profile',
  ServiceFeedback= 'service_feedback',
  ApplicationFeedback= 'application_feedback'
}
const enum FilterKey {
  Status = 'status',
  Role = 'role',
  Date = 'date',
  Branch = 'branch'
}
export enum FeedbackType {
  ChangeProfile = 'Change Profile',
  ServiceFeedback= 'Service Feedback',
  ApplicationFeedback= 'Application Feedback'
}
export enum StatusFeedbackCode {
  Waiting = 'waiting',
  Completed = 'completed',
  Rejected = 'rejected',
  Received = 'received',
  Updated = 'updated'
}
export enum StatusFeedback {
  Waiting = 'Waiting',
  Completed = 'Completed',
  Rejected = 'Rejected',
  Received = 'Received',
  Updated = 'Updated'
}
export enum DealerTypeCode {
  DaikinDealer = 'daikin_dealer',
  SubDealer = 'sub_dealer',
  Staff = 'daikin_staff',
}
export enum DealerType {
  DaikinDealer = 'Dealer',
  SubDealer = 'Sub-Dealer',
  Staff = 'Salesman',
}
@Component({
  template: require('./view.html'),
  components: {
    'btn-filter-by-branch': FilterByBranch
  },
  computed: {
    ...mapState({
      data: (state: IState) => state.feedback.data,
      loading: (state: IState) => state.feedback.loading,
      pagination: (state: IState) => state.feedback.pagination,
      branch: (state: IState) => state.branchManage.data,
      authUser: (state: IState) => state.global.authUser,
    })
  },
})

export class ProfileFeedbackList extends Vue {
  public get serviceId(): string {
    return this.$route.params.id;
  }
  public get dataFilterBranch() {
    const _branch: IFilterInput[] = this.branch.reduce((res, item) => {
      res.push({
        key: item.id,
        value: item.id,
        name: item.name
      });

      return res;
    }, []);

    return [
      {
        key: FilterKey.Branch,
        name: 'Branch',
        value: _branch
      },
    ];
  }
  public get isSalePlanning() {
    if (this.authUser.superAdmin || this.authUser.role.alias === SystemRoleAlias.Admin
      || this.authUser.role.alias === SystemRoleAlias.SaleAdmin) {
      return true;
    }

    return false;
  }
  public get columns() {
    return [
      // {
      //   prop: 'type',
      //   label: 'Type',
      //   width: 180,
      //   formatter: this.formatterTypeFeedback,
      // },
      {
        prop: 'request',
        label: 'Requested By',
        width: 250,
        formatter: (model: any) => {
          return model.dealer && model.dealer.name ? model.dealer.name : '--' ;
        }
      },
      {
        prop: 'role',
        label: 'Role',
        width: 150,
        formatter: this.formatterRole
      },
      {
        prop: 'direct-dealer',
        label: 'Dealer Code',
        width: 180,
        formatter: (model: any) => {
          return this.formatterCode(model, DealerTypeCode.DaikinDealer);
        }
      },
      // {
      //   prop: 'sub-dealer',
      //   label: 'Sub-Dealer Code',
      //   width: 180,
      //   formatter: (model: any) => {
      //     return this.formatterCode(model, DealerTypeCode.SubDealer);
      //   }
      // },
      // {
      //   prop: 'salesman',
      //   label: 'Salesman Code',
      //   width: 180,
      //   formatter: (model: any) => {
      //     return this.formatterCode(model, DealerTypeCode.Staff);
      //   }
      // },
      {
        prop: 'branch',
        label: 'Branch',
        width: 150,
        formatter: (model: any) => {
          return model.dealer && model.dealer.branch && model.dealer.branch.name ? model.dealer.branch.name : '--' ;
        }
      },
      // {
      //   prop: 'dealer',
      //   label: 'Dealer Owner',
      //   width: 250,
      //   formatter: () => {
      //     return '--' ;
      //   }
      // },
      // {
      //   prop: 'dealerCode',
      //   label: 'Dealer Owner Code',
      //   width: 250,
      //   formatter: () => {
      //     return '--' ;
      //   }
      // },
      {
        prop: 'createdAt',
        label: 'Requested At',
        width: 200,
        formatter: (model: any) => {
          if (model.createdAt) {
            return moment(model.createdAt).format('HH:mm-DD/MM/YYYY');
          }

          return '--';
        }
      },
      {
        prop: 'status',
        label: 'Status',
        width: 180,
        formatter: this.formaterStatus,
        labelStyle: (model: any) => {
          return model.status || '--';
        },
      },
      {
        prop: 'action',
        label: '',
        align: 'center',
        fixed: 'right',
        width: 60
      }
    ];
  }
  public get dataFilter() {
    const _status = [
      {
        key: StatusFeedbackCode.Waiting,
        value: StatusFeedbackCode.Waiting,
        name: StatusFeedback.Waiting
      },
      {
        key: StatusFeedbackCode.Received,
        value: StatusFeedbackCode.Received,
        name: StatusFeedback.Received
      },
      {
        key: StatusFeedbackCode.Updated,
        value: StatusFeedbackCode.Updated,
        name: StatusFeedback.Updated
      }
    ];
    // const _role = [
    //   {
    //     key: DealerTypeCode.DaikinDealer,
    //     value: DealerTypeCode.DaikinDealer,
    //     name: DealerType.DaikinDealer
    //   },
    //   {
    //     key: DealerTypeCode.SubDealer,
    //     value: DealerTypeCode.SubDealer,
    //     name: DealerType.SubDealer
    //   },
    //   {
    //     key: DealerTypeCode.Staff,
    //     value: DealerTypeCode.Staff,
    //     name: DealerType.Staff
    //   }
    // ];

    return [
      {
        key: FilterKey.Status,
        name: 'Status',
        value: _status
      },
      // {
      //   key: FilterKey.Role,
      //   name: 'Role',
      //   value: _role
      // }
    ];
  }
  public get selectedFilter() {
    const _filterParams = cloneDeep(this.filterParams);

    return [
      {
        key: FilterKey.Status,
        value: _filterParams[FilterKey.Status]
      },
      {
        key: FilterKey.Branch,
        value: _filterParams[FilterKey.Branch]
      }
    ];
  }

  public searchText: string = '';
  public visible: boolean = false;
  public pagination: IPaginationParams;
  public loading: boolean;
  public dataJson: any = {};
  public branch: any[];
  public authUser: IUser;
  public filterParams = {
    [FilterKey.Status]: [],
    [FilterKey.Role]: [],
    [FilterKey.Date]: [],
    [FilterKey.Branch]: []
  };
  public FilterRequestTime: any = {
    from: null,
    to: null,
  };
  public feedbackId: string = '';
  public displayAddress(model: IAddress) {
    return model && model.province ? this.$store.getters['getAddressString'](model) : '';
  }
  public formatterRole(model: any) {
    if (model && model.dealer && model.dealer.role) {
      switch (model.dealer.role.alias) {
      case DealerTypeCode.DaikinDealer:
        return DealerType.DaikinDealer;
      case DealerTypeCode.Staff:
        return DealerType.Staff;
      }
    }

    return '--';
  }
  public formatterCode(model, role) {
    if (model && model.dealer && model.dealer.role && model.dealer.role.alias === role) {
      return model && model.dealer && model.dealer.dealerCode ? model.dealer.dealerCode : '--';
    }

    return '--';
  }
  public formaterStatus(model: any) {
    switch (model.status) {
    case StatusFeedbackCode.Waiting:
      return StatusFeedback.Waiting;
    case StatusFeedbackCode.Completed:
      return StatusFeedback.Completed;
    case StatusFeedbackCode.Rejected:
      return StatusFeedback.Rejected;
    case StatusFeedbackCode.Received:
      return StatusFeedback.Received;
    case StatusFeedbackCode.Updated:
      return StatusFeedback.Updated;
    }
  }
  public formatterTypeFeedback(model: any) {
    switch (model.type) {
    case FeedbackTypeCode.ChangeProfile:
      return FeedbackType.ChangeProfile;
    case FeedbackTypeCode.ServiceFeedback:
      return FeedbackType.ServiceFeedback;
    case FeedbackTypeCode.ApplicationFeedback:
      return FeedbackType.ApplicationFeedback;
    }
  }
  public getActions(status) {
    if (status === StatusFeedbackCode.Waiting && this.isSalePlanning) {
      return [
        'view',
        'receive',
        'update'
        // 'complete',
        // 'reject'
      ];
    }
    if (status === StatusFeedbackCode.Waiting && !this.isSalePlanning) {
      return [
        'view',
        'receive',
      ];
    }
    if (status === StatusFeedbackCode.Received && this.isSalePlanning) {
      return [
        'view',
        'update',
      ];
    }

    return [
      'view',
    ];
  }
  public handleActions({ id, action }) {
    if (action === 'complete') {
      this.handleUpdate(id, 'completed');

      return;
    }

    if (action === 'reject') {
      this.handleUpdate(id, 'rejected');

      return;
    }
    if (action === 'receive') {
      this.handleUpdate(id, 'received');

      return;
    }

    if (action === 'update') {
      this.handleUpdate(id, 'updated');

      return;
    }

    if (action === 'view') {
      this.clickDetail(id);

      return;
    }

    return;
  }
  public clickDetail(id: string) {
    const feedbackDetail = this.$store.getters.getFeedbackDetail(id);
    this.feedbackId = id;
    this.dataJson = feedbackDetail;
    this.visible = true;
  }
  public changeFilter(model, time) {
    if (time && time.from && time.to) {
      this.FilterRequestTime = time;
    }
    if (time && !time.from && !time.to) {
      this.FilterRequestTime = {
        from: null,
        to: null,
      };
    }
    const status = model.find((e) => e.key === FilterKey.Status);
    // const role = model.find((e) => e.key === FilterKey.Role);
    const nextFilter = {
      [FilterKey.Status]: status ? status.value : [],
      // [FilterKey.Role]: role ? role.value : []
    };
    this.filterParams = {
      ...cloneDeep(this.filterParams),
      ...nextFilter
    };
    this.fetchData();
  }
  public changeFilterBranch(filter) {
    const nextFilter = {
      [FilterKey.Branch]: filter ? filter : [],
    };
    this.filterParams = {
      ...cloneDeep(this.filterParams),
      ...nextFilter
    };
    this.fetchData();
  }
  public handleSearch() {
    this.fetchData();
  }
  public handleUpdate(id: string, status: string) {
    this.$store.dispatch(ActionTypeFeedback.UPDATE_STATUS, ({
      status,
      id,
      onSuccess: () => {
        this.loading = false;
        const message = 'Update feedback successfully';
        this.$store.commit(MutationType.OpenTopAlert, {
          message,
          type: TypeAlert.Success
        });
      },
      onFailure: () => {
        this.loading = false;
      }
    }));
  }
  public handlePaginationChange(value) {
    if (typeof value === 'number') {
      this.$store.commit(MutationTypeFeedback.FeedbackPaginationChange, {
        ...this.pagination,
        size: value
      });
    } else {
      this.$store.commit(MutationTypeFeedback.FeedbackPaginationChange, {
        ...this.pagination,
        ...value
      });
    }
    this.fetchData();
  }

  protected mounted() {
    this.$nextTick(() => {
      this.$store.commit(MutationType.SetBreadcrumb, this.$t('title.feedbacks'));
      if (this.isSalePlanning) {
        this.$store.dispatch(ActionTypeBranchManage.GetListBranch, {
          form: {
            filter: {},
            pagination: {}
          }
        });
      }
      this.fetchData();
    });
  }
  protected beforeDestroy() {
    this.$store.commit(MutationType.ClearBreadcrumb);
  }

  private fetchData() {
    const text = cloneDeep(this.searchText);
    const _filterParams = <any> {};
    if (text) {
      _filterParams.search = text;
    }
    this.$store.commit(
      MutationTypeFeedback.FilterChange,
      stripObject({
        _and:
        {
          mode: 'special',
          data: [
            {type: {_eq : 'change_profile'}},
            this.filterParams[FilterKey.Status].length ? {
              [FilterKey.Status]: {
                _in: this.filterParams[FilterKey.Status]
              }
            } : null,
            this.filterParams[FilterKey.Branch].length ? {
              dealer: {
                branch : {
                  id: {
                    _in: this.filterParams[FilterKey.Branch]
                  }
                }
              }
            } : null,
            this.filterParams[FilterKey.Role].length ? {
              dealer: {
                role: {
                  alias : {
                    _in : this.filterParams[FilterKey.Role]
                  }
                }
              }
            } : null,
            this.FilterRequestTime && this.FilterRequestTime.from && this.FilterRequestTime.to ? {
              createdAt : {
                _gte: this.FilterRequestTime.from,
                _lte: moment(this.FilterRequestTime.to).clone().endOf('month')
              }
            } : null,
            text.trim() !== '' ? {
              _or : [
                {
                  dealer: {
                    name: {
                      _ilike: `%${text}%`
                    }
                  }
                }
              ]
            } : null
          ].filter((e) => !!e)
        }
      })
    );
    this.$store.dispatch(ActionTypeFeedback.FilterNoCache, {
      optionsHTTP: {
        orderBy: {
          updatedAt: 'desc'
        }
      }
    });
  }
}
