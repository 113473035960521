import { DKGqlClient } from 'root/api/graphql/Client';
import { ActionType, IState, MutationType } from 'root/store';
import { crudActions } from 'root/store/helpers';
import { ActionContext } from 'vuex';
import { ITransactionViewState } from './mutation';
import { ActionTypeTransactionView } from './types';

const { transactionView } = DKGqlClient();
export const actions = {
  ...crudActions('transactionView', {
    filter: transactionView.filter
  }),
  async [ActionTypeTransactionView.GetGroupView]({
    commit, dispatch
  }: ActionContext<ITransactionViewState, IState>) {
    try {
      const ProductTransList = await transactionView.getGroupView();
      commit(MutationType.GetProductTrans, ProductTransList);
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    }
  },
  async [ActionTypeTransactionView.GetViewById]({
    commit, dispatch
  }: ActionContext<ITransactionViewState, IState>,
                                                {id}) {
    try {
      const ProductTransList = await transactionView.getViewById(id);
      commit(MutationType.GetProductTrans, ProductTransList);
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    }
  }
};
