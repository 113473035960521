import { IBanners } from 'root/models';
import { gqlClient } from '../Core';
import { CRUDGqlService, ICRUDGqlService } from '../Core/crud';
import { BannerQuery } from '../Query/banner';
import { productListQuery } from '../Query/product';

export interface IBannerGqlService extends ICRUDGqlService<IBanners> {
    //
  insertBranchBanner(form: any): Promise<any>;
  updateBranchBanner(id: string, set: any): Promise<any>;
  deleteBranchBanner(id: string, branchId: any): Promise<any>;
  updateDescriptionServiceType(variableName: string, description: string): Promise<any>;
  getConfig(limit: number, offset: number): Promise<any>;
  changeConfig(id: string, set: any): Promise<any>;
}

export function bannerGqlService(): IBannerGqlService {
  async function updateBranchBanner(id: string, set: any): Promise<any> {
    const results = await gqlClient.mutate({
      mutation: BannerQuery.UPDATE_BRANCH_BANNER,
      variables: {
        id,
        set
      },
    });
    if (results.errors) {
      throw results.errors;
    }

    return results;
  }
  async function insertBranchBanner(form: any): Promise<any> {
    const results = await gqlClient.mutate({
      mutation: BannerQuery.INSERT_BRANCH_BANNER,
      variables: {
        form
      },
    });
    if (results.errors) {
      throw results.errors;
    }

    return results;
  }
  async function deleteBranchBanner(id: string, branchId: any): Promise<any> {
    const results = await gqlClient.mutate({
      mutation: BannerQuery.DELETE_BRANCH_BANNER,
      variables: {
        id,
        branchId
      },
    });
    if (results.errors) {
      throw results.errors;
    }

    return results;
  }
  async function updateDescriptionServiceType(variableName: string, description: string): Promise<any> {
    const results = await gqlClient.mutate({
      mutation: productListQuery.UPDATE_DESCRIPTION_SERVICE_TYPE,
      variables: {
        variableName,
        description
      },
    });
    if (results.errors) {
      throw results.errors;
    }

    return results;
  }
  async function getConfig(limit: number, offset: number): Promise<any> {
    const results = await gqlClient.query({
      query: productListQuery.GET_ALL_CONFIGURATION,
      variables: {
        limit, offset
      },
      fetchPolicy: 'network-only'
    });
    if (results.errors) {
      throw results.errors;
    }

    return results.data;
  }
  async function changeConfig(id: string, value: any): Promise<any> {
    const results = await gqlClient.mutate({
      mutation: BannerQuery.CHANGE_CONFIG,
      variables: {
        id,
        value
      },
    });
    if (results.errors) {
      throw results.errors;
    }

    return results;
  }

  return {
    ...CRUDGqlService<IBanners>('banners', BannerQuery),
    insertBranchBanner,
    updateBranchBanner,
    deleteBranchBanner,
    updateDescriptionServiceType,
    getConfig,
    changeConfig
  };
}
