import { DKGqlClient } from 'root/api/graphql/Client';
import { ActionType, IState } from 'root/store';
import { crudActions } from 'root/store/helpers';
import { ActionContext } from 'vuex';
import { IDealerTypeState } from '.';
import { ActionTypeDealerType } from './types';

const { dealerType } = DKGqlClient();
export const actions = {
  ...crudActions('dealerType', {
    filter: dealerType.filter,
  }),
  async [ActionTypeDealerType.CreateDealerType]({
    dispatch
 }: ActionContext<IDealerTypeState, IState>,
                                                {form, onSuccess, onFailure}) {
    try {
      await dealerType.insertDealerType(form);
      onSuccess();
    } catch (error) {
      onFailure();
      dispatch(ActionType.CatchException, error);
    }
  },
  async [ActionTypeDealerType.UpdateDealerType]({
    dispatch
  }: ActionContext<IDealerTypeState, IState>,
                                                {alias, form, onSuccess, onFailure}) {
    try {
      await dealerType.updateDealerType(alias, form);
      onSuccess();
    } catch (error) {
      onFailure(error);
      dispatch(ActionType.CatchException, error);
    }
  },
};
