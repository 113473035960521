import { StatusCode } from './Common';
import { ISimpleUser } from './User';

export enum PermissionType {
  DashBoard = 'dashBoard',
  SupportPortal = 'support_portal',
  Bookings = 'bookings',
  // SystemUsers = 'system_users',
  Customer = 'customers',
  Technician = 'technicians',
  Station = 'station',
  // Partners = 'partners',
  NewsAndAnnouncement = 'news-announcement',
  DevicesParts = 'device',
  Service = 'service',
  TechnicianWorkflows = 'technician_workflows',
  Billing = 'billing',
  Report = 'report',
  KPI = 'KPI',
  Configuration = 'configuration',
  Vouchers = 'vouchers',
  Accessory = 'accessories',
  Banner = 'banner',
  Systemuser = 'systemuser',
  Dealers = 'dealers',
  Branchgroup = 'branchgroup',
  Product = 'product',
  Redemption = 'redemption',
  ManageInvoice = 'manage-invoice',
  ManageImport = 'manage-import',
  Feedback = 'feedback',
  CustomCampaign = 'evaluate-display-photo',
  raCampaign = 'ra-campaign',
  Region = 'region',
  Branch = 'branch',
  Department = 'department',
  DealerType = 'dealerType',
  PerCampaign = 'zero-percent-campaign',
  NewRACampaign = '2023-campaign',
  SalesEduCampaign = 'sale-edu-campaign',
  SaleInCampaign = 'sale-in-campaign'

  // TransactionEpayment = 'transaction_epayment',
  // FeedbackIssue = 'feedback_issue',
  // NewsNotification = 'news_notification',
  // Roles = 'roles',
  // FailureCause = 'failure_cause',
  // FaultLocation = 'fault_location',
}

export interface IRoleCreateForm {
  name: string;
  permissions: PermissionType[];
  status: StatusCode;
}

export interface IRole extends IRoleCreateForm {
  id?: string;
  createdAt?: Date;
  updatedAt?: Date;
  createdBy?: ISimpleUser;
  updatedBy?: ISimpleUser;
  alias?: string;
}

export const roleCreateForm = (): IRoleCreateForm => {
  return {
    name: '',
    permissions: [],
    status: StatusCode.Active
  };
};
export function roleDefault(): IRole {
  return {
    ...roleCreateForm(),
    id: ''
  };
}
