import { DocumentNode } from 'graphql';
import gql from 'graphql-tag';
import { crudQuery, ICRUDQuery } from '../Core/query';

const raCampaignModel = `
  id
  address
  name
  dealerCode
  shortName
  proshopType {
    name
    alias
  }
  branch {
    id
    name
    branchCode
  }
  role {
    alias
  }
  assignedSubDealers(where: {status: {_eq: "active"}}) {
    dealer {
      name
      dealerCode
    }
  }
  email
  countRaJoined
  shops_aggregate(where: {isOwner: {_eq: true}}) {
    aggregate {
      count
    }
  }
  acl_campaigns_aggregate(where: {campaign: {campaignType: {_eq: "RA_CAMPAIGN"}}}) {
    aggregate {
      count
    }
  }
`;
const raCampaignDetailModel = `
  id
  createdAt
  branch {
    id
    name
  }
  email
  address
  name
  role {
    name
  }
  proshopType {
    name
    alias
  }
  acl_campaigns_aggregate(where: {campaign: {campaignType: {_eq: "RA_CAMPAIGN"}}}) {
    aggregate {
      count
    }
  }
  status
  updatedAt
  dealerCode
  shortName
  updatedBy
  createdBy
  createdUser {
    id
    name
  }
  updatedUser {
    id
    name
  }
`;

export const raCampaign = 'acl_campaigns';
// tslint:disable-next-line:no-empty-interface
interface IRACampaignQuery extends ICRUDQuery {
  GET_LIST_RA_CAMPAGIN: DocumentNode;
  GET_LIST_MANAGE_EXPORT: DocumentNode;
  GET_LIST_SHOP_CAMPAGIN: DocumentNode;
  GET_LIST_SHOP_CAMPAGIN_NOTJOIN: DocumentNode;
  APRROVE_SHOP: DocumentNode;
  REQUEST_REUP: DocumentNode;
  GET_SHOP: DocumentNode;
  GET_PDF_EXPORT_DATA: DocumentNode;
  SUBMIT_REVIEW: DocumentNode;
  GET_PACKAGE: DocumentNode;
  UPDATE_PACKAGE: DocumentNode;
  GET_CONFIG: DocumentNode;
  GET_MASK_CONFIG: DocumentNode;
  END_CAMPAIGN: DocumentNode;
  MASK_CHANGE: DocumentNode;
  GET_STATISTIC: DocumentNode;
  EXPORT_STATISTIC: DocumentNode;
  GET_QUALIFIED_SHOP: DocumentNode;
  APPROVE_ALL: DocumentNode;
  GET_ACTION_HISTORY: DocumentNode;
  GET_DEALER_BY_BRANCH: DocumentNode;
  GET_LIST_JOINED_SHOP: DocumentNode;
  GET_LIST_JOINED_SHOP_NOT_REVIEW: DocumentNode;
  GET_CONFIG_CROP_PICTURE: DocumentNode;
  GET_LIST_SHOPS: DocumentNode;
  GET_LIST_SHOPS_NOT_TYPE: DocumentNode;
  GET_LIST_SHOPS_NOT_REVIEW: DocumentNode;
  GET_LIST_SHOPS_NOT_REVIEW_NOT_TYPE: DocumentNode;
  GET_LIST_SHOPS_NOT_JOIN: DocumentNode;
  GET_LIST_SHOPS_NOT_JOIN_NOT_TYPE: DocumentNode;
  GET_LIST_SHOPS_NOT_JOIN_NOT_REIVEW: DocumentNode;
  GET_LIST_SHOPS_NOT_JOIN_NOT_REIVEW_NOT_TYPE: DocumentNode;
  GET_LIST_SUB_DEALER_REQUEST: DocumentNode;
  GET_LIST_SUB_DEALER_REQUEST_BRANCH: DocumentNode;
  GET_LIST_SUB_DEALER_REQUEST_REGION: DocumentNode;
  GET_LIST_SUB_DEALER_REQUEST_ALL: DocumentNode;
  UPDATE_SUBDEALER_REQUEST: DocumentNode;
  GET_LIST_CONFIRMATION_CODE: DocumentNode;
  DOWNLOAD_CONFIRMATION_CODE: DocumentNode;
  GENERATE_CODE: DocumentNode;
  GET_LIST_SUB: DocumentNode;
  GET_LIST_SUB_NOT_REVIEW: DocumentNode;
  GET_LIST_SUB_NOT_JOIN: DocumentNode;
  GET_LIST_SUB_NOT_JOIN_NOT_REIVEW: DocumentNode;
  GET_LIST_SUB_NOT_JOIN_NOT_REIVEW_CODE_TYPE: DocumentNode;
  GET_SUB_DEALER_CAMPAIGN_DETAIL: DocumentNode;
  PUSH_REMINDER: DocumentNode;
  LOG_REMINDER: DocumentNode;
  GET_BRANCH_ID: DocumentNode;
}
export const raCampaignQuery: IRACampaignQuery = {
  ...crudQuery(raCampaign, raCampaignModel),
  GET_LIST_RA_CAMPAGIN: gql`
  query GET_LIST_RA_CAMPAGIN($limit:Int, $role: [String!],
    $branch: [uuid!] , $order:[dealers_order_by!],
    $offset: Int, $search: String) {
    dealers(where: {status:{_eq: "active"},
    _and: [{_or: [{role: {alias: {_eq: "daikin_dealer"}}},
    {role: {alias: {_eq: "sub_dealer"}}, assignedSubDealers: {dealerId: {_is_null: false}}}]},
    {role:{alias:{_in:$role}}}, {branch: {id: {_in: $branch}}},
      {_or: [{name: {_ilike: $search}}, {shortName: {_ilike: $search}}, {dealerCode: {_ilike: $search}}]}]},
    offset: $offset, limit: $limit, order_by: $order) {
      ${raCampaignModel}
    }
    dealers_aggregate (where: {status:{_eq: "active"},
    _and: [{_or: [{role: {alias: {_eq: "daikin_dealer"}}},
    {role: {alias: {_eq: "sub_dealer"}}, assignedSubDealers: {dealerId: {_is_null: false}}}]},
    {role:{alias:{_in:$role}}}, {branch: {id: {_in: $branch}}},
      {_or: [{name: {_ilike: $search}}, {shortName: {_ilike: $search}}, {dealerCode: {_ilike: $search}}]}]}) {
        aggregate {
          count
        }
    }
  }
  `,
  GET_LIST_SHOP_CAMPAGIN: gql`
  query GET_LIST_SHOP_CAMPAGIN($id: uuid!, $status:[String!], $limit:Int, $offset: Int, $search: String) {
    dealers(where: {role: {alias: {_in: ["daikin_dealer", "sub_dealer"]}}, id: {_eq: $id}}) {
      ${raCampaignDetailModel}
      shops(where: {isOwner: { _eq: true },_and: [{_or: [
        { shop: { acl_campaigns: {campaign: {campaignType: {_eq: "RA_CAMPAIGN"}}, requestStatus: { _in: $status } } } }
        { shop: { _not: { acl_campaigns: {campaign: {campaignType: {_nin: ["ACL_CAMPAIGN"]}}}}}}]}
        {_or: [
        { shop: { name: { _ilike: $search } } }
        { shop: { phoneNumber: { _ilike: $search } } }
        { shop: { email: { _ilike: $search } } }
        ]}]},offset: $offset, limit: $limit) {
        shop {
          id
          name
          email
          address
          phoneNumber
          acl_campaigns(where: {campaign: {campaignType: {_eq: "RA_CAMPAIGN"}}}) {
            id
            reason
            requestStatus
            updatedAt
            classification
            reviews {
              description
              updatedAt
            }
          }
          dealerShops {
            isOwner
            contractStatus
          }
        }
      }
      shops_aggregate(where: {
        isOwner: { _eq: true }
        _and: [
          {_or: [
        { shop: { acl_campaigns: {campaign: {campaignType: {_eq: "RA_CAMPAIGN"}}, requestStatus: { _in: $status } } } }
        { shop: { _not: { acl_campaigns: {campaign: {campaignType: {_nin: ["ACL_CAMPAIGN"]}}} } } }
        ]}
        {
        _or: [{ shop: { name: { _ilike: $search } } }{ shop: { phoneNumber: { _ilike: $search } } }
          { shop: { email: { _ilike: $search }}}]}]}) {
        aggregate {
          count
        }
      }
    }
  }
  `,
  GET_LIST_SHOP_CAMPAGIN_NOTJOIN: gql`
  query GET_LIST_SHOP_CAMPAGIN($id: uuid!, $status:[String!], $limit:Int, $offset: Int, $search: String) {
    dealers(where: {role: {alias: {_in: ["daikin_dealer", "sub_dealer"]}}, id: {_eq: $id}}) {
      ${raCampaignDetailModel}
      shops(where: {isOwner: {_eq: true}, _and: [{shop: {acl_campaigns: {campaign: {campaignType: {_eq: "RA_CAMPAIGN"}}
      ,requestStatus: {_in: $status}}}},
        {_or: [{shop: {name: {_ilike: $search}}}, {shop: {phoneNumber: {_ilike: $search}}},
          {shop: {email: {_ilike: $search}}}]}]}, offset: $offset, limit: $limit) {
        shop {
          id
          name
          email
          address
          phoneNumber
          acl_campaigns(where: {campaign: {campaignType: {_eq: "RA_CAMPAIGN"}}}) {
            id
            reason
            requestStatus
            updatedAt
            classification
            reviews {
              description
              updatedAt
            }
          }
          dealerShops {
            isOwner
            contractStatus
          }
        }
      }
      shops_aggregate(where:{isOwner:{_eq: true}, _and:[{shop:{acl_campaigns:{
      campaign: {campaignType:{_eq: "RA_CAMPAIGN"}}, requestStatus: {_in: $status}}}},
        {_or: [{shop: {name: {_ilike: $search}}}, {shop: {phoneNumber: {_ilike: $search}}},
          {shop: {email: {_ilike: $search}}}]}]}) {
        aggregate {
          count
        }
      }
    }
  }
  `,
  GET_LIST_MANAGE_EXPORT: gql`
  query GetManageExport($type: jsonb, $limit: Int!, $offset: Int!) {
    export_acl_campaign_reports (where:{filter:{_contains: $type}},
      limit: $limit, offset: $offset, order_by: {createdAt: desc}) {
      createdAt
      createdBy
      exportStatus
      failedReasons
      id
      isReindex
      status
      url
      createdUser {
        name
      }
    }
    export_acl_campaign_reports_aggregate(where:{filter:{_contains: $type}}) {
      aggregate {
        count
      }
    }
  }
  `,
  APRROVE_SHOP: gql`
  mutation APRROVE_SHOP($ids: [uuid!], $action: String!, $reason: String, $updatedBy: uuid!){
    update_acl_campaigns(where: {id: {_in: $ids}},
      _set: {requestStatus: $action, reason: $reason, updatedBy: $updatedBy}) {
      affected_rows
    }
  }`,
  REQUEST_REUP: gql`
  mutation REQUEST_REUP($ids: [uuid!], $action: String!, $reason: String, $updatedBy: uuid!){
    update_acl_campaigns(where: {id: {_in: $ids}},
      _set: {requestStatus: $action, reason: $reason, updatedBy: $updatedBy,
        isBranchReview: true, isDealerReUp: false}) {
      affected_rows
    }
  }`,
  GET_SHOP: gql`
  query GET_SHOP_CAMPAIGN_DETAIL($id: uuid!) {
    shops(where: {id: {_eq: $id}}) {
      address
      email
      name
      shopCode
      phoneNumber
      dealerShops(where:{isOwner: {_eq: true}}) {
        isOwner
        contractStatus
        dealer {
          role {
            alias
          }
          phoneNumber
          name
          dealerCode
          information {
            requestedRewardReceiptURLs
            data
          }
        }
      }
      acl_campaigns(where: {campaign: {campaignType: {_eq: "RA_CAMPAIGN"}}}) {
        dealer {
          id
          name
        }
        id
        packages
        pictureURLs
        requestStatus
        confirmationCode
        classification
        model1
        model2
        model3
        branchRequestReUpCount
        isBranchReview
        isDealerReUp
        reviews {
          createdAt
          forceConfirmationCode
          createdUser {
            name
          }
          classification
          updatedAt
          updatedUser {
            name
          }
          description
        }
        updatedAt
        createdAt
        logs(order_by: {createdAt: desc}) {
          requestStatus
          submittedAt
          updatedAt
          createdUser{
            name
          }
          reason
          dealer {
            name
          }
        }
      }
    }
  }
  `,
  GET_PDF_EXPORT_DATA: gql`
  query getDetailCampaignById($id: uuid!) {
    acl_campaigns(where: {id: {_eq: $id}, campaign: {campaignType: {_eq: "RA_CAMPAIGN"}}}) {
    campaign {
    campaignCode
    aclCampaignConfig {
      reviewEndDate
      reviewStartDate
      uploadStartDate
      uploadEndDate
    }
    target
    title
    condition {
      description
      name
    }
    dealerRoles
    metadata
    }
    dealer {
      name
      publicCode
      dealerCode
      taxNumber
      address
      ownerName
      ownerPosition
      shortName
      role {
        alias
      }
      assignedSubDealers(where: {status: {_eq: "active"}}) {
        dealer {
          name
          dealerCode
          taxNumber
          ownerName
          ownerPosition
          address
        }
      }
    }
    shop {
      name
      shopCode
      address
    }
    packages
    model1
    model2
    model3
    pictureURLs
    resizedPictureURLs
    reason
    requestStatus
    updatedAt
    reviews(order_by: {createdAt: desc}, limit: 1) {
      createdUser {
        name
      }
    }
    updatedUser {
        name
        }
      }
    }
  `,
  SUBMIT_REVIEW: gql`
  mutation SUBMIT_REVIEW($id: uuid, $review: String, $createdBy: uuid, $qualify: String!, $force: Boolean!){
    update_acl_campaigns(where: {id: {_eq: $id}}, _set: {updatedBy: $createdBy, forceConfirmationCode: $force,
      classification: $qualify}) {
      affected_rows
      __typename
    }
    insert_acl_campaign_reviews(objects: {campaignId: $id, description: $review,
      classification: $qualify, forceConfirmationCode: $force, createdBy: $createdBy, updatedBy: $createdBy}) {
      affected_rows
    }
  }`,
  GET_PACKAGE: gql`
  query GET_PACKAGE {
    campaigns(where: {campaignType : {_eq: "RA_CAMPAIGN"}}) {
      metadata
      startDate
      endDate
      isEnded
    }
  }`,
  UPDATE_PACKAGE: gql`
  mutation UPDATE_PACKAGE($id: String!, $models: RACampaignModels){
    updateRAModels(form: {id: $id, models: $models})
  }`,
  GET_CONFIG: gql`
  query GET_CONFIG {
    campaigns(where:{campaignType:{_eq:"RA_CAMPAIGN"}}){
      metadata
      aclCampaignConfig {
        id
        uploadStartDate
        uploadEndDate
      }
    }
  }`,
  GET_MASK_CONFIG: gql`
  query GET_MASK_CONFIG($id: uuid!) {
    campaigns(where: {aclConfig: {_eq: $id}}) {
      isSpecial
      isEnded
      startDate
      endDate
    }
  }`,
  END_CAMPAIGN: gql`
  mutation END_CAMPAIGN($id: uuid!){
    update_campaigns(where: {aclConfig: {_eq: $id}}, _set: {isEnded : true}) {
      affected_rows
    }
  }`,
  MASK_CHANGE: gql`
  mutation MASK_CHANGE($id: uuid!, $mask: Boolean!){
    update_campaigns(where: {aclConfig: {_eq: $id}}, _set: {isSpecial : $mask}) {
      affected_rows
    }
  }`,
  GET_STATISTIC: gql`
  query GET_STATISTIC {
    joinedDealers: acl_campaigns_aggregate(where: {dealer:{branch:{isTest:{_eq: false}},
      role:{alias:{_eq:"daikin_dealer"}}},
    campaign: {campaignType: {_eq: "RA_CAMPAIGN"}}}) {
      aggregate {
        count(columns: dealerId, distinct: true)
      }
    }
    totalDealers: dealers_aggregate(where: {role: {alias: {_eq: "daikin_dealer"}}, branch:{isTest:{_eq: false}},
     status: {_eq: "active"}}) {
      aggregate {
        count(columns: id, distinct: true)
      }
    }
    joinedSubDealers: acl_campaigns_aggregate(where: {dealer:{branch:{isTest:{_eq: false}},
      role:{alias:{_eq:"sub_dealer"}}},
    campaign: {campaignType: {_eq: "RA_CAMPAIGN"}}}) {
      aggregate {
        count(columns: dealerId, distinct: true)
      }
    }
    totalSubDealers: dealers_aggregate(where: {role: {alias: {_eq: "sub_dealer"}}, branch:{isTest:{_eq: false}},
     status: {_eq: "active"}}) {
      aggregate {
        count(columns: id, distinct: true)
      }
    }
    joinedShops: acl_campaigns_aggregate(where: {dealer:{branch:{isTest:{_eq: false}}},
      campaign: {campaignType: {_eq: "RA_CAMPAIGN"}}}) {
      aggregate {
        count(columns: shopId, distinct: true)
      }
    }
    totalShops: shops_aggregate(where:{dealerShops:{isOwner:{_eq: true}}}) {
      aggregate {
        count(columns: id, distinct: true)
      }
    }
    totalCompleted: acl_campaigns_aggregate(where: {dealer:{branch:{isTest:{_eq: false}}},
    campaign: {campaignType: {_eq: "RA_CAMPAIGN"}},
    requestStatus: {_in: ["approved","rejected"]}}) {
      aggregate {
        count(columns: shopId, distinct: true)
      }
    }
    totalIncompleted: acl_campaigns_aggregate(where: {dealer:{branch:{isTest:{_eq: false}}},
    campaign: {campaignType: {_eq: "RA_CAMPAIGN"}},
    requestStatus: {_nin: ["approved","rejected"]}}) {
      aggregate {
        count(columns: shopId, distinct: true)
      }
    }
    totalRejected: acl_campaigns_aggregate(where: {dealer:{branch:{isTest:{_eq: false}}}
    campaign: {campaignType: {_eq: "RA_CAMPAIGN"}},
    requestStatus: {_eq: "rejected"}}) {
      aggregate {
        count(columns: shopId, distinct: true)
      }
    }
    requests: acl_campaign_status_logs(where: {aclCampaign: {campaign: {campaignType: {_eq: "RA_CAMPAIGN"}},
    dealer: {branch: {isTest: {_eq: false}}}}}) {
      requestStatus
      createdAt
      updatedAt
      campaignId
      dealer {
        role {
          alias
        }
      }
    }
    config: acl_campaign_configurations {
      uploadStartDate
      uploadEndDate
    }
    configCampaign: campaigns(where:{campaignType:{_eq:"RA_CAMPAIGN"}}){
      aclCampaignConfig {
        id
        uploadStartDate
        uploadEndDate
      }
    }
    modelFTKA: acl_campaigns_aggregate(where: {dealer:{branch:{isTest:{_eq: false}}},
      campaign: {campaignType: {_eq: "RA_CAMPAIGN"}},
    _or: [{model1: {_ilike: "FTKA%"}},{model2: {_ilike: "FTKA%"}},{model3: {_ilike: "FTKA%"}}]}) {
      aggregate {
        count
      }
    }
    modelFTKB1: acl_campaigns_aggregate(where: {dealer:{branch:{isTest:{_eq: false}}},
    campaign: {campaignType: {_eq: "RA_CAMPAIGN"}},
    _or: [{model1: {_ilike: "FTKB%"}}]}) {
      aggregate {
        count
      }
    }
    modelFTKB2: acl_campaigns_aggregate(where: {dealer:{branch:{isTest:{_eq: false}}},
      campaign: {campaignType: {_eq: "RA_CAMPAIGN"}},
      _or: [{model3: {_ilike: "FTKB%"}}]}) {
        aggregate {
          count
        }
      }
    modelFTKY1: acl_campaigns_aggregate(where: {dealer:{branch:{isTest:{_eq: false}}},
      campaign: {campaignType: {_eq: "RA_CAMPAIGN"}},
    _or: [{model2: {_ilike: "FTKY%"}}]}) {
      aggregate {
        count
      }
    }
    modelFTKY2: acl_campaigns_aggregate(where: {dealer:{branch:{isTest:{_eq: false}}},
      campaign: {campaignType: {_eq: "RA_CAMPAIGN"}},
    _or: [{model3: {_ilike: "FTKY%"}}]}) {
      aggregate {
        count
      }
    }
    modelFTKC1: acl_campaigns_aggregate(where: {dealer:{branch:{isTest:{_eq: false}}},
      campaign: {campaignType: {_eq: "RA_CAMPAIGN"}},
    _or: [{model2: {_ilike: "FTKC%"}}]}) {
      aggregate {
        count
      }
    }
    modelFTKC2: acl_campaigns_aggregate(where: {dealer:{branch:{isTest:{_eq: false}}},
      campaign: {campaignType: {_eq: "RA_CAMPAIGN"}},
    _or: [{model3: {_ilike: "FTKC%"}}]}) {
      aggregate {
        count
      }
    }
    totalModel: acl_campaigns_aggregate(where: {dealer:{branch:{isTest:{_eq: false}}},
      campaign: {campaignType: {_eq: "RA_CAMPAIGN"}},
    model1: {_is_null: false}, model2: {_is_null: false}, model3: {_is_null: false}}) {
      aggregate {
        count
      }
    }
    labelBranch: branches(where: {isTest:{_eq: false},status: {_eq: "active"}}) {
      id
      name
    }
  }
  `,
  EXPORT_STATISTIC: gql`
  mutation EXPORT_STATISTIC($id: uuid!, $filter: jsonb) {
    insert_export_acl_campaign_reports(objects: { createdBy: $id, filter: $filter}) {
      affected_rows
    }
  }`,
  GET_QUALIFIED_SHOP: gql`
  query GET_QUALIFIED_SHOP($limit:Int, $offset: Int) {
    acl_campaigns(where: {campaign: {campaignType: {_eq: "RA_CAMPAIGN"}}, classification: {_eq: "qualified"},
    requestStatus: {_in: ["waiting_for_review","request_re_up"]}}, limit: $limit, offset: $offset) {
      id
      confirmationCode
      forceConfirmationCode
      dealer {
        id
        name
        role {
          alias
        }
        assignedSubDealers {
          dealer {
            name
            dealerCode
          }
        }
      }
      reviews {
        id
        createdAt
        updatedAt
        description
      }
      shop {
        id
        name
        address
        dealerShops{
          isOwner
          contractStatus
        }
      }
      requestStatus
    }
    acl_campaigns_aggregate(where: {campaign: {campaignType: {_eq: "RA_CAMPAIGN"}}, classification: {_eq: "qualified"},
    requestStatus: {_in: ["waiting_for_review","request_re_up"]}}) {
      aggregate {
        count
      }
    }
  }
  `,
  APPROVE_ALL: gql`
  mutation APPROVE_ALL($id: uuid!){
    update_acl_campaigns(where: {campaign: {campaignType: {_eq: "RA_CAMPAIGN"}}, classification: {_eq: "qualified"}},
      _set: {requestStatus: "approved", updatedBy: $id}) {
      affected_rows
    }
  }`,
  GET_ACTION_HISTORY: gql`
    subscription GET_ACTION_HISTORY ($id: uuid!) {
      acl_campaign_status_logs(order_by: {createdAt: desc}, where: {campaignId: {_eq: $id}}) {
        requestStatus
          submittedAt
          updatedAt
          createdUser{
            name
          }
          reason
          dealer {
            name
          }
      }
    }
  `,
  GET_DEALER_BY_BRANCH: gql`
  query GET_DEALER_BY_BRANCH ($name: uuid!) {
    total: dealers_aggregate(where: {branch: {id: {_eq: $name}}}) {
      aggregate {
        count
      }
    }
    joinSub: acl_campaigns_aggregate(where: {campaign: {campaignType: {_eq: "RA_CAMPAIGN"}},
    dealer: {branch: {id: {_eq: $name}},role: {alias: {_eq: "sub_dealer"}}}}) {
      aggregate {
        count(columns: dealerId, distinct: true)
      }
    }
    joinDealer: acl_campaigns_aggregate(where: {dealer:{branch:{id: {_eq: $name}},
    role:{alias:{_eq:"daikin_dealer"}}}, campaign: {campaignType: {_eq: "RA_CAMPAIGN"}}}) {
      aggregate {
        count(columns: dealerId, distinct: true)
      }
    }
  }
  `,
  GET_LIST_JOINED_SHOP: gql`
  query GET_JOINED_SHOP_LIST($search: String, $branch: [uuid!],
     $saleFilter: [String!], $branchFilter: [String!], $limit:Int, $offset: Int) {
    acl_campaigns(where: {_and:[{dealer:{branchId:{_in: $branch}}}, {requestStatus: {_in: $saleFilter}},
      {classification: {_in: $branchFilter}}
      {_or: [{shop: {name: {_ilike: $search}}},{shop:{shopCode:{_ilike : $search}}},
        {dealer:{name:{_ilike : $search}}},{dealer:{dealerCode:{_ilike : $search}}}]}]},
        limit: $limit, offset: $offset) {
      id
      dealer {
        id
        name
        publicCode
        dealerCode
        taxNumber
        address
        ownerName
        ownerPosition
        shortName
      }
      shop {
        id
        name
        shopCode
        address
      }
      packages
      pictureURLs
      resizedPictureURLs
      reason
      requestStatus
      reviews {
        classification
        createdAt
        description
      }
      classification
      requestStatus
      shopId
    }
    acl_campaigns_aggregate(where: {_and:[{dealer:{branchId:{_in: $branch}}}, {requestStatus: {_in: $saleFilter}},
      {classification: {_in: $branchFilter}}
      {_or: [{shop: {name: {_ilike: $search}}},{shop:{shopCode:{_ilike : $search}}},
        {dealer:{name:{_ilike : $search}}},{dealer:{dealerCode:{_ilike : $search}}}]}]}) {
      aggregate {
        count
      }
    }
  }
  `,
  GET_LIST_JOINED_SHOP_NOT_REVIEW: gql`
  query GET_LIST_JOINED_SHOP_NOT_REVIEW($search: String, $branch: [uuid!],
     $saleFilter: [String!], $branchFilter: [String!], $limit:Int, $offset: Int) {
    acl_campaigns(where: {_and: [{dealer: {branchId: {_in: $branch}}}, {requestStatus: {_in: $saleFilter}},
      {_or:[{classification: {_in: $branchFilter}}, {_not: {reviews: {}}}]}, {_or:
      [{shop: {name: {_ilike: $search}}},{shop:{shopCode:{_ilike : $search}}},
        {dealer:{name:{_ilike : $search}}},{dealer:{dealerCode:{_ilike : $search}}}]}]},
        limit: $limit, offset: $offset) {
      id
      dealer {
        id
        name
        publicCode
        dealerCode
        taxNumber
        address
        ownerName
        ownerPosition
        shortName
      }
      shop {
        id
        name
        shopCode
        address
      }
      packages
      pictureURLs
      resizedPictureURLs
      reason
      requestStatus
      reviews {
        classification
        createdAt
        description
      }
      classification
      requestStatus
      shopId
    }
    acl_campaigns_aggregate(where: {_and: [{dealer: {branchId: {_in: $branch}}}, {requestStatus: {_in: $saleFilter}},
      {_or:[{classification: {_in: $branchFilter}}, {_not: {reviews: {}}}]}, {_or:
      [{shop: {name: {_ilike: $search}}},{shop:{shopCode:{_ilike : $search}}},
        {dealer:{name:{_ilike : $search}}},{dealer:{dealerCode:{_ilike : $search}}}]}]}) {
      aggregate {
        count
      }
    }
  }
  `,
  GET_CONFIG_CROP_PICTURE: gql`
  query GET_CONFIG_CROP_PICTURE {
    system_configurations(where: {variableName: {_eq: "ACL_CAMPAIGN_CROP_PICTURE"}}){
      variableName
      value
    }
  }`,
  GET_LIST_SHOPS_NOT_REVIEW: gql`
  query GET_LIST_SHOPS_NOT_REVIEW($type: String, $search: String, $status:[String!], $branch: [uuid!],
    $saleFilter: [String!], $branchFilter: [String!], $limit:Int, $offset: Int) {
      shops(where: {_and:[{acl_campaigns:{campaign: {campaignType: {_eq: "RA_CAMPAIGN"}},
      requestStatus: {_in: $saleFilter}}}, {acl_campaigns:{confirmationCode:{_ilike:$type}}},
        {dealerShops:{dealer: {branchId: {_in: $branch}}}},
        {_or:[{acl_campaigns:{campaign: {campaignType: {_eq: "RA_CAMPAIGN"}}, classification: {_in: $branchFilter}}},
        {_not: {acl_campaigns:{campaign: {campaignType: {_nin: ["ACL_CAMPAIGN"]}}, reviews: {}}}}]},
        {_or:[{dealerShops:{shop: {name: {_ilike: $search}}}},{dealerShops:{shop:{shopCode:{_ilike : $search}}}},
        {dealerShops:{dealer:{name:{_ilike : $search}}}},{dealerShops:{dealer:{dealerCode:{_ilike : $search}}}}]}
        ]},
        limit: $limit, offset: $offset) {
        name
        shopCode
        address
        id
        dealerShops(where: {isOwner: {_eq: true}}) {
          isOwner
          dealer {
            id
            name
            dealerCode
            role {
              alias
            }
          }
        }
        acl_campaigns(where:{campaign:{campaignType:{_eq:"RA_CAMPAIGN"}}}) {
          id
          dealer {
            id
            name
            publicCode
            dealerCode
            taxNumber
            address
            ownerName
            ownerPosition
            shortName
            role {
              alias
            }
          }
          isDealerConfirm
          shop {
            id
            name
            shopCode
            address
          }
          confirmationCode
          forceConfirmationCode
          packages
          pictureURLs
          resizedPictureURLs
          reason
          requestStatus
          reviews {
            id
            classification
            createdAt
            description
          }
          classification
          requestStatus
          shopId
        }
      }
      shops_aggregate(where: {_and:[{acl_campaigns:{campaign: {campaignType: {_eq: "RA_CAMPAIGN"}},
      requestStatus: {_in: $saleFilter}}}, {acl_campaigns:{confirmationCode:{_ilike:$type}}},
        {dealerShops:{dealer: {branchId: {_in: $branch}}}},
        {_or:[{acl_campaigns:{campaign: {campaignType: {_eq: "RA_CAMPAIGN"}}, classification: {_in: $branchFilter}}},
        {_not: {acl_campaigns:{campaign: {campaignType: {_nin: ["ACL_CAMPAIGN"]}}, reviews: {}}}}]},
        {_or:[{dealerShops:{shop: {name: {_ilike: $search}}}},{dealerShops:{shop:{shopCode:{_ilike : $search}}}},
        {dealerShops:{dealer:{name:{_ilike : $search}}}},{dealerShops:{dealer:{dealerCode:{_ilike : $search}}}}]}
        ]}) {
        aggregate {
          count
        }
      }
    }
  `,
  GET_LIST_SHOPS_NOT_REVIEW_NOT_TYPE: gql`
  query GET_LIST_SHOPS_NOT_REVIEW($search: String, $status:[String!], $branch: [uuid!],
    $saleFilter: [String!], $branchFilter: [String!], $limit:Int, $offset: Int) {
      shops(where: {_and:[{acl_campaigns:{campaign: {campaignType: {_eq: "RA_CAMPAIGN"}},
      requestStatus: {_in: $saleFilter}}},
        {dealerShops:{dealer: {branchId: {_in: $branch}}}},
        {_or:[{acl_campaigns:{campaign: {campaignType: {_eq: "RA_CAMPAIGN"}}, classification: {_in: $branchFilter}}},
        {_not: {acl_campaigns:{campaign: {campaignType: {_nin: ["ACL_CAMPAIGN"]}}, reviews: {}}}}]},
        {_or:[{dealerShops:{shop: {name: {_ilike: $search}}}},{dealerShops:{shop:{shopCode:{_ilike : $search}}}},
        {dealerShops:{dealer:{name:{_ilike : $search}}}},{dealerShops:{dealer:{dealerCode:{_ilike : $search}}}}]}
        ]},
        limit: $limit, offset: $offset) {
        name
        shopCode
        address
        id
        dealerShops(where: {isOwner: {_eq: true}}) {
          isOwner
          dealer {
            id
            name
            dealerCode
            role {
              alias
            }
          }
        }
        acl_campaigns(where:{campaign:{campaignType:{_eq:"RA_CAMPAIGN"}}}) {
          id
          dealer {
            id
            name
            publicCode
            dealerCode
            taxNumber
            address
            ownerName
            ownerPosition
            shortName
            role {
              alias
            }
          }
          isDealerConfirm
          shop {
            id
            name
            shopCode
            address
          }
          confirmationCode
          forceConfirmationCode
          packages
          pictureURLs
          resizedPictureURLs
          reason
          requestStatus
          reviews {
            id
            classification
            createdAt
            description
          }
          classification
          requestStatus
          shopId
        }
      }
      shops_aggregate(where: {_and:[{acl_campaigns:{campaign: {campaignType: {_eq: "RA_CAMPAIGN"}},
      requestStatus: {_in: $saleFilter}}},
        {dealerShops:{dealer: {branchId: {_in: $branch}}}},
        {_or:[{acl_campaigns:{campaign: {campaignType: {_eq: "RA_CAMPAIGN"}}, classification: {_in: $branchFilter}}},
        {_not: {acl_campaigns:{campaign: {campaignType: {_nin: ["ACL_CAMPAIGN"]}}, reviews: {}}}}]},
        {_or:[{dealerShops:{shop: {name: {_ilike: $search}}}},{dealerShops:{shop:{shopCode:{_ilike : $search}}}},
        {dealerShops:{dealer:{name:{_ilike : $search}}}},{dealerShops:{dealer:{dealerCode:{_ilike : $search}}}}]}
        ]}) {
        aggregate {
          count
        }
      }
    }
  `,
  GET_LIST_SHOPS_NOT_JOIN_NOT_REIVEW: gql`
  query GET_LIST_SHOPS_NOT_JOIN_NOT_REIVEW($type: String, $search: String, $status:[String!], $branch: [uuid!],
    $saleFilter: [String!], $branchFilter: [String!], $limit:Int, $offset: Int) {
      shops(where: {_and:[{_or:[{_and:[{_not:{acl_campaigns:{campaign:
        {campaignType: {_nin: ["ACL_CAMPAIGN"]}}}}},{dealerShops:{isOwner:{_eq: true}}}]},
        {acl_campaigns:{campaign: {campaignType: {_eq: "RA_CAMPAIGN"}}, requestStatus: {_in: $saleFilter}}}]},
        {acl_campaigns:{confirmationCode:{_ilike:$type}}},
        {dealerShops:{dealer: {branchId: {_in: $branch}}}},
        {_or:[{acl_campaigns:{campaign: {campaignType: {_eq: "RA_CAMPAIGN"}},
        classification: {_in: $branchFilter}}}, {_not: {acl_campaigns:{campaign:{campaignType:{_nin: ["ACL_CAMPAIGN"]}},
        reviews: {}}}}]}, {_or:
        [{dealerShops:{shop: {name: {_ilike: $search}}}},{dealerShops:{shop:{shopCode:{_ilike : $search}}}},
        {dealerShops:{dealer:{name:{_ilike : $search}}}},{dealerShops:{dealer:{dealerCode:{_ilike : $search}}}}]}
        ]},
        limit: $limit, offset: $offset) {
        name
        shopCode
        address
        id
        dealerShops(where: {isOwner: {_eq: true}}) {
          isOwner
          dealer {
            id
            name
            dealerCode
            role {
              alias
            }
          }
        }
        acl_campaigns(where:{campaign:{campaignType:{_eq:"RA_CAMPAIGN"}}}) {
          id
          dealer {
            name
            id
            publicCode
            dealerCode
            taxNumber
            address
            ownerName
            ownerPosition
            shortName
            role {
              alias
            }
          }
          isDealerConfirm
          shop {
            id
            name
            shopCode
            address
          }
          confirmationCode
          forceConfirmationCode
          packages
          pictureURLs
          resizedPictureURLs
          reason
          requestStatus
          reviews {
            id
            classification
            createdAt
            description
          }
          classification
          requestStatus
          shopId
        }
      }
      shops_aggregate(where: {_and:[{_or:[{_and:[{_not:{acl_campaigns:{campaign:
        {campaignType: {_nin: ["ACL_CAMPAIGN"]}}}}},{dealerShops:{isOwner:{_eq: true}}}]},
        {acl_campaigns:{campaign: {campaignType: {_eq: "RA_CAMPAIGN"}}, requestStatus: {_in: $saleFilter}}}]},
        {acl_campaigns:{confirmationCode:{_ilike:$type}}},
        {dealerShops:{dealer: {branchId: {_in: $branch}}}},
        {_or:[{acl_campaigns:{campaign: {campaignType: {_eq: "RA_CAMPAIGN"}},
        classification: {_in: $branchFilter}}}, {_not: {acl_campaigns:{campaign:{campaignType:{_nin: ["ACL_CAMPAIGN"]}},
        reviews: {}}}}]}, {_or:
        [{dealerShops:{shop: {name: {_ilike: $search}}}},{dealerShops:{shop:{shopCode:{_ilike : $search}}}},
        {dealerShops:{dealer:{name:{_ilike : $search}}}},{dealerShops:{dealer:{dealerCode:{_ilike : $search}}}}]}
        ]}) {
        aggregate {
          count
        }
      }
    }
  `,
  GET_LIST_SHOPS_NOT_JOIN_NOT_REIVEW_NOT_TYPE: gql`
  query GET_LIST_SHOPS_NOT_JOIN_NOT_REIVEW($search: String, $status:[String!], $branch: [uuid!],
    $saleFilter: [String!], $branchFilter: [String!], $limit:Int, $offset: Int) {
      shops(where: {_and:[{_or:[{_and:[{_not:{acl_campaigns:{campaign:
        {campaignType: {_nin: ["ACL_CAMPAIGN"]}}}}},{dealerShops:{isOwner:{_eq: true}}}]},
        {acl_campaigns:{campaign: {campaignType: {_eq: "RA_CAMPAIGN"}}, requestStatus: {_in: $saleFilter}}}]},
        {dealerShops:{dealer: {branchId: {_in: $branch}}}},
        {_or:[{acl_campaigns:{campaign: {campaignType: {_eq: "RA_CAMPAIGN"}},
        classification: {_in: $branchFilter}}}, {_not: {acl_campaigns:{campaign:{campaignType:{_nin: ["ACL_CAMPAIGN"]}},
        reviews: {}}}}]}, {_or:
        [{dealerShops:{shop: {name: {_ilike: $search}}}},{dealerShops:{shop:{shopCode:{_ilike : $search}}}},
        {dealerShops:{dealer:{name:{_ilike : $search}}}},{dealerShops:{dealer:{dealerCode:{_ilike : $search}}}}]}
        ]},
        limit: $limit, offset: $offset) {
        name
        shopCode
        address
        id
        dealerShops(where: {isOwner: {_eq: true}}) {
          isOwner
          dealer {
            id
            name
            dealerCode
            role {
              alias
            }
          }
        }
        acl_campaigns(where:{campaign:{campaignType:{_eq:"RA_CAMPAIGN"}}}) {
          id
          dealer {
            name
            id
            publicCode
            dealerCode
            taxNumber
            address
            ownerName
            ownerPosition
            shortName
            role {
              alias
            }
          }
          isDealerConfirm
          shop {
            id
            name
            shopCode
            address
          }
          confirmationCode
          forceConfirmationCode
          packages
          pictureURLs
          resizedPictureURLs
          reason
          requestStatus
          reviews {
            id
            classification
            createdAt
            description
          }
          classification
          requestStatus
          shopId
        }
      }
      shops_aggregate(where: {_and:[{_or:[{_and:[{_not:{acl_campaigns:{campaign:
        {campaignType: {_nin: ["ACL_CAMPAIGN"]}}}}},{dealerShops:{isOwner:{_eq: true}}}]},
        {acl_campaigns:{campaign: {campaignType: {_eq: "RA_CAMPAIGN"}}, requestStatus: {_in: $saleFilter}}}]},
        {dealerShops:{dealer: {branchId: {_in: $branch}}}},
        {_or:[{acl_campaigns:{campaign: {campaignType: {_eq: "RA_CAMPAIGN"}},
        classification: {_in: $branchFilter}}}, {_not: {acl_campaigns:{campaign:{campaignType:{_nin: ["ACL_CAMPAIGN"]}},
        reviews: {}}}}]}, {_or:
        [{dealerShops:{shop: {name: {_ilike: $search}}}},{dealerShops:{shop:{shopCode:{_ilike : $search}}}},
        {dealerShops:{dealer:{name:{_ilike : $search}}}},{dealerShops:{dealer:{dealerCode:{_ilike : $search}}}}]}
        ]}) {
        aggregate {
          count
        }
      }
    }
  `,
  GET_LIST_SHOPS_NOT_JOIN: gql`
  query GET_LIST_SHOPS_NOT_JOIN($type: String, $search: String, $status:[String!], $branch: [uuid!],
    $saleFilter: [String!], $branchFilter: [String!], $limit:Int, $offset: Int) {
      shops(where: {_and:[{_or:[{_and:[{_not:{acl_campaigns:{campaign: {campaignType: {_nin: ["ACL_CAMPAIGN"]}}}}},
      {dealerShops:{isOwner:{_eq: true}}}]},
        {acl_campaigns:{campaign: {campaignType: {_eq: "RA_CAMPAIGN"}}, requestStatus: {_in: $saleFilter}}}]},
        {acl_campaigns:{confirmationCode:{_ilike:$type}}},
        {dealerShops:{dealer: {branchId: {_in: $branch}}}},
        {acl_campaigns:{campaign: {campaignType: {_eq: "RA_CAMPAIGN"}}, classification: {_in: $branchFilter}}}, {_or:
        [{dealerShops:{shop: {name: {_ilike: $search}}}},{dealerShops:{shop:{shopCode:{_ilike : $search}}}},
        {dealerShops:{dealer:{name:{_ilike : $search}}}},{dealerShops:{dealer:{dealerCode:{_ilike : $search}}}}]}
        ]},
        limit: $limit, offset: $offset) {
        name
        shopCode
        id
        address
        dealerShops(where: {isOwner: {_eq: true}}) {
          isOwner
          dealer {
            id
            name
            dealerCode
            role {
              alias
            }
          }
        }
        acl_campaigns(where:{campaign:{campaignType:{_eq:"RA_CAMPAIGN"}}}) {
          id
          dealer {
            id
            name
            publicCode
            dealerCode
            taxNumber
            address
            ownerName
            ownerPosition
            shortName
            role {
              alias
            }
          }
          isDealerConfirm
          shop {
            id
            name
            shopCode
            address
          }
          confirmationCode
          forceConfirmationCode
          packages
          pictureURLs
          resizedPictureURLs
          reason
          requestStatus
          reviews {
            id
            classification
            createdAt
            description
          }
          classification
          requestStatus
          shopId
        }
      }
      shops_aggregate(where: {_and:[{_or:[{_and:[{_not:{acl_campaigns:{campaign:
        {campaignType: {_nin: ["ACL_CAMPAIGN"]}}}}},
      {dealerShops:{isOwner:{_eq: true}}}]},
        {acl_campaigns:{campaign: {campaignType: {_eq: "RA_CAMPAIGN"}}, requestStatus: {_in: $saleFilter}}}]},
        {acl_campaigns:{confirmationCode:{_ilike:$type}}},
        {dealerShops:{dealer: {branchId: {_in: $branch}}}},
        {acl_campaigns:{campaign: {campaignType: {_eq: "RA_CAMPAIGN"}}, classification: {_in: $branchFilter}}}, {_or:
        [{dealerShops:{shop: {name: {_ilike: $search}}}},{dealerShops:{shop:{shopCode:{_ilike : $search}}}},
        {dealerShops:{dealer:{name:{_ilike : $search}}}},{dealerShops:{dealer:{dealerCode:{_ilike : $search}}}}]}
        ]}) {
        aggregate {
          count
        }
      }
    }
  `,
  GET_LIST_SHOPS_NOT_JOIN_NOT_TYPE: gql`
  query GET_LIST_SHOPS_NOT_JOIN($search: String, $status:[String!], $branch: [uuid!],
    $saleFilter: [String!], $branchFilter: [String!], $limit:Int, $offset: Int) {
      shops(where: {_and:[{_or:[{_and:[{_not:{acl_campaigns:{campaign: {campaignType: {_nin: ["ACL_CAMPAIGN"]}}}}},
      {dealerShops:{isOwner:{_eq: true}}}]},
        {acl_campaigns:{campaign: {campaignType: {_eq: "RA_CAMPAIGN"}}, requestStatus: {_in: $saleFilter}}}]},
        {dealerShops:{dealer: {branchId: {_in: $branch}}}},
        {acl_campaigns:{campaign: {campaignType: {_eq: "RA_CAMPAIGN"}}, classification: {_in: $branchFilter}}}, {_or:
        [{dealerShops:{shop: {name: {_ilike: $search}}}},{dealerShops:{shop:{shopCode:{_ilike : $search}}}},
        {dealerShops:{dealer:{name:{_ilike : $search}}}},{dealerShops:{dealer:{dealerCode:{_ilike : $search}}}}]}
        ]},
        limit: $limit, offset: $offset) {
        name
        shopCode
        id
        address
        dealerShops(where: {isOwner: {_eq: true}}) {
          isOwner
          dealer {
            id
            name
            dealerCode
            role {
              alias
            }
          }
        }
        acl_campaigns(where:{campaign:{campaignType:{_eq:"RA_CAMPAIGN"}}}) {
          id
          dealer {
            id
            name
            publicCode
            dealerCode
            taxNumber
            address
            ownerName
            ownerPosition
            shortName
            role {
              alias
            }
          }
          isDealerConfirm
          shop {
            id
            name
            shopCode
            address
          }
          confirmationCode
          forceConfirmationCode
          packages
          pictureURLs
          resizedPictureURLs
          reason
          requestStatus
          reviews {
            id
            classification
            createdAt
            description
          }
          classification
          requestStatus
          shopId
        }
      }
      shops_aggregate(where: {_and:[{_or:[{_and:[{_not:{acl_campaigns:{campaign:
        {campaignType: {_nin: ["ACL_CAMPAIGN"]}}}}},
      {dealerShops:{isOwner:{_eq: true}}}]},
        {acl_campaigns:{campaign: {campaignType: {_eq: "RA_CAMPAIGN"}}, requestStatus: {_in: $saleFilter}}}]},
        {dealerShops:{dealer: {branchId: {_in: $branch}}}},
        {acl_campaigns:{campaign: {campaignType: {_eq: "RA_CAMPAIGN"}}, classification: {_in: $branchFilter}}}, {_or:
        [{dealerShops:{shop: {name: {_ilike: $search}}}},{dealerShops:{shop:{shopCode:{_ilike : $search}}}},
        {dealerShops:{dealer:{name:{_ilike : $search}}}},{dealerShops:{dealer:{dealerCode:{_ilike : $search}}}}]}
        ]}) {
        aggregate {
          count
        }
      }
    }
  `,
  GET_LIST_SHOPS: gql`
  query GET_LIST_SHOPS($type: String, $search: String, $status:[String!], $branch: [uuid!],
    $saleFilter: [String!], $branchFilter: [String!], $limit:Int, $offset: Int) {
      shops(where: {_and:[{acl_campaigns:{campaign: {campaignType: {_eq: "RA_CAMPAIGN"}},
      requestStatus: {_in: $saleFilter}}}, {acl_campaigns:{confirmationCode:{_ilike:$type}}},
        {dealerShops:{dealer: {branchId: {_in: $branch}}}},
        {acl_campaigns:{campaign: {campaignType: {_eq: "RA_CAMPAIGN"}}, classification: {_in: $branchFilter}}},
        {_or:[{dealerShops:{shop: {name: {_ilike: $search}}}},{dealerShops:{shop:{shopCode:{_ilike : $search}}}},
        {dealerShops:{dealer:{name:{_ilike : $search}}}},{dealerShops:{dealer:{dealerCode:{_ilike : $search}}}}]}
        ]},
        limit: $limit, offset: $offset) {
        name
        shopCode
        address
        id
        dealerShops(where: {isOwner: {_eq: true}}) {
          isOwner
          dealer {
            id
            name
            dealerCode
            role {
              alias
            }
          }
        }
        acl_campaigns(where:{campaign:{campaignType:{_eq:"RA_CAMPAIGN"}}}) {
          id
          dealer {
            id
            name
            publicCode
            dealerCode
            taxNumber
            address
            ownerName
            ownerPosition
            shortName
            role {
              alias
            }
          }
          isDealerConfirm
          shop {
            id
            name
            shopCode
            address
          }
          confirmationCode
          forceConfirmationCode
          packages
          pictureURLs
          resizedPictureURLs
          reason
          requestStatus
          reviews {
            id
            classification
            createdAt
            description
          }
          classification
          requestStatus
          shopId
        }
      }
      shops_aggregate(where: {_and:[{acl_campaigns:{campaign: {campaignType: {_eq: "RA_CAMPAIGN"}},
      requestStatus: {_in: $saleFilter}}}, {acl_campaigns:{confirmationCode:{_ilike:$type}}},
        {dealerShops:{dealer: {branchId: {_in: $branch}}}},
        {acl_campaigns:{campaign: {campaignType: {_eq: "RA_CAMPAIGN"}}, classification: {_in: $branchFilter}}},
        {_or:[{dealerShops:{shop: {name: {_ilike: $search}}}},{dealerShops:{shop:{shopCode:{_ilike : $search}}}},
        {dealerShops:{dealer:{name:{_ilike : $search}}}},{dealerShops:{dealer:{dealerCode:{_ilike : $search}}}}]}
        ]}) {
        aggregate {
          count
        }
      }
    }
  `,
  GET_LIST_SHOPS_NOT_TYPE: gql`
  query GET_LIST_SHOPS($search: String, $status:[String!], $branch: [uuid!],
    $saleFilter: [String!], $branchFilter: [String!], $limit:Int, $offset: Int) {
      shops(where: {_and:[{acl_campaigns:{campaign: {campaignType: {_eq: "RA_CAMPAIGN"}},
      requestStatus: {_in: $saleFilter}}},
        {dealerShops:{dealer: {branchId: {_in: $branch}}}},
        {acl_campaigns:{campaign: {campaignType: {_eq: "RA_CAMPAIGN"}}, classification: {_in: $branchFilter}}},
        {_or:[{dealerShops:{shop: {name: {_ilike: $search}}}},{dealerShops:{shop:{shopCode:{_ilike : $search}}}},
        {dealerShops:{dealer:{name:{_ilike : $search}}}},{dealerShops:{dealer:{dealerCode:{_ilike : $search}}}}]}
        ]},
        limit: $limit, offset: $offset) {
        name
        shopCode
        address
        id
        dealerShops(where: {isOwner: {_eq: true}}) {
          isOwner
          dealer {
            id
            name
            dealerCode
            role {
              alias
            }
          }
        }
        acl_campaigns(where:{campaign:{campaignType:{_eq:"RA_CAMPAIGN"}}}) {
          id
          dealer {
            id
            name
            publicCode
            dealerCode
            taxNumber
            address
            ownerName
            ownerPosition
            shortName
            role {
              alias
            }
          }
          isDealerConfirm
          shop {
            id
            name
            shopCode
            address
          }
          confirmationCode
          forceConfirmationCode
          packages
          pictureURLs
          resizedPictureURLs
          reason
          requestStatus
          reviews {
            id
            classification
            createdAt
            description
          }
          classification
          requestStatus
          shopId
        }
      }
      shops_aggregate(where: {_and:[{acl_campaigns:{campaign: {campaignType: {_eq: "RA_CAMPAIGN"}},
      requestStatus: {_in: $saleFilter}}},
        {dealerShops:{dealer: {branchId: {_in: $branch}}}},
        {acl_campaigns:{campaign: {campaignType: {_eq: "RA_CAMPAIGN"}}, classification: {_in: $branchFilter}}},
        {_or:[{dealerShops:{shop: {name: {_ilike: $search}}}},{dealerShops:{shop:{shopCode:{_ilike : $search}}}},
        {dealerShops:{dealer:{name:{_ilike : $search}}}},{dealerShops:{dealer:{dealerCode:{_ilike : $search}}}}]}
        ]}) {
        aggregate {
          count
        }
      }
    }
  `,
  GET_LIST_SUB_DEALER_REQUEST: gql`
  query GET_LIST_SUB_DEALER_REQUEST($limit:Int, $region: [uuid!], $branch: [uuid!] , $offset: Int, $search: String) {
    campaign_dealers(where:{_and: [{dealer:{region:{id:{_in: $region}}}},{dealer:{branch:{id:{_in: $branch}}}},
    {_or:[{dealer:{name:{_ilike:$search}}},{dealer:{dealerCode:{_ilike:$search}}}]}]},
      limit: $limit, offset: $offset) {
      id
      requestStatus
      dealer {
        name
      id
      dealerCode
      idCard
      idCardImage
      address
      phoneNumber
      branchId
      branch {
        id
        name
        branchCode
      }
      region {
        id
        name
      }
      information {
        businessHouseholdNo
        businessHouseholdPicURLs
        businessRegistrationNo
        businessRegistrationPicURLs
        productReceiptPicURLs
      }
      }
    }
    campaign_dealers_aggregate(where:{_and: [{dealer:{region:{id:{_in: $region}}}},
      {dealer:{branch:{id:{_in: $branch}}}},
      {_or:[{dealer:{name:{_ilike:$search}}},{dealer:{dealerCode:{_ilike:$search}}}]}]}){
      aggregate {
        count
      }
    }
  }
  `,
  GET_LIST_SUB_DEALER_REQUEST_BRANCH: gql`
  query GET_LIST_SUB_DEALER_REQUEST($limit:Int, $branch: [uuid!] , $offset: Int, $search: String) {
    campaign_dealers(where:{_and: [{dealer:{branch:{id:{_in: $branch}}}},
    {_or:[{dealer:{name:{_ilike:$search}}},{dealer:{dealerCode:{_ilike:$search}}}]}]},
      limit: $limit, offset: $offset) {
      id
      requestStatus
      dealer {
        name
      id
      dealerCode
      idCard
      idCardImage
      address
      phoneNumber
      branchId
      branch {
        id
        name
        branchCode
      }
      region {
        id
        name
      }
      information {
        businessHouseholdNo
        businessHouseholdPicURLs
        businessRegistrationNo
        businessRegistrationPicURLs
        productReceiptPicURLs
      }
      }
    }
    campaign_dealers_aggregate(where:{_and: [{dealer:{branch:{id:{_in: $branch}}}},
      {_or:[{dealer:{name:{_ilike:$search}}},{dealer:{dealerCode:{_ilike:$search}}}]}]}){
      aggregate {
        count
      }
    }
  }
  `,
  GET_LIST_SUB_DEALER_REQUEST_REGION: gql`
  query GET_LIST_SUB_DEALER_REQUEST($limit:Int, $region: [uuid!], $offset: Int, $search: String) {
    campaign_dealers(where:{_and: [{dealer:{region:{id:{_in: $region}}}},
    {_or:[{dealer:{name:{_ilike:$search}}},{dealer:{dealerCode:{_ilike:$search}}}]}]},
      limit: $limit, offset: $offset) {
      id
      requestStatus
      dealer {
        name
      id
      dealerCode
      idCard
      idCardImage
      address
      phoneNumber
      branchId
      branch {
        id
        name
        branchCode
      }
      region {
        id
        name
      }
      information {
        businessHouseholdNo
        businessHouseholdPicURLs
        businessRegistrationNo
        businessRegistrationPicURLs
        productReceiptPicURLs
      }
      }
    }
    campaign_dealers_aggregate(where:{_and: [{dealer:{region:{id:{_in: $region}}}},
      {_or:[{dealer:{name:{_ilike:$search}}},{dealer:{dealerCode:{_ilike:$search}}}]}]}){
      aggregate {
        count
      }
    }
  }
  `,
  GET_LIST_SUB_DEALER_REQUEST_ALL: gql`
  query GET_LIST_SUB_DEALER_REQUEST($limit:Int $offset: Int, $search: String) {
    campaign_dealers(where:{_and:[{_or:[{dealer:{name:{_ilike:$search}}},{dealer:{dealerCode:{_ilike:$search}}}]}]},
      limit: $limit, offset: $offset) {
      id
      requestStatus
      dealer {
        name
      id
      dealerCode
      idCard
      idCardImage
      address
      phoneNumber
      branchId
      branch {
        id
        name
        branchCode
      }
      region {
        id
        name
      }
      information {
        businessHouseholdNo
        businessHouseholdPicURLs
        businessRegistrationNo
        businessRegistrationPicURLs
        productReceiptPicURLs
      }
      }
    }
    campaign_dealers_aggregate(where:{_and: [{_or:[{dealer:{name:{_ilike:$search}}},
      {dealer:{dealerCode:{_ilike:$search}}}]}]}){
      aggregate {
        count
      }
    }
  }
  `,
  UPDATE_SUBDEALER_REQUEST: gql`
  mutation UPDATE_SUBDEALER_REQUEST($id: uuid!){
    update_campaign_dealers(where: {id:{_eq:$id}},
      _set: {requestStatus: "received"}) {
      affected_rows
    }
  }`,
  GET_LIST_CONFIRMATION_CODE: gql`
  query GET_LIST_CONFIRMATION_CODE($type: String, $search: String, $status:[String!], $limit:Int, $offset: Int,) {
    confirmation_codes(where:{_and:[{codeStatus:{_in:$status}},{code:{_ilike:$type}},
      {_or:[{dealer:{name:{_ilike:$search}}},
      {dealer:{dealerCode:{_ilike:$search}}},{code:{_ilike:$search}}]}]},
      limit: $limit, offset: $offset, order_by: {createdAt:desc}) {
      id
      code
      createdAt
      updatedAt
      createdBy
      codeStatus
      appliedCampaign {
        id
        shop {
          id
          name
          shopCode
        }
      }
      dealer {
        role {
          alias
        }
        assignedSubDealers(where: {status: {_eq: "active"}}) {
          dealer {
            name
            dealerCode
          }
        }
        name
        dealerCode
      }
    }
    confirmation_codes_aggregate(where:{_and:[{codeStatus:{_in:$status}},{code:{_ilike:$type}},
      {_or:[{dealer:{name:{_ilike:$search}}},
      {dealer:{dealerCode:{_ilike:$search}}},{code:{_ilike:$search}}]}]},) {
      aggregate {
        count
      }
    }
  }`,
  DOWNLOAD_CONFIRMATION_CODE: gql`
  mutation DOWNLOAD_CONFIRMATION_CODE {
    exportConfirmationCodeUrl(form:{})
  }`,
  GENERATE_CODE: gql`
  mutation GENERATE_CODE($codeType: String!, $quantity: Int!) {
    generateConfirmationCode(form: {codeType: $codeType, quantity: $quantity})
  }
  `,
  GET_LIST_SUB_NOT_REVIEW: gql`
  query GET_LIST_SUB_NOT_REVIEW($type: String, $search: String, $status:[String!], $branch: [uuid!],
    $saleFilter: [String!], $branchFilter: [String!], $limit:Int, $offset: Int) {
      dealers(where: {assignedSubDealers: {dealerId: {_is_null:false}},
        role: {alias: {_eq: "sub_dealer"}}, status: {_eq:"active"},_and:[{acl_campaigns:{campaign:
        {campaignType: {_eq: "RA_CAMPAIGN"}},
        confirmationCode: {_ilike: $type}, requestStatus: {_in: $saleFilter}}},
        {branchId:{_in: $branch}},{_or:[{acl_campaigns:{campaign: {campaignType: {_eq: "RA_CAMPAIGN"}},
        classification: {_in: $branchFilter}}}, {_not: {acl_campaigns:{campaign:{campaignType:{_nin: ["ACL_CAMPAIGN"]}},
        reviews: {}}}}]}, {_or: [{name: {_ilike: $search}},{dealerCode:{_ilike : $search}}]}]},
        limit: $limit, offset: $offset) {
        name
        address
        id
        dealerCode
        role {
          alias
        }
        assignedSubDealers {
          dealer {
            name
            dealerCode
          }
        }
        acl_campaigns(where:{campaign:{campaignType:{_eq:"RA_CAMPAIGN"}}}) {
          id
          dealer {
            name
            id
            publicCode
            dealerCode
            taxNumber
            address
            ownerName
            ownerPosition
            shortName
            role {
              alias
            }
          }
          isDealerConfirm
          shop {
            id
            name
            shopCode
            address
          }
          confirmationCode
          forceConfirmationCode
          packages
          pictureURLs
          resizedPictureURLs
          reason
          requestStatus
          reviews {
            id
            classification
            createdAt
            description
          }
          classification
          requestStatus
          shopId
        }
      }
      dealers_aggregate(where: {assignedSubDealers: {dealerId: {_is_null:false}},
        role: {alias: {_eq: "sub_dealer"}},status: {_eq:"active"},
      _and:[{acl_campaigns:{campaign:{campaignType: {_eq: "RA_CAMPAIGN"}},
      confirmationCode: {_ilike: $type}, requestStatus: {_in: $saleFilter}}},
        {branchId:{_in: $branch}},{_or:[{acl_campaigns:{campaign: {campaignType: {_eq: "RA_CAMPAIGN"}},
        classification: {_in: $branchFilter}}}, {_not: {acl_campaigns:{campaign:{campaignType:{_nin: ["ACL_CAMPAIGN"]}},
        reviews: {}}}}]}, {_or: [{name: {_ilike: $search}},{dealerCode:{_ilike : $search}}]}]},) {
        aggregate {
          count
        }
      }
    }
  `,
  GET_LIST_SUB_NOT_JOIN_NOT_REIVEW: gql`
  query GET_LIST_SUB_NOT_JOIN_NOT_REIVEW($type: String, $search: String, $status:[String!], $branch: [uuid!],
    $saleFilter: [String!], $branchFilter: [String!], $limit:Int, $offset: Int) {
      dealers(where: {assignedSubDealers: {dealerId: {_is_null:false}},
        role: {alias: {_eq: "sub_dealer"}},status: {_eq:"active"}, _and:[{_or:[{
        _not:{acl_campaigns:{campaign:{campaignType: {_nin: ["ACL_CAMPAIGN"]}}}}},
        {acl_campaigns:{campaign: {campaignType: {_eq: "RA_CAMPAIGN"}}, confirmationCode: {_ilike: $type},
         requestStatus: {_in: $saleFilter}}}]},
        {branchId:{_in: $branch}},{_or:[{acl_campaigns:{campaign: {campaignType: {_eq: "RA_CAMPAIGN"}},
        classification: {_in: $branchFilter}}}, {_not: {acl_campaigns:{campaign:{campaignType:{_nin: ["ACL_CAMPAIGN"]}},
        reviews: {}}}}]}, {_or: [{name: {_ilike: $search}},{dealerCode:{_ilike : $search}}]}]},
        limit: $limit, offset: $offset) {
        name
        address
        dealerCode
        id
        role {
          alias
        }
        assignedSubDealers {
          dealer {
            name
            dealerCode
          }
        }
        acl_campaigns(where:{campaign:{campaignType:{_eq:"RA_CAMPAIGN"}}}) {
          id
          dealer {
            name
            id
            publicCode
            dealerCode
            taxNumber
            address
            ownerName
            ownerPosition
            shortName
            role {
              alias
            }
          }
          isDealerConfirm
          shop {
            id
            name
            shopCode
            address
          }
          confirmationCode
          forceConfirmationCode
          packages
          pictureURLs
          resizedPictureURLs
          reason
          requestStatus
          reviews {
            id
            classification
            createdAt
            description
          }
          classification
          requestStatus
          shopId
        }
      }
      dealers_aggregate(where: {assignedSubDealers: {dealerId: {_is_null:false}},
        role: {alias: {_eq: "sub_dealer"}},status: {_eq:"active"}, _and:[{_or:[{
        _not:{acl_campaigns:{campaign:{campaignType: {_nin: ["ACL_CAMPAIGN"]}}}}},
        {acl_campaigns:{campaign: {campaignType: {_eq: "RA_CAMPAIGN"}}, confirmationCode: {_ilike: $type},
         requestStatus: {_in: $saleFilter}}}]},
        {branchId:{_in: $branch}},{_or:[{acl_campaigns:{campaign: {campaignType: {_eq: "RA_CAMPAIGN"}},
        classification: {_in: $branchFilter}}}, {_not: {acl_campaigns:{campaign:{campaignType:{_nin: ["ACL_CAMPAIGN"]}},
        reviews: {}}}}]}, {_or: [{name: {_ilike: $search}},{dealerCode:{_ilike : $search}}]}]},) {
        aggregate {
          count
        }
      }
    }
  `,
  GET_LIST_SUB_NOT_JOIN_NOT_REIVEW_CODE_TYPE: gql`
  query GET_LIST_SUB_NOT_JOIN_NOT_REIVEW_CODE_TYPE($type: String, $search: String, $status:[String!], $branch: [uuid!],
    $saleFilter: [String!], $branchFilter: [String!], $limit:Int, $offset: Int) {
      dealers(where: {assignedSubDealers: {dealerId: {_is_null:false}},
        role: {alias: {_eq: "sub_dealer"}},status: {_eq:"active"}, _and:[{_or:[
        {acl_campaigns:{campaign: {campaignType: {_eq: "RA_CAMPAIGN"}}, confirmationCode: {_ilike: $type},
         requestStatus: {_in: $saleFilter}}}]},
        {branchId:{_in: $branch}},{_or:[{acl_campaigns:{campaign: {campaignType: {_eq: "RA_CAMPAIGN"}},
        classification: {_in: $branchFilter}}}, {_not: {acl_campaigns:{campaign:{campaignType:{_nin: ["ACL_CAMPAIGN"]}},
        reviews: {}}}}]}, {_or: [{name: {_ilike: $search}},{dealerCode:{_ilike : $search}}]}]},
        limit: $limit, offset: $offset) {
        name
        address
        dealerCode
        id
        role {
          alias
        }
        assignedSubDealers {
          dealer {
            name
            dealerCode
          }
        }
        acl_campaigns(where:{campaign:{campaignType:{_eq:"RA_CAMPAIGN"}}}) {
          id
          dealer {
            name
            id
            publicCode
            dealerCode
            taxNumber
            address
            ownerName
            ownerPosition
            shortName
            role {
              alias
            }
          }
          isDealerConfirm
          shop {
            id
            name
            shopCode
            address
          }
          confirmationCode
          forceConfirmationCode
          packages
          pictureURLs
          resizedPictureURLs
          reason
          requestStatus
          reviews {
            id
            classification
            createdAt
            description
          }
          classification
          requestStatus
          shopId
        }
      }
      dealers_aggregate(where: {assignedSubDealers: {dealerId: {_is_null:false}},
        role: {alias: {_eq: "sub_dealer"}},status: {_eq:"active"}, _and:[{_or:[
        {acl_campaigns:{campaign: {campaignType: {_eq: "RA_CAMPAIGN"}}, confirmationCode: {_ilike: $type},
         requestStatus: {_in: $saleFilter}}}]},
        {branchId:{_in: $branch}},{_or:[{acl_campaigns:{campaign: {campaignType: {_eq: "RA_CAMPAIGN"}},
        classification: {_in: $branchFilter}}}, {_not: {acl_campaigns:{campaign:{campaignType:{_nin: ["ACL_CAMPAIGN"]}},
        reviews: {}}}}]}, {_or: [{name: {_ilike: $search}},{dealerCode:{_ilike : $search}}]}]}) {
        aggregate {
          count
        }
      }
    }
  `,
  GET_LIST_SUB_NOT_JOIN: gql`
  query GET_LIST_SUB_NOT_JOIN($type: String, $search: String, $status:[String!], $branch: [uuid!],
    $saleFilter: [String!], $branchFilter: [String!], $limit:Int, $offset: Int) {
      dealers(where: {assignedSubDealers: {dealerId: {_is_null:false}},
        role: {alias: {_eq: "sub_dealer"}},status: {_eq:"active"},
      _and:[{_or:[{_and:[{_not:{acl_campaigns:{campaign: {campaignType:
      {_nin: ["ACL_CAMPAIGN"]}}}}}]},
      {acl_campaigns:{campaign: {campaignType: {_eq: "RA_CAMPAIGN"}},confirmationCode: {_ilike: $type},
       requestStatus: {_in: $saleFilter}}}]}
      {branchId: {_in: $branch}},
      {acl_campaigns:{campaign: {campaignType: {_eq: "RA_CAMPAIGN"}}, classification: {_in: $branchFilter}}}, {_or:
      [{name: {_ilike: $search}},{dealerCode:{_ilike : $search}}]}
      ]},
        limit: $limit, offset: $offset) {
        name
        dealerCode
        id
        address
        assignedSubDealers {
          dealer {
            name
            dealerCode
          }
        }
        acl_campaigns(where:{campaign:{campaignType:{_eq:"RA_CAMPAIGN"}}}) {
          id
          dealer {
            id
            name
            publicCode
            dealerCode
            taxNumber
            address
            ownerName
            ownerPosition
            shortName
            role {
              alias
            }
          }
          isDealerConfirm
          shop {
            id
            name
            shopCode
            address
          }
          confirmationCode
          forceConfirmationCode
          packages
          pictureURLs
          resizedPictureURLs
          reason
          requestStatus
          reviews {
            id
            classification
            createdAt
            description
          }
          classification
          requestStatus
          shopId
        }
      }
      dealers_aggregate(where: {assignedSubDealers: {dealerId: {_is_null:false}},
        role: {alias: {_eq: "sub_dealer"}},status: {_eq:"active"},
      _and:[{_or:[{_and:[{_not:{acl_campaigns:{campaign: {campaignType:
      {_nin: ["ACL_CAMPAIGN"]}}}}}]},
      {acl_campaigns:{campaign: {campaignType: {_eq: "RA_CAMPAIGN"}},confirmationCode: {_ilike: $type},
       requestStatus: {_in: $saleFilter}}}]}
      {branchId: {_in: $branch}},
      {acl_campaigns:{campaign: {campaignType: {_eq: "RA_CAMPAIGN"}}, classification: {_in: $branchFilter}}}, {_or:
      [{name: {_ilike: $search}},{dealerCode:{_ilike : $search}}]}
      ]}) {
        aggregate {
          count
        }
      }
    }
  `,
  GET_LIST_SUB: gql`
  query GET_LIST_SUB($type: String, $search: String, $status:[String!], $branch: [uuid!],
    $saleFilter: [String!], $branchFilter: [String!], $limit:Int, $offset: Int) {
      dealers(where: {role: {assignedSubDealers: {dealerId: {_is_null:false}},
        alias: {_eq: "sub_dealer"}},status: {_eq:"active"},
      _and:[{acl_campaigns:{campaign: {campaignType: {_eq: "RA_CAMPAIGN"}},
      confirmationCode: {_ilike: $type}, requestStatus: {_in: $saleFilter}}},
       {branchId: {_in: $branch}},
        {acl_campaigns:{campaign: {campaignType: {_eq: "RA_CAMPAIGN"}}, classification: {_in: $branchFilter}}},
        {_or:[{name: {_ilike: $search}},{dealerCode:{_ilike : $search}}]}
        ]},
        limit: $limit, offset: $offset) {
        name
        dealerCode
        id
        address
        assignedSubDealers {
          dealer {
            name
            dealerCode
          }
        }
        acl_campaigns(where:{campaign:{campaignType:{_eq:"RA_CAMPAIGN"}}}) {
          id
          dealer {
            id
            name
            publicCode
            dealerCode
            taxNumber
            address
            ownerName
            ownerPosition
            shortName
            role {
              alias
            }
          }
          isDealerConfirm
          shop {
            id
            name
            shopCode
            address
          }
          confirmationCode
          forceConfirmationCode
          packages
          pictureURLs
          resizedPictureURLs
          reason
          requestStatus
          reviews {
            id
            classification
            createdAt
            description
          }
          classification
          requestStatus
          shopId
        }
      }
      dealers_aggregate(where: {assignedSubDealers: {dealerId: {_is_null:false}},
        role: {alias: {_eq: "sub_dealer"}},status: {_eq:"active"},
      _and:[{acl_campaigns:{campaign: {campaignType: {_eq: "RA_CAMPAIGN"}},
      confirmationCode: {_ilike: $type}, requestStatus: {_in: $saleFilter}}},
       {branchId: {_in: $branch}},
        {acl_campaigns:{campaign: {campaignType: {_eq: "RA_CAMPAIGN"}}, classification: {_in: $branchFilter}}},
        {_or:[{name: {_ilike: $search}},{dealerCode:{_ilike : $search}}]}
        ]}) {
        aggregate {
          count
        }
      }
    }
  `,
  GET_SUB_DEALER_CAMPAIGN_DETAIL: gql`
  query GET_SUB_DEALER_CAMPAIGN_DETAIL($id: uuid!) {
    dealers(where: {id: {_eq: $id}}) {
      address
      email
      name
      phoneNumber
      dealerCode
      idCard
      idCardImage
      ownerName
      information {
        businessHouseholdNo
        businessHouseholdPicURLs
        businessRegistrationNo
        businessRegistrationPicURLs
        productReceiptPicURLs
        requestedRewardReceiptURLs
        data
      }
      bankBranch
      bankName
      accountName
      accountNumber
      assignedSubDealers {
        dealer {
          name
          dealerCode
        }
      }
      acl_campaigns(where: {campaign: {campaignType: {_eq: "RA_CAMPAIGN"}}}) {
        id
        dealer {
          id
          name
        }
        packages
        pictureURLs
        requestStatus
        confirmationCode
        classification
        model1
        model2
        model3
        branchRequestReUpCount
        isBranchReview
        isDealerReUp
        reviews {
          createdAt
          forceConfirmationCode
          createdUser {
            name
          }
          classification
          updatedAt
          updatedUser {
            name
          }
          description
        }
        updatedAt
        createdAt
        logs(order_by: {createdAt: desc}) {
          requestStatus
          submittedAt
          updatedAt
          createdUser{
            name
          }
          reason
          dealer {
            name
          }
        }
      }
    }
  }
  `,
  PUSH_REMINDER: gql`
  mutation PUSH_REMINDER($form: FormCreateNotification!) {
    createNotification(form: $form) {
      id
    }
  }
  `,
  LOG_REMINDER: gql`
  mutation LOG_REMINDER($campaignId: uuid!, $createdBy: uuid!) {
    insert_acl_campaign_status_logs(objects: {campaignId: $campaignId, createdByUser: $createdBy,
      requestStatus: "notification_reminder"}) {
      affected_rows
    }
  }
  `,
  GET_BRANCH_ID: gql`
  query GET_BRANCH_ID($id: uuid!){
    group_manager(where: {managerId:{_eq: $id}}) {
      group {
        branchId
      }
      groupId
    }
  }`
};
