import { DKGqlClient } from 'root/api/graphql/Client';
import { ActionType, IState } from 'root/store';
import { crudActions } from 'root/store/helpers';
import { ActionContext } from 'vuex';
import { IGroupManageState } from '.';
import { ActionTypeGroupManage, MutationTypeGroupManager } from './types';

const { groupManage } = DKGqlClient();
export const actions = {
  ...crudActions('group_manager', {
    filter: groupManage.filter,
  }),
  async [ActionTypeGroupManage.GroupGetById]({
    commit, dispatch
  }: ActionContext<IGroupManageState, IState>,
                                             {id}) {
    try {
      const dealerDetail = await groupManage.groupManageGetById(id);
      commit(MutationTypeGroupManager.SetGroupManager, {data: dealerDetail});
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    }
  },
  async [ActionTypeGroupManage.GetListGroupManage]({
    commit, dispatch
  }: ActionContext<IGroupManageState, IState>,
                                                   {form}) {
    try {
      commit(MutationTypeGroupManager.GroupManagerLoading);
      const listGroup = await groupManage.getGroupManageList(form);
      commit(MutationTypeGroupManager.SetListGroup, listGroup);
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    } finally {
      commit(MutationTypeGroupManager.GroupManagerLoaded);
    }
  },
  async [ActionTypeGroupManage.GetGroupLeader]({
    commit, dispatch
  }: ActionContext<IGroupManageState, IState>,
                                               {form}) {
    try {
      const listGroup = await groupManage.getGroupLeader(form);

      if (listGroup) {
        commit(MutationTypeGroupManager.SetGroupLeader, listGroup);
      }
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    }
  },
  async [ActionTypeGroupManage.UpdateGroupManager]({
    dispatch
  }: ActionContext<IGroupManageState, IState>,
                                                   {id, set, form, onSuccess, onFailure}) {
    try {
      const _form = {
        groupId: id,
        managerIds: set
      };
      if (_form.managerIds.length) {
        await groupManage.updateGroupManager(_form);
      }
      if (!_form.managerIds.length) {
        await groupManage.removeGroupManager(_form);
      }
      // const updateResult = await groupManage.updateGroupManager(_form);
      await groupManage.updateGroup(id, form);
      onSuccess();
      // if (updateResult && updateGroup) {
      //   commit(ActionTypeGroupManage.GetListGroupManage);
      // }
      // if (updateGroup) {
      //   dispatch(ActionTypeGroupManage.GetListGroupManage);
      // }
    } catch (error) {
      onFailure(error);
      dispatch(ActionType.CatchException, error);
    }
  },
  async [ActionTypeGroupManage.InsertGroup]({
    commit, dispatch
  }: ActionContext<IGroupManageState, IState>,
                                            {form, formManager, onSuccess, onFailure}) {
    try {
      const updateResult = await groupManage.insertGroup(form);
      if (updateResult) {
        const dataPush = <any> [];
        if (formManager.managerId && formManager.managerId.length) {
          formManager.managerId.map((it) => {
            dataPush.push(
              {groupId: updateResult.data.insert_groups.returning[0].id,
                managerId: it,
                createdBy: form.createdBy,
                branchId: form.branchId
              });
          });
        }
        const resultManager = dataPush.length ? await groupManage.insertGroupManager(dataPush) : 1;
        onSuccess();
        if (resultManager) {
          commit(ActionTypeGroupManage.GetListGroupManage);

        }
      }
    } catch (error) {
      onFailure(error);
      dispatch(ActionType.CatchException, error);
    }
  },
  async [ActionTypeGroupManage.GetListFilterGroup]({
    commit, dispatch
  }: ActionContext<IGroupManageState, IState>) {
    try {
      const list = await groupManage.getListFilter();
      commit(MutationTypeGroupManager.SetListGroupFilter, {data: list});
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    }
  },
};
