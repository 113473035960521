import { TabPane } from 'element-ui';
import { cloneDeep } from 'lodash';
import { ActionTypeBranchManage } from 'root/admin/BranchAndGroup/BranchManage/Store/types';
import { CustomCampaignRouterName, CustomCampaignTab } from 'root/admin/CustomCampaign';
import { SystemRoleAlias } from 'root/admin/User/Store/types';
import { IPaginationParams } from 'root/api/graphql/Core';
import { FilterByBranch } from 'root/components/FilterByBranch';
import { fullNameUser } from 'root/helpers';
import { IAddress, IBreadcrumb, IFilterInput, IUser } from 'root/models';
import { IState, MutationType } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import { ActionTypeRaCampaign, MutationTypeRaCampaign } from '../../Store/types';
import { SubDealerCampaignDetail } from '../SubDealerCampaignDetail';
import './styles.scss';

const enum FilterKey {
  Role = 'role',
  Branch = 'branch'
}
export enum Role {
  DaikinDealer = 'daikin_dealer',
  SubDealer = 'sub_dealer',
}
export enum RoleDealer {
  DaikinDealer = 'Direct Dealer',
  SubDealer = 'Sub-Dealer',
}
@Component({
  template: require('./view.html'),
  computed: {
    ...mapState({
      data: (state: IState) => state.raCampaign.data,
      loading: (state: IState) => state.raCampaign.loading,
      pagination: (state: IState) => state.raCampaign.pagination,
      authUser: (state: IState) => state.global.authUser,
      branch: (state: IState) => state.branchManage.data,
    }),
  },
  components: {
    'btn-filter-by-branch': FilterByBranch,
    'subs-detail-modal': SubDealerCampaignDetail
  }
})

export class RAParticipant extends Vue {
  public get serviceId(): string {
    return this.$route.params.id;
  }

  public get activeTab() {
    return CustomCampaignTab.RAParticipant;
  }

  public set activeTab(_value: string) {
    //
  }
  public get isSalePlanning() {
    if (this.authUser.superAdmin || this.authUser.role.alias === SystemRoleAlias.Admin
      || this.authUser.role.alias === SystemRoleAlias.SaleAdmin) {
      return true;
    }

    return false;
  }
  public get isBranchPic() {
    if (this.authUser.role.alias === SystemRoleAlias.BranchManager) {
      return true;
    }

    return false;
  }
  public get isDepartmentPic() {
    if (this.authUser.role.alias === SystemRoleAlias.GroupLeader) {
      return true;
    }

    return false;
  }
  public get dataFilterRole() {
    const _role: IFilterInput[] = [
      {
        key: Role.DaikinDealer,
        value: Role.DaikinDealer,
        name: RoleDealer.DaikinDealer,
      },
      {
        key: Role.SubDealer,
        value: Role.SubDealer,
        name: RoleDealer.SubDealer,
      }
    ];

    return [
      {
        key: FilterKey.Role,
        name: 'Role',
        value: _role
      },
    ];
  }
  public get dataFilter() {
    const _branch: IFilterInput[] = this.branch.reduce((res, item) => {
      res.push({
        key: item.id,
        value: item.id,
        name: item.name
      });

      return res;
    }, []);

    return [
      {
        key: FilterKey.Branch,
        name: 'Branch',
        value: _branch
      },
    ];
  }
  public get selectedFilter() {
    const _filterParams = cloneDeep(this.filterParams);

    return [
      {
        key: FilterKey.Role,
        value: _filterParams[FilterKey.Role]
      },
      {
        key: FilterKey.Branch,
        value: _filterParams[FilterKey.Branch]
      }
    ];
  }
  public get columns() {
    return [
      {
        prop: 'dealer',
        label: 'Direct Dealer Name',
        labelItem: 'Direct Dealer Code',
        formatter: (model: any) => {
          return fullNameUser(model) || '--';
        },
        avatar: {
          formatter: (model: any) => {
            return model ? model : null;
          }
        },
        width: 250,
        fixed: true
      },
      {
        prop: 'sub-dealer',
        label: 'Sub-Dealer Name',
        labelItem: 'Sub-Dealer Code',
        formatter: (model: any) => {
          return fullNameUser(model) || '--';
        },
        avatar: {
          formatter: (model: any) => {
            return model ? model : null;
          }
        },
        width: 250,
        fixed: true
      },
      {
        prop: 'role',
        label: 'role',
        width: 180,
        formatter: this.formatterRole
      },
      {
        prop: 'proshopType',
        label: 'Type',
        width: 180,
        formatter: (model: any) => {
          return model.proshopType ? model.proshopType.name : '--';
        }
      },
      {
        prop: 'address',
        label: 'Address',
        width: 220,
        formatter: (model: any) => {
          if (model.address) {
            return this.displayAddress(model.address) || '--';
          }

          return '--';
        }
      },
      {
        prop: 'branch',
        label: 'Branch',
        width: 180,
        formatter: (model: any) => {
          if (model.branch && model.branch.name) {
            return model.branch.name;
          }

          return '--';
        }
      },
      {
        prop: 'email',
        label: 'Owner Email',
        width: 200,
        formatter: (model: any) => {
          return model.email || '--';
        }
      },
      {
        prop: 'totalShop',
        label: 'Total Shop',
        width: 150,
        formatter: (model: any) => {
          return model.shops_aggregate.aggregate.count;
        },
        enableSort: true,
      },
      {
        prop: 'joinedShop',
        label: 'Joined Shop',
        width: 150,
        formatter: (model: any) => {
          return model.countRaJoined ? model.countRaJoined : 0;
        },
        enableSort: true,
      },
      {
        prop: 'action',
        label: '',
        align: 'center',
        fixed: 'right',
        width: 60
      }
    ];
  }

  public searchText: string = '';
  public authUser: IUser;
  public pagination: IPaginationParams;
  public filterParams = {
    [FilterKey.Role]: [],
    [FilterKey.Branch]: []
  };
  public visibleDetail: boolean = false;
  public shopId: string = '';
  public branch: any[];
  public sortType: number = 0;
  public sortTotalType: number = 0;
  public sortJoinType: number = 0;
  public displayAddress(model: IAddress) {
    return model && model.province ? this.$store.getters['getAddressString'](model) : '--';
  }

  public getAction() {

    return 'view';
  }
  public handleActions(account, _action) {
    // tslint:disable-next-line: early-exit
    if (account && account.role.alias === 'daikin_dealer') {
      this.$router.push({
        name: CustomCampaignRouterName.RACampaignDetail,
        params: {
          id : account.id
        }
      });

      return;
    }

    this.shopId = account.id;
    this.visibleDetail = true;
  }
  public changeTab(tab: TabPane) {
    switch (tab.name) {
    case CustomCampaignTab.RAStatistic:
      this.$router.push({
        name: CustomCampaignRouterName.RAStatistic
      });
      break;
    case CustomCampaignTab.RAShops:
      this.$router.push({
        name: CustomCampaignRouterName.RAShops
      });
      break;
    case CustomCampaignTab.RAQualifiedShops:
      this.$router.push({
        name: CustomCampaignRouterName.RAQualifiedShops
      });
      break;
    case CustomCampaignTab.RAConfiguration:
      this.$router.push({
        name: CustomCampaignRouterName.RAConfiguration
      });
      break;
    case CustomCampaignTab.RAManageExport:
      this.$router.push({
        name: CustomCampaignRouterName.RAManageExport
      });
      break;
    case CustomCampaignTab.SubDealerRequest:
      this.$router.push({
        name: CustomCampaignRouterName.SubDealerRequest
      });
      break;
    case CustomCampaignTab.ConfirmationCode:
      this.$router.push({
        name: CustomCampaignRouterName.ConfirmationCode
      });
      break;
    case CustomCampaignTab.SubDealerCampaign:
      this.$router.push({
        name: CustomCampaignRouterName.SubDealerCampaign
      });
      break;
    default:
      break;
    }
  }
  public handleSort(sort: any) {
    if (sort === 'totalShop') {
      const _clone = this.sortTotalType + 1;
      this.sortJoinType = 0;
      this.sortTotalType = _clone % 3;
    }
    if (sort === 'joinedShop') {
      const _clone = this.sortJoinType + 1;
      this.sortTotalType = 0;
      this.sortJoinType = _clone % 3;
    }

    this.fetchData();
  }
  public handleSearch() {
    this.fetchData();
  }
  public formatterRole(model) {
    if (model && model.role) {
      switch (model.role.alias) {
      case Role.DaikinDealer:
        return 'Direct Dealer';
      case Role.SubDealer:
        return 'Sub Dealer';
      }
    }

    return '--';
  }
  public formatterDirectDealerName(model) {
    if (model && model.role && model.role.alias === Role.DaikinDealer) {
      return model.name;
    }

    if (model && model.role && model.role.alias === Role.SubDealer) {
      return model.assignedSubDealers.length && model.assignedSubDealers[0].dealer
      && model.assignedSubDealers[0].dealer.name ? model.assignedSubDealers[0].dealer.name : ' --';
    }

    return '--';
  }
  public formatterDirectDealerCode(model) {
    if (model && model.role && model.role.alias === Role.DaikinDealer) {
      return model.dealerCode;
    }
    if (model && model.role && model.role.alias === Role.SubDealer) {
      return model.assignedSubDealers.length && model.assignedSubDealers[0].dealer
      && model.assignedSubDealers[0].dealer.dealerCode ? model.assignedSubDealers[0].dealer.dealerCode : ' --';
    }

    return '--';
  }
  public formatterSubDealerName(model) {
    if (model && model.role && model.role.alias === Role.SubDealer) {
      return model.name;
    }

    return '--';
  }
  public formatterSubDealerCode(model) {
    if (model && model.role && model.role.alias === Role.SubDealer) {
      return model.dealerCode;
    }

    return '--';
  }

  public handlePaginationChange(value) {
    if (typeof value === 'number') {
      this.$store.commit(MutationTypeRaCampaign.PaginationChange, {
        ...this.pagination,
        size: value
      });
    } else {
      this.$store.commit(MutationTypeRaCampaign.PaginationChange, {
        ...this.pagination,
        ...value
      });
    }
    this.fetchData();
  }

  public editSuccess() {
    this.fetchData();
  }
  public closeDialogDetail() {
    // change after
    this.shopId = '';
    this.visibleDetail = false;
    this.fetchData();
  }
  public changeFilterRole(model) {
    const role = model.find((e) => e.key === FilterKey.Role);
    const nextFilter = {
      [FilterKey.Role]: role ? role.value : [],
    };
    this.filterParams = {
      ...cloneDeep(this.filterParams),
      ...nextFilter
    };
    this.fetchData();
  }
  public changeFilter(filter) {
    const nextFilter = {
      [FilterKey.Branch]: filter ? filter : [],
    };
    this.filterParams = {
      ...cloneDeep(this.filterParams),
      ...nextFilter
    };
    this.fetchData();
  }

  protected mounted() {
    this.$nextTick(() => {
      const breadcrumb: IBreadcrumb[] = [
        {
          label: 'title.campaign',
          path: null,
          current: false
        },
        {
          label: 'title.ra_campaign',
          path: null,
          current: true
        }
      ];
      this.$store.commit(MutationType.SetBreadcrumb, breadcrumb);
      this.$store.dispatch(ActionTypeBranchManage.GetListBranch, {
        form: {
          filter: {},
          pagination: {}
        }
      });
      this.fetchData();
    });
  }
  protected beforeDestroy() {
    this.$store.commit(MutationType.ClearBreadcrumb);
  }

  private fetchData() {
    const text = cloneDeep(this.searchText);
    const _filterParams = <any> {};
    let _cloneSort = {};
    if (this.sortJoinType === 1) {
      _cloneSort = {countRaJoined: 'desc'};
    }
    if (this.sortJoinType === 2) {
      _cloneSort = {countRaJoined: 'asc'};
    }
    if (this.sortTotalType === 1) {
      _cloneSort = {shops_aggregate: {count: 'desc'}};
    }
    if (this.sortTotalType === 2) {
      _cloneSort = {shops_aggregate: {count: 'asc'}};
    }
    if (this.filterParams[FilterKey.Role].length) {
      _filterParams.role = this.filterParams[FilterKey.Role];
    }
    if (this.filterParams[FilterKey.Branch].length) {
      _filterParams.branch = this.filterParams[FilterKey.Branch];
    }
    if (this.isDepartmentPic) {
      this.$store.dispatch(ActionTypeRaCampaign.GetBranchId, {
        id: this.authUser.id,
        onSuccess: (branchId) => {
          const _cloneGroupId = [];
          if (branchId && branchId.length) {
            branchId.map((branch) => {
              _cloneGroupId.push(branch.group.branchId);
            });
          }
          this.$store.dispatch(ActionTypeRaCampaign.GetAclCampaign, {
            search: `%${text}%`,
            role: _filterParams.role,
            branch: _filterParams.branch && _filterParams.branch.length ?
            _filterParams.branch : _cloneGroupId && _cloneGroupId.length ? _cloneGroupId : [],
            order: _cloneSort,
            pagination: this.pagination
          });
        }
      });

      return;
    }
    this.$store.dispatch(ActionTypeRaCampaign.GetAclCampaign, {
      search: `%${text}%`,
      role: _filterParams.role,
      branch: _filterParams.branch,
      order: _cloneSort,
      pagination: this.pagination
    });
  }
}
