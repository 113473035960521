import { DKGqlClient } from 'root/api/graphql/Client';
import { ISaleInCampaign } from 'root/models';
import { ActionType, IState } from 'root/store';
import { crudActions } from 'root/store/helpers';
import { ActionContext } from 'vuex';
import { ActionTypeSaleInCampaign, ActionTypeSaleInLogs, ActionTypeSaleInProduct,
   ActionTypeSaleInReward, MutationTypeSaleInCampaign } from './types';
const { saleInCampaign } = DKGqlClient();
export const actions = {
  ...crudActions('saleInCampaign', {
    filter: saleInCampaign.filter,
    fetchMany: saleInCampaign.fetchMany,
  }),
  ...crudActions('saleInProduct', {
    filter: saleInCampaign.saleInProduct.filter,
    fetchMany: saleInCampaign.saleInProduct.fetchMany,
  }, {
    keyState: 'saleInProduct'
  }),
  ...crudActions('saleInReward', {
    filter: saleInCampaign.saleInReward.filter,
    fetchMany: saleInCampaign.saleInReward.fetchMany,
  }, {
    keyState: 'saleInReward'
  }),
  ...crudActions('saleInLogs', {
    filter: saleInCampaign.saleInLogs.filter,
    fetchMany: saleInCampaign.saleInLogs.fetchMany,
  }, {
    keyState: 'saleInLogs'
  }),
  async [ActionTypeSaleInCampaign.GetDealerType]({
    dispatch
  }: ActionContext<ISaleInCampaign, IState>,
                                                 {onSuccess}) {
    try {
      const data = await saleInCampaign.getDealerTypes();
      onSuccess(data);
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    }
  },
  async [ActionTypeSaleInCampaign.GetConditionMetadata]({
    dispatch
  }: ActionContext<ISaleInCampaign, IState>,
                                                        {onSuccess}) {
    try {
      const data = await saleInCampaign.getConditionMetadata();
      onSuccess(data);
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    }
  },
  async [ActionTypeSaleInCampaign.CheckCampaignCodeExits]({
    dispatch
  }: ActionContext<ISaleInCampaign, IState>,
                                                          {code, onSuccess}) {
    try {
      const data = await saleInCampaign.checkCampaignExistCode(code);
      onSuccess(data);
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    }
  },
  async [ActionTypeSaleInCampaign.GetParticipantList]({
    dispatch
  }: ActionContext<ISaleInCampaign, IState>,
                                                      {role, search, type, branch, proShopType, businessType,
                                                            potentialType, pagination, onSuccess}) {
    try {
      const data = await saleInCampaign.getParticipantList(role, search, type, branch, proShopType, businessType,
         potentialType, pagination);
      onSuccess(data);
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    }
  },
  async [ActionTypeSaleInProduct.Create]({
    dispatch
  }: ActionContext<ISaleInCampaign, IState>,
                                         {form, onSuccess}) {
    try {
      await saleInCampaign.createSaleInProduct(form);
      onSuccess();
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    }
  },
  async [ActionTypeSaleInProduct.Update]({
    dispatch
  }: ActionContext<ISaleInCampaign, IState>,
                                         {id, form, onSuccess}) {
    try {
      await saleInCampaign.updateSaleInProduct(id, form);
      onSuccess();
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    }
  },
  async [ActionTypeSaleInProduct.Upsert]({
    dispatch
  }: ActionContext<ISaleInCampaign, IState>,
                                         {form, onSuccess}) {
    try {
      await saleInCampaign.upsertSaleInProduct(form);
      onSuccess();
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    }
  },
  async [ActionTypeSaleInReward.Create]({
    dispatch
  }: ActionContext<ISaleInCampaign, IState>,
                                        {form, onSuccess}) {
    try {
      await saleInCampaign.createSaleInReward(form);
      onSuccess();
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    }
  },
  async [ActionTypeSaleInReward.Update]({
    dispatch
  }: ActionContext<ISaleInCampaign, IState>,
                                        {id, form, onSuccess}) {
    try {
      await saleInCampaign.updateSaleInReward(id, form);
      onSuccess();
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    }
  },
  async [ActionTypeSaleInCampaign.GetCampaignDetailById]({
    dispatch
  }: ActionContext<ISaleInCampaign, IState>,
                                                         {id, onSuccess}) {
    try {
      const data = await saleInCampaign.getSaleInCampaignDetail(id);
      onSuccess(data);
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    }
  },
  async [ActionTypeSaleInCampaign.GetCampaignDetailParticipantList]({
    dispatch
  }: ActionContext<ISaleInCampaign, IState>,
                                                                    // tslint:disable-next-line:max-line-length
                                                                    {id, confirmedAt, search, type, branch, time, pagination, onSuccess, getAll}) {
    try {
      const {data, dataAll} = await saleInCampaign.getSaleInCampaignDetailParticipantList(id,
        confirmedAt, search, type, branch, time, pagination);
      onSuccess(data);
      if (getAll) {
        getAll(dataAll);
      }
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    }
  },
  async [ActionTypeSaleInCampaign.GetPinedCampaignsList]({
    commit, dispatch
  }: ActionContext<ISaleInCampaign, IState>,
                                                         {onSuccess}) {
    try {
      const data = await saleInCampaign.getPinedCampaigns();
      commit(MutationTypeSaleInCampaign.SetCampaignPinedList, data);
      if (onSuccess) {
        onSuccess(data);
      }
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    }
  },
  async [ActionTypeSaleInCampaign.PinCampaign]({
    dispatch
  }: ActionContext<ISaleInCampaign, IState>,
                                               {id, type, onSuccess}) {
    try {
      const data = await saleInCampaign.pinedCampaigns(id, type);
      onSuccess(data);
      dispatch(ActionTypeSaleInCampaign.GetPinedCampaignsList, {
        onSuccess: () => {
          // no handle
        }
      });
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    }
  },
  async [ActionTypeSaleInCampaign.ChangeCampaignStatus]({
    dispatch
  }: ActionContext<ISaleInCampaign, IState>,
                                                        {form, onSuccess}) {
    try {
      await saleInCampaign.changeCampaignStatus(form);
      onSuccess();
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    }
  },
  async [ActionTypeSaleInCampaign.CreateCampaign]({
    dispatch
  }: ActionContext<ISaleInCampaign, IState>,
                                                  {form, onSuccess}) {
    try {
      await saleInCampaign.createSaleInCampaign(form);
      onSuccess();
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    }
  },
  async [ActionTypeSaleInCampaign.UpdateCampaign]({
    dispatch
  }: ActionContext<ISaleInCampaign, IState>,
                                                  {id, form, information, conditionGroup, onSuccess}) {
    try {
      await saleInCampaign.updateSaleInCampaign(id, form, information, conditionGroup);
      onSuccess();
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    }
  },
  async [ActionTypeSaleInCampaign.ImportSaleInCampaign]({
    dispatch
  }: ActionContext<ISaleInCampaign, IState>,
                                                        {form, onSuccess}) {
    try {
      await saleInCampaign.importSaleInCampaign(form);
      onSuccess();
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    }
  },
  async [ActionTypeSaleInCampaign.GetImportModelLogs]({
    dispatch
  }: ActionContext<ISaleInCampaign, IState>,
                                                      {importId, onSuccess}) {
    try {
      const data = await saleInCampaign.getImportModelLogs(importId);
      onSuccess(data);
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    }
  },
  async [ActionTypeSaleInProduct.CheckModelNumberExits]({
    dispatch
  }: ActionContext<ISaleInCampaign, IState>,
                                                        {model, onSuccess}) {
    try {
      const data = await saleInCampaign.checkModelNumberExits(model);
      onSuccess(data);
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    }
  },
  async [ActionTypeSaleInReward.CheckRewardCodeExits]({
    dispatch
  }: ActionContext<ISaleInCampaign, IState>,
                                                      {code, onSuccess}) {
    try {
      const data = await saleInCampaign.checkRewardCodeExits(code);
      onSuccess(data);
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    }
  },
  async [ActionTypeSaleInLogs.GetImportModelLogs]({
    dispatch
  }: ActionContext<ISaleInCampaign, IState>,
                                                  {referenceId, typeLogs, status, search, pagination, onSuccess}) {
    try {
      const data = await saleInCampaign.getHistoryImportModelLogs(referenceId, typeLogs, status, search, pagination);
      onSuccess(data);
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    }
  },
  async [ActionTypeSaleInCampaign.GetListQuantitySoldParticipant]({
    dispatch
  }: ActionContext<ISaleInCampaign, IState>,
                                                                  {participantId, pagination, onSuccess}) {
    try {
      const data = await saleInCampaign.getQuantityListParticipant(participantId, pagination);
      onSuccess(data);
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    }
  },
  async [ActionTypeSaleInCampaign.GetListRewardParticipant]({
    dispatch
  }: ActionContext<ISaleInCampaign, IState>,
                                                            {participantId, pagination, onSuccess}) {
    try {
      const data = await saleInCampaign.getRewardListParticipant(participantId, pagination);
      onSuccess(data);
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    }
  },
  async [ActionTypeSaleInCampaign.SendConfirm]({
    dispatch
  }: ActionContext<ISaleInCampaign, IState>,
                                               {form, onSuccess}) {
    try {
      const data = await saleInCampaign.sendConfirm(form);
      onSuccess(data);
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    }
  },
  async [ActionTypeSaleInLogs.GetImportLogsId]({
    dispatch
  }: ActionContext<ISaleInCampaign, IState>,
                                               {importId, onSuccess}) {
    try {
      const data = await saleInCampaign.getImportLogId(importId);
      onSuccess(data);
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    }
  },
  async [ActionTypeSaleInCampaign.ChangeParticipant]({
    dispatch
  }: ActionContext<ISaleInCampaign, IState>,
                                                     {form}) {
    try {
      await saleInCampaign.changeParticipant(form);
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    }
  },
  async [ActionTypeSaleInLogs.GetParticipantLog]({
    dispatch
  }: ActionContext<ISaleInCampaign, IState>,
                                                 {campaignId, onSuccess}) {
    try {
      const data = await saleInCampaign.getParticipantLogs(campaignId);
      onSuccess(data);
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    }
  },
  async [ActionTypeSaleInCampaign.UpdateCampaignOnInactive]({
    dispatch
  }: ActionContext<ISaleInCampaign, IState>,
                                                            {form, onSuccess}) {
    try {
      await saleInCampaign.updateCampaignOnInactive(form);
      onSuccess();
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    }
  },

};
