import { DocumentNode } from 'graphql';
import gql from 'graphql-tag';
import { ICRUDQuery } from 'root/store/helpers';
import { crudQuery } from 'root/store/helpers/query';
const PREFIX_NOTIFICATION = 'notifications';

export const CREATE_NEW_NOTIFICATION = gql`
  mutation createNotification($form: FormCreateNotification!) {
    createNotification(form: $form) {
      id
      title
    }
  }
`;

export const QUERY_TARGET_DETAILS = gql`
  query fetchAllTargetDetail($where: notification_dealers_bool_exp) {
    notification_dealers {
      id
      receiverIds
      referenceId
    }
  }
`;

export const UPDATE_NOTIFICATIONS = gql`
  mutation updateNotification($form: FormUpdateNotification!, $id: String!) {
    updateNotification(form: $form, id: $id) {
      id
    }
  }
`;

export const UPDATE_NOTIFICATIONS_DEALERS = gql`
  mutation updateNotificationsDealer(
    $referenceId: uuid!
    $receiverIds: jsonb!
  ) {
    update_notification_dealers(
      where: { referenceId: { _eq: $referenceId } }
      _set: { receiverIds: $receiverIds }
    ) {
      returning {
        id
        title
      }
    }
  }
`;

const schema = `
		createdAt
    title
    id
    target
    scheduledAt
    notificationStatus
    typeDealer
    typeDealers
    dealerId
    dealerIds
    subtitle
    descriptionEng
    subtitleEng
    titleEng
    createdUser{
      id
      name
    }
    updatedUser{
      id
      name
    }
    metadata
    typeDealer
    updatedAt
    image
    url
    description
`;
interface INotificationQuery extends ICRUDQuery {
  GET_NOTIFICATION: DocumentNode;
  READ_NOTIFICATION: DocumentNode;
}
export const NotificationQuery: INotificationQuery = {
  ...crudQuery(PREFIX_NOTIFICATION, schema),
  GET_NOTIFICATION: gql`
  subscription GET_NOTIFICATION($id: uuid!){
    user_notification_reads(where: {userId: {_eq: $id}}, order_by: {notification: {sendTime: desc}}){
      id
      readAt
      notification {
        id
        title
        subtitle
        sendTime
        target
        image
        content
        referenceId
        # createdUser {
        #   fullName
        # }
        createdAt
      }
    }
  }`,
  READ_NOTIFICATION: gql`
  mutation READ_NOTIFICATION($ids: [uuid!]!, $readAt: timestamptz!) {
    update_user_notification_reads(where: {id: {_in: $ids}}, _set: {readAt: $readAt}) {
      returning {
        id
      }
    }
  }
  `
};
