import { gqlClient } from '../Core';
import { CRUDGqlService, ICRUDGqlService } from '../Core/crud';
import { getListRoleQuery, Role } from '../Query/role';

export interface IRoleGqlService extends ICRUDGqlService<any> {
  //
  getListRole(): Promise<any>;
  updateSystemRoleType(id: string, type: string): Promise<any>;
}
export function roleGqlService(): IRoleGqlService {
  async function getListRole(): Promise<any> {
    const results = await gqlClient.query({
      query: getListRoleQuery.GET_LIST_ROLE,
      fetchPolicy: 'network-only'
    });
    if (results.errors) {
      throw results.errors;
    }

    return results.data.getSystemUserProfile;
  }
  async function updateSystemRoleType(id: string, type: string) {
    const result = await gqlClient.mutate({
      mutation: getListRoleQuery.UPDATE_SYSTEM_ROLE_TYPE,
      variables: {
        id,
        type
      }
    });
    if (result.errors) {
      throw result.errors;
    }
  }

  return {
    ...CRUDGqlService<any>(Role, getListRoleQuery),
    getListRole,
    updateSystemRoleType
  };
}
