import { DocumentNode } from 'graphql';
import gql from 'graphql-tag';
import { crudQuery, ICRUDQuery } from '../Core/query';

const importModel = `
  id
  url
  importDealerWithShopStatus
  failedReason
  isReindex
  failedItems
`;
const importSubDealerModel = `
  id
  url
  importSubDealerStatus
  failedReason
  isReindex
  failedItems
`;

export const imports = 'import_dealer_with_shops';
// tslint:disable-next-line:no-empty-interface
interface IImportQuery extends ICRUDQuery {
  GET_IMPORT_LIST: DocumentNode;
  UPDATE_REINDEX: DocumentNode;
  GET_IMPORT_LIST_SUB: DocumentNode;
  UPDATE_REINDEX_SUB: DocumentNode;
}
export const importListQuery: IImportQuery = {
  ...crudQuery(imports, importModel),
  GET_IMPORT_LIST: gql`
    query GetImportDealer {
      import_dealer_with_shops {
        ${importModel}
      }
    }
  `,
  UPDATE_REINDEX: gql`
    mutation UPDATE_REINDEX($id: uuid!, $reIndex: Boolean!) {
      update_import_dealer_with_shops(where: {id: {_eq: $id}}, _set: {isReindex: $reIndex}) {
        affected_rows
      }
    }
  `,
  GET_IMPORT_LIST_SUB: gql`
    query GetImportDealer($limit:Int, $offset: Int) {
      import_sub_dealers (offset: $offset, limit: $limit, order_by:{createdAt:desc}){
        ${importSubDealerModel}
      }
      import_sub_dealers_aggregate {
        aggregate {
          count
        }
      }
    }
  `,
  UPDATE_REINDEX_SUB: gql`
    mutation UPDATE_REINDEX_SUB($id: uuid!, $reIndex: Boolean!) {
      update_import_sub_dealers(where: {id: {_eq: $id}}, _set: {isReindex: $reIndex}) {
        affected_rows
      }
    }
  `,
};
