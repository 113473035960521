
import { IPaginationParams } from 'root/api/graphql/Core';
import { formatCurrency, FormatDateFullTime } from 'root/helpers';
import { IAddress, IUser } from 'root/models';
import { IState } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import { ActionTypeSaleInCampaign } from '../../../Store/types';
import './styles.scss';
@Component({
  template: require('./view.html'),
  props: {
    visible: Boolean,
    detail: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  computed: {
    ...mapState({
      authUser: (state: IState) => state.global.authUser,
    })
  },
})
export class SaleInParticipantDetail extends Vue {
  public get columns() {
    return [
      ...this.activeTab === 'sold' ?
        [
          {
            label: 'Display model',
            formatter: (model: any) => {
              return model && model.model && model.model.displayModel ? model.model.displayModel : '--';
            },
            width: 300
          },
          // {
          //   label: 'Unit',
          //   formatter: () => {
          //     return '--';
          //   },
          //   width: 140
          // },
          {
            label: 'Quantity',
            prop: 'quantity',
            width: 140
          }
        ] : this.activeTab === 'reward' ? [
          {
            label: 'Gift name',
            formatter: (model: any) => {
              return model && model.reward && model.reward.rewardName ? model.reward.rewardName : '--';
            },
            width: 163
          },
          {
            label: 'Quantity',
            prop: 'quantity',
            width: 163
          },
          {
            label: 'Unit',
            formatter: (model: any) => {
              return model && model.reward && model.reward.unit ? model.reward.unit : '--';
            },
            width: 163
          },
          {
            label: 'Unit price',
            formatter: (model: any) => {
              return model && model.reward && model.reward.price ?
              `${formatCurrency(model.reward.price, false, 0)} VND` : '--';
            },
            width: 163
          },
        ] : []
    ];
  }
  public FormatDateFullTime = FormatDateFullTime;
  public detail;
  public data: any = [];
  public loading: boolean = false;
  public authUser: IUser;
  public activeTab: string = 'sold';
  public formatCurrency = formatCurrency;
  public dataLength: {
    dealer: number,
    trainee: number
  } = {
    dealer: 0,
    trainee: 0
  };
  public pagination: IPaginationParams = {
    limit: 10,
    page: 1,
    total: 10
  };
  public formatterAddress(model: IAddress) {
    return model && model.province
      ? this.$store.getters['getAddressString'](model)
      : '';
  }
  public handlePaginationChange(value) {
    if (typeof value === 'number') {
      this.pagination = {
        ...this.pagination,
        limit: value
      };
    } else {
      this.pagination = {
        ...value
      };
    }
    this.fetchData();
  }
  public fetchData() {
    if (this.activeTab === 'sold') {
      this.$store.dispatch(ActionTypeSaleInCampaign.GetListQuantitySoldParticipant, {
        participantId: this.$props.detail.id,
        pagination: this.pagination,
        onSuccess: (data) => {
          this.data = data.si_campaign_participant_models;
          this.pagination = {
            ...this.pagination,
            total: data.si_campaign_participant_models_aggregate.aggregate.count
          };
        }
      });

      return;
    }
    if (this.activeTab === 'reward') {
      this.$store.dispatch(ActionTypeSaleInCampaign.GetListRewardParticipant, {
        participantId: this.$props.detail.id,
        pagination: this.pagination,
        onSuccess: (data) => {
          this.data = data.si_campaign_participant_rewards;
          this.pagination = {
            ...this.pagination,
            total: data.si_campaign_participant_rewards_aggregate.aggregate.count
          };
        }
      });

      return;
    }

    return;
  }
  public onTabClick() {
    this.pagination = {
      limit: 10,
      page: 1,
      total: 10
    };
    this.fetchData();
  }
  public openDialog() {
    this.fetchData();
  }
  public closeDialog() {
    this.$emit('closeDialog');
  }
}
