import { ElUpload } from 'element-ui/types/upload';
import { ActionTypeDealer } from 'root/admin/Dealer/Store/types';
import { DKGqlClient } from 'root/api/graphql/Client';
import { IPaginationParams } from 'root/api/graphql/Core';
import { IUser } from 'root/models';
import { pathUpload, uploadToFirebase } from 'root/services';
import { FirebaseAuthService } from 'root/services/auth/firebase';
import { IState, MutationType, TypeAlert } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import { ActionTypeImport, MutationTypeImport } from '../Store/types';
import './styles.scss';

const enum ImportStatusCode {
  Done = 'done',
  In_progress = 'in_progress',
  Waiting = 'waiting',
  Failed = 'failed'
}
@Component({
  template: require('./view.html'),
  computed: {
    ...mapState({
      authUser: (state: IState) => state.global.authUser,
      data: (state: IState) => state.manageImport.listImportSubDealer,
      pagination: (state: IState) => state.manageImport.pagination,
      loading: (state: IState) => state.manageImport.loading,
    })
  },
})

export class ManageImportSubDealer extends Vue {
  public pagination: IPaginationParams;
  public visibleImport: boolean = false;
  public fileList = [];
  public fileUploaded= [];
  public $refs: {
    upload: ElUpload;
  };
  public authUser: IUser;
  public loadingUpload: boolean = false;
  private pathUploadAvatar: pathUpload = pathUpload.DEALER;

  public get columns() {
    return [
      {
        prop: 'url',
        label: 'Url',
        width: 180,
        formatter: (model: any) => {
          return model.url ? model.url.substring(
            model.url.indexOf('%') + 1,
            model.url.lastIndexOf('?')
          ) : '--';
        },
      },
      {
        prop: 'importDealerWithShopStatus',
        label: 'Status',
        formatter: (model: any) => {
          return model.importSubDealerStatus ? model.importSubDealerStatus : '--';
        },
      },
      {
        prop: 'failedReason',
        label: 'Fail Reason',
        width: 300,
      },
      {
        prop: 'isReIndex',
        label: 'Is ReIndex',
      },
    ];

  }

  public formatterImportStatus(model: any) {
    switch (model.importSubDealerStatus) {
    case ImportStatusCode.Done:
      return 'Done';
    case ImportStatusCode.Waiting:
      return 'Waiting';
    case ImportStatusCode.In_progress:
      return 'In Progress';
    case ImportStatusCode.Failed:
      return 'Failed';
    }
  }

  public isFailed(model: any) {
    if (model.importSubDealerStatus === 'failed') {
      return false;
    }

    return true;
  }
  public getValueReIndex(model: any) {
    if (model.isReindex) {
      return true;
    }

    return false;
  }
  public changeReIndex(model: any) {
    model.isReindex = !model.isReindex;
    this.$store.dispatch(ActionTypeImport.ReIndexImportSubDealer, {
      id: model.id,
      reIndex: model.isReindex
    });
  }
  public formatterFailReason(model: any) {
    if (model && !model.failedItems) {
      return '--';
    }

    const failArr = [];
    model.failedItems.map((fail) =>
    failArr.push(fail.msg));

    return failArr.join('; ');
  }
  public reloadListImport() {
    this.$store.dispatch(ActionTypeImport.FilterNoCache, {
      optionsHTTP: {
        orderBy: {
          updatedAt: 'desc'
        }
      }
    });
  }
  public closeModalImport() {
    this.visibleImport = false;
    this.$refs.upload.clearFiles();
    this.fileList = [];
  }
  public importDealer() {
    this.visibleImport = true;
  }
  public pushFileUpload(file: any) {
    const f = this.fileList;
    if (this.fileList.length > 0) {
      const _file = this.fileList.filter((it) => it.name === file.name);
      if (_file && _file.length > 0) {
        this.$refs.upload.clearFiles();
        const message = 'File has been exits';
        this.$store.commit(MutationType.OpenTopAlert, {
          message,
          type: TypeAlert.Warning
        });

        return this.$refs.upload.$data.uploadFiles = f;
      }

      return this.fileList = [file];
    }

    f.push(file);

    return this.fileList = [file];
  }
  public onRemove(file: any, fileList: any) {
    console.log(file);
    this.fileList = fileList;
  }
  public async submitFile() {
    // tslint:disable-next-line: whitespace
    try {
      this.loadingUpload = true;
      // const uploadedFile = <any> [];
      const token = await DKGqlClient().user.getFirebaseAuthenticateToken();
      await FirebaseAuthService().signInWithCustomToken(token.authenticate);
      const uploadedFile = await Promise.all(this.fileList.map(async (it) => {
        const _file = await uploadToFirebase(it.raw, this.pathUploadAvatar);

        // return _file ? uploadedFile.push(_file) : uploadedFile;
        // tslint:disable-next-line:no-var-before-return
        return _file;
      }));

      if (uploadedFile.length > 0) {
        const form = {
          createdBy: this.authUser && this.authUser.id,
          url: uploadedFile[0]
        };
        this.$store.dispatch(ActionTypeDealer.ImportFileDealer, {
          form,
          onSuccess: () => {
            this.loadingUpload = false;
            const message = 'Import dealer successfully';
            this.$store.commit(MutationType.OpenTopAlert, {
              message,
              type: TypeAlert.Success
            });
            this.visibleImport = false;
            this.fileList = [];
            this.fileUploaded = [];
            this.$refs.upload.clearFiles();
            this.fetchData();
          },
          onFailure: () => {
            this.loadingUpload = false;

          }});
      }

    } catch (error) {
      console.log(error);
    }

  }

  public handlePaginationChange(value) {
    if (typeof value === 'number') {
      this.$store.commit(MutationTypeImport.ManageImportPaginationChange, {
        ...this.pagination,
        size: value
      });
    } else {
      this.$store.commit(MutationTypeImport.ManageImportPaginationChange, {
        ...this.pagination,
        ...value
      });
    }
    this.fetchData();
  }

  protected mounted() {
    this.$nextTick(() => {
      this.$store.commit(MutationType.SetBreadcrumb, this.$t('title.manageImportSub'));
      this.fetchData();
    });
  }
  protected beforeDestroy() {
    this.$store.commit(MutationType.ClearBreadcrumb);
  }

  private fetchData() {
    this.$store.dispatch(ActionTypeImport.GET_LIST_IMPORT_SUB, {
      pagination: this.pagination
    });
  }
}
