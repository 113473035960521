import {saveAs} from 'file-saver';
import JsZip from 'jszip';
import { find } from 'lodash';
import moment from 'moment';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { gqlClient } from 'root/api/graphql/Core';
import { newRACampaignQuery } from 'root/api/graphql/Query/newRACampaign';
import {globalState} from './../../../store/mutations';

function getBase64Image(url) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.setAttribute('crossOrigin', 'anonymous');
    img.onload = () => {
      const canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0);

      return resolve(canvas.toDataURL('image/png'));
    };
    img.onerror = (error) => {
      return reject(error);
    };
    img.src = url;
  });
}

async function getBase64ImageFromURL(url) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.setAttribute('crossOrigin', 'anonymous');

    img.onload = () => {
      const canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;

      const ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0);

      const dataURL = canvas.toDataURL('image/png');

      resolve(dataURL);
    };

    img.onerror = (error) => {
      reject(error);
    };

    img.src = url;
  });
}
async function getBase64ImageFromURLResize(url) {
  return new Promise((resolve, reject) => {
    const img = new Image(75, 75);
    img.setAttribute('crossOrigin', 'anonymous');

    img.onload = () => {
      const canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;

      const ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0, 75, 75);

      const dataURL = canvas.toDataURL('image/png');

      resolve(dataURL);
    };

    img.onerror = (error) => {
      reject(error);
    };

    img.src = url;
  });
}

export async function downloadPDF(listExportIds: any[]) {
  const zip = new JsZip();
  const currentTime = moment(<any> Date.now()).utc(true).format('HH:mm, DD-MM-YYYY');
  await Promise.all(listExportIds.map(async (item) => {
    const results = await gqlClient.query({
      query: newRACampaignQuery.GET_PDF_EXPORT_DATA,
      variables: {
        id: item
      },
      fetchPolicy: 'network-only'
    });
    if (results.errors) {
      throw results.errors;
    }

    const data = results.data;
    const docDefinition = {
      content: [
        {
          table: {
            headerRows: 1,
            widths: ['*', '*'],
            body: [
              [
                {
                  image: await getBase64Image(
                    '/assets/img/logoDaikin.svg'
                  ),
                  width: 229,
                  height: 48,
                  border: [false, false, false, false],
                },
                {
                  table: {
                    width: ['*'],
                    alignment: 'right',
                    body: [
                      [
                        {
                          text: 'DAIKIN AIR CONDITIONING (VIETNAM) JSC.',
                          border: [false, false, false, false],
                          alignment: 'left',
                          fontSize: 10,
                          bold: 'true',
                        },
                      ],
                      [
                        {
                          text: 'Head office:',
                          border: [false, false, false, false],
                          fontSize: 5,
                          alignment: 'left',
                        },
                      ],
                      [
                        {
                          // tslint:disable-next-line:max-line-length
                          text: 'Nam A Office Tower, 12th Floor, 201-203 Cach Mang Thang Tam Street, Ward 4, District 3, Ho Chi Minh City',
                          border: [false, false, false, false],
                          fontSize: 5,
                          alignment: 'left',
                        },
                      ],
                      [
                        {
                          text: 'Tel: +84 28 6250 4888 - Tax code : 0301450108',
                          border: [false, false, false, false],
                          fontSize: 5,
                          alignment: 'left',
                        },
                      ],
                    ],
                    // alignment: 'right',
                  },
                  border: [false, false, false, false],
                  alignment: 'right',
                },
              ],
            ],
          },
          border: [true, true, true, true],
        },
        {
          columns: [
            {
              width: '50%',
              text: ' ',
              bold: 'true',
              alignment: 'center',
              lineHeight: '1',
            },
          ],
        },
        {
          columns: [
            // { width: '50%', text: ' ' },
            {
              width: '100%',
              text: 'BIÊN BẢN XÁC NHẬN',
              bold: 'true',
              fontSize: 26,
              alignment: 'center',
              lineHeight: '1',
            },
          ],
        },
        {
          columns: [
            {
              width: '100%',
              text: '(Theo thông báo số M0201/O5A-AN _Ext ngày 01/02/2023)',
              fontSize: 12,
              color: '#767676',
              alignment: 'center',
              lineHeight: '1',
            },
          ],
        },
        {
          columns: [
            {
              width: '*',
              margin: [9, 20, 0, 0],
              text: ' THÔNG TIN ĐẠI LÝ TRỰC TIẾP ',
              bold: 'true',
              // background: '#BEBEBE',
              lineHeight: '1',
            },
          ],
        },
        {
          table: {
            headerRows: 1,
            widths: ['100%'],
            body: [
              [
                {
                  table: {
                    widths: ['50%', '50%'],
                    body: [
                      [
                        {
                          columns: [
                            {
                              text: 'Tên đại lý: ',
                              border: [false, false, false, false],
                              alignment: 'left',
                              width: 60,
                            },
                            {
                              text: `${data && data.acl_campaigns_2023 && data.acl_campaigns_2023.length
                              && data.acl_campaigns_2023[0].dealer && data.acl_campaigns_2023[0].dealer.name ?
                              data.acl_campaigns_2023[0].dealer.name : '--'}`,
                              border: [false, false, false, false],
                              alignment: 'right',
                              width: '*',
                            }
                          ],
                          border: [false, false, false, false],
                        },
                        {
                          columns: [
                            {
                              text: 'Tên cửa hàng: ',
                              border: [false, false, false, false],
                              alignment: 'left',
                              width: 80,
                            },
                            {
                              text: `${data && data.acl_campaigns_2023 && data.acl_campaigns_2023.length
                                && data.acl_campaigns_2023[0].shop && data.acl_campaigns_2023[0].shop.name ?
                                data.acl_campaigns_2023[0].shop.name : '--'}`,
                              border: [false, false, false, false],
                              alignment: 'right',
                              width: '*',
                            }
                          ],
                          border: [false, false, false, false],
                        }
                      ],
                      [
                        {
                          columns: [
                            {
                              text: 'Mã đại lý:',
                              border: [false, false, false, false],
                              alignment: 'left',
                              width: '*',
                            },
                            {
                              text: `${data && data.acl_campaigns_2023 && data.acl_campaigns_2023.length
                                && data.acl_campaigns_2023[0].dealer && data.acl_campaigns_2023[0].dealer.dealerCode ?
                                data.acl_campaigns_2023[0].dealer.dealerCode : '--'}`,
                              border: [false, false, false, false],
                              alignment: 'right',
                              width: '*',
                            }
                          ],
                          border: [false, false, false, false],
                        },
                        {
                          columns: [
                            {
                              text: 'Mã cửa hàng:',
                              border: [false, false, false, false],
                              alignment: 'left',
                              width: '*',
                            },
                            {
                              text: `${data && data.acl_campaigns_2023 && data.acl_campaigns_2023.length
                                && data.acl_campaigns_2023[0].shop && data.acl_campaigns_2023[0].shop.shopCode ?
                                data.acl_campaigns_2023[0].shop.shopCode : '--'}`,
                              border: [false, false, false, false],
                              alignment: 'right',
                              width: '*',
                            }
                          ],
                          border: [false, false, false, false],
                        }
                      ],
                      [
                        {
                          columns: [
                            {
                              text: 'Địa chỉ:',
                              border: [false, false, false, false],
                              alignment: 'left',
                              width: 40,
                            },
                            {
                              text: `${data && data.acl_campaigns_2023 && data.acl_campaigns_2023.length
                                && data.acl_campaigns_2023[0].dealer && data.acl_campaigns_2023[0].dealer.address ?
                                addressFormater(data.acl_campaigns_2023[0].dealer.address) : '--'}`,
                              border: [false, false, false, false],
                              alignment: 'right',
                              width: 'auto',
                            }
                          ],
                          border: [false, false, false, false],
                        },
                        {
                          columns: [
                            {
                              text: 'Địa chỉ cửa hàng:',
                              border: [false, false, false, false],
                              alignment: 'left',
                              width: 95,
                            },
                            {
                              text: `${data && data.acl_campaigns_2023 && data.acl_campaigns_2023.length
                                && data.acl_campaigns_2023[0].shop && data.acl_campaigns_2023[0].shop.address ?
                                addressFormater(data.acl_campaigns_2023[0].shop.address) : '--'}`,
                              border: [false, false, false, false],
                              alignment: 'right',
                              width: 'auto',
                            }
                          ],
                          border: [false, false, false, false],
                        }
                      ],
                      [
                        {
                          columns: [
                            {
                              text: 'Mã số thuế:',
                              border: [false, false, false, false],
                              alignment: 'left',
                              width: '*',
                            },
                            {
                              text: `${data && data.acl_campaigns_2023 && data.acl_campaigns_2023.length
                                && data.acl_campaigns_2023[0].dealer && data.acl_campaigns_2023[0].dealer.taxNumber ?
                                data.acl_campaigns_2023[0].dealer.taxNumber : '--'}`,
                              border: [false, false, false, false],
                              alignment: 'right',
                              width: '*',
                            }
                          ],
                          border: [false, false, false, false],
                        },
                        {
                          columns: [
                            {
                              text: 'Kết quả:',
                              border: [false, false, false, false],
                              alignment: 'left',
                              width: '*',
                            },
                            {
                              text: `${data && data.acl_campaigns_2023 && data.acl_campaigns_2023.length
                                && data.acl_campaigns_2023[0].requestStatus &&
                                data.acl_campaigns_2023[0].requestStatus === 'approved' ?
                                'Đủ điều kiện' : 'Không đủ điều kiện'}`,
                              border: [false, false, false, false],
                              alignment: 'right',
                              width: '*',
                            }
                          ],
                          border: [false, false, false, false],
                        }
                      ]
                    ],
                    alignment: 'left',
                  },
                  alignment: 'left',
                },
              ],
            ],
          },
          border: [true, true, true, true],
          margin: [7, 10, 0, 0]
        },
        {
          columns: [
            {
              width: '*',
              margin: [9, 20, 0, 0],
              text: ' THÔNG TIN SẢN PHẨM TRƯNG BÀY ',
              bold: 'true',
              // background: '#BEBEBE',
              lineHeight: '1',
            },
          ],
        },
        {
          table: {
            widths: ['100%'],
            body: [
              [
                {
                  table: {
                    widths: ['50%', '50%'],
                    body: [
                      [
                        {
                          columns: [
                            {
                              text: 'Model FTKY, FTXV, FTKZ (Số lượng)',
                              border: [false, false, false, false],
                              alignment: 'left',
                              width: '*',
                              bold: true
                            },
                            {
                              text: `${data && data.acl_campaigns_2023 && data.acl_campaigns_2023.length
                              && data.acl_campaigns_2023[0].model1 ? data.acl_campaigns_2023[0].model1 : '--'}
                              (${data && data.acl_campaigns_2023 && data.acl_campaigns_2023.length
                                && data.acl_campaigns_2023[0].quantityModel1 ?
                                data.acl_campaigns_2023[0].quantityModel1 : '0'})`,
                              border: [false, false, false, false],
                              alignment: 'right',
                              width: '*',
                            }
                          ],
                          border: [false, false, false, false],
                        },
                        {
                          columns: [
                            {
                              text: 'Model FTHF, FTKF, FTKB (Số lượng)',
                              border: [false, false, false, false],
                              alignment: 'left',
                              width: '*',
                              bold: 'true',
                            },
                            {
                              text: `${data && data.acl_campaigns_2023 && data.acl_campaigns_2023.length
                                && data.acl_campaigns_2023[0].model2 ? data.acl_campaigns_2023[0].model2 : '--'}
                                (${data && data.acl_campaigns_2023 && data.acl_campaigns_2023.length
                                  && data.acl_campaigns_2023[0].quantityModel2 ?
                                  data.acl_campaigns_2023[0].quantityModel2 : '0'})`,
                              border: [false, false, false, false],
                              alignment: 'right',
                              width: '*',
                            }
                          ],
                          border: [false, false, false, false],
                        }
                      ],
                      [
                        {
                          columns: [
                            {
                              text: 'Other Model (Số lượng)',
                              border: [false, false, false, false],
                              alignment: 'left',
                              width: '*',
                              bold: true
                            },
                            {
                              text: `${data && data.acl_campaigns_2023 && data.acl_campaigns_2023.length
                                && data.acl_campaigns_2023[0].model3 ? data.acl_campaigns_2023[0].model3 : '--'}
                                (${data && data.acl_campaigns_2023 && data.acl_campaigns_2023.length
                                  && data.acl_campaigns_2023[0].quantityModel3 ?
                                  data.acl_campaigns_2023[0].quantityModel3 : '0'})`,
                              border: [false, false, false, false],
                              alignment: 'right',
                              width: '*',
                            }
                          ],
                          border: [false, false, false, false],
                        },
                        {
                          columns: [
                            {
                              text: '',
                              border: [false, false, false, false],
                              alignment: 'left',
                              width: '*',
                              bold: 'true',
                            }
                          ],
                          border: [false, false, false, false],
                        }
                      ]
                    ],
                    alignment: 'left',
                  },
                  alignment: 'left',
                },
              ],
            ],
          },
          margin: [7, 10, 0, 0],
          border: [true, true, true, true],
        },
        {
          columns: [
            {
              width: '*',
              margin: [9, 20, 20, 0],
              text: ' HÌNH ẢNH TRƯNG BÀY TẠI CỬA HÀNG',
              bold: 'true',
              // background: '#BEBEBE',
              lineHeight: '1',
            },
          ],
        },
        {
          table: {
            widths: ['*', '*', '*', '*'],
            body: [
              [
                {
                  text: '1. Mặt tiền cửa hàng',
                  alignment: 'center',
                  italics: 'true',

                  border: [true, true, true, true],
                },
                {
                  text: '2. Bên trong cửa hàng',
                  alignment: 'center',
                  italics: 'true',

                  border: [false, true, true, true],
                },
                {
                  text: '3. Chụp gần',
                  alignment: 'center',
                  italics: 'true',

                  border: [false, true, true, true],

                },
                {
                  text: '4. Chụp xa',
                  alignment: 'center',
                  italics: 'true',

                  border: [false, true, true, true],
                },
              ],
              [
                {
                  image: await getBase64ImageFromURL(
                    this.ConfigCropPicture === 'YES' && data.acl_campaigns_2023[0].resizedPictureURLs.front ?
                      data.acl_campaigns_2023[0].resizedPictureURLs.front :
                      data.acl_campaigns_2023[0].pictureURLs.front ? data.acl_campaigns_2023[0].pictureURLs.front : ''
                  ),
                  fit: [100, 100],
                  alignment: 'center',
                  border: [true, false, true, true],
                },
                // tslint:disable-next-line:max-line-length
                {
                  image: await getBase64ImageFromURL(
                    this.ConfigCropPicture === 'YES' && data.acl_campaigns_2023[0].resizedPictureURLs.inside ?
                      data.acl_campaigns_2023[0].resizedPictureURLs.inside :
                      data.acl_campaigns_2023[0].pictureURLs.inside ? data.acl_campaigns_2023[0].pictureURLs.inside : ''
                  ),
                  fit: [100, 100],
                  alignment: 'center',
                  border: [false, false, true, true],
                },
                {
                  image: await getBase64ImageFromURL(
                    this.ConfigCropPicture === 'YES' && data.acl_campaigns_2023[0].resizedPictureURLs.close ?
                      data.acl_campaigns_2023[0].resizedPictureURLs.close :
                      data.acl_campaigns_2023[0].pictureURLs.close ? data.acl_campaigns_2023[0].pictureURLs.close : ''
                  ),
                  fit: [100, 100],
                  alignment: 'center',
                  border: [false, false, true, true],
                },
                {
                  image: await getBase64ImageFromURL(
                    this.ConfigCropPicture === 'YES' && data.acl_campaigns_2023[0].resizedPictureURLs.far ?
                      data.acl_campaigns_2023[0].resizedPictureURLs.far :
                      data.acl_campaigns_2023[0].pictureURLs.far ? data.acl_campaigns_2023[0].pictureURLs.far : ''
                  ),
                  fit: [100, 100],
                  alignment: 'center',
                  border: [false, false, true, true],
                },
              ],
            ],
          },
          width: '100%',
          margin: [9, 10, 0, 0],
        },
        {
          columns: [
            {
              width: '*',
              margin: [9, 20, 0, 0],
              text: ' ĐÁNH GIÁ TRƯNG BÀY ',
              bold: 'true',
              // background: '#BEBEBE',
              lineHeight: '1',
            },
          ],
        },
        {
          columns: [
            {
              text: `${data && data.acl_campaigns_2023 && data.acl_campaigns_2023.length
                && data.acl_campaigns_2023[0] && data.acl_campaigns_2023[0].logs &&
                data.acl_campaigns_2023[0].logs.length && data.acl_campaigns_2023[0].logs.length > 1
                 && data.acl_campaigns_2023[0].logs[1].createdAt ?
                moment(data.acl_campaigns_2023[0].logs[1].createdAt).format(`HH:mm, DD/MM/YYYY`) : '--'}`,
              border: [false, false, false, false],
              alignment: 'left',
              width: 100,
            },
            {
              text: 'Cửa hàng đăng tải hình ảnh trưng bày',
              border: [false, false, false, false],
              alignment: 'left',
              width: '*',
              margin: [24, 0, 0, 0],
            }
          ],
          width: '100%',
          border: [false, false, false, false],
          margin: [9, 10, 0, 0],
        },
        {
          columns: [
            {
              text: `${data && data.acl_campaigns_2023 && data.acl_campaigns_2023.length
              && data.acl_campaigns_2023[0] && data.acl_campaigns_2023[0].reviews &&
              data.acl_campaigns_2023[0].reviews.length ?
            moment(data.acl_campaigns_2023[0].reviews[0].createdAt).format(`HH:mm, DD/MM/YYYY`) : '--'}`,
              border: [false, false, false, false],
              alignment: 'left',
              width: 100,
            },
            {
              text: `Đánh giá lần đầu`,
              border: [false, false, false, false],
              alignment: 'left',
              width: 120,
              margin: [24, 0, 0, 0],
            },
            {
              text: `DAV Sales Staff [${data && data.acl_campaigns_2023 && data.acl_campaigns_2023.length
                && data.acl_campaigns_2023[0] && data.acl_campaigns_2023[0].reviews &&
                data.acl_campaigns_2023[0].reviews.length && data.acl_campaigns_2023[0].reviews[0].createdUser
                && data.acl_campaigns_2023[0].reviews[0].createdUser.name ?
                data.acl_campaigns_2023[0].reviews[0].createdUser.name : '--'}]`,
              border: [false, false, false, false],
              alignment: 'left',
              width: '*',
              margin: [24, 0, 0, 0],
            }
          ],
          width: '100%',
          border: [false, false, false, false],
          margin: [9, 10, 0, 0],
        },
        {
          columns: [
            {
              text: `${data && data.acl_campaigns_2023 && data.acl_campaigns_2023.length
                && data.acl_campaigns_2023[0] && data.acl_campaigns_2023[0].saleManager &&
                data.acl_campaigns_2023[0].saleManager.length && data.acl_campaigns_2023[0].saleManager[0].request
                && data.acl_campaigns_2023[0].saleManager[0].request.histories
                && data.acl_campaigns_2023[0].saleManager[0].request.histories.length
                && data.acl_campaigns_2023[0].saleManager[0].request.histories[0].createdAt ?
            // tslint:disable-next-line:max-line-length
            moment(data.acl_campaigns_2023[0].saleManager[0].request.histories[0].createdAt).format(`HH:mm, DD/MM/YYYY`) : '--'}`,
              border: [false, false, false, false],
              alignment: 'left',
              width: 100,
            },
            {
              text: `Đánh giá lần hai`,
              border: [false, false, false, false],
              alignment: 'left',
              width: 120,
              margin: [24, 0, 0, 0],
            },
            {
              text: `DAV Sales Manager [${data && data.acl_campaigns_2023 && data.acl_campaigns_2023.length
                && data.acl_campaigns_2023[0] && data.acl_campaigns_2023[0].saleManager &&
                data.acl_campaigns_2023[0].saleManager.length && data.acl_campaigns_2023[0].saleManager[0].request
                && data.acl_campaigns_2023[0].saleManager[0].request.histories
                && data.acl_campaigns_2023[0].saleManager[0].request.histories.length
                && data.acl_campaigns_2023[0].saleManager[0].request.histories[0].creator
                && data.acl_campaigns_2023[0].saleManager[0].request.histories[0].creator.name ?
                data.acl_campaigns_2023[0].saleManager[0].request.histories[0].creator.name : '--'}]`,
              border: [false, false, false, false],
              alignment: 'left',
              width: '*',
              margin: [24, 0, 0, 0],
            }
          ],
          width: '100%',
          border: [false, false, false, false],
          margin: [9, 10, 0, 0],
        },
        {
          columns: [
            {
              text: `${data && data.acl_campaigns_2023 && data.acl_campaigns_2023.length
              && data.acl_campaigns_2023[0] && data.acl_campaigns_2023[0].logs &&
              data.acl_campaigns_2023[0].logs.length && data.acl_campaigns_2023[0].logs[0].createdAt ?
              moment(data.acl_campaigns_2023[0].logs[0].createdAt).format(`HH:mm, DD/MM/YYYY`) : '--'}`,
              border: [false, false, false, false],
              alignment: 'left',
              width: 100,
            },
            {
              text: `Đánh giá lần cuối`,
              border: [false, false, false, false],
              alignment: 'left',
              width: 120,
              margin: [24, 0, 0, 0],
            },
            {
              text: `DAV Sales Planning [${data && data.acl_campaigns_2023 && data.acl_campaigns_2023.length
                && data.acl_campaigns_2023[0] && data.acl_campaigns_2023[0].logs &&
                data.acl_campaigns_2023[0] && data.acl_campaigns_2023[0].logs.length &&
                data.acl_campaigns_2023[0].logs[0].createdUser &&
                data.acl_campaigns_2023[0].logs[0].createdUser.name ?
                data.acl_campaigns_2023[0].logs[0].createdUser.name : '--'}]`,
              border: [false, false, false, false],
              alignment: 'left',
              width: '*',
              margin: [24, 0, 0, 0],
            }
          ],
          width: '100%',
          border: [false, false, false, false],
          margin: [9, 10, 0, 0],
        },
        {
          columns: [
            {
              text: `${data && data.acl_campaigns_2023 && data.acl_campaigns_2023.length
              && data.acl_campaigns_2023[0] && data.acl_campaigns_2023[0].dealerApproveAt ?
              moment(data.acl_campaigns_2023[0].dealerApproveAt).format(`HH:mm, DD/MM/YYYY`) : '--'}`,
              border: [false, false, false, false],
              alignment: 'left',
              width: 100,
            },
            {
              text: `Đại lý xác nhận đồng ý với kết quả đánh giá trưng bày.`,
              border: [false, false, false, false],
              alignment: 'left',
              width: '*',
              margin: [24, 0, 0, 0],
            }
          ],
          width: '100%',
          border: [false, false, false, false],
          margin: [9, 10, 0, 0],
        },
        {
          columns: [
            {
              width: '50%',
              text: ' ',
              bold: 'true',
              alignment: 'right',
              lineHeight: '1',
            },
          ],
        },
      ],
    };
    const subDocCloneDefinition = {
      content: [
        {
          table: {
            headerRows: 1,
            widths: ['*', '*'],
            body: [
              [
                {
                  image: await getBase64Image(
                    '/assets/img/logoDaikin.svg'
                  ),
                  width: 229,
                  height: 48,
                  border: [false, false, false, false],
                },
                {
                  table: {
                    width: ['*'],
                    alignment: 'right',
                    body: [
                      [
                        {
                          text: 'DAIKIN AIR CONDITIONING (VIETNAM) JSC.',
                          border: [false, false, false, false],
                          alignment: 'left',
                          fontSize: 10,
                          bold: 'true',
                        },
                      ],
                      [
                        {
                          text: 'Head office:',
                          border: [false, false, false, false],
                          fontSize: 5,
                          alignment: 'left',
                        },
                      ],
                      [
                        {
                          // tslint:disable-next-line:max-line-length
                          text: 'Nam A Office Tower, 12th Floor, 201-203 Cach Mang Thang Tam Street, Ward 4, District 3, Ho Chi Minh City',
                          border: [false, false, false, false],
                          fontSize: 5,
                          alignment: 'left',
                        },
                      ],
                      [
                        {
                          text: 'Tel: +84 28 6250 4888 - Tax code : 0301450108',
                          border: [false, false, false, false],
                          fontSize: 5,
                          alignment: 'left',
                        },
                      ],
                    ],
                    // alignment: 'right',
                  },
                  border: [false, false, false, false],
                  alignment: 'right',
                },
              ],
            ],
          },
          border: [true, true, true, true],
        },
        {
          columns: [
            {
              width: '50%',
              text: ' ',
              bold: 'true',
              alignment: 'center',
              lineHeight: '1',
            },
          ],
        },
        {
          columns: [
            // { width: '50%', text: ' ' },
            {
              width: '100%',
              text: 'BIÊN BẢN XÁC NHẬN',
              bold: 'true',
              fontSize: 26,
              alignment: 'center',
              lineHeight: '1',
            },
          ],
        },
        {
          columns: [
            {
              width: '100%',
              text: '(Theo thông báo số M0201/O5A-AN _Ext ngày 01/02/2023)',
              fontSize: 12,
              color: '#767676',
              alignment: 'center',
              lineHeight: '1',
            },
          ],
        },
        {
          columns: [
            {
              width: '*',
              margin: [9, 20, 0, 0],
              text: ' THÔNG TIN ĐẠI LÝ TRỰC TIẾP ',
              bold: 'true',
              alignment: 'left',
              // background: '#BEBEBE',
              lineHeight: '1',
            },
            {
              width: '*',
              margin: [9, 20, 0, 0],
              text: ' THÔNG TIN ĐẠI LÝ GIÁN TIẾP ',
              bold: 'true',
              alignment: 'right',
              // background: '#BEBEBE',
              lineHeight: '1',
            },
          ],
        },
        {
          table: {
            headerRows: 1,
            widths: ['100%'],
            body: [
              [
                {
                  table: {
                    widths: ['50%', '50%'],
                    body: [
                      [
                        {
                          columns: [
                            {
                              text: 'Tên đại lý: ',
                              border: [false, false, false, false],
                              alignment: 'left',
                              width: 60
                            },
                            {
                              text: `${data && data.acl_campaigns_2023 && data.acl_campaigns_2023.length
                                && data.acl_campaigns_2023[0].dealer &&
                                data.acl_campaigns_2023[0].dealer.assignedSubDealers2023 &&
                                data.acl_campaigns_2023[0].dealer.assignedSubDealers2023.length &&
                                data.acl_campaigns_2023[0].dealer.assignedSubDealers2023[0].dealer
                                && data.acl_campaigns_2023[0].dealer.assignedSubDealers2023[0].dealer.name ?
                                data.acl_campaigns_2023[0].dealer.assignedSubDealers2023[0].dealer.name : '--'}`,
                              border: [false, false, false, false],
                              alignment: 'right',
                              width: '*',
                            }
                          ],
                          border: [false, false, false, false],
                        },
                        {
                          columns: [
                            {
                              text: 'Tên đại lý: ',
                              border: [false, false, false, false],
                              alignment: 'left',
                              width: 120,
                            },
                            {
                              text: `${data && data.acl_campaigns_2023 && data.acl_campaigns_2023.length
                                && data.acl_campaigns_2023[0].dealer && data.acl_campaigns_2023[0].dealer.name ?
                                data.acl_campaigns_2023[0].dealer.name : '--'}`,
                              border: [false, false, false, false],
                              alignment: 'right',
                              width: '*',
                            }
                          ],
                          border: [false, false, false, false],
                        }
                      ],
                      [
                        {
                          columns: [
                            {
                              text: 'Mã đại lý:',
                              border: [false, false, false, false],
                              alignment: 'left',
                              width: '*',
                            },
                            {
                              text: `${data && data.acl_campaigns_2023 && data.acl_campaigns_2023.length
                                && data.acl_campaigns_2023[0].dealer &&
                                data.acl_campaigns_2023[0].dealer.assignedSubDealers2023 &&
                                data.acl_campaigns_2023[0].dealer.assignedSubDealers2023.length &&
                                data.acl_campaigns_2023[0].dealer.assignedSubDealers2023[0].dealer
                                && data.acl_campaigns_2023[0].dealer.assignedSubDealers2023[0].dealer.dealerCode ?
                                data.acl_campaigns_2023[0].dealer.assignedSubDealers2023[0].dealer.dealerCode
                                 : '--'}`,
                              border: [false, false, false, false],
                              alignment: 'right',
                              width: '*',
                            }
                          ],
                          border: [false, false, false, false],
                        },
                        {
                          columns: [
                            {
                              text: 'Mã đại lý:',
                              border: [false, false, false, false],
                              alignment: 'left',
                              width: '*',
                            },
                            {
                              text: `${data && data.acl_campaigns_2023 && data.acl_campaigns_2023.length
                                && data.acl_campaigns_2023[0].dealer && data.acl_campaigns_2023[0].dealer.dealerCode ?
                                data.acl_campaigns_2023[0].dealer.dealerCode : '--'}`,
                              border: [false, false, false, false],
                              alignment: 'right',
                              width: '*',
                            }
                          ],
                          border: [false, false, false, false],
                        }
                      ],
                      [
                        {
                          columns: [
                            {
                              text: 'Địa chỉ:',
                              border: [false, false, false, false],
                              alignment: 'left',
                              width: 40,
                            },
                            {
                              text: `${data && data.acl_campaigns_2023 && data.acl_campaigns_2023.length
                                && data.acl_campaigns_2023[0].dealer &&
                                data.acl_campaigns_2023[0].dealer.assignedSubDealers2023 &&
                                data.acl_campaigns_2023[0].dealer.assignedSubDealers2023.length &&
                                data.acl_campaigns_2023[0].dealer.assignedSubDealers2023[0].dealer
                                && data.acl_campaigns_2023[0].dealer.assignedSubDealers2023[0].dealer.address ?
                                // tslint:disable-next-line:max-line-length
                                addressFormater(data.acl_campaigns_2023[0].dealer.assignedSubDealers2023[0].dealer.address) : '--'}`,
                              border: [false, false, false, false],
                              alignment: 'right',
                              width: '*',
                            }
                          ],
                          border: [false, false, false, false],
                        },
                        {
                          columns: [
                            {
                              text: 'Địa chỉ:',
                              border: [false, false, false, false],
                              alignment: 'left',
                              width: 40,
                            },
                            {
                              text: `${data && data.acl_campaigns_2023 && data.acl_campaigns_2023.length
                                && data.acl_campaigns_2023[0].dealer && data.acl_campaigns_2023[0].dealer.address ?
                                addressFormater(data.acl_campaigns_2023[0].dealer.address) : '--'}`,
                              border: [false, false, false, false],
                              alignment: 'right',
                              width: '*',
                            }
                          ],
                          border: [false, false, false, false],
                        }
                      ],
                      [
                        {
                          columns: [
                            {
                              text: 'Mã số thuế',
                              border: [false, false, false, false],
                              alignment: 'left',
                              width: '*',
                            },
                            {
                              text: `${data && data.acl_campaigns_2023 && data.acl_campaigns_2023.length
                                && data.acl_campaigns_2023[0].dealer &&
                                data.acl_campaigns_2023[0].dealer.assignedSubDealers2023 &&
                                data.acl_campaigns_2023[0].dealer.assignedSubDealers2023.length &&
                                data.acl_campaigns_2023[0].dealer.assignedSubDealers2023[0].dealer
                                && data.acl_campaigns_2023[0].dealer.assignedSubDealers2023[0].dealer.taxNumber ?
                                data.acl_campaigns_2023[0].dealer.assignedSubDealers2023[0].dealer.taxNumber : '--'}`,
                              border: [false, false, false, false],
                              alignment: 'right',
                              width: '*',
                            }
                          ],
                          border: [false, false, false, false],
                        },
                        {
                          columns: [
                            {
                              text: 'Kết quả',
                              border: [false, false, false, false],
                              alignment: 'left',
                              width: '*',
                            },
                            {
                              text: `${data && data.acl_campaigns_2023 && data.acl_campaigns_2023.length
                                && data.acl_campaigns_2023[0].requestStatus &&
                                data.acl_campaigns_2023[0].requestStatus === 'approved' ?
                                'Đủ điều kiện' : 'Không đủ điều kiện'}`,
                              border: [false, false, false, false],
                              alignment: 'right',
                              width: '*',
                            }
                          ],
                          border: [false, false, false, false],
                        }
                      ]
                    ],
                    alignment: 'left',
                  },
                  alignment: 'left',
                },
              ],
            ],
          },
          border: [true, true, true, true],
          margin: [7, 10, 0, 0]
        },
        {
          columns: [
            {
              width: '*',
              margin: [9, 20, 0, 0],
              text: ' THÔNG TIN SẢN PHẨM TRƯNG BÀY ',
              bold: 'true',
              // background: '#BEBEBE',
              lineHeight: '1',
            },
          ],
        },
        {
          table: {
            widths: ['100%'],
            body: [
              [
                {
                  table: {
                    widths: ['50%', '50%'],
                    body: [
                      [
                        {
                          columns: [
                            {
                              text: 'Model FTKY, FTXV, FTKZ (Số lượng)',
                              border: [false, false, false, false],
                              alignment: 'left',
                              width: '*',
                              bold: true
                            },
                            {
                              text: `${data && data.acl_campaigns_2023 && data.acl_campaigns_2023.length
                                && data.acl_campaigns_2023[0].model1 ? data.acl_campaigns_2023[0].model1 : '--'}
                                (${data && data.acl_campaigns_2023 && data.acl_campaigns_2023.length
                                  && data.acl_campaigns_2023[0].quantityModel1 ?
                                  data.acl_campaigns_2023[0].quantityModel1 : '0'})`,
                              border: [false, false, false, false],
                              alignment: 'right',
                              width: '*',
                            }
                          ],
                          border: [false, false, false, false],
                        },
                        {
                          columns: [
                            {
                              text: 'Model FTHF, FTKF, FTKB (Số lượng)',
                              border: [false, false, false, false],
                              alignment: 'left',
                              width: '*',
                              bold: 'true',
                            },
                            {
                              text: `${data && data.acl_campaigns_2023 && data.acl_campaigns_2023.length
                                && data.acl_campaigns_2023[0].model2 ? data.acl_campaigns_2023[0].model2 : '--'}
                                (${data && data.acl_campaigns_2023 && data.acl_campaigns_2023.length
                                  && data.acl_campaigns_2023[0].quantityModel2 ?
                                  data.acl_campaigns_2023[0].quantityModel2 : '0'})`,
                              border: [false, false, false, false],
                              alignment: 'right',
                              width: '*',
                            }
                          ],
                          border: [false, false, false, false],
                        }
                      ],
                      [
                        {
                          columns: [
                            {
                              text: 'Other Model (Số lượng)',
                              border: [false, false, false, false],
                              alignment: 'left',
                              width: '*',
                              bold: true
                            },
                            {
                              text: `${data && data.acl_campaigns_2023 && data.acl_campaigns_2023.length
                                && data.acl_campaigns_2023[0].model3 ? data.acl_campaigns_2023[0].model3 : '--'}
                                (${data && data.acl_campaigns_2023 && data.acl_campaigns_2023.length
                                  && data.acl_campaigns_2023[0].quantityModel3 ?
                                  data.acl_campaigns_2023[0].quantityModel3 : '0'})`,
                              border: [false, false, false, false],
                              alignment: 'right',
                              width: '*',
                            }
                          ],
                          border: [false, false, false, false],
                        },
                        {
                          columns: [
                            {
                              text: '',
                              border: [false, false, false, false],
                              alignment: 'left',
                              width: '*',
                              bold: 'true',
                            }
                          ],
                          border: [false, false, false, false],
                        }
                      ]
                    ],
                    alignment: 'left',
                  },
                  alignment: 'left',
                },
              ],
            ],
          },
          margin: [7, 10, 0, 0],
          border: [true, true, true, true],
        },
        {
          columns: [
            {
              width: '*',
              margin: [9, 20, 20, 0],
              text: ' HÌNH ẢNH TRƯNG BÀY TẠI CỬA HÀNG',
              bold: 'true',
              // background: '#BEBEBE',
              lineHeight: '1',
            },
          ],
        },
        {
          table: {
            widths: ['*', '*', '*', '*'],
            body: [
              [
                {
                  text: '1. Mặt tiền cửa hàng',
                  alignment: 'center',
                  italics: 'true',

                  border: [true, true, true, true],
                },
                {
                  text: '2. Bên trong cửa hàng',
                  alignment: 'center',
                  italics: 'true',

                  border: [false, true, true, true],
                },
                {
                  text: '3. Chụp gần',
                  alignment: 'center',
                  italics: 'true',

                  border: [false, true, true, true],

                },
                {
                  text: '4. Chụp xa',
                  alignment: 'center',
                  italics: 'true',

                  border: [false, true, true, true],
                },
              ],
              [
                {
                  image: await getBase64ImageFromURL(
                    this.ConfigCropPicture === 'YES' && data.acl_campaigns_2023[0].resizedPictureURLs.front ?
                      data.acl_campaigns_2023[0].resizedPictureURLs.front :
                      data.acl_campaigns_2023[0].pictureURLs.front ? data.acl_campaigns_2023[0].pictureURLs.front : ''
                  ),
                  fit: [100, 100],
                  alignment: 'center',
                  border: [true, false, true, true],
                },
                // tslint:disable-next-line:max-line-length
                {
                  image: await getBase64ImageFromURL(
                    this.ConfigCropPicture === 'YES' && data.acl_campaigns_2023[0].resizedPictureURLs.inside ?
                      data.acl_campaigns_2023[0].resizedPictureURLs.inside :
                      data.acl_campaigns_2023[0].pictureURLs.inside ? data.acl_campaigns_2023[0].pictureURLs.inside : ''
                  ),
                  fit: [100, 100],
                  alignment: 'center',
                  border: [false, false, true, true],
                },
                {
                  image: await getBase64ImageFromURL(
                    this.ConfigCropPicture === 'YES' && data.acl_campaigns_2023[0].resizedPictureURLs.close ?
                      data.acl_campaigns_2023[0].resizedPictureURLs.close :
                      data.acl_campaigns_2023[0].pictureURLs.close ? data.acl_campaigns_2023[0].pictureURLs.close : ''
                  ),
                  fit: [100, 100],
                  alignment: 'center',
                  border: [false, false, true, true],
                },
                {
                  image: await getBase64ImageFromURL(
                    this.ConfigCropPicture === 'YES' && data.acl_campaigns_2023[0].resizedPictureURLs.far ?
                      data.acl_campaigns_2023[0].resizedPictureURLs.far :
                      data.acl_campaigns_2023[0].pictureURLs.far ? data.acl_campaigns_2023[0].pictureURLs.far : ''
                  ),
                  fit: [100, 100],
                  alignment: 'center',
                  border: [false, false, true, true],
                },
              ],
            ],
          },
          width: '100%',
          margin: [9, 10, 0, 0],
        },
        {
          columns: [
            {
              width: '*',
              margin: [9, 20, 20, 0],
              text: ' HÌNH ẢNH CHỨNG TỪ MUA HÀNG',
              bold: 'true',
              // background: '#BEBEBE',
              lineHeight: '1',
            },
          ],
        },
        {
          table: {
            widths: ['*', '*', '*', '*', '*', '*'],
            body: [
              [
                {
                  image: await getBase64ImageFromURLResize(
                    data && data.acl_campaigns_2023.length && data.acl_campaigns_2023[0].dealer
                  && data.acl_campaigns_2023[0].dealer.information2023
                  && data.acl_campaigns_2023[0].dealer.information2023.productReceiptPicURLs
                  && data.acl_campaigns_2023[0].dealer.information2023.productReceiptPicURLs.length > 0 ?
                  data.acl_campaigns_2023[0].dealer.information2023.productReceiptPicURLs[0] :
                  '/assets/img/empty-document.svg'
                  ),
                  fit: [75, 75],
                  alignment: 'center',
                  border: [true, true, true, true],
                },
                {
                  image: await getBase64ImageFromURLResize(
                    data && data.acl_campaigns_2023.length && data.acl_campaigns_2023[0].dealer
                  && data.acl_campaigns_2023[0].dealer.information2023
                  && data.acl_campaigns_2023[0].dealer.information2023.productReceiptPicURLs
                  && data.acl_campaigns_2023[0].dealer.information2023.productReceiptPicURLs.length > 1 ?
                  data.acl_campaigns_2023[0].dealer.information2023.productReceiptPicURLs[1] :
                  '/assets/img/empty-document.svg'
                  ),
                  fit: [75, 75],
                  alignment: 'center',
                  border: [true, true, true, true],
                },
                {
                  image: await getBase64ImageFromURLResize(
                    data && data.acl_campaigns_2023.length && data.acl_campaigns_2023[0].dealer
                  && data.acl_campaigns_2023[0].dealer.information2023
                  && data.acl_campaigns_2023[0].dealer.information2023.productReceiptPicURLs
                  && data.acl_campaigns_2023[0].dealer.information2023.productReceiptPicURLs.length > 2 ?
                  data.acl_campaigns_2023[0].dealer.information2023.productReceiptPicURLs[2] :
                   '/assets/img/empty-document.svg'
                  ),
                  fit: [75, 75],
                  alignment: 'center',
                  border: [true, true, true, true],
                },
                {
                  image: await getBase64ImageFromURLResize(
                    data && data.acl_campaigns_2023.length && data.acl_campaigns_2023[0].dealer
                  && data.acl_campaigns_2023[0].dealer.information2023
                  && data.acl_campaigns_2023[0].dealer.information2023.productReceiptPicURLs
                  && data.acl_campaigns_2023[0].dealer.information2023.productReceiptPicURLs.length > 3 ?
                  data.acl_campaigns_2023[0].dealer.information2023.productReceiptPicURLs[3] :
                  '/assets/img/empty-document.svg'
                  ),
                  fit: [75, 75],
                  alignment: 'center',
                  border: [true, true, true, true],
                },
                {
                  image: await getBase64ImageFromURLResize(
                    data && data.acl_campaigns_2023.length && data.acl_campaigns_2023[0].dealer
                  && data.acl_campaigns_2023[0].dealer.information2023
                  && data.acl_campaigns_2023[0].dealer.information2023.productReceiptPicURLs
                  && data.acl_campaigns_2023[0].dealer.information2023.productReceiptPicURLs.length > 4 ?
                  data.acl_campaigns_2023[0].dealer.information2023.productReceiptPicURLs[4] :
                  '/assets/img/empty-document.svg'
                  ),
                  fit: [75, 75],
                  alignment: 'center',
                  border: [true, true, true, true],
                },
                {
                  image: await getBase64ImageFromURLResize(
                    data && data.acl_campaigns_2023.length && data.acl_campaigns_2023[0].dealer
                  && data.acl_campaigns_2023[0].dealer.information2023
                  && data.acl_campaigns_2023[0].dealer.information2023.productReceiptPicURLs
                  && data.acl_campaigns_2023[0].dealer.information2023.productReceiptPicURLs.length > 5 ?
                  data.acl_campaigns_2023[0].dealer.information2023.productReceiptPicURLs[5] :
                  '/assets/img/empty-document.svg'
                  ),
                  fit: [75, 75],
                  alignment: 'center',
                  border: [true, true, true, true],
                }
              ],
              [
                {
                  text: '1. Chứng từ 1',
                  alignment: 'center',
                  italics: 'true',
                  border: [true, false, true, true],
                },
                {
                  text: '2. Chứng từ 2',
                  alignment: 'center',
                  italics: 'true',

                  border: [false, false, true, true],
                },
                {
                  text: '3. Chứng từ 3',
                  alignment: 'center',
                  italics: 'true',
                  border: [false, false, true, true],
                },
                {
                  text: '4. Chứng từ 4',
                  alignment: 'center',
                  italics: 'true',
                  border: [false, false, true, true],
                },
                {
                  text: '5. Chứng từ 5',
                  alignment: 'center',
                  italics: 'true',
                  border: [false, false, true, true],
                },
                {
                  text: '6. Chứng từ 6',
                  alignment: 'center',
                  italics: 'true',
                  border: [false, false, true, true],
                },
              ],
            ],
          },
          width: '100%',
          margin: [9, 10, 0, 0],
        },
        {
          columns: [
            {
              width: '*',
              margin: [9, 20, 0, 0],
              text: ' ĐÁNH GIÁ TRƯNG BÀY ',
              bold: 'true',
              // background: '#BEBEBE',
              lineHeight: '1',
            },
          ],
        },
        {
          columns: [
            {
              text: `${data && data.acl_campaigns_2023 && data.acl_campaigns_2023.length
                && data.acl_campaigns_2023[0] && data.acl_campaigns_2023[0].logs &&
                data.acl_campaigns_2023[0].logs.length && data.acl_campaigns_2023[0].logs.length > 1
                 && data.acl_campaigns_2023[0].logs[1].createdAt ?
                moment(data.acl_campaigns_2023[0].logs[1].createdAt).format(`HH:mm, DD/MM/YYYY`) : '--'}`,
              border: [false, false, false, false],
              alignment: 'left',
              width: 100,
            },
            {
              text: 'Cửa hàng đăng tải hình ảnh trưng bày',
              border: [false, false, false, false],
              alignment: 'left',
              width: '*',
              margin: [24, 0, 0, 0],
            }
          ],
          width: '100%',
          border: [false, false, false, false],
          margin: [9, 10, 0, 0],
        },
        {
          columns: [
            {
              text: `${data && data.acl_campaigns_2023 && data.acl_campaigns_2023.length
              && data.acl_campaigns_2023[0] && data.acl_campaigns_2023[0].reviews &&
              data.acl_campaigns_2023[0].reviews.length ?
            moment(data.acl_campaigns_2023[0].reviews[0].createdAt).format(`HH:mm, DD/MM/YYYY`) : '--'}`,
              border: [false, false, false, false],
              alignment: 'left',
              width: 100,
            },
            {
              text: `Đánh giá lần đầu`,
              border: [false, false, false, false],
              alignment: 'left',
              width: 120,
              margin: [24, 0, 0, 0],
            },
            {
              text: `DAV Sales Staff [${data && data.acl_campaigns_2023 && data.acl_campaigns_2023.length
                && data.acl_campaigns_2023[0] && data.acl_campaigns_2023[0].reviews &&
                data.acl_campaigns_2023[0].reviews.length && data.acl_campaigns_2023[0].reviews[0].createdUser
                && data.acl_campaigns_2023[0].reviews[0].createdUser.name ?
                data.acl_campaigns_2023[0].reviews[0].createdUser.name : '--'}]`,
              border: [false, false, false, false],
              alignment: 'left',
              width: '*',
              margin: [24, 0, 0, 0],
            }
          ],
          width: '100%',
          border: [false, false, false, false],
          margin: [9, 10, 0, 0],
        },
        {
          columns: [
            {
              text: `${data && data.acl_campaigns_2023 && data.acl_campaigns_2023.length
                && data.acl_campaigns_2023[0] && data.acl_campaigns_2023[0].saleManager &&
                data.acl_campaigns_2023[0].saleManager.length && data.acl_campaigns_2023[0].saleManager[0].request
                && data.acl_campaigns_2023[0].saleManager[0].request.histories
                && data.acl_campaigns_2023[0].saleManager[0].request.histories.length
                && data.acl_campaigns_2023[0].saleManager[0].request.histories[0].createdAt ?
            // tslint:disable-next-line:max-line-length
            moment(data.acl_campaigns_2023[0].saleManager[0].request.histories[0].createdAt).format(`HH:mm, DD/MM/YYYY`) : '--'}`,
              border: [false, false, false, false],
              alignment: 'left',
              width: 100,
            },
            {
              text: `Đánh giá lần hai`,
              border: [false, false, false, false],
              alignment: 'left',
              width: 120,
              margin: [24, 0, 0, 0],
            },
            {
              text: `DAV Sales Manager [${data && data.acl_campaigns_2023 && data.acl_campaigns_2023.length
                && data.acl_campaigns_2023[0] && data.acl_campaigns_2023[0].saleManager &&
                data.acl_campaigns_2023[0].saleManager.length && data.acl_campaigns_2023[0].saleManager[0].request
                && data.acl_campaigns_2023[0].saleManager[0].request.histories
                && data.acl_campaigns_2023[0].saleManager[0].request.histories.length
                && data.acl_campaigns_2023[0].saleManager[0].request.histories[0].creator
                && data.acl_campaigns_2023[0].saleManager[0].request.histories[0].creator.name ?
                data.acl_campaigns_2023[0].saleManager[0].request.histories[0].creator.name : '--'}]`,
              border: [false, false, false, false],
              alignment: 'left',
              width: '*',
              margin: [24, 0, 0, 0],
            }
          ],
          width: '100%',
          border: [false, false, false, false],
          margin: [9, 10, 0, 0],
        },
        {
          columns: [
            {
              text: `${data && data.acl_campaigns_2023 && data.acl_campaigns_2023.length
              && data.acl_campaigns_2023[0] && data.acl_campaigns_2023[0].logs &&
              data.acl_campaigns_2023[0].logs.length && data.acl_campaigns_2023[0].logs[0].createdAt ?
              moment(data.acl_campaigns_2023[0].logs[0].createdAt).format(`HH:mm, DD/MM/YYYY`) : '--'}`,
              border: [false, false, false, false],
              alignment: 'left',
              width: 100,
            },
            {
              text: `Đánh giá lần cuối`,
              border: [false, false, false, false],
              alignment: 'left',
              width: 120,
              margin: [24, 0, 0, 0],
            },
            {
              text: `DAV Sales Planning [${data && data.acl_campaigns_2023 && data.acl_campaigns_2023.length
                && data.acl_campaigns_2023[0] && data.acl_campaigns_2023[0].logs &&
                data.acl_campaigns_2023[0] && data.acl_campaigns_2023[0].logs.length &&
                data.acl_campaigns_2023[0].logs[0].createdUser &&
                data.acl_campaigns_2023[0].logs[0].createdUser.name ?
                data.acl_campaigns_2023[0].logs[0].createdUser.name : '--'}]`,
              border: [false, false, false, false],
              alignment: 'left',
              width: '*',
              margin: [24, 0, 0, 0],
            }
          ],
          width: '100%',
          border: [false, false, false, false],
          margin: [9, 10, 0, 0],
        },
        {
          columns: [
            {
              text: `${data && data.acl_campaigns_2023 && data.acl_campaigns_2023.length
              && data.acl_campaigns_2023[0] && data.acl_campaigns_2023[0].dealerApproveAt ?
              moment(data.acl_campaigns_2023[0].dealerApproveAt).format(`HH:mm, DD/MM/YYYY`) : '--'}`,
              border: [false, false, false, false],
              alignment: 'left',
              width: 100,
            },
            {
              text: `Đại lý xác nhận đồng ý với kết quả đánh giá trưng bày.`,
              border: [false, false, false, false],
              alignment: 'left',
              width: '*',
              margin: [24, 0, 0, 0],
            }
          ],
          width: '100%',
          border: [false, false, false, false],
          margin: [9, 10, 0, 0],
        },
        {
          columns: [
            {
              width: '50%',
              text: ' ',
              bold: 'true',
              alignment: 'right',
              lineHeight: '1',
            },
          ],
        },
      ],
    };
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    if (results.data.acl_campaigns_2023[0].dealer.role.alias === 'daikin_dealer') {
      const pdfDocGenerator = pdfMake.createPdf(<any> docDefinition);
      await promiseZip(pdfDocGenerator, zip, data, currentTime);
    } else if (results.data.acl_campaigns_2023[0].dealer.role.alias === 'sub_dealer') {
      const pdfDocGenerator = pdfMake.createPdf(<any> subDocCloneDefinition);
      await promiseZip(pdfDocGenerator, zip, data, currentTime);
    }
  }));
  zip.generateAsync({ type: 'blob' })
    .then(async (content) => {
      saveAs(content, `RA Display 2023 - ${currentTime.toString()}`);
      // const token = await DKGqlClient().user.getFirebaseAuthenticateToken();
      // await FirebaseAuthService().signInWithCustomToken(token.authenticate);
      // await uploadPdfToFirebase(
      //   content,
      //   this.pathUpload,
      //   `RA Display 2023 - ${currentTime.toString()}.zip`
      // );
      this.exportLoading = false;
    });
}
function promiseZip(pdfDocGenerator, zip, data, currentTime) {

  return new Promise((resolve) => {
    pdfDocGenerator.getBase64((base64Data) => {
    // tslint:disable-next-line:max-line-length
      zip.file(`RA Display 2023 - ${data.acl_campaigns_2023[0].dealer.name} - ${currentTime.toString()} - ${randomString()}.pdf`, base64Data , {base64 : true});

      return resolve(true);
    });
  });
}
function randomString() {
  let result = '';
  const characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < 3; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  return result;
}
function addressFormater(model: any) {
  if (model) {
    return getAddressString(model) || '--';
  }

  return '--';
}
function getAddressString({ ward, address, district, province }) {
  const isPrefix = true;
  const localVN = globalState.localVN,
    _province = province ? find(localVN, (e) => province && compareAddressId(e.matp, province)) : null,
    _district = _province && district
        ? find(_province.child, (e) => district && compareAddressId(e.maqh, district)) : null,
    _ward = _district && ward
        ? find(_district.child, (e) => ward && compareAddressId(e.xaid, ward)) : null;

    // tslint:disable-next-line:max-line-length
  return `${address ? `${address}` : ''}${_ward ? `, ${isPrefix ? _ward.name : _ward.name.replace(/Xã /g, '')}` : ''}${_district ? `, ${isPrefix ? _district.name : _district.name.replace(/Huyện /g, '')}` : ''}${_province ? `, ${isPrefix ? _province.name : _province.name.replace(/Thành phố |Tỉnh /g, '')}` : ''}`;
}
function compareAddressId(a: string | number, b: string | number): boolean {
  return a.toString() === b.toString() || Number(a) === Number(b);
}
