import { stripObject } from '@hgiasac/helper';
import {cloneDeep, isEmpty} from 'lodash';
import moment from 'moment-timezone';

export function fullNameUser(model) {
  return model ? `${model.name}` : '';
}

export function formatTimeDuration(time: Date | string, locale?: string) {
  if (!time) {
    return '';
  }
  const duration = moment(time).locale(locale || 'vi').diff(moment.now());

  return moment.duration(duration, 'second').format('hh:mm:ss');

  // return moment(time).locale(locale || 'vi').format('hh:mm:ss');
}

export function FormatDateTime(time: Date, formatOutput: string = 'DD/MM/YYYY', locale: string = 'en') {
  if (!time) {
    return '';
  }

  return moment(time).locale(locale).format(formatOutput);
}
export function FormatDateFullTime(time: Date | string, {
  locale,
  format
} = {
  locale: true,
  format: 'HH:mm, DD/MM/YYYY'
}) {
  if (!time) {
    return '';
  }

  return moment(time).utc(locale).format(format);
}

export function formatBirthday(time: Date | string, locale = 'en') {
  if (!time) {
    return '';
  }

  return moment(time).locale(locale).format('DD/MM/YYYY');
}

export function formatterRequestStartTime(time: string) {
  return time ? moment(time).utcOffset(14, true).toISOString() : '';
}
export function convertToUTC(time: string) {
  return time ? moment(time).utc().format() : '';
}
export function formatStringAndNumber(s: string) {
  const pattern = /[@#$%^&*(),.?":{}|<>]/g;

  return s.replace(pattern, '');
}

/**
 * @func convert time to UTC with format full date time
 * @note when parse with func @formatDateFullTime need to use locale = false
 * @param time
 * @param format
 */
export function convertToUTCWithFormat(time: string, format = 'YYYY-MM-DD HH:mm:ss') {
  if (!time) {
    return '';
  }

  return moment(time).utc().format(format);
}
export function parseUTCToTime(time: string, format = 'HH:mm, DD/MM/YYYY') {
  const timeZone = moment.tz.guess();

  return moment(time).utc(true).tz(timeZone).format(format);
}

// tslint:disable-next-line: max-line-length
export function convertToUTCByStartEnd(time: string, format = 'YYYY-MM-DD HH:mm:ss', startEnd = 0, type: any = 'month') {
  if (!time) {
    return '';
  }

  switch (startEnd) {
  case 0:
    return moment(time).startOf(type).utc().format(format);
    break;
  case 1:
    return moment(time).endOf(type).utc().format(format);
    break;
  default:
    break;
  }
}

export function formaterListCounter(pagination, length) {
  const _pagination = cloneDeep(pagination),
    { page, size } = _pagination,
    total = _pagination && _pagination.total ? _pagination.total : '',
    from = (page - 1) * size + 1,
    to = from + length - 1;

  return `You're viewing item from
   ${to ? from : 0} to ${to} in ${total ? total : 0} ${ total && total > 1 ? 'results' : 'result'}`;
}
export function formatterPhoneNumber(phone: string) {
  const prefix = '+84';
  let formattedPhone = phone.replace(/\s/g, '');

  if (phone[0] === '+') {
    return phone;
  }

  while (formattedPhone[0] === '0') {
    formattedPhone = formattedPhone.substring(1, formattedPhone.length);
  }

  return `${prefix}${formattedPhone}`;
}
export function revertPhoneNumber(phone: string) {
  // const prefix = '+84';

  if (phone[0] === '0') {
    phone = phone.substring(1, phone.length);

    return `${phone}`;
  }

  if (phone[0] === '+') {
    phone = phone.substring(3, phone.length);
  }

  return `${phone}`;
}

export function parseTimeLocal(time: Date | string) {
  return moment(time).utc(true).tz(moment.tz.guess()).format('DD/MM/YYYY');
}

export function msToTime(s) {
  const ms = s % 1000;
  s = (s - ms) / 1000;
  const secs = s % 60;
  s = (s - secs) / 60;
  const mins = s % 60;
  const hrs = (s - mins) / 60;

  return `${hrs < 10 ? `0${hrs}` : hrs}:${mins < 10 ? `0${mins}` : `${mins}`}:${secs < 10 ? `0${secs}` : secs}`;
}

export function FormatExpiredDate(model, duration) {
  const { date, expiryDate } = model;
  if (expiryDate) {
    return moment(expiryDate).utc(true).format('DD/MM/YYYY');
  }
  // tslint:disable-next-line: no-multi-spaces
  const day =  moment(date).utc(true).format('YYYY-MM-DD');

  return moment(day).add(duration, 'days').format('DD/MM/YYYY');
}

export function roundStringNumberWithoutTrailingZeroes(num, dp) {
  if (arguments.length !== 2) { throw new Error('2 arguments required'); }

  num = String(num);
  if (num.indexOf('e+') !== -1) {
      // Can't round numbers this large because their string representation
      // contains an exponent, like 9.99e+37
    throw new Error('num too large');
  }
  if (num.indexOf('.') === -1) {
      // Nothing to do
    return num;
  }

  const parts = num.split('.'),
    beforePoint = parts[0];
  let finalNumber, afterPoint = parts[1];
  const shouldRoundUp = afterPoint[dp] >= 5;

  afterPoint = afterPoint.slice(0, dp);
  if (!shouldRoundUp) {
    finalNumber = `${beforePoint}.${afterPoint}`;
  } else if (/^9+$/.test(afterPoint)) {
      // If we need to round up a number like 1.9999, increment the integer
      // before the decimal point and discard the fractional part.
    finalNumber = `${Number(beforePoint) + 1}`;
  } else {
      // Starting from the last digit, increment digits until we find one
      // that is not 9, then stop
    let i = dp - 1;
    // tslint:disable-next-line:no-constant-condition
    while (true) {
      if (afterPoint[i] === '9') {
        afterPoint = `${afterPoint.substr(0, i)}0${afterPoint.substr(i + 1)}`;
        i--;
      } else {
        afterPoint = afterPoint.substr(0, i) +
                           (Number(afterPoint[i]) + 1) +
                           afterPoint.substr(i + 1);
        break;
      }
    }

    finalNumber = `${beforePoint}.${afterPoint}`;
  }

  // Remove trailing zeroes from fractional part before returning
  return finalNumber.replace(/0+$/, '');
}

export function getLatLngFromCoordinatorString(text) {
  if (!text || text === '') {
    return null;
  }
  const [lat, long] = text.replace(/\s/g, '').split(',');

  return {lat: +lat, long: +long};
}
export function formatFilterEndTime(start: string, end: string) {
  if (start || end) {
    return {
      _or: [
        {
          endTime: stripObject({
            _gte: start ? convertToUTCByStartEnd(start, '', 0, 'd') : null,
            _lte: end ? convertToUTCByStartEnd(end, '', 1, 'd') : null
          })
        },
        {
          pendingRatingTime: stripObject({
            _gte: start ? convertToUTCByStartEnd(start, '', 0, 'd') : null,
            _lte: end ? convertToUTCByStartEnd(end, '', 1, 'd') : null
          }),
          endTime: {
            _is_null: true
          }
        }
      ]
    };
  }

  return {};
}

export function getAddressForBookingContact(localVN, address: any) {
  const item = address;
  const province = localVN.find((e) => address.province && compareAddressId(e.matp, address.province));
  if (!isEmpty(province)) {
    item.province = province.name;
    const district = province.child.find((e) => address.district && compareAddressId(e.maqh, address.district));
    if (!isEmpty(district)) {
      item.district = district.name;
      const ward = district.child.find((e) => address.ward && compareAddressId(e.xaid, address.ward));
      if (!isEmpty(ward)) {
        item.ward = ward.name;
      }
    }
  }

  return item;
}
export function compareAddressId(a: string | number, b: string | number): boolean {
  return a.toString() === b.toString() || Number(a) === Number(b);
}
export function getMonthName(index: number) {
  // tslint:disable-next-line:max-line-length
  const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

  console.log(months, index);

  return months;

}
export function getDateOfISOWeek(w: number, y: number) {
  const simple = new Date(y, 0, 1 + (w - 1) * 7);
  const dow = simple.getDay();
  const ISOweekStart = simple;
  if (dow <= 4) {
    ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
  } else {
    ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
  }

  return ISOweekStart;
}
