import { Subscription } from 'apollo-client/util/Observable';
import { TabPane } from 'element-ui';
import { cloneDeep } from 'lodash';
import { ActionTypeBranchManage } from 'root/admin/BranchAndGroup/BranchManage/Store/types';
import { ActionTypeGroupManage } from 'root/admin/BranchAndGroup/GroupManage/Store/types';
import { SystemRoleAlias } from 'root/admin/User/Store/types';
import { DKGqlClient } from 'root/api/graphql/Client';
import { gqlClient, IPaginationParams } from 'root/api/graphql/Core';
import { zeroCampaignQuery } from 'root/api/graphql/Query/zeroCampaign';
import { FilterByBranch } from 'root/components/FilterByBranch';
import { SearchByFilter } from 'root/components/SearchByFilter';
import { IAddress, IFilterInput, StatusCode } from 'root/models';
import { IBreadcrumb, IState, MutationType } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import { NewRaCampaignRouterName, NewRaCampaignTab } from '..';
import { AddParticipantForm } from '../Components/AddParticipantForm';
import { ActionTypeNewRaCampaign, MutationTypeNewRaCampaign } from '../Store/types';
import './styles.scss';

enum NewRaCampaignSearchBy {
  Dealer = 'dealerName',
  DealerCode = 'dealerCode',
  SubDealerName = 'subdealer',
  SubDealerCode = 'subdealerCode',
  SubDealerPhone = 'subDealerPhone'
}
const enum FilterKey {
  Branch = 'branch',
  Group = 'group'
}

@Component({
  template: require('./view.html'),
  computed: {
    ...mapState({
      data: (state: IState) => state.newRACampaign.data,
      loading: (state: IState) => state.newRACampaign.loading,
      pagination: (state: IState) => state.newRACampaign.pagination,
      authUser: (state: IState) => state.global.authUser,
      branch: (state: IState) => state.branchManage.data,
      group: (state: IState) => state.groupManage.data,
    }),
    NewRaCampaignSearchBy() {
      const result: IFilterInput[] = [];
      for (const key in NewRaCampaignSearchBy) {
        // tslint:disable-next-line:early-exit
        if (NewRaCampaignSearchBy.hasOwnProperty(key)) {
          const element = NewRaCampaignSearchBy[key];
          result.push({
            key,
            value: element,
            name: this.$t(element)
          });
        }
      }

      return [{
        key: 'select',
        name: this.$t('select'),
        value: result
      }];
    },
    selectedFieldSearchBy() {
      const filterParams = cloneDeep(this.modelSearchBy);

      return [{
        key: 'select',
        value: filterParams ? filterParams : []
      }];
    },
  },
  components: {
    'add-participant': AddParticipantForm,
    'search-by-filter': SearchByFilter,
    'btn-filter-by-branch': FilterByBranch
  }
})

export class SubdealerRegistration extends Vue {
  public pagination: IPaginationParams;
  public visibleAddParticipant: boolean = false;
  public modelSearchBy: any[] = [];
  public searchText: string = '';
  public authUser: any;
  public config: any[] = [];
  public loadingExport: boolean = false;
  public subscribeURL: Subscription = null;
  public branch: any[];
  public SystemRoleAlias = SystemRoleAlias;
  public group: any[];
  public filterParams = {
    [FilterKey.Branch]: [],
    [FilterKey.Group]: []
  };
  public get currentRole() {
    return this.authUser.role.alias;
  }
  public get checkBranchGroup() {
    return this.authUser && this.authUser.role && this.authUser.role.alias && (
      this.authUser.role.alias === SystemRoleAlias.SaleDepartmentPic ||
      this.authUser.role.alias === SystemRoleAlias.SaleStaff ||
      this.authUser.role.alias === SystemRoleAlias.GroupLeader ||
      this.authUser.role.alias === SystemRoleAlias.BranchManager ||
      this.authUser.role.alias === SystemRoleAlias.BranchPIC
    );
  }
  public get checkPermissionAddParticipant() {
    return this.authUser && this.authUser.role && this.authUser.role.alias &&
    (this.authUser.role.alias === SystemRoleAlias.SaleStaff
      || this.authUser.role.alias === SystemRoleAlias.SaleDepartmentPic);
  }
  public get isShowModuleByConfig() {
    const module = this.config.find((it) => it.moduleName === 'new_ra_campaign_tabs');
    if (module && module.roles.includes(this.authUser.role.alias) && module.emails.includes(this.authUser.email)) {

      return true;
    }

    return false;
  }
  public get activeTab() {
    return NewRaCampaignTab.Registration;
  }
  public set activeTab(_value: string) {
    //
  }
  public get selectedFilter() {
    const filterParams = cloneDeep(this.filterParams);

    return [
      {
        key: FilterKey.Branch,
        value: filterParams[FilterKey.Branch]
      },
      {
        key: FilterKey.Group,
        value: filterParams[FilterKey.Group]
      },
    ];
  }
  public get dataFilter() {
    const _groups: IFilterInput = this.group.filter((type) => type.name !== 'Technician').reduce((res, item) => {
      res.push({
        key: item.id,
        value: item.id,
        name: item.name
      });

      return res;
    }, []);

    return [
      {
        key: FilterKey.Group,
        name: 'Department',
        value: _groups
      },
    ];
  }
  public get dataFilterBranch() {
    const _branch: IFilterInput[] = this.branch.reduce((res, item) => {
      res.push({
        key: item.id,
        value: item.id,
        name: item.name
      });

      return res;
    }, []);

    return [
      {
        key: FilterKey.Branch,
        name: 'Branch',
        value: _branch
      },
    ];
  }
  public get columns() {
    return [
      {
        prop: 'subdealer',
        label: 'Sub-Dealer Name',
        labelItem: 'Sub-Dealer Code',
        width: 250,
        fixed: true
      },
      {
        prop: 'dealer',
        label: 'Dealer Name',
        labelItem: 'Dealer Code',
        width: 250,
      },
      {
        prop: 'phone',
        label: 'Sub-Dealer Phone',
        width: 180,
        formatter: (model: any) => {
          if (model.subDealerView && model.subDealerView.phoneNumber) {
            return model.subDealerView.phoneNumber || '--';

          }

          return '--';
        }
      },
      {
        prop: 'address',
        label: 'Sub-Dealer Address',
        width: 220,
        formatter: (model: any) => {
          if (model.subDealerView && model.subDealerView.address) {
            return this.displayAddress(model.subDealerView.address) || '--';
          }

          return '--';
        },
      },
      {
        prop: 'status',
        label: this.$t('status'),
        formatter: (model: any) => model && model.status ? this.$t(model.status) : '',
        labelStyle: (model: any) => {

          return model.status;
        },
        width: 180
      },
      ...this.checkPermissionAddParticipant ? [
        {
          prop: 'action',
          label: 'Remove Relationship',
          align: 'center',
          fixed: 'right',
          width: 110
        }
      ] : []
    ];
  }
  public getAction() {
    return 'delete';
  }
  public changeTab(tab: TabPane) {
    switch (tab.name) {
    case NewRaCampaignTab.Participant:
      this.$router.push({
        name: NewRaCampaignRouterName.Participant
      });
      break;
    case NewRaCampaignTab.Shop:
      this.$router.push({
        name: NewRaCampaignRouterName.Shop
      });
      break;
    case NewRaCampaignTab.SubDealer:
      this.$router.push({
        name: NewRaCampaignRouterName.SubDealer
      });
      break;
    case NewRaCampaignTab.RequestList:
      this.$router.push({
        name: NewRaCampaignRouterName.RequestList
      });
      break;
    case NewRaCampaignTab.Config:
      this.$router.push({
        name: NewRaCampaignRouterName.Config
      });
      break;
    case NewRaCampaignTab.Statistic:
      this.$router.push({
        name: NewRaCampaignRouterName.Statistic
      });
      break;
    case NewRaCampaignTab.Export:
      this.$router.push({
        name: NewRaCampaignRouterName.Export
      });
      break;
    case NewRaCampaignTab.Classified:
      this.$router.push({
        name: NewRaCampaignRouterName.Classified
      });
      break;
    default:
      break;
    }
  }
  public checkSubdealerHasJoinedCampaign(model) {
    return !!(model && model.subDealerView && model.subDealerView.acl_campaigns_2023.length);
  }
  public addParticipant() {
    this.visibleAddParticipant = true;
  }
  public changeSearchBy(model: IFilterInput[]) {
    this.modelSearchBy = model[0].value;

    this.fetchData();
  }
  public onEnterFilter(value) {
    this.searchText = value;

    this.fetchData();
  }
  public handleActions(id) {
    this.$msgbox({
      title: this.$t('delete_confirm').toString(),
      message: this.$t('delete_confirm_message').toString(),
      showCancelButton: true,
      customClass: 'message-error',
      confirmButtonText: this.$t('confirm').toString(),
      cancelButtonText: this.$t('cancel').toString(),
      beforeClose: (action, instance, done) => {
        if (action === 'confirm') {
          instance.confirmButtonLoading = true;
          instance.confirmButtonText = 'Loading...';
          this.handleDelete({ instance, done, id });
        } else {
          done();
        }

        return;
      }
    })
        .then(() => {
          this.$message({
            type: 'success',
            message: this.$t('message.deleteSuccess').toString()
          });
        })
        .catch(() => {
          // no handle
        });
  }
  public changeFilterBranch(filter) {
    const nextFilter = {
      [FilterKey.Branch]: filter ? filter : [],
    };
    this.filterParams = {
      ...cloneDeep(this.filterParams),
      ...nextFilter
    };
    this.fetchData();
  }
  public changeFilter(model) {
    const group = model.find((e) => e.key === FilterKey.Group);
    const nextFilter = {
      [FilterKey.Group]: group ? group.value : [],
    };
    this.filterParams = {
      ...cloneDeep(this.filterParams),
      ...nextFilter
    };
    this.fetchData();
  }
  public handleDelete(model) {
    this.$store.dispatch(ActionTypeNewRaCampaign.Update, {
      id: model.id,
      form: {
        status: StatusCode.Deleted
      },
      onSuccess: () => {
        model.instance.confirmButtonLoading = false;
        model.instance.confirmButtonText = 'Confirm';
        model.done();
        this.fetchData();
      },
      onFail: () => {
        model.instance.confirmButtonLoading = false;
        model.instance.confirmButtonText = 'Confirm';
      }
    });
  }
  public displayAddress(model: IAddress) {
    return model && model.province
      ? this.$store.getters['getAddressString'](model)
      : '';
  }
  public handlePaginationChange(value) {
    if (typeof value === 'number') {
      this.$store.commit(MutationTypeNewRaCampaign.PaginationChange, {
        ...this.pagination,
        size: value
      });
    } else {
      this.$store.commit(MutationTypeNewRaCampaign.PaginationChange, {
        ...this.pagination,
        ...value
      });
    }
    this.fetchData();
  }
  public checkModelSearchBy(model) {
    return this.modelSearchBy.indexOf(model) >= 0;
  }
  public refetchData() {
    this.fetchData();
  }
  public handleExport() {
    this.loadingExport = true;
    const text = cloneDeep(this.searchText);
    const command = 'relationship_sub_dealer';
    this.$store.dispatch(ActionTypeNewRaCampaign.ExportListRegistration, {
      form: {
        isReIndex: true,
        type: command,
        filter: {
          groupIds: this.filterParams[FilterKey.Group].length ? this.filterParams[FilterKey.Group] : [],
          branchIds: this.filterParams[FilterKey.Branch].length ? this.filterParams[FilterKey.Branch] : [],
          ...text && text.length ? {
            search: {
              dealerName: this.checkModelSearchBy(NewRaCampaignSearchBy.Dealer) ? text : null,
              dealerCode: this.checkModelSearchBy(NewRaCampaignSearchBy.DealerCode) ? text : null,
              subDealerName: this.checkModelSearchBy(NewRaCampaignSearchBy.SubDealerName) ? text : null,
              subDealerCode: this.checkModelSearchBy(NewRaCampaignSearchBy.SubDealerCode) ? text : null,
              subDealerPhone: this.checkModelSearchBy(NewRaCampaignSearchBy.SubDealerPhone) ? text : null,
            }
          } : { search: null }
        }
      },
      onSuccess: (url) => {
    // tslint:disable-next-line: early-exit
        if (url.returning && url.returning.length) {
          this.subscribeURL = <any> gqlClient.subscribe({
        query: zeroCampaignQuery.DOWNLOAD_EXPORT,
        variables : {id: url.returning[0].id},
        fetchPolicy: 'cache-first'
      }).subscribe((results) => {
        // tslint:disable-next-line: early-exit
        if (results.data && results.data.exports && results.data.exports.length
          && results.data.exports[0].exportStatus === 'done') {
          window.open(results.data.exports[0].url, '_blank');
          this.subscribeURL.unsubscribe();
          this.loadingExport = false;
        }
      });
        }
      }
    });
  }

  protected async mounted() {
    const breadcrumb: IBreadcrumb[] = [
      {
        label: 'title.new_ra_campaign',
        path: null,
        current: false
      },
      {
        label: 'title.new_ra_campaign_registration',
        path: null,
        current: true
      }
    ];
    this.$store.commit(MutationType.SetBreadcrumb, breadcrumb);
    this.$store.commit(MutationTypeNewRaCampaign.PaginationChange, {
      page: 1,
      limit: 20
    });
    this.config = await DKGqlClient().configuration.getConfigNavigator();
    this.$store.dispatch(ActionTypeBranchManage.GetListBranch, {
      form: {
        filter: {},
        pagination: {}
      }
    });
    this.$store.dispatch(ActionTypeGroupManage.GetListFilterGroup);
    this.fetchData();
  }
  protected beforeDestroy() {
    this.$store.commit(MutationType.ClearBreadcrumb);
  }
  private fetchData() {
    const text = cloneDeep(this.searchText);
    const {branch, group} = this.filterParams;
    this.$store.commit(MutationTypeNewRaCampaign.FilterChange, {
      _and: {
        mode: 'special',
        data: [
          ...this.checkBranchGroup ? [
            { subDealerView : {branchId: {_eq: this.authUser.branch.id}}}
          ] : [],
          ...branch && branch.length ? [
            {
              subDealerView: {
                branchId: {
                  _in: branch
                }
              }
            }
          ] : [],
          ...group && group.length ? [
            {
              subDealerView: {
                groupId: {
                  _in: group
                }
              }
            }
          ] : [],
          text.trim() !== '' ? {
            _or: [
              ...this.checkModelSearchBy(NewRaCampaignSearchBy.Dealer) ? [
                {
                  dealerView: {
                    name: {
                      _ilike: `%${text}%`
                    }
                  }
                }
              ] : [],
              ...this.checkModelSearchBy(NewRaCampaignSearchBy.DealerCode) ? [
                {
                  dealerView: {
                    dealerCode: {
                      _ilike: `%${text}%`
                    }
                  }
                }
              ] : [],
              ...this.checkModelSearchBy(NewRaCampaignSearchBy.SubDealerName) ? [
                {
                  subDealerView: {
                    name: {
                      _ilike: `%${text}%`
                    }
                  }
                }
              ] : [],
              ...this.checkModelSearchBy(NewRaCampaignSearchBy.SubDealerCode) ? [
                {
                  subDealerView: {
                    dealerCode: {
                      _ilike: `%${text}%`
                    }
                  }
                }
              ] : [],
              ...this.checkModelSearchBy(NewRaCampaignSearchBy.SubDealerPhone) ? [
                {
                  subDealerView: {
                    phoneNumber: {
                      _ilike: `%${text}%`
                    }
                  }
                }
              ] : [],
            ]
          } : {}
        ]
      }
    });
    this.$store.dispatch(ActionTypeNewRaCampaign.FilterNoCache);
  }
}
