import { Subscription } from 'apollo-client/util/Observable';
import { ElNotificationComponent } from 'element-ui/types/notification';
import { gqlClient } from 'root/api/graphql/Core';
import { productListQuery } from 'root/api/graphql/Query/product';
import { Header } from 'root/components/Header';
import { Navigator } from 'root/components/Navigator';
import { ConfigurationKey, ELocalStorage, LocalStorageKey } from 'root/models';
import { RouteDictionary } from 'root/router/types';
import { IState } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import './home.scss';

@Component({
  template: require('./view.html'),
  components: {
    'dk-header': Header,
    navigator: Navigator
  },
  computed: {
    ...mapState({
      isLoading: (state: IState) => {
        return state.global.isLoading;
      }
    }),
  },
})

export class HomeAdminLayout extends Vue {
  public isCollapse: boolean = false;
  public hasNewVersion: boolean = false;
  public version: string = '';
  private subscribeAdminVersion: Subscription = null;
  private instanceNotification: ElNotificationComponent = null;

  public handleCollapse() {
    this.isCollapse = !this.isCollapse;
  }
  public get isShowHeader(): boolean {
    const path = this.$route.path;

    return path !== RouteDictionary.Login;
      // && path !== RouteDictionary.Register
      // && path !== RouteDictionary.ForgotPassword;
  }
  public compareVersion(a, b) {
    if (a === b) {
      return 0;
    }
    const versionA = a.split('.');
    const versionB = b.split('.');
    const len = Math.min(versionA.length, versionB.length);
    for (let i = 0; i < len; i++) {
      if (parseInt(versionA[i], null) > parseInt(versionB[i], null)) {
        return 1;
      }
      if (parseInt(versionA[i], null) < parseInt(versionB[i], null)) {
        return -1;
      }
    }
    if (versionA.length > versionB.length) {
      return 1;
    }
    if (versionA.length < versionB.length) {
      return -1;
    }

    return 0;
  }
  public confirmReload(_currentVersion) {
    this.$msgbox({
      title: this.$t('reload_confirm').toString(),
      message: this.$t('reload_confirm_message').toString(),
      showCancelButton: false,
      customClass: 'message-error',
      closeOnPressEscape: false,
      closeOnClickModal : false,
      showClose: false,
      confirmButtonText: this.$t('update').toString(),
      beforeClose: (action, instance, done) => {
        if (action === 'confirm') {
          instance.confirmButtonLoading = true;
          instance.confirmButtonText = 'Loading...';
          window.location.reload();
          localStorage.setItem(ELocalStorage.Version, _currentVersion);
          done();
        } else {
          done();
        }

        return;
      }
    })
        .then(() => {
          this.$message({
            type: 'success',
            message: this.$t('message.updateSuccess').toString()
          });
        })
        .catch(() => {
          // no handle
        });
  }

  protected mounted() {
    this.subscribeAdminVersion = <any> gqlClient.subscribe({
      query: productListQuery.GET_CONFIGURATION,
      fetchPolicy: 'cache-first'
    }).subscribe((results) => {
      const _currentVersion = results.data.system_configurations.find((config) =>
        config.variableName === ConfigurationKey.PortalVersion).value;
      const _forceUpdateVersionPortal = results.data.system_configurations.find((config) =>
        config.variableName === ConfigurationKey.IsForceUpdatePortal).value;
      if (localStorage.version && localStorage.version !== _currentVersion && _forceUpdateVersionPortal === 'YES') {
        const _resultCompare = this.compareVersion(localStorage.version, _currentVersion);
        if (_resultCompare < 0) {
          this.confirmReload(_currentVersion);
        }
      }
      if (localStorage.version && localStorage.version !== _currentVersion && _forceUpdateVersionPortal === 'NO') {
        const _resultCompare = this.compareVersion(localStorage.version, _currentVersion);
        if (_resultCompare < 0) {
          this.hasNewVersion = true;
          this.version = _currentVersion;
        }
      }
      if (!localStorage.version && _forceUpdateVersionPortal === 'YES') {
        this.confirmReload(_currentVersion);
      }
      if (!localStorage.version && _forceUpdateVersionPortal === 'NO') {
        this.hasNewVersion = true;
        this.version = _currentVersion;
      }
    }, (error) => {
      console.error('subscribe admin version error', error);
    });
  }

  protected beforeDetroy() {
    this.subscribeAdminVersion.unsubscribe();
  }
  /*
  protected mounted() {
    this.$nextTick(() => {
      const currentAdminVersion = localStorage.getItem(LocalStorageKey.AdminVersion);
      this.subscribeAdminVersion = <any> gqlClient.subscribe({
        query: ConfigurationQuery.SUBSCRIBE_ADMIN_VERSION,
        fetchPolicy: 'cache-first'
      }).subscribe((result) => {
        console.log('result', result);
        const adminVersionObj = result.data.system_configurations ? result.data.system_configurations[0] : {},
          adminVersion: string = adminVersionObj.value;
        if (!currentAdminVersion) {
          this.openAdminVersionChangeNotify(adminVersion);

          return;
        }
        /**
         * compare version to show notification
        // tslint:disable-next-line:max-line-length
        // if (Number(adminVersion.toString().replace(/\./gi, '')) >
        // Number(currentAdminVersion.toString().replace(/\./gi, ''))) {
        //   this.openAdminVersionChangeNotify(adminVersion);
        // }
    //   }, (error) => {
    //     console.error('subscribe admin version error', error);
    //   });
    // });
  }
  */
  private openAdminVersionChangeNotify(adminVersion: string) {
    if (this.instanceNotification) {
      this.instanceNotification.close();
    }
    this.instanceNotification = this.$notify({
      dangerouslyUseHTMLString: true,
      title: `Dealers management Portal has been updated to new version ${adminVersion}`,
      position: 'bottom-right',
      showClose: false,
      message: `
        <div class="content">
          <img src="/assets/img/daikin-fav.png" />
          <div class="text">Refresh your browser to see changes</div>
        </div>
      `,
      duration: 0,
      onClick: () => {
        localStorage.setItem(LocalStorageKey.AdminVersion, adminVersion);
        this.instanceNotification.close();
        location.reload(true);
      }
    });
  }
}
