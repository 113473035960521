import { IUser } from 'root/models';
import { IState, MutationType, TypeAlert } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import { ActionTypeZeroCampaign } from '../../Store/types';
import './styles.scss';
@Component({
  template: require('./view.html'),
  props: {
    visible: Boolean,
    campaignId: String,
    listFinancialCompany: Array
  },
  computed: {
    ...mapState({
      authUser: (state: IState) => state.global.authUser,
    }),
  }
})
export class UpdateFinancialCompany extends Vue {
  public listFinancialCompany: any;
  public authUser: IUser;
  public listFinancialCompanyUpdate: any = [];
  public loadingSubmit: boolean = false;
  public companyHD: boolean = false;
  public companyFE: boolean = false;
  public companyACS: boolean = false;
  public cloneCompanyHD: boolean = false;
  public cloneCompanyFE: boolean = false;
  public cloneCompanyACS: boolean = false;
  public async openDialog() {
    this.companyHD = false;
    this.companyFE = false;
    this.companyACS = false;
    this.cloneCompanyHD = false;
    this.cloneCompanyFE = false;
    this.cloneCompanyACS = false;
    // tslint:disable-next-line: early-exit
    if (this.listFinancialCompany && this.listFinancialCompany) {
      this.listFinancialCompany.map((comp) => {
        switch (comp) {
        case 'HD':
          this.cloneCompanyHD = true;

          return this.companyHD = true;
        case 'FE':
          this.cloneCompanyFE = true;

          return this.companyFE = true;
        case 'ACS':
          this.cloneCompanyACS = true;

          return this.companyACS = true;
        }
      });
    }
  }
  public submitUpdate() {
    this.loadingSubmit = true;
    this.listFinancialCompanyUpdate = [];
    if (this.cloneCompanyHD) {
      this.listFinancialCompanyUpdate.push('HD');
    }
    if (this.cloneCompanyFE) {
      this.listFinancialCompanyUpdate.push('FE');
    }
    if (this.cloneCompanyACS) {
      this.listFinancialCompanyUpdate.push('ACS');
    }
    this.listFinancialCompany.map((company) => {
      if (this.listFinancialCompanyUpdate.indexOf(company) >= 0) {
        const _index = this.listFinancialCompanyUpdate.indexOf(company);
        this.listFinancialCompanyUpdate.splice(_index, 1);
      }
    });
    this.$store.dispatch(ActionTypeZeroCampaign.GetListFinancialCompany, {
      alias: this.listFinancialCompanyUpdate,
      onSuccess: (listCompany) => {
        if (!listCompany.length) {
          const messageWarning = 'Please check financial company you want to update';
          this.$store.commit(MutationType.OpenTopAlert, {
            message: messageWarning,
            type: TypeAlert.Warning,
          });
          this.loadingSubmit = false;

          return;
        }
        this.$store.dispatch(ActionTypeZeroCampaign.UpdateFinancialCompany, {
          campaignId: this.$props.campaignId,
          id: listCompany,
          createdBy: this.authUser.id,
          onSuccess: () => {
            const message = 'Update financial company successfully';
            this.$store.commit(MutationType.OpenTopAlert, {
              message,
              type: TypeAlert.Success,
            });
            this.loadingSubmit = false;
            this.closeDialog();
          }
        });
      }
    });
  }
  public closeDialog() {
    this.$emit('closeDialog');
  }
}
