import { Form } from 'element-ui';
import { cloneDeep } from 'lodash';
import { ActionTypeBranchManage } from 'root/admin/BranchAndGroup/BranchManage/Store/types';
import { ActionTypeSaleInCampaign } from 'root/admin/CustomCampaign/SaleInCampaign/Store/types';
import { IPaginationParams } from 'root/api/graphql/Core';
import { ruleRequired } from 'root/helpers';
import { IFilterInput } from 'root/models';
import { IState } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import './styles.scss';

const enum FilterKey {
  Type = 'type',
}

@Component({
  template: require('./view.html'),
  props: {
    isUpdate: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState({
      branch: (state: IState) => state.branchManage.data
    }),
    branchOptions() {
      return this.branch.map((it) => ({
        key: it.id,
        label: it.branchCode,
        value: it.id
      }));
    }
  }
})

export class SaleInFormParticipant extends Vue {
  public rules = {
    required: ruleRequired(),
  };
  public get dataFilter() {
    const type: IFilterInput[] = [
      {
        key: 'daikin_dealer',
        value: 'daikin_dealer',
        name: 'Direct Dealer'
      },
      {
        key: 'sub_dealer',
        value: 'sub_dealer',
        name: 'Sub dealer'
      },
    ];

    return [
      {
        key: FilterKey.Type,
        name: 'Type',
        value: type
      },
    ];
  }
  public get selectedFilter() {
    const _filterParams = cloneDeep(this.filterParams);

    return [
      {
        key: FilterKey.Type,
        value: _filterParams[FilterKey.Type]
      },
    ];
  }
  public get targetType() {
    return [
      {
        key: 'daikin_dealer',
        value: 'daikin_dealer',
        label: 'Direct Dealer'
      },
      {
        key: 'sub_dealer',
        value: 'sub_dealer',
        label: 'Sub dealer'
      },
    ];
  }
  public get columns() {
    return [
      {
        label: 'name',
        labelItem: 'Code',
        formatter: (model: any) => {
          return model && model.name ? model.name : '--';
        },
        description: {
          formatter: (model: any) => {
            return model && model.dealerCode ? model.dealerCode : '--';
          },
        },
        width: 164
      },
      {
        label: 'Role',
        formatter: (model: any) => {
          return model && model.role && model.role.alias ? this.formatterDealerRole(model.role.alias) : '--';
        },
        width: 140
      },
      {
        label: 'Phone',
        formatter: (model: any) => {
          return model && model.phoneNumber ? model.phoneNumber : '--';
        },
        width: 160
      },
      {
        label: 'Branch',
        formatter: (model: any) => {
          return model && model.branch && model.branch.name ? model.branch.name : '--';
        },
        width: 140
      }
    ];
  }
  public $refs: {
    form: Form;
  };
  public form: any = {

  };
  public searchText: string = '';
  public proShopType: any[] = [];
  public businessType: any[] = [];
  public potentialType: any[] = [];
  public loading: boolean = false;
  public pagination: IPaginationParams = {
    limit: 20,
    page: 1,
    total: null
  };
  public filterParams = {
    [FilterKey.Type]: [],
  };
  public data: any = [];
  public cloneDealerType: any = [];
  public dealerType: {
    proshop: [],
    business: [],
    potential: []
  } = {
    proshop: [],
    business: [],
    potential: []
  };
  public findDealerTypeDuplicate(arr1, arr2) {
    const clone = arr2.map((it) => it.alias);

    return arr1.filter((it) => clone.indexOf(it) >= 0);
  }
  public uniqTypeDealer() {
    this.proShopType = this.findDealerTypeDuplicate(this.cloneDealerType, this.dealerType.proshop);
    this.businessType = this.findDealerTypeDuplicate(this.cloneDealerType, this.dealerType.business);
    this.potentialType = this.findDealerTypeDuplicate(this.cloneDealerType, this.dealerType.potential);
  }
  public onChangeDealerBranch() {
    if (this.form.targetBranch && this.form.targetBranch.length &&
      this.form.targetBranch.indexOf('all') >= 0) {
      this.form.targetBranch = ['all'];
    }
    this.pagination = {
      ...this.pagination, page: 1
    };
    this.fetchParticipantList();
  }
  public changeFilter(model) {
    const type = model.find((e) => e.key === FilterKey.Type);

    const nextFilter = {
      [FilterKey.Type]: type ? type.value : [],
    };
    this.filterParams = {
      ...cloneDeep(this.filterParams),
      ...nextFilter
    };
    this.fetchParticipantList();
  }
  public onChangeDealerType() {
    this.pagination = {
      ...this.pagination, page: 1
    };
    this.fetchParticipantList();
  }
  public fetchParticipantList() {
    const text = cloneDeep(this.searchText);
    const {type} = this.filterParams;
    this.loading = true;
    this.$store.dispatch(ActionTypeSaleInCampaign.GetParticipantList, {
      role: this.form.dealerRoles,
      search: text && text.length ? `%${text}%` : null,
      type: this.form.dealerRoles && this.form.dealerRoles.length ? this.form.dealerRoles :
      type && type.length ? type : null,
      branch: this.form.targetBranch && this.form.targetBranch.length && this.form.targetBranch.indexOf('all') < 0 ?
      this.form.targetBranch : null,
      proShopType: this.proShopType,
      businessType: this.businessType,
      potentialType: this.potentialType,
      pagination: this.pagination,
      onSuccess: (result) => {
        this.loading = false;
        this.data = result.dealers;
        this.pagination = {
          ...this.pagination ,
          total: result.dealers_aggregate.aggregate.count
        };
      }
    });
  }
  public handleSearch() {
    this.pagination = {
      ...this.pagination,
      page: 1,
    };
    this.fetchParticipantList();
  }
  public formatterDealerRole(role: any) {
    const mapRole = {
      daikin_dealer: 'Dealer',
      daikin_staff: 'Salesman',
      sub_dealer: 'Sub Dealer'
    };

    return mapRole[role];
  }
  public handlePaginationChange(value) {
    if (typeof value === 'number') {
      this.pagination = {
        ...this.pagination,
        limit: value
      };
    } else {
      this.pagination = {
        ...this.pagination,
        ...value
      };
    }
    this.fetchParticipantList();
  }
  public fetchData() {
    this.$store.dispatch(ActionTypeBranchManage.GetListBranch, {
      form: {
        filter: {},
        pagination: {}
      }
    });
    this.$store.dispatch(ActionTypeSaleInCampaign.GetDealerType, {
      onSuccess: (data) => {
        this.dealerType = {
          proshop: data.filter((it) => it.type === 'proshop'),
          business: data.filter((it) => it.type === 'business'),
          potential: data.filter((it) => it.type === 'potential')
        };
      }
    });
  }
  public async mounted() {
    this.fetchData();
  }
}
