import { DocumentNode } from 'graphql';
import gql from 'graphql-tag';
import { crudQuery, ICRUDQuery } from '../Core/query';

const groupModel = `
id
name
branchId
branch {
  name
  id
}
address
description
status
`;

export const Groups = 'groups';
// tslint:disable-next-line:no-empty-interface
interface IGroupsQuery extends ICRUDQuery {
  GET_LIST_GROUP: DocumentNode;
}
export const getListGroupQuery: IGroupsQuery = {
  ...crudQuery(Groups, groupModel),
  GET_LIST_GROUP: gql`
  query GET_LIST_GROUP {
    groups {
      ${groupModel}
    }
  }
`,
};
const groupSytemModel = `
description
id
name
status
`;

export const GroupsSystem = 'system_groups';
// tslint:disable-next-line:no-empty-interface
interface IGroupsSystemQuery extends ICRUDQuery {
  GET_LIST_GROUP_SYSTEM: DocumentNode;
}
export const getListGroupSystemQuery: IGroupsSystemQuery = {
  ...crudQuery(GroupsSystem, groupSytemModel),
  GET_LIST_GROUP_SYSTEM: gql`
  query GET_LIST_GROUP_SYSTEM {
    system_groups {
      ${groupSytemModel}
    }
  }
`,
};
