import { isArray } from 'lodash';
import moment from 'moment';
import { FormatDateFullTime } from 'root/helpers';
import { defaultNotification, INotification } from 'root/models/Notifications';
import { IState } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import './styles.scss';

@Component({
  template: require('./view.html'),
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    currentNotificationId: {
      type: String,
      default: ''
    },
    targetDetails: {
      type: Array,
      default: []
    }
  },
  computed: {
    ...mapState({
      notifications: (state: IState) => state.notification.data
    })
  }
})
export class NotificationDetail extends Vue {
  public detail: INotification = defaultNotification();
  public notifications: INotification[];
  public dealersEmail: string[] = [];
  public closeDialog() {
    this.$emit('closeDialog');
    this.detail = defaultNotification();
    this.dealersEmail = [];
  }

  public formatTarget(target: string | string[]): string {
    if (!isArray(target)) {
      return target
        .split('_')
        .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
    }
    const _cloneTarget = [...target];
    if (_cloneTarget.includes('daikin_staff')) {
      const _index = _cloneTarget.indexOf('daikin_staff');
      _cloneTarget.splice(_index, 1, 'salesman');
    }
    const result = _cloneTarget.reduce((acc, type) => {
      const formattedType = type
        .split('_')
        .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');

      return `${acc + formattedType}, `;
    }, '');

    return result.slice(0, -2) || '--';
  }
  public formatterDealerTarget(model: any) {
    if (model && model.target.length && model.target[0] === 'dealer_app') {
      return '--';
    }

    return model.dealerIds ? model.dealerIds.length : '--';
  }
  public formatType(model: any) {
    if (model && model.target.length && model.target[0] === 'dealer_app') {
      return 'All';
    }

    return model.metadata && model.metadata.filter && model.metadata.filter.typeDealers.length
    && model.metadata.filter.typeDealers[0] !== ' '
    ? this.formatTarget(model.metadata.filter.typeDealers) : '--';
  }

  public formatStatus(status: string): string {
    return status
      ? status
          .split('-')
          .map((element) => element.charAt(0).toUpperCase() + element.slice(1))
          .join(' ')
      : '--';
  }

  public formatPushStatus(scheduledAt) {
    return moment(scheduledAt) > moment() ? 'scheduled' : 'sent';
  }

  public convertUrl(url: string) {
    if (!url) {
      return '';
    }
    if (url.indexOf('https://') > -1 || url.indexOf('http://') > -1) {
      return url;
    }

    return `https://${url}`;
  }

  public formatDate(date: string) {
    return FormatDateFullTime(date, {
      format: 'HH:mm - DD/MM/YYYY',
      locale: true
    });
  }

  public openDialog() {
    const { targetDetails: targetDetailList } = this.$props;

    const notification = this.notifications.find(
      (noti) => noti.id === this.$props.currentNotificationId
    );
    if (!notification) {
      return;
    }

    // const targetDetail = targetDetailList.find(
    //   (target) => target.referenceId === notification.id
    // );
    const listEmail: string[] = [];

    // if (targetDetail) {
    //   const { receiverIds } = targetDetail;
    //   if (!isArray(receiverIds)) {
    //     const { email = '' } =
    //       this.$store.getters['getDealerDetail'](receiverIds) || {};
    //     email && listEmail.push(email);
    //   } else {
    //     receiverIds.forEach((id) => {
    //       const { email = '' } =
    //         this.$store.getters['getDealerDetail'](id) || {};
    //       email && listEmail.push(email);
    //     });
    //   }
    // }
    if (notification.dealerIds && notification.dealerIds.length) {
      notification.dealerIds.map((dealerId) => {
        const target = targetDetailList.find((e) => e.id === dealerId);
        // tslint:disable-next-line: early-exit
        if (target) {
          const targetDetail = target.role && target.role.alias === 'daikin_staff'
          ? target.phoneNumber : target.dealerCode;
          listEmail.push(targetDetail);
        }
      }
    );
    }

    this.dealersEmail = [...listEmail];
    this.detail = { ...notification };
  }
}
