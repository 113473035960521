import './style.scss';

import { find, findIndex } from 'lodash';

// import { cloneDeep } from 'apollo-utilities';
import { ElTable } from 'element-ui/types/table';
import { IPaginationParams } from 'root/api/graphql/Core';
import { ServiceStatus, StatusCode } from 'root/models';
import { ItemPage } from 'root/models';
import Vue from 'vue';
// import { MutationTypeBooking } from 'root/pages/Booking/Store/types';
import Component from 'vue-class-component';
import { Avatar } from '../Avatar';

export interface IItemActionForListDataComponent {
  label: string;
  emitName: string;
  isShow?: any;
}

export type IActionForListDataComponent = Array<
  string | IItemActionForListDataComponent
>;

@Component({
  template: require('./view.html'),
  props: {
    data: {
      type: Array,
      default: []
    },
    align: {
      type: String,
      default: 'left'
    },
    actions: Array,
    columns: Array,
    pagination: Object,
    loading: Boolean,
    eyeAction: Boolean,
    eyeActionIcon: String,
    size: {
      type: String,
      default: 'default'
    },
    fixedIndex: {
      type: Boolean,
      default: false
    },
    fixed: {
      type: String,
      default: 'right'
    },
    showAction: {
      type: Boolean,
      default: true
    },
    noHandleDisabled: Boolean,
    enableSelect: Boolean,
    selected: Array,
    cloneSelected: Array,
    maxHeight: Number,
    showIndex: {
      type: Boolean,
      default: true
    },
    multiple: {
      type: Boolean
    },
    showArrow: {
      type: Boolean,
      default: false
    },
    itemPage: {
      type: Boolean,
      default: false
    },
    listSorted: {
      type: Array,
      default: () => {
        return [];
      }
    },
    isExpand: Boolean,
    showHeader: {
      type: Boolean,
      default: true
    },
    handleSelected: {
      type: Boolean,
      default: false
    },
    indexColWidth: {
      type: Number,
      default: 100
    },
    showIndexWithSelect: {
      type: Boolean,
      default: false
    },
    sortTotalType: {
      type: Number,
      default: 0
    },
    sortJoinType: {
      type: Number,
      default: 0
    },
    sortSuccessType: {
      type: Number,
      default: 0
    },
    sortFailType: {
      type: Number,
      default: 0
    },
    sortWithoutNull: Boolean,
  },
  components: {
    avatar: Avatar
  },
  watch: {
    data(newValue: any[]) {
      if (this.$props.handleSelected) {
        setTimeout(() => {
          newValue.map((e) => {
            if (findIndex(this.cloneSelected, (s: any) => s && s.id === e.id) > -1) {
              this.$refs.table.toggleRowSelection(e, true);
            }
          });
        }, 0);
      }
      if (this.$props.enableSelect) {
        setTimeout(() => {
          newValue.map((e) => {
            if (findIndex(this.selected, (s: any) => s && s.id === e.id) > -1) {
              this.$refs.table.toggleRowSelection(e, true);
            }
          });
        }, 0);
      }

      return;
    }
  }
})
export class ListData extends Vue {
  public pagination: IPaginationParams;
  public actions: IActionForListDataComponent;
  public data: any[];
  public $refs: {
    table: ElTable;
  };
  public selected: string[];
  public ItemPage = ItemPage;
  public expandRowKeys: string[] = [];

  public currentChange(newValue) {
    this.pagination.page = newValue;
    // this.pagination.offset = newValue * this.pagination.limit;
    window.scrollTo(0, 0);
    this.$emit('changePagination', this.pagination);
  }

  public handlePagination(val) {
    this.$emit('handlePagination', val);
  }

  public get convertActions(): object[] {

    return (
      this.actions &&
      this.actions.map((e) => {
        if (typeof e === 'object') {
          return e;
        }

        return {
          label: e,
          isShow: (_) => {
            return true;
          }
        };
      })
    );
  }
  public showConvertActions(value) {
    const temp =
      this.convertActions &&
      this.convertActions
        .map((e: any) => e.isShow && !e.isShow(value))
        .find((el) => !el);
    if (temp === undefined) {
      return false;
    }

    return true;
  }

  public formatterActionLabel(
    action: IItemActionForListDataComponent,
    model: any
  ) {
    // console.log(model, action);
    if (
      action.label === 'block' &&
      (model.status === StatusCode.Blocked || model.campaignStatus === StatusCode.Blocked ||
        model.productStatus === StatusCode.Blocked)
    ) {
      return this.$t('un.block');
    }
    if (action.label === 'disable' && model.status === StatusCode.Disabled) {
      return this.$t('available');
    }
    if (
      (action.label === 'block' || action.label === 'disable') &&
      model.serviceStatus
    ) {
      if (
        model.serviceStatus === ServiceStatus.Available &&
        action.label === 'block'
      ) {
        return 'Block';
      }
      if (
        model.serviceStatus === ServiceStatus.Available &&
        action.label === 'disable'
      ) {
        return 'Disable';
      }

      return 'Active';
    }

    return this.$t(action.label);
  }

  public checkSlot(name) {
    const slots = Object.keys(this.$slots);
    const scopedSlots = Object.keys(this.$scopedSlots);
    if (Array.isArray(scopedSlots) && scopedSlots.length > 0) {
      return scopedSlots.find((e) => name === e);
    }
    if (Array.isArray(slots) && slots.length > 0) {
      return slots.find((e) => name === e);
    }

    return false;
  }

  public checkValue(value) {
    return value ? true : false;
  }
  public onClickHeader(propName) {
    this.$emit('handleSortChange', propName);
  }
  public sortChange(sortProps) {
    // tslint:disable-next-line: no-unused
    let order = '';
    if (sortProps.order) {
      switch (sortProps.order) {
      case 'descending':
        order = this.$props.sortWithoutNull ? 'desc_nulls_first' : 'desc';
        break;
      case 'ascending':
        order = this.$props.sortWithoutNull ? 'asc_nulls_last' : 'asc';
        break;

      default:
        break;
      }
    }
    const x = {};
    x[`${sortProps.prop}`] = order;
    const sort = sortProps.prop && sortProps.order ? x : {};
    this.$emit('handleSort', sort);
  }

  public clickDropdown(newValue: string) {
    const value = newValue.split('|'),
      type = value[0],
      id = value[1];
    switch (type) {
    case 'detail':
    case 'view':
      this.$emit('clickDetail', id);
      break;
    case 'edit':
      this.$emit('clickUpdate', id);
      break;
    case 'approve':
      this.$emit('clickApprove', id);
      break;
    case 'reject':
      this.$emit('clickReject', id);
      break;
    case 'delete':
      this.deleteConfirm(id);
      break;
    case 'disable':
      this.disabledConfirm(id);
      break;
    case 'block':
      this.disabledConfirm(id);
      break;
    case 'change-password':
      this.$emit('changePassword', id.toString());
      break;
    case 'change-status':
      this.$emit('changeStatus', id.toString());
      break;
    case 'upgrade-request':
      this.$emit('upgradeRequest', id.toString());
      break;
    case 'templating':
      this.$emit(
          'templating',
          find(this.$props.data, (e) => e.id.toString() === id)
        );
      break;
    case 'close':
      this.$emit('clickClose', id.toString());
      break;
    }

    this.actions.forEach((e) => {
      if (typeof e === 'object' && type === e.label) {
        this.$emit(e.emitName, id);
      }
    });
  }

  public clickDetail(id: string) {
    this.$emit('clickDetail', id);
  }
  public text(model, scope) {
    return model[scope.column.property];
  }

  public disabledConfirm(id: string) {
    this.$msgbox({
      title: this.$t('confirm').toString(),
      message: this.$t('action_confirm_message').toString(),
      showCancelButton: true,
      confirmButtonText: this.$t('confirm').toString(),
      cancelButtonText: this.$t('cancel').toString(),
      beforeClose: (action, instance, done) => {
        if (action === 'confirm') {
          instance.confirmButtonLoading = true;
          instance.confirmButtonText = 'Loading...';
          this.$emit('handleDisabled', { id, instance, done });
        } else {
          done();
        }

        return;
      }
    }).catch(() => {
      // no handle
    });
  }
  public deleteConfirm(id: string) {
    // const model = find(this.data, (e) => e.id.toString() === id.toString());
    // console.log(model);
    // if (model.status === StatusCode.Suspended && this.$props.noHandleDisabled) {
    //   return;
    // }
    this.$msgbox({
      title: this.$t('delete_confirm').toString(),
      message: this.$t('delete_confirm_message').toString(),
      showCancelButton: true,
      customClass: 'message-error',
      confirmButtonText: this.$t('confirm').toString(),
      cancelButtonText: this.$t('cancel').toString(),
      beforeClose: (action, instance, done) => {
        if (action === 'confirm') {
          instance.confirmButtonLoading = true;
          instance.confirmButtonText = 'Loading...';
          this.$emit('handleDelete', { id, instance, done });
        } else {
          done();
        }

        return;
      }
    })
      .then(() => {
        this.$message({
          type: 'success',
          message: this.$t('message.deleteSuccess').toString()
        });
      })
      .catch(() => {
        // no handle
      });
  }

  public selectAll(val: any[]) {
    console.log(val);
    // console.log('selectAll', val);
    if (val.length === 0) {
      // const ids = cloneDeep(this.data).map((e) => e.id);
      // console.log(ids);
      // this.$store.commit(MutationTypeBooking.RemoveTechniciansAssigning, ids);
      // return;
    }
    this.$emit('changeSelection', val);
    // tslint:disable-next-line:early-exit
    if (this.$props.handleSelected) {
      if (val.length === 0) {
        this.$emit('onSelectAll', {val, remove: true, removeData: this.data});

        return;
      }
      this.$emit('onSelectAll', {val, remove: false});
    }
  }
  // public handleSelectionChange(val: any) {
  //   // this.$emit('changeSelection', val.map((e) => e && e.id));
  //   this.$emit('changeSelection', val.filter((e) => e && e.id));
  // }
  public removeSelected(id: string) {
    const row = this.data.find((e) => e.id === id);
    if (row) {
      this.$refs.table.toggleRowSelection(row);
    }
  }
  public select(rows, current) {
    if (this.$props.handleSelected) {
      this.$emit('changeItem', current);
    }
    if (this.$props.multiple) {
      this.$emit('changeSelection', rows);
      if (findIndex(rows, (e: any) => e.id === current.id) < 0) {
        this.$emit('removeSelected', current.id);
      }

      return;
    }
    if (rows.length === 0) {
      this.$emit('removeSelected', current.id);
    }
    rows.map((e) => {
      if (e.id === current.id) {
        this.$emit('changeSelection', e);
      }
      this.$refs.table.toggleRowSelection(e, e.id === current.id);
    });
  }
  public refreshData() {
    this.$refs.table.clearSelection();
  }
  public handleRowClick(model) {
    this.$emit('rowClick', model);
  }
  public getTableIndex(index: number) {
    if (!this.pagination.limit && (!this.pagination.page || this.pagination.page === 1)) {
      return index + 1;
    }

    return this.pagination.limit * (this.pagination.page - 1) + 1 + index;
  }
  public toggleRowExpansion(row: { id: string }) {
    const isInclude = this.expandRowKeys.includes(row.id);
    if (isInclude) {
      this.expandRowKeys = [];

      return;
    }
    this.expandRowKeys = [row.id];
  }
}
