import {saveAs} from 'file-saver';
import JsZip from 'jszip';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { CustomCampaignRouterPath } from 'root/admin/CustomCampaign';
import { DealerRouterName } from 'root/admin/Dealer';
import { SystemRoleAlias } from 'root/admin/User/Store/types';
import { DKGqlClient } from 'root/api/graphql/Client';
import { gqlClient, IPaginationParams } from 'root/api/graphql/Core';
import { aclCampaignQuery } from 'root/api/graphql/Query/aclCampaign';
import {
  formatterPhoneNumber,
  parseUTCToTime,
  FormatDateFullTime,
  FormatDateTime
} from 'root/helpers';
import { ConfigurationKey, IAddress, IBreadcrumb, IFilterInput, IUser } from 'root/models';
import { pathUpload, uploadPdfToFirebase } from 'root/services';
import { FirebaseAuthService } from 'root/services/auth/firebase';
import { IState, MutationType } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import {
  ActionTypeAclCampaign,
  MutationTypeAclCampaign,
} from '../../Store/types';
import { CampaignDetailModel } from '../CampaignDetailModel';
import './styles.scss';

export enum ContractStatus {
  Accepted = 'accepted',
}
export enum RequestStatusCode {
  NotJoin = 'not_join',
  WaitingForReup = 'request_re_up',
  WaitingForReview = 'waiting_for_review',
  Approved = 'approved',
  Rejected = 'rejected',
}

export enum RequestStatus {
  NotJoin = 'Not Join',
  WaitingForReup = 'Waiting For Reup',
  WaitingForReview = 'Waiting For Review',
  Approved = 'Approved',
  Rejected = 'Rejected',
}
export enum RequestAction {
  Approved = 'approve',
  Rejected = 'reject',
  RequestUpdate = 'request_update',
}
export enum QualifiedCode {
  Qualified = 'qualified',
  NonQualified = 'non_qualified'
}
export enum Qualified {
  Qualified = 'Qualified',
  NonQualified = 'Non Qualified'
}
const enum FilterKey {
  Status = 'status'
}
@Component({
  template: require('./view.html'),
  components: {
    // editor: DealerEditor
    'shops-detail-modal': CampaignDetailModel,
  },
  computed: {
    ...mapState({
      authUser: (state: IState) => state.global.authUser,
      dealer: (state: IState) => state.aclCampaign.data,
      data: (state: IState) => state.aclCampaign.data,
      loading: (state: IState) => state.aclCampaign.loading,
      pagination: (state: IState) => state.aclCampaign.pagination,
      typeCollapse: (state: IState) => state.global.typeCollapse
    }),
    detail() {
      return this.$store.getters.getAclCampaignDetail(this.dealerId);
    },
    cloneData() {
      return this.data && this.data.length ? this.data[0].shops : [];
    },
  },
})
export class AclCampaignDetail extends Vue {
  public get dealerId(): string {
    return this.$route.params.id;
  }
  public get isSalePlanning() {
    if (this.authUser.superAdmin || this.authUser.role.alias === SystemRoleAlias.Admin
      || this.authUser.role.alias === SystemRoleAlias.SaleAdmin) {
      return true;
    }

    return false;
  }
  public get columns() {
    return [
      {
        prop: 'review at',
        label: 'Last review at',
        formatter: (model: any) => {
          return model.shop ? model.shop.name : '--';
        },
        width: 100,
      },
      {
        prop: 'classification',
        label: 'Last classification',
        formatter: (model: any) => {
          return model.shop ? model.shop.name : '--';
        },
        width: 150,
      },
      {
        prop: 'review content',
        label: 'last review content',
        formatter: (model: any) => {
          return model.shop ? model.shop.name : '--';
        },
        width: 300,
      },
      {
        prop: 'name',
        label: 'Name',
        formatter: (model: any) => {
          return model.shop ? model.shop.name : '--';
        },
        width: 250,
      },
      {
        prop: 'staff',
        label: 'Staff',
        width: 120,
        formatter: (model: any) => {
          return model.staff || '--';
        }
      },
      // {
      //   prop: 'phoneNumber',
      //   label: 'Phone',
      //   width: 180,
      //   formatter: (model: any) => {
      //     return model.shop && model.shop.phoneNumber ? model.shop.phoneNumber : '--';
      //   }
      // },
      // {
      //   prop: 'email',
      //   label: 'Email',
      //   width: 220,
      //   formatter: (model: any) => {
      //     return model.shop && model.shop.email ? model.shop.email : '--';
      //   }
      // },
      {
        prop: 'address',
        label: 'Address',
        width: 220,
        formatter: (model: any) => {
          if (model.shop.address) {
            return this.displayAddress(model.shop.address) || '--';
          }

          return '--';
        }
      },
      {
        prop: 'reject reason',
        label: 'Rejected Reason',
        width: 150,
        formatter: this.formatterReason
      },
      {
        prop: 'status',
        label: 'Request Status',
        width: 150,
        // formatter: (model: any) => {
        //   return model.shop.acl_campaigns.length && model.shop.acl_campaigns[0].requestStatus ?
        //    model.shop.acl_campaigns[0].requestStatus : '--';
        // },
        formatter: this.formaterStatus,
        labelStyle: (model: any) => {
          return model.shop && model.shop.acl_campaigns.length ?
          model.shop.acl_campaigns[0].requestStatus : RequestStatusCode.NotJoin;
        },
      },
      {
        prop: 'action',
        label: '',
        align: 'center',
        fixed: 'right',
        width: 60
      }
    ];
  }
  public get dataFilter() {
    const _statuses: IFilterInput[] = [
      {
        key: RequestStatusCode.NotJoin,
        value: RequestStatusCode.NotJoin,
        name: RequestStatus.NotJoin,
      },
      {
        key: RequestStatusCode.WaitingForReview,
        value: RequestStatusCode.WaitingForReview,
        name: RequestStatus.WaitingForReview,
      },
      {
        key: RequestStatusCode.WaitingForReup,
        value: RequestStatusCode.WaitingForReup,
        name: RequestStatus.WaitingForReup,
      },
      {
        key: RequestStatusCode.Approved,
        value: RequestStatusCode.Approved,
        name: RequestStatus.Approved,
      },
      {
        key: RequestStatusCode.Rejected,
        value: RequestStatusCode.Rejected,
        name: RequestStatus.Rejected,
      },
    ];

    return [
      {
        key: FilterKey.Status,
        name: this.$t('field.status'),
        value: _statuses
      }
    ];
  }
  public get selectedFilter() {
    const filterParams = cloneDeep(this.filterParams);

    return [
      {
        key: FilterKey.Status,
        value: filterParams[FilterKey.Status]
      }
    ];
  }
  public pathUpload: pathUpload = pathUpload.PDFEXPORT;
  public detail: any;
  public pagination: IPaginationParams;
  public searchText: string = '';
  public FormatDateFullTime = FormatDateFullTime;
  public formatDate = parseUTCToTime;
  public visibleEditor: boolean = false;
  public shopId: string = '';
  public visibleDetail: boolean = false;
  public cloneData: any[];
  public disableApprove: boolean = true;
  public disableDownload: boolean = true;
  public approveIds: any[] = [];
  public isTechnician: boolean;
  public exportLoading: boolean = false;
  public listExportIds: any[] = [];
  public authUser: IUser;
  public isOnCampaign: boolean = false;
  public typeCollapse: boolean;
  public ConfigCropPicture: string = 'NO';
  public filterParams = {
    [FilterKey.Status]: []
  };
  public back() {
    this.$router.push(DealerRouterName.ListDealer);
  }
  public formatterStaff(model: any) {
    return model.shop.dealerShops.filter(
      (item) => !item.isOwner && item.contractStatus === ContractStatus.Accepted
    ).length;
  }
  public formatterTime(model: any) {
    if (model.shop && model.shop.acl_campaigns.length && model.shop.acl_campaigns[0].reviews.length) {
      const last = model.shop.acl_campaigns[0].reviews.length - 1;

      return moment(model.shop.acl_campaigns[0].reviews[last].updatedAt).format('HH:mm');
    }

    return '--';
  }
  public addressFormater(model: any) {
    if (model) {
      return this.displayAddress(model) || '--';
    }

    return '--';
  }
  public formatDateTime(date: Date) {
    return moment(date).format('HH:mm, DD/MM/YYYY');
  }
  public formatterDate(model: any) {
    if (model.shop && model.shop.acl_campaigns.length && model.shop.acl_campaigns[0].reviews.length) {
      const last = model.shop.acl_campaigns[0].reviews.length - 1;

      return moment(model.shop.acl_campaigns[0].reviews[last].updatedAt).format('DD/MM/YYYY');
    }

    return '--';
  }
  public formatterClassification(model: any) {
    if (model.shop && model.shop.acl_campaigns.length && model.shop.acl_campaigns[0].classification) {
      switch (model.shop.acl_campaigns[0].classification) {
      case QualifiedCode.Qualified:
        return Qualified.Qualified;
      case QualifiedCode.NonQualified:
        return Qualified.NonQualified;
      }
    }

    return '--';
  }
  public formatterReason(model: any) {
    if (model.shop && model.shop.acl_campaigns.length && model.shop.acl_campaigns[0].requestStatus === 'rejected') {

      return model.shop.acl_campaigns[0].reason;
    }

    return '--';
  }
  public formatterContent(model: any) {
    if (model.shop && model.shop.acl_campaigns.length && model.shop.acl_campaigns[0].reviews.length) {
      const last = model.shop.acl_campaigns[0].reviews.length - 1;

      return model.shop.acl_campaigns[0].reviews[last].description.length < 120 ?
        model.shop.acl_campaigns[0].reviews[last].description
        : model.shop.acl_campaigns[0].reviews[last].description.slice(0, 120) + '...';
    }

    return '--';
  }

  public formaterStatus(model: any) {
    if (model.shop && model.shop.acl_campaigns.length) {
      switch (model.shop && model.shop.acl_campaigns.length && model.shop.acl_campaigns[0].requestStatus) {
      case RequestStatusCode.WaitingForReup:
        return RequestStatus.WaitingForReup;
      case RequestStatusCode.WaitingForReview:
        return RequestStatus.WaitingForReview;
      case RequestStatusCode.Approved:
        return RequestStatus.Approved;
      case RequestStatusCode.Rejected:
        return RequestStatus.Rejected;
      }
    }

    return RequestStatus.NotJoin;
  }
  public getAction() {
    return 'view';
  }
  public closeDialog() {
    this.visibleEditor = false;
  }
  public handleActions(id) {
    this.clickDetail(id.shop.id);
  }
  public approve() {
    // change after
    this.$msgbox({
      title: this.$t('approve_confirm').toString(),
      message: `Do you want to approve campaign request for ${this.approveIds.length} shop?`,
      showCancelButton: true,
      confirmButtonText: this.$t('confirm').toString(),
      cancelButtonText: this.$t('cancel').toString(),
      beforeClose: (action, instance, done) => {
        if (action === 'confirm') {
          instance.confirmButtonLoading = true;
          instance.confirmButtonText = 'Loading...';
          this.handleApprove({ instance, done });
        } else {
          done();
        }

        return;
      },
    })
      .then(() => {
        this.$message({
          type: 'success',
          message: this.$t('message.approveSuccess').toString(),
        });
      })
      .catch(() => {
        // no handle
      });
  }
  public handleApprove(model) {
    this.$store.dispatch(ActionTypeAclCampaign.ApproveShop, {
      ids: this.approveIds,
      action: 'approved',
      updatedBy: this.authUser.id,
      onSuccess: () => {
        model.instance.confirmButtonLoading = false;
        model.instance.confirmButtonText = 'Confirm';
        model.done();
        this.fetchData();
      },
      onFailure: () => {
        model.instance.confirmButtonLoading = false;
        model.instance.confirmButtonText = 'Confirm';
      },
    });
  }
  public formatPackage(metadata: any, packages: any) {
    if (!packages || packages.length === 0) {
      return '--';
    }
    // tslint:disable-next-line: prefer-const
    let result = '';
    for (const [key, value] of Object.entries(metadata)) {
      // tslint:disable-next-line:early-exit
      if (key.toString() === 'models') {
        packages.map((item, index) => {
          for (const [key1, val] of Object.entries(value)) {
            if (key1.toString() === item.toString()) {
              index === 0 ? result += (val.name) : result += (`, ${val.name}`);
            }
          }
        });
      }
    }

    return result;
  }
  public formatStartTimeEndtime(startTime: any , endTime: any) {
    if (!startTime || !endTime) {
      return '--';
    }

    return `${FormatDateTime(startTime)} - ${FormatDateTime(endTime)}`;
  }
  public formatStartTimeEndtimeFromMetadata(metadata, keyToDetech) {
    for (const [key, value] of Object.entries(metadata)) {
      if (key.toString() === keyToDetech) {
        return value;
      }
    }
  }
  public getBase64Image(url) {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.setAttribute('crossOrigin', 'anonymous');
      img.onload = () => {
        const canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0);

        return resolve(canvas.toDataURL('image/png'));
      };
      img.onerror = (error) => {
        return reject(error);
      };
      img.src = url;
    });
  }
  public async getBase64ImageFromURL(url) {
    // const reader = new window.FileReader();
    // fetch(url).then((response) => response.blob())
    // .then((myBlob) => reader.readAsDataURL(myBlob))
    // .then((myBase64) => {
    //   const imagesString = myBase64;
    //   console.log(imagesString);
    // }).catch((error) => {
    //   console.log(error);
    // });
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.setAttribute('crossOrigin', 'anonymous');

      img.onload = () => {
        const canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;

        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0);

        const dataURL = canvas.toDataURL('image/png');

        resolve(dataURL);
      };

      img.onerror = (error) => {
        reject(error);
      };

      img.src = url;
    });
  }
  public promiseZip(pdfDocGenerator, zip, data, currentTime) {

    return new Promise((resolve) => {
      pdfDocGenerator.getBase64((base64Data) => {
      // tslint:disable-next-line:max-line-length
        zip.file(`ACL Display - ${data.acl_campaigns[0].dealer.shortName} - ${data.acl_campaigns[0].shop.name} ${currentTime.toString()}.pdf`, base64Data , {base64 : true});

        return resolve(true);
      });
    });
  }
  public async download() {
    this.exportLoading = true;
    if (this.listExportIds.length === 0) {
      return;
    }
    const zip = new JsZip();
    const currentTime = moment(<any> Date.now()).utc(true).format('HH:mm, DD-MM-YYYY');
    await Promise.all(this.listExportIds.map(async (item) => {
      const results = await gqlClient.query({
        query: aclCampaignQuery.GET_PDF_EXPORT_DATA,
        variables: {
          id: item
        },
        fetchPolicy: 'network-only'
      });
      if (results.errors) {
        throw results.errors;
      }

      const data = results.data;
      const docDefinition = {
        content: [
          {
            table: {
              headerRows: 1,
              widths: [360, 'auto'],
              body: [
                [
                  {
                    image: await this.getBase64Image(
                      '/assets/img/logoDaikin.svg'
                    ),
                    width: 229,
                    height: 48,
                    border: [false, false, false, false],
                  },
                  {
                    table: {
                      width: ['*'],
                      body: [
                        [
                          {
                            text: 'DAIKIN VIETNAM',
                            border: [false, false, false, false],
                            alignment: 'right',
                            bold: 'true',
                          },
                        ],
                        [
                          {
                            text: 'www.daikin.com.vn',
                            border: [false, false, false, false],
                            alignment: 'right',
                          },
                        ],
                        [
                          {
                            text: 'hotrodaily@daikin.com.vn',
                            border: [false, false, false, false],
                            alignment: 'right',
                          },
                        ],
                        [
                          {
                            text: '180067777 (Miễn phí)',
                            border: [false, false, false, false],
                            alignment: 'right',
                          },
                        ],
                      ],
                      alignment: 'right',
                    },
                    border: [false, false, false, false],
                    alignment: 'right',
                  },
                ],
              ],
            },
            margin: [-3, -15, -15, 0],
            border: [true, true, true, true],
          },
          {
            columns: [
              {
                width: '50%',
                text: ' ',
                bold: 'true',
                alignment: 'right',
                lineHeight: '1',
              },
            ],
          },
          {
            columns: [
              { width: '50%', text: ' ' },
              {
                width: '50%',
                text: 'BIÊN BẢN XÁC NHẬN',
                bold: 'true',
                fontSize: 26,
                alignment: 'right',
                lineHeight: '1',
              },
            ],
          },
          {
            columns: [
              {
                width: '*',
                margin: [3, 30, 0, 0],
                text: ' THÔNG TIN CHƯƠNG TRÌNH ',
                bold: 'true',
                background: '#BEBEBE',
                lineHeight: '1',
              },
            ],
          },
          {
            table: {
              headerRows: 1,
              widths: ['100%', '100%'],
              body: [
                [
                  {
                    table: {
                      width: ['100%', 'auto'],
                      body: [
                        [
                          {
                            text: [
                              {
                                text: 'Thông báo số: ',
                                border: [false, false, false, false],
                                width: 'auto',
                                bold: 'true',
                              },
                              {
                                text: 'K1104/05A-AN_Ext',
                                border: [false, false, false, false],
                                width: 'auto',
                              }
                            ],
                            border: [false, false, false, false],
                          },
                        ],
                        [
                          {
                            text: [
                              {
                                text: 'Thời gian chương trình: ',
                                border: [false, false, false, false],
                                width: 200,
                                bold: 'true',
                              },
                              {
                                text: ' ',
                                width: 'auto',
                                border: [false, false, false, false],
                              },
                            ],
                            border: [false, false, false, false],
                          },
                        ],
                        [
                          {
                            text: [
                              {
                                text: 'Mua máy trưng bày: ',
                                border: [false, false, false, false],
                                width: '*',
                              },
                              {
                                text: `18/11/2021-31/12/2021`,
                                width: 'auto',
                                border: [false, false, false, false],
                              },
                              // {
                          //   text: `${data.acl_campaigns[0].campaign.metadata ?
                          //     this.formatStartTimeEndtimeFromMetadata(
                          //       data.acl_campaigns[0].campaign.metadata,
                          //     'Mua máy trưng bày') : '--'}`,
                          //   width: 'auto',
                          //   border: [false, false, false, false],
                          // },
                            ],
                            margin: [10, 0, 0, 0],
                            border: [false, false, false, false],
                          },
                        ],
                        [
                          {
                            text: [
                              {
                                text: 'Trưng bày: ',
                                border: [false, false, false, false],
                                width: '*',
                              },
                              {
                                text: `01/12/2021-28/02/2022`,
                                width: 'auto',
                                border: [false, false, false, false],
                              },
                              // {
                              //   text: `${data.acl_campaigns[0].campaign.metadata ?
                              //     this.formatStartTimeEndtimeFromMetadata(
                              //       data.acl_campaigns[0].campaign.metadata,
                              //     'Trưng bày') : '--'}`,
                              //   width: 'auto',
                              //   border: [false, false, false, false],
                              // },
                            ],
                            margin: [10, 0, 0, 0],
                            border: [false, false, false, false],
                          },
                        ],
                        [
                          {
                            text: [
                              {
                                text: 'Tải hình trưng bày lên ứng dụng: ',
                                border: [false, false, false, false],
                                width: '*',
                              },
                              {
                                text: `08/02/2022-28/02/2022`,
                                width: 'auto',
                                border: [false, false, false, false],
                              },
                              // {
                              //   text: `${data.acl_campaigns[0].campaign.aclCampaignConfig
                              //     && data.acl_campaigns[0].campaign.aclCampaignConfig ?
                              //     this.formatStartTimeEndtime(
                              //       data.acl_campaigns[0].campaign.aclCampaignConfig.uploadStartDate,
                              //       data.acl_campaigns[0].campaign.aclCampaignConfig.uploadEndDate) : '--'}`,
                              //   width: '*',
                              //   border: [false, false, false, false],
                              // },
                            ],
                            margin: [10, 0, 0, 0],
                            border: [false, false, false, false],
                          },
                        ],
                        [
                          {
                            text: [
                              {
                                text: 'Đánh giá trưng bày: ',
                                border: [false, false, false, false],
                                width: '*',
                              },
                              {
                                text: `15/02/2022-05/03/2022`,
                                width: 'auto',
                                border: [false, false, false, false],
                              },
                              // {
                              //   text: `${data.acl_campaigns[0].campaign.aclCampaignConfig
                              //     && data.acl_campaigns[0].campaign.aclCampaignConfig ?
                              //     this.formatStartTimeEndtime(
                              //       data.acl_campaigns[0].campaign.aclCampaignConfig.reviewStartDate,
                              //       data.acl_campaigns[0].campaign.aclCampaignConfig.reviewEndDate) : '--'}`,
                              //   width: '*',
                              //   border: [false, false, false, false],
                              // },
                            ],
                            margin: [10, 0, 0, 0],
                            border: [false, false, false, false],
                          },
                        ],
                        [
                          {
                            text: [
                              {
                                text: 'Trả thưởng: ',
                                border: [false, false, false, false],
                                width: 'auto',
                              },
                              {
                                text: `05/03/2022-31/03/2022`,
                                width: 'auto',
                                border: [false, false, false, false],
                              },
                              // {
                              //   text: `${data.acl_campaigns[0].campaign.metadata ?
                              //     this.formatStartTimeEndtimeFromMetadata(
                              //       data.acl_campaigns[0].campaign.metadata,
                              //     'Trả thưởng') : '--'}`,
                              //   width: 'auto',
                              //   border: [false, false, false, false],
                              // },
                            ],
                            margin: [10, 0, 0, 0],
                            border: [false, false, false, false],
                          },
                        ],
                        [
                          {
                            text: [
                              {
                                text: 'Đối tượng: ',
                                border: [false, false, false, false],
                                bold: 'true',
                                width: 'auto',
                              },
                              {
                                text: 'Đại lý trực tiếp',
                                border: [false, false, false, false],
                                width: 'auto',
                              },
                            ],
                            border: [false, false, false, false],
                          },
                        ],
                        [
                          {
                            text: [
                              {
                                text: 'Sản phẩm áp dụng: ',
                                border: [false, false, false, false],
                                alignment: 'left',
                                bold: 'true',
                                width: 'auto',
                              },
                              {
                                text: 'Máy lọc không khí',
                                border: [false, false, false, false],
                                alignment: 'left',
                                width: 'auto',
                              },
                            ],
                            border: [false, false, false, false],
                          },
                        ],
                        [
                          {
                            text: [
                              {
                                text: 'Nội dung: ',
                                border: [false, false, false, false],
                                alignment: 'left',
                                bold: 'true',
                                width: 'auto',
                              },
                              {
                                // tslint:disable-next-line: max-line-length
                                text: `Khi Đại lý mua một trong các gói trưng bày dưới đây và sử dụng để tham gia chương trình “hỗ trợ trưng bày và kinh doanh máy lọc không khí Daikin” thì Đại lý sẽ được mua thêm một gói hỗ trợ với giá giảm 50% so với giá niêm yết.`,
                                width: 'auto',
                                border: [false, false, false, false],
                                alignment: 'left',
                              },
                            ],
                            border: [false, false, false, false],
                          },
                        ],
                        [
                          {
                            text: [
                              {
                                text: 'Điều kiện: ',
                                border: [false, false, false, false],
                                alignment: 'left',
                                bold: 'true',
                                width: 'auto',
                              },
                              {
                                text: '---',
                                width: 'auto',
                                border: [false, false, false, false],
                                alignment: 'left',
                              },
                            ],
                            border: [false, false, false, false],
                          },
                        ],
                      ],
                      alignment: 'left',
                    },
                    border: [true, true, true, true],
                    alignment: 'left',
                  },
                ],
              ],
            },
            margin: [0, 0, -11, 0],
            border: [true, true, true, true],
          },
          {
            columns: [
              {
                width: '*',
                margin: [3, 20, 0, 0],
                text: ' THÔNG TIN HÓA ĐƠN ',
                bold: 'true',
                background: '#BEBEBE',
                lineHeight: '1.2',
              },
            ],
          },
          {
            table: {
              headerRows: 1,
              widths: ['100%', 'auto'],
              body: [
                [
                  {
                    table: {
                      width: ['100%'],
                      body: [
                        [
                          {
                            text: [
                              {
                                text: 'Tên đại lý: ',
                                border: [false, false, false, false],
                                alignment: 'left',
                                width: '*',
                                bold: 'true',
                              },
                              {
                                // tslint:disable-next-line:max-line-length
                                text: `${data.acl_campaigns[0].dealer.name ? data.acl_campaigns[0].dealer.name : '--'}`,
                                alignment: 'left',
                                border: [false, false, false, false],
                              },
                            ],
                            border: [false, false, false, false],
                          },
                        ],
                        [
                          {
                            text: [
                              {
                                text: 'Mã đại lý: ',
                                border: [false, false, false, false],
                                alignment: 'left',
                                width: '*',
                                bold: 'true',
                              },
                              {
                                text: `${data.acl_campaigns[0].dealer.dealerCode
                                  ? data.acl_campaigns[0].dealer.dealerCode : '--'}`,
                                width: '*',
                                border: [false, false, false, false],
                                alignment: 'left',
                              },
                            ],
                            border: [false, false, false, false],
                          },
                        ],
                        [
                          {
                            text: [
                              {
                                text: 'Địa chỉ: ',
                                border: [false, false, false, false],
                                alignment: 'left',
                                bold: 'true',
                                width: '*',
                              },
                              {
                                text: `${this.addressFormater(data.acl_campaigns[0].dealer.address) || '--'}`,
                                border: [false, false, false, false],
                                alignment: 'left',
                                width: '*',
                              },
                            ],
                            border: [false, false, false, false],
                          },
                        ],
                        [
                          {
                            text: [
                              {
                                text: 'Mã số thuế: ',
                                border: [false, false, false, false],
                                alignment: 'left',
                                bold: 'true',
                                width: '*',
                              },
                              {
                                text: `${data.acl_campaigns[0].dealer.taxNumber
                                  ? data.acl_campaigns[0].dealer.taxNumber : '--'}`,
                                border: [false, false, false, false],
                                alignment: 'left',
                                width: '*',
                              },
                            ],
                            border: [false, false, false, false],
                          },
                        ],
                        [
                          {
                            text: [
                              {
                                text: 'Chủ sở hữu: ',
                                border: [false, false, false, false],
                                alignment: 'left',
                                bold: 'true',
                                width: '*',
                              },
                              {
                                text: `${data.acl_campaigns[0].dealer.ownerName
                                  ? data.acl_campaigns[0].dealer.ownerName : '--'}`,
                                width: '*',
                                border: [false, false, false, false],
                                alignment: 'left',
                              },
                            ],
                            border: [false, false, false, false],
                          },
                        ],
                        [
                          {
                            text: [
                              {
                                text: 'Chức vụ: ',
                                border: [false, false, false, false],
                                alignment: 'left',
                                bold: 'true',
                                width: '*',
                              },
                              {
                                text: `${data.acl_campaigns[0].dealer.ownerPosition
                                  ? data.acl_campaigns[0].dealer.ownerPosition : '--'}`,
                                width: '*',
                                border: [false, false, false, false],
                                alignment: 'left',
                              },
                            ],
                            border: [false, false, false, false],
                          },
                        ],
                      ],
                      alignment: 'left',
                    },
                    border: [true, true, true, true],
                    alignment: 'left',
                  },
                ],
              ],
            },
            margin: [0, 0, -11, 0],
          },
          {
            columns: [
              {
                width: '50%',
                text: ' ',
                bold: 'true',
                alignment: 'right',
                lineHeight: '1',
              },
            ],
          },
          {
            columns: [
              {
                width: '*',
                text: ' THÔNG TIN ĐÁNH GIÁ ',
                bold: 'true',
                background: '#BEBEBE',
                lineHeight: '1.2',
                margin: [3, 20, 0, 0],
              },
            ],
          },
          {
            columns: [
              {
                width: '50%',
                text: ' ',
                bold: 'true',
                alignment: 'right',
                lineHeight: '1',
              },
            ],
          },
          {
            columns: [
              {
                width: 500,
                text: `${data.acl_campaigns[0].shop.name ? data.acl_campaigns[0].shop.name : '--'}`,
                bold: 'true',
                background: '#BEBEBE',
                lineHeight: '1.2',
              },
            ],
          },
          {
            table: {
              headerRows: 1,
              widths: ['*', '*', '*', '*'],
              body: [
                [
                  {
                    table: {
                      widths: ['100%', 'auto'],
                      body: [
                        [
                          {
                            text: [
                              {
                                text: 'Mã cửa hàng: ',
                                border: [false, false, false, false],
                                alignment: 'left',
                                bold: 'true',
                              },
                              {
                                text: `${data.acl_campaigns[0].shop.shopCode
                                  ? data.acl_campaigns[0].shop.shopCode : '--'}`,
                                border: [false, false, false, false],
                                alignment: 'left',
                              },
                            ],
                            border: [false, false, false, false],
                          },
                        ],
                        [
                          {
                            text: [
                              {
                                text: 'Địa chỉ: ',
                                border: [false, false, false, false],
                                alignment: 'left',
                                bold: 'true',
                              },
                              {
                                text: `${this.addressFormater(data.acl_campaigns[0].shop.address) || '--'}`,
                                border: [false, false, false, false],
                                alignment: 'left',
                              },
                            ],
                            border: [false, false, false, false],
                          },
                        ],
                        [
                          {
                            text: [
                              {
                                text: 'Sản phẩm tham gia chương trình: ',
                                border: [false, false, false, false],
                                alignment: 'left',
                                bold: 'true',
                              },
                              {
                                text:
                                // tslint:disable-next-line:max-line-length
                                `${this.formatPackage(data.acl_campaigns[0].campaign.metadata, data.acl_campaigns[0].packages)}`,
                                border: [false, false, false, false],
                                alignment: 'left',
                              },
                            ],
                            border: [false, false, false, false],
                          },
                        ],
                      ],
                      alignment: 'left',
                    },
                    border: [true, true, true, true],
                    alignment: 'left',
                    margin: [0, 0, 5, 0],
                    colSpan: 4,
                  },
                  { text: '', border: [false, false, false, false] },
                  { text: '', border: [true, true, true, false] },
                  { text: '', border: [false, false, false, false] },
                ],
              ],
            },
            margin: [0, 0, 0, 0],
          },
          { text: ' ', fontSize: 14, bold: true, pageBreak: 'before', margin: [0, 0, 0, 8] },
          {
            table: {
              headerRows: 1,
              widths: ['*', '*', '*', '*'],
              body: [
                // [
                //   {
                //     table: {
                //       width: ['*'],
                //       body: [
                //         [
                //           {
                //             text: 'Mã cửa hàng:',
                //             border: [false, false, false, false],
                //             alignment: 'left',
                //             bold: 'true',
                //           },
                //           {
                //             text: `${data.acl_campaigns[0].shop.shopCode
                //               ? data.acl_campaigns[0].shop.shopCode : '--'}`,
                //             border: [false, false, false, false],
                //             alignment: 'left',
                //           },
                //         ],
                //         [
                //           {
                //             text: 'Địa chỉ:',
                //             border: [false, false, false, false],
                //             alignment: 'left',
                //             bold: 'true',
                //           },
                //           {
                //             text: `${this.addressFormater(data.acl_campaigns[0].shop.address) || '--'}`,
                //             border: [false, false, false, false],
                //             alignment: 'left',
                //           },
                //         ],
                //         [
                //           {
                //             text: 'Gói chương trình:',
                //             border: [false, false, false, false],
                //             alignment: 'left',
                //             bold: 'true',
                //           },
                //           {
                //             text:
                //             // tslint:disable-next-line:max-line-length
                // tslint:disable-next-line:max-line-length
                //             `${this.formatPackage(data.acl_campaigns[0].campaign.metadata, data.acl_campaigns[0].package)}`,
                //             border: [false, false, false, false],
                //             alignment: 'left',
                //           },
                //         ],
                //       ],
                //       alignment: 'left',
                //     },
                //     border: [true, true, true, true],
                //     alignment: 'left',
                //     margin: [-3, 0, -15, 0],
                //     colSpan: 4,
                //   },
                //   { text: '', border: [false, false, false, false] },
                //   { text: '', border: [true, true, true, false] },
                //   { text: '', border: [false, false, false, false] },
                // ],
                [
                  {
                    text: 'HÌNH ẢNH TRƯNG BÀY',
                    alignment: 'left',
                    margin: [0, 10, 0, 0],
                    lineHeight: '1',
                    bold: 'true',
                    colSpan: 4,
                    border: [true, true, true, false],
                  },
                  { text: '', border: [false, false, false, false] },
                  { text: '', border: [false, false, false, false] },
                  { text: '', border: [false, false, false, false] },
                ],
                [
                  {
                    image: await this.getBase64ImageFromURL(
                      this.ConfigCropPicture === 'YES' && data.acl_campaigns[0].resizedPictureURLs.front ?
                      data.acl_campaigns[0].resizedPictureURLs.front :
                      data.acl_campaigns[0].pictureURLs.front ? data.acl_campaigns[0].pictureURLs.front : ''
                    ),
                    fit: [120, 120],
                    alignment: 'center',
                    border: [true, false, false, false],
                  },
                  {
                    image: await this.getBase64ImageFromURL(
                      this.ConfigCropPicture === 'YES' && data.acl_campaigns[0].resizedPictureURLs.inside ?
                      data.acl_campaigns[0].resizedPictureURLs.inside :
                      data.acl_campaigns[0].pictureURLs.inside ? data.acl_campaigns[0].pictureURLs.inside : ''
                    ),
                    fit: [120, 120],
                    alignment: 'center',
                    border: [false, false, false, false],
                  },
                  {
                    image: await this.getBase64ImageFromURL(
                      this.ConfigCropPicture === 'YES' && data.acl_campaigns[0].resizedPictureURLs.close ?
                      data.acl_campaigns[0].resizedPictureURLs.close :
                      data.acl_campaigns[0].pictureURLs.close ? data.acl_campaigns[0].pictureURLs.close : ''
                    ),
                    fit: [120, 120],
                    border: [false, false, false, false],
                    alignment: 'center',
                  },
                  {
                    image: await this.getBase64ImageFromURL(
                      this.ConfigCropPicture === 'YES' && data.acl_campaigns[0].resizedPictureURLs.far ?
                      data.acl_campaigns[0].resizedPictureURLs.far :
                      data.acl_campaigns[0].pictureURLs.far ? data.acl_campaigns[0].pictureURLs.far : ''
                    ),
                    fit: [120, 120],
                    alignment: 'center',
                    border: [false, false, true, false],
                  },
                ],
                [
                  {
                    text: '1. Mặt tiền cửa hàng',
                    alignment: 'center',
                    italics: 'true',
                    border: [true, false, false, true],
                  },
                  // tslint:disable-next-line:max-line-length
                  {
                    text: '2. Bên trong cửa hàng',
                    alignment: 'center',
                    italics: 'true',
                    border: [false, false, false, true],
                  },
                  {
                    text: '3. Chụp gần',
                    alignment: 'center',
                    italics: 'true',
                    border: [false, false, false, true],
                  },
                  {
                    text: '4. Chụp xa',
                    alignment: 'center',
                    italics: 'true',
                    border: [false, false, true, true],
                  },
                ],
              ],
            },
            margin: [0, 0, 0, 0],
          },
          {
            table: {
              headerRows: 1,
              widths: ['100%', 'auto'],
              body: [
                [
                  {
                    table: {
                      width: ['100%'],
                      body: [
                        [
                          {
                            text: [
                              {
                                text: 'KẾT QUẢ ',
                                alignment: 'left',
                                lineHeight: '1',
                                margin: [0, 10, 0, 0],
                                bold: 'true',
                                border: [false, false, false, false],
                              },
                              {
                                margin: [0, 10, 0, 0],
                                text: 'Đủ điều kiện',
                                color: '#fff',
                                background: '#BEBEBE',
                                colSpan: 3,
                                border: [false, false, false, false],
                              },
                            ],
                            border: [false, false, false, false],
                          },
                        ],
                        [
                          {
                            text: [
                              {
                                text: 'Đại lý đủ điều kiện tham gia chương trình và nhận ưu đãi như thông báo ',
                                alignment: 'left',
                                colSpan: 4,
                                border: [false, false, false, false],
                              },
                            ],
                            border: [false, false, false, false],
                          },
                        ],
                        [
                          {
                            text: [
                              {
                                text: 'Đối tượng đánh giá lần đầu: ',
                                bold: 'true',
                                alignment: 'left',
                                border: [false, false, false, false],
                              },
                              {
                                text: `${data.acl_campaigns[0].reviews.length > 0 ?
                                  data.acl_campaigns[0].reviews[0].createdUser.name : '--'}`,
                                border: [false, false, false, false],
                              },
                            ],
                            border: [false, false, false, false],
                          },
                        ],
                        [
                          {
                            text: [
                              {
                                text: 'Đối tượng xác nhận lần cuối: ',
                                bold: 'true',
                                alignment: 'left',
                                border: [false, false, false, false],
                              },
                              {  text: `${data.acl_campaigns[0].updatedUser ?
                                data.acl_campaigns[0].updatedUser.name : '--'}`,
                                border: [false, false, false, false],
                              }
                            ],
                            border: [false, false, false, false],
                          },
                        ],
                      ],
                      alignment: 'left',
                    },
                    border: [true, false, true, true],
                    alignment: 'left',
                  },
                ],
              ],
            },
            margin: [0, 0, -11, 0],
          },
          {
            columns: [
              {
                width: '50%',
                text: ' ',
                bold: 'true',
                alignment: 'right',
                lineHeight: '1',
              },
            ],
          },
          {
            columns: [
              {
                width: '*',
                text: 'Đại Lý và Daikin Vietnam đồng xác nhận kết quả trưng bày',
                bold: 'true',
                // color: '#767676',
                lineHeight: '1.2',
              },
            ],
          },
          // {
          //   columns: [
          //     {
          //       width: '*',
          //       // tslint:disable-next-line:max-line-length
          // tslint:disable-next-line: max-line-length
          //       text: '- Đại lý xác nhận thông tin kê khai trên là chính xác và hoàn toàn chịu trách nhiệm nếu thông tin bị sai lệch ',
          //       italics: 'true',
          //       lineHeight: '1',
          //     },
          //   ],
          // },
          // {
          //   columns: [
          //     {
          //       width: '*',
          //       // tslint:disable-next-line:max-line-length
          //       text: '- Biên bản này được lập thành 02 (hai) bản có giá trị pháp lý như nhau. Mỗi bên giữ một bản',
          //       italics: 'true',
          //       lineHeight: '1',
          //     },
          //   ],
          // },
          // {
          //   columns: [
          //     {
          //       width: '*',
          //       // tslint:disable-next-line:max-line-length
          //       text: ` - Dữ liệu của báo cáo này được xuất vào ${FormatDateFullTime(<any > Date.now())} ` ,
          //       italics: 'true',
          //       lineHeight: '1',
          //     },
          //   ],
          // },
          // {
          //   columns: [
          //     {
          //       width: '50%',
          //       text: ' ',
          //       bold: 'true',
          //       alignment: 'right',
          //       lineHeight: '2',
          //     },
          //   ],
          // },
          // {
          //   table: {
          //     // headers are automatically repeated if the table spans over multiple pages
          //     // you can declare how many rows should be treated as headers
          //     headerRows: 1,
          //     widths: ['*', '*', '*'],
          //     body: [
          //       [
          //         {
          //           table: {
          //             width: ['*'],
          //             body: [
          //               [
          //                 {
          //                   text: `${data.acl_campaigns[0].shop.name ? data.acl_campaigns[0].shop.name : '--'}`,
          //                   border: [false, false, false, false],
          //                   alignment: 'cẹnter',
          //                   bold: 'true',
          //                 },
          //               ],
          //               [
          //                 {
          //                   text: ' ',
          //                   border: [false, false, false, false],
          //                   alignment: 'center',
          //                   bold: 'true',
          //                   lineHeight: '5',
          //                 },
          //               ],
          //             ],
          //             alignment: 'left',
          //             border: [false, false, false, false],
          //           },
          //           border: [false, false, false, false],
          //         },
          //         { text: '', border: [false, false, false, false] },
          //         {
          //           table: {
          //             width: ['*'],
          //             body: [
          //               [
          //                 {
          //                   text: 'DAIKIN VIETNAM',
          //                   border: [false, false, false, false],
          //                   alignment: 'cẹnter',
          //                   bold: 'true',
          //                 },
          //               ],
          //               [
          //                 {
          //                   text: ' ',
          //                   border: [false, false, false, false],
          //                   alignment: 'center',
          //                   bold: 'true',
          //                   lineHeight: '5',
          //                 },
          //               ],
          //             ],
          //             alignment: 'left',
          //             border: [false, false, false, false],
          //           },
          //           border: [false, false, false, false],
          //         },
          //       ],
          //     ],
          //   },
          //   border: [false, false, false, false],
          // },
        ],
      };
      pdfMake.vfs = pdfFonts.pdfMake.vfs;
      const pdfDocGenerator = pdfMake.createPdf(<any> docDefinition);
      await this.promiseZip(pdfDocGenerator, zip, data, currentTime);

    }));
    zip.generateAsync({type: 'blob'})
        .then(async (content) => {
          saveAs(content, `ACL Display - ${this.detail.shortName} ${currentTime.toString()}`);
          const token = await DKGqlClient().user.getFirebaseAuthenticateToken();
          await FirebaseAuthService().signInWithCustomToken(token.authenticate);
          await uploadPdfToFirebase(
            content,
            this.pathUpload,
            `ACL Display - ${this.detail.shortName} ${currentTime.toString()}.zip`
            );
          this.exportLoading = false;
        });
  }
  public displayAddress(model: IAddress) {
    return model && model.province
      ? this.$store.getters['getAddressString'](model)
      : '';
  }
  public formatUTCDate(date) {
    return moment(date).utc().format('DD/MM/YYYY');
  }
  public formatterPhoneNumber(phone) {
    return formatterPhoneNumber(phone);
  }
  public clickDetail(id: string) {
    this.shopId = id;
    this.visibleDetail = true;
  }
  public closeDialogDetail() {
    // change after
    this.shopId = '';
    this.visibleDetail = false;
    this.fetchData();
  }
  public handlePaginationChange(value) {
    if (typeof value === 'number') {
      this.$store.commit(MutationTypeAclCampaign.PaginationChange, {
        ...this.pagination,
        size: value,
      });
    } else {
      this.$store.commit(MutationTypeAclCampaign.PaginationChange, {
        ...this.pagination,
        ...value,
      });
    }
    this.fetchData();
  }
  public changeSelect(e) {
    this.approveIds = [];
    this.listExportIds = [];
    if (
      e.length && e.every(
        (item) =>
          item.shop && item.shop.acl_campaigns.length && item.shop.acl_campaigns[0].requestStatus ===
          RequestStatusCode.WaitingForReview || item.shop && item.shop.acl_campaigns.length
          && item.shop.acl_campaigns[0].requestStatus === RequestStatusCode.WaitingForReup
      )
    ) {
      this.disableApprove = false;
      e.map((it) => this.approveIds.push(it.shop && it.shop.acl_campaigns.length && it.shop.acl_campaigns[0].id));

      return;
    }
    if (
      e.length && e.every(
        (item) =>
          item.shop && item.shop.acl_campaigns.length
          && item.shop.acl_campaigns[0].requestStatus === RequestStatusCode.Approved
      )
    ) {
      this.disableDownload = false;
      e.map((it) => this.listExportIds.push(it.shop && it.shop.acl_campaigns.length && it.shop.acl_campaigns[0].id));

      return;
    }

    // e.map((it) => {
    //   if (it.shop && it.shop.acl_campaigns.length && it.shop.acl_campaigns[0].requestStatus ===
    //     RequestStatusCode.Approved) {
    //     this.listExportIds.push(it.shop.acl_campaigns[0].id);
    //   }}
    //     );

    // this.listExportIds && this.listExportIds.length > 0
    //     ? (this.disableDownload = false)
    //     : (this.disableDownload = true);
    this.disableApprove = true;
    this.disableDownload = true;
  }
  public removeItem() {
    this.disableDownload = true;
  }
  public handleSearch() {
    this.fetchData();
  }
  public changeFilter(model) {

    const status = model.find((e) => e.key === FilterKey.Status);
    const nextFilter = {
      [FilterKey.Status]: status ? status.value : []
    };
    this.filterParams = {
      ...cloneDeep(this.filterParams),
      ...nextFilter
    };
    this.fetchData();
  }
  protected mounted() {
    this.$nextTick(() => {
      const breadcrumb: IBreadcrumb[] = [
        {
          label: 'title.campaign',
          path: null,
          current: false,
        },
        {
          label: 'title.acl_campaign',
          path: CustomCampaignRouterPath.ACLCampaignList,
          current: true,
        },
        {
          label: 'title.view_details',
          path: null,
          current: true,
        },
      ];
      this.$store.commit(MutationType.SetShowCollapse);
      this.$store.commit(MutationType.SetTypeCollapse, true);
      this.$store.commit(MutationType.SetBreadcrumb, breadcrumb);
      this.$store.dispatch(ActionTypeAclCampaign.GetConfigCropPicture, {
        onSuccess: (config) => {
          this.ConfigCropPicture =
            config && config.system_configurations.length ? config.system_configurations.find((e) =>
            e.variableName === ConfigurationKey.ACLCampaignCropPicture).value : 'NO';
        }
      });
      this.$store.dispatch(ActionTypeAclCampaign.GetCampaignConfiguration, {
        onSuccess: (aclConfig) => {
          if (moment(aclConfig[0].aclCampaignConfig.uploadStartDate).isBefore(moment()) &&
            moment(aclConfig[0].aclCampaignConfig.uploadEndDate).isAfter(moment())) {
            this.isOnCampaign = true;
          }
        }
      });
      this.fetchData();
    });
  }
  protected beforeDestroy() {
    this.$store.commit(MutationType.ClearShowCollapse);
    this.$store.commit(MutationType.ClearBreadcrumb);
  }
  private fetchData() {
    const text = cloneDeep(this.searchText);
    const _filterStatus = <any> {};
    let _notJoin = true;
    let _cloneFilter = null;
    if (this.filterParams[FilterKey.Status].length) {
      _filterStatus.status = this.filterParams[FilterKey.Status];
    }
    if (_filterStatus.status && _filterStatus.status.indexOf('not_join') < 0) {
      _notJoin = false;
    }
    if (_filterStatus.status && _filterStatus.status.indexOf('not_join') >= 0) {
      const _index = _filterStatus.status.indexOf('not_join');
      _cloneFilter = [..._filterStatus.status];
      _cloneFilter.splice(_index, 1);
      _notJoin = true;
    }
    this.$store.dispatch(ActionTypeAclCampaign.GetAclCampaignDetail, {
      id: this.dealerId,
      status: _cloneFilter ? _cloneFilter : _filterStatus.status,
      notJoin: _notJoin,
      search: `%${text}%`,
      pagination: this.pagination,
    });
  }
}
