import { gqlClient } from '../Core';
import { CRUDGqlService, ICRUDGqlService } from '../Core/crud';
import { raCampaign, raCampaignQuery } from '../Query/raCampaign';

export interface IRACampaignGqlService extends ICRUDGqlService<any> {
  getList(search: string, role: any, branch: any, order: any, limit: number, offset: number): Promise<any>;
  getListManageExport(limit: number, offset: number): Promise<any>;
  getListShop(id: string, status: any, notJoin: boolean, search: string, limit: number, offset: number): Promise<any>;
  approveShop(ids: string[], action: string, reason: string, updatedBy: string): Promise<any>;
  requestReup(ids: string[], action: string, reason: string, updatedBy: string): Promise<any>;
  getShop(id: string): Promise<any>;
  getPdfExportData(id: string): Promise<any>;
  submitReview(id: string, review: string, qualify: string, force: boolean, createdBy: string): Promise<any>;
  getPackage(): Promise<any>;
  updatePackage(id: string, models: any): Promise<any>;
  getConfig(): Promise<any>;
  getMaskConfig(id: string): Promise<any>;
  endCampaign(id: string): Promise<any>;
  maskChange(id: string, mask: boolean): Promise<any>;
  getStatistic(): Promise<any>;
  exportStatistic(id: string): Promise<any>;
  getQualifiedShopsList(limit: number, offset: number): Promise<any>;
  approveAll(id: string): Promise<any>;
  getDealerByBranch(name: string): Promise<any>;
  getJoinedShopList(type: string, search: string, notJoin: boolean, status: any, branch: any, saleFilter: any,
                    notReview: boolean, branchFilter: any, limit: number, offset: number): Promise<any>;
  getConfigCropPicture(): Promise<any>;
  getListRequest(search: string, region: any, branch: any, limit: number, offset: number): Promise<any>;
  updateSubDealerRequest(id: string): Promise<any>;
  getConfirmCode(type: string, search: string, status: any, limit: number, offset: number): Promise<any>;
  downloadConfirmationCode(): Promise<any>;
  generateCode(codeType: string, quantity: number): Promise<any>;
  getSubDealerCampaignList(type: string, search: string, notJoin: boolean, status: any, branch: any, saleFilter: any,
                           notReview: boolean, branchFilter: any, limit: number, offset: number): Promise<any>;
  getSubDealerCampaginDetail(id: string): Promise<any>;
  pushReminder(form: any): Promise<any>;
  logReminder(campaignId: string, createdBy: string): Promise<any>;
  getBranchId(id: string): Promise<any>;
}
export function raCampaignGqlService(): IRACampaignGqlService {
  async function getList(search: string, role: any, branch: any, order: any,
                         limit: number, offset: number): Promise<any> {
    const results = await gqlClient.query({
      query: raCampaignQuery.GET_LIST_RA_CAMPAGIN,
      variables: {
        search, role, branch, order, limit, offset
      },
      fetchPolicy: 'network-only'
    });
    if (results.errors) {
      throw results.errors;
    }

    return results.data;
  }
  async function getListManageExport(limit: number, offset: number): Promise<any> {
    const results = await gqlClient.query({
      query: raCampaignQuery.GET_LIST_MANAGE_EXPORT,
      variables: {
        limit, offset,
        // tslint:disable-next-line: object-literal-key-quotes
        type: {'type' : 'RA_CAMPAIGN'}
      },
      fetchPolicy: 'network-only'
    });
    if (results.errors) {
      throw results.errors;
    }

    return results.data;
  }
  async function getPdfExportData(id: string): Promise<any> {
    const results = await gqlClient.query({
      query: raCampaignQuery.GET_PDF_EXPORT_DATA,
      variables: {
        id
      },
      fetchPolicy: 'network-only'
    });
    if (results.errors) {
      throw results.errors;
    }

    return results.data;
  }
  async function getListShop(id: string, status: any, notJoin: boolean,
                             search: string, limit: number, offset: number): Promise<any> {
    if (notJoin) {
      const result = await gqlClient.query({
        query: raCampaignQuery.GET_LIST_SHOP_CAMPAGIN,
        variables: {
          id, status, search, limit, offset
        },
        fetchPolicy: 'network-only'
      });
      if (result.errors) {
        throw result.errors;
      }

      return result.data;
    }
    const results = await gqlClient.query({
      query: raCampaignQuery.GET_LIST_SHOP_CAMPAGIN_NOTJOIN,
      variables: {
        id, status, search, limit, offset
      },
      fetchPolicy: 'network-only'
    });
    if (results.errors) {
      throw results.errors;
    }

    return results.data;
  }
  async function approveShop(ids: string[], action: string, reason: string, updatedBy: string): Promise<any> {
    const results = await gqlClient.mutate({
      mutation: raCampaignQuery.APRROVE_SHOP,
      variables: {
        ids,
        action,
        reason,
        updatedBy
      }
    });
    if (results.errors) {
      throw results.errors;
    }

    return results.data;
  }
  async function requestReup(ids: string[], action: string, reason: string, updatedBy: string): Promise<any> {
    const results = await gqlClient.mutate({
      mutation: raCampaignQuery.REQUEST_REUP,
      variables: {
        ids,
        action,
        reason,
        updatedBy
      }
    });
    if (results.errors) {
      throw results.errors;
    }

    return results.data;
  }
  async function getShop(id: string): Promise<any> {
    const results = await gqlClient.query({
      query: raCampaignQuery.GET_SHOP,
      variables: {
        id
      },
      fetchPolicy: 'network-only'
    });
    if (results.errors) {
      throw results.errors;
    }

    return results.data;
  }
  async function submitReview(id: string, review: string, qualify: string,
                              force: boolean, createdBy: string): Promise<any> {
    const results = await gqlClient.mutate({
      mutation: raCampaignQuery.SUBMIT_REVIEW,
      variables: {
        id,
        review,
        qualify,
        force,
        createdBy
      }
    });
    if (results.errors) {
      throw results.errors;
    }

    return results.data;
  }
  async function getPackage(): Promise<any> {
    const results = await gqlClient.query({
      query: raCampaignQuery.GET_PACKAGE,
      fetchPolicy: 'network-only'
    });
    if (results.errors) {
      throw results.errors;
    }

    return results.data.campaigns;
  }
  async function updatePackage(id: string, models: any): Promise<any> {
    const results = await gqlClient.mutate({
      mutation: raCampaignQuery.UPDATE_PACKAGE,
      variables: {
        id,
        models,
      }
    });
    if (results.errors) {
      throw results.errors;
    }

    return results.data;
  }
  async function getConfig(): Promise<any> {
    const results = await gqlClient.query({
      query: raCampaignQuery.GET_CONFIG,
      fetchPolicy: 'network-only'
    });
    if (results.errors) {
      throw results.errors;
    }

    return results.data.campaigns;
  }
  async function getMaskConfig(id: string): Promise<any> {
    const results = await gqlClient.query({
      query: raCampaignQuery.GET_MASK_CONFIG,
      variables: {
        id
      },
      fetchPolicy: 'network-only'
    });
    if (results.errors) {
      throw results.errors;
    }

    return results.data.campaigns;
  }
  async function endCampaign(id: string): Promise<any> {
    const results = await gqlClient.mutate({
      mutation: raCampaignQuery.END_CAMPAIGN,
      variables: {
        id
      }
    });
    if (results.errors) {
      throw results.errors;
    }

    return results.data;
  }
  async function maskChange(id: string, mask: boolean): Promise<any> {
    const results = await gqlClient.mutate({
      mutation: raCampaignQuery.MASK_CHANGE,
      variables: {
        id,
        mask
      }
    });
    if (results.errors) {
      throw results.errors;
    }

    return results.data;
  }
  async function getStatistic(): Promise<any> {
    const results = await gqlClient.query({
      query: raCampaignQuery.GET_STATISTIC,
      fetchPolicy: 'network-only'
    });
    if (results.errors) {
      throw results.errors;
    }

    return results.data;
  }
  async function exportStatistic(id: string): Promise<any> {
    const results = await gqlClient.mutate({
      mutation: raCampaignQuery.EXPORT_STATISTIC,
      variables: {
        id,
        // tslint:disable-next-line: object-literal-key-quotes
        filter: {'type' : 'RA_CAMPAIGN'}
      }
    });
    if (results.errors) {
      throw results.errors;
    }

    return results.data;
  }
  async function getQualifiedShopsList(limit: number, offset: number): Promise<any> {
    const results = await gqlClient.query({
      query: raCampaignQuery.GET_QUALIFIED_SHOP,
      variables: {
        limit, offset
      },
      fetchPolicy: 'network-only'
    });
    if (results.errors) {
      throw results.errors;
    }

    return results.data;
  }
  async function approveAll(id: string): Promise<any> {
    const results = await gqlClient.mutate({
      mutation: raCampaignQuery.APPROVE_ALL,
      variables: {
        id
      }
    });
    if (results.errors) {
      throw results.errors;
    }

    return results.data;
  }
  async function getDealerByBranch(name: string): Promise<any> {
    const results = await gqlClient.query({
      query: raCampaignQuery.GET_DEALER_BY_BRANCH,
      variables: {
        name
      }
    });
    if (results.errors) {
      throw results.errors;
    }

    return results.data;
  }
  async function getJoinedShopList(type: string, search: string, notJoin: boolean, status: any, branch: any,
                                   saleFilter: any, notReview: boolean, branchFilter: any,
                                   limit: number, offset: number): Promise<any> {
    let _status = [];
    if (status && status.length) {
      _status.push(...status);
    }
    if (saleFilter && saleFilter.length) {
      _status.push(...saleFilter);
    }
    if (_status.length <= 0) {
      _status = null;
    }
    if (notJoin && status && status.length === 0 && !saleFilter) {
      _status = [];
    }
    if (notReview && notJoin && type) {
      const result = await gqlClient.query({
        query: raCampaignQuery.GET_LIST_SHOPS_NOT_JOIN_NOT_REIVEW,
        variables: {
          // tslint:disable-next-line: object-shorthand-properties-first
          type, search, branch, saleFilter: _status, branchFilter , limit, offset
        },
        fetchPolicy: 'network-only'
      });
      if (result.errors) {
        throw result.errors;
      }

      return result.data;
    }
    if (notReview && notJoin && !type) {
      const result = await gqlClient.query({
        query: raCampaignQuery.GET_LIST_SHOPS_NOT_JOIN_NOT_REIVEW_NOT_TYPE,
        variables: {
          // tslint:disable-next-line: object-shorthand-properties-first
          search, branch, saleFilter: _status, branchFilter , limit, offset
        },
        fetchPolicy: 'network-only'
      });
      if (result.errors) {
        throw result.errors;
      }

      return result.data;
    }
    if (!notReview && notJoin && type) {
      const result = await gqlClient.query({
        query: raCampaignQuery.GET_LIST_SHOPS_NOT_JOIN,
        variables: {
          // tslint:disable-next-line: object-shorthand-properties-first
          type, search, branch, saleFilter: _status, branchFilter , limit, offset
        },
        fetchPolicy: 'network-only'
      });
      if (result.errors) {
        throw result.errors;
      }

      return result.data;
    }
    if (!notReview && notJoin && !type) {
      const result = await gqlClient.query({
        query: raCampaignQuery.GET_LIST_SHOPS_NOT_JOIN_NOT_TYPE,
        variables: {
          // tslint:disable-next-line: object-shorthand-properties-first
          search, branch, saleFilter: _status, branchFilter , limit, offset
        },
        fetchPolicy: 'network-only'
      });
      if (result.errors) {
        throw result.errors;
      }

      return result.data;
    }
    if (notReview && !notJoin && type) {
      const result = await gqlClient.query({
        query: raCampaignQuery.GET_LIST_SHOPS_NOT_REVIEW,
        variables: {
          // tslint:disable-next-line: object-shorthand-properties-first
          type, search, branch, saleFilter: _status, branchFilter , limit, offset
        },
        fetchPolicy: 'network-only'
      });
      if (result.errors) {
        throw result.errors;
      }

      return result.data;
    }
    if (notReview && !notJoin && !type) {
      const result = await gqlClient.query({
        query: raCampaignQuery.GET_LIST_SHOPS_NOT_REVIEW_NOT_TYPE,
        variables: {
          // tslint:disable-next-line: object-shorthand-properties-first
          search, branch, saleFilter: _status, branchFilter , limit, offset
        },
        fetchPolicy: 'network-only'
      });
      if (result.errors) {
        throw result.errors;
      }

      return result.data;
    }
    if (notReview && !notJoin && !type) {
      const result = await gqlClient.query({
        query: raCampaignQuery.GET_LIST_SHOPS_NOT_TYPE,
        variables: {
          // tslint:disable-next-line: object-shorthand-properties-first
          search, branch, saleFilter: _status, branchFilter , limit, offset
        },
        fetchPolicy: 'network-only'
      });
      if (result.errors) {
        throw result.errors;
      }

      return result.data;
    }
    const results = await gqlClient.query({
      query: raCampaignQuery.GET_LIST_SHOPS,
      variables: {
        // tslint:disable-next-line: object-shorthand-properties-first
        type, search, branch, saleFilter: _status, branchFilter, limit, offset
      },
      fetchPolicy: 'network-only'
    });
    if (results.errors) {
      throw results.errors;
    }

    return results.data;
  }
  async function getConfigCropPicture(): Promise<any> {
    const results = await gqlClient.query({
      query: raCampaignQuery.GET_CONFIG_CROP_PICTURE
    });
    if (results.errors) {
      throw results.errors;
    }

    return results.data;
  }
  async function getListRequest(search: string, region: any, branch: any, limit: number, offset: number): Promise<any> {
    if (region && branch) {
      const resultsAll = await gqlClient.query({
        query: raCampaignQuery.GET_LIST_SUB_DEALER_REQUEST,
        variables: {
          search, region, branch, limit, offset
        },
        fetchPolicy: 'network-only'
      });
      if (resultsAll.errors) {
        throw resultsAll.errors;
      }

      return resultsAll.data;
    }
    if (!region && branch) {
      const resultsBranch = await gqlClient.query({
        query: raCampaignQuery.GET_LIST_SUB_DEALER_REQUEST_BRANCH,
        variables: {
          search, branch, limit, offset
        },
        fetchPolicy: 'network-only'
      });
      if (resultsBranch.errors) {
        throw resultsBranch.errors;
      }

      return resultsBranch.data;
    }
    if (region && !branch) {
      const resultsRegion = await gqlClient.query({
        query: raCampaignQuery.GET_LIST_SUB_DEALER_REQUEST_REGION,
        variables: {
          search, region, limit, offset
        },
        fetchPolicy: 'network-only'
      });
      if (resultsRegion.errors) {
        throw resultsRegion.errors;
      }

      return resultsRegion.data;
    }
    const results = await gqlClient.query({
      query: raCampaignQuery.GET_LIST_SUB_DEALER_REQUEST_ALL,
      variables: {
        search, limit, offset
      },
      fetchPolicy: 'network-only'
    });
    if (results.errors) {
      throw results.errors;
    }

    return results.data;
  }
  async function updateSubDealerRequest(id: string): Promise<any> {
    const results = await gqlClient.mutate({
      mutation: raCampaignQuery.UPDATE_SUBDEALER_REQUEST,
      variables: {
        id
      }
    });
    if (results.errors) {
      throw results.errors;
    }

    return results.data;
  }
  async function getConfirmCode(type: string, search: string,
                                status: any, limit: number, offset: number): Promise<any> {
    const results = await gqlClient.query({
      query: raCampaignQuery.GET_LIST_CONFIRMATION_CODE,
      variables: {
        type, search, status, limit, offset
      },
      fetchPolicy: 'network-only'
    });
    if (results.errors) {
      throw results.errors;
    }

    return results.data;
  }
  async function downloadConfirmationCode(): Promise<any> {
    const results = await gqlClient.mutate({
      mutation: raCampaignQuery.DOWNLOAD_CONFIRMATION_CODE,
    });
    if (results.errors) {
      throw results.errors;
    }

    return results.data.exportConfirmationCodeUrl;
  }
  async function generateCode(codeType: string, quantity: number): Promise<any> {
    const results = await gqlClient.mutate({
      mutation: raCampaignQuery.GENERATE_CODE,
      variables: {
        codeType,
        quantity
      }
    });
    if (results.errors) {
      throw results.errors;
    }

    return results.data;
  }
  async function getSubDealerCampaignList(type: string, search: string, notJoin: boolean, status: any, branch: any,
                                          // tslint:disable-next-line: max-line-length
                                          saleFilter: any, notReview: boolean, branchFilter: any, limit: number, offset: number): Promise<any> {
    let _status = [];
    if (status && status.length) {
      _status.push(...status);
    }
    if (saleFilter && saleFilter.length) {
      _status.push(...saleFilter);
    }
    if (_status.length <= 0) {
      _status = null;
    }
    if (notJoin && status && status.length === 0 && !saleFilter) {
      _status = [];
    }
    if (notReview && notJoin && !type) {
      const result = await gqlClient.query({
        query: raCampaignQuery.GET_LIST_SUB_NOT_JOIN_NOT_REIVEW,
        variables: {
        // tslint:disable-next-line: object-shorthand-properties-first
          type, search, branch, saleFilter: _status, branchFilter , limit, offset
        },
        fetchPolicy: 'network-only'
      });
      if (result.errors) {
        throw result.errors;
      }

      return result.data;
    }
    if (notReview && notJoin && type) {
      const result = await gqlClient.query({
        query: raCampaignQuery.GET_LIST_SUB_NOT_JOIN_NOT_REIVEW_CODE_TYPE,
        variables: {
        // tslint:disable-next-line: object-shorthand-properties-first
          type, search, branch, saleFilter: _status, branchFilter , limit, offset
        },
        fetchPolicy: 'network-only'
      });
      if (result.errors) {
        throw result.errors;
      }

      return result.data;
    }
    if (!notReview && notJoin) {
      const result = await gqlClient.query({
        query: raCampaignQuery.GET_LIST_SUB_NOT_JOIN,
        variables: {
        // tslint:disable-next-line: object-shorthand-properties-first
          type, search, branch, saleFilter: _status, branchFilter , limit, offset
        },
        fetchPolicy: 'network-only'
      });
      if (result.errors) {
        throw result.errors;
      }

      return result.data;
    }
    if (notReview && !notJoin) {
      const result = await gqlClient.query({
        query: raCampaignQuery.GET_LIST_SUB_NOT_REVIEW,
        variables: {
        // tslint:disable-next-line: object-shorthand-properties-first
          type, search, branch, saleFilter: _status, branchFilter , limit, offset
        },
        fetchPolicy: 'network-only'
      });
      if (result.errors) {
        throw result.errors;
      }

      return result.data;
    }
    const results = await gqlClient.query({
      query: raCampaignQuery.GET_LIST_SUB,
      variables: {
        // tslint:disable-next-line: object-shorthand-properties-first
        type, search, branch, saleFilter: _status, branchFilter, limit, offset
      },
      fetchPolicy: 'network-only'
    });
    if (results.errors) {
      throw results.errors;
    }

    return results.data;
  }
  async function getSubDealerCampaginDetail(id: string): Promise<any> {
    const results = await gqlClient.query({
      query: raCampaignQuery.GET_SUB_DEALER_CAMPAIGN_DETAIL,
      variables: {
        id
      },
      fetchPolicy: 'network-only'
    });
    if (results.errors) {
      throw results.errors;
    }

    return results.data;
  }
  async function pushReminder(form: string): Promise<any> {
    const results = await gqlClient.mutate({
      mutation: raCampaignQuery.PUSH_REMINDER,
      variables: {
        form
      }
    });
    if (results.errors) {
      throw results.errors;
    }

    return results.data;
  }
  async function logReminder(campaignId: string, createdBy: string): Promise<any> {
    const results = await gqlClient.mutate({
      mutation: raCampaignQuery.LOG_REMINDER,
      variables: {
        campaignId, createdBy
      }
    });
    if (results.errors) {
      throw results.errors;
    }

    return results.data;
  }
  async function getBranchId(id: string): Promise<any> {
    const results = await gqlClient.query({
      query: raCampaignQuery.GET_BRANCH_ID,
      variables: {
        id
      },
      fetchPolicy: 'network-only'
    });
    if (results.errors) {
      throw results.errors;
    }

    return results.data.group_manager;
  }

  return {
    ...CRUDGqlService<any>(raCampaign, raCampaignQuery),
    getList,
    getListShop,
    approveShop,
    requestReup,
    getShop,
    getPdfExportData,
    submitReview,
    getPackage,
    updatePackage,
    getConfig,
    getMaskConfig,
    endCampaign,
    maskChange,
    getListManageExport,
    getStatistic,
    exportStatistic,
    getQualifiedShopsList,
    approveAll,
    getDealerByBranch,
    getJoinedShopList,
    getConfigCropPicture,
    getListRequest,
    updateSubDealerRequest,
    getConfirmCode,
    downloadConfirmationCode,
    generateCode,
    getSubDealerCampaignList,
    getSubDealerCampaginDetail,
    pushReminder,
    logReminder,
    getBranchId
  };
}
