import { DocumentNode } from 'graphql';
import gql from 'graphql-tag';
import { crudQuery } from 'root/store/helpers/query';
import { ICRUDQuery } from '../Core/query';

const PREFIX_CONFIG = 'system_configurations';

const schema = `
	id name value type variableName
`;
export interface IConfigurationQuery extends ICRUDQuery {
  SUBSCRIBE_ADMIN_VERSION: DocumentNode;
  GET_CONFIG_NAVIGATOR: DocumentNode;
}
export const ConfigurationQuery: IConfigurationQuery = {
  ...crudQuery(PREFIX_CONFIG, schema),
  SUBSCRIBE_ADMIN_VERSION: gql`
  subscription SUBSCRIBE_ADMIN_VERSION {
    system_configurations (where: {
      variableName: {
        _eq: "PORTAL_VERSION"
      }
    }) {
      id name value type variableName
    }
  }
`,
  GET_CONFIG_NAVIGATOR: gql`
  query GET_CONFIG_NAVIGATOR{
    configuration_navigators(where: {isCheck: {_eq: true}}){
      moduleName
      type
      roles
      emails
      isCheck
    }
  }`
};
