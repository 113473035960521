import { stripObject } from '@hgiasac/helper';
import { Form } from 'element-ui';
import { cloneDeep } from 'lodash';
import { Avatar } from 'root/components/Avatar';
import { formatBirthday, FormatDateFullTime, FormatDateTime } from 'root/helpers';
import { dealerUpgradeForm, IDealerUpgradeForm } from 'root/models/Dealer';
import { IState, MutationType, TypeAlert } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import { ActionTypeDealer, EUpgradeAction } from '../../Store/types';
import './styles.scss';
const actionType: any[] = [
  {
    label: 'Approved',
    value: EUpgradeAction.Approved
  },
  {
    label: 'Rejected',
    value: EUpgradeAction.Rejected
  }
];
@Component({
  template: require('./view.html'),
  props: {
    visible: Boolean,
    detailId: String
  },
  components: {
    avatar: Avatar
  },
  computed: {
    ...mapState({
      upgradeRequest: (state: IState) => state.dealer.upgradeRequest.data,
    }),
    cloneActionOptions() {
      return actionType;
    },
  }
})
export class UpgradeRequestModal extends Vue {

  public $refs: {
    form: Form
  };
  public form: IDealerUpgradeForm = dealerUpgradeForm();
  public FormatDateFullTime = FormatDateFullTime;
  public formatBirthday = formatBirthday;
  public FormatDateTime = FormatDateTime;
  public detail= null;
  public detailId: string;
  public activeTab: string = 'New';
  public upgradeRequest: any[];
  public loading: boolean;
  public changeTab(tab: string) {
    this.activeTab = tab;
  }

  public async openDialog() {
    // this.$store.dispatch(ActionTypeUpgradeRequest.FilterNoCache);
    this.$store.dispatch(ActionTypeDealer.GetUpgradeRequest, {id: this.detailId});
    this.detail = this.$store.getters.getDealerDetail(this.detailId);
    if (this.detail) {
      this.form = this.detail.upgradingRequest;
    }
  }
  public tranformStatus(value: any) {
    if (value) {
      if (value && value.action) {
        return value.action;
      }
      if (value.upgradeStatus === EUpgradeAction.NewRequest) {
        return 'new request';
      }

      return value.upgradeStatus;

    }

    return '';
  }
  public back() {
    this.$emit('update:visible', false);
  }
  public closeDialog() {
    this.activeTab = 'New';
    this.form = dealerUpgradeForm();
    this.back();
  }
  public async submit() {
    this.$refs.form.validate(async (valid) => {
      if (valid) {
        const form = <any> cloneDeep(this.form);

        this.createUpgradeRequest(form);
      }

      return;
    });
  }
  private async createUpgradeRequest(form: any) {
    const postForm = {
      action: form.action,
      timeToUpdate: form.timeToUpdate,
      requestId: this.detail.upgradingRequest ? this.detail.upgradingRequest.id : null,
      reason: form.reason,
    };

    this.$store.dispatch(ActionTypeDealer.CreateUpgradeRequest, {
      form: {
        ...stripObject(postForm)
      },
      onSuccess: () => {
        this.loading = false;
        const message = 'Upgrade request successfully';
        this.$store.commit(MutationType.OpenTopAlert, {
          message,
          type: TypeAlert.Success
        });
        this.$store.dispatch(ActionTypeDealer.GetListDealer, {
          form: {
            filter: {},
            pagination: {
              size: 20,
              page: 1
            }
          }
        });
        this.closeDialog();
      },
      onFailure: () => {
        this.loading = false;
      }
    });
  }
}
