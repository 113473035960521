import { DocumentNode } from 'graphql';
import gql from 'graphql-tag';
import { crudQuery, ICRUDQuery } from '../Core/query';

const userModel = `
avatar
dob
email
gender
name
id
branch {
  id
  name
}
role {
  name
  alias
}
group {
  id
  name
}
createdAt
createdBy
updatedAt
updatedBy
status
superAdmin
phoneNumber
systemGroup {
  name
}
systemGroupId
createdUser {
  email
  name
}
updatedUser {
  email
  name
}
`;

const userModelExtends = `
groupId
roleId
`;

export const SystemUsers = 'system_users';
// tslint:disable-next-line:no-empty-interface
interface IUserQuery extends ICRUDQuery {
  GET_USER_PROFILE: DocumentNode;
  CREATE_SYSTEM_USER: DocumentNode;
  CHANGE_PASS_SYSTEM_USER: DocumentNode;
  UPDATE_STATUS_SYSTEM_USER: DocumentNode;
  GET_FIREBASE_AUTHENTICATE_TOKEN: DocumentNode;
  GET_TEAM_STAFF: DocumentNode;
  GET_TEAM_STAFF_DEALER: DocumentNode;
  UPDATE_USER_MANAGER: DocumentNode;
  GET_INFO_BY_PHONE: DocumentNode;
}
export const userProfileQuery: IUserQuery = {
  ...crudQuery(SystemUsers, `${userModel} ${userModelExtends}`),
  GET_USER_PROFILE: gql`
  query GET_USER_PROFILE {
    getSystemUserProfile {
      ${userModel}
    }
  }
`,
  CREATE_SYSTEM_USER: gql`
  mutation CREATE_SYSTEM_USER($form: FormCreateSystemUser!) {
    createSystemUser(form: $form){
      id
      email
      name
    }
  }
`,
  CHANGE_PASS_SYSTEM_USER: gql `
mutation CHANGE_PASS_SYSTEM_USER($form: FormUpdateSystemUserPassword!){
  updateSystemUserPassword(form: $form){
    id
    name
  }
}
`,
  UPDATE_STATUS_SYSTEM_USER: gql`
  mutation UPDATE_STATUS_SYSTEM_USER($id: uuid!, $status: String!){
    update_system_users(where: {id: {_eq: $id}}, _set:{status: $status}){
      affected_rows
    }
  }
  `,
  GET_FIREBASE_AUTHENTICATE_TOKEN: gql`
    query GET_FIREBASE_AUTHENTICATE_TOKEN {
      getFirebaseAuthenticateToken {
        authenticate
      }
    }
  `,
  GET_TEAM_STAFF: gql`
    query GET_TEAM_STAFF($id: uuid!) {
      leader_staffs(where: {leaderId: {_eq: $id}}) {
        createdAt
        createdBy
        id
        leaderId
        staffId
        status
        updatedAt
        updatedBy
      }
    }
  `,
  GET_TEAM_STAFF_DEALER: gql`
    query GET_TEAM_STAFF_DEALER($id: uuid!) {
      dealer_leaders(where: {leaderId: {_eq: $id}}) {
        createdAt
        createdBy
        dealerId
        id
        leaderId
        status
        updatedAt
        updatedBy
      }
    }
  `,
  UPDATE_USER_MANAGER: gql`
  mutation UPDATE_USER_MANAGER($form: FormUpdateSystemUserManagement){
    updateSystemUserManagement(form: $form){
      id
    }
  }
  `,
  GET_INFO_BY_PHONE: gql`
  query GET_INFO_BY_PHONE($phone: String!){
    system_users(where: { phoneNumber : {_eq: $phone}}){
      email
      name
      address
    }
  }
  `
};
