import { PermissionType } from 'root/models';
import { RouteConfig } from 'vue-router';
import { BannerContainer } from './BannerContainer';
import { BannerList } from './BannerList';

export enum BannerRouterPath {
  Default = '/banner-management'
}

export const BannerRouter: RouteConfig = {
  path: '',
  component: BannerContainer,
  children: [
    {
      path: BannerRouterPath.Default,
      component: BannerList,
      meta: {
        permission: PermissionType.Configuration
      }
    }
  ]
};
