import { DKGqlClient } from 'root/api/graphql/Client';
import { ActionType, IState } from 'root/store';
import { crudActions } from 'root/store/helpers';
import { ActionContext } from 'vuex';
import { INewRACampaignState } from './mutation';
import { ActionTypeCampaignRequestList, ActionTypeClassifiedRequest,
  ActionTypeNewRaCampaign, MutationTypeNewRaCampaign } from './types';

const { newRaCampagin } = DKGqlClient();
export const actions = {
  ...crudActions('new_ra_campaign', {
    create: newRaCampagin.create,
    filter: newRaCampagin.filter,
    getById: newRaCampagin.getById,
    deleteById: newRaCampagin.deleteById,
    update: newRaCampagin.update,
    fetchMany: newRaCampagin.fetchMany,
  }),
  ...crudActions('acl_requests', {
    create: newRaCampagin.acl_requests.create,
    filter: newRaCampagin.acl_requests.filter,
    getById: newRaCampagin.acl_requests.getById,
    deleteById: newRaCampagin.acl_requests.deleteById,
    update: newRaCampagin.acl_requests.update,
    fetchMany: newRaCampagin.acl_requests.fetchMany,
  }, {keyState: 'acl_requests'}),
  ...crudActions('acl_request_reviews', {
    create: newRaCampagin.acl_request_reviews.create,
    filter: newRaCampagin.acl_request_reviews.filter,
    getById: newRaCampagin.acl_request_reviews.getById,
    deleteById: newRaCampagin.acl_request_reviews.deleteById,
    update: newRaCampagin.acl_request_reviews.update,
    fetchMany: newRaCampagin.acl_request_reviews.fetchMany,
  }, {keyState: 'acl_request_reviews'}),
  async [ActionTypeNewRaCampaign.GetListSubDealerAssign]({
    dispatch}: ActionContext<INewRACampaignState, IState>,
                                                         {onSuccess}) {
    try {
      const data = await newRaCampagin.getAllSubdealer();
      if (onSuccess) {
        onSuccess(data);
      }
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    }
  },
  async [ActionTypeNewRaCampaign.AddParticipant]({
    dispatch}: ActionContext<INewRACampaignState, IState>,
                                                 {createdBy, form, onSuccess}) {
    try {
      const data = await newRaCampagin.addParticipant(createdBy, form);
      if (onSuccess) {
        onSuccess(data);
      }
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    }
  },
  async [ActionTypeNewRaCampaign.ExportListRegistration]({
    dispatch}: ActionContext<INewRACampaignState, IState>,
                                                         {form, onSuccess}) {
    try {
      const data = await newRaCampagin.exportListRegistration(form);
      if (onSuccess) {
        onSuccess(data);
      }
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    }
  },
  async [ActionTypeNewRaCampaign.GetNewRaCampaign]({
    commit, dispatch
  }: ActionContext<INewRACampaignState, IState>,
                                                   {search, role, branch, order, pagination}) {
    try {
      commit(MutationTypeNewRaCampaign.NewRaCampaignLoading);
      const { page, limit } = pagination;
      const offset = (page - 1) * limit;
      const listAclCampaign = await newRaCampagin.getList(search, role, branch, order, limit, offset);
      if (listAclCampaign) {
        commit(MutationTypeNewRaCampaign.SetNewRaCampaign, listAclCampaign);
      }
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    } finally {
      commit(MutationTypeNewRaCampaign.NewRaCampaignLoaded);
    }
  },
  async [ActionTypeNewRaCampaign.GetNewRaCampaignDetail]({
    commit, dispatch
  }: ActionContext<INewRACampaignState, IState>,
                                                         {classification,
                                                           id, status, notJoin, search, pagination, dealerApproval}) {
    try {
      commit(MutationTypeNewRaCampaign.NewRaCampaignLoading);
      const { page, limit } = pagination;
      const offset = (page - 1) * limit;
      const campaignDetail = await newRaCampagin.getListShop(classification,
        id, status, notJoin, search, limit, offset, dealerApproval);
      if (campaignDetail) {
        commit(MutationTypeNewRaCampaign.SetCampaignDetail, campaignDetail);
      }
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    } finally {
      commit(MutationTypeNewRaCampaign.NewRaCampaignLoaded);
    }
  },
  async [ActionTypeNewRaCampaign.GetCampaignConfiguration]({
    commit, dispatch
  }: ActionContext<INewRACampaignState, IState>,
                                                           {onSuccess, onFailure}) {
    try {
      commit(MutationTypeNewRaCampaign.NewRaCampaignLoading);
      const campaignConfig = await newRaCampagin.getConfig();
      if (onSuccess) {
        onSuccess(campaignConfig);
      }
    } catch (error) {
      if (onFailure) {
        onFailure();
      }
      dispatch(ActionType.CatchException, error);
    } finally {
      commit(MutationTypeNewRaCampaign.NewRaCampaignLoaded);
    }
  },
  async [ActionTypeNewRaCampaign.GetJoinedShop]({
    commit, dispatch
  }: ActionContext<INewRACampaignState, IState>,
                                                {classification, payment, saleManager, search, notJoin, status, branch,
                                                  saleFilter, notReview, branchFilter, pagination, dealerApproval}) {
    try {
      commit(MutationTypeNewRaCampaign.NewRaCampaignLoading);
      const { page, limit } = pagination;
      const offset = (page - 1) * limit;
      const listJoinedShop = await newRaCampagin.getJoinedShopList(
        classification, payment, saleManager, search, notJoin, status, branch,
        saleFilter, notReview, branchFilter, limit, offset, dealerApproval);
      commit(MutationTypeNewRaCampaign.SetJoinedShopList, listJoinedShop);
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    } finally {
      commit(MutationTypeNewRaCampaign.NewRaCampaignLoaded);
    }
  },
  async [ActionTypeNewRaCampaign.GetSubDealerCampaign]({
    commit, dispatch
  }: ActionContext<INewRACampaignState, IState>,
                                                       { classification, payment, saleManager, search, notJoin, status,
                                                        branch, saleFilter, notReview, branchFilter,
                                                        pagination, dealerApproval}) {
    try {
      commit(MutationTypeNewRaCampaign.NewRaCampaignLoading);
      const { page, limit } = pagination;
      const offset = (page - 1) * limit;
      const listSubDealer = await newRaCampagin.getSubDealerCampaignList(
        classification, payment, saleManager, search, notJoin, status, branch,
        saleFilter, notReview, branchFilter, limit, offset, dealerApproval);
      commit(MutationTypeNewRaCampaign.SetSubDealerCampaign, listSubDealer);
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    } finally {
      commit(MutationTypeNewRaCampaign.NewRaCampaignLoaded);
    }
  },
  async [ActionTypeNewRaCampaign.GetSubDealerCampaignDetail]({
    commit, dispatch
  }: ActionContext<INewRACampaignState, IState>,
                                                             {id, onSuccess, onFailure}) {
    try {
      commit(MutationTypeNewRaCampaign.NewRaCampaignLoading);
      const ShopDetail = await newRaCampagin.getSubDealerCampaignDetail(id);
      if (onSuccess) {
        onSuccess(ShopDetail);
      }
    } catch (error) {
      if (onFailure) {
        onFailure();
      }
      dispatch(ActionType.CatchException, error);
    } finally {
      commit(MutationTypeNewRaCampaign.NewRaCampaignLoaded);
    }
  },
  async [ActionTypeNewRaCampaign.SubmitReview]({
    commit, dispatch
  }: ActionContext<INewRACampaignState, IState>,
                                               {id, review, qualify, force, createdBy, onSuccess, onFailure}) {
    try {
      commit(MutationTypeNewRaCampaign.NewRaCampaignLoading);
      await newRaCampagin.submitReview(id, review, qualify, force, createdBy);
      if (onSuccess) {
        onSuccess();
      }
    } catch (error) {
      if (onFailure) {
        onFailure();
      }
      dispatch(ActionType.CatchException, error);
    } finally {
      commit(MutationTypeNewRaCampaign.NewRaCampaignLoaded);
    }
  },
  async [ActionTypeNewRaCampaign.RequestReup]({
    commit, dispatch
  }: ActionContext<INewRACampaignState, IState>,
                                              {ids, action, updatedBy, reason, onSuccess, onFailure}) {
    try {
      commit(MutationTypeNewRaCampaign.NewRaCampaignLoading);
      await newRaCampagin.requestReup(ids, action, reason, updatedBy);
      if (onSuccess) {
        onSuccess();
      }
    } catch (error) {
      if (onFailure) {
        onFailure();
      }
      dispatch(ActionType.CatchException, error);
    } finally {
      commit(MutationTypeNewRaCampaign.NewRaCampaignLoaded);
    }
  },
  async [ActionTypeNewRaCampaign.GetShopDetail]({
    commit, dispatch
  }: ActionContext<INewRACampaignState, IState>,
                                                {id, onSuccess, onFailure}) {
    try {
      commit(MutationTypeNewRaCampaign.NewRaCampaignLoading);
      const ShopDetail = await newRaCampagin.getShop(id);
      if (onSuccess) {
        onSuccess(ShopDetail);
      }
    } catch (error) {
      if (onFailure) {
        onFailure();
      }
      dispatch(ActionType.CatchException, error);
    } finally {
      commit(MutationTypeNewRaCampaign.NewRaCampaignLoaded);
    }
  },
  async [ActionTypeNewRaCampaign.UpdatePackage]({
    commit, dispatch
  }: ActionContext<INewRACampaignState, IState>,
                                                {id, models, onSuccess, onFailure}) {
    try {
      commit(MutationTypeNewRaCampaign.NewRaCampaignLoading);
      const aclPackage = await newRaCampagin.updatePackage(id, models);
      if (onSuccess) {
        onSuccess(aclPackage);
      }
    } catch (error) {
      if (onFailure) {
        onFailure();
      }
      dispatch(ActionType.CatchException, error);
    } finally {
      commit(MutationTypeNewRaCampaign.NewRaCampaignLoaded);
    }
  },
  async [ActionTypeNewRaCampaign.GetQualifiedRequest]({
    commit, dispatch
  }: ActionContext<INewRACampaignState, IState>,
                                                      // tslint:disable-next-line:max-line-length
                                                      {sales, saleManager, pagination, salesStaff, salesPic, salesPlanning, remove, search, type}) {
    try {
      commit(MutationTypeNewRaCampaign.Loading);
      const { page, limit } = pagination;
      const offset = (page - 1) * limit;
      const listQualifiedShops = await newRaCampagin.getQualifiedShopsList(sales, saleManager, limit,
        offset, salesStaff, salesPic, salesPlanning, remove, search, type);
      if (listQualifiedShops) {
        commit(MutationTypeNewRaCampaign.SetQualifiedRequest, listQualifiedShops);
      }
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    } finally {
      commit(MutationTypeNewRaCampaign.Loaded);
    }
  },
  async [ActionTypeCampaignRequestList.GetHistoryLog]({
    dispatch}: ActionContext<INewRACampaignState, IState>,
                                                      {roles, requestId, onSuccess}) {
    try {
      const data = await newRaCampagin.getHistoryLog(roles , requestId);
      if (onSuccess) {
        onSuccess(data);
      }
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    }
  },
  async [ActionTypeNewRaCampaign.EndCampaign]({
    commit, dispatch
  }: ActionContext<INewRACampaignState, IState>,
                                              {id, onSuccess, onFailure}) {
    try {
      commit(MutationTypeNewRaCampaign.Loading);
      await newRaCampagin.endCampaign(id);
      if (onSuccess) {
        onSuccess();
      }
    } catch (error) {
      if (onFailure) {
        onFailure();
      }
      dispatch(ActionType.CatchException, error);
    } finally {
      commit(MutationTypeNewRaCampaign.Loaded);
    }
  },
  async [ActionTypeNewRaCampaign.GetMaskConfig]({
    commit, dispatch
  }: ActionContext<INewRACampaignState, IState>,
                                                {id, onSuccess, onFailure}) {
    try {
      commit(MutationTypeNewRaCampaign.Loading);
      const maskConfig = await newRaCampagin.getMaskConfig(id);
      if (onSuccess) {
        onSuccess(maskConfig);
      }
    } catch (error) {
      if (onFailure) {
        onFailure();
      }
      dispatch(ActionType.CatchException, error);
    } finally {
      commit(MutationTypeNewRaCampaign.Loaded);
    }
  },
  async [ActionTypeNewRaCampaign.MaskChange]({
    commit, dispatch
  }: ActionContext<INewRACampaignState, IState>,
                                             {id, mask, onSuccess, onFailure}) {
    try {
      commit(MutationTypeNewRaCampaign.Loading);
      await newRaCampagin.maskChange(id, mask);
      if (onSuccess) {
        onSuccess();
      }
    } catch (error) {
      if (onFailure) {
        onFailure();
      }
      dispatch(ActionType.CatchException, error);
    } finally {
      commit(MutationTypeNewRaCampaign.Loaded);
    }
  },
  async [ActionTypeNewRaCampaign.GetDealerByBranch]({
    commit, dispatch
  }: ActionContext<INewRACampaignState, IState>,
                                                    {name, onSuccess, onFailure}) {
    try {
      commit(MutationTypeNewRaCampaign.Loading);
      const result = await newRaCampagin.getDealerByBranch(name);
      if (onSuccess) {
        onSuccess(result);
      }
    } catch (error) {
      if (onFailure) {
        onFailure();
      }
      dispatch(ActionType.CatchException, error);
    } finally {
      commit(MutationTypeNewRaCampaign.Loaded);
    }
  },
  async [ActionTypeNewRaCampaign.GetStatistic]({
    commit, dispatch
  }: ActionContext<INewRACampaignState, IState>,
                                               {onSuccess, onFailure}) {
    try {
      commit(MutationTypeNewRaCampaign.Loading);
      const statistic = await newRaCampagin.getStatistic();
      if (onSuccess) {
        onSuccess(statistic);
      }
    } catch (error) {
      if (onFailure) {
        onFailure();
      }
      dispatch(ActionType.CatchException, error);
    } finally {
      commit(MutationTypeNewRaCampaign.Loaded);
    }
  },
  async [ActionTypeNewRaCampaign.ExportStatistic]({
    commit, dispatch
  }: ActionContext<INewRACampaignState, IState>,
                                                  {id, onSuccess, onFailure}) {
    try {
      commit(MutationTypeNewRaCampaign.Loading);
      const statistic = await newRaCampagin.exportStatistic(id);
      if (onSuccess) {
        onSuccess(statistic);
      }
    } catch (error) {
      if (onFailure) {
        onFailure();
      }
      dispatch(ActionType.CatchException, error);
    } finally {
      commit(MutationTypeNewRaCampaign.Loaded);
    }
  },
  async [ActionTypeNewRaCampaign.GetManageExport]({
    commit, dispatch
  }: ActionContext<INewRACampaignState, IState>,
                                                  {pagination}) {
    try {
      commit(MutationTypeNewRaCampaign.Loading);
      const { page, limit } = pagination;
      const offset = (page - 1) * limit;
      const listManagerExport = await newRaCampagin.getListManageExport(limit, offset);
      if (listManagerExport) {
        commit(MutationTypeNewRaCampaign.SetManageExport, listManagerExport);
      }
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    } finally {
      commit(MutationTypeNewRaCampaign.Loaded);
    }
  },
  async [ActionTypeClassifiedRequest.GetSalesStaffSameGroup]({
    dispatch
  }: ActionContext<INewRACampaignState, IState>,
                                                             {filter, onSuccess}) {
    try {
      const data = await newRaCampagin.getSalesStaffReviewSameGroup(filter);
      onSuccess(data);
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    }
  },
  async [ActionTypeNewRaCampaign.ExportPaymentDocument]({
    commit, dispatch
  }: ActionContext<INewRACampaignState, IState>,
                                                        {id, form, onSuccess, onFailure}) {
    try {
      commit(MutationTypeNewRaCampaign.Loading);
      const data = await newRaCampagin.exportPaymentDocument(id, form);
      if (onSuccess) {
        onSuccess(data);
      }
    } catch (error) {
      if (onFailure) {
        onFailure();
      }
      dispatch(ActionType.CatchException, error);
    } finally {
      commit(MutationTypeNewRaCampaign.Loaded);
    }
  },
};
