import { cloneDeep, find } from 'lodash';
import { IAclCampaignState } from '.';

export const getters = {
  getAclCampaignDetail(state: IAclCampaignState) {
    return (id: string) => {
      return find(cloneDeep(state.data), (e) => e.id === id);
    };
  }
};
