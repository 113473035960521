import { stripObject } from '@hgiasac/helper';
import { Form } from 'element-ui';
import { cloneDeep } from 'lodash';
import { AddressSelection } from 'root/components/AddressSelection';
import { ruleEmailNoRequired, rulePhoneNumber,
  ruleRequired
} from 'root/helpers';
import { IUser } from 'root/models';
import { dealerStaffFormDefault, IDealerStaffForm } from 'root/models/Dealer';
import { IState, MutationType, TypeAlert } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import { ActionTypeAccountStaff } from '../Store/types';
import './styles.scss';
@Component({
  template: require('./view.html'),
  props: {
    visible: Boolean,
    dealerId: String,
    shops: Array,
    staff: Object,
  },
  components: {
    'address-selection': AddressSelection
  },
  computed: {
    ...mapState({
      authUser: (state: IState) => state.global.authUser,
    }),
    cloneShopOptions() {
      const shops: any[] = cloneDeep(this.$props.shops);

      return shops.map((e) => {
        return {
          label: e.name,
          value: e.id,
        };
      });
    },
  }
})

export class AccountStaffEditor extends Vue {

  public get rules() {
    return {
      required: ruleRequired(),
      rulePhoneNumber: rulePhoneNumber(),
      ruleEmailNoRequired: ruleEmailNoRequired(),
    };
  }

  public get cloneShopOptions() {
    const shops: any[] = cloneDeep(this.$props.shops);

    return shops.map((e) => {
      return {
        label: e.name,
        value: e.id,
      };
    });
  }

  public $refs: {
    form: Form
  };
  public authUser: IUser;
  public loading: boolean = false;
  public form: IDealerStaffForm = dealerStaffFormDefault();
  public dealerId: string;
  public staff;
  public viewProfile: boolean = false;

  public async submit() {
    this.$refs.form.validate(async (valid) => {
      if (valid) {
        this.loading = true;
        const form = <any> cloneDeep(this.form);
        const { email } = this.form;
        if (email) {
          form.email = email.toLowerCase();
        }
        form.createdBy = this.authUser.id;
        this.$store.dispatch(ActionTypeAccountStaff.CREATE_STAFF, {
          form: {...stripObject(form)},
          onSuccess: () => {
            this.loading = false;
            const message = 'Create staff successfully';
            this.$store.commit(MutationType.OpenTopAlert, {
              message,
              type: TypeAlert.Success
            });
            this.$emit('createSuccess');
            this.back();
          },
          onFailure: () => {
            this.loading = false;

          }
        });
      }

      return;
    });
  }
  public change() {

    return;
  }
  public back() {
    this.form = dealerStaffFormDefault();
    this.viewProfile = false;
    this.$refs.form.clearValidate();
    this.$emit('update:visible', false);
  }

  public async openDialog() {
    if (this.staff) {
      this.form = this.staff;
      this.viewProfile = true;
    }
  }
  public closeDialog() {
    this.form = dealerStaffFormDefault();
    this.viewProfile = false;
    this.$refs.form.clearValidate();
    this.$emit('update:visible', false);
  }

}
