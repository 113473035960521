
import { cloneDeep, isEmpty } from 'lodash';
import moment from 'moment';
import { ActionTypeSaleEduCampaign } from 'root/admin/CustomCampaign/Store/types';
import { IPaginationParams } from 'root/api/graphql/Core';
import { formatCurrency, FormatDateFullTime } from 'root/helpers';
import { IFilterInput, IUser } from 'root/models';
import Vue from 'vue';
import Component from 'vue-class-component';
import { ImportHistoryDetail } from '../ImportHistoryDetail';
import './styles.scss';

const enum FilterKey {
  Status = 'status'
}
@Component({
  template: require('./view.html'),
  props: {
    visible: Boolean
  },
  components: {
    'history-detail': ImportHistoryDetail
  }
})
export class SalesEduImportHistory extends Vue {
  public get columns() {
    return [
      {
        label: 'Import Code',
        prop: 'importCode',
        width: 200
      },
      {
        label: 'Imported By',
        prop: 'importBy',
        formatter: (model: any) => {
          return model.creator && model.creator.name ? model.creator.name : ' --';
        },
        width: 280
      },
      {
        label: 'Role',
        prop: 'role',
        formatter: (model: any) => {
          return model.creator && model.creator.role && model.creator.role.name ? model.creator.role.name : ' --';
        },
        width: 180
      },
      {
        label: 'Imported At',
        prop: 'importAt',
        formatter: (model: any) => {
          return model.createdAt ?
           FormatDateFullTime(model.createdAt, {locale: true, format: 'HH:mm, DD/MM/YYYY'}) : ' --';
        },
        width: 180
      },
      {
        label: 'Url',
        prop: 'url',
        width: 200
      },
      {
        label: 'Status',
        prop: 'status',
        formatter: (model: any) => {
          return model && model.importStatus ? this.formatterStatus(model.importStatus) : '--';
        },
        labelStyle: (model: any) => {
          return `text--capitalize import-status__${model.importStatus}`;
        },
        width: 120
      },
      {
        label: 'Reason',
        prop: 'failedReasons',
        width: 300
      },
      {
        prop: 'action',
        label: '',
        align: 'center',
        fixed: 'right',
        width: 60
      }
    ];
  }
  public get selectedFilter() {
    const _filterParams = cloneDeep(this.filterParams);

    return [
      {
        key: FilterKey.Status,
        value: _filterParams[FilterKey.Status]
      },
    ];
  }
  public get dataFilter() {
    const _statuses: IFilterInput[] = [
      {
        key: 'success',
        value: 'done',
        name: 'Successful',
        style: 'filter__success'
      },
      {
        key: 'fail',
        value: 'failed',
        name: 'Failed',
        style: 'filter__failed'
      }
    ];

    return [
      {
        key: FilterKey.Status,
        name: this.$t('field.status'),
        value: _statuses
      }
    ];
  }
  public get pickerOptionDealerConfirmPeriodTimeFrom() {
    return {
      disabledDate: (time) => {
        return time.getTime() > moment() || (
          this.filterParams.importedAt.to && time.getTime() >
          moment(this.filterParams.importedAt.to)
        );
      }
    };
  }
  public get pickerOptionDealerConfirmPeriodTimeTo() {
    return {
      disabledDate: (time) => {
        return time.getTime() > moment() || (
          this.filterParams.importedAt.from && time.getTime() <
           moment(this.filterParams.importedAt.from)
        );
      }
    };
  }
  public FormatDateFullTime = FormatDateFullTime;
  public detail: any = {};
  public data: any = [];
  public loading: boolean = false;
  public authUser: IUser;
  public visibleDetail: boolean = false;
  public formatCurrency = formatCurrency;
  public searchText: string = '';
  public modelDetail: any = {};
  public pagination: IPaginationParams = {
    limit: 20,
    page: 1,
    total: 20
  };
  public filterParams = {
    [FilterKey.Status]: [],
    importedAt: {
      from: null,
      to: null
    }
  };

  public onClickHyperLink(url) {
    window.open(url, '_blank');
  }
  public clickDetail(model) {
    this.modelDetail = model;
    this.visibleDetail = true;
  }
  public formatterStatus(status) {
    const map = {
      done: 'Successful',
      failed: 'Failed'
    };

    return map[status];
  }
  public handleClear() {
    this.filterParams = {
      [FilterKey.Status]: [],
      importedAt: {
        from: null,
        to: null
      }
    };
  }
  public handlePaginationChange(value) {
    if (typeof value === 'number') {
      this.pagination = {
        ...this.pagination,
        limit: value,
        page: 1
      };
    } else {
      this.pagination = {
        ...value
      };
    }
    this.fetchData();
  }
  public closeDialogDetail() {
    this.visibleDetail = false;
    this.modelDetail = {};
  }
  public handleChangeFilter(filter) {
    const status = filter.find((e) => e.key === FilterKey.Status);
    const nextFilter = {
      [FilterKey.Status]: status ? status.value : [],
    };
    this.filterParams = {
      ...cloneDeep(this.filterParams),
      ...nextFilter
    };
    this.pagination = {
      limit: 10,
      page: 1
    };
    this.fetchData();
  }
  public handleSearch() {
    this.pagination = {
      limit: 10,
      page: 1
    };
    this.fetchData();
  }
  public fetchData() {
    const text = cloneDeep(this.searchText);
    this.loading = true;
    const {status, importedAt} = this.filterParams;
    this.$store.dispatch(ActionTypeSaleEduCampaign.GetListImportHistory, {
      status: status && status.length ? status : null,
      search: text && text.length ? `%${text}%` : null,
      importedAt: importedAt && !isEmpty(importedAt) ? importedAt : null,
      pagination: this.pagination,
      onSuccess: (data) => {
        this.data = data.imports;
        this.pagination = {
          ...this.pagination,
          total: data.imports_aggregate.aggregate.count
        };
        this.loading = false;
      }
    });
  }
  public openDialog() {
    this.fetchData();
  }
  public closeDialog() {
    this.$emit('closeDialog');
  }
}
