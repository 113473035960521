import { ActionTypeSaleInCampaign } from 'root/admin/CustomCampaign/SaleInCampaign/Store/types';
import { FormatDateFullTime } from 'root/helpers';
import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  template: require('./view.html'),
  props: {
    visible: Boolean,
    detailId: String,
    detailType: String,
    detailProps: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
})

export class SaleInCampaignHistoryDetail extends Vue {
  public get updateInfoColumns() {
    return [
      {
        label: 'data',
        prop: 'label',
        width: 270
      },
      {
        label: 'former',
        prop: 'former',
        width: 238
      },
      {
        label: 'new',
        prop: 'newValue',
        width: 164
      },
    ];
  }
  public get columns() {
    return [
      ...this.$props.detailProps && this.$props.detailProps.type === 'si_progress' ? [
        {
          label: 'Dealer Name',
          labelItem: 'Dealer Code',
          formatter: (model: any) => {
            return model && model.dealerName ? model.dealerName : '--';
          },
          description: {
            formatter: (model: any) => {
              return model && model.dealerCode ? model.dealerCode : '--';
            },
          },
          width: 200
        },
        {
          label: 'Model',
          prop: 'modelNumber',
          width: 160
        },
        {
          label: 'Quantity',
          prop: 'quantity',
          width: 120
        },
      ] : [],
      ...this.$props.detailProps && this.$props.detailProps.type === 'si_target' ? [
        {
          label: 'Dealer Name',
          labelItem: 'Dealer Code',
          formatter: (model: any) => {
            return model && model.dealerName ? model.dealerName : '--';
          },
          description: {
            formatter: (model: any) => {
              return model && model.dealerCode ? model.dealerCode : '--';
            },
          },
          width: 200
        },
        {
          label: 'Target',
          prop: 'target',
          width: 160
        },
      ] : [],
      {
        label: 'status',
        prop: 'status',
        labelStyle: (model: any) => {
          return `text--capitalize import_status_history__${model.status}`;
        },
        width: 140
      },
      {
        label: 'reason',
        prop: 'reason',
        width: 130
      }
    ];
  }
  public FormatDateFullTime = FormatDateFullTime;
  public formatDate = FormatDateFullTime;
  public LogsType = {
    Create: 'create',
    Edit: 'edit',
    Import: 'import',
    Activate: 'activate',
    Deactivate: 'deactivate'
  }
  ;
  public detail: any = {};
  public dataUpdateInformation: any[] = [];
  public dataImportModel: any[] = [];
  public handlePaginationChange() {
    // 
  }
  public downloadImportFile(url: string) {
    window.open(url, '_blank');
  }
  public async openDialog() {
    if (this.$props.detailId) {
      this.detail = this.$store.getters.getSaleInLogsDetail(this.$props.detailId);

      return;
    }
    if (this.detail && this.detail.action === this.LogsType.Edit) {
      this.dataUpdateInformation = [];
      // tslint:disable-next-line:forin
      for (const key in this.detail.updatedKeys) {
        const value = {
          label: key,
          former: this.detail.updatedKeys[key].old.value,
          newValue: this.detail.updatedKeys[key].new.value
        };
        this.dataUpdateInformation.push(value);
      }

      return;
    }
    if (this.$props.detailProps) {
      this.dataImportModel = [];
      this.$store.dispatch(ActionTypeSaleInCampaign.GetImportModelLogs, {
        importId: this.$props.detailProps.id,
        onSuccess: (data) => {
          console.log(data);
          this.dataImportModel = data;
        }
      });

      return;
    }

    return;
  }
  public closeDialog() {
    this.$emit('update:visible', false);
    this.$emit('closeDialog');
  }
}
