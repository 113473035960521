import { formatCurrency } from 'root/helpers';
import Vue from 'vue';
import Component from 'vue-class-component';
import './styles.scss';

@Component({
  template: require('./view.html'),
  props: {
    value: [String, Number],
    maxValue: Number,
    negative: Boolean,
    disabled: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ''
    }
  }
})

export class CurrencyInput extends Vue {
  public $refs: {
    input: HTMLInputElement
  };

  public keyupNative(e: any) {
    this.format(e.target);
  }
  public blur(e) {
    this.format(e.target, 'blur');
  }
  public focus(e: Event) {
    this.$emit('focus', e);
  }
  public change(e: any) {
    this.$emit('update:value', formatCurrency(e.target.value));
  }
  private format(input: HTMLInputElement, blur?: string) {
    // appends $ to value, validates decimal side
    // and puts cursor back in right position.

    // get input value
    let inputVal = input.value;

    if ((this.$props.maxValue && Math.abs(Number(inputVal.replace(/[,-]/g, ''))) > this.$props.maxValue)
      || this.$props.maxValue === 0) {
      inputVal = formatCurrency(this.$props.maxValue);
    }

    // don't validate empty input
    if (inputVal === '') { return; }

    // original length
    const originalLen = inputVal.length;

    // initial caret position 
    let caretPos = input.selectionStart;

    inputVal = formatCurrency(inputVal);

    if (blur === 'blur') {
      inputVal = formatCurrency(inputVal);
    }

    inputVal = `${this.$props.negative ? '-' : ''}${inputVal}`;
    // send updated string to input
    input.value = inputVal;
    this.$emit('update:value', inputVal);

    // put caret back in the right position
    const updatedLen = inputVal.length;
    caretPos = updatedLen - originalLen + caretPos;
    input.setSelectionRange(caretPos, caretPos);
  }
}
