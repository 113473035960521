import { stripObject } from '@hgiasac/helper';
import { Form } from 'element-ui';
import { cloneDeep, omit } from 'lodash';
import moment from 'moment-timezone';
import { ActionTypeBranch, MutationTypeBranch } from 'root/admin/Branch/Store/types';
import { ActionTypeBranchManage } from 'root/admin/BranchAndGroup/BranchManage/Store/types';
import { ActionTypeGroupManage } from 'root/admin/BranchAndGroup/GroupManage/Store/types';
import { TypeRole } from 'root/admin/Dealer/Store/types';
import { ActionTypeGroupSystem } from 'root/admin/Group/Store/types';
import { ActionTypeRole } from 'root/admin/Role/Store/types';
import { DKGqlClient } from 'root/api/graphql/Client';
import { Avatar } from 'root/components';
import { AddressSelection } from 'root/components/AddressSelection';
import { Upload } from 'root/components/Upload';
import {
  formatterPhoneNumber, resetOrientation,
  revertPhoneNumber,
  ruleCompare, ruleEmail, ruleEmailNoRequired, ruleMinLength, ruleNumber, rulePhoneNumber,
  ruleRequired, sanitizeString
} from 'root/helpers';
import { IPartner } from 'root/models/Partner';
import {
  groupSystemUserSelection, stationRoleOptions,
  userRegisterFormDefault, EGender, ESystemGroup, ISimpleUser, IUser, IUserRegisterForm, UserType
} from 'root/models/User';
import { pathUpload, sizeImage, uploadToFirebase } from 'root/services';
import { FirebaseAuthService } from 'root/services/auth/firebase';
import { ActionType, IState, MutationType, TypeAlert } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapGetters, mapState } from 'vuex';
import { ActionTypeUser, MutationTypeUser, SystemRoleAlias } from '../Store/types';
import './styles.scss';

const genderOptions = [
  {
    value: EGender.Male,
    label: EGender.Male
  },
  {
    value: EGender.Female,
    label: EGender.Female
  }
];

const enum OldSystemRole {
  RegionDirector = 'Region Director',
  TeamLeader = 'Team Leader',
  Staff = 'Staff'
}

@Component({
  template: require('./view.html'),
  components: {
    Upload,
    avatar: Avatar,
    'address-selection': AddressSelection
  },
  props: {
    visible: Boolean,
    userType: String,
    userId: String,
    fromCallCenter: Boolean
  },
  computed: {
    ...mapGetters(['role', 'group']),
    ...mapState({
      authUser: (state: IState) => state.global.authUser,
      userDetail: (state: IState) => state.user.data,
      userDetailLoading: () => false,
      roles: (state: IState) => state.role.data,
      group: (state: IState) => state.group.systemGroup.data.filter((it) => it.name !== ESystemGroup.Dealer),
      isRefesh: (state: IState) => state.global.isRefesh,
      branch: (state: IState) => state.branchManage.data,
      teamgroup: (state: IState) => state.groupManage.data,
      dealer: (state: IState) => state.dealer.data,
      dataUser: (state: IState) => state.user.data,
      dataStaff: (state: IState) => state.teamStaff.data,
    }),
    cloneRoleOptions() {
      let roles: any[] = cloneDeep(this.roles);
      if (this.selectedGroupId) {
        roles = roles.filter((it) => it.systemGroupId === this.selectedGroupId);
      }
      const cloneRoles = roles.filter((e) => {
        return e.name !== OldSystemRole.RegionDirector &&
        e.name !== OldSystemRole.TeamLeader &&
        e.name !== OldSystemRole.Staff;
      });

      return cloneRoles.map((e) => {
        return {
          label: e.name,
          value: e.id,
        };
      });
    },
    cloneGroupOptions() {
      const groups: any[] = cloneDeep(this.group);

      return groups.map((e) => {
        return {
          label: e.name,
          value: e.id
        };
      });
    },
    cloneBranchOptions() {
      const branchs: any[] = cloneDeep(this.branch);

      return branchs.map((e) => {
        return {
          label: e.name,
          value: e.id
        };
      });
    },
    cloneTeamGroupOptions() {
      let groups: any[] = cloneDeep(this.teamgroup);
      if (this.selectedBranch) {
        groups = groups.filter((it) => it.branch && it.branch.id === this.selectedBranch);
      }

      return this.selectedBranch
        ? groups.map((e) => {
          return {
            label: e.name,
            value: e.id
          };
        })
        : [];
    },
    cloneTeamDealerOptions() {
      let dealer: any[] = cloneDeep(this.dealer);
      if (this.selectedTeamGroup) {
        dealer = dealer.filter((it) => it.group && it.group.id === this.selectedTeamGroup);
      }

      return this.selectedTeamGroup
        ? dealer.map((e) => {
          return {
            label: e.name,
            value: e.id
          };
        })
        : [];
    },
    cloneStaffOptions() {
      const staff: any[] = cloneDeep(this.dataStaff);

      return staff.map((e) => {
        return {
          label: e.manager.name,
          value: e.manager.id
        };
      });
    },
    cloneStations() {
      // if (this.isSystemUser && this.form.permission === 'head_of_station') {
      //   return this.stations.filter((e) => !this.stationIdsHaveHeader.includes(e.id));
      // }

      return this.stations;
    }
  },
})

export class UserEditor extends Vue {

  public get isSystemUser(): boolean {
    return this.userType === UserType.System;
  }
  public get isCustomer(): boolean {
    return this.userType === UserType.Customer;
  }
  public get isTechnician(): boolean {
    return this.userType === UserType.Technician;
  }
  public get isUpdating(): boolean {
    return !!this.userId;
  }
  public get clonePartners() {
    const text = sanitizeString(this.querySearchPartner);

    return this.partners.filter((e) => sanitizeString(e.name).includes(text));
    // !this.partnerHaveCoordinator.includes(e.id) &&
  }

  public get rules() {
    return {
      required: ruleRequired(),
      rulePhoneNumber: rulePhoneNumber(),
      min: ruleMinLength(8),
      ruleEmail: ruleEmail(),
      ruleEmailNoRequired: ruleEmailNoRequired(),
      compare: ruleCompare(this.form.password),
      number: ruleNumber()
    };
  }

  public get informationTitle(): string {
    return `${this.$t(this.userType)} ${this.$t('information')}`;
  }

  public $refs: {
    form: Form
  };
  public loading: boolean = false;
  public authUser: IUser;
  public userDetail: IUser;
  public roleOptions: any[];
  public form: IUserRegisterForm = userRegisterFormDefault();
  public groupSystemUserSelection = groupSystemUserSelection();
  public stationRoleOptions = stationRoleOptions();
  public stationGroupOptions = groupSystemUserSelection();
  public fileUpload: File = null;
  public detail: IUser;
  public route: any;
  public userType: UserType;
  public userId: string;
  public technicianRates: any;
  public partners: IPartner[];
  public partnerHaveCoordinator: string[] = [];
  public validateTechnicianCode: {
    status?: string,
    message?: string
  } = null;
  public genderOptions = genderOptions;
  public selectedGroup: boolean = false;
  public selectedGroupId: string = '';
  public cloneRoleOptions: any[];
  public isRefesh: boolean;
  public isShowPass: boolean = false;
  public isShowBranch: boolean = false;
  public isShowTeam: boolean = false;
  public isBranchPIC: boolean = false;
  public isSalePlanning: boolean = false;
  public isShowStaff: boolean = false;
  public selectedBranch: string = '';
  public regionSelectedBranch: any[] = [];
  public selectedTeamGroup: string = '';
  public memberIds: any[] = [];
  public staffIds: any[] = [];
  public isManager: boolean = false;
  public isSalesStaffUpdate: boolean = false;

  private pathUploadAvatar: pathUpload = pathUpload.SYSTEM_USER;
  private querySearchPartner: string = '';

  public changeForm() {
    //
  }

  public async handleUpload(file: any) {
    // tslint:disable-next-line: whitespace
    const _file = <any>await resetOrientation(file.raw, -1);
    this.form.avatar = window.URL.createObjectURL(_file);
    this.fileUpload = _file;
  }
  public handleShowPass() {
    this.isShowPass = !this.isShowPass;
  }
  public onChangeBranch(value: string) {
    this.selectedBranch = value;
    this.memberIds = [];
    this.staffIds = [];
    this.onChangeTeamGroup('');
  }
  public onChangeTeamGroup(value: string) {
    this.selectedTeamGroup = value;
    this.form.groupIds = value;
    this.memberIds = [];
    this.staffIds = [];
    // if (value) {
    //   this.$store.commit(
    //     MutationTypeUser.FilterChange,
    //     stripObject({
    //       groupId: value
    //     }));
    //   this.$store.dispatch(ActionTypeUser.FilterNoCache);
    //   this.$store.commit(
    //     MutationTypeTeamStaff.FilterChange, {
    //       mode: 'special',
    //       groupId: value,
    //       manager: {
    //         role: {
    //           alias:  SystemRoleAlias.Staff
    //         }
    //       }
    //     }
    //     );
    //   this.$store.dispatch(ActionTypeTeamStaff.FilterNoCache);
    // }
    // this.getDealers({
    //   group: value,
    //   branch: this.selectedBranch
    // });
    this.$nextTick(() => {
      this.$refs.form.clearValidate();
    });
  }
  public onChangeRole(value: string) {
    const selectedRole = this.cloneRoleOptions.filter((it) => it.value === value);
    const _selectedRole = selectedRole && selectedRole.length && selectedRole[0].label;
    switch (_selectedRole) {
    case TypeRole.RegionDirector:
      this.isShowBranch = true;
      this.isShowTeam = false;
      this.isShowStaff = false;
      this.isBranchPIC = false;
      this.isSalePlanning = false;
      break;
    case TypeRole.TeamLeader:
      this.isShowTeam = true;
      this.isShowBranch = false;
      this.isShowStaff = true;
      this.isBranchPIC = false;
      this.isSalePlanning = false;
      break;
    case TypeRole.Staff:
      this.isShowTeam = true;
      this.isShowBranch = false;
      this.isShowStaff = false;
      this.isBranchPIC = false;
      this.isSalePlanning = false;
      break;
    case TypeRole.BranchPIC:
      this.isShowTeam = false;
      this.isShowBranch = false;
      this.isShowStaff = false;
      this.isBranchPIC = true;
      this.isSalePlanning = false;
      break;
    case TypeRole.BranchManager:
      this.isShowTeam = false;
      this.isShowBranch = false;
      this.isShowStaff = false;
      this.isBranchPIC = true;
      this.isSalePlanning = false;
      break;
    case TypeRole.SalePlanning:
      this.isShowTeam = false;
      this.isShowBranch = false;
      this.isShowStaff = false;
      this.isBranchPIC = true;
      this.isSalePlanning = true;
      break;
    case TypeRole.SaleDepartmentPic:
      this.isShowTeam = false;
      this.isShowBranch = false;
      this.isShowStaff = false;
      this.isBranchPIC = true;
      this.isSalePlanning = true;
      break;
    case TypeRole.SaleManager:
      this.isShowTeam = false;
      this.isShowBranch = false;
      this.isShowStaff = false;
      this.isBranchPIC = true;
      this.isSalePlanning = true;
      break;
    case TypeRole.SaleStaff:
      this.isShowTeam = false;
      this.isShowBranch = false;
      this.isShowStaff = false;
      this.isBranchPIC = true;
      this.isSalePlanning = true;
      this.isSalesStaffUpdate = true;
      break;

    default:
      this.isShowBranch = false;
      this.isShowTeam = false;
      break;
    }
  }

  public async submit() {
    this.$refs.form.validate(async (valid) => {
      if (valid) {
        try {
          let form = <any> cloneDeep(this.form);
          let { avatar, dob } = this.form;
          const { phoneNumber } = this.form;
          if (avatar && this.fileUpload) {
            const token = await DKGqlClient().user.getFirebaseAuthenticateToken();
            await FirebaseAuthService().signInWithCustomToken(token.authenticate);
            avatar = await uploadToFirebase(this.fileUpload, this.pathUploadAvatar, sizeImage.avatar.thumbnail);
            form.avatar = avatar;
          }
          if (phoneNumber) {
            form.phoneNumber = formatterPhoneNumber(phoneNumber);
          }
          if (dob) {
            // tslint:disable-next-line: whitespace
            dob = <any>moment(dob).utc().unix();
          }

          form = omit(form, ['createdUser', 'updatedUser']);
          this.handleSystemUser(form);
        } catch (error) {
          this.$store.dispatch(ActionType.CatchException, error);
        }
      }

      return;
    });
  }

  public back() {
    this.$emit('update:visible', false);
  }

  public async openDialog() {
    if (!this.isRefesh) {
      this.$store.dispatch(ActionTypeRole.FilterNoCache);
      this.$store.dispatch(ActionTypeGroupSystem.FilterNoCache);
      this.$store.dispatch(ActionTypeBranchManage.GetListBranch, {
        form: {
          filter: {},
          pagination: {}
        }
      });
      this.$store.dispatch(ActionTypeGroupManage.GetListGroupManage, {
        form: {
          filter: {},
          pagination: {}
        }
      });
      // this.$store.dispatch(ActionTypeDealer.GetListDealer, {
      //   form: {
      //     filter: {},
      //     pagination: {
      //       size: 20,
      //       page: 1
      //     }
      //   }
      // });
    }
    if (this.userId) {
      this.form = this.$store.getters.getSystemUserDetail(this.userId);
      this.isSalesStaffUpdate = false;
      this.form.phoneNumber = this.form.phoneNumber ?
        revertPhoneNumber(this.form.phoneNumber) : this.form.phoneNumber;
      if (this.form.role && this.form.role.alias === SystemRoleAlias.RegionDirector
         && this.userId !== this.authUser.id) {
        this.isShowBranch = true;
        this.isManager = true;
        this.$store.commit(MutationTypeBranch.FilterChange, {
          directorId: this.userId
        });
        this.$store.commit(MutationTypeBranch.PaginationChange, {
          limit: 3000,
          page: 1
        });
        this.$store.dispatch(ActionTypeBranch.FilterNoCache, {
          params: {
            onSuccess: (result) => {
              const _branch = result && result.length ? result.map((it) => it.id) : [];
              this.regionSelectedBranch = _branch;
              this.form.branchIds = _branch;
            }
          }
        });
      }
      if (this.form.role && this.form.role.alias === SystemRoleAlias.TeamLeader
        && this.userId !== this.authUser.id) {
        this.onChangeTeamGroup(this.form.groupId);
        const staff = await this.$store.dispatch(ActionTypeUser.GetTeamStaff, { id: this.userId });
        const dealer = await this.$store.dispatch(ActionTypeUser.GetTeamStaffDealer, { id: this.userId });
        if (staff && staff.length) {
          this.staffIds = staff.map((it) => it.staffId);
          // this.form.staffIds = staff.map(it => it.staffId);
        }
        if (dealer && dealer.length) {
          this.memberIds = dealer.map((it) => it.dealerId);
          // this.form.memberIds = dealer.map(it=>it.dealerId)
        }
        this.isManager = true;
        this.isShowTeam = true;
        this.isShowStaff = true;
      }
      if (this.form.role && this.form.role.alias === SystemRoleAlias.Staff
        && this.userId !== this.authUser.id) {
        this.onChangeTeamGroup(this.form.groupId);
        const dealer = await this.$store.dispatch(ActionTypeUser.GetTeamStaffDealer, { id: this.userId });
        if (dealer && dealer.length) {
          const _dealer = dealer.map((it) => it.dealerId);
          this.memberIds = _dealer;
          this.form.memberIds = _dealer;
        }
        this.isManager = true;
        this.isShowTeam = true;
        this.isShowStaff = false;
      }
      if (this.form.role && (
        this.form.role.alias === SystemRoleAlias.SaleStaff || this.form.role.alias === SystemRoleAlias.SaleDepartmentPic
        || this.form.role.alias === SystemRoleAlias.BranchPIC || this.form.role.alias === SystemRoleAlias.GroupLeader
        || this.form.role.alias === SystemRoleAlias.BranchManager
      )) {
        this.onChangeTeamGroup(this.form.groupId);
        const dealer = await this.$store.dispatch(ActionTypeUser.GetTeamStaffDealer, { id: this.userId });
        if (dealer && dealer.length) {
          const _dealer = dealer.map((it) => it.dealerId);
          this.memberIds = _dealer;
          this.form.memberIds = _dealer;
        }
        this.isManager = true;
        this.isShowTeam = true;
        this.isShowStaff = false;
        this.isSalesStaffUpdate = true;
      }
      if (this.form.branch) {
        this.form.branchId = this.form.branch.id;
        this.selectedBranch = this.form.branch.id;
      }
      if (this.form.groupId) {
        this.form.groupIds = this.form.groupId;
      }
    }
    if (this.userId === this.authUser.id) {
      this.form = this.authUser;
      this.form.branchId = this.form.branch.id;
      this.form.groupIds = this.form.groupId;
    }

    if (this.$refs.form) {
      this.$refs.form.clearValidate();
    }
  }
  public confirmClose() {
    this.$msgbox({
      title: this.$t('confirm').toString(),
      message: this.$t('action_confirm_message').toString(),
      showCancelButton: true,
      confirmButtonText: this.$t('confirm').toString(),
      cancelButtonText: this.$t('cancel').toString(),
      beforeClose: (action, _instance, done) => {
        if (action === 'confirm') {
          this.closeDialog();
          done();
        } else {
          done();
        }

        return;
      }
    });
  }
  public closeDialog() {
    this.form = userRegisterFormDefault();
    this.isShowBranch = false;
    this.isShowTeam = false;
    this.selectedBranch = '';
    this.selectedTeamGroup = '';
    this.regionSelectedBranch = [];
    this.memberIds = [];
    this.staffIds = [];
    this.selectedGroupId = '';
    this.selectedGroup = false;
    this.isShowStaff = false;
    this.isBranchPIC = false;
    this.isSalePlanning = false;
    this.isManager = false;
    this.$store.commit(
      MutationTypeUser.FilterChange,
      stripObject({

      }));
    this.$store.commit(MutationTypeUser.ChangeOrderBy, { createdAt: 'desc' });
    this.$store.dispatch(ActionTypeUser.FilterNoCache);
    this.back();
  }

  public remoteMethod(query: string) {
    if (query !== '') {
      this.querySearchPartner = query;
    }
  }

  public changeStationRole(value: any) {
    this.selectedGroupId = value;
    this.selectedGroup = true;
    // this.$store.commit(MutationTypeUser.FilterChange, {
    //   role: {
    //     alias: SystemRoleAlias.Staff
    //   }
    // });
    // this.$store.commit(MutationTypeUser.ChangeOrderBy, { createdAt: 'desc' });
    // this.$store.dispatch(ActionTypeUser.FilterNoCache);
  }

  protected beforeDestroy() {
    this.querySearchPartner = '';
    // this.$store.commit(MutationTypeTechnicianRate.ResetState);
  }
  protected mounted() {
    // tslint:disable-next-line:early-exit
  }

  private async handleSystemUser(form: any) {
    const postForm: any = {
      avatar: form.avatar,
      name: form.name,
      dob: form.dob,
      email: form.email,
      password: form.password,
      roleId: form.roleId,
      gender: form.gender,
      systemGroupId: form.systemGroupId,
      phoneNumber: form.phoneNumber,
    };
    if (form.branchIds && form.branchIds.length) {
      postForm.branchIds = form.branchIds;
    }
    if (this.memberIds.length) {
      postForm.memberIds = this.memberIds;
      postForm.branchId = form.branchId;
      postForm.groupId = form.groupIds;
      postForm.groupIds = form.groupIds;
      postForm.branchIds = form.branchId;
    }
    if (this.staffIds.length) {
      postForm.staffIds = this.staffIds;
      postForm.branchId = form.branchId;
      postForm.groupId = form.groupIds;
      postForm.groupIds = form.groupIds;
      postForm.branchIds = form.branchId;
    }
    if (this.isSalePlanning || this.isBranchPIC) {
      postForm.branchId = form.branchId;
      postForm.groupId = form.groupIds;
    }
    if (this.isSalesStaffUpdate) {
      postForm.branchId = form.branchId;
      postForm.groupId = form.groupIds;
    }
    if (this.userId) { // update
      if (this.isManager) {
        this.$store.dispatch(ActionTypeUser.UpdateUserManager, {
          form: {
            branchId: postForm.branchId ? postForm.branchId : '',
            groupId: postForm.groupId ? postForm.groupId : '',
            ...!this.isSalesStaffUpdate ? {
              branchIds: postForm.branchIds ? postForm.branchIds : [],
              groupIds: postForm.groupIds ? postForm.groupIds : [],
              memberIds: postForm.memberIds ? postForm.memberIds : [],
              staffIds: postForm.staffIds ? postForm.staffIds : [],
            } : {},
            userId: this.userId
          },
          id: this.userId,
          onSuccess: () => {
            this.loading = false;
          },
          onFailure: () => {
            this.loading = false;
          }
        });
      }
      this.$store.dispatch(ActionTypeUser.UpdateSystemUser, {
        form: {
          ...omit(postForm, ['__typename', 'roleId', 'updatedAt', 'updatedBy', 'phoneNumber', 'systemGroupId',
            'branchIds', 'staffIds', 'memberIds', 'groupIds']),
          updatedBy: this.authUser.id
        },
        id: this.userId,
        onSuccess: () => {
          this.loading = false;
          const message = 'Edit System User success';
          this.$store.commit(MutationType.OpenTopAlert, {
            message,
            type: TypeAlert.Success
          });
          this.closeDialog();
          this.$emit('updateSuccess');
        },
        onFailure: () => {
          this.loading = false;
        }
      });
    } else { // create
      this.$store.dispatch(ActionTypeUser.UserCreate, {
        form: {
          ...stripObject(postForm)
        },
        onSuccess: (model: ISimpleUser) => {
          this.loading = false;
          const message = 'Create System User success';
          this.$store.commit(MutationType.OpenTopAlert, {
            message,
            type: TypeAlert.Success
          });
          this.closeDialog();
          this.$emit('createSuccess', model);
        },
        onFailure: () => {
          this.loading = false;
        }
      });
    }
  }

  private getDealers() {
    // this.$store.dispatch(ActionTypeDealer.GetListDealer, {
    //   form: {
    //     filter: stripObject({
    //       branch,
    //       group
    //     }),
    //     pagination: {}
    //   }
    // });
  }
}
