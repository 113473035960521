import './styles.scss';

import { TabPane } from 'element-ui';
import { ElUpload } from 'element-ui/types/upload';
import { cloneDeep, find } from 'lodash';
import { ActionTypeBranchManage } from 'root/admin/BranchAndGroup/BranchManage/Store/types';
import { CustomCampaignRouterName, CustomCampaignTab } from 'root/admin/CustomCampaign';
import { ActionTypeRegion } from 'root/admin/Region/Store/types';
import { SystemRoleAlias } from 'root/admin/User/Store/types';
import { IPaginationParams } from 'root/api/graphql/Core';
import { Avatar } from 'root/components/Avatar';
import { FilterByBranch } from 'root/components/FilterByBranch';
import { SearchByFilter } from 'root/components/SearchByFilter';
import { fullNameUser } from 'root/helpers';
import { IAddress, IBreadcrumb, IFilterInput, IProvince } from 'root/models';
import { IUser } from 'root/models/User';
import { IState, MutationType, TypeAlert } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import { ActionTypeRaCampaign, MutationTypeRaCampaign } from '../../Store/types';

const enum FilterKey {
  Branch = 'branch',
  Region = 'region',
  Status = 'status'
}
@Component({
  template: require('./view.html'),
  components: {
    avatar: Avatar,
    'btn-filter-by-branch': FilterByBranch,
    'search-by-filter': SearchByFilter
  },
  computed: {
    ...mapState({
      authUser: (state: IState) => state.global.authUser,
      data: (state: IState) => state.raCampaign.subDealerRequest,
      pagination: (state: IState) => state.raCampaign.pagination,
      branch: (state: IState) => state.branchManage.data,
      region: (state: IState) => state.region.data,
      loading: (state: IState) => state.raCampaign.loading,
      localVN: (state: IState) => state.global.localVN,
    }),
  },
})
export class SubDealerRequest extends Vue {
  public get activeTab() {
    return CustomCampaignTab.SubDealerRequest;
  }
  public set activeTab(_value: string) {
    //
  }
  public get isSalePlanning() {
    if (this.authUser.superAdmin || this.authUser.role.alias === SystemRoleAlias.Admin
      || this.authUser.role.alias === SystemRoleAlias.SaleAdmin) {
      return true;
    }

    return false;
  }
  public get columns() {
    return [
      {
        prop: 'name',
        label: 'Sub-dealer Name',
        labelItem: 'Sub-dealer Code',
        width: 250,
        fixed: true
      },
      {
        prop: 'businessLicense',
        label: 'Business License',
        width: 200,
        formatter: (model: any) => {
          return model.dealer && model.dealer.information && model.dealer.information.businessRegistrationNo ?
            model.dealer.information.businessRegistrationNo : '--';
        }
      },
      {
        prop: 'houseHoldLicense',
        label: 'HouseHold Business License',
        width: 200,
        formatter: (model: any) => {
          return model.dealer && model.dealer.information && model.dealer.information.businessHouseholdNo ?
            model.dealer.information.businessHouseholdNo : '--';
        }
      },
      {
        prop: 'identityNumber',
        label: 'Identity Number',
        width: 200,
        formatter: (model: any) => {
          return model.dealer && model.dealer.idCard ? model.dealer.idCard : '--';
        }
      },
      {
        prop: 'branch',
        label: 'Branch Name',
        labelItem: 'Branch Code',
        width: 180,
      },
      {
        prop: 'region',
        label: 'Region',
        width: 180,
        formatter: (model: any) => {
          return model.dealer && model.dealer.region && model.dealer.region.name ? model.dealer.region.name : '--';
        }
      },
      {
        prop: 'province',
        label: 'Province',
        width: 180,
        formatter: this.formatterProvice,
      },
      {
        prop: 'phoneNumber',
        label: 'Phone',
        width: 180,
        formatter: (model: any) => {
          return model.dealer && model.dealer.phoneNumber ? model.dealer.phoneNumber : '--';
        }
      },
      {
        prop: 'address',
        label: 'Address',
        width: 220,
        formatter: (model: any) => {
          if (model.dealer && model.dealer.address) {
            return this.displayAddress(model.dealer.address) || '--';
          }

          return '--';
        }
      },
      {
        prop: 'status',
        label: this.$t('status'),
        formatter: (model: any) => (model.requestStatus ? this.$t(model.requestStatus) : ''),
        labelStyle: (model: any) => {
          return model.requestStatus;
        },
        width: 150
      },
      {
        prop: 'action',
        label: '',
        align: 'center',
        fixed: 'right',
        width: 60
      }
    ];
  }
  public get actions() {
    return [
      'view'
    ];
  }

  public get inputSearchPlaceholder(): string {
    return `Search by Name, Email`;
  }
  public get selectedFilter() {
    const filterParams = cloneDeep(this.filterParams);

    return [
      {
        key: FilterKey.Branch,
        value: filterParams[FilterKey.Branch]
      },
      {
        key: FilterKey.Region,
        value: filterParams[FilterKey.Region]
      },
    ];
  }
  public get dataFilterBranch() {
    const _branch: IFilterInput[] = this.branch.reduce((res, item) => {
      res.push({
        key: item.id,
        value: item.id,
        name: item.name
      });

      return res;
    }, []);

    return [
      {
        key: FilterKey.Branch,
        name: 'Branch',
        value: _branch
      },
    ];
  }
  public get dataFilter() {
    const _region: IFilterInput[] = this.region.reduce((res, item) => {
      res.push({
        key: item.id,
        value: item.id,
        name: item.name
      });

      return res;
    }, []);

    return [
      {
        key: FilterKey.Region,
        name: 'Region',
        value: _region
      },
    ];
  }

  public authUser: IUser;
  public localVN: IProvince[];
  public pagination: IPaginationParams;
  public branch: any[];
  public region: any[];
  public group: any[];
  public typefilter: any[];
  public status: any[];
  public dealerId: string = '';
  public visible: boolean = false;
  public isSample: boolean = false;
  public userId: string = '';
  public loading: boolean;
  public visibleDetail: boolean = false;
  public visibleUpdate: boolean = false;
  public popupVisible: boolean = false;
  public filterParams = {
    [FilterKey.Branch]: [],
    [FilterKey.Region]: [],
    // [FilterKey.Status]: []
  };
  public modelSearchBy: any[] = [];
  public visibleUpgrade: boolean = false;
  public searchText: string = '';
  public filterTypeDealer: any[];
  public isRefesh: boolean;
  public visibleChangePass: boolean = false;
  public file: any;
  public fileUpload: any;
  public fileUploaded= [];
  public fileList = [];
  public loadingUpload: boolean = false;
  public DaikinDealerId: string;
  public $refs: {
    upload: ElUpload;
  };
  public visibleImport: boolean = false;

  public type: any;
  public getActions(status) {
    if (status === 'waiting') {
      return [
        'receive',
      ];
    }

    return null;
  }
  public formatterProvice(model) {
    // cahgne after;
    const _province = model.dealer && model.dealer.address ? model.dealer.address.province : null;
    if (!_province) {
      return '--';
    }
    const province = find(cloneDeep(this.localVN), (e) => Number(e.matp) === Number(_province));

    return province && province.name ? province.name : '--';
  }
  public handleActions({ id, action }) {
    if (action === 'receive') {
      this.handleUpdate(id);

      return;
    }

    return;
  }
  public changeTab(tab: TabPane) {
    switch (tab.name) {
    case CustomCampaignTab.RAStatistic:
      this.$router.push({
        name: CustomCampaignRouterName.RAStatistic
      });
      break;
    case CustomCampaignTab.RAShops:
      this.$router.push({
        name: CustomCampaignRouterName.RAShops
      });
      break;
    case CustomCampaignTab.RAQualifiedShops:
      this.$router.push({
        name: CustomCampaignRouterName.RAQualifiedShops
      });
      break;
    case CustomCampaignTab.RAConfiguration:
      this.$router.push({
        name: CustomCampaignRouterName.RAConfiguration
      });
      break;
    case CustomCampaignTab.RAManageExport:
      this.$router.push({
        name: CustomCampaignRouterName.RAManageExport
      });
      break;
    case CustomCampaignTab.Participant:
      this.$router.push({
        name: CustomCampaignRouterName.RACampaign
      });
      break;
    case CustomCampaignTab.ConfirmationCode:
      this.$router.push({
        name: CustomCampaignRouterName.ConfirmationCode
      });
      break;
    case CustomCampaignTab.SubDealerCampaign:
      this.$router.push({
        name: CustomCampaignRouterName.SubDealerCampaign
      });
      break;
    default:
      break;
    }
  }
  public changeSearchBy(model: IFilterInput[]) {
    this.modelSearchBy = model[0].value;

    this.fetchData();
  }
  public onEnterFilter(value) {
    this.searchText = value;

    this.fetchData();
  }

  public applyFilter() {
    this.fetchData();
  }
  public goAddNewRouter() {
    this.visible = true;
    this.userId = '';
  }
  public closeDialog() {
    this.isSample = false;
    this.fetchData();
  }
  public displayAddress(model: IAddress) {
    return model && model.province
      ? this.$store.getters['getAddressString'](model)
      : '';
  }
  public changeFilter(model) {
    const region = model.find((e) => e.key === FilterKey.Region);
    const nextFilter = {
      [FilterKey.Region]: region ? region.value : [],
    };
    this.filterParams = {
      ...cloneDeep(this.filterParams),
      ...nextFilter
    };
    this.fetchData();
  }
  public changeFilterBranch(filter) {
    const nextFilter = {
      [FilterKey.Branch]: filter ? filter : [],
    };
    this.filterParams = {
      ...cloneDeep(this.filterParams),
      ...nextFilter
    };
    this.fetchData();
  }
  public handleSearch() {
    this.fetchData();
  }
  public closeModalImport() {
    this.visibleImport = false;
    this.$refs.upload.clearFiles();
    this.fileList = [];
  }

  public handleCommand(command) {
    if (command === 'addnew') {
      this.visible = true;
    } else {
      this.visibleImport = true;
    }
  }

  public clickDetail(id: string) {
    this.dealerId = id;
    this.visible = true;
  }
  public handleUpdate(id: string) {
    this.$store.dispatch(ActionTypeRaCampaign.UpdateSubDealerRequest, ({
      id,
      onSuccess: () => {
        this.loading = false;
        const message = 'Update sub-dealer request successfully';
        this.$store.commit(MutationType.OpenTopAlert, {
          message,
          type: TypeAlert.Success
        });
        this.fetchData();
      },
      onFailure: () => {
        this.loading = false;
      }
    }));
  }

  public formatFullNameUser(model) {
    return fullNameUser(model);
  }
  public handlePaginationChange(value) {
    if (typeof value === 'number') {
      this.$store.commit(MutationTypeRaCampaign.PaginationChange, {
        ...this.pagination,
        size: value
      });
    } else {
      this.$store.commit(MutationTypeRaCampaign.PaginationChange, {
        ...this.pagination,
        ...value
      });
    }
    this.fetchData();
  }

  protected mounted() {
    this.$nextTick(() => {
      this.$store.commit(MutationType.ClearBack);
      const breadcrumb: IBreadcrumb[] = [
        {
          label: 'title.campaign',
          path: null,
          current: false
        },
        {
          label: 'title.ra_campaign',
          path: null,
          current: true
        }
      ];
      this.$store.commit(MutationType.SetBreadcrumb, breadcrumb);
      this.$store.dispatch(ActionTypeRegion.FilterNoCache, {
        optionsHTTP: {
          orderBy: {
            updatedAt: 'desc'
          }
        }
      });
      this.$store.dispatch(ActionTypeBranchManage.GetListBranch, {
        form: {
          filter: {},
          pagination: {}
        }
      });
      this.fetchData();

    });
  }
  protected beforeDestroy() {
    this.$store.commit(MutationType.ClearBreadcrumb);
  }

  private async fetchData() {
    const text = cloneDeep(this.searchText);
    const _filterParams = <any> {};
    if (this.filterParams[FilterKey.Branch].length) {
      _filterParams.branch = this.filterParams[FilterKey.Branch];
    }
    if (this.filterParams[FilterKey.Region].length) {
      _filterParams.region = this.filterParams[FilterKey.Region];
    }
    if (text) {
      _filterParams.search = text;
    }
    this.$store.dispatch(ActionTypeRaCampaign.GetListSubDealerRequest, {
      search: `%${text}%`,
      region: _filterParams.region,
      branch: _filterParams.branch,
      pagination: this.pagination
    });
  }
}
