import { IBanners } from 'root/models';
import { crudMutations, CRUDState, ICRUDState } from 'root/store/helpers';
import { BannersMutationType } from './types';

export interface IBannerState extends ICRUDState<IBanners> {
  config: any[];
  configPagination: any;
}

export const defaultState: IBannerState = {
  ...CRUDState(),
  config: [],
  configPagination: {
    page: 1,
    limit: 10
  }
};

export const mutations = {
  ...crudMutations('banners'),
  [BannersMutationType.SetConfiguration](state: IBannerState, data) {
    state.config = data.system_configurations;
    state.configPagination.total = data.system_configurations_aggregate.aggregate.count;
  },
  [BannersMutationType.ConfigPaginationChange](state: IBannerState, data) {
    state.configPagination = data;
  },
};
