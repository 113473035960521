import { cloneDeep, find } from 'lodash';
import { ActionTypeExample } from 'root/admin/WelcomePage/Store/types';
import { ruleRequired, sanitizeString } from 'root/helpers';
import { IProvince } from 'root/models';
import { IState } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import { mapState } from 'vuex';
import './styles.scss';

@Component({
  template: require('./view.html'),
  props: {
    address: Object,
    prefix: {
      type: String,
      default: 'address'
    },
    required: {
      type: Boolean,
      default: true
    },
    disabled: Boolean,
    rows: {
      type: Number,
      default: 3
    }
  },
  computed: {
    ...mapState({
      localVN: (state: IState) => state.global.localVN,
    })
  }
})

export class AddressDealer extends Vue {

  public get rules() {

    return {
      required: ruleRequired(),
    };
  }

  public get getCurrentDistrict() {
    const _province = this.$props.address ? this.$props.address.province : null;

    if (!_province) {
      return [];
    }
    const province = find(cloneDeep(this.localVN), (e) => Number(e.matp) === Number(_province));

    return province
      ? province.child.filter((e) => sanitizeString(e.name).includes(sanitizeString(this.textFilterDistrict)))
      : [];
  }

  public get getCurrentWards() {
    const districtId = this.$props.address ? this.$props.address.district : null;
    if (!districtId) {
      return [];
    }
    const districts = find(this.getCurrentDistrict, (e) => Number(e.maqh) === Number(districtId));

    return districts
      ? districts.child.filter((e) => sanitizeString(e.name).includes(sanitizeString(this.textFilterWard)))
      : [];
  }

  public get cloneLocalVN() {
    return this.localVN.filter((e) => sanitizeString(e.name).includes(sanitizeString(this.textFilterProvince)));
    // return [];
  }
  public localVN: IProvince[];
  public textFilterProvince: string = '';
  public textFilterDistrict: string = '';
  public textFilterWard: string = '';
  public addressFormData: any = {};

  public changeProvince(value: any) {
    this.$emit('update:address', {
      province: value,
      district: null,
      ward: null
    });
    this.onChange();
  }

  public changeDistrict(value: any) {
    this.$emit('update:address', {
      ...this.addressFormData,
      ward: null,
      district: value
    });
    this.onChange();
  }

  public onChange() {
    this.textFilterWard = '';
    this.textFilterDistrict = '';
    this.textFilterProvince = '';
    this.$emit('change');
  }
  protected mounted() {
    this.$store.dispatch(ActionTypeExample.GetAdministration);
  }

  @Watch('address', { immediate: true })
  private onAddressChange(val) {
    this.addressFormData = !val ? {} : val;
  }

}
