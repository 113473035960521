import { DocumentNode } from 'graphql';
import gql from 'graphql-tag';
import { crudQuery, ICRUDQuery } from '../Core/query';

const productModel = `
customerId
dealerId
description
deviceType {
  id
  name
}
id
image
name
modelNumber
productCode
productStatus
receiptId
serialNumber
deviceTypeId
createdAt
createdDealer {
  id
  name
}
updatedAt
`;
const productRegistationModel = `
  id
  address
  name
  dealerCode
  shortName
  proshopType {
    name
    alias
  }
  branch {
    id
    name
    branchCode
  }
  group {
    id
    name
  }
  email
  countWarrantyFails
  countWarrantySuccesses
  success: warranties_aggregate(where: {warrantyStatus: {_eq: "activated"}}) {
    aggregate {
      count(columns: id)
    }
  }
  failed: warranties_aggregate(where: {warrantyStatus: {_eq: "failed"}}) {
    aggregate {
      count(columns: id)
    }
  }
`;
const warrantyModel= `
  createdDealer {
    name
    dealerCode
    address
    branch {
      id
      name
    }
    group {
      id
      name
    }
  }
  serialNumber
  warrantyStatus
  createdAt
  modelNumber
  id
  createdBy
  transactions (order_by: {createdAt:asc}) {
    isEnded
    dealer {
      name
      dealerCode
      ownerName
      role {
        alias
      }
    }
  }
`;

export const Products = 'products';
// tslint:disable-next-line:no-empty-interface
interface IProductQuery extends ICRUDQuery {
  GET_PRODUCT_LIST: DocumentNode;
  UPDATE_PRODUCT_STATUS: DocumentNode;
  CREATE_PRODUCT: DocumentNode;
  UPDATE_SCAN_PRODUCT_MODE: DocumentNode;
  GET_SCAN_PRODUCT_MODE: DocumentNode;
  UPDATE_DESCRIPTION_SERVICE_TYPE: DocumentNode;
  GET_LIST_PRODUCT_REGISTATION: DocumentNode;
  GET_LIST_PRODUCT_WARRANTY: DocumentNode;
  GET_LIST_PRODUCT_WARRANTY_DEPARTMENT: DocumentNode;
  GET_LIST_PRODUCT_WARRANTY_BRANCH: DocumentNode;
  GET_LIST_PRODUCT_WARRANTY_ALL: DocumentNode;
  GET_DEALER_PRODUCT_DETAIL: DocumentNode;
  GET_DEALER_PRODUCT_WARRANTY: DocumentNode;
  GET_PRODUCT_DETAIL: DocumentNode;
  GET_DEVICE_TYPE: DocumentNode;
  GET_CONFIGURATION: DocumentNode;
  GET_LIST_PRODUCT_REGISTATION_SUB_DEALER: DocumentNode;
  GET_ALL_CONFIGURATION: DocumentNode;
  GET_STATISTIC: DocumentNode;
}
export const productListQuery: IProductQuery = {
  ...crudQuery(Products, productModel),
  GET_PRODUCT_LIST: gql`
  query GET_PRODUCT_LIST {
    products{
      ${productModel}
    }
  }
`,
  UPDATE_PRODUCT_STATUS: gql`
  mutation UPDATE_PRODUCT_STATUS($id: uuid!, $status: String!) {
    update_products( where: {id: {_eq: $id}}, _set: {status: $status}){
      affected_rows
    }
  }
`,
  CREATE_PRODUCT: gql`
  mutation CREATE_PRODUCT($form : FormCreateDealer!) {
    createDealer(form: $form){
      id
      name
    }
  }
`,
  UPDATE_SCAN_PRODUCT_MODE: gql`
  mutation UPDATE_SCAN_PRODUCT_MODE($value: String!){
    update_system_configurations(where: {variableName: {_eq: "SCAN_PRODUCT_MODE"}}, _set: {value: $value}) {
      affected_rows
    }
  }
  `,
  GET_SCAN_PRODUCT_MODE: gql`
  query GET_SCAN_PRODUCT_MODE{
    system_configurations(where: {status: {_eq: "active"}}) {
      id
      name
      value
      variableName
      description
    }
  }
  `,
  UPDATE_DESCRIPTION_SERVICE_TYPE: gql`
  mutation UPDATE_DESCRIPTION_SERVICE_TYPE($variableName: String!, $description: String!){
    update_system_configurations(where: {variableName: {_eq: $variableName}}, _set: {description: $description}) {
      affected_rows
    }
  }
  `,
  GET_LIST_PRODUCT_REGISTATION: gql`
  query GET_LIST_PRODUCT_REGISTATION($limit:Int, $branch: [uuid!] , $order:[dealers_order_by!],
    $offset: Int, $search: String) {
    dealers(where: {role: {alias: {_eq: "daikin_dealer"}}, _and: [{branch: {id: {_in: $branch}}},
      {_or: [{name: {_ilike: $search}}, {shortName: {_ilike: $search}},
      {dealerCode: {_ilike: $search}}, {email: {_ilike: $search}}]}]},
    offset: $offset, limit: $limit, order_by: $order) {
      ${productRegistationModel}
    }
    dealers_aggregate (where: {role: {alias: {_eq: "daikin_dealer"}}, _and: [{branch: {id: {_in: $branch}}},
      {_or: [{name: {_ilike: $search}}, {shortName: {_ilike: $search}}, {dealerCode: {_ilike: $search}}]}]}) {
        aggregate {
          count
        }
    }
  }
  `,
  GET_LIST_PRODUCT_WARRANTY: gql`
  query GET_LIST_PRODUCT_WARRANTY($limit:Int, $branch: [uuid!], $department: [uuid!],
     $status: [String!], $offset: Int, $search: String) {
      warranties(where: {_and: [{createdDealer:{branch: {id: {_in: $branch}}}},
        {createdDealer:{group: {id: {_in: $department}}}},{warrantyStatus: {_in: $status}},
        {_or: [{createdDealer: {name: {_ilike: $search}}},{createdDealer: {dealerCode: {_ilike: $search}}},
        {modelNumber:{_ilike: $search}}, {serialNumber:{_ilike: $search}}]}]},
        order_by: {createdAt: desc}, offset: $offset, limit: $limit) {
        ${warrantyModel}
      }
      warranties_aggregate(where: {_and: [{createdDealer:{branch: {id: {_in: $branch}}}},
        {createdDealer:{group: {id: {_in: $department}}}},{warrantyStatus: {_in: $status}},
        {_or: [{createdDealer: {name: {_ilike: $search}}},{createdDealer: {dealerCode: {_ilike: $search}}},
        {modelNumber:{_ilike: $search}}, {serialNumber:{_ilike: $search}}]}]}) {
        aggregate {
          count
        }
      }
  }
  `,
  GET_LIST_PRODUCT_WARRANTY_DEPARTMENT: gql`
  query GET_LIST_PRODUCT_WARRANTY($limit:Int, $department: [uuid!],
     $status: [String!], $offset: Int, $search: String) {
      warranties(where: {_and: [
        {createdDealer:{group: {id: {_in: $department}}}},{warrantyStatus: {_in: $status}},
        {_or: [{createdDealer: {name: {_ilike: $search}}},{createdDealer: {dealerCode: {_ilike: $search}}},
        {modelNumber:{_ilike: $search}}, {serialNumber:{_ilike: $search}}]}]},
        order_by: {createdAt: desc}, offset: $offset, limit: $limit) {
        ${warrantyModel}
      }
      warranties_aggregate(where: {_and: [
        {createdDealer:{group: {id: {_in: $department}}}},{warrantyStatus: {_in: $status}},
        {_or: [{createdDealer: {name: {_ilike: $search}}},{createdDealer: {dealerCode: {_ilike: $search}}},
        {modelNumber:{_ilike: $search}}, {serialNumber:{_ilike: $search}}]}]}) {
        aggregate {
          count
        }
      }
  }
  `,
  GET_LIST_PRODUCT_WARRANTY_BRANCH: gql`
  query GET_LIST_PRODUCT_WARRANTY($limit:Int, $branch: [uuid!],
     $status: [String!], $offset: Int, $search: String) {
      warranties(where: {_and: [{createdDealer:{branch: {id: {_in: $branch}}}},{warrantyStatus: {_in: $status}},
        {_or: [{createdDealer: {name: {_ilike: $search}}},{createdDealer: {dealerCode: {_ilike: $search}}},
        {modelNumber:{_ilike: $search}}, {serialNumber:{_ilike: $search}}]}]},
        order_by: {createdAt: desc}, offset: $offset, limit: $limit) {
        ${warrantyModel}
      }
      warranties_aggregate(where: {_and: [{createdDealer:{branch: {id: {_in: $branch}}}},
        {warrantyStatus: {_in: $status}},
        {_or: [{createdDealer: {name: {_ilike: $search}}},{createdDealer: {dealerCode: {_ilike: $search}}},
        {modelNumber:{_ilike: $search}}, {serialNumber:{_ilike: $search}}]}]}) {
        aggregate {
          count
        }
      }
  }
  `,
  GET_LIST_PRODUCT_WARRANTY_ALL: gql`
  query GET_LIST_PRODUCT_WARRANTY($limit:Int, $status: [String!], $offset: Int, $search: String) {
      warranties(where: {_and: [{warrantyStatus: {_in: $status}},
        {_or: [{createdDealer: {name: {_ilike: $search}}},{createdDealer: {dealerCode: {_ilike: $search}}},
        {modelNumber:{_ilike: $search}}, {serialNumber:{_ilike: $search}}]}]},
        order_by: {createdAt: desc}, offset: $offset, limit: $limit) {
        ${warrantyModel}
      }
      warranties_aggregate(where: {_and: [{warrantyStatus: {_in: $status}},
        {_or: [{createdDealer: {name: {_ilike: $search}}},{createdDealer: {dealerCode: {_ilike: $search}}},
        {modelNumber:{_ilike: $search}}, {serialNumber:{_ilike: $search}}]}]}) {
        aggregate {
          count
        }
      }
  }
  `,
  GET_DEALER_PRODUCT_DETAIL: gql`
  query GET_DEALER_PRODUCT_DETAIL($id: uuid!) {
    dealers(where: {id: {_eq: $id}}) {
      id
      email
      dealerCode
      createdAt
      address
      shortName
      name
      updatedBy
      updatedAt
      createdUser {
        id
        name
      }
      updatedUser {
        id
        name
      }
      group {
        id
        name
      }
      branch {
        id
        name
      }
      proshopType {
        name
      }
      success: warranties_aggregate(where: {warrantyStatus: {_eq: "activated"}}) {
        aggregate {
          count(columns: id)
        }
      }
      failed: warranties_aggregate(where: {warrantyStatus: {_eq: "failed"}}) {
        aggregate {
          count(columns: id)
        }
      }
      RA: warranties_aggregate(where: {deviceType : { code : {_eq: "RA"}}}) {
        aggregate {
          count(columns: id)
        }
      }
      SKY: warranties_aggregate(where: {deviceType : { code : {_eq: "SKY"}}}) {
        aggregate {
          count(columns: id)
        }
      }
      ACL: warranties_aggregate(where: {deviceType : { code : {_eq: "ACL"}}}) {
        aggregate {
          count(columns: id)
        }
      }
      MULTI: warranties_aggregate(where: {deviceType : { code : {_eq: "MULTI"}}}) {
        aggregate {
          count(columns: id)
        }
      }
      reason: warranties_aggregate(where: { error : { _is_null: false}}) {
        aggregate {
          count(columns: id)
        }
      }
      warranties {
        serialNumber
        warrantyStatus
        createdAt
        type
        modelNumber
        deviceType {
          id
          code
        }
      }
      warranties_aggregate {
        aggregate {
          count
        }
      }
    }
  }`,
  GET_DEALER_PRODUCT_WARRANTY: gql`
  query GET_DEALER_PRODUCT_WARRANTY($id: uuid!, $search: String!, $deviceType: [String!],
    $status: [String!], $offset: Int!, $limit: Int!) {
    warranties(where: {createdBy: {_eq: $id},_and:[{deviceType: {code:{_in:$deviceType}}},
    {warrantyStatus:{_in:$status}}, {_or:[{modelNumber:{_ilike:$search}},{serialNumber:{_ilike:$search}}]}]},
    order_by:{createdAt:desc}, limit: $limit, offset: $offset) {
      serialNumber
      warrantyStatus
      createdAt
      type
      id
      createdBy
      modelNumber
      deviceType {
        id
        code
        name
      }
      error
    }
    warranties_aggregate(where: {createdBy: {_eq: $id},_and:[{deviceType: {code:{_in:$deviceType}}},
      {warrantyStatus:{_in:$status}}, {_or:[{modelNumber:{_ilike:$search}},{serialNumber:{_ilike:$search}}]}]}) {
      aggregate {
        count
      }
    }
  }
  `,
  GET_PRODUCT_DETAIL: gql`
  query GET_DEALER_PRODUCT_WARRANTY($id: uuid!) {
    warranties(where: {id: {_eq: $id}}) {
      serialNumber
      warrantyStatus
      createdAt
      updatedAt
      type
      error
      modelNumber
      deviceType {
        id
        code
        name
      }
      createdDealer {
        id
        name
      }
      updatedDealer {
        id
        name
      }
      transactions (order_by: {createdAt: desc}) {
        createdAt
        register {
          name
        }
        dealer {
          name
          dealerCode
          shortName
          role {
            alias
          }
        }
      }
    }
  }
  `,
  GET_DEVICE_TYPE: gql`
  query GET_DEVICE_TYPE {
    device_type(where: {status: {_eq: "active"}}) {
      name
      id
      code
    }
  }`,
  GET_CONFIGURATION: gql`
  subscription GET_SCAN_PRODUCT_MODE{
    system_configurations(where: {status: {_eq: "active"}}) {
      id
      name
      value
      variableName
      description
    }
  }
  `,
  GET_LIST_PRODUCT_REGISTATION_SUB_DEALER: gql`
  query GET_LIST_PRODUCT_REGISTATION_SUB_DEALER($limit:Int, $branch: [uuid!] , $order:[dealers_order_by!],
    $offset: Int, $search: String) {
    dealers(where: {role: {alias: {_eq: "sub_dealer"}}, _and: [{branch: {id: {_in: $branch}}},
      {_or: [{name: {_ilike: $search}}, {shortName: {_ilike: $search}},
      {dealerCode: {_ilike: $search}}, {email: {_ilike: $search}}]}]},
    offset: $offset, limit: $limit, order_by: $order) {
      ${productRegistationModel}
      assignedSubDealers(where: {status: {_eq: "active"}}) {
        dealer {
          name
          dealerCode
        }
      }
    }
    dealers_aggregate (where: {role: {alias: {_eq: "sub_dealer"}}, _and: [{branch: {id: {_in: $branch}}},
      {_or: [{name: {_ilike: $search}}, {shortName: {_ilike: $search}},
      {dealerCode: {_ilike: $search}}, {email: {_ilike: $search}}]}]}) {
        aggregate {
          count
        }
    }
  }
  `,
  GET_ALL_CONFIGURATION: gql`
  query GET_ALL_CONFIGURATION($limit: Int, $offset: Int) {
    system_configurations(where: {status: {_eq: "active"}}, offset: $offset, limit: $limit) {
      id
      name
      value
      variableName
      description
    }
    system_configurations_aggregate(where: {status: {_eq: "active"}}) {
      aggregate {
        count
      }
    }
  }
  `,
  GET_STATISTIC: gql`
  query GET_STATISTIC {
    labelBranch: branches(where: {isTest:{_eq: false},status: {_eq: "active"}}) {
      id
      name
    }
  }
  `,
};
