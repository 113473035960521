import { ElUpload } from 'element-ui/types/upload';
import Vue from 'vue';
import Component from 'vue-class-component';
import './styles.scss';

@Component({
  template: require('./view.html'),
  props: {
    disabled: Boolean,
    loading: Boolean,
    btnClass: String,
    icon: String,
    maxLength: Number,
    multiple: {
      type: Boolean,
      default: false
    },
    textUpload: {
      type: Boolean,
      default: false
    },
    placeHolderImg: {
      type: Boolean,
      default: false
    },
    customWidth: Number,
    customHeight: Number
  }
})

export class Upload extends Vue {
  public $refs: {
    elUpload: ElUpload
  };
  public handleUpload(file: File) {
    this.$emit('handleUpload', file);
  }
  public onRemove(arg) {
    console.log('onRemove', arg);
  }
}
