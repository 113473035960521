import {
  notificationGqlService,
  INotificationGqlService
} from './Notification';
import { teamStaffGqlService, ITeamStaffService } from './TeamStaff';
import { accountStaffGqlService, IAccountStaffGqlService } from './accountStaff';
import { aclCampaignGqlService, IAclCampaignGqlService } from './aclCampaign';
import { IProductWarrantyGqlService, ProductWarrantyGqlService } from './activeEWarranty';
import { bannerGqlService, IBannerGqlService } from './banner';
import { BranchGqlService, IBranchGqlService } from './branch';
import {
  BranchManageGqlService,
  IBranchManageGqlService
} from './branchManage';
import { CampaignGqlService, ICampaignGqlService } from './campaign';
import { conditionGqlService, IConditionGqlService } from './condition';
import { configurationGqlService, IConfigurationService } from './configuration';
import { CustomCampaignGqlService, ICustomCampaignGqlService } from './customCampaign';
import { dealerGqlService, IDealerGqlService } from './dealer';
import { dealerShopGqlService, IDealerShopGqlService } from './dealerShop';
import { dealerTypeGqlService, IDealerTypeGqlService } from './dealerType';
import { DeviceTypesGqlService, IDeviceTypesGqlService } from './deviceType';
import { exampleGqlService, IExampleGqlService } from './example';
import { feedbackGqlService, IFeedbackGqlService } from './feedback';
import {
  groupGqlService,
  groupSystemGqlService,
  IGroupGqlService,
  IGroupSystemGqlService
} from './group';
import { GroupManageGqlService, IGroupManageGqlService } from './groupManage';
import { invoiceGqlService, IInvoiceGqlService } from './invoice';
import { manageImportGqlService, IManageImportGqlService } from './manageImport';
import { newRACampaignGqlService, INewRACampaignCampaignGqlService } from './newRACampaign';
// import { GroupManageGqlService } from './groupManage';
import { pointGqlService, IPointGqlService } from './point';
import { productGqlService, IProductGqlService } from './product';
import {
  productTransGqlService,
  IProductTransGqlService
} from './productTrans';
import { raCampaignGqlService, IRACampaignGqlService } from './raCampaign';
import { IRedemptionGqlService, RedemptionGqlService } from './redemption';
import { IRegionsGqlService, RegionsGqlService } from './region';
import { roleGqlService, IRoleGqlService } from './role';
import { saleInCampaignGqlService, ISaleInCampaignGqlService } from './saleInCampaign';
import { transactionViewGqlService, ITransactionViewGqlService } from './transactionView';
import { userGqlService, IUserGqlService } from './user';
import { zeroCampaignGqlService, IZeroCampaignGqlService } from './zeroCampaign';

export interface IGqlClient {
  example: IExampleGqlService;
  user: IUserGqlService;
  role: IRoleGqlService;
  group: IGroupGqlService;
  systemGroup: IGroupSystemGqlService;
  dealer: IDealerGqlService;
  dealerShop: IDealerShopGqlService;
  dealerType: IDealerTypeGqlService;
  accountStaff: IAccountStaffGqlService;
  branch: IBranchGqlService;
  product: IProductGqlService;
  productTrans: IProductTransGqlService;
  notification: INotificationGqlService;
  banner: IBannerGqlService;
  points: IPointGqlService;
  deviceType: IDeviceTypesGqlService;
  branchManage: IBranchManageGqlService;
  groupManage: IGroupManageGqlService;
  redemption: IRedemptionGqlService;
  region: IRegionsGqlService;
  campaign: ICampaignGqlService;
  aclCampaign: IAclCampaignGqlService;
  raCampaign: IRACampaignGqlService;
  zeroCampaign: IZeroCampaignGqlService;
  condition: IConditionGqlService;
  invoice: IInvoiceGqlService;
  manageImport: IManageImportGqlService;
  feedback: IFeedbackGqlService;
  transactionView: ITransactionViewGqlService;
  productEWarranty: IProductWarrantyGqlService;
  teamStaff: ITeamStaffService;
  customCampaign: ICustomCampaignGqlService;
  configuration: IConfigurationService;
  newRaCampagin: INewRACampaignCampaignGqlService;
  saleInCampaign: ISaleInCampaignGqlService;
}
export function DKGqlClient(): IGqlClient {
  return {
    example: exampleGqlService(),
    user: userGqlService(),
    role: roleGqlService(),
    group: groupGqlService(),
    systemGroup: groupSystemGqlService(),
    dealer: dealerGqlService(),
    dealerShop: dealerShopGqlService(),
    accountStaff: accountStaffGqlService(),
    feedback: feedbackGqlService(),
    dealerType: dealerTypeGqlService(),
    banner: bannerGqlService(),
    branch: BranchGqlService(),
    product: productGqlService(),
    productTrans: productTransGqlService(),
    points: pointGqlService(),
    deviceType: DeviceTypesGqlService(),
    branchManage: BranchManageGqlService(),
    notification: notificationGqlService(),
    groupManage: GroupManageGqlService(),
    redemption: RedemptionGqlService(),
    region: RegionsGqlService(),
    campaign: CampaignGqlService(),
    condition: conditionGqlService(),
    invoice: invoiceGqlService(),
    manageImport: manageImportGqlService(),
    transactionView: transactionViewGqlService(),
    productEWarranty: ProductWarrantyGqlService(),
    teamStaff: teamStaffGqlService(),
    customCampaign: CustomCampaignGqlService(),
    configuration: configurationGqlService(),
    aclCampaign: aclCampaignGqlService(),
    raCampaign: raCampaignGqlService(),
    zeroCampaign: zeroCampaignGqlService(),
    newRaCampagin: newRACampaignGqlService(),
    saleInCampaign: saleInCampaignGqlService(),
  };
}
