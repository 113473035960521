import { Calendar } from 'dayspan';
import { IBooking } from './Booking';
import { StatusCode } from './Common';
import { ITechnician } from './Technician';
import { ISimpleUser } from './User';

export interface IStation {
  id?: string;
  status?: StatusCode;
  createdAt?: string;
  updatedAt?: string;
  createdBy?: string;
  updatedBy?: string;
  name: string;
  description: string;
  address: string;
  code: string;
  headOfStations?: ISimpleUser[];
}
export function stationDefault(): IStation {
  return {
    name: '',
    description: '',
    address: '',
    code: ''
  };
}
export interface IWorkingEventProps {
  calendarEvent: any;
  calendar: Calendar<any, any>;
  edit: () => void;
  details: object;
  close: () => void;
}
export interface IWorkingEventForm {
  weekNumber?: number;
  firstDayOff?: string;
  firstDayOffDescription?: string;
  secondDayOff?: string;
  secondDayOffDescription?: string;
  technicianId: string;
  date?: string;
  description?: string;
  year?: string;
  id?: string;
  type?: string;
}
export function workingEventFormDefault(): IWorkingEventForm {
  return {
    firstDayOff: '',
    firstDayOffDescription: '',
    secondDayOff: '',
    secondDayOffDescription: '',
    technicianId: '',
    weekNumber: null,
    date: '',
    type: 'Day Off'
  };
}

export interface IFilterWorkingScheduleForm {
  filter: {
    partnerId: string;
    weekNumber: number;
    year: number;
    technicianName?: string;
  };
  pagination: {
    size: number;
    nextPageToken?: string;
  };
}
export interface IWorkingSchedule {
  total: Array<{
    assigned: number;
    partnerAssigning: number;
    pendingService: number;
  }>;
  pagination: {
    size: number;
    nextPageToken?: string;
  };
  data: Array<{
    technician: ITechnician;
    items: Array<{
      bookings: IBooking[],
      technicianDayOff: IDayOff,
      technicianWorkingShift: IWorkingShift;
    }>;
  }>;
}
export enum WorkingDayType {
  DayOff = 'dayOff',
  WorkingShift = 'workingShift'
}
export enum DayOffType {
  SecondDayOff = 'second_day_off',
  FirstDayOff = 'first_day_off'
}
export interface IWorkingShift {
  weekday: number;
  from: string;
  to: string;
}
export interface IDayOff {
  weekday: number;
  type: DayOffType;
  from: string;
  to: string;
}
