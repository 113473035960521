// import { stripObject } from '@hgiasac/helper';
import { Form } from 'element-ui';
import { cloneDeep, omit } from 'lodash';
import moment from 'moment-timezone';
// import { ActionTypeBranchManage } from 'root/admin/BranchAndGroup/BranchManage/Store/types';
// import { ActionTypeGroupManage } from 'root/admin/BranchAndGroup/GroupManage/Store/types';
import { ActionTypeRole } from 'root/admin/Role/Store/types';
import { SystemRoleAlias } from 'root/admin/User/Store/types';
import { Avatar } from 'root/components';
import { AddressDealer } from 'root/components/AddressDealer';
import { Upload } from 'root/components/Upload';
import {
  formatterPhoneNumber,
  ruleEmail,
  ruleEmailNoRequired,
  ruleMinLength,
  ruleNumber,
  rulePhoneNumber,
  ruleRequired,
} from 'root/helpers';
import {subDealerFormDefault, ISubDealerForm } from 'root/models/Dealer';
import { IPartner } from 'root/models/Partner';
import {
  groupSystemUserSelection,
  EGender,
  ESystemGroup,
  IUser
} from 'root/models/User';
import { IState, MutationType, TypeAlert } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapGetters, mapState } from 'vuex';
import { ActionTypeDealer, TypeRole } from '../Store/types';
import './styles.scss';

const genderOptions = [
  {
    value: EGender.Male,
    label: EGender.Male
  },
  {
    value: EGender.Female,
    label: EGender.Female
  }
];

@Component({
  template: require('./view.html'),
  components: {
    Upload,
    avatar: Avatar,
    'address-dealer': AddressDealer
  },
  props: {
    visible: Boolean,
    userId: String,
    fromCallCenter: Boolean
  },
  computed: {
    ...mapGetters(['branch', 'group']),
    ...mapState({
      authUser: (state: IState) => state.global.authUser,
      userDetailLoading: () => false,
      branch: (state: IState) => state.branchManage.data,
      group: (state: IState) => state.groupManage.data,
      role: (state: IState) => state.role.data,
      isRefesh: (state: IState) => state.global.isRefesh,
      bankInfo: (state: IState) => state.dealer.bankInfo,
    }),
    cloneBranchOptions() {
      const branch: any[] = cloneDeep(this.branch);

      return branch.map((e) => {
        return {
          label: e.name,
          value: e.id
        };
      });
    },
    BankNameOptions() {
      const bank: any[] = cloneDeep(this.bankInfo);

      return bank.map((e) => {
        return {
          label: `${e.code} - ${e.name}`,
          value: `${e.code} - ${e.name}`
        };
      });
    },
    cloneRoleOptions() {
      const role: any[] = cloneDeep(this.role);
      const roleDeale = role.filter(
        (it: any) =>
          it.systemGroup && it.systemGroup.name &&
          it.systemGroup.name === ESystemGroup.Dealer &&
          it.name !== ESystemGroup.Freelancer &&
          it.name !== ESystemGroup.Guest
      );

      return roleDeale.map((e) => {
        return {
          label: e.name,
          value: e.id,
          disabled: e.name === ESystemGroup.SubDealer || e.name === ESystemGroup.Technician
        };
      });
    },
    cloneGroupOptions() {
      let groups: any[] = cloneDeep(this.group);
      if (this.selectedBranch) {
        groups = groups.filter((it) => it.branch && it.branch.id === this.selectedBranch);
      }

      return this.selectedBranch
        ? groups.map((e) => {
          return {
            label: e.name,
            value: e.id
          };
        })
        : [];
    },
    cloneStations() {
      // if (this.isSystemUser && this.form.permission === 'head_of_station') {
      //   return this.stations.filter((e) => !this.stationIdsHaveHeader.includes(e.id));
      // }

      return this.stations;
    }
  }
})
export class SubDealerEditor extends Vue {
  public get isUpdating(): boolean {
    return !!this.userId;
  }
  public get checkUpdateOwnerName() {
    if (this.authUser.superAdmin || this.authUser.role.alias === SystemRoleAlias.Admin
      || this.authUser.role.alias === SystemRoleAlias.SaleAdmin) {
      return true;
    }

    return false;
  }

  public get rules() {
    return {
      required: ruleRequired(),
      rulePhoneNumber: rulePhoneNumber(),
      min: ruleMinLength(8),
      ruleEmail: ruleEmail(),
      ruleEmailNoRequired: ruleEmailNoRequired(),
      number: ruleNumber()
    };
  }

  public $refs: {
    form: Form;
  };
  public loading: boolean = false;
  public authUser: IUser;
  public userDetail: IUser;
  public roleOptions: any[];
  public role: any;
  public form: ISubDealerForm = subDealerFormDefault();
  public groupSystemUserSelection = groupSystemUserSelection();
  public stationGroupOptions = groupSystemUserSelection();
  public fileUpload: File = null;
  public detail: IUser;
  public route: any;
  public userId: string;
  public businessLicense: string = '';
  public houseHoldLicense: string = '';
  public identity: string = '';
  public technicianRates: any;
  public partners: IPartner[];
  public partnerHaveCoordinator: string[] = [];
  public validateTechnicianCode: {
    status?: string;
    message?: string;
  } = null;
  public genderOptions = genderOptions;
  public isDealer: boolean = true;
  public cloneRoleOptions: any[];
  public isSub: boolean = false;
  public addressText = '';
  public isRefesh: boolean;
  public selectedBranch = '';
  public isShowPass: boolean = false;
  public isTechnician: boolean = false;

  public changeForm() {
    //
  }
  public remoteMethodService(query: string) {
    this.addressText = query;
  }
  public onChangeBranch(value: string) {
    this.selectedBranch = value;
  }

  public changeType(key: string) {
    const isSub = this.cloneRoleOptions.filter((it) => it.value === key);
    if (isSub && isSub.length && isSub[0].label === TypeRole.SubDealer) {
      return (this.isDealer = false);
    }

    return (this.isDealer = true);
  }

  public handleShowPass() {
    this.isShowPass = !this.isShowPass;
  }
  public confirmClose() {
    this.$msgbox({
      title: this.$t('confirm').toString(),
      message: this.$t('action_confirm_message').toString(),
      showCancelButton: true,
      confirmButtonText: this.$t('confirm').toString(),
      cancelButtonText: this.$t('cancel').toString(),
      beforeClose: (action, _instance, done) => {
        if (action === 'confirm') {
          this.closeDialog();
          done();
        } else {
          done();
        }

        return;
      }
    });
  }
  public async submit() {
    this.$refs.form.validate(async (valid) => {
      if (valid) {
        // this.loading = true;
        // tslint:disable-next-line: whitespace
        let form = <any>cloneDeep(this.form);
        const { phoneNumber } = this.form;

        if (phoneNumber) {
          form.phoneNumber = formatterPhoneNumber(phoneNumber);
        }

        form = omit(form, ['createdUser', 'updatedUser']);
        if (this.userId) {
          form.updatedBy = this.authUser.id;
        }
        this.handleSystemUser(form);
      }

      return;
    });
  }

  public back() {
    this.$emit('update:visible', false);
  }
  public async openDialog() {
    if (this.userId) {
      const _form = this.$store.getters.getSubDealerDetail(this.userId);
      this.form = cloneDeep(_form);
      this.businessLicense = _form.information.businessRegistrationNo;
      this.houseHoldLicense = _form.information.businessHouseholdNo;
      this.identity = _form.idCard;
      this.isTechnician = false;
    }
    this.$store.dispatch(ActionTypeDealer.GetBankInfo, {
      onSuccess: (bankInFo) => {
        console.log(bankInFo);
      }
    });

    // setTimeout(() => {
    //   if (this.$refs.form) {
    //     this.$refs.form.clearValidate();
    //   }
    // }, 0);

  }
  public closeDialog() {
    this.form = subDealerFormDefault();
    this.addressText = '';
    this.isDealer = true;
    this.back();
  }

  public formatterExpiredDate(date) {
    return date ? moment(date).utc().format('DD/MM/YYYY') : null;
  }

  protected mounted() {
    if (!this.isRefesh) {

      this.$store.dispatch(ActionTypeRole.FilterNoCache);
    }

    return;
  }

  private async handleSystemUser(form: any) {
    const updateForm = {
      name: form.name,
      dealerCode: form.dealerCode,
      ownerName: form.ownerName,
      address: form.address,
      bankName: form.bankName,
      bankBranch: form.bankBranch,
      accountNumber: form.accountNumber,
      accountName: form.accountName,
      idCard: this.identity,
    };
    if (this.userId && this.checkUpdateOwnerName) {
      const _form = updateForm;
      this.$store.dispatch(ActionTypeDealer.UpdateSubDealer, {
        set: {
          ..._form,
          updatedBy: this.authUser.id
        },
        businessLicense: this.businessLicense,
        houseHoldLicense: this.houseHoldLicense,
        id: this.userId,
        onSuccess: () => {
          this.loading = false;
          const message = 'Update Sub Dealer successfully';
          this.$store.commit(MutationType.OpenTopAlert, {
            message,
            type: TypeAlert.Success
          });
          this.closeDialog();
          this.$emit('editSuccess');
          // this.$emit('updateSuccess');
        },
        onFailure: () => {
          this.loading = false;
        }
      });
    } else if (this.userId && !this.checkUpdateOwnerName) {
      const _form = updateForm;
      this.$store.dispatch(ActionTypeDealer.UpdateDealer, {
        set: {
          ..._form,
          updatedBy: this.authUser.id
        },
        id: this.userId,
        onSuccess: () => {
          this.loading = false;
          const message = 'Update Sub Dealer successfully';
          this.$store.commit(MutationType.OpenTopAlert, {
            message,
            type: TypeAlert.Success
          });
          this.closeDialog();
          this.$emit('editSuccess');
          // this.$emit('updateSuccess');
        },
        onFailure: () => {
          this.loading = false;
        }
      });
    } else {
      // create
      // this.$store.dispatch(ActionTypeDealer.CreateDealer, {
      //   form: {
      //     ...stripObject(postForm)
      //   },
      //   onSuccess: () => {
      //     this.loading = false;
      //     const message = 'Create Dealer successfully';
      //     this.$store.commit(MutationType.OpenTopAlert, {
      //       message,
      //       type: TypeAlert.Success
      //     });
      //     this.$store.dispatch(ActionTypeDealer.GetListDealer, {
      //       form: {
      //         filter: {},
      //         pagination: {
      //           size: 20,
      //           page: 1
      //         }
      //       }
      //     });
      //     this.closeDialog();
      //     // this.$emit('createSuccess');
      //   },
      //   onFailure: () => {
      //     this.loading = false;
      //   }
      // });
    }
  }
}
