import { IPaginationParams } from 'root/api/graphql/Core';
import { crudMutations, CRUDState, ICRUDState } from 'root/store/helpers';
import { MutationTypeAclCampaign } from './types';
// import { MutationTypeEvaluatePhoto } from './types';

export interface IAclCampaignState extends ICRUDState<any> {
  loading: boolean;
  manageExport: any;
  listQualifiedShop: any;
  listJoinedShop: any;
}

export const defaultState: IAclCampaignState = {
  ...CRUDState(),
  loading: false,
  manageExport: [],
  listQualifiedShop: [],
  listJoinedShop : []
};

export const mutations = {
  ...crudMutations('acl_campaigns'),
  [MutationTypeAclCampaign.SetAclCompaign](state: IAclCampaignState, data) {
    state.data = data.dealers;
    state.pagination.total = data.dealers_aggregate.aggregate.count;
  },
  [MutationTypeAclCampaign.SetAclManageExport](state: IAclCampaignState, data) {
    state.manageExport = data.export_acl_campaign_reports;
    state.pagination.total = data.export_acl_campaign_reports_aggregate.aggregate.count;
  },
  [MutationTypeAclCampaign.SetAclCompaignDetail](state: IAclCampaignState, data) {
    state.data = data.dealers;
    state.pagination.total = data.dealers[0].shops_aggregate.aggregate.count;
  },
  [MutationTypeAclCampaign.SetPagination](state: IAclCampaignState, pagination: IPaginationParams) {
    state.pagination.limit = pagination.limit;
    state.pagination.page = pagination.page;
  },
  [MutationTypeAclCampaign.SetQualifiedList](state: IAclCampaignState, data) {
    state.listQualifiedShop = data.acl_campaigns;
    state.pagination.total = data.acl_campaigns_aggregate.aggregate.count;
  },
  [MutationTypeAclCampaign.SetJoinedShopList](state: IAclCampaignState, data) {
    state.listJoinedShop = data.shops;
    state.pagination.total = data.shops_aggregate.aggregate.count;
  },
};
