export enum ActionTypeBranchManage {
  FilterNoCache = 'branch_managerFilterNoCache',
  BranchGetById = 'branch_managerGetById',
  Update = 'branch_managerUpdate',
  GetListBranch = 'branch_managerGetListBranch',
  GetListManager = 'branch_mamagerGetListManager',
  UpdateBranchManager = 'branch_managerUpdate',
  InsertBranch = 'branch_managerInsert',
  InsertInvoice = 'branch_managerInsertInvoice',
  UpdateStatus = 'branch_managerUpdateStatus',
  updateBranch = 'branch_managerUpdateBranch'
}
export enum MutationTypeBranchManager {
  FilterChange = 'branch_managerFilterChange',
  GetListBranch = 'branch_managerGetListBranch',
  PaginationChange = 'branch_managerPaginationChange',
  SetBranchManager = 'setBranchManager',
  SetManager = 'SetManager',
  BranchLoading = 'branch_managerLoading',
  BranchLoaded = 'branch_managerLoaded',
}
export enum BranchManageTab {
  BranchGroups = 'BranchGroups',
  GroupLeader = 'GroupLeader',
  ManagerBranch = 'ManagerBranch',
  DealerBranch = 'DealerBranch',
  TechnicianBranch = 'TechnicianBranch',
  BranchInventory = 'BranchInventory',
  BranchInvoice = 'BranchInvoice',
  BranchProduct = 'BranchProduct',
  ActiveEWarrantyBranch = 'BranchEWarranty',
  BranchTeamLeader = 'BranchTeamLeader',
  BranchStaff = 'BranchStaff'
}
