// import { stripObject } from '@hgiasac/helper';
import { stripObject } from '@hgiasac/helper';
import { Form } from 'element-ui';
import { cloneDeep, omit } from 'lodash';
import moment from 'moment-timezone';
// import { ActionTypeBranchManage } from 'root/admin/BranchAndGroup/BranchManage/Store/types';
// import { ActionTypeGroupManage } from 'root/admin/BranchAndGroup/GroupManage/Store/types';
import { ActionTypeRole } from 'root/admin/Role/Store/types';
import { DKGqlClient } from 'root/api/graphql/Client';
import { gqlClient } from 'root/api/graphql/Core';
import { dealerListQuery } from 'root/api/graphql/Query/dealer';
import { Avatar } from 'root/components';
import { AddressDealer } from 'root/components/AddressDealer';
import { Upload } from 'root/components/Upload';
import {
  // formatterPhoneNumber,
  resetOrientation,
  ruleCompare,
  ruleEmail,
  ruleEmailNoRequired,
  ruleMinLength,
  ruleNumber,
  rulePhoneNumber,
  ruleRequired,
  sanitizeString
} from 'root/helpers';
import { dealerFormDefault, IDealer, IDealerForm } from 'root/models/Dealer';
import { IPartner } from 'root/models/Partner';
import {
  groupSystemUserSelection,
  EGender,
  ESystemGroup,
  IUser
} from 'root/models/User';
import { pathUpload, sizeImage, uploadToFirebase } from 'root/services';
import { FirebaseAuthService } from 'root/services/auth/firebase';
import { IState, MutationType, TypeAlert } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapGetters, mapState } from 'vuex';
import { ActionTypeDealer, TypeRole } from '../../Store/types';
import './styles.scss';

const genderOptions = [
  {
    value: EGender.Male,
    label: EGender.Male
  },
  {
    value: EGender.Female,
    label: EGender.Female
  }
];

@Component({
  template: require('./view.html'),
  components: {
    Upload,
    avatar: Avatar,
    'address-dealer': AddressDealer
  },
  props: {
    visible: Boolean,
    userId: String,
    fromCallCenter: Boolean
  },
  computed: {
    ...mapGetters(['branch', 'group']),
    ...mapState({
      authUser: (state: IState) => state.global.authUser,
      userDetailLoading: () => false,
      branch: (state: IState) => state.branchManage.data,
      group: (state: IState) => state.groupManage.data,
      role: (state: IState) => state.role.data,
      isRefesh: (state: IState) => state.global.isRefesh,
      types: (state: IState) => state.dealer.dealerType,
    }),
    cloneBranchOptions() {
      const branch: any[] = cloneDeep(this.branch);

      return branch.map((e) => {
        return {
          label: e.name,
          value: e.id
        };
      });
    },
    cloneRoleOptions() {
      const role: any[] = cloneDeep(this.role);
      const roleDeale = role.filter(
        (it: any) =>
          it.systemGroup && it.systemGroup.name &&
          it.systemGroup.name === ESystemGroup.Dealer &&
          it.name !== ESystemGroup.Freelancer &&
          it.name !== ESystemGroup.Guest
      );

      return roleDeale.map((e) => {
        return {
          label: e.name,
          value: e.id,
          disabled: e.name === ESystemGroup.SubDealer || e.name === ESystemGroup.Technician
        };
      });
    },
    cloneStations() {
      // if (this.isSystemUser && this.form.permission === 'head_of_station') {
      //   return this.stations.filter((e) => !this.stationIdsHaveHeader.includes(e.id));
      // }

      return this.stations;
    },
    proshopType() {
      return this.types.filter((it) => it.type === 'proshop');
    },
    businessType() {
      return this.types.filter((it) => it.type === 'business');
    },
    potentialType() {
      return this.types.filter((it) => it.type === 'potential');
    }
  }
})
export class DealerEditor extends Vue {
  public get isUpdating(): boolean {
    return !!this.userId;
  }
  public get clonePartners() {
    const text = sanitizeString(this.querySearchPartner);

    return this.partners.filter((e) => sanitizeString(e.name).includes(text));
    // !this.partnerHaveCoordinator.includes(e.id) &&
  }
  public get cloneGroupOptions() {
    let groups: any[] = cloneDeep(this.group);
    if (this.selectedBranch) {
      groups = groups.filter((it) => it.branch && it.branch.id === this.selectedBranch);
    }

    return this.selectedBranch
    ? groups.map((e) => ({
      label: e.name,
      value: e.id
    }))
    : [];
  }
  public get rules() {
    return {
      required: ruleRequired(),
      rulePhoneNumber: rulePhoneNumber(),
      min: ruleMinLength(8),
      ruleEmail: ruleEmail(),
      ruleEmailNoRequired: ruleEmailNoRequired(),
      compare: ruleCompare(this.form.password),
      number: ruleNumber()
    };
  }

  public get updateEmailPermission() {
    if (this.authUser.superAdmin || this.authUser.role.alias === 'admin'
      || this.authUser.role.alias === 'sale_admin') {
      return false;
    }

    return true;
  }

  public $refs: {
    form: Form;
  };
  public loading: boolean = false;
  public authUser: IUser;
  public userDetail: IUser;
  public roleOptions: any[];
  public role: any;
  public form: IDealerForm = dealerFormDefault();
  public groupSystemUserSelection = groupSystemUserSelection();
  // public stationBranchOptions = stationBranchOptions();
  public stationGroupOptions = groupSystemUserSelection();
  public fileUpload: File = null;
  public detail: IUser;
  public route: any;
  public group;
  // public userType: UserType;
  public userId: string;
  public technicianRates: any;
  public partners: IPartner[];
  public partnerHaveCoordinator: string[] = [];
  public validateTechnicianCode: {
    status?: string;
    message?: string;
  } = null;
  public genderOptions = genderOptions;
  public isDealer: boolean = true;
  public cloneRoleOptions: any[];
  public isSub: boolean = false;
  public addressText = '';
  public isRefesh: boolean;
  public selectedBranch = '';
  public isShowPass: boolean = false;
  public isTechnician: boolean = false;
  public allDealers: IDealer[] = [];
  public emailExists: boolean = false;
  public phoneExists: boolean = false;

  private pathUploadAvatar: pathUpload = pathUpload.SYSTEM_USER;
  // private stationIdsHaveHeader: string[] = [];
  // private subscribe: any = null;
  // private subscribePartnerHaveCoordinator: Subscription = null;
  private querySearchPartner: string = '';

  public changeForm() {
    //
  }
  public remoteMethodService(query: string) {
    console.log(query);
    this.addressText = query;
  }
  public onChangeBranch(value: string) {
    this.selectedBranch = value;
  }

  public changeType(key: string) {
    const isSub = this.cloneRoleOptions.filter((it) => it.value === key);
    if (isSub && isSub.length && isSub[0].label === TypeRole.SubDealer) {
      return (this.isDealer = false);
    }

    return (this.isDealer = true);
  }

  public async handleUpload(file: any) {
    // tslint:disable-next-line: whitespace
    const _file = <any>await resetOrientation(file.raw, -1);
    this.form.avatar = window.URL.createObjectURL(_file);
    this.fileUpload = _file;
  }
  public handleShowPass() {
    this.isShowPass = !this.isShowPass;
  }

  public async submit() {
    this.$refs.form.validate(async (valid) => {
      if (valid) {
        // this.loading = true;
        // tslint:disable-next-line: whitespace
        let form = <any>cloneDeep(this.form);
        let { avatar, dob } = this.form;
        const { phoneNumber } = this.form;

        if (avatar && this.fileUpload) {
          const token = await DKGqlClient().user.getFirebaseAuthenticateToken();
          await FirebaseAuthService().signInWithCustomToken(token.authenticate);
          avatar = await uploadToFirebase(this.fileUpload, this.pathUploadAvatar,
            {
              ...sizeImage.avatar.thumbnail,
              quality: 1,
              fileType: 'image/png'
            });
          form.avatar = avatar;
        }
        // if (phoneNumber) {
        //   form.phoneNumber = formatterPhoneNumber(phoneNumber);
        // }
        if (dob) {
          // tslint:disable-next-line: whitespace
          dob = <any>moment(dob)
            .utc()
            .unix();
        }

        form = omit(form, ['createdUser', 'updatedUser']);
        if (this.userId) {
          form.updatedBy = this.authUser.id;
        }
        if (form.email) {

          const result = await gqlClient.query({
            query: dealerListQuery.CHECK_EXIST_EMAIL,
            variables: {
              email: form.email
            },
            fetchPolicy: 'network-only'
          });
          if (result.errors) {
            throw result.errors;
          }
          if (result.data && result.data.dealers.length && result.data.dealers[0].id !== this.userId) {
            this.emailExists = true;

            return;
          }
        }
        if (phoneNumber) {

          const result = await gqlClient.query({
            query: dealerListQuery.CHECK_EXIST_PHONENUMBER,
            variables: {
              phoneNumber: form.phoneNumber
            },
            fetchPolicy: 'network-only'
          });
          if (result.errors) {
            throw result.errors;
          }
          if (result.data && result.data.dealers.length && result.data.dealers[0].id !== this.userId) {
            this.phoneExists = true;

            return;
          }
        }
        this.handleSystemUser(form);
      }

      return;
    });
  }

  public back() {
    this.$emit('update:visible', false);
  }
  public async openDialog() {
    if (this.userId) {
      const cloneForm = this.$store.getters.getDealerDetail(this.userId);
      this.form = {
        ...cloneDeep(cloneForm),
        groupId: cloneForm.group.id
      };
      // if (this.form && this.form.address) {
      //   this.addressText = this.form.address.address;
      //   this.isSub = this.form.subDealer ? true : false;
      // }
      const {branch, group, proshopType, businessType, potentialType} = cloneForm;
      this.form.branchId = branch.id;
      if (this.form && this.form.branchId) {
        this.selectedBranch = this.form.branchId;
      }
      this.form.groupId = group.id;
      this.form.proshopType = proshopType ? proshopType.alias : '';
      this.form.businessType = businessType ? businessType.alias : '';
      this.form.potentialType = potentialType ? potentialType.alias : '';
      this.isTechnician = false;
      if (this.form.role && this.form.role.name === 'Technician') {
        this.isTechnician = true;
        setTimeout(() => {
          if (this.$refs.form) {
            this.$refs.form.clearValidate();
          }
        }, 0);

        return;
      }
    }
    if (this.role && this.role.length) {
      this.role.map((it) => {
        if (it.name === ESystemGroup.DaikinDealer) {
          this.form.roleId = it.id;
        }
      });
    }
    this.$store.dispatch(ActionTypeDealer.GetDealerType);
    setTimeout(() => {
      if (this.$refs.form) {
        this.$refs.form.clearValidate();
      }
    }, 0);

  }
  public closeDialog() {
    this.form = dealerFormDefault();
    this.emailExists = false;
    this.phoneExists = false;
    this.addressText = '';
    this.isDealer = true;
    this.back();
  }
  public confirmClose() {
    this.$msgbox({
      title: this.$t('confirm').toString(),
      message: this.$t('action_confirm_message').toString(),
      showCancelButton: true,
      confirmButtonText: this.$t('confirm').toString(),
      cancelButtonText: this.$t('cancel').toString(),
      beforeClose: (action, _instance, done) => {
        if (action === 'confirm') {
          this.closeDialog();
          done();
        } else {
          done();
        }

        return;
      }
    });
  }
  public remoteMethod(query: string) {
    if (query !== '') {
      this.querySearchPartner = query;
    }
  }
  public formatterExpiredDate(date) {
    return date ? moment(date).utc().format('DD/MM/YYYY') : null;
  }

  protected mounted() {
    if (!this.isRefesh) {

      this.$store.dispatch(ActionTypeRole.FilterNoCache);
    }

    return;
  }

  protected beforeDestroy() {
    this.querySearchPartner = '';
    // this.$store.commit(MutationTypeTechnicianRate.ResetState);
  }

  private async handleSystemUser(form: any) {
    const bank = <any> {};
    if (form.bankName) {
      bank.bankName = form.bankName;
    }
    if (form.bankCode) {
      bank.bankCode = form.bankCode;
    }
    if (form.bankBranch) {
      bank.bankBranch = form.bankBranch;
    }
    if (form.accountNumber) {
      bank.accountNumber = form.accountNumber;
    }
    if (form.accountName) {
      bank.accountName = form.accountName;
    }
    const postForm = {
      avatar: form.avatar,
      name: form.name,
      address: form.address,
      email: form.email,
      password: form.password,
      gender: form.gender,
      branchId: form.branchId,
      groupId: form.groupId,
      roleId: form.roleId,
      dealerCode: form.dealerCode,
      description: form.description,
      loginId: form.loginId,
      companyName: form.companyName,
      bankInfo: bank,
      phoneNumber: form.phoneNumber
    };
    const updateForm = {
      avatar: form.avatar,
      name: form.name,
      address: form.address,
      shortName: form.shortName,
      email: form.email,
      password: form.password,
      gender: form.gender,
      branchId: form.branchId,
      groupId: form.groupId,
      roleId: form.roleId,
      dealerCode: form.dealerCode,
      description: form.description,
      loginId: form.loginId,
      companyName: form.companyName,
      bankName: form.bankName,
      bankCode: form.bankCode,
      bankBranch: form.bankBranch,
      accountName: form.accountName,
      accountNumber: form.accountNumber,
      cardExpirationDate: form.cardExpirationDate,
      cardNumber: form.cardNumber,
      cardType: form.cardType,
      phoneNumber: form.phoneNumber,
      proshopTypeId: form.proshopType || null,
      businessTypeId: form.businessType || null,
      potentialTypeId: form.potentialType || null
    };
    if (this.userId) {
      // update
      // postForm.address = { ...form.addres, address: form.address };
      const _form = this.isSub
        ? postForm
        : omit(updateForm, ['__typename', 'password']);
      this.$store.dispatch(ActionTypeDealer.UpdateDealer, {
        set: {
          ..._form,
          updatedBy: this.authUser.id
        },
        id: this.userId,
        onSuccess: () => {
          this.loading = false;
          const message = 'Update Dealer successfully';
          this.$store.commit(MutationType.OpenTopAlert, {
            message,
            type: TypeAlert.Success
          });
          this.$store.dispatch(ActionTypeDealer.GetListDealer, {
            form: {
              filter: {},
              pagination: {
                size: 20,
                page: 1
              }
            }
          });
          this.closeDialog();
          this.$emit('editSuccess');
          // this.$emit('updateSuccess');
        },
        onFailure: () => {
          this.loading = false;
        }
      });
    } else {
      // create
      this.$store.dispatch(ActionTypeDealer.CreateDealer, {
        form: {
          ...stripObject(postForm)
        },
        onSuccess: () => {
          this.loading = false;
          const message = 'Create Dealer successfully';
          this.$store.commit(MutationType.OpenTopAlert, {
            message,
            type: TypeAlert.Success
          });
          this.$store.dispatch(ActionTypeDealer.GetListDealer, {
            form: {
              filter: {},
              pagination: {
                size: 20,
                page: 1
              }
            }
          });
          this.closeDialog();
          // this.$emit('createSuccess');
        },
        onFailure: () => {
          this.loading = false;
        }
      });
    }
  }
}
