import { stripObject } from '@hgiasac/helper';
import { Subscription } from 'apollo-client/util/Observable';
import { TabPane } from 'element-ui';
import { ElUpload } from 'element-ui/types/upload';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import { ActionTypeBranchManage } from 'root/admin/BranchAndGroup/BranchManage/Store/types';
import { ActionTypeGroupManage } from 'root/admin/BranchAndGroup/GroupManage/Store/types';
import { SystemRoleAlias } from 'root/admin/User/Store/types';
import { DKGqlClient } from 'root/api/graphql/Client';
import { gqlClient, IPaginationParams } from 'root/api/graphql/Core';
import { zeroCampaignQuery } from 'root/api/graphql/Query/zeroCampaign';
import { FilterByBranch } from 'root/components/FilterByBranch';
import { SearchByFilter } from 'root/components/SearchByFilter';
import { FormatDateFullTime } from 'root/helpers';
import { IBreadcrumb, IFilterInput, IUser } from 'root/models';
import { pathUpload, uploadToFirebase } from 'root/services';
import { FirebaseAuthService } from 'root/services/auth/firebase';
import { IState, MutationType, TypeAlert } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import { mapState } from 'vuex';
import { ZeroPercentCampaignRouterName, ZeroPercentCampaignTab } from '..';
import { UpdateFinancialCompany } from '../Components/UpdateFinancialCompany';
import { ZPCampaignDetail } from '../Components/ZPCampaignDetail';
import { ActionTypeZeroCampaign, MutationTypeZeroCampaign } from '../Store/types';
import './styles.scss';

const enum FilterKey {
  HD = 'HD',
  FE = 'FE',
  ACS = 'ACS',
  Branch = 'branch',
  Group = 'group',
  Status = 'status',
  Company = 'company'
}
export enum FinancialCompanyStatusCode {
  WaitingForReview = 'waiting_for_review',
  Approved = 'approved',
  Rejected = 'rejected',
  HaveCode = 'have_code',
  RequestStopJoining = 'request_stop_joining',
  StopJoining = 'stop_joining'
}
export enum FinancialCompanyStatus {
  WaitingForReview = 'Waiting For Review',
  Approved = 'Approved',
  Rejected = 'Rejected',
  HaveCode = 'Have Code',
  RequestStopJoining = 'Request Stop Joining',
  StopJoining = 'Stop Joining'
}
export enum FinancialCompanyColor {
  WaitingForReview = '#FF9B1A',
  Approved = '#24A148',
  Rejected = '#FB4B53',
  HaveCode = '#03A1E4',
  RequestStopJoining = '#A66EFA',
  StopJoining = '#A66EFA'
}
export enum PosCodeType {
  OpenCode = 'open_code',
  ClosedCode = 'closed_code'
}
enum campaignSearchBy {
  DealerCode = 'dealerCode',
  Dealer = 'dealer',
  DealerShop = 'dealerShop',
  ShopCode = 'shopCode',
  PosCode = 'posCode'
}
@Component({
  template: require('./view.html'),
  computed: {
    ...mapState({
      data: (state: IState) => state.zeroCampaign.data,
      loading: (state: IState) => state.zeroCampaign.loading,
      pagination: (state: IState) => state.zeroCampaign.pagination,
      authUser: (state: IState) => state.global.authUser,
      branch: (state: IState) => state.branchManage.data,
      group: (state: IState) => state.groupManage.data,
    }),
    campaignSearchBy() {
      const result: IFilterInput[] = [];
      for (const key in campaignSearchBy) {
        // tslint:disable-next-line:early-exit
        if (campaignSearchBy.hasOwnProperty(key)) {
          const element = campaignSearchBy[key];
          result.push({
            key,
            value: element,
            name: this.$t(element)
          });
        }
      }

      return [{
        key: 'select',
        name: this.$t('select'),
        value: result
      }];
    },
    selectedFieldSearchBy() {
      const filterParams = cloneDeep(this.modelSearchBy);

      return [{
        key: 'select',
        value: filterParams ? filterParams : []
      }];
    },
  },
  components: {
    'campaign-detail': ZPCampaignDetail,
    'update-financial-company': UpdateFinancialCompany,
    'search-by-filter': SearchByFilter,
    'btn-filter-by-branch': FilterByBranch
  }
})

export class ZPCampaignParticipant extends Vue {

  public get activeTab() {
    return ZeroPercentCampaignTab.Participant;
  }

  public set activeTab(_value: string) {
    //
  }
  public get isSuperAdmin() {
    if (this.authUser.superAdmin || this.authUser.role.alias === SystemRoleAlias.Admin) {
      return true;
    }

    return false;
  }
  public get isSalePlanning() {
    if (this.authUser.role.alias === SystemRoleAlias.SaleAdmin) {
      return true;
    }

    return false;
  }
  public get isBranchPic() {
    if (this.authUser.role.alias === SystemRoleAlias.BranchManager) {
      return true;
    }

    return false;
  }
  public get isDepartmentPic() {
    if (this.authUser.role.alias === SystemRoleAlias.GroupLeader) {
      return true;
    }

    return false;
  }
  public get currentRole() {
    return this.authUser.role.alias;
  }
  public get disableExport() {
    // if (this.filterParams[FilterKey.Status].length && this.filterParams[FilterKey.Status].length === 1 &&
    //   this.TimeFilter && this.TimeFilter.startMonth && this.TimeFilter.startYear
    //   && this.TimeFilter.endMonth && this.TimeFilter.endYear) {
    //   return false;
    // }
    if (this.filterParams[FilterKey.Status].length && this.filterParams[FilterKey.Status].length === 1) {
      return false;
    }

    return true;
  }
  public get dataSalePlanningFilter() {
    const _branch: IFilterInput[] = this.branch.reduce((res, item) => {
      res.push({
        key: item.id,
        value: item.id,
        name: item.name
      });

      return res;
    }, []);
    const _status: IFilterInput[] = [
      {
        key: FinancialCompanyStatusCode.Approved,
        value: FinancialCompanyStatusCode.Approved,
        name: FinancialCompanyStatus.Approved
      },
      {
        key: FinancialCompanyStatusCode.HaveCode,
        value: FinancialCompanyStatusCode.HaveCode,
        name: FinancialCompanyStatus.HaveCode
      },
      {
        key: FinancialCompanyStatusCode.Rejected,
        value: FinancialCompanyStatusCode.Rejected,
        name: FinancialCompanyStatus.Rejected
      },
      {
        key: FinancialCompanyStatusCode.StopJoining,
        value: FinancialCompanyStatusCode.StopJoining,
        name: FinancialCompanyStatus.StopJoining
      },
      {
        key: FinancialCompanyStatusCode.RequestStopJoining,
        value: FinancialCompanyStatusCode.RequestStopJoining,
        name: FinancialCompanyStatus.RequestStopJoining
      }
    ];
    const company: IFilterInput[] = [
      {
        key: 'hd',
        value: 'HD',
        name: 'HD Saison'
      },
      {
        key: 'fe',
        value: 'FE',
        name: 'FE Credit'
      },
      {
        key: 'acs',
        value: 'ACS',
        name: 'ACS'
      },
    ];

    return [
      ...this.isSalePlanning ? [
        {
          key: FilterKey.Branch,
          name: 'Branch',
          value: _branch
        },
      ] : [],
      {
        key: FilterKey.Status,
        name: 'Status',
        value: _status
      },
      {
        key: FilterKey.Company,
        name: 'Financial Company',
        value: company
      },
    ];
  }
  public get dataDepartmentFilter() {
    const _companyStatus: IFilterInput[] = [
      {
        key: FinancialCompanyStatusCode.WaitingForReview,
        value: FinancialCompanyStatusCode.WaitingForReview,
        name: FinancialCompanyStatus.WaitingForReview
      },
      {
        key: FinancialCompanyStatusCode.Approved,
        value: FinancialCompanyStatusCode.Approved,
        name: FinancialCompanyStatus.Approved
      },
      {
        key: FinancialCompanyStatusCode.HaveCode,
        value: FinancialCompanyStatusCode.HaveCode,
        name: FinancialCompanyStatus.HaveCode
      },
      {
        key: FinancialCompanyStatusCode.Rejected,
        value: FinancialCompanyStatusCode.Rejected,
        name: FinancialCompanyStatus.Rejected
      },
      {
        key: FinancialCompanyStatusCode.StopJoining,
        value: FinancialCompanyStatusCode.StopJoining,
        name: FinancialCompanyStatus.StopJoining
      },
      {
        key: FinancialCompanyStatusCode.RequestStopJoining,
        value: FinancialCompanyStatusCode.RequestStopJoining,
        name: FinancialCompanyStatus.RequestStopJoining
      }
    ];

    return [
      {
        key: FilterKey.HD,
        name: 'HD SAISON',
        value: _companyStatus
      },
      {
        key: FilterKey.FE,
        name: 'FE CREDIT',
        value: _companyStatus
      },
      {
        key: FilterKey.ACS,
        name: 'ACS',
        value: _companyStatus
      },
    ];
  }
  public get dataFilterBranch() {
    const _branch: IFilterInput[] = this.branch.reduce((res, item) => {
      res.push({
        key: item.id,
        value: item.id,
        name: item.name
      });

      return res;
    }, []);

    return [
      {
        key: FilterKey.Branch,
        name: 'Branch',
        value: _branch
      },
    ];
  }
  public get selectedFilter() {
    const _filterParams = cloneDeep(this.filterParams);

    return [
      {
        key: FilterKey.HD,
        value: _filterParams[FilterKey.HD]
      },
      {
        key: FilterKey.FE,
        value: _filterParams[FilterKey.FE]
      },
      {
        key: FilterKey.ACS,
        value: _filterParams[FilterKey.ACS]
      },
      {
        key: FilterKey.Branch,
        value: _filterParams[FilterKey.Branch]
      },
      {
        key: FilterKey.Group,
        value: _filterParams[FilterKey.Group]
      },
      {
        key: FilterKey.Status,
        value: _filterParams[FilterKey.Status]
      },
      {
        key: FilterKey.Company,
        value: _filterParams[FilterKey.Company]
      }
    ];
  }
  public get columns() {
    return [
      {
        prop: 'shop',
        label: 'Dealer Shop',
        labelItem: 'Shop Code',
        width: 270,
      },
      {
        prop: 'dealer',
        label: 'Dealer Name',
        labelItem: 'Dealer Code',
        width: 270,
      },
      // {
      //   prop: 'branch',
      //   label: 'Branch',
      //   width: 120,
      // },
      // {
      //   prop: 'group',
      //   label: 'Department',
      //   width: 120,
      // },
      {
        prop: 'hdsg',
        label: 'HD Saison',
        labelItem: 'Update Time',
        width: 200,
      },
      {
        prop: 'typeHdsg',
        label: 'Type',
        width: 120,
      },
      {
        prop: 'updatedHdsg',
        label: 'Updated By',
        labelItem: 'Name',
        width: 120,
      },
      {
        prop: 'feCredit',
        label: 'Fe Credit',
        labelItem: 'Update Time',
        width: 200,
      },
      {
        prop: 'typeFe',
        label: 'Type',
        width: 120,
      },
      {
        prop: 'updatedFe',
        label: 'Updated By',
        labelItem: 'Name',
        width: 120,
      },
      {
        prop: 'asc',
        label: 'ACS',
        labelItem: 'Update Time',
        width: 200,
      },
      {
        prop: 'typeASC',
        label: 'Type',
        width: 120,
      },
      {
        prop: 'updatedASC',
        label: 'Updated By',
        labelItem: 'Name',
        width: 120,
      },
    ];
  }

  public searchText: string = '';
  public authUser: IUser;
  public pagination: IPaginationParams;
  public campaignId: string = '';
  public FormatDateFullTime = FormatDateFullTime;
  public fileList = [];
  public statistic: any = {
    fe: {},
    hd: {},
    acs: {}
  };
  public fileUploaded = [];
  public modelSearchBy: any[] = [];
  public visibleImport: boolean = false;
  public loadingUpload: boolean = false;
  public SystemRoleAlias = SystemRoleAlias;
  public typeImport: string = '';
  public loadingExport: boolean = false;
  public loadingImport: boolean = false;
  public loadingDownload: boolean = false;
  // tslint:disable-next-line: max-line-length
  public urlTemplate: string = 'https://firebasestorage.googleapis.com/v0/b/daikin-sale-staging.appspot.com/o/imports%2Fsample%2FImport%20code%20into%20DK%20dealer%20app.xlsx?alt=media&token=67777226-659a-4269-88ab-2339522d914d';
  // tslint:disable-next-line:max-line-length
  public openTemplate: string = 'https://firebasestorage.googleapis.com/v0/b/daikin-sale-staging.appspot.com/o/imports%2Fsample%2FImport%20code%20into%20DK%20dealer%20app.xlsx?alt=media&token=154873e3-1d80-4a4d-863c-4599f2cd7e9e';
  // tslint:disable-next-line:max-line-length
  public closeTemplate: string = 'https://firebasestorage.googleapis.com/v0/b/daikin-sale-staging.appspot.com/o/imports%2Fsample%2FImport%20%C4%90%C3%B3ng%20code%20h%C3%A0ng%20lo%E1%BA%A1t.xlsx?alt=media&token=4208756e-6afe-4e99-ab56-b76be5a9c10a';
  public pathUploadAvatar: pathUpload = pathUpload.POSCODE;
  public TimeFilter: any = {
    startMonth: null,
    endMonth: null,
    startYear: null,
    endYear: null
  };
  public FilterTimeUpdate: any = {
    from: null,
    to: null
  };
  public $refs: {
    upload: ElUpload;
  };
  public subscribeURL: Subscription = null;
  public subscribeImport: Subscription = null;
  public filterParams = {
    [FilterKey.HD]: [],
    [FilterKey.FE]: [],
    [FilterKey.ACS]: [],
    [FilterKey.Branch]: [],
    [FilterKey.Group]: [],
    [FilterKey.Status]: [],
    [FilterKey.Company]: [],
  };
  public visibleDetail: boolean = false;
  public visibleUpdateCompany: boolean = false;
  public listFinancialCompany: any = [];
  public branch: any[];
  public group: any[];

  public getActions() {
    if (this.isDepartmentPic || this.isSuperAdmin) {
      return ['view', 'updateCompany'];
    }

    return ['view'];
  }
  public handleActions(model) {
    if (model && model.action === 'view') {
      this.onClickDetail(model.id);
    }
    if (model && model.action === 'updateCompany') {
      this.onClickUpdateCompany(model.id);
    }

    return;
  }
  public changeTab(tab: TabPane) {
    switch (tab.name) {
    case ZeroPercentCampaignTab.Statistic:
      this.$router.push({
        name: ZeroPercentCampaignRouterName.Statistic
      });
      break;
    case ZeroPercentCampaignTab.RequestReview:
      this.$router.push({
        name: ZeroPercentCampaignRouterName.RequestReview
      });
      break;
    case ZeroPercentCampaignTab.Configuration:
      this.$router.push({
        name: ZeroPercentCampaignRouterName.Configuration
      });
      break;
    default:
      break;
    }
  }

  public handleSearch() {
    this.fetchData();
  }

  public handlePaginationChange(value) {
    if (typeof value === 'number') {
      this.$store.commit(MutationTypeZeroCampaign.PaginationChange, {
        ...this.pagination,
        size: value
      });
    } else {
      this.$store.commit(MutationTypeZeroCampaign.PaginationChange, {
        ...this.pagination,
        ...value
      });
    }
    this.fetchData();
  }
  public formatterTypePosCode(model , alias) {
    if (model && model.ip_campaign_companies && model.ip_campaign_companies.length) {
      const _company = model.ip_campaign_companies.find((company) => company.financial_company.alias === alias);
      if (!_company) {
        return '--';
      }

      return _company.type;
    }

    return '--';
  }
  public formatterPosCode(model , alias) {
    if (model && model.ip_campaign_companies && model.ip_campaign_companies.length) {
      const _company = model.ip_campaign_companies.find((company) => company.financial_company.alias === alias);
      if (!_company) {
        return '--';
      }

      return _company.posCode;
    }

    return '--';
  }
  public StatusByRole(company) {
    if (this.currentRole === SystemRoleAlias.SaleStaff && company.companyStatus === 'waiting_for_review'
    && !company.lastReviewer) {
      return 'waiting_for_review';
    }
    if (this.currentRole !== SystemRoleAlias.SaleStaff && company.companyStatus === 'waiting_for_review'
    && !company.lastReviewer) {
      return '';
    }
    if (this.currentRole === SystemRoleAlias.SaleDepartmentPic && company.companyStatus === 'waiting_for_review'
    && company.lastReviewer && company.lastReviewer.role &&
    company.lastReviewer.role.alias === SystemRoleAlias.SaleStaff) {
      return 'waiting_for_review';
    }
    if (this.currentRole === SystemRoleAlias.GroupLeader && company.companyStatus === 'waiting_for_review'
    && company.lastReviewer && company.lastReviewer.role &&
    company.lastReviewer.role.alias === SystemRoleAlias.SaleDepartmentPic) {
      return 'waiting_for_review';
    }

    return company.lastReviewStatus ? company.lastReviewStatus : 'waiting_for_review';
  }
  public formatterUpdateByRole(model, alias) {
    if (model && model.ip_campaign_companies && model.ip_campaign_companies.length) {
      const _company = model.ip_campaign_companies.find((company) => company.financial_company.alias === alias);
      if (!_company) {
        return '--';
      }

      return _company && _company.updater && _company.updater.role && _company.updater.role.name ?
      _company.updater.role.name : '--';
    }

    return '--';
  }
  public formatterUpdateByName(model, alias) {
    if (model && model.ip_campaign_companies && model.ip_campaign_companies.length) {
      const _company = model.ip_campaign_companies.find((company) => company.financial_company.alias === alias);
      if (!_company) {
        return '--';
      }

      return _company && _company.updater && _company.updater.name ? _company.updater.name : '--';
    }

    return '--';
  }
  public formatterFinancialCompanyName(model, alias) {
    if (model && model.ip_campaign_companies && model.ip_campaign_companies.length) {
      const _company = model.ip_campaign_companies.find((company) => company.financial_company.alias === alias);
      if (!_company) {
        return '--';
      }

      // return this.formatterFinancialCompanyStatus(_company.companyStatus &&
      //   (_company.companyStatus === 'have_code' || _company.companyStatus === 'stop_joining'
      //   || _company.companyStatus === 'request_stop_joining') ?
      //   _company.companyStatus :
      //   _company.lastReviewStatus ? _company.lastReviewStatus : '');

      return this.formatterFinancialCompanyStatus(_company.companyStatus ? _company.companyStatus : '');
    }

    return '--';
  }
  public formatterFinacialCompanyUpdateAt(model, alias) {
    if (model && model.ip_campaign_companies && model.ip_campaign_companies.length) {
      const _company = model.ip_campaign_companies.find((company) => company.financial_company.alias === alias);
      if (!_company) {
        return '--';
      }

      return this.FormatterDateByStatusLogs(_company);
    }

    return '--';
  }
  public FormatterDateByStatusLogs(model: any) {
    if (model && model.ip_campaign_company_status_logs.length) {
      let _firstLog: any = model.ip_campaign_company_status_logs[0];
      model.ip_campaign_company_status_logs.map((log) => {
        if (moment(log.createdAt).isAfter(moment(_firstLog.createdAt))) {
          _firstLog = log;
        }
      });

      return this.FormatDateFullTime(_firstLog.createdAt);
    }

    return model.updatedAt ? this.FormatDateFullTime(model.updatedAt) : '--';
  }
  public importPosCode() {
    this.loadingImport = true;
    this.visibleImport = true;
  }
  public handleImportPosCode(value) {
    this.typeImport = value;
    this.importPosCode();
  }
  public closeModalImport() {
    this.loadingImport = false;
    this.visibleImport = false;
    this.typeImport = '';
    this.$refs.upload.clearFiles();
    this.fileList = [];
  }
  public downloadTemplate() {
    window.open(this.urlTemplate, '_blank');
  }
  public pushFileUpload(file: any) {
    const f = this.fileList;
    if (this.fileList.length > 0) {
      const _file = this.fileList.filter((it) => it.name === file.name);
      if (_file && _file.length > 0) {
        this.$refs.upload.clearFiles();
        const message = 'File has been exits';
        this.$store.commit(MutationType.OpenTopAlert, {
          message,
          type: TypeAlert.Warning
        });

        return this.$refs.upload.$data.uploadFiles = f;
      }

      return this.fileList = [file];
    }

    f.push(file);

    return this.fileList = [file];
  }
  public onRemove(_file: any, fileList: any) {
    this.fileList = fileList;
  }
  public async submitFile() {
    // tslint:disable-next-line: whitespace
    try {
      this.loadingUpload = true;
      // const uploadedFile = <any> [];
      const token = await DKGqlClient().user.getFirebaseAuthenticateToken();
      await FirebaseAuthService().signInWithCustomToken(token.authenticate);
      const uploadedFile = await Promise.all(this.fileList.map(async (it) => {
        const _file = await uploadToFirebase(it.raw, this.pathUploadAvatar);

        // return _file ? uploadedFile.push(_file) : uploadedFile;
        // tslint:disable-next-line:no-var-before-return
        return _file;
      }));

      if (uploadedFile.length > 0) {
        const form = {
          createdBy: this.authUser.id,
          url: uploadedFile[0],
          type: this.typeImport === 'openCode' ? 'ip_campaign_pos_code' : 'ip_campaign_close_code',
          isReIndex: true
        };
        this.$store.dispatch(ActionTypeZeroCampaign.ImportPosCode, {
          form,
          onSuccess: (result) => {
            this.loadingUpload = false;
            if (result && result.data && result.data.insert_imports
            && result.data.insert_imports.returning && result.data.insert_imports.returning.length) {
              this.subscribeImport = <any> gqlClient.subscribe({
                query: zeroCampaignQuery.IMPORT_POS_CODE_SUBSCRIBE,
                variables : {id: result.data.insert_imports.returning[0].id},
                fetchPolicy: 'cache-first'
              }).subscribe((results) => {
                if (results.data && results.data.imports && results.data.imports.length
                  && results.data.imports[0].importStatus === 'done') {
                  const message = `Import ${this.typeImport === 'openCode' ? 'POS Code' : 'Close Code'} successfully`;
                  this.$store.commit(MutationType.OpenTopAlert, {
                    message,
                    type: TypeAlert.Success
                  });
                  this.subscribeImport.unsubscribe();
                  this.loadingExport = false;
                  this.fetchData();
                } else if (results.data && results.data.imports && results.data.imports.length
                  && results.data.imports[0].importStatus === 'failed') {
                  const message = `Import ${this.typeImport === 'openCode' ? 'POS Code' : 'Close Code'} failure`;
                  this.$store.commit(MutationType.OpenTopAlert, {
                    message,
                    type: TypeAlert.Error
                  });
                  this.subscribeImport.unsubscribe();
                  this.loadingExport = false;
                }
              });
            }
            this.visibleImport = false;
            this.fileList = [];
            this.fileUploaded = [];
            this.$refs.upload.clearFiles();
          },
          onFailure: () => {
            this.loadingUpload = false;

          }});
      }

    } catch (error) {
      console.log(error);
    }

  }
  public formatterFinancialCompanyStatus(status) {
    if (status) {
      switch (status) {
      case FinancialCompanyStatusCode.WaitingForReview:
        return FinancialCompanyStatus.WaitingForReview;
      case FinancialCompanyStatusCode.Approved:
        return FinancialCompanyStatus.Approved;
      case FinancialCompanyStatusCode.Rejected:
        return FinancialCompanyStatus.Rejected;
      case FinancialCompanyStatusCode.HaveCode:
        return FinancialCompanyStatus.HaveCode;
      case FinancialCompanyStatusCode.RequestStopJoining:
        return FinancialCompanyStatus.RequestStopJoining;
      case FinancialCompanyStatusCode.StopJoining:
        return FinancialCompanyStatus.StopJoining;
      default:
        return '--';
      }
    }

    return '--';
  }
  public handleCommand() {
    this.loadingExport = true;
    const text = cloneDeep(this.searchText);
    let command = '';
    // if (this.filterParams[FilterKey.Status].length && this.filterParams[FilterKey.Status].length > 1) {
    //   const message = 'Please choose status, date range before you do the action';
    //   this.$store.commit(MutationType.OpenTopAlert, {
    //     message,
    //     type: TypeAlert.Warning,
    //   });
    //   this.loadingExport = false;

    //   return;
    // }
    switch (this.filterParams[FilterKey.Status][0]) {
    case FinancialCompanyStatusCode.Approved:
    case FinancialCompanyStatusCode.HaveCode:
    case FinancialCompanyStatusCode.Rejected:
    case FinancialCompanyStatusCode.RequestStopJoining:
      command = 'ip_campaign_open_code';
      break;
    case FinancialCompanyStatusCode.StopJoining:
      command = 'ip_campaign_close_code';
      break;
    }
    this.$store.dispatch(ActionTypeZeroCampaign.ExportCode, {
      form: {
        isReIndex: true,
        type: command,
        createdBy: this.authUser.id,
        filter: {
          // ...this.TimeFilter,
          requestStatus: command === 'ip_campaign_open_code' ? this.filterParams[FilterKey.Status] : ['stop_joining'],
          companyIds: this.filterParams[FilterKey.Company] && this.filterParams[FilterKey.Company].length > 0 ?
          this.filterParams[FilterKey.Company] : ['HD', 'FE', 'ACS'],
          search: text && text.length > 0 ? {
            ...this.modelSearchBy.length && this.modelSearchBy.indexOf('dealer') >= 0 ? {
              dealerName: text
            } : null,
            ...this.modelSearchBy.length && this.modelSearchBy.indexOf('dealerCode') >= 0 ? {
              dealerCode: text
            } : null,
            ...this.modelSearchBy.length && this.modelSearchBy.indexOf('dealerShop') >= 0 ? {
              shopName: text
            } : null,
            ...this.modelSearchBy.length && this.modelSearchBy.indexOf('shopCode') >= 0 ? {
              shopCode: text
            } : null,
            ...this.modelSearchBy.length && this.modelSearchBy.indexOf('posCode') >= 0 ? {
              posCode: text
            } : null
          } : null,
        }
      },
      onSuccess: (url) => {
        // tslint:disable-next-line: early-exit
        if (url.returning && url.returning.length) {
          this.subscribeURL = <any> gqlClient.subscribe({
            query: zeroCampaignQuery.DOWNLOAD_EXPORT,
            variables : {id: url.returning[0].id},
            fetchPolicy: 'cache-first'
          }).subscribe((results) => {
            // tslint:disable-next-line: early-exit
            if (results.data && results.data.exports && results.data.exports.length
              && results.data.exports[0].exportStatus === 'done') {
              window.open(results.data.exports[0].url, '_blank');
              this.subscribeURL.unsubscribe();
              this.loadingExport = false;
              const message = 'Export successfully';
              this.$store.commit(MutationType.OpenTopAlert, {
                message,
                type: TypeAlert.Success
              });

              return;
            }
            // tslint:disable-next-line:early-exit
            if (results.data && results.data.exports && results.data.exports.length
              && results.data.exports[0].exportStatus === 'failed') {
              // window.open(results.data.exports[0].url, '_blank');
              this.subscribeURL.unsubscribe();
              const message = 'Update Campaign failure';
              this.$store.commit(MutationType.OpenTopAlert, {
                message,
                type: TypeAlert.Error
              });
              this.loadingExport = false;

              return;
            }
          });
        }
      }
    });
  }
  public formatterLabelStatus(model: any, alias) {
    if (model && model.ip_campaign_companies && model.ip_campaign_companies.length) {
      const _company = model.ip_campaign_companies.find((company) => company.financial_company.alias === alias);
      if (!_company) {
        return '--';
      }
      // const status = _company.companyStatus &&
      // (_company.companyStatus === 'have_code' || _company.companyStatus === 'stop_joining'
      // || _company.companyStatus === 'request_stop_joining') ?
      // _company.companyStatus :
      // _company.lastReviewStatus ? _company.lastReviewStatus : '';

      switch (_company.companyStatus) {
      case FinancialCompanyStatusCode.WaitingForReview:
        return FinancialCompanyColor.WaitingForReview;
      case FinancialCompanyStatusCode.Approved:
        return FinancialCompanyColor.Approved;
      case FinancialCompanyStatusCode.Rejected:
        return FinancialCompanyColor.Rejected;
      case FinancialCompanyStatusCode.HaveCode:
        return FinancialCompanyColor.HaveCode;
      case FinancialCompanyStatusCode.RequestStopJoining:
        return FinancialCompanyColor.RequestStopJoining;
      case FinancialCompanyStatusCode.StopJoining:
        return FinancialCompanyColor.StopJoining;
      default:
        return '--';
      }
    }

    return '--';
  }
  public onClickDetail(id) {
    this.visibleDetail = true;
    this.campaignId = id;
  }
  public onClickUpdateCompany(id) {
    const campaign = this.$store.getters.getZeroCampaignDetail(id);
    this.listFinancialCompany = [];
    if (campaign && campaign.ip_campaign_companies.length) {
      campaign.ip_campaign_companies.map((company) => {
        this.listFinancialCompany.push(company.financial_company.alias);
      });
    }
    this.visibleUpdateCompany = true;
    this.campaignId = id;
  }
  public closeDialogDetail() {
    this.visibleDetail = false;
    this.campaignId = '';
    this.$router.replace({query: null});
    this.fetchData();
  }
  public closeDialogUpdateFinancialCompany() {
    this.visibleUpdateCompany = false;
    this.campaignId = '';
    this.fetchData();
  }
  public changeFilterBranch(filter) {
    const nextFilter = {
      [FilterKey.Branch]: filter ? filter : [],
    };
    this.filterParams = {
      ...cloneDeep(this.filterParams),
      ...nextFilter
    };
    this.fetchData();
  }
  public changeFilter(model, time) {
    if (time && time.from && time.to) {
      const startMonth = parseInt(moment(time.from).format('MM'), 10);
      const endMonth = parseInt(moment(time.to).format('MM'), 10);
      const startYear = parseInt(moment(time.from).format('YYYY'), 10);
      const endYear = parseInt(moment(time.to).format('YYYY'), 10);
      this.TimeFilter = { startMonth, endMonth, startYear, endYear};
      this.FilterTimeUpdate = time;
    }
    if (time && !time.from && !time.to) {
      this.TimeFilter = {
        startMonth: null,
        endMonth: null,
        startYear: null,
        endYear: null
      };
      this.FilterTimeUpdate = {
        from: null,
        to: null,
      };
    }
    const HD = model.find((e) => e.key === FilterKey.HD);
    const FE = model.find((e) => e.key === FilterKey.FE);
    const ACS = model.find((e) => e.key === FilterKey.ACS);
    const group = model.find((e) => e.key === FilterKey.Group);
    const status = model.find((e) => e.key === FilterKey.Status);
    const company = model.find((e) => e.key === FilterKey.Company);
    const branch = model.find((e) => e.key === FilterKey.Branch);
    const nextFilter = {
      [FilterKey.HD]: HD ? HD.value : [],
      [FilterKey.FE]: FE ? FE.value : [],
      [FilterKey.ACS]: ACS ? ACS.value : [],
      [FilterKey.Group]: group ? group.value : [],
      [FilterKey.Status]: status ? status.value : [],
      [FilterKey.Company]: company ? company.value : [],
      [FilterKey.Branch]: branch ? branch.value : []
    };
    this.filterParams = {
      ...cloneDeep(this.filterParams),
      ...nextFilter
    };
    this.fetchData();
  }
  public changeSearchBy(model: IFilterInput[]) {
    this.modelSearchBy = model[0].value;

    this.fetchData();
  }
  public onEnterFilter(value) {
    this.searchText = value;

    this.fetchData();
  }
  public handleCommandDownload(type: any) {
    this.loadingDownload = true;
    switch (type) {
    case 'openCode':
      window.open(this.openTemplate, '_blank');
      this.loadingDownload = false;

      return;
    case 'closeCode':
      window.open(this.closeTemplate, '_blank');
      this.loadingDownload = false;

      return;
    }
  }
  protected mounted() {
    this.$nextTick(() => {
      const breadcrumb: IBreadcrumb[] = [
        {
          label: 'title.zer-per-campaign',
          path: null,
          current: false
        },
      ];
      this.$store.dispatch(ActionTypeBranchManage.GetListBranch, {
        form: {
          filter: {},
          pagination: {}
        }
      });
      this.$store.dispatch(ActionTypeGroupManage.GetListGroupManage, {
        form: {
          filter: {},
          pagination: {}
        }
      });
      this.$store.commit(MutationType.SetBreadcrumb, breadcrumb);
      if (this.$route.query && this.$route.query.detail) {
        this.visibleDetail = true;
        this.campaignId = <any> this.$route.query.detail;

        return;
      }
      this.fetchData();
    });
  }
  protected beforeDestroy() {
    this.$store.commit(MutationType.ClearBreadcrumb);
  }

  @Watch('$route.query.detail', { deep: true })
  protected onChangeRouter(val, oldVal) {
    if (val !== oldVal && val) {
      this.visibleDetail = true;
      this.campaignId = val;
    }
  }

  private fetchData() {
    const text = cloneDeep(this.searchText);
    // const _filterParams = <any> {};
    const _searchModel: any[] = [];
    if (this.modelSearchBy.length && this.modelSearchBy.indexOf('dealer') >= 0) {
      _searchModel.push({
        dealer: {
          name :  {
            _ilike: `%${text}%`
          }
        }
      });
    }
    if (this.modelSearchBy.length && this.modelSearchBy.indexOf('dealerCode') >= 0) {
      _searchModel.push({
        dealer: {
          dealerCode :  {
            _ilike: `%${text}%`
          }
        }
      });
    }
    if (this.modelSearchBy.length && this.modelSearchBy.indexOf('dealerShop') >= 0) {
      _searchModel.push({
        shop: {
          name :  {
            _ilike: `%${text}%`
          }
        }
      });
    }
    if (this.modelSearchBy.length && this.modelSearchBy.indexOf('shopCode') >= 0) {
      _searchModel.push({
        shop: {
          shopCode :  {
            _ilike: `%${text}%`
          }
        }
      });
    }
    if (this.modelSearchBy.length && this.modelSearchBy.indexOf('posCode') >= 0) {
      _searchModel.push({
        ip_campaign_companies: {
          posCode :  {
            _ilike: `%${text}%`
          }
        }
      });
    }
    this.$store.commit(MutationTypeZeroCampaign.FilterChange, stripObject({
      _and:
      {
        mode: 'special',
        data: [
          {
            ip_campaign_companies: {
              status: { _eq: 'active' }
            }
          },
          this.filterParams[FilterKey.Branch].length ? {
            dealer: {
              branchId: {
                _in: this.filterParams[FilterKey.Branch]
              },
            }
          } : null,
          this.filterParams[FilterKey.HD].length ? {
            ip_campaign_companies: {
              companyStatus: {
                _in: this.filterParams[FilterKey.HD]
              },
              status: { _eq: 'active' },
              financial_company: {
                alias: {
                  _eq: 'HD'
                }
              }
            }
          } : null,
          this.filterParams[FilterKey.FE].length ? {
            ip_campaign_companies: {
              companyStatus: {
                _in: this.filterParams[FilterKey.FE]
              },
              status: { _eq: 'active' },
              financial_company: {
                alias: {
                  _eq: 'FE'
                }
              }
            }
          } : null,
          this.filterParams[FilterKey.ACS].length ? {
            ip_campaign_companies: {
              companyStatus: {
                _in: this.filterParams[FilterKey.ACS]
              },
              status: { _eq: 'active' },
              financial_company: {
                alias: {
                  _eq: 'ACS'
                }
              }
            }
          } : null,
          this.filterParams[FilterKey.Branch].length > 0 ? {
            dealer: {
              branch: {
                id : {
                  _in : this.filterParams[FilterKey.Branch]
                }
              }
            }
          } : null,
          this.filterParams[FilterKey.Group].length > 0 ? {
            dealer: {
              group: {
                id : {
                  _in : this.filterParams[FilterKey.Group]
                }
              }
            }
          } : null,
          this.filterParams[FilterKey.Status].length > 0 && this.filterParams[FilterKey.Company].length === 0 ? {
            ip_campaign_companies: {
              companyStatus: {
                _in : this.filterParams[FilterKey.Status]
              },
              status: { _eq: 'active' }
            }
          } : null,
          this.filterParams[FilterKey.Company].length > 0 ? {
            ip_campaign_companies: {
              financial_company: {
                alias: {
                  _in : this.filterParams[FilterKey.Company]
                }
              },
              status: { _eq: 'active' },
            }
          } : null,
          this.filterParams[FilterKey.Status].length > 0 && this.filterParams[FilterKey.Company].length !== 0 ? {
            ip_campaign_companies: {
              companyStatus: {
                _in : this.filterParams[FilterKey.Status]
              },
              status: { _eq: 'active' },
              financial_company: {
                alias: {
                  _in : this.filterParams[FilterKey.Company]
                }
              }
            },
          } : null,
          this.FilterTimeUpdate && this.FilterTimeUpdate.from && this.FilterTimeUpdate.to ? {
            ip_campaign_companies: {
              createdAt: {
                _gte: this.FilterTimeUpdate.from,
                _lte: moment(this.FilterTimeUpdate.to).clone().endOf('month')
              }
            }
          } : null,
          text.trim() !== '' ? {
            _or : [..._searchModel]
          } : null
        ].filter((e) => !!e)
      }
    }
    ));
    this.$store.dispatch(ActionTypeZeroCampaign.FilterNoCache, {
      optionsHTTP: {
        orderBy: {
          ip_campaign_companies_aggregate: {
            max: {
              updatedAt: 'desc'
            }
          }
        }
      }
    });
    if (this.currentRole !== SystemRoleAlias.SaleAdmin) {
      this.$store.dispatch(ActionTypeZeroCampaign.GetStatisticParticipantList, {
        where: {
          ...this.authUser.role.alias !== SystemRoleAlias.SaleStaff ? {
            lastReviewer: {
              role: {
                alias: {
                  _eq: this.authUser.role.alias === SystemRoleAlias.SaleDepartmentPic ?
                  SystemRoleAlias.SaleStaff : this.authUser.role.alias === SystemRoleAlias.GroupLeader ?
                  SystemRoleAlias.SaleDepartmentPic : null
                }
              }
            }
          } : {},
          ...this.authUser.role.alias === SystemRoleAlias.SaleStaff ? {
            companyStatus: {
              _in: [
                'waiting_for_review',
                'request_stop_joining'
              ]
            },
            requesterStopId: {
              _is_null: true
            },
            _or: [
              {
                lastReviewerId: {
                  _is_null: true
                }
              },
              {
                lastReviewer: {
                  role: {
                    alias: {
                      _nin: [
                        'sale_staff',
                        'sale_department_pic'
                      ]
                    }
                  }
                }
              }
            ],
          } : {}
        },
        onSuccess: (data) => {
          this.statistic = {
            fe: data.financial_companies.find((e) => e.alias === 'FE').campaignCompanies_aggregate.aggregate.count,
            hd: data.financial_companies.find((e) => e.alias === 'HD').campaignCompanies_aggregate.aggregate.count,
            acs: data.financial_companies.find((e) => e.alias === 'ACS').campaignCompanies_aggregate.aggregate.count
          };
        }
      });

      return;
    }
    this.$store.dispatch(ActionTypeZeroCampaign.GetStatisticParticipantListSaleAdmin, {
      onSuccess: (data) => {
        this.statistic = {
          fe: data.financial_companies.find((e) => e.alias === 'FE').campaignCompanies_aggregate.aggregate.count,
          hd: data.financial_companies.find((e) => e.alias === 'HD').campaignCompanies_aggregate.aggregate.count,
          acs: data.financial_companies.find((e) => e.alias === 'ACS').campaignCompanies_aggregate.aggregate.count
        };
      }
    });
  }

}
