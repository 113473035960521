import { StatusCode } from 'root/models/Common';
import { ITrainingCourseCustom, TrainingCourseCustomDefault } from './TraningCourse';
import { userDefault, IUser } from './User';
import { ISimpleUser } from './User';

export interface IResultSchemes {
  grading: string;
  condition: string;
  paymentPerUnit: number;
  isQualify: boolean;
  groupId: string;
}

export function resultSchemaDefault(): IResultSchemes {
  return {
    grading: '',
    condition: '',
    paymentPerUnit: null,
    isQualify: null,
    groupId: ''
  };
}

export interface ITrainingResultForm {
  name: string;
  note: string;
  courseId: string;
  trainingCourse: ITrainingCourseCustom;
  result: string;
  technician: IUser;
  technicianId: string;
  resultSchemes: IResultSchemes;
  createdBy?: string;
  updatedBy?: string;
}
export function TrainingResultFormDefault(): ITrainingResultForm {
  return {
    name: '',
    note: '',
    result: '',
    courseId: '',
    trainingCourse: TrainingCourseCustomDefault(),
    technician: userDefault(),
    technicianId: '',
    resultSchemes: resultSchemaDefault()
  };
}
export interface ITrainingResult extends ITrainingResultForm {
  id: string;
  status: StatusCode;
  createdUser?: ISimpleUser;
  updatedUser?: ISimpleUser;
}

export function TrainingResultDefault(): ITrainingResult {
  return {
    ...TrainingResultFormDefault(),
    id: '',
    status: StatusCode.Active
  };
}
export interface ITrainingResultItem {
  courseItemId: string;
  technicianId: string;
  score: number;
  subjectName: string;
  value?: string;
  id?: string;
  createdBy?: string;
  updatedBy?: string;
}
export const trainingResultItemDefault = (): ITrainingResultItem => {
  return {
    courseItemId: '',
    score: null,
    subjectName: '',
    technicianId: ''
  };
};
export interface IResultItem {
  id?: string;
  courseItemId: string;
  technicianId: string;
  score: number;
  value?: string;
}
export const resultItemDefault = (): IResultItem => {
  return {
    score: null,
    courseItemId: '',
    technicianId: ''
  };
};
