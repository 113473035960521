import { PermissionType } from 'root/models';
import { RouteConfig } from 'vue-router';
import { CampaignDetail } from './Campaign/CampaignDetail';
import { CampaignList } from './Campaign/CampaignList';
import { Condition } from './Condition';
import { RedeemDetail } from './RedeemDetail';
import { RedemptionContainer } from './RedemptionContainer';
import { RedemptionList } from './RedemptionList';

export enum RedemptionRouterPath {
  Redemption = '/redemption/list',
  Compaign = '/redemption/campaign',
  CampaignDetail = '/redemption/campaign/detail/:id',
  RedeemtionDetail = '/redemption/detail/:id',
  Condition = '/redemption/condition'
}
export enum RedemptionRouterName {
  Redemption = 'List Redemption',
  Compaign = 'List Compaign',
  CampaignDetail = 'Campaign Detail',
  RedeemtionDetail = 'Redeemtion Detail',
  Condition = 'Campaign Condition'
}

export const RedemptionRouter: RouteConfig = {
  path: RedemptionRouterPath.Redemption,
  component: RedemptionContainer,
  meta: {
    permission: PermissionType.Redemption
  },
  children: [
    {
      path: RedemptionRouterPath.Redemption,
      component: RedemptionList,
      name: RedemptionRouterName.Redemption,
      meta: {
        permission: PermissionType.Redemption
      },
    },
    {
      path: RedemptionRouterPath.Compaign,
      component: CampaignList,
      name: RedemptionRouterName.Compaign,
      meta: {
        permission: PermissionType.Redemption
      },
    },
    {
      path: RedemptionRouterPath.Condition,
      component: Condition,
      name: RedemptionRouterName.Condition,
      meta: {
        permission: PermissionType.Redemption
      },
    },
    {
      path: RedemptionRouterPath.CampaignDetail,
      component: CampaignDetail,
      name: RedemptionRouterName.CampaignDetail,
      meta: {
        permission: PermissionType.Redemption
      },
    },
    {
      path: RedemptionRouterPath.RedeemtionDetail,
      component: RedeemDetail,
      name: RedemptionRouterName.RedeemtionDetail,
      meta: {
        permission: PermissionType.Redemption
      },
    },
  ]
};
