import './styles.scss';

// import moment from 'moment';
import { ElUpload } from 'element-ui/types/upload';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import { ActionTypeInvoice, MutationTypeInvoice } from 'root/admin/Invoice/Store/types';
import { IProductState } from 'root/admin/Product/Store';
import { SystemRoleAlias } from 'root/admin/User/Store/types';
import { DKGqlClient } from 'root/api/graphql/Client';
import { IPaginationParams } from 'root/api/graphql/Core';
import { Avatar } from 'root/components/Avatar';
import { formatCurrency, fullNameUser } from 'root/helpers';
import { IAddress, IBreadcrumb, IFilterInput } from 'root/models';
import { IUser } from 'root/models/User';
import { pathUpload, uploadToFirebase } from 'root/services';
import { FirebaseAuthService } from 'root/services/auth/firebase';
import { IState, MutationType, TypeAlert } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import { BranchAndGroupRouterName, BranchAndGroupRouterPath } from '../..';
import { ActionTypeBranchManage } from '../../BranchManage/Store/types';

const enum FilterKey {
  DeviceType = 'deviceTypeId'
}

@Component({
  template: require('./view.html'),
  components: {
    avatar: Avatar,
    // 'user-detail-modal': UserDetailModal,
  },
  computed: {
    ...mapState({
      authUser: (state: IState) => state.global.authUser,
      data: (state: IState) => state.invoice.data,
      pagination: (state: IState) => state.invoice.pagination,
      loading: (state: IState) => state.invoice.loading,
      // filterParams: (state: IState) => state.group.filterParams,
      deviceType: (state: IState) => state.deviceType.data,
      isRefesh: (state: IState) => state.global.isRefesh
    }),
    selectedStatus() {
      const filterParams = [];

      if (!filterParams) {
        return [];
      }

      return [{
        key: 'isCreatedByAppleReviewer',
        value: []
      }];
    }
  },
  watch: {
    type() {
      this.paramsFilter = {
        text: ''
      };
      // this.$store.commit(MutationTypeUser.ClearUserList);
      this.$store.commit(MutationType.SetBreadcrumb, this.$t(this.type));
      this.fetchData();
    }
  },
})

export class GroupInvoiceList extends Vue {
  public get serviceId(): string {
    return this.$route.params.id;
  }
  public get columns() {
    return [
      {
        prop: 'invoiceNumber',
        label: 'Invoice Number',
        width: 180,
        formatter: (model: any) => {
          if (model.code) {
            return model.code || '--';
          }
        },
      },
      {
        prop: 'dealerCode',
        label: 'Dealer Code',
        width: 180,
        formatter: (model: any) => {
          if (model.dealer) {
            return model.dealer.dealerCode || '--';
          }
        },
      },
      {
        prop: 'groupLeaders',
        label: 'Department Leaders',
        width: 250,
        formatter: (model: any) => {
          if (model.groupLeaders && model.groupLeaders.length) {
            return model.groupLeaders.map((it) => it.name).join(', ') || '--';
          }
        },
      },
      {
        prop: 'name',
        label: 'Dealer Name',
        width: 180,
        formatter: (model: any) => {
          if (model.dealer) {
            return model.dealer.name || '--';
          }
        },
      },
      {
        prop: 'date',
        label: 'Date Of Issue',
        width: 180,
        formatter: (model: any) => {
          return model.date ? moment(model.date).format('DD/MM/YYYY') : '--';
        }
      },
      // {
      //   prop: 'address',
      //   label: 'Address',
      //   width: 220,
      //   formatter: (model: any) => {
      //     return model.dealer && model.dealer.address ? this.displayAddress(model.dealer.address) : '--';
      //   }
      // },
      // {
      //   prop: 'tax',
      //   label: 'TAX IDENTIFICATION NUMBER',
      //   width: 180,
      //   formatter: (model: any) => {
      //     return model.taxNumber ? model.taxNumber : '--';
      //   }
      // },
      // {
      //   prop: 'orderNumber',
      //   label: 'Order Number',
      //   width: 180,
      //   formatter: (model: any) => {
      //     return model.orderNumber ? model.orderNumber : '--';
      //   }
      // },
      {
        prop: 'totalProcuduct',
        label: 'Total Product',
        width: 130,
        formatter: (model: any) => {
          if (model.totalAmount) {
            return model.totalAmount || '--';

          }

          return '--';
        }
      },
      // {
      //   prop: 'totalAmount',
      //   label: 'Total Amount',
      //   width: 180,
      //   formatter: (model: any) => {
      //     if (model.total) {
      //       return model.total ? this.formatCurrency(model.total) : '--';
      //     }

      //     return '--';
      //   }
      // },
      {
        prop: 'createdAt',
        label: 'Imported At',
        formatter: (model: any) => {
          return moment(model.createdAt).format('HH:mm, DD/MM/YYYY') || '--';
        },
        width: 180
      },
    ];

  }

  public get inputSearchPlaceholder(): string {

    return `Search by Name, Email`;
  }
  public get acceptImport() {
    if (this.authUser && (this.authUser.role.alias !== SystemRoleAlias.TeamLeader &&
      this.authUser.role.alias === SystemRoleAlias.Staff)) {
      return false;
    }
    if (this.authUser && (this.authUser.role.alias !== SystemRoleAlias.Staff &&
      this.authUser.role.alias === SystemRoleAlias.TeamLeader)) {
      return false;
    }

    return true;
  }

  public get actions() {

    return [];
  }
  public get dataFilter() {
    const _deviceType: IFilterInput[] = this.deviceType.reduce((res, item) => {
      res.push({
        key: item.id,
        value: item.id,
        name: item.name
      });

      return res;
    }, []);

    return [
      {
        key: FilterKey.DeviceType,
        name: this.$t('field.deviceType'),
        value: _deviceType
      },
    ];
  }
  public get selectedFilter() {
    const _filterParams = cloneDeep(this.filterParams);

    return [
      {
        key: FilterKey.DeviceType,
        value: _filterParams[FilterKey.DeviceType]
      },
    ];
  }

  public authUser: IUser;
  public pagination: IPaginationParams;
  public data: IProductState[];
  public deviceType: any[];
  public visible: boolean = false;
  public isSample: boolean = false;
  public userId: string = '';
  public visibleDetail: boolean = false;
  public popupVisible: boolean = false;
  public filterParams = {
    [FilterKey.DeviceType]: [],
  };
  public searchText: string = '';
  public isRefesh: boolean;
  public fileUpload: any;
  public fileUploaded=[];
  public fileList = [];
  public $refs: {
    upload: ElUpload;
  };
  public formatCurrency = formatCurrency;
  public loadingUpload: boolean = false;
  public visibleImport: boolean = false;
  public UserType = {
    Customer: 'customer',
    System: 'system',
  };
  public type: any;
  // tslint:disable-next-line:max-line-length
  private sampleUrl = `https://firebasestorage.googleapis.com/v0/b/daikin-sale-dev.appspot.com/o/invoice%2F16219302162231617247582343Template_Invoice__Final___1___2_.xlsx?alt=media&token=c6661be1-c2eb-4d91-ad45-73e20af925f0`;
  private pathUploadAvatar: pathUpload = pathUpload.INVOICE;
  public displayAddress(model: IAddress) {
    return model && model.province ? this.$store.getters['getAddressString'](model) : '';
  }

  public handleSearch() {
    this.fetchData();
  }
  public applyFilter() {
    this.fetchData();
  }
  public downloadSample() {
    window.open(
      this.sampleUrl,
      '_blank'
    );
  }
  public showModal() {
    this.visibleImport = true;
  }
  public closeModalImport() {
    this.visibleImport = false;
    this.$refs.upload.clearFiles();
    this.fileList = [];
  }
  public pushFileUpload(file: any) {
    const f = this.fileList;
    if (this.fileList.length > 0) {
      const _file = this.fileList.filter((it) => it.name === file.name);
      if (_file && _file.length > 0) {
        this.$refs.upload.clearFiles();
        const message = 'File has been exits';
        this.$store.commit(MutationType.OpenTopAlert, {
          message,
          type: TypeAlert.Warning
        });

        return this.$refs.upload.$data.uploadFiles = f;
      }

      return this.fileList.push(file);
    }

    f.push(file);

    return this.fileList = f;
  }
  public onRemove(file: any, fileList: any) {
    console.log(file);
    this.fileList = fileList;
  }
  public async submitFile() {
    // tslint:disable-next-line: whitespace
    try {
      this.loadingUpload = true;
      // const uploadedFile = <any> [];
      const token = await DKGqlClient().user.getFirebaseAuthenticateToken();
      await FirebaseAuthService().signInWithCustomToken(token.authenticate);
      const uploadedFile = await Promise.all(this.fileList.map(async (it) => {
        const _file = await uploadToFirebase(it.raw, this.pathUploadAvatar);

        // return _file ? uploadedFile.push(_file) : uploadedFile;
        // tslint:disable-next-line:no-var-before-return
        return _file;
      }));

      if (uploadedFile.length > 0) {
        const form = {
          createdBy: this.authUser.id,
          url: uploadedFile
        };
        this.$store.dispatch(ActionTypeBranchManage.InsertInvoice, {
          form,
          onSuccess: () => {
            this.loadingUpload = false;
            const message = 'Import invoice successfully';
            this.$store.commit(MutationType.OpenTopAlert, {
              message,
              type: TypeAlert.Success
            });
            this.visibleImport = false;
            this.fileList = [];
            this.fileUploaded = [];
            this.$refs.upload.clearFiles();
            this.fetchData();
          },
          onFailure: () => {
            this.loadingUpload = false;

          }});
      }

    } catch (error) {
      console.log(error);
    }

  }

  public clickDetail(id: string) {
    this.$router.push({
      name: BranchAndGroupRouterName.DetailBranch,
      params: {
        id
      }});
  }
  public handlePaginationChange(value) {
    if (typeof value === 'number') {
      this.$store.commit(MutationTypeInvoice.PaginationChange, {
        ...this.pagination,
        limit: value
      });
    } else {
      this.$store.commit(MutationTypeInvoice.PaginationChange, {
        ...this.pagination,
        ...value
      });
    }
    this.fetchData();
  }

  public formatFullNameUser(model) {
    return fullNameUser(model);
  }

  protected mounted() {
    this.$nextTick(() => {
      const breadcrumb: IBreadcrumb[] = [
        {
          label: 'title.managedepartment',
          path: BranchAndGroupRouterPath.ListGroupManage,
          current: false
        },
        {
          label: 'title.view_details',
          path: null,
          current: true
        }
      ];
      this.$store.commit(MutationType.SetBack);
      this.$store.commit(MutationType.SetBreadcrumb, breadcrumb);
      // this.$store.commit(MutationTypeUser.PaginationReset);
      if (!this.isRefesh) {
        this.fetchData();

      }
    });
  }
  protected beforeDestroy() {
    this.$store.commit(MutationType.ClearBreadcrumb);
  }

  private fetchData() {
    const text = cloneDeep(this.searchText);
    const _filter = <any> {
      groupId: this.serviceId
    };
    if (text) {
      _filter.search = text;
    }
    this.$store.dispatch(ActionTypeInvoice.GET_LIST_INVOICE, {
      form: {
        filter: _filter,
        pagination: {
          size: this.pagination.limit,
          page: text ? 1 : this.pagination.page
        }
      }
    });
  }
}
