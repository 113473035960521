import { stripObject } from '@hgiasac/helper';
import { ElUpload } from 'element-ui/types/upload';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import { ActionTypeBranchManage } from 'root/admin/BranchAndGroup/BranchManage/Store/types';
import { ActionTypeDeviceType } from 'root/admin/DeviceType/Store/types';
import { ActionTypeProduct, MutationTypeProduct } from 'root/admin/Product/Store/types';
import { ProductDetailModal } from 'root/admin/ProductManage/Components/ProductDetail';
import { ActionTypeProductTrans } from 'root/admin/ProductTransaction/Store/types';
import { SystemRoleAlias } from 'root/admin/User/Store/types';
import { DKGqlClient } from 'root/api/graphql/Client';
import { IPaginationParams } from 'root/api/graphql/Core';
import { Avatar } from 'root/components/Avatar';
import { IFilterInput, IUser } from 'root/models';
import { pathUpload, uploadToFirebase } from 'root/services';
import { FirebaseAuthService } from 'root/services/auth/firebase';
import { IState, MutationType, TypeAlert } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import './styles.scss';

const enum FilterKey {
  DeviceType = 'deviceType'
}
@Component({
  template: require('./view.html'),
  components: {
    // editor: ProductEditor,
    avatar: Avatar,
    'product-detail-modal': ProductDetailModal
    // 'change-pass-modal': ChangePasswordDialog,
  },
  computed: {
    ...mapState({
      data: (state: IState) => state.product.data,
      pagination: (state: IState) => state.product.pagination,
      loading: (state: IState) => state.product.loading,
      isRefesh: (state: IState) => state.global.isRefesh,
      deviceType: (state: IState) => state.deviceType.data,
      authUser: (state: IState) => state.global.authUser,
    }),
  }
})
export class BranchProductList extends Vue {
  public get serviceId(): string {
    return this.$route.params.id;
  }
  public get columns() {
    return [
      {
        label: 'Device Name',
        formatter: (model: any) => {
          return model.name;
        },
        avatar: {
          formatter: (model: any) => {
            return model ? model : null;
          }
        },
        width: 250
      },
      {
        prop: 'serialNumber',
        label: 'Serial Number',
        width: 150,
        formatter: (model: any) => {
          return model.serialNumber || '--';
        }
      },
      {
        prop: 'modelNumber',
        label: 'Model Number',
        width: 250,
        formatter: (model: any) => {
          return model.modelNumber || '--';
        }
      },
      {
        prop: 'deviceType',
        label: 'Device Type',
        width: 180,
        formatter: (model: any) => {
          if (model.deviceType) {
            return model.deviceType.name || '--';
          }

          return '--';
        }
      },
      {
        prop: 'createdBy',
        label: this.$t('created.by'),
        width: 180,
        formatter: (model: any) => {
          if (model) {
            return model.createdUser ? model.createdUser.name : model.createdDealer ?
            model.createdDealer.name : '--';
          }

          return '--';
        }
      },
      {
        prop: 'createdAt',
        label: this.$t('created.at'),
        formatter: (model: any) => {
          if (model.createdAt) {
            return moment(model.createdAt).format('DD/MM/YYYY');
          }

          return '--';
        },
        width: 180
      }
    ];
  }

  public get actions() {
    return ['view'];
  }
  // public get type(): ProductType {
  //   return <ProductType> this.$route.name;
  // }
  // public handleCommand(command) {
  //   if (command === 'addnew') {
  //     this.goAddNewRouter();
  //   } else {
  //     this.showUploadDialog();
  //   }
  // }
  // public showUploadDialog() {
  //   this.isSample = true;
  // }

  public get inputSearchPlaceholder(): string {
    return `Search by Name, Email`;
  }
  public get dataFilter() {
    const _deviceType: IFilterInput[] = this.deviceType.reduce((res, item) => {
      res.push({
        key: item.id,
        value: item.id,
        name: item.name
      });

      return res;
    }, []);

    return [
      {
        key: FilterKey.DeviceType,
        name: this.$t('field.deviceType'),
        value: _deviceType
      }
    ];
  }
  public get selectedFilter() {
    const _filterParams = cloneDeep(this.filterParams);

    return [
      {
        key: FilterKey.DeviceType,
        value: _filterParams[FilterKey.DeviceType]
      }
    ];
  }
  public authUser: IUser;
  public groups: any[];
  public roles: any[];
  public pagination: IPaginationParams;
  public data: any[];
  public visible: boolean = false;
  public popupVisible: boolean = false;
  public isSample: boolean = false;
  public productId: string = '';
  public visibleDetail: boolean = false;
  public searchText: string = '';
  public filterParams = {
    [FilterKey.DeviceType]: []
  };
  public deviceType: any[];
  public isRefesh: boolean;
  // public roleSystem: any[];
  public ProductType = {
    Customer: 'customer',
    System: 'system'
  };
  public file: any;
  public fileUpload: any;
  public fileUploaded= [];
  public fileList = [];
  public loadingUpload: boolean = false;
  public type: any;
  public $refs: {
    upload: ElUpload;
  };
  public visibleImport: boolean = false;
  private pathUploadAvatar: pathUpload = pathUpload.INVOICE;
  // tslint:disable-next-line:max-line-length
  private sampleUrl = `https://firebasestorage.googleapis.com/v0/b/daikin-sale-dev.appspot.com/o/invoice%2F16219302162231617247582343Template_Invoice__Final___1___2_.xlsx?alt=media&token=c6661be1-c2eb-4d91-ad45-73e20af925f0`;

  public getRoleSystem() {
    let roleSystem = [];
    if (this.roles && this.roles.length && this.groups && this.groups.length) {
      const _groupId = this.groups.map((it) => it.id);
      roleSystem = this.roles.filter((item) =>
        _groupId.includes(item.systemGroupId)
      );
    }

    return roleSystem;
  }

  public closeDialogDetail() {
    this.productId = '';
    this.visibleDetail = false;
  }

  public createSuccess() {
    this.visible = false;
    this.fetchData();
  }
  public changePassSuccess() {
    this.popupVisible = false;
    this.fetchData();
  }

  public applyFilter() {
    this.fetchData();
  }
  public goAddNewRouter() {
    this.visible = true;
    this.productId = '';
  }
  public closeDialog() {
    this.isSample = false;
    this.fetchData();
  }
  public handleSearch() {
    this.$store.commit(MutationTypeProduct.PaginationChange, {
      ...this.pagination,
      page: 1
    });
    this.fetchData();
  }

  public clickDetail(id: string) {
    this.productId = id;
    this.visibleDetail = true;
  }
  public handleUpdate(id: string) {
    this.visible = true;
    this.productId = id;
  }

  public changeFilter(model) {
    const deviceType = model.find((e) => e.key === FilterKey.DeviceType);
    const nextFilter = {
      [FilterKey.DeviceType]: deviceType ? deviceType.value : []
    };
    this.filterParams = {
      ...cloneDeep(this.filterParams),
      ...nextFilter
    };
    this.fetchData();
  }
  public showModal() {
    this.visibleImport = true;
  }
  public closeModalImport() {
    this.visibleImport = false;
    this.$refs.upload.clearFiles();
    this.fileList = [];
  }
  public downloadSample() {
    window.open(
      this.sampleUrl,
      '_blank'
    );
  }
  public pushFileUpload(file: any) {
    const f = this.fileList;
    if (this.fileList.length > 0) {
      const _file = this.fileList.filter((it) => it.name === file.name);
      if (_file && _file.length > 0) {
        this.$refs.upload.clearFiles();
        const message = 'File has been exits';
        this.$store.commit(MutationType.OpenTopAlert, {
          message,
          type: TypeAlert.Warning
        });

        return this.$refs.upload.$data.uploadFiles = f;
      }

      return this.fileList.push(file);
    }

    f.push(file);

    return this.fileList = f;
  }
  public onRemove(file: any, fileList: any) {
    console.log(file);
    this.fileList = fileList;
  }
  public async submitFile() {
    // tslint:disable-next-line: whitespace
    try {
      this.loadingUpload = true;
      // const uploadedFile = <any> [];
      const token = await DKGqlClient().user.getFirebaseAuthenticateToken();
      await FirebaseAuthService().signInWithCustomToken(token.authenticate);
      const uploadedFile = await Promise.all(this.fileList.map(async (it) => {
        const _file = await uploadToFirebase(it.raw, this.pathUploadAvatar);

        // return _file ? uploadedFile.push(_file) : uploadedFile;
        // tslint:disable-next-line:no-var-before-return
        return _file;
      }));

      if (uploadedFile.length > 0) {
        const form = {
          createdBy: this.authUser.id,
          url: uploadedFile
        };
        this.$store.dispatch(ActionTypeBranchManage.InsertInvoice, {
          form,
          onSuccess: () => {
            this.loadingUpload = false;
            const message = 'Import invoice successfully';
            this.$store.commit(MutationType.OpenTopAlert, {
              message,
              type: TypeAlert.Success
            });
            this.visibleImport = false;
            this.fileList = [];
            this.fileUploaded = [];
            this.$refs.upload.clearFiles();
            this.fetchData();
          },
          onFailure: () => {
            this.loadingUpload = false;

          }});
      }

    } catch (error) {
      console.log(error);
    }

  }

  public handlePaginationChange(value) {
    if (typeof value === 'number') {
      this.$store.commit(MutationTypeProduct.PaginationChange, {
        ...this.pagination,
        size: value
      });
    } else {
      this.$store.commit(MutationTypeProduct.PaginationChange, {
        ...this.pagination,
        ...value
      });
    }
    this.fetchData();
  }

  protected mounted() {
    this.$nextTick(() => {
      // tslint:disable-next-line:early-exit
      if (!this.isRefesh) {
        this.$store.commit(MutationType.SetBreadcrumb, this.$t('title.product'));
        this.$store.dispatch(ActionTypeDeviceType.FilterNoCache);

        this.fetchData();
      }
    });
  }
  protected beforeDestroy() {
    this.$store.commit(MutationType.ClearBreadcrumb);
  }

  private fetchData() {
    const text = cloneDeep(this.searchText);

    this.$store.commit(
      MutationTypeProduct.FilterChange,
      stripObject({
        branchId: this.serviceId,
        _and:
          text.trim() !== '' &&
          this.filterParams[FilterKey.DeviceType].length > 0
            ? {
              mode: 'special',
              data: {
                _and: {
                  [FilterKey.DeviceType]: {
                    id: {
                      _in: this.filterParams[FilterKey.DeviceType]
                    }
                  },
                  _or: [
                    {
                      name: {
                        _like: `%${text}%`
                      }
                    },
                    {
                      serialNumber: {
                        _like: `%${text}%`
                      }
                    },
                    {
                      modelNumber: {
                        _like: `%${text}%`
                      }
                    }
                  ]
                }
              }
            }
            : text.trim() !== '' &&
            this.filterParams[FilterKey.DeviceType].length <= 0
            ?
              {
                mode: 'special',
                data: {
                  _or: [
                    {
                      name: {
                        _like: `%${text}%`
                      }
                    },
                    {
                      serialNumber: {
                        _like: `%${text}%`
                      }
                    },
                    {
                      modelNumber: {
                        _like: `%${text}%`
                      }
                    }
                  ]
                }
              } : this.filterParams[FilterKey.DeviceType].length > 0 ? {
                mode: 'special',
                data: {
                  _and: {
                    [FilterKey.DeviceType]: {
                      id: {
                        _in: this.filterParams[FilterKey.DeviceType]
                      }
                    },
                  }
                }
              } : null
      })
    );
    this.$store.dispatch(ActionTypeProduct.FilterNoCache, {
      optionsHTTP: {
        orderBy: {
          updatedAt: 'desc'
        }
      }
    });
    if (this.authUser && (this.authUser.role.alias === SystemRoleAlias.BranchManager ||
      this.authUser.role.alias === SystemRoleAlias.GroupLeader)) {
      return this.$store.dispatch(ActionTypeProductTrans.GetGroupView, {
        where: {}
      });
    }

    return;
  }
}
