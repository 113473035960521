export enum ActionTypeDealer {
  GetListDealer = 'getDealers',
  GetListSaleman = 'getSalemans',
  GetTargetDetail = 'getTargetDetail',
  FilterNoCache = 'dealerFilterNoCache',
  FetchMany = 'dealerFetchMany',
  UpdateStatusDealer = 'updateStatusDealer',
  ChangePassDealer = 'changePassDelaer',
  CreateDealer = 'createDealer',
  GetById = 'dealerGetById',
  UpdateDealer = 'updateDealer',
  UpdateSubDealer= 'updateSubDealer',
  GetUpgradeRequest = 'dealerGetUpgradeRequest',
  CreateUpgradeRequest = 'createUpgradeRequest',
  ImportFileDealer = 'importFileDealer',
  GetListSubDealer = 'getListSubDealer',
  ImportFileSubDealer = 'importFileSubDealer',
  ExportDealer = 'exportDealer',
  GetRoleId = 'getRoleId',
  GetBankInfo = 'getBankInfo',
  GetDealerType = 'getDealerType'
}
export enum MutationTypeDealer {
  GetUpgradeRequestMutation = 'GetUpgradeRequest',
  DealerPaginationChange = 'dealerPaginationChange',
  Loading = 'dealerLoading',
  Loaded = 'dealerLoaded',
  PaginationReset = 'dealerPaginationReset',
  ResetState = 'dealerResetState',
  GetListSubDealer = 'getListSubDealer',
  SetBank = 'setBank',
  SetDealerType = 'setDealerType'
}
export enum DealerTab {
  Inventory = 'Inventory',
  Transaction = 'Transaction',
  PointHistory = 'PointHistory',
  SaleRouts = 'SaleRouts',
  ActiveEWarranty = 'ActiveEWarranty',
  DealerShop = 'DealerShop',
  Staff = 'Account',
  Campaign = 'Campaign'
}
export enum TransStatus {
  SaleIn = 'sale_in',
  SaleOut = 'sale_out'
}
export enum TypeRole {
  Admin = 'admin',
  DaikinDealer = 'Daikin Dealer',
  Freelancer = 'freelancer',
  SaleAdmin = 'Sale Admin',
  SubDealer = 'Sub Dealer',
  GroupLeader = 'Group Leader',
  SaleManager = 'Sale Manager',
  TeamLeader = 'Team Leader',
  RegionDirector = 'Region Director',
  Staff = 'Staff',
  SaleStaff = 'Sale Staff',
  BranchPIC = 'Branch PIC',
  BranchManager = 'Branch Manager',
  SaleDepartmentPic= 'Sale Department PIC',
  SalePlanning = 'Sales Department PIC'
}
export enum EUpgradeAction {
  NewRequest = 'requesting',
  Pending = 'pending',
  Done = 'done',
  Approved = 'approved',
  Rejected = 'rejected'
}
export enum ETypePointHistory {
  Redeem = 'redeem',
  Expired = 'expired',
  Earn = 'earn'
}
