import { crudMutations, CRUDState, ICRUDState } from 'root/store/helpers';

export interface IRoleState extends ICRUDState<any> {
  loading: boolean;
}

export const defaultState: IRoleState = {
  ...CRUDState(),
  loading: false,
};

export const mutations = {
  ...crudMutations('role')
};
