import { TabPane } from 'element-ui';
import { ActionTypeRegion, RegionManageTab } from 'root/admin/Region/Store/types';
import { parseUTCToTime, FormatDateFullTime } from 'root/helpers';
import { IAddress, IBreadcrumb, IServiceCategory, IWorkMaster } from 'root/models';
import { IState, MutationType } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import { BranchAndGroupRouterName, BranchAndGroupRouterPath } from '../../';
import { RegionEditor } from '../RegionEditor';
// import { ActionTypeBranchManage, BranchManageTab } from '../Store/types';
import './styles.scss';

@Component({
  template: require('./view.html'),
  components: {
    editor: RegionEditor
  },
  computed: {
    ...mapState({
      branchManage: (state: IState) => state.branchManage.data,
      detail: (state: IState) => state.region.detail,
      isRefesh: (state: IState) => state.global.isRefesh,
      typeCollapse: (state: IState) => state.global.typeCollapse
    }),
    // detail() {

    //   return this.$store.getters.getBranhManageDetail(this.serviceId);
    // }
  }
})
export class RegionDetail extends Vue {
  public get serviceId(): string {
    return this.$route.params.id;
  }
  public get activeTab() {
    return this.$route.name;
  }
  public set activeTab(_value: string) {
    //
  }
  public FormatDateFullTime = FormatDateFullTime;
  public formatDate = parseUTCToTime;
  public visibleEditor: boolean = false;
  public branchManage: any[];
  public repairs: IWorkMaster[];
  public category: IServiceCategory;
  public fileUpload: File[] = [];
  public isRefesh: boolean;
  // public detail: any;
  public back() {
    this.$router.push(BranchAndGroupRouterName.ListBranch);
  }
  public closeDialog() {
    this.visibleEditor = false;
  }
  public goEdit() {
    this.visibleEditor = true;
  }
  public displayAddress(model: IAddress) {
    return model && model.province ? this.$store.getters['getAddressString'](model) : '';
  }
  public formatterDepartmentQty(model: any) {
    let count = 0;
    model.map((item) => {
      count += item.groups_aggregate.aggregate.count;
    });

    return count;
  }
  public changeTab(tab: TabPane) {
    switch (tab.name) {
    case RegionManageTab.RegionBranch:
      this.$router.push({
        name: BranchAndGroupRouterName.RegionBranch,
        params: {
          id: this.serviceId
        }
      });
      break;
    case RegionManageTab.RegionBranchLeader:
      this.$router.push({
        name: BranchAndGroupRouterName.RegionBranchLeader,
        params: {
          id: this.serviceId
        }
      });
      break;
    case RegionManageTab.RegionDealer:
      this.$router.push({
        name: BranchAndGroupRouterName.RegionDealer,
        params: {
          id: this.serviceId
        }
      });
      break;

    default:
      break;
    }
  }
  protected mounted() {
    const breadcrumb: IBreadcrumb[] = [
      {
        label: 'title.manageregion',
        path: BranchAndGroupRouterPath.ListRegion,
        current: false
      },
      {
        label: 'title.view_details',
        path: null,
        current: true
      }
    ];
    this.$store.commit(MutationType.SetBack);
    this.$store.commit(MutationType.SetBreadcrumb, breadcrumb);
    this.$store.commit(MutationType.SetShowCollapse);
    this.$store.commit(MutationType.SetTypeCollapse, true);
    !this.isRefesh && this.fetchData();
  }
  protected beforeDestroy() {
    this.$store.commit(MutationType.ClearShowCollapse);
    this.$store.commit(MutationType.ClearBreadcrumb);
  }
  private fetchData() {

    this.$store.dispatch(ActionTypeRegion.RegionGetById, {
      id: this.serviceId,
      onSuccess: () => {
          //
      },
      onFailure: () => {
          //
      }
    });
  }
}
