import { stripObject } from '@hgiasac/helper';
import { assign } from 'lodash';
import { gqlClient } from './index';
import { ICRUDQuery } from './query';
import {
  convertFilterParams,
  gqlQueryOption,
  IGqlQueryOption,
  IPaginationParams
} from './utils';

export interface ICRUDGqlService<M> {
  filter(
    pagingParams?: IPaginationParams,
    options?: IGqlQueryOption
  ): Promise<any>;
  fetchMany(options?: IGqlQueryOption, variables?: any): Promise<M[]>;
  getById(id: string): Promise<M>;
  update(id: string, form: any): Promise<M>;
  create(form: any): Promise<M>;
  createMany(form: any[]): Promise<M[]>;
  deleteById(id: string): Promise<any>;
}

export function CRUDGqlService<M>(
  prefix: string,
  queries: ICRUDQuery
): ICRUDGqlService<M> {
  async function filter(
    pagingParams: IPaginationParams,
    options: IGqlQueryOption
  ): Promise<any> {
    const { page, limit } = pagingParams,
      { fetchPolicy, filterParams, orderBy } = assign(
        gqlQueryOption(),
        options
      );

    const params = filterParams ? filterParams : {};
    const result = await gqlClient.query({
      fetchPolicy,
      query: queries.FILTER,
      variables: stripObject({
        limit,
        orderBy,
        offset: (page - 1) * limit,
        filter: {
          ...convertFilterParams(params)
        }
      }),
      context: {
        debounceKey: prefix
      }
    });
    if (result.errors) {
      throw result.errors;
    }

    return {
      data: result.data[prefix],
      pagination: {
        limit,
        page,
        total: result.data[`${prefix}_aggregate`].aggregate.count
      }
    };
  }

  async function fetchMany(
    options?: IGqlQueryOption,
    variables?: any
  ): Promise<M[]> {
    const { fetchPolicy } = assign(gqlQueryOption(), options);
    variables = variables ? variables : {};
    const result = await gqlClient.query({
      fetchPolicy,
      variables,
      query: queries.FETCH_MANY
    });

    if (result.errors) {
      throw result.errors;
    }

    return result.data[prefix];
  }

  async function getById(id: string): Promise<M> {
    const result = await gqlClient.query({
      query: queries.GET_BY_ID,
      fetchPolicy: 'network-only',
      variables: {
        id
      }
    });

    if (result.errors) {
      throw result.errors;
    }

    return result.data[`${prefix}`];
  }

  async function update(id: string, form: any): Promise<M> {
    const result = await gqlClient.mutate({
      mutation: queries.UPDATE,
      variables: {
        id,
        form
      }
    });
    if (result.errors) {
      throw result.errors;
    }

    return result.data[`update_${prefix}`].returning[0];
  }
  async function deleteById(id: string): Promise<M> {
    const result = await gqlClient.mutate({
      mutation: queries.DELETE,
      variables: {
        id
      }
    });
    if (result.errors) {
      throw result.errors;
    }

    return result.data[`update_${prefix}`].returning[0];
  }
  async function create(form: any): Promise<M> {
    const result = await gqlClient.mutate({
      mutation: queries.CREATE,
      variables: {
        form
      }
    });
    if (result.errors) {
      throw result.errors;
    }

    return result.data[`insert_${prefix}`].returning[0];
  }
  async function createMany(form: any[]): Promise<M[]> {
    const result = await gqlClient.mutate({
      mutation: queries.CREATE,
      variables: {
        form
      }
    });
    if (result.errors) {
      throw result.errors;
    }

    return result.data[`insert_${prefix}`].returning;
  }

  return {
    filter,
    getById,
    update,
    create,
    deleteById,
    fetchMany,
    createMany
  };
}
