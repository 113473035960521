import { PermissionType } from 'root/models';
import { RouteConfig } from 'vue-router';
import { CampaignConfiguration } from './CampaignConfiguration';
import { CampaignStatistic } from './CampaignStatistic';
import { ClassifiedRequest } from './ClassifiedRequest';
import { NewRaCampaignDetail } from './Components/NewRACampaignDetail';
import { RequestDetail } from './Components/RequestDetail';
import { ManageExport } from './ManageExport';
import { NewRACampaignContainer } from './NewRACampaignContainer';
import { NewRACampaignShop } from './NewRACampaignShop';
import { ParticipantList } from './ParticipantList';
import { RequestList } from './RequestList';
import { SubDealerCampaignList } from './SubDealerCampaignList';
import { SubdealerRegistration } from './SubdealerRegistration';

export enum NewRaCampaignTab {
  Default = 'default',
  Registration = 'registration',
  Participant = 'participant',
  Shop = 'shop',
  SubDealer = 'subDealer',
  RequestList = 'requestList',
  Config = 'config',
  Statistic = 'statistic',
  Export = 'export',
  Classified = 'classified'
}
export enum NewRaCampaignRouterPath {
  Default = '/2023-campaign',
  Registration = '/2023-campaign/registration',
  Participant = '/2023-campaign/participant',
  CampaignDetail = '/2023-campaign/detail/:id',
  Shop = '/2023-campaign/shop',
  SubDealer = '/2023-campaign/subDealer',
  RequestList = '/2023-campaign/request-list',
  RequestDetail = '/2023-campaign/request-list/:id',
  Config = '/2023-campaign/configuration',
  Statistic = '/2023-campaign/statistic',
  Export = '/2023-campaign/export',
  Classified = '/2023-campaign/classified-request'
}
export enum NewRaCampaignRouterName {
  Default = 'default',
  Registration = 'registration',
  Participant = 'participant',
  CampaignDetail = 'campaignDetail',
  Shop = 'shop',
  SubDealer = 'subDealer',
  RequestList = 'requestList',
  RequestDetail = 'requestDetail',
  Config = 'config',
  Statistic = 'statistic',
  Export = 'export',
  Classified = 'classified'
}
export const NewRaCampaignRouter: RouteConfig = {
  path: '/2023-campaign',
  component: NewRACampaignContainer,
  redirect: NewRaCampaignRouterPath.Default,
  meta: {
    permission: PermissionType.NewRACampaign
  },
  children: [
    {
      path: NewRaCampaignRouterPath.Registration,
      component: SubdealerRegistration,
      name: NewRaCampaignRouterName.Registration,
      meta: {
        permission: PermissionType.NewRACampaign
      },
    },
    {
      path: NewRaCampaignRouterPath.Participant,
      component: ParticipantList,
      name: NewRaCampaignRouterName.Participant,
      meta: {
        permission: PermissionType.NewRACampaign
      },
    },
    {
      path: NewRaCampaignRouterPath.CampaignDetail,
      component: NewRaCampaignDetail,
      name: NewRaCampaignRouterName.CampaignDetail,
      meta: {
        permission: PermissionType.NewRACampaign
      },
    },
    {
      path: NewRaCampaignRouterPath.Shop,
      component: NewRACampaignShop,
      name: NewRaCampaignRouterName.Shop,
      meta: {
        permission: PermissionType.NewRACampaign
      },
    },
    {
      path: NewRaCampaignRouterPath.SubDealer,
      component: SubDealerCampaignList,
      name: NewRaCampaignRouterName.SubDealer,
      meta: {
        permission: PermissionType.NewRACampaign
      },
    },
    {
      path: NewRaCampaignRouterPath.RequestList,
      component: RequestList,
      name: NewRaCampaignRouterName.RequestList,
      meta: {
        permission: PermissionType.NewRACampaign
      },
    },
    {
      path: NewRaCampaignRouterPath.RequestDetail,
      component: RequestDetail,
      name: NewRaCampaignRouterName.RequestDetail,
      meta: {
        permission: PermissionType.NewRACampaign
      },
    },
    {
      path: NewRaCampaignRouterPath.Config,
      component: CampaignConfiguration,
      name: NewRaCampaignRouterName.Config,
      meta: {
        permission: PermissionType.NewRACampaign
      },
    },
    {
      path: NewRaCampaignRouterPath.Statistic,
      component: CampaignStatistic,
      name: NewRaCampaignRouterName.Statistic,
      meta: {
        permission: PermissionType.NewRACampaign
      },
    },
    {
      path: NewRaCampaignRouterPath.Export,
      component: ManageExport,
      name: NewRaCampaignRouterName.Export,
      meta: {
        permission: PermissionType.NewRACampaign
      },
    },
    {
      path: NewRaCampaignRouterPath.Classified,
      component: ClassifiedRequest,
      name: NewRaCampaignRouterName.Classified,
      meta: {
        permission: PermissionType.NewRACampaign
      },
    },
  ]
};
