import { ElUpload } from 'element-ui/types/upload';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import { ActionTypeBranchManage } from 'root/admin/BranchAndGroup/BranchManage/Store/types';
import { DKGqlClient } from 'root/api/graphql/Client';
import { IPaginationParams } from 'root/api/graphql/Core';
import { formatCurrency, FormatDateFullTime } from 'root/helpers';
import { IAddress, IFilterInput, IUser } from 'root/models';
import { pathUpload, uploadToFirebase } from 'root/services';
import { FirebaseAuthService } from 'root/services/auth/firebase';
import { IBreadcrumb, IState, MutationType, TypeAlert } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import { mapState } from 'vuex';
import { SaleInCampaignRouterPath } from '../../..';
import { ActionTypeSaleInCampaign } from '../../../Store/types';
import { SaleInCampaignForm } from '../SaleInCampaignForm';
import { SaleInCampaignHistoryLogs } from '../SaleInCampaignHistoryLogs';
import { SaleInCampaignSendConfirm } from '../SaleInCampaignSendConfirm';
import { SaleInParticipantDetail } from '../SaleInParticipantDetail';
import './styles.scss';

const enum FilterKey {
  Type = 'type',
  Branch = 'branch',
}

@Component({
  template: require('./view.html'),
  computed: {
    ...mapState({
      authUser: (state: IState) => state.global.authUser,
      typeCollapse: (state: IState) => state.global.typeCollapse,
      route: (state: IState) => state.route,
      branch: (state: IState) => state.branchManage.data
    }),
  },
  components: {
    'sale-in-campaign-history-logs': SaleInCampaignHistoryLogs,
    'sale-in-send-confirm': SaleInCampaignSendConfirm,
    'sale-in-participant-detail': SaleInParticipantDetail,
    'sale-in-form': SaleInCampaignForm
  }
})

export class SaleInCampaignDetail extends Vue {
  public get permissionImportTarget() {
    return this.detail && this.detail.campaignStatus && (
      this.detail.campaignStatus === 'active' || this.detail.campaignStatus === 'inactive'
    );
  }
  public get permissionImportProgress() {
    return this.detail && this.detail.campaignStatus && (
      this.detail.campaignStatus === 'active' || this.detail.campaignStatus === 'closed'
    ) && (this.detail.sicInformation && (
      !this.detail.sicInformation.rewardStartTime || (
        this.detail.sicInformation.rewardStartTime &&
        moment().isAfter(moment(this.detail.sicInformation.rewardStartTime))
      )
    ) && (
      !this.detail.sicInformation.rewardEndTime || (
        this.detail.sicInformation.rewardEndTime &&
        moment().isBefore(moment(this.detail.sicInformation.rewardEndTime))
      )
    ));
  }
  public get dataFilter() {
    const branch = this.branch.map((it) => (
    {
      key: it.id,
      value: it.id,
      name: it.branchCode
    }
    ));
    const type: IFilterInput[] = [
      {
        key: 'daikin_dealer',
        value: 'daikin_dealer',
        name: 'Direct Dealer'
      },
      {
        key: 'sub_dealer',
        value: 'sub_dealer',
        name: 'Sub dealer'
      },
    ];

    return [
      {
        key: FilterKey.Type,
        name: 'Type',
        value: type
      },
      {
        key: FilterKey.Branch,
        name: 'Branch ',
        value: branch
      },
    ];
  }
  public get selectedFilter() {
    const _filterParams = cloneDeep(this.filterParams);

    return [
      {
        key: FilterKey.Type,
        value: _filterParams[FilterKey.Type]
      },
      {
        key: FilterKey.Branch,
        value: _filterParams[FilterKey.Branch]
      },
    ];
  }
  public get columns() {
    return [
      {
        label: 'Name',
        labelItem: 'Code',
        formatter: (model: any) => {
          return model && model.dealer && model.dealer.name ? model.dealer.name : '--';
        },
        description: {
          formatter: (model: any) => {
            return model && model.dealer && model.dealer.dealerCode ? model.dealer.dealerCode : '--';
          },
        },
        width: 164
      },
      {
        label: 'Type',
        formatter: (model: any) => {
          return model && model.dealer && model.dealer.role && model.dealer.role.name ? model.dealer.role.name : '--';
        },
        width: 120
      },
      {
        label: 'Phone',
        formatter: (model: any) => {
          return model && model.dealer && model.dealer.phoneNumber ? model.dealer.phoneNumber : '--';
        },
        width: 140
      },
      {
        label: 'Address',
        formatter: (model: any) => {
          return model && model.dealer && model.dealer.address ? this.displayAddress(model.dealer.address) : '--';
        },
        width: 272
      },
      {
        label: 'Branch',
        formatter: (model: any) => {
          return model && model.dealer && model.dealer.branch && model.dealer.branch.name ?
          model.dealer.branch.name : '--';
        },
        width: 140
      },
      {
        label: 'Quantity sold',
        formatter: (model: any) => {
          return model && model.quantitySold ? model.quantitySold : '--';
        },
        width: 180
      },
      {
        prop: 'target',
        label: 'Target',
        width: 180
      },
      {
        label: 'Confirmed',
        prop: 'confirm',
        labelItem: 'Confirmed At',
        width: 140
      }
    ];
  }
  public $refs: {
    upload: ElUpload;
  };
  public detail: any = {};
  public authUser: IUser;
  public branch;
  public activeCollapse: any = 'general';
  public FormatDateFullTime = FormatDateFullTime;
  public data: any[] = [];
  public searchText: string = '';
  public visibleHistory: boolean = false;
  public loading: boolean = false;
  public filterParams = {
    [FilterKey.Type]: [],
    [FilterKey.Branch]: [],
  };
  public participantDetail: any = {};
  public loadingUpload: boolean = false;
  public visibleImport: boolean = false;
  public FilterRequestTime: any = {
    from: null,
    to: null,
  };
  public visibleSendConfirm: boolean = false;
  public visibleDescription: boolean = false;
  public typeImport: string = '';
  public pathUploadCampaign: pathUpload = pathUpload.POSCODE;
  public fileList = [];
  public createVisible: boolean = false;
  public viewer: any;
  public descriptionContent: string = '';
  public formatCurrency = formatCurrency;
  public imageList: any[] = [];
  public participantDetailVisible: boolean = false;
  public fileUploaded= [];
  // tslint:disable-next-line:max-line-length
  public progressUrl: string = 'https://firebasestorage.googleapis.com/v0/b/daikin-sale-staging.appspot.com/o/imports%2Fsample%2FFile%20Import%2Ftemplate_import_progress_sic.xlsx?alt=media&token=de47df23-3d16-4e40-a68b-f7be6af08295';
  // tslint:disable-next-line:max-line-length
  public targetUrl: string = 'https://firebasestorage.googleapis.com/v0/b/daikin-sale-staging.appspot.com/o/imports%2Fsample%2FFile%20Import%2Ftemplate_import_target_sic.xlsx?alt=media&token=4e2ecde0-f250-4ae7-b877-417a8797154b';
  public pagination: IPaginationParams = {
    limit: 20,
    page: 1,
    total: null
  };
  public handleViewLogs() {
    this.visibleHistory = true;
  }
  public downloadSample(type: string) {
    if (type === 'progress') {
      window.open(this.progressUrl, '_blank');

      return;
    }
    window.open(this.targetUrl, '_blank');
  }
  public onClickImport(type: string) {
    if (type === 'progress') {
      this.typeImport = 'si_progress';
      this.visibleImport = true;

      return;
    }
    this.typeImport = 'si_target';
    this.visibleImport = true;
  }
  public onClickSendConfirm() {
    this.visibleSendConfirm = true;
  }
  public handlePaginationChange(value) {
    if (typeof value === 'number') {
      this.pagination = {
        ...this.pagination,
        limit: value
      };
    } else {
      this.pagination = {
        ...this.pagination,
        ...value
      };
    }
    this.fetchData();
  }
  public changeFilter(model, time) {
    if (time && (time.from || time.to)) {
      this.FilterRequestTime = time;
    }
    if (time && !time.from && !time.to) {
      this.FilterRequestTime = {
        from: null,
        to: null,
      };
    }
    const branch = model.find((e) => e.key === FilterKey.Branch);
    const type = model.find((e) => e.key === FilterKey.Type);

    const nextFilter = {
      [FilterKey.Type]: type ? type.value : [],
      [FilterKey.Branch]: branch ? branch.value : [],
    };
    this.filterParams = {
      ...cloneDeep(this.filterParams),
      ...nextFilter
    };
    this.fetchData();
  }
  public formatterModels(models) {
    if (!models || models.length <= 0) {
      return '--';
    }

    return models.map((it) => it.model.modelNumber).join(', ');
  }
  public closeDialog() {
    this.createVisible = false;
  }
  public closeModalImport() {
    this.visibleImport = false;
    this.$refs.upload.clearFiles();
    this.fileList = [];
  }
  public inited(viewer) {
    this.viewer = viewer;
    this.viewer.hide();
  }
  public onViewImage(model) {
    this.imageList = model ? [model] : [''];
    if (this.imageList && this.imageList.length && this.imageList[0] !== '') {
      this.viewer.show();
      setTimeout(() => {
        const element = <HTMLElement> document.getElementsByClassName('device-img')[0];
        element.click();
      }, 0);

      return;
    }
    this.$message.error('No images');
  }
  public pushFileUpload(file: any) {
    const f = this.fileList;
    if (this.fileList.length > 0) {
      const _file = this.fileList.filter((it) => it.name === file.name);
      if (_file && _file.length > 0) {
        this.$refs.upload.clearFiles();
        const message = 'File has been exits';
        this.$store.commit(MutationType.OpenTopAlert, {
          message,
          type: TypeAlert.Warning
        });

        return this.$refs.upload.$data.uploadFiles = f;
      }

      return this.fileList = [file];
    }

    f.push(file);

    return this.fileList = [file];
  }
  public goEdit() {
    this.createVisible = true;
  }
  public clickDetail(id: string) {
    const detail = this.data.find((it) => it.id === id);
    this.participantDetail = {
      ...detail
    };
    this.participantDetailVisible = true;
  }
  public async submitFile() {
    // tslint:disable-next-line: whitespace
    try {
      this.loadingUpload = true;
      // const uploadedFile = <any> [];
      const token = await DKGqlClient().user.getFirebaseAuthenticateToken();
      await FirebaseAuthService().signInWithCustomToken(token.authenticate);
      const uploadedFile = await Promise.all(this.fileList.map(async (it) => {
        const _file = await uploadToFirebase(it.raw, this.pathUploadCampaign);

        // return _file ? uploadedFile.push(_file) : uploadedFile;
        // tslint:disable-next-line:no-var-before-return
        return _file;
      }));

      if (uploadedFile.length > 0) {
        const form = {
          createdBy: this.authUser.id,
          url: uploadedFile[0],
          type: this.typeImport,
          referenceId: this.$route.params.id,
          isReIndex: true
        };
        this.$store.dispatch(ActionTypeSaleInCampaign.ImportSaleInCampaign, {
          form,
          onSuccess: () => {
            this.loadingUpload = false;
            const message = 'Import successfully';
            this.$store.commit(MutationType.OpenTopAlert, {
              message,
              type: TypeAlert.Success
            });
            this.visibleImport = false;
            this.fileList = [];
            this.fileUploaded = [];
            this.$refs.upload.clearFiles();
            this.fetchData();
          },
          onFailure: () => {
            this.loadingUpload = false;
          }});
      }

    } catch (error) {
      console.log(error);
    }

  }
  public onRemove(file: any, fileList: any) {
    console.log(file);
    this.fileList = fileList;
  }
  public displayAddress(model: IAddress) {
    return model && model.province
      ? this.$store.getters['getAddressString'](model)
      : '';
  }
  public formatterBranch(branches: any) {
    if (branches.length) {
      const result = [];
      branches.map((branch) => {
        const formatBranch = branch.branch && branch.branch.name ? branch.branch.name : '--';
        result.push(formatBranch);
      });

      return result.join(',');
    }

    return '--';
  }
  public formatterRole(roles: any) {
    if (roles.length) {
      const result = [];
      roles.map((role) => {
        const formatRole = this.formatterDealerRole(role);
        result.push(formatRole);
      });

      return result.join(',');
    }

    return '--';
  }
  public formatterDealerRole(role: any) {
    const mapRole = {
      daikin_dealer: 'Dealer',
      daikin_staff: 'Salesman',
      sub_dealer: 'Sub Dealer'
    };

    return mapRole[role];
  }
  public closeDetailDescription() {
    this.descriptionContent = '';
    this.visibleDescription = false;
  }
  public viewDetail() {
    if (this.detail && this.detail.sicInformation && this.detail.sicInformation.descriptionType
      && this.detail.sicInformation.descriptionType === 'pdf' && this.detail.description) {
      window.open(this.detail.description, '_blank');

      return;
    }
    if (this.detail && this.detail.sicInformation && this.detail.sicInformation.descriptionType
      && this.detail.sicInformation.descriptionType === 'html' && this.detail.description) {
      this.openDescriptionPopup(this.detail.description);

      return;
    }

    return;
  }
  public openDescriptionPopup(content) {
    this.descriptionContent = content;
    this.visibleDescription = true;
  }
  public closeDialogParticipant() {
    this.participantDetailVisible = false;
    this.participantDetail = {};
  }
  public handleSearch() {
    this.pagination = {
      ...this.pagination,
      page: 1,
    };
    this.fetchData();
  }
  public fetchData() {
    const text = cloneDeep(this.searchText);
    const {branch, type} = this.filterParams;
    // tslint:disable-next-line:early-exit
    if (this.$route.params.id) {
      this.$store.dispatch(ActionTypeSaleInCampaign.GetCampaignDetailById, {
        id: this.$route.params.id,
        onSuccess: (result) => {
          this.detail = result[0];
        }
      });
      this.loading = true;
      this.$store.dispatch(ActionTypeSaleInCampaign.GetCampaignDetailParticipantList, {
        id: this.$route.params.id,
        search: text && text.length ? `%${text}%` : null,
        type: type && type.length ? type : null,
        branch: branch && branch.length ? branch : null,
        time: this.FilterRequestTime,
        pagination: this.pagination,
        onSuccess: (result) => {
          this.data = result.si_campaign_participants;
          this.pagination = {
            ...this.pagination,
            total: result.si_campaign_participants_aggregate.aggregate.count
          };
          this.loading = false;
        }
      });
    }
  }
  protected async mounted() {
    const breadcrumb: IBreadcrumb[] = [
      {
        label: 'Sell In Campaign',
        path: SaleInCampaignRouterPath.Default,
        current: false
      },
      {
        label: 'title.view_details',
        path: null,
        current: true
      }
    ];
    this.$store.dispatch(ActionTypeBranchManage.GetListBranch, {
      form: {
        filter: {},
        pagination: {}
      }
    });
    this.$store.commit(MutationType.SetBack);
    this.$store.commit(MutationType.SetBreadcrumb, breadcrumb);
    this.$store.commit(MutationType.SetShowCollapse);
    this.$store.commit(MutationType.SetTypeCollapse, true);
    this.$nextTick(() => {
      this.fetchData();
    });
  }
  protected beforeDestroy() {
    this.$store.commit(MutationType.ClearShowCollapse);
    this.$store.commit(MutationType.ClearBreadcrumb);
  }
  @Watch('$route.params.id', {deep: true})
  protected onChangeRouter(val, oldVal) {
    if (val !== oldVal && val) {
      this.fetchData();
    }
  }
}
