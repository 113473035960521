// import { ElTable } from 'element-ui/types/table';
import { cloneDeep } from 'lodash';
import moment from 'moment';
// import { ActionTypeUser, MutationTypeUser, SystemRoleAlias } from 'root/admin/User/Store/types';
import { IPaginationParams } from 'root/api/graphql/Core';
import { ListData } from 'root/components';
import { IAddress, IUser } from 'root/models';
import { IState, MutationType, TypeAlert } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import { ActionTypeCampaignRequestList, ActionTypeClassifiedRequest, ActionTypeNewRaCampaign } from '../../Store/types';
import { Qualified, QualifiedCode, RequestStatus, RequestStatusCode } from '../NewRACampaignDetail';
import './styles.scss';

const enum FilterKey {
  SalesStaff = 'salesStaff',
  Classification = 'classification',
  Type = 'type'
}

@Component({
  template: require('./view.html'),
  computed: {
    ...mapState({
      authUser: (state: IState) => state.global.authUser,
      data: (state: IState) => state.newRACampaign.listQualifiedRequest,
      pagination: (state: IState) => state.newRACampaign.pagination,
      loading: (state: IState) => state.newRACampaign.loading,
      // user: (state: IState) => state.user.data,
    }),
  },
  props: {
    popupVisible: Boolean,
  },
})
export class RequestCampaignForm extends Vue {
  public get disableSubmit() {
    return this.cloneSelected && this.cloneSelected.length;
  }
  public $refs: {
    listRequest: ListData
  };
  public loading: boolean;
  public authUser: IUser;
  public loadingConfirm: boolean = false;
  public selectedItem: any[] = [];
  public visibleConfirm: boolean = false;
  public requestName: string = '';
  public comment: string = '';
  public cloneSelected: any = [];
  public qualifiedValue: string = '';
  public isErrorName: boolean = false;
  public isErrorQualify: boolean = false;
  public isErrorComment: boolean = false;
  public pagination: IPaginationParams;
  public data;
  public user: any[] = [];
  public filterParams = {
    [FilterKey.SalesStaff]: [],
    [FilterKey.Classification]: [],
    [FilterKey.Type]: [],
  };
  public get qualifyOption() {
    return [
      {
        key: 'qualified',
        label: 'Qualified',
        value: 'qualified',
      },
      {
        key: 'non_qualified',
        label: 'Non Qualified',
        value: 'non_qualified',
      }
    ];
  }
  public get dataFilter() {
    const clone = this.user.reduce((res, item) => {
      res.push({
        key: item.id,
        value: item.id,
        name: item.name
      });

      return res;
    }, []);
    const classification = [
      {
        key: 'qualified',
        name: 'Qualified',
        value: 'qualified',
      },
      {
        key: 'non_qualified',
        name: 'Non Qualified',
        value: 'non_qualified',
      }
    ];
    const type = [
      {
        key: 'sub_dealer',
        name: 'Sub Dealer',
        value: 'sub_dealer',
      },
      {
        key: 'daikin_dealer',
        name: 'Dealer',
        value: 'daikin_dealer',
      }
    ];

    return [
      {
        key: FilterKey.SalesStaff,
        name: 'Sales Staff',
        value: clone
      },
      {
        key: FilterKey.Classification,
        name: 'Sales Staff Review',
        value: classification
      },
      {
        key: FilterKey.Type,
        name: 'Type',
        value: type
      },
    ];
  }
  public get selectedFilter() {
    const filterParams = cloneDeep(this.filterParams);

    return [
      {
        key: FilterKey.SalesStaff,
        value: filterParams[FilterKey.SalesStaff]
      },
      {
        key: FilterKey.Classification,
        value: filterParams[FilterKey.Classification]
      },
      {
        key: FilterKey.Type,
        value: filterParams[FilterKey.Type]
      },
    ];
  }
  public get columns() {
    return [
      {
        prop: 'sub-dealer',
        label: 'Sub-Dealer Name',
        labelItem: 'Sub-Dealer Code',
        formatter: (model: any) => {
          if (model && model.dealerView && model.dealerView.role.alias === 'sub_dealer') {
            return model.dealerView && model.dealerView.name ? model.dealerView.name : '--';
          }

          return '--';
        },
        description: {
          formatter: (model: any) => {
            if (model && model.dealerView && model.dealerView.role.alias === 'sub_dealer') {
              return model.dealerView && model.dealerView.dealerCode ? model.dealerView.dealerCode : '--';
            }

            return '--';
          }
        },
        width: 250,
      },
      {
        prop: 'dealer',
        label: 'Direct Dealer Name',
        labelItem: 'Direct Dealer Code',
        formatter: (model: any) => {
          if (model && model.dealerView && model.dealerView.role.alias === 'daikin_dealer') {
            return model.dealerView && model.dealerView.name ? model.dealerView.name : '--';
          }
          if (model && model.dealerView && model.dealerView.role.alias === 'sub_dealer') {

            return model.dealerView && model.dealerView.assignedSubDealers2023View &&
            model.dealerView.assignedSubDealers2023View.length &&
            model.dealerView.assignedSubDealers2023View[0].dealerView
            && model.dealerView.assignedSubDealers2023View[0].dealerView.name ?
            model.dealerView.assignedSubDealers2023View[0].dealerView.name : '--';
          }

          return '--';
        },
        description: {
          formatter: (model: any) => {
            if (model && model.dealerView && model.dealerView.role.alias === 'daikin_dealer') {
              return model.dealerView && model.dealerView.dealerCode ? model.dealerView.dealerCode : '--';
            }
            if (model && model.dealerView && model.dealerView.role.alias === 'sub_dealer') {
              return model.dealerView && model.dealerView.assignedSubDealers2023View.length &&
              model.dealerView.assignedSubDealers2023View[0].dealerView
              && model.dealerView.assignedSubDealers2023View[0].dealerView.dealerCode
              ? model.dealerView.assignedSubDealers2023View[0].dealerView.dealerCode : '--';
            }

            return '--';
          }
        },
        width: 250,
      },
      {
        prop: 'shop',
        label: 'Shop Name',
        labelItem: 'Shop Code',
        formatter: (model: any) => {
          if (model && model.dealerView && model.dealerView.role.alias === 'daikin_dealer') {
            return model.shop && model.shop.name ? model.shop.name : '--';
          }

          return '--';
        },
        description: {
          formatter: (model: any) => {
            if (model && model.dealerView && model.dealerView.role.alias === 'daikin_dealer') {
              return model.shop && model.shop.shopCode ? model.shop.shopCode : '--';
            }

            return '--';
          }
        },
        width: 220,
      },
      {
        prop: 'shopAddress',
        label: 'Address',
        width: 220,
        formatter: (model: any) => {
          if (model.dealerView.address || model.shop.address) {
            if (model.dealerView && model.dealerView.role.alias === 'sub_dealer') {
              return this.displayAddress(model.dealerView.address) || '--';
            }

            return this.displayAddress(model.shop.address) || '--';
          }

          return '--';
        }
      },
      {
        prop: 'spiralReview',
        label: 'Spiral Review Result',
        formatter: (model: any) => {
          if (model && model.dealerView && model.dealerView.role && model.dealerView.role.alias === 'sub_dealer') {
            return model && model.dealerView && model.dealerView.aclSpiralReviews &&
          model.dealerView.aclSpiralReviews[0] && model.dealerView.aclSpiralReviews[0].totalPoint ?
          model.dealerView.aclSpiralReviews[0].totalPoint : '--';
          }

          return model && model.shop && model.shop.aclSpiralReviews &&
          model.shop.aclSpiralReviews[0] && model.shop.aclSpiralReviews[0].totalPoint ?
          model.shop.aclSpiralReviews[0].totalPoint : '--';
        },
        // labelStyle: this.formatterReviewStatus,
        width: 120,
      },
      {
        prop: 'BPStatus',
        label: 'Sales Staff Review Result',
        formatter: this.formatterClassification,
        labelStyle: this.formatterReviewStatus,
        width: 120,
      },
      {
        prop: 'review content',
        label: 'Sales Staff last review content',
        width: 150,
      },
      {
        prop: 'paymentDocument',
        label: 'Payment Document',
        width: 120,
        formatter: (model) => {
          if (model && model.dealerView && model.dealerView.role && model.dealerView.role.alias === 'sub_dealer') {
            return model && model.dealerView && model.dealerView.information2023 && model.dealerView.information2023
            && model.dealerView.information2023.productReceiptPicURLs &&
            model.dealerView.information2023.productReceiptPicURLs.length ? 'Yes' : 'No';
          }

          return model && model.dealerView && model.dealerView.information2023 && model.dealerView.information2023
          && (model.dealerView.information2023.requestedRewardReceiptURLs &&
            model.dealerView.information2023.requestedRewardReceiptURLs.length ||
            model.dealerView.information2023.data && model.dealerView.information2023.data.link) ? 'Yes' : 'No';
        },
        labelStyle: (model) => {
          if (model && model.dealerView && model.dealerView.role && model.dealerView.role.alias === 'sub_dealer') {
            return model && model.dealerView && model.dealerView.information2023 && model.dealerView.information2023
            && model.dealerView.information2023.productReceiptPicURLs &&
            model.dealerView.information2023.productReceiptPicURLs.length ? 'activated' : 'non_qualified';
          }

          return model && model.dealerView && model.dealerView.information2023 && model.dealerView.information2023
          && (model.dealerView.information2023.requestedRewardReceiptURLs &&
            model.dealerView.information2023.requestedRewardReceiptURLs.length ||
            model.dealerView.information2023.data &&
            model.dealerView.information2023.data.link) ? 'activated' : 'non_qualified';
        },
      },

      // {
      //   prop: 'SDPStatus',
      //   label: 'Sales PIC Review Result',
      //   formatter: this.formatterSalePicReview,
      //   labelStyle: this.formatterSalePicLabel,
      //   // labelStyle: this.formatterReviewStatus,
      //   width: 120,
      // },
      // {
      //   prop: 'SPStatus',
      //   label: 'Sale Planning Review Result',
      //   width: 120,
      //   formatter: this.formatterSalePlanningStatus,
      //   labelStyle: this.formatterStyleStatus,
      // },
      {
        prop: 'review at',
        label: 'Sales Staff last review time',
        width: 150,
      },

    ];
  }
  public formatterClassification(model: any) {
    if (model.classification) {
      switch (model.classification) {
      case QualifiedCode.Qualified:
        return Qualified.Qualified;
      case QualifiedCode.NonQualified:
        return Qualified.NonQualified;
      }
    }

    return 'Not Review';
  }
  public formatterReviewStatus(model: any) {
    if (model.classification) {
      switch (model.classification) {
      case QualifiedCode.Qualified:
        return `newRACampaign__${QualifiedCode.Qualified}`;
      case QualifiedCode.NonQualified:
        return `newRACampaign__${QualifiedCode.NonQualified}`;
      }
    }

    return 'not_review';
  }
  public formatterSalePlanningStatus(model: any) {
    if (model) {
      switch (model.requestStatus) {
      case RequestStatusCode.Approved:
        return RequestStatus.Approved;
      case RequestStatusCode.Rejected:
        return RequestStatus.Rejected;
      default:
        return 'Not Review';
      }
    }

    return 'Not Review';
  }
  public formatterTime(model: any) {
    if (model && model.classification !== null && model.reviews.length) {
      const last = model.reviews.length - 1;

      return moment(model.reviews[last].updatedAt).format('HH:mm - DD/MM/YYYY');
    }

    return '--';
  }
  public formatterContent(model: any) {
    if (model && model.classification !== null && model.reviews.length) {
      const last = model.reviews.length - 1;

      return model.reviews[last].description;
    }

    return '--';
  }
  public formatterStyleStatus(model: any) {
    if (model.requestStatus) {
      switch (model.requestStatus) {
      case RequestStatusCode.Approved:
        return RequestStatusCode.Approved;
      case RequestStatusCode.Rejected:
        return RequestStatusCode.Rejected;
      default:
        return 'not_review';
      }
    }

    return 'not_review';
  }
  public displayAddress(model: IAddress) {
    return model && model.province
      ? this.$store.getters['getAddressString'](model)
      : '';
  }
  public changeFilter(model) {
    const salesStaff = model.find((e) => e.key === FilterKey.SalesStaff);
    const classification = model.find((e) => e.key === FilterKey.Classification);
    const type = model.find((e) => e.key === FilterKey.Type);
    const nextFilter = {
      [FilterKey.SalesStaff]: salesStaff ? salesStaff.value : [],
      [FilterKey.Classification]: classification ? classification.value : [],
      [FilterKey.Type]: type ? type.value : [],
    };
    this.filterParams = {
      ...cloneDeep(this.filterParams),
      ...nextFilter
    };
    this.fetchData();
  }
  public closeDialog() {
    this.back();
  }
  public back() {
    this.selectedItem = [];
    this.cloneSelected = [];
    this.filterParams = {
      [FilterKey.SalesStaff]: [],
      [FilterKey.Classification]: [],
      [FilterKey.Type]: [],
    };
    this.$refs.listRequest.refreshData();
    this.$emit('update:popupVisible', false);
  }
  public openDialog() {
    this.$store.dispatch(ActionTypeClassifiedRequest.GetSalesStaffSameGroup, {
      filter: {
        saleStaff: {
          // groupId: {
          //   _eq: this.authUser.group && this.authUser.group.id ? this.authUser.group.id : null
          // }
        }
      },
      onSuccess: (data) => {
        this.user = data.map((it) => it.saleStaff);
      }
    });
    this.fetchData();
  }
  public fetchData() {
    const filterParams = <any> {};
    const sales = this.filterParams[FilterKey.SalesStaff];
    const type = this.filterParams[FilterKey.Type];
    if (this.filterParams[FilterKey.Classification].length) {
      filterParams.classification = this.filterParams[FilterKey.Classification];
    }
    this.$store.dispatch(ActionTypeNewRaCampaign.GetQualifiedRequest, {
      sales: sales && sales.length ? sales : null,
      pagination: this.pagination,
      salesPlanning: ['not_review'],
      salesStaff: filterParams.classification && filterParams.classification.length ?
      filterParams.classification : ['qualified', 'non_qualified'],
      type: type && type.length ? type : null,
    });
  }
  public apply() {
    this.visibleConfirm = true;
  }
  public createRequest() {
    this.loadingConfirm = true;
    this.isErrorName = false;
    this.isErrorQualify = false;
    this.isErrorComment = false;
    if (!this.requestName) {
      this.loadingConfirm = false;

      return this.isErrorName = true;
    }
    if (!this.qualifiedValue) {
      this.loadingConfirm = false;

      return this.isErrorQualify = true;
    }
    if (!this.comment) {
      this.loadingConfirm = false;

      return this.isErrorComment = true;
    }
    this.$store.dispatch(ActionTypeCampaignRequestList.Create, {
      form: {
        requestName: this.requestName,
        classification: this.qualifiedValue,
        comment: this.comment,
        reviews: {
          data: [
            ...this.cloneSelected && this.cloneSelected.length ? [
              ...this.cloneSelected.map((it) => ({
                campaignId: it.id,
                shopId: it.dealerView && it.dealerView.role.alias === 'daikin_dealer' ? it.shop.id : null,
                dealerId: it.dealerView && it.dealerView.id ? it.dealerView.id : null,
              }))
            ] : []
          ]
        }
      },
      onSuccess: () => {
        this.loadingConfirm = false;
        const message = 'Create successfully';
        this.$store.commit(MutationType.OpenTopAlert, {
          message,
          type: TypeAlert.Success
        });
        this.closeDialogConfirm();
        this.closeDialog();
      },
      onFailure: () => {
        this.loadingConfirm = false;
        const message = 'Create failure';
        this.$store.commit(MutationType.OpenTopAlert, {
          message,
          type: TypeAlert.Error
        });
        this.closeDialogConfirm();
        this.closeDialog();
      }
    });

    return;
  }
  public formatterSalePicReview(model: any) {
    if (model.salePicReview) {
      switch (model.salePicReview) {
      case QualifiedCode.Qualified:
        return Qualified.Qualified;
      case QualifiedCode.NonQualified:
        return Qualified.NonQualified;
      }
    }

    return 'Not Review';
  }
  public formatterSalePicLabel(model: any) {
    if (model.salePicReview) {
      switch (model.salePicReview) {
      case QualifiedCode.Qualified:
        return `newRACampaign__${QualifiedCode.Qualified}`;
      case QualifiedCode.NonQualified:
        return `newRACampaign__${QualifiedCode.NonQualified}`;
      }
    }

    return 'not_review';
  }
  public handlePaginationChange(value) {
    if (typeof value === 'number') {
      this.pagination = {
        ...this.pagination,
        page: value
      };
    } else {
      this.pagination = {
        ...this.pagination,
        page: value
      };
    }
    this.fetchData();
  }
  public changeSelect() {
    // this.selectedItem = value;
    // this.cloneSelected = [
    //   ...value.map((it) => it.id)
    // ];
    // console.log(value, this.cloneSelected);
  }
  public onSelectAll({val, remove, removeData }) {
    if (!remove) {
      this.cloneSelected = this.cloneSelected.concat(val);

      return;
    }
    this.cloneSelected = this.cloneSelected.filter((ar) => {
      return !removeData.find((rm) => rm.id === ar.id);
    });
  }
  public changeItem(value) {
    const idx = this.cloneSelected.indexOf(value);
    if (idx >= 0) {
      this.cloneSelected.splice(idx, 1);

      return;
    }
    this.cloneSelected.push(value);
  }
  public closeDialogConfirm() {
    this.visibleConfirm = false;
    this.requestName = '';
    this.qualifiedValue = '';
    this.comment = '';
  }
  public removeItem() {
    // 
  }
  public mounted() {
    // this.$store.commit(MutationTypeUser.FilterChange, {
    //   role: {
    //     alias: SystemRoleAlias.SaleStaff
    //   },
    //   groupId: this.authUser.group && this.authUser.group.id ? this.authUser.group.id : null
    // });
    // this.$store.dispatch(ActionTypeUser.FilterNoCache);
  }
}
