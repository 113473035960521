// import { TransStatus } from 'root/admin/Dealer/Store/types';
import { stripObject } from '@hgiasac/helper';
import { ActionTypeProductWarranty, MutationTypeProductWarranty } from 'root/admin/ActiveEWarranty/Store/types';
import { ActionTypeProductTrans } from 'root/admin/ProductTransaction/Store/types';
import { SystemRoleAlias } from 'root/admin/User/Store/types';
import { Avatar } from 'root/components/Avatar';
import { formatBirthday, FormatDateFullTime, FormatDateTime } from 'root/helpers';
import { IUser, StatusCode } from 'root/models';
import { IState } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import './styles.scss';
@Component({
  template: require('./view.html'),
  props: {
    visible: Boolean,
    detailId: String
  },
  components: {
    avatar: Avatar
  },
  computed: {
    ...mapState({
      authUser: (state: IState) => state.global.authUser,
      productHistory: (state: IState) => state.productTrans.data,
      eWarranty: (state: IState) => state.productEWarranty.data
    }),
    dataWarranty() {
      if (this.eWarranty && this.eWarranty.length) {
        const _detail = this.eWarranty.filter((it) => it.warrantyStatus && it.warrantyStatus === StatusCode.Activated);

        return _detail && _detail.length ? _detail[0] : {};
      }

      return {};
    }
  }
})
export class ProductDetailModal extends Vue {

  public get displayRole(): string {
    return this.productDetail.role ? this.productDetail.role.name.toString() : '';
  }
  public get displayStationName() {

    return this.productDetail.station ? this.productDetail.station.name : '';
  }
  public FormatDateFullTime = FormatDateFullTime;
  public formatBirthday = formatBirthday;
  public FormatDateTime = FormatDateTime;
  public productDetail = null;
  public detailId: string;
  public activeTab: string = 'General';
  public productHistory: any[];
  public eWarranty: any[];
  public authUser: IUser;
  public changeTab(tab: string) {
    this.activeTab = tab;
  }
  public fomatStatus(status: string) {
    return status === 'sale_in' ? 'Sale In' : 'Sale Out';
  }
  public formatDealerName(model: any) {
    if (this.authUser && (this.authUser.role.alias === SystemRoleAlias.SaleAdmin ||
      this.authUser.role.alias === SystemRoleAlias.Admin ||
       this.authUser.role.alias === SystemRoleAlias.RegionDirector)) {

      // return model.transactionStatus && model.transactionStatus === TransStatus.SaleIn ?
      //   model.dealer && model.dealer.name : model.formerDealer && model.formerDealer.name;
      return model.dealer ? model.dealer.name : '';
    }

    // // return model.transactionStatus && model.transactionStatus === TransStatus.SaleIn ?
    // //     model.dealerName : model.formerDealerName;
    return model.dealerName ? model.dealerName : '';
  }

  public async openDialog() {
    if (this.authUser && (this.authUser.role.alias === SystemRoleAlias.SaleAdmin ||
      this.authUser.role.alias === SystemRoleAlias.Admin ||
       this.authUser.role.alias === SystemRoleAlias.RegionDirector)) {
      this.$store.dispatch(ActionTypeProductTrans.GetByProductId, {id: this.detailId});
      this.$store.commit(MutationTypeProductWarranty.FilterChange, stripObject({
        mode: 'special',
        _and: {
          mode: 'special',
          data: {
            productId: {_eq: this.detailId}
          }
        }}));
      this.$store.dispatch(ActionTypeProductWarranty.FilterNoCache);
    } else {
      this.$store.dispatch(ActionTypeProductTrans.GetViewById, {id: this.detailId});
      this.$store.commit(MutationTypeProductWarranty.FilterChange, stripObject({
        mode: 'special',
        _and: {
          mode: 'special',
          data: {
            productId: {_eq: this.detailId}
          }
        }}));
      this.$store.commit(MutationTypeProductWarranty.ChangeOrderBy, {createdAt: 'desc'});
      this.$store.dispatch(ActionTypeProductWarranty.FilterNoCache);

    }
    // this.$store.dispatch(ActionTypeProductTrans.FilterNoCache);
    this.productDetail = this.$store.getters.getProductDetail(this.detailId);
  }

  public closeDialog() {
    this.$emit('closeDialog');
    this.activeTab = 'General';
  }
}
