import { stripObject } from '@hgiasac/helper';
import { Form } from 'element-ui';
import { cloneDeep } from 'lodash';
import { AddressSelection } from 'root/components/AddressSelection';
import { ruleEmailNoRequired, ruleRequired } from 'root/helpers';
import { IUser } from 'root/models';
import { dealerShopFormDefault, IDealerShopForm } from 'root/models/Dealer';
import { IState, MutationType, TypeAlert } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import { ActionTypeDealerShop } from '../Store/types';
import './styles.scss';
@Component({
  template: require('./view.html'),
  props: {
    visible: Boolean,
    dealerId: String,
    dealerProfile: Object,
    userId: String,
  },
  components: {
    'address-selection': AddressSelection
  },
  computed: {
    ...mapState({
      authUser: (state: IState) => state.global.authUser,
    }),
  }
})

export class DealerShopEditor extends Vue {

  public get rules() {
    return {
      required: ruleRequired(),
      ruleEmailNoRequired: ruleEmailNoRequired(),
    };
  }

  public $refs: {
    form: Form
  };
  public authUser: IUser;
  public loading: boolean = false;
  public form: IDealerShopForm = dealerShopFormDefault();
  public dealerId: string;
  public dealerProfile;
  public userId: string;
  public viewProfile: boolean = false;

  public async submit() {
    this.$refs.form.validate(async (valid) => {
      if (valid) {
        this.loading = true;
        if (this.userId) {
          const updateForm = <any> cloneDeep(this.form);
          this.$store.dispatch(ActionTypeDealerShop.UPDATE_SHOP, {
            form: {...stripObject(updateForm)},
            id: this.userId,
            updatedBy: this.authUser.id,
            onSuccess: () => {
              this.loading = false;
              const message = 'Update shop successfully';
              this.$store.commit(MutationType.OpenTopAlert, {
                message,
                type: TypeAlert.Success
              });
              this.$emit('createSuccess');
              this.back();
            },
            onFailure: () => {
              this.loading = false;
            }
          });

          return;
        }
        const form = <any> cloneDeep(this.form);
        const { email } = this.form;
        if (email) {
          form.email = email.toLowerCase();
        }
        this.$store.dispatch(ActionTypeDealerShop.CREATE_SHOP, {
          form: {...stripObject(form)},
          id: this.dealerId,
          createdBy: this.authUser.id,
          onSuccess: () => {
            this.loading = false;
            const message = 'Create shop successfully';
            this.$store.commit(MutationType.OpenTopAlert, {
              message,
              type: TypeAlert.Success
            });
            this.$emit('createSuccess');
            this.back();
          },
          onFailure: () => {
            this.loading = false;

          }
        });
      }

      return;
    });
  }

  public back() {
    this.form = dealerShopFormDefault();
    this.viewProfile = false;
    this.$refs.form.clearValidate();
    this.$emit('update:visible', false);
  }

  public async openDialog() {
    if (this.userId) {
      this.$store.dispatch(ActionTypeDealerShop.GET_SHOP_DETAIL, {
        id: this.userId,
        onSuccess: (detail) => {
          this.form = detail;
        },
        onFailure: () => {
            //
        }
      });
    }

    return;
  }
  public closeDialog() {
    this.form = dealerShopFormDefault();
    this.viewProfile = false;
    this.$refs.form.clearValidate();
    this.$emit('update:visible', false);
  }

}
