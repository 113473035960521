import { Avatar } from 'root/components';
import { parseUTCToTime } from 'root/helpers';
import { IAddress } from 'root/models';
import Vue from 'vue';
import Component from 'vue-class-component';
import { ActionTypeAccountStaff } from '../Store/types';
import './styles.scss';

@Component({
  template: require('./view.html'),
  components: {
    avatar: Avatar,
  },
  props: {
    visible: Boolean,
    dealerId: String
  },
})

export class AccountStaffDetail extends Vue {
  public dealerId;
  public dealer: any = {};
  public formatDate = parseUTCToTime;
  public displayAddress(model: IAddress) {
    return model && model.province ? this.$store.getters['getAddressString'](model) : '--';
  }
  public openDialog() {
    // change after
    this.$store.dispatch(ActionTypeAccountStaff.GET_STAFF_DETAIL, {
      id: this.dealerId,
      onSuccess: (detail) => {
        this.dealer = detail;
      },
      onFailure: () => {
          //
      }
    });
  }
  public closeDialog() {
    this.$emit('update:visible', false);
  }
}
