import moment from 'moment';
import { gqlClient } from '../Core';
import { CRUDGqlService, ICRUDGqlService } from '../Core/crud';
import { customCampaignListQuery, CustomCampaign } from '../Query/customCampaign';

export interface ICustomCampaignGqlService extends ICRUDGqlService<any> {
  bank_dealers: ICRUDGqlService<any>;
  getCustomCampaign(): Promise<any>;
  handleUpdateEvaluate(form: any): Promise<any>;
  handleOnOff(id: string, status: string): Promise<any>;
  getAllSample(): Promise<any>;
  downloadSample(id: string): Promise<any>;
  importBankingPayment(form: any): Promise<any>;
  checkAsPaid(ids: any, updatedBy: string, paidAt: any): Promise<any>;
  exportSalesEduCampaign(object: any): Promise<any>;
  getListTrainee(status: any, pagination: any, referenceId: any): Promise<any>;
  getListImportHistory(status: any, importedAt: any, search: any, pagination: any): Promise<any>;
  getListDealerDetail(status: any, sheet: any, importCode: any, pagination: any): Promise<any>;
  downloadImportLogs(form: any): Promise<any>;
}
export function CustomCampaignGqlService(): ICustomCampaignGqlService {
  async function getCustomCampaign(): Promise<any> {
    const results = await gqlClient.query({
      query: customCampaignListQuery.GET_CUSTOM_CAMPAIGN,
      fetchPolicy: 'network-only'
    });
    if (results.errors) {
      throw results.errors;
    }

    return results.data.picture_campaign_config;
  }
  async function handleUpdateEvaluate(form: any): Promise<any> {
    const results = await gqlClient.mutate({
      mutation: customCampaignListQuery.HANDLE_STATUS_EVALUATE,
      variables: {
        form
      },
    });
    if (results.errors) {
      throw results.errors;
    }

  }
  async function handleOnOff(id: string, status: string): Promise<any> {
    const results = await gqlClient.mutate({
      mutation: customCampaignListQuery.HANDLE_ON_OFF_EVALUATE,
      variables: {
        id,
        status
      },
    });
    if (results.errors) {
      throw results.errors;
    }

  }
  async function getAllSample(): Promise<any> {
    const results = await gqlClient.mutate({
      mutation: customCampaignListQuery.GET_ALL_SAMPLE,
      variables: {},
    });
    if (results.errors) {
      throw results.errors;
    }

    return results.data.insert_export_participant_campaigns.returning[0].id;

    // if (results.data.insert_export_participant_campaigns.returning[0].id) {
    //   const url = await gqlClient.query({
    //     query: customCampaignListQuery.GET_URl_SAMPLE,
    //     variables: {id: results.data.insert_export_participant_campaigns.returning[0].id},
    //     fetchPolicy: 'network-only'
    //   });
    //   if (url.errors) {
    //     throw url.errors;
    //   }

    //   return url;
    // }

  }
  async function downloadSample(id: string): Promise<any> {
    const results = await gqlClient.subscribe({
      query: customCampaignListQuery.GET_URl_SAMPLE,
      variables: {
        id,
      },
    });

    // tslint:disable-next-line: no-var-before-return
    return results;
  }
  async function importBankingPayment(form: any): Promise<any> {
    const results = await gqlClient.mutate({
      mutation: customCampaignListQuery.IMPORT_BANKING_PAYMENT,
      variables: {
        form,
      },
    });
    if (results.errors) {
      throw results.errors;
    }

    return results;
  }
  async function checkAsPaid(ids: any, updatedBy: string, paidAt: any): Promise<any> {
    const results = await gqlClient.mutate({
      mutation: customCampaignListQuery.CHECK_AS_PAID,
      variables: {
        filter: {
          id: {
            _in: ids
          }
        },
        set: {
          updatedBy,
          paidAt,
          checkedBy: updatedBy
        }
      },
    });
    if (results.errors) {
      throw results.errors;
    }

    return results;
  }

  async function exportSalesEduCampaign(object: any): Promise<any> {
    const results = await gqlClient.mutate({
      mutation: customCampaignListQuery.EXPORT_SALES_EDU,
      variables: {
        object
      },
    });
    if (results.errors) {
      throw results.errors;
    }

    return results.data;
  }
  async function getListTrainee(status: any, pagination: any, referenceId: any): Promise<any> {
    const {limit, page} = pagination;
    const results = await gqlClient.mutate({
      mutation: customCampaignListQuery.GET_LIST_TRAINEE,
      variables: {
        form: {
          filter: {
            bankingPaymentId: referenceId,
            importStatus: status && status.length ? status : ['success', 'failed']
          },
          pagination: {
            page,
            order: ['createdAt'],
            size: limit
          }
        }
      },
    });
    if (results.errors) {
      throw results.errors;
    }

    return results.data.getBankDealerDetail;
  }
  async function getListImportHistory(status: any, importedAt: any, search: any, pagination: any): Promise<any> {
    const {limit, page} = pagination;
    const results = await gqlClient.mutate({
      mutation: customCampaignListQuery.GET_LIST_IMPORT_HISTORY,
      variables: {
        status, search, limit, offset: (page - 1) * limit,
        createdAt: importedAt && (importedAt.from || importedAt.to) ? {
          ...importedAt.from ? {
            _gte: importedAt.from
          } : {},
          ...importedAt.to ? {
            _lte: moment(importedAt.to).add(1, 'days').subtract(1, 'seconds')
          } : {}
        } : {}
      },
    });
    if (results.errors) {
      throw results.errors;
    }

    return results.data;
  }
  async function getListDealerDetail(status: any, sheet: any, importCode: any, pagination: any): Promise<any> {
    const {limit, page} = pagination;
    const results = await gqlClient.mutate({
      mutation: customCampaignListQuery.GET_LIST_TRAINEE_DETAIL,
      variables: {
        form: {
          filter: {
            sheet,
            importCode,
            status: status && status.length ? status : ['success', 'failed'],
          },
          pagination: {
            page,
            order: ['createdAt'],
            size: limit
          }
        },
        dealer: {
          filter: {
            importCode,
            sheet: 'Import dealer',
            status: ['success', 'failed'],
          },
          pagination: {}
        },
        trainee: {
          filter: {
            importCode,
            sheet: 'Import trainee',
            status: ['success', 'failed'],
          },
          pagination: {}
        }
      },
    });
    if (results.errors) {
      throw results.errors;
    }

    return results.data;
  }
  async function downloadImportLogs(form: any): Promise<any> {
    const results = await gqlClient.mutate({
      mutation: customCampaignListQuery.DOWNLOAD_IMPORT_LOGS,
      variables: {
        form
      },
    });
    if (results.errors) {
      throw results.errors;
    }

    return results.data;
  }

  return {
    ...CRUDGqlService<any>(CustomCampaign, customCampaignListQuery),
    getCustomCampaign,
    handleUpdateEvaluate,
    handleOnOff,
    getAllSample,
    downloadSample,
    importBankingPayment,
    checkAsPaid,
    exportSalesEduCampaign,
    getListTrainee,
    getListImportHistory,
    getListDealerDetail,
    downloadImportLogs,
    bank_dealers: CRUDGqlService<any>('bank_dealers', customCampaignListQuery.BankingPayment),
  };
}
