import { cloneDeep, find, findIndex, isArray } from 'lodash';
import moment from 'moment-timezone';
import { IFilterInput } from 'root/models';
import Vue from 'vue';
import Component from 'vue-class-component';
import './styles.scss';

@Component({
  template: require('./view.html'),
  props: {
    filterEnable: Boolean,
    selected: {
      type: Array,
      default: (): IFilterInput[] => [
        {
          key: 'status',
          value: ['processing']
        }
      ]
    },
    data: {
      type: Array,
      default: () => {
        return [];
      }
    },
    isShowDateRange: {
      type: Boolean,
      default: false
    },
    isShowMonthRange: {
      type: Boolean,
      default: false
    },
    titleDateRange: {
      type: String,
      default: ''
    },
    fixTopPopover: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: 'Filter'
    },
    disableValue: {
      type: Array,
      default: () => {
        return [];
      }
    },
  },
  computed: {
    // cloneData() {}
  }
})

export class ButtonFilter extends Vue {
  public data: IFilterInput[];
  public newFilter: IFilterInput[] = [];
  public deleted: IFilterInput[] = [];
  public selected: IFilterInput[];
  public isShow: boolean = false;
  // public total: string = null;
  public isSingleCheckbox: boolean;
  public periodTime: any = {
    from: '',
    to: ''
  };
  public resultSearch: IFilterInput[] = [];
  public searchText: any = {};

  public get total(): string {
    let _total = 0;
    this.currentChecked.map((e) => {
      _total = isArray(e.value) ? _total + e.value.length : _total;
    });
    if (this.isIndeterminateDateRange) {
      _total += 1;
    }

    return _total ? `(${_total})` : null;
  }

  // public get label() {
  //   return 'Filter';
  // }
  public get dataFilter() {
    return this.resultSearch.length > 0 ? this.resultSearch : this.data;
  }
  public get pickerOptionPeriodTimeFrom() {
    return {
      disabledDate: (time) => {
        return time.getTime() > moment().add(-1, 'days') || (
          this.periodTime.to && time.getTime() > moment(this.periodTime.to).add(-1, 'days')
        );
      }
    };
  }
  public isDisable(value) {
    return this.$props.disableValue.indexOf(value) >= 0;
  }
  public get pickerOptionPeriodTimeTo() {
    return {
      disabledDate: (time) => {
        return time.getTime() >= moment() || (
          this.periodTime.from && time.getTime() <= moment(this.periodTime.from)
        );
      }
    };
  }
  public get isIndeterminateDateRange() {
    if (this.periodTime && this.periodTime.from && this.periodTime.to) {
      return true;
    }

    return false;
  }
  public get currentChecked(): IFilterInput[] {
    const selected = this.selected,
      deleted = this.deleted,
      newFilter = this.newFilter;
    const data = cloneDeep(this.data);

    return data.map((e) => {
      const selectedByKey = selected.find((s) => s.key === e.key),
        valueChecked = selectedByKey ? selectedByKey.value : e.isSingleCheckbox ? false : [];
      const deletedByKey = deleted.find((s) => s.key === e.key),
        valueDeleted = deletedByKey ? deletedByKey.value : [];
      const newByKey = newFilter.find((s) => s.key === e.key),
        valueNew = newByKey ? newByKey.value : e.isSingleCheckbox ? false : [];

      let singleCheckValue = false;
      if (deletedByKey) {
        singleCheckValue = false;
      } else if (newByKey) {
        singleCheckValue = newByKey ? newByKey.value : false;
      } else {
        singleCheckValue = valueChecked;
      }

      return{
        ...e,
        value: e.isSingleCheckbox ? singleCheckValue : e.value.map((v) => {
          if (valueDeleted.indexOf(v.value) > -1) {
            return false;
          }
          if (valueChecked.indexOf(v.value) > -1 || valueNew.indexOf(v.value) > -1) {
            return v.value;
          }
        }).filter((v) => v)
      };
    }).filter((e) => e.value || e.isSingleCheckbox);
  }
  public get slots() {
    return this.$slots;
  }

  public click() {
    this.$emit('click');
    this.$emit('update:filterEnable', false);
  }

  public isIndeterminate(key: string) {
    const data = cloneDeep(this.data),
      values = find(data, (e) => e.key === key),
      currentChecked = this.currentChecked.find((e) => e.key === key),
      currentCheckedVale = currentChecked ? currentChecked.value : null;

    return currentCheckedVale.length > 0 && currentCheckedVale.length < values.value.length;
  }
  public formaterChecked(value: any, key: string) {
    const newFilter = find(this.newFilter, (e) => e.key === key),
      newFilterValue = newFilter ? newFilter.value : [];
    const selected = find(this.selected, (e) => e.key === key),
      selectedValue = selected ? selected.value : [];
    const deleted = find(this.deleted, (e) => e.key === key),
      deletedValue = deleted ? deleted.value : [];

    if (deletedValue.indexOf(value) > -1) {
      return false;
    }
    if (newFilterValue.indexOf(value) > -1 || selectedValue.indexOf(value) > -1) {
      return true;
    }

    return false;
  }

  public isCheckAll(key: string) {
    const data = cloneDeep(this.data),
      values = find(data, (e) => e.key === key),
      currentChecked = this.currentChecked.find((e) => e.key === key),
      currentCheckedVale = currentChecked ? currentChecked.value : false;

    if (currentChecked.isSingleCheckbox) {
      return currentChecked.value;
    }

    return currentCheckedVale.length > 0 && currentCheckedVale.length === values.value.length;
  }
  public handleCheckAllChange(value: any, key: string) {
    const model = this.data.find((e) => e.key === key);
    if (model.isSingleCheckbox) {
      if (value) {
        this.newFilter.push({
          ...model,
          value
        });
        this.deleted = this.deleted.filter((e) => e.key !== key);
      } else {
        this.deleted.push(model);
        this.newFilter = this.newFilter.filter((e) => e.key !== key);
      }

      return;
    }
    model.value.map((e) => this.handleCheck(value, key, e.value));
  }
  public handleCheck(valueChecked: boolean, key: string, value: string) {
    const newFilterIndex = findIndex(this.newFilter, (e) => e.key === key),
      newFilterValue = newFilterIndex > -1 ? this.newFilter[newFilterIndex].value : [];
    const selectedIndex = findIndex(this.selected, (e) => e.key === key),
      selectedValue = selectedIndex > -1 ? this.selected[selectedIndex].value : [];
    const deletedIndex = findIndex(this.deleted, (e) => e.key === key),
      deletedValue = deletedIndex > -1 ? this.deleted[deletedIndex].value : [];

    if (valueChecked) {
      newFilterValue.push(value);
      if (deletedIndex > -1) {
        this.deleted[deletedIndex].value = deletedValue.filter((e) => e !== value);
      }
      if (newFilterIndex > -1) {
        this.newFilter[newFilterIndex].value = newFilterValue;
      } else {
        this.newFilter.push({
          key,
          value: [value]
        });
      }
    } else {
      const currentValue = Array.isArray(newFilterValue) ? newFilterValue.filter((e) => e !== value) : [];
      if (newFilterIndex > -1) {
        this.newFilter[newFilterIndex].value = currentValue;
      }
      if (selectedValue.indexOf(value) > -1) {
        if (deletedIndex > -1) {
          deletedValue.push(value);
          this.deleted[deletedIndex].value = deletedValue;
        } else {
          this.deleted.push({
            key,
            value: [value]
          });
        }
      }

    }
    this.$emit('handleChangeFilter', this.currentChecked);
  }

  public onEnterFilter(key) {
    this.resultSearch = cloneDeep(this.data);
    const index = findIndex(this.data, (s) => s.key === key);
    // tslint:disable-next-line: max-line-length
    const value = this.searchText[key] ? this.data[index].value.filter((s) => s.name.toLowerCase().includes(this.searchText[key].toLowerCase())) : this.data[index].value;
    this.resultSearch[index] = {...this.resultSearch[index], value};
  }

  public handleClear() {
    this.resultSearch = cloneDeep(this.data);
    this.searchText = {};
    this.newFilter = [];
    this.periodTime = {
      from: '',
      to: ''
    };
    this.deleted = this.selected;
    this.$emit('clear');
  }
  public done() {
    this.resultSearch = cloneDeep(this.data);
    this.searchText = {};
    this.$emit('change', this.currentChecked, this.periodTime);
    // const currentChecked = cloneDeep(this.currentChecked);
    // let total = 0;
    // currentChecked.map((e) => {
    //   total = total + e.value.length;
    // });
    // this.total = total ? `(${total})` : null;
    this.isShow = false;
  }
  public hide() {
    this.resultSearch = cloneDeep(this.data);
    this.searchText = {};
    this.newFilter = [];
    this.deleted = [];
  }
}
