import { crudMutations, CRUDState, ICRUDState } from 'root/store/helpers';

export interface IZeroCampaignState extends ICRUDState<any> {
  loading: boolean;
  ip_campaign_companies: ICRUDState<any>;
}

export const defaultState: IZeroCampaignState = {
  ...CRUDState(),
  loading: false,
  ip_campaign_companies: CRUDState(),
};

export const mutations = {
  ...crudMutations('ip_campaigns'),
  ...crudMutations('ip_campaign_companies', 'id', {keyState: 'ip_campaign_companies'}),
};
