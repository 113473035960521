import { gqlClient } from '../Core';
import { CRUDGqlService, ICRUDGqlService } from '../Core/crud';
import { imports, importListQuery } from '../Query/import';

export interface IManageImportGqlService extends ICRUDGqlService<any> {
  //
  getListImport(): Promise<any>;
  updateReIndex(id: string, reIndex: boolean): Promise<any>;
  getListImportSub(limit: number, offset: number): Promise<any>;
  updateReIndexSub(id: string, reIndex: boolean): Promise<any>;
}
export function manageImportGqlService(): IManageImportGqlService {
  async function getListImport(): Promise<any> {
    const results = await gqlClient.query({
      query: importListQuery.GET_IMPORT_LIST,
      fetchPolicy: 'network-only'
    });
    if (results.errors) {
      throw results.errors;
    }

    return results.data.import_dealers;
  }
  async function updateReIndex(id: string, reIndex: boolean): Promise<any> {
    const result = await gqlClient.mutate({
      mutation: importListQuery.UPDATE_REINDEX,
      variables: {
        id,
        reIndex
      }
    });
    if (result.errors) {
      throw result.errors;
    }
  }
  async function getListImportSub(limit: number, offset: number): Promise<any> {
    const results = await gqlClient.query({
      query: importListQuery.GET_IMPORT_LIST_SUB,
      variables: { limit, offset},
      fetchPolicy: 'network-only'
    });
    if (results.errors) {
      throw results.errors;
    }

    return results.data;
  }
  async function updateReIndexSub(id: string, reIndex: boolean): Promise<any> {
    const result = await gqlClient.mutate({
      mutation: importListQuery.UPDATE_REINDEX_SUB,
      variables: {
        id,
        reIndex
      }
    });
    if (result.errors) {
      throw result.errors;
    }
  }

  return {
    ...CRUDGqlService<any>(imports, importListQuery),
    getListImport,
    updateReIndex,
    getListImportSub,
    updateReIndexSub
  };
}
