import { DKGqlClient } from 'root/api/graphql/Client';
import { ActionType, IState } from 'root/store';
import { crudActions } from 'root/store/helpers';
import { ActionContext } from 'vuex';
import { IZeroCampaignState } from './mutation';
import { ActionTypeZeroCampaign, ActionTypeZeroCampaignRequestReview } from './types';

const { zeroCampaign } = DKGqlClient();
export const actions = {
  ...crudActions('ip_campaigns', {
    filter: zeroCampaign.filter
  }),
  ...crudActions('ip_campaign_companies', {
    filter: zeroCampaign.ip_campaign_companies.filter,
    update: zeroCampaign.ip_campaign_companies.update
  }, {keyState: 'ip_campaign_companies'}),
  async [ActionTypeZeroCampaign.GetZeroCampaignById]({
    dispatch
  }: ActionContext<IZeroCampaignState, IState>,
                                                     {id, onSuccess}) {
    try {
      const campaign = await zeroCampaign.getZeroCampaignById(id);
      if (onSuccess) {
        onSuccess(campaign);
      }
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    }
  },
  async [ActionTypeZeroCampaign.UpdateSalesmanInfo]({
    dispatch
  }: ActionContext<IZeroCampaignState, IState>,
                                                    {id, set, onSuccess}) {
    try {
      await zeroCampaign.updateSalesmanInfo(id, set);
      if (onSuccess) {
        onSuccess();
      }
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    }
  },
  async [ActionTypeZeroCampaign.GetListFinancialCompany]({
    dispatch
  }: ActionContext<IZeroCampaignState, IState>,
                                                         {alias, onSuccess}) {
    try {
      const listId = await zeroCampaign.getListIdFinancialCompany(alias);
      if (onSuccess) {
        onSuccess(listId);
      }
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    }
  },
  async [ActionTypeZeroCampaign.UpdateFinancialCompany]({
    dispatch
  }: ActionContext<IZeroCampaignState, IState>,
                                                        {campaignId, id, createdBy, onSuccess}) {
    try {
      await zeroCampaign.updateFinancialCompany(campaignId, id, createdBy);
      if (onSuccess) {
        onSuccess();
      }
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    }
  },
  async [ActionTypeZeroCampaign.AprroveRejectCampaign]({
    dispatch
  }: ActionContext<IZeroCampaignState, IState>,
                                                       {id, companyStatus, reason, updatedBy, updatedAt, onSuccess}) {
    try {
      await zeroCampaign.approveRejectCampaign(id, companyStatus, reason, updatedBy, updatedAt);
      if (onSuccess) {
        onSuccess();
      }
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    }
  },
  async [ActionTypeZeroCampaign.ImportPosCode]({
    dispatch
  }: ActionContext<IZeroCampaignState, IState>,
                                               {form, onSuccess}) {
    try {
      const result = await zeroCampaign.importPosCode(form);
      if (onSuccess) {
        onSuccess(result);
      }
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    }
  },
  async [ActionTypeZeroCampaign.GetConfig]({
    dispatch
  }: ActionContext<IZeroCampaignState, IState>,
                                           {onSuccess}) {
    try {
      const config = await zeroCampaign.getConfig();
      if (onSuccess) {
        onSuccess(config);
      }
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    }
  },
  async [ActionTypeZeroCampaign.ForceCloseCode]({
    dispatch
  }: ActionContext<IZeroCampaignState, IState>,
                                                {form, onSuccess}) {
    try {
      await zeroCampaign.forceCloseCode(form);
      if (onSuccess) {
        onSuccess();
      }
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    }
  },
  async [ActionTypeZeroCampaign.ExportCode]({
    dispatch
  }: ActionContext<IZeroCampaignState, IState>,
                                            {form, onSuccess}) {
    try {
      const url = await zeroCampaign.exportCode(form);
      if (onSuccess) {
        onSuccess(url);
      }
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    }
  },
  async [ActionTypeZeroCampaign.GetStatistic]({
    dispatch
  }: ActionContext<IZeroCampaignState, IState>,
                                              {time, onSuccess}) {
    try {
      const statistic = await zeroCampaign.getStatistic(time);
      if (onSuccess) {
        onSuccess(statistic);
      }
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    }
  },
  async [ActionTypeZeroCampaign.GetStatisticTable]({
    dispatch
  }: ActionContext<IZeroCampaignState, IState>,
                                                   {time, onSuccess}) {
    try {
      const statistic = await zeroCampaign.getStatisticTable(time);
      if (onSuccess) {
        onSuccess(statistic);
      }
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    }
  },
  async [ActionTypeZeroCampaign.GetStatisticDataByBranch]({
    dispatch
  }: ActionContext<IZeroCampaignState, IState>,
                                                          {name, time, onSuccess, onFailure}) {
    try {
      const result = await zeroCampaign.getStatisticByBranch(name, time);
      if (onSuccess) {
        onSuccess(result);
      }
    } catch (error) {
      if (onFailure) {
        onFailure();
      }
      dispatch(ActionType.CatchException, error);
    }
  },
  async [ActionTypeZeroCampaign.GetStatisticForDepartmentPic]({
    dispatch
  }: ActionContext<IZeroCampaignState, IState>,
                                                              {onSuccess}) {
    try {
      const statistic = await zeroCampaign.getStatisticForDepartmentPic();
      if (onSuccess) {
        onSuccess(statistic);
      }
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    }
  },
  async [ActionTypeZeroCampaign.UpdateOwnerNameInfo]({
    dispatch
  }: ActionContext<IZeroCampaignState, IState>,
                                                     {shopId, set, onSuccess}) {
    try {
      await zeroCampaign.updateOwnerInfo(shopId, set);
      if (onSuccess) {
        onSuccess();
      }
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    }
  },
  async [ActionTypeZeroCampaign.UpdatePOSCode]({
    dispatch
  }: ActionContext<IZeroCampaignState, IState>,
                                               {id, posCode, onSuccess}) {
    try {
      await zeroCampaign.updatePOSCode(id, posCode);
      if (onSuccess) {
        onSuccess();
      }
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    }
  },
  async [ActionTypeZeroCampaign.ApproveCampaign]({
    dispatch
  }: ActionContext<IZeroCampaignState, IState>,
                                                 {id, set, onSuccess}) {
    try {
      await zeroCampaign.approveCampaign(id, set);
      if (onSuccess) {
        onSuccess();
      }
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    }
  },
  async [ActionTypeZeroCampaign.GetStatisticParticipantList]({
    dispatch
  }: ActionContext<IZeroCampaignState, IState>,
                                                             {where, onSuccess}) {
    try {
      const data = await zeroCampaign.getStatisticListParticipant(where);
      if (onSuccess) {
        onSuccess(data);
      }
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    }
  },
  async [ActionTypeZeroCampaignRequestReview.ApproveAll]({
    dispatch
  }: ActionContext<IZeroCampaignState, IState>,
                                                         {ids, set, onSuccess, onFailure}) {
    try {
      await zeroCampaign.approveAll(ids, set);
      if (onSuccess) {
        onSuccess();
      }
    } catch (error) {
      dispatch(ActionType.CatchException, error);
      onFailure();
    }
  },
  async [ActionTypeZeroCampaign.GetStatisticTopList]({
    dispatch
  }: ActionContext<IZeroCampaignState, IState>,
                                                     {form, onSuccess}) {
    try {
      const data = await zeroCampaign.getStatisticTopList(form);
      if (onSuccess) {
        onSuccess(data);
      }
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    }
  },
  async [ActionTypeZeroCampaign.GetStatisticTopListShop]({
    dispatch
  }: ActionContext<IZeroCampaignState, IState>,
                                                         {form, onSuccess}) {
    try {
      const data = await zeroCampaign.getStatisticTopListShop(form);
      if (onSuccess) {
        onSuccess(data);
      }
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    }
  },
  async [ActionTypeZeroCampaign.ExportCampaignOverview]({
    dispatch
  }: ActionContext<IZeroCampaignState, IState>,
                                                        {form, onSuccess}) {
    try {
      const data = await zeroCampaign.exportCampaignOverview(form);
      if (onSuccess) {
        onSuccess(data);
      }
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    }
  },
  async [ActionTypeZeroCampaign.GetStatisticParticipantListSaleAdmin]({
    dispatch
  }: ActionContext<IZeroCampaignState, IState>,
                                                                      {onSuccess}) {
    try {
      const data = await zeroCampaign.getStatisticListParticipantSaleAdmin();
      if (onSuccess) {
        onSuccess(data);
      }
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    }
  },
  async [ActionTypeZeroCampaign.GetConfiguration]({
    dispatch
  }: ActionContext<IZeroCampaignState, IState>,
                                                  {onSuccess}) {
    try {
      const campaign = await zeroCampaign.getConfiguration();
      if (onSuccess) {
        onSuccess(campaign);
      }
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    }
  },
  async [ActionTypeZeroCampaign.UpdateConfiguration]({
    dispatch
  }: ActionContext<IZeroCampaignState, IState>,
                                                     {key, status, reason, updatedBy, onSuccess}) {
    try {
      const campaign = await zeroCampaign.updateConfiguration(key, status, reason, updatedBy);
      if (onSuccess) {
        onSuccess(campaign);
      }
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    }
  },
  async [ActionTypeZeroCampaign.GetConfigHistoryLogs]({
    dispatch
  }: ActionContext<IZeroCampaignState, IState>,
                                                      {pagination, onSuccess}) {
    try {
      const { limit, page } = pagination;
      const offset = limit * (page - 1);
      const result = await zeroCampaign.getConfigHistoryLogs(limit, offset);
      if (onSuccess) {
        onSuccess(result);
      }
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    }
  },
};
