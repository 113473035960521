import { PermissionType } from 'root/models';
import { RouteConfig } from 'vue-router';
import { ApplicationFeedbackList } from './ApplicationFeedbackList';
import { ManageFeedbackContainer } from './ManageFeedbackContainer';
import { ProfileFeedbackList } from './ProfileFeedbackList';
import { SerciceFeedbackList } from './ServiceFeedbackList';

export enum ManageFeedbackRouterPath {
  ManageFeedbackList = '/manage-feedback',
  ChangeProfileList = '/profile-feedback',
  ApplicationFeedbackList = '/application-feedback',
  SerciceFeedbackList = '/service-feedback',
}

export enum ManageFeedbackRouterName {
    ManageFeedbackList = 'ListFeedback',
    ChangeProfileList = 'ChangeProfileList',
    ApplicationFeedbackList = 'ApplicationFeedbackList',
    SerciceFeedbackList = 'SerciceFeedbackList',
}

export const manageFeedbackRouter: RouteConfig = {
  path: '/manage-feedback',
  component: ManageFeedbackContainer,
  redirect: ManageFeedbackRouterPath.ChangeProfileList,
  meta: {
    permission: PermissionType.Feedback
  },
  children: [
    {
      path: ManageFeedbackRouterPath.ChangeProfileList,
      component: ProfileFeedbackList,
      name: ManageFeedbackRouterName.ChangeProfileList,
      meta: {
        permission: PermissionType.Feedback
      }
    },
    {
      path: ManageFeedbackRouterPath.ApplicationFeedbackList,
      component: ApplicationFeedbackList,
      name: ManageFeedbackRouterName.ApplicationFeedbackList,
      meta: {
        permission: PermissionType.Feedback
      }
    },
    {
      path: ManageFeedbackRouterPath.SerciceFeedbackList,
      component: SerciceFeedbackList,
      name: ManageFeedbackRouterName.SerciceFeedbackList,
      meta: {
        permission: PermissionType.Feedback
      }
    },
  ]
};
