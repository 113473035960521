import { crudMutations, CRUDState, ICRUDState } from 'root/store/helpers';
import { MutationTypeUser } from './types';

export interface IUserState extends ICRUDState<any> {
  loading: boolean;
  staff: any;
  dealer: any;
}

export const defaultState: IUserState = {
  ...CRUDState(),
  loading: false,
  staff: [],
  dealer: []
};

export const mutations = {
  ...crudMutations('user'),
  [MutationTypeUser.GetTeamStaff](state: IUserState, data) {
    state.staff = data;
  },
  [MutationTypeUser.GetTeamStaffDealer](state: IUserState, data) {
    state.dealer = data;
  }
};
