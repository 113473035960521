import { cloneDeep, find } from 'lodash';
import { IBranchManageState } from './mutation';

export const getters = {
  getBranhManageDetail(state: IBranchManageState) {

    return (id: string) => {

      return find(cloneDeep(state.data), (e) => e.id === id);
    };
  },
};
