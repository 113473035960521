import { crudMutations, CRUDState, ICRUDState } from 'root/store/helpers';
import { MutationTypeNewRaCampaign } from './types';

export interface INewRACampaignState extends ICRUDState<any> {
  loading: boolean;
  listJoinedShop: any;
  listSubDealerCampaign: any;
  listQualifiedRequest: any;
  acl_requests: ICRUDState<any>;
  acl_request_reviews: ICRUDState<any>;
  manageExport: any;
}

export const defaultState: INewRACampaignState = {
  ...CRUDState(),
  loading: false,
  listJoinedShop : [],
  listSubDealerCampaign: [],
  listQualifiedRequest: [],
  acl_requests: CRUDState(),
  acl_request_reviews: CRUDState(),
  manageExport: [],
};

export const mutations = {
  ...crudMutations('new_ra_campaign'),
  ...crudMutations('acl_requests', 'id', {keyState: 'acl_requests'}),
  ...crudMutations('acl_request_reviews', 'id', {keyState: 'acl_request_reviews'}),
  [MutationTypeNewRaCampaign.SetNewRaCampaign](state: INewRACampaignState, data) {
    state.data = data.dealers;
    state.pagination.total = data.dealers_aggregate.aggregate.count;
  },
  [MutationTypeNewRaCampaign.NewRaCampaignLoading](state: INewRACampaignState) {
    state.loading = true;
  },
  [MutationTypeNewRaCampaign.NewRaCampaignLoaded](state: INewRACampaignState) {
    state.loading = false;
  },
  [MutationTypeNewRaCampaign.SetCampaignDetail](state: INewRACampaignState, data) {
    state.data = data.dealers;
    state.pagination.total = data.dealers[0].shops_aggregate.aggregate.count;
  },
  [MutationTypeNewRaCampaign.SetJoinedShopList](state: INewRACampaignState, data) {
    state.listJoinedShop = data.shops;
    state.pagination.total = data.shops_aggregate.aggregate.count;
  },
  [MutationTypeNewRaCampaign.SetSubDealerCampaign](state: INewRACampaignState, data) {
    state.listSubDealerCampaign = data.dealers;
    state.pagination.total = data.dealers_aggregate.aggregate.count;
  },
  [MutationTypeNewRaCampaign.SetQualifiedRequest](state: INewRACampaignState, data) {
    state.listQualifiedRequest = data.acl_campaigns_2023_view;
    state.pagination.total = data.acl_campaigns_2023_view_aggregate.aggregate.count;
  },
  [MutationTypeNewRaCampaign.SetManageExport](state: INewRACampaignState, data) {
    state.manageExport = data.exports;
    state.pagination.total = data.exports_aggregate.aggregate.count;
  },
};
