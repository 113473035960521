import { Form } from 'element-ui';
import { cloneDeep } from 'lodash';
import { ruleCompare, ruleMinLength, ruleRequired } from 'root/helpers';
import { IUser } from 'root/models';
import { ActionType, IState } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import { ActionTypeUser } from '../../Store/types';
import './styles.scss';

@Component({
  template: require('./view.html'),
  computed: {
    ...mapState({
      authUser: (state: IState) => state.global.authUser,
    })
  },
  props: {
    popupVisible: Boolean
  }
})

export class ChangePasswordDialog extends Vue {
  public $refs: {
    form: Form
  };
  public authUser: IUser;
  public form = {
    newPassword: '',
    reTypePassword: ''
  };
  public get rules() {
    return {
      required: ruleRequired(),
      ruleMinLength: ruleMinLength(8, ''),
      compare: ruleCompare(this.form.newPassword)
    };
  }
  public loading: boolean = false;
  public openDialog() {
    if (this.$refs.form) {
      this.$refs.form.clearValidate();
    }
  }
  public closeDialog() {
    this.form = {
      newPassword: '',
      reTypePassword: ''
    };
    this.$emit('update:popupVisible', false);
  }
  public handleConfirm() {
    this.$refs.form.validate(async (valid) => {
      if (valid) {
        this.loading = true;
        const form = cloneDeep(this.form),
          {newPassword} = form;
        try {
          const formChangePass = {
            id: this.authUser.id,
            password: newPassword
          };
          this.$store.dispatch(ActionTypeUser.ChangePassSystemUser, {
            form: formChangePass,
            onSuccess: () => {
              this.loading = false;
              this.$message.success('Update Password Successfull!');
              this.closeDialog();
            }
          });
        } catch (error) {
          this.loading = false;
          this.$store.dispatch(ActionType.CatchException, error);
        }
      }

      return;
    });
  }
}
