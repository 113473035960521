export enum ActionTypeDealerShop {
  GET_LIST_DEALERSHOP = 'getListDealerShop',
  CREATE_SHOP = 'createShop',
  FilterNoCache = 'dealerShopFilterNoCache',
  GET_SHOP_DETAIL = 'getDealerShopDetail',
  UPDATE_SHOP = 'updateShop'
}
export enum MutationTypeDealerShop {
  GetListDealerShop = 'dealerShopGetList',
  DealerShopLoading = 'dealerShopLoading',
  DealerShopLoaded = 'dealerShopLoaded',
  SetPagination = 'dealerShopSetPagination',
  PaginationChange = 'dealerShopPaginationChange'
}
