import { gqlClient } from '../Core';
import { CRUDGqlService, ICRUDGqlService } from '../Core/crud';
import { regions, regionsListQuery } from '../Query/region';

export interface IRegionsGqlService extends ICRUDGqlService<any> {
  insertRegion(form: any): Promise<any>;
  updateRegion(id: string, form: any): Promise<any>;
  regionGetById(id: string): Promise<any>;
  regionGetListBranch(id: string): Promise<any>;
  //
}
export function RegionsGqlService(): IRegionsGqlService {
  async function insertRegion(form: any): Promise<any> {
    const results = await gqlClient.mutate({
      mutation: regionsListQuery.INSERT_REGION,
      variables: {
        form
      },
    });
    if (results.errors) {
      throw results.errors;
    }

    return results;
  }
  async function updateRegion(id: string, form: any): Promise<any> {
    const results = await gqlClient.mutate({
      mutation: regionsListQuery.UPDATE_REGION,
      variables: {
        id,
        form
      },
    });
    if (results.errors) {
      throw results.errors;
    }

    return results;
  }
  async function regionGetById(id: string): Promise<any> {
    const results = await gqlClient.query({
      query: regionsListQuery.REGION_GET_BY_ID,
      variables: {
        id
      },
      fetchPolicy: 'network-only'
    });
    if (results.errors) {
      throw results.errors;
    }

    return results.data.regions[0];
  }
  async function regionGetListBranch(id: string): Promise<any> {
    const results = await gqlClient.query({
      query: regionsListQuery.REGION_GET_LIST_BRANCH,
      variables: {
        id
      },
      fetchPolicy: 'network-only'
    });
    if (results.errors) {
      throw results.errors;
    }

    return results.data.regions[0];
  }

  return {
    ...CRUDGqlService<any>(regions, regionsListQuery),
    insertRegion,
    updateRegion,
    regionGetById,
    regionGetListBranch
  };
}
