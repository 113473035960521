import './styles.scss';

// import moment from 'moment';
import { stripObject } from '@hgiasac/helper';
import { cloneDeep } from 'lodash';
import { IPaginationParams } from 'root/api/graphql/Core';
import { IUser } from 'root/models/User';
import { IState, MutationType } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import { DealerTypeDetail } from '../Components/DealerTypeDetail';
import { DealerTypeEditor } from '../Components/DealerTypeEditor';
import { ActionTypeDealerType, MutationTypeDealerType } from '../Store/types';

@Component({
  template: require('./view.html'),
  components: {
    editor: DealerTypeEditor,
    'dealer-type-detail-modal': DealerTypeDetail,
  },
  computed: {
    ...mapState({
      data: (state: IState) => state.dealerType.data,
      pagination: (state: IState) => state.dealerType.pagination,
      loading: (state: IState) => state.dealerType.loading,
    }),
  },
})
export class DealerTypeList extends Vue {
  public get serviceId(): string {
    return this.$route.params.id;
  }
  public get actions() {

    return ['view', 'edit'];
  }

  public get columns() {

    return [
      {
        prop: 'name',
        label: 'Name',
        width: 180,
        formatter: (model: any) => {
          if (model.name) {
            return model.name || '--';
          }

          return '--';
        }
      },
      {
        prop: 'type',
        label: 'Type',
        width: 180,
        formatter: (model: any) => {
          if (model.type) {
            return model.type || '--';
          }

          return '--';
        }
      },
      {
        prop: 'description',
        label: 'Description',
        formatter: (model: any) => {
          return model.description || '--';
        },
        width: 140
      },
      {
        prop: 'status',
        label: this.$t('status'),
        formatter: (model: any) => (model.status ? this.$t(model.status) : ''),
        labelStyle: (model: any) => {
          return model.status;
        },
        align: 'center',
        width: 180
      },
      {
        prop: 'action',
        label: '',
        align: 'center',
        fixed: 'right',
        width: 60
      }
    ];
  }

  public authUser: IUser;
  public pagination: IPaginationParams;
  public data: any[];
  public deviceType: any[];
  public visible: boolean = false;
  public isSample: boolean = false;
  public userId: string = '';
  public visibleDetail: boolean = false;
  public region: any[];
  public searchText: string = '';

  public UserType = {
    Customer: 'customer',
    System: 'system'
  };
  public type: any;
  public isRefesh: boolean;
  public displayListManager(model: any) {
    if (model.branchManagers && model.branchManagers.length > 0) {
      return model.branchManagers.map((t) => `${t.name}`).join(', ');
    }

    return '__';
  }
  public displayListGroups(model: any) {
    if (model.groups && model.groups.length > 0) {
      return model.groups.map((t) => `${t.name}`).join(', ');
    }

    return '__';
  }

  public formatterDepartmentQty(model: any) {
    if (model.branches && model.branches.length) {
      let count = 0;
      model.branches.map((item) => {
        count += item.groups_aggregate.aggregate.count;
      });

      return count;
    }

    return 0;
  }

  public handleSearch() {
    this.fetchData();
  }
  public getActions() {

    return [
      'view',
      'edit',
    ];
  }
  public handleActions({ alias, action }) {
    if (action === 'edit') {
      this.handleUpdate(alias);

      return;
    }

    if (action === 'view') {
      this.clickDetail(alias);

      return;
    }

    return;
  }

  public handleChangePagination(value) {
    if (typeof value === 'number') {
      this.$store.commit(MutationTypeDealerType.PaginationChange, {
        ...this.pagination,
        size: value
      });
    } else {
      this.$store.commit(MutationTypeDealerType.PaginationChange, {
        ...this.pagination,
        ...value
      });
    }
    this.fetchData();
  }

  public closeDialogDetail() {
    this.userId = '';
    this.visibleDetail = false;
  }

  public createSuccess() {
    this.visible = false;
    this.fetchData();
  }

  public applyFilter() {
    this.fetchData();
  }
  public goAddNewRouter() {
    this.visible = true;
    this.userId = '';
  }
  public closeDialog() {
    this.isSample = false;
    this.fetchData();
  }

  public clickDetail(id: string) {
    this.userId = id;
    this.visibleDetail = true;
  }
  public handleUpdate(id: string) {
    this.visible = true;
    this.userId = id;
  }

  protected mounted() {
    this.$nextTick(() => {
      this.$store.commit(MutationType.ClearBack);
      this.$store.commit(MutationType.SetBreadcrumb, this.$t('title.manageregion'));
      // this.$store.commit(MutationTypeUser.PaginationReset);
      !this.isRefesh && this.fetchData();
    });
  }
  protected beforeDestroy() {
    this.$store.commit(MutationType.ClearBreadcrumb);
  }

  private fetchData() {
    const text = cloneDeep(this.searchText);
    const _filterParams = <any> {};
    if (text) {
      _filterParams.search = text;
    }
    this.$store.commit(
      MutationTypeDealerType.FilterChange,
      stripObject({
        _and:
          text.trim() !== '' ?
            {
              mode: 'special',
              data: {
                _or: [
                  {
                    name: {
                      _ilike: `%${text}%`
                    }
                  },
                ]
              }
            } :  null
      })
    );
    this.$store.dispatch(ActionTypeDealerType.FilterNoCache, {
      optionsHTTP: {
        orderBy: {
          updatedAt: 'desc'
        }
      }
    });
  }
}
