import { DocumentNode } from 'graphql';
import gql from 'graphql-tag';
import { crudQuery, ICRUDQuery } from '../Core/query';

const pointModel = `
data {
  branchId
  campaignId
  createdAt
  dealerId
  expiredTime
  id
  pointStatus
  rewardTitle
  source
  title
  totalPoint
  updatedAt
}
pagination {
  nextPageToken
  page
  size
  total
  totalPages
  totalPerPage
}
`;

export const Points = 'points';
// tslint:disable-next-line:no-empty-interface
interface IPointQuery extends ICRUDQuery {
  GET_POINT_LIST: DocumentNode;
}
export const pointListQuery: IPointQuery = {
  ...crudQuery(Points, pointModel),
  GET_POINT_LIST: gql`
  query GET_POINT_LIST($form: FilterPointHistory) {
    getPointHistory(form: $form){
      ${pointModel}
    }
  }
`,
};
