import { ActionTypeSaleInCampaign } from 'root/admin/CustomCampaign/SaleInCampaign/Store/types';
import { DKEditor } from 'root/components/DKCustomEditor';
import Vue from 'vue';
import Component from 'vue-class-component';
import './styles.scss';

@Component({
  template: require('./view.html'),
  props: {
    isUpdate: Boolean
  },
  components: {
    'dk-editor': DKEditor,
  }
})

export class SaleInFormConditions extends Vue {
  public get targetType() {
    return [
      {
        key: 'discount',
        value: 'discount',
        label: 'Discount'
      },
      {
        key: 'gift',
        value: 'gift',
        label: 'Gift'
      },
    ];
  }
  public get columns() {
    return [
      {
        label: 'name',
        labelItem: 'Code',
        width: 164
      },
      {
        label: 'Type',
        width: 140
      },
      {
        label: 'Phone',
        width: 160
      },
      {
        label: 'Branch',
        width: 140
      }
    ];
  }
  public form: any = {};
  public listCondition: any[] = [];
  public activeNames: any = 0;
  public searchText: string = '';
  public metadata: any = null;
  public conditionValue: any[] = [];
  public SBUOptions: any[] = [];
  public RewardOptions: any[] = [];
  public ModelOptions: any[] = [];
  public campaignType: string = '';
  public deleteCondition(index) {
    this.listCondition.splice(index, 1);
  }
  public getUnitPrice(reward) {
    const price = this.RewardOptions.find((it) => it.value === reward);

    return price && price.unitPrice ? price.unitPrice : '--';
  }
  public onChangeSBU(sbu, index) {
    this.listCondition[index].model = [];
    this.ModelOptions = this.metadata.find((it) => it.id === sbu).models.map((model) => ({
      label: model.modelNumber,
      value: model.id
    }));
  }
  public onchangeCheckBoxReward(_index) {
    // // this.listCondition[index].rewardNotifyContent = null;
    // this.listCondition[index] = {
    //   ... this.listCondition[index],
    //   rewardNotifyContent: null
    // };
  }
  public onchangeCheckBoxIncentive(_index) {
    // this.listCondition[index] = {
    //   ... this.listCondition[index],
    //   incentiveConditionFrom: null
    // };
    // this.listCondition[index] = {
    //   ... this.listCondition[index],
    //   incentiveConditionTo: null
    // };
    // this.listCondition[index] = {
    //   ... this.listCondition[index],
    //   incentiveNotifyContent: null
    // };
    // this.listCondition[index].incentiveConditionFrom = null;
    // this.listCondition[index].incentiveConditionTo = null;
    // this.listCondition[index].incentiveNotifyContent = null;
  }
  public async mounted() {
    this.$store.dispatch(ActionTypeSaleInCampaign.GetConditionMetadata, {
      onSuccess: (data) => {
        this.metadata = data.si_campaign_data;
        this.SBUOptions = data.si_campaign_data.map((it) => ({
          label: it.value,
          value: it.id
        }));
        this.RewardOptions = data.si_campaign_rewards.map((it) => ({
          label: it.rewardName,
          value: it.id,
          unitPrice: it.price
        }));
        this.ModelOptions = [];
        this.metadata.map((model) => {
          model.models.map((el) => {
            this.ModelOptions.push({
              label: el.modelNumber,
              value: el.id
            });
          });
        });
      }
    });
  }
}
