import { DKGqlClient } from 'root/api/graphql/Client';
import { pathUpload, sizeImage, uploadToFirebase } from 'root/services';
import { FirebaseAuthService } from 'root/services/auth/firebase';

export class DKCustomUploadAdapter {
  private loader: any;
  private path: pathUpload;

  constructor(loader: any, path: pathUpload) {
    this.loader = loader;
    this.path = path;
  }
  // Starts the upload process.
  public async upload() {
    try {
      const token = await DKGqlClient().user.getFirebaseAuthenticateToken();
      await FirebaseAuthService().signInWithCustomToken(token.authenticate);
      const file = await this.loader.file;
      const imgUrl = await uploadToFirebase(file, this.path, {
        ...sizeImage.message.original,
        quality: 1
      });

      return Promise.resolve({
        default: imgUrl
      });
    } catch (error) {
      return Promise.reject(error);
    }
  }
}

export function DKCustomUploadAdapterPlugin(editor: any, path: pathUpload) {
  editor.plugins.get('FileRepository').createUploadAdapter = (loader: any) => {
    // Configure the URL to the upload script in your back-end here!
    return new DKCustomUploadAdapter(loader, path);
  };
}
