import { crudMutations, CRUDState, ICRUDState } from 'root/store/helpers';
import { MutationTypeProduct } from './types';

export interface IProductState extends ICRUDState<any> {
  loading: boolean;
  dealerDetail: any;
  dealerProductlist: any[];
  deviceType: any[];
}

export const defaultState: IProductState = {
  ...CRUDState(),
  loading: false,
  dealerDetail: {},
  dealerProductlist: [],
  deviceType: [],
};

export const mutations = {
  ...crudMutations('product'),
  [MutationTypeProduct.SetProductRegistation](state: IProductState, data) {
    state.data = data.dealers;
    state.pagination.total = data.dealers_aggregate.aggregate.count;
  },
  [MutationTypeProduct.SetProductWarranty](state: IProductState, data) {
    state.data = data.warranties;
    state.pagination.total = data.warranties_aggregate.aggregate.count;
  },
  [MutationTypeProduct.SetDealerProductDetail](state: IProductState, data) {
    state.dealerDetail = data.dealers[0];
  },
  [MutationTypeProduct.SetDealerProductWarranty](state: IProductState, data) {
    state.dealerProductlist = data.warranties;
    state.pagination.total = data.warranties_aggregate.aggregate.count;
  },
  [MutationTypeProduct.SetDeviceType](state: IProductState, data) {
    state.deviceType = data.device_type;
  },
};
