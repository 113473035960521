import { IDeviceType } from './DeviceParts';

export interface IWorkMasterForm {
  name: string;
  code: string;
  description: string;
  totalHours: number;
  totalHoursForPartner: number;
  deviceTypeId: string;
}
export function workMasterFormDefault(): IWorkMasterForm {
  return {
    description: '',
    code: '',
    name: '',
    totalHours: 0,
    totalHoursForPartner: 0,
    deviceTypeId: ''
  };
}

export interface IWorkMaster extends IWorkMasterForm {
  id: string;
  deviceType?: IDeviceType;
}
export function workMasterDefault(): IWorkMaster {
  return {
    ...workMasterFormDefault(),
    id: ''
  };
}
export enum WorkMasterType {
  Repair = 'repair',
  Installation = 'installation',
  Maintenance = 'maintenance'
}
