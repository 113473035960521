
import moment from 'moment';
import { SystemRoleAlias } from 'root/admin/User/Store/types';
import { FormatDateFullTime } from 'root/helpers';
import { IAddress, IUser } from 'root/models';
import { IState, MutationType, TypeAlert } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import { ActionTypeZeroCampaign } from '../../Store/types';
import { FinancialCompanyColor, FinancialCompanyStatus, FinancialCompanyStatusCode } from '../../ZPCampaignParticipant';
import { ApproveZeroCampaign } from '../ApproveZeroCampaign';
import { CompanyCampaignDetail } from '../CompanyCampaignDetail';
import { ForceCloseCode } from '../ForceCloseCode';
import { RejectZeroCampaign } from '../RejectZeroCampaign';
import { UpdateFinancialCompany } from '../UpdateFinancialCompany';
import { UpdateSalesmanInformation } from '../UpdateSalesmanInformation';
import './styles.scss';
@Component({
  template: require('./view.html'),
  props: {
    visible: Boolean,
    campaignId: String
  },
  computed: {
    ...mapState({
      // userDetail: (state: IState) => state.user.userDetail,
      authUser: (state: IState) => state.global.authUser,
    })
  },
  components: {
    'update-salesman-info': UpdateSalesmanInformation,
    'update-financial-company': UpdateFinancialCompany,
    'approve-confirm': ApproveZeroCampaign,
    'reject-confirm': RejectZeroCampaign,
    'close-code': ForceCloseCode,
    'company-campaign': CompanyCampaignDetail
  }
})
export class ZPCampaignDetail extends Vue {
  public FormatDateFullTime = FormatDateFullTime;
  public campaignId: string;
  public authUser: IUser;
  public campaign: any = {};
  public companyHD: any = {};
  public companyFE: any = {};
  public companyACS: any = {};
  public campaignCompany: any = {};
  public visibleUpdateInfo: boolean = false;
  public salesmanInfo: any = {};
  public listFinancialCompany: any = [];
  public visibleUpdateCompany: boolean = false;
  public visibleApprove: boolean = false;
  public visibleReject: boolean = false;
  public visibleCloseCode: boolean = false;
  public visibleOwnerInfo: boolean = false;
  public visibleEditCode: boolean = false;
  public campaignCompanyId: string = '';
  public ownerName: string = '';
  public ownerPhone: string = '';
  public isErrorOwnerName: boolean = false;
  public isErrorOwnerPhone: boolean = false;
  public loadingSubmit: boolean = false;
  public posCodeValue: string = '';
  public isErrorPOSCode: boolean = false;

  public get isSuperAdmin() {
    if (this.authUser.superAdmin || this.authUser.role.alias === SystemRoleAlias.Admin) {
      return true;
    }

    return false;
  }
  public get isSalePlanning() {
    if (this.authUser.role.alias === SystemRoleAlias.SaleAdmin) {
      return true;
    }

    return false;
  }
  public get isBranchPic() {
    if (this.authUser.role.alias === SystemRoleAlias.BranchManager) {
      return true;
    }

    return false;
  }
  public get isDepartmentPic() {
    if (this.authUser.role.alias === SystemRoleAlias.GroupLeader) {
      return true;
    }

    return false;
  }

  public get checkHasSalesmanInfo() {
    if (this.campaign && this.campaign.salesmanName && this.campaign.salesmanPhone) {
      return true;
    }

    return false;
  }
  public get checkPermissionsDepartment() {
    if (this.isSuperAdmin || this.isDepartmentPic) {
      return true;
    }

    return false;
  }
  public checkPermissionApprovalReject(status) {
    if (this.isSuperAdmin && status === FinancialCompanyStatusCode.WaitingForReview ||
      this.isSalePlanning && status === FinancialCompanyStatusCode.WaitingForReview ||
      this.isDepartmentPic && status === FinancialCompanyStatusCode.WaitingForReview) {
      return true;
    }

    return false;
  }
  public displayAddress(model: IAddress) {
    return model && model.province ? this.$store.getters['getAddressString'](model) : '';
  }
  public formatterFinancialCompanyName(model, alias) {
    if (model && model.ip_campaign_companies && model.ip_campaign_companies.length) {
      const _company = model.ip_campaign_companies.find((company) => company.financial_company.alias === alias);
      if (!_company) {
        return '--';
      }

      return this.formatterFinancialCompanyStatus(_company.companyStatus);
    }

    return '--';
  }
  public formatterFinacialRequestTime(model, alias) {
    if (model && model.ip_campaign_companies && model.ip_campaign_companies.length) {
      const _company = model.ip_campaign_companies.find((company) => company.financial_company.alias === alias);
      if (!_company) {
        return '--';
      }

      return this.FormatDateFullTime(_company.createdAt);
    }

    return '--';
  }
  public formatterFinacialStatusCode(model, alias) {
    if (model && model.ip_campaign_companies && model.ip_campaign_companies.length) {
      const _company = model.ip_campaign_companies.find((company) => company.financial_company.alias === alias);
      if (!_company) {
        return '--';
      }

      return this.formatterFinancialCompanyStatusCode(_company.companyStatus);
    }

    return '--';
  }
  public FormatterDateByStatusLogs(model: any) {
    if (model && model.ip_campaign_company_status_logs.length) {
      let _firstLog: any = model.ip_campaign_company_status_logs[0];
      model.ip_campaign_company_status_logs.map((log) => {
        if (moment(log.createdAt).isAfter(moment(_firstLog.createdAt))) {
          _firstLog = log;
        }
      });

      return this.FormatDateFullTime(_firstLog.createdAt);
    }

    return model.updatedAt ? this.FormatDateFullTime(model.updatedAt) : '--';
  }
  public FormatterDateStopJoining(model) {
    if (model && model.ip_campaign_company_status_logs.length) {
      let _firstLog: any;
      let _secondLog: any;
      model.ip_campaign_company_status_logs.map((log) => {
        if (log.companyStatus === FinancialCompanyStatusCode.HaveCode) {
          _firstLog = log;
        }
        if (log.companyStatus === FinancialCompanyStatusCode.Approved) {
          _secondLog = log;
        }
      });

      return _firstLog && _firstLog.createdAt ? this.FormatDateFullTime(_firstLog.createdAt) :
      _secondLog && _secondLog.createdAt ? this.FormatDateFullTime(_secondLog.createdAt) : '--';
    }

    return '--';
  }
  public FormatterDealerStopJoining(model) {
    if (model && model.ip_campaign_company_status_logs.length) {
      let _firstLog: any;
      let _secondLog: any;
      model.ip_campaign_company_status_logs.map((log) => {
        if (log.companyStatus === FinancialCompanyStatusCode.HaveCode) {
          _firstLog = log;
        }
        if (log.companyStatus === FinancialCompanyStatusCode.Approved) {
          _secondLog = log;
        }
      });

      return _firstLog && _firstLog.dealer && _firstLog.dealer.name ? _firstLog.dealer.name :
      _firstLog && _firstLog.system_user && _firstLog.system_user.name ? _firstLog.system_user.name :
      _secondLog && _secondLog.dealer && _secondLog.dealer.name ? _secondLog.dealer.name :
      _secondLog && _secondLog.system_user && _secondLog.system_user.name ? _secondLog.system_user.name : '--';
    }

    return '--';
  }
  public FormatterDateByStatus(model, status) {
    if (model && model.ip_campaign_company_status_logs.length) {
      let _firstLog: any;
      model.ip_campaign_company_status_logs.map((log) => {
        if (log.companyStatus === status) {
          _firstLog = log;
        }
      });

      return _firstLog && _firstLog.createdAt ? this.FormatDateFullTime(_firstLog.createdAt) : '--';
    }

    return '--';
  }
  public FormatterDealerByStatusLogs(model: any) {
    if (model && model.ip_campaign_company_status_logs.length) {
      let _firstLog: any = model.ip_campaign_company_status_logs[0];
      model.ip_campaign_company_status_logs.map((log) => {
        if (moment(log.createdAt).isAfter(moment(_firstLog.createdAt))) {
          _firstLog = log;
        }
      });

      return _firstLog && _firstLog.dealer && _firstLog.dealer.name &&
      _firstLog.companyStatus === FinancialCompanyStatusCode.WaitingForReview || _firstLog && _firstLog.dealer
      && _firstLog.dealer.name && _firstLog.companyStatus === FinancialCompanyStatusCode.StopJoining
      ? _firstLog.dealer.name : _firstLog && _firstLog.system_user && _firstLog.system_user.name ?
      _firstLog.system_user.name : '--';
    }

    return '--';
  }
  public FormatterDealerByStatus(model, status) {
    if (model && model.ip_campaign_company_status_logs.length) {
      let _firstLog: any;
      model.ip_campaign_company_status_logs.map((log) => {
        if (log.companyStatus === status) {
          _firstLog = log;
        }
      });

      return _firstLog && _firstLog.dealer && _firstLog.dealer.name &&
      _firstLog.companyStatus === FinancialCompanyStatusCode.WaitingForReview || _firstLog && _firstLog.dealer
      && _firstLog.dealer.name && _firstLog.companyStatus === FinancialCompanyStatusCode.StopJoining
      ? _firstLog.dealer.name : _firstLog && _firstLog.system_user && _firstLog.system_user.name ?
      _firstLog.system_user.name : '--';
    }

    return '--';
  }
  public formatterFinancialCompanyStatus(status) {
    if (status) {
      switch (status) {
      case FinancialCompanyStatusCode.WaitingForReview:
        return FinancialCompanyStatus.WaitingForReview;
      case FinancialCompanyStatusCode.Approved:
        return FinancialCompanyStatus.Approved;
      case FinancialCompanyStatusCode.Rejected:
        return FinancialCompanyStatus.Rejected;
      case FinancialCompanyStatusCode.HaveCode:
        return FinancialCompanyStatus.HaveCode;
      case FinancialCompanyStatusCode.RequestStopJoining:
        return FinancialCompanyStatus.RequestStopJoining;
      case FinancialCompanyStatusCode.StopJoining:
        return FinancialCompanyStatus.StopJoining;
      default:
        return '--';
      }
    }

    return '--';
  }
  public formatterFinancialCompanyStatusCode(status) {
    if (status) {
      switch (status) {
      case FinancialCompanyStatusCode.WaitingForReview:
        return FinancialCompanyStatusCode.WaitingForReview;
      case FinancialCompanyStatusCode.Approved:
        return FinancialCompanyStatusCode.Approved;
      case FinancialCompanyStatusCode.Rejected:
        return FinancialCompanyStatusCode.Rejected;
      case FinancialCompanyStatusCode.HaveCode:
        return FinancialCompanyStatusCode.HaveCode;
      case FinancialCompanyStatusCode.RequestStopJoining:
        return FinancialCompanyStatusCode.RequestStopJoining;
      case FinancialCompanyStatusCode.StopJoining:
        return FinancialCompanyStatusCode.StopJoining;
      default:
        return '--';
      }
    }

    return '--';
  }
  public formatterLabelStatus(model) {
    if (model && model.companyStatus) {
      switch (model.companyStatus) {
      case FinancialCompanyStatusCode.WaitingForReview:
        return FinancialCompanyColor.WaitingForReview;
      case FinancialCompanyStatusCode.Approved:
        return FinancialCompanyColor.Approved;
      case FinancialCompanyStatusCode.Rejected:
        return FinancialCompanyColor.Rejected;
      case FinancialCompanyStatusCode.HaveCode:
        return FinancialCompanyColor.HaveCode;
      case FinancialCompanyStatusCode.RequestStopJoining:
        return FinancialCompanyColor.RequestStopJoining;
      case FinancialCompanyStatusCode.StopJoining:
        return FinancialCompanyColor.StopJoining;
      default:
        return '--';
      }
    }

    return '';
  }
  public onClickApprove(action, model) {
    if (!this.checkHasSalesmanInfo) {
      const message = 'Please enter Salesman name, Salesman phone before you do the action   ';
      this.$store.commit(MutationType.OpenTopAlert, {
        message,
        type: TypeAlert.Warning,
      });

      return;
    }
    if (action === 'approved') {
      this.campaignCompanyId = model.id;
      this.visibleApprove = true;
    }
    if (action === 'rejected') {
      this.campaignCompanyId = model.id;
      this.visibleReject = true;
    }

    return;
  }
  public onClickCloseCode(model) {
    this.visibleCloseCode = true;
    this.campaignCompany = model;
  }
  public onClickEditCode(model) {
    this.visibleEditCode = true;
    this.campaignCompany = model;
  }
  public openDialog() {
    // tslint:disable-next-line: early-exit
    if (this.campaignId) {
      this.fetchData();
    }
  }
  public fetchData() {
    this.$store.dispatch(ActionTypeZeroCampaign.GetZeroCampaignById, {
      id: this.campaignId,
      onSuccess: (campaign) => {
        // tslint:disable-next-line: early-exit
        if (campaign && campaign.length) {
          this.campaign = campaign[0];
          this.companyHD = campaign[0].ip_campaign_companies.find((company) =>
            company.financial_company.alias === 'HD');
          this.companyFE = campaign[0].ip_campaign_companies.find((company) =>
            company.financial_company.alias === 'FE');
          this.companyACS = campaign[0].ip_campaign_companies.find((company) =>
            company.financial_company.alias === 'ACS');
          const {salesmanName, salesmanPhone} = this.campaign;
          this.salesmanInfo = { salesmanName, salesmanPhone };
        }
      }
    });
  }
  public onClickAddSalesmanInfo() {
    this.visibleUpdateInfo = true;
  }
  public onClickUpdateCompany() {
    const campaign = this.$store.getters.getZeroCampaignDetail(this.campaignId);
    this.listFinancialCompany = [];
    if (campaign && campaign.ip_campaign_companies.length) {
      campaign.ip_campaign_companies.map((company) => {
        this.listFinancialCompany.push(company.financial_company.alias);
      });
    }
    this.visibleUpdateCompany = true;
  }
  public onUpdateOwnerInfo() {
    this.ownerName = this.campaign.shop.managerName;
    this.ownerPhone = this.campaign.shop.managerPhone;
    this.visibleOwnerInfo = true;
  }
  public refetchData() {
    this.fetchData();
  }
  public submitUpdateCode() {
    this.isErrorPOSCode = false;
    if (!this.posCodeValue) {
      return this.isErrorPOSCode = true;
    }
    this.loadingSubmit = true;
    this.$store.dispatch(ActionTypeZeroCampaign.UpdatePOSCode, {
      id: this.campaignCompany.id,
      posCode: this.posCodeValue,
      onSuccess: () => {
        const message = 'Update POS Code successfully';
        this.$store.commit(MutationType.OpenTopAlert, {
          message,
          type: TypeAlert.Success,
        });
        this.loadingSubmit = false;
        this.closeDialogEditCode();
      }
    });
  }
  public submitUpdate() {
    this.isErrorOwnerName = false;
    this.isErrorOwnerPhone = false;
    if (!this.ownerName) {
      this.isErrorOwnerName = true;

      return;
    }
    if (!this.ownerPhone) {
      this.isErrorOwnerPhone = true;

      return;
    }
    this.loadingSubmit = true;
    this.$store.dispatch(ActionTypeZeroCampaign.UpdateOwnerNameInfo, {
      shopId: this.campaign.shop.id,
      set: {
        managerName: this.ownerName,
        managerPhone: this.ownerPhone,
      },
      onSuccess: () => {
        const message = 'Update owner information successfully';
        this.$store.commit(MutationType.OpenTopAlert, {
          message,
          type: TypeAlert.Success,
        });
        this.isErrorOwnerName = false;
        this.isErrorOwnerPhone = false;
        this.loadingSubmit = false;
        this.closeDialogOwner();
      }
    });
  }
  public openDialogEditCode() {
    this.posCodeValue = this.campaignCompany && this.campaignCompany.posCode ? this.campaignCompany.posCode : '';
  }
  public closeDialogEditCode() {
    this.fetchData();
    this.campaignCompany = {};
    this.visibleEditCode = false;
    this.isErrorPOSCode = false;
  }
  public closeDialogUpdateFinancialCompany() {
    this.visibleUpdateCompany = false;
    this.fetchData();
  }
  public openDialogOwner() {
    // no handle
  }
  public closeDialogOwner() {
    this.visibleOwnerInfo = false;
    this.fetchData();
  }
  public closeDialog() {
    this.$emit('closeDialog');
  }
  public closeDialogUpdateInfo() {
    this.visibleUpdateInfo = false;
    this.fetchData();
  }
  public closeDialogApprove() {
    this.visibleApprove = false;
    this.campaignCompanyId = '';
    setTimeout(() => {
      this.fetchData();
    }, 500);
  }
  public closeDialogReject() {
    this.visibleReject = false;
    this.campaignCompanyId = '';
    setTimeout(() => {
      this.fetchData();
    }, 500);
  }
  public closeDialogCloseCode() {
    this.visibleCloseCode = false;
    this.campaignCompany = {};
    this.fetchData();
  }
}
