import { crudMutations, CRUDState, ICRUDState } from 'root/store/helpers';
import { MutationTypeImport } from './types';

export interface IManageImportState extends ICRUDState<any> {
  loading: boolean;
  listImportSubDealer: any;
}

export const defaultState: IManageImportState = {
  ...CRUDState(),
  loading: false,
  listImportSubDealer: []
};

// export const mutations = {
//   ...crudMutations('import_dealers'),
//   [MutationTypeImport.GetListImport](state: IManageImportState, data: any) {
//     state.data = data;
//   }
// };

export const mutations = {
  ...crudMutations('import_dealers'),
  [MutationTypeImport.SetImportSubDealerList](state: IManageImportState, data) {
    state.listImportSubDealer = data.import_sub_dealers;
    state.pagination.total = data.import_sub_dealers_aggregate.aggregate.count;
  },
};
