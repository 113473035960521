export enum ActionTypeProduct {
  GET_LIST_PRODUCT = 'getListProduct',
  FilterNoCache = 'productFilterNoCache',
  UpdateStatusProduct = 'updateStatusProduct',
  UpdateScanProductMode = 'updateScanProductMode',
  getScanProductMode = 'getScanProductMode',
  GetListProductRegistation = 'getListProductRegistation',
  GetListProductWarranty = 'getListProductWarranty',
  GetDealerProductDetail = 'getDealerProductDetail',
  GetProductDetail= 'getProductDetail',
  GetDeviceType = 'GetDeviceType',
  GetListProductRegistationSubDealer = 'getListProductRegistationSubDealer',
  GetStatistic = 'productRegistrationGetStatistic'
}
export enum MutationTypeProduct {
  FilterChange = 'productFilterChange',
  PaginationChange = 'productPaginationChange',
  ResetState = 'productResetState',
  ProductRegistationLoading = 'productLoading',
  ProductRegistationLoaded = 'productLoaded',
  SetProductRegistation = 'setProductRegistation',
  SetProductWarranty = 'setProductWarranty',
  SetDealerProductDetail = 'setDealerProductDetail',
  SetDealerProductWarranty = 'setDealerProductWarranty',
  SetDeviceType = 'SetDeviceType'
}
