import { DocumentNode } from 'graphql';
import gql from 'graphql-tag';
import { crudQuery, ICRUDQuery } from '../Core/query';

const redemptionModel = `
createdAt
createdBy
content
createdUser {
  id
  name
}
description
endDate
id
image
point
startDate
target
status
title
updatedAt
quantity
maxQuantity
usedQuantity
rewardStatus
contentEng
titleEng
redeemType
rewardType
voucherCodes {
  code
  id
  voucherCodeStatus
  rewardId
  hashedCode
}
voucherCodes_aggregate {
  aggregate {
    count
  }
}
updatedUser {
  id
  name
}
`;
const redeemDetail = `
data {
  branchName
  dealerCode
  dealerId
  dealerName
  frequency
  groupName
  point
  totalPoint
}
pagination {
  nextPageToken
  page
  size
  total
  totalPages
  totalPerPage
}
`;
const voucherModel = `
  id
  code
  dealerRewards {
    createdAt
    dealer {
      id
      name
    }
  }
  hashedCode
  reward {
    rewardStatus
  }
  voucherCodeStatus
`;

export const redemption = 'rewards';
// tslint:disable-next-line:no-empty-interface
interface IRedemptionQuery extends ICRUDQuery {
  CREATE_REDEMPTION: DocumentNode;
  UPDATE_REDEMPTION: DocumentNode;
  GET_REDEEM_DETAIL: DocumentNode;
  SEARCH_VOUCHER: DocumentNode;
  UPDATE_VOUCHER: DocumentNode;
  GET_LIST_VOUCHER: DocumentNode;
  ADD_VOUCHER_CODE: DocumentNode;
}
export const redemptionQuery: IRedemptionQuery = {
  ...crudQuery(redemption, redemptionModel),
  CREATE_REDEMPTION: gql`
    mutation CREATE_REDEMPTION($form: [rewards_insert_input!]!){
      insert_rewards(objects: $form){
        affected_rows
        returning {
          id
        }
      }
    }
  `,
  UPDATE_REDEMPTION: gql `
    mutation UPDATE_REDEMPTION($id: uuid!, $set: rewards_set_input){
      update_rewards(where: {id: {_eq: $id}}, _set: $set){
        affected_rows
      }
    }
  `,
  GET_REDEEM_DETAIL: gql `
  query GET_REDEEM_DETAIL($form: FilterRewardDetail ) {
    getRedeemDetail(form: $form){
      ${redeemDetail}
    }
  }
  `,
  ADD_VOUCHER_CODE: gql`
  mutation ADD_VOUCHER_CODE($form: FormAddVoucherCode){
    addVoucherCodes(form: $form) {
      codes
      failedCodes
    }
  }
  `,
  SEARCH_VOUCHER: gql`
  query SEARCH_VOUCHER($code: String) {
    voucher_codes(where: {hashedCode:{_eq: $code}}){
      ${voucherModel}
    }
  }
  `,
  UPDATE_VOUCHER: gql`
  mutation UPDATE_VOUCHER($code: [String!], $id: uuid!){
    update_voucher_codes(where: { hashedCode: { _in: $code }}, _set: {rewardId: $id}){
      affected_rows
    }
  }
  `,
  GET_LIST_VOUCHER: gql`
  query GET_LIST_VOUCHER($where: voucher_codes_bool_exp){
    voucher_codes(limit: 100,where: $where,order_by: {voucherCodeStatus: desc}) {
      ${voucherModel}
    }
  }
  `
};
