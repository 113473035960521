export enum ActionTypeUser {
  UserCreate = 'UserCreate',
  GetListCustomers = 'GetListCustomers',
  DeleteCustomer = 'deleteCustomer',
  BlockUser = 'BlockUser',
  GetUserById = 'userFindById',
  UpdateUser = 'UpdateUser',
  CreateSystemUser = 'CreateSystemUser',
  UpdateSystemUser = 'userUpdate',
  CreateTechnician = 'CreateTechnician',
  UpdateTechnician = 'UpdateTechnician',
  GetAllTechnician = 'GetAllTechnician',
  CreateManyTechnician = 'CreateManyTechnician',
  GetManySystemUser = 'getManySystemUser',
  ResetPassword = 'resetUserPassword',
  GetProfile = 'getProfile',
  FilterNoCache = 'userFilterNoCache',
  ChangePassSystemUser = 'changePassSystemUser',
  UpdateStatusSystemUser = 'updateStatusSystemUser',
  GetTeamStaff = 'getTeamStaff',
  GetTeamStaffDealer = 'getTeamStaffDealer',
  UpdateUserManager = 'updateUserManager',
  GetInfoByPhone = 'getInfoByPhone'
}
export enum MutationTypeUser {
  FilterChange = 'userFilterChange',
  ChangeOrderBy = 'userChangeOrderBy',
  PaginationChange = 'userPaginationChange',
  GetTeamStaff = 'getTeamStaff',
  GetTeamStaffDealer = 'getTeamStaffDealer',
  ResetState = 'userResetState',
}
export enum SystemRoleAlias {
  Admin = 'admin',
  SaleAdmin = 'sale_admin',
  BranchManager = 'branch_manager',
  BranchPIC = 'branch_pic',
  GroupLeader = 'group_leader',
  SaleDepartmentPic = 'sale_department_pic',
  CallCenter = 'call_center',
  SubLeader = 'sub_dealer',
  DaikinDealer = 'daikin_dealer',
  Freelancer = 'freelancer',
  RegionDirector = 'region_director',
  TeamLeader = 'team_leader',
  Staff = 'staff',
  SaleStaff = 'sale_staff',
  Operator = 'operator',
  SystemAdmin = 'moderator'
}
