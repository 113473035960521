import { formatCurrency, FormatDateFullTime } from 'root/helpers';
import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  template: require('./view.html'),
  props: {
    visible: Boolean,
    detailId: String
  },
})

export class SaleInRewardDetail extends Vue {
  public detail: any = {};
  public formatDate = FormatDateFullTime;
  public formatCurrency = formatCurrency;
  public closeDialog() {
    this.$emit('update:visible', false);
  }
  public openDialog() {
    this.detail = this.$store.getters.getSaleInRewardDetail(this.$props.detailId);
  }
}
