import { DocumentNode } from 'graphql';
import gql from 'graphql-tag';
import { crudQuery, ICRUDQuery } from '../Core/query';

const dealerModel = `
data {
  address {
    address
    district
    province
    ward
  }

  shortName
  shopCount
  dealerCode
  publicCode
  ownerName
  phoneNumber
  avatar
  branch {
    id
    name
  }
  email
  group {
    name
    id
  }
  proshopType {
    name
    alias
  }
  businessType {
    name
    alias
  }
  potentialType {
    name
    alias
  }
  taxNumber
  id
  name
  status
}
pagination {
  nextPageToken
  page
  size
  total
  totalPages
  totalPerPage
}
`;
const dealerDetailModel = `
address {
  address
  district
  province
  ward
}
createdAt
avatar
branch {
  id
  name
}
email
group {
  name
  id
}
id
name
role {
  name
}
proshopType {
  name
  alias
}
businessType {
  name
  alias
}
potentialType {
  name
  alias
}
taxNumber
status
updatedAt
dealerCode
ownerName
phoneNumber
publicCode
shortName
updatedBy
activeSalemans
shopCount
createdBy
createdUser {
  id
  name
}
updatedUser {
  id
  name
}
`;
const dealerUpgradeRequest = `
action
createdAt
dealerId
reason
requestTime
status
timeToUpdate
upgradeStatus
userReason
updatedAt
role {
  id
  name
}
`;

export const Dealers = 'dealers';
// tslint:disable-next-line:no-empty-interface
interface IDealerQuery extends ICRUDQuery {
  GET_DEALER_LIST: DocumentNode;
  GET_SALEMAN_LIST: DocumentNode;
  GET_TARGET_DETAIL: DocumentNode;
  UPDATE_DEALER_STATUS: DocumentNode;
  CHANGE_PASS_DEALER: DocumentNode;
  CREATE_DEALER: DocumentNode;
  GET_BY_ID: DocumentNode;
  UPDATE_DEALER: DocumentNode;
  UPDATE_SUB_DEALER: DocumentNode;
  GET_UPGRADE_REQUEST: DocumentNode;
  CREATE_UPGRADE_REQUEST: DocumentNode;
  INSERT_FILE_DEALER: DocumentNode;
  GET_NEWS_TARGET_DETAIL: DocumentNode;
  CHECK_EXIST_EMAIL: DocumentNode;
  CHECK_EXIST_PHONENUMBER: DocumentNode;
  GET_SUBDEALER_LIST: DocumentNode;
  GET_SUBDEALER_LIST_REGION: DocumentNode;
  GET_SUBDEALER_LIST_BRANCH: DocumentNode;
  GET_SUBDEALER_LIST_ALL: DocumentNode;
  INSERT_FILE_SUBDEALER: DocumentNode;
  EXPORT_DEALER: DocumentNode;
  DOWNLOAD_EXPORT: DocumentNode;
  GET_ROLE_ID: DocumentNode;
  GET_BANK_INFO: DocumentNode;
  GET_DEALER_TYPES: DocumentNode;
}
export const dealerListQuery: IDealerQuery = {
  ...crudQuery(Dealers, dealerDetailModel),
  GET_DEALER_LIST: gql`
  query GET_DEALER_LIST($form: FilterDealerInput!) {
    getDealerList(form: $form) {
      ${dealerModel}
    }
  }
`,
  GET_SALEMAN_LIST: gql`
    query GET_SALEMAN_LIST($form: FilterDealerInput!) {
      getSaleManList(form: $form) {
        ${dealerModel}
      }
    }
  `,
  GET_TARGET_DETAIL: gql`
  query MyQuery($role: [String!], $status: [String!]) {
    dealers(where: {role: {alias: {_in: $role}}, status: {_in: $status}}) {
      id
      email
      name
      dealerCode
      phoneNumber
      role {
        alias
      }
    }
  }
  `,
  UPDATE_DEALER_STATUS: gql`
    mutation UPDATE_DEALER_STATUS($id: uuid!, $status: String!) {
      update_dealers(where: { id: { _eq: $id } }, _set: { status: $status }) {
        affected_rows
      }
    }
  `,
  CHANGE_PASS_DEALER: gql`
    mutation CHANGE_PASS_DEALER($id: String!, $pass: String!) {
      updateDealerPassword(form: {id: $id, password: $pass}) {
        id
      }
    }
  `,
  CREATE_DEALER: gql`
    mutation CREATE_DEALER($form: FormCreateDealer!) {
      createDealer(form: $form) {
        id
        name
      }
    }
  `,
  GET_BY_ID: gql`
  query GET_BY_ID($id: String!){
    getDealerDetail(form: {id: $id}){
      ${dealerDetailModel}
    }
  }
`,
  UPDATE_DEALER: gql`
    mutation UPDATE_DEALER($id: uuid!, $set: dealers_set_input) {
      update_dealers(where: { id: { _eq: $id } }, _set: $set) {
        affected_rows
      }
    }
  `,
  UPDATE_SUB_DEALER: gql`
    mutation UPDATE_SUB_DEALER($id: uuid!, $set: dealers_set_input, $houseHoldLicense: String!,
      $businessLicense: String!) {
      update_dealers(where: { id: { _eq: $id } }, _set: $set) {
        affected_rows
      }
      update_dealer_informations(where: {dealerId: {_eq: $id}}, _set: {businessRegistrationNo: $businessLicense,
        businessHouseholdNo: $houseHoldLicense}) {
        affected_rows
      }
    }
  `,
  GET_UPGRADE_REQUEST: gql `
    query GET_UPGRADE_REQUEST($id: uuid!){
      dealer_upgrading_requests(where: {dealerId: {_eq: $id}}) {
        ${dealerUpgradeRequest}
      }
    }
  `,
  CREATE_UPGRADE_REQUEST: gql`
    mutation CREATE_UPGRADE_REQUEST($form: FormUpgradeAccount!){
      upgradeDealerAccount(form: $form)
    }
  `,
  INSERT_FILE_DEALER: gql `
  mutation INSERT_FILE_DEALER($objects: [import_dealer_with_shops_insert_input!]!){
    insert_import_dealer_with_shops(objects: $objects) {
      affected_rows
    }
  }
`,
  GET_NEWS_TARGET_DETAIL: gql`
  query GET_NEWS_TARGET_DETAIL{
    dealers(where: {status: {_neq: "deleted"}}) {
      id
      email
      dealerCode
      phoneNumber
      role {
        alias
      }
    }
  }
`,
  CHECK_EXIST_EMAIL: gql`
  query CHECK_EXIST_EMAIL($email: String!) {
    dealers(where: {email: {_eq: $email}}) {
      id
    }
  }
  `,
  CHECK_EXIST_PHONENUMBER: gql`
  query CHECK_EXIST_PHONENUMBER($phoneNumber: String!) {
    dealers(where: {phoneNumber: {_eq: $phoneNumber}}) {
      id
    }
  }
  `,
  GET_SUBDEALER_LIST: gql`
  query GET_SUBDEALER_LIST($limit: Int, $offset: Int, $searchName: String, $searchCode: String, $searchBusiness: String,
    $searchHouseHold: String,
    $searchIdentity: String, $searchBranchName: String, $searchBranchCode: String, $searchPhoneNumber: String,
    $branch: [uuid!], $region: [uuid!], $status:[String!]) {
    dealers(where: {status: {_neq: "deleted"}, role: {alias: {_eq: "sub_dealer"}},_and:[
      {status:{_in: $status}}, {branchId: {_in: $branch}}, {region: {id: {_in: $region}}}
      ,{_or:[{name:{_ilike:$searchName}}, {dealerCode:{_ilike: $searchCode}},
      {information:{businessRegistrationNo:{_ilike: $searchBusiness}}},
      {information:{businessHouseholdNo:{_ilike: $searchHouseHold}}},
      {idCard:{_ilike: $searchIdentity}}, {branch:{name:{_ilike:$searchBranchName}}},
      {branch:{branchCode:{_ilike:$searchBranchCode}}}, {phoneNumber : {_ilike:$searchPhoneNumber}}]}]},
      offset: $offset, limit: $limit, order_by: {updatedAt: desc}) {
      name
      id
      dealerCode
      idCard
      idCardImage
      address
      phoneNumber
      branchId
      ownerName
      branch {
        id
        name
        branchCode
      }
      region {
        id
        name
      }
      information {
        businessHouseholdNo
        businessHouseholdPicURLs
        businessRegistrationNo
        businessRegistrationPicURLs
        productReceiptPicURLs
      }
      assignedSubDealers(where: {status: {_eq: "active"}}) {
        dealer {
          name
          dealerCode
        }
      }
      bankBranch
      bankName
      accountName
      accountNumber
      createdAt
      createdUser{
        name
      }
      updatedAt
      updatedUser{
        name
      }
      status
    }
    dealers_aggregate(where: {status: {_neq: "deleted"}, role: {alias: {_eq: "sub_dealer"}},_and:[
      {status:{_in: $status}}, {branchId: {_in: $branch}}, {region: {id: {_in: $region}}}
      ,{_or:[{name:{_ilike:$searchName}}, {dealerCode:{_ilike: $searchCode}},
      {information:{businessRegistrationNo:{_ilike: $searchBusiness}}},
      {information:{businessHouseholdNo:{_ilike: $searchHouseHold}}},
      {idCard:{_ilike: $searchIdentity}}, {branch:{name:{_ilike:$searchBranchName}}},
      {branch:{branchCode:{_ilike:$searchBranchCode}}}, {phoneNumber : {_ilike:$searchPhoneNumber}}]}]}) {
      aggregate {
        count
      }
    }
  }`,
  GET_SUBDEALER_LIST_REGION: gql`
  query GET_SUBDEALER_LIST_REGION($limit: Int, $offset: Int, $searchName: String, $searchCode: String,
    $searchBusiness: String, $searchHouseHold: String, $searchIdentity: String, $searchBranchName: String,
    $searchPhoneNumber: String,
    $searchBranchCode: String, $region: [uuid!], $status:[String!]) {
    dealers(where: {status: {_neq: "deleted"}, role: {alias: {_eq: "sub_dealer"}},_and:[
      {status:{_in: $status}}, {region: {id: {_in: $region}}}
      ,{_or:[{name:{_ilike:$searchName}}, {dealerCode:{_ilike: $searchCode}},
      {information:{businessRegistrationNo:{_ilike: $searchBusiness}}},
      {information:{businessHouseholdNo:{_ilike: $searchHouseHold}}},
      {idCard:{_ilike: $searchIdentity}}, {branch:{name:{_ilike:$searchBranchName}}},
      {branch:{branchCode:{_ilike:$searchBranchCode}}}, {phoneNumber: {_ilike: $searchPhoneNumber}}]}]},
      offset: $offset, limit: $limit, order_by: {updatedAt: desc}) {
      name
      id
      dealerCode
      idCard
      idCardImage
      address
      phoneNumber
      branchId
      ownerName
      branch {
        id
        name
        branchCode
      }
      region {
        id
        name
      }
      information {
        businessHouseholdNo
        businessHouseholdPicURLs
        businessRegistrationNo
        businessRegistrationPicURLs
        productReceiptPicURLs
      }
      assignedSubDealers(where: {status: {_eq: "active"}}) {
        dealer {
          name
          dealerCode
        }
      }
      bankBranch
      bankName
      accountName
      accountNumber
      createdAt
      createdUser{
        name
      }
      updatedAt
      updatedUser{
        name
      }
      status
    }
    dealers_aggregate(where: {status: {_neq: "deleted"}, role: {alias: {_eq: "sub_dealer"}},_and:[
      {status:{_in: $status}}, {region: {id: {_in: $region}}}
      ,{_or:[{name:{_ilike:$searchName}}, {dealerCode:{_ilike: $searchCode}},
      {information:{businessRegistrationNo:{_ilike: $searchBusiness}}},
      {information:{businessHouseholdNo:{_ilike: $searchHouseHold}}},
      {idCard:{_ilike: $searchIdentity}}, {branch:{name:{_ilike:$searchBranchName}}},
      {branch:{branchCode:{_ilike:$searchBranchCode}}}, {phoneNumber: {_ilike: $searchPhoneNumber}}]}]}) {
      aggregate {
        count
      }
    }
  }`,
  GET_SUBDEALER_LIST_BRANCH: gql`
  query GET_SUBDEALER_LIST_BRANCH($limit: Int, $offset: Int, $searchName: String, $searchCode: String,
    $searchBusiness: String, $searchPhoneNumber: String,
    $searchHouseHold: String, $searchIdentity: String, $searchBranchName: String, $searchBranchCode: String,
    $branch: [uuid!], $status:[String!]) {
    dealers(where: {status: {_neq: "deleted"}, role: {alias: {_eq: "sub_dealer"}},_and:[
      {status:{_in: $status}}, {branchId: {_in: $branch}},
      ,{_or:[{name:{_ilike:$searchName}}, {dealerCode:{_ilike: $searchCode}},
      {information:{businessRegistrationNo:{_ilike: $searchBusiness}}},
      {information:{businessHouseholdNo:{_ilike: $searchHouseHold}}},
      {idCard:{_ilike: $searchIdentity}}, {branch:{name:{_ilike:$searchBranchName}}},
      {branch:{branchCode:{_ilike:$searchBranchCode}}}, {phoneNumber: {_ilike: $searchPhoneNumber}}]}]},
      offset: $offset, limit: $limit, order_by: {updatedAt: desc}) {
      name
      id
      dealerCode
      idCard
      idCardImage
      address
      phoneNumber
      branchId
      ownerName
      branch {
        id
        name
        branchCode
      }
      region {
        id
        name
      }
      information {
        businessHouseholdNo
        businessHouseholdPicURLs
        businessRegistrationNo
        businessRegistrationPicURLs
        productReceiptPicURLs
      }
      assignedSubDealers(where: {status: {_eq: "active"}}) {
        dealer {
          name
          dealerCode
        }
      }
      bankBranch
      bankName
      accountName
      accountNumber
      createdAt
      createdUser{
        name
      }
      updatedAt
      updatedUser{
        name
      }
      status
    }
    dealers_aggregate(where: {status: {_neq: "deleted"}, role: {alias: {_eq: "sub_dealer"}},_and:[
      {status:{_in: $status}}, {branchId: {_in: $branch}},
      ,{_or:[{name:{_ilike:$searchName}}, {dealerCode:{_ilike: $searchCode}},
      {information:{businessRegistrationNo:{_ilike: $searchBusiness}}},
      {information:{businessHouseholdNo:{_ilike: $searchHouseHold}}},
      {idCard:{_ilike: $searchIdentity}}, {branch:{name:{_ilike:$searchBranchName}}},
      {branch:{branchCode:{_ilike:$searchBranchCode}}}, {phoneNumber: {_ilike: $searchPhoneNumber}}]}]}) {
      aggregate {
        count
      }
    }
  }`,
  GET_SUBDEALER_LIST_ALL: gql`
  query GET_SUBDEALER_LIST_ALL($limit: Int, $offset: Int, $searchName: String, $searchCode: String,
    $searchBusiness: String, $searchHouseHold: String, $searchIdentity: String,
    $searchBranchName: String, $searchBranchCode: String, $searchPhoneNumber: String, $status:[String!]) {
    dealers(where: {status: {_neq: "deleted"}, role: {alias: {_eq: "sub_dealer"}},_and:[
      {status:{_in: $status}} ,{_or:[{name:{_ilike:$searchName}}, {dealerCode:{_ilike: $searchCode}},
      {information:{businessRegistrationNo:{_ilike: $searchBusiness}}},
      {information:{businessHouseholdNo:{_ilike: $searchHouseHold}}}, {phoneNumber: {_ilike: $searchPhoneNumber}}
      {idCard:{_ilike: $searchIdentity}}, {branch:{name:{_ilike:$searchBranchName}}},
      {branch:{branchCode:{_ilike:$searchBranchCode}}}]}]},
      offset: $offset, limit: $limit, order_by: {updatedAt: desc}) {
      name
      id
      dealerCode
      idCard
      idCardImage
      address
      phoneNumber
      branchId
      ownerName
      branch {
        id
        name
        branchCode
      }
      region {
        id
        name
      }
      information {
        businessHouseholdNo
        businessHouseholdPicURLs
        businessRegistrationNo
        businessRegistrationPicURLs
        productReceiptPicURLs
        requestedRewardReceiptURLs
      }
      assignedSubDealers(where: {status: {_eq: "active"}}) {
        dealer {
          name
          dealerCode
        }
      }
      bankBranch
      bankName
      accountName
      accountNumber
      createdAt
      createdUser{
        name
      }
      updatedAt
      updatedUser{
        name
      }
      status
    }
    dealers_aggregate(where: {status: {_neq: "deleted"}, role: {alias: {_eq: "sub_dealer"}},_and:[
      {status:{_in: $status}} ,{_or:[{name:{_ilike:$searchName}}, {dealerCode:{_ilike: $searchCode}},
      {information:{businessRegistrationNo:{_ilike: $searchBusiness}}},
      {information:{businessHouseholdNo:{_ilike: $searchHouseHold}}}, {phoneNumber: {_ilike: $searchPhoneNumber}}
      {idCard:{_ilike: $searchIdentity}}, {branch:{name:{_ilike:$searchBranchName}}},
      {branch:{branchCode:{_ilike:$searchBranchCode}}}]}]}) {
      aggregate {
        count
      }
    }
  }`,
  INSERT_FILE_SUBDEALER: gql `
  mutation INSERT_FILE_SUBDEALER($objects: [import_sub_dealers_insert_input!]!){
    insert_import_sub_dealers(objects: $objects) {
      affected_rows
    }
  }
`,
  EXPORT_DEALER: gql`
  mutation EXPORT_DEALER($object: [export_dealers_insert_input!]!) {
    insert_export_dealers(objects: $object) {
      returning {
        id
      }
    }
  }
  `,
  DOWNLOAD_EXPORT: gql`
  subscription DOWNLOAD_EXPORT($id: uuid!) {
    export_dealers(where: {id: {_eq: $id}}){
      id
      url
      exportStatus
    }
  }
  `,
  GET_ROLE_ID: gql`
  query GET_ROLE_ID($role: String!) {
    system_roles(where: {alias: {_eq: $role}}){
      id
    }
  }`,
  GET_BANK_INFO: gql`
  query GET_BANK_INFO {
    banks {
      id
      name
      code
    }
  }`,
  GET_DEALER_TYPES: gql`
  query GET_DEALER_TYPES {
    dealer_types {
      type
      alias
      name
    }
  }`
};
