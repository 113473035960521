import { gqlClient } from '../Core';
import { CRUDGqlService, ICRUDGqlService } from '../Core/crud';
import { transactionView, transactionViewQuery } from '../Query/transactionView';

export interface ITransactionViewGqlService extends ICRUDGqlService<any> {
  getGroupView(): Promise<any>;
  getViewById(id: string): Promise<any>;
}
export function transactionViewGqlService(): ITransactionViewGqlService {
  async function getGroupView(): Promise<any> {
    const results = await gqlClient.query({
      query: transactionViewQuery.GET_GROUP_VIEW,
      fetchPolicy: 'network-only'
    });
    if (results.errors) {
      throw results.errors;
    }

    return results.data.product_transaction_leader_view;
  }
  async function getViewById(id: string): Promise<any> {
    const result = await gqlClient.query({
      query: transactionViewQuery.GET_VIEW_BY_ID,
      variables: {
        id,
      },
      fetchPolicy: 'network-only'
    });
    if (result.errors) {
      throw result.errors;
    }

    return result.data.product_history_leader_view;
  }

  return {
    ...CRUDGqlService<any>(transactionView
    , transactionViewQuery),
    getGroupView,
    getViewById
  };
}
