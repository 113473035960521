export enum ActionTypeTransactionView {
  GET_LIST_PRODUCT_TRANS = 'getListTransactionView',
  FilterNoCache = 'transactionViewFilterNoCache',
  GetByProductId = 'transactionViewGetByProductId',
  GetGroupView = 'transactionViewGetGroupView',
  GetViewById = 'transactionViewGetViewById'
}
export enum MutationTypeTransactionView {
  FilterChange = 'transactionViewFilterChange',
  PaginationChange = 'transactionViewPaginationChange'
}
