import { crudMutations, CRUDState, ICRUDState } from 'root/store/helpers';
import { MutationTypeEvaluatePhoto } from './types';

export interface IEvaluatePhotoState extends ICRUDState<any> {
  loading: boolean;
  customCampagin: any;
  bank_dealers: ICRUDState<any>;
}

export const defaultState: IEvaluatePhotoState = {
  ...CRUDState(),
  loading: false,
  customCampagin: [],
  bank_dealers: CRUDState(),
};

export const mutations = {
  ...crudMutations('evaluatePhoto'),
  ...crudMutations('bank_dealers', 'id', {keyState: 'bank_dealers'}),
  [MutationTypeEvaluatePhoto.SetDataCustomCompaign](state: IEvaluatePhotoState, data) {
    state.customCampagin = data;
  }
};
