import { gqlClient } from '../Core';
import { CRUDGqlService, ICRUDGqlService } from '../Core/crud';
import { dealerShop, dealerShopListQuery } from '../Query/dealerShop';

export interface IDealerShopGqlService extends ICRUDGqlService<any> {
  //
  getListDealerShop(id: string, search: string, limit: number, offset: number): Promise<any>;
  createShop(form: any, id: string, createdBy: string): Promise<any>;
  getShopDetail(id: string): Promise<any>;
  updateShop(form: any, id: string, updateBy: string): Promise<any>;
}
export function dealerShopGqlService(): IDealerShopGqlService {
  async function getListDealerShop(id: string, search: string, limit: number, offset: number): Promise<any> {
    const results = await gqlClient.query({
      query: dealerShopListQuery.GET_DEALER_SHOP_LIST,
      variables: {
        id,
        search,
        limit,
        offset
      },
      fetchPolicy: 'network-only'
    });
    if (results.errors) {
      throw results.errors;
    }

    return results.data;
  }
  async function createShop(form: any, id: string, createdBy: string): Promise<any> {
    const results = await gqlClient.mutate({
      mutation: dealerShopListQuery.CREATE_SHOP,
      variables: {
        createdBy,
        dealerId: id,
        name: form.name,
        shopCode: form.shopCode,
        email: form.email,
        address: form.address,
      }
    });
    if (results.errors) {
      throw results.errors;
    }

    return results;
  }
  async function getShopDetail(id: string): Promise<any> {
    const results = await gqlClient.query({
      query: dealerShopListQuery.GET_SHOP_DETAIL,
      variables: {
        id
      },
      fetchPolicy: 'network-only'
    });
    if (results.errors) {
      throw results.errors;
    }

    return results.data.shops[0];
  }
  async function updateShop(form: any, id: string, updatedBy: string): Promise<any> {
    const results = await gqlClient.mutate({
      mutation: dealerShopListQuery.UPDATE_SHOP,
      variables: {
        updatedBy,
        shopId: id,
        name: form.name,
        address: form.address
      }
    });
    if (results.errors) {
      throw results.errors;
    }

    return results;
  }

  return {
    ...CRUDGqlService<any>(dealerShop, dealerShopListQuery),
    getListDealerShop,
    createShop,
    getShopDetail,
    updateShop
  };
}
