export enum pathUpload {
  CUSTOMER = 'customer/',
  SYSTEM_USER = 'system_users/',
  TECHNICIAN = 'technician/',
  BOOKING = 'booking/',
  SERVICES = 'services/',
  DEVICES = 'devices/',
  BANNERS='template/newpage/',
  UPLOADS = 'uploads/',
  NEWS = 'news/',
  ACCESSORY = 'accessory/',
  INVOICE = 'invoice/',
  REDEMPTION = 'redemption/',
  CAMPAIGN = 'campaign/',
  DEALER = 'dealer/',
  SUBDEALER = 'sub_dealer/',
  PDFEXPORT ='/export_acl_campaign_dealer/',
  POSCODE = '/imports',
  BANKING_PAYMENT = '/server/imports/banking-payment'
}

export const sizeImage = {
  avatar: {
    thumbnail: {
      maxWidth: 128,
      maxHeight: 128,
      quality: 0.5
    },
    original: {
      maxWidth: 1024,
      maxHeight: 1024
    }
  },
  message: {
    thumbnail: {
      maxWidth: 320,
      maxHeight: 240
    },
    original: {
      maxWidth: 1920,
      maxHeight: 1920
    }
  },
  projects: {
    thumbnail: {
      maxHeight: 80,
      maxWidth: 80
    },
    original: {
      maxHeight: 1024,
      maxWidth: 1024
    }
  },
  icon: {
    maxWidth: 128,
    maxHeight: 128,
    quality: 1,
    fileType: 'image/png',
  }
};
