import { stripObject } from '@hgiasac/helper';
import { cloneDeep } from 'lodash';
import { SystemRoleAlias } from 'root/admin/User/Store/types';
import { IPaginationParams } from 'root/api/graphql/Core';
import { ListData } from 'root/components';
import { SearchByFilter } from 'root/components/SearchByFilter';
import { FormatDateFullTime } from 'root/helpers';
import { IAddress, IFilterInput, IUser } from 'root/models';
import { IState } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import { FinancialCompanyColor, FinancialCompanyStatus, FinancialCompanyStatusCode } from '../../RequestReviewList';
import { ActionTypeZeroCampaignRequestReview,
  MutationTypeZeroCampaignRequestReview } from '../../Store/types';
import './styles.scss';

const enum FilterKey {
  Type = 'type',
  Status = 'status',
}
enum campaignSearchBy {
  DealerCode = 'dealerCode',
  Dealer = 'dealer',
  DealerShop = 'dealerShop',
  ShopCode = 'shopCode',
  PosCode = 'posCode'
}

@Component({
  template: require('./view.html'),
  computed: {
    ...mapState({
      authUser: (state: IState) => state.global.authUser,
      data: (state: IState) => state.zeroCampaign.ip_campaign_companies.data,
      loading: (state: IState) => state.zeroCampaign.ip_campaign_companies.loading,
      pagination: (state: IState) => state.zeroCampaign.ip_campaign_companies.pagination,
    }),
    campaignSearchBy() {
      const result: IFilterInput[] = [];
      for (const key in campaignSearchBy) {
        // tslint:disable-next-line:early-exit
        if (campaignSearchBy.hasOwnProperty(key)) {
          const element = campaignSearchBy[key];
          result.push({
            key,
            value: element,
            name: this.$t(element)
          });
        }
      }

      return [{
        key: 'select',
        name: this.$t('select'),
        value: result
      }];
    },
    selectedFieldSearchBy() {
      const filterParams = cloneDeep(this.modelSearchBy);

      return [{
        key: 'select',
        value: filterParams ? filterParams : []
      }];
    },
  },
  props: {
    popupVisible: Boolean,
    selected: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  components: {
    'search-by-filter': SearchByFilter,
  }
})
export class ApproveAllRequestForm extends Vue {
  public get disableSubmit() {
    return this.selectedItem && this.selectedItem.length;
  }
  public $refs: {
    listRequest: ListData
  };
  public loading: boolean;
  public authUser: IUser;
  public loadingConfirm: boolean = false;
  public selectedItem: any[] = [];
  public visibleConfirm: boolean = false;
  public requestName: string = '';
  public comment: string = '';
  public qualifiedValue: string = '';
  public isErrorName: boolean = false;
  public isErrorQualify: boolean = false;
  public searchText: string = '';
  public modelSearchBy: any[] = [];
  public isErrorComment: boolean = false;
  public pagination: IPaginationParams;
  public FormatDateFullTime = FormatDateFullTime;
  public user: any[] = [];
  public filterParams = {
    [FilterKey.Status]: [],
    [FilterKey.Type]: [],
  };
  public get qualifyOption() {
    return [
      {
        key: 'qualified',
        label: 'Qualified',
        value: 'qualified',
      },
      {
        key: 'non_qualified',
        label: 'Non Qualified',
        value: 'non_qualified',
      }
    ];
  }
  public get dataFilter() {
    const _status: IFilterInput[] = [
      {
        key: FinancialCompanyStatusCode.Approved,
        value: FinancialCompanyStatusCode.Approved,
        name: FinancialCompanyStatus.Approved
      },
      {
        key: FinancialCompanyStatusCode.Rejected,
        value: FinancialCompanyStatusCode.Rejected,
        name: FinancialCompanyStatus.Rejected
      },
    ];
    const company: IFilterInput[] = [
      {
        key: 'open_code',
        value: 'open_code',
        name: 'Open Code'
      },
      {
        key: 'closed_code',
        value: 'closed_code',
        name: 'Close Code'
      },
    ];

    return [
      {
        key: FilterKey.Type,
        name: 'Type',
        value: company
      },
      {
        key: FilterKey.Status,
        name: 'Latest Status',
        value: _status
      },
    ];
  }
  public get selectedFilter() {
    const _filterParams = cloneDeep(this.filterParams);

    return [
      {
        key: FilterKey.Status,
        value: _filterParams[FilterKey.Status]
      },
      {
        key: FilterKey.Type,
        value: _filterParams[FilterKey.Type]
      }
    ];
  }
  public get columns() {
    return [
      {
        prop: 'shop',
        label: 'Dealer Shop',
        labelItem: 'Shop Code',
        width: 270,
      },
      {
        prop: 'dealer',
        label: 'Dealer Name',
        labelItem: 'Dealer Code',
        width: 270,
      },
      {
        prop: 'lastStatus',
        label: 'Last Stauts',
        labelItem: 'Update Time',
        width: 200,
      },
      {
        prop: 'company',
        label: 'Financial company',
        formatter: (model) => {
          return model && model.financial_company && model.financial_company.name ?
          model.financial_company.name : '--';
        },
        width: 200,
      },
      {
        prop: 'type',
        label: 'Type',
        width: 120,
      },
      {
        prop: 'updated',
        label: 'Updated By',
        labelItem: 'Name',
        width: 200,
      },
    ];
  }
  public displayAddress(model: IAddress) {
    return model && model.province
      ? this.$store.getters['getAddressString'](model)
      : '';
  }
  public changeFilter(model) {
    const status = model.find((e) => e.key === FilterKey.Status);
    const type = model.find((e) => e.key === FilterKey.Type);
    const nextFilter = {
      [FilterKey.Status]: status ? status.value : [],
      [FilterKey.Type]: type ? type.value : [],
    };
    this.filterParams = {
      ...cloneDeep(this.filterParams),
      ...nextFilter
    };
    this.fetchData();
  }
  public closeDialog() {
    this.back();
  }
  public back() {
    this.selectedItem = [];
    this.filterParams = {
      [FilterKey.Type]: [],
      [FilterKey.Status]: [],
    };
    // this.$refs.listRequest.refreshData();
    this.$emit('update:popupVisible', false);
  }
  public changeSearchBy(model: IFilterInput[]) {
    this.modelSearchBy = model[0].value;

    this.fetchData();
  }
  public openDialog() {
  //  
    this.fetchData();
    this.selectedItem = [...this.$props.selected];
  }
  public formatterUpdateByRole(model) {
    if (model && model.updatedBy && model.updatedBy.role && model.updatedBy.role.name) {

      return model.updatedBy.role.name;
    }

    return '--';
  }
  public formatterUpdateByName(model) {
    if (model && model.updatedBy && model.updatedBy.name) {

      return model.updatedBy.name;
    }

    return '--';
  }
  public formatterFinancialCompanyName(model) {
    if (model && model.lastReviewStatus) {
      switch (model.lastReviewStatus) {
      case FinancialCompanyStatusCode.WaitingForReview:
        return FinancialCompanyStatus.WaitingForReview;
      case FinancialCompanyStatusCode.Approved:
        return FinancialCompanyStatus.Approved;
      case FinancialCompanyStatusCode.Rejected:
        return FinancialCompanyStatus.Rejected;
      case FinancialCompanyStatusCode.HaveCode:
        return FinancialCompanyStatus.HaveCode;
      case FinancialCompanyStatusCode.RequestStopJoining:
        return FinancialCompanyStatus.RequestStopJoining;
      case FinancialCompanyStatusCode.StopJoining:
        return FinancialCompanyStatus.StopJoining;
      default:
        return '--';
      }
    }

    return '--';
  }
  public handlePaginationChange(value) {
    if (typeof value === 'number') {
      this.$store.commit(MutationTypeZeroCampaignRequestReview.PaginationChange, {
        ...this.pagination,
        size: value
      });
    } else {
      this.$store.commit(MutationTypeZeroCampaignRequestReview.PaginationChange, {
        ...this.pagination,
        ...value
      });
    }
    this.fetchData();
  }
  public onEnterFilter(value) {
    this.searchText = value;

    this.fetchData();
  }
  public fetchData() {
    const text = cloneDeep(this.searchText);
    // const _filterParams = <any> {};
    const _searchModel: any[] = [];
    const {type, status} = this.filterParams;
    if (this.modelSearchBy.length && this.modelSearchBy.indexOf('dealer') >= 0) {
      _searchModel.push({
        dealer: {
          name :  {
            _ilike: `%${text}%`
          }
        }
      });
    }
    if (this.modelSearchBy.length && this.modelSearchBy.indexOf('dealerCode') >= 0) {
      _searchModel.push({
        dealer: {
          dealerCode :  {
            _ilike: `%${text}%`
          }
        }
      });
    }
    if (this.modelSearchBy.length && this.modelSearchBy.indexOf('dealerShop') >= 0) {
      _searchModel.push({
        shop: {
          name :  {
            _ilike: `%${text}%`
          }
        }
      });
    }
    if (this.modelSearchBy.length && this.modelSearchBy.indexOf('shopCode') >= 0) {
      _searchModel.push({
        shop: {
          shopCode :  {
            _ilike: `%${text}%`
          }
        }
      });
    }
    if (this.modelSearchBy.length && this.modelSearchBy.indexOf('posCode') >= 0) {
      _searchModel.push({
        ip_campaign_companies: {
          posCode :  {
            _ilike: `%${text}%`
          }
        }
      });
    }
    this.$store.commit(MutationTypeZeroCampaignRequestReview.FilterChange, stripObject({
      _and:
      {
        mode: 'special',
        data: [
          {
            ...this.authUser.role.alias !== SystemRoleAlias.SaleStaff ? {
              lastReviewer: {
                role: {
                  alias: {
                    _eq: this.authUser.role.alias === SystemRoleAlias.SaleDepartmentPic ?
                    SystemRoleAlias.SaleStaff : this.authUser.role.alias === SystemRoleAlias.GroupLeader ?
                    SystemRoleAlias.SaleDepartmentPic : null
                  }
                }
              }
            } : {}
          },
          {
            ...this.authUser.role.alias === SystemRoleAlias.SaleStaff ? {
              companyStatus: {
                _in: [
                  'waiting_for_review',
                  'request_stop_joining'
                ]
              },
              requesterStopId: {
                _is_null: true
              },
              _or: [
                {
                  lastReviewerId: {
                    _is_null: true
                  }
                },
                {
                  lastReviewer: {
                    role: {
                      alias: {
                        _nin: [
                          'sale_staff',
                          'sale_department_pic'
                        ]
                      }
                    }
                  }
                }
              ],
            } : {}
          }
        ].filter((e) => !!e)
      },
      ...type && type.length ? {
        type
      } : {},
      ...status && status.length ? {
        lastReviewStatus: status
      } : {}
    }
    ));
    this.$store.dispatch(ActionTypeZeroCampaignRequestReview.FilterNoCache, {});
  }
  public apply() {
    this.$msgbox({
      title: 'Confirm',
      message: `Are you sure you want to approve ${this.selectedItem.length} requests?`,
      showCancelButton: true,
      confirmButtonText: 'Confirm',
      cancelButtonText: 'Cancel',
      beforeClose: (action, instance, done) => {
        if (action === 'confirm') {
          instance.confirmButtonLoading = true;
          instance.confirmButtonText = 'Loading...';
          this.handleApprove({ instance, done });
        } else {
          done();
        }

        return;
      }
    })
        .then(() => {
          this.$message({
            type: 'success',
            message: this.$t('message.export_success').toString()
          });
        })
        .catch(() => {
          // no handle
        });
  }
  public handleApprove(model) {
    const ids = this.selectedItem.map((it) => it.id);
    this.$store.dispatch(ActionTypeZeroCampaignRequestReview.ApproveAll, {
      ids,
      set: {
        updatedBy: this.authUser.id,
        lastReviewerId: this.authUser.id,
        lastReviewStatus: 'approved',
      },
      onSuccess: () => {
        this.fetchData();
        model.instance.confirmButtonLoading = false;
        model.instance.confirmButtonText = 'Confirm';
        model.done();
        this.$emit('fetchData');
        this.closeDialog();
      },
      onFailure: () => {
        model.instance.confirmButtonLoading = false;
        model.instance.confirmButtonText = 'Retry';
      }
    });
  }
  public createRequest() {
// 
  }
  public formatterLabelStatus(model: any) {
    if (model && model.lastReviewStatus) {
      switch (model.lastReviewStatus) {
      case FinancialCompanyStatusCode.WaitingForReview:
        return FinancialCompanyColor.WaitingForReview;
      case FinancialCompanyStatusCode.Approved:
        return FinancialCompanyColor.Approved;
      case FinancialCompanyStatusCode.Rejected:
        return FinancialCompanyColor.Rejected;
      case FinancialCompanyStatusCode.HaveCode:
        return FinancialCompanyColor.HaveCode;
      case FinancialCompanyStatusCode.RequestStopJoining:
        return FinancialCompanyColor.RequestStopJoining;
      case FinancialCompanyStatusCode.StopJoining:
        return FinancialCompanyColor.StopJoining;
      default:
        return '--';
      }
    }

    return '--';
  }
  public formatterFinacialCompanyUpdateAt(model) {
    if (model && model.lastReview && model.lastReview.createdAt) {
      return this.FormatDateFullTime(model.lastReview.createdAt);
    }

    return '--';
  }
  public changeSelect(value) {
    this.selectedItem = value;
  }
  public closeDialogConfirm() {
    this.visibleConfirm = false;
    this.requestName = '';
    this.qualifiedValue = '';
    this.comment = '';
  }
  public removeItem(value) {
    console.log(value, this.selectedItem);
  }
  public mounted() {
// 
  }
}
