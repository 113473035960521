import { DocumentNode } from 'graphql';
import gql from 'graphql-tag';
import { crudQuery, ICRUDQuery } from '../Core/query';

const campaignModel = `
data {
  dealers {
    id
    name
    branch {
      id
      name
    }
  }
  campaignStatus
  action
  description
  duration
  id
  point
  title
  titleEng
  descriptionEng
  targetBranch
  icon
  metadata
  dealerRoles
  isSpecial
  status
  endDate
  createdAt
  updatedAt
  createdUser {
    id
    name
  }
  updatedUser {
    id
    name
  }
  startDate
  image
  condition {
    code
    description
    id
    name
    nameEng
  }
}
pagination {
  nextPageToken
  page
  size
  total
  totalPages
  totalPerPage
}`;

export const campaign = 'campaign_dealers';
// tslint:disable-next-line:no-empty-interface
interface ICampaignQuery extends ICRUDQuery {
  CREATE_CAMPAIGN: DocumentNode;
  UPDATE_CAMPAIGN: DocumentNode;
  UPDATE_STATUS: DocumentNode;
  GET_LIST_CAMPAGIN: DocumentNode;
  GET_CAMPAIGN_DETAIL: DocumentNode;
  GET_CAMPAIGN_LIST: DocumentNode;
  ADD_BRANCH_CAMPAIGN: DocumentNode;
  REMOVE_BRANCH_CAMPAIGN: DocumentNode;
}
export const campaignQuery: ICampaignQuery = {
  ...crudQuery(campaign, campaignModel),
  CREATE_CAMPAIGN: gql`
   mutation($form: [campaigns_insert_input!]!){
    insert_campaigns (objects: $form) {
      affected_rows
      returning {
        id
        campaignStatus
        description
        title
        titleEng
        descriptionEng
        status
        endDate
        createdAt
        updatedAt
        startDate
        image
      }
    }
  }
  `,
  UPDATE_CAMPAIGN: gql`
    mutation UPDATE_CAMPAIGN($form: FormUpdateCampaign!, $id: String) {
      updateCampaign(form: $form, id: $id) {
        branchId
        campaignId
        dealerId
        groupId
      }
    }
  `,
  UPDATE_STATUS: gql`
    mutation UPDATE_STATUS($form: campaigns_set_input!, $id: uuid!) {
      update_campaigns(where: { id: { _eq: $id } }, _set: $form) {
        affected_rows
      }
    }
  `,
  GET_LIST_CAMPAGIN: gql`
  query GET_LIST_CAMPAGIN($form: FilterCampaignInput ) {
    getCampaignList(form: $form){
      ${campaignModel}
    }
  }
  `,
  GET_CAMPAIGN_DETAIL: gql`
    query GET_CAMPAIGN_DETAIL($form: FilterCampaignDetail) {
      getCampaignDetail(form: $form) {
        data {
          branchName
          frequency
          participant
          totalPoint
          condition {
            code
            description
            id
            name
            nameEng
          }
        }
        pagination {
          nextPageToken
          page
          size
          total
          totalPages
          totalPerPage
        }
      }
    }
  `,
  GET_CAMPAIGN_LIST: gql`
  query GET_CAMPAIGN_LIST($limit: Int, $offset: Int, $filter: campaigns_bool_exp) {
    campaigns(limit: $limit, offset: $offset, where: $filter, order_by: {updatedAt: desc}) {
      id
      title
      startDate
      endDate
      dealerRoles
      isSpecial
      metadata
      targetBranch
      description
      createdAt
      updatedAt
      createdUser {
        name
      }
      updatedUser {
        name
      }
      targetBranches(where: {status: {_neq: "deleted"}}) {
        id
        branch {
          id
          name
        }
      }
      icon image
    }
    campaigns_aggregate(where: $filter) {
      aggregate {
        count
      }
    }
  }`,
  ADD_BRANCH_CAMPAIGN: gql`
    mutation ADD_BRANCH_CAMPAIGN($arr: [campaign_target_branches_insert_input!]!){
      insert_campaign_target_branches(objects: $arr) {
        affected_rows
      }
    }
  `,
  REMOVE_BRANCH_CAMPAIGN: gql`
  mutation REMOVE_BRANCH_CAMPAIGN ($campaignId: uuid!, $remove: [uuid!]) {
    update_campaign_target_branches(where: {campaignId: {_eq: $campaignId},
    branchId:{_in:$remove}},
    _set: {status: "deleted"}){
      affected_rows
    }
  }
  `
};
