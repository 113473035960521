import { stripObject } from '@hgiasac/helper';
import { DocumentNode } from 'graphql';
import gql from 'graphql-tag';

export interface ICRUDQuery {
  FILTER?: DocumentNode;
  GET_BY_ID?: DocumentNode;
  CREATE?: DocumentNode;
  UPDATE?: DocumentNode;
  DELETE?: DocumentNode;
  FETCH_MANY?: DocumentNode;
}

export function crudQuery(
  prefix: string,
  model: string,
  customFilterArgumentType?: string
): ICRUDQuery {
  return stripObject({
    FILTER: gql`
      query FILTER_${prefix}($limit: Int!, $offset: Int!,
        $filter: ${customFilterArgumentType || prefix}_bool_exp,
        $orderBy: [${customFilterArgumentType || prefix}_order_by!] = {}) {
        ${prefix}(limit: $limit, offset: $offset, where: $filter, order_by: $orderBy) {
          ${model}
        }
        ${prefix}_aggregate(where: $filter) {
          aggregate {
            count
          }
        }
    }`,
    FETCH_MANY: gql`
      query FETCH_MANY_${prefix} {
        ${prefix}(where: {status: {_neq: "deleted"}}, order_by: {createdAt: desc}) {
          ${model}
        }
    }`,
    UPDATE: gql`
      mutation UPDATE_${prefix}($id: uuid!, $form: ${prefix}_set_input){
        update_${prefix}(where: {id: {_eq: $id}}, _set: $form) {
          returning {
            ${model}
          }
        }
      }`,
    DELETE: gql`
      mutation DELETE_${prefix}($id: uuid!){
        update_${prefix}(where: {id: {_eq: $id}}, _set: {status: "deleted"}) {
          returning {
            ${model}
          }
        }
      }`,
    CREATE: gql`
      mutation INSERT_${prefix}($form: [${prefix}_insert_input!]!) {
        insert_${prefix}(objects: $form) {
          returning {
            ${model}
          }
        }
      }`,
    GET_BY_ID: gql`
      query ${prefix}_GET_BY_ID($id: uuid!) {
        ${prefix}(where: {id: {_eq: $id}, status: {_neq: "deleted"}}) {
          ${model}
        }
      }
    `
  });
}
