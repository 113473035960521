export enum ConditionActionType {
  ConditionFilterNoCache = 'conditionsFilterNoCache',
  ConditionDelete = 'conditionsDelete',
  ConditionUpdate = 'conditionsUpdate',
  ConditionCreate = 'conditionsCreate',
  UpdatePriority = 'conditionsUpdatePriority'
}

export enum ConditionMutationType {
  ConditionLoading = 'conditionsLoading',
  ConditionLoaded = 'conditionsLoaded',
  PaginationChange = 'conditionsPaginationChange',
  FilterChange = 'conditionsFilterChange',
  UpdateModels = 'conditionsUpdateModels'
}
