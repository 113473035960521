import { i18n } from 'root/locales';
import { addressDefault, Gender, IAddress, StatusCode } from './Common';
import { IRole } from './Role';
import { IStation } from './Station';

export interface IUserRegisterForm {
  email: string;
  phoneNumber: string;
  dob: Date;
  gender: Gender;
  // address: IAddress;
  password?: string;
  name: string;
  roleId: string;
  role?: any;
  groupId?: string;
  groupIds?: any;
  branchIds?: string[];
  memberIds?: string[];
  staffIds?: string[];
  avatar: string;
  branchId?: string;
  branch?: any;
  // status?: StatusCode;
  superAdmin?: boolean;
  // partner?: IInfoPartner;
  // permission?: string;
  // technicianRate?: any;
  // technicianCode?: string;
  // stationId?: string;
  // partnerId?: string;
  // idCard?: IIdCard;
  // bankAccount?: IBankAccount;
}

export enum UserRole {
  Admin = 'admin',
  Customer = 'customer',
  Staff = 'staff',
  Technician = 'technician',
  Partner = 'partner',
  Empty = ''
}
export enum UserType {
  Customer = 'customers',
  Technician = 'technicians',
  System = 'system_users'
}

export const userRegisterFormDefault = (): IUserRegisterForm => {
  return {
    avatar: '',
    email: '',
    roleId: null,
    groupId: null,
    dob: null,
    gender: null,
    phoneNumber: '',
    password: '',
    name: '',
    groupIds: null,
    staffIds: null,
    // address: {
    //   district: null,
    //   province: null,
    //   address: '',
    //   ward: null
    // },
    // partner: {
    //   name: ''
    // },
    // status: StatusCode.Active,
    // permission: '',
    // technicianRate: {
    //   rateName: '',
    //   id: '',
    //   yearOfExperience: ''
    // },
    // idCard: {
    //   idNumber: '',
    //   issueDate: '',
    //   issuePlace: ''
    // },
    // bankAccount: {
    //   bankCode: '',
    //   bankName: '',
    //   accountName: '',
    //   accountNumber: ''
    // }
  };
};

export const userDefault = (): IUser => {
  return {
    ...userRegisterFormDefault(),
    id: null
  };
};

export interface IUser extends IUserRegisterForm {
  id?: string;
  status?: StatusCode;
  role?: IRole;
  group?: any;
  firebaseId?: string;
  partnerId?: string;
  partner?: any;
  stationId?: string;
  station?: IStation;
  technicianCode?: string;
  supervisorId?: string;
  authenticatedTime?: string;
  systemGroup?: any;
  branch?: any;
}

export interface ISimpleUser {
  id: string;
  name: string;
  avatar?: string;
  address?: IAddress;
  phoneNumber?: string;
  gender?: Gender;
  code?: string;
  email?: string;
  daikinCode?: string;
}

export interface IUserParamsFilter {
  gender?: Gender;
  role?: UserRole;
  status?: string;
  order?: string;
  text?: string;
}

export interface ILoginForm {
  email: string;
  password: string;
}

export enum CustomerStatus {
  Active = 'active',
  Inactive = 'inactive',
  Blocked = 'blocked',
  Deleted = 'deleted'
}

export interface ITechnicianCreateForm {
  avatar: string;
  name: string;
  phoneNumber: string;
  email: string;
  password: string;
  dob: Date;
  gender: string;
  status: StatusCode;
  address: IAddress;
  // numberExperienceYear: number;
  TrainingCourse?: string;
  partner?: ISimpleUser;
  partnerId?: string;
  // note: string;
  firebaseId?: string;
}

export const technicianForm = (): ITechnicianCreateForm => {
  return {
    address: addressDefault(),
    avatar: '',
    dob: null,
    gender: null,
    name: '',
    // note: '',
    phoneNumber: null,
    status: StatusCode.Active,
    // numberExperienceYear: null,
    email: '',
    password: ''
  };
};

export enum GroupSystemUserType {
  Admin = 'admin',
  Staff = 'staff',
  // Partner = 'partner'
  Station = 'station'
}
export const groupSystemUserSelection = () => {
  const result = [];
  // tslint:disable-next-line:forin
  for (const key in GroupSystemUserType) {
    result.push({
      key: GroupSystemUserType[key],
      value: i18n().t(GroupSystemUserType[key])
    });
  }

  return result;
};
export enum StationRole {
  System = 'system',
  Sale = 'sale'
}
export const stationRoleOptions = () => {
  const result = [];
  // tslint:disable-next-line:forin
  for (const key in StationRole) {
    result.push({
      key: StationRole[key],
      value: StationRole[key]
    });
  }

  return result;
};

export interface IUpdateTechnicianPasswordForm {
  password: string;
}

/**
 * certificates
 */
export interface ICertificatesForm {
  date: string | Date;
  expiry: string | Date;
  name: string;
  status: StatusCode;
  description: string;
  categoryId: string;
  photos: string[];
  technicianId: string;
}
export interface ICertificates extends ICertificatesForm {
  id: string;
  createdAt: Date;
  createdBy: IUser;
  updatedAt?: Date;
  updatedBy?: ISimpleUser;
  technician?: ISimpleUser;
  certificateCategory?: ICertificateCategory;
}
export const certificatesFormDefault = (): ICertificatesForm => {
  return {
    date: null,
    description: null,
    expiry: null,
    name: '',
    categoryId: '',
    photos: [],
    technicianId: '',
    status: StatusCode.Active
  };
};
export const certificatesDefault = (): ICertificates => {
  return {
    ...certificatesFormDefault(),
    createdAt: null,
    createdBy: userDefault(),
    id: ''
  };
};
/**
 * certificate category
 */
export interface ICertificateCategoryForm {
  description: string;
  name: string;
  createBy?: string;
  updatedBy?: string;
}
export function certificateCategoryFormDefault(): ICertificateCategoryForm {
  return {
    description: '',
    name: ''
  };
}
export interface ICertificateCategory extends ICertificateCategoryForm {
  id: string;
  createdAt: string;
  status: StatusCode;
  updatedAt?: string;
  createdUser?: ISimpleUser;
  updatedUser?: ISimpleUser;
}

/**
 * traning course
 */
export interface ITrainingCourseForm {
  date: string | Date;
  name: string;
  status: StatusCode;
  description: string;
}
export interface ITrainingCourse extends ITrainingCourseForm {
  id: string;
  createdAt: Date;
  createdBy: IUser;
  updatedAt?: Date;
  updatedBy?: ISimpleUser;
}
export const TrainingCourseFormDefault = (): ITrainingCourseForm => {
  return {
    date: null,
    description: null,
    name: '',
    status: StatusCode.Active
  };
};

export interface IIdCard {
  idNumber?: string;
  issueDate?: string;
  issuePlace?: string;
}

export interface IBankAccount {
  accountNumber?: string;
  accountName?: string;
  bankCode?: string;
  bankName?: string;
}
export enum ELocalStorage {
  Token = 'token',
  RefreshToken = 'refreshToken',
  Version = 'version',
}
export enum EGender {
  Male = 'Male',
  Female = 'Female'
}
export enum ESystemGroup {
  Sale = 'Sale',
  System = 'System',
  Dealer = 'Dealer',
  Freelancer = 'Free lancer',
  SubDealer = 'Sub Dealer',
  Guest = 'Guest',
  DaikinDealer = 'Daikin Dealer',
  Technician = 'Technician'
}
