import { IBannerState } from '.';

export const getters = {
  getBannersSorted(state: IBannerState) {
    return state.data.sort((a, b) => {
      if (a.orderNumber === b.orderNumber) {
        return 0;
      }
      if (a.orderNumber === null) {
        return -1;
      }
      if (b.orderNumber === null) {
        return 1;
      }

      return a.orderNumber > b.orderNumber ? 1 : -1;
    });
  }
};
