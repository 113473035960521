import { DKGqlClient } from 'root/api/graphql/Client';
import { ActionType, IState } from 'root/store';
import { crudActions } from 'root/store/helpers';
import { ActionContext } from 'vuex';
import { IAccountStaffState } from './mutation';
import { ActionTypeAccountStaff, MutationTypeAccountStaff } from './types';

const { accountStaff } = DKGqlClient();
export const actions = {
  ...crudActions('accountStaff', {
  }),
  async [ActionTypeAccountStaff.GET_STAFF_LIST]({
    commit, dispatch
  }: ActionContext<IAccountStaffState, IState>,
                                                {id, search, status, pagination}) {
    try {
      commit(MutationTypeAccountStaff.AccountStaffLoading);
      const shops = await accountStaff.getShopId(id);
      const shopIds = shops.map((item) => item.shopId);
      dispatch(ActionTypeAccountStaff.GET_LIST_ACCOUNTSTAFF, {
        search,
        status,
        pagination,
        ids: shopIds,
      });
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    } finally {
      commit(MutationTypeAccountStaff.AccountStaffLoaded);
    }
  },
  async [ActionTypeAccountStaff.GET_LIST_ACCOUNTSTAFF]({
    commit, dispatch
  }: ActionContext<IAccountStaffState, IState>,
                                                       {ids, search, status, pagination}) {
    try {
      commit(MutationTypeAccountStaff.AccountStaffLoading);
      const { page, limit } = pagination;
      const offset = (page - 1) * limit;
      const AccountStaffList = await accountStaff.getListAccountStaff(ids, search, status, limit, offset);
      commit(MutationTypeAccountStaff.GetListAccountStaff, AccountStaffList);
      // commit(MutationTypeAccountStaff.SetPagination, pagination);
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    } finally {
      commit(MutationTypeAccountStaff.AccountStaffLoaded);
    }
  },
  async [ActionTypeAccountStaff.CREATE_STAFF]({
    commit, dispatch
  }: ActionContext<IAccountStaffState, IState>,
                                              {form, onSuccess, onFailure}) {
    try {
      commit(MutationTypeAccountStaff.AccountStaffLoading);
      await accountStaff.createStaff(form);
      // if (DealerStaff) {
      //   dispatch(ActionTypeAccountStaff.GET_STAFF_LIST, ({
      //     id
      //   }));
      // }
      if (onSuccess) {
        onSuccess();
      }
    } catch (error) {
      if (onFailure) {
        onFailure();
      }
      dispatch(ActionType.CatchException, error);
    } finally {
      commit(MutationTypeAccountStaff.AccountStaffLoaded);
    }
  },
  async [ActionTypeAccountStaff.GET_STAFF_DETAIL]({
    dispatch
  }: ActionContext<IAccountStaffState, IState>,
                                                  {id, onSuccess, onFailure}) {
    try {
      const detail = await accountStaff.getStaffDetail(id);
      if (onSuccess) {
        onSuccess(detail);
      }
    } catch (error) {
      if (onFailure) {
        onFailure();
      }
      dispatch(ActionType.CatchException, error);
    }
  }
};
