import { crudMutations, CRUDState, ICRUDState } from 'root/store/helpers';
import { MutationTypeBranchManager } from './types';

export interface IBranchManageState extends ICRUDState<any> {
  loading: boolean;
  detail: any;
  managers: [];
}

export const defaultState: IBranchManageState = {
  ...CRUDState(),
  pagination: {
    limit: 10,
    page: 1
  },
  loading: false,
  detail: {},
  managers: []
};

export const mutations = {
  ...crudMutations('branch_manager'),
  [MutationTypeBranchManager.SetBranchManager](
    state: IBranchManageState,
    data
  ) {
    state.detail = data.data;
  },
  [MutationTypeBranchManager.SetManager](state: IBranchManageState, data) {
    state.managers = data.data;
    state.pagination = {
      ...state.pagination,
      ...data.pagination,
      limit: data.pagination.size
    };
  }
};
