import { isFunction, omit } from 'lodash';
import { DKGqlClient } from 'root/api/graphql/Client';
import { ActionType, IState } from 'root/store';
import { crudActions } from 'root/store/helpers';
import { ActionContext } from 'vuex';
import { INotificationState } from '.';
import { ActionTypeNotification, MutationTypeNotification } from './type';

const {
  filter,
  deleteById,
  create,
  update,
  createNewNotification,
  updateNotification,
  updateNotificationDealers,
  readNotifications
} = DKGqlClient().notification;

export const actions = {
  ...crudActions('notifications', { filter, deleteById, create, update }),
  async [ActionTypeNotification.NotificationUpdateDealers](
    { dispatch, commit }: ActionContext<INotificationState, IState>,
    { referenceId, receiverIds, onSuccess, onFailure }
  ) {
    try {
      commit(MutationTypeNotification.NotificationLoading);
      await updateNotificationDealers(referenceId, receiverIds);
      if (isFunction(onSuccess)) {
        onSuccess();
      }
    } catch (err) {
      if (isFunction(onFailure)) {
        onFailure();
      }
      dispatch(ActionType.CatchException, err);
    } finally {
      commit(MutationTypeNotification.NotificationLoaded);
    }
  },
  async [ActionTypeNotification.NotificationUpdate](
    { dispatch, commit }: ActionContext<INotificationState, IState>,
    { form, onSuccess, onFailure }
  ) {
    try {
      commit(MutationTypeNotification.NotificationLoading);
      const res = await updateNotification(form);
      console.log('🚀 ~ file: actions.ts ~ line 47 ~ res', res);

      if (isFunction(onSuccess)) {
        onSuccess();
      }
      // console.log('🚀 ~ file: actions.ts ~ line 42 ~ form', form);
    } catch (err) {
      if (isFunction(onFailure)) {
        onFailure();
      }
      dispatch(ActionType.CatchException, err);
    } finally {
      commit(MutationTypeNotification.NotificationLoaded);
    }
  },
  async [ActionTypeNotification.NotificationCreate](
    { dispatch, commit }: ActionContext<INotificationState, IState>,
    { form, onSuccess, onFailure }
  ) {
    try {
      commit(MutationTypeNotification.NotificationLoading);
      let processedForm = omit(form, [
        'createdAt',
        'createdUser',
        'updatedAt',
        'updatedUser',
        'id',
        'notificationStatus',
        'metadata'
      ]);
      const scheduledAt = new Date(processedForm.scheduledAt)
        .toISOString()
        .replace(/T/, ' ')
        .replace(/\..+/, '');
      processedForm = {
        ...processedForm,
        scheduledAt,
        metadata: form.metadata,
        target: [processedForm.target]
      };
      await createNewNotification(processedForm);
      if (isFunction(onSuccess)) {
        onSuccess();
      }
    } catch (err) {
      if (isFunction(onFailure)) {
        onFailure();
      }
      dispatch(ActionType.CatchException, err);
    } finally {
      commit(MutationTypeNotification.NotificationLoaded);
    }
  },
  async [ActionTypeNotification.ReadNotifications](
    {dispatch}: ActionContext<INotificationState, IState>, {ids, readAt}
  ) {
    try {
      await readNotifications(ids, readAt);
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    }
  },
};
