import { TabPane } from 'element-ui';
import moment from 'moment-timezone';
import { ActionTypeProduct } from 'root/admin/Product/Store/types';
import BarChart from 'root/helpers/bar-chart';
import LineChart from 'root/helpers/line-chart';
import { IBreadcrumb, IState, MutationType } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import { ProductManageRouterName, ProductRegistationTab } from '..';
// import { ActionTypeZeroCampaign } from '../Store/types';
import './styles.scss';

@Component({
  template: require('./view.html'),
  components: {
    'bar-chart': BarChart,
    'line-chart': LineChart,
  },
  computed: {
    ...mapState({
      authUser: (state: IState) => state.global.authUser,
    })
  }
})

export class StatisticProducts extends Vue {
  public get activeTab() {
    return ProductRegistationTab.StatisticProduct;
  }

  public set activeTab(_value: string) {
    //
  }
  public get optionTimeLineBar() {
    return [
      {
        key: 'All',
        value: 'All',
        name: 'All',
      },
      {
        key: '03/2022',
        value: '03/2022',
        name: '03/2022',
      },
      {
        key: '04/2022',
        value: '04/2022',
        name: '04/2022',
      },
      {
        key: '05/2022',
        value: '05/2022',
        name: '05/2022',
      },
      {
        key: '06/2022',
        value: '06/2022',
        name: '06/2022',
      }
    ];
  }
  public get pickerOptionPeriodTimeTo() {
    return {
      disabledDate: (time) => {
        return time.getTime() >= moment();
      }
    };
  }
  public get chartBarModelData() {
    const labels = ['Room Air', 'Sky Air', 'AP', 'Multi'];

    return {
      labels,
      datasets: [
        {
          backgroundColor: ['#FFB14D', '#3DBB61', '#BB8EFF', '#35B3E9'],
          barPercentage: 100,
          barThickness: 20,
          categoryPercentage: 0.5,
          data: [0, 0, 0, 0]
        },
      ]
    };
  }
  public chartBarModelOptions: object = {
    responsive: true,
    scales: {
      xAxes: [{
        scaleLabel: {
          display: true,
          labelString: 'Model'
        }
      }],
      yAxes: [{
        scaleLabel: {
          display: true,
          labelString: 'Quantity'
        },
        ticks: {
          beginAtZero: true,
        }
      }]
    },
    legend: {
      display: false
    },
    title: {
      display: true,
      text: 'SBU',
      align: 'start',
      fontSize: 12,
      fontStyle: 'bold'
    },
    maintainAspectRatio: false,
  };
  public chartBarFailOptions: object = {
    responsive: true,
    scales: {
      xAxes: [{
        scaleLabel: {
          display: true,
          labelString: 'Reason'
        }
      }],
      yAxes: [{
        scaleLabel: {
          display: true,
          labelString: 'Quantity'
        },
        ticks: {
          beginAtZero: true,
        }
      }]
    },
    legend: {
      display: false
    },
    title: {
      display: true,
      text: 'Fail Reason',
      align: 'start',
      fontSize: 12,
      fontStyle: 'bold'
    },
    maintainAspectRatio: false,
  };
  public get chartFailReasonData() {
    const labels = this.labelBranch;

    return {
      labels,
      datasets: [
        {
          label: 'Total Reason',
          backgroundColor: ['#FCD0D3', '#FFD2BB', '#FFEDBB', '#B8EFB7', '#B8B7FF', '#B8B7FF', '#DCB9FF',
            '#FCD0D3', '#FFD2BB', '#FFEDBB', '#B8EFB7', '#B8B7FF', '#B8B7FF', '#DCB9FF'],
          barPercentage: 100,
          barThickness: 50,
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          categoryPercentage: 10,
          stack: 1
        },
      ]
    };
  }
  public get chartBarBranchData() {
    const labels = this.labelBranch;

    return {
      labels,
      datasets: [
        {
          label: 'Total',
          backgroundColor: ['#FCD0D3', '#FFD2BB', '#FFEDBB', '#B8EFB7', '#B8B7FF', '#B8B7FF', '#DCB9FF',
            '#FCD0D3', '#FFD2BB', '#FFEDBB', '#B8EFB7', '#B8B7FF', '#B8B7FF', '#DCB9FF'],
          barPercentage: 100,
          barThickness: 50,
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          categoryPercentage: 10,
          stack: 1
        },
        {
          label: 'Joined',
          backgroundColor: ['#FCD0D3', '#FFD2BB', '#FFEDBB', '#B8EFB7', '#B8B7FF', '#B8B7FF', '#DCB9FF',
            '#FCD0D3', '#FFD2BB', '#FFEDBB', '#B8EFB7', '#B8B7FF', '#B8B7FF', '#DCB9FF'],
          barPercentage: 100,
          barThickness: 50,
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          categoryPercentage: 10,
          stack: 1
        },
      ]
    };
  }
  public chartBarBranchOptions: object = {
    responsive: true,
    scales: {
      xAxes: [{
        scaleLabel: {
          display: true,
          labelString: 'Branch'
        }
      }],
      yAxes: [{
        staked: true,
        scaleLabel: {
          display: true,
          labelString: 'Quantity'
        },
        ticks: {
          beginAtZero: true,
        }
      }]
    },
    legend: {
      display: false
    },
    // tooltips: {
    //   enabled: false
    // },
    tooltips: {
      mode: 'label',
      callbacks: {
        label: (t, d) => {
          const dstLabel = d.datasets[t.datasetIndex].label;
          const yLabel = t.yLabel;
          if (t.datasetIndex === 0) {

            return `${dstLabel} :  ${yLabel + d.datasets[2].data[t.index] + d.datasets[3].data[t.index]}`;
          }
          if (t.datasetIndex === 1) {

            return `${dstLabel} :  ${d.datasets[2].data[t.index] + d.datasets[3].data[t.index]}`;
          }

          return `${dstLabel} : ${yLabel}`;
        },
      }
    },
    title: {
      display: true,
      text: 'Branch',
      align: 'start',
      fontSize: 12,
      fontStyle: 'bold'
    },
    maintainAspectRatio: false,
  };
  public get chartLineData() {

    return {
      hoverBackgroundColor: 'red',
      hoverBorderWidth: 10,
      labels: this.cloneLabelLine.length ? this.cloneLabelLine : this.label,
      datasets: [
        {
          label: 'Success',
          borderColor: '#24a148',
          pointBackgroundColor: '#24a148',
          backgroundColor: 'rgba(0, 0, 0, 0)',
          data: []
        },
        {
          label: 'Fail',
          borderColor: '#fb4b53',
          pointBackgroundColor: '#fb4b53',
          backgroundColor: 'rgba(0, 0, 0, 0)',
          data: []
        }
      ]
    };
  }
  public statistic: any = null;
  public label: any[] = [];
  public cloneLabelLine: any[] = [];
  public lineBarSelect: string = 'All';
  public labelBranch: any[] = [];
  public chartLineOptions: object = {
    legend: {
      display: true,
      align: 'end',
      labels: {
        usePointStyle: true,
        pointStyle: 'circle'
      },
    },
    scales: {
      xAxes: [{
        scaleLabel: {
          display: true,
          labelString: 'Date'
        }
      }],
      yAxes: [{
        scaleLabel: {
          display: true,
          labelString: 'Total'
        }
      }]
    },
    title: {
      display: true,
      text: 'TOTAL SUCCESS/TOTAL FAIL (DAY)',
      align: 'start',
      fontSize: 12,
      fontStyle: 'bold'
    },
    animation: {
      animateScale: true
    },
    elements: {
      line: {
        tension: 0,
      }
    },
    responsive: true,
    maintainAspectRatio: false,
  };
  public changeTimeLinBar(value) {
    switch (value) {
    case '03/2022' :
      const _clone = this.enumerateDays(moment('2022-03-01T00:00:00+00:00'), moment('2022-04-01T00:00:00+00:00'));
      this.cloneLabelLine =
      this.enumerateDaysBetweenDates(moment('2022-03-01T00:00:00+00:00'), moment('2022-04-01T00:00:00+00:00'));
      this.sortDataLine(_clone);
      break;
    case '04/2022' :
      const _clone1 = this.enumerateDays(moment('2022-04-01T00:00:00+00:00'), moment('2022-05-01T00:00:00+00:00'));
      this.cloneLabelLine =
      this.enumerateDaysBetweenDates(moment('2022-04-01T00:00:00+00:00'), moment('2022-05-01T00:00:00+00:00'));
      this.sortDataLine(_clone1);
      break;
    case '05/2022' :
      const _clone2 = this.enumerateDays(moment('2022-05-01T00:00:00+00:00'), moment('2022-06-01T00:00:00+00:00'));
      this.cloneLabelLine =
      this.enumerateDaysBetweenDates(moment('2022-05-01T00:00:00+00:00'), moment('2022-06-01T00:00:00+00:00'));
      this.sortDataLine(_clone2);
      break;
    case '06/2022' :
      const _clone3 = this.enumerateDays(moment('2022-06-01T00:00:00+00:00'), moment('2022-07-01T00:00:00+00:00'));
      this.cloneLabelLine =
      this.enumerateDaysBetweenDates(moment('2022-06-01T00:00:00+00:00'), moment('2022-07-01T00:00:00+00:00'));
      this.sortDataLine(_clone3);
      break;
    case 'All' :
      this.cloneLabelLine =
          this.enumerateDaysBetweenDates(moment(this.statistic.configCampaign[0].aclCampaignConfig.uploadStartDate),
          moment(this.statistic.configCampaign[0].aclCampaignConfig.uploadEndDate));
      const _cloneAll = this.enumerateDays(moment(this.statistic.configCampaign[0].aclCampaignConfig.uploadStartDate),
        moment(this.statistic.configCampaign[0].aclCampaignConfig.uploadEndDate));
      this.sortDataLine(_cloneAll);
      break;
    }
  }
  public sortDataLine(_clone) {
    // this.dataShopsRequest = [];
    // this.dataSubRequest = [];
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < _clone.length; i++) {
      // this.dataShopsRequest.push(0);
      // this.dataSubRequest.push(0);
    }
    _clone.map((day, _index) => {
      const _toDay = moment(day);
      const _nextDay = moment(day).add(1, 'days');
      const _cloneAllRequest = [];
      const _cloneRequestPerDay = [];
      this.statistic.requests.map((request) => {
        if (moment(request.createdAt).isAfter(moment(_toDay))
        && moment(request.createdAt).isBefore(moment(_nextDay))) {
          _cloneRequestPerDay.push(request);
        }
      });
      const _requestPerDayUnique = [..._cloneRequestPerDay];
      _cloneRequestPerDay.map((it) => {
        _requestPerDayUnique.map((req, ind) => {
          if (it.campaignId === req.campaignId && moment(it.createdAt).isAfter(moment(req.createdAt))) {
            _requestPerDayUnique.splice(ind, 1);
          }
        });
      });
      _cloneAllRequest.push(..._requestPerDayUnique);
      _cloneAllRequest.map((requestClone) => {
        if (requestClone.dealer && requestClone.dealer.role.alias === 'daikin_dealer'
          && requestClone.requestStatus === 'waiting_for_review') {
          // this.dataShopsRequest[index] += 1;
        }
        if (requestClone.dealer && requestClone.dealer.role.alias === 'sub_dealer'
          && requestClone.requestStatus === 'waiting_for_review') {
          // this.dataSubRequest[index] += 1;
        }
      });
    });
  }
  public enumerateDaysBetweenDates(startDate, endDate) {
    const dates = [];

    // startDate = startDate.add(1, 'days');

    while (startDate.isBefore(endDate)) {
      dates.push(moment(startDate).format('DD/MM/YYYY'));
      startDate = startDate.add(1, 'days');
    }

    return dates;
  }
  public enumerateDays(startDate, endDate) {
    const dates = [];

    // startDate = startDate.add(1, 'days');

    while (startDate.isBefore(endDate)) {
      dates.push(moment(startDate));
      startDate = startDate.add(1, 'days');
    }

    return dates;
  }
  public changeTab(tab: TabPane) {
    switch (tab.name) {
    case ProductRegistationTab.ProductList:
      this.$router.push({
        name: ProductManageRouterName.ProductRegistation
      });
      break;
    case ProductRegistationTab.DealerProduct:
      this.$router.push({
        name: ProductManageRouterName.DealerProduct
      });
      break;
    case ProductRegistationTab.SubDealerProduct:
      this.$router.push({
        name: ProductManageRouterName.SubDealerProduct
      });
      break;
    default:
      break;
    }
  }
  public fetchData() {
    // this.loading = true;
    this.$store.dispatch(ActionTypeProduct.GetStatistic, {
      onSuccess: (statistic) => {
        this.labelBranch = [];
        // this.modelQuantity = [
        //   this.statistic.modelFTKA.aggregate.count,
        //   this.statistic.modelFTKB1.aggregate.count + this.statistic.modelFTKB2.aggregate.count,
        //   this.statistic.modelFTKC1.aggregate.count + this.statistic.modelFTKC2.aggregate.count
        //   + this.statistic.modelFTKY1.aggregate.count + this.statistic.modelFTKY2.aggregate.count,
        //   this.statistic.totalModel.aggregate.count * 3 - this.statistic.modelFTKA.aggregate.count
        //   - this.statistic.modelFTKB1.aggregate.count - this.statistic.modelFTKB2.aggregate.count
        //   - this.statistic.modelFTKC1.aggregate.count - this.statistic.modelFTKC2.aggregate.count
        //   - this.statistic.modelFTKY1.aggregate.count - - this.statistic.modelFTKY2.aggregate.count
        // ];
        statistic.labelBranch.map((branch) => {
          this.labelBranch.push(branch.name);
        });
        // this.branchIds.map((it, index) => {
        //   this.$store.dispatch(ActionTypeRaCampaign.GetDealerByBranch, {
        //     name: it,
        //     onSuccess: (result) => {
        //       this.dataJoinedSubBranchchart.splice(index, 1, result.joinSub.aggregate.count);
        //       this.dataJoinedBranchchart.splice(index, 1, result.joinDealer.aggregate.count);
        //       this.dataTotalBranchchart.splice(index, 1, result.total.aggregate.count);
        //       this.dataNotJoinedBranchchart.splice(index, 1,
        //         result.total.aggregate.count - result.joinSub.aggregate.count - result.joinDealer.aggregate.count);
        //     }
        //   });
        // });
        // this.loading = false;
      }
    });
  }
  protected mounted() {
    this.$nextTick(() => {
      const breadcrumb: IBreadcrumb[] = [
        {
          label: 'title.productRegistation',
          path: null,
          current: false
        },
      ];
      this.$store.commit(MutationType.SetBreadcrumb, breadcrumb);
      this.fetchData();
    });
  }
}
