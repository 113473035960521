
import { cloneDeep } from 'lodash';
import moment from 'moment';
import { ActionTypeSaleEduCampaign } from 'root/admin/CustomCampaign/Store/types';
import { IPaginationParams } from 'root/api/graphql/Core';
import { formatCurrency, FormatDateFullTime } from 'root/helpers';
import { IFilterInput, IUser } from 'root/models';
import { IState, MutationType, TypeAlert } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import './styles.scss';

const enum FilterKey {
  Status = 'status'
}
@Component({
  template: require('./view.html'),
  props: {
    visible: Boolean,
    detailId: String
  },
  computed: {
    ...mapState({
      authUser: (state: IState) => state.global.authUser,
    })
  },
})
export class SalesEduCampaignDetail extends Vue {
  public get getPermissionCheckAsPaid() {
    if (this.detail && this.detail.confirmedAt && !this.detail.paidAt
      && this.detail.importStatus === 'successful') {
      return true;
    }

    return false;
  }
  public get columns() {
    return [
      {
        label: 'Trainee Name',
        prop: 'dealer',
        labelItem: 'Trainee Code',
        fixed: true,
        width: 200
      },
      {
        label: 'Amount Received',
        prop: 'amountReceived',
        formatter: (model: any) => {
          return model && model.amountReceived ?
          `${model.amountReceived}` : '0 VND';
        },
        width: 120
      },
      {
        label: 'Blocked Amount',
        prop: 'blockedAmount',
        formatter: (model: any) => {
          return model && model.blockedAmount ? `${model.blockedAmount}` : '0 VND';
        },
        width: 120
      },
      {
        label: 'Status',
        prop: 'status',
        formatter: (model: any) => {
          return model && model.status ? this.formatterStatus(model.status) : '--';
        },
        labelStyle: (model: any) => {
          return `text--capitalize import-status__${model.status}`;
        },
        width: 120
      },
      {
        label: 'Reason',
        prop: 'reason',
        width: 150
      },
    ];
  }
  public get selectedFilter() {
    const _filterParams = cloneDeep(this.filterParams);

    return [
      {
        key: FilterKey.Status,
        value: _filterParams[FilterKey.Status]
      },
    ];
  }
  public get dataFilter() {
    const _statuses: IFilterInput[] = [
      {
        key: 'success',
        value: 'success',
        name: 'Successful',
        style: 'filter__success'
      },
      {
        key: 'fail',
        value: 'failed',
        name: 'Failed',
        style: 'filter__failed'
      }
    ];

    return [
      {
        key: FilterKey.Status,
        name: this.$t('field.status'),
        value: _statuses
      }
    ];
  }
  public FormatDateFullTime = FormatDateFullTime;
  public detail: any = {};
  public data: any = [];
  public loading: boolean = false;
  public authUser: IUser;
  public formatCurrency = formatCurrency;
  public pagination: IPaginationParams = {
    limit: 10,
    page: 1,
    total: 10
  };
  public filterParams = {
    [FilterKey.Status]: []
  };

  public onClickHyperLink(url) {
    window.open(url, '_blank');
  }
  public formatterStatus(status) {
    const map = {
      success: 'Successful',
      failed: 'Failed'
    };

    return map[status];
  }
  public onClickCheckAsPaid() {
    this.$msgbox({
      title: 'Check as Paid',
      message: `Are you sure want to check as paid for this dealer?`,
      showCancelButton: true,
      confirmButtonText: this.$t('confirm').toString(),
      cancelButtonText: this.$t('cancel').toString(),
      beforeClose: (action, instance, done) => {
        if (action === 'confirm') {
          instance.confirmButtonLoading = true;
          instance.confirmButtonText = 'Loading...';
          instance.confirmButtonLoading = false;
          this.$store.dispatch(ActionTypeSaleEduCampaign.CheckAsPaid, {
            ids: this.detail.id,
            updatedBy: this.authUser.id,
            paidAt: moment(),
            onSuccess: () => {
              instance.confirmButtonLoading = false;
              const message = 'Update successfully';
              this.$store.commit(MutationType.OpenTopAlert, {
                message,
                type: TypeAlert.Success
              });
              this.closeDialog();
            }
          });
          done();
        } else {
          done();
        }

        return;
      }
    }).catch(() => {
      // no handle
    });
  }
  public handlePaginationChange(value) {
    if (typeof value === 'number') {
      this.pagination = {
        ...this.pagination,
        limit: value
      };
    } else {
      this.pagination = {
        ...value
      };
    }
    this.fetchData();
  }
  public handleChangeFilter(filter) {
    const status = filter.find((e) => e.key === FilterKey.Status);
    const nextFilter = {
      [FilterKey.Status]: status ? status.value : [],
    };
    this.filterParams = {
      ...cloneDeep(this.filterParams),
      ...nextFilter
    };
    this.pagination = {
      limit: 10,
      page: 1
    };
    this.fetchData();
  }
  public fetchData() {
    if (this.$props.detailId) {
      this.loading = true;
      this.detail = this.$store.getters.getSalesEduCampaign(this.$props.detailId);
      const {status} = this.filterParams;
      this.$store.dispatch(ActionTypeSaleEduCampaign.GetListTrainee, {
        status,
        pagination: this.pagination,
        referenceId: this.detail.referenceId,
        onSuccess: (data) => {
          this.data = data.data;
          this.pagination = {
            ...this.pagination,
            total: data.pagination.total
          };
          this.loading = false;
        }
      });
    }

    return;
  }
  public openDialog() {
    this.fetchData();
  }
  public closeDialog() {
    this.$emit('closeDialog');
  }
}
