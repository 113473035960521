import { gqlClient } from '../Core';
import { CRUDGqlService, ICRUDGqlService } from '../Core/crud';
import { dealerListQuery, Dealers } from '../Query/dealer';

export interface IDealerGqlService extends ICRUDGqlService<any> {
  //
  getListDealer(form: any): Promise<any>;
  getListSaleman(form: any): Promise<any>;
  getTargetDetail(role: string, status: any): Promise<any>;
  changePassDealer(id: string, pass: string): Promise<any>;
  updateStatusDealer(id: string, status: string): Promise<any>;
  createDealer(form: any): Promise<any>;
  dealerGetById(id: string): Promise<any>;
  updateDealer(id: string, set: any): Promise<any>;
  updateSubDealer(id: string, set: any, businessLicense: string, houseHoldLicense: string): Promise<any>;
  getUpgradeRequest(id: string): Promise<any>;
  createUpgradeRequest(form: any): Promise<any>;
  insertFileDealer(form: any): Promise<any>;
  getListSubDealer(limit: number, offset: number, searchName: string, searchCode: string,
                   searchBusiness: string, searchHouseHold: string, searchIdentity: string,
                   searchBranchName: string, searchBranchCode: string, searchPhoneNumber: string,
                   branch: any, region: any, status: any): Promise<any>;
  insertFileSubDealer(form: any): Promise<any>;
  exportDealer(object: any): Promise<any>;
  getRoleId(role: string): Promise<any>;
  getBankInfo(): Promise<any>;
  getDealerType(): Promise<any>;
}
export function dealerGqlService(): IDealerGqlService {
  async function getListDealer(form: any): Promise<any> {
    const results = await gqlClient.query({
      query: dealerListQuery.GET_DEALER_LIST,
      variables: {
        form
      },
      fetchPolicy: 'network-only'
    });
    if (results.errors) {
      throw results.errors;
    }

    return results.data.getDealerList;
  }
  async function getListSaleman(form: any): Promise<any> {
    const results = await gqlClient.query({
      query: dealerListQuery.GET_SALEMAN_LIST,
      variables: {
        form
      },
      fetchPolicy: 'network-only'
    });
    if (results.errors) {
      throw results.errors;
    }

    return results.data.getSaleManList;
  }
  async function getTargetDetail(role: string, status: any): Promise<any> {
    const results = await gqlClient.query({
      query: dealerListQuery.GET_TARGET_DETAIL,
      variables: {
        role,
        status
      },
      fetchPolicy: 'network-only'
    });
    if (results.errors) {
      throw results.errors;
    }

    return results.data.dealers;
  }
  async function dealerGetById(id: string): Promise<any> {
    const results = await gqlClient.query({
      query: dealerListQuery.GET_BY_ID,
      variables: {
        id
      },
      fetchPolicy: 'network-only'
    });
    if (results.errors) {
      throw results.errors;
    }

    return results.data.getDealerDetail;
  }
  async function changePassDealer(id: string, pass: string) {
    const result = await gqlClient.mutate({
      mutation: dealerListQuery.CHANGE_PASS_DEALER,
      variables: {
        id,
        pass
      }
    });
    if (result.errors) {
      throw result.errors;
    }
  }
  async function updateStatusDealer(id: string, status: string) {
    const result = await gqlClient.mutate({
      mutation: dealerListQuery.UPDATE_DEALER_STATUS,
      variables: {
        id,
        status
      }
    });
    if (result.errors) {
      throw result.errors;
    }
  }
  async function createDealer(form: any) {
    const result = await gqlClient.mutate({
      mutation: dealerListQuery.CREATE_DEALER,
      variables: {
        form
      }
    });
    if (result.errors) {
      throw result.errors;
    }
  }
  async function updateDealer(id: string, set: any) {
    const result = await gqlClient.mutate({
      mutation: dealerListQuery.UPDATE_DEALER,
      variables: {
        id,
        set
      }
    });
    if (result.errors) {
      throw result.errors;
    }
  }
  async function updateSubDealer(id: string, set: any, businessLicense: string, houseHoldLicense: string) {
    const result = await gqlClient.mutate({
      mutation: dealerListQuery.UPDATE_SUB_DEALER,
      variables: {
        id, set, businessLicense, houseHoldLicense
      }
    });
    if (result.errors) {
      throw result.errors;
    }
  }
  async function getUpgradeRequest(id: string) {
    const result = await gqlClient.mutate({
      mutation: dealerListQuery.GET_UPGRADE_REQUEST,
      variables: {
        id,
      }
    });
    if (result.errors) {
      throw result.errors;
    }

    return result.data.dealer_upgrading_requests;
  }
  async function createUpgradeRequest(form: any): Promise<any> {
    const results = await gqlClient.mutate({
      mutation: dealerListQuery.CREATE_UPGRADE_REQUEST,
      variables: {
        form
      },
    });
    if (results.errors) {
      throw results.errors;
    }

    return results.data.getDealerList;
  }
  async function insertFileDealer(form: any): Promise<any> {
    const results = await gqlClient.mutate({
      mutation: dealerListQuery.INSERT_FILE_DEALER,
      variables: {
        objects: form
      },
    });
    if (results.errors) {
      throw results.errors;
    }

    return results;
  }
  async function getListSubDealer(limit: number, offset: number, searchName: string, searchCode: string,
                                  searchBusiness: string, searchHouseHold: string, searchIdentity: string,
                                  searchBranchName: string, searchBranchCode: string, searchPhoneNumber: string,
                                  branch: any, region: any, status: any): Promise<any> {
    if (branch && region) {
      const result = await gqlClient.query({
        query: dealerListQuery.GET_SUBDEALER_LIST,
        variables: {
          limit , offset , searchName, searchCode, searchBusiness,
          searchHouseHold, searchIdentity, searchBranchName, searchBranchCode, searchPhoneNumber, branch, region, status
        },
        fetchPolicy: 'network-only'
      });
      if (result.errors) {
        throw result.errors;
      }

      return result.data;
    }
    if (!branch && region) {
      const resultsRegion = await gqlClient.query({
        query: dealerListQuery.GET_SUBDEALER_LIST_REGION,
        variables: {
          limit , offset , searchName, searchCode, searchBusiness,
          searchHouseHold, searchIdentity, searchBranchName, searchBranchCode, searchPhoneNumber, region, status
        },
        fetchPolicy: 'network-only'
      });
      if (resultsRegion.errors) {
        throw resultsRegion.errors;
      }

      return resultsRegion.data;
    }
    if (branch && !region) {
      const resultsBranch = await gqlClient.query({
        query: dealerListQuery.GET_SUBDEALER_LIST_BRANCH,
        variables: {
          limit , offset , searchName, searchCode, searchBusiness,
          searchHouseHold, searchIdentity, searchBranchName, searchBranchCode, searchPhoneNumber, branch, status
        },
        fetchPolicy: 'network-only'
      });
      if (resultsBranch.errors) {
        throw resultsBranch.errors;
      }

      return resultsBranch.data;
    }
    const results = await gqlClient.query({
      query: dealerListQuery.GET_SUBDEALER_LIST_ALL,
      variables: {
        limit , offset , searchName, searchCode, searchBusiness,
        searchHouseHold, searchIdentity, searchBranchName, searchBranchCode, searchPhoneNumber, status
      },
      fetchPolicy: 'network-only'
    });
    if (results.errors) {
      throw results.errors;
    }

    return results.data;

  }
  async function insertFileSubDealer(form: any): Promise<any> {
    const results = await gqlClient.mutate({
      mutation: dealerListQuery.INSERT_FILE_SUBDEALER,
      variables: {
        objects: form
      },
    });
    if (results.errors) {
      throw results.errors;
    }

    return results;
  }
  async function exportDealer(object: any): Promise<any> {
    const results = await gqlClient.mutate({
      mutation: dealerListQuery.EXPORT_DEALER,
      variables: {
        object
      }
    });
    if (results.errors) {
      throw results.errors;
    }

    return results.data.insert_export_dealers;
  }
  async function getRoleId(role: string): Promise<any> {
    const results = await gqlClient.query({
      query: dealerListQuery.GET_ROLE_ID,
      variables: {
        role
      }
    });
    if (results.errors) {
      throw results.errors;
    }

    return results.data.system_roles;
  }
  async function getBankInfo(): Promise<any> {
    const results = await gqlClient.query({
      query: dealerListQuery.GET_BANK_INFO,
    });
    if (results.errors) {
      throw results.errors;
    }

    return results.data;
  }
  async function getDealerType(): Promise<any> {
    const results = await gqlClient.query({
      query: dealerListQuery.GET_DEALER_TYPES,
    });
    if (results.errors) {
      throw results.errors;
    }

    return results.data;
  }

  return {
    ...CRUDGqlService<any>(Dealers, dealerListQuery),
    getListDealer,
    getListSaleman,
    getTargetDetail,
    changePassDealer,
    updateStatusDealer,
    createDealer,
    dealerGetById,
    updateDealer,
    updateSubDealer,
    getUpgradeRequest,
    createUpgradeRequest,
    insertFileDealer,
    getListSubDealer,
    insertFileSubDealer,
    exportDealer,
    getRoleId,
    getBankInfo,
    getDealerType
  };
}
