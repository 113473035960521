import { cloneDeep, find } from 'lodash';
import { IRaCampaignState } from '.';

export const getters = {
  getRaCampaignDetail(state: IRaCampaignState) {
    return (id: string) => {
      return find(cloneDeep(state.data), (e) => e.id === id);
    };
  }
};
