import { Subscription } from 'apollo-client/util/Observable';
import { TabPane } from 'element-ui';
import { cloneDeep } from 'lodash';
import moment from 'moment-timezone';
import { SystemRoleAlias } from 'root/admin/User/Store/types';
import { IPaginationParams } from 'root/api/graphql/Core';
import { FilterByBranch } from 'root/components/FilterByBranch';
import { SearchByFilter } from 'root/components/SearchByFilter';
import { IAddress, IFilterInput, StatusCode } from 'root/models';
import { IBreadcrumb, IState, MutationType, TypeAlert } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import { NewRaCampaignRouterName, NewRaCampaignTab } from '..';
import { RequestCampaignForm } from '../Components/RequestCampaignsForm';
import { ActionTypeCampaignRequestList, MutationTypeCampaignRequestList } from '../Store/types';
import './styles.scss';

const enum FilterKey {
  Status = 'status',
  Qualified = 'qualified'
}
export const RequestStatusEnum = {
  Requesting: 'requesting',
  Reviewing: 'reviewing',
  Confirmed: 'confirmed',
  Approved: 'approved',
  Rejected: 'rejected',
  Cancelled: 'canceled'
};
const QualifiedEnum = {
  qualified : 'Qualified',
  non_qualified : 'Not Qualified',
};
@Component({
  template: require('./view.html'),
  computed: {
    ...mapState({
      data: (state: IState) => state.newRACampaign.acl_requests.data,
      loading: (state: IState) => state.newRACampaign.acl_requests.loading,
      pagination: (state: IState) => state.newRACampaign.acl_requests.pagination,
      authUser: (state: IState) => state.global.authUser,
      branch: (state: IState) => state.branchManage.data,
      group: (state: IState) => state.groupManage.data,
    }),
  },
  components: {
    'request-campaign-form': RequestCampaignForm,
    'search-by-filter': SearchByFilter,
    'btn-filter-by-branch': FilterByBranch
  }
})

export class RequestList extends Vue {
  public pagination: IPaginationParams;
  public visibleAddParticipant: boolean = false;
  public modelSearchBy: any[] = [];
  public searchText: string = '';
  public authUser: any;
  public action: string = '';
  public titleAction: string = '';
  public messageAction: string = '';
  public reason: string = '';
  public titleReason: string = '';
  public requestId: string = '';
  public type: string = '';
  public config: any[] = [];
  public loadingExport: boolean = false;
  public visibleAction: boolean = false;
  public loadingAction: boolean = false;
  public isErrorReason: boolean = false;
  public subscribeURL: Subscription = null;
  public branch: any[];
  public SystemRoleAlias = SystemRoleAlias;
  public group: any[];
  public filterParams = {
    [FilterKey.Status]: [],
    [FilterKey.Qualified]: []
  };
  public get currentRole() {
    return this.authUser.role.alias;
  }
  public get activeTab() {
    return NewRaCampaignTab.RequestList;
  }
  public set activeTab(_value: string) {
    //
  }
  public get isSalesDepartmentPic() {
    return this.currentRole === SystemRoleAlias.SaleDepartmentPic;
  }
  public get isSalesManager() {
    return this.currentRole === SystemRoleAlias.GroupLeader;
  }
  public get isSalePlanning() {
    return this.currentRole === SystemRoleAlias.SaleAdmin;
  }
  public get selectedFilter() {
    const filterParams = cloneDeep(this.filterParams);

    return [
      {
        key: FilterKey.Status,
        value: filterParams[FilterKey.Status]
      },
      {
        key: FilterKey.Qualified,
        value: filterParams[FilterKey.Qualified]
      },
    ];
  }
  public get dataFilter() {
    return [
      {
        key: FilterKey.Status,
        name: 'Status',
        value: Object.keys(RequestStatusEnum).map((e) => (
          {
            key: RequestStatusEnum[e],
            value: RequestStatusEnum[e],
            name: e
          }
        ))
      },
      {
        key: FilterKey.Qualified,
        name: 'Qualified',
        value: Object.keys(QualifiedEnum).map((e) => (
          {
            key: e,
            value: e,
            name: QualifiedEnum[e]
          }
        ))
      },
    ];
  }
  public get columns() {
    return [
      {
        prop: 'requestStatus',
        label: 'Request Status',
        width: 200,
        fixed: true
      },
      {
        prop: 'name',
        label: 'Request Name',
        formatter: (model: any) => {
          return model.requestName ? model.requestName : '--';
        },
        width: 300,
      },
      {
        prop: 'createdAt',
        label: 'Created At',
        width: 180,
        formatter: (model: any) => {
          return moment(model.createdAt).format('HH:mm, DD/MM/YYYY') || '--';

        }
      },
      {
        prop: 'createdBy',
        label: 'Created By',
        width: 220,
        formatter: (model: any) => {
          return model.createdUser && model.createdUser.name ? model.createdUser.name : '--';
        },
      },
      ...!this.isSalesDepartmentPic ? [
        {
          prop: 'createdByBranch',
          label: 'Branch',
          width: 120,
          formatter: (model: any) => {
            return model.createdUser && model.createdUser.branch && model.createdUser.branch.name ?
            model.createdUser.branch.name : '--';
          },
        },
        {
          prop: 'createdByGroup',
          label: 'Department',
          width: 120,
          formatter: (model: any) => {
            return model.createdUser && model.createdUser.group && model.createdUser.group.name ?
            model.createdUser.group.name : '--';
          },
        },
      ] : [],
      {
        prop: 'salesManager',
        label: 'Review/Confirm by',
        width: 160,
        formatter: (model: any) => {
          return model.histories && model.histories.length && model.histories[0].creator &&
          model.histories[0].creator.name ? model.histories[0].creator.name : '--';
        },
      },
      {
        prop: 'totalRequest',
        label: 'Total Request',
        width: 180,
        formatter: (model: any) => {
          return model.reviews ? model.reviews.filter((e) => e.status === 'active').length : 0;
        }
      },
      {
        prop: 'classification',
        label: 'Classification',
        width: 180,
        formatter: (model: any) => {
          return model.classification ? `${model.classification.split('_').join(' ')}` : '--';
        },
        labelStyle: (model) => {
          return `text--capitalize request-qualified__${model.classification}`;
        }
      },
      {
        prop: 'action',
        label: '',
        align: 'center',
        fixed: 'right',
        width: 60
      }
    ];
  }
  public getActions(model: any) {
    if (model && model.status === RequestStatusEnum.Requesting && this.isSalesDepartmentPic) {
      return ['view', 'cancel'];
    }
    if (model && model.status === RequestStatusEnum.Requesting && this.isSalesManager) {
      return ['review'];
    }
    if (model && model.status === RequestStatusEnum.Reviewing && this.isSalesManager) {
      return ['view', 'confirm'];
    }
    if (model && model.status === RequestStatusEnum.Confirmed && this.isSalePlanning) {
      return ['view', 'approve-request', 'reject-request'];
    }

    return ['view'];
  }
  public changeTab(tab: TabPane) {
    switch (tab.name) {
    case NewRaCampaignTab.Participant:
      this.$router.push({
        name: NewRaCampaignRouterName.Participant
      });
      break;
    case NewRaCampaignTab.Shop:
      this.$router.push({
        name: NewRaCampaignRouterName.Shop
      });
      break;
    case NewRaCampaignTab.SubDealer:
      this.$router.push({
        name: NewRaCampaignRouterName.SubDealer
      });
      break;
    case NewRaCampaignTab.Registration:
      this.$router.push({
        name: NewRaCampaignRouterName.Registration
      });
      break;
    case NewRaCampaignTab.Config:
      this.$router.push({
        name: NewRaCampaignRouterName.Config
      });
      break;
    case NewRaCampaignTab.Statistic:
      this.$router.push({
        name: NewRaCampaignRouterName.Statistic
      });
      break;
    case NewRaCampaignTab.Export:
      this.$router.push({
        name: NewRaCampaignRouterName.Export
      });
      break;
    case NewRaCampaignTab.Classified:
      this.$router.push({
        name: NewRaCampaignRouterName.Classified
      });
      break;
    default:
      break;
    }
  }
  public checkSubdealerHasJoinedCampaign(model) {
    return (model && model.dealer && model.dealer.acl_campaigns_2023.length);
  }
  public addParticipant() {
    this.visibleAddParticipant = true;
  }
  public changeSearchBy(model: IFilterInput[]) {
    this.modelSearchBy = model[0].value;

    this.fetchData();
  }
  public onEnterFilter(value) {
    this.searchText = value;

    this.fetchData();
  }
  public handleActions({act, id}) {
    this.requestId = id;
    switch (act) {
    case 'cancel':
      this.action = 'cancel';
      this.visibleAction = true;
      this.titleAction = 'Cancel request';
      this.messageAction = 'Are you sure to cancel this request? Please input reason below.';
      this.titleReason = 'Reason request ';
      this.type = 'danger';
      break;
    case 'review':
      this.action = 'review';
      this.visibleAction = true;
      this.titleAction = 'Review request';
      this.messageAction = 'Are you sure to review this request?';
      this.titleReason = '';
      this.type = 'primary';
      break;
    case 'confirm':
      this.action = 'confirm';
      this.visibleAction = true;
      this.titleAction = 'Confirm request';
      this.messageAction = 'Are you sure to confirm this request?';
      this.titleReason = 'Confirm comment';
      this.type = 'primary';
      break;
    case 'approve-request':
      this.action = 'approve';
      this.visibleAction = true;
      this.titleAction = 'Approve request';
      this.messageAction = 'Are you sure to approve this request?';
      this.titleReason = 'Approve note';
      this.type = 'primary';
      break;
    case 'reject-request':
      this.action = 'reject';
      this.visibleAction = true;
      this.titleAction = 'Reject request';
      this.messageAction = 'Are you sure to reject this request? Please input reason below.';
      this.titleReason = 'Reason reject ';
      this.type = 'danger';
      break;
    case 'view':
      this.$router.push({
        name: NewRaCampaignRouterName.RequestDetail,
        params: {
          id
        }
      });
      this.requestId = '';
      break;
    default:
      break;
    }

  }
  public submitAction() {
    this.loadingAction = true;
    // if ((this.action !== 'review' && this.action !== 'confirm' && this.action !== 'approve') &&
    // (!this.reason || this.reason.length <= 0)) {
    //   this.loadingAction = false;

    //   return this.isErrorReason = true;
    // }
    this.isErrorReason = false;
    this.$store.dispatch(ActionTypeCampaignRequestList.Update, {
      form: {
        status: this.action === 'cancel' ? RequestStatusEnum.Cancelled :
        this.action === 'review' ? RequestStatusEnum.Reviewing :
        this.action === 'confirm' ? RequestStatusEnum.Confirmed :
        this.action === 'approve' ? RequestStatusEnum.Approved :
        this.action === 'reject' ? RequestStatusEnum.Rejected : '',
        ...this.action === 'cancel' ? {
          canceledReason: this.reason,
        } : {},
        ...this.action === 'reject' ? {
          rejectedReason: this.reason,
        } : {},
        ...this.action === 'reject' ? {
          rejectedReason: this.reason,
        } : {},
        ...this.action === 'approve' ? {
          approvalNote: this.reason,
        } : {},
        ...this.action === 'confirm' ? {
          confirmedComment: this.reason,
        } : {},
      },
      id: this.requestId,
      onSuccess: () => {
        const message = `${this.action} request successfully!`;
        this.$store.commit(MutationType.OpenTopAlert, {
          message,
          type: TypeAlert.Success
        });
        this.loadingAction = false;
        this.closeDialogAction();
      }
    });
  }
  public changeFilter(model) {
    const status = model.find((e) => e.key === FilterKey.Status);
    const qualified = model.find((e) => e.key === FilterKey.Qualified);
    const nextFilter = {
      [FilterKey.Status]: status ? status.value : [],
      [FilterKey.Qualified]: qualified ? qualified.value : [],
    };
    this.filterParams = {
      ...cloneDeep(this.filterParams),
      ...nextFilter
    };
    this.$store.commit(MutationTypeCampaignRequestList.PaginationChange, {
      limit: 20,
      page: 1
    });
    this.fetchData();
  }
  public handleDelete(model) {
    this.$store.dispatch(ActionTypeCampaignRequestList.Update, {
      id: model.id,
      form: {
        status: StatusCode.Deleted
      },
      onSuccess: () => {
        model.instance.confirmButtonLoading = false;
        model.instance.confirmButtonText = 'Confirm';
        model.done();
        this.fetchData();
      },
      onFail: () => {
        model.instance.confirmButtonLoading = false;
        model.instance.confirmButtonText = 'Confirm';
      }
    });
  }
  public displayAddress(model: IAddress) {
    return model && model.province
      ? this.$store.getters['getAddressString'](model)
      : '';
  }
  public handleSearch() {
    this.fetchData();
  }
  public handlePaginationChange(value) {
    if (typeof value === 'number') {
      this.$store.commit(MutationTypeCampaignRequestList.PaginationChange, {
        ...this.pagination,
        size: value
      });
    } else {
      this.$store.commit(MutationTypeCampaignRequestList.PaginationChange, {
        ...this.pagination,
        ...value
      });
    }
    this.fetchData();
  }
  public checkModelSearchBy(model) {
    return this.modelSearchBy.indexOf(model) >= 0;
  }
  public refetchData() {
    this.fetchData();
  }
  public closeDialogAction() {
    this.visibleAction = false;
    this.requestId = '';
    this.action = '';
    this.titleAction = '';
    this.messageAction = '';
    this.titleReason = '';
    this.reason = '';
    this.fetchData();
  }

  protected async mounted() {
    const breadcrumb: IBreadcrumb[] = [
      {
        label: 'title.new_ra_campaign',
        path: null,
        current: false
      },
      {
        label: 'title.new_ra_campaign_requestList',
        path: null,
        current: true
      }
    ];
    this.$store.commit(MutationType.SetBreadcrumb, breadcrumb);
    this.$store.commit(MutationTypeCampaignRequestList.PaginationChange, {
      page: 1,
      limit: 20
    });
    this.fetchData();
  }
  protected beforeDestroy() {
    this.$store.commit(MutationType.ClearBreadcrumb);
  }
  private fetchData() {
    const text = cloneDeep(this.searchText);
    const {status, qualified} = this.filterParams;
    this.$store.commit(MutationTypeCampaignRequestList.ChangeOrderBy, {
      updatedAt: 'desc'
    });
    this.$store.commit(MutationTypeCampaignRequestList.FilterChange, {
      _and: {
        mode: 'special',
        data: [
          {
            ...status && status.length ? {
              status: {
                _in: status
              }
            } : {},
            ...qualified && qualified.length ? {
              classification: {
                _in: qualified
              }
            } : {},
          },
          {_or: [
            {
              requestName: {
                _ilike: `%${text}%`
              }
            }
          ]}
        ]
      }
    });
    this.$store.dispatch(ActionTypeCampaignRequestList.FilterNoCache);
  }
}
