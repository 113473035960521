import { crudMutations, CRUDState, ICRUDState } from 'root/store/helpers';
// import { MutationTypeImport } from './types';

export interface IFeedbackState extends ICRUDState<any> {
  loading: boolean;
}

export const defaultState: IFeedbackState = {
  ...CRUDState(),
  loading: false,
};

// export const mutations = {
//   ...crudMutations('feedback'),
//   [MutationTypeFeedback.GetListFeedback](state: IFeedbackState, data: any) {
//     state.data = data;
//   }
// };

export const mutations = {
  ...crudMutations('feedback')
};
