import { crudMutations, CRUDState, ICRUDState } from 'root/store/helpers';
import { MutationTypeSaleInCampaign } from './types';

export interface ISaleInCampaignState extends ICRUDState<any> {
  loading: boolean;
  saleInProduct: ICRUDState<any>;
  saleInReward: ICRUDState<any>;
  saleInLogs: ICRUDState<any>;
  pinedList: any[];
}

export const defaultState: ISaleInCampaignState = {
  ...CRUDState(),
  loading: false,
  saleInProduct: CRUDState(),
  saleInReward: CRUDState(),
  saleInLogs: CRUDState(),
  pinedList: []
};

export const mutations = {
  ...crudMutations('saleInCampaign'),
  ...crudMutations('saleInProduct', 'id', {keyState: 'saleInProduct'}),
  ...crudMutations('saleInReward', 'id', {keyState: 'saleInReward'}),
  ...crudMutations('saleInLogs', 'id', {keyState: 'saleInLogs'}),
  [MutationTypeSaleInCampaign.SetCampaignPinedList](state: ISaleInCampaignState, data) {
    state.pinedList = data;
  },
};
