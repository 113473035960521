import { FetchPolicy } from 'apollo-client';
import { isEmpty, isObject, omit } from 'lodash';
import { convertTextToSearch } from 'root/helpers';

export interface IPaginationParams {
  limit: number;
  page: number;
  total?: number;
  filter?: any;
}

export function PaginationParams(params?: IPaginationParams): IPaginationParams {
  return {
    page: 1,
    limit: 20,
    ...params,
  };
}

export interface IFilterParams {
  bookingStatus?: string | string[];
}
export function FilterParams(params?: IFilterParams): IFilterParams {
  return {
    ...params
  };
}
export function assignObjectToParams(params: object, data: any, key = '_and') {
  const result = {};
  if (!params[key]) {
    result[key] = data;
  }
  for (const keyScope in params) {
  // tslint:disable-next-line:early-exit
    if (params.hasOwnProperty(keyScope)) {
      result[keyScope] = keyScope === key
        ? assignObjectToParams(params[keyScope], data, key)
        : params[keyScope];
    }
  }

  return result;
}
export function convertFilterParams(params: any, neqDeleted = true) {
  let result: any = {};
  for (const key in params) {
    // tslint:disable-next-line:early-exit
    if (params.hasOwnProperty(key)) {
      const element = <any> params[key];
      if (Array.isArray(element)) {
        if (!isEmpty(element)) {
          if (key === '_or') {
            if (!result[key]) {
              result = {
                ...result,
                _or: []
              };
            }
            element.forEach((item: any) => {
              Object.keys(item).forEach((itemKey) => {
                result._or.push({
                  [itemKey]: {
                    _ilike: `%${item[itemKey].text}%`
                  }
                });
              });
            });
          } else {
            result[key] = {
              _in: element
            };
          }
        }
      } else if (<any> isObject(element)) {
        if (element.searchMode) {
          const convertedText = convertTextToSearch(element.text);
          result[key] = {
            _ilike: `%${convertedText}%`
          };
        } else if (element.mode === 'special') {
          result[key] = element['data'];
        } else {
          result[key] = convertFilterParams(element);
        }
      } else {
        result[key] = {
          _eq: element
        };
      }
    }
  }

  const status = neqDeleted ? { _neq: 'deleted' } : null;
  if (params.mode && params.mode === 'special') {
    result = {
      ...omit(result, ['mode' ])};
  } else {
    result.status = {
      ...result.status,
      ...status
    };
  }
  // result.status = {
  //   ...result.status,
  //   ...status
  // };

  return result;
}

export interface IGqlQueryOption {
  fetchPolicy?: FetchPolicy;
  filterParams?: any;
  orderBy?: object;
}
export function gqlQueryOption(): IGqlQueryOption {
  return {
    fetchPolicy: 'network-only',
    filterParams: {}
  };
}
