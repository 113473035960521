import { cloneDeep, find } from 'lodash';
import { IProductTransState } from '.';

export const getters = {
  getProductTransDetail(state: IProductTransState) {
    return (id: string) => {
      return find(cloneDeep(state.data), (e) => e.productId === id);
    };
  }
};
