// // import { IBranch, ISchool, TrainingRole } from 'root/models';
// import {
//   ActionTypeBranch
// } from 'root/pages/Branch/Store/types';
// import {
//   ActionTypeSchool,
// } from 'root/pages/School/Store/types';
// import { ActionTypeUser } from 'root/pages/User/Store/types';
import { ActionTypeDealer } from 'root/admin/Dealer/Store/types';
import { sanitizeString } from 'root/helpers';
import { IState, MutationType, TypeAlert } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import { ActionTypeNewRaCampaign } from '../../Store/types';
import './styles.scss';

@Component({
  template: require('./view.html'),
  computed: {
    ...mapState({
      authUser: (state: IState) => state.global.authUser,
      dealer: (state: IState) => state.dealer.data,
    }),
    dealerData() {
      return this.dealer && this.dealer.length ? this.dealer : [];
    }
  },
  props: {
    popupVisible: Boolean,
  },
})
export class AddParticipantForm extends Vue {
  public localVN;
  public selectedSub: any[] = [];
  public cloneSelectedSub: any[] = [];
  public selectedDealer: any[] = [];
  public isCheckAllSubDealer: boolean = false;
  public subdealerLoading: boolean = false;
  public dealerLoading: boolean = false;
  // public schoolSearchText: string = '';
  public subdealerSearchText: string = '';
  public dealerSearchText: string = '';
  public subDealerData: any[] = [];
  public cloneSubDealerData: any[] = [];
  public dealer: any[];
  public cloneDealerData: any []= [];
  public dealerData: any[];
  public loading: boolean = false;
  public subdealerFilterData: any[] = [];

  public authUser: any;
  public currentUser: any = null;
  public result: any[] = [];
  public get disableSubmit() {
    return this.result && this.result.length > 0 && this.result.some((it) => it.sub && it.sub.length > 0);
  }
  public get currentRole() {
    return this.authUser.roles.length < 2 ? this.authUser.roles[0] : this.authUser.currentRole;
  }

  public back() {
    this.subdealerSearchText = '';
    this.dealerSearchText = '';
    this.cloneDealerData = [];
    this.$emit('update:popupVisible', false);
  }
  public apply() {
    this.loading = true;
    this.$store.dispatch(ActionTypeNewRaCampaign.AddParticipant, {
      form: this.result,
      createdBy: this.authUser.id,
      onSuccess: () => {
        this.loading = false;
        const message = 'Add participants successfully';
        this.$store.commit(MutationType.OpenTopAlert, {
          message,
          type: TypeAlert.Success,
        });
        this.closeDialog();
        this.cloneSelectedSub = [];
        this.selectedDealer = [];
        this.selectedSub = [];
        this.dealerSearchText = '';
        this.$emit('fetchData');
      }
    });
  }
  public openDialog() {
    this.subdealerLoading = true;
    this.dealerLoading = true;
    this.selectedDealer = [];
    this.selectedSub = [];
    this.cloneSelectedSub = [];
    this.result = [];
    this.$store.dispatch(ActionTypeDealer.GetTargetDetail, {
      role: ['daikin_dealer'],
      status: ['active'],
      onSuccess: () => {
        this.dealerLoading = false;
      }
    });
    this.$store.dispatch(ActionTypeNewRaCampaign.GetListSubDealerAssign, {
      onSuccess: (data) => {
        this.subDealerData = data;
        this.cloneSubDealerData = data;
        this.subdealerLoading = false;
      }
    });
  }
  public closeDialog() {
    this.back();
  }
  public removeSubdealerResult(id) {

    let subdealer: any;
    subdealer = this.selectedSub.find((sub: any) => sub.id === id);
    const idx = this.selectedSub.indexOf(subdealer);
    this.selectedSub.splice(idx, 1);

    let ind = null;
    let subInd = null;
    this.result.map((el, index) => {
      el.sub.map((it, subindex) => {
        if (it.id === id) {
          ind = index;
          subInd = subindex;
        }
      });
    });
    this.result[ind].sub.splice(subInd, 1);
    let removeInd = null;
    this.result.map((el, index) => {
      if (el.sub && el.sub.length === 0) {
        return removeInd = index;
      }
    });
    if (removeInd !== null && removeInd >= 0) {
      this.result.splice(removeInd, 1);
      this.selectedDealer.splice(removeInd, 1);
    }
  }
  public filterSubdealer() {
    if (this.subdealerSearchText.trim() !== '') {
      const text = sanitizeString(this.subdealerSearchText);
      this.cloneSubDealerData = this.subDealerData.filter((el) => (
        sanitizeString(el.name).includes(text) || sanitizeString(el.dealerCode).includes(text)
        || sanitizeString(el.phoneNumber).includes(text)
      ));

      return;
    }
    this.cloneSubDealerData = this.subDealerData;
  }
  public filterDealer() {
    if (this.dealerSearchText.trim() !== '') {
      const text = sanitizeString(this.dealerSearchText);
      this.cloneDealerData = this.dealerData.filter((el) => (
        sanitizeString(el.name).includes(text) || sanitizeString(el.dealerCode).includes(text)
      ));

      return;
    }
    this.cloneDealerData = [];
  }

  public isSelected(_item) {
    return false;
  }
  public selectSubItem(item) {
    let list = this.selectedSub;
    let clone = this.cloneSelectedSub;
    if (this.isAssignedSubdealer(item)) {
      return;
    }
    if (!this.isSubSelected(item) && !this.isCloneSubSelected(item)) {
      list.push(item);
      clone.push(item);
    } else if (this.isSubSelected(item) && !this.isCloneSubSelected(item)) {
      list.push(item);
      // clone.push(item);
    } else if (this.isSubSelected(item) && this.isCloneSubSelected(item)) {
      clone = clone.filter((e) => e.id !== item.id);
      list = list.filter((e) => e.id !== item.id);
    } else {
      // list = list.filter((e) => e.id !== item.id);
      // clone = clone.filter((e) => e.id !== item.id);

      return;
    }
    this.cloneSelectedSub = clone;
    this.selectedSub = list;
  }
  public isSubSelected(item) {
    return this.selectedSub.filter((el) => el.id === item.id).length > 0;
  }
  public isSelectedDealer(item) {
    return this.selectedDealer.filter((el) => el.id === item.id).length > 0;
  }
  public isCloneSubSelected(item) {
    return this.cloneSelectedSub.filter((el) => el.id === item.id).length > 0;
  }
  public isAssignedSubdealer(item) {
    return item && item.assignedSubDealers2023View && item.assignedSubDealers2023View.length > 0;
  }
  public isSelectedSubDealer(item) {

    return this.isSubSelected(item) && !this.isCloneSubSelected(item);
  }
  public selectDealerItem(item) {
    const list = this.selectedDealer;
    if (!this.isDealerSelected(item)) {
      list.push(item);
    } else {
      // return;
    }
    this.cloneDealerData = this.cloneDealerData.filter((it) => it.id !== item.id);
    this.selectedDealer = list;
    let itemIndex = null;
    this.result.map((it, index) => {
      if (item.id === it.dealer.id) {
        return itemIndex = index;
      }
    });
    if (itemIndex !== null && itemIndex >= 0) {
      this.result[itemIndex].sub = [...this.result[itemIndex].sub, ...this.cloneSelectedSub];
      this.cloneSelectedSub = [];

      return;
    }
    const _result = {dealer: item, sub: this.cloneSelectedSub};
    this.result.push(_result);
    this.cloneSelectedSub = [];
  }
  public isDealerSelected(item) {
    return this.selectedDealer.filter((el) => el.id === item.id).length > 0;
  }
  public checkSelectedItem(child: any[], parent: any[]) {
    return child.length === parent.length && parent.length > 0;
  }
  public subdealerIndetermainate() {
    this.isCheckAllSubDealer = !this.isCheckAllSubDealer;
    if (this.isCheckAllSubDealer) {
      this.cloneSelectedSub = this.subDealerData;
      this.selectedSub = this.subDealerData;

      return;
    }
    this.cloneSelectedSub = [];
  }
}
