import { DKGqlClient } from 'root/api/graphql/Client';
import { ActionType, IState, MutationType } from 'root/store';
import { crudActions } from 'root/store/helpers';
import { ActionContext } from 'vuex';
import { IBranchState } from './mutation';
import { ActionTypeBranch } from './types';

const { branch } = DKGqlClient();
export const actions = {
  ...crudActions('branch', {
    filter: branch.filter
  }),
  async [ActionTypeBranch.GET_LIST_BRANCH]({
    commit,
    dispatch
  }: ActionContext<IBranchState, IState>) {
    try {
      const BranchList = await branch.getListBranch();
      commit(MutationType.GetBranch, BranchList);
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    }
  }
};
