import { DocumentNode } from 'graphql';
import gql from 'graphql-tag';
import { crudQuery, ICRUDQuery } from '../Core/query';
const newRACampaignModel = `
  id
  dealerCode
  subDealerCode
  dealerId
  subDealerId
  status
  dealer {
    name
    email
    id
    dealerCode
    phoneNumber
  }
  dealerView {
    name
    email
    id
    dealerCode
    phoneNumber
  }
  subDealerView {
    name
    id
    email
    dealerCode
    phoneNumber
    address
    status
    acl_campaigns_2023 {
      id
    }
  }
  subDealer {
    name
    id
    email
    dealerCode
    phoneNumber
    address
    status
    acl_campaigns_2023 {
      id
    }
  }
  status
`;
const raCampaignModel2023 = `
  id
  address
  name
  dealerCode
  shortName
  phoneNumber
  proshopType {
    name
    alias
  }
  branch {
    id
    name
    branchCode
  }
  role {
    alias
  }
  assignedSubDealers2023View(where: {status: {_eq: "active"}}) {
    dealer {
      id
      name
      dealerCode
    }
    dealerView {
      id
      name
      dealerCode
    }
  }
  email
  countRaJoined2023
  shops_aggregate(where: {isOwner: {_eq: true}}) {
    aggregate {
      count
    }
  }
  acl_campaigns_2023_aggregate(where: {campaign: {campaignType: {_eq: "RA_CAMPAIGN_2023"}}}) {
    aggregate {
      count
    }
  }
`;
const raCampaignDetailModel = `
  id
  createdAt
  branch {
    id
    name
  }
  email
  address
  name
  role {
    name
  }
  proshopType {
    name
    alias
  }
  acl_campaigns_2023_aggregate(where: {campaign: {campaignType: {_eq: "RA_CAMPAIGN_2023"}}}) {
    aggregate {
      count
    }
  }
  status
  updatedAt
  dealerCode
  shortName
  updatedBy
  createdBy
  createdUser {
    id
    name
  }
  updatedUser {
    id
    name
  }
`;
const campaignRequest = `
  id
  status
  createdAt
  updatedAt
  requestName
  classification
  comment
  reviews {
    id
    status
    aclCampaign {
      id
      saleStaffReview
      salePicReview
      saleManagerReview
      salePlanningReview
      spiralReview
      createdAt
      reviews {
        classification
        createdAt
      }
    }
  }
  createdUser {
    name
    group {
      name
    }
    branch {
      name
    }
  }
  histories (where: { action: { _in : ["reviewing", "confirmed"]}}, order_by: {createdAt: desc}) {
    creator {
      name
    }
  }
  updatedUser {
    name
  }
`;
const classifiedRequest = `
id
aclCampaign {
  id
  saleStaffReview
  salePicReview
  saleManagerReview
  salePlanningReview
  spiralReview
  createdAt
  reviews {
    classification
    createdAt
    description
  }
  dealer {
    id
    role {
      alias
    }
    address
    information2023 {
      requestedRewardReceiptURLs
      productReceiptPicURLs
      data
    }
    name
    dealerCode
    assignedSubDealers2023View {
      dealer {
        id
        name
        dealerCode
      }
    }
  }
  shop {
    id
    name
    shopCode
    address
  }
}
`;

export const newRACampaign = 'assigned_sub_dealers_2023_view';
export const newRACampaignRequest = 'acl_requests';
// tslint:disable-next-line:no-empty-interface
interface INewRACampaignQuery extends ICRUDQuery {
  GET_ALL_SUBDEALER: DocumentNode;
  ADD_PARTICIPANT: DocumentNode;
  EXPORT_LIST_REGISTRATION: DocumentNode;
  GET_LIST_RA_CAMPAIGN: DocumentNode;
  GET_LIST_SHOP_CAMPAGIN: DocumentNode;
  GET_LIST_SHOP_CAMPAGIN_NOTJOIN: DocumentNode;
  GET_CONFIG: DocumentNode;
  GET_LIST_SHOPS: DocumentNode;
  GET_LIST_SHOPS_NOT_REVIEW: DocumentNode;
  GET_LIST_SHOPS_NOT_JOIN: DocumentNode;
  GET_LIST_SHOPS_NOT_JOIN_NOT_REIVEW: DocumentNode;
  GET_LIST_SUB: DocumentNode;
  GET_LIST_SUB_NOT_REVIEW: DocumentNode;
  GET_LIST_SUB_NOT_JOIN: DocumentNode;
  GET_LIST_SUB_NOT_JOIN_NOT_REIVEW: DocumentNode;
  GET_SUB_DEALER_CAMPAIGN_DETAIL: DocumentNode;
  SUBMIT_REVIEW: DocumentNode;
  REQUEST_REUP: DocumentNode;
  GET_SHOP: DocumentNode;
  GET_ACTION_HISTORY_2023: DocumentNode;
  UPDATE_PACKAGE: DocumentNode;
  GET_QUALIFIED_REQUEST: DocumentNode;
  GET_HISTORY_LOG: DocumentNode;
  END_CAMPAIGN: DocumentNode;
  GET_MASK_CONFIG: DocumentNode;
  MASK_CHANGE: DocumentNode;
  GET_DEALER_BY_BRANCH: DocumentNode;
  GET_STATISTIC: DocumentNode;
  EXPORT_STATISTIC: DocumentNode;
  GET_LIST_MANAGE_EXPORT: DocumentNode;
  GET_SALE_STAFF_REVIEW_SAME_GROUP: DocumentNode;
  CampaignRequest: ICRUDQuery;
  ClassifiedRequest: ICRUDQuery;
  GET_PDF_EXPORT_DATA: DocumentNode;
}
export const newRACampaignQuery: INewRACampaignQuery = {
  ...crudQuery(newRACampaign, newRACampaignModel),
  CampaignRequest: crudQuery(newRACampaignRequest, campaignRequest),
  ClassifiedRequest: crudQuery('acl_request_reviews', classifiedRequest),
  GET_ALL_SUBDEALER: gql`
    query GET_ALL_SUBDEALER {
      dealers(
        where: {
          role: { alias: { _eq: "sub_dealer" } }
          status: { _eq: "active" }
        }
      ) {
        dealerCode
        name
        phoneNumber
        id
        assignedSubDealers2023View {
          id
          dealerCode
        }
      }
    }
  `,
  ADD_PARTICIPANT: gql`
    mutation ($objects: [assigned_sub_dealers_2023_insert_input!]!) {
      insert_assigned_sub_dealers_2023(objects: $objects) {
        affected_rows
      }
    }
  `,
  EXPORT_LIST_REGISTRATION: gql`
    mutation EXPORT_LIST_REGISTRATION($objects: [exports_insert_input!]!) {
      insert_exports(objects: $objects) {
        affected_rows
        returning {
          id
        }
      }
    }
  `,
  GET_LIST_RA_CAMPAIGN: gql`
  query GET_LIST_RA_CAMPAGIN(
    $limit: Int
    $role: [String!]
    $branch: [uuid!]
    $order: [dealers_order_by!]
    $offset: Int
    $search: [dealers_bool_exp!]
  ) {
    dealers(
      where: {
        status: { _eq: "active" }
        _and: [
          {
            _or: [
              { role: { alias: { _eq: "daikin_dealer" } } }
              {
                role: { alias: { _eq: "sub_dealer" } }
                assignedSubDealers2023View: { dealerId: { _is_null: false } }
              }
            ]
          }
          { role: { alias: { _in: $role } } }
          { branch: { id: { _in: $branch } } }
          {
            _or: $search
          }
        ]
      }
      offset: $offset
      limit: $limit
      order_by: $order
    ) {
      ${raCampaignModel2023}
    }
dealers_aggregate(
      where: {
        status: { _eq: "active" }
        _and: [
          {
            _or: [
              { role: { alias: { _eq: "daikin_dealer" } } }
              {
                role: { alias: { _eq: "sub_dealer" } }
                assignedSubDealers2023View: { dealerId: { _is_null: false } }
              }
            ]
          }
          { role: { alias: { _in: $role } } }
          { branch: { id: { _in: $branch } } }
          {
            _or: $search
          }
        ]
      }
    ) {
      aggregate {
        count
      }
    }
  }
`,
  GET_LIST_SHOP_CAMPAGIN: gql`
query GET_LIST_SHOP_CAMPAGIN(
    $id: uuid!
    $status: [String!]
    $limit: Int
    $offset: Int
    $search: String,
    $classification: [String!]
    $dealerApproval: [Boolean!]
  ) {
    dealers(
      where: {
        role: { alias: { _in: ["daikin_dealer", "sub_dealer"] } }
        id: { _eq: $id }
      }
    ) {
      ${raCampaignDetailModel}
      shops(
        where: {
          isOwner: { _eq: true }
          _and: [
            {
              _or: [
                {
                  shop: {
                    acl_campaigns_2023: {
                      campaign: { campaignType: { _eq: "RA_CAMPAIGN_2023" } }
                      requestStatus: { _in: $status },
                      saleStaffReview: {_in: $classification}
                      isDealerConfirm: {_in: $dealerApproval}
                    }
                  }
                }
                {
                  shop: {
                    _not: {
                      acl_campaigns_2023: {
                        campaign: { campaignType: { _eq: "RA_CAMPAIGN_2023" } }
                      }
                    }
                  }
                }
              ]
            }
            {
              _or: [
                { shop: { name: { _ilike: $search } } }
                { shop: { phoneNumber: { _ilike: $search } } }
                { shop: { email: { _ilike: $search } } }
              ]
            }
          ]
        }
        offset: $offset
        limit: $limit
      ) {
        shop {
          id
          name
          email
          address
          phoneNumber
          shopCode
          acl_campaigns_2023(
            where: { campaign: { campaignType: { _eq: "RA_CAMPAIGN_2023" } } }
          ) {
            id
            reason
            requestStatus
            updatedAt
            classification
            isDealerConfirm
            dealerApproveAt
            reviews {
              description
              updatedAt
            }
          }
          dealerShops {
            isOwner
            contractStatus
          }
        }
      }
      shops_aggregate(
        where: {
          isOwner: { _eq: true }
          _and: [
            {
              _or: [
                {
                  shop: {
                    acl_campaigns_2023: {
                      campaign: { campaignType: { _eq: "RA_CAMPAIGN_2023" } }
                      requestStatus: { _in: $status },
                      saleStaffReview: {_in: $classification}
                      isDealerConfirm: {_in: $dealerApproval}
                    }
                  }
                }
                {
                  shop: {
                    _not: {
                      acl_campaigns_2023: {
                        campaign: { campaignType: { _eq: "RA_CAMPAIGN_2023" } }
                      }
                    }
                  }
                }
              ]
            }
            {
              _or: [
                { shop: { name: { _ilike: $search } } }
                { shop: { phoneNumber: { _ilike: $search } } }
                { shop: { email: { _ilike: $search } } }
              ]
            }
          ]
        }
      ) {
        aggregate {
          count
        }
      }
    }
  }`,
  GET_LIST_SHOP_CAMPAGIN_NOTJOIN: gql`
query GET_LIST_SHOP_CAMPAGIN(
    $id: uuid!
    $status: [String!]
    $limit: Int
    $offset: Int
    $search: String
    $classification: [String!]
    $dealerApproval: [Boolean!]
  ) {
    dealers(
      where: {
        role: { alias: { _in: ["daikin_dealer", "sub_dealer"] } }
        id: { _eq: $id }
      }
    ) {
      ${raCampaignDetailModel}
      shops(
        where: {
          isOwner: { _eq: true }
          _and: [
            {
              shop: {
                acl_campaigns_2023: {
                  campaign: { campaignType: { _eq: "RA_CAMPAIGN_2023" } }
                  requestStatus: { _in: $status },
                  saleStaffReview: {_in: $classification}
                  isDealerConfirm: {_in: $dealerApproval}
                }
              }
            }
            {
              _or: [
                { shop: { name: { _ilike: $search } } }
                { shop: { phoneNumber: { _ilike: $search } } }
                { shop: { email: { _ilike: $search } } }
              ]
            }
          ]
        }
        offset: $offset
        limit: $limit
      ) {
        shop {
          id
          name
          email
          address
          phoneNumber
          acl_campaigns_2023(
            where: { campaign: { campaignType: { _eq: "RA_CAMPAIGN_2023" } } }
          ) {
            id
            reason
            requestStatus
            updatedAt
            classification
            isDealerConfirm
            dealerApproveAt
            reviews {
              description
              updatedAt
            }
          }
          dealerShops {
            isOwner
            contractStatus
          }
        }
      }
      shops_aggregate(
        where: {
          isOwner: { _eq: true }
          _and: [
            {
              shop: {
                acl_campaigns_2023: {
                  campaign: { campaignType: { _eq: "RA_CAMPAIGN_2023" } }
                  requestStatus: { _in: $status }
                  saleStaffReview: {_in: $classification}
                  isDealerConfirm: {_in: $dealerApproval}
                }
              }
            }
            {
              _or: [
                { shop: { name: { _ilike: $search } } }
                { shop: { phoneNumber: { _ilike: $search } } }
                { shop: { email: { _ilike: $search } } }
              ]
            }
          ]
        }
      ) {
        aggregate {
          count
        }
      }
    }
  }
`,
  GET_CONFIG: gql`
    query GET_CONFIG {
      campaigns(where: { campaignType: { _eq: "RA_CAMPAIGN_2023" } }) {
        metadata
        aclCampaignConfig {
          id
          uploadStartDate
          uploadEndDate
        }
      }
    }
  `,
  GET_LIST_SHOPS: gql`
    query GET_LIST_SHOPS(
      $search: String
      $status: [String!]
      $branch: [uuid!]
      $saleFilter: [String!]
      $branchFilter: [String!]
      $classification: [String!]
      $saleManager: [String!]
      $payment: dealers_bool_exp = {}
      $limit: Int
      $offset: Int
      $dealerApproval: [Boolean!]
    ) {
      shops(
        where: {
          _and: [
            {
              acl_campaigns_2023: {
                campaign: { campaignType: { _eq: "RA_CAMPAIGN_2023" } }
                requestStatus: { _in: $saleFilter }
                salePicReview: { _in: $classification }
                saleManagerReview: { _in: $saleManager }
                isDealerConfirm: {_in: $dealerApproval}
              }
            }
            { dealerShops: {isOwner: { _eq: true }, dealer: {_and:[
              { branchId: { _in: $branch } },
              $payment
            ]} } }
            {
              acl_campaigns_2023: {
                campaign: { campaignType: { _eq: "RA_CAMPAIGN_2023" } }
                classification: { _in: $branchFilter }
                salePicReview: { _in: $classification }
              }
            }
            {
              _or: [
                { dealerShops: { shop: { name: { _ilike: $search } } } }
                { dealerShops: { shop: { shopCode: { _ilike: $search } } } }
                { dealerShops: { dealer: { name: { _ilike: $search } } } }
                { dealerShops: { dealer: { dealerCode: { _ilike: $search } } } }
              ]
            }
          ]
        }
        limit: $limit
        offset: $offset
        order_by: { updatedAt: desc }
      ) {
        name
        shopCode
        address
        id
        aclSpiralReviews(order_by: { updateTimes: desc }) {
          totalPoint
        }
        dealerShops(where: { isOwner: { _eq: true } }) {
          isOwner
          dealer {
            id
            name
            information2023 {
              requestedRewardReceiptURLs
              data
            }
            dealerCode
            role {
              alias
            }
          }
        }
        acl_campaigns_2023(
          where: { campaign: { campaignType: { _eq: "RA_CAMPAIGN_2023" } } }
        ) {
          id
          dealer {
            id
            name
            publicCode
            dealerCode
            taxNumber
            address
            ownerName
            ownerPosition
            shortName
            role {
              alias
            }
          }
          spiralReviews(order_by: { updateTimes: desc }) {
            totalPoint
          }
          salePicReview
          saleManagerReview
          isDealerConfirm
          dealerApproveAt
          shop {
            id
            name
            shopCode
            address
          }
          packages
          pictureURLs
          resizedPictureURLs
          reason
          requestStatus
          reviews {
            id
            classification
            createdAt
            description
          }
          classification
          requestStatus
          shopId
        }
      }
      shops_aggregate(
        where: {
          _and: [
            {
              acl_campaigns_2023: {
                campaign: { campaignType: { _eq: "RA_CAMPAIGN_2023" } }
                requestStatus: { _in: $saleFilter }
                salePicReview: { _in: $classification }
                saleManagerReview: { _in: $saleManager }
                isDealerConfirm: {_in: $dealerApproval}
              }
            }
            { dealerShops: { isOwner: { _eq: true }, dealer: {_and:[
              { branchId: { _in: $branch } },
              $payment
            ]} } }
            {
              acl_campaigns_2023: {
                campaign: { campaignType: { _eq: "RA_CAMPAIGN_2023" } }
                classification: { _in: $branchFilter }
                salePicReview: { _in: $classification }
              }
            }
            {
              _or: [
                { dealerShops: { shop: { name: { _ilike: $search } } } }
                { dealerShops: { shop: { shopCode: { _ilike: $search } } } }
                { dealerShops: { dealer: { name: { _ilike: $search } } } }
                { dealerShops: { dealer: { dealerCode: { _ilike: $search } } } }
              ]
            }
          ]
        }
      ) {
        aggregate {
          count
        }
      }
    }
  `,
  GET_LIST_SHOPS_NOT_REVIEW: gql`
    query GET_LIST_SHOPS_NOT_REVIEW(
      $search: String
      $status: [String!]
      $branch: [uuid!]
      $saleFilter: [String!]
      $branchFilter: [String!]
      $classification: [String!]
      $saleManager: [String!]
      $payment: dealers_bool_exp = {}
      $limit: Int
      $offset: Int
      $dealerApproval: [Boolean!]
    ) {
      shops(
        where: {
          _and: [
            {
              acl_campaigns_2023: {
                campaign: { campaignType: { _eq: "RA_CAMPAIGN_2023" } }
                requestStatus: { _in: $saleFilter }
                salePicReview: { _in: $classification }
                saleManagerReview: { _in: $saleManager }
                isDealerConfirm: {_in: $dealerApproval}
              }
            }
            { dealerShops: {isOwner: { _eq: true }, dealer: {_and:[
              { branchId: { _in: $branch } },
              $payment
            ]} } }
            {
              _or: [
                {
                  acl_campaigns_2023: {
                    campaign: { campaignType: { _eq: "RA_CAMPAIGN_2023" } }
                    classification: { _in: $branchFilter }
                    salePicReview: { _in: $classification }
                  }
                }
                {
                  _not: {
                    acl_campaigns_2023: {
                      campaign: { campaignType: { _eq: "RA_CAMPAIGN_2023" } }
                      reviews: {}
                    }
                  }
                }
              ]
            }
            {
              _or: [
                { dealerShops: { shop: { name: { _ilike: $search } } } }
                { dealerShops: { shop: { shopCode: { _ilike: $search } } } }
                { dealerShops: { dealer: { name: { _ilike: $search } } } }
                { dealerShops: { dealer: { dealerCode: { _ilike: $search } } } }
              ]
            }
          ]
        }
        limit: $limit
        offset: $offset
        order_by: { updatedAt: desc }
      ) {
        name
        shopCode
        address
        id
        aclSpiralReviews(order_by: { updateTimes: desc }) {
          totalPoint
        }
        dealerShops(where: { isOwner: { _eq: true } }) {
          isOwner
          dealer {
            id
            name
            information2023 {
              requestedRewardReceiptURLs
              data
            }
            dealerCode
            role {
              alias
            }
          }
        }
        acl_campaigns_2023(
          where: { campaign: { campaignType: { _eq: "RA_CAMPAIGN_2023" } } }
        ) {
          id
          dealer {
            id
            name
            publicCode
            dealerCode
            taxNumber
            address
            ownerName
            ownerPosition
            shortName
            role {
              alias
            }
          }
          spiralReviews(order_by: { updateTimes: desc }) {
            totalPoint
          }
          salePicReview
          saleManagerReview
          isDealerConfirm
          dealerApproveAt
          shop {
            id
            name
            shopCode
            address
          }
          packages
          pictureURLs
          resizedPictureURLs
          reason
          requestStatus
          reviews {
            id
            classification
            createdAt
            description
          }
          classification
          requestStatus
          shopId
        }
      }
      shops_aggregate(
        where: {
          _and: [
            {
              acl_campaigns_2023: {
                campaign: { campaignType: { _eq: "RA_CAMPAIGN_2023" } }
                requestStatus: { _in: $saleFilter }
                salePicReview: { _in: $classification }
                saleManagerReview: { _in: $saleManager }
                isDealerConfirm: {_in: $dealerApproval}
              }
            }
            { dealerShops: { isOwner: { _eq: true }, dealer: {_and:[
              { branchId: { _in: $branch } },
              $payment
            ]} } }
            {
              _or: [
                {
                  acl_campaigns_2023: {
                    campaign: { campaignType: { _eq: "RA_CAMPAIGN_2023" } }
                    classification: { _in: $branchFilter }
                    salePicReview: { _in: $classification }
                  }
                }
                {
                  _not: {
                    acl_campaigns_2023: {
                      campaign: { campaignType: { _eq: "RA_CAMPAIGN_2023" } }
                      reviews: {}
                    }
                  }
                }
              ]
            }
            {
              _or: [
                { dealerShops: { shop: { name: { _ilike: $search } } } }
                { dealerShops: { shop: { shopCode: { _ilike: $search } } } }
                { dealerShops: { dealer: { name: { _ilike: $search } } } }
                { dealerShops: { dealer: { dealerCode: { _ilike: $search } } } }
              ]
            }
          ]
        }
      ) {
        aggregate {
          count
        }
      }
    }
  `,
  GET_LIST_SHOPS_NOT_JOIN: gql`
    query GET_LIST_SHOPS_NOT_JOIN(
      $search: String
      $status: [String!]
      $branch: [uuid!]
      $saleFilter: [String!]
      $branchFilter: [String!]
      $classification: [String!]
      $saleManager: [String!]
      $payment: dealers_bool_exp = {}
      $limit: Int
      $offset: Int
      $dealerApproval: [Boolean!]
    ) {
      shops(
        where: {
          _and: [
            {
              _or: [
                {
                  _and: [
                    {
                      _not: {
                        acl_campaigns_2023: {
                          campaign: {
                            campaignType: { _eq: "RA_CAMPAGIN_2023" }
                          }
                        }
                      }
                    }
                    { dealerShops: { isOwner: { _eq: true } } }
                  ]
                }
                {
                  acl_campaigns_2023: {
                    campaign: { campaignType: { _eq: "RA_CAMPAGIN_2023" } }
                    requestStatus: { _in: $saleFilter }
                    salePicReview: { _in: $classification }
                    saleManagerReview: { _in: $saleManager }
                    isDealerConfirm: {_in: $dealerApproval}
                  }
                }
              ]
            }
            { dealerShops: {isOwner: { _eq: true }, dealer: {_and:[
              { branchId: { _in: $branch } },
              $payment
            ]} } }
            {
              acl_campaigns_2023: {
                campaign: { campaignType: { _eq: "RA_CAMPAIGN_2023" } }
                classification: { _in: $branchFilter }
                salePicReview: { _in: $classification }
              }
            }
            {
              _or: [
                { dealerShops: { shop: { name: { _ilike: $search } } } }
                { dealerShops: { shop: { shopCode: { _ilike: $search } } } }
                { dealerShops: { dealer: { name: { _ilike: $search } } } }
                { dealerShops: { dealer: { dealerCode: { _ilike: $search } } } }
              ]
            }
          ]
        }
        limit: $limit
        offset: $offset
        order_by: { updatedAt: desc }
      ) {
        name
        shopCode
        id
        address
        aclSpiralReviews(order_by: { updateTimes: desc }) {
          totalPoint
        }
        dealerShops(where: { isOwner: { _eq: true } }) {
          isOwner
          dealer {
            id
            name
            information2023 {
              requestedRewardReceiptURLs
              data
            }
            dealerCode
            role {
              alias
            }
          }
        }
        acl_campaigns_2023(
          where: { campaign: { campaignType: { _eq: "RA_CAMPAIGN_2023" } } }
        ) {
          id
          dealer {
            id
            name
            publicCode
            dealerCode
            taxNumber
            address
            ownerName
            ownerPosition
            shortName
            role {
              alias
            }
          }
          spiralReviews(order_by: { updateTimes: desc }) {
            totalPoint
          }
          salePicReview
          saleManagerReview
          isDealerConfirm
          dealerApproveAt
          shop {
            id
            name
            shopCode
            address
          }
          packages
          pictureURLs
          resizedPictureURLs
          reason
          requestStatus
          reviews {
            id
            classification
            createdAt
            description
          }
          classification
          requestStatus
          shopId
        }
      }
      shops_aggregate(
        where: {
          _and: [
            {
              _or: [
                {
                  _and: [
                    {
                      _not: {
                        acl_campaigns_2023: {
                          campaign: {
                            campaignType: { _eq: "RA_CAMPAIGN_2023" }
                          }
                        }
                      }
                    }
                    { dealerShops: { isOwner: { _eq: true } } }
                  ]
                }
                {
                  acl_campaigns_2023: {
                    campaign: { campaignType: { _eq: "RA_CAMPAIGN_2023" } }
                    requestStatus: { _in: $saleFilter }
                    salePicReview: { _in: $classification }
                    saleManagerReview: { _in: $saleManager }
                    isDealerConfirm: {_in: $dealerApproval}
                  }
                }
              ]
            }
            { dealerShops: {isOwner: { _eq: true }, dealer: {_and:[
              { branchId: { _in: $branch } },
              $payment
            ]} } }
            {
              acl_campaigns_2023: {
                campaign: { campaignType: { _eq: "RA_CAMPAIGN_2023" } }
                classification: { _in: $branchFilter }
                salePicReview: { _in: $classification }
              }
            }
            {
              _or: [
                { dealerShops: { shop: { name: { _ilike: $search } } } }
                { dealerShops: { shop: { shopCode: { _ilike: $search } } } }
                { dealerShops: { dealer: { name: { _ilike: $search } } } }
                { dealerShops: { dealer: { dealerCode: { _ilike: $search } } } }
              ]
            }
          ]
        }
      ) {
        aggregate {
          count
        }
      }
    }
  `,
  GET_LIST_SHOPS_NOT_JOIN_NOT_REIVEW: gql`
    query GET_LIST_SHOPS_NOT_JOIN_NOT_REIVEW(
      $search: String
      $status: [String!]
      $branch: [uuid!]
      $saleFilter: [String!]
      $branchFilter: [String!]
      $classification: [String!]
      $saleManager: [String!]
      $payment: dealers_bool_exp = {}
      $limit: Int
      $offset: Int
      $dealerApproval: [Boolean!]
    ) {
      shops(
        where: {
          _and: [
            {
              _or: [
                {
                  _and: [
                    {
                      _not: {
                        acl_campaigns_2023: {
                          campaign: {
                            campaignType: { _eq: "RA_CAMPAIGN_2023" }
                          }
                        }
                      }
                    }
                    { dealerShops: { isOwner: { _eq: true } } }
                  ]
                }
                {
                  acl_campaigns_2023: {
                    campaign: { campaignType: { _eq: "RA_CAMPAIGN_2023" } }
                    requestStatus: { _in: $saleFilter }
                    salePicReview: { _in: $classification }
                    saleManagerReview: { _in: $saleManager }
                    isDealerConfirm: {_in: $dealerApproval}
                  }
                }
              ]
            }
            { dealerShops: { isOwner: { _eq: true },dealer: {_and:[
              { branchId: { _in: $branch } },
              $payment
            ]} } }
            {
              _or: [
                {
                  acl_campaigns_2023: {
                    campaign: { campaignType: { _eq: "RA_CAMPAIGN_2023" } }
                    classification: { _in: $branchFilter }
                    salePicReview: { _in: $classification }
                  }
                }
                {
                  _not: {
                    acl_campaigns_2023: {
                      campaign: { campaignType: { _eq: "RA_CAMPAGIN_2023" } }
                      reviews: {}
                    }
                  }
                }
              ]
            }
            {
              _or: [
                { dealerShops: { shop: { name: { _ilike: $search } } } }
                { dealerShops: { shop: { shopCode: { _ilike: $search } } } }
                { dealerShops: { dealer: { name: { _ilike: $search } } } }
                { dealerShops: { dealer: { dealerCode: { _ilike: $search } } } }
              ]
            }
          ]
        }
        limit: $limit
        offset: $offset
        order_by: { updatedAt: desc }
      ) {
        name
        shopCode
        address
        id
        aclSpiralReviews(order_by: { updateTimes: desc }) {
          totalPoint
        }
        dealerShops(where: { isOwner: { _eq: true } }) {
          isOwner
          dealer {
            id
            name
            information2023 {
              requestedRewardReceiptURLs
              data
            }
            dealerCode
            role {
              alias
            }
          }
        }
        acl_campaigns_2023(
          where: { campaign: { campaignType: { _eq: "RA_CAMPAIGN_2023" } } }
        ) {
          id
          dealer {
            name
            id
            publicCode
            dealerCode
            taxNumber
            address
            ownerName
            ownerPosition
            shortName
            role {
              alias
            }
          }
          spiralReviews(order_by: { updateTimes: desc }) {
            totalPoint
          }
          salePicReview
          saleManagerReview
          isDealerConfirm
          dealerApproveAt
          shop {
            id
            name
            shopCode
            address
          }
          packages
          pictureURLs
          resizedPictureURLs
          reason
          requestStatus
          reviews {
            id
            classification
            createdAt
            description
          }
          classification
          requestStatus
          shopId
        }
      }
      shops_aggregate(
        where: {
          _and: [
            {
              _or: [
                {
                  _and: [
                    {
                      _not: {
                        acl_campaigns_2023: {
                          campaign: {
                            campaignType: { _eq: "RA_CAMPAIGN_2023" }
                          }
                        }
                      }
                    }
                    { dealerShops: { isOwner: { _eq: true } } }
                  ]
                }
                {
                  acl_campaigns_2023: {
                    campaign: { campaignType: { _eq: "RA_CAMPAIGN_2023" } }
                    requestStatus: { _in: $saleFilter }
                    salePicReview: { _in: $classification }
                    saleManagerReview: { _in: $saleManager }
                    isDealerConfirm: {_in: $dealerApproval}
                  }
                }
              ]
            }
            { dealerShops: {isOwner: { _eq: true },  dealer: {_and:[
              { branchId: { _in: $branch } },
              $payment
            ]} } }
            {
              _or: [
                {
                  acl_campaigns_2023: {
                    campaign: { campaignType: { _eq: "RA_CAMPAIGN_2023" } }
                    classification: { _in: $branchFilter }
                    salePicReview: { _in: $classification }
                  }
                }
                {
                  _not: {
                    acl_campaigns_2023: {
                      campaign: { campaignType: { _eq: "RA_CAMPAIGN_2023" } }
                      reviews: {}
                    }
                  }
                }
              ]
            }
            {
              _or: [
                { dealerShops: { shop: { name: { _ilike: $search } } } }
                { dealerShops: { shop: { shopCode: { _ilike: $search } } } }
                { dealerShops: { dealer: { name: { _ilike: $search } } } }
                { dealerShops: { dealer: { dealerCode: { _ilike: $search } } } }
              ]
            }
          ]
        }
      ) {
        aggregate {
          count
        }
      }
    }
  `,
  GET_LIST_SUB: gql`
    query GET_LIST_SUB(
      $search: String
      $status: [String!]
      $branch: [uuid!]
      $saleFilter: [String!]
      $branchFilter: [String!]
      $classification: [String!]
      $saleManager: [String!]
      $payment: dealers_bool_exp = {}
      $limit: Int
      $offset: Int
      $dealerApproval: [Boolean!]
    ) {
      dealers(
        where: {
          assignedSubDealers2023View: {
              dealerId: { _is_null: false }
              status: { _eq: "active" }
            }
          role: {
            alias: { _eq: "sub_dealer" }
          }
          status: { _eq: "active" }
          _and: [
            $payment,
            {
              acl_campaigns_2023: {
                campaign: { campaignType: { _eq: "RA_CAMPAIGN_2023" } }
                requestStatus: { _in: $saleFilter }
                salePicReview: { _in: $classification }
                saleManagerReview: { _in: $saleManager }
                isDealerConfirm: {_in: $dealerApproval}
              }
            }
            { branchId: { _in: $branch } }
            {
              acl_campaigns_2023: {
                campaign: { campaignType: { _eq: "RA_CAMPAIGN_2023" } }
                classification: { _in: $branchFilter }
                salePicReview: { _in: $classification }
              }
            }
            {
              _or: [
                { name: { _ilike: $search } }
                { dealerCode: { _ilike: $search } }
              ]
            }
          ]
        }
        limit: $limit
        offset: $offset
        order_by: { updatedAt: desc }
      ) {
        name
        dealerCode
        id
        address
        information2023 {
          productReceiptPicURLs
          data
        }
        aclSpiralReviews(order_by: { updateTimes: desc }) {
          totalPoint
        }
        assignedSubDealers2023View {
          dealer {
            name
            dealerCode
          }
          dealerView {
            name
            dealerCode
          }
        }
        acl_campaigns_2023(
          where: { campaign: { campaignType: { _eq: "RA_CAMPAIGN_2023" } } }
        ) {
          id
          dealer {
            id
            name
            publicCode
            dealerCode
            taxNumber
            address
            ownerName
            ownerPosition
            shortName
            role {
              alias
            }
          }
          spiralReviews(order_by: { updateTimes: desc }) {
            totalPoint
          }
          salePicReview
          saleManagerReview
          isDealerConfirm
          dealerApproveAt
          shop {
            id
            name
            shopCode
            address
          }
          packages
          pictureURLs
          resizedPictureURLs
          reason
          requestStatus
          reviews {
            id
            classification
            createdAt
            description
          }
          classification
          requestStatus
          shopId
        }
      }
      dealers_aggregate(
        where: {
          assignedSubDealers2023View: {
            dealerId: { _is_null: false }
            status: { _eq: "active" }
          }
          role: { alias: { _eq: "sub_dealer" } }
          status: { _eq: "active" }
          _and: [
            $payment,
            {
              acl_campaigns_2023: {
                campaign: { campaignType: { _eq: "RA_CAMPAIGN_2023" } }
                requestStatus: { _in: $saleFilter }
                salePicReview: { _in: $classification }
                saleManagerReview: { _in: $saleManager }
                isDealerConfirm: {_in: $dealerApproval}
              }
            }
            { branchId: { _in: $branch } }
            {
              acl_campaigns_2023: {
                campaign: { campaignType: { _eq: "RA_CAMPAIGN_2023" } }
                classification: { _in: $branchFilter }
                salePicReview: { _in: $classification }
              }
            }
            {
              _or: [
                { name: { _ilike: $search } }
                { dealerCode: { _ilike: $search } }
              ]
            }
          ]
        }
      ) {
        aggregate {
          count
        }
      }
    }
  `,
  GET_LIST_SUB_NOT_JOIN: gql`
    query GET_LIST_SUB_NOT_JOIN(
      $search: String
      $status: [String!]
      $branch: [uuid!]
      $saleFilter: [String!]
      $branchFilter: [String!]
      $classification: [String!]
      $saleManager: [String!]
      $payment: dealers_bool_exp = {}
      $limit: Int
      $offset: Int
      $dealerApproval: [Boolean!]
    ) {
      dealers(
        where: {
          assignedSubDealers2023View: {
            dealerId: { _is_null: false }
            status: { _eq: "active" }
          }
          role: { alias: { _eq: "sub_dealer" } }
          status: { _eq: "active" }
          _and: [
            $payment,
            {
              _or: [
                {
                  _and: [
                    {
                      _not: {
                        acl_campaigns_2023: {
                          campaign: {
                            campaignType: { _eq: "RA_CAMPAIGN_2023" }
                          }
                        }
                      }
                    }
                  ]
                }
                {
                  acl_campaigns_2023: {
                    campaign: { campaignType: { _eq: "RA_CAMPAIGN_2023" } }
                    requestStatus: { _in: $saleFilter }
                    salePicReview: { _in: $classification }
                    saleManagerReview: { _in: $saleManager }
                    isDealerConfirm: {_in: $dealerApproval}
                  }
                }
              ]
            }
            { branchId: { _in: $branch } }
            {
              acl_campaigns_2023: {
                campaign: { campaignType: { _eq: "RA_CAMPAIGN_2023" } }
                classification: { _in: $branchFilter }
                salePicReview: { _in: $classification }
                saleManagerReview: { _in: $saleManager }
                isDealerConfirm: {_in: $dealerApproval}
              }
            }
            {
              _or: [
                { name: { _ilike: $search } }
                { dealerCode: { _ilike: $search } }
              ]
            }
          ]
        }
        limit: $limit
        offset: $offset
        order_by: { updatedAt: desc }
      ) {
        name
        dealerCode
        id
        address
        information2023 {
          productReceiptPicURLs
          data
        }
        aclSpiralReviews(order_by: { updateTimes: desc }) {
          totalPoint
        }
        assignedSubDealers2023View {
          dealer {
            id
            name
            dealerCode
          }
          dealerView {
            name
            dealerCode
          }
        }
        acl_campaigns_2023(
          where: { campaign: { campaignType: { _eq: "RA_CAMPAIGN_2023" } } }
        ) {
          id
          dealer {
            id
            name
            publicCode
            dealerCode
            taxNumber
            address
            ownerName
            ownerPosition
            shortName
            role {
              alias
            }
          }
          spiralReviews(order_by: { updateTimes: desc }) {
            totalPoint
          }
          salePicReview
          saleManagerReview
          isDealerConfirm
          dealerApproveAt
          shop {
            id
            name
            shopCode
            address
          }
          packages
          pictureURLs
          resizedPictureURLs
          reason
          requestStatus
          reviews {
            id
            classification
            createdAt
            description
          }
          classification
          requestStatus
          shopId
        }
      }
      dealers_aggregate(
        where: {
          assignedSubDealers2023View: {
            dealerId: { _is_null: false }
            status: { _eq: "active" }
          }
          role: { alias: { _eq: "sub_dealer" } }
          status: { _eq: "active" }
          _and: [
            $payment,
            {
              _or: [
                {
                  _and: [
                    {
                      _not: {
                        acl_campaigns_2023: {
                          campaign: {
                            campaignType: { _eq: "RA_CAMPAIGN_2023" }
                          }
                        }
                      }
                    }
                  ]
                }
                {
                  acl_campaigns_2023: {
                    campaign: { campaignType: { _eq: "RA_CAMPAIGN_2023" } }
                    requestStatus: { _in: $saleFilter }
                    salePicReview: { _in: $classification }
                    saleManagerReview: { _in: $saleManager }
                    isDealerConfirm: {_in: $dealerApproval}
                  }
                }
              ]
            }
            { branchId: { _in: $branch } }
            {
              acl_campaigns_2023: {
                campaign: { campaignType: { _eq: "RA_CAMPAIGN_2023" } }
                classification: { _in: $branchFilter }
                salePicReview: { _in: $classification }
                saleManagerReview: { _in: $saleManager }
                isDealerConfirm: {_in: $dealerApproval}
              }
            }
            {
              _or: [
                { name: { _ilike: $search } }
                { dealerCode: { _ilike: $search } }
              ]
            }
          ]
        }
      ) {
        aggregate {
          count
        }
      }
    }
  `,
  GET_LIST_SUB_NOT_JOIN_NOT_REIVEW: gql`
    query GET_LIST_SUB_NOT_JOIN_NOT_REIVEW(
      $search: String
      $status: [String!]
      $branch: [uuid!]
      $saleFilter: [String!]
      $branchFilter: [String!]
      $classification: [String!]
      $saleManager: [String!]
      $payment: dealers_bool_exp = {}
      $limit: Int
      $offset: Int
      $dealerApproval: [Boolean!]
    ) {
      dealers(
        where: {
          assignedSubDealers2023View: {
            dealerId: { _is_null: false }
            status: { _eq: "active" }
          }
          role: { alias: { _eq: "sub_dealer" } }
          status: { _eq: "active" }
          _and: [
            $payment,
            {
              _or: [
                {
                  _not: {
                    acl_campaigns_2023: {
                      campaign: { campaignType: { _eq: "RA_CAMPAIGN_2023" } }
                    }
                  }
                }
                {
                  acl_campaigns_2023: {
                    campaign: { campaignType: { _eq: "RA_CAMPAIGN_2023" } }
                    requestStatus: { _in: $saleFilter }
                    salePicReview: { _in: $classification }
                    saleManagerReview: { _in: $saleManager }
                    isDealerConfirm: {_in: $dealerApproval}
                  }
                }
              ]
            }
            { branchId: { _in: $branch } }
            {
              _or: [
                {
                  acl_campaigns_2023: {
                    campaign: { campaignType: { _eq: "RA_CAMPAIGN_2023" } }
                    classification: { _in: $branchFilter }
                    salePicReview: { _in: $classification }
                    saleManagerReview: { _in: $saleManager }
                  }
                }
                {
                  _not: {
                    acl_campaigns_2023: {
                      campaign: { campaignType: { _eq: "RA_CAMPAIGN_2023" } }
                      reviews: {}
                    }
                  }
                }
              ]
            }
            {
              _or: [
                { name: { _ilike: $search } }
                { dealerCode: { _ilike: $search } }
              ]
            }
          ]
        }
        limit: $limit
        offset: $offset
        order_by: { updatedAt: desc }
      ) {
        name
        address
        information2023 {
          productReceiptPicURLs
          data
        }
        dealerCode
        id
        aclSpiralReviews(order_by: { updateTimes: desc }) {
          totalPoint
        }
        role {
          alias
        }
        assignedSubDealers2023View {
          dealer {
            id
            name
            dealerCode
          }
          dealerView {
            name
            dealerCode
          }
        }
        acl_campaigns_2023(
          where: { campaign: { campaignType: { _eq: "RA_CAMPAIGN_2023" } } }
        ) {
          id
          dealer {
            name
            id
            publicCode
            dealerCode
            taxNumber
            address
            ownerName
            ownerPosition
            shortName
            role {
              alias
            }
          }
          spiralReviews(order_by: { updateTimes: desc }) {
            totalPoint
          }
          salePicReview
          saleManagerReview
          isDealerConfirm
          dealerApproveAt
          shop {
            id
            name
            shopCode
            address
          }
          packages
          pictureURLs
          resizedPictureURLs
          reason
          requestStatus
          reviews {
            id
            classification
            createdAt
            description
          }
          classification
          requestStatus
          shopId
        }
      }
      dealers_aggregate(
        where: {
          assignedSubDealers2023View: {
            dealerId: { _is_null: false }
            status: { _eq: "active" }
          }
          role: { alias: { _eq: "sub_dealer" } }
          status: { _eq: "active" }
          _and: [
            $payment,
            {
              _or: [
                {
                  _not: {
                    acl_campaigns_2023: {
                      campaign: { campaignType: { _eq: "RA_CAMPAIGN_2023" } }
                    }
                  }
                }
                {
                  acl_campaigns_2023: {
                    campaign: { campaignType: { _eq: "RA_CAMPAIGN_2023" } }
                    requestStatus: { _in: $saleFilter }
                    salePicReview: { _in: $classification }
                    saleManagerReview: { _in: $saleManager }
                    isDealerConfirm: {_in: $dealerApproval}
                  }
                }
              ]
            }
            { branchId: { _in: $branch } }
            {
              _or: [
                {
                  acl_campaigns_2023: {
                    campaign: { campaignType: { _eq: "RA_CAMPAIGN_2023" } }
                    classification: { _in: $branchFilter }
                    salePicReview: { _in: $classification }
                    saleManagerReview: { _in: $saleManager }
                    isDealerConfirm: {_in: $dealerApproval}
                  }
                }
                {
                  _not: {
                    acl_campaigns_2023: {
                      campaign: { campaignType: { _eq: "RA_CAMPAIGN_2023" } }
                      reviews: {}
                    }
                  }
                }
              ]
            }
            {
              _or: [
                { name: { _ilike: $search } }
                { dealerCode: { _ilike: $search } }
              ]
            }
          ]
        }
      ) {
        aggregate {
          count
        }
      }
    }
  `,
  GET_LIST_SUB_NOT_REVIEW: gql`
    query GET_LIST_SUB_NOT_REVIEW(
      $search: String
      $status: [String!]
      $branch: [uuid!]
      $saleFilter: [String!]
      $branchFilter: [String!]
      $classification: [String!]
      $saleManager: [String!]
      $payment: dealers_bool_exp = {}
      $limit: Int
      $offset: Int
      $dealerApproval: [Boolean!]
    ) {
      dealers(
        where: {
          assignedSubDealers2023View: {
            dealerId: { _is_null: false }
            status: { _eq: "active" }
          }
          role: { alias: { _eq: "sub_dealer" } }
          status: { _eq: "active" }
          _and: [
            $payment,
            {
              acl_campaigns_2023: {
                campaign: { campaignType: { _eq: "RA_CAMPAIGN_2023" } }
                requestStatus: { _in: $saleFilter }
                salePicReview: { _in: $classification }
                saleManagerReview: { _in: $saleManager }
                isDealerConfirm: {_in: $dealerApproval}
              }
            }
            { branchId: { _in: $branch } }
            {
              _or: [
                {
                  acl_campaigns_2023: {
                    campaign: { campaignType: { _eq: "RA_CAMPAIGN_2023" } }
                    classification: { _in: $branchFilter }
                    salePicReview: { _in: $classification }
                    saleManagerReview: { _in: $saleManager }
                  }
                }
                {
                  _not: {
                    acl_campaigns_2023: {
                      campaign: { campaignType: { _eq: "RA_CAMPAIGN_2023" } }
                      reviews: {}
                    }
                  }
                }
              ]
            }
            {
              _or: [
                { name: { _ilike: $search } }
                { dealerCode: { _ilike: $search } }
              ]
            }
          ]
        }
        limit: $limit
        offset: $offset
        order_by: { updatedAt: desc }
      ) {
        name
        address
        information2023 {
          productReceiptPicURLs
          data
        }
        id
        aclSpiralReviews(order_by: { updateTimes: desc }) {
          totalPoint
        }
        dealerCode
        role {
          alias
        }
        assignedSubDealers2023View {
          dealer {
            id
            name
            dealerCode
          }
          dealerView {
            name
            dealerCode
          }
        }
        acl_campaigns_2023(
          where: { campaign: { campaignType: { _eq: "RA_CAMPAIGN_2023" } } }
        ) {
          id
          dealer {
            name
            id
            publicCode
            dealerCode
            taxNumber
            address
            ownerName
            ownerPosition
            shortName
            role {
              alias
            }
          }
          spiralReviews(order_by: { updateTimes: desc }) {
            totalPoint
          }
          salePicReview
          saleManagerReview
          isDealerConfirm
          dealerApproveAt
          shop {
            id
            name
            shopCode
            address
          }
          packages
          pictureURLs
          resizedPictureURLs
          reason
          requestStatus
          reviews {
            id
            classification
            createdAt
            description
          }
          classification
          requestStatus
          shopId
        }
      }
      dealers_aggregate(
        where: {
          assignedSubDealers2023View: {
            dealerId: { _is_null: false }
            status: { _eq: "active" }
          }
          role: { alias: { _eq: "sub_dealer" } }
          status: { _eq: "active" }
          _and: [
            $payment,
            {
              acl_campaigns_2023: {
                campaign: { campaignType: { _eq: "RA_CAMPAIGN_2023" } }
                requestStatus: { _in: $saleFilter }
                salePicReview: { _in: $classification }
                saleManagerReview: { _in: $saleManager }
                isDealerConfirm: {_in: $dealerApproval}
              }
            }
            { branchId: { _in: $branch } }
            {
              _or: [
                {
                  acl_campaigns_2023: {
                    campaign: { campaignType: { _eq: "RA_CAMPAIGN_2023" } }
                    classification: { _in: $branchFilter }
                    salePicReview: { _in: $classification }
                    saleManagerReview: { _in: $saleManager }
                  }
                }
                {
                  _not: {
                    acl_campaigns_2023: {
                      campaign: { campaignType: { _eq: "RA_CAMPAIGN_2023" } }
                      reviews: {}
                    }
                  }
                }
              ]
            }
            {
              _or: [
                { name: { _ilike: $search } }
                { dealerCode: { _ilike: $search } }
              ]
            }
          ]
        }
      ) {
        aggregate {
          count
        }
      }
    }
  `,
  GET_SUB_DEALER_CAMPAIGN_DETAIL: gql`
    query GET_SUB_DEALER_CAMPAIGN_DETAIL($id: uuid!) {
      dealers(where: { id: { _eq: $id } }) {
        address
        email
        name
        phoneNumber
        dealerCode
        idCard
        idCardImage
        ownerName
        aclSpiralReviews(order_by: { updateTimes: asc }) {
          name
          code
          checkInAt
          checkOutAt
          reason
          totalPoint
          displayModel
          requestDisplay
          images
          updateTimes
          visitStatus
          visitReason
        }
        information2023 {
          businessHouseholdNo
          businessHouseholdPicURLs
          businessRegistrationNo
          businessRegistrationPicURLs
          productReceiptPicURLs
          requestedRewardReceiptURLs
          data
        }
        bankBranch
        bankName
        accountName
        accountNumber
        assignedSubDealers2023View {
          dealer {
            name
            dealerCode
          }
          dealerView {
            name
            dealerCode
          }
        }
        acl_campaigns_2023(
          where: { campaign: { campaignType: { _eq: "RA_CAMPAIGN_2023" } } }
        ) {
          id
          requestReviews_aggregate(where: {status: {_eq: "active"},
          request: {status: {_nin: ["canceled"]}}}) {
            aggregate {count}
          }
          requestReviews(order_by: {createdAt: desc}) {
            id
            request {
              id
              createdAt
              requestName
              comment
            }
            createdAt
            creator {
              name
              role {
                name
              }
            }
            updatedAt
            updater {
              name
              role {
                name
              }
            }
            status
            removedReason
          }
          dealer {
            id
            name
          }
          spiralReviews(order_by: { updateTimes: asc }) {
            name
            code
            checkInAt
            checkOutAt
            reason
            totalPoint
            displayModel
            requestDisplay
            images
            updateTimes
            visitStatus
            visitReason
          }
          packages
          pictureURLs
          requestStatus
          classification
          model1
          model2
          model3
          quantityModel1
          quantityModel2
          quantityModel3
          requestReUpCount
          isReview
          isDealerReUp
          reviews(order_by: {createdAt: desc}) {
            createdAt
            createdUser {
              name
              role {
                name
              }
            }

            classification
            updatedAt
            updatedUser {
              name
            }
            description
          }
          updatedAt
          createdAt
          logs(order_by: { createdAt: desc }) {
            requestStatus
            submittedAt
            updatedAt
            createdUser {
              name
            }
            reason
            dealer {
              id
              name
            }
          }
        }
      }
    }
  `,
  SUBMIT_REVIEW: gql`
    mutation SUBMIT_REVIEW(
      $id: uuid
      $review: String
      $createdBy: uuid
      $qualify: String!
      $force: Boolean!
    ) {
      update_acl_campaigns_2023(
        where: { id: { _eq: $id } }
        _set: {
          updatedBy: $createdBy
          saleStaffReview: $qualify
          reviewerSaleStaff: $createdBy
          classification: $qualify
        }
      ) {
        affected_rows
        __typename
      }
      insert_acl_campaign_reviews_2023(
        objects: {
          campaignId: $id
          description: $review
          classification: $qualify
          forceConfirmationCode: $force
          createdBy: $createdBy
          updatedBy: $createdBy
        }
      ) {
        affected_rows
      }
    }
  `,
  REQUEST_REUP: gql`
    mutation REQUEST_REUP(
      $ids: [uuid!]
      $action: String!
      $reason: String
      $updatedBy: uuid!
    ) {
      update_acl_campaigns_2023(
        where: { id: { _in: $ids } }
        _set: {
          requestStatus: $action
          reason: $reason
          updatedBy: $updatedBy
          isReview: true
          isDealerReUp: false
          classification: null
          reviewerSaleStaff: null
          saleStaffReview: "not_review"
          salePicReview: "not_review"
          saleManagerReview: "not_review"
          isRemoved: false
        }
      ) {
        affected_rows
      }
    }
  `,
  GET_SHOP: gql`
    query GET_SHOP_CAMPAIGN_DETAIL($id: uuid!) {
      shops(where: { id: { _eq: $id } }) {
        address
        email
        name
        shopCode
        phoneNumber
        aclSpiralReviews(order_by: { updateTimes: asc }) {
          name
          code
          checkInAt
          checkOutAt
          reason
          totalPoint
          displayModel
          requestDisplay
          images
          updateTimes
          visitStatus
          visitReason
        }
        dealerShops(where: { isOwner: { _eq: true } }) {
          isOwner
          contractStatus
          dealer {
            id
            role {
              alias
            }
            phoneNumber
            name
            dealerCode
            information2023 {
              requestedRewardReceiptURLs
              data
            }
          }
        }
        acl_campaigns_2023(
          where: { campaign: { campaignType: { _eq: "RA_CAMPAIGN_2023" } } }
        ) {
          dealer {
            id
            name
          }
          id
          requestReviews_aggregate(where: {status: {_eq: "active"},
          request: {status: {_nin: ["canceled"]}}}) {
            aggregate {count}
          }
          requestReviews(order_by: {createdAt: desc}) {
            request {
              id
              createdAt
              updatedAt
              requestName
              comment
            }
            createdAt
            updatedAt
            updater {
              name
              role {
                name
              }
            }
            creator {
              name
              role {
                name
              }
            }
            status
            removedReason
          }
          spiralReviews(order_by: { updateTimes: asc }) {
            name
            code
            checkInAt
            checkOutAt
            reason
            totalPoint
            displayModel
            requestDisplay
            images
            updateTimes
            visitStatus
            visitReason
          }
          packages
          pictureURLs
          requestStatus
          confirmationCode
          classification
          model1
          model2
          model3
          quantityModel1
          quantityModel2
          quantityModel3
          requestReUpCount
          isReview
          isDealerReUp
          reviews(order_by: {createdAt: desc}) {
            createdAt
            forceConfirmationCode
            createdUser {
              name
              role {
                name
              }
            }
            classification
            updatedAt
            updatedUser {
              name
            }
            description
          }
          updatedAt
          createdAt
          logs(order_by: { createdAt: desc }) {
            requestStatus
            submittedAt
            updatedAt
            createdUser {
              name
            }
            reason
            dealer {
              name
            }
          }
        }
      }
    }
  `,
  GET_ACTION_HISTORY_2023: gql`
    subscription GET_ACTION_HISTORY_2023($id: uuid!) {
      acl_campaign_status_logs_2023(
        order_by: { createdAt: desc }
        where: { campaignId: { _eq: $id } }
      ) {
        requestStatus
        submittedAt
        updatedAt
        createdUser {
          name
        }
        reason
        dealer {
          name
        }
        dealerView {
          name
        }
      }
    }
  `,
  UPDATE_PACKAGE: gql`
    mutation UPDATE_PACKAGE($id: String!, $models: RACampaignModels) {
      updateRAModels(form: { id: $id, models: $models })
    }
  `,
  GET_QUALIFIED_REQUEST: gql`
    query GET_QUALIFIED_REQUEST(
      $sales: [uuid!]
      $limit: Int
      $offset: Int
      $salesStaff: [String!] = ["qualified", "non_qualified"]
      $search: [acl_campaigns_2023_view_bool_exp!] = [{}]
      $salesPic: [String!] = ["not_review"]
      $salesPlanning: [String!]
      $saleManager: [String!]
      $remove: [Boolean!]
      $type: [String!]
    ) {
      acl_campaigns_2023_view(
        where: {
          reviews: { createdBy: { _in: $sales } }
          classification: { _in: $salesStaff }
          salePicReview: { _in: $salesPic }
          saleManagerReview: { _in: $saleManager }
          salePlanningReview: { _in: $salesPlanning }
          isRemoved: { _in: $remove }
          _or: $search
          dealerView: {
            _or: [
              { role: { alias: { _eq: "daikin_dealer" } } }
              {
                role: { alias: { _eq: "sub_dealer" } }
                assignedSubDealers2023View: { status: { _eq: "active" } }
              }
            ]
            role: { alias: { _in: $type } }
          }
        }
        limit: $limit
        offset: $offset
        order_by: { updatedAt: desc }
      ) {
        id
        classification
        isRemoved
        salePicReview
        saleManagerReview
        requestReviews(where: {status: {_eq: "deleted"}},order_by: {createdAt: desc}) {
          removedReason
          status
          creator {
            name
            role {
              alias
              name
            }
          }
          updater {
            name
          }
        }
        dealerView {
          information2023 {
            requestedRewardReceiptURLs
            productReceiptPicURLs
            data
          }
          id
          name
          address
          aclSpiralReviews(order_by: { updateTimes: desc }) {
            totalPoint
          }
          role {
            alias
          }
          assignedSubDealers2023View {
            dealerView {
              id
              name
              dealerCode
            }
          }
          dealerCode
        }
        shop {
          id
        }
        reviews {
          id
          createdAt
          updatedAt
          description
        }
        shop {
          id
          name
          address
          shopCode
          aclSpiralReviews(order_by: { updateTimes: desc }) {
            totalPoint
          }
        }
        requestStatus
      }
      acl_campaigns_2023_view_aggregate(
        where: {
          reviews: { createdBy: { _in: $sales } }
          classification: { _in: $salesStaff }
          salePicReview: { _in: $salesPic }
          saleManagerReview: { _in: $saleManager }
          salePlanningReview: { _in: $salesPlanning }
          isRemoved: { _in: $remove }
          _or: $search
          dealerView: {
            _or: [
              { role: { alias: { _eq: "daikin_dealer" } } }
              {
                role: { alias: { _eq: "sub_dealer" } }
                assignedSubDealers2023View: { status: { _eq: "active" } }
              }
            ]
            role: { alias: { _in: $type } }
          }
        }
      ) {
        aggregate {
          count
        }
      }
    }
  `,
  GET_HISTORY_LOG: gql`
    query GET_HISTORY_LOG($requestId: uuid!, $roles: [String!]) {
      acl_request_logs(
        where: {
          requestId: { _eq: $requestId }
          creator: { role: { alias: { _in: $roles } } }
        }
        order_by: { createdAt: desc }
      ) {
        createdAt
        id
        description
        creator {
          name
          avatar
          role {
            name
          }
        }
      }
    }
  `,
  END_CAMPAIGN: gql`
    mutation END_CAMPAIGN($id: uuid!) {
      update_campaigns(
        where: { aclConfig: { _eq: $id } }
        _set: { isEnded: true }
      ) {
        affected_rows
      }
    }
  `,
  GET_MASK_CONFIG: gql`
    query GET_MASK_CONFIG($id: uuid!) {
      campaigns(where: { aclConfig: { _eq: $id } }) {
        isSpecial
        isEnded
        startDate
        endDate
      }
    }
  `,
  MASK_CHANGE: gql`
    mutation MASK_CHANGE($id: uuid!, $mask: Boolean!) {
      update_campaigns(
        where: { aclConfig: { _eq: $id } }
        _set: { isSpecial: $mask }
      ) {
        affected_rows
      }
    }
  `,
  GET_DEALER_BY_BRANCH: gql`
    query GET_DEALER_BY_BRANCH($name: uuid!) {
      joinedSub: acl_campaigns_2023_aggregate(
        where: {
          dealer: {
            branch: { isTest: { _eq: false }, id: {_eq: $name} }
            role: { alias: { _eq: "sub_dealer" } }
          }
          campaign: { campaignType: { _eq: "RA_CAMPAIGN_2023" } }
        }
      ) {
        aggregate {
          count(columns: dealerId, distinct: true)
        }
      }
      joinedShops: acl_campaigns_2023_aggregate(
        where: {
          dealer: { branch: { isTest: { _eq: false }, id: {_eq: $name} } }
          campaign: { campaignType: { _eq: "RA_CAMPAIGN_2023" } }
        }
      ) {
        aggregate {
          count(columns: shopId, distinct: true)
        }
      }
      totalSubDealers: dealers_aggregate(
        where: {
          role: { alias: { _eq: "sub_dealer" } }
          assignedSubDealers2023View: { dealerId: {_is_null: false},
          status: {_eq: "active"} }
          branch: { isTest: { _eq: false }, id: {_eq: $name}  }
          status: { _eq: "active" }
        }
      ) {
        aggregate {
          count(columns: id, distinct: true)
        }
      }
      totalShops: shops_aggregate(
        where: { dealerShops: { isOwner: { _eq: true },
        dealer: {branch: {isTest: { _eq: false }, id: {_eq: $name}}} } }
      ) {
        aggregate {
          count(columns: id, distinct: true)
        }
      }
    }
  `,
  GET_STATISTIC: gql`
    query GET_STATISTIC {
      joinedDealers: acl_campaigns_2023_aggregate(
        where: {
          dealer: {
            branch: { isTest: { _eq: false } }
            role: { alias: { _eq: "daikin_dealer" } }
          }
          campaign: { campaignType: { _eq: "RA_CAMPAIGN_2023" } }
        }
      ) {
        aggregate {
          count(columns: dealerId, distinct: true)
        }
      }
      totalDealers: dealers_aggregate(
        where: {
          role: { alias: { _eq: "daikin_dealer" } }
          branch: { isTest: { _eq: false } }
          status: { _eq: "active" }
        }
      ) {
        aggregate {
          count(columns: id, distinct: true)
        }
      }
      joinedSubDealers: acl_campaigns_2023_aggregate(
        where: {
          dealer: {
            branch: { isTest: { _eq: false } }
            role: { alias: { _eq: "sub_dealer" } }
          }
          campaign: { campaignType: { _eq: "RA_CAMPAIGN_2023" } }
        }
      ) {
        aggregate {
          count(columns: dealerId, distinct: true)
        }
      }
      totalSubDealers: dealers_aggregate(
        where: {
          role: { alias: { _eq: "sub_dealer" } }
          assignedSubDealers2023View: { dealerId: {_is_null: false},
          status: {_eq: "active"} }
          branch: { isTest: { _eq: false } }
          status: { _eq: "active" }
        }
      ) {
        aggregate {
          count(columns: id, distinct: true)
        }
      }
      joinedShops: acl_campaigns_2023_aggregate(
        where: {
          dealer: { branch: { isTest: { _eq: false } } }
          campaign: { campaignType: { _eq: "RA_CAMPAIGN_2023" } }
        }
      ) {
        aggregate {
          count(columns: shopId, distinct: true)
        }
      }
      totalShops: shops_aggregate(
        where: { dealerShops: { isOwner: { _eq: true } } }
      ) {
        aggregate {
          count(columns: id, distinct: true)
        }
      }
      totalCompleted: acl_campaigns_2023_aggregate(
        where: {
          dealer: { branch: { isTest: { _eq: false } } }
          campaign: { campaignType: { _eq: "RA_CAMPAIGN_2023" } }
          requestStatus: { _in: ["approved", "rejected"] }
        }
      ) {
        aggregate {
          count
        }
      }
      totalIncompleted: acl_campaigns_2023_aggregate(
        where: {
          dealer: { branch: { isTest: { _eq: false } } }
          campaign: { campaignType: { _eq: "RA_CAMPAIGN_2023" } }
          requestStatus: { _nin: ["approved", "rejected"] }
        }
      ) {
        aggregate {
          count
        }
      }
      totalRejected: acl_campaigns_2023_aggregate(
        where: {
          dealer: { branch: { isTest: { _eq: false } } }
          campaign: { campaignType: { _eq: "RA_CAMPAIGN_2023" } }
          requestStatus: { _eq: "rejected" }
        }
      ) {
        aggregate {
          count
        }
      }
      requests: acl_campaign_status_logs_2023(
        where: {
          aclCampaign: {
            campaign: { campaignType: { _eq: "RA_CAMPAIGN_2023" } }
            dealer: { branch: { isTest: { _eq: false } } }
          }
        }
      ) {
        requestStatus
        createdAt
        updatedAt
        campaignId
        dealer {
          role {
            alias
          }
        }
      }
      config: acl_campaign_configurations {
        uploadStartDate
        uploadEndDate
      }
      configCampaign: campaigns(
        where: { campaignType: { _eq: "RA_CAMPAIGN_2023" } }
      ) {
        aclCampaignConfig {
          id
          uploadStartDate
          uploadEndDate
        }
      }
      modelFTKY: acl_campaigns_2023_aggregate(
        where: {
          dealer: { branch: { isTest: { _eq: false } } }
          campaign: { campaignType: { _eq: "RA_CAMPAIGN_2023" } }
          _or: [
            { model1: { _ilike: "FTKY%" } }
          ]
        }
      ) {
        aggregate {
          sum {
            quantityModel1
          }
        }
      }
      modelFTXV: acl_campaigns_2023_aggregate(
        where: {
          dealer: { branch: { isTest: { _eq: false } } }
          campaign: { campaignType: { _eq: "RA_CAMPAIGN_2023" } }
          _or: [
            { model1: { _ilike: "FTXV%" } }
          ]
        }
      ) {
        aggregate {
          sum {
            quantityModel1
          }
        }
      }
      modelFTKZ: acl_campaigns_2023_aggregate(
        where: {
          dealer: { branch: { isTest: { _eq: false } } }
          campaign: { campaignType: { _eq: "RA_CAMPAIGN_2023" } }
          _or: [
            { model1: { _ilike: "FTKZ%" } }
          ]
        }
      ) {
        aggregate {
          sum {quantityModel1}
        }
      }
      modelFTHF: acl_campaigns_2023_aggregate(
        where: {
          dealer: { branch: { isTest: { _eq: false } } }
          campaign: { campaignType: { _eq: "RA_CAMPAIGN_2023" } }
          _or: [
            { model2: { _ilike: "FTHF%" } }
          ]
        }
      ) {
        aggregate {
          sum {
            quantityModel2
          }
        }
      }
      modelFTKF: acl_campaigns_2023_aggregate(
        where: {
          dealer: { branch: { isTest: { _eq: false } } }
          campaign: { campaignType: { _eq: "RA_CAMPAIGN_2023" } }
          _or: [
            { model2: { _ilike: "FTKF%" } }
          ]
        }
      ) {
        aggregate {
          sum {
            quantityModel2
          }
        }
      }
      modelFTKB: acl_campaigns_2023_aggregate(
        where: {
          dealer: { branch: { isTest: { _eq: false } } }
          campaign: { campaignType: { _eq: "RA_CAMPAIGN_2023" } }
          _or: [
            { model2: { _ilike: "FTKB%" } }
          ]
        }
      ) {
        aggregate {
          sum {quantityModel2}
        }
      }
      otherModel: acl_campaigns_2023_aggregate(
        where: {
          dealer: { branch: { isTest: { _eq: false } } }
          campaign: { campaignType: { _eq: "RA_CAMPAIGN_2023" } }
        }
      ) {
        aggregate {
          sum {quantityModel3}
        }
      }
      labelBranch: branches(
        where: { isTest: { _eq: false }, status: { _eq: "active" } }
      ) {
        id
        name
      }
    }
  `,
  EXPORT_STATISTIC: gql`
    mutation EXPORT_STATISTIC($id: uuid!, $filter: String!, $form: jsonb) {
      insert_exports(
        objects: { createdBy: $id, type: $filter, isReIndex: true, filter: $form }
      ) {
        affected_rows
        returning {
          id
        }
      }
    }
  `,
  GET_LIST_MANAGE_EXPORT: gql`
    query GetManageExport($type: exports_bool_exp!, $limit: Int!, $offset: Int!) {
      exports(
        where: $type
        limit: $limit
        offset: $offset
        order_by: { createdAt: desc }
      ) {
        createdAt
        createdBy
        exportStatus
        failedReasons
        id
        status
        url
        createdUser {
          name
        }
      }
      exports_aggregate(
        where: $type
      ) {
        aggregate {
          count
        }
      }
    }
  `,
  GET_SALE_STAFF_REVIEW_SAME_GROUP: gql`
    query getSaleStaffReviewSameGroup(
      $filter: acl_campaigns_2023_view_bool_exp!
    ) {
      acl_campaigns_2023_view(where: $filter, distinct_on: reviewerSaleStaff) {
        saleStaff {
          id
          name
        }
      }
    }
  `,
  GET_PDF_EXPORT_DATA: gql`
    query getDetailCampaignById($id: uuid!) {
      acl_campaigns_2023(
        where: {
          id: { _eq: $id }
          campaign: { campaignType: { _eq: "RA_CAMPAIGN_2023" } }
        }
      ) {
        campaign {
          campaignCode
          aclCampaignConfig {
            reviewEndDate
            reviewStartDate
            uploadStartDate
            uploadEndDate
          }
          target
          title
          condition {
            description
            name
          }
          dealerRoles
          metadata
        }
        dealer {
          name
          publicCode
          dealerCode
          taxNumber
          address
          ownerName
          ownerPosition
          shortName
          information2023 {
            productReceiptPicURLs
          }
          role {
            alias
          }
          assignedSubDealers2023(where: { status: { _eq: "active" } }) {
            dealer {
              name
              dealerCode
              taxNumber
              ownerName
              ownerPosition
              address
            }
          }
        }
        shop {
          name
          shopCode
          address
        }
        packages
        model1
        model2
        model3
        quantityModel1
        quantityModel2
        quantityModel3
        pictureURLs
        dealerApproveAt
        resizedPictureURLs
        reason
        requestStatus
        createdAt
        updatedAt
        logs(order_by:{createdAt:desc}, limit:2) {
          createdUser{
            name
          }
          createdAt
        }
        reviews(order_by: { createdAt: desc }, limit: 1) {
          createdUser {
            name
          }
          createdAt
        }
        firstReview: requestReviews(order_by: { createdAt: desc }){
          creator {
            name
          }
          createdAt
        }
        saleManager: requestReviews(order_by: {createdAt: desc}) {
          request {
            histories(where:{creator:{role:{alias:{_eq:"group_leader"}}}},
          order_by:{createdAt: desc}){
            createdAt
            creator{
              name
            }
          }
          }
        }
        updatedUser {
          name
        }
      }
    }
  `,
};
