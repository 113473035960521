import './styles.scss';

// import moment from 'moment';
import { cloneDeep } from 'lodash';
import { BranchAndGroupRouterPath } from 'root/admin/BranchAndGroup';
import { ActionTypePoints, MutationTypePoint } from 'root/admin/Point/Store/types';
import { SystemRoleAlias } from 'root/admin/User/Store/types';
import { IPaginationParams } from 'root/api/graphql/Core';
import { Avatar } from 'root/components/Avatar';
import { fullNameUser, FormatDateFullTime } from 'root/helpers';
import { IBreadcrumb, IFilterInput } from 'root/models';
import { IUser } from 'root/models/User';
import { IState, MutationType } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import { DealerRouterName, DealerRouterPath } from '..';
import { ETypePointHistory } from '../Store/types';

// const STRING_TO_BOOL = {
//   true: true,
//   false: false
// };
const enum FilterKey {
  Type = 'type',
}

@Component({
  template: require('./view.html'),
  components: {
    avatar: Avatar,
    // 'user-detail-modal': UserDetailModal,
  },
  computed: {
    ...mapState({
      authUser: (state: IState) => state.global.authUser,
      data: (state: IState) => state.point.data,
      pagination: (state: IState) => state.point.pagination,
      loading: (state: IState) => state.point.loading,
      // filterParams: (state: IState) => state.point.filterParams,
      isRefesh: (state: IState) => state.global.isRefesh
    }),
    selectedStatus() {
      const filterParams = [];

      if (!filterParams) {
        return [];
      }

      return [{
        key: 'isCreatedByAppleReviewer',
        value: []
      }];
    }
  },
  watch: {
    type() {
      this.paramsFilter = {
        text: ''
      };
      // this.$store.commit(MutationTypeUser.ClearUserList);
      this.$store.commit(MutationType.SetBreadcrumb, this.$t(this.type));
      this.fetchData();
    }
  },
})

export class PointHistoryList extends Vue {
  public get columns() {
    return [
      {
        prop: 'type',
        label: 'Type',
        width: 180,
        formatter: (model: any) => {
          return model.source || '--';

        },
      },
      {
        prop: 'point',
        label: 'Point',
        width: 180,
        formatter: (model: any) => {
          if (model.totalPoint) {

            return `${model.source &&
              (model.source === ETypePointHistory.Earn) ? '+' : '-'}
               ${model.totalPoint}` || '--';

          }

          return '--';
        },
        labelStyle: (model: any) => {
          return (model.source === ETypePointHistory.Earn)
           ? 'schedule' : 'rejected';
        },
      },
      {
        prop: 'createdAt',
        label: 'Date',
        width: 180,
        formatter: (model: any) => {
          return FormatDateFullTime(model.expiredTime) || '--';
        },
      },
      {
        prop: 'source',
        label: 'Source',
        width: 180,
        formatter: (model: any) => {
          if (model.title) {
            return model.source === ETypePointHistory.Earn ? model.title :
             (model.rewardTitle ? model.rewardTitle : '--') ;

          }

          return '--';
        },
      },
    ];

  }
  public get dataFilter() {
    const _statuses: IFilterInput[] = [
      {
        key: 'earn',
        value: 'earn',
        name: 'Earn'
      },
      {
        key: 'redeem',
        value: 'redeem',
        name: 'Redeem'
      },
      {
        key: 'expired',
        value: 'expired',
        name: 'Expired'
      }
    ];

    return [
      {
        key: FilterKey.Type,
        name: 'Type',
        value: _statuses
      }
    ];
  }
  public get selectedFilter() {
    const filterParams = cloneDeep(this.filterParams);

    return [
      {
        key: FilterKey.Type,
        value: filterParams[FilterKey.Type]
      }
    ];
  }

  public get inputSearchPlaceholder(): string {

    return `Search by Name, Email`;
  }
  public get serviceId(): string {
    return this.$route.params.id;
  }
  public authUser: IUser;
  public pagination: IPaginationParams;
  public data: any[];
  public filterParams = {
    [FilterKey.Type]: [],
  };
  public paramsFilter = {
    text: ''
  };
  public isRefesh: boolean;
  public visible: boolean = false;
  public isSample: boolean = false;
  public userId: string = '';
  public visibleDetail: boolean = false;
  public popupVisible: boolean = false;
  public searchText: string = '';
  public pageSize: number = 0;

  public UserType = {
    Customer: 'customer',
    System: 'system',
  };
  public type: any;
  // tslint:disable-next-line:max-line-length
  private sampleUrl = `https://firebasestorage.googleapis.com/v0/b/daikin-test-staging.appspot.com/o/technicians%2Ftechnicians-import-sample.xlsx?alt=media&token=279661a1-11fe-49a0-8109-364e964ec11b`;

  // public isShowButtonNew(): boolean {
  //   if (this.authUser.group === GroupSystemUserType.Station) {
  //     return false;
  //   }

  //   return true;
  // }
  public changeFilter(model) {
    const status = model.find((e) => e.key === FilterKey.Type);
    const nextFilter = {
      [FilterKey.Type]: status ? status.value : []
    };
    this.filterParams = {
      ...cloneDeep(this.filterParams),
      ...nextFilter
    };
    this.fetchData();
  }

  public closeDialogDetail() {
    this.userId = '';
    this.visibleDetail = false;
  }

  public createSuccess() {
    this.visible = false;
    this.fetchData();
  }

  public applyFilter() {
    this.fetchData();
  }
  public goAddNewRouter() {
    this.visible = true;
    this.userId = '';
  }
  public closeDialog() {
    this.isSample = false;
    this.fetchData();
  }
  public downloadSample() {
    window.open(
      this.sampleUrl,
      '_blank'
    );
  }

  public clickDetail(id: string) {
    this.$router.push({
      name: DealerRouterName.DetailDealer,
      params: {
        id
      }});
  }
  public handleUpdate(id: string) {
    this.visible = true;
    this.userId = id;
  }
  public handleSearch() {
    this.fetchData();
  }

  public formatFullNameUser(model) {
    return fullNameUser(model);
  }
  public handlePaginationChange(value) {
    if (typeof value === 'number') {
      this.$store.commit(MutationTypePoint.PaginationChange, {
        ...this.pagination,
        limit: value
      });
      this.pageSize = value;
    } else {
      this.$store.commit(MutationTypePoint.PaginationChange, {
        ...this.pagination,
        ...value
      });
    }
    this.fetchData();
  }

  protected mounted() {
    this.$nextTick(() => {
      const breadcrumb: IBreadcrumb[] = [
        {
          label: 'title.dealer',
          path: DealerRouterPath.ListDealer,
          current: false
        },
        {
          label: 'title.view_details',
          path: null,
          current: true
        }
      ];
      const _breadcrumb: IBreadcrumb[] = [
        {
          label: 'title.managegroup',
          path: BranchAndGroupRouterPath.ListGroupManage,
          current: false
        },
        {
          label: 'title.view_details',
          path: null,
          current: true
        }
      ];
      this.$store.commit(MutationType.SetBack);
      this.$store.commit(MutationType.SetBreadcrumb, _breadcrumb);
      if (this.authUser && (this.authUser.role.alias === SystemRoleAlias.Admin ||
        this.authUser.role.alias === SystemRoleAlias.SaleAdmin)) {
        this.$store.commit(MutationType.SetBreadcrumb, breadcrumb);
      }
      this.fetchData();
    });
  }
  protected beforeDestroy() {
    // this.$store.commit(MutationType.ClearBreadcrumb);
  }

  private fetchData() {
    const filter = <any> {
      dealerId: this.serviceId
    };
    const text = cloneDeep(this.searchText);
    if (this.filterParams[FilterKey.Type].length) {
      filter.type = this.filterParams[FilterKey.Type];
    }
    if (text) {
      filter.search = text;
    }
    this.$store.dispatch(ActionTypePoints.GET_LIST_POINT, {
      form: {
        filter,
        pagination: {
          size: this.pagination.limit ? this.pagination.limit : this.pageSize,
          page: this.pagination.page
        }
      }
    });
  }
}
