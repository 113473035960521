import { cloneDeep, find } from 'lodash';
import { IFeedbackState } from './mutation';

export const getters = {
  getFeedbackDetail(state: IFeedbackState) {
    return (id: string) => {
      return find(cloneDeep(state.data), (e) => e.id === id);
    };
  }
};
