import { TabPane } from 'element-ui';
import { SystemRoleAlias } from 'root/admin/User/Store/types';
import { parseUTCToTime, FormatDateFullTime } from 'root/helpers';
import { IAddress, IBreadcrumb, IServiceCategory, IUser, IWorkMaster } from 'root/models';
import { IState, MutationType } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import { BranchAndGroupRouterName, BranchAndGroupRouterPath } from '../../';
import { GroupEditor } from '../Components/GroupManageEditor';
import { ActionTypeGroupManage, GroupManageTab } from '../Store/types';
import './styles.scss';

@Component({
  template: require('./view.html'),
  components: {
    editor: GroupEditor
  },
  computed: {
    ...mapState({
      groupManage: (state: IState) => state.groupManage.data,
      authUser: (state: IState) => state.global.authUser,
      detail: (state: IState) => state.groupManage.detail,
      isRefesh: (state: IState) => state.global.isRefesh,
      typeCollapse: (state: IState) => state.global.typeCollapse
    }),
    // detail() {
    //   this.$store.getters.getGroupManageDetail(this.serviceId);
    // }
  }
})
export class GroupDetail extends Vue {
  public get serviceId(): string {
    return this.$route.params.id;
  }
  public get activeTab() {
    return this.$route.name;
  }
  public set activeTab(_value: string) {
    //
  }
  public get accessEdit() {
    if (this.authUser && (this.authUser.role.alias === SystemRoleAlias.TeamLeader ||
      this.authUser.role.alias === SystemRoleAlias.Staff)) {
      return false;
    }

    return true;
  }
  public FormatDateFullTime = FormatDateFullTime;
  public formatDate = parseUTCToTime;
  public visibleEditor: boolean = false;
  public groupManage: any[];
  public repairs: IWorkMaster[];
  public category: IServiceCategory;
  public fileUpload: File[] = [];
  public isRefesh: boolean;
  public authUser: IUser;
  // public detail: any;
  public back() {
    this.$router.push(BranchAndGroupRouterName.ListBranch);
  }
  public closeDialog() {
    this.visibleEditor = false;
  }
  public goEdit() {
    this.visibleEditor = true;
  }
  public displayAddress(model: IAddress) {
    return model && model.province ? this.$store.getters['getAddressString'](model) : '';
  }
  public changeTab(tab: TabPane) {
    switch (tab.name) {
    case GroupManageTab.DealerGroup:
      this.$router.push({
        name: BranchAndGroupRouterName.DealerGroup,
        params: {
          id: this.serviceId
        }
      });
      break;
    case GroupManageTab.TechnicianGroup:
      this.$router.push({
        name: BranchAndGroupRouterName.TechnicianGroup,
        params: {
          id: this.serviceId
        }
      });
      break;
    case GroupManageTab.GroupLeader:
      this.$router.push({
        name: BranchAndGroupRouterName.GroupLeaderGroup,
        params: {
          id: this.serviceId
        }
      });
      break;
    case GroupManageTab.GroupInventory:
      this.$router.push({
        name: BranchAndGroupRouterName.GroupInventory,
        params: {
          id: this.serviceId
        }
      });
      break;
    case GroupManageTab.GroupInvoice:
      this.$router.push({
        name: BranchAndGroupRouterName.GroupInvoice,
        params: {
          id: this.serviceId
        }
      });
      break;
    case GroupManageTab.GroupProduct:
      this.$router.push({
        name: BranchAndGroupRouterName.GroupProduct,
        params: {
          id: this.serviceId
        }
      });
      break;
    case GroupManageTab.ActiveEWarrantyGroup:
      this.$router.push({
        name: BranchAndGroupRouterName.GroupEWarranty,
        params: {
          id: this.serviceId
        }
      });
      break;
    case GroupManageTab.TeamLeader:
      this.$router.push({
        name: BranchAndGroupRouterName.GroupTeamLeader,
        params: {
          id: this.serviceId
        }
      });
      break;
    case GroupManageTab.Staff:
      this.$router.push({
        name: BranchAndGroupRouterName.GroupStaff,
        params: {
          id: this.serviceId
        }
      });
      break;

    default:
      break;
    }
  }
  protected mounted() {
    const breadcrumb: IBreadcrumb[] = [
      {
        label: 'title.managedepartment',
        path: BranchAndGroupRouterPath.ListGroupManage,
        current: false
      },
      {
        label: 'title.view_details',
        path: null,
        current: true
      }
    ];
    this.$store.commit(MutationType.SetBack);
    this.$store.commit(MutationType.SetBreadcrumb, breadcrumb);
    this.$store.commit(MutationType.SetShowCollapse);
    this.$store.commit(MutationType.SetTypeCollapse, true);
    if (!this.isRefesh) {
      this.fetchData();

    }
  }
  protected beforeDestroy() {
    this.$store.commit(MutationType.ClearShowCollapse);
    this.$store.commit(MutationType.ClearBreadcrumb);
  }
  private fetchData() {
    this.$store.dispatch(ActionTypeGroupManage.GroupGetById, {
      id: this.serviceId,
      onSuccess: () => {
          //
      },
      onFailure: () => {
          //
      }
    });
  }
}
