import { gqlClient } from '../Core';
import { CRUDGqlService, ICRUDGqlService } from '../Core/crud';
import { invoices, invoiceListQuery } from '../Query/invoice';

export interface IInvoiceGqlService extends ICRUDGqlService<any> {
  //
  getListInvoice(form: any): Promise<any>;
}
export function invoiceGqlService(): IInvoiceGqlService {
  async function getListInvoice(form: any): Promise<any> {
    const results = await gqlClient.query({
      query: invoiceListQuery.GET_INVOICE_LIST,
      variables: {
        form
      },
      fetchPolicy: 'network-only'
    });
    if (results.errors) {
      throw results.errors;
    }

    return results.data.getInvoices;
  }

  return {
    ...CRUDGqlService<any>(invoices, invoiceListQuery),
    getListInvoice,
  };
}
