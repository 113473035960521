import { IPaginationParams } from 'root/api/graphql/Core';
import { crudMutations, CRUDState, ICRUDState } from 'root/store/helpers';
import { MutationTypeDealerShop} from './types';

export interface IDealerShopState extends ICRUDState<any> {
  loading: boolean;
}

export const defaultState: IDealerShopState = {
  ...CRUDState(),
  loading: false,
};

export const mutations = {
  ...crudMutations('dealerShop'),
  [MutationTypeDealerShop.GetListDealerShop](state: IDealerShopState, data: any) {
    state.data = data.shops;
    state.pagination.total = data.shops_aggregate.aggregate.count;
  },
  [MutationTypeDealerShop.SetPagination](state: IDealerShopState, pagination: IPaginationParams) {
    state.pagination.limit = pagination.limit;
    state.pagination.page = pagination.page;
  }
};
