import { CRUDGqlService, ICRUDGqlService } from '../Core/crud';
import { productEWarrantyListQuery, EWarranty } from '../Query/activeEWarranty';

export interface IProductWarrantyGqlService extends ICRUDGqlService<any> {
  //
}
export function ProductWarrantyGqlService(): IProductWarrantyGqlService {

  return {
    ...CRUDGqlService<any>(EWarranty, productEWarrantyListQuery),
  };
}
