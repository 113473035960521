import { PermissionType } from 'root/models';
import { RouteConfig } from 'vue-router';
import { ApplicationProduct } from './ApplicationProduction';
import { RewardManagement } from './RewardManagement';
import { SaleInCampaignContainer } from './SaleInCampaignContainer';
import { SaleInCampaignManagement } from './SaleInCampaignManagement';
import { SaleInCampaignDetail } from './SaleInCampaignManagement/Components/SaleInCampaignDetail';

export enum SaleInCampaignRouterPath {
  Default = '/sale-in-campaign/',
  Product = '/sale-in-campaign/product',
  Reward = '/sale-in-campaign/reward',
  Detail = '/sale-in-campaign-detail/:id',
  Pined = '/sale-in-campaign-detail',
}
export enum SalesInCampaignRouterName {
  Default = 'saleInDefault',
  Product = 'saleInProduct',
  Reward = 'saleInReward',
  Detail = 'saleInDetail'
}
export const SaleInCampaignRouter: RouteConfig = {
  path: '/sale-in-campaign',
  component: SaleInCampaignContainer,
  redirect: SaleInCampaignRouterPath.Default,
  meta: {
    permission: PermissionType.SaleInCampaign
  },
  children: [
    {
      path: SaleInCampaignRouterPath.Default,
      component: SaleInCampaignManagement,
      name: SalesInCampaignRouterName.Default,
      meta: {
        permission: PermissionType.SaleInCampaign
      },
    },
    {
      path: SaleInCampaignRouterPath.Product,
      component: ApplicationProduct,
      name: SalesInCampaignRouterName.Product,
      meta: {
        permission: PermissionType.SaleInCampaign
      },
    },
    {
      path: SaleInCampaignRouterPath.Reward,
      component: RewardManagement,
      name: SalesInCampaignRouterName.Reward,
      meta: {
        permission: PermissionType.SaleInCampaign
      },
    },
    {
      path: SaleInCampaignRouterPath.Detail,
      component: SaleInCampaignDetail,
      name: SalesInCampaignRouterName.Detail,
      meta: {
        permission: PermissionType.SaleInCampaign
      },
    }
  ]
};
