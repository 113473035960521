import { stripObject } from '@hgiasac/helper';
import { cloneDeep, find } from 'lodash';
import moment from 'moment';
import { ActionTypeGroupSystem } from 'root/admin/Group/Store/types';
import { ActionTypeRole } from 'root/admin/Role/Store/types';
import { IPaginationParams } from 'root/api/graphql/Core';
import { Avatar } from 'root/components/Avatar';
import { ChangePassword } from 'root/components/ChangePassword';
import { fullNameUser } from 'root/helpers';
import { Gender, IFilterInput, StatusCode } from 'root/models';
import { ESystemGroup, IUser } from 'root/models/User';
import { IState, MutationType, TypeAlert } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import { ChangePasswordDialog } from '../Components/ChangePasswordDialog';
import { UserDetailModal } from '../Components/UserDetailModal';
import { ActionTypeUser, MutationTypeUser } from '../Store/types';
import { UserEditor } from '../UserEditor';
import './styles.scss';

const enum FilterKey {
  Group = 'systemGroupId',
  Role = 'roleId',
  Gender = 'gender',
  Status = 'status'
}
const enum GenderCode {
  Male = 'Male',
  Female = 'Female'
}
@Component({
  template: require('./view.html'),
  components: {
    editor: UserEditor,
    avatar: Avatar,
    'user-detail-modal': UserDetailModal,
    'change-pass-modal': ChangePasswordDialog,
    changPassword: ChangePassword
  },
  computed: {
    ...mapState({
      authUser: (state: IState) => state.global.authUser,
      data: (state: IState) => state.user.data,
      pagination: (state: IState) => state.user.pagination,
      loading: (state: IState) => state.user.loading,
      groups: (state: IState) =>
        state.group.systemGroup.data.filter(
          (it) => it.name !== ESystemGroup.Dealer
        ),
      roles: (state: IState) => state.role.data,
      isRefesh: (state: IState) => state.global.isRefesh
    }),
    selectedStatus() {
      const filterParams = [];

      if (!filterParams) {
        return [];
      }

      return [
        {
          key: 'isCreatedByAppleReviewer',
          value: []
        }
      ];
    }
  }
})
export class UserList extends Vue {
  public get columns() {
    return [
      {
        label: this.$t('fullName'),
        formatter: (model: any) => {
          return fullNameUser(model);
        },
        avatar: {
          formatter: (model: any) => {
            return model ? model : null;
          }
        },
        width: 250
      },
      // {
      //   prop: 'gender',
      //   label: 'Gender',
      //   width: 150,
      //   formatter: (model: any) => {
      //     return model.gender || '--';
      //   }
      // },
      {
        prop: 'email',
        label: 'Email',
        width: 250,
        formatter: (model: any) => {
          return model.email || '--';
        }
      },
      {
        prop: 'phoneNumber',
        label: this.$t('phoneNumber'),
        width: 180,
        formatter: (model: any) => {
          return model.phoneNumber || '--';
        }
      },
      {
        prop: 'group',
        label: this.$t('group'),
        width: 180,
        formatter: (model: any) => {
          if (model.systemGroup) {
            return model.systemGroup.name || '--';
          }
        }
      },
      {
        prop: 'branch',
        label: 'Branch',
        width: 120,
        formatter: (model: any) => {
          return model && model.branch && model.branch.name ? model.branch.name : '--';
        }
      },
      {
        prop: 'department',
        label: 'Department',
        width: 120,
        formatter: (model: any) => {
          return model && model.group && model.group.name ? model.group.name : '--';
        }
      },
      {
        prop: 'role',
        label: this.$t('role'),
        width: 150,
        formatter: (model: any) => {
          if (model.role) {
            return model.role.name;
          }

          return '--';
        }
      },
      {
        prop: 'createAt',
        label: this.$t('created.at'),
        formatter: (model: any) => {
          if (model.createdAt) {
            return moment(model.createdAt).format('DD/MM/YYYY');
          }

          return '--';
        },
        width: 180
      },
      {
        prop: 'status',
        label: this.$t('status'),
        formatter: (model: any) => (model.status ? this.$t(model.status) : ''),
        labelStyle: (model: any) => {
          return model.status || '--';
        },
        width: 150
      }
    ];
  }

  public get actions() {
    return ['view', 'edit', 'block', 'delete', 'change-password'];
  }

  public get inputSearchPlaceholder(): string {
    return `Search by Name, Email`;
  }
  public get dataFilter() {
    const _groups: IFilterInput[] = this.groups.reduce((res, item) => {
      res.push({
        key: item.id,
        value: item.id,
        name: item.name
      });

      return res;
    }, []);
    const _roles: IFilterInput[] = this.getRoleSystem().reduce((res, item) => {
      res.push({
        key: item.id,
        value: item.id,
        name: item.name
      });

      return res;
    }, []);
    const _status: IFilterInput[] = [
      {
        key: StatusCode.Active,
        value: StatusCode.Active,
        name: this.$t(StatusCode.Active)
      },
      {
        key: StatusCode.Blocked,
        value: StatusCode.Blocked,
        name: this.$t(StatusCode.Blocked)
      }
    ];
    const _gender: IFilterInput[] = [
      {
        key: Gender.Male,
        value: GenderCode.Male,
        name: GenderCode.Male
      },
      {
        key: Gender.Female,
        value: GenderCode.Female,
        name: GenderCode.Female
      }
    ];

    return [
      {
        key: FilterKey.Group,
        name: this.$t('field.group'),
        value: _groups
      },
      {
        key: FilterKey.Role,
        name: this.$t('field.role'),
        value: _roles
      },
      {
        key: FilterKey.Gender,
        name: this.$t('field.gender'),
        value: _gender
      },
      {
        key: FilterKey.Status,
        name: this.$t('field.status'),
        value: _status
      }
    ];
  }
  public get selectedFilter() {
    const filterParams = cloneDeep(this.filterParams);

    return [
      {
        key: FilterKey.Group,
        value: filterParams[FilterKey.Group]
      },
      {
        key: FilterKey.Role,
        value: filterParams[FilterKey.Role]
      },
      {
        key: FilterKey.Gender,
        value: filterParams[FilterKey.Gender]
      },
      {
        key: FilterKey.Status,
        value: filterParams[FilterKey.Status]
      }
    ];
  }

  public groups: any[];
  public roles: any[];
  public authUser: IUser;
  public pagination: IPaginationParams;
  public data: IUser[];
  public visible: boolean = false;
  public popupVisible: boolean = false;
  public isSample: boolean = false;
  public userId: string = '';
  public visibleDetail: boolean = false;
  public searchText: string = '';
  public filterParams = {
    [FilterKey.Group]: [],
    [FilterKey.Role]: [],
    [FilterKey.Gender]: [],
    [FilterKey.Status]: []
  };
  // public roleSystem: any[];
  public isRefesh: boolean;
  public visibleChangePass: boolean = false;

  public UserType = {
    Customer: 'customer',
    System: 'system'
  };
  public type: any;
  // public get type(): UserType {
  //   return <UserType> this.$route.name;
  // }
  // public handleCommand(command) {
  //   if (command === 'addnew') {
  //     this.goAddNewRouter();
  //   } else {
  //     this.showUploadDialog();
  //   }
  // }
  // public showUploadDialog() {
  //   this.isSample = true;
  // }

  public handlePaginationChange(value) {
    if (typeof value === 'number') {
      this.$store.commit(MutationTypeUser.PaginationChange, {
        ...this.pagination,
        size: value
      });
    } else {
      this.$store.commit(MutationTypeUser.PaginationChange, {
        ...this.pagination,
        ...value
      });
    }
    this.fetchData();
  }
  public getRoleSystem() {
    let roleSystem = [];
    if (this.roles && this.roles.length && this.groups && this.groups.length) {
      const _groupId = this.groups.map((it) => it.id);
      roleSystem = this.roles.filter((item) =>
        _groupId.includes(item.systemGroupId)
      );
    }

    return roleSystem;
  }

  public closeDialogDetail() {
    this.userId = '';
    this.visibleDetail = false;
  }

  public createSuccess() {
    this.visible = false;
    this.fetchData();
  }
  public changePassSuccess() {
    this.popupVisible = false;
    this.fetchData();
  }

  public applyFilter() {
    this.fetchData();
  }
  public goAddNewRouter() {
    this.visible = true;
    this.userId = '';
  }
  public closeDialog() {
    this.isSample = false;
    this.fetchData();
  }
  public handleSearch() {
    this.$store.commit(MutationTypeUser.PaginationChange, {
      ...this.pagination,
      page: 1,
    });
    this.fetchData();
  }
  public openChangePassword(id: string) {
    this.userId = id;
    this.visibleChangePass = true;
  }
  public changePassword(pass: string) {
    const newPassword: string = pass;
    const formChangePass = {
      id: this.userId,
      password: newPassword
    };
    this.$store.dispatch(ActionTypeUser.ChangePassSystemUser, {
      form: formChangePass,
      onSuccess: () => {
        this.$message({
          type: 'success',
          message: this.$t('change_password_successfully').toString()
        });
        this.userId = '';
        this.visibleChangePass = false;
        this.fetchData();
      },
      onFailure: (error) => {
        this.$message.error(error.message);
        this.userId = '';
        this.visibleChangePass = false;
        this.fetchData();
      }
    });

  }

  public handleDelete(model: any) {
    this.$store.dispatch(ActionTypeUser.UpdateStatusSystemUser, {
      id: model.id,
      status: StatusCode.Deleted,
      onSuccess: () => {
        this.fetchData();
        model.instance.confirmButtonLoading = false;
        model.instance.confirmButtonText = 'Confirm';
        model.done();
        const message = 'Delete System User success';
        this.$store.commit(MutationType.OpenTopAlert, {
          message,
          type: TypeAlert.Success
        });
      },
      onFailure: () => {
        model.instance.confirmButtonLoading = false;
        model.instance.confirmButtonText = 'Confirm';
      }
    });
  }
  public handleDisabled(model: any) {
    const user = find(
        cloneDeep(this.data),
        (e) => e.id.toString() === model.id.toString()
      ),
      isBlocked = user.status === StatusCode.Blocked;
    this.$store.dispatch(ActionTypeUser.UpdateStatusSystemUser, {
      id: model.id,
      status: isBlocked ? StatusCode.Active : StatusCode.Blocked,
      onSuccess: () => {
        this.fetchData();
        model.instance.confirmButtonLoading = false;
        model.instance.confirmButtonText = 'Confirm';
        model.done();
        const message = isBlocked
          ? 'Unlock System User success'
          : 'Block System User success';
        this.$store.commit(MutationType.OpenTopAlert, {
          message,
          type: TypeAlert.Success
        });
      },
      onFailure: () => {
        model.instance.confirmButtonLoading = false;
        model.instance.confirmButtonText = 'Confirm';
      }
    });
  }

  public clickDetail(id: string) {
    this.userId = id;
    this.visibleDetail = true;
  }
  public handleUpdate(id: string) {
    this.visible = true;
    this.userId = id;
  }

  public changeFilter(model) {
    const group = model.find((e) => e.key === FilterKey.Group);
    const role = model.find((e) => e.key === FilterKey.Role);
    const gender = model.find((e) => e.key === FilterKey.Gender);
    const status = model.find((e) => e.key === FilterKey.Status);
    const nextFilter = {
      [FilterKey.Group]: group ? group.value : [],
      [FilterKey.Role]: role ? role.value : [],
      [FilterKey.Gender]: gender ? gender.value : [],
      [FilterKey.Status]: status ? status.value : []
    };
    this.filterParams = {
      ...cloneDeep(this.filterParams),
      ...nextFilter
    };
    this.fetchData();
  }

  public formatFullNameUser(model) {
    return fullNameUser(model);
  }

  protected mounted() {
    this.$nextTick(() => {
      // this.$store.dispatch(ActionTypeGroupSystem.GET_LIST_GROUP_SYSTEM);
      this.$store.dispatch(ActionTypeGroupSystem.FilterNoCache);
      this.$store.dispatch(ActionTypeRole.FilterNoCache);
      this.$store.commit(MutationType.SetBreadcrumb, this.$t('title.systemUser'));
      this.$store.commit(MutationTypeUser.ResetState);
      !this.isRefesh && this.fetchData();
    });
  }
  protected beforeDestroy() {
    this.$store.commit(MutationType.ClearBreadcrumb);
  }

  private fetchData() {
    const text = cloneDeep(this.searchText);
    this.$store.commit(
      MutationTypeUser.FilterChange,
      stripObject({
        [FilterKey.Group]: this.filterParams[FilterKey.Group],
        [FilterKey.Role]: this.filterParams[FilterKey.Role],
        [FilterKey.Status]: this.filterParams[FilterKey.Status],
        [FilterKey.Gender]: this.filterParams[FilterKey.Gender],
        _and:
          text.trim() !== ''
            ? {
              mode: 'special',
              data: {
                _or: [
                  {
                    name: {
                      _ilike: `%${text}%`
                    }
                  },
                  {
                    email: {
                      _ilike: `%${text}%`
                    }
                  },
                  {
                    phoneNumber: {
                      _ilike: `%${text}%`
                    }
                  },
                ]
              }
            } : null
      }));
    this.$store.commit(MutationTypeUser.ChangeOrderBy, {createdAt: 'desc'});
    this.$store.dispatch(ActionTypeUser.FilterNoCache);
  }
}
