import { cloneDeep } from 'lodash';
import { ActionTypeBranchManage } from 'root/admin/BranchAndGroup/BranchManage/Store/types';
import { Avatar } from 'root/components/Avatar';
import { SearchByFilter } from 'root/components/SearchByFilter';
import { FormatDateFullTime } from 'root/helpers';
import { IFilterInput, IUser } from 'root/models';
import { IState, MutationType, TypeAlert } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import { SalesInCampaignRouterName } from '..';
import { ActionTypeSaleInCampaign, MutationTypeSaleInCampaign } from '../Store/types';
import { SaleInCampaignForm } from './Components/SaleInCampaignForm';
import './styles.scss';

const enum FilterKey {
  Status = 'status',
  Type = 'type',
  Branch = 'branch',
  Role = 'role'
}
enum SearchByKey {
  CampaignName = 'campaignName',
  CampaignCode = 'campaignCode'
}

@Component({
  template: require('./view.html'),
  components: {
    avatar: Avatar,
    'search-by-filter': SearchByFilter,
    'sale-in-campaign-form': SaleInCampaignForm
  },
  computed: {
    ...mapState({
      data: (state: IState) => state.saleInCampaign.data,
      pagination: (state: IState) => state.saleInCampaign.pagination,
      loading: (state: IState) => state.saleInCampaign.loading,
      authUser: (state: IState) => state.global.authUser,
      branch: (state: IState) => state.branchManage.data
    }),
    searchBy() {
      const result: IFilterInput[] = [];
      for (const key in SearchByKey) {
        // tslint:disable-next-line:early-exit
        if (SearchByKey.hasOwnProperty(key)) {
          const element = SearchByKey[key];
          result.push({
            key,
            value: element,
            name: this.$t(element)
          });
        }
      }

      return [{
        key: 'select',
        name: this.$t('select'),
        value: result
      }];
    },
    selectedFieldSearchBy() {
      const filterParams = cloneDeep(this.filterParams);

      return [{
        key: 'select',
        value: filterParams.searchBy ? filterParams.searchBy : []
      }];
    },
  }
})

export class SaleInCampaignManagement extends Vue {
  public get dataFilter() {
    const statuses: IFilterInput[] = [
      {
        key: 'draft',
        value: 'draft',
        name: 'Draft'
      },
      {
        key: 'active',
        value: 'active',
        name: 'Active'
      },
      {
        key: 'inactive',
        value: 'inactive',
        name: 'Inactive'
      },
      {
        key: 'force_closed',
        value: 'force_closed',
        name: 'Closed'
      }
    ];
    const branch = this.branch.map((it) => (
    {
      key: it.id,
      value: it.id,
      name: it.branchCode
    }
    ));
    const roles: IFilterInput[] = [
      {
        key: 'daikin_dealer',
        value: 'daikin_dealer',
        name: 'Direct Dealer'
      },
      {
        key: 'sub_dealer',
        value: 'sub_dealer',
        name: 'Sub dealer'
      },
    ];
    const type: IFilterInput[] = [
      {
        key: 'gift_product',
        value: 'gift_product',
        name: 'Gift/Product'
      },
      {
        key: 'discount',
        value: 'discount',
        name: 'Discount'
      },
    ];

    return [
      {
        key: FilterKey.Status,
        name: 'Status',
        value: statuses
      },
      {
        key: FilterKey.Type,
        name: 'Type',
        value: type
      },
      {
        key: FilterKey.Branch,
        name: 'Branch ',
        value: branch
      },
      {
        key: FilterKey.Role,
        name: 'Role',
        value: roles
      },
    ];
  }
  public get selectedFilter() {
    const _filterParams = cloneDeep(this.filterParams);

    return [
      {
        key: FilterKey.Status,
        value: _filterParams[FilterKey.Status]
      },
      {
        key: FilterKey.Type,
        value: _filterParams[FilterKey.Type]
      },
      {
        key: FilterKey.Branch,
        value: _filterParams[FilterKey.Branch]
      },
      {
        key: FilterKey.Role,
        value: _filterParams[FilterKey.Role]
      },
    ];
  }
  public get columns() {
    return [
      {
        prop: 'pin',
        fixed: true,
        width: 40
      },
      {
        label: 'Code',
        prop: 'campaignCode',
        fixed: true,
        width: 120
      },
      {
        label: 'Campaign Name',
        prop: 'title',
        fixed: true,
        width: 250
      },
      {
        label: 'Type',
        prop: 'type',
        formatter: (model: any) => {
          return model && model.sicInformation && model.sicInformation.rewardType ?
          model.sicInformation.rewardType.split('_').join('/') : '--' ;
        },
        labelStyle: () => {
          return 'text--capitalize';
        },
        width: 120
      },
      {
        label: 'Role',
        prop: 'target',
        formatter: (model: any) => {
          return model && model.dealerRoles ?
          this.formatterRole(model.dealerRoles) : '--' ;
        },
        width: 200
      },
      {
        label: 'Branch',
        formatter: (model: any) => {
          return model.targetBranch && model.targetBranch === 'all' ?
          'All' : model.targetBranches && model.targetBranches.length ?
          this.formatterBranch(model.targetBranches) : '--' ;
        },
        width: 200
      },
      {
        label: 'Start At',
        formatter: (model: any) => {
          return model && model.startDate ? this.FormatDateFullTime(model.startDate,
            {locale: true, format: 'DD/MM/YYYY'}) : '--';
        },
        width: 150
      },
      {
        label: 'End At',
        formatter: (model: any) => {
          return model && model.endDate ? this.FormatDateFullTime(model.endDate,
            {locale: true, format: 'DD/MM/YYYY'}) : '--';
        },
        width: 150
      },
      // {
      //   label: 'Description',
      //   width: 250
      // },
      {
        label: 'Status',
        formatter: (model: any) => {
          return model && model.campaignStatus && model.campaignStatus !== 'force_closed' ?
           model.campaignStatus : model && model.campaignStatus === 'force_closed' ? 'Closed' : '--';
        },
        labelStyle: (model: any) => {
          return `text--capitalize sale-in-campaign-status__${model.campaignStatus}`;
        },
        width: 120
      },
      {
        prop: 'action',
        label: '',
        align: 'center',
        fixed: 'right',
        width: 60
      }
    ];
  }
  public FormatDateFullTime = FormatDateFullTime;
  public createVisible: boolean = false;
  public visibleAction: boolean = false;
  public loadingAction: boolean = false;
  public detailId: string= '';
  public searchText: string = '';
  public campaignAction: string = '';
  public authUser: IUser;
  public filterParams = {
    searchBy: ['campaignName', 'campaignCode'],
    [FilterKey.Status]: [],
    [FilterKey.Type]: [],
    [FilterKey.Branch]: [],
    [FilterKey.Role]: []
  };
  public titleAction: string = '';
  public messageAction: string = '';
  public reasonAction: string = '';
  public branch;
  public isErrorReason: boolean = false;
  public onClickCreate() {
    this.detailId = '';
    this.createVisible = true;
  }
  public onclickUpdate(id) {
    this.detailId = id;
    this.createVisible = true;
  }
  public onClickPinCampaign(campaign: any, type: boolean) {
    this.$msgbox({
      title: `${type ? 'Pin' : 'Unpin'} campaign`,
      message: `Do you want to ${type ? 'pin' : 'unpin'} this campaign`,
      showCancelButton: true,
      confirmButtonText: this.$t('confirm').toString(),
      cancelButtonText: this.$t('cancel').toString(),
      beforeClose: (action, instance, done) => {
        if (action === 'confirm') {
          instance.confirmButtonLoading = true;
          instance.confirmButtonText = 'Loading...';
          this.handlePinCampaign({ instance, done, campaign, type });
        } else {
          done();
        }

        return;
      }
    })
        .then(() => {
          this.$message({
            type: 'success',
            message: this.$t('message.updateSuccess').toString()
          });
        })
        .catch(() => {
          // no handle
        });
  }
  public changeFilter(model) {
    const status = model.find((e) => e.key === FilterKey.Status);
    const branch = model.find((e) => e.key === FilterKey.Branch);
    const type = model.find((e) => e.key === FilterKey.Type);
    const role = model.find((e) => e.key === FilterKey.Role);

    const nextFilter = {
      [FilterKey.Status]: status ? status.value : [],
      [FilterKey.Type]: type ? type.value : [],
      [FilterKey.Branch]: branch ? branch.value : [],
      [FilterKey.Role]: role ? role.value : []
    };
    this.filterParams = {
      ...cloneDeep(this.filterParams),
      ...nextFilter
    };
    this.fetchData();
  }
  public handlePinCampaign(model) {
    this.$store.dispatch(ActionTypeSaleInCampaign.PinCampaign, {
      id: model.campaign.sicInformation.id,
      type: model.type,
      onSuccess: () => {
        model.instance.confirmButtonLoading = false;
        model.instance.confirmButtonText = 'Confirm';
        model.done();
        this.fetchData();
      },
      onFail: () => {
        model.instance.confirmButtonLoading = false;
        model.instance.confirmButtonText = 'Confirm';
      }
    });
  }
  public getActions(status: any) {
    if (status === 'draft') {
      return ['view', 'edit', 'activate'];
    }
    if (status === 'active') {
      return ['view', 'deactivate', 'force-close'];
    }
    if (status === 'inactive') {
      return ['view', 'edit', 'activate', 'force-close'];
    }

    return ['view'];
  }
  public handleActions({act, model}) {
    if (act === 'view') {
      this.onClickDetail(model.id);

      return;
    }
    if (act === 'edit') {
      this.onclickUpdate(model.id);

      return;
    }
    if (act === 'deactivate') {
      this.campaignAction = model.id;
      this.visibleAction = true;
      this.titleAction = 'deactivate';
      this.messageAction = 'Do you want to deactivate this campaign?';

      return;
    }
    if (act === 'activate') {
      this.campaignAction = model.id;
      this.visibleAction = true;
      this.titleAction = 'activate';
      this.messageAction = 'Do you want to activate this campaign?';

      return;
    }
    if (act === 'force-close') {
      this.campaignAction = model.id;
      this.visibleAction = true;
      this.titleAction = 'force-close';
      this.messageAction = 'Do you want to force close this campaign?';

      return;
    }

    return;
  }
  public onClickDetail(id: string) {
    this.$router.push({
      name: SalesInCampaignRouterName.Detail,
      params: {
        id
      }
    });
  }
  public formatterBranch(branches: any) {
    if (branches.length) {
      const result = [];
      branches.map((branch) => {
        const formatBranch = branch.branch && branch.branch.name ? branch.branch.name : '--';
        result.push(formatBranch);
      });

      return result.join(',');
    }

    return '--';
  }
  public formatterRole(roles: any) {
    if (roles.length) {
      const result = [];
      roles.map((role) => {
        const formatRole = this.formatterDealerRole(role);
        result.push(formatRole);
      });

      return result.join(',');
    }

    return '--';
  }
  public openDialogAction() {
    // 
  }
  public closeDialogAction() {
    this.titleAction = '';
    this.messageAction = '';
    this.campaignAction = '';
    this.visibleAction = false;
    this.reasonAction = '';
  }
  public submitAction(action) {
    if (action === 'deactivate') {
      this.loadingAction = true;
      this.$store.dispatch(ActionTypeSaleInCampaign.ChangeCampaignStatus, {
        form: {
          campaignId: this.campaignAction,
          campaignStatus: 'inactive',
          updatedBy: this.authUser.id
        },
        onSuccess: () => {
          const message = 'Updated successfully';
          this.$store.commit(MutationType.OpenTopAlert, {
            message,
            type: TypeAlert.Success
          });
          this.closeDialogAction();
          this.fetchData();
          this.loadingAction = false;
        }
      });

      return;
    }
    if (action === 'activate') {
      this.loadingAction = true;
      this.$store.dispatch(ActionTypeSaleInCampaign.ChangeCampaignStatus, {
        form: {
          campaignId: this.campaignAction,
          campaignStatus: 'active',
          updatedBy: this.authUser.id
        },
        onSuccess: () => {
          const message = 'Updated successfully';
          this.$store.commit(MutationType.OpenTopAlert, {
            message,
            type: TypeAlert.Success
          });
          this.closeDialogAction();
          this.fetchData();
          this.loadingAction = false;
        }
      });

      return;
    }
    if (action === 'force-close') {
      if (!this.reasonAction) {
        return this.isErrorReason = true;
      }
      this.isErrorReason = false;
      this.loadingAction = true;
      this.$store.dispatch(ActionTypeSaleInCampaign.ChangeCampaignStatus, {
        form: {
          campaignId: this.campaignAction,
          campaignStatus: 'force_closed',
          forceCloseReason: this.reasonAction,
          updatedBy: this.authUser.id
        },
        onSuccess: () => {
          const message = 'Updated successfully';
          this.$store.commit(MutationType.OpenTopAlert, {
            message,
            type: TypeAlert.Success
          });
          this.closeDialogAction();
          this.fetchData();
          this.loadingAction = false;
        }
      });

      return;
    }

    return;
  }
  public changeSearchBy(model: IFilterInput[]) {
    this.filterParams = {
      ...this.filterParams,
      searchBy: model[0].value
    };

    this.fetchData();
  }
  public onEnterFilter(value) {
    this.searchText = value;
    this.$store.commit(MutationTypeSaleInCampaign.PaginationChange, {
      page: 1,
      limit: 20
    });

    this.fetchData();
  }
  public formatterDealerRole(role: any) {
    const mapRole = {
      daikin_dealer: 'Dealer',
      daikin_staff: 'Salesman',
      sub_dealer: 'Sub Dealer'
    };

    return mapRole[role];
  }
  public closeDialog() {
    this.createVisible = false;
  }
  public fetchData() {
    const text = cloneDeep(this.searchText);
    const {status, branch, type, role, searchBy} = this.filterParams;
    this.$store.commit(MutationTypeSaleInCampaign.ChangeOrderBy, {
      createdAt: 'desc',
      sicInformation: {
        pin: 'desc'
      }
    });
    this.$store.commit(MutationTypeSaleInCampaign.FilterChange, {
      _and: {
        mode: 'special',
        data: {
          alias: {
            _eq: 'sell_in'
          },
          ...role && role.length ? {
            dealerRoles: {
              _contained_in: role
            }
          } : {},
          ...branch && branch.length ? {
            targetBranches: {
              branchId: {
                _in: branch
              }
            }
          } : {},
          ...type && type.length ? {
            sicInformation: {
              rewardType: {
                _in: type
              }
            }
          } : {},
          ...status && status.length ? {
            campaignStatus: {
              _in: status
            }
          } : {},
          ...text && text.trim().length > 0 && searchBy && searchBy.length ? {
            _or: [
              {
                ...searchBy.includes('campaignName') ? {
                  title: {
                    _ilike: `%${text}%`
                  }
                } : {},
              },
              {
                ...searchBy.includes('campaignCode') ? {
                  campaignCode: {
                    _ilike: `%${text}%`
                  }
                } : {},
              }
            ].filter((e) => !!e)
          } : {}
        }
      }
    });
    this.$store.dispatch(ActionTypeSaleInCampaign.FilterNoCache);
  }
  protected mounted() {
    this.$store.commit(MutationType.SetBreadcrumb, 'Sell In Campaign');
    this.$store.commit(MutationType.ClearBack);
    this.$store.dispatch(ActionTypeBranchManage.GetListBranch, {
      form: {
        filter: {},
        pagination: {}
      }
    });
    this.fetchData();
  }
}
