export enum ActionTypeRegion {
  FilterNoCache = 'regionsFilterNoCache',
  InsertRegion = 'regionsInsert',
  UpdateRegion = 'regionsUpdate',
  RegionGetById = 'regionGetById',
  GetListBranch =' getListBranch'
}
export enum MutationTypeRegion {
  FilterChange = 'regionsFilterChange',
  PaginationChange = 'regionsPaginationChange',
  ResetState = 'regionsResetState',
  RegionSetDetail = 'regionSetDetail'
}

export enum RegionManageTab {
  RegionBranch = 'RegionBranch',
  RegionBranchLeader = 'RegionBranchLeader',
  RegionDealer = 'RegionDealer',
}
