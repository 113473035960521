import { DKGqlClient } from 'root/api/graphql/Client';
import { ActionType, IState, MutationType } from 'root/store';
import { crudActions } from 'root/store/helpers';
import { ActionContext } from 'vuex';
import { IProductState } from './mutation';
import { ActionTypeProduct, MutationTypeProduct } from './types';

const { product } = DKGqlClient();
export const actions = {
  ...crudActions('product', {
    filter: product.filter
  }),
  async [ActionTypeProduct.GET_LIST_PRODUCT]({
    commit, dispatch
  }: ActionContext<IProductState, IState>) {
    try {
      const ProductList = await product.getListProduct();
      commit(MutationType.GetProduct, ProductList);
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    }
  },
  async [ActionTypeProduct.getScanProductMode]({
     dispatch
  }: ActionContext<IProductState, IState>) {
    try {
      const ProductList = await product.getScanProductMode();
      if (ProductList) {

        return ProductList;
      }
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    }
  },
  async [ActionTypeProduct.UpdateScanProductMode]({
     dispatch
  }: ActionContext<IProductState, IState>,
                                                  {value, onSuccess}) {
    try {
      const ProductList = await product.updateScanProductMode(value);
      onSuccess();
      if (ProductList) {
        //
      }
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    }
  },
  async [ActionTypeProduct.GetListProductRegistation]({
    commit, dispatch
  }: ActionContext<IProductState, IState>,
                                                      {search, branch, order, pagination}) {
    try {
      commit(MutationTypeProduct.ProductRegistationLoading);
      const { page, limit } = pagination;
      const offset = (page - 1) * limit;
      const listProduct = await product.getListRegistation(search, branch, order, limit, offset);
      if (listProduct) {
        commit(MutationTypeProduct.SetProductRegistation, listProduct);
      }
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    } finally {
      commit(MutationTypeProduct.ProductRegistationLoaded);
    }
  },
  async [ActionTypeProduct.GetListProductWarranty]({
    commit, dispatch
  }: ActionContext<IProductState, IState>,
                                                   {search, branch, department, status, pagination}) {
    try {
      commit(MutationTypeProduct.ProductRegistationLoading);
      const { page, limit } = pagination;
      const offset = (page - 1) * limit;
      const listWarranty = await product.getListWarranty(search, branch, department, status, limit, offset);
      if (listWarranty) {
        commit(MutationTypeProduct.SetProductWarranty, listWarranty);
      }
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    } finally {
      commit(MutationTypeProduct.ProductRegistationLoaded);
    }
  },
  async [ActionTypeProduct.GetDealerProductDetail]({
    commit, dispatch
  }: ActionContext<IProductState, IState>,
                                                   {id, search, deviceType, status, pagination}) {
    try {
      commit(MutationTypeProduct.ProductRegistationLoading);
      const { page, limit } = pagination;
      const offset = (page - 1) * limit;
      const detail = await product.getDetailDealerProduct(id);
      const listWarranty = await product.getDealerProductList(id, search, deviceType, status, limit, offset);
      if (detail) {
        commit(MutationTypeProduct.SetDealerProductDetail, detail);
      }
      if (listWarranty) {
        commit(MutationTypeProduct.SetDealerProductWarranty, listWarranty);
      }
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    } finally {
      commit(MutationTypeProduct.ProductRegistationLoaded);
    }
  },
  async [ActionTypeProduct.GetProductDetail]({
    dispatch
  }: ActionContext<IProductState, IState>,
                                             {id, onSuccess}) {
    try {
      const detail = await product.getProductDetail(id);
      if (onSuccess) {
        onSuccess(detail);
      }
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    }
  },
  async [ActionTypeProduct.GetDeviceType]({
    commit, dispatch
 }: ActionContext<IProductState, IState>) {
    try {
      const DeviceType = await product.getDeviceType();
      if (DeviceType) {
        commit(MutationTypeProduct.SetDeviceType, DeviceType);
      }
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    }
  },
  async [ActionTypeProduct.GetListProductRegistationSubDealer]({
    commit, dispatch
  }: ActionContext<IProductState, IState>,
                                                               {search, branch, order, pagination}) {
    try {
      commit(MutationTypeProduct.ProductRegistationLoading);
      const { page, limit } = pagination;
      const offset = (page - 1) * limit;
      const listProduct = await product.getListRegistationSubDealer(search, branch, order, limit, offset);
      if (listProduct) {
        commit(MutationTypeProduct.SetProductRegistation, listProduct);
      }
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    } finally {
      commit(MutationTypeProduct.ProductRegistationLoaded);
    }
  },
  async [ActionTypeProduct.GetStatistic]({
    dispatch
  }: ActionContext<IProductState, IState>,
                                         {onSuccess, onFailure}) {
    try {
      const statistic = await product.getStatistic();
      if (onSuccess) {
        onSuccess(statistic);
      }
    } catch (error) {
      if (onFailure) {
        onFailure();
      }
      dispatch(ActionType.CatchException, error);
    }
  },
};
