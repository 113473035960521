import { DocumentNode } from 'graphql';
import gql from 'graphql-tag';
import { crudQuery } from 'root/store/helpers/query';
import { ICRUDQuery } from '../Core/query';

const PREFIX_BANNERS = 'banners';

const schema = `
	id
	name
	createdAt
	createdBy
	targetType
	createdUser{
		id
		name
	}
	updatedAt
	updatedBy
	updatedUser{
		id
		name
	}
  target
	status
	url
	imageUrl
	type
	description
	orderNumber
  branchBanners{
    branchId
  }
`;
interface IBannerQuery extends ICRUDQuery {
  //
  UPDATE_BRANCH_BANNER: DocumentNode;
  INSERT_BRANCH_BANNER: DocumentNode;
  DELETE_BRANCH_BANNER: DocumentNode;
  CHANGE_CONFIG: DocumentNode;
}
export const BannerQuery: IBannerQuery = {
  ...crudQuery(PREFIX_BANNERS, schema),
  INSERT_BRANCH_BANNER: gql`
  mutation INSERT_BRANCH_BANNER($form: [branch_banners_insert_input!]!){
    insert_branch_banners(objects: $form){
      affected_rows
      returning {
        id
      }
    }
  }
`,
  UPDATE_BRANCH_BANNER: gql`
  mutation UPDATE_BRANCH_BANNER($id: uuid!, $set: branch_banners_set_input!){
    update_branch_banners(where: {bannerId: {_eq: $id}}, _set: $set){
      affected_rows
    }
  }
`,
  DELETE_BRANCH_BANNER: gql`
  mutation DELETE_BRANCH_BANNER($id: uuid!, $branchId: uuid!){
    delete_branch_banners(where: {bannerId: {_eq: $id}, branchId: {_eq: $branchId}}){
      affected_rows
    }
  }
  `,
  CHANGE_CONFIG: gql`
  mutation CHANGE_CONFIG($id: uuid!, $value: String!){
    update_system_configurations(where: {id: {_eq: $id}},  _set: {value: $value}){
      affected_rows
    }
  }
  `,
};
