import { CRUDGqlService, ICRUDGqlService } from '../Core/crud';
import { exampleQueries, EXAMPLE_PREFIX } from '../Query';

export interface IExampleGqlService extends ICRUDGqlService<any> {
  //
}
export function exampleGqlService(): IExampleGqlService {
  return {
    ...CRUDGqlService<any>(EXAMPLE_PREFIX, exampleQueries)
  };
}
