import { TabPane } from 'element-ui';
import moment from 'moment';
import { CustomCampaignRouterName, CustomCampaignTab } from 'root/admin/CustomCampaign';
import { SystemRoleAlias } from 'root/admin/User/Store/types';
import { IBreadcrumb, IUser } from 'root/models';
import { IState, MutationType } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import { ActionTypeRaCampaign } from '../../Store/types';
import './styles.scss';

@Component({
  template: require('./view.html'),
  computed: {
    ...mapState({
      authUser: (state: IState) => state.global.authUser,
    })
  }
})

export class RaCampaignConfiguration extends Vue {
  public get activeTab() {
    return CustomCampaignTab.RAConfiguration;
  }

  public set activeTab(_value: string) {
    //
  }
  public get isSalePlanning() {
    if (this.authUser.superAdmin || this.authUser.role.alias === SystemRoleAlias.Admin
      || this.authUser.role.alias === SystemRoleAlias.SaleAdmin) {
      return true;
    }

    return false;
  }
  public configId: string = '';
  public uploadFrom: Date = null;
  public authUser: IUser;
  public visibleEndcampaign: boolean = false;
  public maskSpecial: boolean = false;
  public campaignName: string = '';
  public startDate: Date = null;
  public endDate: Date = null;
  public isError: boolean = false;
  public endedCampaign: boolean = false;
  public changeTab(tab: TabPane) {
    switch (tab.name) {
    case CustomCampaignTab.RAParticipant:
      this.$router.push({
        name: CustomCampaignRouterName.RACampaign
      });
      break;
    case CustomCampaignTab.RAQualifiedShops:
      this.$router.push({
        name: CustomCampaignRouterName.RAQualifiedShops
      });
      break;
    case CustomCampaignTab.RAStatistic:
      this.$router.push({
        name: CustomCampaignRouterName.RAStatistic
      });
      break;
    case CustomCampaignTab.RAManageExport:
      this.$router.push({
        name: CustomCampaignRouterName.RAManageExport
      });
      break;
    case CustomCampaignTab.RAShops:
      this.$router.push({
        name: CustomCampaignRouterName.RAShops
      });
      break;
    case CustomCampaignTab.ConfirmationCode:
      this.$router.push({
        name: CustomCampaignRouterName.ConfirmationCode
      });
      break;
    case CustomCampaignTab.SubDealerRequest:
      this.$router.push({
        name: CustomCampaignRouterName.SubDealerRequest
      });
      break;
    case CustomCampaignTab.SubDealerCampaign:
      this.$router.push({
        name: CustomCampaignRouterName.SubDealerCampaign
      });
      break;
    default:
      break;
    }
  }
  public closeDialog() {
    this.visibleEndcampaign = false;
    this.isError = false;
    this.campaignName = '';
  }
  public handleEndCampaign() {
    if (this.campaignName && this.campaignName.toLowerCase() === 'room air campaign') {
      this.$store.dispatch(ActionTypeRaCampaign.EndCampaign, {
        id: this.configId,
        onSuccess: () => {
          this.$message({
            type: 'success',
            message: this.$t('message.endCampaign').toString()
          });
          this.fetchData();
          this.closeDialog();
        }
      });

      return;
    }
    this.isError = true;
  }
  public onClickEndCampaign() {
    this.visibleEndcampaign = true;
  }
  public FormatDateFullTime(date: Date) {
    return moment(date).format('DD/MM/YYYY');
  }
  public fetchData() {
    this.$store.dispatch(ActionTypeRaCampaign.GetCampaignConfiguration, {
      onSuccess: (config) => {
        this.configId = config[0].aclCampaignConfig.id;
        this.startDate = config[0].aclCampaignConfig.uploadStartDate;
        this.endDate = config[0].aclCampaignConfig.uploadEndDate;
        this.$store.dispatch(ActionTypeRaCampaign.GetMaskConfig, {
          id: this.configId,
          onSuccess: (maskConfig) => {
            this.maskSpecial = maskConfig[0].isSpecial;
            this.endedCampaign = maskConfig[0].isEnded;
            // this.startDate = maskConfig[0].startDate;
            // this.endDate = maskConfig[0].endDate;
          }
        });
      }
    });
  }
  public maskChange() {
    this.$store.dispatch(ActionTypeRaCampaign.MaskChange, {
      id: this.configId,
      mask: this.maskSpecial,
      onSuccess: () => {
        this.$message({
          type: 'success',
          message: this.$t('message.maskChange').toString()
        });
        this.closeDialog();
      }
    });
  }
  protected mounted() {
    this.$nextTick(() => {
      const breadcrumb: IBreadcrumb[] = [
        {
          label: 'title.campaign',
          path: null,
          current: false
        },
        {
          label: 'title.ra_campaign',
          path: null,
          current: true
        }
      ];
      this.$store.commit(MutationType.SetBreadcrumb, breadcrumb);

      this.fetchData();
    });
  }
}
