import { TabPane } from 'element-ui';
import { cloneDeep } from 'lodash';
import moment from 'moment-timezone';
import { ActionTypeBranchManage } from 'root/admin/BranchAndGroup/BranchManage/Store/types';
import { ActionTypeGroupManage } from 'root/admin/BranchAndGroup/GroupManage/Store/types';
import { ActionTypeProduct } from 'root/admin/Product/Store/types';
import { SystemRoleAlias } from 'root/admin/User/Store/types';
import { IPaginationParams } from 'root/api/graphql/Core';
import { fullNameUser } from 'root/helpers';
import { IAddress, IBreadcrumb, IFilterInput, IUser } from 'root/models';
import { IState, MutationType } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import { ProductManageRouterName, ProductRegistationTab } from '..';
import { ProductRegistationDetail } from '../DealerProducts/components/ProductRegistrationDetail';
import './styles.scss';

const enum FilterKey {
  Branch = 'branch',
  Department = 'department',
  Status = 'status'
}
export enum warrantyStatusCode {
  Success = 'activated',
  Failed = 'failed'
}
export enum warrantyStatus {
  Success = 'Success',
  Failed = 'Fail'
}
@Component({
  template: require('./view.html'),
  computed: {
    ...mapState({
      data: (state: IState) => state.product.data,
      loading: (state: IState) => state.product.loading,
      pagination: (state: IState) => state.product.pagination,
      authUser: (state: IState) => state.global.authUser,
      branch: (state: IState) => state.branchManage.data,
      group: (state: IState) => state.groupManage.data,
    }),
  },
  components: {
    detail: ProductRegistationDetail
  },
})

export class ProductRegistrationList extends Vue {
  public get serviceId(): string {
    return this.$route.params.id;
  }

  public get activeTab() {
    return ProductRegistationTab.ProductList;
  }

  public set activeTab(_value: string) {
    //
  }
  public get isSalePlanning() {
    if (this.authUser.role.alias === SystemRoleAlias.SaleAdmin) {
      return true;
    }

    return false;
  }
  public get dataFilter() {
    const _branch: IFilterInput[] = this.branch.reduce((res, item) => {
      res.push({
        key: item.id,
        value: item.id,
        name: item.name
      });

      return res;
    }, []);
    const _groups: IFilterInput = this.group.filter((type) => type.name !== 'Technician').reduce((res, item) => {
      res.push({
        key: item.id,
        value: item.id,
        name: item.name
      });

      return res;
    }, []);
    const _status: IFilterInput[] = [
      {
        key: warrantyStatusCode.Success,
        value: warrantyStatusCode.Success,
        name: warrantyStatus.Success
      },
      {
        key: warrantyStatusCode.Failed,
        value: warrantyStatusCode.Failed,
        name: warrantyStatus.Failed
      },
    ];

    // return [
    //   {
    //     key: FilterKey.Branch,
    //     name: 'Branch',
    //     value: _branch
    //   },
    //   {
    //     key: FilterKey.Department,
    //     name: 'Department',
    //     value: _groups
    //   },
    //   {
    //     key: FilterKey.Status,
    //     name: this.$t('field.status'),
    //     value: _status
    //   }
    // ];
    return this.isSalePlanning ? [{
      key: FilterKey.Branch,
      name: 'Branch',
      value: _branch
    },
    {
      key: FilterKey.Department,
      name: 'Department',
      value: _groups
    }] : [
      {
        key: FilterKey.Branch,
        name: 'Branch',
        value: _branch
      },
      {
        key: FilterKey.Department,
        name: 'Department',
        value: _groups
      },
      {
        key: FilterKey.Status,
        name: this.$t('field.status'),
        value: _status
      }
    ];
  }
  public get selectedFilter() {
    const _filterParams = cloneDeep(this.filterParams);

    return [
      {
        key: FilterKey.Branch,
        value: _filterParams[FilterKey.Branch]
      },
      {
        key: FilterKey.Department,
        value: _filterParams[FilterKey.Department]
      },
      {
        key: FilterKey.Status,
        value: _filterParams[FilterKey.Status]
      }
    ];
  }
  public get columns() {
    return [
      {
        prop: 'name',
        label: 'Dealer Name',
        labelItem: 'Code',
        formatter: (model: any) => {
          return fullNameUser(model) || '--';
        },
        avatar: {
          formatter: (model: any) => {
            return model ? model : null;
          }
        },
        width: 250,
        fixed: true
      },
      // {
      //   prop: 'address',
      //   label: 'Address',
      //   width: 220,
      //   formatter: (model: any) => {
      //     if (model.createdDealer && model.createdDealer.address) {
      //       return this.displayAddress(model.createdDealer.address) || '--';
      //     }

      //     return '--';
      //   }
      // },
      {
        prop: 'branch',
        label: 'Branch',
        width: 180,
        formatter: (model: any) => {
          if (model.createdDealer && model.createdDealer.branch && model.createdDealer.branch.name) {
            return model.createdDealer.branch.name;
          }

          return '--';
        }
      },
      {
        prop: 'department',
        label: 'Department',
        width: 180,
        formatter: (model: any) => {
          if (model.createdDealer && model.createdDealer.group && model.createdDealer.group.name) {
            return model.createdDealer.group.name;
          }

          return '--';
        }
      },
      {
        prop: 'modelNumber',
        label: 'Model Number',
        width: 200,
        formatter: (model: any) => {
          return model.modelNumber || '--';
        }
      },
      {
        prop: 'serialNumber',
        label: 'Serial Number',
        width: 200,
        formatter: (model: any) => {
          return model.serialNumber || '--';
        }
      },
      {
        prop: 'createAt',
        label: 'Date Created',
        width: 200,
        formatter: this.formatterCreatedDate
      },
      {
        prop: 'status',
        label: 'Status',
        width: 150,
        formatter: this.formatterWarrantyStatus,
        labelStyle: (model: any) => {
          return model.warrantyStatus;
        },
      },
      {
        prop: 'action',
        label: '',
        align: 'center',
        fixed: 'right',
        width: 60
      }
    ];
  }

  public searchText: string = '';
  public authUser: IUser;
  public dealerId: string = '';
  public visible: boolean = false;
  public productId: string = '';
  public pagination: IPaginationParams;
  public filterParams = {
    [FilterKey.Branch]: [],
    [FilterKey.Department]: [],
    [FilterKey.Status]: []
  };
  public branch: any[];
  public group: any[];
  public displayAddress(model: IAddress) {
    return model && model.province ? this.$store.getters['getAddressString'](model) : '--';
  }

  public getAction() {

    return 'view';
  }
  public handleActions(account) {
    this.dealerId = account.createdBy;
    this.productId = account.id;
    this.visible = true;
  }
  public changeTab(tab: TabPane) {
    switch (tab.name) {
    case ProductRegistationTab.DealerProduct:
      this.$router.push({
        name: ProductManageRouterName.DealerProduct
      });
      break;
    case ProductRegistationTab.SubDealerProduct:
      this.$router.push({
        name: ProductManageRouterName.SubDealerProduct
      });
      break;
    default:
      break;
    }
  }
  public formatterWarrantyStatus(model: any) {
    if (model && model.warrantyStatus) {
      switch (model.warrantyStatus) {
      case warrantyStatusCode.Success:
        return warrantyStatus.Success;
      case warrantyStatusCode.Failed:
        return warrantyStatus.Failed;
      }
    }

    return '--';
  }
  public formatterCreatedDate(model: any) {
    return model && model.createdAt ? moment(model.createdAt).format('HH:mm, DD/MM/YYYY') : '--';
  }
  public handleSearch() {
    this.fetchData();
  }

  public handlePaginationChange(value) {
    if (typeof value === 'number') {
      // this.$store.commit(MutationTypeAclCampaign.PaginationChange, {
      //   ...this.pagination,
      //   size: value
      // });
    } else {
      // this.$store.commit(MutationTypeAclCampaign.PaginationChange, {
      //   ...this.pagination,
      //   ...value
      // });
    }
    this.fetchData();
  }

  public editSuccess() {
    this.fetchData();
  }
  public changeFilter(model) {
    const branch = model.find((e) => e.key === FilterKey.Branch);
    const department = model.find((e) => e.key === FilterKey.Department);
    const status = model.find((e) => e.key === FilterKey.Status);
    const nextFilter = {
      [FilterKey.Branch]: branch ? branch.value : [],
      [FilterKey.Department]: department ? department.value : [],
      [FilterKey.Status]: status ? status.value : [],
    };
    this.filterParams = {
      ...cloneDeep(this.filterParams),
      ...nextFilter
    };
    this.fetchData();
  }

  protected mounted() {
    this.$nextTick(() => {
      const breadcrumb: IBreadcrumb[] = [
        {
          label: 'title.productRegistation',
          path: null,
          current: false
        },
      ];
      this.$store.commit(MutationType.SetBreadcrumb, breadcrumb);
      this.$store.dispatch(ActionTypeBranchManage.GetListBranch, {
        form: {
          filter: {},
          pagination: {}
        }
      });
      this.$store.dispatch(ActionTypeGroupManage.GetListGroupManage, {
        form: {
          filter: {},
          pagination: {}
        }
      });
      this.fetchData();
    });
  }
  protected beforeDestroy() {
    this.$store.commit(MutationType.ClearBreadcrumb);
  }

  private fetchData() {
    const text = cloneDeep(this.searchText);
    const _filterParams = <any> {};
    if (this.filterParams[FilterKey.Branch].length) {
      _filterParams.branch = this.filterParams[FilterKey.Branch];
    }
    if (this.filterParams[FilterKey.Department].length) {
      _filterParams.department = this.filterParams[FilterKey.Department];
    }
    if (this.filterParams[FilterKey.Status].length) {
      _filterParams.status = this.filterParams[FilterKey.Status];
    }

    this.$store.dispatch(ActionTypeProduct.GetListProductWarranty, {
      search: `%${text}%`,
      branch: _filterParams.branch,
      department: _filterParams.department,
      status: this.isSalePlanning ? warrantyStatusCode.Success : _filterParams.status,
      pagination: this.pagination
    });
  }
}
