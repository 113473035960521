export enum ActionTypeSaleInCampaign {
  FilterNoCache = 'saleInCampaignFilterNoCache',
  GetDealerType = 'getDealerType',
  GetConditionMetadata = 'GetConditionMetadata',
  CheckCampaignCodeExits = 'CheckCampaignCodeExits',
  GetParticipantList = 'GetParticipantListSaleInCampaign',
  Update = 'saleInCampaignUpdate',
  GetCampaignDetailById = 'GetSaleInCampaignDetailById',
  GetCampaignDetailParticipantList = 'GetSaleInCampaignDetailParticipantList',
  GetPinedCampaignsList = 'GetPinedCampaignsList',
  PinCampaign = 'PinSaleInCampaign',
  ChangeCampaignStatus = 'ChangeSaleInCampaignStatus',
  CreateCampaign = 'CreateSaleInCampaign',
  UpdateCampaign = 'UpdateSaleInCampaign',
  ImportSaleInCampaign = 'importSaleInCampaign',
  GetImportModelLogs = 'GetImportModelLogs',
  GetListQuantitySoldParticipant = 'GetListQuantitySoldParticipant',
  GetListRewardParticipant = 'GetListRewardParticipant',
  SendConfirm = 'saleInCampaignSendConfirm',
  ChangeParticipant = 'ChangeSaleInParticipant',
  UpdateCampaignOnInactive = 'UpdateCampaignOnInactive'
}
export enum MutationTypeSaleInCampaign {
  FilterChange = 'saleInCampaignFilterChange',
  PaginationChange = 'saleInCampaignPaginationChange',
  ChangeOrderBy = 'saleInCampaignChangeOrderBy',
  SetCampaignPinedList = 'setCampaignPinedList'
}
export enum ActionTypeSaleInProduct {
  FilterNoCache = 'saleInProductFilterNoCache',
  Create = 'SaleInProductCreate',
  Update = 'SaleInProductUpdate',
  Upsert = 'upsertSaleInProduct',
  CheckModelNumberExits = 'CheckModelNumberExits'
}
export enum MutationTypeSaleInProduct {
  FilterChange = 'saleInProductFilterChange',
  PaginationChange = 'saleInProductPaginationChange',
  ChangeOrderBy = 'saleInProductChangeOrderBy',
}
export enum ActionTypeSaleInReward {
  FilterNoCache = 'saleInRewardFilterNoCache',
  Create = 'SaleInRewardCreate',
  Update = 'SaleInRewardUpdate',
  CheckRewardCodeExits = 'CheckRewardCodeExits'
}
export enum MutationTypeSaleInReward {
  FilterChange = 'saleInRewardFilterChange',
  PaginationChange = 'saleInRewardPaginationChange',
  ChangeOrderBy = 'saleInRewardChangeOrderBy',
}
export enum ActionTypeSaleInLogs {
  FilterNoCache = 'saleInLogsFilterNoCache',
  GetImportModelLogs = 'GetHistoryImportModelLogs',
  GetImportLogsId = 'GetImportLogsId',
  GetParticipantLog = 'GetParticipantLog'
  // Create = 'SaleInRewardCreate',
  // Update = 'SaleInRewardUpdate',
}
export enum MutationTypeSaleInLogs {
  FilterChange = 'saleInLogsFilterChange',
  PaginationChange = 'saleInLogsPaginationChange',
  ChangeOrderBy = 'saleInLogsChangeOrderBy',
}
