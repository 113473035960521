export enum ActionTypeDealerType {
  GET_LIST_FEEDBACK = 'getListFeedback',
  FilterNoCache = 'dealerTypeFilterNoCache',
  UpdateDealerType = 'updateDealerType',
  CreateDealerType = 'createDealerType'
}
export enum MutationTypeDealerType {
  GetListFeedback = 'feedbackGetList',
  PaginationChange = 'dealerTypePaginationChange',
  FilterChange = 'dealerTypeFilterChange',
  DealerTypeLoading = 'dealerTypeLoading',
  DealerTypeLoaded = 'dealerTypeLoaded',
}
