import { stripObject } from '@hgiasac/helper';
import { Form, Message } from 'element-ui';
import { cloneDeep, omit } from 'lodash';
import { DKGqlClient } from 'root/api/graphql/Client';
import { resetOrientation, ruleRequired, ruleUrl } from 'root/helpers';
import { BannersFormDefault, BannerType, IBannersForm, IUser } from 'root/models';
import { pathUpload, sizeImage, uploadToFirebase } from 'root/services';
import { FirebaseAuthService } from 'root/services/auth/firebase';
import { ActionType, IState } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import { BannersActionType } from '../store/types';
import './styles.scss';

const targetType = [
  {
    value: 'ALL',
    label: 'All'
  },
  {
    value: 'SPECIFIC',
    label: 'Specific'
  }
];
@Component({
  template: require('./view.html'),
  props: {
    detailId: {
      type: String,
      required: true,
      default: ''
    },
    visible: {
      type: Boolean,
      required: true,
      default: false
    },
    detail: {
      type: Object,
      required: true,
      default: BannersFormDefault()
    }
  },
  computed: {
    ...mapState({
      authUser: (state: IState) => state.global.authUser,
      branch: (state: IState) => state.branchManage.data,
      isRefesh: (state: IState) => state.global.isRefesh
    }),
    cloneBranchOptions() {
      const branch: any[] = cloneDeep(this.branch);

      return branch.map((e) => {
        return {
          label: e.name,
          value: e.id
        };
      });
    },
  }
}
)

export class BannerEditor extends Vue {
  public get bannerTypeOptions() {
    return [
      {
        label: 'Default',
        value: BannerType.Default
      },
      {
        label: 'Link',
        value: BannerType.Link
      }
    ];
  }

  public get title() {
    return this.$props.detailId ? 'Edit Slider' : 'New Slider';
  }

  public get getDetailId() {
    return this.$props.detailId;
  }
  public form: IBannersForm = BannersFormDefault();
  public primaryPhotoUpload: File = null;
  public loading: boolean = false;
  public targetType = targetType;
  public authUser: IUser;
  public isSelecteBranch: boolean = false;
  public isError: boolean = false;
  public branchSelect = [];
  public addressText = '';
  public cloneBranch: any =[];
  public $refs: {
    form: Form;
  };

  public rules = {
    required: ruleRequired(),
    url: ruleUrl()
  };
  private pathUploadSpec: pathUpload = pathUpload.BANNERS;

  public uppercaseName(name: string) {
    return name.toUpperCase();
  }

  public async handleUploadPrimaryPhoto(file: any) {
    const _file = <any> await resetOrientation(file.raw, -1);
    const reader = new FileReader();
    reader.readAsDataURL(file.raw);
    reader.onload = (e: any) => {
      const fileHandle = e.target.result;
      const img = document.createElement('img');
      img.src = fileHandle;
      const width = img.width;
      const height = img.height;
      // tslint:disable-next-line: early-exit
      if (width < 500 || height < 500) {
        this.form.imageUrl = '';
        this.primaryPhotoUpload = null;
        Message.error(
            'Image size must be 500*500px or more'
        );
      } else {
        this.primaryPhotoUpload = file.raw;
        this.form.imageUrl = window.URL.createObjectURL(_file);
      }
    };
  }

  public onTypeChange(value) {
    if (value === BannerType.Default) {
      this.form.url = '';
    }
  }
  public changeBranch(value) {
    this.branchSelect = value;
    this.isError = false;
  }
  public changeType(value: string) {
    if (value === 'SPECIFIC') {
      return this.isSelecteBranch = true;
    }

    return this.isSelecteBranch = false;
  }
  public remoteMethodService(query: string) {
    this.addressText = query;
  }

  public submit() {
    const _addBranch = this.branchSelect.filter((it) => this.cloneBranch.indexOf(it) === -1);
    const _removeBranch = this.cloneBranch.filter((it) => this.branchSelect.indexOf(it) === -1);
    this.$refs.form.validate(async (valid) => {
      if (this.form.target === 'SPECIFIC' && this.branchSelect.length === 0) {
        this.isError = true;

        return;
      }
      // tslint:disable-next-line: early-exit
      if (valid) {
        this.loading = true;
        const form = cloneDeep(this.form);
        if (this.primaryPhotoUpload && form.imageUrl) {
          const token = await DKGqlClient().user.getFirebaseAuthenticateToken();
          await FirebaseAuthService().signInWithCustomToken(token.authenticate);
          const image = await uploadToFirebase(
            this.primaryPhotoUpload,
            this.pathUploadSpec,
            {
              ...sizeImage.message.original,
              quality: 1
            }
          );
          form.imageUrl = image;
        }
        if (this.$props.detailId) {
          this.$store.dispatch(BannersActionType.BannersUpdate, {
            form: {
              ...stripObject(omit(form, ['branchId', 'branchBanners']))
            },
            id: this.$props.detailId,
            onSuccess: (result) => {
              if (result && _addBranch.length) {
                this.$store.dispatch(BannersActionType.InsertBranchBanner, {
                  id: result.id,
                  form: {
                    branchId: _addBranch,
                    updatedBy: this.authUser.id
                  },
                  onSuccess: () => {
                    this.$emit('updatedSuccess');
                    this.loading = false;
                  },
                  onFailure: (err) => {
                    this.$store.dispatch(ActionType.CatchException, err);
                    this.loading = false;
                  }
                });
              }
              if (result && _removeBranch.length) {
                this.$store.dispatch(BannersActionType.RemoveBranchBanner, {
                  id: result.id,
                  form: {
                    branchId: _removeBranch,
                    updatedBy: this.authUser.id
                  },
                  onSuccess: () => {
                    this.$emit('updatedSuccess');
                    this.loading = false;
                  },
                  onFailure: (err) => {
                    this.$store.dispatch(ActionType.CatchException, err);
                    this.loading = false;
                  }
                });
              } else {
                this.$emit('updatedSuccess');
                this.closeDialog();
                this.loading = false;
              }
              this.$message.success(this.$t('update_successfully').toString());
              this.$emit('fetchData');
              this.closeDialog();
              this.loading = false;
            },
            onFailure: (err) => {
              this.$store.dispatch(ActionType.CatchException, err);
              this.loading = false;
            }
          });

          return;
        }
        this.$store.dispatch(BannersActionType.BannersCreate, {
          form: {
            ...stripObject(omit(form, ['branchId', 'branchBanners']))
          },
          onSuccess: (result) => {
            if (result && this.branchSelect.length) {
              this.$store.dispatch(BannersActionType.InsertBranchBanner
                , {
                  id: result.id,
                  form: {
                    branchId: this.branchSelect,
                    createdBy: this.authUser.id
                  },
                  onSuccess: () => {
                    this.$message.success(this.$t('update_successfully').toString());
                    this.$emit('updatedSuccess');
                    this.$emit('fetchData');
                    this.closeDialog();
                    this.loading = false;
                  },
                  onFailure: (err) => {
                    this.$store.dispatch(ActionType.CatchException, err);
                    this.loading = false;
                  }
                });
            } else {
              this.$message.success(this.$t('create_successfully').toString());
              this.$emit('updatedSuccess');
              this.closeDialog();
              this.loading = false;
            }

          },
          onFailure: (err) => {
            this.$store.dispatch(ActionType.CatchException, err);
            this.loading = false;
          }
        });
      }
    });
  }

  public openDialog() {
    this.form = cloneDeep(this.$props.detail);
    if (this.$props.detail && this.$props.detail.target === 'SPECIFIC') {
      this.isSelecteBranch = true;
      if (this.$props.detail.branchBanners && this.$props.detail.branchBanners.length) {
        this.branchSelect = this.$props.detail.branchBanners.map((it) => it.branchId);
        this.cloneBranch = cloneDeep(this.branchSelect);
      }
    }

    if (this.$refs.form) {
      this.$refs.form.clearValidate();
    }
  }

  public closeDialog() {
    this.$emit('closeBannerEditor');
    this.$refs.form.resetFields();
    this.form = BannersFormDefault();
    this.branchSelect = [];
    this.isSelecteBranch = false;
    this.primaryPhotoUpload = null;
  }
}
