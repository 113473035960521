import { ISimpleUser } from './User';

export interface IBannersForm {
  name: string;
  description: string;
  imageUrl: string;
  type: string;
  url: string;
  createdBy?: string;
  updatedBy?: string;
  branchBanners?: [];
  branchId?: any;
  targetType?: string;
  target?: string;
}
export function BannersFormDefault(): IBannersForm {
  return {
    description: '',
    name: '',
    imageUrl: '',
    type: '',
    url: '',
    branchId: [],
    targetType: '',
    target: ''
  };
}

export interface IBanners extends IBannersForm {
  id: string;
  createdUser?: ISimpleUser;
  updatedUser?: ISimpleUser;
  status?: string;
  updatedAt?: string;
  createdAt?: string;
  orderNumber?: number;
  branchBanners?: [];
  branchId?: any;
  targetType?: string;
  target?: string;
}
export function BannersDefault(): IBanners {
  return {
    ...BannersFormDefault(),
    id: ''
  };
}

export enum BannerType {
  Default = 'default',
  Link = 'link'
  // Shortcut = 'shortcut'
}
