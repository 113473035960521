export enum ActionTypeNotification {
  NotificationFilterNoCache = 'notificationsFilterNoCache',
  NotificationDelete = 'notificationsDelete',
  NotificationCreate = 'notificationsCreate',
  NotificationUpdate = 'notificationsUpdate',
  NotificationUpdateDealers = 'notificationUpdateDealer',
  ReadNotifications = 'ReadNotifications'
}

export enum MutationTypeNotification {
  NotificationLoading = 'notificationsLoading',
  NotificationLoaded = 'notificationsLoaded',
  SetNotificationPagination = 'notificationsPaginationChange',
  ResetNotificationPagination = 'notificationsPaginationReset',
  NotificationFilterChange = 'notificationsFilterChange',
}
