import { StatusCode } from './Common';
import { IDevicePart } from './DeviceParts';
import { ISimpleUser } from './User';

export interface IServiceCategoryForm {
  name: string;
  englishName: string;
  description: string;
  imageIcon: string;
  instruction: string;
  updatedBy?: any;
  createdBy?: any;
}
export interface IServiceCategory extends IServiceCategoryForm {
  id: string;
  status: StatusCode;
  createdAt: string;
  updatedAt: string;
}
export function serviceCategoryFormDefault(): IServiceCategoryForm {
  return {
    description: '',
    imageIcon: '',
    name: '',
    englishName: '',
    instruction: ''
  };
}
export function serviceCategoryDefault(): IServiceCategory {
  return {
    ...serviceCategoryFormDefault(),
    id: '',
    status: null,
    createdAt: null,
    createdBy: null,
    updatedAt: null,
    updatedBy: null
  };
}
export interface IServicesForm {
  name: string;
  englishName?: string;
  description: string;
  imageIcon: string;
  instruction: string;
  // isNew: boolean;
  category: string;
  browser?: string;
  link?: string;
  createdBy?: string;
  updatedBy?: string;
}
export interface IServices extends IServicesForm {
  createdAt: string;
  updatedAt?: string;
  serviceStatus: ServiceStatus;
  id: string;
  serviceCategory?: IServiceCategory;
  status?: StatusCode;
  color?: string;
  code?: EServiceType;
  priority: number;
  serviceCode?: string;
}
export function servicesFormDefault(): IServicesForm {
  return {
    name: '',
    englishName: '',
    description: '',
    imageIcon: '',
    instruction: '',
    // isNew: false,
    category: '',
    browser: '',
    link: ''
  };
}
export function serviceDefault(): IServices {
  return {
    ...servicesFormDefault(),
    id: '',
    createdAt: null,
    updatedAt: null,
    serviceStatus: ServiceStatus.Available,
    priority: 0
  };
}
export enum ServiceStatus {
  Available = 'available',
  Unavailable = 'unavailable'
}

export interface IServicePricingForm {
  deviceId: string;
  name: string;
  price: number;
  serviceId: string;
  description: string;
  createdBy?: string;
  updatedBy?: string;
}
export function servicePricingFormDefault(): IServicePricingForm {
  return {
    description: '',
    deviceId: '',
    name: '',
    price: null,
    serviceId: ''
  };
}
export interface ISerivcePricing extends IServicePricingForm {
  id: string;
  createdAt?: string;
  updatedAt?: string;
  device?: IDevicePart;
  service?: IServices;
  updatedUser?: ISimpleUser;
  createdUser?: ISimpleUser;
}
export function servicePricingDefault(): ISerivcePricing {
  return {
    ...servicePricingFormDefault(),
    id: '',
    device: null,
    service: null
  };
}
export interface ITransportPricingForm {
  name: string;
  description: string;
  serviceId: string;
  withinDistance: {
    from: number,
    to: number
  };
  price: number;
  createdBy?: string;
  updatedBy?: string;
}
export interface ITransportPricing extends ITransportPricingForm {
  id: string;
  service: IServices;
  updatedUser?: ISimpleUser;
  createdUser?: ISimpleUser;
  createdAt?: string;
  updatedAt?: string;
}
export function transportPricingFormDefault(): ITransportPricingForm {
  return {
    name: '',
    description: '',
    serviceId: '',
    price: null,
    withinDistance: {
      from: null,
      to: null
    }
  };
}
export function transportPricingDefault(): ITransportPricing {
  return {
    ...transportPricingFormDefault(),
    id: '',
    service: serviceDefault(),
    updatedUser: null,
    createdUser: null
  };
}

export enum EServiceType {
  Repair = 'repair',
  Maintenance = 'maintenance',
  Installation = 'installation'
}
export enum TechnicianServiceFilter {
  PartnerCode = 'partner.daikinCode',
  PartnerName = 'partner.name',
  TechnicianCode = 'technicianCode',
  TechnicianName = 'name',
  TechnicianPhone = 'phoneNumber',
  StationCode = 'partner.station.code',
  StationName = 'partner.station.name'
}

export interface IServiceOption {
  id?: string;
  name: string;
  englishName: string;
  image?: string;
  imageDescription?: string;
  description: string;
  englishDescription: string;
  createdAt?: string;
  createdBy?: string;
  createdUser?: ISimpleUser;
  updatedAt?: string;
  updatedBy?: string;
  updatedUser?: ISimpleUser;
  serviceId?: string;
  service?: IServices;
  status?: StatusCode;
  isDefault?: boolean;
  priority?: number;
}
export const serviceOptionFormDefault = (): IServiceOption => ({
  englishName: '',
  name: '',
  image: '',
  imageDescription: '',
  description: '',
  englishDescription: ''
});

export enum ServiceCategory {
  Technical = 'technical',
  NonTechnical = 'non_technical',
  BrowserBased = 'browser_based'
}

export enum BrowserType {
  InApp = 'in_app',
  External = 'external'
}
