// import { stripObject } from '@hgiasac/helper';
import { stripObject } from '@hgiasac/helper';
import { Form } from 'element-ui';
import { cloneDeep, omit } from 'lodash';
import moment from 'moment';
import { DKGqlClient } from 'root/api/graphql/Client';
import { Avatar } from 'root/components';
import { DKEditor } from 'root/components/DKCustomEditor';
import { Upload } from 'root/components/Upload';
import { resetOrientation, ruleMaxLength, rulePositiveNumber, ruleRequired } from 'root/helpers';
import { RedeemType, RewardType, StatusCode } from 'root/models';
import { redemptionFormDefault, IRedemptionForm } from 'root/models/Redemption';
import { IUser } from 'root/models/User';
import { pathUpload, sizeImage, uploadToFirebase } from 'root/services';
import { FirebaseAuthService } from 'root/services/auth/firebase';
import { IState, MutationType, TypeAlert } from 'root/store';
import sha256 from 'sha256';
import { crc32c } from 'unix-checksum';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapGetters, mapState } from 'vuex';
import { ActionTypeDealer } from '../../../Dealer/Store/types';
import { ActionTypeRedemption } from '../../Store/types';
// import { ActionTypeDealer } from '../../Store/types';
import './styles.scss';

export enum TargetRedeemOptions {
  All = 'all',
  Dealer = 'dealer',
  Technician = 'technician'
}

const rewardTypeOptions = [
  {
    value: RewardType.Static,
    label: RewardType.StaticLabel
  },
  {
    value: RewardType.Dynamic,
    label: RewardType.DynamicLabel
  }
];
const redeemTypeOptions = [
  {
    value: RedeemType.Single,
    label: RedeemType.SingleLabel
  },
  {
    value: RedeemType.Multiple,
    label: RedeemType.MultipleLabel
  }
];
@Component({
  template: require('./view.html'),
  components: {
    avatar: Avatar,
    upload: Upload,
    'dk-editor': DKEditor
  },
  props: {
    visible: Boolean,
    userId: String,
    fromCallCenter: Boolean
  },
  computed: {
    ...mapGetters(['branch', 'group']),
    ...mapState({
      authUser: (state: IState) => state.global.authUser,
      userDetailLoading: () => false,
    })
  }
})
export class RedemptionEditor extends Vue {
  public get isUpdating(): boolean {
    return !!this.userId;
  }
  public get rules() {
    return {
      required: ruleRequired(),
      max: ruleMaxLength(50),
      positive: rulePositiveNumber()
    };
  }

  public $refs: {
    form: Form;
  };
  public loading: boolean = false;
  public authUser: IUser;
  public userDetail: IUser;
  public form: IRedemptionForm = redemptionFormDefault();
  public detail: IUser;
  public route: any;
  public userId: string;
  public isRefesh: boolean;
  public fileUpload: File;
  public rewardTypeOptions = rewardTypeOptions;
  public redeemTypeOptions = redeemTypeOptions;
  public staticReward: boolean = true;
  public inputVisible: boolean = false;
  public inputValue: string = '';
  public dynamicTags: any[] = [];
  public voucherCode: any[] = [];
  public avaibleVoucher: any[] = [];
  public voucher: any[];
  public staticVoucher: string = '';
  public errorCode: boolean = false;
  public selectedRedeemType: string = '';
  public errorVoucherCode: boolean = false;

  public endDatePickerOption = {
    disabledDate: (time: any) => {
      const startDate = this.form.startDate;
      if (startDate) {
        return time.getTime() < moment(startDate) ;
      }

      return false;

    }
  };

  public targetOptions = [
    {
      value: TargetRedeemOptions.All,
      label: 'All'
    },
    {
      value: TargetRedeemOptions.Dealer,
      label: 'Dealer'
    },
    {
      value: TargetRedeemOptions.Technician,
      label: 'Technician'
    },
  ];

  private querySearchPartner: string = '';

  public changeForm() {
    //
  }
  public removeUpload() {
    this.form.image = '';
    this.fileUpload = null;
  }

  public async handleUpload(file: any) {
    // tslint:disable-next-line: whitespace
    const _file = <any>await resetOrientation(file.raw, -1);
    this.form.image = window.URL.createObjectURL(_file);
    this.fileUpload = _file;
  }

  public get pickerOptions() {
    return {
      disabledDate: (time) => {
        return moment(time) < moment(new Date()).subtract(1, 'days');
      }
    };
  }
  public inputCode(value: string) {
    if (value) {
      this.errorVoucherCode = false;
    }
  }

  public changeType(type: string) {
    if (type && type === RewardType.Static) {
      this.dynamicTags = [];
      this.voucherCode = [];
      this.avaibleVoucher = [];

      return this.staticReward = true;
    }

    return this.staticReward = false;
  }

  public async submit() {
    if (!this.voucherCode) {
      this.errorVoucherCode = true;
    }
    if (!this.dynamicTags.length && this.form.rewardType === RewardType.Dynamic) {
      this.errorVoucherCode = true;
    }
    this.$refs.form.validate(async (valid) => {
      if (valid) {
        this.errorVoucherCode = false;
        // if (this.form.rewardType === RewardType.Dynamic &&
        //  parseInt(this.form.quantity, 10) !== this.dynamicTags.length) {
        //   this.errorCode = true;

        //   return;
        // }
        if (this.form.quantity) {
          this.form.maxQuantity = this.form.quantity;
        }
        this.loading = true;
        let { image } = this.form;
        if (image && this.fileUpload) {
          const token = await DKGqlClient().user.getFirebaseAuthenticateToken();
          await FirebaseAuthService().signInWithCustomToken(token.authenticate);
          image = await uploadToFirebase(
            this.fileUpload,
            pathUpload.REDEMPTION,
            { ...sizeImage.message.original, quality: 1 }
          );
          this.form.image = image;
        }
        const form = <any> cloneDeep(this.form);
        this.handleSystemUser(form);
      }

      return;
    });
  }

  public back() {
    this.$emit('update:visible', false);
  }
  public openDialog() {
    if (this.userId) {
      const dataVoucher: any[] = [];
      this.form = this.$store.getters.getRedemptionDetail(this.userId);
      if (this.form && this.form.voucherCodes && this.form.voucherCodes.length) {
        this.form.voucherCodes.map((it) => {
          dataVoucher.push(it.hashedCode);
        });
      }
      this.staticReward = this.form.rewardType && this.form.rewardType === RewardType.Static ? true : false;
      this.form.voucherCodes = dataVoucher;
      this.dynamicTags = dataVoucher;
      if (this.form.rewardType === RewardType.Static) {
        this.staticVoucher = this.form.voucherCodes.length ? this.form.voucherCodes[0].code : '';
      }
      if (this.form && this.form.maxQuantity) {
        this.form.quantity = this.form.maxQuantity;
      }
    }
    setTimeout(() => {
      if (this.$refs.form) {
        this.$refs.form.clearValidate();
      }
    }, 0);

  }
  public closeDialog() {
    this.form = redemptionFormDefault();
    this.dynamicTags = [];
    this.staticReward = true;
    this.loading = false;
    this.errorVoucherCode = false;
    this.selectedRedeemType = '';
    this.back();
  }

  public remoteMethod(query: string) {
    if (query !== '') {
      this.querySearchPartner = query;
    }
  }
  public handleClose(tag) {
    if (!this.userId) {
      this.dynamicTags.splice(this.dynamicTags.indexOf(tag), 1);
      this.voucherCode.splice(this.dynamicTags.indexOf(tag), 1);
    }

  }

  public showInput() {
    this.inputVisible = true;
    // this.$nextTick(_ => {
    //   this.$refs.saveTagInput.$refs.input.focus();
    // });
  }

  public async handleInputConfirm() {
    const _inputValue = this.inputValue;
    const _dynamic = this.dynamicTags;
    if (_inputValue) {
      this.errorVoucherCode = false;
      const _voucher = await this.$store.dispatch(ActionTypeRedemption.SearchVoucher, {
        code: crc32c(sha256(_inputValue), 'HEX')
      });
      if (_voucher && _voucher.length && _voucher[0].reward.rewardStatus === StatusCode.Expired
        && _voucher[0].hashedCode === crc32c(sha256(_inputValue), 'HEX')) {
        this.avaibleVoucher.push(_inputValue);
      }
      if (_voucher && _voucher.length && _voucher[0].reward.rewardStatus !== StatusCode.Expired
        && _voucher[0].hashedCode === crc32c(sha256(_inputValue), 'HEX')) {
        this.voucherCode.indexOf(_inputValue) < 0 && this.voucherCode.push(_inputValue);
      }
      _dynamic.indexOf(_inputValue) < 0 && _dynamic.push(_inputValue) ;
    }
    if (_dynamic.length === parseInt(this.form.quantity, 10)) {
      this.errorCode = false;
    }
    this.inputVisible = false;
    this.inputValue = '';
  }

  protected mounted() {
    //
  }

  protected beforeDestroy() {
    this.querySearchPartner = '';
    // this.$store.commit(MutationTypeTechnicianRate.ResetState);
  }

  private async handleSystemUser(form: any) {
    const formattedForm = cloneDeep(form);
    const startDate = moment(this.form.startDate);
    const endDate = moment(this.form.endDate);
    if (
      moment().isBetween(startDate, endDate) ||
      (moment(moment().format('YYYY/MM/DD')).isSame(
        endDate.format('YYYY/MM/DD')
      ) &&
        moment(moment().format('YYYY/MM/DD')).isSame(
          startDate.format('YYYY/MM/DD')
        ))
    ) {
      formattedForm.rewardStatus = 'available';
    }
    if (moment().isBefore(startDate)) {
      formattedForm.rewardStatus = 'schedule';
    }
    if (this.userId) {
      // update

      this.$store.dispatch(ActionTypeRedemption.UpdateRedemption, {
        set: {
          ...omit(formattedForm, [
            '__typename',
            'updatedAt',
            'updatedBy',
            'createdAt',
            'createdBy',
            'createdUser',
            'updatedUser',
            'id',
            'voucherCodes',
            'quantity',
            'rewardType',
            'voucherCodes_aggregate',
            'maxQuantity',
            'usedQuantity'
          ]),
          updatedBy: this.authUser.id
        },
        id: this.userId,
        onSuccess: () => {
          this.loading = false;
          const message = 'Update Redeem successfully';
          this.$store.commit(MutationType.OpenTopAlert, {
            message,
            type: TypeAlert.Success
          });
          this.$store.dispatch(ActionTypeDealer.GetListDealer, {
            form: {
              filter: {},
              pagination: {
                size: 20,
                page: 1
              }
            }
          });
          this.closeDialog();
          this.$emit('updateSuccess');
        },
        onFailure: () => {
          this.loading = false;
        }
      });
    } else {
      // create
      // const dataVoucher: any[] = [];
      // if (this.dynamicTags.length && !formattedForm.voucherCodes) {
      //   this.dynamicTags.map((it) => {
      //     dataVoucher.push({
      //       code: it
      //     });
      //   });
      // }
      formattedForm.quantity = parseInt(formattedForm.quantity, 10);
      formattedForm.voucherCodes = this.dynamicTags.length ? this.dynamicTags : [formattedForm.voucherCodes];
      this.$store.dispatch(ActionTypeRedemption.CreateRedemption, {
        form: {
          ...stripObject(formattedForm),
          createdBy: this.authUser.id
        },
        reusedVouchder: this.avaibleVoucher,
        onSuccess: () => {
          this.loading = false;
          const message = 'Create Redeem successfully';
          this.$store.commit(MutationType.OpenTopAlert, {
            message,
            type: TypeAlert.Success
          });
          this.$store.dispatch(ActionTypeDealer.GetListDealer, {
            form: {
              filter: {},
              pagination: {
                size: 20,
                page: 1
              }
            }
          });
          this.closeDialog();
          this.$emit('createSuccess');
        },
        onFailure: () => {
          this.loading = false;
        }
      });
    }
  }
}
