import { cloneDeep, find } from 'lodash';
import { ISaleInCampaignState } from '.';

export const getters = {
  getSaleInCampaignDetail(state: ISaleInCampaignState) {
    return (id: string) => {
      return find(cloneDeep(state.data), (e) => e.id === id);
    };
  },
  getSaleInProductDetail(state: ISaleInCampaignState) {
    return (id: string) => {
      return find(cloneDeep(state.saleInProduct.data), (e) => e.id === id);
    };
  },
  getSaleInRewardDetail(state: ISaleInCampaignState) {
    return (id: string) => {
      return find(cloneDeep(state.saleInReward.data), (e) => e.id === id);
    };
  },
  getSaleInLogsDetail(state: ISaleInCampaignState) {
    return (id: string) => {
      return find(cloneDeep(state.saleInLogs.data), (e) => e.id === id);
    };
  },
};
