import { DKGqlClient } from 'root/api/graphql/Client';
import { ActionType, IState } from 'root/store';
import { crudActions } from 'root/store/helpers';
import { ActionContext } from 'vuex';
import { IFeedbackState } from './mutation';
import { ActionTypeFeedback, MutationTypeFeedback } from './types';

const { feedback } = DKGqlClient();
export const actions = {
  ...crudActions('feedback', {
    filter: feedback.filter,
  }),
  async [ActionTypeFeedback.GET_LIST_FEEDBACK]({
    commit, dispatch
  }: ActionContext<IFeedbackState, IState>) {
    try {
      commit(MutationTypeFeedback.FeedbackLoading);
      const FeedbackList = await feedback.getListFeedback();
      commit(MutationTypeFeedback.GetListFeedback, FeedbackList);
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    } finally {
      commit(MutationTypeFeedback.FeedbackLoaded);
    }
  },
  async [ActionTypeFeedback.UPDATE_STATUS]({
     dispatch
  }: ActionContext<IFeedbackState, IState>,
                                           {id, status, onSuccess, onFailure}) {
    try {
      const FeedbackList = await feedback.updateStatus(id, status);
      if (FeedbackList) {
        //
      }
      if (onSuccess) {
        dispatch(ActionTypeFeedback.FilterNoCache, {
          optionsHTTP: {
            orderBy: {
              updatedAt: 'desc'
            }
          }
        });
        onSuccess();
      }
    } catch (error) {
      if (onFailure) {
        onFailure();
      }
      dispatch(ActionType.CatchException, error);
    }
  },
  async [ActionTypeFeedback.GetOwnerDealer]({
    dispatch
  }: ActionContext<IFeedbackState, IState>, {shopId, onSuccess}) {
    try {
      // commit(MutationTypeFeedback.FeedbackLoading);
      const DealerOwner = await feedback.getOwner(shopId);
      if (onSuccess) {
        onSuccess(DealerOwner);
      }
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    }
  },
};
