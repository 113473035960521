// import { PermissionType } from 'root/models';
import { PermissionType } from 'root/models';
import { RouteConfig } from 'vue-router';
import { ManageInvoiceContainer } from './ManageInvoiceContainer';
import { ManageInvoiceList } from './ManageInvoiceList';
// import { ManageInvoiceContainer } from './ManageInvoiceContainer';
// import { ManageInvoiceDetail } from './ManageInvoiceDetail';
// import { ManageInvoiceDetail } from './ManageInvoiceDetail';
// import { ManageInvoiceEditor } from './ManageInvoiceEditor';
// import { ManageInvoiceList } from './ManageInvoiceList';

export enum ManageInvoiceRouterPath {
  Default = '/manage-invoice',
  ListManageInvoice = '/manage-invoice',
}
export enum ManageInvoiceRouterName {
  ListManageInvoice = 'ListManageInvoice',
}

export const manageInvoiceRouter: RouteConfig = {
  path: '/manage-invoice',
  component: ManageInvoiceContainer,
  meta: {
    permission: PermissionType.ManageInvoice
  },
  children: [
    {
      path: ManageInvoiceRouterPath.ListManageInvoice,
      component: ManageInvoiceList,
      name: ManageInvoiceRouterName.ListManageInvoice,
      meta: {
        permission: PermissionType.ManageInvoice
      }
    },
    // {
    //   path: ManageInvoiceRouterPath.Profile,
    //   component: ManageInvoiceList,
    //   name: ManageInvoiceRouterName.Profile,
    //   meta: {
    //     // permission: PermissionType.Customer
    //   }
    // },
    // {
    //   path: ManageInvoiceRouterPath.System,
    //   component: ManageInvoiceList,
    //   name: ManageInvoiceRouterName.System,
    //   meta: {
    //     permission: PermissionType.SystemManageInvoices
    //   }
    // },
    // {
    //   path: ManageInvoiceRouterPath.Technician,
    //   component: ManageInvoiceList,
    //   name: ManageInvoiceRouterName.Technician,
    //   meta: {
    //     permission: PermissionType.Technician
    //   }
    // },
    // {
    //   path: ManageInvoiceRouterPath.Create,
    //   component: ManageInvoiceEditor,
    //   name: ManageInvoiceRouterName.Create,
    // },
    // {
    //   path: ManageInvoiceRouterPath.Detail,
    //   component: ManageInvoiceDetail,
    //   name: ManageInvoiceRouterName.Detail
    // }, {
    //   path: ManageInvoiceRouterPath.Edit,
    //   component: ManageInvoiceEditor,
    //   name: ManageInvoiceRouterName.Edit
    // }
  ]
};
