export const CityData = [
  {
    id: 1,
    value: 'An Giang'
  },
  {
    id: 2,
    value: 'Bà Rịa - Vũng Tàu'
  },
  {
    id: 3,
    value: 'Bắc Giang'
  },
  {
    id: 4,
    value: 'Bắc Kạn'
  },
  {
    id: 6,
    value: 'Bắc Ninh'
  },
  {
    id: 7,
    value: 'Bến Tre'
  },
  {
    id: 8,
    value: 'Bình Định'
  },
  {
    id: 9,
    value: 'Bình Dương'
  },
  {
    id: 10,
    value: 'Bình Phước'
  },
  {
    id: 11,
    value: 'Bình Thuận'
  },
  {
    id: 12,
    value: 'Cà Mau'
  },
  {
    id: 13,
    value: 'Cần Thơ'
  },
  {
    id: 15,
    value: 'Đắk Lắk'
  },
  {
    id: 16,
    value: 'Đắk Nông'
  },
  {
    id: 17,
    value: 'Đà Nẵng'
  },
  {
    id: 18,
    value: 'Đồng Nai'
  },
  {
    id: 19,
    value: 'Đồng Tháp'
  },
  {
    id: 20,
    value: 'Điện Biên'
  },
  {
    id: 21,
    value: 'Gia Lai'
  },
  {
    id: 22,
    value: 'Hà Nam'
  },
  {
    id: 23,
    value: 'Hà Nội'
  },
  {
    id: 24,
    value: 'Hà Giang'
  },
  {
    id: 25,
    value: 'Hà Tĩnh'
  },
  {
    id: 26,
    value: 'Hải Dương'
  },
  {
    id: 27,
    value: 'Hải Phòng'
  },
  {
    id: 28,
    value: 'Hậu Giang'
  },
  {
    id: 29,
    value: 'Hòa Bình'
  },
  {
    id: 30,
    value: 'Hưng Yên'
  },
  {
    id: 31,
    value: 'Khánh Hòa'
  },
  {
    id: 32,
    value: 'Kiên Giang'
  },
  {
    id: 33,
    value: 'Kon Tum'
  },
  {
    id: 34,
    value: 'Lai Châu'
  },
  {
    id: 35,
    value: 'Lâm Đồng'
  },
  {
    id: 36,
    value: 'Lạng Sơn'
  },
  {
    id: 37,
    value: 'Lào Cai'
  },
  {
    id: 38,
    value: 'Long An'
  },
  {
    id: 39,
    value: 'Nam Định'
  },
  {
    id: 40,
    value: 'Nghệ An'
  },
  {
    id: 41,
    value: 'Ninh Bình'
  },
  {
    id: 42,
    value: 'Ninh Thuận'
  },
  {
    id: 43,
    value: 'Phú Thọ'
  },
  {
    id: 44,
    value: 'Quảng Bình'
  },
  {
    id: 45,
    value: 'Quảng Nam'
  },
  {
    id: 46,
    value: 'Quảng Ngãi'
  },
  {
    id: 47,
    value: 'Quảng Ninh'
  },
  {
    id: 48,
    value: 'Quảng Trị'
  },
  {
    id: 49,
    value: 'Sóc Trăng'
  },
  {
    id: 50,
    value: 'Sơn La'
  },
  {
    id: 51,
    value: 'Tây Ninh'
  },
  {
    id: 52,
    value: 'Thái Bình'
  },
  {
    id: 53,
    value: 'Thái Nguyên'
  },
  {
    id: 54,
    value: 'Thanh Hóa'
  },
  {
    id: 55,
    value: 'Thừa Thiên Huế'
  },
  {
    id: 56,
    value: 'Tiền Giang'
  },
  {
    id: 57,
    value: 'Tuyên Quang'
  },
  {
    id: 58,
    value: 'Tuyên Quang'
  },
  {
    id: 59,
    value: 'Hồ Chí Minh'
  },
  {
    id: 60,
    value: 'Vĩnh Long'
  },
  {
    id: 61,
    value: 'Vĩnh Phúc'
  },
  {
    id: 62,
    value: 'Yên Bái'
  },
  {
    id: 63,
    value: 'Phú Yên'
  },
  {
    id: 81,
    value: 'Bạc Liêu'
  },
  {
    id: 3748,
    value: 'Cao Bằng'
  },
  {
    id: 3749,
    value: 'Mỹ Tho'
  },
];
