export enum ActionTypeCampaign {
  FilterNoCache = 'campaignFilterNoCache',
  UpdateStatusCampaign = 'campaignUpdateStatus',
  CreateCampaign = 'campaignCreate',
  UpdateCampaign = 'campaignUpdate',
  GetList = 'campaignGetList',
  GetCampaignDetail = 'campaignGetDetail',
  GetListCampaign = 'getListCampaign',
  UpdateCampaignBranch = 'UpdateCampaignBranch'
}
export enum MutationTypeCampaign {
  FilterChange = 'campaignFilterChange',
  ChangeOrderBy = 'campaignChangeOrderBy',
  GetList = 'campaignGetList',
  GetCampaignDetail = 'campaignGetDetail',
  PaginationChange = 'campaignPaginationChange',
  CampaignLoading = 'campaignLoading',
  CampaignLoaded = 'campaignLoaded',
  SetCampaignList = 'setCampaignList'
}
