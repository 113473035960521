import { DocumentNode } from 'graphql';
import gql from 'graphql-tag';
import { crudQuery, ICRUDQuery } from '../Core/query';

const roleModel = `
id
name
permissions
status
pointType
updatedAt
updatedBy
systemGroupId
systemGroup {
  name
}
`;

export const Role = 'system_roles';
// tslint:disable-next-line:no-empty-interface
interface IRolesQuery extends ICRUDQuery {
  GET_LIST_ROLE: DocumentNode;
  UPDATE_SYSTEM_ROLE_TYPE: DocumentNode;
}
export const getListRoleQuery: IRolesQuery = {
  ...crudQuery(Role, roleModel),
  GET_LIST_ROLE: gql`
  query GET_LIST_ROLE {
    system_roles {
      ${roleModel}
    }
  }
`,
  UPDATE_SYSTEM_ROLE_TYPE: gql`
  mutation UPDATE_SYSTEM_ROLE_TYPE($id: uuid!, $type: String!){
    update_system_roles(where: {id: {_eq: $id}}, _set:{pointType: $type}){
      affected_rows
    }
  }
  `
};
