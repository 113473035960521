import './styles.scss';

// import moment from 'moment';
import { stripObject } from '@hgiasac/helper';
import { cloneDeep } from 'lodash';
import { ActionTypeTeamStaff, MutationTypeTeamStaff } from 'root/admin/TeamStaff/Store/types';
import { UserDetailModal } from 'root/admin/User/Components/UserDetailModal';
import { ActionTypeUser, MutationTypeUser, SystemRoleAlias } from 'root/admin/User/Store/types';
import { UserEditor } from 'root/admin/User/UserEditor';
import { IPaginationParams } from 'root/api/graphql/Core';
import { Avatar } from 'root/components/Avatar';
import { fullNameUser } from 'root/helpers';
import { IBreadcrumb, IFilterInput, StatusCode } from 'root/models';
import { IUser } from 'root/models/User';
import { IState, MutationType } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import { BranchAndGroupRouterPath } from '../..';
import { MutationTypeGroupManager } from '../../GroupManage/Store/types';

const enum FilterKey {
  Status = 'status'
}
@Component({
  template: require('./view.html'),
  components: {
    avatar: Avatar,
    'user-detail-modal': UserDetailModal,
    editor: UserEditor
  },
  computed: {
    ...mapState({
      authUser: (state: IState) => state.global.authUser,
      data: (state: IState) => state.teamStaff.data,
      pagination: (state: IState) => state.teamStaff.pagination,
      loading: (state: IState) => state.teamStaff.loading,
      // filterParams: (state: IState) => state.group.filterParams,
      isRefesh: (state: IState) => state.global.isRefesh
    }),
    selectedStatus() {
      const filterParams = [];

      if (!filterParams) {
        return [];
      }

      return [{
        key: 'isCreatedByAppleReviewer',
        value: []
      }];
    }
  },
  watch: {
    type() {
      this.paramsFilter = {
        text: ''
      };
      // this.$store.commit(MutationTypeUser.ClearUserList);
      this.$store.commit(MutationType.SetBreadcrumb, this.$t(this.type));
      this.fetchData();
    }
  },
})

export class BranchStaffList extends Vue {
  public get serviceId(): string {
    return this.$route.params.id;
  }
  public get columns() {
    return [
      {
        prop: 'name',
        label: 'Name',
        width: 180,
        formatter: (model: any) => {
          if (model.manager) {
            return model.manager.name || '--';
          }
        },
        avatar: {
          formatter: (model: any) => {
            return model ? model.manager : null;
          }
        },
      },
      {
        prop: 'gender',
        label: 'Gender',
        width: 180,
        formatter: (model: any) => {
          return model.manager && model.manager.gender ? model.manager.gender : '--';
        }
      },
      {
        prop: 'email',
        label: 'email',
        width: 180,
        formatter: (model: any) => {
          return model.manager && model.manager.email ? model.manager.email : '--';
        }
      },
      {
        prop: 'phone',
        label: 'Phone',
        width: 180,
        formatter: (model: any) => {
          if (model.manager) {
            return model.manager.phoneNumber || '--';

          }

          return '--';
        }
      },
      {
        prop: 'status',
        label: this.$t('status'),
        formatter: (model: any) => model.manager && model.manager.status ? this.$t(model.manager.status) : '',
        labelStyle: (model: any) => {
          return model.manager.status;
        },
        align: 'center',
        width: 180
      }
    ];

  }

  public authUser: IUser;
  public pagination: IPaginationParams;
  public data: any[];
  public deviceType: any[];
  public visible: boolean = false;
  public isSample: boolean = false;
  public userId: string = '';
  public visibleDetail: boolean = false;
  public popupVisible: boolean = false;
  public filterParams = {
    [FilterKey.Status]: [],
  };
  public searchText: string = '';
  public isRefesh: boolean;

  public get inputSearchPlaceholder(): string {

    return `Search by Name, Email`;
  }

  public UserType = {
    Customer: 'customer',
    System: 'system',
  };
  public type: any;
  // tslint:disable-next-line:max-line-length
  private sampleUrl = `https://firebasestorage.googleapis.com/v0/b/daikin-test-staging.appspot.com/o/technicians%2Ftechnicians-import-sample.xlsx?alt=media&token=279661a1-11fe-49a0-8109-364e964ec11b`;

  public handleSearch() {
    this.fetchData();
  }

  public closeDialogDetail() {
    this.userId = '';
    this.visibleDetail = false;
  }

  public createSuccess() {
    this.visible = false;
    this.fetchData();
  }
  public get actions() {
    if (this.authUser && (this.authUser.role.alias === SystemRoleAlias.SaleAdmin ||
      this.authUser.role.alias === SystemRoleAlias.Admin)) {
      return ['view', 'edit'];
    }

    return ['view'];
  }
  public applyFilter() {
    this.fetchData();
  }
  public goAddNewRouter() {
    this.visible = true;
    this.userId = '';
  }
  public closeDialog() {
    this.isSample = false;
    this.fetchData();
  }
  public downloadSample() {
    window.open(
      this.sampleUrl,
      '_blank'
    );
  }

  public clickDetail(id: string) {
    this.userId = this.data.filter((it) => it.id === id)[0].manager.id;
    this.visibleDetail = true;
  }
  public handleUpdate(id: string) {
    this.visible = true;
    this.userId = this.data.filter((it) => it.id === id)[0].manager.id;
  }
  public get dataFilter() {
    const _status: IFilterInput[] = [
      {
        key: StatusCode.Active,
        value: StatusCode.Active,
        name: this.$t(StatusCode.Active)
      },
      {
        key: StatusCode.Blocked,
        value: StatusCode.Blocked,
        name: this.$t(StatusCode.Blocked)
      }
    ];

    return [
      {
        key: FilterKey.Status,
        name: this.$t('field.status'),
        value: _status
      },
    ];
  }
  public get selectedFilter() {
    const _filterParams = cloneDeep(this.filterParams);

    return [
      {
        key: FilterKey.Status,
        value: _filterParams[FilterKey.Status]
      },
    ];
  }

  public changeFilter(model) {
    const deviceType = model.find((e) => e.key === FilterKey.Status);
    const nextFilter = {
      [FilterKey.Status]: deviceType ? deviceType.value : [],
    };
    this.filterParams = {
      ...cloneDeep(this.filterParams),
      ...nextFilter
    };
    this.fetchData();
  }
  public handlePaginationChange(value) {
    if (typeof value === 'number') {
      this.$store.commit(MutationTypeGroupManager.PaginationChange, {
        ...this.pagination,
        limit: value
      });
    } else {
      this.$store.commit(MutationTypeGroupManager.PaginationChange, {
        ...this.pagination,
        ...value
      });
    }
    this.fetchData();
  }

  public formatFullNameUser(model) {
    return fullNameUser(model);
  }

  protected mounted() {
    this.$nextTick(() => {
      const breadcrumb: IBreadcrumb[] = [
        {
          label: 'title.managegroup',
          path: BranchAndGroupRouterPath.ListGroupManage,
          current: false
        },
        {
          label: 'title.view_details',
          path: null,
          current: true
        }
      ];
      this.$store.commit(MutationType.SetBack);
      this.$store.commit(MutationType.SetBreadcrumb, breadcrumb);
      // this.$store.commit(MutationTypeUser.PaginationReset);
      !this.isRefesh && this.fetchData();
    });
  }
  protected beforeDestroy() {
    this.$store.commit(MutationType.ClearBreadcrumb);
  }

  private fetchData() {
    const text = cloneDeep(this.searchText);
    const _filterParams =<any> {
      group: this.serviceId
    };
    if (this.filterParams[FilterKey.Status].length) {
      _filterParams.status = this.filterParams[FilterKey.Status];
    }
    this.$store.commit(
      MutationTypeTeamStaff.FilterChange, {
        mode: 'special',
        branchId: this.serviceId,
        manager: {
          role: {
            alias:  SystemRoleAlias.Staff
          }
        }
      }
      );
    if (text !== '' || this.filterParams[FilterKey.Status].length) {
      this.$store.commit(
        MutationTypeTeamStaff.FilterChange,
        stripObject({
          [FilterKey.Status]: this.filterParams[FilterKey.Status],
          branchId: this.serviceId,
          _and:
            text.trim() !== ''
              ? {
                mode: 'special',
                data: {
                  branchId: {
                    _eq: this.serviceId
                  },
                  manager: {
                    role: {
                      alias: {
                        _eq: SystemRoleAlias.Staff
                      }
                    }
                  },
                  _or: [
                    {
                      manager: {
                        name: {
                          _ilike: `%${text}%`
                        }
                      }
                    },
                    {
                      manager: {
                        email: {
                          _ilike: `%${text}%`
                        }
                      }
                    }
                  ]
                }
              } : {
                groupId: this.serviceId,
                manager: {
                  role: {
                    alias:  SystemRoleAlias.Staff
                  }
                }
              }
        }));
    }
    this.$store.dispatch(ActionTypeTeamStaff.FilterNoCache);
    this.$store.commit(MutationTypeUser.FilterChange, {
      role: {
        alias:  SystemRoleAlias.Staff
      }
    });
    this.$store.dispatch(ActionTypeUser.FilterNoCache);

  }
}
