import { PermissionType } from 'root/models';
import { RouteConfig } from 'vue-router';
import { ManageImportContainer } from './ManageImportContainer';
import { ManageImportList } from './ManageImportList';
import { ManageImportSubDealer } from './ManageImportSubDealer';

export enum ManageImportRouterPath {
  ManageImportList = '/manage-import',
  ManageImportDealer = '/manage-import/dealer',
  ManageImportSubDealer = '/manage-import/sub-dealer',
}

export enum ManageImportRouterName {
  ManagerImportList = 'ListManageImport',
  ManagerImportDealerList = 'ListManageImportDealer',
  ManagerImportSubDealerList = 'ListManageImportSubDealer',
}

export const manageImportRouter: RouteConfig = {
  path: '/manage-import',
  component: ManageImportContainer,
  meta: {
    permission: PermissionType.ManageImport
  },
  children: [
    {
      path: ManageImportRouterPath.ManageImportDealer,
      component: ManageImportList,
      name: ManageImportRouterName.ManagerImportDealerList,
      meta: {
        permission: PermissionType.ManageImport
      }
    },
    {
      path: ManageImportRouterPath.ManageImportSubDealer,
      component: ManageImportSubDealer,
      name: ManageImportRouterName.ManagerImportSubDealerList,
      meta: {
        permission: PermissionType.ManageImport
      }
    },
  ]
};
