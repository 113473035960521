import { PermissionType } from 'root/models';
import { RouteConfig } from 'vue-router';
import { RequestReviewList } from './RequestReviewList';
import { ZPCampaignParticipant } from './ZPCampaignParticipant';
import { ZPCampaignStatistic } from './ZPCampaignStatistic';
import { ZPConfiguration } from './ZPConfiguration';
import { ZeroPercentCampaignContainer } from './ZeroPercentCampaignContainer';

export enum ZeroPercentCampaignTab {
  Participant = 'ZPParticipant',
  Statistic = 'ZPStatistic',
  RequestReview = 'requestReview',
  Configuration = 'configuration'
}
export enum ZeroPercentCampaignRouterPath {
  Participant = '/zero-percent-campaign/participant',
  Statistic = '/zero-percent-campaign/statistic',
  RequestReview = '/zero-percent-campaign/request',
  Configuration = '/zero-percent-campaign/configuration',
}

export enum ZeroPercentCampaignRouterName {
  Participant = 'ZPParticipant',
  Statistic = 'ZPStatistic',
  RequestReview = 'requestReview',
  Configuration = 'configuration'
}
export const zeroPercentCampaignRouter: RouteConfig = {
  path: '/zero-percent-campaign',
  component: ZeroPercentCampaignContainer,
  redirect: ZeroPercentCampaignRouterPath.Participant,
  meta: {
    permission: PermissionType.PerCampaign
  },
  children: [
    {
      path: ZeroPercentCampaignRouterPath.Participant,
      component: ZPCampaignParticipant,
      name: ZeroPercentCampaignRouterName.Participant,
      meta: {
        permission: PermissionType.PerCampaign
      },
    },
    {
      path: ZeroPercentCampaignRouterPath.Statistic,
      component: ZPCampaignStatistic,
      name: ZeroPercentCampaignRouterName.Statistic,
      meta: {
        permission: PermissionType.PerCampaign
      },
    },
    {
      path: ZeroPercentCampaignRouterPath.RequestReview,
      component: RequestReviewList,
      name: ZeroPercentCampaignRouterName.RequestReview,
      meta: {
        permission: PermissionType.PerCampaign
      },
    },
    {
      path: ZeroPercentCampaignRouterPath.Configuration,
      component: ZPConfiguration,
      name: ZeroPercentCampaignRouterName.Configuration,
      meta: {
        permission: PermissionType.PerCampaign
      },
    },
  ]
};
