import moment from 'moment';
import { gqlClient } from '../Core';
import { CRUDGqlService, ICRUDGqlService } from '../Core/crud';
import { zeroCampaign, zeroCampaignQuery } from '../Query/zeroCampaign';

export interface IZeroCampaignGqlService extends ICRUDGqlService<any> {
  ip_campaign_companies: ICRUDGqlService<any>;
  getZeroCampaignById(id: string): Promise<any>;
  updateSalesmanInfo(id: string, set: any): Promise<any>;
  getListIdFinancialCompany(alias: any): Promise<any>;
  updateFinancialCompany(campaignId: string, id: any, createdBy: string): Promise<any>;
  approveRejectCampaign(id: string, companyStatus: string, reason: string,
                        updatedBy: string, updatedAt: string): Promise<any>;
  importPosCode(form: any): Promise<any>;
  getConfig(): Promise<any>;
  forceCloseCode(form: any): Promise<any>;
  exportCode(form: any): Promise<any>;
  getStatistic(time: any): Promise<any>;
  getStatisticTable(time: any): Promise<any>;
  getStatisticByBranch(name: string, time: any): Promise<any>;
  getStatisticForDepartmentPic(): Promise<any>;
  updateOwnerInfo(shopId: string, set: any): Promise<any>;
  updatePOSCode(id: string, posCode: string): Promise<any>;
  approveCampaign(id: string, set: any): Promise<any>;
  getStatisticListParticipant(where: any): Promise<any>;
  approveAll(ids: any, set: any): Promise<any>;
  getStatisticTopList(form: any): Promise<any>;
  getStatisticTopListShop(form: any): Promise<any>;
  exportCampaignOverview(form: any): Promise<any>;
  getStatisticListParticipantSaleAdmin(): Promise<any>;
  getConfiguration(): Promise<any>;
  updateConfiguration(key: any, status: any, reason: string, updatedBy: string): Promise<any>;
  getConfigHistoryLogs(limit: any, offset: any): Promise<any>;
}
export function zeroCampaignGqlService(): IZeroCampaignGqlService {
  async function getZeroCampaignById(id: string): Promise<any> {
    const results = await gqlClient.query({
      query: zeroCampaignQuery.GET_BY_ID,
      variables: {
        id
      },
      fetchPolicy: 'network-only'
    });
    if (results.errors) {
      throw results.errors;
    }

    return results.data.ip_campaigns;
  }

  async function updateSalesmanInfo(id: string, set: any): Promise<any> {
    const results = await gqlClient.mutate({
      mutation: zeroCampaignQuery.UPDATE_SALESMAN_INFO,
      variables: {
        id, set
      },
    });
    if (results.errors) {
      throw results.errors;
    }

    return results.data;
  }
  async function getListIdFinancialCompany(alias: any): Promise<any> {
    const results = await gqlClient.query({
      query: zeroCampaignQuery.GET_LIST_COMPANY_ID,
      variables: {
        alias
      },
      fetchPolicy: 'network-only'
    });
    if (results.errors) {
      throw results.errors;
    }

    return results.data.financial_companies;
  }
  async function updateFinancialCompany(campaignId: string, id: any, createdBy: string): Promise<any> {
    if (!id.length) {
      return;
    }
    const object = [];
    id.map((it) => {
      object.push({campaignId, createdBy, companyId: it.id});
    });
    const results = await gqlClient.mutate({
      mutation: zeroCampaignQuery.UPDATE_FINANCIAL_COMPANY,
      variables: {
        object
      },
    });
    if (results.errors) {
      throw results.errors;
    }

    return results.data;
  }
  async function approveRejectCampaign(id: string, companyStatus: string,
                                       reason: string, updatedBy: string, updatedAt: string): Promise<any> {
    const results = await gqlClient.mutate({
      mutation: zeroCampaignQuery.APPROVE_REJECT_CAMPAIGN,
      variables: {
        id, companyStatus, reason, updatedBy, updatedAt
      },
    });
    if (results.errors) {
      throw results.errors;
    }

    return results.data;
  }
  async function importPosCode(form: any): Promise<any> {
    const results = await gqlClient.mutate({
      mutation: zeroCampaignQuery.IMPORT_POS_CODE,
      variables: {
        objects: form
      },
    });
    if (results.errors) {
      throw results.errors;
    }

    return results;
  }
  async function getConfig(): Promise<any> {
    const results = await gqlClient.query({
      query: zeroCampaignQuery.GET_CONFIG,
      fetchPolicy: 'network-only'
    });
    if (results.errors) {
      throw results.errors;
    }

    return results.data.campaigns;
  }
  async function forceCloseCode(form: any): Promise<any> {
    const results = await gqlClient.mutate({
      mutation: zeroCampaignQuery.FORCE_CLOSE_CODE,
      variables: {
        form
      },
    });
    if (results.errors) {
      throw results.errors;
    }

    return results;
  }
  async function exportCode(form: any): Promise<any> {
    const results = await gqlClient.mutate({
      mutation: zeroCampaignQuery.EXPORT_CODE,
      variables: {
        objects: form
      },
    });
    if (results.errors) {
      throw results.errors;
    }

    return results.data.insert_exports;
  }
  async function getStatistic(time: any): Promise<any> {
    const filter = {
      ...time && time.length >= 2 ? {
        _gte: moment(time[0]).format(),
        _lte: moment(time[1]).endOf('day').format() // end of day for today's
      } : {}
    };
    const results = await gqlClient.query({
      query: zeroCampaignQuery.GET_STATISTIC,
      variables: {
        filter
      },
      fetchPolicy: 'network-only'
    });
    if (results.errors) {
      throw results.errors;
    }

    return results.data;
  }
  async function getStatisticTable(time: any): Promise<any> {
    const filter = {
      ...time && time.length >= 2 ? {
        _gte: moment(time[0]).format(),
        _lte: moment(time[1]).endOf('day').format() // end of day for today's
      } : {}
    };
    const results = await gqlClient.query({
      query: zeroCampaignQuery.GET_STATISTIC_TABLE,
      variables: {
        filter
      },
      fetchPolicy: 'network-only'
    });
    if (results.errors) {
      throw results.errors;
    }

    return results.data;
  }
  async function getStatisticByBranch(name: string, time: any): Promise<any> {
    const filter = {
      ...time && time.length >= 2 ? {
        _gte: moment(time[0]).format(),
        _lte: moment(time[1]).endOf('day').format() // end of day for today's
      } : {}
    };
    const results = await gqlClient.query({
      query: zeroCampaignQuery.GET_STATISTIC_BY_BRANCH,
      variables: {
        name, filter
      },
      fetchPolicy: 'network-only'
    });
    if (results.errors) {
      throw results.errors;
    }

    return results.data;
  }
  async function getStatisticForDepartmentPic(): Promise<any> {
    const results = await gqlClient.query({
      query: zeroCampaignQuery.GET_STATISTIC_FOR_DEPARTMENT_PIC,
      fetchPolicy: 'network-only'
    });
    if (results.errors) {
      throw results.errors;
    }

    return results.data;
  }
  async function updateOwnerInfo(shopId: string, set: any): Promise<any> {
    const results = await gqlClient.mutate({
      mutation: zeroCampaignQuery.UPDATE_MANAGER_INFO,
      variables: {
        shopId, set
      },
    });
    if (results.errors) {
      throw results.errors;
    }

    return results.data;
  }
  async function updatePOSCode(id: string, posCode: string): Promise<any> {
    const results = await gqlClient.mutate({
      mutation: zeroCampaignQuery.UPDATE_POS_CODE,
      variables: {
        id, posCode
      },
    });
    if (results.errors) {
      throw results.errors;
    }

    return results.data;
  }
  async function approveCampaign(id: string, set: any): Promise<any> {
    const results = await gqlClient.mutate({
      mutation: zeroCampaignQuery.APPROVE_CAMPAIGN,
      variables: {
        id, set
      },
    });
    if (results.errors) {
      throw results.errors;
    }

    return results.data;
  }
  async function getStatisticListParticipant(where: any): Promise<any> {
    const results = await gqlClient.query({
      query: zeroCampaignQuery.GET_STATISTIC_PARTICIPANT,
      fetchPolicy: 'no-cache',
      variables: {
        where
      }
    });
    if (results.errors) {
      throw results.errors;
    }

    return results.data;
  }
  async function approveAll(ids: any, set: any): Promise<any> {
    const results = await gqlClient.mutate({
      mutation: zeroCampaignQuery.APPROVE_ALL,
      variables: {ids, set}
    });
    if (results.errors) {
      throw results.errors;
    }

    return results.data;
  }
  async function getStatisticTopList(form: any): Promise<any> {
    const results = await gqlClient.query({
      query: zeroCampaignQuery.GET_STATISTIC_LIST,
      variables: {form}
    });
    if (results.errors) {
      throw results.errors;
    }

    return results.data.topDealerMostCustomers;
  }
  async function getStatisticTopListShop(form: any): Promise<any> {
    const results = await gqlClient.query({
      query: zeroCampaignQuery.GET_STATISTIC_LIST_SHOP,
      variables: {form}
    });
    if (results.errors) {
      throw results.errors;
    }

    return results.data.topShopMostCustomers;
  }
  async function exportCampaignOverview(form: any): Promise<any> {
    const results = await gqlClient.mutate({
      mutation: zeroCampaignQuery.EXPORT_CAMPAIGN_OVERVIEW,
      variables: {form}
    });
    if (results.errors) {
      throw results.errors;
    }

    return results.data;
  }
  async function getStatisticListParticipantSaleAdmin(): Promise<any> {
    const results = await gqlClient.query({
      query: zeroCampaignQuery.GET_STATISTIC_PARTICIPANT_SALE_ADMIN,
      fetchPolicy: 'no-cache'
    });
    if (results.errors) {
      throw results.errors;
    }

    return results.data;
  }
  async function getConfiguration(): Promise<any> {
    const results = await gqlClient.query({
      query: zeroCampaignQuery.GET_CONFIGURATION,
    });
    if (results.errors) {
      throw results.errors;
    }

    return results.data.ip_campaign_configs;
  }
  async function updateConfiguration(key: any, status: any, reason: string, updatedBy: string): Promise<any> {
    const results = await gqlClient.mutate({
      mutation: zeroCampaignQuery.UPDATE_CONFIGURATION,
      variables: {
        key, status, reason, updatedBy
      }
    });
    if (results.errors) {
      throw results.errors;
    }

    return results.data.ip_campaign_configs;
  }
  async function getConfigHistoryLogs(limit: any, offset: any): Promise<any> {
    const results = await gqlClient.query({
      query: zeroCampaignQuery.GET_CONFIG_HISTORY_LOGS,
      variables: {
        limit, offset
      }
    });
    if (results.errors) {
      throw results.errors;
    }

    return results.data;
  }

  return {
    ...CRUDGqlService<any>(zeroCampaign, zeroCampaignQuery),
    getZeroCampaignById,
    updateSalesmanInfo,
    getListIdFinancialCompany,
    updateFinancialCompany,
    approveRejectCampaign,
    importPosCode,
    getConfig,
    forceCloseCode,
    exportCode,
    getStatistic,
    getStatisticTable,
    getStatisticByBranch,
    getStatisticForDepartmentPic,
    updateOwnerInfo,
    updatePOSCode,
    approveCampaign,
    getStatisticListParticipant,
    approveAll,
    getStatisticTopList,
    getStatisticTopListShop,
    exportCampaignOverview,
    getStatisticListParticipantSaleAdmin,
    getConfiguration,
    updateConfiguration,
    getConfigHistoryLogs,
    ip_campaign_companies: CRUDGqlService<any>('ip_campaign_companies', zeroCampaignQuery.REQUEST_LIST),
  };
}
