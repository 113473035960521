import { isArray } from 'lodash';
import { ActionTypeBranchManage } from 'root/admin/BranchAndGroup/BranchManage/Store/types';
import { ActionTypeDealer } from 'root/admin/Dealer/Store/types';
import { Avatar } from 'root/components/Avatar';
import { DKSelectedViewOnlyList } from 'root/components/DKSelectedViewOnlyList';
import { formatBirthday, FormatDateFullTime, FormatDateTime } from 'root/helpers';
import { IUser } from 'root/models';
import { IState } from 'root/store';
// import { IState } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import './styles.scss';
@Component({
  template: require('./view.html'),
  props: {
    visible: Boolean,
    detailId: String,
    isEng: Boolean,
  },
  components: {
    avatar: Avatar,
    'dk-selected-view-only-list': DKSelectedViewOnlyList,
  },
  computed: {
    ...mapState({
      // userDetail: (state: IState) => state.user.userDetail,
      stationPartnerData: (state: IState) => ({
        labelKey: 'name',
        valueKey: 'id',
        extraKey: 'daikinCode',
        data: state.branchManage.data,
      }),
      dealer: (state: IState) => state.dealer.data
    })
  }
})
export class CampaignDetailModal extends Vue {
  public FormatDateTime = FormatDateTime;
  public FormatDateFullTime = FormatDateFullTime;
  public formatBirthday = formatBirthday;
  public userDetail = null;
  public detailId: string;
  public selectedItems = [];

  public get displayRole(): string {
    return this.userDetail.role ? this.userDetail.role.name.toString() : '';
  }
  public get displayStationName() {
    if (this.userDetail.permission === 'partner') {
      return this.userDetail.partner && this.userDetail.partner.station ? this.userDetail.partner.station.name : '';
    }

    return this.userDetail.station ? this.userDetail.station.name : '';
  }

  public displayGroup(model: IUser) {
    if (model.superAdmin) {
      return this.$t('super.admin');
    }
    if (model.systemGroup) {
      return this.$t(model.systemGroup.name);
    }

    return '';
  }
  public formatterDealerRole(role) {
    if (!isArray(role)) {
      return role
        .split('_')
        .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
    }
    const _cloneTarget = [...role];
    if (_cloneTarget.includes('daikin_staff')) {
      const _index = _cloneTarget.indexOf('daikin_staff');
      _cloneTarget.splice(_index, 1, 'salesman');
    }
    const result = _cloneTarget.reduce((acc, type) => {
      const formattedType = type
        .split('_')
        .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');

      return `${acc + formattedType}, `;
    }, '');

    return result.slice(0, -2) || '--';
  }
  public RenderBranchName(val: any[]) {
    const branchName = [];
    val && val.length && val.map((it) => {
      if (it.branch && it.branch.id && branchName.indexOf(it.branch) < 0) {
        branchName.push(it.branch);
      }
    });

    return branchName && branchName.length ? branchName.map((it) => it.name).join(', ') : '--';
  }
  public RenderDealerName(val: any[]) {
    return val && val.length && val.map((it) => {
      return it.name;
    }).join(', ');

  }

  public async openDialog() {
    this.userDetail = this.$store.getters.getCampaignDetail(this.detailId);
    // tslint:disable-next-line:early-exit
    // if (this.userDetail.dealers && this.userDetail.dealers.length) {
    //   const deal = [];
    //   const _branch = [];
    //   this.userDetail.dealers.map((it) => {
    //     deal.push(it.id);
    //     if (it.branch && it.branch.id && _branch.indexOf(it.branch.id) < 0) {
    //       _branch.push(it.branch.id);
    //     }
    //   });
    //   this.selectedItems = deal;
    // }

  }

  public downloadFile() {
    window.open(this.userDetail.description, '_blank') ;
  }
  public formatterFileName(name) {
    const start = name.indexOf('system_users%2F');
    const end = name.indexOf('?alt=media');

    return name.slice(start + 13, end);
  }
  public closeDialog() {
    this.$emit('closeDialog');
  }
  protected mounted() {
    //
    this.$store.dispatch(ActionTypeBranchManage.GetListBranch,
      {form: {
        filter: {},
        pagination: {}
      }});
    this.$store.dispatch(ActionTypeDealer.GetListDealer, {
      form: {
        filter: {},
        pagination: {
          size: 20,
          page: 1
        }
      }
    });
  }
}
