import { cloneDeep } from 'lodash';
import { ActionTypeUser, MutationTypeUser, SystemRoleAlias } from 'root/admin/User/Store/types';
import { IPaginationParams } from 'root/api/graphql/Core';
import { FormatDateFullTime } from 'root/helpers';
import { IAddress, IBreadcrumb } from 'root/models';
import { IState, MutationType, TypeAlert } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import { NewRaCampaignRouterPath } from '../..';
import { RequestStatusEnum } from '../../RequestList';
import { ActionTypeCampaignRequestList, ActionTypeClassifiedRequest,
  MutationTypeClassifiedRequest } from '../../Store/types';
import { HistoryLog } from '../HistoryLog';
import { Qualified, QualifiedCode, RequestStatus, RequestStatusCode } from '../NewRACampaignDetail';
import { ShopCampaignDetailModel } from '../ShopCampaignDetailModel';
import { SubDealerCampaignDetailModel } from '../SubDealerCampaignDetailModel';
import './styles.scss';
const enum FilterKey {
  SalesStaff = 'salesStaff'
}
@Component({
  template: require('./view.html'),
  computed: {
    ...mapState({
      authUser: (state: IState) => state.global.authUser,
      typeCollapse: (state: IState) => state.global.typeCollapse,
      user: (state: IState) => state.user.data,
      data: (state: IState) => state.newRACampaign.acl_request_reviews.data,
      loading: (state: IState) => state.newRACampaign.acl_request_reviews.loading,
      pagination: (state: IState) => state.newRACampaign.acl_request_reviews.pagination,
    }),
  },
  components: {
    'history-log': HistoryLog,
    'shop-campaign-detail': ShopCampaignDetailModel,
    'subdealer-campaign-detail': SubDealerCampaignDetailModel
  }
})
export class RequestDetail extends Vue {
  public get serviceId(): string {
    return this.$route.params.id;
  }
  public get activeTab() {
    return 'ClassifiedRequest';
  }
  public set activeTab(_value: string) {
    //
  }
  public get canApproveAll() {
    return this.authUser.role.alias === SystemRoleAlias.SaleAdmin && this.detail
    && this.detail.status === 'confirmed';
  }
  public get canApproveRequestItem() {
    return this.authUser.role.alias === SystemRoleAlias.GroupLeader && this.detail
    && this.detail.status === 'reviewing';
  }
  public get canRemoveRequestItem() {
    return (this.authUser.role.alias === SystemRoleAlias.GroupLeader && this.detail
      && this.detail.status === 'reviewing') || (this.authUser.role.alias === SystemRoleAlias.SaleAdmin && this.detail
        && this.detail.status === 'confirmed');
  }
  public get selectedFilter() {
    const filterParams = cloneDeep(this.filterParams);

    return [
      {
        key: FilterKey.SalesStaff,
        value: filterParams[FilterKey.SalesStaff]
      },
    ];
  }
  public get dataFilter() {
    const clone = this.user.reduce((res, item) => {
      res.push({
        key: item.id,
        value: item.id,
        name: item.name
      });

      return res;
    }, []);

    return [
      {
        key: FilterKey.SalesStaff,
        name: 'Sales Staff',
        value: clone
      },
    ];
  }
  public get columns() {
    return [

      {
        prop: 'dealer',
        label: 'Direct Dealer Name',
        labelItem: 'Direct Dealer Code',
        formatter: (model: any) => {
          if (model && model.aclCampaign && model.aclCampaign.dealer && model.aclCampaign.dealer.role &&
            model.aclCampaign.dealer.role.alias && model.aclCampaign.dealer.role.alias === 'daikin_dealer') {
            return model.aclCampaign.dealer.name;
          }
          if (model && model.aclCampaign && model.aclCampaign.dealer && model.aclCampaign.dealer.role &&
            model.aclCampaign.dealer.role.alias && model.aclCampaign.dealer.role.alias === 'sub_dealer') {
            return model.aclCampaign.dealer.assignedSubDealers2023View &&
            model.aclCampaign.dealer.assignedSubDealers2023View.length
            && model.aclCampaign.dealer.assignedSubDealers2023View[0].dealer
            && model.aclCampaign.dealer.assignedSubDealers2023View[0].dealer.name ?
            model.aclCampaign.dealer.assignedSubDealers2023View[0].dealer.name : '--';
          }

          return '--';
        },
        description: {
          formatter: (model: any) => {
            if (model && model.aclCampaign && model.aclCampaign.dealer && model.aclCampaign.dealer.role &&
              model.aclCampaign.dealer.role.alias && model.aclCampaign.dealer.role.alias === 'daikin_dealer') {
              return model.aclCampaign.dealer.dealerCode;
            }
            if (model && model.aclCampaign && model.aclCampaign.dealer && model.aclCampaign.dealer.role &&
              model.aclCampaign.dealer.role.alias && model.aclCampaign.dealer.role.alias === 'sub_dealer') {
              return model.aclCampaign.dealer.assignedSubDealers2023View &&
              model.aclCampaign.dealer.assignedSubDealers2023View.length
              && model.aclCampaign.dealer.assignedSubDealers2023View[0].dealer
              && model.aclCampaign.dealer.assignedSubDealers2023View[0].dealer.dealerCode ?
              model.aclCampaign.dealer.assignedSubDealers2023View[0].dealer.dealerCode : '--';
            }

            return '--';
          },
        },
        width: 250,
      },
      {
        prop: 'sub-dealer',
        label: 'Sub-Dealer Name',
        labelItem: 'Sub-Dealer Code',
        formatter: (model: any) => {
          if (model && model.aclCampaign && model.aclCampaign.dealer && model.aclCampaign.dealer.role &&
            model.aclCampaign.dealer.role.alias && model.aclCampaign.dealer.role.alias === 'sub_dealer') {
            return model.aclCampaign.dealer.name;
          }

          return '--';
        },
        description: {
          formatter: (model: any) => {
            if (model && model.aclCampaign && model.aclCampaign.dealer && model.aclCampaign.dealer.role &&
              model.aclCampaign.dealer.role.alias && model.aclCampaign.dealer.role.alias === 'sub_dealer') {
              return model.aclCampaign.dealer.dealerCode;
            }

            return '--';
          },
        },
        width: 250,
      },
      {
        prop: 'shop',
        label: 'Shop Name',
        labelItem: 'Shop Code',
        formatter: (model: any) => {
          if (model && model.aclCampaign && model.aclCampaign.dealer && model.aclCampaign.dealer.role &&
            model.aclCampaign.dealer.role.alias && model.aclCampaign.dealer.role.alias === 'daikin_dealer') {
            return model.aclCampaign.shop.name;
          }

          return '--';
        },
        description: {
          formatter: (model: any) => {
            if (model && model.aclCampaign && model.aclCampaign.dealer && model.aclCampaign.dealer.role &&
              model.aclCampaign.dealer.role.alias && model.aclCampaign.dealer.role.alias === 'daikin_dealer') {
              return model.aclCampaign.shop.shopCode;
            }

            return '--';
          },
        },
        width: 220,
      },
      {
        prop: 'shopAddress',
        label: 'Address',
        width: 220,
        formatter: (model: any) => {
          if (model && model.aclCampaign && model.aclCampaign.dealer && model.aclCampaign.dealer.role
            && model.aclCampaign.dealer.role.alias === 'sub_dealer') {
            return this.displayAddress(model.aclCampaign.dealer.address ? model.aclCampaign.dealer.address : {})
            || '--';
          }

          return this.displayAddress(model.aclCampaign.shop.address ? model.aclCampaign.shop.address : {})
          || '--';
        }
      },
      {
        prop: 'review content',
        label: 'Sales Staff last review content',
        formatter: (model: any) => {
          if (model && model.aclCampaign && model.aclCampaign.reviews && model.aclCampaign.reviews.length
            && model.aclCampaign.reviews[0].description) {
            return model.aclCampaign.reviews[0].description;
          }

          return '--';
        },
        width: 300,
      },
      {
        prop: 'review at',
        label: 'Sales Staff last review time',
        formatter: (model: any) => {
          return model && model.aclCampaign && model.aclCampaign.reviews && model.aclCampaign.reviews.length
          && model.aclCampaign.reviews[0].createdAt ?
          this.formatDate(model.aclCampaign.reviews[0].createdAt) : '--';
        },
        width: 180,
      },
      {
        prop: 'spiralReview',
        label: 'Spiral Review Result',
        formatter: () => {
          return '--';
        },
        width: 120,
      },
      {
        prop: 'paymentDocument',
        label: 'Payment Document',
        width: 120,
        formatter: (model) => {
          if (model && model.aclCampaign && model.aclCampaign.dealer && model.aclCampaign.dealer.role &&
            model.aclCampaign.dealer.role.alias === 'sub_dealer') {
            return model && model.aclCampaign && model.aclCampaign.dealer &&
            model.aclCampaign.dealer.information2023 && model.aclCampaign.dealer.information2023
            && model.aclCampaign.dealer.information2023.productReceiptPicURLs &&
            model.aclCampaign.dealer.information2023.productReceiptPicURLs.length ? 'Yes' : 'No';
          }

          return model && model.aclCampaign && model.aclCampaign.dealer &&
            model.aclCampaign.dealer.information2023 && model.aclCampaign.dealer.information2023
            && (model.aclCampaign.dealer.information2023.requestedRewardReceiptURLs &&
              model.aclCampaign.dealer.information2023.requestedRewardReceiptURLs.length ||
              model.aclCampaign.dealer.information2023.data && model.aclCampaign.dealer.information2023.data.link
              ) ? 'Yes' : 'No';
        },
        labelStyle: (model) => {
          if (model && model.aclCampaign && model.aclCampaign.dealer && model.aclCampaign.dealer.role &&
            model.aclCampaign.dealer.role.alias === 'sub_dealer') {
            return model && model.aclCampaign && model.aclCampaign.dealer &&
            model.aclCampaign.dealer.information2023 && model.aclCampaign.dealer.information2023
            && model.aclCampaign.dealer.information2023.productReceiptPicURLs &&
            model.aclCampaign.dealer.information2023.productReceiptPicURLs.length ? 'activated' : 'non_qualified';
          }

          return model && model.aclCampaign && model.aclCampaign.dealer &&
          model.aclCampaign.dealer.information2023 && model.aclCampaign.dealer.information2023
          && (model.aclCampaign.dealer.information2023.requestedRewardReceiptURLs &&
            model.aclCampaign.dealer.information2023.requestedRewardReceiptURLs.length ||
            model.aclCampaign.dealer.information2023.data && model.aclCampaign.dealer.information2023.data.link
            ) ? 'activated' : 'non_qualified';
        },
      },
      {
        prop: 'BPStatus',
        label: 'Sales Staff Review Result',
        formatter: (model: any) => {
          if (model && model.aclCampaign && model.aclCampaign.saleStaffReview) {
            return model.aclCampaign.saleStaffReview.split('_').join(' ');
          }

          return '--';
        },
        labelStyle: (model: any) => {
          return `text--capitalize request-review__${model.aclCampaign.saleStaffReview}`;
        },
        width: 120,
      },
      {
        prop: 'SDPStatus',
        label: 'Sales PIC Review Result',
        formatter: (model: any) => {
          if (model && model.aclCampaign && model.aclCampaign.salePicReview) {
            return model.aclCampaign.salePicReview.split('_').join(' ');
          }

          return '--';
        },
        labelStyle: (model: any) => {
          return `text--capitalize request-review__${model.aclCampaign.salePicReview}`;
        },
        width: 120,
      },
      {
        prop: 'SMStatus',
        label: 'Sales Manager Review Result',
        formatter: (model: any) => {
          if (model && model.aclCampaign && model.aclCampaign.saleManagerReview) {
            return model.aclCampaign.saleManagerReview.split('_').join(' ');
          }

          return '--';
        },
        labelStyle: (model: any) => {
          return `text--capitalize request-review__${model.aclCampaign.saleManagerReview}`;
        },
        width: 120,
      },
      {
        prop: 'SPStatus',
        label: 'Sale Planning Review Result',
        formatter: (model: any) => {
          if (model && model.aclCampaign && model.aclCampaign.salePlanningReview) {
            return model.aclCampaign.salePlanningReview.split('_').join(' ');
          }

          return '--';
        },
        labelStyle: (model: any) => {
          return `text--capitalize request-review__${model.aclCampaign.salePlanningReview}`;
        },
        width: 120,
      },
      {
        prop: 'action',
        label: '',
        align: 'center',
        fixed: 'right',
        width: 60
      }
    ];
  }
  public detail: any= {};
  public formatDate = FormatDateFullTime;
  public user: any[];
  public authUser: any;
  public visibleHistory: boolean = false;
  public visibleSubDetail: boolean = false;
  public visibleShopDetail: boolean = false;
  public visibleAction: boolean = false;
  public pagination: IPaginationParams;
  public loadingAction: boolean = false;
  public visibleActionClone: boolean = false;
  public loadingActionClone: boolean = false;
  public titleAction: string = '';
  public messageAction: string = '';
  public titleReason: string = '';
  public action: string = '';
  public type: string = '';
  public isErrorReason: boolean = false;
  public reason: string = '';
  public requestReviewId: string = '';
  public shopId: string = '';
  public subdealerId: string = '';
  public filterParams = {
    [FilterKey.SalesStaff]: [],
  };
  public refetchData() {
    this.$store.dispatch(ActionTypeCampaignRequestList.FindById, {
      id: this.$route.params.id,
      onSuccess: (detail) => {
        this.detail = detail && detail.length ? detail[0] : {};
      }
    });
  }
  public fetchData() {
    const sales = this.filterParams[FilterKey.SalesStaff];
    this.$store.commit(MutationTypeClassifiedRequest.FilterChange, {
      _and: {
        mode: 'special',
        data: [
          {
            requestId: {
              _eq: this.$route.params.id,
            }
          },
          ...sales && sales.length ? [
            {aclCampaign: {
              reviews: {
                createdBy: {
                  _in: sales
                }
              }
            }}
          ] : [],
          {
            status: {
              _eq: 'active'
            }
          }
        ]
      }
    });
    this.$store.dispatch(ActionTypeClassifiedRequest.FilterNoCache);
  }
  public getActions() {
    if (this.canRemoveRequestItem) {
      return ['view', 'removeRequest'];
    }

    return ['view'];
  }
  public submitAction() {
    this.loadingAction = true;
    if (!this.reason || this.reason.length <= 0) {
      this.loadingAction = false;

      return this.isErrorReason = true;
    }
    this.isErrorReason = false;
    this.$store.dispatch(ActionTypeClassifiedRequest.Update, {
      id: this.requestReviewId,
      form: {
        status: 'deleted',
        removedReason: this.reason
      },
      onSuccess: () => {
        this.$message.success('Remove request successfully!');
        this.fetchData();
        this.refetchData();
        this.visibleAction = false;
        this.loadingAction = false;
      }
    });
  }
  public submitActionClone() {
    this.loadingActionClone = true;
    // if ((this.action !== 'review' && this.action !== 'confirm' && this.action !== 'approve') &&
    // (!this.reason || this.reason.length <= 0)) {
    //   this.loadingAction = false;

    //   return this.isErrorReason = true;
    // }
    this.isErrorReason = false;
    this.$store.dispatch(ActionTypeCampaignRequestList.Update, {
      form: {
        status: this.action === 'confirm' ? RequestStatusEnum.Confirmed :
        this.action === 'approve' ? RequestStatusEnum.Approved :
        this.action === 'reject' ? RequestStatusEnum.Rejected : '',
        ...this.action === 'reject' ? {
          rejectedReason: this.reason,
        } : {},
        ...this.action === 'reject' ? {
          rejectedReason: this.reason,
        } : {},
        ...this.action === 'approve' ? {
          approvalNote: this.reason,
        } : {},
        ...this.action === 'confirm' ? {
          confirmedComment: this.reason,
        } : {},
      },
      id: this.$route.params.id,
      onSuccess: () => {
        const message = `${this.action} request successfully!`;
        this.$store.commit(MutationType.OpenTopAlert, {
          message,
          type: TypeAlert.Success
        });
        this.loadingActionClone = false;
        this.closeDialogActionClone();
      }
    });
  }
  public handleActions({act, model}) {
    if (act === 'removeRequest') {
      this.requestReviewId = model.id;
      this.reason = '';
      this.visibleAction = true;

      return;
    }
    if (act === 'view' && model && model.aclCampaign && model.aclCampaign.dealer &&
    model.aclCampaign.dealer.role && model.aclCampaign.dealer.role.alias === 'daikin_dealer') {
      this.visibleShopDetail = true;
      this.shopId = model.aclCampaign.shop.id;

      return;
    }
    if (act === 'view' && model && model.aclCampaign && model.aclCampaign.dealer &&
    model.aclCampaign.dealer.role && model.aclCampaign.dealer.role.alias === 'sub_dealer') {
      this.visibleSubDetail = true;
      this.subdealerId = model.aclCampaign.dealer.id;

      return;
    }

    return;
  }
  public changeTab() {
    // 
  }
  public formatterSalePlanningStatus(model: any) {
    if (model.acl_campaigns_2023.length) {
      switch (model.acl_campaigns_2023[0].requestStatus) {
      case RequestStatusCode.Approved:
        return RequestStatus.Approved;
      case RequestStatusCode.Rejected:
        return RequestStatus.Rejected;
      default:
        return 'Not Review';
      }
    }

    return 'Not Review';
  }
  public handlePaginationChange(value) {
    if (typeof value === 'number') {
      this.$store.commit(MutationTypeClassifiedRequest.PaginationChange, {
        ...this.pagination,
        size: value
      });
    } else {
      this.$store.commit(MutationTypeClassifiedRequest.PaginationChange, {
        ...this.pagination,
        ...value
      });
    }
    this.fetchData();
  }
  public handleViewLogs() {
    this.visibleHistory = true;
  }
  public closeDialogAction() {
    this.visibleAction = false;
    this.action = '';
    this.titleAction = '';
    this.messageAction = '';
    this.titleReason = '';
    this.reason = '';
    this.fetchData();
    this.refetchData();
  }
  public closeDialogActionClone() {
    this.visibleActionClone = false;
    this.action = '';
    this.titleAction = '';
    this.messageAction = '';
    this.titleReason = '';
    this.reason = '';
    this.fetchData();
    this.refetchData();
  }
  public formatterStyleStatus(model: any) {
    if (model && model.acl_campaigns_2023.length && model.acl_campaigns_2023[0].requestStatus) {
      switch (model.acl_campaigns_2023[0].requestStatus) {
      case RequestStatusCode.Approved:
        return RequestStatusCode.Approved;
      case RequestStatusCode.Rejected:
        return RequestStatusCode.Rejected;
      default:
        return 'not_review';
      }
    }

    return 'not_review';
  }
  public formatterClassification(model: any) {
    if (model.acl_campaigns_2023.length && model.acl_campaigns_2023[0].classification) {
      switch (model.acl_campaigns_2023[0].classification) {
      case QualifiedCode.Qualified:
        return Qualified.Qualified;
      case QualifiedCode.NonQualified:
        return Qualified.NonQualified;
      }
    }

    return 'Not Review';
  }
  public formatterReviewTotal(model) {
    return model.filter((e) => e.status === 'active').length;
  }
  public formatterReviewStatus(model: any) {
    if (model.acl_campaigns_2023.length && model.acl_campaigns_2023[0].classification) {
      switch (model.acl_campaigns_2023[0].classification) {
      case QualifiedCode.Qualified:
        return QualifiedCode.Qualified;
      case QualifiedCode.NonQualified:
        return QualifiedCode.NonQualified;
      }
    }

    return 'not_review';
  }
  public displayAddress(model: IAddress) {
    return model && model.province
      ? this.$store.getters['getAddressString'](model)
      : '';
  }
  public handleConfirmAll(act) {
    switch (act) {
    case 'confirm':
      this.action = 'confirm';
      this.visibleActionClone = true;
      this.titleAction = 'Confirm request';
      this.messageAction = 'Are you sure to confirm this request?';
      this.titleReason = 'Confirm comment';
      this.type = 'primary';
      break;
    case 'approve':
      this.action = 'approve';
      this.visibleActionClone = true;
      this.titleAction = 'Approve request';
      this.messageAction = 'Are you sure to approve this request?';
      this.titleReason = 'Approve note';
      this.type = 'primary';
      break;
    case 'reject':
      this.action = 'reject';
      this.visibleActionClone = true;
      this.titleAction = 'Reject request';
      this.messageAction = 'Are you sure to reject this request? Please input reason below.';
      this.titleReason = 'Reason reject ';
      this.type = 'danger';
      break;
    default:
      break;
    }
  }
  public confirmAll(model) {
    this.$store.dispatch(ActionTypeCampaignRequestList.Update, {
      id: this.detail.id,
      form: {
        status: model.action
      },
      onSuccess: () => {
        model.instance.confirmButtonLoading = false;
        model.instance.confirmButtonText = 'Confirm';
        model.done();
        this.fetchData();
        this.refetchData();
      },
      onFail: () => {
        model.instance.confirmButtonLoading = false;
        model.instance.confirmButtonText = 'Confirm';
      }
    });
  }
  public changeFilter(model) {
    const salesStaff = model.find((e) => e.key === FilterKey.SalesStaff);
    const nextFilter = {
      [FilterKey.SalesStaff]: salesStaff ? salesStaff.value : [],
    };
    this.filterParams = {
      ...cloneDeep(this.filterParams),
      ...nextFilter
    };
    this.fetchData();
    this.refetchData();
  }
  public closeDialogDetail() {
    this.visibleSubDetail = false;
    this.visibleShopDetail = false;
    this.subdealerId = '';
    this.shopId = '';
  }
  protected async mounted() {
    this.$store.commit(MutationType.SetShowCollapse);
    this.$store.commit(MutationType.SetTypeCollapse, true);
    const breadcrumb: IBreadcrumb[] = [
      {
        label: 'title.new_ra_campaign',
        path: null,
        current: false
      },
      {
        label: 'title.new_ra_campaign_requestList',
        path: NewRaCampaignRouterPath.RequestList,
        current: true
      },
      {
        label: 'title.new_ra_campaign_request_detail',
        path: null,
        current: true
      }
    ];
    this.$store.commit(MutationType.SetBreadcrumb, breadcrumb);
    this.$store.commit(MutationTypeUser.FilterChange, {
      role: {
        alias: SystemRoleAlias.SaleStaff
      },
      groupId: this.authUser.group && this.authUser.group.id ? this.authUser.group.id : null
    });
    this.$store.dispatch(ActionTypeUser.FilterNoCache);
    this.refetchData();
    this.fetchData();
  }
  protected beforeDestroy() {
    this.$store.commit(MutationType.ClearShowCollapse);
    this.$store.commit(MutationType.ClearBreadcrumb);
  }
}
