import { cloneDeep } from 'lodash';
import { IPaginationParams } from 'root/api/graphql/Core';
import { IAddress, IBreadcrumb } from 'root/models';
import { dealerStaffFormDefault, IDealerStaffForm } from 'root/models/Dealer';
import { IState, MutationType } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import { DealerRouterPath } from '..';
import { DealerEditor } from '../Components/DealerEditor';
import { ActionTypeDealerShop } from '../DealerShop/Store/types';
import { AccountStaffDetail } from './AccountStaffDetail';
import { AccountStaffEditor } from './AccountStaffEditor';
import { ActionTypeAccountStaff, MutationTypeAccountStaff } from './Store/types';
import './styles.scss';

const enum FilterKey {
  Status = 'status'
}

const enum AccountStatus {
  Active = 'active',
  Inactive = 'inactive',
}
const enum AccountStatusLabel {
  Active = 'Active',
  Inactive = 'Inactive',
}
@Component({
  template: require('./view.html'),
  components: {
    editor: AccountStaffEditor,
    ownerEditor: DealerEditor,
    'account-staff-detail': AccountStaffDetail
  },
  computed: {
    ...mapState({
      data: (state: IState) => state.accountStaff.data,
      loading: (state: IState) => state.accountStaff.loading,
      pagination: (state: IState) => state.accountStaff.pagination,
    }),
    // cloneData() {
    //   return this.data ? this.data.sort((a, b) => (a.isOwner === b.isOwner) ? 0 : a.isOwner ? -1 : 1) : [];
    // }
  },
})

export class AccountStaff extends Vue {
  public get serviceId(): string {
    return this.$route.params.id;
  }
  public get columns() {
    return [
      {
        prop: 'name',
        label: 'Name',
        width: 180,
        formatter: (model: any) => {
          return model.dealer && model.dealer.name ? model.dealer.name : '--';
        },
        avatar: {
          formatter: (model: any) => {
            return model.dealer ? model.dealer : null;
          }
        },
      },
      // {
      //   prop: 'role',
      //   label: 'Role',
      //   width: 150,
      //   formatter: (model: any) => {
      //     return model.isOwner ? 'Owner' : 'Staff';
      //   }
      // },
      {
        prop: 'phoneNumber',
        label: 'Phone',
        width: 180,
        formatter: (model: any) => {
          return model.dealer && model.dealer.phoneNumber ? model.dealer.phoneNumber : '--';
        }
      },
      {
        prop: 'email',
        label: 'Email',
        width: 180,
        formatter: (model: any) => {
          return model.dealer && model.dealer.email ? model.dealer.email : '--';
        }
      },
      {
        prop: 'address',
        label: 'Address',
        width: 220,
        formatter: (model: any) => {
          return model.dealer && model.dealer.address ? this.displayAddress(model.dealer.address) : '--';
        }
      },
      {
        prop: 'status',
        label: this.$t('status'),
        formatter: (model: any) => (model.dealer && model.dealer.status ? this.$t(model.dealer.status) : ''),
        labelStyle: (model: any) => {
          return model.dealer && model.dealer.status ? model.dealer.status : '--';
        },
        align: 'center',
        width: 180
      },
      {
        prop: 'action',
        label: 'Action',
        align: 'center',
        fixed: 'right',
      }
    ];
  }

  public searchText: string = '';
  public visible: boolean = false;
  public visibleEditor: boolean = false;
  public visibleDetail: boolean = false;
  public dealerId: string = '';
  public pagination: IPaginationParams;
  public ownerId: string ='';
  public shops: any = [];
  public filterParams = {
    [FilterKey.Status]: []
  };
  public staff: IDealerStaffForm = dealerStaffFormDefault();
  public hasShop: boolean = false;
  public displayAddress(model: IAddress) {
    return model && model.province ? this.$store.getters['getAddressString'](model) : '';
  }

  public getAction(model: any) {
    if (model && model.isOwner) {
      return 'edit';
    }

    return 'view';
  }
  public get dataFilter() {
    const _status = [
      {
        key: AccountStatus.Active,
        value: AccountStatus.Active,
        name: AccountStatusLabel.Active
      },
      {
        key: AccountStatus.Inactive,
        value: AccountStatus.Inactive,
        name: AccountStatusLabel.Inactive
      }];

    return [
      {
        key: FilterKey.Status,
        name: 'Status',
        value: _status
      }
    ];
  }
  public get selectedFilter() {
    const _filterParams = cloneDeep(this.filterParams);

    return [
      {
        key: FilterKey.Status,
        value: _filterParams[FilterKey.Status]
      }
    ];
  }
  public handleActions(account, action) {
    if (action === 'edit') {
      this.ownerId = account.dealer.id;
      this.visibleEditor = true;

      return;
    }

    // this.staff = account.dealer;
    this.dealerId = account.dealer.id;
    this.visibleDetail = true;
  }
  public changeFilter(model) {
    const status = model.find((e) => e.key === FilterKey.Status);
    const nextFilter = {
      [FilterKey.Status]: status ? status.value : []
    };
    this.filterParams = {
      ...cloneDeep(this.filterParams),
      ...nextFilter
    };
    this.fetchData();
  }
  public handleSearch() {
    this.fetchData();
  }
  public goAddNewRouter() {
    this.staff = null;
    this.visible = true;
  }
  public handlePaginationChange(value) {
    if (typeof value === 'number') {
      this.$store.commit(MutationTypeAccountStaff.PaginationChange, {
        ...this.pagination,
        size: value
      });
    } else {
      this.$store.commit(MutationTypeAccountStaff.PaginationChange, {
        ...this.pagination,
        ...value
      });
    }
    this.fetchData();
  }

  public editSuccess() {
    this.fetchData();
  }
  public createSuccess() {
    this.fetchData();
  }

  protected mounted() {
    this.$nextTick(() => {
      const breadcrumb: IBreadcrumb[] = [
        {
          label: 'title.dealer',
          path: DealerRouterPath.ListDealer,
          current: false
        },
        {
          label: 'title.view_details',
          path: null,
          current: true
        }
      ];
      this.$store.commit(MutationType.SetBreadcrumb, breadcrumb);

      this.$store.dispatch(ActionTypeDealerShop.GET_LIST_DEALERSHOP, ({
        id: this.serviceId,
        pagination: {
          page: 1,
          limit: null
        },
        onSuccess: (shops) => {
          if (shops.shops && shops.shops.length) {
            this.shops = shops.shops;

            return this.hasShop = true;
          }

          return;
        }
      }));
      this.fetchData();
    });
  }
  protected beforeDestroy() {
    this.$store.commit(MutationType.ClearBreadcrumb);
  }

  private fetchData() {
    const text = cloneDeep(this.searchText);
    const _filterParams = <any> {};
    if (this.filterParams[FilterKey.Status].length) {
      _filterParams.status = this.filterParams[FilterKey.Status];
    }
    this.$store.dispatch(ActionTypeAccountStaff.GET_STAFF_LIST, ({
      id: this.serviceId,
      status: _filterParams.status,
      search: `%${text}%`,
      pagination: this.pagination
    }));
  }
}
