export enum ActionTypeRedemption {
  FilterNoCache = 'rewardsFilterNoCache',
  UpdateStatusRedemption = 'rewardsUpdateStatus',
  CreateRedemption = 'rewardsCreate',
  UpdateRedemption = 'rewardsUpdate',
  GetRedeemDetail = 'rewardsDetail',
  SearchVoucher = 'voucherSearch',
  UpdateVoucher = 'voucherUpdate',
  GetListVoucher = 'voucherGetList',
  AddVoucherCode = 'voucherCodeAdd'
}
export enum MutationTypeRedemption {
  FilterChange = 'rewardsFilterChange',
  ChangeOrderBy = 'rewardsChangeOrderBy',
  PaginationChange = 'rewardsPaginationChange',
  GetRedeemDetail = 'rewardsGetDetail',
  SearchVoucher = 'voucherSearch',
  GetListVoucher = 'voucherGetList',
  FilterChangeVoucher = 'voucherFilterChange'
}
