import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import { pathUpload } from 'root/services';
import Vue from 'vue';
import Component from 'vue-class-component';
import { DKCustomUploadAdapterPlugin } from './adapter';
import './style.scss';

@Component({
  template: require('./view.html'),
  props: {
    value: String,
    pathUpload: {
      type: String,
      default: pathUpload.UPLOADS
    },
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  model: {
    prop: 'value',
    event: 'change'
  },
  methods: {
    onReady(editor) {
        // Insert the toolbar before the editable area.
      editor.ui.getEditableElement().parentElement.insertBefore(
          editor.ui.view.toolbar.element,
          editor.ui.getEditableElement()
      );
    }
  }
})
export class DKEditor extends Vue {
  public get editor() {
    return DecoupledEditor;
  }
  public get editorConfig() {
    return {
      extraPlugins: [(editor: DecoupledEditor) => DKCustomUploadAdapterPlugin(editor, this.$props.pathUpload)]
    };
  }
  public change(newValue: string) {
    this.$emit('change', newValue);
  }
}
