import { sendErrorToBugsnag } from '../bugsnag';
import { fb, getFirebaseApp } from './firebaseInit';
import { IAuthService } from './types';
export function FirebaseAuthService(): IAuthService {

  return {
    createUserWithEmailAndPassword: (email, password) =>
      getFirebaseApp().auth()
      .createUserWithEmailAndPassword(email, password),
    signInWithEmailAndPassword: (form) =>
      getFirebaseApp().auth()
      .signInWithEmailAndPassword(form.email, form.password),
    signOut: () => getFirebaseApp().auth().signOut(),
    onAuthStateChanged: (func) => getFirebaseApp().auth().onAuthStateChanged(func),
    getIdToken: () => {
      const user = getFirebaseApp().auth().currentUser;
      if (user) {
        return user.getIdToken();
      }

      sendErrorToBugsnag({
        message: 'Can not get current user, token = null',
      });

      return Promise.resolve('');
    },
    changePassword: async (email: string, oldPassword: string, newPassword: string) => {
      const currentUser = getFirebaseApp().auth().currentUser;
      const credential = fb.auth.EmailAuthProvider.credential(email, oldPassword);
      await currentUser.reauthenticateAndRetrieveDataWithCredential(credential);

      return currentUser.updatePassword(newPassword);
    },
    signInWithCustomToken(token: string): Promise<any> {
      return getFirebaseApp().auth().signInWithCustomToken(token);
    }
  };
}
