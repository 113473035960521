import { stripObject } from '@hgiasac/helper';
import { Subscription } from 'apollo-client/util/Observable';
import { ElUpload } from 'element-ui/types/upload';
import { cloneDeep, isEmpty } from 'lodash';
import moment from 'moment';
import { DKGqlClient } from 'root/api/graphql/Client';
import { gqlClient, IPaginationParams } from 'root/api/graphql/Core';
import { zeroCampaignQuery } from 'root/api/graphql/Query/zeroCampaign';
import { SearchByFilter } from 'root/components/SearchByFilter';
import { formatCurrency, FormatDateFullTime } from 'root/helpers';
import { IFilterInput } from 'root/models';
import { pathUpload, uploadToFirebase } from 'root/services';
import { FirebaseAuthService } from 'root/services/auth/firebase';
import { IState, MutationType, TypeAlert } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import { ActionTypeSaleEduCampaign, MutationTypeSaleEduCampaign } from '../../Store/types';
import { SalesEduCampaignDetail } from '../components/SalesEduCampaignDetail';
import { SalesEduImportHistory } from '../components/SalesEduImportHistory';
import './styles.scss';

export enum FilterKey {
  RewardPaid = 'rewardPaid',
  DealerConfirm = 'dealerConfirm',
  Amount = 'amount',
  BlockedAmount = 'blockedAmount',
  ImportStatus = 'importStatus'
}
enum BankingPaymentSearchBy {
  ImportCode = 'importCode',
  ImportBy = 'importBy',
  DealerName = 'dealerName',
  DealerCode = 'dealerCode'
}

@Component({
  template: require('./view.html'),
  computed: {
    ...mapState({
      data: (state: IState) => state.customCampaign.bank_dealers.data,
      loading: (state: IState) => state.customCampaign.bank_dealers.loading,
      pagination: (state: IState) => state.customCampaign.bank_dealers.pagination,
      authUser: (state: IState) => state.global.authUser,
    }),
    BankingPaymentSearchBy() {
      const result: IFilterInput[] = [];
      for (const key in BankingPaymentSearchBy) {
        // tslint:disable-next-line:early-exit
        if (BankingPaymentSearchBy.hasOwnProperty(key)) {
          const element = BankingPaymentSearchBy[key];
          result.push({
            key,
            value: element,
            name: this.$t(element)
          });
        }
      }

      return [{
        key: 'select',
        name: this.$t('select'),
        value: result
      }];
    },
    selectedFieldSearchBy() {
      const filterParams = cloneDeep(this.filterParams);

      return [{
        key: 'select',
        value: filterParams.searchBy ? filterParams.searchBy : []
      }];
    },
  },
  components: {
    'search-by-filter': SearchByFilter,
    'sales-edu-campaign-detail': SalesEduCampaignDetail,
    'history-logs': SalesEduImportHistory
  }
})

export class SalesEduCampaignList extends Vue {
  public get isSalePlanning() {
    return this.authUser && this.authUser.role && (
      this.authUser.role.alias === 'sale_admin' || this.authUser.role.alias === 'admin'
    );
  }
  public get dataFilter() {
    return [
      {
        key: FilterKey.ImportStatus,
        value: [
          {
            key: 'successful',
            value: 'successful',
            name: 'Successful'
          },
          // {
          //   key: 'partial_failed',
          //   value: 'partial_failed',
          //   name: 'Partial Failed'
          // },
          {
            key: 'failed',
            value: 'failed',
            name: 'Failed'
          }
        ],
        name: 'Import Status'
      }
    ];
  }
  public get disabledImport() {
    return !(this.fileList && this.fileList.length);
  }
  public get columns() {
    return [
      {
        label: 'Import Code',
        prop: 'importCode',
        fixed: true,
        width: 120
      },
      {
        label: 'Dealer Name',
        prop: 'dealer',
        labelItem: 'Dealer Code',
        fixed: true,
        width: 180
      },
      {
        label: 'Total amount',
        formatter: (model: any) => {
          return model && model.totalAmount ? `${formatCurrency(model.totalAmount, false, 0, '.')} VND` : '0 VND';
        },
        width: 160
      },
      {
        label: 'Total block amount',
        formatter: (model: any) => {
          return model && model.totalBlockedAmount ?
           `${formatCurrency(model.totalBlockedAmount, false, 0, '.')} VND` : '0 VND';
        },
        width: 150
      },
      {
        label: 'Start at',
        formatter: (model: any) => {
          return model && model.startAt ?
          FormatDateFullTime(model.startAt, {locale: true, format: 'DD/MM/YYYY'}) : '--';
        },
        width: 130
      },
      {
        label: 'End at',
        formatter: (model: any) => {
          return model && model.endAt ?
          FormatDateFullTime(model.endAt, {locale: true, format: 'DD/MM/YYYY'}) : '--';
        },
        width: 130
      },
      {
        label: 'Dealer Confirm',
        prop: 'confirmedAt',
        customerHeader: 'confirmedAtHeader',
        labelItem: 'Confirm at',
        sorted: true,
        width: 180
      },
      {
        label: 'Reward paid',
        prop: 'paidAt',
        customerHeader: 'paidAtHeader',
        labelItem: 'Paid at',
        sorted: true,
        width: 180
      },

      {
        label: 'Checked by',
        formatter: (model: any) => {
          return model && model.checker && model.checker.name ? model.checker.name : '--';
        },
        width: 130
      },
      {
        label: 'Imported By',
        prop: 'importBy',
        labelItem: 'Role',
        width: 180
      },
      {
        label: 'Imported At',
        formatter: (model: any) => {
          return model && model.importedAt ?
          FormatDateFullTime(model.importedAt) : '--';
        },
        width: 180
      },
      {
        label: 'Import Status',
        formatter: (model: any) => {
          return model && model.importStatus ?
          model.importStatus : '--';
        },
        labelStyle: (model: any) => {
          return `text--capitalize import-status__${model.importStatus}`;
        },
        fixed: 'right',
        width: 110
      },
      {
        prop: 'action',
        label: '',
        align: 'center',
        fixed: 'right',
        width: 60
      }
    ];
  }
  public get pickerOptionPeriodTimeFrom() {
    return {
      disabledDate: (time) => {
        return time.getTime() > moment() || (
          this.filterParams[FilterKey.RewardPaid].to && time.getTime() >
          moment(this.filterParams[FilterKey.RewardPaid].to)
        );
      }
    };
  }
  public get pickerOptionPeriodTimeTo() {
    return {
      disabledDate: (time) => {
        return time.getTime() > moment() || (
          this.filterParams[FilterKey.RewardPaid].from && time.getTime() <
           moment(this.filterParams[FilterKey.RewardPaid].from)
        );
      }
    };
  }
  public get pickerOptionDealerConfirmPeriodTimeFrom() {
    return {
      disabledDate: (time) => {
        return time.getTime() > moment() || (
          this.filterParams[FilterKey.DealerConfirm].to && time.getTime() >
            moment(this.filterParams[FilterKey.DealerConfirm].to)
        );
      }
    };
  }
  public get pickerOptionDealerConfirmPeriodTimeTo() {
    return {
      disabledDate: (time) => {
        return time.getTime() > moment() || (
          this.filterParams[FilterKey.DealerConfirm].from && time.getTime() <
           moment(this.filterParams[FilterKey.DealerConfirm].from)
        );
      }
    };
  }
  public get disabledCheckAsPaid() {
    if (!this.cloneSelected || this.cloneSelected.length <= 0 ||
    this.cloneSelected.some((item) =>
      !item.confirmedAt || !!item.paidAt || item.importStatus !== 'successful')) {
      return true;
    }

    return false;
  }
  public get selectedFilter() {
    const _filterParams = cloneDeep(this.filterParams);

    return [
      {
        key: FilterKey.ImportStatus,
        value: _filterParams[FilterKey.ImportStatus]
      }
    ];
  }
  public $refs: {
    upload: ElUpload;
  };
  public searchText: string = '';
  public FormatDateFullTime = FormatDateFullTime;
  public FilterKey = FilterKey;
  public detailId: string = '';
  public pathUpload: pathUpload = pathUpload.BANKING_PAYMENT;
  public fileList = [];
  public pagination: IPaginationParams;
  public fileUploaded= [];
  public historyVisible: boolean = false;
  public authUser;
  public sortParam: any = {};
  public cloneSelected: any[] = [];
  public visibleDetail: boolean = false;
  public visibleImport: boolean = false;
  public loadingUpload: boolean = false;
  // tslint:disable-next-line:max-line-length
  public urlSample: string = 'https://firebasestorage.googleapis.com/v0/b/daikin-sale-staging.appspot.com/o/imports%2Fsample%2Ftemplate_import_banking_payment%20.xlsx?alt=media&token=e2de146f-f1e9-424e-bb1f-3376a7ca27e1';
  public subscribeURL: Subscription = null;
  public filterParams = {
    searchBy: ['importCode', 'importBy', 'dealerName', 'dealerCode'],
    [FilterKey.RewardPaid]: {
      from: null,
      to: null
    },
    [FilterKey.DealerConfirm]: {
      from: null,
      to: null
    },
    [FilterKey.Amount]: {
      from: null,
      to: null
    },
    [FilterKey.BlockedAmount]: {
      from: null,
      to: null
    },
    [FilterKey.ImportStatus]: [],
  };
  public handleSearch() {
    // 
  }
  public handleChangePagination(value) {
    if (typeof value === 'number') {
      this.$store.commit(MutationTypeSaleEduCampaign.PaginationChange, {
        ...this.pagination,
        size: value
      });
    } else {
      this.$store.commit(MutationTypeSaleEduCampaign.PaginationChange, {
        ...this.pagination,
        ...value
      });
    }
    this.fetchData();
  }
  public changeItem(value) {
    const idx = this.cloneSelected.indexOf(value);
    if (idx >= 0) {
      this.cloneSelected.splice(idx, 1);

      return;
    }
    this.cloneSelected.push(value);

  }
  public clearFilter() {
    this.filterParams = {
      searchBy: ['importCode', 'importBy', 'dealerName', 'dealerCode'],
      [FilterKey.RewardPaid]: {
        from: null,
        to: null
      },
      [FilterKey.DealerConfirm]: {
        from: null,
        to: null
      },
      [FilterKey.Amount]: {
        from: null,
        to: null
      },
      [FilterKey.BlockedAmount]: {
        from: null,
        to: null
      },
      [FilterKey.ImportStatus]: [],
    };
  }
  public onSelectAll({val, remove, removeData }) {
    if (!remove) {
      this.cloneSelected = this.cloneSelected.concat(val);

      return;
    }
    this.cloneSelected = this.cloneSelected.filter((ar) => {
      return !removeData.find((rm) => rm.id === ar.id);
    });
  }
  public closeDialogDetail() {
    this.visibleDetail = false;
  }
  public handleActions({action, id}) {
    if (action === 'view') {
      this.detailId = id;
      this.visibleDetail = true;

      return;
    }
    if (action === 'checkAsPaid') {
      this.handleCheckAsPaid([id]);

      return;
    }

    return;
  }
  public onEnterFilter(value) {
    this.searchText = value;
    this.$store.commit(MutationTypeSaleEduCampaign.PaginationChange, {
      page: 1,
      limit: 20
    });

    this.fetchData();
  }
  public onClickCheckAsPaid() {
    this.handleCheckAsPaid(this.cloneSelected.map((it) => it.id));
  }
  public onClickExport() {
    this.$msgbox({
      title: 'Export',
      message: `Are you sure want to export?`,
      showCancelButton: true,
      confirmButtonText: this.$t('confirm').toString(),
      cancelButtonText: this.$t('cancel').toString(),
      beforeClose: (action, instance, done) => {
        if (action === 'confirm') {
          instance.confirmButtonLoading = true;
          instance.confirmButtonText = 'Loading...';
          instance.confirmButtonLoading = false;
          this.handleExport(instance, done);
        } else {
          done();
        }

        return;
      }
    }).catch(() => {
      // no handle
    });
  }
  public onClickHistory() {
    this.historyVisible = true;
  }
  public closeHistoryDialog() {
    this.historyVisible = false;
  }
  public handleExport(instance, done) {
    const text = cloneDeep(this.searchText);
    const {
      amount, blockedAmount, dealerConfirm, importStatus, rewardPaid, searchBy
    } = this.filterParams;
    this.$store.dispatch(ActionTypeSaleEduCampaign.Export, {
      object: {
        type: 'banking_payment',
        isReIndex: true,
        filter: {
          rewardPaid: rewardPaid && (rewardPaid.from || rewardPaid.to) ? {
            ...rewardPaid.from ? {
              from: rewardPaid.from
            } : null,
            ...rewardPaid.to ? {
              to: moment(rewardPaid.to).add(1, 'days').subtract(1, 'seconds')
            } : null
          } : null,
          dealerConfirmed: dealerConfirm && (dealerConfirm.from || dealerConfirm.to) ? {
            ...dealerConfirm.from ? {
              from: dealerConfirm.from
            } : null,
            ...dealerConfirm.to ? {
              to: moment(dealerConfirm.to).add(1, 'days').subtract(1, 'seconds')
            } : null
          } : null,
          blockedAmount: blockedAmount && (blockedAmount.from || blockedAmount.to) ? {
            ...blockedAmount.from ? {
              from: blockedAmount.from
            } : null,
            ...blockedAmount.to ? {
              to: blockedAmount.to
            } : null
          } : null,
          amount: amount && (amount.from || amount.to) ? {
            ...amount.from ? {
              from: amount.from
            } : null,
            ...amount.to ? {
              to: amount.to
            } : null
          } : null,
          importStatus: importStatus && importStatus.length ? importStatus : ['successful', 'failed'],
          search: searchBy && searchBy.length && text.length ? {
            ...searchBy.includes('dealerCode') ? {
              dealerCode: text
            } : null,
            ...searchBy.includes('dealerName') ? {
              dealerName: text
            } : null,
            ...searchBy.includes('importCode') ? {
              importCode: text
            } : null,
            ...searchBy.includes('importBy') ? {
              importBy: text
            } : null
          } : null
        }
      },
      onSuccess: (data) => {
        // tslint:disable-next-line:early-exit
        if (data && data.insert_exports_one && data.insert_exports_one.id) {
          this.subscribeURL = <any> gqlClient.subscribe({
          query: zeroCampaignQuery.DOWNLOAD_EXPORT,
          variables : {id: data.insert_exports_one.id},
          fetchPolicy: 'cache-first'
        }).subscribe((results) => {
          // tslint:disable-next-line: early-exit
          if (results.data && results.data.exports && results.data.exports.length
            && results.data.exports[0].exportStatus === 'done') {
            window.open(results.data.exports[0].url, '_blank');
            this.subscribeURL.unsubscribe();
            instance.confirmButtonLoading = false;
            const message = 'Export successfully';
            this.$store.commit(MutationType.OpenTopAlert, {
              message,
              type: TypeAlert.Success
            });
            done();
          }
          // tslint:disable-next-line:early-exit
          if (results.data && results.data.exports && results.data.exports.length
            && results.data.exports[0].exportStatus === 'failed') {
            this.subscribeURL.unsubscribe();
            instance.confirmButtonLoading = false;
            const message = 'Export failure!';
            this.$store.commit(MutationType.OpenTopAlert, {
              message,
              type: TypeAlert.Error
            });
            done();
          }
        });

        }}
    });
  }
  public handleCheckAsPaid(ids) {
    this.$msgbox({
      title: 'Check as Paid',
      message: `Are you sure want to check as paid for ${ids.length} dealers?`,
      showCancelButton: true,
      confirmButtonText: this.$t('confirm').toString(),
      cancelButtonText: this.$t('cancel').toString(),
      beforeClose: (action, instance, done) => {
        if (action === 'confirm') {
          instance.confirmButtonLoading = true;
          instance.confirmButtonText = 'Loading...';
          instance.confirmButtonLoading = false;
          this.$store.dispatch(ActionTypeSaleEduCampaign.CheckAsPaid, {
            ids,
            updatedBy: this.authUser.id,
            paidAt: moment(),
            onSuccess: () => {
              instance.confirmButtonLoading = false;
              const message = 'Update successfully';
              this.$store.commit(MutationType.OpenTopAlert, {
                message,
                type: TypeAlert.Success
              });
              this.fetchData();
            }
          });
          done();
        } else {
          done();
        }

        return;
      }
    }).catch(() => {
      // no handle
    });
  }
  public getActions({confirmedAt, paidAt, importStatus}) {
    if (this.isSalePlanning && confirmedAt && !paidAt && importStatus === 'successful') {
      return ['view', 'checkAsPaid'];
    }

    return ['view'];
  }
  public changeSearchBy(model: IFilterInput[]) {
    this.filterParams = {
      ...this.filterParams,
      searchBy: model[0].value
    };

    this.fetchData();
  }
  public onClickDownloadSample() {
    window.open(this.urlSample, '_blank');
  }
  public changeFilter(model) {
    const importStatus = model.find((e) => e.key === FilterKey.ImportStatus);

    const nextFilter = {
      [FilterKey.ImportStatus]: importStatus ? importStatus.value : []
    };
    this.filterParams = {
      ...cloneDeep(this.filterParams),
      ...nextFilter
    };
    this.$store.commit(MutationTypeSaleEduCampaign.PaginationChange, {
      page: 1,
      limit: 20
    });
    this.fetchData();
  }
  public onClickImport() {
    this.visibleImport = true;
  }
  public closeModalImport() {
    this.visibleImport = false;
    this.$refs.upload.clearFiles();
    this.fileList = [];
  }
  public pushFileUpload(file: any) {
    const f = this.fileList;
    if (this.fileList.length > 0) {
      const _file = this.fileList.filter((it) => it.name === file.name);
      if (_file && _file.length > 0) {
        this.$refs.upload.clearFiles();
        const message = 'File has been exits';
        this.$store.commit(MutationType.OpenTopAlert, {
          message,
          type: TypeAlert.Warning
        });

        return this.$refs.upload.$data.uploadFiles = f;
      }

      return this.fileList = [file];
    }

    f.push(file);

    return this.fileList = [file];
  }
  public onRemove(_file: any, fileList: any) {
    this.fileList = fileList;
  }
  public async submitFile() {
    // tslint:disable-next-line: whitespace
    try {
      this.loadingUpload = true;
      // const uploadedFile = <any> [];
      const token = await DKGqlClient().user.getFirebaseAuthenticateToken();
      await FirebaseAuthService().signInWithCustomToken(token.authenticate);
      const uploadedFile = await Promise.all(this.fileList.map(async (it) => {
        const _file = await uploadToFirebase(it.raw, this.pathUpload);

        // return _file ? uploadedFile.push(_file) : uploadedFile;
        // tslint:disable-next-line:no-var-before-return
        return _file;
      }));

      if (uploadedFile.length > 0) {
        const form = {
          createdBy: this.authUser.id,
          url: uploadedFile[0],
          isReIndex: true,
          type: 'edu_banking'
        };
        this.$store.dispatch(ActionTypeSaleEduCampaign.ImportBankingPayment, {
          form,
          onSuccess: () => {
            this.loadingUpload = false;
            const message = 'Import successfully';
            this.$store.commit(MutationType.OpenTopAlert, {
              message,
              type: TypeAlert.Success
            });
            this.visibleImport = false;
            this.fileList = [];
            this.fileUploaded = [];
            this.$refs.upload.clearFiles();
            this.fetchData();
          },
          onFailure: () => {
            this.loadingUpload = false;

          }});
      }

    } catch (error) {
      console.log(error);
    }

  }
  public handleSort(value) {
    this.sortParam = value;
    this.fetchData();
  }
  public fetchData() {
    const text = cloneDeep(this.searchText);
    const {
      amount, blockedAmount, dealerConfirm, importStatus, rewardPaid, searchBy
    } = this.filterParams;
    this.$store.commit(MutationTypeSaleEduCampaign.ChangeOrderBy, {
      ...this.sortParam && !isEmpty(this.sortParam) ? this.sortParam : {
        importedAt: 'desc'
      }
    });
    this.$store.commit(MutationTypeSaleEduCampaign.FilterChange, stripObject({
      _and: {
        mode: 'special',
        data: [
          ...amount && (amount.from || amount.to) ? [
            ...amount.from ? [
              {
                totalAmount: {
                  _gte: amount.from
                }
              }
            ] : [],
            ...amount.to ? [
              {
                totalAmount: {
                  _lte: amount.to
                }
              }
            ] : []
          ] : [],
          ...blockedAmount && (blockedAmount.from || blockedAmount.to) ? [
            ...blockedAmount.from ? [
              {
                totalBlockedAmount: {
                  _gte: blockedAmount.from
                }
              }
            ] : [],
            ...blockedAmount.to ? [
              {
                totalBlockedAmount: {
                  _lte: blockedAmount.to
                }
              }
            ] : []
          ] : [],
          ...dealerConfirm && (dealerConfirm.from || dealerConfirm.to) ? [
            ...dealerConfirm.from ? [
              {
                confirmedAt: {
                  _gte: dealerConfirm.from
                }
              }
            ] : [],
            ...dealerConfirm.to ? [
              {
                confirmedAt: {
                  _lte: moment(dealerConfirm.to).add(1, 'days').subtract(1, 'seconds')
                }
              }
            ] : []
          ] : [],
          ...rewardPaid && (rewardPaid.from || rewardPaid.to) ? [
            ...rewardPaid.from ? [
              {
                paidAt: {
                  _gte: rewardPaid.from
                }
              }
            ] : [],
            ...rewardPaid.to ? [
              {
                paidAt: {
                  _lte: moment(rewardPaid.to).add(1, 'days').subtract(1, 'seconds')
                }
              }
            ] : []
          ] : [],
          {
            ...importStatus && importStatus.length ? {
              importStatus: {
                _in: importStatus
              }
            } : {}
          },
          {
            ...text && text.trim().length > 0 && searchBy && searchBy.length ? {
              _or: [
                {
                  ...searchBy.includes('dealerCode') ? {
                    dealer: {
                      dealerCode: {
                        _ilike: `%${text}%`
                      }
                    }
                  } : {}
                },
                {
                  ...searchBy.includes('dealerName') ? {
                    dealer: {
                      name: {
                        _ilike: `%${text}%`
                      }
                    }
                  } : {}
                },
                {
                  ...searchBy.includes('importCode') ? {
                    importCode: {
                      _ilike: `%${text}%`
                    }
                  } : {}
                },
                {
                  ...searchBy.includes('importBy') ? {
                    creator: {
                      name: {
                        _ilike: `%${text}%`
                      }
                    }
                  } : {}
                },
              ].filter((e) => !!e)
            } : {}
          }
        ]
      }
    }));
    this.$store.dispatch(ActionTypeSaleEduCampaign.FilterNoCache);
  }
  protected mounted() {
    this.$nextTick(() => {
      this.$store.commit(MutationType.ClearBack);
      this.$store.commit(MutationType.SetBreadcrumb, this.$t('title.sales-edu-campaign'));
    });
    this.fetchData();
  }
  protected beforeDestroy() {
    this.$store.commit(MutationType.ClearBreadcrumb);
  }
}
