import { IPaginationParams } from 'root/api/graphql/Core';
import { crudMutations, CRUDState, ICRUDState } from 'root/store/helpers';
import { MutationTypeAccountStaff } from './types';

export interface IAccountStaffState extends ICRUDState<any> {
  loading: boolean;
}

export const defaultState: IAccountStaffState = {
  ...CRUDState(),
  loading: false,
};

export const mutations = {
  ...crudMutations('accountStaff'),
  [MutationTypeAccountStaff.GetListAccountStaff](state: IAccountStaffState, data: any) {
    state.data = data.dealer_shops;
    state.pagination.total = data.dealer_shops_aggregate.aggregate.count;
  },
  [MutationTypeAccountStaff.SetPagination](state: IAccountStaffState, pagination: IPaginationParams) {
    state.pagination.limit = pagination.limit;
    state.pagination.page = pagination.page;
  }
};
