import axios from 'axios';
import saveAs from 'file-saver';
import moment from 'moment';
import { SystemRoleAlias } from 'root/admin/User/Store/types';
import { gqlClient } from 'root/api/graphql/Core';
import { raCampaignQuery } from 'root/api/graphql/Query/raCampaign';
import { Avatar } from 'root/components/Avatar';
import { ruleRequired, FormatDateFullTime } from 'root/helpers';
import { IAddress, IUser } from 'root/models';
import { IState } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import { ActionTypeRaCampaign } from '../../Store/types';
import { ContractStatus, Qualified, QualifiedCode,
   RequestAction, RequestStatus, RequestStatusCode } from '../CampaignDetail';
import './styles.scss';
@Component({
  template: require('./view.html'),
  props: {
    visible: Boolean,
    detailId: String
  },
  components: {
    avatar: Avatar
  },
  computed: {
    ...mapState({
      // userDetail: (state: IState) => state.user.userDetail,
      authUser: (state: IState) => state.global.authUser,
    })
  }
})
export class CampaignDetailModel extends Vue {
  public FormatDateFullTime = FormatDateFullTime;
  public userDetail = null;
  public detailId: string;
  public review: string = '';
  public authUser: IUser;
  public visibleRequest: boolean = false;
  public visibleUpdateModel: boolean = false;
  public titleRequest: string = '';
  public messageRequest: string = '';
  public reason: string = '';
  public loading: boolean = false;
  public loadingButton: boolean = false;
  public loadingBtn: boolean = false;
  public PackageOption: any[] = [];
  public package: any[] = [];
  public packages: any[] = [];
  public showPackage: any[] = [];
  public modelValue: any[] = [];
  public actionHistory: any[] = [];
  public qualifyvalue: string = '';
  public isErrorUpdateModel: boolean = false;
  public isErrorModel1: boolean = false;
  public isErrorModel2: boolean = false;
  public isErrorModel3: boolean = false;
  public isErrorLengthModel1: boolean = false;
  public isErrorLengthModel2: boolean = false;
  public isErrorLengthModel3: boolean = false;
  public isErrorQualify: boolean = false;
  public isErrorReview: boolean = false;
  public isBranchReview: boolean = false;
  public isDealerReup: boolean = true;
  public isOnCampaign: boolean = false;
  public isEndedCampaign: boolean = false;
  public img: string = '';
  public model1: string = '';
  public model2: string = '';
  public model3: string = '';
  public optionmodel1: any = [];
  public optionmodel2: any = [];
  public optionmodel3: any = [];
  public visibleMedia: boolean = false;
  public visibleMediaReimbursemen: boolean = false;
  public isCheckConfirmationCode: boolean = false;
  public currentIndex: number = 0;
  public currentIndexReimbursement: number = 0;
  public imageList: any[] = [];
  public imageListReimbursement: any[] = [];
  public CloneImageListReimbursement: any[] = [];
  public get qualifyOption() {
    return [
      {
        label: 'Qualified',
        value: 'qualified',
      },
      {
        label: 'Non Qualified',
        value: 'non_qualified',
      }
    ];
  }
  public get rules() {
    return {
      required: ruleRequired(),
    };
  }
  public get currentImg() {
    return this.imageList[Math.abs(this.currentIndex) % this.imageList.length];
  }
  public get currentImgReimbursement() {
    return this.imageListReimbursement[Math.abs(this.currentIndexReimbursement) % this.imageListReimbursement.length];
  }
  public get checkCanRequestUpdate() {
    if (this.userDetail && this.userDetail.acl_campaigns.length &&
       this.userDetail.acl_campaigns[0].requestStatus === 'waiting_for_review') {
      return true;
    }

    return false;
  }
  public get checkCanApprove() {
    if (this.userDetail && this.userDetail.acl_campaigns.length &&
      this.userDetail.acl_campaigns[0].requestStatus === RequestStatusCode.WaitingForReview ||
      this.userDetail && this.userDetail.acl_campaigns.length &&
      this.userDetail.acl_campaigns[0].requestStatus === RequestStatusCode.WaitingForReup) {

      return true;
    }

    return false;
  }
  public get isSuperAdmin() {
    if (this.authUser.superAdmin) {
      return true;
    }

    return false;
  }
  public get isSalePlanning() {
    if (this.authUser.role.alias === SystemRoleAlias.SaleAdmin) {
      return true;
    }

    return false;
  }
  public get isBranchPic() {
    if (this.authUser.role.alias === SystemRoleAlias.BranchManager) {
      return true;
    }

    return false;
  }
  public get isDepartmentPic() {
    if (this.authUser.role.alias === SystemRoleAlias.GroupLeader) {
      return true;
    }

    return false;
  }
  public get isDisableSubmit() {
    if (this.userDetail && this.userDetail.acl_campaigns.length &&
    this.userDetail.acl_campaigns[0].confirmationCode || this.isCheckConfirmationCode) {
      return false;
    }

    return true;
  }
  public get getShowForceButton() {
    if (this.userDetail && this.userDetail.acl_campaigns.length && this.userDetail.acl_campaigns[0].confirmationCode) {
      return false;
    }

    return true;
  }
  public get checkCanUpdateModel() {
    if (this.userDetail && this.userDetail.acl_campaigns.length &&
      this.userDetail.acl_campaigns[0].requestStatus === RequestStatusCode.WaitingForReview ||
      this.userDetail && this.userDetail.acl_campaigns.length &&
      this.userDetail.acl_campaigns[0].requestStatus === RequestStatusCode.WaitingForReup) {

      return true;
    }

    return false;
  }
  public get checkCanRequestReup() {
    if (this.userDetail && this.userDetail.acl_campaigns.length &&
      this.userDetail.acl_campaigns[0].requestStatus === RequestStatusCode.WaitingForReview
      || this.userDetail && this.userDetail.acl_campaigns.length && this.userDetail.acl_campaigns[0].isDealerReUp) {

      return true;
    }

    return false;
  }
  public get checkPushReminder() {
    if (this.userDetail && this.userDetail.acl_campaigns.length &&
      this.userDetail.dealerShops && this.userDetail.dealerShops.length
      && this.userDetail.dealerShops[0].dealer && this.userDetail.dealerShops[0].dealer.information
      && this.userDetail.dealerShops[0].dealer.information.requestedRewardReceiptURLs.length === 0) {

      return true;
    }

    return false;
  }
  public checkModel(a, b) {
    if (b.indexOf(a.value) >= 0) {
      return true;
    }

    return false;
  }
  public displayAddress(model: IAddress) {
    return model && model.province ? this.$store.getters['getAddressString'](model) : '';
  }
  public formaterStatus(model: any) {
    switch (model) {
    case RequestStatusCode.NotJoin:
      return RequestStatus.NotJoin;
    case RequestStatusCode.WaitingForReup:
      return RequestStatus.WaitingForReup;
    case RequestStatusCode.WaitingForReview:
      return RequestStatus.WaitingForReview;
    case RequestStatusCode.Approved:
      return RequestStatus.Approved;
    case RequestStatusCode.Rejected:
      return RequestStatus.Rejected;
    case 'notification_reminder':
      return 'Push Reminder';
    }
  }
  public pushReminder() {
    this.$msgbox({
      title: 'Confirm Push Remider',
      message: `Do you want to push reminder update profile for this shop?`,
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      beforeClose: (action, instance, done) => {
        if (action === 'confirm') {
          instance.confirmButtonLoading = true;
          instance.confirmButtonText = 'Loading...';
          this.handlePushReminder({ instance, done });
        } else {
          done();
        }

        return;
      }
    })
        .then(() => {
          this.$message({
            type: 'success',
            message: 'Push Reminder Successfully'
          });
        })
        .catch(() => {
          // no handle
        });
  }
  public handlePushReminder(model: any) {
    const formReminder = {
      // tslint:disable-next-line: max-line-length
      subtitle: 'Bạn chưa cập nhật đủ hồ sơ trả thưởng chương trình Trưng Bày ĐHKK Dân dụng. Vui lòng cập nhật hồ sơ trả thưởng trước ngày 31/07/2022 để nhận thưởng.',
      // tslint:disable-next-line: max-line-length
      title: 'Bạn chưa cập nhật đủ hồ sơ trả thưởng chương trình Trưng Bày ĐHKK Dân dụng. Vui lòng cập nhật hồ sơ trả thưởng trước ngày 31/07/2022 để nhận thưởng.',
      target: ['dealer'],
      scheduledAt: moment(Date.now()).utc(false).format('YYYY-MM-DD HH:mm:ss'),
      // tslint:disable-next-line: max-line-length
      description: 'Bạn chưa cập nhật đủ hồ sơ trả thưởng chương trình Trưng Bày ĐHKK Dân dụng. Vui lòng cập nhật hồ sơ trả thưởng trước ngày 31/07/2022 để nhận thưởng.',
      receiverId: this.userDetail && this.userDetail.acl_campaigns.length &&
      this.userDetail.acl_campaigns[0].dealer.id ? [this.userDetail.acl_campaigns[0].dealer.id] : '',
      dealerId: this.userDetail.acl_campaigns[0].dealer.id,
      type: 'manual'
    };
    this.$store.dispatch(ActionTypeRaCampaign.PushReminder, {
      form: formReminder,
      campaignId: this.userDetail.acl_campaigns[0].id,
      createdBy: this.authUser.id,
      onSuccess: () => {
        model.instance.confirmButtonLoading = false;
        model.instance.confirmButtonText = 'Yes';
        model.done();
      }
    });
  }
  public submitReview() {
    if (!this.qualifyvalue) {
      this.isErrorQualify = true;

      return;
    }
    this.isErrorQualify = false;
    let _qualified = '';
    switch (this.qualifyvalue) {
    case Qualified.Qualified:
      _qualified = QualifiedCode.Qualified;
      break;
    case Qualified.NonQualified:
      _qualified = QualifiedCode.NonQualified;
      break;
    }
    // tslint:disable-next-line: early-exit
    if (this.qualifyvalue === Qualified.NonQualified && !this.review) {
      this.isErrorReview = true;

      return;
    }
    this.isErrorReview = false;
    this.$store.dispatch(ActionTypeRaCampaign.SubmitReview, {
      id: this.userDetail.acl_campaigns[0].id,
      review: this.review,
      qualify: _qualified,
      force: this.isCheckConfirmationCode,
      createdBy: this.authUser.id,
      onSuccess: () => {
        this.qualifyvalue = '';
        this.review = '';
        this.$message({
          type: 'success',
          message: this.$t('message.submitSuccess').toString()
        });
        this.fetchData();
      }
    });
  }
  public formatterClassification(model: any) {
    switch (model) {
    case QualifiedCode.Qualified:
      return Qualified.Qualified;
    case QualifiedCode.NonQualified:
      return Qualified.NonQualified;
    }
  }
  public formatterAction(action) {
    switch (action) {
    case RequestStatusCode.Approved:
      return RequestAction.Approved;
    case RequestStatusCode.Rejected:
      return RequestAction.Rejected;
    }
  }
  public accept(actionRequest) {
    this.$msgbox({
      title: actionRequest === RequestStatusCode.Approved ?
        this.$t('approve_confirm').toString() : this.$t('reject_confirm').toString(),
      message: `Do you want to ${this.formatterAction(actionRequest)} campaign request for shop?`,
      showCancelButton: true,
      customClass: actionRequest === RequestStatusCode.Rejected ? 'message-error' : '',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      beforeClose: (action, instance, done) => {
        if (action === 'confirm') {
          instance.confirmButtonLoading = true;
          instance.confirmButtonText = 'Loading...';
          this.handleApprove({ instance, done, actionRequest });
        } else {
          done();
        }

        return;
      }
    })
        .then(() => {
          this.$message({
            type: 'success',
            message: actionRequest === RequestStatusCode.Approved ?
              this.$t('message.approveSuccess').toString() : this.$t('message.rejectSuccess').toString()
          });
        })
        .catch(() => {
          // no handle
        });
  }

  public handleApprove(model) {
    this.$store.dispatch(ActionTypeRaCampaign.ApproveShop, {
      ids: [this.userDetail.acl_campaigns[0].id],
      action: model.actionRequest,
      updatedBy: this.authUser.id,
      onSuccess: () => {
        this.fetchData();
        model.instance.confirmButtonLoading = false;
        model.instance.confirmButtonText = 'Yes';
        model.done();
      },
      onFailure: () => {
        model.instance.confirmButtonLoading = false;
        model.instance.confirmButtonText = 'No';
      }
    });
  }
  public handleReject(action) {
    this.$store.dispatch(ActionTypeRaCampaign.ApproveShop, {
      action,
      ids: [this.userDetail.acl_campaigns[0].id],
      reason: this.reason,
      updatedBy: this.authUser.id,
      onSuccess: () => {
        this.$message({
          type: 'success',
          message: this.$t('message.rejectSuccess').toString()
        });
        this.loadingBtn = false;
        this.fetchData();
        this.closeDialogRequest();
      },
      onFailure: () => {
        // nohandle
      }
    });
  }
  public handleRequestReup(action) {
    this.$store.dispatch(ActionTypeRaCampaign.RequestReup, {
      action,
      ids: [this.userDetail.acl_campaigns[0].id],
      reason: this.reason,
      updatedBy: this.authUser.id,
      onSuccess: () => {
        this.$message({
          type: 'success',
          message: this.$t('message.requestUpdate').toString()
        });
        this.loadingBtn = false;
        this.fetchData();
        this.closeDialogRequest();
      },
      onFailure: () => {
        // nohandle
      }
    });
  }
  public fetchData() {
    this.loading = true;
    this.$store.dispatch(ActionTypeRaCampaign.GetShopDetail, {
      id: this.detailId,
      onSuccess: (model) => {
        this.loading = false;
        this.userDetail = model.shops[0];
        this.showPackage = [];
        this.imageList = [];
        this.imageListReimbursement = [];
        this.CloneImageListReimbursement = [];
        if (model && model.shops[0].acl_campaigns.length && model.shops[0].acl_campaigns[0].pictureURLs) {
          // tslint:disable-next-line: forin
          for (const picture in this.userDetail.acl_campaigns[0].pictureURLs) {
            this.imageList.push(this.userDetail.acl_campaigns[0].pictureURLs[picture]);
          }
        }
        if (this.userDetail && this.userDetail.dealerShops && this.userDetail.dealerShops.length
          && this.userDetail.dealerShops[0].dealer && this.userDetail.dealerShops[0].dealer.information
          && this.userDetail.dealerShops[0].dealer.information.requestedRewardReceiptURLs.length) {
          // tslint:disable-next-line: forin
          for (const picture in this.userDetail.dealerShops[0].dealer.information.requestedRewardReceiptURLs) {
            // tslint:disable-next-line: max-line-length
            const splitArr = this.userDetail.dealerShops[0].dealer.information.requestedRewardReceiptURLs[picture].split('&daikinFileType=');
            if (splitArr && splitArr.length && splitArr.length === 1) {
              this.imageListReimbursement.push(splitArr[0]);

            }
            if (splitArr && splitArr.length && splitArr.length >= 2 && splitArr[1] === 'pdf') {
              this.CloneImageListReimbursement.push(splitArr[0]);

            }
            if (splitArr && splitArr.length && splitArr.length >= 2 && splitArr[1] === 'pic') {
              this.imageListReimbursement.push(splitArr[0]);

            }
            // this.imageListReimbursement.push(
            //   this.userDetail.dealerShops[0].dealer.information.requestedRewardReceiptURLs[picture]);
          }
        }
        // tslint:disable-next-line: early-exit
        if (model && model.shops[0].acl_campaigns.length) {
          this.isDealerReup = this.userDetail.acl_campaigns[0].isDealerReUp;
          this.isBranchReview = this.userDetail.acl_campaigns[0].isBranchReview;
          // this.userDetail.acl_campaigns[0].packages.map((item) => {
          //   this.showPackage.push(this.packages[item - 1]);
          // });
          this.showPackage.push(this.userDetail.acl_campaigns[0].model1);
          this.model1 = this.userDetail.acl_campaigns[0].model1;
          this.showPackage.push(this.userDetail.acl_campaigns[0].model2);
          this.model2 = this.userDetail.acl_campaigns[0].model2;
          this.showPackage.push(this.userDetail.acl_campaigns[0].model3);
          this.model3 = this.userDetail.acl_campaigns[0].model3;
          // tslint:disable-next-line: no-unused
          const getActionHistory = <any> gqlClient.subscribe({
            query: raCampaignQuery.GET_ACTION_HISTORY,
            variables: {id: this.userDetail.acl_campaigns[0].id},
            fetchPolicy: 'cache-first'
          }).subscribe((result) => {
            if (result.data) {
              this.actionHistory = result.data.acl_campaign_status_logs;
            }
          }, (error) => {
            console.error('Get action history failed', error);
          });
        }
      }
    });
  }
  public async openDialog() {
    this.$store.dispatch(ActionTypeRaCampaign.GetCampaignConfiguration, {
      onSuccess: (aclConfig) => {
        this.optionmodel1 = [];
        this.optionmodel2 = [];
        this.optionmodel3 = [];
        if (moment(aclConfig[0].aclCampaignConfig.uploadStartDate).isBefore(moment()) &&
          moment(aclConfig[0].aclCampaignConfig.uploadEndDate).isAfter(moment())) {
          this.isOnCampaign = true;
        }
        if (aclConfig.length && aclConfig[0].metadata && aclConfig[0].metadata.model1) {
          aclConfig[0].metadata.model1.map((model) => {
            this.optionmodel1.push({
              value: model,
              label: model
            });
          });
        }
        if (aclConfig.length && aclConfig[0].metadata && aclConfig[0].metadata.model2) {
          aclConfig[0].metadata.model2.map((model) => {
            this.optionmodel2.push({
              value: model,
              label: model
            });
          });
        }
        // tslint:disable-next-line: early-exit
        if (aclConfig.length && aclConfig[0].metadata && aclConfig[0].metadata.model3) {
          aclConfig[0].metadata.model3.map((model) => {
            this.optionmodel3.push({
              value: model,
              label: model
            });
          });
        }
      }
    });
    // this.$store.dispatch(ActionTypeRaCampaign.GetPackage, {
    //   onSuccess: (aclPackage) => {
    //     this.packages = [];
    //     this.PackageOption = [];
    //     this.isEndedCampaign = aclPackage[0].isEnded;
    //     // tslint:disable-next-line: forin
    //     for (const model in aclPackage[0].metadata.models) {
    //       this.PackageOption.push({
    //         value: parseInt(model, null),
    //         label: aclPackage[0].metadata.models[parseInt(model, null)]['name']
    //       });
    //       this.packages.push(aclPackage[0].metadata.models[parseInt(model, null)]['name']);
    //     }
    //   }
    // });
    this.fetchData();
  }
  public formatterStaff(model: any) {
    return model.dealerShops.filter(
      (item) => !item.isOwner && item.contractStatus === ContractStatus.Accepted
    ).length;
  }
  public formatterRole(model: any) {
    const _dealer = model.dealerShops.find(
      (item) => item.isOwner
    );
    if (_dealer && _dealer.dealer && _dealer.dealer.role && _dealer.dealer.role.alias === 'daikin_dealer') {
      return 'Direct Dealer';
    }
    if (_dealer && _dealer.dealer && _dealer.dealer.role && _dealer.dealer.role.alias === 'sub_dealer') {
      return 'Sub-Dealer';
    }

    return '--';
  }
  public closeDialogRequest() {
    // change after
    this.visibleRequest = false;
    this.titleRequest = '';
    this.messageRequest = '';
    this.reason = '';
  }
  public closeDialogUpdate() {
    // change after
    // this.visibleRequest = false;
    // this.titleRequest = '';
    // this.messageRequest = '';
    // this.reason = '';
    this.visibleUpdateModel = false;
    // this.modelValue = [];
  }
  public requestUpdate(action) {
    if (action === RequestAction.Rejected) {
      this.titleRequest = 'Reject Request';
      this.messageRequest = `Do you want to reject campaign request for ${this.userDetail.name}`;
      this.visibleRequest = true;

      return;
    }

    this.titleRequest = 'Request Update';
    this.messageRequest = `Do you want to request update new images for ${this.userDetail.name}`;
    this.visibleRequest = true;
  }
  public reject() {
    this.loadingBtn = true;
    if (this.titleRequest === 'Reject Request') {
      this.handleReject('rejected');

      return;
    }
    this.handleRequestReup('request_re_up');
  }
  public handleUpdate() {
    this.visibleUpdateModel = true;
  }
  public formatterDirectName(model) {
    if (model.dealerShops.length) {
      return model.dealerShops.find((shops) => shops.isOwner).dealer.name;
    }
  }
  public formatterDirectCode(model) {
    if (model.dealerShops.length) {
      return model.dealerShops.find((shops) => shops.isOwner).dealer.dealerCode;
    }
  }
  public updateModel() {
    this.isErrorModel1 = false;
    this.isErrorModel2 = false;
    this.isErrorModel3 = false;
    this.isErrorLengthModel1 = false;
    this.isErrorLengthModel2 = false;
    this.isErrorLengthModel3 = false;
    if (!this.model1) {
      return this.isErrorModel1 = true;
    }
    if (this.model1.length > 11) {
      return this.isErrorLengthModel1 = true;
    }
    if (!this.model2) {
      return this.isErrorModel2 = true;
    }
    if (this.model2.length > 11) {
      return this.isErrorLengthModel2 = true;
    }
    if (!this.model2) {
      return this.isErrorModel2 = true;
    }
    if (this.model3.length > 11) {
      return this.isErrorLengthModel3 = true;
    }
    this.loadingButton = true;
    this.$store.dispatch(ActionTypeRaCampaign.UpdatePackage, {
      id: this.userDetail.acl_campaigns[0].id,
      models: {
        ftkb: this.model1,
        ftkc: this.model2,
        any: this.model3
      },
      onSuccess: () => {
        this.fetchData();
        this.$message({
          type: 'success',
          message: this.$t('message.updatePackage').toString()
        });
        this.loadingButton = false;
        this.closeDialogUpdate();
        // this.isErrorUpdateModel = false;
        this.isErrorModel1 = false;
        this.isErrorModel2 = false;
        this.isErrorModel3 = false;
        this.isErrorLengthModel1 = false;
        this.isErrorLengthModel2 = false;
        this.isErrorLengthModel3 = false;
      }
    });
  }
  public clickDownloadPDF(url) {
    axios
        .get(url, {responseType: 'blob'})
        .then((response) => {
          saveAs(response.data, 'downloaded-file.pdf');
        });
  }
  public clickViewPDF(url) {
    window.open(url, '_blank');
  }
  public closeDialog() {
    this.$emit('closeDialog');
  }
  public clickViewImage(index: number) {
    this.currentIndex = index - 1;
    this.visibleMedia = true;
  }
  public clickViewImageReimbursement(index: number) {
    this.currentIndexReimbursement = index;
    this.visibleMediaReimbursemen = true;
  }
  public closeDialogMedia() {
    this.visibleMedia = false;
  }
  public prev() {
    this.currentIndex -= 1;
  }
  public next() {
    this.currentIndex += 1;
  }
  public closeDialogMediaReimbursement() {
    this.visibleMediaReimbursemen = false;
  }
  public prevReimbursement() {
    this.currentIndexReimbursement -= 1;
  }
  public nextReimbursement() {
    this.currentIndexReimbursement += 1;
  }
}
