import { gqlClient } from '../Core';
import { CRUDGqlService, ICRUDGqlService } from '../Core/crud';
import { productListQuery, Products } from '../Query/product';

export interface IProductGqlService extends ICRUDGqlService<any> {
  //
  getListProduct(): Promise<any>;
  updateStatusProduct(id: string, status: string): Promise<any>;
  createProduct(form: any): Promise<any>;
  updateScanProductMode(value: string): Promise<any>;
  getScanProductMode(): Promise<any>;
  getListRegistation(search: string, branch: any, order: any, limit: number, offset: number): Promise<any>;
  getListWarranty(search: string, branch: any,
                  department: any, status: any, limit: number, offset: number): Promise<any>;
  getDetailDealerProduct(id: string): Promise<any>;
  getDealerProductList(id: string, search: string,
                       deviceType: string, status: any, limit: number, offset: number): Promise<any>;
  getProductDetail(id: string): Promise<any>;
  getDeviceType(): Promise<any>;
  getListRegistationSubDealer(search: string, branch: any, order: any, limit: number, offset: number): Promise<any>;
  getStatistic(): Promise<any>;
}
export function productGqlService(): IProductGqlService {
  async function getListProduct(): Promise<any> {
    const results = await gqlClient.query({
      query: productListQuery.GET_PRODUCT_LIST,
      fetchPolicy: 'network-only'
    });
    if (results.errors) {
      throw results.errors;
    }

    return results.data.products;
  }
  async function updateStatusProduct(id: string, status: string) {
    const result = await gqlClient.mutate({
      mutation: productListQuery.UPDATE_PRODUCT_STATUS,
      variables: {
        id,
        status
      }
    });
    if (result.errors) {
      throw result.errors;
    }
  }
  async function createProduct(form: any) {
    const result = await gqlClient.mutate({
      mutation: productListQuery.CREATE_PRODUCT,
      variables: {
        form
      }
    });
    if (result.errors) {
      throw result.errors;
    }
  }
  async function updateScanProductMode(value: string) {
    const result = await gqlClient.mutate({
      mutation: productListQuery.UPDATE_SCAN_PRODUCT_MODE,
      variables: {
        value
      }
    });
    if (result.errors) {
      throw result.errors;
    }
  }
  async function getScanProductMode(): Promise<any> {
    const results = await gqlClient.query({
      query: productListQuery.GET_SCAN_PRODUCT_MODE,
      fetchPolicy: 'network-only'
    });
    if (results.errors) {
      throw results.errors;
    }

    return results.data.system_configurations;
  }
  async function getListRegistation(search: string, branch: any, order: any,
                                    limit: number, offset: number): Promise<any> {
    const results = await gqlClient.query({
      query: productListQuery.GET_LIST_PRODUCT_REGISTATION,
      variables: {
        search, branch, order, limit, offset
      },
      fetchPolicy: 'network-only'
    });
    if (results.errors) {
      throw results.errors;
    }

    return results.data;
  }
  async function getListWarranty(search: string, branch: any, department: any,
                                 status: any, limit: number, offset: number): Promise<any> {
    if (branch && department) {
      const results = await gqlClient.query({
        query: productListQuery.GET_LIST_PRODUCT_WARRANTY,
        variables: {
          search, branch, department, status, limit, offset
        },
        fetchPolicy: 'network-only'
      });
      if (results.errors) {
        throw results.errors;
      }

      return results.data;
    }
    if (!branch && department) {
      const resultsDepartment = await gqlClient.query({
        query: productListQuery.GET_LIST_PRODUCT_WARRANTY_DEPARTMENT,
        variables: {
          search, department, status, limit, offset
        },
        fetchPolicy: 'network-only'
      });
      if (resultsDepartment.errors) {
        throw resultsDepartment.errors;
      }

      return resultsDepartment.data;
    }
    if (branch && !department) {
      const resultsBranch = await gqlClient.query({
        query: productListQuery.GET_LIST_PRODUCT_WARRANTY_BRANCH,
        variables: {
          search, branch, status, limit, offset
        },
        fetchPolicy: 'network-only'
      });
      if (resultsBranch.errors) {
        throw resultsBranch.errors;
      }

      return resultsBranch.data;
    }
    const resultsAll = await gqlClient.query({
      query: productListQuery.GET_LIST_PRODUCT_WARRANTY_ALL,
      variables: {
        search, status, limit, offset
      },
      fetchPolicy: 'network-only'
    });
    if (resultsAll.errors) {
      throw resultsAll.errors;
    }

    return resultsAll.data;

  }
  async function getDetailDealerProduct(id: string): Promise<any> {
    const results = await gqlClient.query({
      query: productListQuery.GET_DEALER_PRODUCT_DETAIL,
      variables: {
        id
      },
      fetchPolicy: 'network-only'
    });
    if (results.errors) {
      throw results.errors;
    }

    return results.data;
  }
  async function getDealerProductList(id: string, search: string,
                                      deviceType: string, status: any, limit: number, offset: number): Promise<any> {
    const results = await gqlClient.query({
      query: productListQuery.GET_DEALER_PRODUCT_WARRANTY,
      variables: {
        id, search, deviceType, status, limit, offset
      },
      fetchPolicy: 'network-only'
    });
    if (results.errors) {
      throw results.errors;
    }

    return results.data;
  }
  async function getProductDetail(id: string): Promise<any> {
    const results = await gqlClient.query({
      query: productListQuery.GET_PRODUCT_DETAIL,
      variables: {
        id
      },
      fetchPolicy: 'network-only'
    });
    if (results.errors) {
      throw results.errors;
    }

    return results.data;
  }
  async function getDeviceType(): Promise<any> {
    const results = await gqlClient.query({
      query: productListQuery.GET_DEVICE_TYPE,
      fetchPolicy: 'network-only'
    });
    if (results.errors) {
      throw results.errors;
    }

    return results.data;
  }
  async function getListRegistationSubDealer(search: string, branch: any, order: any,
                                             limit: number, offset: number): Promise<any> {
    const results = await gqlClient.query({
      query: productListQuery.GET_LIST_PRODUCT_REGISTATION_SUB_DEALER,
      variables: {
        search, branch, order, limit, offset
      },
      fetchPolicy: 'network-only'
    });
    if (results.errors) {
      throw results.errors;
    }

    return results.data;
  }
  async function getStatistic(): Promise<any> {
    const results = await gqlClient.query({
      query: productListQuery.GET_STATISTIC,
      fetchPolicy: 'network-only'
    });
    if (results.errors) {
      throw results.errors;
    }

    return results.data;
  }

  return {
    ...CRUDGqlService<any>(Products, productListQuery),
    getListProduct,
    updateStatusProduct,
    createProduct,
    updateScanProductMode,
    getScanProductMode,
    getListRegistation,
    getListWarranty,
    getDetailDealerProduct,
    getDealerProductList,
    getProductDetail,
    getDeviceType,
    getListRegistationSubDealer,
    getStatistic
  };
}
