import { TabPane } from 'element-ui';
import { cloneDeep } from 'lodash';
import { ActionTypeBranchManage } from 'root/admin/BranchAndGroup/BranchManage/Store/types';
import { ActionTypeProduct, MutationTypeProduct } from 'root/admin/Product/Store/types';
import { IPaginationParams } from 'root/api/graphql/Core';
import { FilterByBranch } from 'root/components/FilterByBranch';
import { fullNameUser } from 'root/helpers';
import { IAddress, IBreadcrumb, IFilterInput, IUser } from 'root/models';
import { IState, MutationType } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import { ProductManageRouterName, ProductRegistationTab } from '..';
import './styles.scss';

const enum FilterKey {
  Branch = 'branch'
}
@Component({
  template: require('./view.html'),
  computed: {
    ...mapState({
      data: (state: IState) => state.product.data,
      loading: (state: IState) => state.product.loading,
      pagination: (state: IState) => state.product.pagination,
      authUser: (state: IState) => state.global.authUser,
      branch: (state: IState) => state.branchManage.data,
    }),
  },
  components: {
    'btn-filter-by-branch': FilterByBranch
  }
})

export class DealerProduct extends Vue {
  public get serviceId(): string {
    return this.$route.params.id;
  }

  public get activeTab() {
    return ProductRegistationTab.DealerProduct;
  }

  public set activeTab(_value: string) {
    //
  }
  public get isSalePlanning() {
    if (this.authUser.superAdmin || this.authUser.role.alias === 'admin'
      || this.authUser.role.alias === 'sale_admin') {
      return true;
    }

    return false;
  }
  public get isBranchPic() {
    if (this.authUser.role.alias === 'branch_manager') {
      return true;
    }

    return false;
  }
  public get isDepartmentPic() {
    if (this.authUser.role.alias === 'group_leader') {
      return true;
    }

    return false;
  }
  public get dataFilter() {
    const _branch: IFilterInput[] = this.branch.reduce((res, item) => {
      res.push({
        key: item.id,
        value: item.id,
        name: item.name
      });

      return res;
    }, []);

    return [
      {
        key: FilterKey.Branch,
        name: 'Branch',
        value: _branch
      },
    ];
  }
  public get selectedFilter() {
    const _filterParams = cloneDeep(this.filterParams);

    return [
      {
        key: FilterKey.Branch,
        value: _filterParams[FilterKey.Branch]
      }
    ];
  }
  public get columns() {
    return [
      {
        prop: 'name',
        label: 'Dealer Name',
        labelItem: 'Code',
        formatter: (model: any) => {
          return fullNameUser(model) || '--';
        },
        avatar: {
          formatter: (model: any) => {
            return model ? model : null;
          }
        },
        width: 250,
        fixed: true
      },
      {
        prop: 'shortName',
        label: 'Short Name',
        width: 180,
        formatter: (model: any) => {
          return model.shortName || '--';
        }
      },
      {
        prop: 'proshopType',
        label: 'Type',
        width: 150,
        formatter: (model: any) => {
          return model.proshopType ? model.proshopType.name : '--';
        }
      },
      // {
      //   prop: 'address',
      //   label: 'Address',
      //   width: 220,
      //   formatter: (model: any) => {
      //     if (model.address) {
      //       return this.displayAddress(model.address) || '--';
      //     }

      //     return '--';
      //   }
      // },
      {
        prop: 'branch',
        label: 'Branch',
        width: 150,
        formatter: (model: any) => {
          if (model.branch && model.branch.name) {
            return model.branch.name;
          }

          return '--';
        }
      },
      {
        prop: 'department',
        label: 'Department',
        width: 180,
        formatter: (model: any) => {
          if (model.group && model.group.name) {
            return model.group.name;
          }

          return '--';
        }
      },
      // {
      //   prop: 'email',
      //   label: 'Owner Email',
      //   width: 200,
      //   formatter: (model: any) => {
      //     return model.email || '--';
      //   }
      // },
      {
        prop: 'totalSuccess',
        label: 'Total Success',
        width: 120,
        formatter: (model: any) => {
          return model.countWarrantySuccesses ? model.countWarrantySuccesses : 0;
        },
        enableSort: true,
      },
      {
        prop: 'joinedFail',
        label: 'Total Failed',
        width: 120,
        formatter: (model: any) => {
          return model.countWarrantyFails ? model.countWarrantyFails : 0;
        },
        enableSort: true,
      },
      {
        prop: 'action',
        label: '',
        align: 'center',
        fixed: 'right',
        width: 60
      }
    ];
  }

  public searchText: string = '';
  public authUser: IUser;
  public pagination: IPaginationParams;
  public sortSuccessType: number = 0;
  public sortFailType: number = 0;
  public filterParams = {
    [FilterKey.Branch]: []
  };
  public branch: any[];
  public displayAddress(model: IAddress) {
    return model && model.province ? this.$store.getters['getAddressString'](model) : '--';
  }

  public getAction() {

    return 'view';
  }
  public handleActions(account) {
    this.$router.push({
      name: ProductManageRouterName.DealerProductDetail,
      params: {
        id : account.id
      }
    });
  }
  public changeTab(tab: TabPane) {
    switch (tab.name) {
    case ProductRegistationTab.ProductList:
      this.$router.push({
        name: ProductManageRouterName.ProductRegistation
      });
      break;
    case ProductRegistationTab.SubDealerProduct:
      this.$router.push({
        name: ProductManageRouterName.SubDealerProduct
      });
      break;
    default:
      break;
    }
  }

  public handleSearch() {
    this.fetchData();
  }

  public handlePaginationChange(value) {
    if (typeof value === 'number') {
      this.$store.commit(MutationTypeProduct.PaginationChange, {
        ...this.pagination,
        size: value
      });
    } else {
      this.$store.commit(MutationTypeProduct.PaginationChange, {
        ...this.pagination,
        ...value
      });
    }
    this.fetchData();
  }
  public handleSort(sort: any) {
    if (sort === 'totalSuccess') {
      const _clone = this.sortSuccessType + 1;
      this.sortFailType = 0;
      this.sortSuccessType = _clone % 3;
    }
    if (sort === 'joinedFail') {
      const _clone = this.sortFailType + 1;
      this.sortSuccessType = 0;
      this.sortFailType = _clone % 3;
    }

    this.fetchData();
  }
  public editSuccess() {
    this.fetchData();
  }
  public changeFilter(filter) {
    const nextFilter = {
      [FilterKey.Branch]: filter ? filter : [],
    };
    this.filterParams = {
      ...cloneDeep(this.filterParams),
      ...nextFilter
    };
    this.fetchData();
  }

  protected mounted() {
    this.$nextTick(() => {
      const breadcrumb: IBreadcrumb[] = [
        {
          label: 'title.productRegistation',
          path: null,
          current: false
        },
      ];
      this.$store.commit(MutationType.SetBreadcrumb, breadcrumb);
      this.$store.dispatch(ActionTypeBranchManage.GetListBranch, {
        form: {
          filter: {},
          pagination: {}
        }
      });
      this.fetchData();
    });
  }
  protected beforeDestroy() {
    this.$store.commit(MutationType.ClearBreadcrumb);
  }

  private fetchData() {
    const text = cloneDeep(this.searchText);
    const _filterParams = <any> {};
    let _cloneSort = {};
    if (this.sortFailType === 1) {
      _cloneSort = {countWarrantyFails: 'desc'};
    }
    if (this.sortFailType === 2) {
      _cloneSort = {countWarrantyFails: 'asc'};
    }
    if (this.sortSuccessType === 1) {
      _cloneSort = {countWarrantySuccesses: 'desc'};
    }
    if (this.sortSuccessType === 2) {
      _cloneSort = {countWarrantySuccesses: 'asc'};
    }
    if (this.filterParams[FilterKey.Branch].length) {
      _filterParams.branch = this.filterParams[FilterKey.Branch];
    }

    this.$store.dispatch(ActionTypeProduct.GetListProductRegistation, {
      search: `%${text}%`,
      branch: _filterParams.branch,
      order: _cloneSort,
      pagination: this.pagination
    });
  }
}
