import { DKGqlClient } from 'root/api/graphql/Client';
import { ActionType, IState, MutationType } from 'root/store';
import { crudActions } from 'root/store/helpers';
import { ActionContext } from 'vuex';
import { IGroupState } from './mutation';
import { ActionTypeGroup, ActionTypeGroupSystem } from './types';

const { group, systemGroup } = DKGqlClient();
export const actions = {
  ...crudActions('group', {
    filter: group.filter
  }),
  ...crudActions('systemGroup', {
    filter: systemGroup.filter
  }),
  async [ActionTypeGroup.GET_LIST_GROUP]({
    commit,
    dispatch
  }: ActionContext<IGroupState, IState>) {
    try {
      const groupList = await group.getListGroup();
      commit(MutationType.GetGroup, groupList);
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    }
  },
  async [ActionTypeGroupSystem.GET_LIST_GROUP_SYSTEM]({
    commit,
    dispatch
  }: ActionContext<IGroupState, IState>) {
    try {
      const groupSystemList = await systemGroup.getListGroupSystem();
      commit(MutationType.GetGroup, groupSystemList);
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    }
  }
};
