import { IPaginationParams } from 'root/api/graphql/Core';
import { Avatar } from 'root/components';
import { parseUTCToTime } from 'root/helpers';
import { IAddress } from 'root/models';
import Vue from 'vue';
import Component from 'vue-class-component';
import { ActionTypeDealerShop } from '../Store/types';
import './styles.scss';

@Component({
  template: require('./view.html'),
  components: {
    avatar: Avatar,
  },
  props: {
    visible: Boolean,
    dealerId: String
  },
})

export class DealerShopDetail extends Vue {
  public get columns() {
    return [
      {
        prop: 'name',
        label: 'Name',
        width: 132,
        formatter: (model: any) => {
          return model.dealer && model.dealer.name ? model.dealer.name : '--';
        },
      },
      {
        prop: 'phone',
        label: 'Phone',
        width: 112,
        formatter: (model: any) => {
          return model.dealer && model.dealer.phoneNumber ? model.dealer.phoneNumber : '--';
        },
      },
      {
        prop: 'email',
        label: 'Email',
        width: 198,
        formatter: (model: any) => {
          return model.dealer && model.dealer.email ? model.dealer.email : '--';
        },
      },
      {
        prop: 'address',
        label: 'Address',
        width: 264,
        formatter: (model: any) => {
          return model.dealer && model.dealer.address ? this.displayAddress(model.dealer.address) : '--';
        },
      },
    ];
  }
  public dealerId;
  public dealer: any = {};
  public pagination: IPaginationParams = { limit: 1, page: 1, total: 0 };
  public formatDate = parseUTCToTime;
  public displayAddress(model: IAddress) {
    return model && model.province ? this.$store.getters['getAddressString'](model) : '--';
  }
  public openDialog() {
    // change after
    this.$store.dispatch(ActionTypeDealerShop.GET_SHOP_DETAIL, {
      id: this.dealerId,
      onSuccess: (detail) => {
        this.dealer = detail;
      },
      onFailure: () => {
          //
      }
    });
  }
  public closeDialog() {
    this.$emit('update:visible', false);
  }
}
