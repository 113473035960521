import { userRegisterFormDefault } from '.';

export interface IRedemptionForm {
  description?: string;
  title: string;
  point?: string;
  startDate?: Date;
  endDate?: Date;
  target?: string;
  image?: string;
  content?: string;
  rewardType?: string;
  redeemType?: string;
  quantity?: string;
  voucherCodes?: any;
  maxQuantity?: string;
}
export const redemptionFormDefault = (): IRedemptionForm => {
  return {
    description: '',
    title: '',
    point: '',
    startDate: null,
    endDate: null,
    target: '',
    image: '',
    content: '',
    rewardType: '',
    quantity: '',
    voucherCodes: '',
    redeemType: '',
  };
};

export const redemptionDefault = (): IRedemption => {
  return {
    ...userRegisterFormDefault(),
    title: '',
  };
};

export interface IRedemption extends IRedemptionForm {
  description?: string;
  title: string;
  point?: string;
  startDate?: Date;
  endDate?: Date;
  target?: string;
  image?: string;
  content?: string;
  pointType?: string;
}
export interface ICampaignForm {
  description?: string;
  title: string;
  point?: string;
  startDate?: Date;
  endDate?: Date;
  image?: string;
  content?: string;
  pointType?: string;
  branchId?: any[];
  dealerId?: any[];
  action?: string;
  conditionId?: string;
  titleEng: string;
  descriptionEng?: string;
  icon?: string;
  dealerRoles?: any[];
}
export const campaignFormDefault = (): ICampaignForm => {
  return {
    description: '',
    descriptionEng: '',
    title: '',
    titleEng: '',
    point: '',
    startDate: null,
    endDate: null,
    image: '',
    content: '',
    pointType: 'point',
    branchId: [],
    dealerId: [],
    action: '',
    conditionId: '',
    icon: '',
    dealerRoles: []
  };
};

export const campaignDefault = (): ICampaign => {
  return {
    ...userRegisterFormDefault(),
    title: '',
    titleEng: '',
    pointType: 'point',
    branchId: [],
    dealerId: [],
    action: ''
  };
};

export interface ICampaign extends ICampaignForm {
  description?: string;
  title: string;
  titleEng: string;
  point?: string;
  startDate?: Date;
  endDate?: Date;
  image?: string;
  content?: string;
  pointType?: string;
  branchId?: any[];
  dealerId?: any[];
  action?: string;
  conditionId?: string;
  icon?: string;
  dealerRoles?: any [];
}
