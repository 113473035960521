import { ElUpload } from 'element-ui/types/upload';
import { cloneDeep } from 'lodash';
import { DKGqlClient } from 'root/api/graphql/Client';
import { Avatar } from 'root/components/Avatar';
import { SearchByFilter } from 'root/components/SearchByFilter';
import { IFilterInput, IUser } from 'root/models';
import { pathUpload, uploadToFirebase } from 'root/services';
import { FirebaseAuthService } from 'root/services/auth/firebase';
import { IState, MutationType, TypeAlert } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import { SaleInHistoryLogs } from '../SaleInHistoryLogs';
import { ActionTypeSaleInCampaign, ActionTypeSaleInProduct, MutationTypeSaleInProduct } from '../Store/types';
import { ApplicationProductDetail } from './Components/ApplicationProductionDetail';
import { ApplicationProductForm } from './Components/ApplicationProductionForm';

const enum FilterKey {
  Type = 'type',
}
enum SearchByKey {
  DeviceCode = 'deviceCode',
  ModelNumber = 'modelNumber',
  DisplayModel = 'displayModel',
  DeviceName = 'deviceName',
  DeviceType = 'deviceType'
}

@Component({
  template: require('./view.html'),
  components: {
    avatar: Avatar,
    'search-by-filter': SearchByFilter,
    'application-form': ApplicationProductForm,
    'application-detail': ApplicationProductDetail,
    'application-history-logs': SaleInHistoryLogs
  },
  computed: {
    ...mapState({
      authUser: (state: IState) => state.global.authUser,
      data: (state: IState) => state.saleInCampaign.saleInProduct.data,
      pagination: (state: IState) => state.saleInCampaign.saleInProduct.pagination,
      loading: (state: IState) => state.saleInCampaign.saleInProduct.loading,
    }),
    searchBy() {
      const result: IFilterInput[] = [];
      for (const key in SearchByKey) {
        // tslint:disable-next-line:early-exit
        if (SearchByKey.hasOwnProperty(key)) {
          const element = SearchByKey[key];
          result.push({
            key,
            value: element,
            name: this.$t(`application-product.${element}`)
          });
        }
      }

      return [{
        key: 'select',
        name: this.$t('select'),
        value: result
      }];
    },
    selectedFieldSearchBy() {
      const filterParams = cloneDeep(this.filterParams);

      return [{
        key: 'select',
        value: filterParams.searchBy ? filterParams.searchBy : []
      }];
    },
  }
})

export class ApplicationProduct extends Vue {
  public get dataFilter() {
    const type = this.DeviceTypeOptions.map((it) => (
    {
      key: it.value,
      value: it.value,
      name: it.label
    }
    ));

    return [
      {
        key: FilterKey.Type,
        name: 'Type',
        value: type
      },
    ];
  }
  public get selectedFilter() {
    const _filterParams = cloneDeep(this.filterParams);

    return [
      {
        key: FilterKey.Type,
        value: _filterParams[FilterKey.Type]
      }
    ];
  }
  public get columns() {
    return [
      {
        label: 'Code',
        prop: 'code',
        fixed: true,
        width: 120
      },
      {
        label: 'Model Number',
        prop: 'modelNumber',
        fixed: true,
        width: 250
      },
      {
        label: 'Display model',
        prop: 'displayModel',
        width: 240
      },
      {
        label: 'Device Type',
        formatter: (model: any) => {
          return model && model.deviceType && model.deviceType.value ? model.deviceType.value : '--';
        },
        width: 120
      },
      {
        label: 'Device Name',
        prop: 'deviceName',
        width: 200
      },
    ];
  }
  public $refs: {
    upload: ElUpload;
  };
  public visibleForm: boolean = false;
  public authUser: IUser;
  public visibleDetail: boolean = false;
  public searchText: string = '';
  public visibleHistory: boolean = false;
  public loadingUpload: boolean = false;
  public visibleImport: boolean = false;
  public pathUploadCampaign: pathUpload = pathUpload.POSCODE;
  public fileList = [];
  public fileUploaded= [];
  // tslint:disable-next-line:max-line-length
  public urlSample: string = 'https://firebasestorage.googleapis.com/v0/b/daikin-sale-staging.appspot.com/o/imports%2Fsample%2Ftemplate_import_model_sic.xlsx?alt=media&token=35fefbf4-f13e-4ed5-8199-193bb2fa1d53';
  public DeviceTypeOptions: any[] = [];
  public filterParams = {
    searchBy: ['deviceCode', 'modelNumber', 'displayModel', 'deviceName', 'deviceType'],
    [FilterKey.Type]: [],
  };
  public detailId: string = '';
  public onClickCreate() {
    this.detailId = '';
    this.visibleForm = true;
  }
  public onClickUpdate(id: string) {
    this.detailId = id;
    this.visibleForm = true;
  }
  public onClickHistory() {
    this.visibleHistory = true;
  }
  public clickDetail(id: string) {
    this.detailId = id;
    this.visibleDetail = true;
  }
  public closeDialog() {
    this.visibleForm = false;
  }
  public createSuccess() {
    this.visibleForm = false;
    this.fetchData();
  }
  public changeSearchBy(model: IFilterInput[]) {
    this.filterParams = {
      ...this.filterParams,
      searchBy: model[0].value
    };

    this.fetchData();
  }
  public downloadSample() {
    window.open(this.urlSample, '_blank');
  }
  public onClickImport() {
    this.visibleImport = true;
  }
  public closeModalImport() {
    this.visibleImport = false;
    this.$refs.upload.clearFiles();
    this.fileList = [];
  }
  public pushFileUpload(file: any) {
    const f = this.fileList;
    if (this.fileList.length > 0) {
      const _file = this.fileList.filter((it) => it.name === file.name);
      if (_file && _file.length > 0) {
        this.$refs.upload.clearFiles();
        const message = 'File has been exits';
        this.$store.commit(MutationType.OpenTopAlert, {
          message,
          type: TypeAlert.Warning
        });

        return this.$refs.upload.$data.uploadFiles = f;
      }

      return this.fileList = [file];
    }

    f.push(file);

    return this.fileList = [file];
  }
  public async submitFile() {
    // tslint:disable-next-line: whitespace
    try {
      this.loadingUpload = true;
      // const uploadedFile = <any> [];
      const token = await DKGqlClient().user.getFirebaseAuthenticateToken();
      await FirebaseAuthService().signInWithCustomToken(token.authenticate);
      const uploadedFile = await Promise.all(this.fileList.map(async (it) => {
        const _file = await uploadToFirebase(it.raw, this.pathUploadCampaign);

        // return _file ? uploadedFile.push(_file) : uploadedFile;
        // tslint:disable-next-line:no-var-before-return
        return _file;
      }));

      if (uploadedFile.length > 0) {
        const form = {
          createdBy: this.authUser.id,
          url: uploadedFile[0],
          type: 'si_model',
          isReIndex: true
        };
        this.$store.dispatch(ActionTypeSaleInCampaign.ImportSaleInCampaign, {
          form,
          onSuccess: () => {
            this.loadingUpload = false;
            const message = 'Import successfully';
            this.$store.commit(MutationType.OpenTopAlert, {
              message,
              type: TypeAlert.Success
            });
            this.visibleImport = false;
            this.fileList = [];
            this.fileUploaded = [];
            this.$refs.upload.clearFiles();
            this.fetchData();
          },
          onFailure: () => {
            this.loadingUpload = false;

          }});
      }

    } catch (error) {
      console.log(error);
    }

  }
  public onRemove(file: any, fileList: any) {
    console.log(file);
    this.fileList = fileList;
  }
  public changeFilter(model) {
    const type = model.find((e) => e.key === FilterKey.Type);

    const nextFilter = {
      [FilterKey.Type]: type ? type.value : [],
    };
    this.filterParams = {
      ...cloneDeep(this.filterParams),
      ...nextFilter
    };
    this.fetchData();
  }
  public onEnterFilter(value) {
    this.searchText = value;
    this.$store.commit(MutationTypeSaleInProduct.PaginationChange, {
      page: 1,
      limit: 20
    });

    this.fetchData();
  }
  public fetchData() {
    const text = cloneDeep(this.searchText);
    const {type, searchBy} = this.filterParams;
    this.$store.commit(MutationTypeSaleInProduct.FilterChange, {
      _and: {
        mode: 'special',
        data: {
          ...type && type.length ? {
            deviceType: {
              id: {
                _in: type
              }
            }
          } : {},
          ...text && text.trim().length > 0 && searchBy && searchBy.length ? {
            _or: [
              {
                ...searchBy.includes('deviceCode') ? {
                  code: {
                    _ilike: `%${text}%`
                  }
                } : {},
              },
              {
                ...searchBy.includes('modelNumber') ? {
                  modelNumber: {
                    _ilike: `%${text}%`
                  }
                } : {},
              },
              {
                ...searchBy.includes('displayModel') ? {
                  displayModel: {
                    _ilike: `%${text}%`
                  }
                } : {},
              },
              {
                ...searchBy.includes('deviceName') ? {
                  deviceName: {
                    _ilike: `%${text}%`
                  }
                } : {},
              },
              {
                ...searchBy.includes('deviceType') ? {
                  deviceType: {
                    value: {
                      _ilike: `%${text}%`
                    }
                  }
                } : {},
              },
            ].filter((e) => !!e)
          } : {}
        }
      }
    });
    this.$store.dispatch(ActionTypeSaleInProduct.FilterNoCache);
  }
  protected mounted() {
    this.$store.dispatch(ActionTypeSaleInCampaign.GetConditionMetadata, {
      onSuccess: (data) => {
        this.DeviceTypeOptions = data.si_campaign_data.map((it) => ({
          label: it.value,
          value: it.id
        }));
      }
    });
    this.$store.commit(MutationType.SetBreadcrumb, 'Application Product');
    this.fetchData();
  }
}
