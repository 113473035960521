import { CRUDGqlService, ICRUDGqlService } from '../Core/crud';
import { deviceTypes, deviceTypeListQuery } from '../Query/deviceType';

export interface IDeviceTypesGqlService extends ICRUDGqlService<any> {
  //
}
export function DeviceTypesGqlService(): IDeviceTypesGqlService {

  return {
    ...CRUDGqlService<any>(deviceTypes, deviceTypeListQuery),
  };
}
