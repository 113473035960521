import { DKGqlClient } from 'root/api/graphql/Client';
import { ActionType, IState } from 'root/store';
import { crudActions } from 'root/store/helpers';
import { ActionContext } from 'vuex';
import { ICampaignState } from './mutation';
import { ActionTypeCampaign, MutationTypeCampaign } from './types';

const { campaign } = DKGqlClient();
export const actions = {
  ...crudActions('campaign', {
    filter:  campaign.filter
  }),
  async [ActionTypeCampaign.CreateCampaign]({
    dispatch
  }: ActionContext<ICampaignState, IState>,
                                            {form, onSuccess, onFailure}) {
    try {
      await campaign.createCampaign(form);
      onSuccess();
      // if (createUserResult) {
      //   dispatch(ActionTypeCampaign.GetList, {form: {
      //     filter: {},
      //     pagination: {}
      //   }});
      // }
    } catch (error) {
      onFailure();
      dispatch(ActionType.CatchException, error);
    }
  },
  async [ActionTypeCampaign.UpdateCampaign]({
     dispatch
  }: ActionContext<ICampaignState, IState>,
                                            {id, form, onSuccess, onFailure}) {
    try {
      await campaign.updateCampaign(id, form);
      onSuccess();
    } catch (error) {
      onFailure(error);
      dispatch(ActionType.CatchException, error);
    }
  },
  async [ActionTypeCampaign.UpdateStatusCampaign]({
    dispatch
 }: ActionContext<ICampaignState, IState>,
                                                  {id, form, onSuccess, onFailure}) {
    try {
      await campaign.updateStatusCampaign(id, form);
      onSuccess();
      // if (updateResult) {
      //   dispatch(ActionTypeCampaign.GetList, {form: {
      //     filter: {},
      //     pagination: {}
      //   }});
      // }
    } catch (error) {
      onFailure(error);
      dispatch(ActionType.CatchException, error);
    }
  },
  async [ActionTypeCampaign.GetList]({
    dispatch, commit
 }: ActionContext<ICampaignState, IState>,
                                     { form }) {
    try {
      commit(MutationTypeCampaign.CampaignLoading);
      const updateResult = await campaign.getList(form);
      if (updateResult) {
        commit(MutationTypeCampaign.GetList, updateResult);
      }
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    } finally {
      commit(MutationTypeCampaign.CampaignLoaded);
    }
  },
  async [ActionTypeCampaign.GetCampaignDetail]({
    dispatch, commit
 }: ActionContext<ICampaignState, IState>,
                                               { form }) {
    try {
      const updateResult = await campaign.getCampaignDetail(form);
      if (updateResult) {
        commit(MutationTypeCampaign.GetCampaignDetail, updateResult);
      }
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    }
  },
  async [ActionTypeCampaign.GetListCampaign]({
    dispatch, commit
 }: ActionContext<ICampaignState, IState>,
                                             {pagination, filter}) {
    try {
      const { page, limit } = pagination;
      const offset = (page - 1) * limit;
      const listCampaign = await campaign.getCampaignList(limit, offset, filter);
      if (listCampaign) {
        commit(MutationTypeCampaign.SetCampaignList, listCampaign);
      }
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    }
  },
  async [ActionTypeCampaign.UpdateCampaignBranch]({
    }: ActionContext<ICampaignState, IState>,
                                                  {campaignId, add, remove, createdBy}) {
    try {
      await campaign.updateCampaignBranch(campaignId, add, remove, createdBy);
    } catch (error) {
      // dispatch(ActionType.CatchException, error);
    }
  },
};
