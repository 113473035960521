export interface ISaleInCampaign {
  campaignCode: string;
  startDate: Date;
  endDate: Date;
  title: string;
  isSpecial: boolean;
  icon: string;
  image: string;
  description: string;
  targetBranch: string;
  dealerRoles: string[];
  dealerTypes: string[];
}
export interface ISaleInCampaignGeneralInfo {
  rewardType: string;
  rewardStartTime: Date;
  rewardEndTime: Date;
  countdownDays: number;
  descriptionType: string;
}
export interface ISaleInCampaignForm extends ISaleInCampaign {
  sicInformations: {
    data: ISaleInCampaignGeneralInfo[]
  };
  sicParticipants: any;
  sicConditionGroups: any;
}
export const defaultSaleInCampaign = (): ISaleInCampaign => {
  return {
    campaignCode : '',
    startDate: null,
    endDate: null,
    title: '',
    isSpecial: false,
    icon: '',
    image: '',
    description: '',
    targetBranch: '',
    dealerRoles: [],
    dealerTypes: []
  };
};
export const defaultSaleInCampaignForm = (): ISaleInCampaignForm => {
  return {
    ...defaultSaleInCampaign(),
    sicInformations: {
      data: [
        {
          rewardType: '',
          rewardStartTime: null,
          rewardEndTime: null,
          countdownDays: null,
          descriptionType: '',
        }
      ]
    },
    sicParticipants: {},
    sicConditionGroups: {}
  };
};
