import './styles.scss';

import { Subscription } from 'apollo-client/util/Observable';
import { ElUpload } from 'element-ui/types/upload';
import { cloneDeep } from 'lodash';
import { gqlClient, IPaginationParams } from 'root/api/graphql/Core';
import { dealerListQuery } from 'root/api/graphql/Query/dealer';
import { Avatar } from 'root/components/Avatar';
import { ChangePassword } from 'root/components/ChangePassword';
import { fullNameUser } from 'root/helpers';
import { IAddress, StatusCode } from 'root/models';
import { IUser } from 'root/models/User';
import { IState, MutationType } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import { DealerRouterName } from '..';
import { DealerEditor } from '../Components/DealerEditor';
import { UpgradeRequestModal } from '../Components/UpgradeRequest';
import { IDealerState } from '../Store';
import {
  ActionTypeDealer,
  MutationTypeDealer,
} from '../Store/types';

const enum FilterKey {
  Group = 'groupdId',
  Role = 'roleId',
  Status = 'status'
}

@Component({
  template: require('./view.html'),
  components: {
    editor: DealerEditor,
    avatar: Avatar,
    'upragde-request-modal': UpgradeRequestModal,
    changPassword: ChangePassword
  },
  computed: {
    ...mapState({
      // authUser: (state: IState) => state.global.authUser,
      data: (state: IState) => state.dealer.data,
      pagination: (state: IState) => state.dealer.pagination,
      loading: (state: IState) => state.dealer.loading,
    }),
  },
})
export class SalemanList extends Vue {
  public get columns() {
    return [
      {
        prop: 'name',
        label: 'Saleman Name',
        labelItem: 'Code',
        formatter: (model: any) => {
          return fullNameUser(model) || '--';
        },
        avatar: {
          formatter: (model: any) => {
            return model ? model : null;
          }
        },
        width: 250,
        fixed: true
      },
      // {
      //   prop: 'companyName',
      //   label: 'Company Name',
      //   width: 200,
      //   formatter: (model: any) => {
      //     return model.companyName || '--';
      //   }
      // },
      // {
      //   prop: 'code',
      //   label: 'Public Code',
      //   width: 180,
      //   formatter: (model: any) => {
      //     return model.publicCode || '--';
      //   }
      // },
      // {
      //   prop: 'shortName',
      //   label: 'Short Name',
      //   width: 180,
      //   formatter: (model: any) => {
      //     return model.shortName || '--';
      //   }
      // },
      // {
      //   prop: 'proshopType',
      //   label: 'Type',
      //   width: 180,
      //   formatter: (model: any) => {
      //     return model.proshopType ? model.proshopType.name : '--';
      //   }
      // },
      {
        prop: 'address',
        label: 'Address',
        width: 220,
        formatter: (model: any) => {
          if (model.address) {
            return this.displayAddress(model.address) || '--';
          }

          return '--';
        }
      },
      // {
      //   prop: 'branch',
      //   label: 'Branch',
      //   width: 180,
      //   formatter: (model: any) => {
      //     if (model.branch && model.branch.name) {
      //       return model.branch.name;
      //     }

      //     return '--';
      //   }
      // },
      // {
      //   prop: 'group',
      //   label: 'Department',
      //   width: 180,
      //   formatter: (model: any) => {
      //     if (model.group && model.group.name) {
      //       return model.group.name;
      //     }

      //     return '--';
      //   }
      // },
      {
        prop: 'email',
        label: 'Owner Email',
        width: 180,
        formatter: (model: any) => {
          return model.email || '--';
        }
      },
      // {
      //   prop: 'shopCount',
      //   label: 'Dealer Shop',
      //   width: 150,
      //   formatter: (model: any) => {
      //     return model.shopCount || '--';
      //   }
      // },
      {
        prop: 'status',
        label: this.$t('status'),
        formatter: (model: any) => (model.status ? this.$t(model.status) : ''),
        labelStyle: (model: any) => {
          return model.status;
        },
        width: 150
      },
      // {
      //   prop: 'action',
      //   label: '',
      //   align: 'center',
      //   fixed: 'right',
      //   width: 60
      // }
    ];
  }
  public get actions() {
    return [
      'view'
    ];
  }

  public get inputSearchPlaceholder(): string {
    return `Search by Name, Email`;
  }

  public authUser: IUser;
  public pagination: IPaginationParams;
  public data: IDealerState[];
  public group: any[];
  public typefilter: any[];
  public status: any[];
  public visible: boolean = false;
  public isSample: boolean = false;
  public userId: string = '';
  public visibleDetail: boolean = false;
  public popupVisible: boolean = false;
  public filterParams = {
    [FilterKey.Group]: [],
    [FilterKey.Role]: [],
    [FilterKey.Status]: []
  };
  public visibleUpgrade: boolean = false;
  public searchText: string = '';
  public filterTypeDealer: any[];
  // public showUploadDialog() {
  //   this.isSample = true;
  // }
  public isRefesh: boolean;
  public visibleChangePass: boolean = false;
  // public loading: boolean = false;
  public file: any;
  public fileUpload: any;
  public fileUploaded= [];
  public fileList = [];
  public loadingUpload: boolean = false;
  public loadingExport: boolean = false;
  public DaikinDealerId: string;
  public $refs: {
    upload: ElUpload;
  };
  public visibleImport: boolean = false;

  public UserType = {
    Customer: 'customer',
    System: 'system'
  };
  public type: any;
  private subscribeURL: Subscription = null;

  public closeDialogDetail() {
    this.userId = '';
    this.visibleDetail = false;
  }
  public upgradeRequest(id: string) {
    this.visibleUpgrade = true;
    this.userId = id;
  }
  public importDealer() {
    this.visibleImport = true;
  }

  public createSuccess() {
    this.visible = false;
    this.fetchData();
  }

  public applyFilter() {
    this.fetchData();
  }
  public goAddNewRouter() {
    this.visible = true;
    this.userId = '';
  }
  public closeDialog() {
    this.isSample = false;
    this.fetchData();
  }
  public displayAddress(model: IAddress) {
    return model && model.province
      ? this.$store.getters['getAddressString'](model)
      : '';
  }
  public handleSearch() {
    this.fetchData();
  }
  public closeModalImport() {
    this.visibleImport = false;
    this.$refs.upload.clearFiles();
    this.fileList = [];
  }

  public handleCommand(command) {
    if (command === 'addnew') {
      this.visible = true;
    } else {
      this.visibleImport = true;
    }
  }

  public clickDetail(id: string) {
    this.$router.push({
      name: DealerRouterName.DetailDealer,
      params: {
        id
      }
    });
  }
  public exportDealer(role: string) {
    this.loadingExport = true;
    this.$store.dispatch(ActionTypeDealer.GetRoleId, {
      role,
      onSuccess: (roleId) => {
        // tslint:disable-next-line: early-exit
        if (roleId && roleId.length) {
          this.$store.dispatch(ActionTypeDealer.ExportDealer, {
            object: {filter:
            {
              type: roleId[0].id,
            }
            },
            onSuccess: (url) => {
              // tslint:disable-next-line: early-exit
              if (url.returning && url.returning.length) {
                this.subscribeURL = <any> gqlClient.subscribe({
                  query: dealerListQuery.DOWNLOAD_EXPORT,
                  variables : {id: url.returning[0].id},
                  fetchPolicy: 'cache-first'
                }).subscribe((results) => {
                  // tslint:disable-next-line: early-exit
                  if (results.data && results.data.export_dealers && results.data.export_dealers.length
                    && results.data.export_dealers[0].exportStatus === 'done') {
                    window.open(results.data.export_dealers[0].url, '_blank');
                    this.subscribeURL.unsubscribe();
                    this.loadingExport = false;
                  }
                });
              }
            }
          });
        }
      }
    });
  }
  public handleUpdate(id: string) {
    this.visible = true;
    this.userId = id;
  }

  // public changeFilter(model) {
  //   const group = model.find((e) => e.key === FilterKey.Group);
  //   const role = model.find((e) => e.key === FilterKey.Role);
  //   const status = model.find((e) => e.key === FilterKey.Status);
  //   const nextFilter = {
  //     [FilterKey.Group]: group ? group.value : [],
  //     [FilterKey.Role]: role ? role.value : [],
  //     [FilterKey.Status]: status ? status.value : []
  //   };
  //   this.filterParams = {
  //     ...cloneDeep(this.filterParams),
  //     ...nextFilter
  //   };
  //   this.fetchData();
  // }

  public formatFullNameUser(model) {
    return fullNameUser(model);
  }

  public handlePaginationChange(value) {
    if (typeof value === 'number') {
      this.$store.commit(MutationTypeDealer.DealerPaginationChange, {
        ...this.pagination,
        size: value
      });
    } else {
      this.$store.commit(MutationTypeDealer.DealerPaginationChange, {
        ...this.pagination,
        ...value
      });
    }
    this.fetchData();
  }

  protected mounted() {
    this.$nextTick(() => {
      this.$store.commit(MutationType.ClearBack);
      this.$store.commit(MutationType.SetBreadcrumb, this.$t('title.saleman'));
      this.$store.commit(MutationTypeDealer.PaginationReset);
      this.$store.commit(MutationTypeDealer.Loading);
      this.fetchData();

    });
  }
  protected beforeDestroy() {
    this.$store.commit(MutationType.ClearBreadcrumb);
  }

  private async fetchData() {
    const text = cloneDeep(this.searchText);
    const filterDealer = <any> {
      status: [
        StatusCode.Active,
        StatusCode.Blocked,
        StatusCode.Suspended,
      ]
    };

    // if (this.filterParams[FilterKey.Group].length) {
    //   filterDealer.group = this.filterParams[FilterKey.Group];
    // }
    // if (this.filterParams[FilterKey.Role].length) {
    //   filterDealer.type = this.filterParams[FilterKey.Role];
    // }
    // if (this.filterParams[FilterKey.Status].length) {
    //   filterDealer.status = this.filterParams[FilterKey.Status];
    // }
    if (text) {
      filterDealer.search = text;
    }
    this.$store.dispatch(ActionTypeDealer.GetListSaleman, {
      form: {
        filter: filterDealer,
        pagination: {
          size: this.pagination.limit,
          page: text ? 1 : this.pagination.page
        }
      }
    });
  }
}
