
import { Subscription } from 'apollo-client/util/Observable';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import { ActionTypeSaleEduCampaign } from 'root/admin/CustomCampaign/Store/types';
import { gqlClient, IPaginationParams } from 'root/api/graphql/Core';
import { zeroCampaignQuery } from 'root/api/graphql/Query/zeroCampaign';
import { formatCurrency, FormatDateFullTime } from 'root/helpers';
import { IFilterInput, IUser } from 'root/models';
import { IState, MutationType, TypeAlert } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import './styles.scss';

const enum FilterKey {
  Status = 'status'
}
@Component({
  template: require('./view.html'),
  props: {
    visible: Boolean,
    detail: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  computed: {
    ...mapState({
      authUser: (state: IState) => state.global.authUser,
    })
  },
})
export class ImportHistoryDetail extends Vue {
  public get getPermissionCheckAsPaid() {
    if (this.detail && this.detail.confirmedAt && !this.detail.paidAt
      && this.detail.importStatus === 'successful') {
      return true;
    }

    return false;
  }
  public get columns() {
    return [
      ...this.activeTab === 'dealer' ?
        [
          {
            label: 'Dealer Name',
            prop: 'dealer',
            labelItem: 'Dealer Code',
            width: 200
          }
        ] : [
          {
            label: 'Dealer Code',
            prop: 'dealerCode',
            formatter: (model: any) => {
              return model && model.metadata && model.metadata.companyCode ? model.metadata.companyCode : '--';
            },
            width: 200
          },
          {
            label: 'Trainee Name',
            prop: 'trainee',
            labelItem: 'Code',
            width: 200
          }
        ],
      ...this.activeTab === 'dealer' ?
        [
          {
            label: 'Amount',
            prop: 'amountReceived',
            formatter: (model: any) => {
              return model && model.metadata && model.metadata.totalAmount ?
             `${formatCurrency(model.metadata.totalAmount, false, 0, '.')} VND` : '0 VND';
            },
            width: 120
          },
        ] : [
          {
            label: 'Blocked Amount',
            prop: 'blockedAmount',
            formatter: (model: any) => {
              return model && model.metadata && model.metadata.blockedAmount ?
             `${formatCurrency(model.metadata.blockedAmount, false, 0, '.')} VND` : '0 VND';
            },
            width: 120
          },
        ],
      {
        label: 'Status',
        prop: 'status',
        formatter: (model: any) => {
          return model && model.status ? this.formatterStatus(model.status) : '--';
        },
        labelStyle: (model: any) => {
          return `text--capitalize import-status__${model.status}`;
        },
        width: 120
      },
      {
        label: 'Reason',
        prop: 'reason',
        width: 150
      },
    ];
  }
  public get selectedFilter() {
    const _filterParams = cloneDeep(this.filterParams);

    return [
      {
        key: FilterKey.Status,
        value: _filterParams[FilterKey.Status]
      },
    ];
  }
  public get dataFilter() {
    const _statuses: IFilterInput[] = [
      {
        key: 'success',
        value: 'success',
        name: 'Successful',
        style: 'filter__success'
      },
      {
        key: 'fail',
        value: 'failed',
        name: 'Failed',
        style: 'filter__failed'
      }
    ];

    return [
      {
        key: FilterKey.Status,
        name: this.$t('field.status'),
        value: _statuses
      }
    ];
  }
  public FormatDateFullTime = FormatDateFullTime;
  public detail;
  public data: any = [];
  public loading: boolean = false;
  public authUser: IUser;
  public subscribeURL: Subscription = null;
  public activeTab: string = 'dealer';
  public formatCurrency = formatCurrency;
  public dataLength: {
    dealer: number,
    trainee: number
  } = {
    dealer: 0,
    trainee: 0
  };
  public pagination: IPaginationParams = {
    limit: 10,
    page: 1,
    total: 10
  };
  public filterParams = {
    [FilterKey.Status]: []
  };

  public onClickHyperLink(url) {
    window.open(url, '_blank');
  }
  public formatterStatus(status) {
    const map = {
      success: 'Successful',
      failed: 'Failed'
    };

    return map[status];
  }
  public downloadImportLog() {
    this.$msgbox({
      title: 'Download Import Logs',
      message: `Are you sure want to download import logs?`,
      showCancelButton: true,
      confirmButtonText: this.$t('confirm').toString(),
      cancelButtonText: this.$t('cancel').toString(),
      beforeClose: (action, instance, done) => {
        if (action === 'confirm') {
          instance.confirmButtonLoading = true;
          instance.confirmButtonText = 'Loading...';
          instance.confirmButtonLoading = false;
          this.$store.dispatch(ActionTypeSaleEduCampaign.DownloadImportLogs, {
            form: {
              isReIndex: true,
              filter: {
                importCode: this.$props.detail.importCode
              },
              type: 'banking_payment_log_detail',
              createdBy: this.authUser.id
            },
            onSuccess: (data) => {
              // tslint:disable-next-line:early-exit
              if (data && data.insert_exports_one && data.insert_exports_one.id) {
                this.subscribeURL = <any> gqlClient.subscribe({
                query: zeroCampaignQuery.DOWNLOAD_EXPORT,
                variables : {id: data.insert_exports_one.id},
                fetchPolicy: 'cache-first'
              }).subscribe((results) => {
                // tslint:disable-next-line: early-exit
                if (results.data && results.data.exports && results.data.exports.length
                  && results.data.exports[0].exportStatus === 'done') {
                  window.open(results.data.exports[0].url, '_blank');
                  this.subscribeURL.unsubscribe();
                  instance.confirmButtonLoading = false;
                  const message = 'Download successfully';
                  this.$store.commit(MutationType.OpenTopAlert, {
                    message,
                    type: TypeAlert.Success
                  });
                  done();
                }
                // tslint:disable-next-line:early-exit
                if (results.data && results.data.exports && results.data.exports.length
                  && results.data.exports[0].exportStatus === 'failed') {
                  this.subscribeURL.unsubscribe();
                  instance.confirmButtonLoading = false;
                  const message = 'Download failure!';
                  this.$store.commit(MutationType.OpenTopAlert, {
                    message,
                    type: TypeAlert.Error
                  });
                  done();
                }
              });
              }
            }
          });
        } else {
          done();
        }

        return;
      }
    }).catch(() => {
      // no handle
    });
  }
  public onClickCheckAsPaid() {
    this.$msgbox({
      title: 'Check as Paid',
      message: `Are you sure want to check as paid for this dealer?`,
      showCancelButton: true,
      confirmButtonText: this.$t('confirm').toString(),
      cancelButtonText: this.$t('cancel').toString(),
      beforeClose: (action, instance, done) => {
        if (action === 'confirm') {
          instance.confirmButtonLoading = true;
          instance.confirmButtonText = 'Loading...';
          instance.confirmButtonLoading = false;
          this.$store.dispatch(ActionTypeSaleEduCampaign.CheckAsPaid, {
            ids: this.detail.id,
            updatedBy: this.authUser.id,
            paidAt: moment(),
            onSuccess: () => {
              instance.confirmButtonLoading = false;
              const message = 'Update successfully';
              this.$store.commit(MutationType.OpenTopAlert, {
                message,
                type: TypeAlert.Success
              });
              this.fetchData();
            }
          });
          done();
        } else {
          done();
        }

        return;
      }
    }).catch(() => {
      // no handle
    });
  }
  public handlePaginationChange(value) {
    if (typeof value === 'number') {
      this.pagination = {
        ...this.pagination,
        limit: value
      };
    } else {
      this.pagination = {
        ...value
      };
    }
    this.fetchData();
  }
  public handleChangeFilter(filter) {
    const status = filter.find((e) => e.key === FilterKey.Status);
    const nextFilter = {
      [FilterKey.Status]: status ? status.value : [],
    };
    this.filterParams = {
      ...cloneDeep(this.filterParams),
      ...nextFilter
    };
    this.pagination = {
      limit: 10,
      page: 1
    };
    this.fetchData();
  }
  public fetchData() {
    const {status} = this.filterParams;
    this.$store.dispatch(ActionTypeSaleEduCampaign.GetListDealerDetail, {
      status: status && status.length ? status : null,
      sheet: this.activeTab === 'dealer' ? 'Import dealer' : 'Import trainee',
      pagination: this.pagination,
      importCode: this.$props.detail.importCode,
      onSuccess: (data) => {
        this.dataLength = {
          dealer: data.dealer.pagination.total,
          trainee: data.trainee.pagination.total
        };
        this.data = data.getImportLogDetail.data;
        this.pagination = {
          ...this.pagination,
          total: data.getImportLogDetail.pagination.total
        };
        this.loading = false;
      }
    });
  }
  public openDialog() {
    this.fetchData();
  }
  public closeDialog() {
    this.$emit('closeDialog');
  }
}
