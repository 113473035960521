import { gqlClient } from '../Core';
import { CRUDGqlService, ICRUDGqlService } from '../Core/crud';
import { aclCampaign, aclCampaignQuery } from '../Query/aclCampaign';

export interface IAclCampaignGqlService extends ICRUDGqlService<any> {
  getList(search: string, branch: any, limit: number, offset: number): Promise<any>;
  getListManageExport(limit: number, offset: number): Promise<any>;
  getListShop(id: string, status: any, notJoin: boolean, search: string, limit: number, offset: number): Promise<any>;
  approveShop(ids: string[], action: string, reason: string, updatedBy: string): Promise<any>;
  requestReup(ids: string[], action: string, reason: string, updatedBy: string): Promise<any>;
  getShop(id: string): Promise<any>;
  getPdfExportData(id: string): Promise<any>;
  submitReview(id: string, review: string, qualify: string, createdBy: string): Promise<any>;
  getPackage(): Promise<any>;
  updatePackage(id: string, models: number[]): Promise<any>;
  getConfig(): Promise<any>;
  getMaskConfig(id: string): Promise<any>;
  endCampaign(id: string): Promise<any>;
  maskChange(id: string, mask: boolean): Promise<any>;
  getStatistic(): Promise<any>;
  exportStatistic(id: string): Promise<any>;
  getQualifiedShopsList(limit: number, offset: number): Promise<any>;
  approveAll(id: string): Promise<any>;
  getDealerByBranch(name: string): Promise<any>;
  getJoinedShopList(search: string, notJoin: boolean, status: any, branch: any, saleFilter: any,
                    notReview: boolean, branchFilter: any, limit: number, offset: number): Promise<any>;
  getConfigCropPicture(): Promise<any>;
}
export function aclCampaignGqlService(): IAclCampaignGqlService {
  async function getList(search: string, branch: any, limit: number, offset: number): Promise<any> {
    const results = await gqlClient.query({
      query: aclCampaignQuery.GET_LIST_ACL_CAMPAGIN,
      variables: {
        search, branch, limit, offset
      },
      fetchPolicy: 'network-only'
    });
    if (results.errors) {
      throw results.errors;
    }

    return results.data;
  }
  async function getListManageExport(limit: number, offset: number): Promise<any> {
    const results = await gqlClient.query({
      query: aclCampaignQuery.GET_LIST_MANAGE_EXPORT,
      variables: {
        limit, offset,
        // tslint:disable-next-line: object-literal-key-quotes
        type: {'type' : 'ACL_CAMPAIGN'}
      },
      fetchPolicy: 'network-only'
    });
    if (results.errors) {
      throw results.errors;
    }

    return results.data;
  }
  async function getPdfExportData(id: string): Promise<any> {
    const results = await gqlClient.query({
      query: aclCampaignQuery.GET_PDF_EXPORT_DATA,
      variables: {
        id
      },
      fetchPolicy: 'network-only'
    });
    if (results.errors) {
      throw results.errors;
    }

    return results.data;
  }
  async function getListShop(id: string, status: any, notJoin: boolean,
                             search: string, limit: number, offset: number): Promise<any> {
    if (notJoin) {
      const result = await gqlClient.query({
        query: aclCampaignQuery.GET_LIST_SHOP_CAMPAGIN,
        variables: {
          id, status, search, limit, offset
        },
        fetchPolicy: 'network-only'
      });
      if (result.errors) {
        throw result.errors;
      }

      return result.data;
    }
    const results = await gqlClient.query({
      query: aclCampaignQuery.GET_LIST_SHOP_CAMPAGIN_NOTJOIN,
      variables: {
        id, status, search, limit, offset
      },
      fetchPolicy: 'network-only'
    });
    if (results.errors) {
      throw results.errors;
    }

    return results.data;
  }
  async function approveShop(ids: string[], action: string, reason: string, updatedBy: string): Promise<any> {
    const results = await gqlClient.mutate({
      mutation: aclCampaignQuery.APRROVE_SHOP,
      variables: {
        ids,
        action,
        reason,
        updatedBy
      }
    });
    if (results.errors) {
      throw results.errors;
    }

    return results.data;
  }
  async function requestReup(ids: string[], action: string, reason: string, updatedBy: string): Promise<any> {
    const results = await gqlClient.mutate({
      mutation: aclCampaignQuery.REQUEST_REUP,
      variables: {
        ids,
        action,
        reason,
        updatedBy
      }
    });
    if (results.errors) {
      throw results.errors;
    }

    return results.data;
  }
  async function getShop(id: string): Promise<any> {
    const results = await gqlClient.query({
      query: aclCampaignQuery.GET_SHOP,
      variables: {
        id
      },
      fetchPolicy: 'network-only'
    });
    if (results.errors) {
      throw results.errors;
    }

    return results.data;
  }
  async function submitReview(id: string, review: string, qualify: string, createdBy: string): Promise<any> {
    const results = await gqlClient.mutate({
      mutation: aclCampaignQuery.SUBMIT_REVIEW,
      variables: {
        id,
        review,
        qualify,
        createdBy
      }
    });
    if (results.errors) {
      throw results.errors;
    }

    return results.data;
  }
  async function getPackage(): Promise<any> {
    const results = await gqlClient.query({
      query: aclCampaignQuery.GET_PACKAGE,
      fetchPolicy: 'network-only'
    });
    if (results.errors) {
      throw results.errors;
    }

    return results.data.campaigns;
  }
  async function updatePackage(id: string, models: number[]): Promise<any> {
    const results = await gqlClient.mutate({
      mutation: aclCampaignQuery.UPDATE_PACKAGE,
      variables: {
        id,
        models,
      }
    });
    if (results.errors) {
      throw results.errors;
    }

    return results.data;
  }
  async function getConfig(): Promise<any> {
    const results = await gqlClient.query({
      query: aclCampaignQuery.GET_CONFIG,
      fetchPolicy: 'network-only'
    });
    if (results.errors) {
      throw results.errors;
    }

    return results.data.campaigns;
  }
  async function getMaskConfig(id: string): Promise<any> {
    const results = await gqlClient.query({
      query: aclCampaignQuery.GET_MASK_CONFIG,
      variables: {
        id
      },
      fetchPolicy: 'network-only'
    });
    if (results.errors) {
      throw results.errors;
    }

    return results.data.campaigns;
  }
  async function endCampaign(id: string): Promise<any> {
    const results = await gqlClient.mutate({
      mutation: aclCampaignQuery.END_CAMPAIGN,
      variables: {
        id
      }
    });
    if (results.errors) {
      throw results.errors;
    }

    return results.data;
  }
  async function maskChange(id: string, mask: boolean): Promise<any> {
    const results = await gqlClient.mutate({
      mutation: aclCampaignQuery.MASK_CHANGE,
      variables: {
        id,
        mask
      }
    });
    if (results.errors) {
      throw results.errors;
    }

    return results.data;
  }
  async function getStatistic(): Promise<any> {
    const results = await gqlClient.query({
      query: aclCampaignQuery.GET_STATISTIC,
      fetchPolicy: 'network-only'
    });
    if (results.errors) {
      throw results.errors;
    }

    return results.data;
  }
  async function exportStatistic(id: string): Promise<any> {
    const results = await gqlClient.mutate({
      mutation: aclCampaignQuery.EXPORT_STATISTIC,
      variables: {
        id,
        // tslint:disable-next-line: object-literal-key-quotes
        filter: {'type' : 'ACL_CAMPAIGN'}
      }
    });
    if (results.errors) {
      throw results.errors;
    }

    return results.data;
  }
  async function getQualifiedShopsList(limit: number, offset: number): Promise<any> {
    const results = await gqlClient.query({
      query: aclCampaignQuery.GET_QUALIFIED_SHOP,
      variables: {
        limit, offset
      },
      fetchPolicy: 'network-only'
    });
    if (results.errors) {
      throw results.errors;
    }

    return results.data;
  }
  async function approveAll(id: string): Promise<any> {
    const results = await gqlClient.mutate({
      mutation: aclCampaignQuery.APPROVE_ALL,
      variables: {
        id
      }
    });
    if (results.errors) {
      throw results.errors;
    }

    return results.data;
  }
  async function getDealerByBranch(name: string): Promise<any> {
    const results = await gqlClient.query({
      query: aclCampaignQuery.GET_DEALER_BY_BRANCH,
      variables: {
        name
      }
    });
    if (results.errors) {
      throw results.errors;
    }

    return results.data;
  }
  async function getJoinedShopList(search: string, notJoin: boolean, status: any, branch: any, saleFilter: any,
                                   notReview: boolean, branchFilter: any, limit: number, offset: number): Promise<any> {
    let _status = [];
    if (status && status.length) {
      _status.push(...status);
    }
    if (saleFilter && saleFilter.length) {
      _status.push(...saleFilter);
    }
    if (_status.length <= 0) {
      _status = null;
    }
    if (notJoin && status && status.length === 0 && !saleFilter) {
      _status = [];
    }
    if (notReview && notJoin) {
      const result = await gqlClient.query({
        query: aclCampaignQuery.GET_LIST_SHOPS_NOT_JOIN_NOT_REIVEW,
        variables: {
          // tslint:disable-next-line: object-shorthand-properties-first
          search, branch, saleFilter: _status, branchFilter , limit, offset
        },
        fetchPolicy: 'network-only'
      });
      if (result.errors) {
        throw result.errors;
      }

      return result.data;
    }
    if (!notReview && notJoin) {
      const result = await gqlClient.query({
        query: aclCampaignQuery.GET_LIST_SHOPS_NOT_JOIN,
        variables: {
          // tslint:disable-next-line: object-shorthand-properties-first
          search, branch, saleFilter: _status, branchFilter , limit, offset
        },
        fetchPolicy: 'network-only'
      });
      if (result.errors) {
        throw result.errors;
      }

      return result.data;
    }
    if (notReview && !notJoin) {
      const result = await gqlClient.query({
        query: aclCampaignQuery.GET_LIST_SHOPS_NOT_REVIEW,
        variables: {
          // tslint:disable-next-line: object-shorthand-properties-first
          search, branch, saleFilter: _status, branchFilter , limit, offset
        },
        fetchPolicy: 'network-only'
      });
      if (result.errors) {
        throw result.errors;
      }

      return result.data;
    }
    const results = await gqlClient.query({
      query: aclCampaignQuery.GET_LIST_SHOPS,
      variables: {
        // tslint:disable-next-line: object-shorthand-properties-first
        search, branch, saleFilter: _status, branchFilter, limit, offset
      },
      fetchPolicy: 'network-only'
    });
    if (results.errors) {
      throw results.errors;
    }

    return results.data;
  }
  async function getConfigCropPicture(): Promise<any> {
    const results = await gqlClient.query({
      query: aclCampaignQuery.GET_CONFIG_CROP_PICTURE
    });
    if (results.errors) {
      throw results.errors;
    }

    return results.data;
  }

  return {
    ...CRUDGqlService<any>(aclCampaign, aclCampaignQuery),
    getList,
    getListShop,
    approveShop,
    requestReup,
    getShop,
    getPdfExportData,
    submitReview,
    getPackage,
    updatePackage,
    getConfig,
    getMaskConfig,
    endCampaign,
    maskChange,
    getListManageExport,
    getStatistic,
    exportStatistic,
    getQualifiedShopsList,
    approveAll,
    getDealerByBranch,
    getJoinedShopList,
    getConfigCropPicture
  };
}
