import { gqlClient } from '../Core';
import { CRUDGqlService, ICRUDGqlService } from '../Core/crud';
import { ConfigurationQuery } from '../Query';

export interface IConfigurationService extends ICRUDGqlService<any> {
  getConfigNavigator(): Promise<any>;
}

export function configurationGqlService(): IConfigurationService {
  async function getConfigNavigator(): Promise<any> {
    const results = await gqlClient.query({
      query: ConfigurationQuery.GET_CONFIG_NAVIGATOR,
      fetchPolicy: 'network-only'
    });
    if (results.errors) {
      throw results.errors;
    }

    return results.data.configuration_navigators;
  }

  return {
    getConfigNavigator,
    ...CRUDGqlService<any>('system_configurations', ConfigurationQuery)
  };
}
