import { TabPane } from 'element-ui';
import { CustomCampaignRouterName, CustomCampaignTab } from 'root/admin/CustomCampaign';
import { SystemRoleAlias } from 'root/admin/User/Store/types';
import { IPaginationParams } from 'root/api/graphql/Core';
import { FormatDateFullTime } from 'root/helpers';
import { IUser } from 'root/models';
import { IBreadcrumb, IState, MutationType } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import { ActionTypeRaCampaign, MutationTypeRaCampaign } from '../../Store/types';

@Component({
  template: require('./view.html'),
  computed: {
    ...mapState({
      data: (state: IState) => state.raCampaign.manageExport,
      pagination: (state: IState) => state.raCampaign.pagination,
      loading: (state: IState) => state.raCampaign.loading,
      authUser: (state: IState) => state.global.authUser,
    })
  },
})

export class RaManageExport extends Vue {
  public get activeTab() {
    return CustomCampaignTab.RAManageExport;
  }

  public set activeTab(_value: string) {
    //
  }
  public get isSalePlanning() {
    if (this.authUser.superAdmin || this.authUser.role.alias === SystemRoleAlias.Admin
      || this.authUser.role.alias === SystemRoleAlias.SaleAdmin) {
      return true;
    }

    return false;
  }

  public get columns() {
    return [
      {
        prop: 'createdUser',
        label: 'REQUESTED BY',
        width: 300,
        formatter: (model: any) => {
          return model.createdUser ? model.createdUser.name : '--';
        },
      },
      {
        prop: 'createdAt',
        label: 'REQUESTED AT',
        width: 300,
        formatter: (model: any) => {
          return FormatDateFullTime(model.createdAt) || '--';
        },
      },
      {
        prop: 'status',
        label: 'STATUS',
        width: 300,
        formatter: (model: any) => {
          return model.status ? model.status : '--';
        },
        labelStyle: (model: any) => {
          return model.status;
        },
      },
      {
        prop: 'exportStatus',
        label: '',
        width: 300,
        formatter: (model: any) => {
          return model.exportStatus === 'done' ? 'Ready to download' : 'Not ready';
        },
      }
    ];

  }
  public authUser: IUser;
  public pagination: IPaginationParams;
  public changeTab(tab: TabPane) {
    switch (tab.name) {
    case CustomCampaignTab.RAParticipant:
      this.$router.push({
        name: CustomCampaignRouterName.RACampaign
      });
      break;
    case CustomCampaignTab.RAConfiguration:
      this.$router.push({
        name: CustomCampaignRouterName.RAConfiguration
      });
      break;
    case CustomCampaignTab.RAStatistic:
      this.$router.push({
        name: CustomCampaignRouterName.RAStatistic
      });
      break;
    case CustomCampaignTab.RAQualifiedShops:
      this.$router.push({
        name: CustomCampaignRouterName.RAQualifiedShops
      });
      break;
    case CustomCampaignTab.RAShops:
      this.$router.push({
        name: CustomCampaignRouterName.RAShops
      });
      break;
    case CustomCampaignTab.SubDealerRequest:
      this.$router.push({
        name: CustomCampaignRouterName.SubDealerRequest
      });
      break;
    case CustomCampaignTab.ConfirmationCode:
      this.$router.push({
        name: CustomCampaignRouterName.ConfirmationCode
      });
      break;
    case CustomCampaignTab.SubDealerCampaign:
      this.$router.push({
        name: CustomCampaignRouterName.SubDealerCampaign
      });
      break;
    default:
      break;
    }
  }
  public reloadListImport() {
    this.fetchData();
  }
  public handlePaginationChange(value) {
    if (typeof value === 'number') {
      this.$store.commit(MutationTypeRaCampaign.PaginationChange, {
        ...this.pagination,
        size: value
      });
    } else {
      this.$store.commit(MutationTypeRaCampaign.PaginationChange, {
        ...this.pagination,
        ...value
      });
    }
    this.fetchData();
  }
  protected mounted() {
    this.$nextTick(() => {
      const breadcrumb: IBreadcrumb[] = [
        {
          label: 'title.campaign',
          path: null,
          current: false
        },
        {
          label: 'title.ra_campaign',
          path: null,
          current: true
        }
      ];
      this.$store.commit(MutationType.SetBreadcrumb, breadcrumb);

      this.fetchData();
    });
  }
  private fetchData() {

    this.$store.dispatch(ActionTypeRaCampaign.GetManageExport, {
      pagination: this.pagination
    });
  }
}
