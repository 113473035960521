import { DKGqlClient } from 'root/api/graphql/Client';
import { ActionType, IState, MutationType } from 'root/store';
import { crudActions } from 'root/store/helpers';
import { ActionContext } from 'vuex';
import { IRoleState } from './mutation';
import { ActionTypeRole } from './types';

const { role } = DKGqlClient();
export const actions = {
  ...crudActions('role', {
    filter: role.filter
  }),
  async [ActionTypeRole.GET_LIST_ROLE]({
    commit, dispatch
  }: ActionContext<IRoleState, IState>) {
    try {
      const roleList = await role.getListRole();
      commit(MutationType.GetRole, roleList);
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    }
  },
  async [ActionTypeRole.UpdateSystemRoleType]({
    commit, dispatch
  }: ActionContext<IRoleState, IState>,
                                              {id, type, onSuccess, onFailure}) {
    try {
      const updateResult = await role.updateSystemRoleType(id, type);
      onSuccess();
      if (updateResult) {
        commit(ActionTypeRole.FilterNoCache);
      }
    } catch (error) {
      onFailure(error);
      dispatch(ActionType.CatchException, error);
    }
  },
};
