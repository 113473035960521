import { DKGqlClient } from 'root/api/graphql/Client';
import { ActionType, IState } from 'root/store';
import { crudActions } from 'root/store/helpers';
import { ActionContext } from 'vuex';
import { IDealerShopState } from './mutation';
import { ActionTypeDealerShop, MutationTypeDealerShop } from './types';

const { dealerShop } = DKGqlClient();
export const actions = {
  ...crudActions('dealerShop', {
    filter: dealerShop.filter
  }),
  async [ActionTypeDealerShop.GET_LIST_DEALERSHOP](
    { commit, dispatch }: ActionContext<IDealerShopState, IState>,
    { id, search , pagination, onSuccess }
  ) {
    try {
      commit(MutationTypeDealerShop.DealerShopLoading);
      const { page, limit } = pagination;
      const offset = (page - 1) * limit;
      const DealerShopList = await dealerShop.getListDealerShop(id, search, limit, offset);
      if (onSuccess) {
        onSuccess(DealerShopList);
      }
      commit(MutationTypeDealerShop.GetListDealerShop, DealerShopList);
      // commit(MutationTypeDealerShop.SetPagination, pagination);
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    } finally {
      commit(MutationTypeDealerShop.DealerShopLoaded);
    }
  },
  async [ActionTypeDealerShop.CREATE_SHOP](
    { commit, dispatch }: ActionContext<IDealerShopState, IState>,
    { form, id, createdBy, onSuccess, onFailure }
  ) {
    try {
      commit(MutationTypeDealerShop.DealerShopLoading);
      await dealerShop.createShop(form, id, createdBy);
      // if (DealerShop) {
      //   dispatch(ActionTypeDealerShop.GET_LIST_DEALERSHOP, {
      //     id,
      //   });
      // }
      if (onSuccess) {
        onSuccess();
      }
    } catch (error) {
      if (onFailure) {
        onFailure();
      }
      dispatch(ActionType.CatchException, error);
    } finally {
      commit(MutationTypeDealerShop.DealerShopLoaded);
    }
  },
  async [ActionTypeDealerShop.GET_SHOP_DETAIL]({
    dispatch
  }: ActionContext<IDealerShopState, IState>,
                                               {id, onSuccess, onFailure}) {
    try {
      const detail = await dealerShop.getShopDetail(id);
      if (onSuccess) {
        onSuccess(detail);
      }
    } catch (error) {
      if (onFailure) {
        onFailure();
      }
      dispatch(ActionType.CatchException, error);
    }
  },
  async [ActionTypeDealerShop.UPDATE_SHOP](
    { commit, dispatch }: ActionContext<IDealerShopState, IState>,
    { form, id, updatedBy, onSuccess, onFailure }
  ) {
    try {
      commit(MutationTypeDealerShop.DealerShopLoading);
      await dealerShop.updateShop(form, id, updatedBy);
      if (onSuccess) {
        onSuccess();
      }
    } catch (error) {
      if (onFailure) {
        onFailure();
      }
      dispatch(ActionType.CatchException, error);
    } finally {
      commit(MutationTypeDealerShop.DealerShopLoaded);
    }
  },
};
