import { stripObject } from '@hgiasac/helper';
import { cloneDeep, find } from 'lodash';
import { ActionTypeDeviceType } from 'root/admin/DeviceType/Store/types';
import { IPaginationParams } from 'root/api/graphql/Core';
import { Avatar } from 'root/components/Avatar';
import { FormatDateFullTime } from 'root/helpers';
import { IFilterInput, StatusCode } from 'root/models';
import { IState, MutationType, TypeAlert } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import { ConditionDetailModal } from '../Components/ConditionDetail';
import { ConditionEditor } from '../Components/ConditionEditor';
import { ConditionActionType, ConditionMutationType } from './Store/types';
import './styles.scss';

const enum FilterKey {
  DeviceType = 'deviceType'
}
@Component({
  template: require('./view.html'),
  components: {
    // editor: ProductEditor,
    avatar: Avatar,
    'condition-detail-modal': ConditionDetailModal,
    'condition-editor': ConditionEditor
  },
  computed: {
    ...mapState({
      data: (state: IState) => state.condition.data,
      pagination: (state: IState) => state.condition.pagination,
      loading: (state: IState) => state.condition.loading,
      isRefesh: (state: IState) => state.global.isRefesh,
    }),
  }
})
export class Condition extends Vue {
  public get columns() {

    return [
      {
        label: 'Condition Name',
        formatter: (model: any) => {
          return model.nameEng;
        },
        width: 450
      },
      {
        prop: 'descriptionEng',
        label: 'Description',
        width: 550,
        formatter: (model: any) => {
          return model.descriptionEng || '--';
        }
      },
      {
        prop: 'createdAt',
        label: this.$t('created.at'),
        formatter: (model: any) => {
          if (model.createdAt) {
            return FormatDateFullTime(model.createdAt);
          }

          return '--';
        },
        width: 180
      },
      {
        prop: 'status',
        label: this.$t('status'),
        formatter: (model: any) => (model.status ? this.$t(model.status) : ''),
        labelStyle: (model: any) => {
          return model.status;
        },
        align: 'center',
        width: 180
      },
      {
        prop: 'action',
        label: '',
        align: 'center',
        fixed: 'right',
        width: 60
      }
    ];
  }

  public get actions() {
    return ['view', 'edit', 'block'];
  }

  public get inputSearchPlaceholder(): string {
    return `Search by Name, Email`;
  }
  public get dataFilter() {
    const _deviceType: IFilterInput[] = this.deviceType.reduce((res, item) => {
      res.push({
        key: item.id,
        value: item.id,
        name: item.name
      });

      return res;
    }, []);

    return [
      {
        key: FilterKey.DeviceType,
        name: this.$t('field.deviceType'),
        value: _deviceType
      }
    ];
  }
  public get selectedFilter() {
    const _filterParams = cloneDeep(this.filterParams);

    return [
      {
        key: FilterKey.DeviceType,
        value: _filterParams[FilterKey.DeviceType]
      }
    ];
  }

  public groups: any[];
  public roles: any[];
  public pagination: IPaginationParams;
  public data: any[];
  public visible: boolean = false;
  public popupVisible: boolean = false;
  public isSample: boolean = false;
  public productId: string = '';
  public visibleDetail: boolean = false;
  public searchText: string = '';
  public filterParams = {
    [FilterKey.DeviceType]: []
  };
  public deviceType: any[];
  public isRefesh: boolean;
  // public roleSystem: any[];

  public ProductType = {
    Customer: 'customer',
    System: 'system'
  };
  public type: any;
  public getRoleSystem() {
    let roleSystem = [];
    if (this.roles && this.roles.length && this.groups && this.groups.length) {
      const _groupId = this.groups.map((it) => it.id);
      roleSystem = this.roles.filter((item) =>
        _groupId.includes(item.systemGroupId)
      );
    }

    return roleSystem;
  }
  public renderDes(value: any) {
    const des = value.slice(0, 250);
    if (des && des.length === value.length) {
      return des;
    }

    return des.concat('...');
  }
  public getActions(status: string) {

    if (status === StatusCode.Active) {

      return ['view', 'edit', 'block'];
    }
    if (status === StatusCode.Blocked) {

      return ['unblock'];
    }

    return ['view'];
  }
  public handleActions({ id, action }) {
    if (action === 'edit') {
      this.clickUpdate(id);

      return;
    }

    if (action === 'view') {
      this.clickDetail(id);

      return;
    }

    if (action === 'block' || action === 'unblock') {
      this.disabledConfirm(id);
    }

    return;
  }
  public disabledConfirm(id: string) {
    this.$msgbox({
      title: this.$t('confirm').toString(),
      message: this.$t('action_confirm_message').toString(),
      showCancelButton: true,
      confirmButtonText: this.$t('confirm').toString(),
      cancelButtonText: this.$t('cancel').toString(),
      beforeClose: (action, instance, done) => {
        if (action === 'confirm') {
          instance.confirmButtonLoading = true;
          instance.confirmButtonText = 'Loading...';
          this.handleDisabled({ id, instance, done });
        } else {
          done();
        }

        return;
      }
    }).catch(() => {
      // no handle
    });
  }
  public closeDialogDetail() {
    this.productId = '';
    this.visibleDetail = false;
  }

  public createSuccess() {
    this.visible = false;
    this.fetchData();
  }

  public applyFilter() {
    this.fetchData();
  }
  public goAddNewRouter() {
    this.visible = true;
    this.productId = '';
  }
  public closeDialog() {
    this.isSample = false;
    this.fetchData();
  }
  public handleSearch() {
    this.fetchData();
  }

  public clickDetail(id: string) {
    this.productId = id;
    this.visibleDetail = true;
  }
  public clickUpdate(id: string) {
    this.visible = true;
    this.productId = id;
  }
  public handleDisabled(model: any) {
    const user = find(
        cloneDeep(this.data),
        (e) => e.id.toString() === model.id.toString()
      ),
      isBlocked = user.status === StatusCode.Blocked;
    this.$store.dispatch(ConditionActionType.ConditionUpdate, {
      id: model.id,
      form: {
        status: isBlocked ? StatusCode.Active : StatusCode.Blocked,
      },
      onSuccess: () => {
        this.fetchData();
        model.instance.confirmButtonLoading = false;
        model.instance.confirmButtonText = 'Confirm';
        model.done();
        const message = isBlocked
          ? 'Unlock Campaign Condition success'
          : 'Block Campaign Condition success';
        this.$store.commit(MutationType.OpenTopAlert, {
          message,
          type: TypeAlert.Success
        });
      },
      onFailure: () => {
        model.instance.confirmButtonLoading = false;
        model.instance.confirmButtonText = 'Confirm';
      }
    });
  }
  public changeFilter(model) {
    const deviceType = model.find((e) => e.key === FilterKey.DeviceType);
    const nextFilter = {
      [FilterKey.DeviceType]: deviceType ? deviceType.value : []
    };
    this.filterParams = {
      ...cloneDeep(this.filterParams),
      ...nextFilter
    };
    this.fetchData();
  }

  public handlePaginationChange(value) {
    if (typeof value === 'number') {
      this.$store.commit(ConditionMutationType.PaginationChange, {
        ...this.pagination,
        size: value
      });
    } else {
      this.$store.commit(ConditionMutationType.PaginationChange, {
        ...this.pagination,
        ...value
      });
    }
    this.fetchData();
  }

  protected mounted() {
    this.$nextTick(() => {
      // tslint:disable-next-line:early-exit
      if (!this.isRefesh) {
        this.$store.commit(MutationType.SetBreadcrumb, this.$t('title.condition'));
        this.$store.dispatch(ActionTypeDeviceType.FilterNoCache);

        this.fetchData();
      }
    });
  }
  protected beforeDestroy() {
    this.$store.commit(MutationType.ClearBreadcrumb);
  }

  private fetchData() {
    const text = cloneDeep(this.searchText);

    this.$store.commit(
      ConditionMutationType.FilterChange,
      stripObject({
        _and:
          text.trim() !== '' ||
          this.filterParams[FilterKey.DeviceType].length > 0
            ? {
              mode: 'special',
              data: {
                _and: {
                  _or: [
                    {
                      nameEng: {
                        _like: `%${text}%`
                      }
                    },
                  ]
                }
              }
            }
            : null
      })
    );
    this.$store.dispatch(ConditionActionType.ConditionFilterNoCache, {
      optionsHTTP: {
        orderBy: {
          createdAt: 'desc'
        }
      },
    });
  }
}
