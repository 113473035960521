import { DocumentNode } from 'graphql';
import gql from 'graphql-tag';
import { crudQuery, ICRUDQuery } from '../Core/query';

const feedbackModel = `
  id
  type
  status
  data
  createdAt
  dealer {
    shops(where:{contractStatus:{_eq:"accepted"}}) {
      shopId
      dealer {
        name
      }
    }
    name
    role {
      id
      alias
    }
    dealerCode
    branch {
      id
      name
      branchCode
    }
    group {
      id
      name
      groupCode
    }
    ownerName
    phoneNumber
    assignedSubDealers {
      dealer {
        name
        dealerCode
        phoneNumber
      }
    }
  }
`;

export const feedback = 'dealer_feedbacks';
// tslint:disable-next-line:no-empty-interface
interface IFeedbackQuery extends ICRUDQuery {
  GET_FEEDBACK_LIST: DocumentNode;
  UPDATE_STATUS: DocumentNode;
  GET_OWNER_DEALER: DocumentNode;
}
export const feedbackListQuery: IFeedbackQuery = {
  ...crudQuery(feedback, feedbackModel),
  GET_FEEDBACK_LIST: gql`
    query GetFeedback {
      dealer_feedbacks {
        ${feedbackModel}
      }
    }
  `,
  UPDATE_STATUS: gql`
    mutation UPDATE_STATUS($id: uuid!, $status: String!) {
      update_dealer_feedbacks(where: {id: {_eq: $id}}, _set: {status: $status}) {
        affected_rows
      }
    }
  `,
  GET_OWNER_DEALER: gql`
  query GET_OWNER_DEALER($shopId: uuid!) {
    dealer_shops(where: {shopId: {_eq: $shopId}, isOwner: {_eq: true}}) {
      dealer {
        name
        dealerCode
        ownerName
        phoneNumber
        branch {
          id
          branchCode
          name
        }
      }
    }
  }`
};
