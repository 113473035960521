import { TabPane } from 'element-ui';
import moment from 'moment';
import { CustomCampaignRouterName, CustomCampaignTab } from 'root/admin/CustomCampaign';
import { IPaginationParams } from 'root/api/graphql/Core';
// import { FormatDateFullTime } from 'root/helpers';
import { IAddress, IUser } from 'root/models';
import { IBreadcrumb, IState, MutationType } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import { ActionTypeRaCampaign, MutationTypeRaCampaign } from '../../Store/types';
import { ContractStatus, RequestStatus, RequestStatusCode } from '../CampaignDetail';
import { CampaignDetailModel } from '../CampaignDetailModel';
import { SubDealerCampaignDetail } from '../SubDealerCampaignDetail';

export enum Role {
  DaikinDealer = 'daikin_dealer',
  SubDealer = 'sub_dealer',
}
@Component({
  template: require('./view.html'),
  computed: {
    ...mapState({
      data: (state: IState) => state.raCampaign.listQualifiedShop,
      pagination: (state: IState) => state.raCampaign.pagination,
      loading: (state: IState) => state.raCampaign.loading,
      authUser: (state: IState) => state.global.authUser,
    }),
    checkDisable() {
      if (this.data.length) {
        return false;
      }

      return true;
    }
  },
  components: {
    // editor: DealerEditor
    'shops-detail-modal': CampaignDetailModel,
    'sub-detail-modal': SubDealerCampaignDetail,
  },
})

export class RaQualifiedShops extends Vue {
  public get activeTab() {
    return CustomCampaignTab.RAQualifiedShops;
  }

  public set activeTab(_value: string) {
    //
  }

  public get columns() {
    return [
      {
        prop: 'review at',
        label: 'Last review at',
        formatter: this.formatterTime,
        width: 150,
      },
      {
        prop: 'review content',
        label: 'last review content',
        formatter: (model: any) => {
          return model.shop ? model.shop.name : '--';
        },
        width: 300,
      },
      {
        prop: 'role',
        label: 'role',
        width: 180,
        formatter: this.formatterRole
      },
      {
        prop: 'name',
        label: 'Name',
        formatter: this.formatterSubDealer,
        width: 250,
      },
      {
        prop: 'dealer',
        label: 'Direct Dealer',
        formatter: this.formatterDirectDealer,
        width: 150,
      },
      {
        prop: 'staff',
        label: 'Staff',
        width: 120,
        formatter: (model: any) => {
          return model.staff || '--';
        }
      },
      {
        prop: 'hasConfirmationCode',
        label: 'Confirmation Code',
        formatter: (model: any) => {
          return model.confirmationCode
          || model.forceConfirmationCode ? 'YES' : 'No';
        },
        labelStyle: (model: any) => {
          return model.confirmationCode
          || model.forceConfirmationCode ?
          'ra_has_confirmCode' : 'ra_no_has_confirmCode';
        },
        width: 120,
      },
      {
        prop: 'address',
        label: 'Address',
        width: 220,
        formatter: (model: any) => {
          if (model.shop && model.shop.address) {
            return this.displayAddress(model.shop.address) || '--';
          }

          return '--';
        }
      },
      {
        prop: 'status',
        label: 'Request Status',
        width: 150,
        formatter: this.formaterStatus,
        labelStyle: (model: any) => {
          return `ra_${model.requestStatus}`;
        },
      },
      {
        prop: 'action',
        label: '',
        align: 'center',
        fixed: 'right',
        width: 60
      }
    ];
  }
  public pagination: IPaginationParams;
  public authUser: IUser;
  public shopId: string = '';
  public subId: string = '';
  public visibleDetail: boolean = false;
  public visibleDetailSub: boolean = false;
  public formaterStatus(model: any) {
    switch (model.requestStatus) {
    case RequestStatusCode.WaitingForReup:
      return RequestStatus.WaitingForReup;
    case RequestStatusCode.WaitingForReview:
      return RequestStatus.WaitingForReview;
    case RequestStatusCode.Approved:
      return RequestStatus.Approved;
    case RequestStatusCode.Rejected:
      return RequestStatus.Rejected;
    }

  }
  public getAction() {

    return 'view';
  }
  public formatterTime(model: any) {
    if (model && model.reviews.length) {
      const last = model.reviews.length - 1;

      return moment(model.reviews[last].updatedAt).format('HH:mm - DD/MM/YYYY');
    }

    return '--';
  }
  public formatterRole(model) {
    if (model && model.dealer && model.dealer.role) {
      switch (model.dealer.role.alias) {
      case Role.DaikinDealer:
        return 'Direct Dealer';
      case Role.SubDealer:
        return 'Sub Dealer';
      }
    }

    return '--';
  }
  public formatterSubDealer(model: any) {
    if (model && model.dealer && model.dealer.role && model.dealer.role.alias === 'daikin_dealer') {
      return model.shop && model.shop.name ? model.shop.name : '--';
    }
    if (model && model.dealer && model.dealer.role && model.dealer.role.alias === 'sub_dealer') {
      return model.dealer.name;
    }

    return '--';
  }
  public formatterDirectDealer(model: any) {
    if (model && model.dealer && model.dealer.role && model.dealer.role.alias === 'daikin_dealer') {
      return model.dealer.name;
    }
    if (model && model.dealer && model.dealer.role && model.dealer.role.alias === 'sub_dealer'
    && model.dealer.assignedSubDealers.length && model.dealer.assignedSubDealers[0].dealer
    && model.dealer.assignedSubDealers[0].dealer.name) {
      return model.dealer.assignedSubDealers[0].dealer.name;
    }

    return '--';
  }
  public handleActions(record) {
    if (record.dealer && record.dealer.role && record.dealer.role.alias === 'daikin_dealer') {
      this.clickDetailShop(record.shop.id);
    }
    if (record.dealer && record.dealer.role && record.dealer.role.alias === 'sub_dealer') {
      this.clickDetailSub(record.dealer.id);
    }

    return;
  }
  public clickDetailShop(id: string) {
    this.shopId = id;
    this.visibleDetail = true;
  }
  public clickDetailSub(id: string) {
    this.subId = id;
    this.visibleDetailSub = true;
  }
  public closeDialogDetail() {
    // change after
    this.shopId = '';
    this.visibleDetail = false;
    this.fetchData();
  }
  public closeDialogSubDetail() {
    // change after
    this.subId = '';
    this.visibleDetailSub = false;
    this.fetchData();
  }
  public formatterStaff(model: any) {
    if (model && model.shop && model.shop.dealerShops.length) {
      return model.shop.dealerShops.filter(
        (item) => !item.isOwner && item.contractStatus === ContractStatus.Accepted
      ).length;
    }

    return 0;
  }
  public displayAddress(model: IAddress) {
    return model && model.province
      ? this.$store.getters['getAddressString'](model)
      : '';
  }

  public formatterContent(model: any) {
    if (model.reviews) {
      const last = model.reviews.length - 1;

      return model.reviews[last].description.length < 120 ?
        model.reviews[last].description
        : model.reviews[last].description.slice(0, 120) + '...';
    }

    return '--';
  }
  public changeTab(tab: TabPane) {
    switch (tab.name) {
    case CustomCampaignTab.RAParticipant:
      this.$router.push({
        name: CustomCampaignRouterName.RACampaign
      });
      break;
    case CustomCampaignTab.RAConfiguration:
      this.$router.push({
        name: CustomCampaignRouterName.RAConfiguration
      });
      break;
    case CustomCampaignTab.RAStatistic:
      this.$router.push({
        name: CustomCampaignRouterName.RAStatistic
      });
      break;
    case CustomCampaignTab.RAShops:
      this.$router.push({
        name: CustomCampaignRouterName.RAShops
      });
      break;
    case CustomCampaignTab.SubDealerRequest:
      this.$router.push({
        name: CustomCampaignRouterName.SubDealerRequest
      });
      break;
    case CustomCampaignTab.ConfirmationCode:
      this.$router.push({
        name: CustomCampaignRouterName.ConfirmationCode
      });
      break;
    case CustomCampaignTab.SubDealerCampaign:
      this.$router.push({
        name: CustomCampaignRouterName.SubDealerCampaign
      });
      break;
    default:
      break;
    }
  }
  public reloadListImport() {
    this.fetchData();
  }
  public approveAll() {
    this.$msgbox({
      title: this.$t('approve_all_confirm').toString(),
      message: `Do you want to approve all?`,
      showCancelButton: true,
      confirmButtonText: 'Confirm',
      cancelButtonText: 'Cancel',
      beforeClose: (action, instance, done) => {
        if (action === 'confirm') {
          instance.confirmButtonLoading = true;
          instance.confirmButtonText = 'Loading...';
          this.handleApproveAll({ instance, done });
        } else {
          done();
        }

        return;
      }
    })
        .then(() => {
          this.$message({
            type: 'success',
            message: this.$t('message.approveSuccess').toString()
          });
        })
        .catch(() => {
          // no handle
        });
  }
  public handleApproveAll(model) {
    this.$store.dispatch(ActionTypeRaCampaign.ApproveAll, {
      id: this.authUser.id,
      onSuccess: () => {
        this.fetchData();
        model.instance.confirmButtonLoading = false;
        model.instance.confirmButtonText = 'Confirm';
        model.done();
      },
      onFailure: () => {
        model.instance.confirmButtonLoading = false;
        model.instance.confirmButtonText = 'Cancel';
      }
    });
  }
  public handlePaginationChange(value) {
    if (typeof value === 'number') {
      this.$store.commit(MutationTypeRaCampaign.PaginationChange, {
        ...this.pagination,
        size: value
      });
    } else {
      this.$store.commit(MutationTypeRaCampaign.PaginationChange, {
        ...this.pagination,
        ...value
      });
    }
    this.fetchData();
  }
  protected mounted() {
    this.$nextTick(() => {
      const breadcrumb: IBreadcrumb[] = [
        {
          label: 'title.campaign',
          path: null,
          current: false
        },
        {
          label: 'title.ra_campaign',
          path: null,
          current: true
        }
      ];
      this.$store.commit(MutationType.SetBreadcrumb, breadcrumb);

      this.fetchData();
    });
  }
  private fetchData() {

    this.$store.dispatch(ActionTypeRaCampaign.GetQualifiedShops, {
      pagination: this.pagination
    });
  }
}
