import './styles.scss';

import { Subscription } from 'apollo-client/util/Observable';
import { ElUpload } from 'element-ui/types/upload';
import { cloneDeep } from 'lodash';
import { ActionTypeBranchManage } from 'root/admin/BranchAndGroup/BranchManage/Store/types';
import { ActionTypeGroupManage } from 'root/admin/BranchAndGroup/GroupManage/Store/types';
import { ActionTypeRole } from 'root/admin/Role/Store/types';
import { SystemRoleAlias } from 'root/admin/User/Store/types';
import { DKGqlClient } from 'root/api/graphql/Client';
import { gqlClient, IPaginationParams } from 'root/api/graphql/Core';
import { dealerListQuery } from 'root/api/graphql/Query/dealer';
import { Avatar } from 'root/components/Avatar';
import { ChangePassword } from 'root/components/ChangePassword';
import { FilterByBranch } from 'root/components/FilterByBranch';
import { fullNameUser } from 'root/helpers';
import { IAddress, IFilterInput, StatusCode } from 'root/models';
import { ESystemGroup, IUser } from 'root/models/User';
import { pathUpload, uploadToFirebase } from 'root/services';
import { FirebaseAuthService } from 'root/services/auth/firebase';
import { IState, MutationType, TypeAlert } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import { DealerRouterName } from '..';
import { DealerEditor } from '../Components/DealerEditor';
import { UpgradeRequestModal } from '../Components/UpgradeRequest';
import { IDealerState } from '../Store';
import {
  ActionTypeDealer,
  // EUpgradeAction,
  MutationTypeDealer,
  TypeRole
} from '../Store/types';

const enum FilterKey {
  Branch = 'branchId',
  Group = 'groupdId',
  Role = 'roleId',
  Status = 'status'
}
export enum dealerType {
  Proshop = 'proshop',
  NonProShop = 'non-proshop',
  HA = 'H/A',
  Ws= 'W/S',
  ACProject = 'a/c_project',
  ACRoute = 'a/c_route',
  Developing = 'developing',
  Growing = 'growing',
  Core = 'core',
  Existing = 'existing'
}
export enum dealerTypeName {
  Proshop = 'Proshop',
  NonProShop = 'Non Proshop',
  HA = 'H/A',
  Ws= 'W/S',
  ACProject = 'A/C project',
  ACRoute = 'A/C route',
  Developing = 'Developing',
  Growing = 'GROWING',
  Core = 'CORE',
  Existing = 'EXISTING'
}

@Component({
  template: require('./view.html'),
  components: {
    editor: DealerEditor,
    avatar: Avatar,
    'upragde-request-modal': UpgradeRequestModal,
    changPassword: ChangePassword,
    'btn-filter-by-branch': FilterByBranch
  },
  computed: {
    ...mapState({
      authUser: (state: IState) => state.global.authUser,
      data: (state: IState) => state.dealer.data,
      pagination: (state: IState) => state.dealer.pagination,
      loading: (state: IState) => state.dealer.loading,
      branch: (state: IState) => state.branchManage.data,
      group: (state: IState) => state.groupManage.data,
      typefilter: (state: IState) => state.role.data,
      isRefesh: (state: IState) => state.global.isRefesh,
    }),
    selectedStatus() {
      const filterParams = [];

      if (!filterParams) {
        return [];
      }

      return [
        {
          key: 'isCreatedByAppleReviewer',
          value: []
        }
      ];
    },
  },
})
export class DealerList extends Vue {
  public get isSalePlanning() {
    if (this.authUser.superAdmin || this.authUser.role.alias === SystemRoleAlias.Admin
      || this.authUser.role.alias === SystemRoleAlias.SaleAdmin) {
      return true;
    }

    return false;
  }
  public get columns() {
    return [
      {
        prop: 'name',
        label: 'Dealer Name',
        labelItem: 'Code',
        formatter: (model: any) => {
          return fullNameUser(model) || '--';
        },
        avatar: {
          formatter: (model: any) => {
            return model ? model : null;
          }
        },
        width: 250,
        fixed: true
      },
      // {
      //   prop: 'companyName',
      //   label: 'Company Name',
      //   width: 200,
      //   formatter: (model: any) => {
      //     return model.companyName || '--';
      //   }
      // },
      // {
      //   prop: 'code',
      //   label: 'Public Code',
      //   width: 180,
      //   formatter: (model: any) => {
      //     return model.publicCode || '--';
      //   }
      // },
      {
        prop: 'shortName',
        label: 'Short Name',
        width: 180,
        formatter: (model: any) => {
          return model.shortName || '--';
        }
      },
      {
        prop: 'taxNumber',
        label: 'Tax Number',
        width: 180,
        formatter: (model: any) => {
          return model.taxNumber ? model.taxNumber : '--';
        }
      },
      {
        prop: 'proshopType',
        label: 'ProShop Type',
        width: 180,
        formatter: (model: any) => {
          return model.proshopType ? model.proshopType.name : '--';
        }
      },
      {
        prop: 'businessType',
        label: 'Business Type',
        width: 180,
        formatter: (model: any) => {
          return model.businessType ? model.businessType.name : '--';
        }
      },
      {
        prop: 'potentialType',
        label: 'Potential Type',
        width: 180,
        formatter: (model: any) => {
          return model.potentialType ? model.potentialType.name : '--';
        }
      },
      {
        prop: 'address',
        label: 'Address',
        width: 220,
        formatter: (model: any) => {
          if (model.address) {
            return this.displayAddress(model.address) || '--';
          }

          return '';
        }
      },
      {
        prop: 'branch',
        label: 'Branch',
        width: 180,
        formatter: (model: any) => {
          if (model.branch && model.branch.name) {
            return model.branch.name;
          }

          return '--';
        }
      },
      {
        prop: 'group',
        label: 'Department',
        width: 180,
        formatter: (model: any) => {
          if (model.group && model.group.name) {
            return model.group.name;
          }

          return '--';
        }
      },
      {
        prop: 'email',
        label: 'Owner Email',
        width: 180,
        formatter: (model: any) => {
          return model.email || '--';
        }
      },
      {
        prop: 'shopCount',
        label: 'Dealer Shop',
        width: 150,
        formatter: (model: any) => {
          return model.shopCount || '--';
        }
      },
      {
        prop: 'status',
        label: this.$t('status'),
        formatter: (model: any) => (model.status ? this.$t(model.status) : ''),
        labelStyle: (model: any) => {
          return model.status;
        },
        width: 180
      },
      {
        prop: 'action',
        label: '',
        align: 'center',
        fixed: 'right',
        width: 60
      }
    ];
  }
  public get actions() {
    if (this.isSalePlanning) {
      return [
        'view',
        'edit',
        'block',
        'delete',
        'change-password'
      ];
    }

    return [
      'view',
    ];
  }

  public get inputSearchPlaceholder(): string {
    return `Search by Name, Email`;
  }
  public get dataFilterBranch() {
    const _branch: IFilterInput[] = this.branch.reduce((res, item) => {
      res.push({
        key: item.id,
        value: item.id,
        name: item.name
      });

      return res;
    }, []);

    return [
      {
        key: FilterKey.Branch,
        name: 'Branch',
        value: _branch
      },
    ];
  }
  public get dataFilter() {
    this.reduceType();
    // const _branch: IFilterInput[] = this.branch.reduce((res, item) => {
    //   res.push({
    //     key: item.id,
    //     value: item.id,
    //     name: item.name
    //   });

    //   return res;
    // }, []);
    const _groups: IFilterInput = this.group.filter((type) => type.name !== 'Technician').reduce((res, item) => {
      res.push({
        key: item.id,
        value: item.id,
        name: item.name
      });

      return res;
    }, []);
    const _typeFilter: IFilterInput[] = this.reduceType().reduce(
      (res, item) => {
        res.push({
          key: item.id,
          value: item.id,
          name: item.name
        });

        return res;
      },
      []
    );
    const _statuses: IFilterInput[] = [
      {
        key: StatusCode.Active,
        value: StatusCode.Active,
        name: this.$t(StatusCode.Active)
      },
      {
        key: StatusCode.Blocked,
        value: StatusCode.Blocked,
        name: this.$t(StatusCode.Blocked)
      },
      // {
      //   key: StatusCode.WaitingInactive,
      //   value: StatusCode.WaitingInactive,
      //   name: this.$t(StatusCode.WaitingInactive)
      // },
      {
        key: StatusCode.Inactive,
        value: StatusCode.Inactive,
        name: this.$t(StatusCode.Inactive)
      },
      // {
      //   key: StatusCode.ExpiredCard,
      //   value: StatusCode.ExpiredCard,
      //   name: this.$t(StatusCode.ExpiredCard)
      // },
      {
        key: StatusCode.Suspended,
        value: StatusCode.Suspended,
        name: this.$t(StatusCode.Suspended)
      },
    ];

    return [
      // {
      //   key: FilterKey.Branch,
      //   name: 'Branch',
      //   value: _branch
      // },
      {
        key: FilterKey.Group,
        name: 'Department',
        value: _groups
      },
      {
        key: FilterKey.Role,
        name: 'Type',
        value: _typeFilter
      },
      {
        key: FilterKey.Status,
        name: this.$t('field.status'),
        value: _statuses
      }
    ];
  }
  public get selectedFilter() {
    const filterParams = cloneDeep(this.filterParams);

    return [
      {
        key: FilterKey.Branch,
        value: filterParams[FilterKey.Branch]
      },
      {
        key: FilterKey.Group,
        value: filterParams[FilterKey.Group]
      },
      {
        key: FilterKey.Role,
        value: filterParams[FilterKey.Role]
      },
      {
        key: FilterKey.Status,
        value: filterParams[FilterKey.Status]
      }
    ];
  }

  public authUser: IUser;
  public pagination: IPaginationParams;
  public data: IDealerState[];
  public branch: any[];
  public group: any[];
  public typefilter: any[];
  public status: any[];
  public visible: boolean = false;
  public isSample: boolean = false;
  public userId: string = '';
  public visibleDetail: boolean = false;
  public popupVisible: boolean = false;
  public filterParams = {
    [FilterKey.Branch]: [],
    [FilterKey.Group]: [],
    [FilterKey.Role]: [],
    [FilterKey.Status]: []
  };
  public visibleUpgrade: boolean = false;
  public searchText: string = '';
  public filterTypeDealer: any[];
  // public showUploadDialog() {
  //   this.isSample = true;
  // }
  public isRefesh: boolean;
  public visibleChangePass: boolean = false;
  // public loading: boolean = false;
  public file: any;
  public fileUpload: any;
  public fileUploaded= [];
  public fileList = [];
  public loadingUpload: boolean = false;
  public loadingExport: boolean = false;
  public DaikinDealerId: string;
  public $refs: {
    upload: ElUpload;
  };
  public visibleImport: boolean = false;

  public UserType = {
    Customer: 'customer',
    System: 'system'
  };
  public type: any;
  // tslint:disable-next-line:max-line-length
  private sampleUrl = `https://firebasestorage.googleapis.com/v0/b/daikin-sale-dev.appspot.com/o/template%2Ftemplate_import_dealer.xlsx?alt=media&token=229f1bde-fdc0-4cd4-9b98-ce329c6e7d1c`;
  private pathUploadAvatar: pathUpload = pathUpload.DEALER;
  // public isShowButtonNew(): boolean {
  //   if (this.authUser.group === GroupSystemUserType.Station) {
  //     return false;
  //   }

  //   return true;
  // }
  private subscribeURL: Subscription = null;
  public getActions() {
    if (this.isSalePlanning) {
      return [
        'view',
        'edit',
        'block',
        'delete',
        'change-password'
      ];
    }

    return ['view'];
  }

  public handleActions({ id, action }) {
    if (action === 'edit') {
      this.handleUpdate(id);

      return;
    }

    if (action === 'view') {
      this.clickDetail(id);

      return;
    }

    if (action === 'block') {
      this.handleBlock(id);

      return;
    }
    if (action === 'delete') {
      this.deleteConfirm(id);

      return;
    }
    if (action === 'change-password') {
      this.openChangePassword(id);

      return;
    }

    return;
  }
  public exportDealer(role: string) {
    this.loadingExport = true;
    // if (this.filterParams[FilterKey.Branch].length) {
    //   console.log(this.filterParams[FilterKey.Branch]);
    // }
    this.$store.dispatch(ActionTypeDealer.GetRoleId, {
      role,
      onSuccess: (roleId) => {
        // tslint:disable-next-line: early-exit
        if (roleId && roleId.length) {
          this.$store.dispatch(ActionTypeDealer.ExportDealer, {
            object: {filter:
            {
              type: roleId[0].id,
              branch: this.filterParams[FilterKey.Branch].length ? this.filterParams[FilterKey.Branch] : null,
              group: this.filterParams[FilterKey.Group].length ? this.filterParams[FilterKey.Group] : null,
              status: this.filterParams[FilterKey.Status].length ? this.filterParams[FilterKey.Status] : null,
            }
            },
            onSuccess: (url) => {
              // tslint:disable-next-line: early-exit
              if (url.returning && url.returning.length) {
                this.subscribeURL = <any> gqlClient.subscribe({
                  query: dealerListQuery.DOWNLOAD_EXPORT,
                  variables : {id: url.returning[0].id},
                  fetchPolicy: 'cache-first'
                }).subscribe((results) => {
                  // tslint:disable-next-line: early-exit
                  if (results.data && results.data.export_dealers && results.data.export_dealers.length
                    && results.data.export_dealers[0].exportStatus === 'done') {
                    window.open(results.data.export_dealers[0].url, '_blank');
                    this.subscribeURL.unsubscribe();
                    this.loadingExport = false;
                  }
                });
              }
            }
          });
        }
      }
    });
  }
  public closeDialogDetail() {
    this.userId = '';
    this.visibleDetail = false;
  }
  public upgradeRequest(id: string) {
    this.visibleUpgrade = true;
    this.userId = id;
  }
  public importDealer() {
    this.visibleImport = true;
  }

  public createSuccess() {
    this.visible = false;
    this.fetchData();
  }

  public applyFilter() {
    this.fetchData();
  }
  public goAddNewRouter() {
    this.visible = true;
    this.userId = '';
  }
  public closeDialog() {
    this.isSample = false;
    this.fetchData();
  }
  public downloadSample() {
    window.open(this.sampleUrl, '_blank');
  }
  public displayAddress(model: IAddress) {
    return model && model.province
      ? this.$store.getters['getAddressString'](model)
      : '';
  }
  public openChangePassword(id: string) {
    this.userId = id;
    this.visibleChangePass = true;
  }
  public changePassword(pass: string) {
    const newPassword: string = pass;
    this.$store.dispatch(ActionTypeDealer.ChangePassDealer, {
      id: this.userId,
      pass: newPassword,
      onSuccess: () => {
        this.$message({
          type: 'success',
          message: this.$t('change_password_successfully').toString()
        });
        this.userId = '';
        this.visibleChangePass = false;
        this.fetchData();
      },
      onFailure: (error) => {
        this.$message.error(error.message);
        this.userId = '';
        this.visibleChangePass = false;
        this.fetchData();
      }
    });
  }
  public handleSearch() {
    this.fetchData();
  }
  public closeModalImport() {
    this.visibleImport = false;
    this.$refs.upload.clearFiles();
    this.fileList = [];
  }
  public pushFileUpload(file: any) {
    const f = this.fileList;
    if (this.fileList.length > 0) {
      const _file = this.fileList.filter((it) => it.name === file.name);
      if (_file && _file.length > 0) {
        this.$refs.upload.clearFiles();
        const message = 'File has been exits';
        this.$store.commit(MutationType.OpenTopAlert, {
          message,
          type: TypeAlert.Warning
        });

        return this.$refs.upload.$data.uploadFiles = f;
      }

      return this.fileList = [file];
    }

    f.push(file);

    return this.fileList = [file];
  }
  public onRemove(file: any, fileList: any) {
    console.log(file);
    this.fileList = fileList;
  }
  public async submitFile() {
    // tslint:disable-next-line: whitespace
    try {
      this.loadingUpload = true;
      // const uploadedFile = <any> [];
      const token = await DKGqlClient().user.getFirebaseAuthenticateToken();
      await FirebaseAuthService().signInWithCustomToken(token.authenticate);
      const uploadedFile = await Promise.all(this.fileList.map(async (it) => {
        const _file = await uploadToFirebase(it.raw, this.pathUploadAvatar);

        // return _file ? uploadedFile.push(_file) : uploadedFile;
        // tslint:disable-next-line:no-var-before-return
        return _file;
      }));

      if (uploadedFile.length > 0) {
        const form = {
          createdBy: this.authUser.id,
          url: uploadedFile[0]
        };
        this.$store.dispatch(ActionTypeDealer.ImportFileDealer, {
          form,
          onSuccess: () => {
            this.loadingUpload = false;
            const message = 'Import dealer successfully';
            this.$store.commit(MutationType.OpenTopAlert, {
              message,
              type: TypeAlert.Success
            });
            this.visibleImport = false;
            this.fileList = [];
            this.fileUploaded = [];
            this.$refs.upload.clearFiles();
            this.fetchData();
          },
          onFailure: () => {
            this.loadingUpload = false;

          }});
      }

    } catch (error) {
      console.log(error);
    }

  }
  public handleCommand(command) {
    if (command === 'addnew') {
      this.visible = true;
    } else {
      this.visibleImport = true;
    }
  }
  public deleteConfirm(id: string) {
    this.$msgbox({
      title: this.$t('delete_confirm').toString(),
      message: this.$t('delete_confirm_message').toString(),
      showCancelButton: true,
      customClass: 'message-error',
      confirmButtonText: this.$t('confirm').toString(),
      cancelButtonText: this.$t('cancel').toString(),
      beforeClose: (action, instance, done) => {
        if (action === 'confirm') {
          instance.confirmButtonLoading = true;
          instance.confirmButtonText = 'Loading...';
          this.confirmDelete({ id, instance, done });
        } else {
          done();
        }

        return;
      }
    })
      .then(() => {
        this.$message({
          type: 'success',
          message: this.$t('message.deleteSuccess').toString()
        });
      })
      .catch(() => {
        // no handle
      });
  }
  public confirmDelete(model: any) {
    this.$store.dispatch(ActionTypeDealer.UpdateStatusDealer, {
      id: model.id,
      status: StatusCode.Deleted,
      onSuccess: () => {
        this.fetchData();
        model.instance.confirmButtonLoading = false;
        model.instance.confirmButtonText = 'Confirm';
        model.done();
      },
      onFailure: () => {
        model.instance.confirmButtonLoading = false;
        model.instance.confirmButtonText = 'Confirm';
      }
    });
  }

  public handleDelete(model: any) {
    this.$store.dispatch(ActionTypeDealer.UpdateStatusDealer, {
      id: model.id,
      status: StatusCode.Deleted,
      onSuccess: () => {
        this.fetchData();
        model.instance.confirmButtonLoading = false;
        model.instance.confirmButtonText = 'Confirm';
        model.done();
      },
      onFailure: () => {
        model.instance.confirmButtonLoading = false;
        model.instance.confirmButtonText = 'Confirm';
      }
    });
  }
  public handleDisabled(model: any) {
    const { status } = this.$store.getters.getDealerDetail(model.id);
    this.$store.dispatch(ActionTypeDealer.UpdateStatusDealer, {
      id: model.id,
      status:
        status === StatusCode.Active ? StatusCode.Blocked : StatusCode.Active,
      onSuccess: () => {
        this.fetchData();
        model.instance.confirmButtonLoading = false;
        model.instance.confirmButtonText = 'Confirm';
        const message = 'Block Dealer successfully';
        this.$store.commit(MutationType.OpenTopAlert, {
          message,
          type: TypeAlert.Success
        });
        model.done();
      },
      onFailure: () => {
        model.instance.confirmButtonLoading = false;
        model.instance.confirmButtonText = 'Confirm';
      }
    });
  }
  public handleBlock(id: string) {
    this.$msgbox({
      title: this.$t('confirm').toString(),
      message: this.$t('action_confirm_message').toString(),
      showCancelButton: true,
      confirmButtonText: this.$t('confirm').toString(),
      cancelButtonText: this.$t('cancel').toString(),
      beforeClose: (action, instance, done) => {
        if (action === 'confirm') {
          instance.confirmButtonLoading = true;
          instance.confirmButtonText = 'Loading...';
          this.confirmBlock({ id, instance, done });
        } else {
          done();
        }

        return;
      }
    }).catch(() => {
      // no handle
    });
  }
  public confirmBlock(model: any) {
    const { status } = this.$store.getters.getDealerDetail(model.id);
    this.$store.dispatch(ActionTypeDealer.UpdateStatusDealer, {
      id: model.id,
      status:
        status !== StatusCode.Blocked ? StatusCode.Blocked : StatusCode.Active,
      onSuccess: () => {
        this.fetchData();
        model.instance.confirmButtonLoading = false;
        model.instance.confirmButtonText = 'Confirm';
        const message = 'Block Dealer successfully';
        this.$store.commit(MutationType.OpenTopAlert, {
          message,
          type: TypeAlert.Success
        });
        model.done();
      },
      onFailure: () => {
        model.instance.confirmButtonLoading = false;
        model.instance.confirmButtonText = 'Confirm';
      }
    });
  }
  public changePassSuccess() {
    this.popupVisible = false;
    this.fetchData();
  }
  public reduceType() {
    const roleSystem = this.typefilter.filter(
      (it: any) =>
        it.systemGroup && it.systemGroup.name &&
        it.systemGroup.name === ESystemGroup.Dealer &&
        (it.name === 'Daikin Dealer' || it.name === 'Guest'));

    // tslint:disable-next-line:no-var-before-return
    return roleSystem;
  }

  public clickDetail(id: string) {
    this.$router.push({
      name: DealerRouterName.DetailDealer,
      params: {
        id
      }
    });
  }
  public handleUpdate(id: string) {
    this.visible = true;
    this.userId = id;
  }
  public changeFilterBranch(filter) {
    const nextFilter = {
      [FilterKey.Branch]: filter ? filter : [],
    };
    this.filterParams = {
      ...cloneDeep(this.filterParams),
      ...nextFilter
    };
    this.fetchData();
  }
  public changeFilter(model) {
    // const branch = model.find((e) => e.key === FilterKey.Branch);
    const group = model.find((e) => e.key === FilterKey.Group);
    const role = model.find((e) => e.key === FilterKey.Role);
    const status = model.find((e) => e.key === FilterKey.Status);
    const nextFilter = {
      // [FilterKey.Branch]: branch ? branch.value : [],
      [FilterKey.Group]: group ? group.value : [],
      [FilterKey.Role]: role ? role.value : [],
      [FilterKey.Status]: status ? status.value : []
    };
    this.filterParams = {
      ...cloneDeep(this.filterParams),
      ...nextFilter
    };
    this.fetchData();
  }

  public formatFullNameUser(model) {
    return fullNameUser(model);
  }

  public handlePaginationChange(value) {
    if (typeof value === 'number') {
      this.$store.commit(MutationTypeDealer.DealerPaginationChange, {
        ...this.pagination,
        size: value
      });
    } else {
      this.$store.commit(MutationTypeDealer.DealerPaginationChange, {
        ...this.pagination,
        ...value
      });
    }
    this.fetchData();
  }

  protected mounted() {
    this.$nextTick(() => {
      this.$store.commit(MutationType.ClearBack);
      this.$store.commit(MutationType.SetBreadcrumb, this.$t('title.dealer'));
      this.$store.commit(MutationTypeDealer.PaginationReset);
      this.$store.commit(MutationTypeDealer.Loading);
      this.$store.dispatch(ActionTypeRole.FilterNoCache, {
        params: {
          onSuccess: (result) => {
            const roleDaikin = result.find((it) => it.name === TypeRole.DaikinDealer);
            this.DaikinDealerId = roleDaikin.id;
            this.fetchData();
          }
        }
      });

    });
  }
  protected beforeDestroy() {
    this.$store.commit(MutationType.ClearBreadcrumb);
  }

  private async fetchData() {
    const text = cloneDeep(this.searchText);
    const filterDealer = <any> {
      status: [
        StatusCode.Active,
        StatusCode.Blocked,
        StatusCode.Inactive,
        StatusCode.Suspended,
      ],
      type: this.DaikinDealerId
    };
    if (this.filterParams[FilterKey.Branch].length) {
      filterDealer.branch = this.filterParams[FilterKey.Branch];
    }
    if (this.filterParams[FilterKey.Group].length) {
      filterDealer.group = this.filterParams[FilterKey.Group];
    }
    if (this.filterParams[FilterKey.Role].length) {
      filterDealer.type = this.filterParams[FilterKey.Role];
    }
    if (this.filterParams[FilterKey.Status].length) {
      filterDealer.status = this.filterParams[FilterKey.Status];
    }
    if (text) {
      filterDealer.search = text;
    }
    this.$store.dispatch(ActionTypeDealer.GetListDealer, {
      form: {
        filter: filterDealer,
        pagination: {
          size: this.pagination.limit,
          page: text ? 1 : this.pagination.page
        }
      },
      onSuccess: () => {
        this.$store.dispatch(ActionTypeBranchManage.GetListBranch, {
          form: {
            filter: {},
            pagination: {}
          }
        });
        // this.$store.dispatch(ActionTypeGroupManage.GetListGroupManage, {
        //   form: {
        //     filter: {},
        //     pagination: {}
        //   }
        // });
        this.$store.dispatch(ActionTypeGroupManage.GetListFilterGroup);
      }
    });
  }
}
