import { crudQuery } from 'root/store/helpers/query';
import { ICRUDQuery } from '../Core/query';

const condition = 'conditions';

const schema = `
  createdAt
  createdBy
  description
  id
  name
  descriptionEng
  nameEng
  code
  status
  updatedAt
  updatedBy
  createdUser {
    id
    name
  }
  updatedUser {
    id
    name
  }
`;

export const ConditionQuery: ICRUDQuery = {
  ...crudQuery(condition, schema)
};
