import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';

import './styles.scss';

@Component({
  template: require('./view.html'),
  props: {
    dataDealer: Array,
    dealerSelected: Array,
    isUpdate: Boolean
  }
})

export class DKMultiDealerCheckboxList extends Vue {
  public dataDealer: any[];
  public dealerSelected: string[];
  public branchSelected: string[];
  public allData: any[] = [];
  public checkedItems: string[] = [];
  public typeDealer: string = 'Daikin Dealer';
  public isCheckAll: boolean = false;
  public isIndeterminate: boolean = false;
  public isUpdate: boolean;
  public get getListItem() {
    if (this.dataDealer.length) {
      this.allData = this.dataDealer.filter((item) => item.role.name === this.typeDealer);
      // this.isIndeterminate = this.dealerSelected.length !== this.allData.length;
      if (this.isUpdate) {
        this.checkedItems = this.dealerSelected;
        this.isCheckAll = this.dataDealer.filter((item) =>
        item.role.name === this.typeDealer).map((it) => it.id).length === this.dealerSelected.length;
        this.isIndeterminate = this.dataDealer.filter((item) =>
        item.role.name === this.typeDealer).map((it) => it.id).length !== this.dealerSelected.length;

        return this.allData;
      }
      // this.checkedItems = this.dealerSelected.length ? this.dealerSelected :
      // this.dataDealer.filter((item) => item.role.name === this.typeDealer).map((it) => it.id);
      this.isCheckAll = this.checkedItems.length >= this.allData.length;
      // this.isIndeterminate = this.dealerSelected.length ?
      // this.dataDealer.filter((item) => item.role.name === this.typeDealer)
      // .map((it) => it.id).length !== this.dealerSelected.length : true;

      return this.allData;
    }
    this.checkedItems = [];
    this.isCheckAll = false;
    this.isIndeterminate = false;
    this.allData = [];

    return [];
  }
  public changeTypeDealer(val) {
    this.typeDealer = val;
  }
  public onChangeItems(value) {
    this.checkedItems = value;
    this.$emit('selectedItemChange', this.dataDealer[0].branch, this.typeDealer, value);
    if (value.length === this.allData.length) {
      this.isCheckAll = true;
      this.isIndeterminate = false;
    } else {
      this.isCheckAll = false;
      this.isIndeterminate = true;
    }
  }
  public handleCheckAllChange(val) {
    if (val) {
      this.checkedItems = this.dataDealer.filter((item) => item.role.name === this.typeDealer).map((it) => it.id);
      this.$emit('handleCheckAllBranch', this.dataDealer[0].branch, true);
      this.isIndeterminate = false;
      this.isCheckAll = true;
    } else {
      this.$emit('removeSelectAll', this.dataDealer[0].branch, this.typeDealer, false);
      this.isCheckAll = false;
      this.isIndeterminate = false;
      this.checkedItems = [];
    }
  }
  @Watch('dealerSelected')
  public changeSelectedItems() {
    this.checkedItems = this.dealerSelected;
    this.isCheckAll = this.dealerSelected.length === this.allData.length;
  }
  @Watch('dataDealer')
  public resetForm() {
    if (!this.dataDealer.length) {
      this.checkedItems = [];
      this.isCheckAll = false;
      this.isIndeterminate = false;
      this.allData = [];
    }

    return [];
  }
}
