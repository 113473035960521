import { cloneDeep, find } from 'lodash';
import { IDealerState } from './mutation';

export const getters = {
  getDealerDetail(state: IDealerState) {

    return (id: string) => {

      return find(cloneDeep(state.data), (e) => e.id === id);
    };
  },
  getSubDealerDetail(state: IDealerState) {

    return (id: string) => {

      return find(cloneDeep(state.subDealer), (e) => e.id === id);
    };
  },
};
