import { IPaginationParams } from 'root/api/graphql/Core';
import { IStation } from './Station';

export interface IPhoneInput {
  code?: string;
  number: string | number;
}

export enum UpdatedByType {
  Customer = 'customer',
  Staff = 'staff',
  Admin = 'admin',
  System = 'system'
}

export enum StatusCode {
  Active = 'active',
  Inactive = 'inactive',
  Disabled = 'disabled',
  Deleted = 'deleted',
  Deactivate = 'deactivate',
  Suspended = 'suspended',
  Blocked = 'blocked',
  Unavailable = 'unavailable',
  Availability = 'availability',
  Available = 'available',
  Expired = 'expired',
  Schedule = 'schedule',
  Activated = 'activated',
  Fail = 'fail',
  New = 'new',
  Approve = 'approve',
  Reject ='reject',
  ExpiredCard = 'expired_card',
  WaitingInactive = 'waiting_inactive',
}
export enum ScanProductMode {
  QR = 'qrCode',
  Bar = 'barCode',
  All = 'all'
}

export enum CRUDAction {
  Create = 'create',
  Update = 'update',
  Delete = 'delete'
}

export interface IPagingParams {
  totalPages?: number;
  page: number;
  total?: number;
  order?: string;
  descending?: boolean;
}

export enum OrderType {
  DESC = 'descending',
  ASC = 'ascending'
}

export interface IPagingWordpressParams {
  page: number;
  per_page: number;
}

export interface IPagingResult<T> {
  data: T[];
  pagination: IPaginationParams;
}

export enum Gender {
  Male = 'male',
  Female = 'female',
  Empty = ''
}

export enum RewardType {
  Static = 'static',
  Dynamic = 'dynamic',
  StaticLabel = 'Static Reward',
  DynamicLabel = 'Dynamic Reward'
}
export enum RedeemType {
  Single = 'single',
  Multiple = 'multiple',
  SingleLabel = 'Single',
  MultipleLabel = 'Multiple'
}
export interface IFileInfo {
  url: string;
  fullPath: string;
  bucketName?: string;
}

export interface IImageUrl {
  url: string;
  thumbnailUrl: string;
}

export const phoneCodeDefault = '+65';

export interface IBreadcrumb {
  path: string;
  label: string;
  current?: boolean;
}

export interface IWard {
  xaid: string; // key
  matp: string;
  name: string;
  maqh: string;
}
export interface IDistrict {
  hidden: boolean;
  maqh: string; // key
  matp: string;
  name: string;
  child: IWard[];
}
export interface IProvince {
  matp: string; // key
  name: string;
  type: string;
  code: string;
  child: IDistrict[];
}
export interface IAddress {
  ward: number;
  address: string;
  district: number;
  province: number;
}

export interface IInfoPartner {
  id?: string;
  stationId?: string;
  name: string;
  station?: IStation;
  daikinCode?: string;
}

export const addressDefault = (): IAddress => {
  return {
    district: null,
    province: null,
    address: '',
    ward: null
  };
};

export interface IFilterInput {
  key: string;
  value: any | IFilterInput[];
  name?: string | any;
  isSingleCheckbox?: boolean;
  style?: string;
}
export enum GlobalConst {
  DATE_TIME_PATTERN = 'YYYY-MM-DD HH:mm:ss'
}
export enum LocalStorageKey {
  AdminVersion = 'DK_SALE_PORTAL_VERSION',
  ExportLogKPIPayment = 'DK_EXPORT_LOG_KPI_PAYMENT',
  ExportLogKPIStatistic = 'DK_EXPORT_LOG_KPI_STATISTIC',
  ExportLogMaintenancePaymentForPartner = 'DK_EXPORT_LOG_MAINTENANCE_PAYMENT_FOR_PARTNER',
  ExportLogMaintenancePaymentForTechnician = 'DK_EXPORT_LOG_MAINTENANCE_PAYMENT_FOR_TECHNICIAN',
  ExportLogTechnicianBonus = 'DK_EXPORT_LOG_TECHNICIAN_BONUS',
  ExportLogTechnicianInHouseOT = 'DK_EXPORT_LOG_TECHNICIAN_IN_HOUSE_OT'
}
export enum SkillStatus {
  Active = 'active',
  Disabled = 'disabled'
}

export enum GradingStatus {
  Platinum = 'Platinum',
  Gold = 'Gold',
  Silver = 'Silver',
  Other = 'Other'
}

export enum QualifiedStatus {
  Pass = 'Pass',
  Failed = 'Failed'
}

export const ItemPage: any[] = [
  {
    value: 10,
    label: '1 - 10'
  },
  {
    value: 20,
    label: '1 - 20'
  },
  {
    value: 50,
    label: '1 - 50'
  }
];

export enum PartnerType {
  InHouse = 'in_house',
  OutSource = 'out_source'
}

export enum TypeExceptionalDevice {
  DeviceByContract = 'device_by_contract',
  CannotFindSerial = 'cannot_find_serial',
  DaikinSupport = 'daikin_support',
  Compressor = 'compressor',
  Proshop = 'proshop',
  SVT = 'svt',
  TC = 'tc'
}

export enum WarrantyStatusOptions {
  OutOfWarranty = 'out_of_warranty',
  InWarranty = 'in_warranty'
}

export enum PaidByOptions {
  Customer = 'customer',
  Daikin = 'daikin'
}

export enum ClaimToOptions {
  CUS = 'CUS',
  DISH = 'DISH',
  DSP = 'DSP',
  DAT = 'DAT',
  DAA = 'DAA',
  DAE = 'DAE',
  DIT = 'DIT',
  DIL = 'DIL',
  DAMA = 'DAMA',
  DPH = 'DPH',
  DRM = 'DRM',
  DAV = 'DAV',
  DAVF = 'DAVF',
  MQWH = 'MQWH',
  MQSU = 'MQSU',
  MQUnit = 'MQUnit',
  DASI = 'DASI',
  MQSZ = 'MQSZ'
}

export interface IDKFilterData {
  labelKey: string;
  valueKey: string;
  data: any;
  extraKey?: string;
}

export enum ScheduleExportStatus {
  Loading = 'loading',
  Completed = 'completed',
  Error = 'error',
  Indexing = 'indexing',
  Cancelled = 'cancelled'
}

export enum Submitted {
  Checked = 'checked',
  UnChecked = 'unChecked'
}

export enum VoucherType {
  ReusableCode = 'RUC',
  OneTimeCode = 'OTC'
}

export enum EvaluationType {
  Passed = 'passed',
  Failed = 'failed',
  UnChecked = 'un_checked'
}

export enum TechnicianCellPhoneStatus {
  Active = 'active',
  Revoked = 'revoked'
}
export enum RewardTarget {
  All = 'All',
  Dealer = 'Dealer',
  Technician = 'Technician'
}
