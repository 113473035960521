import { gqlClient } from '../Core';
import { CRUDGqlService, ICRUDGqlService } from '../Core/crud';
import { pointListQuery, Points } from '../Query/point';

export interface IPointGqlService extends ICRUDGqlService<any> {
  //
  getListPoint(form: any): Promise<any>;
}
export function pointGqlService(): IPointGqlService {
  async function getListPoint(form: any): Promise<any> {
    const results = await gqlClient.query({
      query: pointListQuery.GET_POINT_LIST,
      variables: {
        form
      },
      fetchPolicy: 'network-only'
    });
    if (results.errors) {
      throw results.errors;
    }

    return results.data.getPointHistory;
  }

  return {
    ...CRUDGqlService<any>(Points
    , pointListQuery),
    getListPoint,
  };
}
