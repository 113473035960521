import moment from 'moment';
import { ActionTypeExample } from 'root/admin/WelcomePage/Store/types';
import { DKGqlClient } from 'root/api/graphql/Client';
import { ELocalStorage } from 'root/models';
import { RouteDictionary } from 'root/router/types';
import DaikinAuth from 'root/services/auth';
import { ActionType, IState, Layout, MutationType } from 'root/store';
import { crudActions } from 'root/store/helpers';
import { ActionContext } from 'vuex';
import { IUserState } from './mutation';
import { ActionTypeUser, MutationTypeUser } from './types';

const { user } = DKGqlClient();
export const actions = {
  ...crudActions('user', {
    filter: user.filter,
    getById: user.getById,
    update: user.update
  }),
  async [ActionTypeUser.GetProfile]({
    commit, dispatch
  }: ActionContext<IUserState, IState>,
                                    {router}) {
    try {
      commit(MutationType.SetIsRefesh, true);
      const data = {
        token: localStorage.getItem(ELocalStorage.Token),
        refreshToken: localStorage.getItem(ELocalStorage.RefreshToken)
      };
      const newToken = await DaikinAuth.refreshToken(data);
      localStorage.setItem(ELocalStorage.Token, null);
      localStorage.setItem(ELocalStorage.RefreshToken, null);
      localStorage.setItem('ExpireTime', null);
      if (newToken) {
        localStorage.setItem(ELocalStorage.Token, newToken.data.data.token);
        localStorage.setItem(ELocalStorage.RefreshToken, newToken.data.data.refreshToken);
        localStorage.setItem('ExpireTime', moment().add(50, 'minutes').format('DD-MM-YYYY HH:mm:ss'));
        const authUser = await user.getProfile();
        commit(MutationType.Authenticated, authUser);
        commit(MutationType.SetIsRefesh, false);
        commit(MutationType.SetLayout, Layout.Home);
      }
      dispatch(ActionTypeExample.GetAdministration);
    } catch (error) {
      commit(MutationType.SetLayout, Layout.Login);
      if (router) {
        router.replace(RouteDictionary.Login);
      }
      dispatch(ActionType.CatchException, error);
      localStorage.clear();
    }
  },
  async [ActionTypeUser.UserCreate]({
    commit, dispatch
  }: ActionContext<IUserState, IState>,
                                    {form, onSuccess, onFailure}) {
    try {
      const createUserResult = await user.createSystemUser(form);
      onSuccess();
      if (createUserResult) {
        commit(ActionTypeUser.FilterNoCache);
      }
    } catch (error) {
      onFailure();
      dispatch(ActionType.CatchException, error);
    }
  },
  async [ActionTypeUser.ChangePassSystemUser]({
    commit, dispatch
  }: ActionContext<IUserState, IState>,
                                              {form, onSuccess, onFailure}) {
    try {
      const changePassResult = await user.changePassSystemUser(form);
      onSuccess();
      if (changePassResult) {
        commit(ActionTypeUser.FilterNoCache);
      }
    } catch (error) {
      onFailure(error);
      dispatch(ActionType.CatchException, error);
    }
  },
  async [ActionTypeUser.UpdateStatusSystemUser]({
    commit, dispatch
  }: ActionContext<IUserState, IState>,
                                                {id, status, onSuccess, onFailure}) {
    try {
      const updateResult = await user.updateStatusSystemUser(id, status);
      onSuccess();
      if (updateResult) {
        commit(ActionTypeUser.FilterNoCache);
      }
    } catch (error) {
      onFailure(error);
      dispatch(ActionType.CatchException, error);
    }
  },
  async [ActionTypeUser.GetTeamStaff]({
    commit, dispatch
  }: ActionContext<IUserState, IState>,
                                      {id}) {
    try {
      const updateResult = await user.getTeamStaff(id);
      if (updateResult) {
        commit(MutationTypeUser.GetTeamStaff);

        return updateResult;
      }
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    }
  },
  async [ActionTypeUser.GetTeamStaffDealer]({
    commit, dispatch
  }: ActionContext<IUserState, IState>,
                                            {id}) {
    try {
      const updateResult = await user.getTeamStaffDealer(id);
      if (updateResult) {
        commit(MutationTypeUser.GetTeamStaffDealer);

        return updateResult;
      }
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    }
  },
  async [ActionTypeUser.UpdateUserManager]({
     dispatch
  }: ActionContext<IUserState, IState>,
                                           {form, onSuccess, onFailure}) {
    try {
      const updateResult = await user.updateUserManager(form);
      onSuccess();
      if (updateResult) {
        dispatch(ActionTypeUser.FilterNoCache);
      }
    } catch (error) {
      onFailure(error);
      dispatch(ActionType.CatchException, error);
    }
  },
  async [ActionTypeUser.GetInfoByPhone]({
    dispatch
 }: ActionContext<IUserState, IState>,
                                        {phone, onSuccess, onFailure}) {
    try {
      const updateResult = await user.getInfoByPhone(phone);
      onSuccess();
      if (updateResult) {
        dispatch(ActionTypeUser.FilterNoCache);
      }
    } catch (error) {
      onFailure(error);
      dispatch(ActionType.CatchException, error);
    }
  },
};
