import { gqlClient } from '../Core';
import { CRUDGqlService, ICRUDGqlService } from '../Core/crud';
import { getListGroupManageQuery, GroupManage } from '../Query/groupManage';

export interface IGroupManageGqlService extends ICRUDGqlService<any> {
  //
  groupManageGetById(id: string): Promise<any>;
  getGroupManageList(form: any): Promise<any>;
  getGroupLeader(form: any): Promise<any>;
  updateGroupManager(form: any): Promise<any>;
  removeGroupManager(form: any): Promise<any>;
  insertGroup(form: any): Promise<any>;
  insertGroupManager(form: any): Promise<any>;
  updateGroup(id: string, form: any): Promise<any>;
  getListFilter(): Promise<any>;
}
export function GroupManageGqlService(): IGroupManageGqlService {
  async function groupManageGetById(id: string): Promise<any> {
    const results = await gqlClient.query({
      query: getListGroupManageQuery.GET_BY_ID,
      variables: {
        id
      },
      fetchPolicy: 'network-only'
    });
    if (results.errors) {
      throw results.errors;
    }

    return results.data.getGroupDetail;
  }
  async function getGroupManageList(form: any): Promise<any> {
    const results = await gqlClient.query({
      query: getListGroupManageQuery.GET_LIST_GROUP_MANAGER,
      variables: {
        form
      },
      fetchPolicy: 'network-only'
    });
    if (results.errors) {
      throw results.errors;
    }

    return results.data.getGroupList;
  }
  async function getGroupLeader(form: any): Promise<any> {
    const results = await gqlClient.query({
      query: getListGroupManageQuery.GET_GROUP_LEADER,
      variables: {
        form
      },
      fetchPolicy: 'network-only'
    });
    if (results.errors) {
      throw results.errors;
    }

    return results.data.getGroupLeaderList;
  }
  async function updateGroupManager(form: any): Promise<any> {
    const results = await gqlClient.mutate({
      mutation: getListGroupManageQuery.UPDATE_GROUP_MANAGER,
      variables: {
        form
      },
    });
    if (results.errors) {
      throw results.errors;
    }

    return results;
  }
  async function insertGroup(form: any): Promise<any> {
    const results = await gqlClient.mutate({
      mutation: getListGroupManageQuery.INSERT_GROUP,
      variables: {
        form
      },
    });
    if (results.errors) {
      throw results.errors;
    }

    return results;
  }
  async function insertGroupManager(form: any): Promise<any> {
    const results = await gqlClient.mutate({
      mutation: getListGroupManageQuery.INSERT_GROUP_MANAGER,
      variables: {
        form
      },
    });
    if (results.errors) {
      throw results.errors;
    }

    return results;
  }
  async function removeGroupManager(form: any): Promise<any> {
    const results = await gqlClient.mutate({
      mutation: getListGroupManageQuery.DELETE_GROUP_MANAGER,
      variables: {
        groupId: form.groupId
      },
    });
    if (results.errors) {
      throw results.errors;
    }

    return results;
  }
  async function updateGroup(id: string, form: any): Promise<any> {
    const results = await gqlClient.mutate({
      mutation: getListGroupManageQuery.UPDATE_GROUP,
      variables: {
        id,
        form
      },
    });
    if (results.errors) {
      throw results.errors;
    }

    return results;
  }
  async function getListFilter(): Promise<any> {
    const results = await gqlClient.query({
      query: getListGroupManageQuery.GET_LIST_GROUP_FILTER,
    });
    if (results.errors) {
      throw results.errors;
    }

    return results.data;
  }

  return {
    ...CRUDGqlService<any>(GroupManage, getListGroupManageQuery),
    groupManageGetById,
    getGroupManageList,
    getGroupLeader,
    updateGroupManager,
    insertGroup,
    insertGroupManager,
    updateGroup,
    removeGroupManager,
    getListFilter
  };
}
