import { userRegisterFormDefault, IRole } from '.';
import { Gender, IAddress, StatusCode} from './Common';
import { IStation } from './Station';

export interface IDealerForm {
  email?: string;
  phoneNumber?: string;
  dob?: Date;
  gender?: Gender;
  address?: IAddress;
  description?: string;
  password?: string;
  name: string;
  roleId?: string;
  groupId?: string;
  avatar: string;
  status?: StatusCode;
  subDealer?: string;
  role?: IRole;
  group?: any;
  branch?: any;
  branchId?: string;
  province?: string;
  companyName?: string;
  bankName?: string;
  bankCode?: string;
  bankBranch?: string;
  accountName?: string;
  accountNumber?: string;
  proshopType?: any;
  businessType?: any;
  potentialType?: any;
}
export interface ISubDealerForm {
  phoneNumber?: string;
  address?: IAddress;
  name: string;
  dealerCode: string;
  ownerName: string;
  businessLicense?: string;
  houseHoldLicense?: string;
  identity?: string;
  branch?: any;
  branchId?: string;
  province?: string;
  bankName?: string;
  bankBranch?: string;
  accountName?: string;
  accountNumber?: string;
}
export interface ISubDealer extends ISubDealerForm {
  phoneNumber?: string;
  address?: IAddress;
  name: string;
  dealerCode: string;
  ownerName: string;
  businessLicense?: string;
  houseHoldLicense?: string;
  identity?: string;
  branch?: any;
  branchId?: string;
  province?: string;
  bankName?: string;
  bankBranch?: string;
  accountName?: string;
  accountNumber?: string;
}
export const subDealerFormDefault = (): ISubDealerForm => {
  return {
    phoneNumber: '',
    name: '',
    dealerCode: '',
    ownerName: '',
    address: null,
    businessLicense: '',
    houseHoldLicense: '',
    identity: '',
    bankName: '',
    province: '',
    accountName: '',
    accountNumber: '',
    bankBranch: '',
  };
};

export interface IDealerStaff extends IDealerStaffForm {
  id?: string;
  email?: string;
  phoneNumber: string;
  name: string;
  address?: IAddress;
  shopId: string;
  createdBy: string;
}
export interface IDealerStaffForm {
  email?: string;
  phoneNumber: string;
  name: string;
  address?: IAddress;
  shopId: string;
  createdBy: string;
}
export const dealerStaffFormDefault = (): IDealerStaffForm => {
  return {
    email: '',
    phoneNumber: null,
    name: '',
    address: null,
    shopId: '',
    createdBy: '',
  };
};
export interface IDealerShopForm {
  email?: string;
  address?: IAddress;
  name: string;
  shopCode: string;
}
export const dealerShopFormDefault = (): IDealerShopForm => {
  return {
    email: '',
    address: null,
    name: '',
    shopCode: ''
  };
};
export interface IDealerUpgradeForm {
  action?: string;
  timeToUpgrade?: Date;
  reason?: string;
  requestId?: string;
  loginId?: string;
}
export const dealerUpgradeForm = (): IDealerUpgradeForm => {
  return {
    action: '',
    timeToUpgrade: null,
    reason: '',
    requestId: null,
    loginId: null
  };
};
export const dealerFormDefault = (): IDealerForm => {
  return {
    // tslint:disable-next-line:max-line-length
    avatar: 'https://firebasestorage.googleapis.com/v0/b/daikin-sale-dev.appspot.com/o/system_users%2F1615950779372128x128.png?alt=media&token=97ff667c-2f18-4c63-9025-eeb15cb13b04',
    email: '',
    dob: null,
    gender: null,
    phoneNumber: '',
    password: '',
    name: '',
    address: null,
    description: '',
    subDealer: '',
    companyName: '',
    roleId: '',
    bankName: '',
    bankCode: '',
    accountName: '',
    accountNumber: '',
    bankBranch: '',
    proshopType: '',
    businessType: '',
    potentialType: '',
    groupId: ''
  };
};

export const dealerDefault = (): IDealer => {
  return {
    ...userRegisterFormDefault(),
    id: null,
    address: null
  };
};

export interface IDealer extends IDealerForm {
  id?: string;
  status?: StatusCode;
  role?: IRole;
  group?: any;
  branch?: any;
  roleId?: string;
  groupId?: string;
  firebaseId?: string;
  partnerId?: string;
  partner?: any;
  stationId?: string;
  station?: IStation;
  technicianCode?: string;
  supervisorId?: string;
  authenticatedTime?: string;
  address?: IAddress ;
  description?: string;
  password?: string;
  subDealer?: string;
  branchId?: string;
  companyName?: string;
  bankName?: string;
  bankCode?: string;
  bankBranch?: string;
  accountName?: string;
  accountNumber?: string;
  dealerCode?: string;
  phoneNumber?: string;
  proshopType?: any;
  businessType?: any;
  potentialType?: any;
}

export interface IDealerTypeForm {
  type?: string;
  name?: string;
  description?: string;
  alias?: string;
  status?: string;
}
export const dealerTypeFormDefault = (): IDealerTypeForm => {
  return {
    type: '',
    name: '',
    alias: '',
    description: '',
    status: ''
  };
};
