import { cloneDeep, find } from 'lodash';
import { IGroupManageState } from './mutation';

export const getters = {
  getGroupManageDetail(state: IGroupManageState) {

    return (id: string) => {

      return find(cloneDeep(state.data), (e) => e.id === id);
    };
  },
  getGroupManageNameDetail(state: IGroupManageState) {

    return (id: string) => {
      const current = state.data.find((e) => e.id === id);

      return current ? current : null;
    };
  },
};
