import { Logger as log } from 'root/services';
import { MutationType } from 'root/store';
import { Commit, Dispatch } from 'vuex';
const OneSignal = window['OneSignal'] || [];

export interface IOptionNotification {
  user?: any;
  commit?: Commit;
  dispatch?: Dispatch;
  onReceiveNotification?: (data) => void;
  onClickNotification?: (data) => void;
}

export const KeyUserIDForOneSignal = 'UserIdForOneSignal';

export async function initNotification(appId: string, options?: IOptionNotification) {
  try {
    OneSignal.push(() => {
      OneSignal.init({
        appId,
        // safari_web_id: safariWebId,
        autoResubscribe: false,
        notifyButton: {
          enable: true
        }
      }).then(() => {
        log.info('Initial onesignal');
        options.commit(MutationType.SetOneSignalInitial, true);
      }).catch((error) => {
        log.warn(error);
      });

      OneSignal.on('notificationDisplay', (notification) => {
        log.debug('notificationDisplay', notification);
        onReceiveNotification(notification, options);
      });
    });

    const listenToNotificationOpened = () => {
      OneSignal.push(['addListenerForNotificationOpened', (data) => {
        log.info('Received NotificationOpened: ', data);
        setTimeout(options.onClickNotification, 2000, data);
        listenToNotificationOpened();
      }]);
    };

    listenToNotificationOpened();
  } catch (error) {
    log.warn('init notification failed: ', error);
  }

}

export async function checkPermission(method, args) {
  try {
    if (!OneSignal) {
      return Promise.reject('OneSignal is undefined!');
    }
    for (let i = 0; i < 5; i++) {
      OneSignal[method](...args);
    }

    setTimeout(() => { OneSignal[method](...args); }, 3000);

    return Promise.resolve(true);
  } catch (error) {
    return Promise.reject(error);
  }
}
export async function setExternalUserId(data: any) {
  OneSignal.setExternalUserId(data.id);
}
export async function sendTagForNotification(data: IOptionNotification) {
  log.debug('Onesignal set tags', data.user.id.toString());
  checkPermission('sendTag', ['role', data.user.role.alias, (tagSent) => {
    log.debug('tag sent', tagSent);
  }, (error) => {
    log.debug('send tag role ERROR: ', error);
  }]).then(() => {
    // log.debug('send tag role successfully');
  }).catch((error) => {
    log.debug('send tag role failure', error);
  });
}

export async function deleteTagForNotification() {
  checkPermission('deleteTags', [['userId', 'role'], (tagsDeleted) => {
    log.debug('Delete tag success: ', tagsDeleted);
  }]).catch(() => {
    log.debug(`Onesignal delete tag failure`);
  });
  // log.debug('Onesignal logout email');
  // OneSignal.logoutEmail();
}

export function onReceiveNotification(notify, options: IOptionNotification) {
  if (notify && notify.id) {
    options.onReceiveNotification(notify);
  }

  return;
}
