import moment from 'moment';
import { SystemRoleAlias } from 'root/admin/User/Store/types';
import { IUser } from 'root/models';
import { IState, MutationType, TypeAlert } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import { DealerType, DealerTypeCode, FeedbackType,
  FeedbackTypeCode, StatusFeedback, StatusFeedbackCode } from '../ProfileFeedbackList';
import { ActionTypeFeedback } from '../Store/types';
import './styles.scss';

@Component({
  template: require('./view.html'),
  props: {
    visible: Boolean,
    data: Object,
    feedbackId: String
  },
  computed: {
    ...mapState({
      authUser: (state: IState) => state.global.authUser,
    })
  },
})

export class ManageFeedbackEditor extends Vue {
  public get isSalePlanning() {
    if (this.authUser.superAdmin || this.authUser.role.alias === SystemRoleAlias.Admin
      || this.authUser.role.alias === SystemRoleAlias.SaleAdmin) {
      return true;
    }

    return false;
  }
  public get isSalesManFeedback() {
    if (this.data && this.data.dealer && this.data.dealer.role
      && this.data.dealer.role.alias === DealerTypeCode.Staff) {
      return true;
    }

    return false;
  }
  public authUser: IUser;
  public data;
  public dealerOwnerName: string = '--';
  public dealerOwnerCode: string = '--';
  public dealerOwnerPhoneNumber: string = '--';
  public dealerOwnerBranchName: string = '--';
  public dealerOwnerBranchCode: string = '--';
  public feedbackId: string;
  public loading: boolean = false;

  public async accept(status) {
    this.$store.dispatch(ActionTypeFeedback.UPDATE_STATUS, ({
      status,
      id: this.feedbackId,
      onSuccess: () => {
        this.loading = false;
        const message = 'Update feedback successfully';
        this.$store.commit(MutationType.OpenTopAlert, {
          message,
          type: TypeAlert.Success
        });
        this.back();
      },
      onFailure: () => {
        this.loading = false;
        this.back();
      }
    }));
  }
  public formatterRole(role: any) {
    switch (role.alias) {
    case DealerTypeCode.DaikinDealer:
      return DealerType.DaikinDealer;
    case DealerTypeCode.SubDealer:
      return DealerType.SubDealer;
    case DealerTypeCode.Staff:
      return DealerType.Staff;
    }
  }
  public formatterStatus(status) {
    switch (status) {
    case StatusFeedbackCode.Waiting:
      return StatusFeedback.Waiting;
    case StatusFeedbackCode.Completed:
      return StatusFeedback.Completed;
    case StatusFeedbackCode.Rejected:
      return StatusFeedback.Rejected;
    case StatusFeedbackCode.Received:
      return StatusFeedback.Received;
    case StatusFeedbackCode.Updated:
      return StatusFeedback.Updated;
    }
  }
  public formatterType(type) {
    switch (type) {
    case FeedbackTypeCode.ChangeProfile:
      return FeedbackType.ChangeProfile;
    case FeedbackTypeCode.ServiceFeedback:
      return FeedbackType.ServiceFeedback;
    case FeedbackTypeCode.ApplicationFeedback:
      return FeedbackType.ApplicationFeedback;
    }
  }

  public formatterTime(time) {
    if (time) {
      return moment(time).format('HH:mm-DD/MM/YYYY');
    }

    return '--';
  }

  public back() {
    this.$emit('update:visible', false);
  }

  public openDialog() {
    // tslint:disable-next-line: early-exit
    if (this.data && this.data.dealer.shops.length) {
      this.$store.dispatch(ActionTypeFeedback.GetOwnerDealer, {
        shopId: this.data.dealer.shops[0].shopId,
        onSuccess: (dealer) => {
          // tslint:disable-next-line: early-exit
          if (dealer.length) {
            this.dealerOwnerName = dealer[0].dealer.name;
            this.dealerOwnerCode = dealer[0].dealer.dealerCode;
            this.dealerOwnerPhoneNumber = dealer[0].dealer.phoneNumber;
            this.dealerOwnerBranchName = dealer[0].dealer && dealer[0].dealer.branch &&
            dealer[0].dealer.branch.name ? dealer[0].dealer.branch.name : '--';
            this.dealerOwnerBranchCode = dealer[0].dealer && dealer[0].dealer.branch &&
            dealer[0].dealer.branch.branchCode ? dealer[0].dealer.branch.branchCode : '--';
          }
        }
      });
    }
  }
  public closeDialog() {
    this.$emit('update:visible', false);
    this.dealerOwnerName = '--';
    this.dealerOwnerCode = '--';
  }

}
