import { TabPane } from 'element-ui';
import { CustomCampaignRouterName, CustomCampaignTab } from 'root/admin/CustomCampaign';
import { SystemRoleAlias } from 'root/admin/User/Store/types';
import { IPaginationParams } from 'root/api/graphql/Core';
import { FormatDateFullTime } from 'root/helpers';
import { IUser } from 'root/models';
import { IBreadcrumb, IState, MutationType } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import { ActionTypeAclCampaign, MutationTypeAclCampaign } from '../../Store/types';

@Component({
  template: require('./view.html'),
  computed: {
    ...mapState({
      data: (state: IState) => state.aclCampaign.manageExport,
      pagination: (state: IState) => state.aclCampaign.pagination,
      loading: (state: IState) => state.aclCampaign.loading,
      authUser: (state: IState) => state.global.authUser,
    })
  },
})

export class ManageExport extends Vue {
  public get activeTab() {
    return CustomCampaignTab.ManageExport;
  }

  public set activeTab(_value: string) {
    //
  }
  public get isSalePlanning() {
    if (this.authUser.superAdmin || this.authUser.role.alias === SystemRoleAlias.Admin
      || this.authUser.role.alias === SystemRoleAlias.SaleAdmin) {
      return true;
    }

    return false;
  }

  public get columns() {
    return [
      {
        prop: 'createdUser',
        label: 'REQUESTED BY',
        width: 300,
        formatter: (model: any) => {
          return model.createdUser ? model.createdUser.name : '--';
        },
      },
      {
        prop: 'createdAt',
        label: 'REQUESTED AT',
        width: 300,
        formatter: (model: any) => {
          return FormatDateFullTime(model.createdAt) || '--';
        },
      },
      {
        prop: 'status',
        label: 'STATUS',
        width: 300,
        formatter: (model: any) => {
          return model.status ? model.status : '--';
        },
        labelStyle: (model: any) => {
          return model.status;
        },
      },
      {
        prop: 'exportStatus',
        label: '',
        width: 300,
        formatter: (model: any) => {
          return model.exportStatus === 'done' ? 'Ready to download' : 'Not ready';
        },
      }
    ];

  }
  public authUser: IUser;
  public pagination: IPaginationParams;
  public changeTab(tab: TabPane) {
    switch (tab.name) {
    case CustomCampaignTab.Participant:
      this.$router.push({
        name: CustomCampaignRouterName.Participant
      });
      break;
    case CustomCampaignTab.Configuration:
      this.$router.push({
        name: CustomCampaignRouterName.Configuration
      });
      break;
    case CustomCampaignTab.Statistic:
      this.$router.push({
        name: CustomCampaignRouterName.Statistic
      });
      break;
    case CustomCampaignTab.QualifiedShops:
      this.$router.push({
        name: CustomCampaignRouterName.QualifiedShops
      });
      break;
    case CustomCampaignTab.Shops:
      this.$router.push({
        name: CustomCampaignRouterName.Shops
      });
      break;
    default:
      break;
    }
  }
  public reloadListImport() {
    this.fetchData();
  }
  public handlePaginationChange(value) {
    if (typeof value === 'number') {
      this.$store.commit(MutationTypeAclCampaign.PaginationChange, {
        ...this.pagination,
        size: value
      });
    } else {
      this.$store.commit(MutationTypeAclCampaign.PaginationChange, {
        ...this.pagination,
        ...value
      });
    }
    this.fetchData();
  }
  protected mounted() {
    this.$nextTick(() => {
      const breadcrumb: IBreadcrumb[] = [
        {
          label: 'title.campaign',
          path: null,
          current: false
        },
        {
          label: 'title.acl_campaign',
          path: null,
          current: true
        }
      ];
      this.$store.commit(MutationType.SetBreadcrumb, breadcrumb);

      this.fetchData();
    });
  }
  private fetchData() {

    this.$store.dispatch(ActionTypeAclCampaign.GetManageExport, {
      pagination: this.pagination
    });
  }
}
