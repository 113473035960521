export enum ActionTypeAclCampaign {
  FilterNoCache = 'evaluatePhotoFilterNoCache',
  GetAclCampaign = 'getAclCampaign',
  GetAclCampaignDetail = 'getAclCampaignDetail',
  DownloadSample = 'downloadSample',
  ApproveShop = 'approveShop',
  RequestReup = 'requestReup',
  GetShopDetail= 'getShopDetail',
  SubmitReview = 'submitReview',
  GetPackage = 'getPackage',
  UpdatePackage = 'updatePackage',
  GetPdfExportData = 'getPdfExportData',
  GetCampaignConfiguration = 'getCampaignConfiguration',
  GetMaskConfig= 'getMaskConfig',
  EndCampaign = 'endCampaign',
  MaskChange = 'maskChange',
  GetManageExport = 'getManageExport',
  GetStatistic = 'getStatistic',
  ExportStatistic = 'exportStatistic',
  GetQualifiedShops = 'getQualifiedShops',
  ApproveAll = 'approveAll',
  GetDealerByBranch = 'getDealerByBranch',
  GetJoinedShop = 'getJoinedShop',
  GetConfigCropPicture = 'GetConfigCropPicture',
}
export enum MutationTypeAclCampaign {
  SetAclCompaign = 'setDataAclCompaign',
  SetAclManageExport = 'setDataAclManageExport',
  SetAclCompaignDetail = 'setDataAclCompaignDetail',
  AclCampaignLoading = 'acl_campaignsLoading',
  AclCampaignLoaded = 'acl_campaignsLoaded',
  SetPagination= 'acl_campaignsSetPagination',
  PaginationChange = 'acl_campaignsPaginationChange',
  SetQualifiedList= 'setQualifiedList',
  SetJoinedShopList = 'setJoinedShopList'
}
