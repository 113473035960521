import { cloneDeep, find } from 'lodash';
import { IInvoiceState } from './mutation';

export const getters = {
  getInvoiceDetail(state: IInvoiceState) {
    return (id: string) => {
      return find(cloneDeep(state.data), (e) => e.id === id);
    };
  }
};
