import { fullNameUser } from 'root/helpers';
import Vue from 'vue';
import Component from 'vue-class-component';
import './styles.scss';

@Component({
  template: require('./view.html'),
  props: {
    user: {
      type: Object,
      default: () => {
        return {
          name: '',
          avatar: ''
        };
      }
    },
    showPhone: Boolean,
    showFullname: {
      type: Boolean,
      default: true
    },
    showEmail: {
      type: Boolean,
      default: false,
    },
    size: String,
    vertical: String,
    total: {
      type: Number,
      default: undefined
    }
  }
})
export class Avatar extends Vue {
  public fullNameUser = fullNameUser;
}
