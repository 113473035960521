export interface INotificationUser {
  id: string;
  name: string;
}
export interface INotiMetadata {
  filter: any;
}

export interface ITargetDetail {
  id: string;
  receiverIds: string[] | string;
  referenceId: string;
}
export interface INotification {
  subtitle?: string;
  id: string;
  createdAt: string;
  updatedAt: string;
  title: string;
  createdUser: INotificationUser;
  updatedUser: INotificationUser;
  target: string | string[];
  scheduledAt: string;
  dealerId?: string | string[];
  dealerIds?: string[];
  typeDealers?: string[];
  notificationStatus: string;
  image: string;
  url: string;
  description: string;
  typeDealer: string | string[];
  targetDetails?: Array<{
    id: string;
    email: string;
  }>;
  metadata: INotiMetadata;
}

export interface INotificationForm extends INotification {
  // receiverId: string[];
  receiverId: string | string[];
  metadata: INotiMetadata;
}

export const defaultNotification = (): INotification => {
  return {
    subtitle: '',
    id: '',
    updatedAt: '',
    createdAt: '',
    title: '',
    target: '',
    scheduledAt: '',
    createdUser: {
      id: '',
      name: ''
    },
    updatedUser: {
      id: '',
      name: ''
    },
    notificationStatus: '',
    image: '',
    url: '',
    description: '',
    typeDealer: '',
    metadata: {
      filter: {
        typeDealers: ' '
      }
    },
  };
};

export const defaultNotificationForm = (): INotificationForm => {
  return {
    ...defaultNotification(),
    // receiverId: []
    receiverId: '',
    metadata: {
      filter: {
        typeDealers: ' '
      }
    },
  };
};
