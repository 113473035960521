import { gqlClient } from '../Core';
import { CRUDGqlService, ICRUDGqlService } from '../Core/crud';
import { accountStaff, accountStaffListQuery } from '../Query/accountStaff';

export interface IAccountStaffGqlService extends ICRUDGqlService<any> {
  //
  getListAccountStaff(id: string[], search: string, status: string[], limit: number, offset: number): Promise<any>;
  getShopId(id: string): Promise<any>;
  createStaff(form: any): Promise<any>;
  getStaffDetail(id: string): Promise<any>;
}
export function accountStaffGqlService(): IAccountStaffGqlService {
  async function getShopId(id: string): Promise<any> {
    const results = await gqlClient.query({
      query: accountStaffListQuery.GET_SHOP_ID,
      variables: {
        id
      },
      fetchPolicy: 'network-only'
    });
    if (results.errors) {
      throw results.errors;
    }

    return results.data.dealer_shops;
  }

  async function getListAccountStaff(ids: string[], search: string, status: string[],
                                     limit: number, offset: number): Promise<any> {
    const results = await gqlClient.query({
      query: accountStaffListQuery.GET_ACCOUNT_STAFF_LIST,
      variables: {
        ids,
        search,
        status,
        limit,
        offset
      },
      fetchPolicy: 'network-only'
    });
    if (results.errors) {
      throw results.errors;
    }

    return results.data;
  }
  async function createStaff(form: any): Promise<any> {
    const results = await gqlClient.mutate({
      mutation: accountStaffListQuery.CREATE_STAFF,
      variables: {
        form
      }
    });
    if (results.errors) {
      throw results.errors;
    }

    return results;
  }

  async function getStaffDetail(id: string): Promise<any> {
    const results = await gqlClient.query({
      query: accountStaffListQuery.GET_STAFF_DETAIL,
      variables: {
        id
      },
      fetchPolicy: 'network-only'
    });
    if (results.errors) {
      throw results.errors;
    }

    return results.data.dealers[0];
  }

  return {
    ...CRUDGqlService<any>(accountStaff, accountStaffListQuery),
    getListAccountStaff,
    getShopId,
    createStaff,
    getStaffDetail
  };
}
