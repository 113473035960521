// import { MutationType, TypeAlert } from 'root/store';
import { MutationType, TypeAlert } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { ActionTypeZeroCampaign } from '../../Store/types';
import './styles.scss';
@Component({
  template: require('./view.html'),
  props: {
    visible: Boolean,
    campaignCompany: Object,
    campaignDetail: Object
  },
})
export class ForceCloseCode extends Vue {
  public loadingSubmit: boolean = false;
  public reason: string = '';
  public referenceId: string = '';
  public campaignDetail: any;
  public campaignCompany: any;
  public async openDialog() {
    this.$store.dispatch(ActionTypeZeroCampaign.GetConfig, {
      onSuccess: (config) => {
        if (config && config.length) {
          this.referenceId = config[0].id;
        }
      }
    });
  }
  public submitUpdate() {
    this.loadingSubmit = true;
    this.$store.dispatch(ActionTypeZeroCampaign.ForceCloseCode, {
      form: {
        campaignCompanyId: this.$props.campaignCompany.id,
        referenceId: this.referenceId,
        reasons: this.$props.campaignCompany &&
        this.$props.campaignCompany.companyStatus === 'have_code' && this.reason ?
        this.reason : this.$props.campaignCompany && this.$props.campaignCompany.reasons ?
        this.$props.campaignCompany.reasons : '',
      },
      onSuccess: () => {
        const message = 'Force close code successfully';
        this.$store.commit(MutationType.OpenTopAlert, {
          message,
          type: TypeAlert.Success,
        });
        this.loadingSubmit = false;
        this.closeDialog();
      }
    });
  }
  public closeDialog() {
    this.reason = '';
    this.$emit('closeDialog');
  }
}
