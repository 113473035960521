import { DocumentNode } from 'graphql';
import gql from 'graphql-tag';
import { crudQuery, ICRUDQuery } from '../Core/query';

const branchManagerModel = `
data {
branchManagers {
  id
  name
  role {
    alias
    name
  }
}
createdUser {
  id
  name
}
address {
  address
  district
  province
  ward
}
branchCode
description
groups {
  address
  description
  id
  name
}
region {
  id
  name
}
updatedUser {
  id
  name
}
id
name
status
}
pagination {
  page
  size
  total
  totalPages
  totalPerPage
  nextPageToken
}
`;
const branchManagerDetailModel = `
branchManagers {
  id
  name
}
address {
  address
  district
  province
  ward
}
region {
  id
  name
}
createdAt
updatedAt
branchCode
description
groups {
  address
  description
  id
  name
}
createdUser {
  id
  name
}
updatedUser {
  id
  name
}
id
name
status
`;
const managerModel = `
data {
  avatar
  createdAt
  email
  gender
  groups {
    id
    name
  }
  group {
    id
    name
  }
  id
  name
  phoneNumber
  status
}
pagination {
  nextPageToken
  page
  size
  total
  totalPages
  totalPerPage
}
`;

export const BranchManage = 'branch_manager';
// tslint:disable-next-line:no-empty-interface
interface IBranchManageQuery extends ICRUDQuery {
  //
  GET_BY_ID: DocumentNode;
  GET_LIST_BRANCH_MANAGER: DocumentNode;
  GET_MANAGERS: DocumentNode;
  UPDATE_BRANCH_MANAGER: DocumentNode;
  UPDATE_STATUS: DocumentNode;
  INSERT_BRANCH: DocumentNode;
  INSERT_BRANCH_MANAGER: DocumentNode;
  INSERT_INVOICE: DocumentNode;
  UPDATE_BRANCH: DocumentNode;
  DELETE_BRANCH_MANAGER: DocumentNode;
}
export const getListBranchManageQuery: IBranchManageQuery = {
  ...crudQuery(BranchManage, branchManagerModel),
  GET_BY_ID: gql`
  query GET_BY_ID($id: String!){
    getBranchDetail(form: {id: $id}){
      ${branchManagerDetailModel}
    }
  }
`,
  GET_LIST_BRANCH_MANAGER: gql`
  query GET_LIST_BRANCH_MANAGER($form: FilterBranchInput!) {
    getBranchList(form: $form){
      ${branchManagerModel}
    }
  }
`,
  GET_MANAGERS: gql`
  query GET_MANAGERS($form: FilterBranchInput!){
    getBranchManagerList(form: $form){
      ${managerModel}
    }
  }
`,
  UPDATE_BRANCH_MANAGER: gql`
  mutation UPDATE_BRANCH_MANAGER($form: FormUpdateBranchManagers){
    updateBranchManagers(form: $form){
      id
    }
  }
`,
  INSERT_BRANCH: gql`
  mutation INSERT_BRANCH($form: [branches_insert_input!]!){
    insert_branches(objects: $form){
      affected_rows
      returning {
        id
      }
    }
  }
`,
  INSERT_BRANCH_MANAGER: gql`
  mutation INSERT_BRANCH_MANAGER($form: [branch_manager_insert_input!]!){
    insert_branch_manager(objects: $form){
      affected_rows
    }
  }
`,
  INSERT_INVOICE: gql `
  mutation INSERT_INVOICE($form: [import_invoices_insert_input!]!){
    insert_import_invoices(objects: $form) {
      affected_rows
      returning {
        id
      }
    }
  }
`,
  UPDATE_STATUS: gql`
  mutation UPDATE_STATUS($id: uuid!, $set: branch_manager_set_input!){
    update_branch_manager(where: {branchId:{_eq: $id}}, _set:$set){
      affected_rows
    }
  }
  `,
  UPDATE_BRANCH: gql`
  mutation UPDATE_BRANCH($id: uuid!, $set: branches_set_input!){
    update_branches(where: {id: {_eq: $id}}, _set: $set){
      affected_rows
    }
  }
  `,
  DELETE_BRANCH_MANAGER: gql`
  mutation DELETE_BRANCH_MANAGER($branchId: uuid!){
    delete_branch_manager(where: {branchId: {_eq: $branchId}}){
      affected_rows
    }
  }
`
};
