import { DocumentNode } from 'graphql';
import gql from 'graphql-tag';
import { crudQuery, ICRUDQuery } from '../Core/query';

const productTransModel = `
branchId
dealerId
dealer {
  id
  name
}
groupId
id
transactionStatus
createdAt
createdUser {
  id
  name
}
dealer {
  name
  id
}
dealerId
formerDealer {
  id
  name
}
formerDealerId
productId
product {
  image
  name
  id
  serialNumber
  productCode
  modelNumber
  deviceType {
    id
    name
  }
}
`;
const groupView = `
branchId
dealerId
groupId
id
transactionStatus
createdAt
dealerId
formerDealerId
productId
deviceType {
    id
    name
  }
formerDealerName
productGroupId
productCode
serialNumber
modelNumber
productName
productBranchId
dealerName
`;
const groupViewHistory = `
branchId
dealerId
groupId
id
transactionStatus
createdAt
dealerId
formerDealerId
productId
formerDealerName
productGroupId
productCode
serialNumber
modelNumber
productName
productBranchId
dealerName
`;

export const ProductTrans = 'product_transactions';
// tslint:disable-next-line:no-empty-interface
interface IProductTransQuery extends ICRUDQuery {
  GET_PRODUCT_TRANS_LIST: DocumentNode;
  GET_BY_ID_PRODUCT: DocumentNode;
  GET_GROUP_VIEW: DocumentNode;
  GET_VIEW_BY_ID: DocumentNode;
}
export const productTransListQuery: IProductTransQuery = {
  ...crudQuery(ProductTrans, productTransModel),
  GET_PRODUCT_TRANS_LIST: gql`
  query GET_PRODUCT_TRANS_LIST {
    product_transactions{
      ${productTransModel}
    }
  }
`,
  GET_BY_ID_PRODUCT: gql`
  query GET_BY_ID_PRODUCT($id:uuid!){
    product_transactions(where: { productId: {_eq: $id}}, order_by: {createdAt: desc}){
      ${productTransModel}
    }
  }
  `,
  GET_GROUP_VIEW: gql`
  query GET_GROUP_VIEW($where: product_transaction_leader_view_bool_exp) {
    product_transaction_leader_view(where:$where){
      ${groupView}
    }
  }
`,
  GET_VIEW_BY_ID: gql`
  query GET_VIEW_BY_ID($id:uuid!){
    product_history_leader_view(where: { productId: {_eq: $id}}, order_by: {createdAt: desc}){
      ${groupViewHistory}
    }
  }
  `
};
