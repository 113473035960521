import './styles.scss';

// import moment from 'moment';
import { stripObject } from '@hgiasac/helper';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import { ActionTypeDeviceType } from 'root/admin/DeviceType/Store/types';
import { IProductState } from 'root/admin/Product/Store';
import { ActionTypeProduct, MutationTypeProduct } from 'root/admin/Product/Store/types';
import { IPaginationParams } from 'root/api/graphql/Core';
import { Avatar } from 'root/components/Avatar';
import { fullNameUser } from 'root/helpers';
import { IBreadcrumb, IFilterInput, StatusCode } from 'root/models';
import { IUser } from 'root/models/User';
import { IState, MutationType } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import { BranchAndGroupRouterPath } from '../..';
import { DealerRouterName } from '../../../Dealer';

const enum FilterKey {
  DeviceType = 'deviceTypeId'
}

@Component({
  template: require('./view.html'),
  components: {
    avatar: Avatar,
    // 'user-detail-modal': UserDetailModal,
  },
  computed: {
    ...mapState({
      // authUser: (state: IState) => state.global.authUser,
      data: (state: IState) => state.product.data,
      pagination: (state: IState) => state.product.pagination,
      loading: (state: IState) => state.product.loading,
      // filterParams: (state: IState) => state.product.filterParams,
      deviceType: (state: IState) => state.deviceType.data,
      isRefesh: (state: IState) => state.global.isRefesh
    }),
    selectedStatus() {
      const filterParams = [];

      if (!filterParams) {
        return [];
      }

      return [{
        key: 'isCreatedByAppleReviewer',
        value: []
      }];
    }
  },
  watch: {
    type() {
      this.paramsFilter = {
        text: ''
      };
      // this.$store.commit(MutationTypeUser.ClearUserList);
      this.$store.commit(MutationType.SetBreadcrumb, this.$t(this.type));
      this.fetchData();
    }
  },
})

export class GroupInventoryList extends Vue {
  public get serviceId(): string {
    return this.$route.params.id;
  }
  public get columns() {
    return [
      {
        prop: 'deviceName',
        label: 'Device Name',
        width: 180,
        formatter: (model: any) => {
          if (model.name) {
            return model.name || '--';

          }
        }
      },
      {
        prop: 'serialNumber',
        label: 'Serial Number',
        width: 180,
        formatter: (model: any) => {
          return model.serialNumber || '--';
        }
      },
      {
        prop: 'modelNumber',
        label: 'Model Number',
        width: 180,
        formatter: (model: any) => {
          return model.modelNumber || '--';
        }
      },
      {
        prop: 'deviceType',
        label: 'Device Type',
        width: 180,
        formatter: (model: any) => {
          if (model.deviceType && model.deviceType.name) {
            return model.deviceType.name;

          }

          return '--';
        }
      },
      {
        prop: 'createdBy',
        label: 'Created By',
        width: 180,
        formatter: (model: any) => {
          if (model) {
            return model.createdUser ? model.createdUser.name : model.createdDealer ?
            model.createdDealer.name : '--';
          }

          return '--';
        }
      },
      {
        prop: 'createdAt',
        label: 'Created At',
        formatter: (model: any) => {
          return moment(model.createdAt).format('DD/MM/YYYY') || '--';
        },
        width: 180
      },
    ];

  }

  public authUser: IUser;
  public pagination: IPaginationParams;
  public data: IProductState[];
  public deviceType: any[];
  public visible: boolean = false;
  public isSample: boolean = false;
  public userId: string = '';
  public visibleDetail: boolean = false;
  public popupVisible: boolean = false;
  public filterParams = {
    [FilterKey.DeviceType]: [],
  };
  public searchText: string = '';
  public isRefesh: boolean;

  public get inputSearchPlaceholder(): string {

    return `Search by Name, Email`;
  }

  public UserType = {
    Customer: 'customer',
    System: 'system',
  };
  public type: any;
  // tslint:disable-next-line:max-line-length
  private sampleUrl = `https://firebasestorage.googleapis.com/v0/b/daikin-test-staging.appspot.com/o/technicians%2Ftechnicians-import-sample.xlsx?alt=media&token=279661a1-11fe-49a0-8109-364e964ec11b`;

  public handleSearch() {
    this.$store.commit(MutationTypeProduct.PaginationChange, {
      ...this.pagination,
      page: 1
    });
    this.fetchData();
  }

  public closeDialogDetail() {
    this.userId = '';
    this.visibleDetail = false;
  }

  public createSuccess() {
    this.visible = false;
    this.fetchData();
  }

  public applyFilter() {
    this.fetchData();
  }
  public goAddNewRouter() {
    this.visible = true;
    this.userId = '';
  }
  public closeDialog() {
    this.isSample = false;
    this.fetchData();
  }
  public downloadSample() {
    window.open(
      this.sampleUrl,
      '_blank'
    );
  }
  public handleDelete(model: any) {
    this.$store.dispatch(ActionTypeProduct.UpdateStatusProduct, {
      id: model.id,
      status: StatusCode.Deleted,
      onSuccess: () => {
        this.fetchData();
        model.instance.confirmButtonLoading = false;
        model.instance.confirmButtonText = 'Confirm';
        model.done();
      },
      onFailure: () => {
        model.instance.confirmButtonLoading = false;
        model.instance.confirmButtonText = 'Confirm';
      }
    });
  }
  public handleDisabled(model: any) {
    this.$store.dispatch(ActionTypeProduct.UpdateStatusProduct, {
      id: model.id,
      status: StatusCode.Blocked,
      onSuccess: () => {
        this.fetchData();
        model.instance.confirmButtonLoading = false;
        model.instance.confirmButtonText = 'Confirm';
        model.done();
      },
      onFailure: () => {
        model.instance.confirmButtonLoading = false;
        model.instance.confirmButtonText = 'Confirm';
      }
    });
  }

  public clickDetail(id: string) {
    this.$router.push({
      name: DealerRouterName.DetailDealer,
      params: {
        id
      }});
  }
  public handleUpdate(id: string) {
    this.visible = true;
    this.userId = id;
  }
  public get dataFilter() {
    const _deviceType: IFilterInput[] = this.deviceType.reduce((res, item) => {
      res.push({
        key: item.id,
        value: item.id,
        name: item.name
      });

      return res;
    }, []);

    return [
      {
        key: FilterKey.DeviceType,
        name: this.$t('field.deviceType'),
        value: _deviceType
      },
    ];
  }
  public get selectedFilter() {
    const _filterParams = cloneDeep(this.filterParams);

    return [
      {
        key: FilterKey.DeviceType,
        value: _filterParams[FilterKey.DeviceType]
      },
    ];
  }

  public changeFilter(model) {
    const deviceType = model.find((e) => e.key === FilterKey.DeviceType);
    const nextFilter = {
      [FilterKey.DeviceType]: deviceType ? deviceType.value : [],
    };
    this.filterParams = {
      ...cloneDeep(this.filterParams),
      ...nextFilter
    };
    this.fetchData();
  }
  public handlePaginationChange(value) {
    if (typeof value === 'number') {
      this.$store.commit(MutationTypeProduct.PaginationChange, {
        ...this.pagination,
        size: value
      });
    } else {
      this.$store.commit(MutationTypeProduct.PaginationChange, {
        ...this.pagination,
        ...value
      });
    }
    this.fetchData();
  }

  public formatFullNameUser(model) {
    return fullNameUser(model);
  }

  protected mounted() {
    this.$nextTick(() => {
      const breadcrumb: IBreadcrumb[] = [
        {
          label: 'title.managedepartment',
          path: BranchAndGroupRouterPath.ListGroupManage,
          current: false
        },
        {
          label: 'title.view_details',
          path: null,
          current: true
        }
      ];
      this.$store.commit(MutationType.SetBack);
      this.$store.commit(MutationType.SetBreadcrumb, breadcrumb);
      // this.$store.commit(MutationTypeUser.PaginationReset);
      if (!this.isRefesh) {
        this.fetchData();

      }
    });
  }
  protected beforeDestroy() {
    this.$store.commit(MutationType.ClearBreadcrumb);
  }

  private fetchData() {
    const text = cloneDeep(this.searchText);

    this.$store.commit(MutationTypeProduct.FilterChange, stripObject({
      [FilterKey.DeviceType]: this.filterParams[FilterKey.DeviceType],
      groupId: this.serviceId,
      _and: text.trim() !== '' ? {
        mode: 'special',
        data: {
          _or: [
            {
              name: {
                _like: `%${text}%`
              }
            },
            {
              modelNumber: {
                _like: `%${text}%`
              }
            },
            {
              serialNumber: {
                _like: `%${text}%`
              }
            }
          ]
        }
      } : null
    }));
    this.$store.dispatch(ActionTypeProduct.FilterNoCache);
    this.$store.dispatch(ActionTypeDeviceType.FilterNoCache);
  }
}
