export enum ActionTypeFeedback {
  GET_LIST_FEEDBACK = 'getListFeedback',
  FilterNoCache = 'feedbackFilterNoCache',
  UPDATE_STATUS = 'updateStatus',
  GetOwnerDealer = 'getOwnerDealer'
}
export enum MutationTypeFeedback {
  GetListFeedback = 'feedbackGetList',
  FeedbackPaginationChange = 'feedbackPaginationChange',
  FilterChange = 'feedbackFilterChange',
  FeedbackLoading = 'feedbackLoading',
  FeedbackLoaded = 'feedbackLoaded',
}
