import { DKGqlClient } from 'root/api/graphql/Client';
import { ActionType, IState } from 'root/store';
import { crudActions } from 'root/store/helpers';
import { ActionContext } from 'vuex';
import { IManageImportState } from './mutation';
import { ActionTypeImport, MutationTypeImport } from './types';

const { manageImport } = DKGqlClient();
export const actions = {
  ...crudActions('import_dealers', {
    filter: manageImport.filter,
  }),
  async [ActionTypeImport.GET_LIST_IMPORT]({
    commit, dispatch
  }: ActionContext<IManageImportState, IState>) {
    try {
      commit(MutationTypeImport.ManageImportLoading);
      const ImportList = await manageImport.getListImport();
      commit(MutationTypeImport.GetListImport, ImportList);
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    } finally {
      commit(MutationTypeImport.ManageImportLoaded);
    }
  },
  async [ActionTypeImport.ReIndexImport]({
     dispatch
  }: ActionContext<IManageImportState, IState>,
                                         {id, reIndex}) {
    try {
      const ProductList = await manageImport.updateReIndex(id, reIndex);
      if (ProductList) {
        //
      }
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    }
  },
  async [ActionTypeImport.GET_LIST_IMPORT_SUB]({
    commit, dispatch
  }: ActionContext<IManageImportState, IState>,
                                               {pagination}) {
    try {
      commit(MutationTypeImport.ManageImportLoading);
      const { page, limit } = pagination;
      const offset = (page - 1) * limit;
      const ImportList = await manageImport.getListImportSub(limit, offset);
      commit(MutationTypeImport.SetImportSubDealerList, ImportList);
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    } finally {
      commit(MutationTypeImport.ManageImportLoaded);
    }
  },
  async [ActionTypeImport.ReIndexImportSubDealer]({
     dispatch
  }: ActionContext<IManageImportState, IState>,
                                                  {id, reIndex}) {
    try {
      const ProductList = await manageImport.updateReIndexSub(id, reIndex);
      if (ProductList) {
        //
      }
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    }
  },
};
