export enum ActionTypeImport {
  GET_LIST_IMPORT = 'getListImport',
  FilterNoCache = 'import_dealersFilterNoCache',
  ReIndexImport = 'reIndexImport',
  GET_LIST_IMPORT_SUB = 'getListImportSubDealer',
  ReIndexImportSubDealer = 'reIndexImportSubDealer',
}
export enum MutationTypeImport {
  GetListImport = 'import_dealersGetList',
  ManageImportPaginationChange = 'import_dealersPaginationChange',
  ManageImportLoading = 'import_dealersLoading',
  ManageImportLoaded = 'import_dealersLoaded',
  SetImportSubDealerList = 'setImportSubDealerList'
}
