import { get } from 'lodash';
import { IPaginationParams } from 'root/api/graphql/Core';
import { Avatar } from 'root/components';
import { Upload } from 'root/components/Upload';
import { formatBirthday, resetOrientation, FormatDateFullTime } from 'root/helpers';
import { IAddress } from 'root/models';
import { GroupSystemUserType, IUser, UserType } from 'root/models/User';
import { pathUpload, sizeImage, uploadToFirebase } from 'root/services';
import { ActionType, IBreadcrumb, IState, MutationType } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import { mapState } from 'vuex';
import { ChangePasswordDialog } from '../Components/ChangePasswordDialog';
import {ActionTypeUser} from '../Store/types';
import { UserEditor } from '../UserEditor';
import { UserRouterName, UserRouterPath } from '../index';
import './styles.scss';

const enum CustomerDetailTab {
  BookingHistory = 'booking_history',
  ContactList = 'contact_list'
}

const enum TechnicianDetailTab {
  SkillList = 'skill_list',
  PersonalFile = 'personal_file',
  TechnicianBonus = 'technician_bonus'
}

@Component({
  template: require('./view.html'),
  components: {
    Upload,
    avatar: Avatar,
    editor: UserEditor,
    'change-password-dialog': ChangePasswordDialog
  },
  computed: {
    ...mapState({
      authUser: (state: IState) => state.global.authUser,
      pagination: (state: IState) => state.user.pagination,
      route: (state: IState) => state.route,
    })
  },
  watch: {
    userId(newValue) {
      if (newValue) {
        // this.$store.dispatch(ActionTypeUser.GetUserById, {
        //   id: newValue,
        //   userType: this.userType,
        //   onSuccess: () => {
        //     //
        //   },
        //   onFailure: () => {
        //     //
        //   }
        // });
      }

      return;
    }
  }
})

export class UserDetail extends Vue {
  public $refs: {
    // booking: BookingList,
    // contact: ContactList
  };
  public authUser: IUser;
  public formatBirthday = formatBirthday;
  public FormatDateFullTime = FormatDateFullTime;
  public route: any;
  public form = {
    avatar: null
  };
  public fileUpload: File = null;
  public loading: boolean = false;
  public visible: boolean = false;
  public activeTab: string = '';
  public pagination: IPaginationParams;
  public changePasswordVisible: boolean = false;
  private pathUploadAvatar: pathUpload = pathUpload.SYSTEM_USER;

  public get breadcrumbs(): IBreadcrumb[] {
    return [
      {
        label: 'User Details',
        path: '',
        current: true
      }
    ];
  }
  public get userId(): string {
    return get(this.authUser, 'id', '');
  }
  public get isProfile(): boolean {
    return this.$route.name === UserRouterName.Profile;
  }
  public get isSystemUser(): boolean {
    return this.userType === UserType.System;
  }
  public get isCustomer(): boolean {
    return this.userType === UserType.Customer;
  }
  public get isTechnician(): boolean {
    return this.userType === UserType.Technician;
  }
  public get userType(): UserType {
    // tslint:disable-next-line: whitespace
    return <UserType>this.$route.params.userType;
  }
  public get isAdmin(): boolean {
    return this.isSystemUser && this.authUser.group === GroupSystemUserType.Admin;
  }
  public get title() {
    return `View User ${this.authUser.name}`;
  }
  public get informationTitle(): string {
    return `${this.$t(this.userType)} ${this.$t('information')}`;
  }
  public get displayRole(): string {
    if (this.authUser && this.isSystemUser) {
      return this.authUser.role ? this.authUser.role.name.toString() : '';
    }

    return '';
  }
  // public get displayStationName() {
  //   if (this.authUser.permission === 'partner') {
  //     return this.authUser.partner && this.authUser.partner.station ? this.authUser.partner.station.name : '';
  //   }

  //   return this.authUser.station ? this.authUser.station.name : '';
  // }
  public isShowButtonNew(): boolean {

    return get(this.authUser, 'id', '') === this.userId;
  }

  public async handleUpload(file: any) {
    // tslint:disable-next-line: whitespace
    const _file = <any>await resetOrientation(file.raw, -1);
    this.form.avatar = window.URL.createObjectURL(_file);
    this.fileUpload = _file;
    this.loading = true;
    const avatarUploaded = await uploadToFirebase(this.fileUpload, this.pathUploadAvatar, sizeImage.avatar.thumbnail);
    this.form.avatar = avatarUploaded;

    // const form = cloneDeep(this.form);

    return;
  }

  public goEdit() {
    this.visible = true;
  }

  public displayGroup(model: IUser) {
    if (model.superAdmin) {
      return this.$t('super.admin');
    }
    if (model.group) {
      return this.$t(model.group);
    }

    return '';
  }

  public displayAddress(model: IAddress) {
    return model && model.province ? this.$store.getters['getAddressString'](model) : '';
  }
  public back() {
    this.goBack();
  }
  public updateSuccess() {
    // this.$store.dispatch(ActionTypeUser.GetUserById, {
    //   id: this.userId,
    //   userType: this.userType,
    //   onSuccess: () => {
    //     //
    //   },
    //   onFailure: () => {
    //     //
    //   }
    // });
  }
  public openDialogChangePassword() {
    this.changePasswordVisible = true;
  }

  protected mounted() {
    this.$nextTick(() => {
      switch (this.userType) {
      case UserType.System:
        this.activeTab = '';
        break;
      case UserType.Customer:
        this.activeTab = CustomerDetailTab.BookingHistory;
        break;
      case UserType.Technician:
        this.activeTab = TechnicianDetailTab.SkillList;
        break;
      default:
        break;
      }
      const breadcrumb: IBreadcrumb[] = this.isProfile
        ? [
          {
            label: 'view_profile_detail',
            path: null,
            current: true
          }
        ] : [
          {
            label: this.userType,
            path: this.userType === UserType.System ? UserRouterPath.System :
              this.userType === UserType.Technician ? UserRouterPath.Technician : UserRouterPath.List,
            current: false,
          },
          {
            label: 'title.view_details',
            path: null,
            current: true
          }
        ];
      this.$store.commit(MutationType.SetBack);
      this.$store.commit(MutationType.SetBreadcrumb, breadcrumb);
      // this.$store.commit(MutationTypeUser.FilterChange, stripObject({
      //   _and: {
      //     mode: 'special',
      //   }}));
      // this.$store.dispatch(ActionTypeUser.FilterNoCache);
      if (this.isProfile && this.authUser && this.authUser.id) {
        this.$store.dispatch(ActionTypeUser.GetUserById, {
          id: this.authUser.id,
          onSuccess: (models: IUser[]) => {
            this.$store.commit(MutationType.Authenticated, models[0]);
          },
          onFailure: () => {
            //
          }
        });
      }

      return;
    });
  }

  @Watch('authUser', { deep: true })
  protected onauthUserChange(val, oldVal) {
    if (val.avatar !== oldVal.avatar) {
      this.form.avatar = this.authUser.avatar;
    }
  }

  protected beforeDestroy() {
    this.$store.commit(MutationType.ClearBack);
    this.$store.commit(MutationType.ClearBreadcrumb);
  }
  private goBack() {
    if (this.route.from.fullPath !== '/') {
      this.$store.dispatch(ActionType.GoBack, this.$router);

      return;
    }
    switch (this.userType) {
    case UserType.System:
      this.$router.push(UserRouterPath.System);
      break;
    case UserType.Customer:
      this.$router.push(UserRouterPath.List);
      break;
    case UserType.Technician:
      this.$router.push(UserRouterPath.Technician);
      break;
    default:
      break;
    }
  }

}
