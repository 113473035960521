import { crudMutations, CRUDState, ICRUDState } from 'root/store/helpers';
import { MutationTypeCampaign } from './types';

export interface ICampaignState extends ICRUDState<any> {
  loading: boolean;
  detail: any;
  list: any[];
}

export const defaultState: ICampaignState = {
  ...CRUDState(),
  loading: false,
  detail: {
    data: [],
    pagination: {
      limit: 10,
      page: 1
    }
  },
  list: []
};

export const mutations = {
  ...crudMutations('campaign'),
  [MutationTypeCampaign.GetList](state: ICampaignState, data) {
    state.data = data.data;
    state.pagination = { ...data.pagination, ...state.pagination };
  },
  [MutationTypeCampaign.GetCampaignDetail](state: ICampaignState, data) {
    state.detail.data = data.data;
    state.detail.pagination = {
      ...state.detail.pagination,
      ...data.pagination,
      limit: data.pagination.size
    };
  },
  [MutationTypeCampaign.SetCampaignList](state: ICampaignState, data) {
    state.list = data.data.campaigns;
    state.pagination.total = data.data.campaigns_aggregate.aggregate.count;
  }
};
