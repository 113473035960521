import './styles.scss';

// import moment from 'moment';
import { stripObject } from '@hgiasac/helper';
import { cloneDeep } from 'lodash';
import { ActionTypeRegion, MutationTypeRegion } from 'root/admin/Region/Store/types';
import { SystemRoleAlias } from 'root/admin/User/Store/types';
import { IPaginationParams } from 'root/api/graphql/Core';
import { Avatar } from 'root/components/Avatar';
import { fullNameUser } from 'root/helpers';
import { IAddress, StatusCode } from 'root/models';
import { IUser } from 'root/models/User';
import { IState, MutationType } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import { BranchAndGroupRouterName } from '../..';
import {
  ActionTypeBranchManage,
} from '../../BranchManage/Store/types';
import { RegionDetail } from '../RegionDetail';
import { RegionEditor } from '../RegionEditor';

const enum FilterKey {
  Region = 'region'
}

@Component({
  template: require('./view.html'),
  components: {
    avatar: Avatar,
    editor: RegionEditor,
    'region-detail-modal': RegionDetail,
  },
  computed: {
    ...mapState({
      authUser: (state: IState) => state.global.authUser,
      data: (state: IState) => state.region.data,
      pagination: (state: IState) => state.region.pagination,
      loading: (state: IState) => state.region.loading,
      // filterParams: (state: IState) => state.branchManage.filterParams,
      deviceType: (state: IState) => state.deviceType.data,
      isRefesh: (state: IState) => state.global.isRefesh,
      region: (state: IState) => state.region.data
    }),
    selectedStatus() {
      const filterParams = [];

      if (!filterParams) {
        return [];
      }

      return [
        {
          key: 'isCreatedByAppleReviewer',
          value: []
        }
      ];
    }
  },
  watch: {
    type() {
      this.paramsFilter = {
        text: ''
      };
      // this.$store.commit(MutationTypeUser.ClearUserList);
      this.$store.commit(MutationType.SetBreadcrumb, this.$t(this.type));
      this.fetchData();
    }
  }
})
export class RegionList extends Vue {
  public get serviceId(): string {
    return this.$route.params.id;
  }
  public get actions() {

    return ['view', 'edit'];
  }

  public get AccessCreateBranch() {
    if (this.authUser && (this.authUser.role.alias === SystemRoleAlias.SaleAdmin ||
      this.authUser.role.alias === SystemRoleAlias.Admin)) {
      return true;
    }

    return false;
  }

  public get columns() {

    return [
      {
        prop: 'name',
        label: 'Name',
        width: 180,
        formatter: (model: any) => {
          if (model.name) {
            return model.name || '--';
          }

          return '--';
        }
      },
      {
        prop: 'regionCode',
        label: 'Code',
        width: 180,
        formatter: (model: any) => {
          if (model.regionCode) {
            return model.regionCode || '--';
          }

          return '--';
        }
      },
      {
        prop: 'description',
        label: 'Description',
        formatter: (model: any) => {
          return model.description || '--';
        },
        width: 140
      },
      {
        prop: 'branchQty',
        label: 'Branch Qty',
        formatter: (model: any) => {
          if (model.branches_aggregate) {
            return model.branches_aggregate.aggregate.count || 0;
          }
        },
        width: 140
      },
      {
        prop: 'departmentQty',
        label: 'Department Qty',
        formatter: (model: any) => {
          if (model.branches_aggregate) {
            return model.branches_aggregate.aggregate.count || '--';
          }
        },
        width: 140
      },
      {
        prop: 'status',
        label: this.$t('status'),
        formatter: (model: any) => (model.status ? this.$t(model.status) : ''),
        labelStyle: (model: any) => {
          return model.status;
        },
        width: 150
      }
    ];
  }

  public get inputSearchPlaceholder(): string {
    return `Search by Name`;
  }
  public get dataFilter() {
    // const _region: IFilterInput[] = this.region.reduce((res, item) => {
    //   res.push({
    //     key: item.id,
    //     value: item.id,
    //     name: item.name
    //   });

    //   return res;
    // }, []);

    // return [
    //   {
    //     key: FilterKey.Region,
    //     name: this.$t('field.region'),
    //     value: _region
    //   }
    // ];

    return [];
  }
  public get selectedFilter() {
    const _filterParams = cloneDeep(this.filterParams);

    return [
      {
        key: FilterKey.Region,
        value: _filterParams[FilterKey.Region]
      }
    ];
  }

  public authUser: IUser;
  public pagination: IPaginationParams;
  public data: any[];
  public deviceType: any[];
  public visible: boolean = false;
  public isSample: boolean = false;
  public userId: string = '';
  public visibleDetail: boolean = false;
  public region: any[];
  public filterParams = {
    [FilterKey.Region]: []
  };
  public searchText: string = '';

  public UserType = {
    Customer: 'customer',
    System: 'system'
  };
  public type: any;
  public isRefesh: boolean;
  public displayListManager(model: any) {
    if (model.branchManagers && model.branchManagers.length > 0) {
      return model.branchManagers.map((t) => `${t.name}`).join(', ');
    }

    return '__';
  }
  public displayListGroups(model: any) {
    if (model.groups && model.groups.length > 0) {
      return model.groups.map((t) => `${t.name}`).join(', ');
    }

    return '__';
  }

  public formatterDepartmentQty(model: any) {
    if (model.branches && model.branches.length) {
      let count = 0;
      model.branches.map((item) => {
        count += item.groups_aggregate.aggregate.count;
      });

      return count;
    }

    return 0;
  }

  public handleSearch() {
    this.fetchData();
  }

  public handleChangePagination(value) {
    if (typeof value === 'number') {
      this.$store.commit(MutationTypeRegion.PaginationChange, {
        ...this.pagination,
        size: value
      });
    } else {
      this.$store.commit(MutationTypeRegion.PaginationChange, {
        ...this.pagination,
        ...value
      });
    }
    this.fetchData();
  }

  public closeDialogDetail() {
    this.userId = '';
    this.visibleDetail = false;
  }

  public createSuccess() {
    this.visible = false;
    this.fetchData();
  }

  public applyFilter() {
    this.fetchData();
  }
  public goAddNewRouter() {
    this.visible = true;
    this.userId = '';
  }
  public closeDialog() {
    this.isSample = false;
    this.fetchData();
  }
  public handleDelete(model: any) {
    this.$store.dispatch(ActionTypeBranchManage.Update, {
      id: model.id,
      form: {
        status: StatusCode.Deleted
      },
      onSuccess: () => {
        this.fetchData();
        model.instance.confirmButtonLoading = false;
        model.instance.confirmButtonText = 'Confirm';
        model.done();
      },
      onFailure: () => {
        model.instance.confirmButtonLoading = false;
        model.instance.confirmButtonText = 'Confirm';
      }
    });
  }

  public clickDetail(id: string) {
    this.$router.push({
      name: BranchAndGroupRouterName.DetailRegion,
      params: {
        id
      }
    });
  }
  public handleUpdate(id: string) {
    this.visible = true;
    this.userId = id;
  }

  public changeFilter(model) {
    const status = model.find((e) => e.key === FilterKey.Region);
    const nextFilter = {
      [FilterKey.Region]: status ? status.value : []
    };
    this.filterParams = {
      ...cloneDeep(this.filterParams),
      ...nextFilter
    };
    this.fetchData();
  }

  public formatFullNameUser(model) {
    return fullNameUser(model);
  }
  public displayAddress(model: IAddress) {
    return model && model.province ? this.$store.getters['getAddressString'](model) : '';
  }

  protected mounted() {
    this.$nextTick(() => {
      this.$store.commit(MutationType.ClearBack);
      this.$store.commit(MutationType.SetBreadcrumb, this.$t('title.manageregion'));
      // this.$store.commit(MutationTypeUser.PaginationReset);
      !this.isRefesh && this.fetchData();
    });
  }
  protected beforeDestroy() {
    this.$store.commit(MutationType.ClearBreadcrumb);
  }

  private fetchData() {
    const text = cloneDeep(this.searchText);
    const _filterParams = <any> {};
    if (text) {
      _filterParams.search = text;
    }
    this.$store.commit(
      MutationTypeRegion.FilterChange,
      stripObject({
        _and:
          text.trim() !== '' ?
            {
              mode: 'special',
              data: {
                _or: [
                  {
                    name: {
                      _ilike: `%${text}%`
                    }
                  },
                  {
                    regionCode: {
                      _ilike: `%${text}%`
                    }
                  },
                ]
              }
            } :  null
      })
    );
    this.$store.dispatch(ActionTypeRegion.FilterNoCache, {
      optionsHTTP: {
        orderBy: {
          updatedAt: 'desc'
        }
      }
    });
  }
}
