export enum ActionTypeGroupManage {
  FilterNoCache = 'group_managerFilterNoCache',
  GroupGetById = 'group_managerGetById',
  GetListGroupManage = 'group_managerGetList',
  GetGroupLeader = 'group_managerGetLeader',
  UpdateGroupManager = 'group_managerUpdate',
  InsertGroup = 'group_managerInsert',
  InsertGroupManager = 'group_managerInsertManager',
  GetListTeamStaff = 'group_managerGetListTeamStaff',
  GetListFilterGroup = 'getListFilterGroup'
}
export enum MutationTypeGroupManager {
  FilterChange = 'branch_managerFilterChange',
  SetGroupManager = 'setGroupManager',
  SetGroupTeamStaff = 'setGroupTeamStaff',
  SetListGroup = 'SetGroupManager',
  SetGroupLeader = 'SetGroupLeader',
  PaginationChange = 'group_managerPaginationChange',
  GroupManagerLoading = 'group_managerLoading',
  GroupManagerLoaded = 'group_managerLoaded',
  SetListGroupFilter = 'setListGroupFilter'
}
export enum GroupManageTab {
  GroupLeader = 'GroupLeaderGroup',
  DealerGroup = 'DealerGroup',
  TechnicianGroup = 'TechnicianGroup',
  GroupInventory = 'GroupInventory',
  GroupInvoice = 'GroupInvoice',
  GroupProduct = 'GroupProduct',
  ActiveEWarrantyGroup = 'GroupEWarranty',
  TeamLeader = 'GroupTeamLeader',
  Staff = 'GroupStaff'
}
