import { chunk } from 'lodash';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';

import './styles.scss';

@Component({
  template: require('./view.html'),
  props: {
    title: String,
    dataChildren: Array,
    selectedList: {
      type: Array,
      default: () => {
        return [];
      },
    },
    height: Number,
    isViewMore: Boolean,
    isShowSelectedListTitle: {
      type: Boolean,
      default: () => true
    },
    isProvince: {
      type: Boolean,
      default: () => false
    },
    dataParent: Array
  }
})

export class DKSelectedViewOnlyList extends Vue {
  public selectedItem: string = 'all';
  public selectedList: any[];
  public filteredList?: any = null;
  public isFullViewVisible: boolean = false;
  public selectedParentNode: any = [];
  public isShowSelectedListTitle: boolean;
  public dataChildren: any[];
  public dataParent: any[];

  public get getTotalItemCount() {
    // let count = 0;
    // if (!this.listData || !this.listData.data) {
    //   return 0;
    // }
    // this.listData.data.forEach((data) => {
    //   count += data.children.length;
    // });

    return 0;
  }

  public get getItemsToRender() {
    if (!this.selectedList || (this.selectedList && this.selectedList.length === 0)) {
      this.selectedParentNode = [];

      return [];
    }
    const outputList = this.filterSelectedListBaseOnRootData();
    this.selectedParentNode = outputList;

    return outputList;
  }

  public get getArrangedItemsToRender() {
    let outputList = this.filterSelectedListBaseOnRootData();
    const divideVal = Math.round(outputList.length / 3);
    outputList = chunk(outputList, divideVal === 0 ? 1 : divideVal);

    return outputList;
  }

  @Watch('selectedList')
  public onSelectedListChange() {
    // @ts-ignore
    return this.getItemsToRender;
  }

  public renderDaikinCode(code) {
    return !code || code === '' ? '__' : code;
  }

  public closeDialog() {
    this.isFullViewVisible = false;
  }

  public openDialog() {
    this.isFullViewVisible = true;
  }

  public onCheckAll() {
    this.$emit('checkAllDealer');
  }

  private filterSelectedListBaseOnRootData(): any {
    const outputList = [];
    if (this.dataParent.length > 0 && this.dataChildren.length > 0) {
      this.selectedList.map((it) => {
        const branchName = this.dataParent.filter((item) => item.id === it.branch)[0].name;
        const dealer = this.dataChildren.filter((ex) =>
        ex.branchId === it.branch && ex.role.name !== 'Technician' && it.dealer.includes(ex.id)).map((del) => del.name);
        const technician = this.dataChildren.filter((ex) =>
        ex.branchId === it.branch && ex.role.name === 'Technician' && it.dealer.includes(ex.id)).map((del) => del.name);
        outputList.push({
          name: `Dealers - ${branchName}`,
          children: dealer,
          type: true,
        });
        // tslint:disable-next-line:early-exit
        if (technician.length) {
          outputList.push({
            name: `Technicians - ${branchName}`,
            children: technician,
            type: false,
          });
        }
      });
    }

    return outputList;
  }
}
