import { TabPane } from 'element-ui';
import moment from 'moment';
import { CustomCampaignRouterName, CustomCampaignTab } from 'root/admin/CustomCampaign';
import { SystemRoleAlias } from 'root/admin/User/Store/types';
import BarChart from 'root/helpers/bar-chart';
import LineChart from 'root/helpers/line-chart';
import { IBreadcrumb, IUser } from 'root/models';
import { IState, MutationType } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import { ActionTypeAclCampaign } from '../../Store/types';
import './styles.scss';

@Component({
  template: require('./view.html'),
  components: {
    'bar-chart': BarChart,
    'line-chart': LineChart,
  },
  computed: {
    ...mapState({
      authUser: (state: IState) => state.global.authUser,
    })
  }
})

export class CampaignStatistic extends Vue {
  public get activeTab() {
    return CustomCampaignTab.Statistic;
  }

  public set activeTab(_value: string) {
    //
  }
  public get isSalePlanning() {
    if (this.authUser.superAdmin || this.authUser.role.alias === SystemRoleAlias.Admin
      || this.authUser.role.alias === SystemRoleAlias.SaleAdmin) {
      return true;
    }

    return false;
  }
  public get chartLineData() {
    // tslint:disable-next-line: max-line-length
    return {
      hoverBackgroundColor: 'red',
      hoverBorderWidth: 10,
      labels: this.label,
      datasets: [
        {
          label: 'Waiting for review',
          borderColor: '#03A1E4',
          pointBackgroundColor: '#03A1E4',
          backgroundColor: 'rgba(0, 0, 0, 0)',
          // data: this.dataLineChart,
          data: this.dataReview
        }
      ]
    };
  }
  public get chartBarModelData() {
    const labels = ['MC30VVA-M', 'MC40UVM6', 'MCK55TVM6', 'MC55UVM6'];

    return {
      labels,
      datasets: [
        {
          backgroundColor: ['#35B3E9', '#3DBB61', '#BB8EFF', '#FFB14D'],
          barPercentage: 100,
          barThickness: 20,
          categoryPercentage: 0.5,
          data: this.modelQuantity
        },
      ]
    };
  }
  public get chartBarBranchData() {
    const labels = this.labelBranch;

    return {
      labels,
      datasets: [
        {
          label: 'Joined',
          backgroundColor: ['#FF767C', '##FF9761', '#FFD359', '#49CD45', '#4279FB', '#5352FF', '#B569FF',
            '#FF767C', '##FF9761', '#FFD359', '#49CD45', '#4279FB', '#5352FF', '#B569FF'],
          barPercentage: 100,
          barThickness: 50,
          data: this.dataJoinedBranchchart,
          categoryPercentage: 10,
          stack: 1
        },
        {
          label: 'Total',
          backgroundColor: ['#FCD0D3', '#FFD2BB', '#FFEDBB', '#B8EFB7', '#B8B7FF', '#B8B7FF', '#DCB9FF',
            '#FCD0D3', '#FFD2BB', '#FFEDBB', '#B8EFB7', '#B8B7FF', '#B8B7FF', '#DCB9FF'],
          barPercentage: 100,
          barThickness: 50,
          data: this.dataNotJoinedBranchchart,
          categoryPercentage: 10,
          stack: 1
        },
      ]
    };
  }
  public get chartBarData() {
    const labels = this.label;

    return {
      labels,
      datasets: [
        {
          label: 'Waiting for review',
          backgroundColor: '#35B3E9',
          barPercentage: 1.0,
          barThickness: 9,
          categoryPercentage: 0.5,
          data: this.dataReview
        },
        {
          label: 'Waiting for reup',
          backgroundColor: '#FFB14D',
          barPercentage: 1.0,
          barThickness: 9,
          categoryPercentage: 0.5,
          data: this.dataReup
        },
        {
          label: 'Completed',
          backgroundColor: '#3DBB61',
          barPercentage: 1.0,
          barThickness: 9,
          categoryPercentage: 0.5,
          data: this.dataApprove
        },
        {
          label: 'Reject',
          backgroundColor: '#FF767C',
          barPercentage: 1.0,
          barThickness: 9,
          categoryPercentage: 0.5,
          data: this.dataReject
        }
      ]
    };
  }
  public statistic: any = null;
  public loading: boolean = false;
  public label: any[] = [];
  public dataLineChart: any[] = [];
  public dataReview: any[] = [];
  public dataReup: any[] = [];
  public dataApprove: any[] = [];
  public dataReject: any[] = [];
  public modelQuantity: any[] = [];
  public branchQuantity: any[] = [];
  public labelBranch: any[] = [];
  public branchIds: any[] = [];
  public dataJoinedBranchchart: any[] = [];
  public dataTotalBranchchart: any[] = [];
  public dataNotJoinedBranchchart: any[] = [];
  public chartLineOptions: object = {
    legend: {
      display: true,
      align: 'end',
      labels: {
        usePointStyle: true,
        pointStyle: 'circle'
      },
    },
    scales: {
      xAxes: [{
        scaleLabel: {
          display: true,
          labelString: 'Campaign Duration'
        }
      }],
      yAxes: [{
        scaleLabel: {
          display: true,
          labelString: 'Shop Request'
        }
      }]
    },
    title: {
      display: true,
      text: 'NEW JOIN SHOP (DAY)',
      align: 'start',
      fontSize: 12,
      fontStyle: 'bold'
    },
    animation: {
      animateScale: true
    },
    elements: {
      line: {
        tension: 0,
      }
    },
    responsive: true,
    maintainAspectRatio: false,
  };
  public chartBarOptions: object = {
    responsive: true,
    scales: {
      xAxes: [{
        stacked: true,
        scaleLabel: {
          display: true,
          labelString: 'Campaign Duration'
        }
      }],
      yAxes: [{
        stacked: true,
        scaleLabel: {
          display: true,
          labelString: 'Shop Request'
        }
      }]
    },
    legend: {
      align: 'end',
      labels: {
        usePointStyle: true,
        pointStyle: 'circle'
      },
    },
    title: {
      display: true,
      text: 'STATUS',
      align: 'start',
      fontSize: 12,
      fontStyle: 'bold'
    },
    maintainAspectRatio: false,
  };
  public chartBarModelOptions: object = {
    responsive: true,
    scales: {
      xAxes: [{
        scaleLabel: {
          display: true,
          labelString: 'Model'
        }
      }],
      yAxes: [{
        scaleLabel: {
          display: true,
          labelString: 'Quantity'
        },
        ticks: {
          beginAtZero: true,
        }
      }]
    },
    legend: {
      display: false
    },
    title: {
      display: true,
      text: 'MODEL',
      align: 'start',
      fontSize: 12,
      fontStyle: 'bold'
    },
    maintainAspectRatio: false,
  };
  public chartBarBranchOptions: object = {
    responsive: true,
    scales: {
      xAxes: [{
        scaleLabel: {
          display: true,
          labelString: 'Branch'
        }
      }],
      yAxes: [{
        staked: true,
        scaleLabel: {
          display: true,
          labelString: 'Quantity'
        },
        ticks: {
          beginAtZero: true,
        }
      }]
    },
    legend: {
      display: false
    },
    // tooltips: {
    //   enabled: false
    // },
    tooltips: {
      mode: 'label',
      callbacks: {
        label: (t, d) => {
          const dstLabel = d.datasets[t.datasetIndex].label;
          const yLabel = t.yLabel;
          if (t.datasetIndex === 1) {

            return `${dstLabel} :  ${yLabel + d.datasets[0].data[t.index]}`;
          }

          return `${dstLabel} : ${yLabel}`;
        },
        // title: () => {
        //   return '123123123';
        // }
      }
    },
    title: {
      display: true,
      text: 'Shop (Joined/Total)',
      align: 'start',
      fontSize: 12,
      fontStyle: 'bold'
    },
    maintainAspectRatio: false,
  };
  public authUser: IUser;
  public changeTab(tab: TabPane) {
    switch (tab.name) {
    case CustomCampaignTab.Participant:
      this.$router.push({
        name: CustomCampaignRouterName.Participant
      });
      break;
    case CustomCampaignTab.Configuration:
      this.$router.push({
        name: CustomCampaignRouterName.Configuration
      });
      break;
    case CustomCampaignTab.ManageExport:
      this.$router.push({
        name: CustomCampaignRouterName.ManageExport
      });
      break;
    case CustomCampaignTab.QualifiedShops:
      this.$router.push({
        name: CustomCampaignRouterName.QualifiedShops
      });
      break;
    case CustomCampaignTab.Shops:
      this.$router.push({
        name: CustomCampaignRouterName.Shops
      });
      break;
    default:
      break;
    }
  }
  public handleExport() {
    this.$msgbox({
      title: this.$t('export_confirm').toString(),
      message: `Do you want to export all?`,
      showCancelButton: true,
      confirmButtonText: 'Confirm',
      cancelButtonText: 'Cancel',
      beforeClose: (action, instance, done) => {
        if (action === 'confirm') {
          instance.confirmButtonLoading = true;
          instance.confirmButtonText = 'Loading...';
          this.exportStatistic({ instance, done });
        } else {
          done();
        }

        return;
      }
    })
        .then(() => {
          this.$message({
            type: 'success',
            message: this.$t('message.export_success').toString()
          });
        })
        .catch(() => {
          // no handle
        });
  }

  public exportStatistic(model) {
    this.$store.dispatch(ActionTypeAclCampaign.ExportStatistic, {
      id: this.authUser.id,
      onSuccess: () => {
        this.fetchData();
        model.instance.confirmButtonLoading = false;
        model.instance.confirmButtonText = 'Confirm';
        model.done();
      },
      onFailure: () => {
        model.instance.confirmButtonLoading = false;
        model.instance.confirmButtonText = 'Cancel';
      }
    });
  }
  public enumerateDaysBetweenDates(startDate, endDate) {
    const dates = [];

    // startDate = startDate.add(1, 'days');

    while (startDate.isBefore(endDate)) {
      dates.push(moment(startDate).format('DD/MM/YYYY'));
      startDate = startDate.add(1, 'days');
    }

    return dates;
  }
  public enumerateDays(startDate, endDate) {
    const dates = [];

    // startDate = startDate.add(1, 'days');

    while (startDate.isBefore(endDate)) {
      dates.push(moment(startDate));
      startDate = startDate.add(1, 'days');
    }

    return dates;
  }
  public fetchData() {
    this.loading = true;
    this.$store.dispatch(ActionTypeAclCampaign.GetStatistic, {
      onSuccess: (statistic) => {
        this.statistic = statistic;
        this.label =
          this.enumerateDaysBetweenDates(moment(this.statistic.configCampaign[0].aclCampaignConfig.uploadStartDate),
          moment(this.statistic.configCampaign[0].aclCampaignConfig.uploadEndDate));
        const _clone = this.enumerateDays(moment(this.statistic.configCampaign[0].aclCampaignConfig.uploadStartDate),
          moment(this.statistic.configCampaign[0].aclCampaignConfig.uploadEndDate));
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < _clone.length; i++) {
          this.dataReview.push(0);
          this.dataReup.push(0);
          this.dataApprove.push(0);
          this.dataReject.push(0);
        }
        _clone.map((day, index) => {
          const _toDay = moment(day);
          const _nextDay = moment(day).add(1, 'days');
          const _cloneAllRequest = [];
          const _cloneRequestPerDay = [];
          this.statistic.requests.map((request) => {
            if (moment(request.createdAt).isAfter(moment(_toDay))
            && moment(request.createdAt).isBefore(moment(_nextDay))) {
              _cloneRequestPerDay.push(request);
            }
          });
          const _requestPerDayUnique = [..._cloneRequestPerDay];
          _cloneRequestPerDay.map((it) => {
            _requestPerDayUnique.map((req, ind) => {
              if (it.campaignId === req.campaignId && moment(it.createdAt).isAfter(moment(req.createdAt))) {
                _requestPerDayUnique.splice(ind, 1);
              }
            });
          });
          _cloneAllRequest.push(..._requestPerDayUnique);
          _cloneAllRequest.map((requestClone) => {
            if (requestClone.requestStatus === 'waiting_for_review') {
              this.dataReview[index] += 1;
            }
            if (requestClone.requestStatus === 'request_re_up') {
              this.dataReup[index] += 1;
            }
            if (requestClone.requestStatus === 'approved') {
              this.dataApprove[index] += 1;
            }
            if (requestClone.requestStatus === 'rejected') {
              this.dataReject[index] += 1;
            }
          });
        });
        // _clone.map((day, index) => {
        //   const _toDay = moment(day);
        //   const _nextDay = moment(day).add(1, 'days');
        //   this.statistic.requests.map((request) => {
        //     if (request.requestStatus === 'waiting_for_review'
        //     && moment(request.createdAt).isAfter(moment(_toDay))
        //     && moment(request.createdAt).isBefore(moment(_nextDay))) {
        //       this.dataReview[index] += 1;
        //     }
        //     if (request.requestStatus === 'request_re_up'
        //     && moment(request.createdAt).isAfter(moment(_toDay))
        //     && moment(request.createdAt).isBefore(moment(_nextDay))) {
        //       this.dataReup[index] += 1;
        //     }
        //     if (request.requestStatus === 'approved'
        //     && moment(request.createdAt).isAfter(moment(_toDay))
        //     && moment(request.createdAt).isBefore(moment(_nextDay))) {
        //       this.dataApprove[index] += 1;
        //     }
        //     if (request.requestStatus === 'rejected'
        //     && moment(request.createdAt).isAfter(moment(_toDay))
        //     && moment(request.createdAt).isBefore(moment(_nextDay))) {
        //       this.dataReject[index] += 1;
        //     }
        //   });
        // });
        this.modelQuantity = [];
        this.labelBranch = [];
        this.modelQuantity = [
          this.statistic.model1.aggregate.count,
          this.statistic.model2.aggregate.count,
          this.statistic.model3.aggregate.count,
          this.statistic.model4.aggregate.count
        ];
        this.statistic.labelBranch.map((branch) => {
          this.labelBranch.push(branch.name);
          this.branchIds.push(branch.id);
          this.dataJoinedBranchchart.push(0);
          this.dataTotalBranchchart.push(0);
          this.dataNotJoinedBranchchart.push(0);
        });
        this.branchIds.map((it, index) => {
          this.$store.dispatch(ActionTypeAclCampaign.GetDealerByBranch, {
            name: it,
            onSuccess: (result) => {
              this.dataJoinedBranchchart.splice(index, 1, result.join.aggregate.count);
              this.dataTotalBranchchart.splice(index, 1, result.total.aggregate.count);
              this.dataNotJoinedBranchchart.splice(index, 1,
                result.total.aggregate.count - result.join.aggregate.count);
            }
          });
        });
        this.loading = false;
      }
    });
  }
  protected mounted() {
    this.$nextTick(() => {
      const breadcrumb: IBreadcrumb[] = [
        {
          label: 'title.campaign',
          path: null,
          current: false
        },
        {
          label: 'title.acl_campaign',
          path: null,
          current: true
        }
      ];
      this.$store.commit(MutationType.SetBreadcrumb, breadcrumb);

      this.fetchData();
    });
  }
}
