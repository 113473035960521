import { Subscription } from 'apollo-client/util/Observable';
import { TabPane } from 'element-ui';
import moment from 'moment';
import { SystemRoleAlias } from 'root/admin/User/Store/types';
import { gqlClient } from 'root/api/graphql/Core';
import { zeroCampaignQuery } from 'root/api/graphql/Query/zeroCampaign';
import BarChart from 'root/helpers/bar-chart';
import LineChart from 'root/helpers/line-chart';
import PieChart from 'root/helpers/pie-chart';
import { IUser } from 'root/models';
import { IBreadcrumb, IState, MutationType } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import { ZeroPercentCampaignRouterName, ZeroPercentCampaignTab } from '..';
import { ActionTypeZeroCampaign } from '../Store/types';
import './styles.scss';

@Component({
  template: require('./view.html'),
  components: {
    'bar-chart': BarChart,
    'line-chart': LineChart,
    'pie-chart': PieChart
  },
  computed: {
    ...mapState({
      authUser: (state: IState) => state.global.authUser,
    })
  }
})

export class ZPCampaignStatistic extends Vue {
  public get activeTab() {
    return ZeroPercentCampaignTab.Statistic;
  }

  public set activeTab(_value: string) {
    //
  }
  public get isSuperAdmin() {
    if (this.authUser.superAdmin || this.authUser.role.alias === SystemRoleAlias.Admin) {
      return true;
    }

    return false;
  }
  public get isSalePlanning() {
    if (this.authUser.role.alias === SystemRoleAlias.SaleAdmin) {
      return true;
    }

    return false;
  }
  public get isBranchPic() {
    if (this.authUser.role.alias === SystemRoleAlias.BranchManager) {
      return true;
    }

    return false;
  }
  public get currentRole() {
    return this.authUser.role.alias;
  }
  public get isDepartmentPic() {
    if (this.authUser.role.alias === SystemRoleAlias.GroupLeader) {
      return true;
    }

    return false;
  }
  public get chartBarBranchDataShopForDepartmentPic() {

    return {
      labels: ['HD SAISON', 'FE Credit', 'ACS'],
      datasets: [
        {
          backgroundColor: ['#CDC6DB', '#9B8EB8', '#6B5695'],
          barPercentage: 100,
          barThickness: 20,
          data: this.dataShopForDepartmentPic
        },
      ]
    };
  }
  public get columns() {
    return [
      {
        label: 'Agent',
        formatter: (model: any) => {
          return model && model.name ? model.name : '--';
        },
        width: 200,
        fixed: true
      },
      {
        label: 'Dealer Code',
        formatter: (model: any) => {
          return model && model.code ? model.code : 0;
        },
        width: 100
      },
      {
        label: 'Branch Name',
        formatter: (model: any) => {
          return model && model.branchName ? model.branchName : 0;
        },
        width: 100
      },
      {
        label: 'CUSTOMERS PARTICIPATING',
        formatter: (model: any) => {
          return model && model.totalCustomers ? model.totalCustomers : 0;
        },
        labelStyle: (model: any) => {
          return model && model.top ? `statistic-list__${model.top}` : '';
        },
        width: 120
      },
      {
        label: 'Percent',
        formatter: (model: any) => {
          return model && model.percentage ? `${model.percentage} %` : 0;
        },
        labelStyle: (model: any) => {
          return model && model.top ? `statistic-list__${model.top}` : '';
        },
        width: 115
      }
    ];
  }
  public get columnsShop() {
    return [
      {
        label: 'Agent',
        formatter: (model: any) => {
          return model && model.name ? model.name : '--';
        },
        width: 200,
        fixed: true
      },
      {
        label: 'Shop Code',
        formatter: (model: any) => {
          return model && model.code ? model.code : 0;
        },
        width: 100
      },
      {
        label: 'Branch Name',
        formatter: (model: any) => {
          return model && model.branchName ? model.branchName : 0;
        },
        width: 100
      },
      {
        label: 'CUSTOMERS PARTICIPATING',
        formatter: (model: any) => {
          return model && model.totalCustomers ? model.totalCustomers : 0;
        },
        labelStyle: (model: any) => {
          return model && model.top ? `statistic-list__${model.top}` : '';
        },
        width: 120
      },
      {
        label: 'Percent',
        formatter: (model: any) => {
          return model && model.percentage ? `${model.percentage} %` : 0;
        },
        labelStyle: (model: any) => {
          return model && model.top ? `statistic-list__${model.top}` : '';
        },
        width: 115
      }
    ];
  }
  public get chartBarBranchDataShop() {
    const labels = this.labelBranch;

    return {
      labels,
      datasets: [
        {
          label: 'HD SAISON',
          backgroundColor: ['#CDC6DB', '#CDC6DB', '#CDC6DB', '#CDC6DB', '#CDC6DB', '#CDC6DB', '#CDC6DB',
            '#CDC6DB', '#CDC6DB', '#CDC6DB', '#CDC6DB', '#CDC6DB', '#CDC6DB', '#CDC6DB'],
          data: this.dataShopHD,
          stack: 1
        },
        {
          label: 'FE Credit',
          backgroundColor: ['#9B8EB8', '#9B8EB8', '#9B8EB8', '#9B8EB8', '#9B8EB8', '#9B8EB8', '#9B8EB8',
            '#9B8EB8', '#9B8EB8', '#9B8EB8', '#9B8EB8', '#9B8EB8', '#9B8EB8', '#9B8EB8'],
          data: this.dataShopFE,
          stack: 1
        },
        {
          label: 'ACS',
          backgroundColor: ['#6B5695', '#6B5695', '#6B5695', '#6B5695', '#6B5695', '#6B5695', '#6B5695',
            '#6B5695', '#6B5695', '#6B5695', '#6B5695', '#6B5695', '#6B5695', '#6B5695'],
          data: this.dataShopACS,
          stack: 1
        },
      ]
    };
  }
  public get chartFinancial() {
    return {
      labels: ['HD', 'FE', 'ACS'],
      datasets: [
        {
          data: this.dataCustomerChartData,
          backgroundColor: ['#9965F4', '#F465C0', '#F49965']
        }
      ]
    };
  }
  public get chartMachineHD() {
    return {
      labels: ['RA', 'ACL'],
      datasets: [
        {
          data: this.dataMachineHD,
          backgroundColor: ['#009CFF', '#6200FF']
        }
      ]
    };
  }
  public get chartMachineFE() {
    return {
      labels: ['RA', 'ACL'],
      datasets: [
        {
          data: this.dataMachineFE,
          backgroundColor: ['#009CFF', '#6200FF']
        }
      ]
    };
  }
  public get chartMachineACS() {
    return {
      labels: ['RA', 'ACL'],
      datasets: [
        {
          data: this.dataMachineACS,
          backgroundColor: ['#009CFF', '#6200FF']
        }
      ]
    };
  }
  public get chartBarTopAgent() {
    const labels = ['Đại Lý Khánh Hội', 'Đại Lý Thuận Mỹ', 'Đại Lý Khánh Hội',
      'Đại Lý Thuận Mỹ', 'Đại Lý Khánh Hội', 'Đại Lý Thuận Mỹ'];

    return {
      labels,
      datasets: [
        {
          label: 'HD SAISON',
          backgroundColor: ['#00C277', '#00C277', '#00C277', '#00C277', '#00C277', '#00C277', '#00C277',
            '#00C277', '#00C277', '#00C277', '#00C277', '#00C277', '#00C277', '#00C277'],
          barPercentage: 100,
          barThickness: 20,
          data: this.dataDealerHD,
          categoryPercentage: 10,
          stack: 1
        },
        {
          label: 'FE Credit',
          backgroundColor: ['#65E2A8', '#65E2A8', '#65E2A8', '#65E2A8', '#65E2A8', '#65E2A8', '#65E2A8',
            '#65E2A8', '#65E2A8', '#65E2A8', '#65E2A8', '#65E2A8', '#65E2A8', '#65E2A8'],
          barPercentage: 100,
          barThickness: 20,
          data: this.dataDealerFE,
          categoryPercentage: 10,
          stack: 1
        },
        {
          label: 'ACS',
          backgroundColor: ['#C9F3DD', '#C9F3DD', '#C9F3DD', '#C9F3DD', '#C9F3DD', '#C9F3DD', '#C9F3DD',
            '#C9F3DD', '#C9F3DD', '#C9F3DD', '#C9F3DD', '#C9F3DD', '#C9F3DD', '#C9F3DD'],
          barPercentage: 100,
          barThickness: 20,
          data: this.dataDealerACS,
          categoryPercentage: 10,
          stack: 1
        },
      ]
    };
  }
  public get chartBarBranchDataDealer() {
    const labels = this.labelBranch;

    return {
      labels,
      datasets: [
        {
          label: 'HD SAISON',
          backgroundColor: ['#0376CF', '#0376CF', '#0376CF', '#0376CF', '#0376CF', '#0376CF', '#0376CF',
            '#0376CF', '#0376CF', '#0376CF', '#0376CF', '#0376CF', '#0376CF', '#0376CF'],
          data: this.dataDealerHD,
          stack: 1
        },
        {
          label: 'FE Credit',
          backgroundColor: ['#009DFF', '#009DFF', '#009DFF', '#009DFF', '#009DFF', '#009DFF', '#009DFF',
            '#009DFF', '#009DFF', '#009DFF', '#009DFF', '#009DFF', '#009DFF', '#009DFF'],
          data: this.dataDealerFE,
          stack: 1
        },
        {
          label: 'ACS',
          backgroundColor: ['#BBE1FE', '#BBE1FE', '#BBE1FE', '#BBE1FE', '#BBE1FE', '#BBE1FE', '#BBE1FE',
            '#BBE1FE', '#BBE1FE', '#BBE1FE', '#BBE1FE', '#BBE1FE', '#BBE1FE', '#BBE1FE'],
          data: this.dataDealerACS,
          stack: 1
        },
      ]
    };
  }
  public get chartBarBranchDataDealerForDepartmentPic() {

    return {
      labels: ['HD SAISON', 'FE Credit', 'ACS'],
      datasets: [
        {
          backgroundColor: ['#CDC6DB', '#9B8EB8', '#6B5695'],
          barPercentage: 100,
          barThickness: 20,
          data: this.dataDealerForDepartmentPic
        },
      ]
    };
  }
  public chartFinancialOption = {
    title: {
      display: false,
      text: 'HD SAISON',
      align: 'start',
      fontSize: 12,
      fontStyle: 'bold'
    },
    legend: {
      display: false,
      align: 'end',
      labels: {
        usePointStyle: true,
        pointStyle: 'circle'
      },
    },
  };
  public chartFinancialOptionHD = {
    title: {
      display: true,
      text: 'HD SAISON',
      align: 'start',
      fontSize: 12,
      fontStyle: 'bold'
    },
    legend: {
      display: false,
      align: 'end',
      labels: {
        usePointStyle: true,
        pointStyle: 'circle'
      },
    },
  };
  public chartFinancialOptionFE = {
    title: {
      display: true,
      text: 'FE CREDIT',
      align: 'start',
      fontSize: 12,
      fontStyle: 'bold'
    },
    legend: {
      display: false,
      align: 'bottom',
      labels: {
        usePointStyle: true,
        pointStyle: 'circle'
      },
    },
  };
  public chartFinancialOptionACS = {
    title: {
      display: true,
      text: 'ACS',
      align: 'start',
      fontSize: 12,
      fontStyle: 'bold'
    },
    legend: {
      display: false,
      align: 'bottom',
      labels: {
        usePointStyle: true,
        pointStyle: 'circle'
      },
    },
  };
  public chartBarBranchOptionsShopForDepartmentPic: object = {
    responsive: true,
    scales: {
      xAxes: [{
        scaleLabel: {
          display: true,
          labelString: 'Company'
        },

      }],
      yAxes: [{
        staked: true,
        scaleLabel: {
          display: true,
          labelString: 'Quantity'
        },
        ticks: {
          beginAtZero: true,
        }
      }]
    },
    legend: {
      display: false,
      align: 'end',
      labels: {
        usePointStyle: true,
        pointStyle: 'circle'
      },
    },
    // tooltips: {
    //   mode: 'label',
    //   callbacks: {
    //     label: (t, d) => {
    //       const dstLabel = d.datasets[t.datasetIndex].label;
    //       const yLabel = t.yLabel;

    //       return `${dstLabel} : ${yLabel}`;
    //     },
    //   }
    // },
    title: {
      display: true,
      text: 'SHOP',
      align: 'start',
      fontSize: 12,
      fontStyle: 'bold'
    },
    maintainAspectRatio: false,
  };
  public chartBarBranchOptionsShop: object = {
    responsive: true,
    scales: {
      xAxes: [{
        scaleLabel: {
          display: true,
          labelString: 'Branch'
        },
        barThickness: 20,
        maxBarThickness: 40
      }],
      yAxes: [{
        staked: true,
        scaleLabel: {
          display: true,
          labelString: 'Quantity'
        },
        ticks: {
          beginAtZero: true,
        }
      }]
    },
    legend: {
      display: true,
      align: 'end',
      labels: {
        usePointStyle: true,
        pointStyle: 'circle'
      },
    },
    tooltips: {
      mode: 'label',
      callbacks: {
        label: (t, d) => {
          const dstLabel = d.datasets[t.datasetIndex].label;
          const yLabel = t.yLabel;

          return `${dstLabel} : ${yLabel}`;
        },
      }
    },
    title: {
      display: false,
      text: 'SHOP',
      align: 'start',
      fontSize: 12,
      fontStyle: 'bold'
    },
    maintainAspectRatio: false,
  };
  public chartBarBranchOptionsDealerForDepartmentPic: object = {
    responsive: true,
    scales: {
      xAxes: [{
        scaleLabel: {
          display: true,
          labelString: 'Company'
        }
      }],
      yAxes: [{
        staked: true,
        scaleLabel: {
          display: true,
          labelString: 'Quantity'
        },
        ticks: {
          beginAtZero: true,
        }
      }]
    },
    legend: {
      display: false,
      align: 'end',
      labels: {
        usePointStyle: true,
        pointStyle: 'circle'
      },
    },
    // tooltips: {
    //   mode: 'label',
    //   callbacks: {
    //     label: (t, d) => {
    //       const dstLabel = d.datasets[t.datasetIndex].label;
    //       const yLabel = t.yLabel;

    //       return `${dstLabel} : ${yLabel}`;
    //     },
    //   }
    // },
    title: {
      display: false,
      text: 'DEALER',
      align: 'start',
      fontSize: 12,
      fontStyle: 'bold'
    },
    maintainAspectRatio: false,
  };
  public chartBarTopAgentOption: object = {
    responsive: true,
    scales: {
      xAxes: [{
        scaleLabel: {
          display: true,
          labelString: 'Name'
        }
      }],
      yAxes: [{
        staked: true,
        scaleLabel: {
          display: true,
          labelString: 'Quantity'
        },
        ticks: {
          beginAtZero: true,
        }
      }]
    },
    legend: {
      display: true,
      align: 'end',
      labels: {
        usePointStyle: true,
        pointStyle: 'circle'
      },
    },
    tooltips: {
      mode: 'label',
      callbacks: {
        label: (t, d) => {
          const dstLabel = d.datasets[t.datasetIndex].label;
          const yLabel = t.yLabel;

          return `${dstLabel} : ${yLabel}`;
        },
      }
    },
    title: {
      display: false,
      text: 'DEALER',
      align: 'start',
      fontSize: 12,
      fontStyle: 'bold'
    },
    maintainAspectRatio: false,
  };
  public chartBarBranchOptionsDealer: object = {
    responsive: true,
    scales: {
      xAxes: [{
        scaleLabel: {
          display: true,
          labelString: 'Branch'
        },
        barThickness: 20,
        maxBarThickness: 40
      }],
      yAxes: [{
        staked: true,
        scaleLabel: {
          display: true,
          labelString: 'Quantity'
        },
        ticks: {
          beginAtZero: true,
        }
      }]
    },
    legend: {
      display: true,
      align: 'end',
      labels: {
        usePointStyle: true,
        pointStyle: 'circle'
      },
    },
    tooltips: {
      mode: 'label',
      callbacks: {
        label: (t, d) => {
          const dstLabel = d.datasets[t.datasetIndex].label;
          const yLabel = t.yLabel;

          return `${dstLabel} : ${yLabel}`;
        },
      }
    },
    title: {
      display: false,
      text: 'DEALER',
      align: 'start',
      fontSize: 12,
      fontStyle: 'bold'
    },
    maintainAspectRatio: false,
  };
  public get pickerOptionPeriodTimeFromExport() {
    return {
      disabledDate: (time) => {
        return time.getTime() > moment() || (
          this.exportTime.to && time.getTime() > moment(this.exportTime.to)
        );
      }
    };
  }
  public get pickerOptionPeriodTimeToExport() {
    return {
      disabledDate: (time) => {
        return time.getTime() > moment() || (
          this.exportTime.from && time.getTime() < moment(this.exportTime.from)
        );
      }
    };
  }
  public authUser: IUser;
  public statistic: any = null;
  public SystemRoleAlias = SystemRoleAlias;
  public loading: boolean = false;
  public loadingExport: boolean = false;
  public labelBranch: any[] = [];
  public branchIds: any[] = [];
  public dataShopHD: any[] = [];
  public dataShopFE: any[] = [];
  public dataShopACS: any[] = [];
  public dataDealerHD: any[] = [];
  public timeFilter: any = null;
  public loadingList: boolean= false;
  public loadingListShop: boolean= false;
  public visibleExport: boolean = false;
  public dataDealerFE: any[] = [];
  public dataDealerACS: any[] = [];
  public topListFilter: any = 'all';
  public topListFilterShop: any = 'all';
  public dataShopForDepartmentPic: any[] = [];
  public dataDealerForDepartmentPic: any[] = [];
  public dataMachineHD: any = [];
  public dataMachineFE: any = [];
  public dataMachineACS: any = [];
  public dataTable: any = null;
  public data: any[] = [];
  public dataShop: any[] = [];
  public dataCustomerChartData: any = [];
  public subscribeURL: Subscription = null;
  // tslint:disable-next-line:max-line-length
  public templateDevice = 'https://firebasestorage.googleapis.com/v0/b/daikin-sale-staging.appspot.com/o/imports%2Fsample%2Ftemplate_import_device_0%25.xlsx?alt=media&token=6f7971ef-deaf-479e-9fb3-b30b801fa873';
  public loadingTable: boolean = false;
  public exportTime: {
    from: any,
    to: any
  } = {
    from: null,
    to: null
  };
  public exportError: {
    from: boolean,
    to: boolean
  } = {
    from: false,
    to: false
  };
  public changeTab(tab: TabPane) {
    switch (tab.name) {
    case ZeroPercentCampaignTab.Participant:
      this.$router.push({
        name: ZeroPercentCampaignRouterName.Participant
      });
      break;
    case ZeroPercentCampaignTab.RequestReview:
      this.$router.push({
        name: ZeroPercentCampaignRouterName.RequestReview
      });
      break;
    case ZeroPercentCampaignTab.Configuration:
      this.$router.push({
        name: ZeroPercentCampaignRouterName.Configuration
      });
      break;
    default:
      break;
    }
  }
  public fetchDataTable() {
    this.loadingTable = true;
    this.$store.dispatch(ActionTypeZeroCampaign.GetStatisticTable, {
      time: this.timeFilter,
      onSuccess: (data) => {
        this.dataTable = data;
        this.loadingTable = false;
      }
    });
  }
  public downloadSample() {
    window.open(this.templateDevice, '_blank');
  }
  public fetchData() {
    this.loading = true;
    this.fetchDataList();
    this.fetchDataListShop();
    this.fetchDataTable();
    this.$store.dispatch(ActionTypeZeroCampaign.GetStatistic, {
      time: this.timeFilter,
      onSuccess: (statistic) => {
        this.statistic = statistic;
        this.loading = false;
        // this.data = statistic.topDealerMostCustomers;
        this.labelBranch = [];
        this.dataMachineHD = [statistic.totalDeviceRAofHD.aggregate.count,
          statistic.totalDeviceACLofHD.aggregate.count];
        this.dataMachineFE = [statistic.totalDeviceRAofFE.aggregate.count,
          statistic.totalDeviceACLofFE.aggregate.count];
        this.dataMachineACS = [statistic.totalDeviceRAofACS.aggregate.count,
          statistic.totalDeviceACLofACS.aggregate.count];
        this.dataCustomerChartData = [statistic.totalCustomerOfHD.aggregate.count,
          statistic.totalCustomerOfFE.aggregate.count, statistic.totalCustomerOfACS.aggregate.count];
        this.statistic.labelBranch.map((branch) => {
          this.labelBranch.push(branch.name);
          this.branchIds.push(branch.id);
          this.dataShopHD.push(0);
          this.dataShopFE.push(0);
          this.dataShopACS.push(0);
          this.dataDealerHD.push(0);
          this.dataDealerFE.push(0);
          this.dataDealerACS.push(0);
        });
        this.branchIds.map((it, index) => {
          this.$store.dispatch(ActionTypeZeroCampaign.GetStatisticDataByBranch, {
            name: it,
            time: this.timeFilter,
            onSuccess: (result) => {
              this.dataShopHD.splice(index, 1, result.totalShopRegisterHD.aggregate.count);
              this.dataShopFE.splice(index, 1, result.totalShopRegisterFE.aggregate.count);
              this.dataShopACS.splice(index, 1, result.totalShopRegisterACS.aggregate.count);
              this.dataDealerHD.splice(index, 1, result.totalDealerRegisterHD.aggregate.count);
              this.dataDealerFE.splice(index, 1, result.totalDealerRegisterFE.aggregate.count);
              this.dataDealerACS.splice(index, 1, result.totalDealerRegisterACS.aggregate.count);
            }
          });
        });
        // if (!this.isDepartmentPic) {

        //   return;
        // }

        // this.$store.dispatch(ActionTypeZeroCampaign.GetStatisticForDepartmentPic, {
        //   onSuccess: (result) => {
        //     this.dataDealerForDepartmentPic = [result.totalDealerRegisterHD.aggregate.count,
        //       result.totalDealerRegisterFE.aggregate.count, result.totalDealerRegisterACS.aggregate.count];
        //     this.dataShopForDepartmentPic = [result.totalShopRegisterHD.aggregate.count,
        //       result.totalShopRegisterFE.aggregate.count, result.totalShopRegisterACS.aggregate.count];
        //   }
        // });
      }
    });
  }
  public formatterNumber(value) {
    return Math.floor(value * 100) / 100;
  }
  public onChangeFilter() {
    this.fetchData();
  }
  public closeDialogExport() {
    this.exportTime = {
      from: null,
      to: null
    };
    this.visibleExport = false;
  }
  public openDialogExport() {
    //
  }
  public changeFilterList() {
    this.fetchDataList();
  }
  public changeFilterListShop() {
    this.fetchDataListShop();
  }
  public handleExport() {
    this.visibleExport = true;
    this.exportTime = {
      from: null,
      to: null
    };
  }
  public submitExport() {
    this.loadingExport = true;
    if (!this.exportTime || !this.exportTime.from) {
      this.exportError.from = true;
      this.loadingExport = false;

      return;
    }
    if (!this.exportTime || !this.exportTime.to) {
      this.exportError.to = true;
      this.loadingExport = false;

      return;
    }
    this.exportError = {
      from: false,
      to: false
    };
    this.$store.dispatch(ActionTypeZeroCampaign.ExportCampaignOverview, {
      form: {
        filter: {
          from: moment(this.exportTime.from),
          to: moment(this.exportTime.to)
        },
        type: 'ip_campaign_overview',
        isReIndex: true,
        createdBy: this.authUser.id
      },
      onSuccess: (data) => {
        // tslint:disable-next-line:early-exit
        if (data && data.insert_exports && data.insert_exports.returning && data.insert_exports.returning.length) {
          this.subscribeURL = <any> gqlClient.subscribe({
          query: zeroCampaignQuery.DOWNLOAD_EXPORT,
          variables : {id: data.insert_exports.returning[0].id},
          fetchPolicy: 'cache-first'
        }).subscribe((results) => {
          // tslint:disable-next-line: early-exit
          if (results.data && results.data.exports && results.data.exports.length
            && results.data.exports[0].exportStatus === 'done') {
            window.open(results.data.exports[0].url, '_blank');
            this.subscribeURL.unsubscribe();
            this.$message({
              type: 'success',
              message: this.$t('message.export_success').toString()
            });
            this.loadingExport = false;
            this.closeDialogExport();
          }
          // tslint:disable-next-line:early-exit
          if (results.data && results.data.exports && results.data.exports.length
            && results.data.exports[0].exportStatus === 'failed') {
            // window.open(results.data.exports[0].url, '_blank');
            this.subscribeURL.unsubscribe();
            const mess = 'Export failure!';
            this.$message({
              type: 'error',
              message: mess
            });
            this.loadingExport = false;
            this.closeDialogExport();
          }
        });
        }
      },
      onFailure: () => {
        const message = 'Export failure!';
        this.$message({
          message,
          type: 'error',
        });
        this.loadingExport = false;
        this.closeDialogExport();
      }
    });
  }
  public fetchDataListShop() {
    this.loadingListShop = true;
    const filter = {
      companyAlias: this.topListFilterShop === 'all' ? ['FE', 'ACS', 'HD'] : [this.topListFilterShop],
      createdAt: {
        from: this.timeFilter && this.timeFilter.length >= 2 ? moment(this.timeFilter[0]).format() : null,
        to: this.timeFilter && this.timeFilter.length >= 2 ? moment(this.timeFilter[1]).endOf('day').format() : null
      },
      top: 10
    };
    this.$store.dispatch(ActionTypeZeroCampaign.GetStatisticTopListShop, {
      form: filter,
      onSuccess: (data) => {
        this.dataShop = data;
        this.loadingListShop = false;
      }
    });
  }
  public fetchDataList() {
    this.loadingList = true;
    const filter = {
      companyAlias: this.topListFilter === 'all' ? ['FE', 'ACS', 'HD'] : [this.topListFilter],
      createdAt: {
        from: this.timeFilter && this.timeFilter.length >= 2 ? moment(this.timeFilter[0]).format() : null,
        to: this.timeFilter && this.timeFilter.length >= 2 ? moment(this.timeFilter[1]).endOf('day').format() : null
      },
      top: 10
    };
    this.$store.dispatch(ActionTypeZeroCampaign.GetStatisticTopList, {
      form: filter,
      onSuccess: (data) => {
        this.data = data;
        this.loadingList = false;
      }
    });
  }
  protected mounted() {
    this.$nextTick(() => {
      const breadcrumb: IBreadcrumb[] = [
        {
          label: 'title.zer-per-campaign',
          path: null,
          current: false
        },
      ];
      this.$store.commit(MutationType.SetBreadcrumb, breadcrumb);
      this.fetchData();
    });
  }
}
