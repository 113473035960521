import { cloneDeep } from 'lodash';
import moment from 'moment';
import { Avatar } from 'root/components/Avatar';
import { SearchByFilter } from 'root/components/SearchByFilter';
import { formatCurrency, FormatDateFullTime } from 'root/helpers';
import { IFilterInput } from 'root/models';
import { IState, MutationType } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import { SaleInHistoryLogs } from '../SaleInHistoryLogs';
import { ActionTypeSaleInReward, MutationTypeSaleInReward } from '../Store/types';
import { SaleInRewardDetail } from './Components/SaleInRewardDetail';
import { SaleInRewardForm } from './Components/SaleInRewardForm';
import './styles.scss';

const enum FilterKey {
  Type = 'type',
  Status = 'status',
  CreatedAt = 'createdAt'
}
enum SearchByKey {
  Code = 'code',
  Name = 'name'
}

@Component({
  template: require('./view.html'),
  components: {
    avatar: Avatar,
    'search-by-filter': SearchByFilter,
    'sale-in-reward-form': SaleInRewardForm,
    'sale-in-reward-detail': SaleInRewardDetail,
    'application-history-logs': SaleInHistoryLogs
  },
  computed: {
    ...mapState({
      data: (state: IState) => state.saleInCampaign.saleInReward.data,
      pagination: (state: IState) => state.saleInCampaign.saleInReward.pagination,
      loading: (state: IState) => state.saleInCampaign.saleInReward.loading,
    }),
    searchBy() {
      const result: IFilterInput[] = [];
      for (const key in SearchByKey) {
        // tslint:disable-next-line:early-exit
        if (SearchByKey.hasOwnProperty(key)) {
          const element = SearchByKey[key];
          result.push({
            key,
            value: element,
            name: this.$t(element)
          });
        }
      }

      return [{
        key: 'select',
        name: this.$t('select'),
        value: result
      }];
    },
    selectedFieldSearchBy() {
      const filterParams = cloneDeep(this.filterParams);

      return [{
        key: 'select',
        value: filterParams.searchBy ? filterParams.searchBy : []
      }];
    },
  }
})

export class RewardManagement extends Vue {
  public get dataFilter() {
    const type = [
      {
        value: 'device',
        name: 'Device',
        key: 'device',
      },
      {
        value: 'voucher',
        name: 'Voucher',
        key: 'voucher',
      },
      {
        value: 'tool',
        name: 'Tool',
        key: 'tool',
      }
    ];
    const status = [
      {
        value: 'applied',
        name: 'Applied',
        key: 'applied',
      },
      {
        value: 'not_applied',
        name: 'Not applied',
        key: 'not_applied',
      },
    ];

    return [
      {
        key: FilterKey.Type,
        name: 'Type',
        value: type
      },
      {
        key: FilterKey.Status,
        name: 'Status',
        value: status
      },
    ];
  }
  public get selectedFilter() {
    const _filterParams = cloneDeep(this.filterParams);

    return [
      {
        key: FilterKey.Type,
        value: _filterParams[FilterKey.Type]
      },
      {
        key: FilterKey.Status,
        value: _filterParams[FilterKey.Status]
      }
    ];
  }
  public get columns() {
    return [
      {
        label: 'Code',
        prop: 'code',
        fixed: true,
        width: 120
      },
      {
        label: 'Icon',
        prop: 'icon',
        width: 120
      },
      {
        label: 'Reward Name',
        prop: 'rewardName',
        width: 250
      },
      {
        label: 'Type',
        prop: 'rewardType',
        labelStyle: () => {
          return `text--capitalize`;
        },
        width: 120
      },
      {
        label: 'Unit',
        prop: 'unit',
        width: 120
      },
      {
        label: 'Unit Price',
        prop: 'price',
        formatter: (model: any) => {
          return model.price ? `${this.formatCurrency(model.price, false, 0, '.')} VND` : '';
        },
        width: 120
      },
      {
        label: 'Created By',
        formatter: (model: any) => {
          return model.creator && model.creator.name ? model.creator.name : '--';
        },
        width: 200
      },
      {
        label: 'Created At',
        formatter: (model: any) => {
          return model && model.createdAt ? this.formatDate(model.createdAt) : '--';
        },
        width: 200
      },
      {
        label: 'Status',
        formatter: (model: any) => {
          return model.status ? model.status.split('_').join(' ') : '--';
        },
        labelStyle: (model: any) => {
          return `text--capitalize sell-in-status__${model.status}`;
        },
        width: 120
      },
      {
        prop: 'action',
        label: '',
        align: 'center',
        fixed: 'right',
        width: 60
      }
    ];
  }
  public formatCurrency = formatCurrency;
  public visibleForm: boolean = false;
  public visibleDetail: boolean = false;
  public visibleHistory: boolean = false;
  public formatDate = FormatDateFullTime;
  public searchText: string = '';
  public filterParams = {
    searchBy: ['code', 'name'],
    [FilterKey.Type]: [],
    [FilterKey.Status]: [],
    [FilterKey.CreatedAt]: [],
  };
  public FilterRequestTime: any = {
    from: null,
    to: null,
  };
  public detailId: string = '';
  public onClickCreate() {
    this.detailId = '';
    this.visibleForm = true;
  }
  public onClickUpdate(id: string) {
    this.detailId = id;
    this.visibleForm = true;
  }
  public onClickHistory() {
    this.visibleHistory = true;
  }
  public clickDetail(id: string) {
    this.detailId = id;
    this.visibleDetail = true;
  }
  public changeFilter(model, time) {
    if (time && time.from && time.to) {
      this.FilterRequestTime = time;
    }
    if (time && !time.from && !time.to) {
      this.FilterRequestTime = {
        from: null,
        to: null,
      };
    }
    const type = model.find((e) => e.key === FilterKey.Type);
    const status = model.find((e) => e.key === FilterKey.Status);

    const nextFilter = {
      [FilterKey.Type]: type ? type.value : [],
      [FilterKey.Status]: status ? status.value : [],
    };
    this.filterParams = {
      ...cloneDeep(this.filterParams),
      ...nextFilter
    };
    this.fetchData();
  }
  public changeSearchBy(model: IFilterInput[]) {
    this.filterParams = {
      ...this.filterParams,
      searchBy: model[0].value
    };

    this.fetchData();
  }
  public onEnterFilter(value) {
    this.searchText = value;
    this.$store.commit(MutationTypeSaleInReward.PaginationChange, {
      page: 1,
      limit: 20
    });

    this.fetchData();
  }
  public getActions(status: any) {
    if (status === 'applied') {
      return ['view'];
    }

    return ['view', 'edit'];
  }
  public handleActions({act, model}) {
    if (act === 'view') {
      this.clickDetail(model.id);

      return;
    }
    this.onClickUpdate(model.id);
  }
  public closeDialog() {
    this.visibleForm = false;
  }
  public createSuccess() {
    this.visibleForm = false;
    this.fetchData();
  }
  public fetchData() {
    const text = cloneDeep(this.searchText);
    const { type, status, searchBy} = this.filterParams;
    this.$store.commit(MutationTypeSaleInReward.FilterChange, {
      _and: {
        mode: 'special',
        data: {
          ...type && type.length ? {
            rewardType: {
              _in: type
            }
          } : {},
          ...status && status.length ? {
            status: {
              _in: status
            }
          } : {},
          ...this.FilterRequestTime && this.FilterRequestTime.from && this.FilterRequestTime.to ? {
            createdAt : {
              _gte: this.FilterRequestTime.from,
              _lte: moment(this.FilterRequestTime.to).clone().endOf('day')
            }
          } : null,
          ...text && text.trim().length > 0 && searchBy && searchBy.length ? {
            _or: [
              {
                ...searchBy.includes('code') ? {
                  code: {
                    _ilike: `%${text}%`
                  }
                } : {},
              },
              {
                ...searchBy.includes('name') ? {
                  rewardName: {
                    _ilike: `%${text}%`
                  }
                } : {},
              }
            ].filter((e) => !!e)
          } : {}
        }
      }
    });
    this.$store.dispatch(ActionTypeSaleInReward.FilterNoCache);
  }
  protected mounted() {
    this.$store.commit(MutationType.SetBreadcrumb, 'Reward Management');
    this.fetchData();
  }
}
