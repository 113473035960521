import { IEWarrantyState } from 'root/admin/ActiveEWarranty/Store';
import { IBannerState } from 'root/admin/BannerManagement/store';
import { IBranchState } from 'root/admin/Branch/Store';
import { IBranchManageState } from 'root/admin/BranchAndGroup/BranchManage/Store';
import { IGroupManageState } from 'root/admin/BranchAndGroup/GroupManage/Store';
import { IAclCampaignState } from 'root/admin/CustomCampaign/ACLCampaign/Store';
import { INewRACampaignState } from 'root/admin/CustomCampaign/NewRACampaign2023/Store';
import { IRaCampaignState } from 'root/admin/CustomCampaign/RACampaign/Store';
import { ISaleInCampaignState } from 'root/admin/CustomCampaign/SaleInCampaign/Store';
import { IEvaluatePhotoState } from 'root/admin/CustomCampaign/Store';
import { IZeroCampaignState } from 'root/admin/CustomCampaign/ZeroPercentCampaign/Store';
import { IAccountStaffState } from 'root/admin/Dealer/AccountStaff/Store';
import { IDealerShopState } from 'root/admin/Dealer/DealerShop/Store';
import { IDealerState } from 'root/admin/Dealer/Store';
import { IDeviceTypeState } from 'root/admin/DeviceType/Store';
import { IGroupState } from 'root/admin/Group/Store';
import { IInvoiceState } from 'root/admin/Invoice/Store';
import { IDealerTypeState } from 'root/admin/ManageDealerType/Store';
import { IFeedbackState } from 'root/admin/ManageFeedback/Store';
import { IManageImportState } from 'root/admin/ManageImport/Store';
import { INotificationState } from 'root/admin/NewsAnnoucement/store';
import { IPointsState } from 'root/admin/Point/Store';
import { IProductState } from 'root/admin/Product/Store';
import { IProductTransState } from 'root/admin/ProductTransaction/Store';
import { ICampaignState } from 'root/admin/Redemption/Campaign/Store';
import { IConditionState } from 'root/admin/Redemption/Condition/Store';
import { IRedemptionState } from 'root/admin/Redemption/Store';
import { IRegionState } from 'root/admin/Region/Store';
import { IRoleState } from 'root/admin/Role/Store';
import { ITeamStaffState } from 'root/admin/TeamStaff/Store';
import { ITransactionViewState } from 'root/admin/TransactionView/Store';
import { IUserState } from 'root/admin/User/Store';

// define interface
export interface IGlobalConfiguration {
  listCentre: [
    {
      name: string;
      value: string;
    }
  ];
  listCountry: [];
}
export interface IGlobalState {
  localVN: any;
  authUser: any;
  isLoading: boolean;
  lastRoutePath: string;
  currentLocale: string;
  // authUser: IUser;
  topAlert: IAlertState;
  layout: Layout;
  globalConfig: IGlobalConfiguration;
  userCreating: boolean;
  // localVN: IProvince[];
  breakCrumb: string | IBreadcrumb[];
  back: boolean;
  showCollapse: boolean;
  typeCollapse: boolean;
  oneSignalInitial: boolean;
  isAccountExpired: boolean;
  isRefesh: boolean;
}

export enum TypeAlert {
  Success = 'success',
  Warning = 'warning',
  Info = 'info',
  Error = 'error'
}

export interface IAlertState {
  opened: boolean;
  message: string;
  type: TypeAlert;
  icon: string;
}

export interface IBreadcrumb {
  path: string;
  current: boolean;
  label: string;
}

export interface IRouteState {
  path: string;
  params: object;
  query: object;
}

// export interface IPaginationState {
//   page?: number;
//   size?: number;
//   offset?: number;
//   limit?: number;
// }

export interface IState {
  user: IUserState;
  global: IGlobalState;
  route: IRouteState;
  role: IRoleState;
  aclCampaign: IAclCampaignState;
  raCampaign: IRaCampaignState;
  zeroCampaign: IZeroCampaignState;
  group: IGroupState;
  dealer: IDealerState;
  dealerType: IDealerTypeState;
  dealerShop: IDealerShopState;
  accountStaff: IAccountStaffState;
  feedback: IFeedbackState;
  branch: IBranchState;
  product: IProductState;
  banners: IBannerState;
  productTrans: IProductTransState;
  point: IPointsState;
  deviceType: IDeviceTypeState;
  branchManage: IBranchManageState;
  groupManage: IGroupManageState;
  notification: INotificationState;
  region: IRegionState;
  redemption: IRedemptionState;
  campaign: ICampaignState;
  condition: IConditionState;
  invoice: IInvoiceState;
  manageImport: IManageImportState;
  transactionView: ITransactionViewState;
  productEWarranty: IEWarrantyState;
  teamStaff: ITeamStaffState;
  customCampaign: IEvaluatePhotoState;
  newRACampaign: INewRACampaignState;
  saleInCampaign: ISaleInCampaignState;
}

export interface IFormState<T> {
  payload: T;
  valid: boolean;
  errorMessage: string;
}

export interface IBreadcrumbItem {
  text: string;
  link?: string;
}

export enum Layout {
  Home = 'home-layout',
  Login = 'login-layout'
}

export interface IAvatarDefault {
  user: string;
  image: string;
  video: string;
  project: string;
  plan: string;
  message: string;
}

export interface ICustomLazy {
  src: string;
  loading?: string;
  error?: string;
}

// define type screen

export enum ViewPortSize {
  Mobile = 768,
  Tablet = 1023,
  Desktop = 1215,
  Widescreen = 1407
}

// define type mutation and action

export enum MutationType {
  // global
  SetCurrentLocale = 'setCurrentLocale',
  ClosePlashScreen = 'closePlashScreen',
  WindowResized = 'windowResized',
  OpenPlashScreen = 'openPlashScreen',
  SetLayout = 'setLayout',
  SetGlobalConfig = 'setGlobalConfig',
  SetMenuCollapse = 'setMenuCollapse',
  OpenTopAlert = 'openTopAlert',
  CloseTopAlert = 'closeTopAlert',
  SetBreadcrumb = 'SetBreadcrumb',
  ClearBreadcrumb = 'ClearBreadcrumb',
  // users
  SetCreatingUser = 'SetCreatingUser',
  SetLocalVN = 'SetLocalVN',
  SetCreatedUser = 'SetCreatedUser',
  // login
  Authenticated = 'authenticated',
  Unauthenticated = 'unauthenticated',
  SetProfile = 'setProfile',

  // profile
  LoadingProfile = 'loadingProfile',
  ClearStore = 'clearStore',
  // back
  SetBack = 'setBack',
  ClearBack = 'clearBack',
  // collapse
  SetTypeCollapse = 'setTypeCollapse',
  ClearTypeCollapse = 'clearTypeCollapse',
  SetShowCollapse = 'setShowCollapse',
  ClearShowCollapse = 'clearShowCollapse',
  SetOneSignalInitial = 'setOneSignalInitial',
  SetAccountExpired = 'setAccountExpired',

  // role & group
  GetRole = 'getRole',
  GetGroup = 'getGroup',
  getGroupSystem = 'getGroupSystem',
  GetListDealer = 'getListDealer',
  GetListSaleman = 'getListSaleman',
  GetTargetDetail = 'getTargetDetail',
  GetListBranchManage = 'GetListBranchManage',
  GetBranch = 'getBranch',
  GetProduct = 'getProduct',
  GetProductTrans = 'getProductTrans',
  GetPoints = 'getPoints',
  SetIsRefesh = 'setIsRefesh'
}

export enum ActionType {
  // global
  InitializeSettings = 'initializeSettings',
  CatchException = 'catchException',
  SetLayout = 'setLayout',
  SetGlobalConfig = 'setGlobalConfig',
  GoBack = 'goBack',
  GoToRoute = 'goToRoute',
  CollapseMenu = 'collapseMenu',
  OpenTopAlert = 'openTopAlert',
  CloseTopAlert = 'closeTopAlert',

  // navigator
  DirectNavigator = 'directNavigator',

  // login
  ChangeStateAuth = 'changeStateAuth',
  Authenticated = 'authenticated',
  Unauthenticated = 'unauthenticated',

  // profile
  UpdateProfile = 'updateProfile',
  LoadingProfile = 'loadingProfile',
  ReloadProfile = 'reloadProfile',
  GetProfile = 'GetProfile',

  // user-notification-setting
  UserNotificationSettingUpdate = 'userNotificationSettingUpdate'
}

export enum GetterTypeUser {
  PermissionViewAllCentre = 'permissionViewAllCentre',
  PermissionEditRole = 'permissionEditRole',
  GetAuthuserRole = 'GetAuthuserRole'
}

export enum GetterTypeGlobal {
  GetCentreName = 'getCentreName',
  GetCentreValue = 'getCentreValue'
}
