import { Form } from 'element-ui';
import { cloneDeep } from 'lodash';
import {
  ruleCompare,
  ruleEmail,
  ruleEmailNoRequired,
  ruleMinLength,
  ruleNumber,
  rulePhoneNumber,
  ruleRequired,
} from 'root/helpers';
import { dealerFormDefault, IDealerForm } from 'root/models/Dealer';
import {
  IUser
} from 'root/models/User';
import { IState } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import './styles.scss';

@Component({
  template: require('./view.html'),
  props: {
    visible: Boolean,
    userId: String,
    handleSubmit: Function
  },
  computed: {
    ...mapState({
      authUser: (state: IState) => state.global.authUser,
      userDetailLoading: () => false,
      isRefesh: (state: IState) => state.global.isRefesh
    }),
  }
})
export class ChangePassword extends Vue {
  public get isUpdating(): boolean {
    return !!this.userId;
  }

  public get rules() {
    return {
      required: ruleRequired(),
      rulePhoneNumber: rulePhoneNumber(),
      min: ruleMinLength(8),
      ruleEmail: ruleEmail(),
      ruleEmailNoRequired: ruleEmailNoRequired(),
      compare: ruleCompare(this.form.password),
      number: ruleNumber()
    };
  }

  public $refs: {
    form: Form;
  };
  public loading: boolean = false;
  public authUser: IUser;
  public userDetail: IUser;
  public form: IDealerForm = dealerFormDefault();
  public detail: IUser;
  public route: any;
  public userId: string;
  public isShowPass: boolean = false;
  public handleSubmit: (pass: string) => {};

  public handleShowPass() {
    this.isShowPass = !this.isShowPass;
  }

  public async submit() {
    //
    this.$refs.form.validate(async (valid) => {
      if (valid) {
        const form = <any> cloneDeep(this.form);
        this.handleSystemUser(form);
      }

      return;
    });
  }

  public back() {
    this.$emit('update:visible', false);
  }
  public openDialog() {

    if (this.$refs.form) {
      this.$refs.form.clearValidate();
    }
  }
  public closeDialog() {
    this.form = dealerFormDefault();
    this.back();
  }

  protected mounted() {
    //
  }

  private async handleSystemUser(form: any) {
    this.handleSubmit(form.password);
  }
}
