import moment from 'moment';
import { gqlClient } from '../Core';
import { CRUDGqlService, ICRUDGqlService } from '../Core/crud';
import { saleInCampaignQuery } from '../Query/saleInCampaign';

export interface ISaleInCampaignGqlService extends ICRUDGqlService<any> {
  saleInProduct: ICRUDGqlService<any>;
  saleInReward: ICRUDGqlService<any>;
  saleInLogs: ICRUDGqlService<any>;
  getDealerTypes(): Promise<any>;
  getConditionMetadata(): Promise<any>;
  checkCampaignExistCode(code: string): Promise<any>;
  getParticipantList(role: any, search: any, type: any, branch: any, proShopType: any,
                     businessType: any, potentialType: any, pagination: any): Promise<any>;
  createSaleInProduct(form: any): Promise<any>;
  updateSaleInProduct(id: any, form: any): Promise<any>;
  createSaleInReward(form: any): Promise<any>;
  updateSaleInReward(id: any, form: any): Promise<any>;
  getSaleInCampaignDetail(id: any): Promise<any>;
  getSaleInCampaignDetailParticipantList(id: any, confirmedAt: boolean,
                                         search: any, type: any, branch: any, time: any, pagination: any): Promise<any>;
  getPinedCampaigns(): Promise<any>;
  pinedCampaigns(id: any, type: any): Promise<any>;
  changeCampaignStatus(form: any): Promise<any>;
  upsertSaleInProduct(form: any): Promise<any>;
  createSaleInCampaign(form: any): Promise<any>;
  updateSaleInCampaign(id: any , form: any, information: any, conditionGroup: any): Promise<any>;
  importSaleInCampaign(form: any): Promise<any>;
  getImportModelLogs(importId: any): Promise<any>;
  checkModelNumberExits(model: any): Promise<any>;
  checkRewardCodeExits(code: any): Promise<any>;
  getHistoryImportModelLogs(referenceId: any, typeLogs: any, status: any, search: any, pagination: any): Promise<any>;
  getQuantityListParticipant(participantId: any, pagination: any): Promise<any>;
  getRewardListParticipant(participantId: any, pagination: any): Promise<any>;
  sendConfirm(form: any): Promise<any>;
  getImportLogId(importId: any): Promise<any>;
  changeParticipant(form: any): Promise<any>;
  getParticipantLogs(campaignId: any): Promise<any>;
  updateCampaignOnInactive(form: any): Promise<any>;
}
export function saleInCampaignGqlService(): ISaleInCampaignGqlService {
  async function getDealerTypes(): Promise<any> {
    const result = await gqlClient.query({
      query: saleInCampaignQuery.GET_DEAlER_TYPES
    });
    if (result.errors) {
      throw result.errors;
    }

    return result.data.dealer_types;
  }
  async function getConditionMetadata(): Promise<any> {
    const result = await gqlClient.query({
      query: saleInCampaignQuery.GET_CONDITION_METADATA
    });
    if (result.errors) {
      throw result.errors;
    }

    return result.data;
  }
  async function checkCampaignExistCode(code: string): Promise<any> {
    const result = await gqlClient.query({
      query: saleInCampaignQuery.CHECK_CAMPAIGN_CODE_EXIST,
      variables: {
        code
      }
    });
    if (result.errors) {
      throw result.errors;
    }

    return result.data.campaigns_aggregate.aggregate.count;
  }
  async function getParticipantList(role: any, search: any, type: any, branch: any, proShopType: any,
                                    businessType: any, potentialType: any, pagination: any): Promise<any> {
    const {limit, page} = pagination;
    const offset = (page - 1) * limit;
    const result = await gqlClient.query({
      query: saleInCampaignQuery.GET_PARTICIPANT_LIST,
      variables: {
        limit,
        offset,
        where: {
          role: {
            alias: {
              _in: type && type.length ? type : role && role.length ? role : ['daikin_dealer', 'sub_dealer']
            }
          },
          branchId: {
            _in: branch && branch.length ? branch : null
          },
          proshopTypeId: {
            _in: proShopType && proShopType.length ? proShopType : null
          },
          businessTypeId: {
            _in: businessType && businessType.length ? businessType : null
          },
          potentialTypeId: {
            _in: potentialType && potentialType.length ? potentialType : null
          },
          ...search && search.length ? {
            _or: [
              {
                name: {
                  _ilike: search
                }
              },
              {
                dealerCode: {
                  _ilike: search
                }
              },
              {
                phoneNumber: {
                  _ilike: search
                }
              }
            ]
          } : {}
        }
      }
    });
    if (result.errors) {
      throw result.errors;
    }

    return result.data;
  }
  async function createSaleInProduct(form: any): Promise<any> {
    const result = await gqlClient.mutate({
      mutation: saleInCampaignQuery.SALE_IN_PRODUCT_CREATE,
      variables: {
        form
      }
    });
    if (result.errors) {
      throw result.errors;
    }

    return result.data;
  }
  async function updateSaleInProduct(id: any, form: any): Promise<any> {
    const result = await gqlClient.mutate({
      mutation: saleInCampaignQuery.SALE_IN_PRODUCT_UPDATE,
      variables: {
        id, form
      }
    });
    if (result.errors) {
      throw result.errors;
    }

    return result.data;
  }
  async function upsertSaleInProduct(form: any): Promise<any> {
    const result = await gqlClient.mutate({
      mutation: saleInCampaignQuery.SALE_IN_PRODUCT_UPSERT,
      variables: {
        form
      }
    });
    if (result.errors) {
      throw result.errors;
    }

    return result.data;
  }
  async function createSaleInReward(form: any): Promise<any> {
    const result = await gqlClient.mutate({
      mutation: saleInCampaignQuery.SALE_IN_REWARD_CREATE,
      variables: {
        form
      }
    });
    if (result.errors) {
      throw result.errors;
    }

    return result.data;
  }
  async function updateSaleInReward(id: any, form: any): Promise<any> {
    const result = await gqlClient.mutate({
      mutation: saleInCampaignQuery.SALE_IN_REWARD_UPDATE,
      variables: {
        id, form
      }
    });
    if (result.errors) {
      throw result.errors;
    }

    return result.data;
  }
  async function getSaleInCampaignDetail(id: any): Promise<any> {
    const result = await gqlClient.query({
      query: saleInCampaignQuery.GET_SALE_IN_CAMPAIGN_DETAIL,
      variables: {
        id
      },
      fetchPolicy: 'network-only'
    });
    if (result.errors) {
      throw result.errors;
    }

    return result.data.campaigns;
  }
  async function getSaleInCampaignDetailParticipantList(id: any, confirmedAt: boolean, search: any, type: any,
                                                        branch: any, time: any, pagination: any): Promise<any> {
    const {limit, page} = pagination;
    const offset = (page - 1) * limit;
    const result = await gqlClient.query({
      query: saleInCampaignQuery.GET_SALE_IN_CAMPAIGN_DETAIL_PARTICIPANT_LIST,
      variables: {
        limit,
        offset,
        where: {
          campaignId: {
            _eq: id
          },
          ...confirmedAt ? {
            sentConfirmId : {
              _is_null: true
            }
          } : {},
          ...time && (time.from || time.to) ? {
            confirmedAt: {
              ...time.from ? {
                _gte: time.from
              } : {},
              ...time.to ? {
                _lte: moment(time.to).clone().endOf('day')
              } : {},
            }
          } : {},
          dealer: {
            role: {
              alias: {
                _in: type
              }
            },
            branch: {
              id: {
                _in: branch
              }
            },
            _or: [
              {
                name: {
                  _ilike: search
                }
              },
              {
                dealerCode: {
                  _ilike: search
                }
              }
            ]
          },
        }
      }
    });
    if (result.errors) {
      throw result.errors;
    }
    let resultAll = null;
    if (confirmedAt) {
      resultAll = await gqlClient.query({
        query: saleInCampaignQuery.GET_SEND_CONFIRM_LIST_FORM,
        variables: {
          where: {
            campaignId: {
              _eq: id
            },
            sentConfirmId : {
              _is_null: true
            },
          }
        }
      });
      if (resultAll.errors) {
        throw resultAll.errors;
      }
    }

    return {data: result.data, dataAll: resultAll};
  }
  async function getPinedCampaigns(): Promise<any> {
    const result = await gqlClient.query({
      query: saleInCampaignQuery.GET_PINED_CAMPAIGN_LIST,
    });
    if (result.errors) {
      throw result.errors;
    }

    return result.data.campaigns;
  }
  async function pinedCampaigns(id: any, type: any): Promise<any> {
    const result = await gqlClient.mutate({
      mutation: saleInCampaignQuery.PIN_SALE_IN_CAMPAIGN,
      variables: {
        id, type
      }
    });
    if (result.errors) {
      throw result.errors;
    }

    return result.data;
  }
  async function changeCampaignStatus(form: any): Promise<any> {
    const result = await gqlClient.mutate({
      mutation: saleInCampaignQuery.CHANGE_CAMPAIGN_STATUS,
      variables: {
        form
      }
    });
    if (result.errors) {
      throw result.errors;
    }

    return result;
  }
  async function createSaleInCampaign(form: any): Promise<any> {
    const result = await gqlClient.mutate({
      mutation: saleInCampaignQuery.CREATE_SALE_IN_CAMPAIGN,
      variables: {
        form
      }
    });
    if (result.errors) {
      throw result.errors;
    }

    return result;
  }
  async function updateSaleInCampaign(id: any, form: any, information: any, conditionGroup: any): Promise<any> {
    const result = await gqlClient.mutate({
      mutation: saleInCampaignQuery.UPDATE_SALE_IN_CAMPAIGN,
      variables: {
        id, form, information, conditionGroup
      },
      fetchPolicy: 'no-cache',
      errorPolicy: 'ignore'
    });
    if (result.errors) {
      throw result.errors;
    }

    return result;
  }
  async function importSaleInCampaign(form: any): Promise<any> {
    const result = await gqlClient.mutate({
      mutation: saleInCampaignQuery.IMPORT_SALE_IN_CAMPAIGN,
      variables: {
        form
      }
    });
    if (result.errors) {
      throw result.errors;
    }

    return result;
  }
  async function getImportModelLogs(importId: any): Promise<any> {
    const result = await gqlClient.mutate({
      mutation: saleInCampaignQuery.GET_IMPORT_MODEL_LOGS,
      variables: {
        importId
      }
    });
    if (result.errors) {
      throw result.errors;
    }

    return result.data.si_campaign_import_logs;
  }
  async function checkModelNumberExits(model: any): Promise<any> {
    const result = await gqlClient.mutate({
      mutation: saleInCampaignQuery.CHECK_MODEL_NUMBER_EXITS,
      variables: {
        model
      }
    });
    if (result.errors) {
      throw result.errors;
    }

    return result.data.si_campaign_models_aggregate.aggregate.count;
  }
  async function checkRewardCodeExits(code: any): Promise<any> {
    const result = await gqlClient.mutate({
      mutation: saleInCampaignQuery.CHECK_REWARD_CODE_EXITS,
      variables: {
        code
      }
    });
    if (result.errors) {
      throw result.errors;
    }

    return result.data.si_campaign_rewards_aggregate.aggregate.count;
  }
  async function getHistoryImportModelLogs(referenceId: any, typeLogs: any, status: any,
                                           search: any, pagination: any): Promise<any> {
    const { limit, page} = pagination;
    const offset = (page - 1) * limit;
    const result = await gqlClient.query({
      query: saleInCampaignQuery.GET_HISTORY_IMPORT_MODEL_LOGS,
      variables: {
        limit, offset,
        where: {
          ...referenceId ? {
            referenceId : {
              _eq: referenceId
            }
          } : {},
          type: {
            _in: typeLogs
          },
          importStatus: {_in: status},
          importCode: {_ilike: search}
        }
      }
    });
    if (result.errors) {
      throw result.errors;
    }

    return result.data;
  }
  async function getQuantityListParticipant(participantId: any, pagination: any): Promise<any> {
    const { limit, page} = pagination;
    const offset = (page - 1) * limit;
    const result = await gqlClient.query({
      query: saleInCampaignQuery.GET_QUANTITY_LIST_PARTICIPANT,
      variables: {
        participantId, limit, offset
      }
    });
    if (result.errors) {
      throw result.errors;
    }

    return result.data;
  }
  async function getRewardListParticipant(participantId: any, pagination: any): Promise<any> {
    const { limit, page} = pagination;
    const offset = (page - 1) * limit;
    const result = await gqlClient.query({
      query: saleInCampaignQuery.GET_REWARD_LIST_PARTICIPANT,
      variables: {
        participantId, limit, offset
      }
    });
    if (result.errors) {
      throw result.errors;
    }

    return result.data;
  }
  async function sendConfirm(form: any): Promise<any> {
    const result = await gqlClient.mutate({
      mutation: saleInCampaignQuery.SEND_CONFIRM,
      variables: {
        form
      }
    });
    if (result.errors) {
      throw result.errors;
    }

    return result.data;
  }
  async function getImportLogId(importId: any): Promise<any> {
    const result = await gqlClient.query({
      query: saleInCampaignQuery.GET_IMPORT_LOG_ID,
      variables: {
        importId
      }
    });
    if (result.errors) {
      throw result.errors;
    }

    return result.data.si_campaign_import_logs;
  }
  async function changeParticipant(form: any): Promise<any> {
    const result = await gqlClient.mutate({
      mutation: saleInCampaignQuery.CHANGE_PARTICIPANT,
      variables: {
        form
      },
      fetchPolicy: 'no-cache'
    });
    if (result.errors) {
      throw result.errors;
    }

    return result.data;
  }
  async function getParticipantLogs(campaignId: any): Promise<any> {
    const result = await gqlClient.query({
      query: saleInCampaignQuery.GET_PARTICIPANT_LOGS,
      variables: {
        campaignId
      },
      fetchPolicy: 'network-only'
    });
    if (result.errors) {
      throw result.errors;
    }

    return result.data.si_campaign_logs;
  }
  async function updateCampaignOnInactive(form: any): Promise<any> {
    const result = await gqlClient.mutate({
      mutation: saleInCampaignQuery.UPDATE_CAMPAIGN_INACTIVE,
      variables: {
        form
      }
    });
    if (result.errors) {
      throw result.errors;
    }

    return result.data;
  }

  return {
    ...CRUDGqlService<any>('campaigns', saleInCampaignQuery),
    getDealerTypes,
    getConditionMetadata,
    checkCampaignExistCode,
    getParticipantList,
    createSaleInProduct,
    updateSaleInProduct,
    createSaleInReward,
    updateSaleInReward,
    getSaleInCampaignDetail,
    getSaleInCampaignDetailParticipantList,
    getPinedCampaigns,
    pinedCampaigns,
    changeCampaignStatus,
    upsertSaleInProduct,
    createSaleInCampaign,
    updateSaleInCampaign,
    importSaleInCampaign,
    getImportModelLogs,
    checkModelNumberExits,
    checkRewardCodeExits,
    getHistoryImportModelLogs,
    getQuantityListParticipant,
    getRewardListParticipant,
    sendConfirm,
    getImportLogId,
    changeParticipant,
    getParticipantLogs,
    updateCampaignOnInactive,
    saleInProduct: CRUDGqlService<any>('si_campaign_models', saleInCampaignQuery.saleInProduct),
    saleInReward: CRUDGqlService<any>('si_campaign_rewards', saleInCampaignQuery.saleInReward),
    saleInLogs: CRUDGqlService<any>('si_campaign_logs', saleInCampaignQuery.saleInLogs),
  };
}
