import { gqlClient } from '../Core';
import { CRUDGqlService, ICRUDGqlService } from '../Core/crud';
import { redemption, redemptionQuery } from '../Query/redemption';

export interface IRedemptionGqlService extends ICRUDGqlService<any> {
  createRedemption(form: any): Promise<any>;
  updateRedemption(id: string, set: any): Promise<any>;
  getRedeemDetail(form: any): Promise<any>;
  searchVoucher(code: string): Promise<any>;
  updateVoucher(code: any, id: string): Promise<any>;
  getListVoucher(where: any): Promise<any>;
  addVoucherCode(form: any): Promise<any>;
}
export function RedemptionGqlService(): IRedemptionGqlService {
  async function getRedeemDetail(form: any): Promise<any> {
    const results = await gqlClient.query({
      query: redemptionQuery.GET_REDEEM_DETAIL,
      variables: {
        form
      },
      fetchPolicy: 'network-only'
    });
    if (results.errors) {
      throw results.errors;
    }

    return results.data.getRedeemDetail;
  }
  async function createRedemption(form: any): Promise<any> {
    const results = await gqlClient.mutate({
      mutation: redemptionQuery.CREATE_REDEMPTION,
      variables: {
        form,
      }
    });
    if (results.errors) {
      throw results.errors;
    }

    return results;
  }
  async function addVoucherCode(form: any): Promise<any> {
    const results = await gqlClient.mutate({
      mutation: redemptionQuery.ADD_VOUCHER_CODE,
      variables: {
        form,
      }
    });
    if (results.errors) {
      throw results.errors;
    }

    return results;
  }
  async function updateRedemption(id: string, set: any): Promise<any> {
    const results = await gqlClient.mutate({
      mutation: redemptionQuery.UPDATE_REDEMPTION,
      variables: {
        id,
        set
      }
    });
    if (results.errors) {
      throw results.errors;
    }

    return results;
  }
  async function searchVoucher(code: string): Promise<any> {
    const results = await gqlClient.query({
      query: redemptionQuery.SEARCH_VOUCHER,
      variables: {
        code
      },
      fetchPolicy: 'network-only'
    });
    if (results.errors) {
      throw results.errors;
    }

    return results.data;
  }
  async function getListVoucher(where: any): Promise<any> {
    const results = await gqlClient.query({
      query: redemptionQuery.GET_LIST_VOUCHER,
      variables: {
        where
      },
      fetchPolicy: 'network-only'
    });
    if (results.errors) {
      throw results.errors;
    }

    return results.data;
  }
  async function updateVoucher(code: any, id: string): Promise<any> {
    const results = await gqlClient.mutate({
      mutation: redemptionQuery.UPDATE_VOUCHER,
      variables: {
        code,
        id
      }
    });
    if (results.errors) {
      throw results.errors;
    }

    return results;
  }

  return {
    ...CRUDGqlService<any>(redemption, redemptionQuery),
    createRedemption,
    updateRedemption,
    getRedeemDetail,
    searchVoucher,
    updateVoucher,
    getListVoucher,
    addVoucherCode
  };
}
