// import { Message } from 'element-ui';
import { get } from 'lodash';
import { parseParamUrl } from 'root/helpers';
import { RouteDictionary } from 'root/router/types';
import { deleteTagForNotification } from 'root/services';
import { DaikinAuth } from 'root/services/auth';
import { ConsoleLogger } from 'root/services/logger/console';
import VueRouter from 'vue-router';
import { ActionContext } from 'vuex';
import { i18n } from '../locales';
import { ActionType, IState, Layout, MutationType, TypeAlert } from './types';

export const actions = {
  // global
  [ActionType.InitializeSettings]({ commit }: ActionContext<IState, IState>) {
    const locale = 'en';
    i18n().locale = locale;
    commit(MutationType.SetCurrentLocale, locale);
  },
  [ActionType.SetLayout]({ commit }: ActionContext<IState, IState>) {
    commit(MutationType.SetLayout);
  },
  [ActionType.SetGlobalConfig](
    { commit }: ActionContext<IState, IState>,
    globalConfig
  ) {
    commit(MutationType.SetGlobalConfig, globalConfig);
  },
  [ActionType.GoBack](_: ActionContext<any, any>, router: VueRouter) {
    router.back();
  },
  [ActionType.GoToRoute](
    _: ActionContext<any, any>,
    { router, route, paramUrl }
  ) {
    const method = typeof route === 'string' ? 'push' : route.method || 'push';
    router[method](parseParamUrl(route, paramUrl));
  },
  [ActionType.CollapseMenu](
    { commit }: ActionContext<any, any>,
    isCollapse: boolean
  ) {
    commit(MutationType.SetMenuCollapse, isCollapse);
  },

  async [ActionType.CatchException](
    { commit }: ActionContext<IState, IState>,
    // ex: IErrorException
    ex
  ) {
    const graphQLErrors = get(ex, 'graphQLErrors');
    // extensions = graphQLErrors && graphQLErrors[0] ? graphQLErrors[0].extensions : null;
    let message =
      graphQLErrors && graphQLErrors[0]
        ? graphQLErrors[0].message
        : ex.code
        ? i18n().t(ex.code) !== ex.code
          ? i18n().t(ex.code)
          : ex.message
        : ex.message;
    ConsoleLogger.error(ex);
    if (ex.code && ex.code === 'user_not_found') {
      message =
        'There is no user record corresponding to this identifier. The user may have been deleted.';
    }
    if (ex.message && ex.message === 'not_correct_password') {
      message = 'The password is invalid or the user does not have a password.';
    }

    commit(MutationType.OpenTopAlert, {
      message,
      type: TypeAlert.Error
    });

    return;
  },

  [ActionType.CloseTopAlert]({ commit }: ActionContext<IState, IState>) {
    commit(MutationType.CloseTopAlert);
  },
  // [ActionType.GetProfile](
  //   {}: ActionContext<IState, IState>
  // ) {
  //   return getProfile();
  // },
  // login
  async [ActionType.ChangeStateAuth](
    { commit }: ActionContext<IState, IState>,
    { router, user }
  ) {
    if (user) {
      // get profile
      commit(MutationType.OpenPlashScreen);
      /*
      try {
        const profile = await dispatch(ActionType.GetProfile);
        commit(MutationType.ClosePlashScreen);
        // can not get profile, go back to login page
        if (!profile) {
          dispatch(ActionType.CatchException, {
            message: 'This user doesn\'t exist in web System'
          });

          commit(MutationType.SetLayout, Layout.Login);
          router.replace(RouteDictionary.Login);

          return DaikinAuth.signOut();
          // logout
        }
        commit(MutationType.Authenticated, profile);
        commit(MutationType.SetLayout, Layout.Home);

        const localResult = await getAdministrationData();
        commit(MutationType.SetLocalVN, localResult.data.getAdministrationData);
        dispatch(ActionTypeConfiguration.ConfigurationFilter, {
          isNetworkOnly: true,
          onSuccess: () => {
            sendAppVersionToBugsnag();
          }
        });

        if (state.route.path === RouteDictionary.Login) {
          const { role, group, permission } = profile,
            { permissions }: any = role ? role : {};
          switch (group) {
          case GroupSystemUserType.Staff:
            if ([PermissionType.Customer,
                PermissionType.SystemUsers,
                PermissionType.Technician].indexOf(permissions[0]) > -1) {
              router.replace(`/users/${permissions[0]}`);
            } else {
              router.replace(`/${permissions[0]}`);
            }
            break;
          case GroupSystemUserType.Station:
            if (permission === StationRole.Supervisor || permission === StationRole.HeadOfStation) {
              router.replace(StationRouterPath.Default);
            }
            if (permission === StationRole.Partner) {
              router.replace(PartnerRouterPath.Default);
            }
            break;
          default:
            router.replace(RouteDictionary.Home);
            break;
          }
        }
      } catch (error) {
        dispatch(ActionType.CatchException, error);
        dispatch(ActionType.Unauthenticated, rootRouter);
      }
      */
    }
    commit(MutationType.Unauthenticated);
    if (router) {
      router.replace(RouteDictionary.Login);
    }
    commit(MutationType.SetLayout, Layout.Login);
    commit(MutationType.ClosePlashScreen);
  },
  async [ActionType.Unauthenticated](
    { commit, dispatch }: ActionContext<IState, IState>,
    router
  ) {
    commit(MutationType.OpenPlashScreen);
    try {
      await DaikinAuth.signOut();
      commit(MutationType.Unauthenticated);
      commit(MutationType.SetLayout, Layout.Login);
      commit(MutationType.ClearStore);
      if (router) {
        router.replace(RouteDictionary.Login);
      }
      commit(MutationType.ClosePlashScreen);
      deleteTagForNotification();
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    }
  }
};
