import { cloneDeep, find } from 'lodash';
import { INewRACampaignState } from '.';

export const getters = {
  getNewRACampaignDetail(state: INewRACampaignState) {
    return (id: string) => {
      return find(cloneDeep(state.data), (e) => e.id === id);
    };
  },
  getNewRACampaignRequestDetail(state: INewRACampaignState) {
    return (id: string) => {
      return find(cloneDeep(state.acl_requests.data), (e) => e.id === id);
    };
  },
  getClassifiedRequestDetail(state: INewRACampaignState) {
    return (id: string) => {
      return find(cloneDeep(state.listQualifiedRequest), (e) => e.id === id);
    };
  }
};
