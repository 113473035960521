import { Subscription } from 'apollo-client/util/Observable';
import { TabPane } from 'element-ui';
import { cloneDeep, isEmpty } from 'lodash';
import { ActionTypeBranchManage } from 'root/admin/BranchAndGroup/BranchManage/Store/types';
import { SystemRoleAlias } from 'root/admin/User/Store/types';
import { DKGqlClient } from 'root/api/graphql/Client';
import { gqlClient, IPaginationParams } from 'root/api/graphql/Core';
import { zeroCampaignQuery } from 'root/api/graphql/Query/zeroCampaign';
import { FilterByBranch } from 'root/components/FilterByBranch';
import { SearchByFilter } from 'root/components/SearchByFilter';
import { fullNameUser } from 'root/helpers';
import { IAddress, IBreadcrumb, IFilterInput, IUser } from 'root/models';
import { IState, MutationType } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import { NewRaCampaignRouterName, NewRaCampaignTab } from '..';
import { SubDealerCampaignDetailModel } from '../Components/SubDealerCampaignDetailModel';
// import { ActionTypeRaCampaign, MutationTypeRaCampaign } from '../../Store/types';
import { ActionTypeNewRaCampaign, MutationTypeNewRaCampaign } from '../Store/types';
// import { SubDealerCampaignDetail } from '../SubDealerCampaignDetail';
import './styles.scss';

const enum FilterKey {
  Role = 'role',
  Branch = 'branch'
}
export enum Role {
  DaikinDealer = 'daikin_dealer',
  SubDealer = 'sub_dealer',
}
export enum RoleDealer {
  DaikinDealer = 'Direct Dealer',
  SubDealer = 'Sub-Dealer',
}
enum NewRaCampaignSearchBy {
  OwnerEmail = 'ownerEmail',
  PhoneNumber = 'phoneNumber',
  Dealer = 'dealerName',
  DealerCode = 'dealerCode',
  SubDealerName = 'subdealer',
  SubDealerCode = 'subdealerCode',
}
@Component({
  template: require('./view.html'),
  computed: {
    ...mapState({
      data: (state: IState) => state.newRACampaign.data,
      loading: (state: IState) => state.newRACampaign.loading,
      pagination: (state: IState) => state.newRACampaign.pagination,
      authUser: (state: IState) => state.global.authUser,
      branch: (state: IState) => state.branchManage.data,

    }),
    NewRaCampaignSearchBy() {
      const result: IFilterInput[] = [];
      for (const key in NewRaCampaignSearchBy) {
        // tslint:disable-next-line:early-exit
        if (NewRaCampaignSearchBy.hasOwnProperty(key)) {
          const element = NewRaCampaignSearchBy[key];
          result.push({
            key,
            value: element,
            name: this.$t(element)
          });
        }
      }

      return [{
        key: 'select',
        name: this.$t('select'),
        value: result
      }];
    },
    selectedFieldSearchBy() {
      const filterParams = cloneDeep(this.modelSearchBy);

      return [{
        key: 'select',
        value: filterParams ? filterParams : []
      }];
    },
  },
  components: {
    'btn-filter-by-branch': FilterByBranch,
    'search-by-filter': SearchByFilter,
    'subs-detail-modal': SubDealerCampaignDetailModel
  }
})

export class ParticipantList extends Vue {
  public get serviceId(): string {
    return this.$route.params.id;
  }

  public get activeTab() {
    return NewRaCampaignTab.Participant;
  }

  public set activeTab(_value: string) {
    //
  }
  public get currentRole() {
    return this.authUser.role.alias;
  }
  public get isSalePlanning() {
    if (this.authUser.superAdmin || this.authUser.role.alias === SystemRoleAlias.Admin
      || this.authUser.role.alias === SystemRoleAlias.SaleAdmin) {
      return true;
    }

    return false;
  }
  public get isBranchPic() {
    if (this.authUser.role.alias === SystemRoleAlias.BranchManager) {
      return true;
    }

    return false;
  }
  public get isDepartmentPic() {
    if (this.authUser.role.alias === SystemRoleAlias.GroupLeader) {
      return true;
    }

    return false;
  }
  public get isSalesStaff() {
    if (this.authUser.role.alias === SystemRoleAlias.SaleStaff) {
      return true;
    }

    return false;
  }
  public get dataFilterRole() {
    const _role: IFilterInput[] = [
      {
        key: Role.DaikinDealer,
        value: Role.DaikinDealer,
        name: RoleDealer.DaikinDealer,
      },
      {
        key: Role.SubDealer,
        value: Role.SubDealer,
        name: RoleDealer.SubDealer,
      }
    ];

    return [
      {
        key: FilterKey.Role,
        name: 'Role',
        value: _role
      },
    ];
  }
  public get dataFilter() {
    const _branch: IFilterInput[] = this.branch.reduce((res, item) => {
      res.push({
        key: item.id,
        value: item.id,
        name: item.name
      });

      return res;
    }, []);

    return [
      {
        key: FilterKey.Branch,
        name: 'Branch',
        value: _branch
      },
    ];
  }
  public get selectedFilter() {
    const _filterParams = cloneDeep(this.filterParams);

    return [
      {
        key: FilterKey.Role,
        value: _filterParams[FilterKey.Role]
      },
      {
        key: FilterKey.Branch,
        value: _filterParams[FilterKey.Branch]
      }
    ];
  }
  public get columns() {
    return [
      {
        prop: 'dealer',
        label: 'Direct Dealer Name',
        labelItem: 'Direct Dealer Code',
        formatter: (model: any) => {
          return fullNameUser(model) || '--';
        },
        avatar: {
          formatter: (model: any) => {
            return model ? model : null;
          }
        },
        width: 250,
        fixed: true
      },
      {
        prop: 'sub-dealer',
        label: 'Sub-Dealer Name',
        labelItem: 'Sub-Dealer Code',
        formatter: (model: any) => {
          return fullNameUser(model) || '--';
        },
        avatar: {
          formatter: (model: any) => {
            return model ? model : null;
          }
        },
        width: 250,
        fixed: true
      },
      {
        prop: 'role',
        label: 'role',
        width: 180,
        formatter: this.formatterRole
      },
      // {
      //   prop: 'proshopType',
      //   label: 'Type',
      //   width: 180,
      //   formatter: (model: any) => {
      //     return model.proshopType ? model.proshopType.name : '--';
      //   }
      // },
      {
        prop: 'address',
        label: 'Address',
        width: 220,
        formatter: (model: any) => {
          if (model.address) {
            return this.displayAddress(model.address) || '--';
          }

          return '--';
        }
      },
      // {
      //   prop: 'branch',
      //   label: 'Branch',
      //   width: 180,
      //   formatter: (model: any) => {
      //     if (model.branch && model.branch.name) {
      //       return model.branch.name;
      //     }

      //     return '--';
      //   }
      // },
      {
        prop: 'email',
        label: 'Owner Email',
        width: 200,
        formatter: (model: any) => {
          return model.email || '--';
        }
      },
      {
        prop: 'phoneNumber',
        label: 'Phone Number',
        width: 200,
        formatter: (model: any) => {
          return model.phoneNumber || '--';
        }
      },
      {
        prop: 'totalShop',
        label: 'Total Shop',
        width: 150,
        formatter: (model: any) => {
          return model && model.shops_aggregate && model.shops_aggregate.aggregate.count ?
          model.shops_aggregate.aggregate.count : 0;
        },
        enableSort: true,
      },
      {
        prop: 'joinedShop',
        label: 'Joined Shop',
        width: 150,
        formatter: (model: any) => {
          return model.countRaJoined2023 ? model.countRaJoined2023 : 0;
        },
        enableSort: true,
      },
      {
        prop: 'action',
        label: '',
        align: 'center',
        fixed: 'right',
        width: 60
      }
    ];
  }
  public get isShowModuleByConfig() {
    const module = this.config.find((it) => it.moduleName === 'new_ra_campaign_tabs');
    if (module && module.roles.includes(this.authUser.role.alias) && module.emails.includes(this.authUser.email)) {

      return true;
    }

    return false;
  }

  public searchText: string = '';
  public authUser: IUser;
  public pagination: IPaginationParams;
  public modelSearchBy: any[] = [];
  public filterParams = {
    [FilterKey.Role]: [],
    [FilterKey.Branch]: []
  };
  public visibleDetail: boolean = false;
  public SystemRoleAlias = SystemRoleAlias;
  public shopId: string = '';
  public branch: any[];
  public config: any[] = [];
  public sortType: number = 0;
  public sortTotalType: number = 0;
  public sortJoinType: number = 0;
  public loadingExport: boolean = false;
  public subscribeURL: Subscription = null;
  public displayAddress(model: IAddress) {
    return model && model.province ? this.$store.getters['getAddressString'](model) : '--';
  }

  public getAction() {

    return 'view';
  }
  public handleActions(account, _action) {
    // tslint:disable-next-line: early-exit
    if (account && account.role.alias === 'daikin_dealer') {
      this.$router.push({
        name: NewRaCampaignRouterName.CampaignDetail,
        params: {
          id : account.id
        }
      });

      return;
    }

    this.shopId = account.id;
    this.visibleDetail = true;
  }
  public changeTab(tab: TabPane) {
    switch (tab.name) {
    case NewRaCampaignTab.Registration:
      this.$router.push({
        name: NewRaCampaignRouterName.Registration
      });
      break;
    case NewRaCampaignTab.Shop:
      this.$router.push({
        name: NewRaCampaignRouterName.Shop
      });
      break;
    case NewRaCampaignTab.SubDealer:
      this.$router.push({
        name: NewRaCampaignRouterName.SubDealer
      });
      break;
    case NewRaCampaignTab.RequestList:
      this.$router.push({
        name: NewRaCampaignRouterName.RequestList
      });
      break;
    case NewRaCampaignTab.Config:
      this.$router.push({
        name: NewRaCampaignRouterName.Config
      });
      break;
    case NewRaCampaignTab.Statistic:
      this.$router.push({
        name: NewRaCampaignRouterName.Statistic
      });
      break;
    case NewRaCampaignTab.Export:
      this.$router.push({
        name: NewRaCampaignRouterName.Export
      });
      break;
    case NewRaCampaignTab.Classified:
      this.$router.push({
        name: NewRaCampaignRouterName.Classified
      });
      break;
    default:
      break;
    }
  }
  public handleSort(sort: any) {
    if (sort === 'totalShop') {
      const _clone = this.sortTotalType + 1;
      this.sortJoinType = 0;
      this.sortTotalType = _clone % 3;
    }
    if (sort === 'joinedShop') {
      const _clone = this.sortJoinType + 1;
      this.sortTotalType = 0;
      this.sortJoinType = _clone % 3;
    }

    this.fetchData();
  }
  public handleSearch() {
    this.fetchData();
  }
  public formatterRole(model) {
    if (model && model.role) {
      switch (model.role.alias) {
      case Role.DaikinDealer:
        return 'Direct Dealer';
      case Role.SubDealer:
        return 'Sub Dealer';
      }
    }

    return '--';
  }
  public formatterDirectDealerName(model) {
    if (model && model.role && model.role.alias === Role.DaikinDealer) {
      return model.name;
    }

    if (model && model.role && model.role.alias === Role.SubDealer) {
      return model.assignedSubDealers2023View.length && model.assignedSubDealers2023View[0].dealerView
      && model.assignedSubDealers2023View[0].dealerView.name ?
      model.assignedSubDealers2023View[0].dealerView.name : ' --';
    }

    return '--';
  }
  public formatterDirectDealerCode(model) {
    if (model && model.role && model.role.alias === Role.DaikinDealer) {
      return model.dealerCode;
    }
    if (model && model.role && model.role.alias === Role.SubDealer) {
      return model.assignedSubDealers2023View.length && model.assignedSubDealers2023View[0].dealerView
      && model.assignedSubDealers2023View[0].dealerView.dealerCode ?
      model.assignedSubDealers2023View[0].dealerView.dealerCode : ' --';
    }

    return '--';
  }
  public formatterSubDealerName(model) {
    if (model && model.role && model.role.alias === Role.SubDealer) {
      return model.name;
    }

    return '--';
  }
  public formatterSubDealerCode(model) {
    if (model && model.role && model.role.alias === Role.SubDealer) {
      return model.dealerCode;
    }

    return '--';
  }

  public handlePaginationChange(value) {
    if (typeof value === 'number') {
      this.$store.commit(MutationTypeNewRaCampaign.PaginationChange, {
        ...this.pagination,
        size: value
      });
    } else {
      this.$store.commit(MutationTypeNewRaCampaign.PaginationChange, {
        ...this.pagination,
        ...value
      });
    }
    this.fetchData();
  }
  public onEnterFilter(value) {
    this.searchText = value;

    this.fetchData();
  }
  public changeSearchBy(model: IFilterInput[]) {
    this.modelSearchBy = model[0].value;

    this.fetchData();
  }
  public editSuccess() {
    this.fetchData();
  }
  public closeDialogDetail() {
    // change after
    this.shopId = '';
    this.visibleDetail = false;
    this.fetchData();
  }
  public changeFilterRole(model) {
    const role = model.find((e) => e.key === FilterKey.Role);
    const nextFilter = {
      [FilterKey.Role]: role ? role.value : [],
    };
    this.filterParams = {
      ...cloneDeep(this.filterParams),
      ...nextFilter
    };
    this.$store.commit(MutationTypeNewRaCampaign.PaginationChange, {
      page: 1,
      limit: 20
    });
    this.fetchData();
  }
  public changeFilter(filter) {
    const nextFilter = {
      [FilterKey.Branch]: filter ? filter : [],
    };
    this.filterParams = {
      ...cloneDeep(this.filterParams),
      ...nextFilter
    };
    this.$store.commit(MutationTypeNewRaCampaign.PaginationChange, {
      page: 1,
      limit: 20
    });
    this.fetchData();
  }
  public handleExportDocument() {
    this.loadingExport = true;
    this.$msgbox({
      title: this.$t('export_confirm').toString(),
      message: `Do you want to export payment document?`,
      showCancelButton: true,
      confirmButtonText: 'Confirm',
      cancelButtonText: 'Cancel',
      beforeClose: (action, instance, done) => {
        if (action === 'confirm') {
          instance.confirmButtonLoading = true;
          instance.confirmButtonText = 'Loading...';
          this.exportPaymentDocument({ instance, done });
        } else {
          this.loadingExport = false;
          done();
        }

        return;
      }
    });
  }
  public exportPaymentDocument(model) {
    const text = cloneDeep(this.searchText);
    this.$store.dispatch(ActionTypeNewRaCampaign.ExportPaymentDocument, {
      id: this.authUser.id,
      form: {
        branchIds: this.filterParams[FilterKey.Branch].length ? this.filterParams[FilterKey.Branch] : null,
        ...text && text.length ? {
          search: {
            dealerName: this.checkModelSearchBy(NewRaCampaignSearchBy.Dealer) ? text : null,
            dealerCode: this.checkModelSearchBy(NewRaCampaignSearchBy.DealerCode) ? text : null,
            email: this.checkModelSearchBy(NewRaCampaignSearchBy.OwnerEmail) ? text : null,
            phoneNumber: this.checkModelSearchBy(NewRaCampaignSearchBy.PhoneNumber) ? text : null,
          }
        } : { search: null }
      },
      onSuccess: (data) => {
        if (data && data.insert_exports && data.insert_exports.returning && data.insert_exports.returning.length) {
          this.subscribeURL = <any> gqlClient.subscribe({
          query: zeroCampaignQuery.DOWNLOAD_EXPORT,
          variables : {id: data.insert_exports.returning[0].id},
          fetchPolicy: 'cache-first'
        }).subscribe((results) => {
          // tslint:disable-next-line: early-exit
          if (results.data && results.data.exports && results.data.exports.length
            && results.data.exports[0].exportStatus === 'done') {
            window.open(results.data.exports[0].url, '_blank');
            this.subscribeURL.unsubscribe();
            this.$message({
              type: 'success',
              message: this.$t('message.export_success').toString()
            });
            this.loadingExport = false;
          }
        });
        }
        model.instance.confirmButtonLoading = false;
        model.instance.confirmButtonText = 'Confirm';
        model.done();
        // this.loadingExport = false;
      },
      onFailure: () => {
        model.instance.confirmButtonLoading = false;
        model.instance.confirmButtonText = 'Retry';
        this.loadingExport = false;
      }
    });
  }
  public checkModelSearchBy(model) {
    return this.modelSearchBy.indexOf(model) >= 0;
  }
  protected async mounted() {
    this.config = await DKGqlClient().configuration.getConfigNavigator();
    this.$nextTick(() => {
      const breadcrumb: IBreadcrumb[] = [
        {
          label: 'title.new_ra_campaign',
          path: null,
          current: false
        },
        {
          label: 'title.new_ra_campaign_participant',
          path: null,
          current: true
        }
      ];
      this.$store.commit(MutationType.SetBreadcrumb, breadcrumb);
      this.$store.dispatch(ActionTypeBranchManage.GetListBranch, {
        form: {
          filter: {},
          pagination: {}
        }
      });
      this.$store.commit(MutationTypeNewRaCampaign.PaginationChange, {
        page: 1,
        limit: 20
      });
      this.fetchData();
    });
  }

  protected beforeDestroy() {
    this.$store.commit(MutationType.ClearBreadcrumb);
  }

  private fetchData() {
    const text = cloneDeep(this.searchText);
    const _filterParams = <any> {};
    let _cloneSort = {};
    if (this.sortJoinType === 1) {
      _cloneSort = {countRaJoined2023: 'desc'};
    }
    if (this.sortJoinType === 2) {
      _cloneSort = {countRaJoined2023: 'asc'};
    }
    if (this.sortTotalType === 1) {
      _cloneSort = {shops_aggregate: {count: 'desc'}};
    }
    if (this.sortTotalType === 2) {
      _cloneSort = {shops_aggregate: {count: 'asc'}};
    }
    if (this.filterParams[FilterKey.Role].length) {
      _filterParams.role = this.filterParams[FilterKey.Role];
    }
    if (this.filterParams[FilterKey.Branch].length) {
      _filterParams.branch = this.filterParams[FilterKey.Branch];
    }
    const search = [
      {
        ...this.checkModelSearchBy(NewRaCampaignSearchBy.OwnerEmail) ?
        {
          email: {
            _ilike: `%${text}%`
          }
        } : {}
      },
      {
        ...this.checkModelSearchBy(NewRaCampaignSearchBy.PhoneNumber) ?
        {
          phoneNumber: {
            _ilike: `%${text}%`
          }
        } : {}
      },
      {
        ...this.checkModelSearchBy(NewRaCampaignSearchBy.Dealer) ?
        {
          name: {
            _ilike: `%${text}%`
          }
        } : {}
      },
      {
        ...this.checkModelSearchBy(NewRaCampaignSearchBy.DealerCode) ?
        {
          dealerCode: {
            _ilike: `%${text}%`
          }
        } : {}
      },
      {
        ...this.checkModelSearchBy(NewRaCampaignSearchBy.SubDealerName) ?
        {
          name: {
            _ilike: `%${text}%`
          }
        } : {}
      },
      {
        ...this.checkModelSearchBy(NewRaCampaignSearchBy.SubDealerCode) ?
        {
          dealerCode: {
            _ilike: `%${text}%`
          }
        } : {}
      },
    ].filter((e) => !isEmpty(e));
    // if (this.isDepartmentPic || this.isSalesStaff) {
    //   this.$store.dispatch(ActionTypeRaCampaign.GetBranchId, {
    //     id: this.authUser.id,
    //     onSuccess: (branchId) => {
    //       const _cloneGroupId = [];
    //       if (branchId && branchId.length) {
    //         branchId.map((branch) => {
    //           _cloneGroupId.push(branch.group.branchId);
    //         });
    //       }
    //       this.$store.dispatch(ActionTypeNewRaCampaign.GetNewRaCampaign, {
    //         search: `%${text}%`,
    //         role: _filterParams.role,
    //         branch: _filterParams.branch && _filterParams.branch.length ?
    //         _filterParams.branch : _cloneGroupId && _cloneGroupId.length ? _cloneGroupId : [],
    //         order: _cloneSort,
    //         pagination: this.pagination
    //       });
    //     }
    //   });

    //   return;
    // }
    this.$store.dispatch(ActionTypeNewRaCampaign.GetNewRaCampaign, {
      search: search && search.length ? search : [{}],
      role: _filterParams.role,
      branch: _filterParams.branch,
      order: _cloneSort,
      pagination: this.pagination
    });
  }
}
