import { DocumentNode } from 'graphql';
import gql from 'graphql-tag';
import { crudQuery, ICRUDQuery } from '../Core/query';

const active = `
    address
    branchId
    city
    createdAt
    createdBy
    district
    groupId
    id
    phoneNumber
    pictureURLs
    reason
    participant {
      dealer {
        name
        avatar
        dealerCode
        group {
          name
        }
        branch {
          name
        }
      }
    }
    ward
    referenceId
    requestStatus
    rewardSlotType
    shopName
    staffId
    status
    signatureURL
    stt
    updatedAt
    updatedBy
`;
const bankingPaymentModel = `
    id
    importCode
    dealer {
      name
      dealerCode
    }
    totalAmount
    totalBlockedAmount
    startAt
    endAt
    confirmedAt
    paidAt
    referenceId
    importUrl
    checker {
      name
    }
    creator {
      name
      role{name}
    }
    importedAt
    importStatus
    importUrl
`;

export const CustomCampaign = 'user_participant_pictures';
// tslint:disable-next-line:no-empty-interface
interface ICustomCampaignQuery extends ICRUDQuery {
  GET_CUSTOM_CAMPAIGN: DocumentNode;
  HANDLE_STATUS_EVALUATE: DocumentNode;
  HANDLE_ON_OFF_EVALUATE: DocumentNode;
  GET_ALL_SAMPLE: DocumentNode;
  GET_URl_SAMPLE: DocumentNode;
  BankingPayment: ICRUDQuery;
  IMPORT_BANKING_PAYMENT: DocumentNode;
  CHECK_AS_PAID: DocumentNode;
  EXPORT_SALES_EDU: DocumentNode;
  GET_LIST_TRAINEE: DocumentNode;
  GET_LIST_IMPORT_HISTORY: DocumentNode;
  GET_LIST_TRAINEE_DETAIL: DocumentNode;
  DOWNLOAD_IMPORT_LOGS: DocumentNode;
}
export const customCampaignListQuery: ICustomCampaignQuery = {
  ...crudQuery(CustomCampaign, active),
  BankingPayment: crudQuery('bank_dealers', bankingPaymentModel),
  GET_CUSTOM_CAMPAIGN: gql`
  query GET_CUSTOM_CAMPAIGN {
    picture_campaign_config {
      id
      status
    }
  }
`,
  HANDLE_STATUS_EVALUATE: gql`
  mutation HANDLE_STATUS_EVALUATE($form: FormHandleCampaignPicture) {
    handleCampaignPicture(form: $form) {
      id
    }
  }
`,
  HANDLE_ON_OFF_EVALUATE: gql`
  mutation HANDLE_ON_OFF_EVALUATE($id: uuid!, $status: String!) {
    update_picture_campaign_config(where: {id: {_eq: $id}}, _set: {status: $status}) {
      affected_rows
    }
  }
`,
  GET_ALL_SAMPLE: gql`
    mutation GET_ALL_SAMPLE {
      insert_export_participant_campaigns(objects: {}) {
        returning {
          id
        }
      }
    }
  `,
  GET_URl_SAMPLE: gql`
    subscription GET_URL_SAMPLE ($id: uuid!) {
      export_participant_campaigns(where: {id: {_eq: $id}}) {
        exportStatus
        url
        failedReasons
      }
    }
  `,
  IMPORT_BANKING_PAYMENT: gql`
  mutation IMPORT_BANKING_PAYMENT($form: imports_insert_input!) {
    insert_imports_one(object: $form) {
      id
    }
  }`,
  CHECK_AS_PAID: gql`
  mutation CHECK_AS_PAID($filter: bank_dealers_bool_exp!, $set: bank_dealers_set_input) {
    update_bank_dealers(where: $filter, _set: $set) {
      affected_rows
    }
   }
  `,
  EXPORT_SALES_EDU: gql`
  mutation EXPORT_SALES_EDU($object: exports_insert_input!){
    insert_exports_one(object: $object){
  		id
    }
  }`,
  GET_LIST_TRAINEE: gql`
  query getBankDealerDetail ($form: FormBankDealerDetail!) {
  getBankDealerDetail(form: $form){
  	pagination {
      page
      size
      totalPages
      total
    },
    data {
      amountReceived
      blockedAmount
      traineeCode
      traineeName
      reason
      status
    }
  }
  }`,
  GET_LIST_IMPORT_HISTORY: gql`
    query GET_LIST_IMPORT_HISTORY($status: [String!], $search: String, $limit: Int!, $offset: Int!,
    $createdAt: timestamptz_comparison_exp){
      imports(where: {type: {_eq: "edu_banking"}, creator: {
        name: { _ilike: $search }
      }, createdAt: $createdAt,
      importStatus: {_in: $status}}, limit: $limit, offset: $offset,
      order_by: {createdAt: desc}){
        id
        importStatus
        url
        failedReasons
        createdAt
        importCode
        creator {
          name
          role {
            name
          }
        }
      }
      imports_aggregate(where: {type: {_eq: "edu_banking"}, creator: {
        name: { _ilike: $search }
      }, createdAt: $createdAt, importStatus: {_in: $status}}){
        aggregate {
          count
        }
      }
    }
  `,
  GET_LIST_TRAINEE_DETAIL: gql`
  query GetImportLogDetail ($form: FormImportLogDetail!,
    $dealer: FormImportLogDetail!, $trainee: FormImportLogDetail!){
  getImportLogDetail(form: $form){
    data {
      id
      createdBy
      createdAt
      importId
      reason
      status
      sheet
      metadata {
        traineeName
        traineeCode
        companyCode
        companyName
        startDate
        endDate
        totalAmount
        blockedAmount
        amountReceived
      }
    },
    pagination {
      page
      size
      total
      totalPages
      totalPerPage
    }
  }
  dealer: getImportLogDetail(form: $dealer) {
    pagination {
      total
    }
  }
  trainee: getImportLogDetail(form: $trainee) {
    pagination {
      total
    }
  }
  }`,
  DOWNLOAD_IMPORT_LOGS: gql`
  mutation DOWNLOAD_IMPORT_LOGS($form: exports_insert_input!) {
    insert_exports_one(object: $form){
    id
  }
  }
  `
};
