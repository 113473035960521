import { i18n } from 'root/locales';
import { StatusCode } from './Common';
import { ISimpleUser } from './User';

export interface IReasonsForm {
  requestedBy: string;
  type: string;
  code: string;
  reason: string;
  createdBy?: string;
  updatedBy?: string;
}
export function ReasonsFormDefault(): IReasonsForm {
  return {
    requestedBy: '',
    type: '',
    code: '',
    reason: ''
  };
}

export interface IReasons extends IReasonsForm {
  id: string;
  status: StatusCode;
  createdUser?: ISimpleUser;
  updatedUser?: ISimpleUser;
}

export function ReasonsDefault(): IReasons {
  return {
    ...ReasonsFormDefault(),
    id: '',
    status: StatusCode.Active
  };
}

export enum TypeRequestByReason {
  Customer = 'customer',
  Technician = 'technician',
  CallCenter = 'call_center'
}

export const typeRequestByReason = () => {
  const result = [];
  // tslint:disable-next-line:forin
  for (const key in TypeRequestByReason) {
    result.push({
      key: TypeRequestByReason[key],
      value: i18n().t(TypeRequestByReason[key])
    });
  }

  return result;
};

export const displayTypeRequestByReason = (model: IReasons) => {
  // tslint:disable-next-line:forin
  for (const key in TypeRequestByReason) {
    if (model.requestedBy === TypeRequestByReason[key]) {
      return i18n().t(TypeRequestByReason[key]);
    }
  }

  return '';
};

export enum TypeBookingCancellationReason {
  RescheduleBooking = 'reschedule_booking',
  CancelBooking = 'cancel',
  CompleteBooking = 'complete',
  ChangeTechnician = 'change_technician',
  PendingService = 'pending_service'
}

export const typeBookingCancellationReason = () => {
  const result = [];
  // tslint:disable-next-line:forin
  for (const key in TypeBookingCancellationReason) {
    result.push({
      key: TypeBookingCancellationReason[key],
      value: i18n().t(TypeBookingCancellationReason[key])
    });
  }

  return result;
};

export const displayTypeBookingCancellationReason = (model: IReasons) => {
  // tslint:disable-next-line:forin
  for (const key in TypeBookingCancellationReason) {
    if (model.type === TypeBookingCancellationReason[key]) {
      return i18n().t(TypeBookingCancellationReason[key]);
    }
  }

  return '';
};
