import { DKGqlClient } from 'root/api/graphql/Client';
import { ActionType, IState, MutationType } from 'root/store';
import { crudActions } from 'root/store/helpers';
import { ActionContext } from 'vuex';
import { IProductTransState } from './mutation';
import { ActionTypeProductTrans } from './types';

const { productTrans } = DKGqlClient();
export const actions = {
  ...crudActions('productTrans', {
    filter: productTrans.filter
  }),
  async [ActionTypeProductTrans.GET_LIST_PRODUCT_TRANS]({
    commit, dispatch
  }: ActionContext<IProductTransState, IState>) {
    try {
      const ProductTransList = await productTrans.getListProductTrans();
      commit(MutationType.GetProductTrans, ProductTransList);
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    }
  },
  async [ActionTypeProductTrans.GetByProductId]({
    commit, dispatch
  }: ActionContext<IProductTransState, IState>, {id}) {
    try {
      const ProductTransList = await productTrans.getByIdProduct(id);
      commit(MutationType.GetProductTrans, ProductTransList);
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    }
  },
  async [ActionTypeProductTrans.GetGroupView]({
    commit, dispatch
  }: ActionContext<IProductTransState, IState>,
                                              {where}) {
    try {
      const ProductTransList = await productTrans.getGroupView(where);
      commit(MutationType.GetProductTrans, ProductTransList);
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    }
  },
  async [ActionTypeProductTrans.GetViewById]({
    commit, dispatch
  }: ActionContext<IProductTransState, IState>,
                                             {id}) {
    try {
      const ProductTransList = await productTrans.getViewById(id);
      commit(MutationType.GetProductTrans, ProductTransList);
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    }
  }
};
