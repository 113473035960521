import { cloneDeep } from 'lodash';
import moment from 'moment';
import { IPaginationParams } from 'root/api/graphql/Core';
import { parseUTCToTime, FormatDateFullTime } from 'root/helpers';
import { IAddress, IBreadcrumb, IServiceCategory, IWorkMaster, StatusCode } from 'root/models';
import { ICampaign } from 'root/models/Redemption';
import { IState, MutationType } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import { RedemptionRouterName, RedemptionRouterPath } from '../..';
import { CampaignDetailModal } from '../../Components/CompaignDetail';
import { CompaignEditor } from '../../Components/CompaignEditor';
import { ActionTypeCampaign, MutationTypeCampaign } from '../Store/types';
import './styles.scss';

@Component({
  template: require('./view.html'),
  components: {
    editor: CompaignEditor,
    'campaign-detail-modal': CampaignDetailModal,
  },
  computed: {
    ...mapState({
      campaign: (state: IState) => state.campaign.data,
      isRefesh: (state: IState) => state.global.isRefesh,
      dataDetail: (state: IState) => state.campaign.detail.data,
      loadingDetail: (state: IState) => state.campaign.detail.loading,
      paginationDetail: (state: IState) => state.campaign.detail.pagination,
    }),
    detail() {
      return this.$store.getters.getCampaignDetail(this.serviceId);
    }
  }
})
export class CampaignDetail extends Vue {
  public get columns() {
    return [{
      prop: 'branchName',
      label: 'Branch',
      formatter: (model: any) => {
        if (model.branchName) {
          return model.branchName || '--';
        }

        return '--';
      },
      width: 250
    },
    {
      prop: 'participant',
      label: 'Participant',
      width: 250,
      formatter: (model: any) => {
        if (model.participant) {
          return model.participant;
        }

        return '--';
      }
    },
    {
      prop: 'frequency',
      label: 'Frequency',
      width: 180,
      formatter: (model: any) => {
        if (model.frequency) {
          return model.frequency;
        }

        return '--';
      }
    },
    {
      prop: 'totalPoint',
      label: 'Total Point',
      width: 180,
      formatter: (model: any) => {
        if (model.totalPoint) {
          return `+${model.totalPoint}`;
        }

        return '--';
      },
      labelStyle: (model: any) => {
        return model && model.totalPoint ? 'success' : '';
      }
    },
    ];

  }
  public get serviceId(): string {
    return this.$route.params.id;
  }
  public get activeTab() {
    return this.$route.name;
  }
  public set activeTab(_value: string) {
    //
  }
  public FormatDateFullTime = FormatDateFullTime;
  public formatDate = parseUTCToTime;
  public visibleEditor: boolean = false;
  public paginationDetail: IPaginationParams;
  public visible: boolean = false;
  public campaign: ICampaign[];
  public repairs: IWorkMaster[];
  public dataDetail: any[];
  public category: IServiceCategory;
  public fileUpload: File[] = [];
  public isRefesh: boolean;
  public searchText: string = '';
  public isEng: boolean =false;
  public get inputSearchPlaceholder(): string {

    return `Search by participant, branch name`;
  }
  public detail: any;
  public canEdit: boolean = false;
  public back() {
    this.$router.push(RedemptionRouterName.Compaign);
  }
  public closeDialog() {
    this.visibleEditor = false;
  }
  public closeDialogDetail() {
    this.visible = false;
  }
  public goEdit() {
    this.visibleEditor = true;
  }
  public goDetail(value: boolean) {
    this.visible = true;
    this.isEng = value;
  }
  public displayAddress(model: IAddress) {
    return model && model.province ? this.$store.getters['getAddressString'](model) : '';
  }
  public createSuccess() {
    this.visible = false;
    this.fetchData();
  }
  public get CanEdit() {
    if (this.detail && this.detail.campaignStatus === StatusCode.Available) {

      return this.canEdit = true;
    }
    if (this.detail && this.detail.campaignStatus === StatusCode.Blocked) {
      const now = moment();
      if (moment(this.detail.endDate) > now) {

        return this.canEdit = true;
      }

      return this.canEdit = false;
    }
    if (this.detail && this.detail.campaignStatus === StatusCode.Schedule) {

      return this.canEdit = true;
    }

    return this.canEdit = false;
  }
  public handlePaginationChange(value) {
    if (typeof value === 'number') {
      this.$store.commit(MutationTypeCampaign.PaginationChange, {
        ...this.paginationDetail,
        limit: value
      });
    } else {
      this.$store.commit(MutationTypeCampaign.PaginationChange, {
        ...this.paginationDetail,
        ...value
      });
    }
    this.fetchData();
  }

  public handleSearch() {
    this.fetchData();
  }
  protected mounted() {
    const breadcrumb: IBreadcrumb[] = [
      {
        label: 'title.campaign',
        path: RedemptionRouterPath.Compaign,
        current: false
      },
      {
        label: 'title.view_details',
        path: null,
        current: true
      }
    ];
    this.$store.commit(MutationType.SetBack);
    this.$store.commit(MutationType.SetBreadcrumb, breadcrumb);
    this.fetchData();
  }
  protected beforeDestroy() {
    this.$store.commit(MutationType.ClearBreadcrumb);
  }
  private fetchData() {
    this.$store.dispatch(ActionTypeCampaign.GetList, {
      form: {
        filter: {},
        pagination: {}
      },
    });
    const text = cloneDeep(this.searchText);
    this.$store.dispatch(ActionTypeCampaign.GetCampaignDetail, {
      form: {
        filter: {
          campaignId: this.serviceId,
          search: text
        },
        pagination: {
          size: this.paginationDetail.limit,
          page: this.paginationDetail.page
        }
      },
    });
    if (this.campaign.length) {
      return this.$store.getters.getCampaignDetail(this.serviceId);
    }

  }
}
