import { TabPane } from 'element-ui';
import moment from 'moment';
import { CustomCampaignRouterName, CustomCampaignTab } from 'root/admin/CustomCampaign';
import { IPaginationParams } from 'root/api/graphql/Core';
// import { FormatDateFullTime } from 'root/helpers';
import { IAddress, IUser } from 'root/models';
import { IBreadcrumb, IState, MutationType } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import { ActionTypeAclCampaign, MutationTypeAclCampaign } from '../../Store/types';
import { ContractStatus, RequestStatus, RequestStatusCode } from '../CampaignDetail';
import { CampaignDetailModel } from '../CampaignDetailModel';

@Component({
  template: require('./view.html'),
  computed: {
    ...mapState({
      data: (state: IState) => state.aclCampaign.listQualifiedShop,
      pagination: (state: IState) => state.aclCampaign.pagination,
      loading: (state: IState) => state.aclCampaign.loading,
      authUser: (state: IState) => state.global.authUser,
    }),
    checkDisable() {
      if (this.data.length) {
        return false;
      }

      return true;
    }
  },
  components: {
    // editor: DealerEditor
    'shops-detail-modal': CampaignDetailModel,
  },
})

export class QualifiedShops extends Vue {
  public get activeTab() {
    return CustomCampaignTab.QualifiedShops;
  }

  public set activeTab(_value: string) {
    //
  }

  public get columns() {
    return [
      {
        prop: 'review at',
        label: 'Last review at',
        formatter: this.formatterTime,
        width: 150,
      },
      {
        prop: 'review content',
        label: 'last review content',
        formatter: (model: any) => {
          return model.shop ? model.shop.name : '--';
        },
        width: 300,
      },
      {
        prop: 'shop',
        label: 'Name',
        formatter: (model: any) => {
          return model.shop ? model.shop.name : '--';
        },
        width: 250,
      },
      {
        prop: 'dealer',
        label: 'Dealer',
        formatter: (model: any) => {
          return model.dealer ? model.dealer.name : '--';
        },
        width: 150,
      },
      {
        prop: 'staff',
        label: 'Staff',
        width: 120,
        formatter: (model: any) => {
          return model.staff || '--';
        }
      },
      {
        prop: 'address',
        label: 'Address',
        width: 220,
        formatter: (model: any) => {
          if (model.shop && model.shop.address) {
            return this.displayAddress(model.shop.address) || '--';
          }

          return '--';
        }
      },
      {
        prop: 'status',
        label: 'Request Status',
        width: 150,
        formatter: this.formaterStatus,
        labelStyle: (model: any) => {
          return model.requestStatus;
        },
      },
      {
        prop: 'action',
        label: '',
        align: 'center',
        fixed: 'right',
        width: 60
      }
    ];
  }
  public pagination: IPaginationParams;
  public authUser: IUser;
  public shopId: string = '';
  public visibleDetail: boolean = false;
  public formaterStatus(model: any) {
    switch (model.requestStatus) {
    case RequestStatusCode.WaitingForReup:
      return RequestStatus.WaitingForReup;
    case RequestStatusCode.WaitingForReview:
      return RequestStatus.WaitingForReview;
    case RequestStatusCode.Approved:
      return RequestStatus.Approved;
    case RequestStatusCode.Rejected:
      return RequestStatus.Rejected;
    }

  }
  public getAction() {

    return 'view';
  }
  public formatterTime(model: any) {
    if (model && model.reviews.length) {
      const last = model.reviews.length - 1;

      return moment(model.reviews[last].updatedAt).format('HH:mm - DD/MM/YYYY');
    }

    return '--';
  }
  public handleActions(record) {
    this.clickDetail(record.shop.id);
  }
  public clickDetail(id: string) {
    this.shopId = id;
    this.visibleDetail = true;
  }
  public closeDialogDetail() {
    // change after
    this.shopId = '';
    this.visibleDetail = false;
    this.fetchData();
  }
  public formatterStaff(model: any) {
    return model.shop.dealerShops.filter(
      (item) => !item.isOwner && item.contractStatus === ContractStatus.Accepted
    ).length;
  }
  public displayAddress(model: IAddress) {
    return model && model.province
      ? this.$store.getters['getAddressString'](model)
      : '';
  }

  public formatterContent(model: any) {
    if (model.reviews) {
      const last = model.reviews.length - 1;

      return model.reviews[last].description.length < 120 ?
        model.reviews[last].description
        : model.reviews[last].description.slice(0, 120) + '...';
    }

    return '--';
  }
  public changeTab(tab: TabPane) {
    switch (tab.name) {
    case CustomCampaignTab.Participant:
      this.$router.push({
        name: CustomCampaignRouterName.Participant
      });
      break;
    case CustomCampaignTab.Configuration:
      this.$router.push({
        name: CustomCampaignRouterName.Configuration
      });
      break;
    case CustomCampaignTab.Statistic:
      this.$router.push({
        name: CustomCampaignRouterName.Statistic
      });
      break;
    case CustomCampaignTab.Shops:
      this.$router.push({
        name: CustomCampaignRouterName.Shops
      });
      break;
    default:
      break;
    }
  }
  public reloadListImport() {
    this.fetchData();
  }
  public approveAll() {
    this.$msgbox({
      title: this.$t('approve_all_confirm').toString(),
      message: `Do you want to approve all?`,
      showCancelButton: true,
      confirmButtonText: 'Confirm',
      cancelButtonText: 'Cancel',
      beforeClose: (action, instance, done) => {
        if (action === 'confirm') {
          instance.confirmButtonLoading = true;
          instance.confirmButtonText = 'Loading...';
          this.handleApproveAll({ instance, done });
        } else {
          done();
        }

        return;
      }
    })
        .then(() => {
          this.$message({
            type: 'success',
            message: this.$t('message.approveSuccess').toString()
          });
        })
        .catch(() => {
          // no handle
        });
  }
  public handleApproveAll(model) {
    this.$store.dispatch(ActionTypeAclCampaign.ApproveAll, {
      id: this.authUser.id,
      onSuccess: () => {
        this.fetchData();
        model.instance.confirmButtonLoading = false;
        model.instance.confirmButtonText = 'Confirm';
        model.done();
      },
      onFailure: () => {
        model.instance.confirmButtonLoading = false;
        model.instance.confirmButtonText = 'Cancel';
      }
    });
  }
  public handlePaginationChange(value) {
    if (typeof value === 'number') {
      this.$store.commit(MutationTypeAclCampaign.PaginationChange, {
        ...this.pagination,
        size: value
      });
    } else {
      this.$store.commit(MutationTypeAclCampaign.PaginationChange, {
        ...this.pagination,
        ...value
      });
    }
    this.fetchData();
  }
  protected mounted() {
    this.$nextTick(() => {
      const breadcrumb: IBreadcrumb[] = [
        {
          label: 'title.campaign',
          path: null,
          current: false
        },
        {
          label: 'title.acl_campaign',
          path: null,
          current: true
        }
      ];
      this.$store.commit(MutationType.SetBreadcrumb, breadcrumb);

      this.fetchData();
    });
  }
  private fetchData() {

    this.$store.dispatch(ActionTypeAclCampaign.GetQualifiedShops, {
      pagination: this.pagination
    });
  }
}
