import { stripObject } from '@hgiasac/helper';
import { Form } from 'element-ui';
import { ElUpload } from 'element-ui/types/upload';
import { cloneDeep, pick } from 'lodash';
import { DKGqlClient } from 'root/api/graphql/Client';
import { Upload } from 'root/components/Upload';
import { resetOrientation, ruleRequired } from 'root/helpers';
import { IUser } from 'root/models';
import { pathUpload, sizeImage, uploadToFirebase } from 'root/services';
import { FirebaseAuthService } from 'root/services/auth/firebase';
import { IState, MutationType, TypeAlert } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import { ActionTypeSaleInReward } from '../../../Store/types';
import './styles.scss';

@Component({
  template: require('./view.html'),
  props: {
    visible: Boolean,
    detailId: String
  },
  computed: {
    ...mapState({
      authUser: (state: IState) => state.global.authUser,
    }),
  },
  components: {
    upload: Upload,
  }
})

export class SaleInRewardForm extends Vue {
  public $refs: {
    form: Form;
    upload: ElUpload;
  };
  public rules = {
    required: ruleRequired(),
  };
  public authUser: IUser;
  public SBUOptions: any[] = [];
  public form: any = {};
  public isErrorCode: boolean = false;
  public loading: boolean = false;
  public pathUploadSpec: pathUpload = pathUpload.BANNERS;
  public fileUploadBanner = null;
  public RewardTypeOptions: any = [
    {
      value: 'device',
      label: 'Device'
    },
    {
      value: 'voucher',
      label: 'Voucher'
    },
    {
      value: 'tool',
      label: 'Tool'
    }
  ];
  public async handleUploadBanner(file: any) {
    // tslint:disable-next-line: whitespace
    const _file = <any>await resetOrientation(file.raw, -1);
    this.form = {
      ...this.form,
      icon: window.URL.createObjectURL(_file)
    };
    this.fileUploadBanner = _file;
  }
  public removeUploadBanner() {
    this.form = {
      ...this.form,
      icon: ''
    };
    this.fileUploadBanner = null;
  }
  public onChangeCode() {
    this.isErrorCode = false;
    this.$store.dispatch(ActionTypeSaleInReward.CheckRewardCodeExits, {
      code: this.form.code,
      onSuccess: (result) => {
        if (result) {
          return this.isErrorCode = true;
        }

        return this.isErrorCode = false;
      }
    });
  }
  public openDialog() {
    this.form = {};
    // tslint:disable-next-line:early-exit
    if (this.$props.detailId) {
      const detail = this.$store.getters.getSaleInRewardDetail(this.$props.detailId);
      this.form = {
        ...cloneDeep(detail)
      };
    }
  }
  public closeDialog() {
    this.$emit('closeDialog');
    this.isErrorCode = false;
    this.$refs.form.clearValidate();
  }
  public submit() {
    this.$refs.form.validate(async (valid) => {
      // tslint:disable-next-line:early-exit
      if (valid) {
        this.loading = true;
        const form = <any> cloneDeep(this.form);
        if (this.fileUploadBanner && form.icon) {
          const token = await DKGqlClient().user.getFirebaseAuthenticateToken();
          await FirebaseAuthService().signInWithCustomToken(token.authenticate);
          const image = await uploadToFirebase(
            this.fileUploadBanner,
            this.pathUploadSpec,
            {
              ...sizeImage.icon,
              quality: 1
            }
          );
          form.icon = image;
        }
        if (this.$props.detailId) {
          this.$store.dispatch(ActionTypeSaleInReward.Update, {
            id: this.$props.detailId,
            form: {
              ...stripObject(pick(form, ['code', 'rewardType', 'rewardName', 'unit', 'price', 'icon'])),
              updatedBy: this.authUser.id,
            },
            onSuccess: () => {
              this.loading = false;
              const message = 'Updated successfully';
              this.$store.commit(MutationType.OpenTopAlert, {
                message,
                type: TypeAlert.Success
              });
              this.$emit('createSuccess');
            }
          });

          return;
        }
        this.$store.dispatch(ActionTypeSaleInReward.Create, {
          form: {
            ...stripObject(pick(form, ['code', 'rewardType', 'rewardName', 'unit', 'price', 'icon'])),
            createdBy: this.authUser.id,
          },
          onSuccess: () => {
            this.loading = false;
            const message = 'Created successfully';
            this.$store.commit(MutationType.OpenTopAlert, {
              message,
              type: TypeAlert.Success
            });
            this.$emit('createSuccess');
          }
        });
      }
    });
  }
  public async mounted() {
    // 
  }
}
