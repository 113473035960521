import { crudMutations, CRUDState, ICRUDState } from 'root/store/helpers';
import { MutationTypeRegion } from './types';

export interface IRegionState extends ICRUDState<any> {
  loading: boolean;
  detail: any;
}

export const defaultState: IRegionState = {
  ...CRUDState(),
  loading: false,
  detail: {},
};

export const mutations = {
  ...crudMutations('regions'),
  [MutationTypeRegion.RegionSetDetail](state: IRegionState, data) {
    state.detail = data.data;
  }
};
