import { cloneDeep, isArray } from 'lodash';
import moment from 'moment';
// import { SystemRoleAlias } from 'root/admin/User/Store/types';
import { gqlClient, IPaginationParams } from 'root/api/graphql/Core';
import { dealerListQuery } from 'root/api/graphql/Query/dealer';
import { FormatDateFullTime } from 'root/helpers';
import { IDealer } from 'root/models/Dealer';
import { INotification } from 'root/models/Notifications';
import { ITargetDetail } from 'root/models/Notifications';
import { ActionType, IState, MutationType } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import { NotificationDetail } from '../components/NotificationDetail';
import {
  NotificationEditor,
  TargetOptions
} from '../components/NotificationEditor';
import {
  ActionTypeNotification,
  MutationTypeNotification
} from '../store/type';
import './style.scss';

export enum PushStatus {
  // Approved = 'approved',
  // ScanSuccess = 'scan-success',
  // ScanFailed = 'scan-failed',
  Sent = 'sent',
  Scheduled = 'scheduled'
}
@Component({
  template: require('./view.html'),
  computed: {
    ...mapState({
      data: (state: IState) => state.notification.data,
      loading: (state: IState) => state.notification.loading,
      pagination: (state: IState) => state.notification.pagination,
      isRefesh: (state: IState) => state.global.isRefesh,
    })
  },
  components: {
    'notification-detail': NotificationDetail,
    'notification-editor': NotificationEditor
  }
})
export class NotificationsList extends Vue {
  public pagination: IPaginationParams;
  public viewNotificationDetail: boolean = false;
  public currentNotificationId: string = '';
  public viewNotificationEditor: boolean = false;
  public targetDetails: ITargetDetail[] = [];
  public allDealers: IDealer[] = [];

  public dealersDetails: Array<{
    id: string;
    email: string;
    refId: string;
  }> = [];
  public isRefesh: boolean;
  public filterParams = {
    push_status: []
  };
  public openEditorForAddNew() {
    this.viewNotificationEditor = true;
    this.currentNotificationId = '';
  }
  public openEditor(id: string) {
    this.viewNotificationEditor = true;
    this.currentNotificationId = id;
  }
  public closeEditor() {
    this.viewNotificationEditor = false;
  }
  public openDetailDialog(id: string) {
    this.viewNotificationDetail = true;
    this.currentNotificationId = id;
  }
  public closeDetailDialog() {
    this.viewNotificationDetail = false;
  }

  public formatStatus(status: string): string {
    return status
      .split('-')
      .map((element) => element.charAt(0).toUpperCase() + element.slice(1))
      .join(' ');
  }

  public get selectedFilter() {
    const filterParams = cloneDeep(this.filterParams);

    return [
      {
        key: 'push_status',
        value: filterParams['push_status']
      }
    ];
  }

  public changeFilter(model) {
    const pushStatus = model[0].value;
    this.filterParams['push_status'] = [...pushStatus] || [];
    this.$store.commit(MutationTypeNotification.ResetNotificationPagination);
    this.fetchData();
  }

  public get dataFilter() {
    return [
      {
        key: 'push_status',
        name: 'Push Status',
        value: [
          {
            key: PushStatus.Sent,
            value: PushStatus.Sent,
            name: 'Sent'
          },
          {
            key: PushStatus.Scheduled,
            value: PushStatus.Scheduled,
            name: 'Scheduled'
          }
        ]
      }
    ];
  }

  public async fetchTargetDetail() {
    // const query = QUERY_TARGET_DETAILS;
    // const result = await gqlClient.query({
    //   query,
    //   fetchPolicy: 'network-only'
    // });
    const resultsDealerList = await gqlClient.query({
      query: dealerListQuery.GET_NEWS_TARGET_DETAIL,
      fetchPolicy: 'network-only'
    });
    if (resultsDealerList.errors) {
      throw resultsDealerList.errors;
    }
    // if (result.errors) {
    //   throw result.errors;
    // }
    this.allDealers = [...resultsDealerList.data.dealers];
    // this.targetDetails = [...result.data.notification_dealers];
  }

  public async fetchData() {
    try {
      let scheduledFilter = null;
      if (
        this.filterParams['push_status'].length &&
        this.filterParams['push_status'].length < 2
      ) {
        // tslint:disable-next-line:prefer-conditional-expression
        if (this.filterParams['push_status'][0] === PushStatus.Sent) {
          scheduledFilter = {
            _lte: moment()
              .utc()
              .format()
          };
        } else {
          scheduledFilter = {
            _gt: moment()
              .utc()
              .format()
          };
        }
      }
      this.$store.commit(MutationTypeNotification.NotificationFilterChange, {
        _and: {
          mode: 'special',
          data: {
            type: {
              _eq: 'news'
            },
            scheduledAt: scheduledFilter ? scheduledFilter : {}
          }
        }
      });

      this.$store.dispatch(ActionTypeNotification.NotificationFilterNoCache, {
        optionsHTTP: {
          orderBy: {
            createdAt: 'desc'
          }
        }
      });
      // this.$store.dispatch(ActionTypeDealer.GetListDealer, {
      //   form: {
      //     filter: {},
      //     pagination: {
      //       // size: 20,
      //       // page: 1
      //     }
      //   }
      // });
    } catch (err) {
      console.log(
        '🚀 ~ file: component.ts ~ line 176 ~ NotificationsList ~ fetchData ~ err',
        err
      );
      this.$store.dispatch(ActionType.CatchException, err);
    }
  }

  public clearCurrentNotificationId() {
    this.currentNotificationId = '';
  }

  public get columns() {
    return [
      {
        label: this.$t('notification.title'),
        prop: 'title',
        width: 390
      },
      {
        label: this.$t('notification.target'),
        prop: 'target',
        formatter: this.formatterTarget,
        width: 260
      },
      {
        label: this.$t('type'),
        prop: 'typeDealer',
        formatter: this.formatType
          // const typeDealer = model.typeDealers
          //   ? model.typeDealers[0]
          //   : model.typeDealer
          //   ? isArray(model.typeDealer)
          //     ? model.typeDealer[0]
          //     : model.typeDealer
          //   : '--';

          // return model.metadata && model.metadata.filter && model.metadata.filter.typeDealers.length
          // && model.metadata.filter.typeDealers[0] !== ' '
          // ? this.formatTypeDealer(model.metadata.filter.typeDealers) : '--';
        ,
        width: 260
      },
      {
        label: 'TARGET DETAIL',
        prop: 'dealerIds',
        // formatter: (model: any) => {
        //   const dealer = this.dealersDetails.find((el) => el.refId === model.id);

        //   return dealer ? dealer.email : '--';
        // },
        width: 180
      },
      {
        label: this.$t('notification.scheduledAt'),
        formatter: (model: any) =>
          FormatDateFullTime(model.scheduledAt, {
            format: 'HH:mm - DD/MM/YYYY',
            locale: true
          }),
        width: 260
      },
      {
        label: this.$t('notification.notificationStatus.title'),
        prop: 'pushStatus',
        formatter: (model: any) => {
          if (model.scheduledAt) {
            return moment(model.scheduledAt) > moment() ? 'Scheduled' : 'Sent';
          }

          return '--';
        },
        labelStyle: (model: any) => {
          return `push__status push__status--${
            moment(model.scheduledAt) > moment() ? 'scheduled' : 'sent'
          }`;
        },
        width: 260
      },
      {
        prop: 'action',
        label: '',
        align: 'center',
        fixed: 'right',
        width: 60
      }
    ];
  }
  public formatType(model: any) {
    if (model && model.target.length && model.target[0] === 'dealer_app') {
      return 'All';
    }

    return model.metadata && model.metadata.filter && model.metadata.filter.typeDealers.length
    && model.metadata.filter.typeDealers[0] !== ' '
    ? this.formatTypeDealer(model.metadata.filter.typeDealers) : '--';
  }
  public handleActions({ id, action }) {
    if (action === 'edit') {
      this.openEditor(id);

      return;
    }

    if (action === 'view') {
      this.openDetailDialog(id);

      return;
    }

    if (action === 'delete') {
      this.onDelete(id);
    }

    return;
  }
  public getActions(model) {
    if (moment(model) < moment(new Date())) {
      return ['view', 'edit'];
    }

    return ['view', 'edit', 'delete'];
  }

  public dataEmail(ids: any) {
    const dataEmail = [];
    if (ids && ids.length) {
      ids.map((dealerId) => {
        const _dealer = this.allDealers.find((el) => el.id === dealerId);
        dataEmail.push(_dealer);
      });
    }

    return dataEmail;
  }
  public formatterTargetDetal(item: any) {
    if (item && item.role) {
      return item.role && item.role.alias === 'daikin_staff' ? item.phoneNumber : item.dealerCode;
    }

    return '--';
  }
  public formatterTarget(model: any) {

    return model.target.indexOf('dealer') >= 0
    ? 'Single Dealer' : isArray(model.target)
    ? this.formatTarget(model.target[0])
    : this.formatTarget(model.target);
  }
  public formatterDealerTarget(model: any) {
    if (model && model.target.length && model.target[0] === 'dealer_app') {
      return '--';
    }

    return model.dealerIds ? model.dealerIds.length : '--';
  }
  public onDelete(id): void {
    this.$store.dispatch(ActionTypeNotification.NotificationDelete, {
      id,
      onSuccess: () => {
        // this.fetchData();
        this.fetchTargetDetail();
      },
      onFailure: (err) => {
        this.$store.dispatch(ActionType.CatchException, err);
      }
    });
  }
  public formatTypeDealer(target: string | string[]) {
    if (!isArray(target)) {
      return target
        .split('_')
        .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
    }
    const _cloneTarget = [...target];
    if (_cloneTarget.includes('daikin_staff')) {
      const _index = _cloneTarget.indexOf('daikin_staff');
      _cloneTarget.splice(_index, 1, 'salesman');
    }
    const result = _cloneTarget.reduce((acc, type) => {
      const formattedType = type
        .split('_')
        .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');

      return `${acc + formattedType}, `;
    }, '');

    return result.slice(0, -2) || '--';
  }

  public formatTarget(target: string): string {
    return target
      .split('_')
      .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }
  // public formatTypeDealer(value: string) {
  //   let text = '--';
  //   switch (value) {
  //   case SystemRoleAlias.DaikinDealer:
  //     text = 'Daikin Dealer';
  //     break;
  //   case SystemRoleAlias.SubLeader:
  //     text = 'Sub Dealer';
  //     break;
  //   case SystemRoleAlias.Freelancer:
  //     text = 'Freelancer';
  //     break;
  //   default:
  //     break;
  //   }

  //   return text;
  // }

  public onChangePaginationHandle(value) {
    if (typeof value === 'number') {
      this.$store.commit(MutationTypeNotification.SetNotificationPagination, {
        ...this.pagination,
        limit: value
      });
    } else {
      this.$store.commit(MutationTypeNotification.SetNotificationPagination, {
        ...this.pagination,
        ...value
      });
    }
    this.$store.dispatch(ActionTypeNotification.NotificationFilterNoCache);
  }

  protected updated() {
    const notifications: INotification[] = this.$store.getters[
      'getAllNotifications'
    ];

    if (this.targetDetails.length === 0) {
      return;
    }

    const dealers = [];
    notifications.forEach((noti) => {
      if (
        noti.target !== TargetOptions.Dealer &&
        noti.target[0] !== TargetOptions.Dealer &&
        noti.target !== TargetOptions.Technician &&
        noti.target[0] !== TargetOptions.Technician
      ) {
        return;
      }
      const targetDetail = this.targetDetails.find(
        (target) => target.referenceId === noti.id
      );
      if (!targetDetail) {
        return;
      }
      if (!isArray(targetDetail.receiverIds)) {
        // const dealer: IDealer = this.$store.getters['getDealerDetail'](
        //   targetDetail.receiverIds
        // );
        const dealer: IDealer = this.allDealers.find((e) => e.id === targetDetail.id);
        if (!dealer) {
          return;
        }
        dealers.push({ id: dealer.id, email: dealer.email, refId: noti.id });

        return;
      }
      targetDetail.receiverIds.forEach((receiver) => {
        // const dealer: IDealer = this.$store.getters['getDealerDetail'](
        //   receiver
        // );
        const dealer: IDealer = this.allDealers.find((e) => e.id === receiver);
        if (!dealer) {
          return;
        }
        dealers.push({
          id: dealer.id,
          email: dealer.email,
          refId: noti.id
        });
      });
    });
    this.dealersDetails = [...dealers];
  }
  protected mounted() {
    this.$store.commit(
      MutationType.SetBreadcrumb,
      this.$t('notification.breakCrumb')
    );
    // tslint:disable-next-line:early-exit
    if (!this.isRefesh) {
      this.$store.commit(MutationTypeNotification.NotificationLoading);
      this.fetchTargetDetail().then(() => {
        this.fetchData();
      });
    }
  }
}
