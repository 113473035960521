import { TabPane } from 'element-ui';
import moment from 'moment';
import { CustomCampaignRouterName, CustomCampaignTab } from 'root/admin/CustomCampaign';
import { SystemRoleAlias } from 'root/admin/User/Store/types';
import { IBreadcrumb, IUser } from 'root/models';
import { IState, MutationType } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import { ActionTypeAclCampaign } from '../../Store/types';
import './styles.scss';

@Component({
  template: require('./view.html'),
  computed: {
    ...mapState({
      authUser: (state: IState) => state.global.authUser,
    })
  }
})

export class CampaignConfiguration extends Vue {
  public get activeTab() {
    return CustomCampaignTab.Configuration;
  }

  public set activeTab(_value: string) {
    //
  }
  public get isSalePlanning() {
    if (this.authUser.superAdmin || this.authUser.role.alias === SystemRoleAlias.Admin
      || this.authUser.role.alias === SystemRoleAlias.SaleAdmin) {
      return true;
    }

    return false;
  }
  public configId: string = '';
  public uploadFrom: Date = null;
  public authUser: IUser;
  public visibleEndcampaign: boolean = false;
  public maskSpecial: boolean = false;
  public campaignName: string = '';
  public startDate: Date = null;
  public endDate: Date = null;
  public isError: boolean = false;
  public endedCampaign: boolean = false;
  public changeTab(tab: TabPane) {
    switch (tab.name) {
    case CustomCampaignTab.Participant:
      this.$router.push({
        name: CustomCampaignRouterName.Participant
      });
      break;
    case CustomCampaignTab.QualifiedShops:
      this.$router.push({
        name: CustomCampaignRouterName.QualifiedShops
      });
      break;
    case CustomCampaignTab.Statistic:
      this.$router.push({
        name: CustomCampaignRouterName.Statistic
      });
      break;
    case CustomCampaignTab.ManageExport:
      this.$router.push({
        name: CustomCampaignRouterName.ManageExport
      });
      break;
    case CustomCampaignTab.Shops:
      this.$router.push({
        name: CustomCampaignRouterName.Shops
      });
      break;
    default:
      break;
    }
  }
  public closeDialog() {
    this.visibleEndcampaign = false;
    this.isError = false;
    this.campaignName = '';
  }
  public handleEndCampaign() {
    if (this.campaignName && this.campaignName.toLowerCase() === 'acl display campaign') {
      this.$store.dispatch(ActionTypeAclCampaign.EndCampaign, {
        id: this.configId,
        onSuccess: () => {
          this.$message({
            type: 'success',
            message: this.$t('message.endCampaign').toString()
          });
          this.fetchData();
          this.closeDialog();
        }
      });

      return;
    }
    this.isError = true;
  }
  public onClickEndCampaign() {
    this.visibleEndcampaign = true;
  }
  public FormatDateFullTime(date: Date) {
    return moment(date).format('DD/MM/YYYY');
  }
  public fetchData() {
    this.$store.dispatch(ActionTypeAclCampaign.GetCampaignConfiguration, {
      onSuccess: (config) => {
        this.configId = config[0].aclCampaignConfig.id;
        this.startDate = config[0].aclCampaignConfig.uploadStartDate;
        this.endDate = config[0].aclCampaignConfig.uploadEndDate;
        this.$store.dispatch(ActionTypeAclCampaign.GetMaskConfig, {
          id: this.configId,
          onSuccess: (maskConfig) => {
            this.maskSpecial = maskConfig[0].isSpecial;
            this.endedCampaign = maskConfig[0].isEnded;
            // this.startDate = maskConfig[0].startDate;
            // this.endDate = maskConfig[0].endDate;
          }
        });
      }
    });
  }
  public maskChange() {
    this.$store.dispatch(ActionTypeAclCampaign.MaskChange, {
      id: this.configId,
      mask: this.maskSpecial,
      onSuccess: () => {
        this.$message({
          type: 'success',
          message: this.$t('message.maskChange').toString()
        });
        this.closeDialog();
      }
    });
  }
  protected mounted() {
    this.$nextTick(() => {
      const breadcrumb: IBreadcrumb[] = [
        {
          label: 'title.campaign',
          path: null,
          current: false
        },
        {
          label: 'title.acl_campaign',
          path: null,
          current: true
        }
      ];
      this.$store.commit(MutationType.SetBreadcrumb, breadcrumb);

      this.fetchData();
    });
  }
}
