import axios from 'axios';
import { DKGqlClient } from 'root/api/graphql/Client';
import { ActionType, IState, MutationType } from 'root/store';
import { crudActions } from 'root/store/helpers';
import { ActionContext } from 'vuex';
import { IExampleState } from '.';
import { ActionTypeExample } from './types';

const {example} = DKGqlClient();
const baseURl = process.env.BASE_AUTH_API;
export const actions = {
  ...crudActions('example', {
    filter: example.filter
  }),
  async [ActionTypeExample.GetAdministration]({
    commit, dispatch
  }: ActionContext<IExampleState, IState>) {
    try {
      const response = await axios({
        method: 'GET',
        url: `${baseURl}/administration-data`,
      });
      if (response) {
        commit(MutationType.SetLocalVN, response.data);
      }

    } catch (error) {
      dispatch(ActionType.CatchException, error);
      throw error.response.data;
      // return Promise.reject(error.response);
    }
  },
};
