
import { Header } from 'root/components/Header';
import { Navigator } from 'root/components/Navigator';
import { RouteDictionary } from 'root/router/types';
import { IState } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import './home.scss';

@Component({
  template: require('./view.html'),
  components: {
    'dk-header': Header,
    navigator: Navigator
  },
  computed: {
    ...mapState({
      isLoading: (state: IState) => {
        return state.global.isLoading;
      }
    }),
  }
})

export class HomePortalLayout extends Vue {
  public isCollapse: boolean = false;

  public handleCollapse() {
    this.isCollapse = !this.isCollapse;
  }
  public get isShowHeader(): boolean {
    const path = this.$route.path;

    return path !== RouteDictionary.Login;
      // && path !== RouteDictionary.Register
      // && path !== RouteDictionary.ForgotPassword;
  }

  protected mounted() {
    this.$nextTick(() => {
      //
    });
  }
}
