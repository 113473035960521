import { TabPane } from 'element-ui';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import { ActionTypeBranchManage } from 'root/admin/BranchAndGroup/BranchManage/Store/types';
import { DealerRouterName } from 'root/admin/Dealer';
import { SystemRoleAlias } from 'root/admin/User/Store/types';
import { DKGqlClient } from 'root/api/graphql/Client';
import { IPaginationParams } from 'root/api/graphql/Core';
import { FilterByBranch } from 'root/components/FilterByBranch';
import {
  fullNameUser,
  parseUTCToTime,
  FormatDateFullTime,
  FormatDateTime
} from 'root/helpers';
import { ConfigurationKey, IAddress, IBreadcrumb, IFilterInput, IUser } from 'root/models';
import { IState, MutationType } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import { NewRaCampaignRouterName, NewRaCampaignTab } from '..';
// import { ShopCampaignDetailModel } from '../Components/ShopCampaignDetailModel';
import { Role } from '../ParticipantList';
import { ActionTypeNewRaCampaign, MutationTypeNewRaCampaign } from '../Store/types';
import { downloadPDF } from '../downloadPDFhelper';
import './styles.scss';

export enum ContractStatus {
  Accepted = 'accepted',
}
export enum RequestStatusCode {
  NotJoin = 'not_join',
  WaitingForReup = 'request_re_up',
  WaitingForReview = 'waiting_for_review',
  Approved = 'approved',
  Rejected = 'rejected',
}

export enum RequestStatus {
  NotJoin = 'Not Join',
  WaitingForReup = 'Waiting For Reup',
  WaitingForReview = 'Waiting For Review',
  Approved = 'Approved',
  Rejected = 'Rejected',
}
export enum RequestAction {
  Approved = 'approve',
  Rejected = 'reject',
  RequestUpdate = 'request_update',
}
export enum QualifiedCode {
  Qualified = 'qualified',
  NonQualified = 'non_qualified'
}
export enum Qualified {
  Qualified = 'Qualified',
  NonQualified = 'Non Qualified'
}
const enum FilterKey {
  Status = 'status',
  Branch = 'branchId',
  SLStatus = 'spStatus',
  BranchStatus = 'branchStatus',
  Type = 'type',
  Classification = 'classification',
  SaleManager = 'saleManager',
  PaymentDocument = 'paymentDocument',
  DealerApproval = 'dealerApproval'
}
@Component({
  template: require('./view.html'),
  name: 'NewRACampaignShop',
  components: {
    // 'new-shops-detail-modal': ShopCampaignDetailModel,
    'btn-filter-by-branch': FilterByBranch
  },
  computed: {
    ...mapState({
      authUser: (state: IState) => state.global.authUser,
      dealer: (state: IState) => state.newRACampaign.data,
      data: (state: IState) => state.newRACampaign.listJoinedShop,
      loading: (state: IState) => state.newRACampaign.loading,
      pagination: (state: IState) => state.newRACampaign.pagination,
      branch: (state: IState) => state.branchManage.data,
    }),
  },
})
export class NewRACampaignShop extends Vue {
  public get isSalePlanning() {
    if (this.authUser.superAdmin || this.authUser.role.alias === SystemRoleAlias.Admin
      || this.authUser.role.alias === SystemRoleAlias.SaleAdmin) {
      return true;
    }

    return false;
  }
  public get isBranchPic() {
    if (this.authUser.role.alias === SystemRoleAlias.BranchManager) {
      return true;
    }

    return false;
  }
  public get isDepartmentPic() {
    if (this.authUser.role.alias === SystemRoleAlias.GroupLeader) {
      return true;
    }

    return false;
  }
  public get isSalesStaff() {
    if (this.authUser.role.alias === SystemRoleAlias.SaleStaff) {
      return true;
    }

    return false;
  }
  public get currentRole() {
    return this.authUser.role.alias;
  }
  public get activeTab() {
    return NewRaCampaignTab.Shop;
  }

  public set activeTab(_value: string) {
    //
  }
  public get columns() {
    return [
      {
        prop: 'dealer',
        label: 'Direct Dealer Name',
        labelItem: 'Direct Dealer Code',
        formatter: (model: any) => {
          return fullNameUser(model) || '--';
        },
        avatar: {
          formatter: (model: any) => {
            return model ? model : null;
          }
        },
        width: 250,
        fixed: true
      },
      {
        prop: 'shop',
        label: 'Shop Name',
        labelItem: 'Shop Code',
        width: 220,
        formatter: (model: any) => {
          return model && model.name ?
          model.name : '--';
        }
      },
      {
        prop: 'shopAddress',
        label: 'Address',
        width: 220,
        formatter: (model: any) => {
          if (model.address) {
            return this.displayAddress(model.address) || '--';
          }

          return '--';
        }
      },
      // {
      //   prop: 'review content',
      //   label: 'Sales Staff last review content',
      //   formatter: (model: any) => {
      //     return model.acl_campaigns_2023[0] && model.acl_campaigns_2023[0].shop ?
      //     model.acl_campaigns_2023[0].shop.name : '--';
      //   },
      //   width: 300,
      // },
      // {
      //   prop: 'review at',
      //   label: 'Sales Staff last review time',
      //   formatter: (model: any) => {
      //     return model.acl_campaigns_2023[0] && model.acl_campaigns_2023[0].shop ?
      //     model.acl_campaigns_2023[0].shop.name : '--';
      //   },
      //   width: 120,
      // },

      {
        prop: 'spiralReview',
        label: 'Spiral Review Result',
        formatter: (model: any) => {
          return model && model.aclSpiralReviews && model.aclSpiralReviews.length &&
          model.aclSpiralReviews[0].totalPoint ?
          model.aclSpiralReviews[0].totalPoint : '--';
        },
        width: 120,
      },
      {
        prop: 'paymentDocument',
        label: 'Payment Document',
        width: 120,
        formatter: (model) => {
          return model.dealerShops && model.dealerShops.length && model.dealerShops[0].dealer &&
          model.dealerShops[0].dealer.information2023 &&
          (model.dealerShops[0].dealer.information2023.requestedRewardReceiptURLs &&
            model.dealerShops[0].dealer.information2023.requestedRewardReceiptURLs.length
            || model.dealerShops[0].dealer.information2023.data &&
            model.dealerShops[0].dealer.information2023.data.link) ? 'Yes' : 'No';
        },
        labelStyle: (model) => {
          return model.dealerShops && model.dealerShops.length && model.dealerShops[0].dealer &&
           model.dealerShops[0].dealer.information2023 &&
           (model.dealerShops[0].dealer.information2023.requestedRewardReceiptURLs &&
            model.dealerShops[0].dealer.information2023.requestedRewardReceiptURLs.length
            || model.dealerShops[0].dealer.information2023.data &&
            model.dealerShops[0].dealer.information2023.data.link) ? 'activated' : 'non_qualified';
        },
      },
      {
        prop: 'BPStatus',
        label: 'Sales Staff Review Result',
        formatter: this.formatterClassification,
        labelStyle: this.formatterReviewStatus,
        width: 120,
      },
      {
        prop: 'SDPStatus',
        label: 'Department PIC Review Result',
        formatter: this.formatterSalePICClassification,
        labelStyle: this.formatterSalePICReviewStatus,
        width: 120,
      },
      {
        prop: 'SMStatus',
        label: 'Sales Manager Review Result',
        formatter: this.formatterSaleManageClassification,
        labelStyle: this.formatterSaleManageReviewStatus,
        width: 120,
      },
      {
        prop: 'SPStatus',
        label: 'Sale Planning Review Result',
        width: 120,
        formatter: this.formaterSalePlanningStatus,
        labelStyle: this.formatterStyleStatus,
      },
      {
        prop: 'review at',
        label: 'Sales Staff last review time',
        formatter: (model: any) => {
          return model.acl_campaigns_2023[0] && model.acl_campaigns_2023[0].shop ?
          model.acl_campaigns_2023[0].shop.name : '--';
        },
        width: 120,
      },
      {
        prop: 'status',
        label: 'Status',
        width: 120,
        formatter: this.formaterStatus,
        labelStyle: (model: any) => {
          return model.acl_campaigns_2023[0] ? `ra_${model.acl_campaigns_2023[0].requestStatus}` : '';
        },
      },
      {
        prop: 'dealerApprove',
        label: 'Dealer Approval',
        formatter: (model: any) => {
          return model.acl_campaigns_2023[0] && model.acl_campaigns_2023[0].isDealerConfirm ? 'YES' : 'No';
        },
        labelStyle: (model: any) => {
          return model.acl_campaigns_2023[0] && model.acl_campaigns_2023[0].isDealerConfirm ?
          'ra_has_confirmCode' : 'ra_no_has_confirmCode';
        },
        width: 120,
      },
      {
        prop: 'dealerApproveAt',
        label: 'Dealer Approval At',
        formatter: (model: any) => {
          return model.acl_campaigns_2023[0] && model.acl_campaigns_2023[0].dealerApproveAt ?
          moment(model.acl_campaigns_2023[0].dealerApproveAt).format('HH:mm, DD/MM/YYYY') : '--';
        },
        width: 120,
      },
      {
        prop: 'action',
        label: '',
        align: 'center',
        fixed: 'right',
        width: 60
      }
    ];
  }
  public get dataFilterBranch() {
    const _branch: IFilterInput[] = this.branch.reduce((res, item) => {
      res.push({
        key: item.id,
        value: item.id,
        name: item.name
      });

      return res;
    }, []);

    return [
      {
        key: FilterKey.Branch,
        name: 'Branch',
        value: _branch
      },
    ];
  }
  public get dataFilter() {
    const _statuses: IFilterInput[] = [
      {
        key: RequestStatusCode.NotJoin,
        value: RequestStatusCode.NotJoin,
        name: RequestStatus.NotJoin,
      },
      {
        key: RequestStatusCode.WaitingForReview,
        value: RequestStatusCode.WaitingForReview,
        name: RequestStatus.WaitingForReview,
      },
      {
        key: RequestStatusCode.WaitingForReup,
        value: RequestStatusCode.WaitingForReup,
        name: RequestStatus.WaitingForReup,
      },
      {
        key: RequestStatusCode.Approved,
        value: RequestStatusCode.Approved,
        name: RequestStatus.Approved,
      },
      {
        key: RequestStatusCode.Rejected,
        value: RequestStatusCode.Rejected,
        name: RequestStatus.Rejected,
      },
    ];
    const _saleStatus: IFilterInput[] = [
      {
        key: 'not_review',
        value: 'not_review',
        name: 'Not Review',
      },
      {
        key: RequestStatusCode.Approved,
        value: RequestStatusCode.Approved,
        name: RequestStatus.Approved,
      },
      {
        key: RequestStatusCode.Rejected,
        value: RequestStatusCode.Rejected,
        name: RequestStatus.Rejected,
      },
    ];
    const _branchStatus: IFilterInput[] = [
      {
        key: 'not_review',
        value: 'not_review',
        name: 'Not Review',
      },
      {
        key: QualifiedCode.Qualified,
        value: QualifiedCode.Qualified,
        name: Qualified.Qualified,
      },
      {
        key: QualifiedCode.NonQualified,
        value: QualifiedCode.NonQualified,
        name: Qualified.NonQualified,
      },
    ];
    const classification: IFilterInput[] = [
      {
        key: 'not_review',
        value: 'not_review',
        name: 'Not Review',
      },
      {
        key: QualifiedCode.Qualified,
        value: QualifiedCode.Qualified,
        name: Qualified.Qualified,
      },
      {
        key: QualifiedCode.NonQualified,
        value: QualifiedCode.NonQualified,
        name: Qualified.NonQualified,
      },
    ];
    const saleManage: IFilterInput[] = [
      {
        key: 'not_review',
        value: 'not_review',
        name: 'Not Review',
      },
      {
        key: QualifiedCode.Qualified,
        value: QualifiedCode.Qualified,
        name: Qualified.Qualified,
      },
      {
        key: QualifiedCode.NonQualified,
        value: QualifiedCode.NonQualified,
        name: Qualified.NonQualified,
      },
    ];
    const payment: IFilterInput[] = [
      {
        key: 'true',
        value: 'true',
        name: 'Yes',
      },
      {
        key: 'false',
        value: 'false',
        name: 'No',
      },
    ];
    const dealerApproval: IFilterInput[] = [
      {
        key: 'true',
        value: 'true',
        name: 'Yes',
      },
      {
        key: 'false',
        value: 'false',
        name: 'No',
      },
    ];

    return [
      {
        key: FilterKey.Status,
        name: this.$t('field.status'),
        value: _statuses
      },
      {
        key: FilterKey.SLStatus,
        name: 'Sale Planning Review',
        value: _saleStatus
      },
      {
        key: FilterKey.BranchStatus,
        name: 'Sales Staff Review',
        value: _branchStatus
      },
      {
        key: FilterKey.Classification,
        name: 'Department PIC Review',
        value: classification
      },
      {
        key: FilterKey.SaleManager,
        name: 'Sales Manager Review',
        value: saleManage
      },
      {
        key: FilterKey.PaymentDocument,
        name: 'Payment Document',
        value: payment
      },
      {
        key: FilterKey.DealerApproval,
        name: 'Dealer Approval',
        value: dealerApproval
      }
    ];
  }
  public get selectedFilter() {
    const filterParams = cloneDeep(this.filterParams);

    return [
      {
        key: FilterKey.Status,
        value: filterParams[FilterKey.Status]
      },
      {
        key: FilterKey.Branch,
        value: filterParams[FilterKey.Branch]
      },
      {
        key: FilterKey.SLStatus,
        value: filterParams[FilterKey.SLStatus]
      },
      {
        key: FilterKey.BranchStatus,
        value: filterParams[FilterKey.BranchStatus]
      },
      {
        key: FilterKey.Classification,
        value: filterParams[FilterKey.Classification]
      },
      {
        key: FilterKey.SaleManager,
        value: filterParams[FilterKey.SaleManager]
      },
      {
        key: FilterKey.PaymentDocument,
        value: filterParams[FilterKey.PaymentDocument]
      },
      {
        key: FilterKey.DealerApproval,
        value: filterParams[FilterKey.DealerApproval]
      },
    ];
  }
  public get isShowModuleByConfig() {
    const module = this.config.find((it) => it.moduleName === 'new_ra_campaign_tabs');
    if (module && module.roles.includes(this.authUser.role.alias) && module.emails.includes(this.authUser.email)) {

      return true;
    }

    return false;
  }
  public pagination: IPaginationParams;
  public searchText: string = '';
  public FormatDateFullTime = FormatDateFullTime;
  public formatDate = parseUTCToTime;
  public SystemRoleAlias = SystemRoleAlias;
  public disableDownload: boolean = true;
  public downloadPDF = downloadPDF;
  public shopId: string = '';
  public branch: any[];
  public listExportIds: any[] = [];
  public config: any[] = [];
  public approveIds: any[] = [];
  public exportLoading: boolean = false;
  public visibleDetail: boolean = false;
  public isOnCampaign: boolean = false;
  public disableApprove: boolean = true;
  public isNotJoin: boolean = true;
  public ConfigCropPicture: string = 'NO';
  public authUser: IUser;
  public filterParams = {
    [FilterKey.Status]: [],
    [FilterKey.Branch]: [],
    [FilterKey.SLStatus]: [],
    [FilterKey.BranchStatus]: [],
    [FilterKey.Classification]: [],
    [FilterKey.SaleManager]: [],
    [FilterKey.PaymentDocument]: [],
    [FilterKey.DealerApproval]: [],
  };
  public formatterRole(model) {
    if (model && model.dealerShops.length && model.dealerShops[0].dealer && model.dealerShops[0].dealer.role) {
      switch (model.dealerShops[0].dealer.role.alias) {
      case Role.DaikinDealer:
        return 'Direct Dealer';
      case Role.SubDealer:
        return 'Sub Dealer';
      }
    }

    return '--';
  }
  public changeTab(tab: TabPane) {
    switch (tab.name) {
    case NewRaCampaignTab.Participant:
      this.$router.push({
        name: NewRaCampaignRouterName.Participant
      });
      break;
    case NewRaCampaignTab.Registration:
      this.$router.push({
        name: NewRaCampaignRouterName.Registration
      });
      break;
    case NewRaCampaignTab.SubDealer:
      this.$router.push({
        name: NewRaCampaignRouterName.SubDealer
      });
      break;
    case NewRaCampaignTab.RequestList:
      this.$router.push({
        name: NewRaCampaignRouterName.RequestList
      });
      break;
    case NewRaCampaignTab.Config:
      this.$router.push({
        name: NewRaCampaignRouterName.Config
      });
      break;
    case NewRaCampaignTab.Statistic:
      this.$router.push({
        name: NewRaCampaignRouterName.Statistic
      });
      break;
    case NewRaCampaignTab.Export:
      this.$router.push({
        name: NewRaCampaignRouterName.Export
      });
      break;
    case NewRaCampaignTab.Classified:
      this.$router.push({
        name: NewRaCampaignRouterName.Classified
      });
      break;
    default:
      break;
    }
  }
  public back() {
    this.$router.push(DealerRouterName.ListDealer);
  }
  public formatterDirectDealerName(model) {
    if (model && model.dealerShops.length && model.dealerShops[0].dealer &&
    model.dealerShops[0].dealer.role.alias === Role.DaikinDealer) {
      return model.dealerShops[0].dealer.name;
    }

    return '--';
  }
  public formatterDirectDealerCode(model) {
    if (model && model.dealerShops.length && model.dealerShops[0].dealer &&
      model.dealerShops[0].dealer.role.alias === Role.DaikinDealer) {
      return model.dealerShops[0].dealer.dealerCode;
    }

    return '--';
  }
  public formatterSubDealerName(model) {
    if (model && model.dealerShops.length && model.dealerShops[0].dealer &&
      model.dealerShops[0].dealer.role.alias === Role.SubDealer) {
      return model.dealerShops[0].dealer.name;
    }

    return '--';
  }
  public formatterSubDealerCode(model) {
    if (model && model.dealerShops.length && model.dealerShops[0].dealer &&
      model.dealerShops[0].dealer.role.alias === Role.SubDealer) {
      return model.dealerShops[0].dealer.dealerCode;
    }

    return '--';
  }
  public changeSelect(e) {
    this.approveIds = [];
    this.listExportIds = [];
    if (
      e.length && e.every(
        (item) => item.acl_campaigns_2023.length && item.acl_campaigns_2023[0].requestStatus ===
        RequestStatusCode.WaitingForReview || item.acl_campaigns_2023.length &&
        item.acl_campaigns_2023[0].requestStatus === RequestStatusCode.WaitingForReup
      )
    ) {
      this.disableApprove = false;
      e.map((it) => this.approveIds.push(it.acl_campaigns_2023[0].id));

      return;
    }
    if (
      e.length && e.every(
        (item) => item.acl_campaigns_2023.length &&
        (item.acl_campaigns_2023[0].requestStatus === RequestStatusCode.Approved
          || item.acl_campaigns_2023[0].requestStatus === RequestStatusCode.Rejected)
      )
    ) {
      this.disableDownload = false;
      e.map((it) => this.listExportIds.push(it.acl_campaigns_2023[0].id));

      return;
    }
    this.disableApprove = true;
    this.disableDownload = true;
  }
  public async download() {
    this.exportLoading = true;
    if (this.listExportIds.length === 0) {
      this.exportLoading = false;

      return;
    }

    this.downloadPDF(this.listExportIds);
  }
  public formatterTime(model: any) {
    if (model.acl_campaigns_2023.length && model.acl_campaigns_2023[0].reviews.length
      && model.acl_campaigns_2023[0].classification !== null) {
      const last = model.acl_campaigns_2023[0].reviews.length - 1;

      return moment(model.acl_campaigns_2023[0].reviews[last].createdAt).format('HH:mm, DD/MM/YYYY');
    }

    return '--';
  }
  public addressFormater(model: any) {
    if (model) {
      return this.displayAddress(model) || '--';
    }

    return '--';
  }

  public formatterDate(model: any) {
    if (model.acl_campaign.length && model.acl_campaign[0].reviews.length) {
      const last = model.acl_campaign[0].reviews.length - 1;

      return moment(model.acl_campaign[0].reviews[last].updatedAt).format('DD/MM/YYYY');
    }

    return '--';
  }
  public formatterClassification(model: any) {
    if (model.acl_campaigns_2023.length && model.acl_campaigns_2023[0].classification) {
      switch (model.acl_campaigns_2023[0].classification) {
      case QualifiedCode.Qualified:
        return Qualified.Qualified;
      case QualifiedCode.NonQualified:
        return Qualified.NonQualified;
      }
    }

    return 'Not Review';
  }
  public formatterReviewStatus(model: any) {
    if (model.acl_campaigns_2023.length && model.acl_campaigns_2023[0].classification) {
      switch (model.acl_campaigns_2023[0].classification) {
      case QualifiedCode.Qualified:
        return `newRACampaign__${QualifiedCode.Qualified}`;
      case QualifiedCode.NonQualified:
        return `newRACampaign__${QualifiedCode.NonQualified}`;
      }
    }

    return 'not_review';
  }
  public formatterSalePICClassification(model: any) {
    if (model.acl_campaigns_2023.length && model.acl_campaigns_2023[0].salePicReview) {
      switch (model.acl_campaigns_2023[0].salePicReview) {
      case QualifiedCode.Qualified:
        return Qualified.Qualified;
      case QualifiedCode.NonQualified:
        return Qualified.NonQualified;
      }
    }

    return 'Not Review';
  }
  public formatterSalePICReviewStatus(model: any) {
    if (model.acl_campaigns_2023.length && model.acl_campaigns_2023[0].salePicReview) {
      switch (model.acl_campaigns_2023[0].salePicReview) {
      case QualifiedCode.Qualified:
        return `newRACampaign__${QualifiedCode.Qualified}`;
      case QualifiedCode.NonQualified:
        return `newRACampaign__${QualifiedCode.NonQualified}`;
      }
    }

    return 'not_review';
  }
  public formatterSaleManageClassification(model: any) {
    if (model.acl_campaigns_2023.length && model.acl_campaigns_2023[0].saleManagerReview) {
      switch (model.acl_campaigns_2023[0].saleManagerReview) {
      case QualifiedCode.Qualified:
        return Qualified.Qualified;
      case QualifiedCode.NonQualified:
        return Qualified.NonQualified;
      }
    }

    return 'Not Review';
  }
  public formatterSaleManageReviewStatus(model: any) {
    if (model.acl_campaigns_2023.length && model.acl_campaigns_2023[0].saleManagerReview) {
      switch (model.acl_campaigns_2023[0].saleManagerReview) {
      case QualifiedCode.Qualified:
        return `newRACampaign__${QualifiedCode.Qualified}`;
      case QualifiedCode.NonQualified:
        return `newRACampaign__${QualifiedCode.NonQualified}`;
      }
    }

    return 'not_review';
  }
  public formatterReason(model: any) {
    if (model.acl_campaigns_2023.length && model.acl_campaigns_2023[0].shop &&
      model.acl_campaigns_2023[0].shop.acl_campaigns_2023.length &&
      model.acl_campaigns_2023[0].shop.acl_campaigns_2023[0].requestStatus === 'rejected') {

      return model.acl_campaigns_2023[0].shop.acl_campaigns_2023[0].reason;
    }

    return '--';
  }
  public formatterContent(model: any) {
    if (model.acl_campaigns_2023.length && model.acl_campaigns_2023[0].reviews.length
      && model.acl_campaigns_2023[0].classification !== null) {
      const last = model.acl_campaigns_2023[0].reviews.length - 1;

      return model.acl_campaigns_2023[0].reviews[last].description.length === 0 ?
      '--' : model.acl_campaigns_2023[0].reviews[last].description.length < 120 ?
      model.acl_campaigns_2023[0].reviews[last].description
        : model.acl_campaigns_2023[0].reviews[last].description.slice(0, 120) + '...';
    }

    return '--';
  }

  public formatPackage(campaign: any) {
    if (!campaign || campaign.length === 0) {
      return '--';
    }

    return `${campaign.model1}, ${campaign.model2}, ${campaign.model3}`;
  }
  public formaterSalePlanningStatus(model: any) {
    if (model.acl_campaigns_2023.length) {
      switch (model.acl_campaigns_2023[0].requestStatus) {
      case RequestStatusCode.Approved:
        return RequestStatus.Approved;
      case RequestStatusCode.Rejected:
        return RequestStatus.Rejected;
      default:
        return 'Not Review';
      }
    }

    return 'Not Review';
  }
  public formatterStyleStatus(model: any) {
    if (model && model.acl_campaigns_2023.length && model.acl_campaigns_2023[0].requestStatus) {
      switch (model.acl_campaigns_2023[0].requestStatus) {
      case RequestStatusCode.Approved:
        return RequestStatusCode.Approved;
      case RequestStatusCode.Rejected:
        return RequestStatusCode.Rejected;
      default:
        return 'not_review';
      }
    }

    return 'not_review';
  }
  public formaterStatus(model: any) {
    if (model.acl_campaigns_2023[0] && model.acl_campaigns_2023[0].requestStatus) {
      switch (model.acl_campaigns_2023[0].requestStatus) {
      case RequestStatusCode.WaitingForReup:
        return RequestStatus.WaitingForReup;
      case RequestStatusCode.WaitingForReview:
        return RequestStatus.WaitingForReview;
      case RequestStatusCode.Approved:
        return RequestStatus.Approved;
      case RequestStatusCode.Rejected:
        return RequestStatus.Rejected;
      }
    }

    return 'Not Join';
  }
  public getAction() {
    return 'view';
  }
  public closeDialog() {
    // this.visibleEditor = false;
  }
  public handleActions(id) {
    this.clickDetail(id.id);
  }
  public formatStartTimeEndtime(startTime: any , endTime: any) {
    if (!startTime || !endTime) {
      return '--';
    }

    return `${FormatDateTime(startTime)} - ${FormatDateTime(endTime)}`;
  }
  public formatStartTimeEndtimeFromMetadata(metadata, keyToDetech) {
    for (const [key, value] of Object.entries(metadata)) {
      if (key.toString() === keyToDetech) {
        return value;
      }
    }
  }

  public displayAddress(model: IAddress) {
    return model && model.province
      ? this.$store.getters['getAddressString'](model)
      : '';
  }
  public formatUTCDate(date) {
    return moment(date).utc().format('DD/MM/YYYY');
  }
  public clickDetail(id: string) {
    this.shopId = id;
    this.visibleDetail = true;
  }
  public closeDialogDetail() {
    // change after
    this.shopId = '';
    this.visibleDetail = false;
    this.fetchData();
  }
  public changeFilterBranch(filter) {
    const nextFilter = {
      [FilterKey.Branch]: filter ? filter : [],
    };
    this.filterParams = {
      ...cloneDeep(this.filterParams),
      ...nextFilter
    };
    this.fetchData();
  }
  public removeItem() {
    this.disableDownload = true;
  }
  public handlePaginationChange(value) {
    if (typeof value === 'number') {
      this.$store.commit(MutationTypeNewRaCampaign.PaginationChange, {
        ...this.pagination,
        size: value,
      });
    } else {
      this.$store.commit(MutationTypeNewRaCampaign.PaginationChange, {
        ...this.pagination,
        ...value,
      });
    }
    this.fetchData();
  }
  public handleSearch() {
    this.fetchData();
  }
  public approve() {
    this.$msgbox({
      title: this.$t('approve_confirm').toString(),
      message: `Do you want to approve campaign request for ${this.approveIds.length} shop?`,
      showCancelButton: true,
      confirmButtonText: this.$t('confirm').toString(),
      cancelButtonText: this.$t('cancel').toString(),
      beforeClose: (action, instance, done) => {
        if (action === 'confirm') {
          instance.confirmButtonLoading = true;
          instance.confirmButtonText = 'Loading...';
          this.handleApprove({ instance, done });
        } else {
          done();
        }

        return;
      },
    })
      .then(() => {
        this.$message({
          type: 'success',
          message: this.$t('message.approveSuccess').toString(),
        });
      })
      .catch(() => {
        // no handle
      });
  }
  public handleApprove(model) {
    this.$store.dispatch(ActionTypeNewRaCampaign.ApproveShop, {
      ids: this.approveIds,
      action: 'approved',
      updatedBy: this.authUser.id,
      onSuccess: () => {
        model.instance.confirmButtonLoading = false;
        model.instance.confirmButtonText = 'Confirm';
        model.done();
        this.fetchData();
      },
      onFailure: () => {
        model.instance.confirmButtonLoading = false;
        model.instance.confirmButtonText = 'Confirm';
      },
    });
  }
  public clickFilter(value) {
    this.isNotJoin = true;
    const statusSP = value.find((e) => e.key === FilterKey.SLStatus);
    const branchStatus = value.find((e) => e.key === FilterKey.BranchStatus);
    const classification = value.find((e) => e.key === FilterKey.Classification);
    const saleManager = value.find((e) => e.key === FilterKey.SaleManager);
    const dealerApproval = value.find((e) => e.key === FilterKey.DealerApproval);
    // tslint:disable-next-line:early-exit
    if ((statusSP && statusSP.value && statusSP.value.length)
    || (branchStatus && branchStatus.value && branchStatus.value.length)
    || (classification && classification.value && classification.value.length)
    || (saleManager && saleManager.value && saleManager.value.length)
    || (dealerApproval && dealerApproval.value && dealerApproval.value.length)) {
      this.isNotJoin = false;
    }
  }
  public clearFilter() {
    this.isNotJoin = true;
  }
  public changeFilter(model) {
    const status = model.find((e) => e.key === FilterKey.Status);
    // const branch = model.find((e) => e.key === FilterKey.Branch);
    const statusSP = model.find((e) => e.key === FilterKey.SLStatus);
    const branchStatus = model.find((e) => e.key === FilterKey.BranchStatus);
    const classification = model.find((e) => e.key === FilterKey.Classification);
    const saleManager = model.find((e) => e.key === FilterKey.SaleManager);
    const payment = model.find((e) => e.key === FilterKey.PaymentDocument);
    const dealerApproval = model.find((e) => e.key === FilterKey.DealerApproval);
    const nextFilter = {
      [FilterKey.Status]: status ? status.value : [],
      // [FilterKey.Branch]: branch ? branch.value : [],
      [FilterKey.SLStatus]: status ? statusSP.value : [],
      [FilterKey.BranchStatus]: branchStatus ? branchStatus.value : [],
      [FilterKey.Classification]: classification ? classification.value : [],
      [FilterKey.SaleManager]: saleManager ? saleManager.value : [],
      [FilterKey.PaymentDocument]: payment ? payment.value : [],
      [FilterKey.DealerApproval]: dealerApproval ? dealerApproval.value : [],
    };
    this.filterParams = {
      ...cloneDeep(this.filterParams),
      ...nextFilter
    };
    this.fetchData();
  }
  protected async mounted() {
    this.config = await DKGqlClient().configuration.getConfigNavigator();
    this.$nextTick(() => {
      const breadcrumb: IBreadcrumb[] = [
        {
          label: 'title.new_ra_campaign',
          path: null,
          current: false
        },
        {
          label: 'title.new_ra_campaign_participant',
          path: null,
          current: true
        }
      ];
      this.$store.commit(MutationType.SetBreadcrumb, breadcrumb);
      this.$store.dispatch(ActionTypeNewRaCampaign.GetConfigCropPicture, {
        onSuccess: (config) => {
          this.ConfigCropPicture =
            config && config.system_configurations.length ? config.system_configurations.find((e) =>
            e.variableName === ConfigurationKey.ACLCampaignCropPicture).value : 'NO';
        }
      });
      this.$store.dispatch(ActionTypeBranchManage.GetListBranch, {
        form: {
          filter: {},
          pagination: {}
        }
      });
      this.$store.dispatch(ActionTypeNewRaCampaign.GetCampaignConfiguration, {
        onSuccess: (aclConfig) => {
          if (moment(aclConfig[0].aclCampaignConfig.uploadStartDate).isBefore(moment()) &&
            moment(aclConfig[0].aclCampaignConfig.uploadEndDate).isAfter(moment())) {
            this.isOnCampaign = true;
          }
        }
      });
      this.$store.commit(MutationTypeNewRaCampaign.PaginationChange, {
        page: 1,
        limit: 20,
        total: 0
      });
      this.fetchData();
    });
  }
  protected beforeDestroy() {
    this.$store.commit(MutationType.ClearBreadcrumb);
  }
  private fetchData() {
    const text = cloneDeep(this.searchText);
    const _filterJoinedShop = <any> {};
    _filterJoinedShop.type = [];
    let _cloneFilter = null;
    let _cloneStatusFilter = null;
    let _notReview = true;
    let _notJoin = true;
    let _cloneFilterReview = null;
    // const classification = null;
    if (this.filterParams[FilterKey.Status].length) {
      _filterJoinedShop.status = this.filterParams[FilterKey.Status];
    }
    if (this.filterParams[FilterKey.Branch].length) {
      _filterJoinedShop.branch = this.filterParams[FilterKey.Branch];
    }
    if (this.filterParams[FilterKey.SLStatus].length) {
      _filterJoinedShop.spStatus = this.filterParams[FilterKey.SLStatus];
      _cloneFilter = [..._filterJoinedShop.spStatus];
    }
    if (this.filterParams[FilterKey.BranchStatus].length) {
      _filterJoinedShop.branchStatus = this.filterParams[FilterKey.BranchStatus];
    }
    if (_filterJoinedShop.branchStatus && _filterJoinedShop.branchStatus.indexOf('not_review') < 0) {
      _notReview = false;
    }
    if (_filterJoinedShop.branchStatus && _filterJoinedShop.branchStatus.indexOf('not_review') >= 0) {
      const _index = _filterJoinedShop.branchStatus.indexOf('not_review');
      _cloneFilterReview = [..._filterJoinedShop.branchStatus];
      _cloneFilterReview.splice(_index, 1);
      _notReview = true;
    }
    if (_filterJoinedShop.spStatus && _filterJoinedShop.spStatus.indexOf('not_review') >= 0) {
      const _index = _filterJoinedShop.spStatus.indexOf('not_review');
      _cloneFilter.splice(_index, 1);
      _cloneFilter.push('waiting_for_review', 'request_re_up');
    }
    if (_filterJoinedShop.status && _filterJoinedShop.status.indexOf('not_join') < 0) {
      _notJoin = false;
    }
    if (_filterJoinedShop.status && _filterJoinedShop.status.indexOf('not_join') >= 0) {
      const _index = _filterJoinedShop.status.indexOf('not_join');
      _cloneStatusFilter = [..._filterJoinedShop.status];
      _cloneStatusFilter.splice(_index, 1);
      _notJoin = true;
    }
    const classification = this.filterParams[FilterKey.Classification] &&
    this.filterParams[FilterKey.Classification].length ?
    this.filterParams[FilterKey.Classification] : null;
    const saleManager = this.filterParams[FilterKey.SaleManager] &&
    this.filterParams[FilterKey.SaleManager].length ?
    this.filterParams[FilterKey.SaleManager] : null;
    const _dealerApproval = this.filterParams[FilterKey.DealerApproval] &&
    this.filterParams[FilterKey.DealerApproval].length ?
    this.filterParams[FilterKey.DealerApproval] : null;
    if (!this.isNotJoin) {
      _notJoin = false;
    }
    let payment = {};

    if (this.filterParams.paymentDocument && this.filterParams.paymentDocument.length === 1
      && this.filterParams.paymentDocument[0] === 'false') {
      payment = {
        _or: [
          {
            _not: {
              information2023: {
                id: {
                  _is_null: false
                }
              }
            }
          },
          {
            _and: [
              {
                information2023: {
                  requestedRewardReceiptURLs: {
                    _eq: []
                  }
                }
              },
              // {
              //   _or: [
              //     {
              //       information2023: {
              //         data: {
              //           _eq: {}
              //         }
              //       }
              //     },
              //     {
              //       data: {
              //         _eq: ''
              //       }
              //     }
              //   ]
              // },
              {
                information2023: {
                  _or: [
                    {
                      data: { _eq: {} }
                    },
                    {
                      data: {
                        _contained_in: {
                          link: ''
                        }
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      };
    }
    if (this.filterParams.paymentDocument && this.filterParams.paymentDocument.length === 1
      && this.filterParams.paymentDocument[0] === 'true') {
      payment = {
        _or: [
          {
            information2023: {
              requestedRewardReceiptURLs: {
                _neq: []
              }
            }
          },
          // {
          //   _and: [
          //     {
          //       information2023: {
          //         data: {
          //           _neq: {}
          //         }
          //       }
          //     },
          //     {
          //       _not: {
          //         data: {
          //           _contained_in: {
          //             link: ''
          //           }
          //         }
          //       }
          //     }
          //   ]
          // }
          {
            information2023: {
              _and: [
                {
                  data: {
                    _neq: {}
                  }
                },
                {
                  _not: {
                    data: {
                      _contained_in: {
                        link: ''
                      }
                    }
                  }
                }
              ]
            }
          }
        ]
      };
    }
    this.$store.dispatch(ActionTypeNewRaCampaign.GetJoinedShop, {
      classification,
      payment,
      saleManager,
      search: `%${text}%`,
      notJoin: _notJoin,
      status: _cloneStatusFilter ? _cloneStatusFilter : _filterJoinedShop.status,
      branch: _filterJoinedShop.branch,
      saleFilter: _cloneFilter,
      notReview: _notReview,
      branchFilter: _cloneFilterReview ? _cloneFilterReview : _filterJoinedShop.branchStatus,
      pagination: this.pagination,
      dealerApproval: _dealerApproval
    });
  }
}
