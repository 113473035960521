import { gqlClient } from '../Core';
import { CRUDGqlService, ICRUDGqlService } from '../Core/crud';
import { getListBranchManageQuery, BranchManage } from '../Query/branchManage';

export interface IBranchManageGqlService extends ICRUDGqlService<any> {
  //
  branchManageGetById(id: string): Promise<any>;
  getBranchManageList(form: any): Promise<any>;
  getManagers(form: any): Promise<any>;
  updateBranchManager(form: any): Promise<any>;
  updateStatus(id: string, set: any): Promise<any>;
  insertBranch(form: any): Promise<any>;
  insertBranchManager(form: any): Promise<any>;
  removeBranchManager(form: any): Promise<any>;
  insertInvoice(form: any): Promise<any>;
  updateBranch(id: string, set: any): Promise<any>;
}
export function BranchManageGqlService(): IBranchManageGqlService {
  async function branchManageGetById(id: string): Promise<any> {
    const results = await gqlClient.query({
      query: getListBranchManageQuery.GET_BY_ID,
      variables: {
        id
      },
      fetchPolicy: 'network-only'
    });
    if (results.errors) {
      throw results.errors;
    }

    return results.data.getBranchDetail;
  }
  async function getBranchManageList(form: any): Promise<any> {
    const results = await gqlClient.query({
      query: getListBranchManageQuery.GET_LIST_BRANCH_MANAGER,
      variables: {
        form
      },
      fetchPolicy: 'network-only'
    });
    if (results.errors) {
      throw results.errors;
    }

    return results.data.getBranchList;
  }
  async function getManagers(form: any): Promise<any> {
    const results = await gqlClient.query({
      query: getListBranchManageQuery.GET_MANAGERS,
      variables: {
        form
      },
      fetchPolicy: 'network-only'
    });
    if (results.errors) {
      throw results.errors;
    }

    return results.data.getBranchManagerList;
  }
  async function updateBranchManager(form: any): Promise<any> {
    const results = await gqlClient.mutate({
      mutation: getListBranchManageQuery.UPDATE_BRANCH_MANAGER,
      variables: {
        form
      },
    });
    if (results.errors) {
      throw results.errors;
    }

    return results;
  }
  async function updateStatus(id: string, set: any): Promise<any> {
    const results = await gqlClient.mutate({
      mutation: getListBranchManageQuery.UPDATE_STATUS,
      variables: {
        id,
        set
      },
    });
    if (results.errors) {
      throw results.errors;
    }

    return results;
  }
  async function updateBranch(id: string, set: any): Promise<any> {
    const results = await gqlClient.mutate({
      mutation: getListBranchManageQuery.UPDATE_BRANCH,
      variables: {
        id,
        set
      },
    });
    if (results.errors) {
      throw results.errors;
    }

    return results;
  }
  async function insertBranch(form: any): Promise<any> {
    const results = await gqlClient.mutate({
      mutation: getListBranchManageQuery.INSERT_BRANCH,
      variables: {
        form
      },
    });
    if (results.errors) {
      throw results.errors;
    }

    return results;
  }
  async function insertBranchManager(form: any): Promise<any> {
    const results = await gqlClient.mutate({
      mutation: getListBranchManageQuery.INSERT_BRANCH_MANAGER,
      variables: {
        form
      },
    });
    if (results.errors) {
      throw results.errors;
    }

    return results;
  }
  async function removeBranchManager(form: any): Promise<any> {
    const results = await gqlClient.mutate({
      mutation: getListBranchManageQuery.DELETE_BRANCH_MANAGER,
      variables: {
        branchId: form.branchId
      },
    });
    if (results.errors) {
      throw results.errors;
    }

    return results;
  }
  async function insertInvoice(form: any): Promise<any> {
    const results = await gqlClient.mutate({
      mutation: getListBranchManageQuery.INSERT_INVOICE,
      variables: {
        form
      },
    });
    if (results.errors) {
      throw results.errors;
    }

    return results;
  }

  return {
    ...CRUDGqlService<any>(BranchManage, getListBranchManageQuery),
    branchManageGetById,
    getBranchManageList,
    getManagers,
    updateBranchManager,
    updateStatus,
    insertBranch,
    insertBranchManager,
    insertInvoice,
    updateBranch,
    removeBranchManager
  };
}
