export enum ActionTypeProductTrans {
  GET_LIST_PRODUCT_TRANS = 'getListProductTrans',
  FilterNoCache = 'productTransFilterNoCache',
  UpdateStatusProductTrans = 'updateStatusProductTrans',
  GetByProductId = 'productTransGetByProductId',
  GetGroupView = 'productTransGetGroupView',
  GetViewById = 'productTransGetViewById'
}
export enum MutationTypeProductTrans {
  FilterChange = 'productTransFilterChange',
  PaginationChange = 'productTransPaginationChange'
}
