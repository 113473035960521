export enum ActionTypeInvoice {
  GET_LIST_INVOICE = 'getListInvoice',
}
export enum MutationTypeInvoice {
  FilterChange = 'invoiceFilterChange',
  PaginationChange = 'invoicePaginationChange',
  ResetState = 'invoiceResetState',
  GetListInvoice = 'invoiceGetList',
  InvoiceLoading = 'invoiceLoading',
  InvoiceLoaded = 'invoiceLoaded',
}
