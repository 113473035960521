// import { DKGqlClient } from 'root/api/graphql/Client';
import { Form } from 'element-ui';
import { cloneDeep } from 'lodash';
import { IPaginationParams } from 'root/api/graphql/Core';
import { SearchByFilter } from 'root/components/SearchByFilter';
import { ruleRequired } from 'root/helpers';
import { ItemPage, IUser } from 'root/models';
import { IState } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import { ActionTypeSaleInCampaign } from '../../../Store/types';
import './styles.scss';

@Component({
  template: require('./view.html'),
  props: {
    campaignId: String,
    popupVisible: Boolean,
    selected: Array,
  },
  computed: {
    ...mapState({
      authUser: (state: IState) => state.global.authUser,
    }),
  },
  watch: {
    selected(newValue) {
      if (newValue.length > 0) {
        this.clearAll = true;
      }
    },
  }
})

export class SaleInCampaignSendConfirm extends Vue {
  public $refs: {
    searchByFilter: SearchByFilter;
    form: Form;
  };
  public rules = {
    required: ruleRequired(),
  };
  public authUser: IUser;
  public list: any = [];
  public dataForm: any[] =[];
  public clearAll: boolean= false;
  public dataAll: any[] =[];
  public searchText: string = '';
  public showFormDevice: boolean = false;
  public popupVisibleSubmit: boolean = false;
  public pagination: IPaginationParams = {
    limit: 20,
    page: 1,
    total: null
  };
  public form: any = {};
  public loading: boolean = false;
  public ItemPage = ItemPage;
  public back() {
    this.$emit('update:popupVisible', false);
  }
  public handleSearch() {
    this.pagination = {
      ...this.pagination,
      page: 1
    };
    this.fetchData();
  }
  public async apply() {
    this.popupVisibleSubmit = true;
    // const {trainee} = DKGqlClient();
    // if (this.$props.action === 'add') {
    //   const form = { action: 'add', traineeIds: this.list.map((it) => it.id) };
    //   try {
    //     // const dataReasult = await trainee.changeTraineeAllowWithdraw(form);
    //     this.$message({ type: 'success', message: dataReasult.toString()});
    //     this.$emit('provinceFilterChange');
    //     this.closeDialog();
    //   } catch (error) {
    //     this.$message({ type: 'error', message: error.toString() });
    //   }
    // }

    // return null;
  }
  public get checkSave() {
    return this.list.length > 0 ;
  }
  public fetchData() {
    this.loading = true;
    const text = cloneDeep(this.searchText);
    this.$store.dispatch(ActionTypeSaleInCampaign.GetCampaignDetailParticipantList, {
      confirmedAt: true,
      id: this.$route.params.id,
      search: text && text.length ? `%${text}%` : null,
      type:  null,
      branch: null,
      time: null,
      pagination: this.pagination,
      onSuccess: (result) => {
        this.dataForm = result.si_campaign_participants;
        this.pagination = {
          ...this.pagination,
          total: result.si_campaign_participants_aggregate.aggregate.count
        };
        this.loading = false;
      },
      getAll: (dataAll) => {
        this.dataAll = dataAll.data.si_campaign_participants;
      }
    });
  }
  public async openDialog() {
    // this.$nextTick(() => {
    //   console.log('open', this.$props.campaignId);
    // });
    this.fetchData();
  }
  public closeDialog() {
    this.list = [];
    this.searchText = '';
    this.pagination.page = 1;
    this.pagination.limit = 20;
    this.$emit('dataSearch', '');
    this.back();
  }
  public onEnterFilter() {
    this.$emit('dataSearch', this.$refs.searchByFilter.searchText.trim() !== undefined
    ? this.$refs.searchByFilter.searchText.trim() : '');
    this.pagination.limit = 20;
    this.pagination.page = 1;
  }
  public indetermainate() {
    this.clearAll = false;
  }
  public checkSelectedItem() {
    return this.dataForm && this.selectedLength === this.dataAll.length;
  }
  public selectItem(item) {
    if (this.list.find((val) => item.id === val.id)) {
      this.list = this.list.filter((e) => e.id !== item.id);
    } else {
      this.list.push(item);
    }
  }
  public selectAll() {
    // tslint:disable-next-line:prefer-conditional-expression
    if (this.selectedLength < this.dataForm.length) {
      const listAll = this.dataAll.filter((val) => !this.list.map((it) => it.id).includes(val.id));
      this.list = [...this.list, ...listAll];
    } else {
      this.list = this.list.filter((val) => !this.dataAll.map((item) => item.id).includes(val.id));
    }
  }
  public isSelected(item) {
    if (this.list.find((val) => item.id === val.id)) {
      return true;
    }

    return false;
  }
  public get selectedLength() {
    return this.list.filter((val) => this.dataAll.map((t) => t.id).includes(val.id)).length;
  }
  public currentChange(newValue) {
    this.pagination = {
      ...this.pagination,
      page: newValue
    };
    this.fetchData();
  }
  public handlePagination(val) {
    this.pagination = {
      ...this.pagination,
      limit: val
    };
    this.fetchData();
  }
  public openDialogSubmit() {
    // 
  }
  public closeDialogSubmit() {
    this.popupVisibleSubmit = false;
  }
  public cancel() {
    this.closeDialogSubmit();
  }
  public async confirm() {
    this.$refs.form.validate(async (valid) => {
      if (valid) {
        this.loading = true;
        const form = {
          ...this.form,
          noReminderDays: parseInt(this.form.noReminderDays, 10),
          dealerIds: this.list.map((it) => it.dealer.id),
          updatedBy: this.authUser.id,
          campaignId: this.$props.campaignId
        };
        this.$store.dispatch(ActionTypeSaleInCampaign.SendConfirm, {
          form,
          onSuccess: () => {
            this.$message.success('Send confirm successfully!');
            this.form = {};
            this.$refs.form.clearValidate();
            this.list = [];
            this.loading = false;
            this.closeDialogSubmit();
            this.closeDialog();
          }
        });

        return;
      }

      return;
    });
  }
}
