import { DocumentNode } from 'graphql';
import gql from 'graphql-tag';
import { crudQuery, ICRUDQuery } from '../Core/query';

const regionsModel = `
id
name
status
regionCode
description
branches_aggregate {
  aggregate {
   count(columns: id)
  }
}
branches {
  groups_aggregate {
    aggregate {
      count(columns: id)
    }
  }
}
createdAt
createdBy
updatedAt
updatedBy
createdUser {
  id
  name
}
updatedUser {
  id
  name
}
`;

export const regions = 'regions';
// tslint:disable-next-line:no-empty-interface
interface IRegionsQuery extends ICRUDQuery {
  INSERT_REGION: DocumentNode;
  UPDATE_REGION: DocumentNode;
  REGION_GET_BY_ID: DocumentNode;
  REGION_GET_LIST_BRANCH: DocumentNode;
}
export const regionsListQuery: IRegionsQuery = {
  ...crudQuery(regions, regionsModel),
  INSERT_REGION: gql`
  mutation InsertRegion($form: [regions_insert_input!]!) {
    insert_regions(objects: $form) {
      affected_rows
    }
  }
  `,
  UPDATE_REGION: gql`
  mutation UPDATE_REGION($id: uuid!, $form: regions_set_input!){
    update_regions(where: {id: {_eq: $id}}, _set: $form){
      affected_rows
    }
  }
  `,
  REGION_GET_BY_ID: gql`
  query REGION_GET_BY_ID($id: uuid!){
    regions(where: {id: {_eq: $id}}) {
      name
      id
      regionCode
      description
      createdUser {
        id
        name
      }
      updatedUser {
        id
        name
      }
      createdAt
      updatedAt
      branches_aggregate {
        aggregate {
         count(columns: id)
        }
      }
      branches {
        groups_aggregate {
          aggregate {
            count(columns: id)
          }
        }
      }
    }
  }`,
  REGION_GET_LIST_BRANCH: gql`
  query REGION_GET_BY_ID($id: uuid!){
    regions(where: {id: {_eq: $id}}) {
      branches {
        id
      }
    }
  }`
};
