import { find } from 'lodash';
import { compareAddressId } from 'root/helpers';
import { IState } from '.';

export const getters = {
  getAddressString(state: IState) {
    return ({ ward, address, district, province }, isPrefix = true) => {
      const localVN = state.global.localVN,
        _province = province ? find(localVN, (e) => province && compareAddressId(e.matp, province)) : null,
        _district = _province && district
          ? find(_province.child, (e) => district && compareAddressId(e.maqh, district)) : null,
        _ward = _district && ward
          ? find(_district.child, (e) => ward && compareAddressId(e.xaid, ward)) : null;

      // tslint:disable-next-line:max-line-length
      return `${address ? `${address}` : ''}${_ward ? `, ${isPrefix ? _ward.name : _ward.name.replace(/Xã /g, '')}` : ''}${_district ? `, ${isPrefix ? _district.name : _district.name.replace(/Huyện /g, '')}` : ''}${_province ? `, ${isPrefix ? _province.name : _province.name.replace(/Thành phố |Tỉnh /g, '')}` : ''}`;
    };
  },
};
