import { Avatar } from 'root/components/Avatar';
import { FormatDateFullTime } from 'root/helpers';
import Vue from 'vue';
import Component from 'vue-class-component';
import { ActionTypeCampaignRequestList } from '../../Store/types';
import './styles.scss';

@Component({
  template: require('./view.html'),
  props: {
    popupVisible: Boolean,
    requestId: String
  },
  components: {
    avatar: Avatar,
  },
})
export class HistoryLog extends Vue {
  public get dataFilter() {
    return [
      {
        key: 'all',
        value: 'all',
        name: 'ALL',
      },
      {
        key: 'sale_department_pic',
        value: 'sale_department_pic',
        name: 'SALE PIC',
      },
      {
        key: 'group_leader',
        value: 'group_leader',
        name: 'SALE MANAGER',
      },
      {
        key: 'sale_admin',
        value: 'sale_admin',
        name: 'SALE PLANNING',
      }
    ];
  }
  public requestId: string;
  public selected: any = [];
  public FormatDateFullTime = FormatDateFullTime;
  public data: any[] = [];
  public closeDialog() {
    this.back();
  }
  public back() {
    this.$emit('update:popupVisible', false);
  }
  public openDialog() {
    this.fetchData();
  }
  public fetchData() {
    if (this.selected.indexOf('all') >= 0) {
      this.selected = ['all', 'sale_department_pic', 'group_leader', 'sale_admin'];
    }
    this.$store.dispatch(ActionTypeCampaignRequestList.GetHistoryLog, {
      roles: this.selected && this.selected.length ? this.selected : null,
      requestId: this.requestId,
      onSuccess: (data) => {
        this.data = data;
      }
    });
  }
  public formatterCheck(name: any) {
    if (this.selected.indexOf(name) >= 0) {

      return 'btn-filter-checked';
    }

    return 'btn-filter-uncheck';
  }
  public clickFilter(name) {
    if (this.selected.indexOf(name) < 0) {
      this.selected.push(name);
      this.fetchData();

      return;
    }
    const _index = this.selected.indexOf(name);
    this.selected.splice(_index, 1);
    this.fetchData();
  }
}
