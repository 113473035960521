import { Subscription } from 'apollo-client/util/Observable';
import { isString } from 'lodash';
import moment from 'moment';
import { ZeroPercentCampaignRouterName } from 'root/admin/CustomCampaign/ZeroPercentCampaign';
import { ActionTypeNotification } from 'root/admin/NewsAnnoucement/store/type';
import { gqlClient } from 'root/api/graphql/Core';
import { NotificationQuery } from 'root/api/graphql/Query/notification';
import { FormatDateFullTime } from 'root/helpers';
import { ELocalStorage, IUser } from 'root/models';
import { ActionType, IState, MutationType } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import { Avatar } from '../Avatar';
import './styles.scss';

@Component({
  template: require('./view.html'),
  components: {
    avatar: Avatar
  },
  props: {
    handleCollapse: {
      type: Function
    },
    isCollapse: {
      type: Boolean
    }
  },
  computed: {
    ...mapState({
      authUser: (state: IState) => state.global.authUser,
      breadcrumb: (state: IState) => state.global.breakCrumb,
      back: (state: IState) => state.global.back,
      showCollapse: (state: IState) => state.global.showCollapse,
      typeCollapse: (state: IState) => state.global.typeCollapse
    })
  }
})
export class Header extends Vue {
  public authUser: IUser;
  public showUserMenu: boolean = false;
  public typeCollapse: boolean;
  public breadcrumb: any;
  public viewAll: boolean = false;
  public unread: number = 0;
  public notification: any[] = [];
  public visibleDetail: boolean = false;
  public FormatDateFullTime = FormatDateFullTime;
  public detail: any = {};
  private subscribeNotification: Subscription = null;
  public get getProfileUrl(): string {
    return `/users/profile`;
  }
  public clickAll() {
    if (!this.unread || this.unread === 0) {
      return;
    }
    this.onClickNotification('all');
  }
  public onClickNotification(id) {
    if (id === 'view') {
      this.viewAll = !this.viewAll;

      return;
    }
    if (id === 'all') {
      this.$msgbox({
        title: this.$t('delete_confirm').toString(),
        message: this.$t('readAll_confirm_message').toString(),
        showCancelButton: true,
        confirmButtonText: this.$t('confirm').toString(),
        cancelButtonText: this.$t('cancel').toString(),
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true;
            instance.confirmButtonText = 'Loading...';
            const unReadId = this.notification.filter((it) => !it.readAt).map((it) => it.id);
            this.readAllNotification(unReadId, instance, done);
          } else {
            done();
          }

          return;
        }
      }).then(() => {
        this.$message({
          type: 'success',
          message: this.$t('message.updated_successfully').toString()
        });
      }).catch(() => {
        // no handle
      });

      return;
    }
    this.readNotification([id]);
    const noti = this.notification.find((it) => it.id === id).notification;
    // const noti = this.notification.find((it) => it.id === ids).notification;
    if (noti && noti.referenceId) {
      this.$router.replace({
        name: ZeroPercentCampaignRouterName.Participant,
        query: {
          detail:  noti.referenceId
        }
      });
    }
    this.visibleDetail = true;
  }
  public readAllNotification(ids, instance, done) {
    this.readNotification(ids);
    instance.confirmButtonLoading = false;
    instance.confirmButtonText = this.$t('confirm').toString();
    done();
  }
  public readNotification(ids) {
    if (ids && ids.length) {
      this.$store.dispatch(ActionTypeNotification.ReadNotifications, {
        ids,
        readAt: moment()
      });

      return;
    }

    return;
  }
  public fetchNotification() {
    this.subscribeNotification = <any> gqlClient.subscribe({
      query: NotificationQuery.GET_NOTIFICATION,
      variables: { id: this.authUser.id },
      fetchPolicy: 'cache-first'
    }).subscribe((result) => {
      this.notification = result.data.user_notification_reads;
      this.unread = result.data.user_notification_reads.filter((noti) => !noti.readAt).length;
    });
  }
  public clickEditProfile(e) {
    e.preventDefault();

    this.showUserMenu = false;
    this.$router.push(this.getProfileUrl);
  }

  public isBreadcrumb(model) {
    if (!isString(model)) {

      return true;
    }

    return false;
  }

  public backButton() {
    this.$router.back();
  }
  public onCollapse() {
    this.$store.commit(MutationType.SetTypeCollapse, !this.typeCollapse);
  }
  public clickSettings(e) {
    e.preventDefault();
    this.showUserMenu = false;
  }
  public mounted() {
    this.fetchNotification();
  }
  public logout() {
    const router = this.$router;
    const _currentVersion = localStorage.version;
    localStorage.clear();
    localStorage.setItem(ELocalStorage.Version, _currentVersion);
    this.$store.dispatch(ActionType.Unauthenticated, router);
  }
}
