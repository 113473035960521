import { DocumentNode } from 'graphql';
import gql from 'graphql-tag';
import { crudQuery, ICRUDQuery } from '../Core/query';

const branchModel = `
id
name
`;

export const Branch = 'branches';
// tslint:disable-next-line:no-empty-interface
interface IBranchQuery extends ICRUDQuery {
  GET_LIST_BRANCH: DocumentNode;
}
export const getListBranchQuery: IBranchQuery = {
  ...crudQuery(Branch, branchModel),
  GET_LIST_BRANCH: gql`
  query GET_LIST_BRANCH {
    branches {
      ${branchModel}
    }
  }
`,
};
