import { DocumentNode } from 'graphql';
import gql from 'graphql-tag';
import { crudQuery, ICRUDQuery } from '../Core/query';

const accountStaffModel = `
  isOwner
  dealer {
    address
    avatar
    email
    phoneNumber
    name
    id
    status
  }
`;
const accountStaffDetail = `
  address
  createdAt
  avatar
  email
  name
  phoneNumber
  status
  updatedAt
  createdUser {
    id
    name
  }
  updatedUser {
    id
    name
  }
`;

export const accountStaff = 'accountStaff';
// tslint:disable-next-line:no-empty-interface
interface IAccountStaffQuery extends ICRUDQuery {
  GET_ACCOUNT_STAFF_LIST: DocumentNode;
  GET_SHOP_ID: DocumentNode;
  CREATE_STAFF: DocumentNode;
  GET_STAFF_DETAIL: DocumentNode;
}
export const accountStaffListQuery: IAccountStaffQuery = {
  ...crudQuery(accountStaff, accountStaffModel),
  GET_ACCOUNT_STAFF_LIST: gql`
  query listStaffOfOwner($ids: [uuid!], $limit:Int, $offset: Int, $search: String, $status: [String!]) {
    dealer_shops(where: {shopId: {_in: $ids}, _or: [{contractStatus: {_eq: "accepted"}}],
      _and: {_or: [{dealer: {name: {_ilike: $search}}},
      {dealer: {email: {_ilike: $search}}},{dealer: {phoneNumber: {_ilike: $search}}}]},
      dealer: {status: {_in: $status}}}, limit: $limit, offset: $offset, distinct_on: dealerId) {
      ${accountStaffModel}
    }
    dealer_shops_aggregate(where: {shopId: {_in: $ids}, _or: [{contractStatus: {_eq: "accepted"}}],
      _and: {_or: [{dealer: {name: {_ilike: $search}}},
       {dealer: {email: {_ilike: $search}}},{dealer: {phoneNumber: {_ilike: $search}}}]},
        dealer: {status: {_in: $status}}}, distinct_on: dealerId) {
      aggregate {
        count
      }
    }
  }
  `,
  GET_SHOP_ID: gql`
    query getShopIdsOfOwner($id: uuid!) {
      dealer_shops(where: {dealerId: {_eq: $id},
         isOwner: {_eq: true}}, order_by: {isOwner: desc}) {
        shopId
      }
    }
  `,
  CREATE_STAFF: gql`
  mutation CreateDealerStaff($form: FormCreateDealerStaff!) {
    createDealerStaff(form: $form) {
      id
    }
  }
  `,
  GET_STAFF_DETAIL: gql`
  query GET_STAFF_DETAIL($id: uuid!){
    dealers(where: {id : {_eq: $id}}){
      ${accountStaffDetail}
    }
  }
  `,
};
