// import { stripObject } from '@hgiasac/helper';
import { stripObject } from '@hgiasac/helper';
import { Form } from 'element-ui';
import { ElUpload } from 'element-ui/types/upload';
import _, { omit } from 'lodash';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import { ActionTypeBranchManage } from 'root/admin/BranchAndGroup/BranchManage/Store/types';
// import { ActionTypeBranchManage } from 'root/admin/BranchAndGroup/BranchManage/Store/types';
// import { ActionTypeGroup } from 'root/admin/Group/Store/types';
import { DKGqlClient } from 'root/api/graphql/Client';
import { Avatar } from 'root/components';
import { DKEditor } from 'root/components/DKCustomEditor';
import { DKFilter } from 'root/components/DKFilter';
import { DKMultiCheckboxList } from 'root/components/DKMultiCheckboxList';
import { DKMultiDealerCheckboxList } from 'root/components/DKMultiDealerCheckbox';
import { DKSelectedViewOnlyList } from 'root/components/DKSelectedViewOnlyList';
import { Upload } from 'root/components/Upload';
import { resetOrientation, ruleMaxLength, rulePositiveNumber, ruleRequired } from 'root/helpers';
// import { StatusCode } from 'root/models';
import { campaignFormDefault, ICampaignForm } from 'root/models/Redemption';
import { IUser } from 'root/models/User';
import { pathUpload, sizeImage, uploadToFirebase } from 'root/services';
import { FirebaseAuthService } from 'root/services/auth/firebase';
import { IState, MutationType, TypeAlert } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapGetters, mapState } from 'vuex';
import { MutationTypeDealer } from '../../../Dealer/Store/types';
import { ActionTypeCampaign } from '../../Campaign/Store/types';
// import { ConditionActionType } from '../../Condition/Store/types';
// import { ActionTypeDealer } from '../../Store/types';
import './styles.scss';

const targetType = [
  {
    value: 'all',
    label: 'All'
  },
  {
    value: 'specific',
    label: 'Specific'
  }
];
@Component({
  template: require('./view.html'),
  components: {
    avatar: Avatar,
    'dk-editor': DKEditor,
    upload: Upload,
    'dk-filter': DKFilter,
    'dk-multi-checkbox-list': DKMultiCheckboxList,
    'dk-selected-view-only-list': DKSelectedViewOnlyList,
    'dk-dealer-select': DKMultiDealerCheckboxList,
  },
  props: {
    visible: Boolean,
    userId: String,
    fromCallCenter: Boolean
  },
  computed: {
    ...mapGetters(['branch', 'group']),
    ...mapState({
      authUser: (state: IState) => state.global.authUser,
      userDetailLoading: () => false,
      branch: (state: IState) => state.branchManage.data,
      group: (state: IState) => state.group.data,
      condition: (state: IState) => state.condition.data,
      dealer: (state: IState) => state.dealer.data
    }),
    cloneBranchOptions() {
      const branch: any[] = cloneDeep(this.branch);

      return branch.map((e) => {
        return {
          label: e.name,
          value: e.id
        };
      });
    },
    cloneGroupOptions() {
      const group: any[] = cloneDeep(this.group);

      return group.map((e) => {
        return {
          label: e.name,
          value: e.id
        };
      });
    },
    cloneConditionOptions() {
      const group: any[] = cloneDeep(this.condition);

      return group.map((e) => {
        return {
          label: e.nameEng,
          value: e.id
        };
      });
    },
    dataBranch() {
      const branch: any[] = cloneDeep(this.branch);

      return branch.map((e) => {
        return {
          label: e.name,
          value: e.id
        };
      });
    },
    dataDealer() {
      const dealer: any[] = cloneDeep(this.dealer);
      if (this.focusBranch) {
        return dealer.filter((it) => it.branchId === this.focusBranch).map((e) => {
          return {
            label: e.name,
            value: e.id,
            id: e.id,
            role: e.role,
            branch: e.branchId
          };
        });
      }

      return [];
    }
  },
})

export class CompaignEditor extends Vue {
  public get RoleOptions() {
    return [
      {
        key: 'daikin_dealer',
        value: 'daikin_dealer',
        label: 'Dealer'
      },
      {
        key: 'sub_dealer',
        value: 'sub_dealer',
        label: 'Sub Dealer'
      }
    ];
  }

  public get isUpdating(): boolean {
    return !!this.userId;
  }
  public get rules() {
    return {
      required: ruleRequired(),
      max: ruleMaxLength(50),
      positive: rulePositiveNumber()
    };
  }

  public $refs: {
    form: Form,
    upload: ElUpload;
  };
  public loading: boolean = false;
  public authUser: IUser;
  public userDetail: IUser;
  public form: ICampaignForm = campaignFormDefault();
  public detail: IUser;
  public route: any;
  public metadata: string = 'pdf';
  public userId: string;
  public isRefesh: boolean;
  public targetType = targetType;
  public citi: any[];
  public fileUpload = null;
  public fileUploadIcon = null;
  public fileList = [];
  public cloneBranchOptions: any[];
  public checkedBranch = [];
  public isDisable: boolean = true;
  public visible: boolean;
  public stationPartnerData: any;
  public campaignId: null;
  public dependentItems = [];
  public selectedItems = [];
  public valueBranch = [];
  public branchSelect = [];
  public addressText = '';
  public valueDealer = [];
  public isSelectBranch: boolean = false;
  public invalidDealer: boolean = false;
  public isCheckDealer: boolean = false;
  public focusBranch: string = '';
  public cloneBranch: any[] =[];
  public isCheckAllBranch: Array<{
    branch: string,
    isAllDealer: boolean,
    dealer: string[],
  }> = [];
  public dealer: any[];
  public branch: any[];
  public isError: boolean = false;
  public unAllDealerBranch: string[] = [];

  public endDatePickerOption = {
    disabledDate: (time: any) => {
      const startDate = this.form.startDate;
      if (startDate) {
        return time.getTime() < moment(startDate) ;
      }

      return false;

    }
  };

  private pathUploadAvatar: pathUpload = pathUpload.SYSTEM_USER;
  private querySearchPartner: string = '';

  public changeForm() {
    //
  }
  public removeUpload() {
    this.form.image = '';
    this.fileUpload = null;
  }
  public removeUploadIcon() {
    this.form.icon = '';
    this.fileUploadIcon = null;
  }
  public get isUpdate() {
    if (this.userId) {
      return true;
    }

    return false;
  }
  public remoteMethodService(query: string) {
    this.addressText = query;
  }
  public onRemove(file: any, fileList: any) {
    console.log(file);
    this.fileList = fileList;
  }
  public async handleUpload(file: any) {
    // tslint:disable-next-line: whitespace
    const _file = <any>await resetOrientation(file.raw, -1);
    this.form.image = window.URL.createObjectURL(_file);
    this.fileUpload = _file;
  }
  public async handleUploadIcon(file: any) {
    // tslint:disable-next-line: whitespace
    const _file = <any>await resetOrientation(file.raw, -1);
    this.form.icon = window.URL.createObjectURL(_file);
    this.fileUploadIcon = _file;
  }
  public get validDealer() {
    if (this.selectedItems.length) {

      return this.invalidDealer = false;
    }

    return this.invalidDealer = true;
  }
  public changeType(value: string) {
    if (value === 'specific') {
      return this.isSelectBranch = true;
    }

    return this.isSelectBranch = false;
  }
  public pushFileUpload(file: any) {
    const f = this.fileList;
    if (this.fileList.length > 0) {
      const _file = this.fileList.filter((it) => it.name === file.name);
      if (_file && _file.length > 0) {
        this.$refs.upload.clearFiles();
        const message = 'File has been exits';
        this.$store.commit(MutationType.OpenTopAlert, {
          message,
          type: TypeAlert.Warning
        });

        return this.$refs.upload.$data.uploadFiles = f;
      }

      return this.fileList = [file];
    }

    f.push(file);

    return this.fileList = [file];
  }
  public async submit() {
    this.$refs.form.validate(async (valid) => {
      // if (this.isCheckAllBranch.length === 0) {
      //   this.invalidDealer = true;
      // }
      if (valid) {
        const form = <any> cloneDeep(this.form);
        this.loading = true;
        let { image, icon } = this.form;
        const token = await DKGqlClient().user.getFirebaseAuthenticateToken();
        await FirebaseAuthService().signInWithCustomToken(token.authenticate);
        if (image && this.fileUpload) {
          // const token = await DKGqlClient().user.getFirebaseAuthenticateToken();
          // await FirebaseAuthService().signInWithCustomToken(token.authenticate);
          image = await uploadToFirebase(
            this.fileUpload,
            pathUpload.CAMPAIGN,
            {
              ...sizeImage.message.original,
              quality: 1
            }
          );
          form.image = image;
        }
        if (icon && this.fileUploadIcon) {
          // const token = await DKGqlClient().user.getFirebaseAuthenticateToken();
          // await FirebaseAuthService().signInWithCustomToken(token.authenticate);
          icon = await uploadToFirebase(
            this.fileUploadIcon,
            pathUpload.CAMPAIGN,
            {
              ...sizeImage.message.original,
              quality: 1
            }
          );
          form.icon = icon;
        }
        this.handleSystemUser(form);
      }

      return;
    });
  }
  /**
   * Handele select dealer table
   */
  public onParentListChange(value) {
    this.dependentItems = value;
    this.valueBranch = value;
    if (value.length) {
      const branchDealer = this.dealer && this.dealer.length &&
      this.dealer.filter((it) => it.branchId === value[value.length - 1]).map((item) => item.id);
      const indexBranch = this.isCheckAllBranch.length &&
      this.isCheckAllBranch.filter((it) => it.branch === value[value.length - 1]);
      if (!indexBranch.length) {
        this.isCheckAllBranch.push({
          branch: value[value.length - 1],
          isAllDealer: true,
          dealer: branchDealer || []
        });
      } else {
        this.isCheckAllBranch[this.isCheckAllBranch.indexOf(indexBranch[0])].dealer = branchDealer;
        this.isCheckAllBranch[this.isCheckAllBranch.indexOf(indexBranch[0])].isAllDealer = true;
      }
      this.valueDealer = branchDealer;
    }
    this.isCheckAllBranch.map((itemCheck, index) => {
      if (value.indexOf(itemCheck.branch) !== -1) {
        return itemCheck;
      }

      return this.isCheckAllBranch.splice(index, 1);
    });
    this.onChangeCheckDealer(true);
    const newUnAllDealerBranch = this.unAllDealerBranch.map((it, index) => {
      if (value.indexOf(it) !== -1) {
        return it;
      }

      this.unAllDealerBranch.splice(index, 0);
    });
    this.unAllDealerBranch = newUnAllDealerBranch;
  }
  public onChangeFocusBranch(val) {
    this.focusBranch = val;
    if (this.isUpdate) {
      const newValueDealer = this.isCheckAllBranch.filter((it) => it.branch === val);
      if (newValueDealer.length) {
        this.valueDealer = newValueDealer[0].dealer;
      }
    }
    const branchDealer = this.dealer && this.dealer.length &&
      this.dealer.filter((it) => it.branchId === val).map((item) => item.id);
    const indexBranch = this.isCheckAllBranch.length &&
      this.isCheckAllBranch.filter((it) => it.branch === val);
    // tslint:disable-next-line:early-exit
    if (!indexBranch.length) {
      this.isCheckAllBranch.push({
        branch: val,
        isAllDealer: true,
        dealer: branchDealer || []
      });
    } else {
      this.valueDealer = indexBranch[0].dealer;
    }
  }
  public onChangeCheckDealer(val) {
    this.isCheckDealer = val;
  }
  public handleGetSelectedItems(branch, type, value) {
    this.selectedItems = value;
    const branchDealerAll = this.dealer && this.dealer.length &&
      this.dealer.filter((it) => it.branchId === branch).map((item) => item.id);
    const branchDealer = this.dealer && this.dealer.length &&
      this.dealer.filter((it) => it.branchId === branch && it.role.name !== type).map((item) => item.id);
    const _dealer = _.concat(branchDealer, value).filter((it, index) =>
    _.concat(branchDealer, value).indexOf(it) === index);
    const indexBranch = this.isCheckAllBranch.length &&
      this.isCheckAllBranch.filter((it) => it.branch === branch);
    // tslint:disable-next-line:early-exit
    if (indexBranch.length) {
      this.isCheckAllBranch[this.isCheckAllBranch.indexOf(indexBranch[0])].dealer = _dealer;
      this.isCheckAllBranch[this.isCheckAllBranch.indexOf(indexBranch[0])].isAllDealer =
      branchDealerAll.length === value.length;
    }
    const indexOfBranch = this.unAllDealerBranch.indexOf(branch);
    // tslint:disable-next-line:early-exit
    if (branchDealerAll.length === value.length) {
      if (indexOfBranch !== -1) {
        this.unAllDealerBranch.splice(indexOfBranch, 1);
      }
    } else if (indexOfBranch === -1) {
      this.unAllDealerBranch.push(branch);
    }
  }
  public removeSelectAll(branch, type, val) {
    this.selectedItems = [];
    this.valueDealer = [];
    const branchDealer = this.dealer && this.dealer.length &&
      this.dealer.filter((it) => it.branchId === branch && it.role.name !== type).map((item) => item.id);
    const indexBranch = this.isCheckAllBranch.length &&
      this.isCheckAllBranch.filter((it) => it.branch === branch);
    const indexOfUnAll = this.unAllDealerBranch.indexOf(branch);
    if (indexBranch.length) {
      this.isCheckAllBranch[this.isCheckAllBranch.indexOf(indexBranch[0])].dealer = branchDealer || [];
      this.isCheckAllBranch[this.isCheckAllBranch.indexOf(indexBranch[0])].isAllDealer = val;
    }
    if (!branchDealer.length) {
      this.valueBranch.splice(this.valueBranch.indexOf(branch), 1);
    }
    if (indexOfUnAll || indexOfUnAll === 0) {
      this.unAllDealerBranch.splice(indexOfUnAll, 1);
    }
  }
  public handleCheckAllBranch(branch, val) {
    const indexBranch = this.isCheckAllBranch.filter((it) => it.branch === branch);
    const dealer = this.dealer.length && this.dealer.filter((it) => it.branchId === branch).map((ex) => ex.id);
    const indexValueBranch = this.valueBranch.indexOf(branch);
    const indexOfUnAll = this.unAllDealerBranch.indexOf(branch);
    if (indexValueBranch === -1) {
      this.valueBranch.push(branch);
    }
    if (indexOfUnAll || indexOfUnAll === 0) {
      this.unAllDealerBranch.splice(indexOfUnAll, 1);
    }
    if (indexBranch.length) {
      this.isCheckAllBranch[this.isCheckAllBranch.indexOf(indexBranch[0])].isAllDealer = val;
      this.isCheckAllBranch[this.isCheckAllBranch.indexOf(indexBranch[0])].dealer = dealer;
    }
  }

  public checkAllDealer() {
    const listCheck = this.valueBranch = this.branch.length && this.branch.map((it) => it.id);
    this.unAllDealerBranch = [];
    const newCheckDealer = [];
    listCheck.map((item) => {
      const dealer = this.dealer.length && this.dealer.filter((ex) => ex.branchId === item).map((dea) => dea.id);
      newCheckDealer.push({
        dealer,
        branch: item,
        isAllDealer: true,
      });
    });
    this.isCheckAllBranch = newCheckDealer;
    this.focusBranch = listCheck[listCheck.length - 1];
  }
  /**
   * end function handle selected dealer
   */

  public change() {
    this.isDisable = false;
  }
  public changeBranch(val: any) {
    this.branchSelect = val;
    this.isError = false;
  }
  public changePartner(val: any) {
    this.form.dealerId = val;
  }
  public back() {
    this.$refs.form.resetFields();
    this.$emit('update:visible', false);
    this.$emit('fetchDone');
  }
  public openDialog() {
    this.$store.dispatch(ActionTypeBranchManage.GetListBranch, {
      form: {
        filter: {},
        pagination: {}
      }
    });
    if (this.userId) {
      const formData = this.$store.getters.getCampaignDetail(this.userId);
      this.form = formData;
      if (formData.targetBranch === 'specific') {
        this.isSelectBranch = true;
      }
      this.cloneBranch = [...formData.targetBranches.map((it) => it.branch.id)];
      this.branchSelect = [...formData.targetBranches.map((it) => it.branch.id)];
      this.form.description = formData.metadata.content;
      // this.form.pointType = 'point';
      // const newIsAll = [];
      // const newUnCheck = [];
      // if (formData.dealers && formData.dealers.length) {
      //   const deal = [];
      //   const _branch = [];
      //   formData.dealers.map((it) => {
      //     deal.push(it.id);
      //     if (it.branch && it.branch.id && _branch.indexOf(it.branch.id) < 0) {
      //       _branch.push(it.branch.id);
      //     }
      //   });
      //   if (formData.condition) {
      //     this.form.conditionId = formData.condition.id;
      //   }
      //   if (_branch.length) {
      //     _branch.map((it, index) => {
      //       const allDealer = this.dealer.length && this.dealer.filter((ex) =>
      //       ex.branchId === it).map((item) => item.id);
      //       const dealerSelect = formData.dealers.filter((ex) =>
      //       ex.branch.id === it).map((item) => item.id);
      //       newIsAll.push({
      //         branch: it,
      //         dealer: dealerSelect,
      //         isAllDealer: allDealer.length === dealerSelect.length
      //       });
      //       if (index === _branch.length - 1) {
      //         this.valueDealer = dealerSelect;
      //         this.focusBranch = it;
      //       }
      //       if (allDealer.length !== dealerSelect.length) {
      //         newUnCheck.push(it);
      //       }
      //     });
      //   }
      //   this.form.dealerId = deal;
      //   this.valueBranch = _branch;
      //   this.selectedItems = deal;
      //   this.isCheckAllBranch = newIsAll;
      //   this.unAllDealerBranch = newUnCheck;
      // }

    }

    if (this.$refs.form) {
      this.$refs.form.clearValidate();
    }
  }
  public closeDialog() {
    this.form = campaignFormDefault();
    this.valueBranch = [];
    this.valueDealer = [];
    this.dependentItems = [];
    this.selectedItems = [];
    this.loading = false;
    this.isCheckAllBranch = [];
    this.unAllDealerBranch = [];
    this.focusBranch = '';
    this.cloneBranch = [];
    this.branchSelect = [];
    this.back();
  }

  public remoteMethod(query: string) {
    if (query !== '') {
      this.querySearchPartner = query;
    }
  }
  public onChangeRadio() {
    this.fileList = [];
    this.form.description = '';
  }

  protected mounted() {
    //
    // this.$store.dispatch(ActionTypeGroup.FilterNoCache);
    // this.$store.dispatch(ActionTypeBranchManage.GetListBranch,
    //   {form: {
    //     filter: {},
    //     pagination: {}
    //   }});
    // this.$store.dispatch(ActionTypeDealer.GetListDealer, {
    //   form: {
    //     filter: {
    //       status: StatusCode.Active
    //     },
    //     pagination: {}
    //   },
    //   onSuccess: () => {
    //     this.$emit('fetchDone');
    //   }
    // });
    // this.$store.dispatch(ConditionActionType.ConditionFilterNoCache, {
    //   optionsHTTP: {
    //     orderBy: {
    //       createdAt: 'desc'
    //     },
    //     filterParams: {
    //       status: StatusCode.Active
    //     }
    //   },
    // });
  }

  protected beforeDestroy() {
    this.querySearchPartner = '';
    this.$store.commit(MutationTypeDealer.ResetState);
  }

  private async handleSystemUser(form: any) {
    // const newDealerList = _.flattenDeep(this.isCheckAllBranch.map((it) => it.dealer));
    // const token = await DKGqlClient().user.getFirebaseAuthenticateToken();
    // await FirebaseAuthService().signInWithCustomToken(token.authenticate);
    const uploadedFile = await Promise.all(this.fileList.map(async (it) => {
      const _file = await uploadToFirebase(it.raw, this.pathUploadAvatar);

        // return _file ? uploadedFile.push(_file) : uploadedFile;
        // tslint:disable-next-line:no-var-before-return
      return _file;
    }));
    const postForm = {
      // action: form.action,
      // dealerIds: newDealerList,
      endDate: moment(this.form.endDate),
      // point: parseInt(form.point, 0),
      startDate: moment(this.form.startDate),
      title: form.title,
      image: form.image,
      icon: form.icon,
      targetBranch: form.targetBranch,
      target: 'dealer_role',
      alias: 'other',
      dealerRoles: form.dealerRoles,
      isSpecial: form.isSpecial,
      description: uploadedFile && uploadedFile.length ? uploadedFile[0] : '',
      metadata: {
        content: form.description && form.description.length ? form.description.replace(`"`, `\"`) : ''
      },
      ...form.targetBranch === 'specific' ?
      {
        targetBranches: {
          data: [
            ...this.branchSelect.map((e) => ({
              branchId: e,
              createdBy: this.authUser.id
            }))
          ]
        }
      }
       : {},
      // conditionId: form.conditionId
    };
    const _addBranch = this.branchSelect.filter((it) => !(this.cloneBranch.indexOf(it) >= 0));
    const _removeBranch = this.cloneBranch.filter((it) => !(this.branchSelect.indexOf(it) >= 0));
    if (this.userId) { // update
      this.$store.dispatch(ActionTypeCampaign.UpdateStatusCampaign, {
        form: {
          ...omit(postForm, [
            '__typename',
            'target',
            'createdAt',
            'createdBy',
            'createdUser',
            'targetBranches',
            'id'
          ]),
          updatedBy: this.authUser.id
        },
        id: this.userId,
        onSuccess: () => {
          this.loading = false;
          const message = 'Update Campaign successfully';
          this.$store.commit(MutationType.OpenTopAlert, {
            message,
            type: TypeAlert.Success
          });
          if (_addBranch.length || _removeBranch.length) {
            this.$store.dispatch(ActionTypeCampaign.UpdateCampaignBranch, {
              campaignId: this.userId,
              add: _addBranch,
              remove: _removeBranch,
              createdBy: this.authUser.id
            });
          }
          // this.$store.dispatch(ActionTypeCampaign.GetList, {form: {
          //   filter: {},
          //   pagination: {}
          // }});
          this.closeDialog();
          this.$emit('createSuccess');
        },
        onFailure: () => {
          this.loading = false;
        }
      });
    } else { // create
      this.$store.dispatch(ActionTypeCampaign.CreateCampaign, {
        form: {
          ...stripObject(postForm),
          createdBy: this.authUser.id
        },
        onSuccess: () => {
          this.loading = false;
          const message = 'Create Campaign successfully';
          this.$store.commit(MutationType.OpenTopAlert, {
            message,
            type: TypeAlert.Success
          });
          // this.$store.dispatch(ActionTypeCampaign.GetList, {form: {
          //   filter: {},
          //   pagination: {}
          // }});
          this.closeDialog();
          this.$emit('createSuccess');
          // this.$emit('createSuccess');
        },
        onFailure: () => {
          this.loading = false;
        }
      });
    }
  }
}
