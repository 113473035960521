import { DKGqlClient } from 'root/api/graphql/Client';
import { ActionType, IState, MutationType } from 'root/store';
import { crudActions } from 'root/store/helpers';
import { ActionContext } from 'vuex';
import { IBranchManageState } from './mutation';
import { ActionTypeBranchManage, MutationTypeBranchManager } from './types';

const { branchManage } = DKGqlClient();
export const actions = {
  ...crudActions('branch_manager', {
    filter: branchManage.filter,
    update: branchManage.update,
  }),
  async [ActionTypeBranchManage.BranchGetById]({
    commit, dispatch
  }: ActionContext<IBranchManageState, IState>,
                                               {id, onSuccess}) {
    try {
      const dealerDetail = await branchManage.branchManageGetById(id);
      commit(MutationTypeBranchManager.SetBranchManager, {data: dealerDetail});
      if (onSuccess) {
        onSuccess(dealerDetail);
      }
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    }
  },
  async [ActionTypeBranchManage.GetListBranch]({
    commit, dispatch
  }: ActionContext<IBranchManageState, IState>,
                                               {form}) {
    try {
      commit(MutationTypeBranchManager.BranchLoading);
      const listBranch = await branchManage.getBranchManageList(form);
      commit(MutationType.GetListBranchManage, listBranch);
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    } finally {
      commit(MutationTypeBranchManager.BranchLoaded);
    }
  },
  async [ActionTypeBranchManage.GetListManager]({
    commit, dispatch
  }: ActionContext<IBranchManageState, IState>,
                                                {form}) {
    try {
      const listBranch = await branchManage.getManagers(form);
      commit(MutationTypeBranchManager.SetManager, listBranch);
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    }
  },
  async [ActionTypeBranchManage.UpdateBranchManager]({
    commit, dispatch
  }: ActionContext<IBranchManageState, IState>,
                                                     {id, set, onSuccess, onFailure}) {
    try {
      const form = {
        branchId: id,
        managerIds: set
      };
      const updateResult = await branchManage.updateBranchManager(form);
      onSuccess();
      if (updateResult) {
        commit(ActionTypeBranchManage.GetListBranch, {
          form: {
            filter: {},
            pagination: {}
          }
        });
      }
    } catch (error) {
      onFailure(error);
      dispatch(ActionType.CatchException, error);
    }
  },
  async [ActionTypeBranchManage.UpdateStatus]({
    commit, dispatch
  }: ActionContext<IBranchManageState, IState>,
                                              {id, set, onSuccess, onFailure}) {
    try {
      const updateResult = await branchManage.updateStatus(id, set);
      onSuccess();
      if (updateResult) {
        commit(ActionTypeBranchManage.GetListBranch, {
          form: {
            filter: {},
            pagination: {}
          }
        });
      }
    } catch (error) {
      onFailure(error);
      dispatch(ActionType.CatchException, error);
    }
  },
  async [ActionTypeBranchManage.InsertBranch]({
    commit, dispatch
  }: ActionContext<IBranchManageState, IState>,
                                              {form, formManager, onSuccess, onFailure}) {
    try {
      const updateResult = await branchManage.insertBranch(form);
      if (updateResult) {
        const dataPush = <any> [];
        if (formManager.managerId && formManager.managerId.length) {
          formManager.managerId.map((it) => {
            dataPush.push(
              {branchId: updateResult.data.insert_branches.returning[0].id,
                managerId: it,
                createdBy: form.createdBy
              });
          });
        }
        const resultManager = dataPush.length ? await branchManage.insertBranchManager(dataPush) : 1;
        onSuccess();
        if (resultManager) {
          commit(ActionTypeBranchManage.GetListBranch, {
            form: {
              filter: {},
              pagination: {}
            }
          });

        }
      }
    } catch (error) {
      onFailure(error);
      dispatch(ActionType.CatchException, error);
    }
  },
  async [ActionTypeBranchManage.updateBranch]({
    dispatch
  }: ActionContext<IBranchManageState, IState>,
                                              {id, form, formManager, onSuccess, onFailure}) {
    try {
      const updateResult = await branchManage.updateBranch(id, form);
      if (updateResult) {
        const _form = {
          branchId: id,
          managerIds:  formManager.managerId
        };
        // await branchManage.updateBranchManager(_form);
        if (_form.managerIds.length) {
          await branchManage.updateBranchManager(_form);
        }
        if (!_form.managerIds.length) {
          await branchManage.removeBranchManager(_form);
        }
        dispatch(ActionTypeBranchManage.GetListBranch, {
          form: {
            filter: {},
            pagination: {}
          }
        });

        onSuccess();
      }
    } catch (error) {
      onFailure(error);
      dispatch(ActionType.CatchException, error);
    }
  },
  async [ActionTypeBranchManage.InsertInvoice]({
     dispatch
  }: ActionContext<IBranchManageState, IState>,
                                               {form, onSuccess, onFailure}) {
    try {
      await branchManage.insertInvoice(form);
      onSuccess();
    } catch (error) {
      onFailure();
      dispatch(ActionType.CatchException, error);
    }
  },
};
