export enum ActionTypeEvaluatePhoto {
  FilterNoCache = 'evaluatePhotoFilterNoCache',
  GetCustomCampaign = 'getCustomCampaign',
  HandleOnOff = 'handleOnOff',
  HandleStatus = 'handleStatus',
  GetAllSample = 'getAllSample',
  DownloadSample = 'downloadSample'
}
export enum MutationTypeEvaluatePhoto {
  FilterChange = 'evaluatePhotoFilterChange',
  PaginationChange = 'evaluatePhotoPaginationChange',
  ChangeOrderBy = 'evaluatePhotoChangeOrderBy',
  SetDataCustomCompaign = 'evaluatePhotoSetDataCustomCompaign'
}
export enum ActionTypeSaleEduCampaign {
  FilterNoCache = 'bank_dealersFilterNoCache',
  Update = 'bank_dealersUpdate',
  ImportBankingPayment = 'ImportBankingPayment',
  CheckAsPaid = 'CheckAsPaid',
  Export = 'ExportSalesEduCampaign',
  GetListTrainee = 'GetListTrainee',
  GetListImportHistory = 'GetListImportHistory',
  GetListDealerDetail = 'GetListDealerDetail',
  DownloadImportLogs = 'DownloadImportLogs'
}
export enum MutationTypeSaleEduCampaign {
  FilterChange = 'bank_dealersFilterChange',
  PaginationChange = 'bank_dealersPaginationChange',
  ChangeOrderBy = 'bank_dealersChangeOrderBy',
  Loading = 'bank_dealersLoading',
  Loaded = 'bank_dealersLoaded',
}
