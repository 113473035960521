import * as productEWarranty from 'root/admin/ActiveEWarranty/Store';
import * as banners from 'root/admin/BannerManagement/store';
import * as branch from 'root/admin/Branch/Store';
import * as branchManage from 'root/admin/BranchAndGroup/BranchManage/Store';
import * as groupManage from 'root/admin/BranchAndGroup/GroupManage/Store';
import * as aclCampaign from 'root/admin/CustomCampaign/ACLCampaign/Store';
import * as newRACampaign from 'root/admin/CustomCampaign/NewRACampaign2023/Store';
import * as raCampaign from 'root/admin/CustomCampaign/RACampaign/Store';
import * as saleInCampaign from 'root/admin/CustomCampaign/SaleInCampaign/Store';
import * as customCampaign from 'root/admin/CustomCampaign/Store';
import * as zeroCampaign from 'root/admin/CustomCampaign/ZeroPercentCampaign/Store';
import * as accountStaff from 'root/admin/Dealer/AccountStaff/Store';
import * as dealerShop from 'root/admin/Dealer/DealerShop/Store';
import * as dealer from 'root/admin/Dealer/Store';
import * as deviceType from 'root/admin/DeviceType/Store';
import * as group from 'root/admin/Group/Store';
import * as invoice from 'root/admin/Invoice/Store';
import * as dealerType from 'root/admin/ManageDealerType/Store';
import * as feedback from 'root/admin/ManageFeedback/Store';
import * as manageImport from 'root/admin/ManageImport/Store';
import * as notification from 'root/admin/NewsAnnoucement/store';
import * as point from 'root/admin/Point/Store';
import * as product from 'root/admin/Product/Store';
import * as productTrans from 'root/admin/ProductTransaction/Store';
import * as campaign from 'root/admin/Redemption/Campaign/Store';
import * as condition from 'root/admin/Redemption/Condition/Store';
import * as redemption from 'root/admin/Redemption/Store';
import * as region from 'root/admin/Region/Store';
import * as role from 'root/admin/Role/Store';
import * as teamStaff from 'root/admin/TeamStaff/Store';
import * as transactionView from 'root/admin/TransactionView/Store';
import * as user from 'root/admin/User/Store';
import * as example from 'root/admin/WelcomePage/Store';

import Vue from 'vue';
import Vuex from 'vuex';
import { actions } from './actions';
import { getters } from './getters';
import { globalState, mutations } from './mutations';

export * from './types';

Vue.use(Vuex);

export default new Vuex.Store({
  actions,
  getters,
  mutations,
  state: {
    global: globalState,
    route: {
      params: {},
      path: '',
      query: {}
    }
  },
  modules: {
    example,
    user,
    role,
    group,
    dealer,
    dealerShop,
    dealerType,
    accountStaff,
    branch,
    product,
    productTrans,
    point,
    deviceType,
    branchManage,
    groupManage,
    notification,
    region,
    banners,
    redemption,
    campaign,
    condition,
    manageImport,
    feedback,
    invoice,
    transactionView,
    productEWarranty,
    teamStaff,
    customCampaign,
    aclCampaign,
    raCampaign,
    zeroCampaign,
    newRACampaign,
    saleInCampaign
  }
});
