import { gqlClient } from '../Core';
import { CRUDGqlService, ICRUDGqlService } from '../Core/crud';
import { campaign, campaignQuery } from '../Query/campaign';

export interface ICampaignGqlService extends ICRUDGqlService<any> {
  createCampaign(form: any): Promise<any>;
  updateCampaign(id: string, form: any): Promise<any>;
  updateStatusCampaign(id: string, form: any): Promise<any>;
  getList(form: any): Promise<any>;
  getCampaignDetail(form: any): Promise<any>;
  getCampaignList(limit: number, offset: number, filter: any): Promise<any>;
  updateCampaignBranch(campaignId: string, add: any[], remove: any[], createdBy: string): Promise<any>;
}
export function CampaignGqlService(): ICampaignGqlService {
  async function getList(form: any): Promise<any> {
    const results = await gqlClient.query({
      query: campaignQuery.GET_LIST_CAMPAGIN,
      variables: {
        form
      },
      fetchPolicy: 'network-only'
    });
    if (results.errors) {
      throw results.errors;
    }

    return results.data.getCampaignList;
  }
  async function getCampaignDetail(form: any): Promise<any> {
    const results = await gqlClient.query({
      query: campaignQuery.GET_CAMPAIGN_DETAIL,
      variables: {
        form
      },
      fetchPolicy: 'network-only'
    });
    if (results.errors) {
      throw results.errors;
    }

    return results.data.getCampaignDetail;
  }
  async function createCampaign(form: any): Promise<any> {
    const results = await gqlClient.mutate({
      mutation: campaignQuery.CREATE_CAMPAIGN,
      variables: {
        form,
      }
    });
    if (results.errors) {
      throw results.errors;
    }

    return results;
  }
  async function updateCampaign(id: string, form: any): Promise<any> {
    const results = await gqlClient.mutate({
      mutation: campaignQuery.UPDATE_CAMPAIGN,
      variables: {
        id,
        form
      }
    });
    if (results.errors) {
      throw results.errors;
    }

    return results;
  }
  async function updateStatusCampaign(id: string, form: any): Promise<any> {
    const results = await gqlClient.mutate({
      mutation: campaignQuery.UPDATE_STATUS,
      variables: {
        id,
        form
      }
    });
    if (results.errors) {
      throw results.errors;
    }

    return results;
  }
  async function getCampaignList(limit: number, offset: number, filter: any): Promise<any> {
    const results = await gqlClient.query({
      query: campaignQuery.GET_CAMPAIGN_LIST,
      variables: {
        limit, offset, filter
      },
      fetchPolicy: 'network-only'
    });
    if (results.errors) {
      throw results.errors;
    }

    return results;
  }
  async function updateCampaignBranch(campaignId: string, add: any[], remove: any[], createdBy: string): Promise<any> {
    if (add && add.length > 0) {
      const arr = add.map((it) => ({
        campaignId, createdBy, branchId: it,
      }));
      const results = await gqlClient.query({
        query: campaignQuery.ADD_BRANCH_CAMPAIGN,
        variables: {
          arr
        },
        fetchPolicy: 'network-only'
      });
      if (results.errors) {
        throw results.errors;
      }
    }
    if (remove && remove.length > 0) {
      const results = await gqlClient.query({
        query: campaignQuery.REMOVE_BRANCH_CAMPAIGN,
        variables: {
          campaignId, remove
        },
        fetchPolicy: 'network-only'
      });
      if (results.errors) {
        throw results.errors;
      }
    }

    return;
  }

  return {
    ...CRUDGqlService<any>(campaign, campaignQuery),
    createCampaign,
    updateCampaign,
    updateStatusCampaign,
    getList,
    getCampaignDetail,
    getCampaignList,
    updateCampaignBranch
  };
}
