import { PermissionType } from 'root/models';
import { RouteConfig } from 'vue-router';
import { BranchAndGroupContainer } from './BranchAndGroupContainer';
import { ActiveEWarrantyBranch } from './BranchManage/ActiveEWarranty';
import { BranchDetail } from './BranchManage/BranchDetail';
import { BranchInventoryList } from './BranchManage/BranchInventory';
import { BranchInvoiceList } from './BranchManage/BranchInvoice';
import { BranchList } from './BranchManage/BranchList';
import { BranchDealerList } from './BranchManage/Dealers';
import { BranchGroupsLeadersList } from './BranchManage/GroupLeader';
import { BranchGroupsList } from './BranchManage/Groups';
import { ManagerBranchList } from './BranchManage/Managers';
import { BranchProductList } from './BranchManage/Product';
import { BranchStaffList } from './BranchManage/Staff';
import { BranchTeamLeaderList } from './BranchManage/TeamLeader';
import { BranchTechnicianList } from './BranchManage/Technicians';
import { ActiveEWarrantyGroup } from './GroupManage/ActiveEWarranty';
import { GroupDealerList } from './GroupManage/Dealers';
import { GroupDetail } from './GroupManage/GroupDetail';
import { GroupInventoryList } from './GroupManage/GroupInventory';
import { GroupInvoiceList } from './GroupManage/GroupInvoice';
import { GroupsLeadersList } from './GroupManage/GroupLeader';
import { GroupList } from './GroupManage/GroupList';
import { GroupProductList } from './GroupManage/Product';
import { GroupStaffList } from './GroupManage/Staff';
import { GroupTeamLeaderList } from './GroupManage/TeamLeader';
import { GroupTechnicianList } from './GroupManage/Technicians';
import { RegionBranch } from './RegionManage/RegionBranch';
import { RegionBranchLeader } from './RegionManage/RegionBranchLeader';
import { RegionDealer } from './RegionManage/RegionDealer';
import { RegionDetail } from './RegionManage/RegionDetail';
import { RegionList } from './RegionManage/RegionList';

export enum BranchAndGroupRouterPath {
  BranchAndGroup = '/branches-groups',
  ListRegion = '/branches-groups/region',
  DetailRegion = '/branches-groups/region/:id',
  RegionBranch = '/branches-groups/region/:id/branch',
  RegionBranchLeader = '/branches-groups/region/:id/branch-manager',
  RegionDealer = '/branches-groups/region/:id/dealer',

  // branch
  ListBranch = '/branches-groups/branches',
  DetailBranch = '/branches-groups/branches/:id',
  BranchGroups = '/branches-groups/branches/:id/group',
  GroupLeader = '/branches-groups/branches/:id/group-leader',
  ManagerBranch = '/branches-groups/branches/:id/branch-manager',
  DealerBranch = '/branches-groups/branches/:id/branch-dealer',
  TechnicianBranch = '/branches-groups/branches/:id/branch-technician',
  BranchInventory = '/branches-groups/branches/:id/branch-inventory',
  BranchInvoice = '/branches-groups/branches/:id/branch-invoice',
  BranchProduct = '/branches-groups/branches/:id/branch-product',
  BranchEWarranty = '/branches-groups/branches/:id/active-e-warranty',
  BranchTeamLeader = '/branches-groups/branches/:id/team-leader',
  BranchStaff = '/branches-groups/branches/:id/staff',

  // group
  ListGroupManage = '/branches-groups/groups',
  DetailGroup = '/branches-groups/groups/:id',
  GroupLeaderGroup = '/branches-groups/groups/:id/group-leader',
  DealerGroup = '/branches-groups/groups/:id/group-dealer',
  TechnicianGroup = '/branches-groups/groups/:id/group-tẹchnician',
  GroupInventory = '/branches-groups/groups/:id/group-inventory',
  GroupInvoice = '/branches-groups/groups/:id/group-invoice',
  GroupProduct = '/branches-groups/groups/:id/group-product',
  GroupEWarranty = '/branches-groups/groups/:id/active-e-warranty',
  GroupTeamLeader = '/branches-groups/groups/:id/team-leader',
  GroupStaff = '/branches-groups/groups/:id/staff'
}
export enum BranchAndGroupRouterName {
  BranchAndGroup = 'BranchAndGroup',
  ListRegion = 'ListRegion',
  DetailRegion = 'DetailRegion',
  RegionBranch = 'RegionBranch',
  RegionBranchLeader = 'RegionBranchLeader',
  RegionDealer = 'RegionDealer',

  // branch
  ListBranch = 'ListBranch',
  DetailBranch = 'DetailBranch',
  BranchGroups = 'BranchGroups',
  GroupLeader = 'GroupLeader',
  ManagerBranch = 'ManagerBranch',
  DealerBranch = 'DealerBranch',
  TechnicianBranch = 'TechnicianBranch',
  BranchInventory = 'BranchInventory',
  BranchInvoice = 'BranchInvoice',
  BranchProduct = 'BranchProduct',
  BranchEWarranty = 'BranchEWarranty',
  BranchTeamLeader ='BranchTeamLeader',
  BranchStaff = 'BranchStaff',
  // group
  ListGroupManage = 'ListGroupManage',
  DetailGroup = 'DetailGroup',
  GroupLeaderGroup = 'GroupLeaderGroup',
  DealerGroup = 'DealerGroup',
  TechnicianGroup = 'TechnicianGroup',
  GroupInventory = 'GroupInventory',
  GroupInvoice = 'GroupInvoice',
  GroupProduct = 'GroupProduct',
  GroupEWarranty = 'GroupEWarranty',
  GroupTeamLeader = 'GroupTeamLeader',
  GroupStaff = 'GroupStaff'
}
export const BranchAndGroupRouter: RouteConfig = {
  path: '/branches-groups',
  component: BranchAndGroupContainer,
  meta: {
    permission: PermissionType.Branchgroup
  },
  children: [
    {
      path: BranchAndGroupRouterPath.ListRegion,
      component: RegionList,
      name: BranchAndGroupRouterName.ListRegion,
      meta: {
        permission: PermissionType.Branchgroup
      },
    },
    {
      path: BranchAndGroupRouterPath.ListBranch,
      component: BranchList,
      name: BranchAndGroupRouterName.ListBranch,
      meta: {
        permission: PermissionType.Branchgroup
      },
    },
    {
      path: BranchAndGroupRouterPath.ListGroupManage,
      component: GroupList,
      name: BranchAndGroupRouterName.ListGroupManage,
      meta: {
        permission: PermissionType.Branchgroup
      },
    },
    {
      path: BranchAndGroupRouterPath.DetailRegion,
      component: RegionDetail,
      name: BranchAndGroupRouterName.DetailRegion,
      redirect: {
        name: BranchAndGroupRouterName.RegionBranch
      },
      children: [
        {
          path: BranchAndGroupRouterPath.RegionBranch,
          component: RegionBranch,
          name: BranchAndGroupRouterName.RegionBranch,
          meta: {
            permission: PermissionType.Branchgroup
          },
        },
        {
          path: BranchAndGroupRouterPath.RegionBranchLeader,
          component: RegionBranchLeader,
          name: BranchAndGroupRouterName.RegionBranchLeader,
          meta: {
            permission: PermissionType.Branchgroup
          },
        },
        {
          path: BranchAndGroupRouterPath.RegionDealer,
          component: RegionDealer,
          name: BranchAndGroupRouterName.RegionDealer,
          meta: {
            permission: PermissionType.Branchgroup
          },
        },
      ]
    },
    {
      path: BranchAndGroupRouterPath.DetailBranch,
      component: BranchDetail,
      name: BranchAndGroupRouterName.DetailBranch,
      redirect: {
        name: BranchAndGroupRouterName.BranchGroups
      },
      children: [
        {
          path: BranchAndGroupRouterPath.BranchGroups,
          component: BranchGroupsList,
          name: BranchAndGroupRouterName.BranchGroups,
          meta: {
            permission: PermissionType.Branchgroup
          },
        },
        {
          path: BranchAndGroupRouterPath.GroupLeader,
          component: BranchGroupsLeadersList,
          name: BranchAndGroupRouterName.GroupLeader,
          meta: {
            permission: PermissionType.Branchgroup
          },
        },
        {
          path: BranchAndGroupRouterPath.ManagerBranch,
          component: ManagerBranchList,
          name: BranchAndGroupRouterName.ManagerBranch,
          meta: {
            permission: PermissionType.Branchgroup
          },
        },
        {
          path: BranchAndGroupRouterPath.DealerBranch,
          component: BranchDealerList,
          name: BranchAndGroupRouterName.DealerBranch,
          meta: {
            permission: PermissionType.Branchgroup
          },
        },
        {
          path: BranchAndGroupRouterPath.TechnicianBranch,
          component: BranchTechnicianList,
          name: BranchAndGroupRouterName.TechnicianBranch,
          meta: {
            permission: PermissionType.Branchgroup
          },
        },
        {
          path: BranchAndGroupRouterPath.BranchInventory,
          component: BranchInventoryList,
          name: BranchAndGroupRouterName.BranchInventory,
          meta: {
            permission: PermissionType.Branchgroup
          },
        },
        {
          path: BranchAndGroupRouterPath.BranchInvoice,
          component: BranchInvoiceList,
          name: BranchAndGroupRouterName.BranchInvoice,
          meta: {
            permission: PermissionType.Branchgroup
          },
        },
        {
          path: BranchAndGroupRouterPath.BranchTeamLeader,
          component: BranchTeamLeaderList,
          name: BranchAndGroupRouterName.BranchTeamLeader,
          meta: {
            permission: PermissionType.Branchgroup
          },
        },
        {
          path: BranchAndGroupRouterPath.BranchStaff,
          component: BranchStaffList,
          name: BranchAndGroupRouterName.BranchStaff,
          meta: {
            permission: PermissionType.Branchgroup
          },
        },
        {
          path: BranchAndGroupRouterPath.BranchProduct,
          component: BranchProductList,
          name: BranchAndGroupRouterName.BranchProduct,
          meta: {
            permission: PermissionType.Branchgroup
          },
        },
        {
          path: BranchAndGroupRouterPath.BranchEWarranty,
          component: ActiveEWarrantyBranch,
          name: BranchAndGroupRouterName.BranchEWarranty,
          meta: {
            permission: PermissionType.Branchgroup
          },
        },
      ]
    },
    {
      path: BranchAndGroupRouterPath.DetailGroup,
      component: GroupDetail,
      name: BranchAndGroupRouterName.DetailGroup,
      redirect: {
        name: BranchAndGroupRouterName.GroupLeaderGroup
      },
      children: [
        {
          path: BranchAndGroupRouterPath.GroupLeaderGroup,
          component: GroupsLeadersList,
          name: BranchAndGroupRouterName.GroupLeaderGroup,
          meta: {
            permission: PermissionType.Branchgroup
          },
        },
        {
          path: BranchAndGroupRouterPath.GroupTeamLeader,
          component: GroupTeamLeaderList,
          name: BranchAndGroupRouterName.GroupTeamLeader,
          meta: {
            permission: PermissionType.Branchgroup
          },
        },
        {
          path: BranchAndGroupRouterPath.GroupStaff,
          component: GroupStaffList,
          name: BranchAndGroupRouterName.GroupStaff,
          meta: {
            permission: PermissionType.Branchgroup
          },
        },
        {
          path: BranchAndGroupRouterPath.DealerGroup,
          component: GroupDealerList,
          name: BranchAndGroupRouterName.DealerGroup,
          meta: {
            permission: PermissionType.Branchgroup
          },
        },
        {
          path: BranchAndGroupRouterPath.TechnicianGroup,
          component: GroupTechnicianList,
          name: BranchAndGroupRouterName.TechnicianGroup,
          meta: {
            permission: PermissionType.Branchgroup
          },
        },
        {
          path: BranchAndGroupRouterPath.GroupInventory,
          component: GroupInventoryList,
          name: BranchAndGroupRouterName.GroupInventory,
          meta: {
            permission: PermissionType.Branchgroup
          },
        },
        {
          path: BranchAndGroupRouterPath.GroupInvoice,
          component: GroupInvoiceList,
          name: BranchAndGroupRouterName.GroupInvoice,
          meta: {
            permission: PermissionType.Branchgroup
          },
        },
        {
          path: BranchAndGroupRouterPath.GroupProduct,
          component: GroupProductList,
          name: BranchAndGroupRouterName.GroupProduct,
          meta: {
            permission: PermissionType.Branchgroup
          },
        },
        {
          path: BranchAndGroupRouterPath.GroupEWarranty,
          component: ActiveEWarrantyGroup,
          name: BranchAndGroupRouterName.GroupEWarranty,
          meta: {
            permission: PermissionType.Branchgroup
          },
        },
      ]
    }
  ]
};
