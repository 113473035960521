import { cloneDeep } from 'lodash';
import moment from 'moment';
import { ruleEmail, ruleRequired } from 'root/helpers';
import { ELocalStorage } from 'root/models';
import DaikinAuth from 'root/services/auth';
import { ActionType, IState, Layout, MutationType } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import './styles.scss';

@Component({
  template: require('./view.html'),
  computed: {
    ...mapState({
      authUser: (state: IState) => state.global.authUser,
    })
  }
})

export class LoginContainer extends Vue {
  public authUser;
  public email: string = '';
  public password: string = '';
  public loading: boolean = false;
  public form = {
    email: '',
    password: ''
  };
  public $refs: {
    loginForm: any
  };
  public langs: any = [
    { label: 'English (United State)', code: 'en' },
    { label: 'Japanese', code: 'jp' },
    { label: 'Vietnamese', code: 'vn' },
    { label: 'Chinese (Simple)', code: 'cn' },
  ];
  public currentLang = { label: 'English (United State)', code: 'en' };

  public errorMessage: string = null;
  public valid: boolean = false;

  public get rules() {

    return {
      required: ruleRequired(),
      email: ruleEmail()
    };
  }

  public submit() {
    this.$refs.loginForm.validate(async (valid) => {
      if (valid) {
        this.loading = true;
        const form = cloneDeep(this.form);
        try {
          this.$store.commit(MutationType.SetAccountExpired, false);
          const data = await DaikinAuth.loginWithEmail(form);
          if (data) {
            localStorage.setItem(ELocalStorage.Token, data.data.data.token);
            localStorage.setItem(ELocalStorage.RefreshToken, data.data.data.refreshToken);
            localStorage.setItem('ExpireTime', moment().add(50, 'minutes').format('DD-MM-YYYY HH:mm:ss'));
            this.$store.commit(MutationType.Authenticated, data.data.data);
            this.$store.commit(MutationType.SetLayout, Layout.Home);
            this.$store.commit(MutationType.SetIsRefesh, false);
            this.$router.replace('/');
          }
        } catch (error) {
          this.$store.dispatch(ActionType.CatchException, error);
        }
        this.loading = false;
      }

      return;
    });
  }

  public mounted() {
    this.$nextTick(() => {
      if (this.authUser && this.authUser.id) {
        this.$store.commit(MutationType.SetLayout, Layout.Home);
        this.$router.replace('/');

      }
    });
  }

  public handleLangChange(lang) {
    this.currentLang = lang;
  }
}
