import { IBreadcrumb, MutationType } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { DealerRouterPath } from '..';
import './styles.scss';

@Component({
  template: require('./view.html'),
})

export class DealerCampainList extends Vue {
  public get serviceId(): string {
    return this.$route.params.id;
  }
  public get columns() {
    return [
      {
        prop: 'name',
        label: 'Campaign Name',
        width: 180,
        formatter: (model: any) => {
          return model.name ? model.name : '--';
        }
      },
      {
        prop: 'staff',
        label: 'Product Qty',
        width: 180,
      },
      {
        prop: 'phoneNumber',
        label: 'Progress',
        width: 180,
        formatter: (model: any) => {
          return model.phoneNumber || '--';
        }
      },
      {
        prop: 'email',
        label: 'Rewards',
        width: 180,
        formatter: (model: any) => {
          return model.email ? model.email : '--';
        }
      },
      {
        prop: 'action',
        label: 'Action',
        align: 'center',
        fixed: 'right',
      }
    ];
  }

  public searchText: string = '';
  public data: any[] = [];

  public clickDetail() {
    // change after
  }
  public changeFilter() {
    // change after
  }
  public handleSearch() {
    // change after
  }
  public goAddNewRouter() {
   // change after
  }

  public handlePaginationChange() {
    // change after
  }

  protected mounted() {
    this.$nextTick(() => {
      const breadcrumb: IBreadcrumb[] = [
        {
          label: 'title.dealer',
          path: DealerRouterPath.ListDealer,
          current: false
        },
        {
          label: 'title.view_details',
          path: null,
          current: true
        }
      ];
      this.$store.commit(MutationType.SetBreadcrumb, breadcrumb);
      this.fetchData();
    });
  }
  protected beforeDestroy() {
    this.$store.commit(MutationType.ClearBreadcrumb);
  }

  private fetchData() {
    // change after
  }
}
