import { PermissionType } from 'root/models';
import { RouteConfig } from 'vue-router';
import { AccountStaff } from './AccountStaff';
import { ActiveEWarranty } from './ActiveEWarranty';
import { DealerCampainList } from './DealerCampaign';
import { DealersContainer } from './DealerContainer';
import { DealerDetail } from './DealerDetail';
import { DealerList } from './DealerList';
import { DealerShop } from './DealerShop';
import { InventoryList } from './Inventory';
import { PointHistoryList } from './PointHistory';
import { SaleRoutsList } from './SaleRouts';
import { SalemanList } from './Saleman';
import { SubDealerList } from './SubDealer';
import { TransactionList } from './Transaction';

export enum DealerRouterPath {
  ListDealer = '/manager-dealers/list',
  DetailDealer = '/dealer/:id',
  Inventory = '/dealer/:id/inventory',
  Transaction = '/dealer/:id/transaction',
  PointHistory = '/dealer/:id/point',
  SaleRouts = '/dealer/:id/sale-rout',
  ActiveEWarranty = '/dealer/:id/active-e-warranty',
  AccountStaff = '/dealer/:id/account-staff',
  DealerShop = '/dealer/:id/dealer-shop',
  Campaign = '/dealer/:id/campaign',
  Saleman = '/manager-dealers/salemans',
  SubDealer = '/manager-dealers/sub-dealer'
}
export enum DealerRouterName {
  ListDealer = 'ListDealer',
  DetailDealer = 'DetailDealer',
  Inventory = 'Inventory',
  Transaction = 'Transaction',
  PointHistory = 'PointHistory',
  SaleRouts = 'SaleRouts',
  ActiveEWarranty = 'ActiveEWarranty',
  AccountStaff = 'Account',
  DealerShop = 'DealerShop',
  Campaign = 'Campaign',
  Saleman = 'saleman',
  SubDealer = 'subdealer'
}

export const dealerRouter: RouteConfig = {
  path: '/manager-dealers',
  component: DealersContainer,
  redirect: DealerRouterPath.ListDealer,
  meta: {
    permission: PermissionType.Dealers
  },
  children: [
    {
      path: DealerRouterPath.ListDealer,
      component: DealerList,
      name: DealerRouterName.ListDealer,
      meta: {
        permission: PermissionType.Dealers
      },
    },
    {
      path: DealerRouterPath.Saleman,
      component: SalemanList,
      name: DealerRouterName.Saleman,
      meta: {
        permission: PermissionType.Dealers
      },
    },
    {
      path: DealerRouterPath.SubDealer,
      component: SubDealerList,
      name: DealerRouterName.SubDealer,
      meta: {
        permission: PermissionType.Dealers
      },
    },
    {
      path: DealerRouterPath.DetailDealer,
      component: DealerDetail,
      meta: {
        permission: PermissionType.Dealers
      },
      children: [
        {
          path: '',
          component: DealerDetail,
          name: DealerRouterName.DetailDealer,
          redirect: {
            name: DealerRouterName.AccountStaff
          },
          meta: {
            permission: PermissionType.Dealers
          },
        },
        {
          path: DealerRouterPath.Inventory,
          component: InventoryList,
          name: DealerRouterName.Inventory,
          meta: {
            permission: PermissionType.Dealers
          },
        },
        {
          path: DealerRouterPath.Transaction,
          component: TransactionList,
          name: DealerRouterName.Transaction,
          meta: {
            permission: PermissionType.Dealers
          },
        },
        {
          path: DealerRouterPath.PointHistory,
          component: PointHistoryList,
          name: DealerRouterName.PointHistory,
          meta: {
            permission: PermissionType.Dealers
          },
        },
        {
          path: DealerRouterPath.SaleRouts,
          component: SaleRoutsList,
          name: DealerRouterName.SaleRouts,
          meta: {
            permission: PermissionType.Dealers
          },
        },
        {
          path: DealerRouterPath.ActiveEWarranty,
          component: ActiveEWarranty,
          name: DealerRouterName.ActiveEWarranty,
          meta: {
            permission: PermissionType.Dealers
          },
        },
        {
          path: DealerRouterPath.AccountStaff,
          component: AccountStaff,
          name: DealerRouterName.AccountStaff,
          meta: {
            permission: PermissionType.Dealers
          },
        },
        {
          path: DealerRouterPath.DealerShop,
          component: DealerShop,
          name: DealerRouterName.DealerShop,
          meta: {
            permission: PermissionType.Dealers
          },
        },
        {
          path: DealerRouterPath.Campaign,
          component: DealerCampainList,
          name: DealerRouterName.Campaign,
          meta: {
            permission: PermissionType.Dealers
          },
        },
      ]
    }
  ]
};
