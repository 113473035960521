import axios from 'axios';
import { FirebaseAuthService } from './firebase';
interface IFormAuthDate {
  email?: string;
  password?: string;
}
interface IFormRefeshToken {
  token?: string;
  refreshToken?: string;
}
const baseURl = process.env.BASE_AUTH_API;

export async function loginWithEmail(data: IFormAuthDate): Promise<any> {
  try {
    const response = await axios({
      data,
      method: 'POST',
      url: `${baseURl}/auth/user/login`,
    });

    return Promise.resolve(response);
  } catch (error) {
    throw error.response.data;
    // return Promise.reject(error.response);
  }

  // return response.data;
}
export function getIdToken(): Promise<string> {

  return localStorage.token;
}
export async function refreshToken(data: IFormRefeshToken): Promise<any> {
  // try {
  //   const newToken = await axios({
  //     data,
  //     method: 'POST',
  //     url: `${baseURl}/auth/token`,
  //     headers: {
  //       device: 'web',
  //       platform: 'sales_portal'
  //     }
  //   });

  //   // return Promise.resolve(newToken);
  // } catch (error) {
  //   return Promise.reject(error);
  // }
  return axios({
    data,
    method: 'POST',
    url: `${baseURl}/auth/token`,
    headers: {
      device: 'web',
      platform: 'sales_portal'
    }
  });
}

export default {
  loginWithEmail,
  refreshToken,
  getIdToken
};
export const DaikinAuth = FirebaseAuthService();

export * from './types';
