import { IUser } from 'root/models';
import { IState, MutationType, TypeAlert } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import { ActionTypeZeroCampaign } from '../../Store/types';
import './styles.scss';
@Component({
  template: require('./view.html'),
  props: {
    visible: Boolean,
    campaignId: String,
    salesmanInfo: Object
  },
  computed: {
    ...mapState({
      authUser: (state: IState) => state.global.authUser,
    })
  },
})
export class UpdateSalesmanInformation extends Vue {
  public salesmanName: string = '';
  public salesmanPhone: string = '';
  public salesmanInfo: any;
  public authUser: IUser;
  public loadingSubmit: boolean = false;
  public isErrorSalesmanName: boolean = false;
  public isErrorSalesmanPhone: boolean = false;
  public async openDialog() {
    // tslint:disable-next-line: early-exit
    if (this.salesmanInfo) {
      const { salesmanName, salesmanPhone } = this.salesmanInfo;
      this.salesmanName = salesmanName;
      this.salesmanPhone = salesmanPhone;
    }
  }
  public submitUpdate() {
    this.isErrorSalesmanName = false;
    this.isErrorSalesmanPhone = false;
    if (!this.salesmanName) {
      this.isErrorSalesmanName = true;

      return;
    }
    if (!this.salesmanPhone) {
      this.isErrorSalesmanPhone = true;

      return;
    }
    this.loadingSubmit = true;
    this.$store.dispatch(ActionTypeZeroCampaign.UpdateSalesmanInfo, {
      id: this.$props.campaignId,
      set: {
        salesmanName: this.salesmanName,
        salesmanPhone: this.salesmanPhone,
        updatedBy: this.authUser.id
      },
      onSuccess: () => {
        const message = 'Update salesman information successfully';
        this.$store.commit(MutationType.OpenTopAlert, {
          message,
          type: TypeAlert.Success,
        });
        this.isErrorSalesmanName = false;
        this.isErrorSalesmanPhone = false;
        this.loadingSubmit = false;
        this.closeDialog();
      }
    });
  }
  public closeDialog() {
    this.$emit('closeDialog');
  }
}
