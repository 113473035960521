import { ElInput } from 'element-ui/types/input';
import { cloneDeep, find, findIndex } from 'lodash';
import { IFilterInput } from 'root/models';
import Vue from 'vue';
import Component from 'vue-class-component';
import './styles.scss';

@Component({
  template: require('./view.html'),
  props: {
    placeholder: {
      type: String,
      default: 'searchBy.selection'
    },
    filterEnable: Boolean,
    name: String,
    selected: {
      type: Array,
      default: (): IFilterInput[] => [
        {
          key: 'select',
          value: ['processing']
        }
      ]
    },
    multipleSelect: {
      type: Boolean, default: true
    },
    isHardPlaceholder: Boolean,
    data: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  computed: {
    // cloneData() {}
  }
})

export class SearchByFilter extends Vue {
  public $refs: {
    searchTextField: ElInput
  };
  public data: IFilterInput[];
  public newFilter: IFilterInput[] = [];
  public deleted: IFilterInput[] = [];
  public selected: IFilterInput[];
  public isShow: boolean = false;
  public total: string = null;
  public isSingleCheckbox: boolean;
  public searchText: string = '';
  public multipleSelect: boolean;

  public get label() {
    return 'Search By';
  }
  public get currentChecked(): IFilterInput[] {
    const selected = this.selected,
      deleted = this.deleted,
      newFilter = this.newFilter;
    const data = cloneDeep(this.data);

    return data.map((e) => {
      const selectedByKey = selected.find((s) => s.key === e.key),
        valueChecked = selectedByKey ? selectedByKey.value : e.isSingleCheckbox ? false : [];
      const deletedByKey = deleted.find((s) => s.key === e.key),
        valueDeleted = deletedByKey ? deletedByKey.value : [];
      const newByKey = newFilter.find((s) => s.key === e.key),
        valueNew = newByKey ? newByKey.value : e.isSingleCheckbox ? false : [];

      let singleCheckValue = false;
      if (deletedByKey) {
        singleCheckValue = false;
      } else if (newByKey) {
        singleCheckValue = newByKey ? newByKey.value : false;
      } else {
        singleCheckValue = valueChecked;
      }

      return{
        ...e,
        value: e.isSingleCheckbox ? singleCheckValue : e.value.map((v) => {
          if (valueDeleted.indexOf(v.value) > -1) {
            return false;
          }
          if (valueChecked.indexOf(v.value) > -1 || valueNew.indexOf(v.value) > -1) {
            return v.value;
          }
        }).filter((v) => v)
      };
    }).filter((e) => e.value || e.isSingleCheckbox);
  }

  public click() {
    this.$emit('click');
    this.$emit('update:filterEnable', false);
  }

  public isIndeterminate(key: string) {
    const data = cloneDeep(this.data),
      values = find(data, (e) => e.key === key),
      currentChecked = this.currentChecked.find((e) => e.key === key),
      currentCheckedVale = currentChecked ? currentChecked.value : null;

    return currentCheckedVale.length > 0 && currentCheckedVale.length < values.value.length;
  }
  public formaterChecked(value: any, key: string) {
    const newFilter = find(this.newFilter, (e) => e.key === key),
      newFilterValue = newFilter ? newFilter.value : [];
    const selected = find(this.selected, (e) => e.key === key),
      selectedValue = selected ? selected.value : [];
    const deleted = find(this.deleted, (e) => e.key === key),
      deletedValue = deleted ? deleted.value : [];

    if (deletedValue.indexOf(value) > -1) {
      return false;
    }
    if (newFilterValue.indexOf(value) > -1 || selectedValue.indexOf(value) > -1) {
      return true;
    }

    return false;
  }

  public isCheckAll(key: string) {
    const data = cloneDeep(this.data),
      values = find(data, (e) => e.key === key),
      currentChecked = this.currentChecked.find((e) => e.key === key),
      currentCheckedVale = currentChecked ? currentChecked.value : false;

    if (currentChecked.isSingleCheckbox) {
      return currentChecked.value;
    }

    return currentCheckedVale.length > 0 && currentCheckedVale.length === values.value.length;
  }
  public handleCheckAllChange(value: any, key: string) {
    const model = this.data.find((e) => e.key === key);
    if (model.isSingleCheckbox) {
      if (value) {
        this.newFilter.push({
          ...model,
          value
        });
        this.deleted = this.deleted.filter((e) => e.key !== key);
      } else {
        this.deleted.push(model);
        this.newFilter = this.newFilter.filter((e) => e.key !== key);
      }

      return;
    }
    model.value.map((e) => this.handleCheck(value, key, e.value));
  }
  public handleCheck(valueChecked: boolean, key: string, value: string) {
    const newFilterIndex = findIndex(this.newFilter, (e) => e.key === key),
      newFilterValue = newFilterIndex > -1 ? this.newFilter[newFilterIndex].value : [];
    const selectedIndex = findIndex(this.selected, (e) => e.key === key),
      selectedValue = selectedIndex > -1 ? this.selected[selectedIndex].value : [];
    const deletedIndex = findIndex(this.deleted, (e) => e.key === key),
      deletedValue = deletedIndex > -1 ? this.deleted[deletedIndex].value : [];

    if (this.multipleSelect) {
      if (valueChecked) {
        newFilterValue.push(value);
        if (deletedIndex > -1) {
          this.deleted[deletedIndex].value = deletedValue.filter((e) => e !== value);
        }
        if (newFilterIndex > -1) {
          this.newFilter[newFilterIndex].value = newFilterValue;
        } else {
          this.newFilter.push({
            key,
            value: [value]
          });
        }
      } else {
        const currentValue = Array.isArray(newFilterValue) ? newFilterValue.filter((e) => e !== value) : [];
        if (newFilterIndex > -1) {
          this.newFilter[newFilterIndex].value = currentValue;
        }
        if (selectedValue.indexOf(value) > -1) {
          if (deletedIndex > -1) {
            deletedValue.push(value);
            this.deleted[deletedIndex].value = deletedValue;
          } else {
            this.deleted.push({
              key,
              value: [value]
            });
          }
        }

      }

      return;
    }

    if (valueChecked) {
      if (deletedIndex > -1 && deletedValue[0] === value) {
        this.deleted = [];
        this.newFilter = [];
      } else {
        this.newFilter = [{
          key,
          value: [value]
        }];
        this.deleted = this.selected;
      }
    } else if (selectedIndex > -1 && selectedValue[0] === value) {
      this.deleted = [{
        key,
        value: selectedValue
      }];
      this.newFilter = [];
    } else {
      this.newFilter = [];
    }

    return;
  }

  public handleClear() {
    this.newFilter = [];
    this.deleted = this.selected;
  }
  public done() {
    this.$refs.searchTextField.focus();
    this.$emit('change', this.currentChecked);
    const currentChecked = cloneDeep(this.currentChecked);
    let total = 0;
    currentChecked.map((e) => {
      total = total + e.value.length;
    });
    this.total = total ? `(${total})` : null;
    this.isShow = false;
  }
  public hide() {
    this.newFilter = [];
    this.deleted = [];
  }

  public onSearch() {
    this.$emit('onSearch', this.searchText);
  }

  public mounted() {
    let total = 0;
    this.selected.map((e) => {
      total = total + e.value.length;
    });
    this.total = total ? `(${total})` : null;
  }
}
