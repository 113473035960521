import { Subscription } from 'apollo-client/util/Observable';
import { TabPane } from 'element-ui';
import { cloneDeep, isEmpty } from 'lodash';
import moment from 'moment';
import { SystemRoleAlias } from 'root/admin/User/Store/types';
import { gqlClient, IPaginationParams } from 'root/api/graphql/Core';
import { zeroCampaignQuery } from 'root/api/graphql/Query/zeroCampaign';
import { SearchByFilter } from 'root/components/SearchByFilter';
// import { FormatDateFullTime } from 'root/helpers';
import { IAddress, IFilterInput, IUser } from 'root/models';
import { IBreadcrumb, IState, MutationType } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import { NewRaCampaignRouterName, NewRaCampaignTab } from '..';
import { Qualified, QualifiedCode, RequestStatus, RequestStatusCode } from '../Components/NewRACampaignDetail';
import { SubDealerCampaignDetailModel } from '../Components/SubDealerCampaignDetailModel';
import { ActionTypeNewRaCampaign } from '../Store/types';
import './styles.scss';

const enum FilterKey {
  SalesStaff = 'salesStaff',
  SalesPic = 'salesPic',
  SalesPlanning = 'salesPlanning',
  Removed = 'removed',
  SaleManager = 'saleManage'
}
enum NewRaCampaignSearchBy {
  Dealer = 'dealerName',
  DealerCode = 'dealerCode',
  SubDealerName = 'subdealer',
  SubDealerCode = 'subdealerCode',
  ShopName = 'shopName',
  ShopCode = 'shopCode'
}

@Component({
  template: require('./view.html'),
  components: {
    'search-by-filter': SearchByFilter,
    'subs-detail-modal': SubDealerCampaignDetailModel
  },
  computed: {
    ...mapState({
      data: (state: IState) => state.newRACampaign.listQualifiedRequest,
      pagination: (state: IState) => state.newRACampaign.pagination,
      loading: (state: IState) => state.newRACampaign.loading,
      authUser: (state: IState) => state.global.authUser,
    }),
    NewRaCampaignSearchBy() {
      const result: IFilterInput[] = [];
      for (const key in NewRaCampaignSearchBy) {
        // tslint:disable-next-line:early-exit
        if (NewRaCampaignSearchBy.hasOwnProperty(key)) {
          const element = NewRaCampaignSearchBy[key];
          result.push({
            key,
            value: element,
            name: this.$t(element)
          });
        }
      }

      return [{
        key: 'select',
        name: this.$t('select'),
        value: result
      }];
    },
    selectedFieldSearchBy() {
      const filterParams = cloneDeep(this.modelSearchBy);

      return [{
        key: 'select',
        value: filterParams ? filterParams : []
      }];
    },
  },
})

export class ClassifiedRequest extends Vue {
  public get activeTab() {
    return NewRaCampaignTab.Classified;
  }

  public set activeTab(_value: string) {
    //
  }
  public get currentRole() {
    return this.authUser.role.alias;
  }
  public get isSalesStaff() {
    if (this.authUser.role.alias === SystemRoleAlias.SaleStaff) {
      return true;
    }

    return false;
  }
  public get isSalePlanning() {
    if (this.authUser.superAdmin || this.authUser.role.alias === SystemRoleAlias.Admin
      || this.authUser.role.alias === SystemRoleAlias.SaleAdmin) {
      return true;
    }

    return false;
  }

  public get columns() {
    return [
      {
        prop: 'isRemove',
        label: '',
        width: 30,
      },
      {
        prop: 'review at',
        label: 'Sales Staff last review at',
        width: 150,
      },
      {
        prop: 'review content',
        label: 'Sales Staff last review content',
        formatter: (model: any) => {
          return this.formatterContent(model);
        },
        width: 260,
      },
      {
        prop: 'sub-dealer',
        label: 'Sub-Dealer Name',
        labelItem: 'Sub-Dealer Code',
        formatter: (model: any) => {
          if (model && model.dealerView && model.dealerView.role.alias === 'sub_dealer') {
            return model.dealerView && model.dealerView.name ? model.dealerView.name : '--';
          }

          return '--';
        },
        description: {
          formatter: (model: any) => {
            if (model && model.dealerView && model.dealerView.role.alias === 'sub_dealer') {
              return model.dealerView && model.dealerView.dealerCode ? model.dealerView.dealerCode : '--';
            }

            return '--';
          }
        },
        width: 250,
      },
      {
        prop: 'dealer',
        label: 'Direct Dealer Name',
        labelItem: 'Direct Dealer Code',
        formatter: (model: any) => {
          if (model && model.dealerView && model.dealerView.role.alias === 'daikin_dealer') {
            return model.dealerView && model.dealerView.name ? model.dealerView.name : '--';
          }
          if (model && model.dealerView && model.dealerView.role.alias === 'sub_dealer') {

            return model.dealerView && model.dealerView.assignedSubDealers2023View &&
            model.dealerView.assignedSubDealers2023View.length &&
            model.dealerView.assignedSubDealers2023View[0].dealerView
            && model.dealerView.assignedSubDealers2023View[0].dealerView.name ?
            model.dealerView.assignedSubDealers2023View[0].dealerView.name : '--';
          }

          return '--';
        },
        description: {
          formatter: (model: any) => {
            if (model && model.dealerView && model.dealerView.role.alias === 'daikin_dealer') {
              return model.dealerView && model.dealerView.dealerCode ? model.dealerView.dealerCode : '--';
            }
            if (model && model.dealerView && model.dealerView.role.alias === 'sub_dealer') {
              return model.dealerView && model.dealerView.assignedSubDealers2023View.length &&
              model.dealerView.assignedSubDealers2023View[0].dealerView
              && model.dealerView.assignedSubDealers2023View[0].dealerView.dealerCode
              ? model.dealerView.assignedSubDealers2023View[0].dealerView.dealerCode : '--';
            }

            return '--';
          }
        },
        width: 250,
      },
      {
        prop: 'shop',
        label: 'Shop Name',
        labelItem: 'Shop Code',
        formatter: (model: any) => {
          if (model && model.dealerView && model.dealerView.role.alias === 'daikin_dealer') {
            return model.shop && model.shop.name ? model.shop.name : '--';
          }

          return '--';
        },
        description: {
          formatter: (model: any) => {
            if (model && model.dealerView && model.dealerView.role.alias === 'daikin_dealer') {
              return model.shop && model.shop.shopCode ? model.shop.shopCode : '--';
            }

            return '--';
          }
        },
        width: 220,
      },
      {
        prop: 'shopAddress',
        label: 'Address',
        width: 220,
        formatter: (model: any) => {
          if (model.dealerView.address || model.shop.address) {
            if (model.dealerView && model.dealerView.role.alias === 'sub_dealer') {
              return this.displayAddress(model.dealerView.address) || '--';
            }

            return this.displayAddress(model.shop.address) || '--';
          }

          return '--';
        }
      },
      {
        prop: 'spiralReview',
        label: 'Spiral Review Result',
        formatter: (model: any) => {
          if (model && model.dealerView && model.dealerView.role && model.dealerView.role.alias === 'sub_dealer') {
            return model && model.dealerView && model.dealerView.aclSpiralReviews &&
          model.dealerView.aclSpiralReviews[0] && model.dealerView.aclSpiralReviews[0].totalPoint ?
          model.dealerView.aclSpiralReviews[0].totalPoint : '--';
          }

          return model && model.shop && model.shop.aclSpiralReviews &&
          model.shop.aclSpiralReviews[0] && model.shop.aclSpiralReviews[0].totalPoint ?
          model.shop.aclSpiralReviews[0].totalPoint : '--';
        },
        // labelStyle: this.formatterReviewStatus,
        width: 120,
      },
      {
        prop: 'BPStatus',
        label: 'Sales Staff Review Result',
        formatter: this.formatterClassification,
        labelStyle: this.formatterReviewStatus,
        width: 120,
      },
      {
        prop: 'SDPStatus',
        label: 'Sales PIC Review Result',
        formatter: this.formatterSalePicReview,
        labelStyle: this.formatterSalePicLabel,
        width: 120,
      },
      {
        prop: 'SMStatus',
        label: 'Sales Manager Review Result',
        formatter: this.formatterSaleManageClassification,
        labelStyle: this.formatterSaleManageReviewStatus,
        width: 120,
      },
      {
        prop: 'SPStatus',
        label: 'Sale Planning Review Result',
        width: 120,
        formatter: this.formatterSalePlanningStatus,
        labelStyle: this.formatterStyleStatus,
      },
      {
        prop: 'paymentDocument',
        label: 'Payment Document',
        width: 120,
        formatter: (model) => {
          if (model && model.dealerView && model.dealerView.role && model.dealerView.role.alias === 'sub_dealer') {
            return model && model.dealerView && model.dealerView.information2023 && model.dealerView.information2023
            && model.dealerView.information2023.productReceiptPicURLs &&
            model.dealerView.information2023.productReceiptPicURLs.length ? 'Yes' : 'No';
          }

          return model && model.dealerView && model.dealerView.information2023 && model.dealerView.information2023
          && (
            model.dealerView.information2023.requestedRewardReceiptURLs &&
          model.dealerView.information2023.requestedRewardReceiptURLs.length ||
          model.dealerView.information2023.data
          ) ? 'Yes' : 'No';
        },
        labelStyle: (model) => {
          if (model && model.dealerView && model.dealerView.role && model.dealerView.role.alias === 'sub_dealer') {
            return model && model.dealerView && model.dealerView.information2023 && model.dealerView.information2023
            && model.dealerView.information2023.productReceiptPicURLs &&
            model.dealerView.information2023.productReceiptPicURLs.length ? 'activated' : 'non_qualified';
          }

          return model && model.dealerView && model.dealerView.information2023 && model.dealerView.information2023
          && (
            model.dealerView.information2023.requestedRewardReceiptURLs &&
          model.dealerView.information2023.requestedRewardReceiptURLs.length ||
          model.dealerView.information2023.data
          ) ? 'activated' : 'non_qualified';
        },
      },
    ];
  }
  public get dataFilter() {
    const classification: IFilterInput[] = [
      // {
      //   key: 'not_review',
      //   value: 'not_review',
      //   name: 'Not Review',
      // },
      {
        key: QualifiedCode.Qualified,
        value: QualifiedCode.Qualified,
        name: Qualified.Qualified,
      },
      {
        key: QualifiedCode.NonQualified,
        value: QualifiedCode.NonQualified,
        name: Qualified.NonQualified,
      },
    ];
    const salePicReview: IFilterInput[] = [
      {
        key: 'not_review',
        value: 'not_review',
        name: 'Not Review',
      },
      {
        key: QualifiedCode.Qualified,
        value: QualifiedCode.Qualified,
        name: Qualified.Qualified,
      },
      {
        key: QualifiedCode.NonQualified,
        value: QualifiedCode.NonQualified,
        name: Qualified.NonQualified,
      },
    ];
    const salesPlanning: IFilterInput[] = [
      {
        key: 'not_review',
        value: 'not_review',
        name: 'Not Review',
      },
      {
        key: 'approved',
        value: 'approved',
        name: 'Approved',
      },
      {
        key: 'rejected',
        value: 'rejected',
        name: 'Rejected',
      },
    ];
    const removed: IFilterInput[] = [
      {
        key: 'true',
        value: 'true',
        name: 'Yes',
      },
      {
        key: 'false',
        value: 'false',
        name: 'No',
      },
    ];
    const salemanagerReview: IFilterInput[] = [
      {
        key: 'not_review',
        value: 'not_review',
        name: 'Not Review',
      },
      {
        key: QualifiedCode.Qualified,
        value: QualifiedCode.Qualified,
        name: Qualified.Qualified,
      },
      {
        key: QualifiedCode.NonQualified,
        value: QualifiedCode.NonQualified,
        name: Qualified.NonQualified,
      },
    ];

    return [
      {
        key: FilterKey.SalesStaff,
        name: 'Sale Staff Review Result',
        value: classification
      },
      {
        key: FilterKey.SalesPic,
        name: 'Sale PIC Review Result',
        value: salePicReview
      },
      {
        key: FilterKey.SalesPlanning,
        name: 'Sale Planning Review Result',
        value: salesPlanning
      },
      {
        key: FilterKey.SaleManager,
        name: 'Sales Manager Review Result',
        value: salemanagerReview
      },
      {
        key: FilterKey.Removed,
        name: 'Remove By Sales Manager',
        value: removed
      },
    ];
  }
  public get selectedFilter() {
    const filterParams = cloneDeep(this.filterParams);

    return [
      {
        key: FilterKey.SalesStaff,
        value: filterParams[FilterKey.SalesStaff]
      },
      {
        key: FilterKey.SalesPic,
        value: filterParams[FilterKey.SalesPic]
      },
      {
        key: FilterKey.SalesPlanning,
        value: filterParams[FilterKey.SalesPlanning]
      },
      {
        key: FilterKey.Removed,
        value: filterParams[FilterKey.Removed]
      },
      {
        key: FilterKey.SaleManager,
        value: filterParams[FilterKey.SaleManager]
      },
    ];
  }
  public authUser: IUser;
  public pagination: IPaginationParams;
  public SystemRoleAlias = SystemRoleAlias;
  public modelSearchBy: any[] = [];
  public loading;
  public loadingExport: boolean = false;
  public subscribeURL: Subscription = null;
  public visibleSubDetail: boolean = false;
  public subId: string = '';
  public visibleDetail: boolean = false;
  public shopId: string = '';
  public searchText: string = '';
  public filterParams = {
    [FilterKey.SalesStaff]: [],
    [FilterKey.SalesPic]: [],
    [FilterKey.SalesPlanning]: [],
    [FilterKey.Removed]: [],
    [FilterKey.SaleManager]: [],
  };
  public changeTab(tab: TabPane) {
    switch (tab.name) {
    case NewRaCampaignTab.Participant:
      this.$router.push({
        name: NewRaCampaignRouterName.Participant
      });
      break;
    case NewRaCampaignTab.Shop:
      this.$router.push({
        name: NewRaCampaignRouterName.Shop
      });
      break;
    case NewRaCampaignTab.SubDealer:
      this.$router.push({
        name: NewRaCampaignRouterName.SubDealer
      });
      break;
    case NewRaCampaignTab.Registration:
      this.$router.push({
        name: NewRaCampaignRouterName.Registration
      });
      break;
    case NewRaCampaignTab.Config:
      this.$router.push({
        name: NewRaCampaignRouterName.Config
      });
      break;
    case NewRaCampaignTab.Statistic:
      this.$router.push({
        name: NewRaCampaignRouterName.Statistic
      });
      break;
    case NewRaCampaignTab.RequestList:
      this.$router.push({
        name: NewRaCampaignRouterName.RequestList
      });
      break;
    case NewRaCampaignTab.Classified:
      this.$router.push({
        name: NewRaCampaignRouterName.Classified
      });
      break;
    default:
      break;
    }
  }
  public formatterClassification(model: any) {
    if (model.classification) {
      switch (model.classification) {
      case QualifiedCode.Qualified:
        return Qualified.Qualified;
      case QualifiedCode.NonQualified:
        return Qualified.NonQualified;
      }
    }

    return 'Not Review';
  }
  public formatterSalePicReview(model: any) {
    if (model.salePicReview) {
      switch (model.salePicReview) {
      case QualifiedCode.Qualified:
        return Qualified.Qualified;
      case QualifiedCode.NonQualified:
        return Qualified.NonQualified;
      }
    }

    return 'Not Review';
  }
  public formatterReviewStatus(model: any) {
    if (model.classification) {
      switch (model.classification) {
      case QualifiedCode.Qualified:
        return `newRACampaign__${QualifiedCode.Qualified}`;
      case QualifiedCode.NonQualified:
        return `newRACampaign__${QualifiedCode.NonQualified}`;
      }
    }

    return 'not_review';
  }
  public formatterSalePicLabel(model: any) {
    if (model.salePicReview) {
      switch (model.salePicReview) {
      case QualifiedCode.Qualified:
        return `newRACampaign__${QualifiedCode.Qualified}`;
      case QualifiedCode.NonQualified:
        return `newRACampaign__${QualifiedCode.NonQualified}`;
      }
    }

    return 'not_review';
  }
  public changeFilter(model) {
    const salesStaff = model.find((e) => e.key === FilterKey.SalesStaff);
    const salePic = model.find((e) => e.key === FilterKey.SalesPic);
    const salePlanning = model.find((e) => e.key === FilterKey.SalesPlanning);
    const remove = model.find((e) => e.key === FilterKey.Removed);
    const saleManager = model.find((e) => e.key === FilterKey.SaleManager);
    const nextFilter = {
      [FilterKey.SalesStaff]: salesStaff ? salesStaff.value : [],
      [FilterKey.SalesPic]: salePic ? salePic.value : [],
      [FilterKey.SalesPlanning]: salePlanning ? salePlanning.value : [],
      [FilterKey.Removed]: remove ? remove.value : [],
      [FilterKey.SaleManager]: saleManager ? saleManager.value : [],
    };
    this.filterParams = {
      ...cloneDeep(this.filterParams),
      ...nextFilter
    };
    this.fetchData();
  }
  public formatterSalePlanningStatus(model: any) {
    if (model) {
      switch (model.requestStatus) {
      case RequestStatusCode.Approved:
        return RequestStatus.Approved;
      case RequestStatusCode.Rejected:
        return RequestStatus.Rejected;
      default:
        return 'Not Review';
      }
    }

    return 'Not Review';
  }
  public formatterSaleManageClassification(model: any) {
    if (model.saleManagerReview) {
      switch (model.saleManagerReview) {
      case QualifiedCode.Qualified:
        return Qualified.Qualified;
      case QualifiedCode.NonQualified:
        return Qualified.NonQualified;
      }
    }

    return 'Not Review';
  }
  public formatterSaleManageReviewStatus(model: any) {
    if (model.saleManagerReview) {
      switch (model.saleManagerReview) {
      case QualifiedCode.Qualified:
        return `newRACampaign__${QualifiedCode.Qualified}`;
      case QualifiedCode.NonQualified:
        return `newRACampaign__${QualifiedCode.NonQualified}`;
      }
    }

    return 'not_review';
  }
  public formatterStyleStatus(model: any) {
    if (model.requestStatus) {
      switch (model.requestStatus) {
      case RequestStatusCode.Approved:
        return RequestStatusCode.Approved;
      case RequestStatusCode.Rejected:
        return RequestStatusCode.Rejected;
      default:
        return 'not_review';
      }
    }

    return 'not_review';
  }
  public changeSearchBy(model: IFilterInput[]) {
    this.modelSearchBy = model[0].value;

    this.fetchData();
  }
  public checkModelSearchBy(model) {
    return this.modelSearchBy.indexOf(model) >= 0;
  }
  public formatterTime(model: any) {
    if (model && model.reviews.length) {
      const last = model.reviews.length - 1;

      return moment(model.reviews[last].updatedAt).format('HH:mm - DD/MM/YYYY');
    }

    return '--';
  }
  public handleClickDetail(id) {
    const user = this.$store.getters.getClassifiedRequestDetail(id);
    if (user && user.dealerView && user.dealerView.role && user.dealerView.role.alias) {
      switch (user.dealerView.role.alias) {
      case 'sub_dealer':
        this.subId = user.dealerView.id;
        this.visibleSubDetail = true;
        break;
      case 'daikin_dealer':
        this.shopId = user.shop.id;
        this.visibleDetail = true;
        break;
      }
    }

    return;
  }
  public closeDialogSubDetail() {
    this.subId = '';
    this.visibleSubDetail = false;
  }
  public closeDialogDetail() {
    this.shopId = '';
    this.visibleDetail = false;
  }
  public formatterContent(model: any) {
    if (model && model.reviews.length) {
      const last = model.reviews.length - 1;

      return model.reviews[last].description;
    }

    return '--';
  }
  public displayAddress(model: IAddress) {
    return model && model.province
      ? this.$store.getters['getAddressString'](model)
      : '';
  }
  public handleExport() {
    this.loadingExport = true;
    const text = cloneDeep(this.searchText);
    const filterParams = <any> {};
    const search = {
      ...this.checkModelSearchBy(NewRaCampaignSearchBy.Dealer) ? {
        dealerName: text
      } : {},
      ...this.checkModelSearchBy(NewRaCampaignSearchBy.DealerCode) ? {
        dealerCode: text
      } : {},
      ...this.checkModelSearchBy(NewRaCampaignSearchBy.SubDealerName) ? {
        subDealerName: text
      } : {},
      ...this.checkModelSearchBy(NewRaCampaignSearchBy.SubDealerCode) ? {
        subDealerCode: text
      } : {},
      ...this.checkModelSearchBy(NewRaCampaignSearchBy.ShopName) ? {
        shopName: text
      } : {},
      ...this.checkModelSearchBy(NewRaCampaignSearchBy.ShopCode) ? {
        shopCode: text
      } : {},
    };
    if (this.filterParams[FilterKey.SalesStaff].length) {
      filterParams.salesStaff = this.filterParams[FilterKey.SalesStaff];
    }
    if (this.filterParams[FilterKey.SalesPic].length) {
      filterParams.salesPic = this.filterParams[FilterKey.SalesPic];
    }
    if (this.filterParams[FilterKey.SaleManager].length) {
      filterParams.saleManage = this.filterParams[FilterKey.SaleManager];
    }
    if (this.filterParams[FilterKey.SalesPlanning].length) {
      filterParams.salesPlanning = this.filterParams[FilterKey.SalesPlanning];
    }
    if (this.filterParams[FilterKey.Removed].length) {
      filterParams.remove = this.filterParams[FilterKey.Removed];
    }
    const command = 'classified_request';
    this.$store.dispatch(ActionTypeNewRaCampaign.ExportListRegistration, {
      form: {
        isReIndex: true,
        type: command,
        filter: {
          search: search && !isEmpty(search) ? search : null,
          saleStaffReview: filterParams.salesStaff && filterParams.salesStaff.length ?
          filterParams.salesStaff : ['qualified', 'non_qualified'],
          salePicReview: filterParams.salesPic && filterParams.salesPic.length ? filterParams.salesPic : null,
          saleManagerReview: filterParams.saleManage && filterParams.saleManage.length ?
          filterParams.saleManage : null,
          salePlanningReview: filterParams.salesPlanning && filterParams.salesPlanning.length ?
          filterParams.salesPlanning : null,
          removeBySaleManager: filterParams.remove && filterParams.remove.length ? filterParams.remove : null,
        }
      },
      onSuccess: (url) => {
    // tslint:disable-next-line: early-exit
        if (url.returning && url.returning.length) {
          this.subscribeURL = <any> gqlClient.subscribe({
        query: zeroCampaignQuery.DOWNLOAD_EXPORT,
        variables : {id: url.returning[0].id},
        fetchPolicy: 'cache-first'
      }).subscribe((results) => {
        // tslint:disable-next-line: early-exit
        if (results.data && results.data.exports && results.data.exports.length
          && results.data.exports[0].exportStatus === 'done') {
          window.open(results.data.exports[0].url, '_blank');
          this.subscribeURL.unsubscribe();
          this.loadingExport = false;
        }
      });
        }
      }
    });
  }
  public onEnterFilter(value) {
    this.searchText = value;

    this.fetchData();
  }
  public handlePaginationChange(value) {
    if (typeof value === 'number') {
      this.pagination = {
        ...this.pagination,
        page: value
      };
    } else {
      this.pagination = {
        ...this.pagination,
        page: value
      };
    }
    this.fetchData();
  }
  protected mounted() {
    this.$nextTick(() => {
      const breadcrumb: IBreadcrumb[] = [
        {
          label: 'title.new_ra_campaign',
          path: null,
          current: false
        },
        {
          label: 'title.new_ra_campaign_participant',
          path: null,
          current: true
        }
      ];
      this.$store.commit(MutationType.SetBreadcrumb, breadcrumb);

      this.fetchData();
    });
  }
  private fetchData() {
    const sales = null;
    const text = cloneDeep(this.searchText);
    const filterParams = <any> {};
    const search = [
      {
        ...this.checkModelSearchBy(NewRaCampaignSearchBy.Dealer) ? {
          dealer: {
            name: {
              _ilike: `%${text}%`
            }
          }
        } : {},
      },
      {
        ...this.checkModelSearchBy(NewRaCampaignSearchBy.DealerCode) ? {
          dealer: {
            dealerCode: {
              _ilike: `%${text}%`
            }
          }
        } : {},
      },
      {
        ...this.checkModelSearchBy(NewRaCampaignSearchBy.SubDealerName) ? {
          dealer: {
            name: {
              _ilike: `%${text}%`
            }
          }
        } : {},
      },
      {
        ...this.checkModelSearchBy(NewRaCampaignSearchBy.SubDealerCode) ? {
          dealer: {
            dealerCode: {
              _ilike: `%${text}%`
            }
          }
        } : {},
      },
      {
        ...this.checkModelSearchBy(NewRaCampaignSearchBy.ShopName) ? {
          shop: {
            name: {
              _ilike: `%${text}%`
            }
          }
        } : {},
      },
      {
        ...this.checkModelSearchBy(NewRaCampaignSearchBy.ShopCode) ? {
          shop: {
            shopCode: {
              _ilike: `%${text}%`
            }
          }
        } : {},
      }
    ].filter((e) => !isEmpty(e));
    if (this.filterParams[FilterKey.SalesStaff].length) {
      filterParams.salesStaff = this.filterParams[FilterKey.SalesStaff];
    }
    if (this.filterParams[FilterKey.SalesPic].length) {
      filterParams.salesPic = this.filterParams[FilterKey.SalesPic];
    }
    if (this.filterParams[FilterKey.SalesPlanning].length) {
      filterParams.salesPlanning = this.filterParams[FilterKey.SalesPlanning];
    }
    if (this.filterParams[FilterKey.Removed].length) {
      filterParams.remove = this.filterParams[FilterKey.Removed];
    }
    if (this.filterParams[FilterKey.SaleManager].length) {
      filterParams.saleManager = this.filterParams[FilterKey.SaleManager];
    }
    this.$store.dispatch(ActionTypeNewRaCampaign.GetQualifiedRequest, {
      sales: sales && sales.length ? sales : null,
      pagination: this.pagination,
      salesStaff: filterParams.salesStaff && filterParams.salesStaff.length ?
      filterParams.salesStaff : ['qualified', 'non_qualified'],
      salesPic: filterParams.salesPic && filterParams.salesPic.length ? filterParams.salesPic : null,
      saleManager: filterParams.saleManager && filterParams.saleManager.length ? filterParams.saleManager : null,
      salesPlanning: filterParams.salesPlanning && filterParams.salesPlanning.length ?
      filterParams.salesPlanning : null,
      remove: filterParams.remove && filterParams.remove.length ? filterParams.remove : null,
      search: search && search.length ? search : [{}]
    });
  }
}
