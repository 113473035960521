import moment from 'moment';
import { SystemRoleAlias } from 'root/admin/User/Store/types';
import { gqlClient } from 'root/api/graphql/Core';
import { aclCampaignQuery } from 'root/api/graphql/Query/aclCampaign';
import { Avatar } from 'root/components/Avatar';
import { ruleRequired, FormatDateFullTime } from 'root/helpers';
import { IAddress, IUser } from 'root/models';
import { IState } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import { ActionTypeAclCampaign } from '../../Store/types';
import { ContractStatus, Qualified, QualifiedCode,
   RequestAction, RequestStatus, RequestStatusCode } from '../CampaignDetail';
import './styles.scss';
@Component({
  template: require('./view.html'),
  props: {
    visible: Boolean,
    detailId: String
  },
  components: {
    avatar: Avatar
  },
  computed: {
    ...mapState({
      // userDetail: (state: IState) => state.user.userDetail,
      authUser: (state: IState) => state.global.authUser,
    })
  }
})
export class CampaignDetailModel extends Vue {
  public FormatDateFullTime = FormatDateFullTime;
  public userDetail = null;
  public detailId: string;
  public review: string = '';
  public authUser: IUser;
  public visibleRequest: boolean = false;
  public visibleUpdateModel: boolean = false;
  public titleRequest: string = '';
  public messageRequest: string = '';
  public reason: string = '';
  public loading: boolean = false;
  public loadingButton: boolean = false;
  public loadingBtn: boolean = false;
  public PackageOption: any[] = [];
  public package: any[] = [];
  public packages: any[] = [];
  public showPackage: any[] = [];
  public modelValue: any[] = [];
  public actionHistory: any[] = [];
  public qualifyvalue: string = '';
  public isErrorUpdateModel: boolean = false;
  public isErrorQualify: boolean = false;
  public isErrorReview: boolean = false;
  public isBranchReview: boolean = false;
  public isDealerReup: boolean = true;
  public isOnCampaign: boolean = false;
  public isEndedCampaign: boolean = false;
  public img: string = '';
  public visibleMedia: boolean = false;
  public currentIndex: number = 0;
  public imageList: any[] = [];
  public get qualifyOption() {
    return [
      {
        label: 'Qualified',
        value: 'qualified',
      },
      {
        label: 'Non Qualified',
        value: 'non_qualified',
      }
    ];
  }
  public get rules() {
    return {
      required: ruleRequired(),
    };
  }
  public get currentImg() {
    return this.imageList[Math.abs(this.currentIndex) % this.imageList.length];
  }
  public get checkCanRequestUpdate() {
    if (this.userDetail && this.userDetail.acl_campaigns.length &&
       this.userDetail.acl_campaigns[0].requestStatus === 'waiting_for_review') {
      return true;
    }

    return false;
  }
  public get checkCanApprove() {
    if (this.userDetail && this.userDetail.acl_campaigns.length &&
      this.userDetail.acl_campaigns[0].requestStatus === RequestStatusCode.WaitingForReview ||
      this.userDetail && this.userDetail.acl_campaigns.length &&
      this.userDetail.acl_campaigns[0].requestStatus === RequestStatusCode.WaitingForReup) {

      return true;
    }

    return false;
  }
  public get isSuperAdmin() {
    if (this.authUser.superAdmin) {
      return true;
    }

    return false;
  }
  public get isSalePlanning() {
    if (this.authUser.role.alias === SystemRoleAlias.SaleAdmin) {
      return true;
    }

    return false;
  }
  public get isBranchPic() {
    if (this.authUser.role.alias === SystemRoleAlias.BranchManager) {
      return true;
    }

    return false;
  }
  public get isDepartmentPic() {
    if (this.authUser.role.alias === SystemRoleAlias.GroupLeader) {
      return true;
    }

    return false;
  }
  public checkModel(a, b) {
    if (b.indexOf(a.value) >= 0) {
      return true;
    }

    return false;
  }
  public displayAddress(model: IAddress) {
    return model && model.province ? this.$store.getters['getAddressString'](model) : '';
  }
  public formaterStatus(model: any) {
    switch (model) {
    case RequestStatusCode.NotJoin:
      return RequestStatus.NotJoin;
    case RequestStatusCode.WaitingForReup:
      return RequestStatus.WaitingForReup;
    case RequestStatusCode.WaitingForReview:
      return RequestStatus.WaitingForReview;
    case RequestStatusCode.Approved:
      return RequestStatus.Approved;
    case RequestStatusCode.Rejected:
      return RequestStatus.Rejected;
    }
  }

  public submitReview() {
    if (!this.qualifyvalue) {
      this.isErrorQualify = true;

      return;
    }
    this.isErrorQualify = false;
    let _qualified = '';
    switch (this.qualifyvalue) {
    case Qualified.Qualified:
      _qualified = QualifiedCode.Qualified;
      break;
    case Qualified.NonQualified:
      _qualified = QualifiedCode.NonQualified;
      break;
    }
    // tslint:disable-next-line: early-exit
    if (this.qualifyvalue === Qualified.NonQualified && !this.review) {
      this.isErrorReview = true;

      return;
    }
    this.isErrorReview = false;
    this.$store.dispatch(ActionTypeAclCampaign.SubmitReview, {
      id: this.userDetail.acl_campaigns[0].id,
      review: this.review,
      qualify: _qualified,
      createdBy: this.authUser.id,
      onSuccess: () => {
        this.qualifyvalue = '';
        this.review = '';
        this.$message({
          type: 'success',
          message: this.$t('message.submitSuccess').toString()
        });
        this.fetchData();
      }
    });
  }
  public formatterClassification(model: any) {
    switch (model) {
    case QualifiedCode.Qualified:
      return Qualified.Qualified;
    case QualifiedCode.NonQualified:
      return Qualified.NonQualified;
    }
  }
  public formatterAction(action) {
    switch (action) {
    case RequestStatusCode.Approved:
      return RequestAction.Approved;
    case RequestStatusCode.Rejected:
      return RequestAction.Rejected;
    }
  }
  public accept(actionRequest) {
    this.$msgbox({
      title: actionRequest === RequestStatusCode.Approved ?
        this.$t('approve_confirm').toString() : this.$t('reject_confirm').toString(),
      message: `Do you want to ${this.formatterAction(actionRequest)} campaign request for shop?`,
      showCancelButton: true,
      customClass: actionRequest === RequestStatusCode.Rejected ? 'message-error' : '',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      beforeClose: (action, instance, done) => {
        if (action === 'confirm') {
          instance.confirmButtonLoading = true;
          instance.confirmButtonText = 'Loading...';
          this.handleApprove({ instance, done, actionRequest });
        } else {
          done();
        }

        return;
      }
    })
        .then(() => {
          this.$message({
            type: 'success',
            message: actionRequest === RequestStatusCode.Approved ?
              this.$t('message.approveSuccess').toString() : this.$t('message.rejectSuccess').toString()
          });
        })
        .catch(() => {
          // no handle
        });
  }

  public handleApprove(model) {
    this.$store.dispatch(ActionTypeAclCampaign.ApproveShop, {
      ids: [this.userDetail.acl_campaigns[0].id],
      action: model.actionRequest,
      updatedBy: this.authUser.id,
      onSuccess: () => {
        this.fetchData();
        model.instance.confirmButtonLoading = false;
        model.instance.confirmButtonText = 'Yes';
        model.done();
      },
      onFailure: () => {
        model.instance.confirmButtonLoading = false;
        model.instance.confirmButtonText = 'No';
      }
    });
  }
  public handleReject(action) {
    this.$store.dispatch(ActionTypeAclCampaign.ApproveShop, {
      action,
      ids: [this.userDetail.acl_campaigns[0].id],
      reason: this.reason,
      updatedBy: this.authUser.id,
      onSuccess: () => {
        this.$message({
          type: 'success',
          message: this.$t('message.rejectSuccess').toString()
        });
        this.loadingBtn = false;
        this.fetchData();
        this.closeDialogRequest();
      },
      onFailure: () => {
        // nohandle
      }
    });
  }
  public handleRequestReup(action) {
    this.$store.dispatch(ActionTypeAclCampaign.RequestReup, {
      action,
      ids: [this.userDetail.acl_campaigns[0].id],
      reason: this.reason,
      updatedBy: this.authUser.id,
      onSuccess: () => {
        this.$message({
          type: 'success',
          message: this.$t('message.requestUpdate').toString()
        });
        this.loadingBtn = false;
        this.fetchData();
        this.closeDialogRequest();
      },
      onFailure: () => {
        // nohandle
      }
    });
  }
  public fetchData() {
    this.loading = true;
    this.$store.dispatch(ActionTypeAclCampaign.GetShopDetail, {
      id: this.detailId,
      onSuccess: (model) => {
        this.loading = false;
        this.userDetail = model.shops[0];
        this.showPackage = [];
        this.imageList = [];
        if (model && model.shops[0].acl_campaigns.length && model.shops[0].acl_campaigns[0].pictureURLs) {
          // tslint:disable-next-line: forin
          for (const picture in this.userDetail.acl_campaigns[0].pictureURLs) {
            this.imageList.push(this.userDetail.acl_campaigns[0].pictureURLs[picture]);
          }
        }
        // tslint:disable-next-line: early-exit
        if (model && model.shops[0].acl_campaigns.length) {
          this.isDealerReup = this.userDetail.acl_campaigns[0].isDealerReUp;
          this.isBranchReview = this.userDetail.acl_campaigns[0].isBranchReview;
          this.userDetail.acl_campaigns[0].packages.map((item) => {
            this.showPackage.push(this.packages[item - 1]);
          });
          // tslint:disable-next-line: no-unused
          const getActionHistory = <any> gqlClient.subscribe({
            query: aclCampaignQuery.GET_ACTION_HISTORY,
            variables: {id: this.userDetail.acl_campaigns[0].id},
            fetchPolicy: 'cache-first'
          }).subscribe((result) => {
            if (result.data) {
              this.actionHistory = result.data.acl_campaign_status_logs;
            }
          }, (error) => {
            console.error('Get action history failed', error);
          });
        }
      }
    });
  }
  public async openDialog() {
    this.$store.dispatch(ActionTypeAclCampaign.GetCampaignConfiguration, {
      onSuccess: (aclConfig) => {
        if (moment(aclConfig[0].aclCampaignConfig.uploadStartDate).isBefore(moment()) &&
          moment(aclConfig[0].aclCampaignConfig.uploadEndDate).isAfter(moment())) {
          this.isOnCampaign = true;
        }
      }
    });
    this.$store.dispatch(ActionTypeAclCampaign.GetPackage, {
      onSuccess: (aclPackage) => {
        this.packages = [];
        this.PackageOption = [];
        this.isEndedCampaign = aclPackage[0].isEnded;
        // tslint:disable-next-line: forin
        for (const model in aclPackage[0].metadata.models) {
          this.PackageOption.push({
            value: parseInt(model, null),
            label: aclPackage[0].metadata.models[parseInt(model, null)]['name']
          });
          this.packages.push(aclPackage[0].metadata.models[parseInt(model, null)]['name']);
        }
      }
    });
    this.fetchData();
  }
  public formatterStaff(model: any) {
    return model.dealerShops.filter(
      (item) => !item.isOwner && item.contractStatus === ContractStatus.Accepted
    ).length;
  }
  public closeDialogRequest() {
    // change after
    this.visibleRequest = false;
    this.titleRequest = '';
    this.messageRequest = '';
    this.reason = '';
  }
  public closeDialogUpdate() {
    // change after
    // this.visibleRequest = false;
    // this.titleRequest = '';
    // this.messageRequest = '';
    // this.reason = '';
    this.visibleUpdateModel = false;
    // this.modelValue = [];
  }
  public requestUpdate(action) {
    if (action === RequestAction.Rejected) {
      this.titleRequest = 'Reject Request';
      this.messageRequest = `Do you want to reject campaign request for ${this.userDetail.name}`;
      this.visibleRequest = true;

      return;
    }

    this.titleRequest = 'Request Update';
    this.messageRequest = `Do you want to request update new images for ${this.userDetail.name}`;
    this.visibleRequest = true;
  }
  public reject() {
    this.loadingBtn = true;
    if (this.titleRequest === 'Reject Request') {
      this.handleReject('rejected');

      return;
    }
    this.handleRequestReup('request_re_up');
  }
  public handleUpdate() {
    this.visibleUpdateModel = true;
  }
  public updateModel() {
    this.loadingButton = true;
    const _index = [];
    this.modelValue.map((item) => {
      _index.push(this.packages.indexOf(item) + 1);
    });
    if (_index.length < 2) {
      this.loadingButton = false;
      this.isErrorUpdateModel = true;

      return;
    }
    this.$store.dispatch(ActionTypeAclCampaign.UpdatePackage, {
      id: this.userDetail.acl_campaigns[0].id,
      models: _index,
      onSuccess: () => {
        this.fetchData();
        this.$message({
          type: 'success',
          message: this.$t('message.updatePackage').toString()
        });
        this.loadingButton = false;
        this.closeDialogUpdate();
        this.isErrorUpdateModel = false;
      }
    });
  }

  public closeDialog() {
    this.$emit('closeDialog');
  }
  public clickViewImage(index: number) {
    this.currentIndex = index - 1;
    this.visibleMedia = true;
  }
  public closeDialogMedia() {
    this.visibleMedia = false;
  }
  public prev() {
    this.currentIndex -= 1;
  }
  public next() {
    this.currentIndex += 1;
  }
}
