import { StatusCode } from './Common';

export interface IVoucherForm {
  name: string;
  description?: string;
  code?: any;
  type: string;
  maxValue?: number;
  minBilling: number;
  quantity: number;
  startDate: string;
  expiryDate: string;
  valueType: string;
  value?: any;
  serviceIds: string[];
  deviceTypeIds: string[];
  locations?: string[];
  scheduledDates: number[];
  maxUseCount?: number;
}
export interface IVoucher extends IVoucherForm {
  id?: string;
  status?: StatusCode;
  createdAt?: string;
  updatedAt?: string;
  createdBy?: string;
  updatedBy?: string;
}
export function voucherDefault(): IVoucher {
  return {
    deviceTypeIds: [],
    expiryDate: '',
    maxValue: 0,
    minBilling: 0,
    name: '',
    quantity: 0,
    serviceIds: [],
    startDate: '',
    type: '',
    valueType: '',
    code: '',
    description: '',
    value: '',
    locations: [],
    scheduledDates: [],
    maxUseCount: null
  };
}

export enum VoucherStatus {
  Upcoming = 'upcoming',
  Inprogress = 'inprogress',
  Expired = 'expired',
  Cancelled = 'cancelled'
}

export enum VoucherCodeStatus {
  Used = 'used',
  Available = 'available',
  Expired = 'expired'
}

export const enum VoucherValueType {
  Percentage = 'percentage',
  FixedValue = 'fixed_value'
}

export interface IVoucherCode {
  id?: string;
  voucherId: string;
  code: string;
  status?: StatusCode;
  createdAt?: string;
  updatedAt?: string;
  createdBy?: string;
  updatedBy?: string;
  logs: any;
  voucher: any;
}

export interface IVoucherLog {
  id?: string;
  voucherCode?: IVoucherCode;
}

export interface ICheckVoucherForm {
  deviceTypeId: string;
  serviceId: string;
  voucherCode: string;
  province: string;
  requestStartTime: string;
}
