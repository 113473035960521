import { TabPane } from 'element-ui';
import { cloneDeep } from 'lodash';
import { CustomCampaignRouterName, CustomCampaignTab } from 'root/admin/CustomCampaign';
import { SystemRoleAlias } from 'root/admin/User/Store/types';
import { IPaginationParams } from 'root/api/graphql/Core';
import { FormatDateFullTime } from 'root/helpers';
import { IFilterInput, IUser } from 'root/models';
import { IBreadcrumb, IState, MutationType, TypeAlert } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import { ActionTypeRaCampaign, MutationTypeRaCampaign } from '../../Store/types';
import { Role } from '../RAParticipant';
import './styles.scss';

const enum FilterKey {
  Status = 'status',
  Type = 'type',
}

const enum StatusCode {
  New = 'new',
  Used = 'used'
}
const enum Status {
  New = 'Available',
  Used = 'Used'
}
const enum TypeCode {
  Pass = 'passed',
  Fail = 'failed'
}
const enum Type {
  Pass = 'Pass',
  Fail = 'Fail'
}

@Component({
  template: require('./view.html'),
  computed: {
    ...mapState({
      data: (state: IState) => state.raCampaign.listConfirmCode,
      pagination: (state: IState) => state.raCampaign.pagination,
      loading: (state: IState) => state.raCampaign.loading,
      authUser: (state: IState) => state.global.authUser,
    })
  },
})

export class ConfirmationCode extends Vue {
  public get activeTab() {
    return CustomCampaignTab.ConfirmationCode;
  }

  public set activeTab(_value: string) {
    //
  }
  public get isSalePlanning() {
    if (this.authUser.superAdmin || this.authUser.role.alias === SystemRoleAlias.Admin
      || this.authUser.role.alias === SystemRoleAlias.SaleAdmin) {
      return true;
    }

    return false;
  }
  public get dataFilter() {
    const _status: IFilterInput[] = [
      {
        key: StatusCode.New,
        value: StatusCode.New,
        name: Status.New,
      },
      {
        key: StatusCode.Used,
        value: StatusCode.Used,
        name: Status.Used,
      },
    ];
    const _type: IFilterInput[] = [
      {
        key: TypeCode.Pass,
        value: TypeCode.Pass,
        name: Type.Pass,
      },
      {
        key: TypeCode.Fail,
        value: TypeCode.Fail,
        name: Type.Fail,
      },
    ];

    return [
      {
        key: FilterKey.Status,
        name: 'Status',
        value: _status
      },
      {
        key: FilterKey.Type,
        name: 'Code Type',
        value: _type
      },
    ];
  }
  public get selectedFilter() {
    const _filterParams = cloneDeep(this.filterParams);

    return [
      {
        key: FilterKey.Status,
        value: _filterParams[FilterKey.Status]
      },
      {
        key: FilterKey.Type,
        value: _filterParams[FilterKey.Type]
      },
    ];
  }

  public get columns() {
    return [
      {
        prop: 'code',
        label: 'Code',
        width: 300,
        formatter: (model: any) => {
          return model.code ? model.code : '--';
        },
      },
      {
        prop: 'createdAt',
        label: 'Created At',
        width: 300,
        formatter: (model: any) => {
          return FormatDateFullTime(model.createdAt) || '--';
        },
      },
      {
        prop: 'updatedAt',
        label: 'Updated At',
        width: 300,
        formatter: (model: any) => {
          return FormatDateFullTime(model.updatedAt) || '--';
        },
      },
      {
        prop: 'dealer',
        label: 'Direct Dealer Name',
        labelItem: 'Direct Dealer Code',
        width: 250,
        fixed: true
      },
      {
        prop: 'sub-dealer',
        label: 'Sub-Dealer Name',
        labelItem: 'Sub-Dealer Code',
        width: 250,
        fixed: true
      },
      {
        prop: 'shop',
        label: 'Shop Name',
        labelItem: 'Shop Code',
        width: 250,
        fixed: true
      },
      {
        prop: 'status',
        label: 'STATUS',
        width: 300,
        formatter: this.formatterStatus,
        labelStyle: (model: any) => {
          return model.codeStatus;
        },
      },
    ];

  }
  public authUser: IUser;
  public pagination: IPaginationParams;
  public searchText: string = '';
  public quantityPassCode: string = '';
  public quantityFailCode: string = '';
  public filterParams = {
    [FilterKey.Status]: [],
    [FilterKey.Type]: [],
  };
  public changeTab(tab: TabPane) {
    switch (tab.name) {
    case CustomCampaignTab.RAParticipant:
      this.$router.push({
        name: CustomCampaignRouterName.RACampaign
      });
      break;
    case CustomCampaignTab.RAConfiguration:
      this.$router.push({
        name: CustomCampaignRouterName.RAConfiguration
      });
      break;
    case CustomCampaignTab.RAStatistic:
      this.$router.push({
        name: CustomCampaignRouterName.RAStatistic
      });
      break;
    case CustomCampaignTab.RAQualifiedShops:
      this.$router.push({
        name: CustomCampaignRouterName.RAQualifiedShops
      });
      break;
    case CustomCampaignTab.RAShops:
      this.$router.push({
        name: CustomCampaignRouterName.RAShops
      });
      break;
    case CustomCampaignTab.SubDealerRequest:
      this.$router.push({
        name: CustomCampaignRouterName.SubDealerRequest
      });
      break;
    case CustomCampaignTab.SubDealerCampaign:
      this.$router.push({
        name: CustomCampaignRouterName.SubDealerCampaign
      });
      break;
    default:
      break;
    }
  }
  public changeFilter(model) {
    const status = model.find((e) => e.key === FilterKey.Status);
    const type = model.find((e) => e.key === FilterKey.Type);
    const nextFilter = {
      [FilterKey.Status]: status ? status.value : [],
      [FilterKey.Type]: type ? type.value : [],
    };
    this.filterParams = {
      ...cloneDeep(this.filterParams),
      ...nextFilter
    };
    this.fetchData();
  }
  public downloadConfirmCode() {
    // this.fetchData();
    this.$store.dispatch(ActionTypeRaCampaign.DownloadConfirmationCode, {
      onSuccess: (url) => {
        window.open(url, '_blank');
      }
    });
  }
  public formatterDirectDealerName(model) {
    if (model.dealer && model.dealer.role && model.dealer.role.alias === Role.DaikinDealer) {
      return model.dealer.name;
    }
    if (model.dealer && model.dealer.role && model.dealer.role.alias === Role.SubDealer) {
      return model.dealer && model.dealer.assignedSubDealers.length && model.dealer.assignedSubDealers[0].dealer
      && model.dealer.assignedSubDealers[0].dealer.name ?
      model.dealer.assignedSubDealers[0].dealer.name : ' --';
    }

    return '--';
  }
  public formatterDirectDealerCode(model) {
    if (model.dealer && model.dealer.role && model.dealer.role.alias === Role.DaikinDealer) {
      return model.dealer.dealerCode;
    }
    if (model.dealer && model.dealer.role && model.dealer.role.alias === Role.SubDealer) {
      return model.dealer && model.dealer.assignedSubDealers.length && model.dealer.assignedSubDealers[0].dealer
      && model.dealer.assignedSubDealers[0].dealer.dealerCode ?
      model.dealer.assignedSubDealers[0].dealer.dealerCode : ' --';
    }

    return '--';

  }
  public formatterSubDealerName(model) {
    if (model.dealer && model.dealer.role && model.dealer.role.alias === Role.SubDealer) {
      return model.dealer.name;
    }

    return '--';
  }
  public formatterSubDealerCode(model) {
    if (model.dealer && model.dealer.role && model.dealer.role.alias === Role.SubDealer) {
      return model.dealer.dealerCode;
    }

    return '--';
  }
  public formatterShopName(model) {
    if (model.appliedCampaign && model.appliedCampaign.shop && model.appliedCampaign.shop.name) {
      return model.appliedCampaign.shop.name;
    }

    return '--';
  }
  public formatterShopCode(model) {
    if (model.appliedCampaign && model.appliedCampaign.shop && model.appliedCampaign.shop.shopCode) {
      return model.appliedCampaign.shop.shopCode;
    }

    return '--';
  }
  public formatterStatus(model: any) {
    if (model.codeStatus) {
      switch (model.codeStatus) {
      case StatusCode.New :
        return Status.New;
      case StatusCode.Used :
        return Status.Used;
      }
    }

    return '--';
  }
  public handleSearch() {
    this.fetchData();
  }
  public handlePaginationChange(value) {
    if (typeof value === 'number') {
      this.$store.commit(MutationTypeRaCampaign.PaginationChange, {
        ...this.pagination,
        size: value
      });
    } else {
      this.$store.commit(MutationTypeRaCampaign.PaginationChange, {
        ...this.pagination,
        ...value
      });
    }
    this.fetchData();
  }
  public generateCode(codeType: string) {
    if (parseInt(this.quantityPassCode, null) > 1000 || parseInt(this.quantityFailCode, null) > 1000) {
      const message = 'Maximum 1000 code';
      this.$store.commit(MutationType.OpenTopAlert, {
        message,
        type: TypeAlert.Error
      });

      return;
    }
    this.$store.dispatch(ActionTypeRaCampaign.GenerateCode, {
      codeType,
      quantity: parseInt(codeType === 'passed' ? this.quantityPassCode : this.quantityFailCode, null),
      onSuccess: () => {
        const message = 'Generate code successfully';
        this.$store.commit(MutationType.OpenTopAlert, {
          message,
          type: TypeAlert.Success
        });
        this.quantityPassCode = '';
        this.quantityFailCode = '';
        this.fetchData();
      }
    });
  }
  protected mounted() {
    this.$nextTick(() => {
      const breadcrumb: IBreadcrumb[] = [
        {
          label: 'title.campaign',
          path: null,
          current: false
        },
        {
          label: 'title.ra_campaign',
          path: null,
          current: true
        }
      ];
      this.$store.commit(MutationType.SetBreadcrumb, breadcrumb);

      this.fetchData();
    });
  }
  private fetchData() {
    const text = cloneDeep(this.searchText);
    const _filterParams = <any> {};
    _filterParams.type = [];
    let _cloneType = null;
    if (this.filterParams[FilterKey.Status].length) {
      _filterParams.status = this.filterParams[FilterKey.Status];
    }
    if (this.filterParams[FilterKey.Type].length) {
      _filterParams.type = this.filterParams[FilterKey.Type];
    }
    if (_filterParams.type.includes('passed') && !_filterParams.type.includes('failed')) {
      _cloneType = 'P%';
    }
    if (_filterParams.type.includes('failed') && !_filterParams.type.includes('passed')) {
      _cloneType = 'F%';
    }

    this.$store.dispatch(ActionTypeRaCampaign.GetConfirmationCode, {
      type: _cloneType,
      search: `%${text}%`,
      status: _filterParams.status,
      pagination: this.pagination
    });
  }
}
