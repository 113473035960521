import { addressDefault, EvaluationType, Gender, IAddress, StatusCode } from './Common';
import { ICustomerDevice } from './CustomerDevice';
import { IPartner } from './Partner';
import { serviceDefault, IServices } from './Services';
import { IBookingAdditionalTechnician } from './Technician';
import { userDefault, ISimpleUser, IUser } from './User';

export enum ContactInfoType {
  Personal = 'personal',
  Business = 'business'
}
export interface IBookingForm {
  address: {
    address: IAddress;
    gender: Gender;
    name: string;
    phoneNumber: string;
    location?: any;
    isInputManual?: boolean;
  };
  bookingCustomerNote: string;
  customerId: string;
  receiverIds: string[];
  requestStartTime: string;
  status: StatusCode;
  isBroadcast?: boolean;
  location?: {
    lat: number;
    long: number;
  };
  serviceId: string;
  technicianId?: string;
  customer?: ISimpleUser;
  type: ContactInfoType;
  deviceTypeId?: string;
  markedCustomer?: boolean;
  voucherCode?: string;
}
export enum BookingLogType {
  PartnerAssigning = 'partner_assigning'
}
export interface IBookingLog {
  type: BookingStatus;
  userId: string;
  createdAt?: string;
  deviceInfo?: object;
  actor?: ISimpleUser;
  metadata?: {
    partner?: IPartner;
  };
}
export interface IBooking extends IBookingForm {
  id: string;
  startTime: string;
  code?: string;
  endTime?: string;
  billingStatus?: string;
  customerRating: {
    star: number,
    note?: string;
    isRatingFromSMS?: boolean;
  };
  logs: IBookingLog[];
  technician: IUser;
  pricing: IBookingPricing;
  totalBillingStatic: IBookingPricing;
  bookingStatus: BookingStatus;
  warningStatus: WarningStatus;
  isWarningZone: boolean;
  cancelBookingReason?: ICancelBookingReason;
  createdAt?: Date | string;
  service: IServices;
  generalInfo?: {
    customer: ICustomer;
    customerRating: IRating;
    forceComplete: any;
    operators: ICustomer[]
    technician: ICustomer;
    pending: any;
    forceCancel: any;
    reschedules: any;
    changeTechnicians: any[];
  };
  noteLockout?: string;
  devices?: ICustomerDevice[];
  agentLogs: IUser[];
  locationStartBooking?: {
    lat: string,
    long: string
  };
  locationIsGoing?: {
    lat: string,
    long: string
  };
  bookingContacts?: {
    phoneNumber: string;
  };
  partnerAssigning?: IPartner;
  partnerAssigningId?: string;
  additionalTechnicians?: IBookingAdditionalTechnician[];
  isProcessingFlag?: boolean;
  bookingEvaluation?: {
    inputDeviceStatistic?: EvaluationType;
    inputCost?: EvaluationType;
    followAppRules?: EvaluationType;
    uploadImages?: EvaluationType;
  };
}
export interface ICustomer {
  actor: IUser;
  deviceInfo: IDeviceInfo;
  type: string;
}
export interface IDeviceInfo {
  appversion: string;
  device: string;
  model: string;
  osversion: string;
  platform: string;
}
export const bookingFormDefault = (): IBookingForm => {
  return {
    address: {
      address: addressDefault(),
      gender: Gender.Empty,
      name: '',
      phoneNumber: '',
      location: '',
    },
    bookingCustomerNote: '',
    customerId: '',
    customer: {
      id: '',
      name: '',
      avatar: ''
    },
    status: StatusCode.Active,
    receiverIds: [],
    requestStartTime: null,
    serviceId: '',
    type: ContactInfoType.Personal,
    markedCustomer: false,
    voucherCode: ''
  };
};
export const bookingDefault = (): IBooking => {
  return {
    ...bookingFormDefault(),
    address: null,
    startTime: null,
    id: '',
    billingStatus: '',
    customerRating: {
      star: 0
    },
    logs: [],
    location: null,
    // customer: userDefault(),
    technician: userDefault(),
    bookingStatus: null,
    warningStatus: null,
    isWarningZone: false,
    pricing: {
      fixedCost: {
        total: 0,
        transportCost: 0
      },
      daikinSupport: 0
    },
    totalBillingStatic: {
      fixedCost: {
        total: 0,
        transportCost: 0
      },
      daikinSupport: 0
    },
    service: serviceDefault(),
    agentLogs: []
  };
};

export interface IBookingPricing {
  daikinSupport: number;
  fixedCost: {
    total: number;
    distance?: number;
    transportCost: number;
    carryCost?: number;
    estimatedTimeTransport?: any;
  };
  devices?: IBookingPricingDevices[];
}
export interface IBookingPricingDevices {
  device: ICustomerDevice;
  pricing: {
    materials: {
      data: any[];
      total: number;
    },
    parts: {
      data: any[];
      total: number;
    },
    repairs: {
      data: any[];
      total: number;
    }
  };
}

export enum BookingType {
  // Broadcast = 'broadcast',
  SortList = 'sortList',
  Specify = 'specify'
}

export enum WarningStatus {
  Resolved = 'resolved',
  NeedToResolve = 'need_to_resolve'
}

export enum BookingStatus {
  Requesting = 'requesting',
  Received = 'received',
  Assigned = 'assigned',
  Accepted = 'accepted',
  OnTheWay = 'on_the_way',
  InProgress = 'in_progress',
  PendingService = 'pending_service',
  Rating = 'rating',
  Completed = 'completed',
  Cancel = 'cancel',
  PartnerAssigning = 'partner_assigning',
  PendingPayment = 'pending_payment',
  ConfirmPayment = 'confirm_payment',
  // Finding = 'finding',
  // AwaitingTechnician = 'awaiting_technician',
  // Estimate = 'estimate',
  // PendingRating = 'pending_rating',
  // WaitingCustomerConfirm = 'waiting_customer_confirm',
  // CustomerConfirmed = 'customer_confirmed'

}

export const typeBookingStatus = () => {
  const result = [];
  // tslint:disable-next-line:forin
  for (const key in BookingStatus) {
    result.push(BookingStatus[key]);
  }

  return result;
};

export const typeWarningStatus = () => {
  const result = [];
  // tslint:disable-next-line:forin
  for (const key in WarningStatus) {
    result.push(WarningStatus[key]);
  }

  return result;
};
export enum BookingGroup {
  Requesting = 'requesting',
  InProgress = 'in_progress',
  Pending = 'pending',
  Close = 'closed',
  WarningZone = 'warning_zone'
}
export interface IBookingGroupSelection {
  name: BookingGroup | string;
  value: BookingGroup;
  statuses: BookingStatus[];
  warningStatuses?: WarningStatus[] | null;
}
export interface ICancelBookingReason {
  reasonId: string;
  note: string;
}
export interface ICancelBookingForm {
  reasonId: string;
  note: string;
  type?: BookingCancellationType;
  requestedBy?: string;
}
export function cancelBookingDefault(): ICancelBookingForm {
  return {
    reasonId: '',
    note: '',
    requestedBy: ''
  };
}
export interface IPendingServiceBookingForm {
  form: {
    reason: {
      reasonId: string;
      note: string;
    }
    pendingEstimateDate: string;
  };
  requestedBy: string;
}
export function pendingServiceBookingFormDefault(): IPendingServiceBookingForm {
  return {
    form: {
      reason: {
        note: '',
        reasonId: '',
      },
      pendingEstimateDate: ''
    },
    requestedBy: ''
  };
}
export interface IBookingCancellation {
  code: string;
  id?: string;
  status: StatusCode;
  reason: string;
  type: BookingCancellationType;
  requestedBy?: string;
}
export function bookingCancellationDefault(): IBookingCancellation {
  return {
    code: '',
    id: null,
    reason: '',
    status: StatusCode.Active,
    type: null,
    requestedBy: ''
  };
}
export enum BookingCancellationType {
  Complete = 'complete',
  Cancel = 'cancel',
  PendingService = 'technician_pending',
  RescheduleBooking = 'reschedule_booking',
  ChangeTechnician = 'change_technician'
}
export interface IRatingBookingForm {
  star: number;
  note?: string;
}
export interface IRating extends IRatingBookingForm {
  id: string;
}
export interface IRescheduleBookingForm {
  requestStartTime: string;
  form: {
    reasonId: string;
    note: string;
  };
  requestedBy: string;
}
export function rescheduleBookingFormDefault(): IRescheduleBookingForm {
  return {
    form: {
      note: '',
      reasonId: ''
    },
    requestStartTime: '',
    requestedBy: ''
  };
}

export enum BookingReceiverType {
  Broadcast = 'broadcast',
  Individual = 'individual',
  Group = 'group',
}

export interface IListAssignmentInput {
  location?: {
    lat: number;
    long: number;
  };
  bookingRequestStartTime?: string;
  distance?: number;
  limit?: number;
  text?: string;
  partnerId?: string;
  typePartner?: string;
}

export interface IFilterBookingForm {
  status: string;
  serviceId: string;
  checked: boolean;
  warningStatus: string;
  isProcessingFlag?: boolean;
}

export function filterBookingFormDefault(): IFilterBookingForm {
  return {
    status: '',
    serviceId: '',
    checked: false,
    warningStatus: '',
    isProcessingFlag: null
  };
}

export interface IBookingAgentNoteForm {
  bookingId: string;
  note: string;
  createdBy?: string;
  updatedBy?: string;
}
export function BookingAgentNoteFormDefault(): IBookingAgentNoteForm {
  return {
    bookingId: '',
    note: ''
  };
}

export interface IBookingAgentNote extends IBookingAgentNoteForm {
  id: string;
  status: StatusCode;
  createdUser?: ISimpleUser;
  updatedUser?: ISimpleUser;
}

export function BookingAgentNoteDefault(): IBookingAgentNote {
  return {
    ...BookingAgentNoteFormDefault(),
    id: '',
    status: StatusCode.Active
  };
}

export interface IBookingRatingRate {
  id?: string;
  name: string;
  rate: number;
  code?: string;
  createdBy?: string;
  updatedBy?: string;
  createdUser?: ISimpleUser;
  updatedUser?: ISimpleUser;
}
export function bookingRatingRateDefault(): IBookingRatingRate {
  return {
    name: '',
    rate: null
  };
}

export interface IBookingContactForm {
  bookingId: string;
  customerId: string;
  note: string;
  createdBy?: string;
  updatedBy?: string;
}
export function BookingContactFormDefault(): IBookingContactForm {
  return {
    bookingId: '',
    customerId: '',
    note: ''
  };
}

export interface IBookingContact extends IBookingContactForm {
  id: string;
  status: StatusCode;
  createdUser?: ISimpleUser;
  updatedUser?: ISimpleUser;
}

export function BookingContactDefault(): IBookingContact {
  return {
    ...BookingContactFormDefault(),
    id: '',
    status: StatusCode.Active
  };
}

export interface IClaimToAndNote {
  claimTo: string;
  note: string;
}
export function claimToAndNoteDefault(): IClaimToAndNote {
  return {
    claimTo: '',
    note: ''
  };
}

export interface IIssuingInvoice {
  id: string;
  code: string;
  totalBillingStatic: {
    customerPay: number;
    customerPayAfterAddedVAT: number;
    customerPayAfterAddedDKSP: number;
  };
  customer: ISimpleUser;
  isInvoiceIssuing: boolean;
  isWantInvoice: boolean;
  bookingStatus: BookingStatus;
  status: StatusCode;
  endTime: string;
  pendingRatingTime?: string;
  createdAt: string;
  logs: IBookingLog[];
}

export interface IBookingExportForm {
  partnerIds: string[];
  periodTime: {
    from: string;
    to: string;
  };
  endTime?: {
    from: string;
    to: string;
  };
  bookingCodes?: string[];
  contactPhoneNumbers?: string[];
  modelNumbers?: string[];
  partNumbers?: string[];
  mode?: number;
}

export enum BookingSearchBy {
  BookingCode = 'bookingCode',
  CustomerName = 'customerName',
  CustomerPhone = 'customerPhone',
  TechnicianName = 'technicianName',
  ContactAddress = 'contactAddress',
  ContactCompany = 'contactCompany',
  PartnerCode = 'partnerCode'
}

export const typeBookingSearchBy = () => {
  const result = [];
  // tslint:disable-next-line:forin
  for (const key in BookingSearchBy) {
    result.push(BookingSearchBy[key]);
  }

  return result;
};

export const enum BookingCardActionType {
  View = 'view',
  Retry = 'retry',
  Assign = 'assign',
  ChangeStatus = 'change_status',
  Reschedule = 'reschedule',
  Cancel = 'cancel',
  Complete = 'complete',
  PendingService = 'pending_service',
  ChangeTechnician = 'change_technicicn',
  LookOut = 'look_out',
  InWarningZone = 'in_warning_zone',
  MarkAsResolved = 'mark_as_resolved',
  EnableProcessingFlag = 'enable_processing_flag',
  DisableProcessingFlag = 'disable_processing_flag',
  SendBooking = 'send_booking',
  ChangePartner = 'change_partner'
}

export interface IBookingEvaluation {
  inputCost?: EvaluationType;
  inputDeviceStatistic?: EvaluationType;
  uploadImages?: EvaluationType;
  followAppRules?: EvaluationType;
}

export function bookingEvaluationDefault(): IBookingEvaluation {
  return {
    inputCost: EvaluationType.UnChecked,
    inputDeviceStatistic: EvaluationType.UnChecked,
    uploadImages: EvaluationType.UnChecked,
    followAppRules: EvaluationType.UnChecked
  };
}
