import { IBooking } from './Booking';
import { StatusCode } from './Common';
import { deviceModelDefault, IDeviceModel, IDeviceType, IErrorDeviceModel } from './DeviceParts';
import { IPartner } from './Partner';
import { IServices } from './Services';
import { ISimpleUser, IUser } from './User';

export interface IWorkflowForm {
  name: string;
  errorCode: string;
  deviceModelId: string;
  note?: string;
  createdBy?: string;
  updatedBy?: string;
}
export function workflowFormDefault(): IWorkflowForm {
  return {
    name: '',
    errorCode: '',
    deviceModelId: '',
    note: ''
  };
}
export interface IWorkflow extends IWorkflowForm {
  id: string;
  deviceModel: IDeviceModel;
  createdAt?: string;
  createdUser?: ISimpleUser;
  createdBy: string;
  updatedBy?: string;
  updatedAt?: string;
  updatedUser?: ISimpleUser;
}
export function workflowDefault(): IWorkflow {
  return {
    ...workflowFormDefault(),
    id: '',
    createdBy: '',
    deviceModel: deviceModelDefault()
  };
}

export interface ISymptomForm {
  title: string;
  description: string;
  // deviceModelIds: string[];
  manageCode: string;
  note: string;
  createdBy?: string;
  updatedBy?: string;
  causeIds: string[];
}
export function symptomFormDefault(): ISymptomForm {
  return {
    title: '',
    description: '',
    manageCode: '',
    note: '',
    causeIds: [],
    // deviceModelIds: []
  };
}
export interface ISymptom extends ISymptomForm {
  id: string;
  createdAt: string;
  updatedAt?: string;
}
export function symptomDefault(): ISymptom {
  return {
    ...symptomFormDefault(),
    id: '',
    createdAt: null
  };
}
export enum CauseType {
  Error = 'error',
  Symptom = 'symptom'
}
export interface ICauseForm {
  code: string;
  title: string;
  description: string;
  createdBy?: string;
  updatedBy?: string;
  targetId: string;
  type: CauseType;
  repairs: any[];
  parts: any[];
  materials: any[];
}
export function causeFormDefault(): ICauseForm {
  return {
    code: '',
    title: '',
    description: '',
    materials: [],
    parts: [],
    repairs: [],
    targetId: '',
    type: null
  };
}
export interface ICause extends ICauseForm {
  id: string;
  createdAt: string;
  updatedAt?: string;
  status: StatusCode;
  target?: any;
}
export function causeDefault(): ICause {
  return {
    ...causeFormDefault(),
    id: '',
    createdAt: null,
    status: StatusCode.Active
  };
}

// export interface IMaterialForm {
//   name: string;
// }
// export function materialFormDefault(): IMaterialForm {
//   return {
//     name: ''
//   };
// }
// export interface Imaterial extends IMaterialForm {
//   id: string;
// }
// export function materialDefault(): Imaterial {
//   return {
//     ...materialFormDefault(),
//     id: ''
//   };
// }

export interface ITechnicianRateForm {
  manDay: number;
  manHour: number;
  manMonth: number;
  rateName: string;
  description: string;
  yearOfExperience: number;
  createdBy?: string;
  updatedBy?: string;
}
export function technicianRateFormDefault(): ITechnicianRateForm {
  return {
    description: '',
    manDay: null,
    manHour: null,
    manMonth: null,
    rateName: '',
    yearOfExperience: null
  };
}
export interface ITechnicianRate extends ITechnicianRateForm {
  id: string;
  status: StatusCode;
  code?: string;
  createdUser?: ISimpleUser;
  updatedUser?: ISimpleUser;
}
export function technicianRateDefault(): ITechnicianRate {
  return {
    ...technicianRateFormDefault(),
    id: '',
    status: StatusCode.Active
  };
}

export interface IWErrorForm {
  title: string;
  description: string;
  code: string;
  createdBy?: string;
  updatedBy?: string;
  causeIds: string[];
  // deviceModelIds: string[];
  note: string;
}
export function errorFormDefault(): IWErrorForm {
  return {
    title: '',
    description: '',
    code: '',
    causeIds: [],
    // deviceModelIds: [],
    note: ''
  };
}
export interface IWError extends IWErrorForm {
  id: string;
  createdAt: string;
  updatedAt?: string;
  errorDeviceModels?: IErrorDeviceModel[];
}
export function errorDefault(): IWError {
  return {
    ...errorFormDefault(),
    id: '',
    createdAt: null
  };
}

export interface ITechnicianSkillForm {
  name: string;
  description: string;
  type: string;
  renewTime: number;
  gradingRatio: number;
  deviceTypeId: string;
  // serviceId: string;
  createdBy?: string;
  updatedBy?: string;
}
export function TechnicianSkillFormDefault(): ITechnicianSkillForm {
  return {
    name: '',
    description: '',
    deviceTypeId: '',
    type: '',
    renewTime: undefined,
    gradingRatio: undefined
  };
}
export interface ITechnicianSkill extends ITechnicianSkillForm {
  id: string;
  status: StatusCode;
  deviceType?: IDeviceType;
  createdUser?: ISimpleUser;
  updatedUser?: ISimpleUser;
}
export function TechnicianSkillDefault(): ITechnicianSkill {
  return {
    ...TechnicianSkillFormDefault(),
    id: '',
    status: StatusCode.Active
  };
}

export interface ITechnicianForm {
  name: string;
  email: string;
  phoneNumber: string;
  avatar: string;
  technicianCode: string;
  partnerId: string;
  technicianRateId: string;
  createdBy?: string;
  updatedBy?: string;
}
export function TechnicianFormDefault(): ITechnicianForm {
  return {
    name: '',
    email: '',
    avatar: '',
    phoneNumber: '',
    technicianCode: '',
    partnerId: '',
    technicianRateId: ''
  };
}
export interface ITechnicianServices {
  id: string;
  technicianId: string;
  technician: ITechnician;
  serviceId: string;
  service: IServices;
}
export interface ITechnician extends ITechnicianForm {
  id: string;
  status: StatusCode;
  createdUser?: ISimpleUser;
  updatedUser?: ISimpleUser;
  partner?: IPartner;
  technicianServices?: ITechnicianServices[];
}
export function TechnicianDefault(): ITechnician {
  return {
    ...TechnicianFormDefault(),
    id: '',
    status: StatusCode.Active
  };
}

export interface ITechnicianCellPhone {
  id: string;
  status: StatusCode;
  technician: ITechnician;
  createdBy?: string;
  updatedBy?: string;
  createdUser?: ISimpleUser;
  updatedUser?: ISimpleUser;
}

export interface IBookingNumbersByTechnician {
  inProgressNumbers?: number;
  pendingNumbers?: number;
  closedNumbers?: number;
  acceptedNumbers?: number;
  onTheWayNumbers?: number;
  partnerAssigningNumbers?: number;
  assignedNumbers?: number;
  pendingServiceNumbers?: number;
}

export interface IBookingByTechinician {
  bookings: IBooking[];
}
export interface ITechnicianQuantity {
  onDuty: number;
  offDuty: number;
  offline: number;
  total: number;
}
export interface IBookingAdditionalTechnician {
  id: string;
  bookingId: string;
  technicianId: string;
  technician: IUser;
}
