import {
  IAlertState,
  IGlobalState,
  IState,
  Layout,
  MutationType,
  TypeAlert
} from './types';

import { omit } from 'lodash';
import { defaultValue } from 'root/helpers';
import { i18n } from 'root/locales';
import { ELocalStorage } from 'root/models';

export function AlertState(): IAlertState {
  return {
    opened: false,
    type: TypeAlert.Info,
    message: 'Text message',
    icon: 'info'
  };
}

export const globalState: IGlobalState = {
  isLoading: false,
  authUser: null,
  topAlert: AlertState(),
  layout: Layout.Login,
  lastRoutePath: null,
  currentLocale: '',
  globalConfig: {
    listCentre: [
      {
        name: '',
        value: ''
      }
    ],
    listCountry: []
  },
  userCreating: false,
  breakCrumb: '',
  back: false,
  showCollapse: false,
  typeCollapse: false,
  oneSignalInitial: false,
  isAccountExpired: false,
  localVN: null,
  isRefesh: false
};

export const mutations = {
  // init
  [MutationType.SetCurrentLocale](state: IState, locale: string) {
    state.global.currentLocale = locale;
    i18n().locale = locale;
  },
  [MutationType.SetGlobalConfig](state: IState, globalConfig: any) {
    state.global.globalConfig = globalConfig;
  },
  [MutationType.ClosePlashScreen](state: IState) {
    state.global.isLoading = false;
  },
  [MutationType.OpenPlashScreen](state: IState) {
    state.global.isLoading = true;
  },
  [MutationType.SetLayout](state: IState, layout: Layout) {
    state.global.layout = layout;
  },
  [MutationType.OpenTopAlert](state: IState, topAlert: IAlertState) {
    state.global.topAlert = {
      ...topAlert,
      opened: true
    };
  },
  [MutationType.CloseTopAlert](state: IState) {
    state.global.topAlert = AlertState();
  },
  [MutationType.SetLocalVN](state: IState, data: any) {
    state.global.localVN = data;
  },
  // login
  [MutationType.Authenticated](state: IState, user) {
    state.global.authUser = user;
  },
  // login
  [MutationType.GetRole](state: IState, data) {
    state.role = data;
  },
  // group
  [MutationType.GetGroup](state: IState, data) {
    state.group = data;
  },
  [MutationType.getGroupSystem](state: IState, data) {
    state.group.systemGroup = data;
  },
  [MutationType.GetBranch](state: IState, data) {
    state.branch = data;
  },
  [MutationType.GetPoints](state: IState, data) {
    state.point = data;
    state.point.pagination = {
      ...state.point.pagination,
      ...data.pagination,
      limit: data.pagination.size
    };
  },
  [MutationType.GetListDealer](state: IState, data) {
    state.dealer.data = data.data;
    state.dealer.pagination = {
      ...state.dealer.pagination,
      ...data.pagination,
    };
    state.dealer.pagination.limit = data.pagination && data.pagination.limit ?
     data.pagination.limit : state.dealer.pagination && state.dealer.pagination.limit;
    state.dealer.pagination.page = data.pagination && data.pagination.page ?
     data.pagination.page : state.dealer.pagination && state.dealer.pagination.page;
  },
  [MutationType.GetListSaleman](state: IState, data) {
    state.dealer.data = data.data;
    state.dealer.pagination = {
      ...state.dealer.pagination,
      ...data.pagination,
    };
    state.dealer.pagination.limit = data.pagination && data.pagination.limit ?
     data.pagination.limit : state.dealer.pagination && state.dealer.pagination.limit;
    state.dealer.pagination.page = data.pagination && data.pagination.page ?
     data.pagination.page : state.dealer.pagination && state.dealer.pagination.page;
  },
  [MutationType.GetTargetDetail](state: IState, data) {
    state.dealer.data = data;
  },
  [MutationType.GetListBranchManage](state: IState, data) {
    state.branchManage.data = data.data;
    state.branchManage.pagination = {
      ...state.branchManage.pagination,
      ...data.pagination
    };
  },
  [MutationType.GetProduct](state: IState, data) {
    state.product = data;
  },
  [MutationType.GetProductTrans](state: IState, data) {
    state.productTrans.data = data;
  },
  [MutationType.Unauthenticated](state: IState) {
    state.global.authUser = null;
    state.global.lastRoutePath = state.route.path;
    localStorage.setItem(ELocalStorage.Token, null);
    localStorage.setItem(ELocalStorage.RefreshToken, null);
    localStorage.setItem('ExpireTime', null);
  },
  [MutationType.SetBreadcrumb](state: IState, data) {
    state.global.breakCrumb = data;
  },
  [MutationType.ClearBreadcrumb](state: IState) {
    state.global.breakCrumb = '';
  },
  // users
  [MutationType.SetCreatingUser](state: IState) {
    state.global.userCreating = true;
  },
  [MutationType.SetCreatedUser](state: IState) {
    state.global.userCreating = false;
  },
  [MutationType.SetIsRefesh](state: IState, value) {
    state.global.isRefesh = value;
  },
  [MutationType.ClearStore](state: IState) {
    const _state = omit(defaultValue(state), ['global', 'route']);
    Object.keys(_state).forEach((key) => {
      state[key] = {
        ..._state[key],
        orderBy: null,
        pagination: {
          // size: 20,
          page: 1,
          limit: 20,
          offset: 0
        }
      };
    });
  },
  // back
  [MutationType.SetBack](state: IState) {
    state.global.back = true;
  },
  [MutationType.ClearBack](state: IState) {
    state.global.back = false;
  },
  // collapse
  [MutationType.SetShowCollapse](state: IState) {
    state.global.showCollapse = true;
  },
  [MutationType.ClearShowCollapse](state: IState) {
    state.global.showCollapse = false;
  },
  [MutationType.SetTypeCollapse](state: IState, data: boolean) {
    state.global.typeCollapse = data;
  },
  [MutationType.ClearTypeCollapse](state: IState) {
    state.global.typeCollapse = false;
  },
  [MutationType.SetOneSignalInitial](state: IState, payload: boolean) {
    state.global.oneSignalInitial = payload;
  },
  [MutationType.SetAccountExpired](state: IState, payload: boolean) {
    state.global.isAccountExpired = payload;
  }
};
