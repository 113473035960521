import { stripObject } from '@hgiasac/helper';
import { cloneDeep } from 'lodash';
import {
  IGqlQueryOption,
  IPaginationParams,
  PaginationParams
} from 'root/api/graphql/Core';
import { ActionContext } from 'vuex';
import { ActionType, IState } from '../types';
import { ICRUDFilterState, ICRUDState, IOptionCRUD } from './mutations';

export interface ICRUDActionOptions<M> {
  createMany?(form: any[]): Promise<M[]>;
  filter?(
    pagingParams?: IPaginationParams,
    options?: IGqlQueryOption
  ): Promise<any>;
  getById?(id: string): Promise<M>;
  update?(id: string, form: any): Promise<M>;
  create?(form: any): Promise<M>;
  deleteById?(id: string): Promise<M>;
  fetchMany?(): Promise<M[]>;
}

export interface IOptionFunctionFilterCRUD {
  disableLoading?: boolean;
}

export function crudActions<T extends object>(
  name: string,
  options: ICRUDActionOptions<T>,
  optionCRUD?: IOptionCRUD
) {
  let keyState;
  if (optionCRUD && optionCRUD.keyState) {
    keyState = optionCRUD.keyState;
  }
  function getDataState(state: ICRUDState<T>, key: string) {
    if (keyState) {
      return state[keyState][key];
    }

    return state[key];
  }

  function filter(
    { commit, dispatch, state, params },
    opts: IOptionFunctionFilterCRUD = {},
    optionsHTTP?: IGqlQueryOption
  ) {
    if (!opts.disableLoading) {
      commit(`${name}Loading`);
    }

    // save filterParams into state
    if (params.filterParams) {
      commit(`${name}FilterChange`, params.filterParams);
    } else {
      params.filterParams = getDataState(state, 'filterParams');
    }

    let orderBy: object = null;
    // save orderBy into state
    if (optionsHTTP && optionsHTTP.orderBy) {
      commit(`${name}ChangeOrderBy`, optionsHTTP.orderBy);
      orderBy = cloneDeep(optionsHTTP.orderBy);
    } else {
      orderBy = getDataState(state, 'orderBy');
    }
    let filterParams = optionsHTTP ? optionsHTTP.filterParams : {};
    filterParams = {
      ...filterParams,
      ...params.filterParams
    };

    const pagination = getDataState(state, 'pagination');
    options
      .filter(pagination, {
        ...optionsHTTP,
        orderBy,
        filterParams: {
          // ...optionsHTTP.filterParams,
          ...filterParams
        }
      })
      .then((results) => {
        if (!opts.disableLoading) {
          commit(`${name}Loaded`);
        }
        const data = results.data,
          filterResults: ICRUDFilterState<T> = results;
        if (params.onSuccess) {
          params.onSuccess(data);
        }
        commit(`${name}UpdateModels`, data);
        commit(`${name}FilterModels`, filterResults);
      })
      .catch((e) => {
        if (!opts.disableLoading) {
          commit(`${name}Loaded`);
        }
        dispatch(ActionType.CatchException, e);
      });
  }

  return stripObject({
    [`${name}FilterNoCache`]: !options.filter
      ? undefined
      : (
          { commit, dispatch, state }: ActionContext<ICRUDState<T>, IState>,
          { params = {}, optionsHTTP = null } = {}
        ) => {
        params = { ...params };

        return filter({ commit, dispatch, state, params }, {}, optionsHTTP);
      },
    [`${name}FetchMany`]: !options.fetchMany
      ? undefined
      : ({ commit, dispatch }: ActionContext<ICRUDState<T[]>, IState>) => {
        commit(`${name}Loading`);
        options
            .fetchMany()
            .then((results) => {
              commit(`${name}Loaded`);
              commit(`${name}RefreshModels`, results);
            })
            .catch((e) => {
              commit(`${name}Loaded`);
              dispatch(ActionType.CatchException, e);
            });
      },
    [`${name}FindById`]: !options.getById
      ? undefined
      : (
          { commit, dispatch }: ActionContext<ICRUDState<T>, IState>,
          { id, onSuccess, onFailure, opts }
        ) => {
        if (!(opts && opts.disableLoading)) {
          commit(`${name}Loading`);
        }

        return options
            .getById(id)
            .then((model) => {
              if (!(opts && opts.disableLoading)) {
                commit(`${name}Loaded`);
              }
              commit(`${name}UpdateModels`, [model[0]]);
              if (onSuccess) {
                onSuccess(model);
              }
            })
            .catch((e) => {
              if (!(opts && opts.disableLoading)) {
                commit(`${name}Loaded`);
              }
              dispatch(ActionType.CatchException, e);
              if (onFailure) {
                onFailure(e);
              }
            });
      },
    [`${name}Update`]: !options.update
      ? undefined
      : (
          { commit, dispatch, rootState }: ActionContext<ICRUDState<T>, IState>,
          { id, form, router, redirectPath, onSuccess, onFailure }
        ) => {
        commit(`${name}Mutating`);
        const authUser = rootState.global.authUser;

        return options
            .update(id, {
              ...form,
              updatedBy: authUser.id
            })
            .then((result) => {
              commit(`${name}Mutated`);
              commit(`${name}UpdateModels`, [result]);

              if (onSuccess) {
                onSuccess(result);
              }

              if (router && redirectPath) {
                router.replace(redirectPath);
              }
            })
            .catch((e) => {
              commit(`${name}Mutated`);
              dispatch(ActionType.CatchException, e);
              if (onFailure) {
                onFailure(e);
              }
            });
      },
    [`${name}Create`]: !options.create
      ? undefined
      : (
          { commit, dispatch, rootState }: ActionContext<ICRUDState<T>, IState>,
          { form, router, redirectPath, onSuccess, onFailure }
        ) => {
        commit(`${name}Mutating`);
          // const authUser = null;
        const authUser = rootState.global.authUser;

        return options
            .create({
              ...form,
              createdBy: authUser.id
            })
            .then((result) => {
              commit(`${name}Mutated`);
              commit(`${name}InsertModel`, result);
              if (onSuccess) {
                onSuccess(result);
              }

              if (router && redirectPath) {
                router.replace(redirectPath);
              }
            })
            .catch((e) => {
              commit(`${name}Mutated`);
              dispatch(ActionType.CatchException, e);
              if (onFailure) {
                onFailure(e);
              }
            });
      },
    [`${name}CreateMany`]: !options.createMany
      ? undefined
      : (
          { commit, dispatch }: ActionContext<ICRUDState<T>, IState>,
          { form, router, redirectPath, onSuccess, onFailure, opts }
        ) => {
        commit(`${name}Mutating`);
          // const authUser = rootState.global.authUser;
        const authUser = null;
        form = form.map((e) => {
          return {
            ...e,
            createdBy: authUser.id
          };
        });

        return options
            .createMany(form)
            .then((result) => {
              const disabledInsert = opts ? opts.disabledInsert : false;
              commit(`${name}Mutated`);
              if (!disabledInsert) {
                commit(`${name}InsertModel`, result);
              }
              if (onSuccess) {
                onSuccess(result);
              }

              if (router && redirectPath) {
                router.replace(redirectPath);
              }
            })
            .catch((e) => {
              commit(`${name}Mutated`);
              dispatch(ActionType.CatchException, e);
              if (onFailure) {
                onFailure(e);
              }
            });
      },
    [`${name}Delete`]: !options.deleteById
      ? undefined
      : (
          { commit, dispatch }: ActionContext<ICRUDState<T>, IState>,
          { id, onFailure, onSuccess }
        ) => {
        return options
            .deleteById(id)
            .then(() => {
              commit(`${name}RemoveModelByIDs`, [id]);
              if (onSuccess) {
                onSuccess();
              }
            })
            .catch((e) => {
              dispatch(ActionType.CatchException, e);
              if (onFailure) {
                onFailure(e);
              }
            });
      },
    [`${name}FilterChange`](
      { commit, dispatch, state }: ActionContext<ICRUDState<T>, IState>,
      { params = {}, optionsHTTP = null } = {}
    ) {
      commit(`${name}FilterChange`, params);
      const pagination = getDataState(state, 'pagination');
      commit(`${name}PaginationChange`, {
        ...pagination,
        page: pagination.page = 1
      });
      dispatch(`${name}Filter`, { params, optionsHTTP });
    },
    [`${name}PaginationChange`](
      { commit, dispatch }: ActionContext<ICRUDState<T>, IState>,
      { pagination = PaginationParams(), optionsHTTP = null } = {}
    ) {
      commit(`${name}PaginationChange`, pagination);
      const params = {};
      dispatch(`${name}Filter`, { params, optionsHTTP });
    }
  });
}
