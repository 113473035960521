import { Avatar } from 'root/components/Avatar';
import { formatBirthday, FormatDateFullTime, FormatDateTime } from 'root/helpers';
import { IUser } from 'root/models';
// import { IState } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import './styles.scss';
@Component({
  template: require('./view.html'),
  props: {
    visible: Boolean,
    detailId: String
  },
  components: {
    avatar: Avatar
  },
  computed: {
    ...mapState({
      // userDetail: (state: IState) => state.user.userDetail,
    })
  }
})
export class ConditionDetailModal extends Vue {
  public FormatDateTime = FormatDateTime;
  public FormatDateFullTime = FormatDateFullTime;
  public formatBirthday = formatBirthday;
  public userDetail = null;
  public detailId: string;

  public get displayRole(): string {
    return this.userDetail.role ? this.userDetail.role.name.toString() : '';
  }
  public get displayStationName() {
    if (this.userDetail.permission === 'partner') {
      return this.userDetail.partner && this.userDetail.partner.station ? this.userDetail.partner.station.name : '';
    }

    return this.userDetail.station ? this.userDetail.station.name : '';
  }

  public displayGroup(model: IUser) {
    if (model.superAdmin) {
      return this.$t('super.admin');
    }
    if (model.systemGroup) {
      return this.$t(model.systemGroup.name);
    }

    return '';
  }

  public async openDialog() {
    this.userDetail = this.$store.getters.getConditionsSorted(this.detailId);
  }

  public closeDialog() {
    this.$emit('closeDialog');
  }
}
