import { TabPane } from 'element-ui';
import { IPaginationParams } from 'root/api/graphql/Core';
import { FormatDateFullTime } from 'root/helpers';
import { IBreadcrumb, IUser } from 'root/models';
import { IState, MutationType } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import { ZeroPercentCampaignRouterName, ZeroPercentCampaignTab } from '..';
import { ActionTypeZeroCampaign } from '../Store/types';
import './styles.scss';

@Component({
  template: require('./view.html'),
  computed: {
    ...mapState({
      authUser: (state: IState) => state.global.authUser,
    })
  }
})
export class ZPConfiguration extends Vue {
  public get activeTab() {
    return ZeroPercentCampaignTab.Configuration;
  }
  public set activeTab(_newValue) {
    // return ZeroPercentCampaignTab.Configuration;
  }
  public get columns() {
    return [
      {
        prop: 'action',
        label: 'Action',
        width: 250,
      },
      {
        prop: 'reason',
        label: 'Reason',
        formatter: (model: any) => {
          return model && model.reason ? model.reason : '--';
        },
        width: 250,
      },
      {
        prop: 'updatedBy',
        label: 'Updated By',
        formatter: (model: any) => {
          return model && model.creator && model.creator.name ? model.creator.name : '--';
        },
        width: 200,
      },
      {
        prop: 'role',
        label: 'role',
        formatter: (model: any) => {
          return model && model.creator && model.creator.role && model.creator.role.name ?
          model.creator.role.name : '--';
        },
        width: 150,
      },
      {
        prop: 'updatedAt',
        label: 'Updated At',
        formatter: (model: any) => {
          return model && model.createdAt ? FormatDateFullTime(model.createdAt) : '--';
        },
        width: 150,
      },
    ];
  }
  public tabPosition: string = 'block';
  public activeSubTab: string = 'blockRegistration';
  public data: any[] = [];
  public authUser: IUser;
  public FormatDateFullTime = FormatDateFullTime;
  public loadingHistory: boolean = false;
  public pagination: IPaginationParams = {
    limit: 20,
    page: 1
  };
  public reason: any = {
    HD: '',
    FE: '',
    ACS: ''
  };
  public errorReason: any = {
    HD: false,
    FE: false,
    ACS: false
  };
  public company: any = {
    HD: false,
    FE: false,
    ACS: false
  };
  public isEditing: any = {
    HD: false,
    FE: false,
    ACS: false
  };
  public loading: any = {
    HD: false,
    FE: false,
    ACS: false
  };
  public changeTab(tab: TabPane) {
    switch (tab.name) {
    case ZeroPercentCampaignTab.Statistic:
      this.$router.push({
        name: ZeroPercentCampaignRouterName.Statistic
      });
      break;
    case ZeroPercentCampaignTab.Participant:
      this.$router.push({
        name: ZeroPercentCampaignRouterName.Participant
      });
      break;
    default:
      break;
    }
  }
  public changeEdit(alias) {
    return this.isEditing[alias] = true;
  }
  public fetchData() {
    this.$store.dispatch(ActionTypeZeroCampaign.GetConfiguration, {
      onSuccess: (data) => {
        this.company = {
          HD: data.find((e) => e.key === 'HD') && data.find((e) => e.key === 'HD').status === 'active' ? true : false,
          FE: data.find((e) => e.key === 'FE') && data.find((e) => e.key === 'FE').status === 'active' ? true : false,
          ACS: data.find((e) => e.key === 'ACS') &&
          data.find((e) => e.key === 'ACS').status === 'active' ? true : false,
        };
      }
    });
  }
  public fetchDataHistory() {
    this.loadingHistory = true;
    this.$store.dispatch(ActionTypeZeroCampaign.GetConfigHistoryLogs, {
      pagination: this.pagination,
      onSuccess: (data) => {
        this.data = data.ip_campaign_config_logs;
        this.pagination.total = data.ip_campaign_config_logs_aggregate.aggregate.count;
        this.loadingHistory = false;
      }
    });
  }
  public handleChangeItemPerPage(value) {
    if (typeof value === 'number') {
      this.pagination = {
        ...this.pagination,
        limit: value
      };
    } else {
      this.pagination = {
        ...this.pagination,
        limit: value
      };
    }
    this.fetchDataHistory();
  }
  public handlePaginationChange(value) {
    this.pagination = value;
    this.fetchDataHistory();
  }
  public handleConfirm(alias) {
    this.loading[alias] = true;
    if (!this.reason || !this.reason[alias] || this.reason[alias].length <= 0) {
      this.loading[alias] = false;
      this.errorReason[alias] = true;

      return;
    }

    this.errorReason[alias] = false;
    this.$store.dispatch(ActionTypeZeroCampaign.UpdateConfiguration, {
      key: alias,
      status: this.company && this.company[alias] ? 'active' : 'deleted',
      reason: this.reason[alias],
      updatedBy: this.authUser.id,
      onSuccess: () => {
        this.isEditing[alias] = false;
        this.reason[alias] = '';
        this.loading[alias] = false;
        this.$message({
          type: 'success',
          message: `Update configuration successfully!`
        });
      }
    });
  }
  protected mounted() {
    this.$nextTick(() => {
      const breadcrumb: IBreadcrumb[] = [
        {
          label: 'title.zer-per-campaign',
          path: null,
          current: false
        },
      ];
      this.$store.commit(MutationType.SetBreadcrumb, breadcrumb);
      this.fetchData();
    });
  }
}
