import { DKGqlClient } from 'root/api/graphql/Client';
import { ActionType, IState } from 'root/store';
import { crudActions } from 'root/store/helpers';
import { ActionContext } from 'vuex';
import { IEvaluatePhotoState } from '.';
import { ActionTypeEvaluatePhoto, ActionTypeSaleEduCampaign, MutationTypeEvaluatePhoto } from './types';

const { customCampaign } = DKGqlClient();
export const actions = {
  ...crudActions('evaluatePhoto', {
    filter: customCampaign.filter
  }),
  ...crudActions('bank_dealers', {
    filter: customCampaign.bank_dealers.filter,
    update: customCampaign.bank_dealers.update,
  }, {
    keyState: 'bank_dealers'
  }),
  async [ActionTypeEvaluatePhoto.GetCustomCampaign]({
    commit, dispatch
  }: ActionContext<IEvaluatePhotoState, IState>) {
    try {
      const createUserResult = await customCampaign.getCustomCampaign();
      if (createUserResult) {
        commit(MutationTypeEvaluatePhoto.SetDataCustomCompaign, createUserResult);

        return createUserResult;
      }
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    }
  },
  async [ActionTypeEvaluatePhoto.HandleStatus]({
     dispatch
  }: ActionContext<IEvaluatePhotoState, IState>,
                                               {form, onSuccess, onFailure}) {
    try {
      const createUserResult = await customCampaign.handleUpdateEvaluate(form);
      onSuccess();
      if (createUserResult) {
        dispatch(ActionTypeEvaluatePhoto.FilterNoCache);
      }
    } catch (error) {
      onFailure();
      dispatch(ActionType.CatchException, error);
    }
  },
  async [ActionTypeEvaluatePhoto.HandleOnOff]({
     dispatch
  }: ActionContext<IEvaluatePhotoState, IState>,
                                              {id, status, onSuccess, onFailure}) {
    try {
      const createUserResult = await customCampaign.handleOnOff(id, status);
      onSuccess();
      if (createUserResult) {
        dispatch(ActionTypeEvaluatePhoto.FilterNoCache);
      }
    } catch (error) {
      onFailure();
      dispatch(ActionType.CatchException, error);
    }
  },
  async [ActionTypeEvaluatePhoto.GetAllSample]({
    dispatch
  }: ActionContext<IEvaluatePhotoState, IState>,
                                               {onSuccess}) {
    try {
      const id = await customCampaign.getAllSample();
      if (onSuccess) {
        onSuccess(id);
      }
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    }
  },
  async [ActionTypeEvaluatePhoto.DownloadSample]({
    dispatch
  }: ActionContext<IEvaluatePhotoState, IState>, {id, onSuccess}) {
    try {
      const url = await customCampaign.downloadSample(id);
      if (onSuccess) {
        onSuccess(url);
      }
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    }
  },
  async [ActionTypeSaleEduCampaign.ImportBankingPayment]({
    dispatch
  }: ActionContext<IEvaluatePhotoState, IState>,
                                                         {form, onSuccess}) {
    try {
      await customCampaign.importBankingPayment(form);
      if (onSuccess) {
        onSuccess();
      }
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    }
  },
  async [ActionTypeSaleEduCampaign.CheckAsPaid]({
    dispatch
  }: ActionContext<IEvaluatePhotoState, IState>,
                                                {ids, updatedBy, paidAt, onSuccess}) {
    try {
      await customCampaign.checkAsPaid(ids, updatedBy, paidAt);
      if (onSuccess) {
        onSuccess();
      }
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    }
  },
  async [ActionTypeSaleEduCampaign.Export]({
    dispatch
  }: ActionContext<IEvaluatePhotoState, IState>,
                                           {object, onSuccess}) {
    try {
      const result = await customCampaign.exportSalesEduCampaign(object);
      if (onSuccess) {
        onSuccess(result);
      }
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    }
  },
  async [ActionTypeSaleEduCampaign.GetListTrainee]({
    dispatch
  }: ActionContext<IEvaluatePhotoState, IState>,
                                                   {status, pagination, referenceId, onSuccess}) {
    try {
      const result = await customCampaign.getListTrainee(status, pagination, referenceId);
      if (onSuccess) {
        onSuccess(result);
      }
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    }
  },
  async [ActionTypeSaleEduCampaign.GetListImportHistory]({
    dispatch
  }: ActionContext<IEvaluatePhotoState, IState>,
                                                         {status, importedAt, search, pagination, onSuccess}) {
    try {
      const result = await customCampaign.getListImportHistory(status, importedAt, search, pagination);
      if (onSuccess) {
        onSuccess(result);
      }
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    }
  },
  async [ActionTypeSaleEduCampaign.GetListDealerDetail]({
    dispatch
  }: ActionContext<IEvaluatePhotoState, IState>,
                                                        {status, sheet, importCode, pagination, onSuccess}) {
    try {
      const result = await customCampaign.getListDealerDetail(status, sheet, importCode, pagination);
      if (onSuccess) {
        onSuccess(result);
      }
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    }
  },
  async [ActionTypeSaleEduCampaign.DownloadImportLogs]({
    dispatch
  }: ActionContext<IEvaluatePhotoState, IState>,
                                                       {form, onSuccess}) {
    try {
      const result = await customCampaign.downloadImportLogs(form);
      if (onSuccess) {
        onSuccess(result);
      }
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    }
  },
};
