import { crudQuery, ICRUDQuery } from '../Core/query';

const deviceTypeModel = `
id
name
`;

export const deviceTypes = 'device_type';
// tslint:disable-next-line:no-empty-interface
interface IDeviceTypeQuery extends ICRUDQuery {
}
export const deviceTypeListQuery: IDeviceTypeQuery = {
  ...crudQuery(deviceTypes, deviceTypeModel),
};
