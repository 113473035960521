import { PermissionType } from 'root/models';
import { RouteConfig } from 'vue-router';
import { NotificationsList } from './MobileNews';
import { NewsAnnouncementContainer } from './NewsAnnouncmentContainer';

export enum NewsNotificationsRouterPath {
  Default = '',
  Notification = '/notification'
}

export const NewsNotificationsRouter: RouteConfig = {
  path: NewsNotificationsRouterPath.Default,
  component: NewsAnnouncementContainer,
  children: [
    {
      path: NewsNotificationsRouterPath.Notification,
      component: NotificationsList,
      meta: {
        permission: PermissionType.NewsAndAnnouncement
      }
    }
  ]
};
