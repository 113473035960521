import { TabPane } from 'element-ui';
import { parseUTCToTime, FormatDateFullTime } from 'root/helpers';
import { IAddress, IBreadcrumb, IServiceCategory, IWorkMaster } from 'root/models';
import { IState, MutationType } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import { BranchAndGroupRouterName, BranchAndGroupRouterPath } from '../../';
import { BranchEditor } from '../Components/BranchManageEditor';
import { ActionTypeBranchManage, BranchManageTab } from '../Store/types';
import './styles.scss';

@Component({
  template: require('./view.html'),
  components: {
    editor: BranchEditor
  },
  computed: {
    ...mapState({
      branchManage: (state: IState) => state.branchManage.data,
      detail: (state: IState) => state.branchManage.detail,
      isRefesh: (state: IState) => state.global.isRefesh,
      typeCollapse: (state: IState) => state.global.typeCollapse
    }),
    // detail() {

    //   return this.$store.getters.getBranhManageDetail(this.serviceId);
    // }
  }
})
export class BranchDetail extends Vue {
  public get serviceId(): string {
    return this.$route.params.id;
  }
  public get activeTab() {
    return this.$route.name;
  }
  public set activeTab(_value: string) {
    //
  }
  public FormatDateFullTime = FormatDateFullTime;
  public formatDate = parseUTCToTime;
  public visibleEditor: boolean = false;
  public branchManage: any[];
  public repairs: IWorkMaster[];
  public category: IServiceCategory;
  public fileUpload: File[] = [];
  public isRefesh: boolean;
  // public detail: any;
  public back() {
    this.$router.push(BranchAndGroupRouterName.ListBranch);
  }
  public closeDialog() {
    this.visibleEditor = false;
  }
  public goEdit() {
    this.visibleEditor = true;
  }
  public displayAddress(model: IAddress) {
    return model && model.province ? this.$store.getters['getAddressString'](model) : '';
  }
  public changeTab(tab: TabPane) {
    switch (tab.name) {
    case BranchManageTab.BranchGroups:
      this.$router.push({
        name: BranchAndGroupRouterName.BranchGroups,
        params: {
          id: this.serviceId
        }
      });
      break;
    case BranchManageTab.GroupLeader:
      this.$router.push({
        name: BranchAndGroupRouterName.GroupLeader,
        params: {
          id: this.serviceId
        }
      });
      break;
    case BranchManageTab.ManagerBranch:
      this.$router.push({
        name: BranchAndGroupRouterName.ManagerBranch,
        params: {
          id: this.serviceId
        }
      });
      break;
    case BranchManageTab.DealerBranch:
      this.$router.push({
        name: BranchAndGroupRouterName.DealerBranch,
        params: {
          id: this.serviceId
        }
      });
      break;
    case BranchManageTab.TechnicianBranch:
      this.$router.push({
        name: BranchAndGroupRouterName.TechnicianBranch,
        params: {
          id: this.serviceId
        }
      });
      break;
    case BranchManageTab.BranchInventory:
      this.$router.push({
        name: BranchAndGroupRouterName.BranchInventory,
        params: {
          id: this.serviceId
        }
      });
      break;
    case BranchManageTab.BranchInvoice:
      this.$router.push({
        name: BranchAndGroupRouterName.BranchInvoice,
        params: {
          id: this.serviceId
        }
      });
      break;
    case BranchManageTab.BranchTeamLeader:
      this.$router.push({
        name: BranchAndGroupRouterName.BranchTeamLeader,
        params: {
          id: this.serviceId
        }
      });
      break;
    case BranchManageTab.BranchStaff:
      this.$router.push({
        name: BranchAndGroupRouterName.BranchStaff,
        params: {
          id: this.serviceId
        }
      });
      break;
    case BranchManageTab.BranchProduct:
      this.$router.push({
        name: BranchAndGroupRouterName.BranchProduct,
        params: {
          id: this.serviceId
        }
      });
      break;
    case BranchManageTab.ActiveEWarrantyBranch:
      this.$router.push({
        name: BranchAndGroupRouterName.BranchEWarranty,
        params: {
          id: this.serviceId
        }
      });
      break;

    default:
      break;
    }
  }
  protected mounted() {
    const breadcrumb: IBreadcrumb[] = [
      {
        label: 'title.managebranch',
        path: BranchAndGroupRouterPath.ListBranch,
        current: false
      },
      {
        label: 'title.view_details',
        path: null,
        current: true
      }
    ];
    this.$store.commit(MutationType.SetBack);
    this.$store.commit(MutationType.SetBreadcrumb, breadcrumb);
    this.$store.commit(MutationType.SetShowCollapse);
    this.$store.commit(MutationType.SetTypeCollapse, true);
    !this.isRefesh && this.fetchData();
  }
  protected beforeDestroy() {
    this.$store.commit(MutationType.ClearShowCollapse);
    this.$store.commit(MutationType.ClearBreadcrumb);
  }
  private fetchData() {

    this.$store.dispatch(ActionTypeBranchManage.BranchGetById, {
      id: this.serviceId,
      onSuccess: () => {
          //
      },
      onFailure: () => {
          //
      }
    });
  }
}
