import './styles.scss';

import { BranchAndGroupRouterPath } from 'root/admin/BranchAndGroup';
import { ActionTypePoints } from 'root/admin/Point/Store/types';
import { IProductTransState } from 'root/admin/ProductTransaction/Store';
import { SystemRoleAlias } from 'root/admin/User/Store/types';
import { IPaginationParams } from 'root/api/graphql/Core';
import { Avatar } from 'root/components/Avatar';
import { fullNameUser } from 'root/helpers';
import { IBreadcrumb } from 'root/models';
import { IUser } from 'root/models/User';
import { IState, MutationType } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import { DealerRouterName, DealerRouterPath } from '..';

const STRING_TO_BOOL = {
  true: true,
  false: false
};

@Component({
  template: require('./view.html'),
  components: {
    avatar: Avatar,
    // 'user-detail-modal': UserDetailModal,
  },
  computed: {
    ...mapState({
      // authUser: (state: IState) => state.global.authUser,
      data: (state: IState) => state.point.data,
      pagination: (state: IState) => state.point.pagination,
      loading: (state: IState) => state.point.loading,
      filterParams: (state: IState) => state.point.filterParams
    }),
    selectedStatus() {
      const filterParams = [];

      if (!filterParams) {
        return [];
      }

      return [{
        key: 'isCreatedByAppleReviewer',
        value: []
      }];
    }
  },
  watch: {
    type() {
      this.paramsFilter = {
        text: ''
      };
      // this.$store.commit(MutationTypeUser.ClearUserList);
      this.$store.commit(MutationType.SetBreadcrumb, this.$t(this.type));
      this.fetchData();
    }
  },
})

export class SaleRoutsList extends Vue {
  public get columns() {
    return [];

  }

  // public get type(): UserType {
  //   return <UserType> this.$route.name;
  // }
  // public handleCommand(command) {
  //   if (command === 'addnew') {
  //     this.goAddNewRouter();
  //   } else {
  //     this.showUploadDialog();
  //   }
  // }
  // public showUploadDialog() {
  //   this.isSample = true;
  // }

  public get inputSearchPlaceholder(): string {

    return `Search by Name, Email`;
  }

  public authUser: IUser;
  public pagination: IPaginationParams;
  public data: IProductTransState[];
  public paramsFilter = {
    text: ''
  };
  public visible: boolean = false;
  public isSample: boolean = false;
  public userId: string = '';
  public visibleDetail: boolean = false;
  public popupVisible: boolean = false;

  public UserType = {
    Customer: 'customer',
    System: 'system',
  };
  public type: any;
  // tslint:disable-next-line:max-line-length
  private sampleUrl = `https://firebasestorage.googleapis.com/v0/b/daikin-test-staging.appspot.com/o/technicians%2Ftechnicians-import-sample.xlsx?alt=media&token=279661a1-11fe-49a0-8109-364e964ec11b`;

  // public isShowButtonNew(): boolean {
  //   if (this.authUser.group === GroupSystemUserType.Station) {
  //     return false;
  //   }

  //   return true;
  // }

  public closeDialogDetail() {
    this.userId = '';
    this.visibleDetail = false;
  }

  public createSuccess() {
    this.visible = false;
    this.fetchData();
  }

  public applyFilter() {
    this.fetchData();
  }
  public goAddNewRouter() {
    this.visible = true;
    this.userId = '';
  }
  public closeDialog() {
    this.isSample = false;
    this.fetchData();
  }
  public downloadSample() {
    window.open(
      this.sampleUrl,
      '_blank'
    );
  }

  public clickDetail(id: string) {
    this.$router.push({
      name: DealerRouterName.DetailDealer,
      params: {
        id
      }});
  }
  public handleUpdate(id: string) {
    this.visible = true;
    this.userId = id;
  }

  public changeFilter(value) {
    const createdByFilterList = value[0] ? value[0].value : [];

    // this.$store.commit(MutationTypeUser.ChangeFilterParams, stripObject({
    //   isCreatedByAppleReviewer: createdByFilterList
    // }));

    const boolCreatedByFilterList = createdByFilterList.map((v: string) => STRING_TO_BOOL[v]);

    let filterParams = {};

    if (boolCreatedByFilterList.length === 1) {
      const filterValue = boolCreatedByFilterList[0];

      if (filterValue) {
        filterParams = {
          _is_null: true,
        };
      } else {
        filterParams = {
          _is_null: false,
          _eq: true
        };
      }

      return filterParams;
    }
    this.fetchData();
  }

  public formatFullNameUser(model) {
    return fullNameUser(model);
  }

  protected mounted() {
    this.$nextTick(() => {
      const breadcrumb: IBreadcrumb[] = [
        {
          label: 'title.dealer',
          path: DealerRouterPath.ListDealer,
          current: false
        },
        {
          label: 'title.view_details',
          path: null,
          current: true
        }
      ];
      const _breadcrumb: IBreadcrumb[] = [
        {
          label: 'title.managegroup',
          path: BranchAndGroupRouterPath.ListGroupManage,
          current: false
        },
        {
          label: 'title.view_details',
          path: null,
          current: true
        }
      ];
      this.$store.commit(MutationType.SetBack);
      if (this.authUser && (this.authUser.role.alias === SystemRoleAlias.Admin ||
        this.authUser.role.alias === SystemRoleAlias.SaleAdmin)) {
        this.$store.commit(MutationType.SetBreadcrumb, breadcrumb);
      }

      this.$store.commit(MutationType.SetBreadcrumb, _breadcrumb);

      this.fetchData();
    });
  }
  protected beforeDestroy() {
    this.$store.commit(MutationType.ClearBreadcrumb);
  }

  private fetchData() {
    this.$store.dispatch(ActionTypePoints.FilterNoCache);
  }
}
