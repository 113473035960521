import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';

import './styles.scss';

@Component({
  template: require('./view.html'),
  props: {
    title: String,
    dataBranch: Array,
    branchSelected: Array,
    isCheckAllDealerBranch: Array,
    unAllDealerBranch: Array
  }
})

export class DKMultiCheckboxList extends Vue {
  public dataBranch: any[];
  public branchSelected: string[];
  public isCheckAllDealerBranch: any[];
  public allData: any[] = [];
  public checkedItems: string[] = [];
  public isIndeterminate: boolean = false;
  public unAllDealerBranch: string[];

  public get getListItem() {
    if (this.dataBranch) {
      this.allData = this.dataBranch;
      if (this.branchSelected.length) {
        this.checkedItems = this.branchSelected;
      }

      return this.allData;
    }

    return [];
  }
  public changeFocusBranch(val) {
    this.$emit('changeFocusBranch', val);
    const indexBranch = this.checkedItems.indexOf(val);
    if (indexBranch < 0) {
      this.checkedItems.push(val);
      this.$emit('changeSelectedBranch', this.checkedItems);

      return this.checkedItems;

    }

    return this.checkedItems;

  }
  public onChangeItems(value) {
    if (value.length > 0) {
      this.$emit('changeSelectedBranch', value);
      if (value.length === 1) {
        this.$emit('changeFocusBranch', value[0]);
      }
      if (this.checkedItems.length > 0) {
        this.$emit('changeFocusBranch', this.checkedItems[this.checkedItems.length - 1]);
      }
    }
    this.checkedItems = value;
  }
  @Watch('branchSelected')
  public changeSelectedItems() {
    this.checkedItems = this.branchSelected;
  }
}
