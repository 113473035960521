import Vue from 'vue';
import Component from 'vue-class-component';

import { SystemRoleAlias } from 'root/admin/User/Store/types';
import { FormatDateFullTime } from 'root/helpers';
import { IUser } from 'root/models';
import { IState } from 'root/store';
import { mapState } from 'vuex';
import { ApproveZeroCampaign } from '../ApproveZeroCampaign';
import { ForceCloseCode } from '../ForceCloseCode';
import { RejectZeroCampaign } from '../RejectZeroCampaign';
import { RequestCloseCode } from '../RequestCloseCode';
import './styles.scss';
@Component({
  template: require('./view.html'),
  components: {
    'approve-confirm': ApproveZeroCampaign,
    'reject-confirm': RejectZeroCampaign,
    'close-code': ForceCloseCode,
    'request-close-code': RequestCloseCode
  },
  props: {
    company: {
      type: Object,
      default: () => {
        return null;
      }
    }
  },
  computed: {
    ...mapState({
      authUser: (state: IState) => state.global.authUser,
    })
  },
})
export class CompanyCampaignDetail extends Vue {
  public company;
  public authUser: IUser;
  public visibleApprove: boolean = false;
  public visibleReject: boolean = false;
  public visibleCloseCode: boolean = false;
  public visibleRequestCloseCode: boolean = false;
  public campaignCompanyId: string = '';
  public campaign: any = {};
  public campaignCompany: any = {};
  public FormatDateFullTime = FormatDateFullTime;
  public get currentRole() {
    return this.authUser.role.alias;
  }
  public get HandleStatusByRole() {
    if (this.company.companyStatus === 'rejected' && this.company.lastReviewStatus === 'approved'
    && this.company.lastReviewer && this.company.lastReviewer.role &&
    this.company.lastReviewer.role.alias === SystemRoleAlias.GroupLeader) {
      return 'rejected';
    }
    switch (this.currentRole) {
    case SystemRoleAlias.SaleStaff:
      if (this.company.companyStatus === 'stop_joining') {
        return 'stop_joining';
      }
      if (this.company && this.company.type === 'open_code' && (
        !this.company.lastReviewer || (this.company.lastReviewer &&
          this.company.lastReviewer.role && this.company.lastReviewer.role.alias
        && this.company.lastReviewer.role.alias === SystemRoleAlias.GroupLeader &&
        (this.company.companyStatus === 'rejected' || this.company.companyStatus === 'approved'))
      )) {
        return this.company.companyStatus;
      }
      if (this.company && this.company.type === 'open_code' && (
        this.company.lastReviewer && this.company.lastReviewer.role && this.company.lastReviewer.role.alias
        && this.company.lastReviewer.role.alias === SystemRoleAlias.GroupLeader &&
        this.company.companyStatus === 'have_code'
      )) {
        return 'have_code';
      }
      if (this.company && this.company.type === 'closed_code' &&
      this.company.companyStatus === 'request_stop_joining' &&
      this.company.lastReviewer && this.company.lastReviewer.role && this.company.lastReviewer.role.alias
        && this.company.lastReviewer.role.alias === SystemRoleAlias.GroupLeader) {
        return 'waiting_for_review';
      }
      if (this.company && this.company.type === 'closed_code' && (
        this.company.stopRequester && this.company.stopRequester.role && this.company.stopRequester.role.alias
        && this.company.stopRequester.role.alias === SystemRoleAlias.SaleStaff
      )) {
        return 'request_by_sales_staff';
      }
      if (this.company && this.company.type === 'closed_code' && (
        this.company.stopRequester && this.company.stopRequester.role && this.company.stopRequester.role.alias
        && this.company.stopRequester.role.alias === SystemRoleAlias.SaleDepartmentPic
      )) {
        return 'request_by_sales_pic';
      }

      return this.company.lastReviewStatus ? this.company.lastReviewStatus :
      this.company.companyStatus ? this.company.companyStatus : '--';
    case SystemRoleAlias.SaleDepartmentPic:
      if (this.company.companyStatus === 'stop_joining') {
        return 'stop_joining';
      }
      if (this.company && this.company.type === 'open_code' && this.company.lastReviewer &&
        this.company.lastReviewer.role && this.company.lastReviewer.role.alias
        && this.company.lastReviewer.role.alias === SystemRoleAlias.SaleStaff) {
        return 'waiting_for_review';
      }
      if (this.company && this.company.type === 'open_code' && (
        this.company.lastReviewer && this.company.lastReviewer.role && this.company.lastReviewer.role.alias
        && this.company.lastReviewer.role.alias === SystemRoleAlias.GroupLeader &&
        this.company.companyStatus === 'have_code'
      )) {
        return 'have_code';
      }
      if (this.company && this.company.type === 'closed_code' &&
      this.company.companyStatus === 'request_stop_joining' &&
      this.company.lastReviewer && this.company.lastReviewer.role && this.company.lastReviewer.role.alias
        && this.company.lastReviewer.role.alias === SystemRoleAlias.GroupLeader) {
        return 'request_stop_joining';
      }
      if (this.company && this.company.type === 'closed_code' &&
      this.company.companyStatus === 'request_stop_joining' &&
      this.company.lastReviewer && this.company.lastReviewer.role && this.company.lastReviewer.role.alias
        && this.company.lastReviewer.role.alias === SystemRoleAlias.SaleStaff) {
        return 'waiting_for_review';
      }
      if (this.company && this.company.type === 'closed_code' && (
        this.company.stopRequester && this.company.stopRequester.role && this.company.stopRequester.role.alias
        && this.company.stopRequester.role.alias === SystemRoleAlias.SaleStaff &&
        (this.company.lastReviewer && this.company.lastReviewer.role && this.company.lastReviewer.role.alias
          && this.company.lastReviewer.role.alias === SystemRoleAlias.SaleStaff)
      )) {
        return 'waiting_for_review';
      }
      if (this.company && this.company.type === 'closed_code' && (
        this.company.stopRequester && this.company.stopRequester.role && this.company.stopRequester.role.alias
        && this.company.stopRequester.role.alias === SystemRoleAlias.SaleStaff
      )) {
        return 'request_by_sales_staff';
      }
      if (this.company && this.company.type === 'closed_code' && (
        this.company.stopRequester && this.company.stopRequester.role && this.company.stopRequester.role.alias
        && this.company.stopRequester.role.alias === SystemRoleAlias.SaleDepartmentPic
      )) {
        return 'request_by_sales_pic';
      }

      return this.company.lastReviewStatus ? this.company.lastReviewStatus :
        this.company.companyStatus ? this.company.companyStatus : '--';
    case SystemRoleAlias.GroupLeader:
      if (this.company.companyStatus === 'stop_joining') {
        return 'stop_joining';
      }
      if (this.company && this.company.type === 'open_code' && this.company.lastReviewer &&
        this.company.lastReviewer.role && this.company.lastReviewer.role.alias
        && this.company.lastReviewer.role.alias === SystemRoleAlias.SaleDepartmentPic) {
        return 'waiting_for_review';
      }
      if (this.company && this.company.type === 'closed_code' && this.company.lastReviewer &&
      this.company.lastReviewer.role && this.company.lastReviewer.role.alias
      && this.company.lastReviewer.role.alias === SystemRoleAlias.SaleDepartmentPic) {
        return 'waiting_for_review';
      }
      if (this.company && this.company.type === 'closed_code' && (
        this.company.stopRequester && this.company.stopRequester.role && this.company.stopRequester.role.alias
        && this.company.stopRequester.role.alias === SystemRoleAlias.SaleStaff
      )) {
        return 'request_by_sales_staff';
      }
      if (this.company && this.company.type === 'closed_code' && (
        this.company.stopRequester && this.company.stopRequester.role && this.company.stopRequester.role.alias
        && this.company.stopRequester.role.alias === SystemRoleAlias.SaleDepartmentPic
      )) {
        return 'request_by_sales_pic';
      }

      return this.company.lastReviewStatus ? this.company.lastReviewStatus :
        this.company.companyStatus ? this.company.companyStatus : '--';
    }

    return this.company.companyStatus ? this.company.companyStatus : '--';
  }
  public get HandleReviewByRole() {
    if (this.company.companyStatus === 'rejected' && this.company.lastReviewStatus === 'approved'
    && this.company.lastReviewer && this.company.lastReviewer.role &&
    this.company.lastReviewer.role.alias === SystemRoleAlias.GroupLeader) {
      return false;
    }
    switch (this.currentRole) {
    case SystemRoleAlias.SaleStaff:
      if (this.company.companyStatus === 'stop_joining') {
        return false;
      }
      if (this.company && this.company.type === 'open_code' && !this.company.lastReviewer) {
        return true;
      }
      if (this.company && this.company.type === 'open_code' &&
        this.company.lastReviewer &&
          this.company.lastReviewer.role && this.company.lastReviewer.role.alias
        && this.company.lastReviewer.role.alias === SystemRoleAlias.GroupLeader &&
        (this.company.companyStatus === 'rejected' || this.company.companyStatus === 'approved')
      ) {
        return false;
      }
      if (this.company && this.company.type === 'open_code' && (
        this.company.lastReviewer && this.company.lastReviewer.role && this.company.lastReviewer.role.alias
        && this.company.lastReviewer.role.alias === SystemRoleAlias.GroupLeader &&
        this.company.companyStatus === 'have_code'
      )) {
        return false;
      }
      if (this.company && this.company.type === 'closed_code' &&
      this.company.companyStatus === 'request_stop_joining' &&
      this.company.lastReviewer && this.company.lastReviewer.role && this.company.lastReviewer.role.alias
        && this.company.lastReviewer.role.alias === SystemRoleAlias.GroupLeader) {
        return true;
      }
      if (this.company && this.company.type === 'closed_code' && (
        this.company.stopRequester && this.company.stopRequester.role && this.company.stopRequester.role.alias
        && this.company.stopRequester.role.alias === SystemRoleAlias.SaleStaff
      )) {
        return false;
      }
      if (this.company && this.company.type === 'closed_code' && (
        this.company.stopRequester && this.company.stopRequester.role && this.company.stopRequester.role.alias
        && this.company.stopRequester.role.alias === SystemRoleAlias.SaleDepartmentPic
      )) {
        return false;
      }

      return false;
    case SystemRoleAlias.SaleDepartmentPic:
      if (this.company.companyStatus === 'stop_joining') {
        return false;
      }
      if (this.company && this.company.type === 'open_code' && this.company.lastReviewer &&
        this.company.lastReviewer.role && this.company.lastReviewer.role.alias
        && this.company.lastReviewer.role.alias === SystemRoleAlias.SaleStaff) {
        return true;
      }
      if (this.company && this.company.type === 'open_code' && (
        this.company.lastReviewer && this.company.lastReviewer.role && this.company.lastReviewer.role.alias
        && this.company.lastReviewer.role.alias === SystemRoleAlias.GroupLeader &&
        this.company.companyStatus === 'have_code'
      )) {
        return false;
      }
      if (this.company && this.company.type === 'closed_code' &&
      this.company.companyStatus === 'request_stop_joining' &&
      this.company.lastReviewer && this.company.lastReviewer.role && this.company.lastReviewer.role.alias
        && this.company.lastReviewer.role.alias === SystemRoleAlias.GroupLeader) {
        return false;
      }
      if (this.company && this.company.type === 'closed_code' &&
      this.company.companyStatus === 'request_stop_joining' &&
      this.company.lastReviewer && this.company.lastReviewer.role && this.company.lastReviewer.role.alias
        && this.company.lastReviewer.role.alias === SystemRoleAlias.SaleStaff) {
        return true;
      }
      if (this.company && this.company.type === 'closed_code' && (
        this.company.stopRequester && this.company.stopRequester.role && this.company.stopRequester.role.alias
        && this.company.stopRequester.role.alias === SystemRoleAlias.SaleStaff && this.company.lastReviewer
        && this.company.lastReviewer.role && this.company.lastReviewer.role.alias === SystemRoleAlias.SaleStaff
      )) {
        return true;
      }
      if (this.company && this.company.type === 'closed_code' && (
        this.company.stopRequester && this.company.stopRequester.role && this.company.stopRequester.role.alias
        && this.company.stopRequester.role.alias === SystemRoleAlias.SaleStaff && this.company.lastReviewer
        && this.company.lastReviewer.role && this.company.lastReviewer.role.alias !== SystemRoleAlias.SaleStaff
      )) {
        return false;
      }
      if (this.company && this.company.type === 'closed_code' && (
        this.company.stopRequester && this.company.stopRequester.role && this.company.stopRequester.role.alias
        && this.company.stopRequester.role.alias === SystemRoleAlias.SaleDepartmentPic
      )) {
        return false;
      }
      if (this.company && this.company.type === 'closed_code' && (
        this.company.stopRequester && this.company.stopRequester.role && this.company.stopRequester.role.alias
        && this.company.stopRequester.role.alias === SystemRoleAlias.SaleDepartmentPic
      )) {
        return false;
      }

      return false;
    case SystemRoleAlias.GroupLeader:
      if (this.company.companyStatus === 'stop_joining') {
        return false;
      }
      if (this.company && this.company.type === 'open_code' && this.company.lastReviewer &&
        this.company.lastReviewer.role && this.company.lastReviewer.role.alias
        && this.company.lastReviewer.role.alias === SystemRoleAlias.SaleDepartmentPic) {
        return true;
      }
      if (this.company && this.company.type === 'closed_code' && this.company.lastReviewer &&
      this.company.lastReviewer.role && this.company.lastReviewer.role.alias
      && this.company.lastReviewer.role.alias === SystemRoleAlias.SaleDepartmentPic) {
        return true;
      }

      return false;
    }

    return false;
  }
  public get checkPermissionForceCloseCode() {
    if (this.currentRole === SystemRoleAlias.SaleAdmin && (
      this.company.companyStatus === 'have_code' || this.company.companyStatus === 'request_stop_joining'
    )) {
      return true;
    }

    return false;
  }
  public get checkPermissionCloseCodeForSaleStaff() {
    if ((this.currentRole === SystemRoleAlias.SaleStaff || this.currentRole === SystemRoleAlias.SaleDepartmentPic)
    && this.company.companyStatus === 'have_code'
    && this.company.type !== 'closed_code') {
      return true;
    }

    return false;
  }
  public get checkPermissionConfirmCloseCodeForSaleStaff() {
    if ((this.currentRole === SystemRoleAlias.SaleStaff) && this.company.companyStatus === 'request_stop_joining'
    && (this.company.lastReviewer && this.company.lastReviewer.role.alias !== SystemRoleAlias.SaleStaff)
    && this.company.type === 'closed_code') {
      return true;
    }

    return false;
  }
  public get checkPermissionCloseCode() {
    if (this.currentRole === SystemRoleAlias.SaleStaff && this.company.companyStatus === 'request_stop_joining'
    && this.company.type === 'closed_code' && this.company.lastReviewer
    && this.company.lastReviewer.role && this.company.lastReviewer.role.alias === SystemRoleAlias.GroupLeader) {
      return true;
    }
    if (this.currentRole === SystemRoleAlias.SaleDepartmentPic &&
      (this.company.companyStatus === 'have_code' || this.company.companyStatus === 'request_stop_joining')
    && this.company.type === 'closed_code' && this.company.lastReviewer
    && this.company.lastReviewer.role && this.company.lastReviewer.role.alias === SystemRoleAlias.SaleStaff) {
      return true;
    }
    if (this.currentRole === SystemRoleAlias.GroupLeader && this.company.companyStatus === 'have_code'
    && this.company.type === 'closed_code' && this.company.lastReviewer
    && this.company.lastReviewer.role && this.company.lastReviewer.role.alias === SystemRoleAlias.SaleDepartmentPic) {
      return true;
    }
    if (this.currentRole === SystemRoleAlias.GroupLeader && this.company.companyStatus === 'request_stop_joining'
    && this.company.type === 'closed_code' && this.company.lastReviewer
    && this.company.lastReviewer.role && this.company.lastReviewer.role.alias === SystemRoleAlias.SaleDepartmentPic) {
      return true;
    }

    return false;
  }
  public get StatusByRole() {
    if (this.currentRole !== SystemRoleAlias.GroupLeader && this.company && this.company.stopRequester) {
      if (this.currentRole === SystemRoleAlias.SaleStaff && this.company.stopRequester.role.alias
        === SystemRoleAlias.SaleStaff) {
        return 'request_by_sales_staff';
      }
      if (this.currentRole === SystemRoleAlias.SaleStaff && this.company.stopRequester.role.alias
        === SystemRoleAlias.SaleDepartmentPic) {
        return 'request_by_sales_pic';
      }

      return 'waiting_for_review';
    }
    if (this.currentRole !== SystemRoleAlias.GroupLeader &&
      (this.company.companyStatus === 'have_code' || this.company.companyStatus === 'stop_joining')) {
      return this.company.companyStatus;
    }

    if (this.currentRole === SystemRoleAlias.SaleDepartmentPic &&
      this.company.companyStatus === 'request_stop_joining'
    && this.company.lastReviewer && this.company.lastReviewer.role &&
    this.company.lastReviewer.role.alias !== SystemRoleAlias.SaleStaff) {
      return 'request_stop_joining';
    }
    if (this.currentRole === SystemRoleAlias.GroupLeader &&
      this.company.companyStatus === 'request_stop_joining'
    && this.company.lastReviewer && this.company.lastReviewer.role &&
    this.company.lastReviewer.role.alias !== SystemRoleAlias.SaleDepartmentPic) {
      return 'request_stop_joining';
    }
    if (this.currentRole === SystemRoleAlias.SaleStaff && this.company.companyStatus === 'waiting_for_review'
    && !this.company.lastReviewer) {
      return 'waiting_for_review';
    }
    if (this.currentRole === SystemRoleAlias.GroupLeader && this.company.companyStatus === 'request_stop_joining'
    && this.company.lastReviewer && this.company.lastReviewer.role &&
    this.company.lastReviewer.role.alias === SystemRoleAlias.SaleDepartmentPic) {
      return 'waiting_for_review';
    }
    if (this.currentRole === SystemRoleAlias.SaleDepartmentPic && this.company.companyStatus === 'request_stop_joining'
    && this.company.lastReviewer && this.company.lastReviewer.role &&
    this.company.lastReviewer.role.alias === SystemRoleAlias.SaleStaff) {
      return 'waiting_for_review';
    }
    if (this.currentRole !== SystemRoleAlias.SaleStaff && this.company.companyStatus === 'waiting_for_review'
    && !this.company.lastReviewer) {
      return '';
    }
    if (this.currentRole === SystemRoleAlias.SaleDepartmentPic && this.company.companyStatus === 'waiting_for_review'
    && this.company.lastReviewer && this.company.lastReviewer.role &&
    this.company.lastReviewer.role.alias === SystemRoleAlias.SaleStaff) {
      return 'waiting_for_review';
    }
    if (this.currentRole === SystemRoleAlias.GroupLeader && (
      this.company.companyStatus === 'waiting_for_review' ||
      this.company.companyStatus === 'have_code'
    )
    && this.company.lastReviewer && this.company.lastReviewer.role &&
    this.company.lastReviewer.role.alias === SystemRoleAlias.SaleDepartmentPic) {
      return 'waiting_for_review';
    }

    return this.company.lastReviewStatus ? this.company.lastReviewStatus : 'waiting_for_review';
  }
  public get columnsHistory() {
    return [
      {
        prop: 'type',
        label: 'Type',
        width: 200,
      },
      {
        prop: 'role',
        label: 'Role',
        formatter: (model) => {
          return model && model.creator && model.creator.role && model.creator.role.name ?
          model.creator.role.name : '--';
        },
        width: 160,
      },
      {
        prop: 'name',
        label: 'Name',
        formatter: (model) => {
          return model && model.creator && model.creator.name ? model.creator.name : '--';
        },
        width: 160,
      },
      {
        prop: 'time',
        label: 'Review Time',
        formatter: (model) => {
          return model && model.createdAt ? this.FormatDateFullTime(model.createdAt) : '--';
        },
        width: 200,
      },
      {
        prop: 'status',
        label: 'Status',
        formatter: (model) => {
          return model && model.reviewStatus ? model.reviewStatus : '--';
        },
        width: 175,
      },
    ];
  }
  public formatterTypePosCode(model) {
    return model && model.type ? model.type : '--';
  }
  public handleActions(action) {
    if (action === 'approve') {
      this.visibleApprove = true;
      this.campaignCompany = this.company;

      return;
    }
    if (action === 'reject') {
      this.visibleReject = true;
      this.campaignCompany = this.company;

      return;
    }
    if (action === 'close') {
      this.visibleRequestCloseCode = true;
      this.campaignCompany = this.company;

      return;
    }
    if (action === 'force-close') {
      this.visibleCloseCode = true;
      this.campaignCompany = this.company;

      return;
    }

    return;
  }
  public mounted() {
    // console.log(this.$props.company, this.company);
  }
  public closeDialogApprove() {
    this.visibleApprove = false;
    this.campaignCompany = {};
    setTimeout(() => {
      this.$emit('refetchData');
    }, 500);
  }
  public closeDialogReject() {
    this.visibleReject = false;
    this.campaignCompany = {};
    setTimeout(() => {
      this.$emit('refetchData');
    }, 500);
  }
  public closeDialogRequestCloseCode() {
    this.visibleRequestCloseCode = false;
    this.campaignCompany = {};
    this.$emit('refetchData');
    // this.campaignCompany = {};
    // this.fetchData();
  }
  public closeDialogCloseCode() {
    this.visibleCloseCode = false;
    this.campaignCompany = {};
    this.$emit('refetchData');
    // this.campaignCompany = {};
    // this.fetchData();
  }
}
