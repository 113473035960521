import './styles.scss';

import { Subscription } from 'apollo-client/util/Observable';
import { ElUpload } from 'element-ui/types/upload';
import { cloneDeep, find } from 'lodash';
import { ActionTypeBranchManage } from 'root/admin/BranchAndGroup/BranchManage/Store/types';
import { ActionTypeRegion } from 'root/admin/Region/Store/types';
import { SystemRoleAlias } from 'root/admin/User/Store/types';
import { DKGqlClient } from 'root/api/graphql/Client';
import { gqlClient, IPaginationParams } from 'root/api/graphql/Core';
import { dealerListQuery } from 'root/api/graphql/Query/dealer';
import { Avatar } from 'root/components/Avatar';
import { FilterByBranch } from 'root/components/FilterByBranch';
import { SearchByFilter } from 'root/components/SearchByFilter';
import { fullNameUser } from 'root/helpers';
import { IAddress, IFilterInput, IProvince, StatusCode } from 'root/models';
import { IUser } from 'root/models/User';
import { pathUpload, uploadToFirebase } from 'root/services';
import { FirebaseAuthService } from 'root/services/auth/firebase';
import { IState, MutationType, TypeAlert } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import { UpgradeRequestModal } from '../Components/UpgradeRequest';
import { IDealerState } from '../Store';
import {
  ActionTypeDealer,
  MutationTypeDealer,
} from '../Store/types';
import { SubDealerDetail } from '../SubDealerDetail';
import { SubDealerEditor } from '../SubDealerEditor';

const enum FilterKey {
  Branch = 'branch',
  Region = 'region',
  Status = 'status'
}
enum subDealerSearchBy {
  SubDealerName = 'subDealerName',
  SubDealerCode = 'subDealerCode',
  businessLicense = 'businessLicense',
  houseHoldLicense = 'houseHoldLicense',
  Identity = 'identityNumber',
  BranchName = 'branchName',
  BranchCode = 'branchCode',
  PhoneNumber = 'phoneNumber'
}
@Component({
  template: require('./view.html'),
  components: {
    avatar: Avatar,
    'btn-filter-by-branch': FilterByBranch,
    'upragde-request-modal': UpgradeRequestModal,
    detail: SubDealerDetail,
    editor: SubDealerEditor,
    'search-by-filter': SearchByFilter
  },
  computed: {
    ...mapState({
      authUser: (state: IState) => state.global.authUser,
      data: (state: IState) => state.dealer.subDealer,
      pagination: (state: IState) => state.dealer.pagination,
      branch: (state: IState) => state.branchManage.data,
      region: (state: IState) => state.region.data,
      loading: (state: IState) => state.dealer.loading,
      localVN: (state: IState) => state.global.localVN,
    }),
    subDealerSearchBy() {
      const result: IFilterInput[] = [];
      for (const key in subDealerSearchBy) {
        // tslint:disable-next-line:early-exit
        if (subDealerSearchBy.hasOwnProperty(key)) {
          const element = subDealerSearchBy[key];
          result.push({
            key,
            value: element,
            name: this.$t(element)
          });
        }
      }

      return [{
        key: 'select',
        name: this.$t('select'),
        value: result
      }];
    },
    selectedFieldSearchBy() {
      const filterParams = cloneDeep(this.modelSearchBy);

      return [{
        key: 'select',
        value: filterParams ? filterParams : []
      }];
    },
  },
})
export class SubDealerList extends Vue {
  public get canImportSubDealer() {
    if (this.authUser.superAdmin || this.authUser.role.alias === SystemRoleAlias.Admin
      || this.authUser.role.alias === SystemRoleAlias.SaleAdmin
      || this.authUser.role.alias === SystemRoleAlias.BranchManager
      || this.authUser.role.alias === SystemRoleAlias.BranchPIC) {
      return true;
    }

    return false;
  }
  public get columns() {
    return [
      {
        prop: 'name',
        label: 'Sub-dealer Name',
        labelItem: 'Sub-dealer Code',
        width: 250,
        fixed: true
      },
      {
        prop: 'businessLicense',
        label: 'Business License',
        width: 200,
        formatter: (model: any) => {
          return model.information && model.information.businessRegistrationNo ?
            model.information.businessRegistrationNo : '--';
        }
      },
      {
        prop: 'houseHoldLicense',
        label: 'HouseHold Business License',
        width: 200,
        formatter: (model: any) => {
          return model.information && model.information.businessHouseholdNo ?
            model.information.businessHouseholdNo : '--';
        }
      },
      {
        prop: 'identityNumber',
        label: 'Identity Number',
        width: 200,
        formatter: (model: any) => {
          return model.idCard ? model.idCard : '--';
        }
      },
      {
        prop: 'branch',
        label: 'Branch Name',
        labelItem: 'Branch Code',
        width: 180,
      },
      {
        prop: 'region',
        label: 'Region',
        width: 180,
        formatter: (model: any) => {
          return model.region && model.region.name ? model.region.name : '--';
        }
      },
      {
        prop: 'province',
        label: 'Province',
        width: 180,
        formatter: this.formatterProvice,
      },
      {
        prop: 'phoneNumber',
        label: 'Phone',
        width: 180,
        formatter: (model: any) => {
          return model.phoneNumber ? model.phoneNumber : '--';
        }
      },
      {
        prop: 'address',
        label: 'Address',
        width: 220,
        formatter: (model: any) => {
          if (model.address) {
            return this.displayAddress(model.address) || '--';
          }

          return '--';
        }
      },
      {
        prop: 'status',
        label: this.$t('status'),
        formatter: (model: any) => (model.status ? this.$t(model.status) : ''),
        labelStyle: (model: any) => {
          return model.status;
        },
        width: 180
      },
      {
        prop: 'action',
        label: '',
        align: 'center',
        fixed: 'right',
        width: 60
      }
    ];
  }
  public get actions() {
    return [
      'view'
    ];
  }

  public get inputSearchPlaceholder(): string {
    return `Search by Name, Email`;
  }
  public get selectedFilter() {
    const filterParams = cloneDeep(this.filterParams);

    return [
      {
        key: FilterKey.Branch,
        value: filterParams[FilterKey.Branch]
      },
      {
        key: FilterKey.Region,
        value: filterParams[FilterKey.Region]
      },
      {
        key: FilterKey.Status,
        value: filterParams[FilterKey.Status]
      }
    ];
  }
  public get dataFilterBranch() {
    const _branch: IFilterInput[] = this.branch.reduce((res, item) => {
      res.push({
        key: item.id,
        value: item.id,
        name: item.name
      });

      return res;
    }, []);

    return [
      {
        key: FilterKey.Branch,
        name: 'Branch',
        value: _branch
      },
    ];
  }
  public get dataFilter() {
    const _region: IFilterInput[] = this.region.reduce((res, item) => {
      res.push({
        key: item.id,
        value: item.id,
        name: item.name
      });

      return res;
    }, []);
    const _statuses: IFilterInput[] = [
      {
        key: StatusCode.Active,
        value: StatusCode.Active,
        name: this.$t(StatusCode.Active)
      },
      {
        key: StatusCode.Blocked,
        value: StatusCode.Blocked,
        name: this.$t(StatusCode.Blocked)
      },
      {
        key: StatusCode.Suspended,
        value: StatusCode.Suspended,
        name: this.$t(StatusCode.Suspended)
      },
    ];

    return [
      {
        key: FilterKey.Region,
        name: 'Region',
        value: _region
      },
      {
        key: FilterKey.Status,
        name: this.$t('field.status'),
        value: _statuses
      }
    ];
  }

  public authUser: IUser;
  public localVN: IProvince[];
  public pagination: IPaginationParams;
  public data: IDealerState[];
  public branch: any[];
  public region: any[];
  public group: any[];
  public typefilter: any[];
  public status: any[];
  public dealerId: string = '';
  public visible: boolean = false;
  public isSample: boolean = false;
  public userId: string = '';
  public visibleDetail: boolean = false;
  public visibleUpdate: boolean = false;
  public popupVisible: boolean = false;
  public filterParams = {
    [FilterKey.Branch]: [],
    [FilterKey.Region]: [],
    [FilterKey.Status]: []
  };
  public modelSearchBy: any[] = [];
  public visibleUpgrade: boolean = false;
  public searchText: string = '';
  public filterTypeDealer: any[];
  public isRefesh: boolean;
  public visibleChangePass: boolean = false;
  public file: any;
  public fileUpload: any;
  public fileUploaded= [];
  public fileList = [];
  public loadingUpload: boolean = false;
  public loadingExport: boolean = false;
  public DaikinDealerId: string;
  public $refs: {
    upload: ElUpload;
  };
  public visibleImport: boolean = false;

  public type: any;
  private pathUploadAvatar: pathUpload = pathUpload.SUBDEALER;
  private subscribeURL: Subscription = null;
  // tslint:disable-next-line: max-line-length
  private sampleUrl = `https://firebasestorage.googleapis.com/v0/b/daikin-sale-dev.appspot.com/o/sub_dealer%2F1649392557886template_import_Subdealer_4_Apr.xlsx?alt=media&token=99ee5da6-d397-40c8-bf66-e8ae11df4cf0`;
  public getActions() {

    return [
      'view',
      'edit',
      'block',
      'delete',
    ];
  }
  public formatterProvice(model) {
    // cahgne after;
    const _province = model.address ? model.address.province : null;
    if (!_province) {
      return '--';
    }
    const province = find(cloneDeep(this.localVN), (e) => Number(e.matp) === Number(_province));

    return province && province.name ? province.name : '--';
  }
  public handleActions({ id, action }) {
    if (action === 'edit') {
      this.handleUpdate(id);

      return;
    }

    if (action === 'view') {
      this.clickDetail(id);

      return;
    }

    if (action === 'block') {
      this.handleBlock(id);

      return;
    }
    if (action === 'delete') {
      this.deleteConfirm(id);

      return;
    }

    return;
  }
  public changeSearchBy(model: IFilterInput[]) {
    this.modelSearchBy = model[0].value;

    this.fetchData();
  }
  public onEnterFilter(value) {
    this.searchText = value;

    this.fetchData();
  }
  public handleBlock(id: string) {
    this.$msgbox({
      title: this.$t('confirm').toString(),
      message: this.$t('action_confirm_message').toString(),
      showCancelButton: true,
      confirmButtonText: this.$t('confirm').toString(),
      cancelButtonText: this.$t('cancel').toString(),
      beforeClose: (action, instance, done) => {
        if (action === 'confirm') {
          instance.confirmButtonLoading = true;
          instance.confirmButtonText = 'Loading...';
          this.confirmBlock({ id, instance, done });
        } else {
          done();
        }

        return;
      }
    }).catch(() => {
      // no handle
    });
  }
  public confirmBlock(model: any) {
    const { status } = this.$store.getters.getSubDealerDetail(model.id);
    this.$store.dispatch(ActionTypeDealer.UpdateStatusDealer, {
      id: model.id,
      status:
        status !== StatusCode.Blocked ? StatusCode.Blocked : StatusCode.Active,
      onSuccess: () => {
        this.fetchData();
        model.instance.confirmButtonLoading = false;
        model.instance.confirmButtonText = 'Confirm';
        const message = 'Block Sub-Dealer successfully';
        this.$store.commit(MutationType.OpenTopAlert, {
          message,
          type: TypeAlert.Success
        });
        model.done();
      },
      onFailure: () => {
        model.instance.confirmButtonLoading = false;
        model.instance.confirmButtonText = 'Confirm';
      }
    });
  }
  public exportDealer(role: string) {
    this.loadingExport = true;
    this.$store.dispatch(ActionTypeDealer.GetRoleId, {
      role,
      onSuccess: (roleId) => {
        // tslint:disable-next-line: early-exit
        if (roleId && roleId.length) {
          this.$store.dispatch(ActionTypeDealer.ExportDealer, {
            object: {filter:
            {
              type: roleId[0].id,
              region: this.filterParams[FilterKey.Region].length ? this.filterParams[FilterKey.Region] : null,
              branch: this.filterParams[FilterKey.Branch].length ? this.filterParams[FilterKey.Branch] : null,
              status: this.filterParams[FilterKey.Status].length ? this.filterParams[FilterKey.Status] : null,
            }
            },
            onSuccess: (url) => {
              // tslint:disable-next-line: early-exit
              if (url.returning && url.returning.length) {
                this.subscribeURL = <any> gqlClient.subscribe({
                  query: dealerListQuery.DOWNLOAD_EXPORT,
                  variables : {id: url.returning[0].id},
                  fetchPolicy: 'cache-first'
                }).subscribe((results) => {
                  // tslint:disable-next-line: early-exit
                  if (results.data && results.data.export_dealers && results.data.export_dealers.length
                    && results.data.export_dealers[0].exportStatus === 'done') {
                    window.open(results.data.export_dealers[0].url, '_blank');
                    this.subscribeURL.unsubscribe();
                    this.loadingExport = false;
                  }
                });
              }
            }
          });
        }
      }
    });
  }
  public deleteConfirm(id: string) {
    this.$msgbox({
      title: this.$t('delete_confirm').toString(),
      message: this.$t('delete_confirm_message').toString(),
      showCancelButton: true,
      customClass: 'message-error',
      confirmButtonText: this.$t('confirm').toString(),
      cancelButtonText: this.$t('cancel').toString(),
      beforeClose: (action, instance, done) => {
        if (action === 'confirm') {
          instance.confirmButtonLoading = true;
          instance.confirmButtonText = 'Loading...';
          this.confirmDelete({ id, instance, done });
        } else {
          done();
        }

        return;
      }
    })
      .then(() => {
        this.$message({
          type: 'success',
          message: this.$t('message.deleteSuccess').toString()
        });
      })
      .catch(() => {
        // no handle
      });
  }
  public confirmDelete(model: any) {
    this.$store.dispatch(ActionTypeDealer.UpdateStatusDealer, {
      id: model.id,
      status: StatusCode.Deleted,
      onSuccess: () => {
        this.fetchData();
        model.instance.confirmButtonLoading = false;
        model.instance.confirmButtonText = 'Confirm';
        model.done();
      },
      onFailure: () => {
        model.instance.confirmButtonLoading = false;
        model.instance.confirmButtonText = 'Confirm';
      }
    });
  }
  public closeDialogDetail() {
    this.userId = '';
    this.visibleDetail = false;
  }
  public upgradeRequest(id: string) {
    this.visibleUpgrade = true;
    this.userId = id;
  }
  public importDealer() {
    this.visibleImport = true;
  }

  public createSuccess() {
    this.visibleUpdate = false;
    this.fetchData();
  }

  public applyFilter() {
    this.fetchData();
  }
  public goAddNewRouter() {
    this.visible = true;
    this.userId = '';
  }
  public closeDialog() {
    this.isSample = false;
    this.fetchData();
  }
  public displayAddress(model: IAddress) {
    return model && model.province
      ? this.$store.getters['getAddressString'](model)
      : '';
  }
  public changeFilter(model) {
    const region = model.find((e) => e.key === FilterKey.Region);
    const status = model.find((e) => e.key === FilterKey.Status);
    const nextFilter = {
      [FilterKey.Region]: region ? region.value : [],
      [FilterKey.Status]: status ? status.value : []
    };
    this.filterParams = {
      ...cloneDeep(this.filterParams),
      ...nextFilter
    };
    this.fetchData();
  }
  public changeFilterBranch(filter) {
    const nextFilter = {
      [FilterKey.Branch]: filter ? filter : [],
    };
    this.filterParams = {
      ...cloneDeep(this.filterParams),
      ...nextFilter
    };
    this.fetchData();
  }
  public pushFileUpload(file: any) {
    const f = this.fileList;
    if (this.fileList.length > 0) {
      const _file = this.fileList.filter((it) => it.name === file.name);
      if (_file && _file.length > 0) {
        this.$refs.upload.clearFiles();
        const message = 'File has been exits';
        this.$store.commit(MutationType.OpenTopAlert, {
          message,
          type: TypeAlert.Warning
        });

        return this.$refs.upload.$data.uploadFiles = f;
      }

      return this.fileList = [file];
    }

    f.push(file);

    return this.fileList = [file];
  }
  public onRemove(file: any, fileList: any) {
    console.log(file);
    this.fileList = fileList;
  }
  public handleSearch() {
    this.fetchData();
  }
  public closeModalImport() {
    this.visibleImport = false;
    this.$refs.upload.clearFiles();
    this.fileList = [];
  }

  public handleCommand(command) {
    if (command === 'addnew') {
      this.visible = true;
    } else {
      this.visibleImport = true;
    }
  }
  public downloadSample() {
    window.open(this.sampleUrl, '_blank');
  }

  public clickDetail(id: string) {
    this.dealerId = id;
    this.visible = true;
  }
  public handleUpdate(id: string) {
    this.visibleUpdate = true;
    this.userId = id;
  }

  public formatFullNameUser(model) {
    return fullNameUser(model);
  }
  public async submitFile() {
    // tslint:disable-next-line: whitespace
    try {
      this.loadingUpload = true;
      // const uploadedFile = <any> [];
      const token = await DKGqlClient().user.getFirebaseAuthenticateToken();
      await FirebaseAuthService().signInWithCustomToken(token.authenticate);
      const uploadedFile = await Promise.all(this.fileList.map(async (it) => {
        const _file = await uploadToFirebase(it.raw, this.pathUploadAvatar);

        // return _file ? uploadedFile.push(_file) : uploadedFile;
        // tslint:disable-next-line:no-var-before-return
        return _file;
      }));
      if (uploadedFile.length > 0) {
        const form = {
          createdBy: this.authUser.id,
          url: uploadedFile[0],
          failedReason: ' '
        };
        this.$store.dispatch(ActionTypeDealer.ImportFileSubDealer, {
          form,
          onSuccess: () => {
            this.loadingUpload = false;
            const message = 'Import sub-dealer successfully';
            this.$store.commit(MutationType.OpenTopAlert, {
              message,
              type: TypeAlert.Success
            });
            this.visibleImport = false;
            this.fileList = [];
            this.fileUploaded = [];
            this.$refs.upload.clearFiles();
            this.fetchData();
          },
          onFailure: () => {
            this.loadingUpload = false;

          }});
      }

    } catch (error) {
      console.log(error);
    }

  }
  public handlePaginationChange(value) {
    if (typeof value === 'number') {
      this.$store.commit(MutationTypeDealer.DealerPaginationChange, {
        ...this.pagination,
        size: value
      });
    } else {
      this.$store.commit(MutationTypeDealer.DealerPaginationChange, {
        ...this.pagination,
        ...value
      });
    }
    this.fetchData();
  }

  protected mounted() {
    this.$nextTick(() => {
      this.$store.commit(MutationType.ClearBack);
      this.$store.commit(MutationType.SetBreadcrumb, this.$t('title.sub_dealer'));
      this.$store.commit(MutationTypeDealer.PaginationReset);
      this.$store.commit(MutationTypeDealer.Loading);
      this.$store.dispatch(ActionTypeRegion.FilterNoCache, {
        optionsHTTP: {
          orderBy: {
            updatedAt: 'desc'
          }
        }
      });
      this.$store.dispatch(ActionTypeBranchManage.GetListBranch, {
        form: {
          filter: {},
          pagination: {}
        }
      });
      this.fetchData();

    });
  }
  protected beforeDestroy() {
    this.$store.commit(MutationType.ClearBreadcrumb);
  }

  private async fetchData() {
    const text = cloneDeep(this.searchText);
    const filterSubDealer = <any> {};
    if (this.filterParams[FilterKey.Branch].length) {
      filterSubDealer.branch = this.filterParams[FilterKey.Branch];
    }
    if (this.filterParams[FilterKey.Region].length) {
      filterSubDealer.region = this.filterParams[FilterKey.Region];
    }
    if (this.filterParams[FilterKey.Status].length) {
      filterSubDealer.status = this.filterParams[FilterKey.Status];
    }
    if (text) {
      filterSubDealer.search = text;
    }
    this.$store.dispatch(ActionTypeDealer.GetListSubDealer, {
      pagination: this.pagination,
      searchName: !this.modelSearchBy.length ? null :
      this.modelSearchBy.length && this.modelSearchBy.indexOf(subDealerSearchBy.SubDealerName) >= 0
        ? `%${filterSubDealer.search}%` : ' ',
      searchCode: !this.modelSearchBy.length ? null :
        this.modelSearchBy.length && this.modelSearchBy.indexOf(subDealerSearchBy.SubDealerCode) >= 0
        ? `%${filterSubDealer.search}%` : ' ',
      searchBusiness: !this.modelSearchBy.length ? null :
        this.modelSearchBy.length && this.modelSearchBy.indexOf(subDealerSearchBy.businessLicense) >= 0
        ? `%${filterSubDealer.search}%` : ' ',
      searchHouseHold: !this.modelSearchBy.length ? null :
        this.modelSearchBy.length && this.modelSearchBy.indexOf(subDealerSearchBy.houseHoldLicense) >= 0
        ? `%${filterSubDealer.search}%` : ' ',
      searchIdentity: !this.modelSearchBy.length ? null :
        this.modelSearchBy.length && this.modelSearchBy.indexOf(subDealerSearchBy.Identity) >= 0
        ? `%${filterSubDealer.search}%` : ' ',
      searchBranchName: !this.modelSearchBy.length ? null :
        this.modelSearchBy.length && this.modelSearchBy.indexOf(subDealerSearchBy.BranchName) >= 0
        ? `%${filterSubDealer.search}%` : ' ',
      searchBranchCode: !this.modelSearchBy.length ? null :
        this.modelSearchBy.length && this.modelSearchBy.indexOf(subDealerSearchBy.BranchCode) >= 0
        ? `%${filterSubDealer.search}%` : ' ',
      searchPhoneNumber: !this.modelSearchBy.length ? null :
        this.modelSearchBy.length && this.modelSearchBy.indexOf(subDealerSearchBy.PhoneNumber) >= 0
        ? `%${filterSubDealer.search}%` : ' ',
      branch: filterSubDealer.branch,
      region: filterSubDealer.region,
      status: filterSubDealer.status,
    });
  }
}
